import httpService from "../../../services/http.service";

export const getRequestData = async (requestId: string) => {
  try {
    const res = await httpService.api({
      type: 'getEmployerRequestDetails',
      params: { requestId }
    });

    return res
  } catch { }
};