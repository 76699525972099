import { compact } from 'lodash';
import React, { useState } from 'react';
import { Platform, TouchableHighlight, View } from 'react-native';
import { Menu } from 'react-native-paper';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../../services/i18n.service';
import {
	deleteDocumentApiModal,
	ScreenSendDocumentsToEmployeeModal,
	sendReminderToEmployee,
} from '../../../Documents/Documents.utils';
const ActionButtonsMenu = ({
	userId,
	name,
	unitId,
	docId,
	showSendReminder,
	showDelete,
	setRemoveDoc,
	customStayle,
	setShowModal,
}: any) => {
	const [isSelectedButtonsMenuOpen, setIsSelectedButtonsMenuOpen] =
		useState<boolean>(false);
	const [id, setId] = useState<number | null>(null);
	const platform = useDevicePlatform();
	const actionsButton = compact([
		{
			title: 'usersManagement.modal.documents.integration.menu.sendNewDoc',
			onPress: () => {
				platform !== DeviceType.WEB
					? setShowModal({ id: userId, name, unitId })
					: ScreenSendDocumentsToEmployeeModal(userId, name, unitId);
			},
		},
		// showSendReminder && {
		// 	title: 'usersManagement.modal.documents.integration.menu.sendReminder',
		// 	onPress: () => {
		// 		sendReminderToEmployee(id);
		// 	},
		// },
		showDelete && {
			title: 'usersManagement.modal.documents.integration.menu.delete',
			onPress: () => {
				deleteDocumentApiModal(docId, setRemoveDoc);
			},
		},
	]);

	return (
		<Menu
			visible={isSelectedButtonsMenuOpen}
			contentStyle={{
				marginTop: Platform.OS === 'web' ? 40 : -20,
				marginLeft: 5,
				backgroundColor: colors.white,
				width: 180,
			}}
			onDismiss={() => setIsSelectedButtonsMenuOpen(false)}
			anchor={
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => {
						setIsSelectedButtonsMenuOpen(
							!isSelectedButtonsMenuOpen,
						);
						setId(userId);
					}}
				>
					<View
						style={{
							marginHorizontal: Platform.OS === 'web' ? 10 : 0,
							paddingHorizontal: Platform.OS === 'web' ? 10 : 0,
							alignItems: 'center',
							paddingVertical: 6,
							...customStayle,
						}}
					>
						<ImageIcon
							width={4}
							height={22}
							name={'verticalDots'}
						/>
					</View>
				</TouchableHighlight>
			}
		>
			{actionsButton.map(
				(
					action: {
						title: string;
						onPress: Function;
					},
					index: number,
				) => (
					<>
						<Menu.Item
							onPress={() => {
								action.onPress();
								setIsSelectedButtonsMenuOpen(false);
							}}
							style={{
								alignItems: 'flex-end',
								...Platform.select({
									web: {
										cursor: 'pointer',
									},
								}),
							}}
							titleStyle={{
								color: colors.darkGrey,
								textAlign: 'right',
							}}
							title={i18nService.translate(action.title)}
						/>
					</>
				),
			)}
		</Menu>
	);
};

export default ActionButtonsMenu;
