import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Linking } from 'react-native';
import { FAB } from 'react-native-paper';
import { useSelector } from 'react-redux';
import icons from '../../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { getHideChat } from '../../../store/selectors/config.selectors';
import {
	getIsInWorkerCard,
	isLoggedIn,
} from '../../../store/selectors/login.selectors';
import Chat from './Chat';

const ChatWrapper = () => {
	const loggedIn = useSelector(isLoggedIn);

	const [openChat, setOpenChat] = useState(false);
	const isInWorkerCard = useSelector(getIsInWorkerCard);
	const hideChat = useSelector(getHideChat);
	const platform = useDevicePlatform();

	useEffect(() => {
		setOpenChat(false);
	}, [loggedIn]);

	const onPressOpen = () => {
		Platform.OS === 'web'
			? setOpenChat(!openChat)
			: Linking.openURL('https://wa.me/97236791615');
	};

	return (
		<>
			{openChat ? <Chat /> : null}
			{!isInWorkerCard && !hideChat ? (
				<FAB
					style={StyleSheet.flatten([
						styles.fab,
						{ bottom: platform === DeviceType.WEB ? 50 : 25 },
					])}
					small
					icon={
						Platform.OS !== 'web'
							? icons.whatsappIcon
							: openChat
							? icons.closeBig
							: icons.message
					}
					onPress={onPressOpen}
				/>
			) : null}
		</>
	);
};

const styles = StyleSheet.create({
	fab: {
		position: 'absolute',
		right: 20,
		padding: 15,
		bottom: 25,
		borderRadius: 35,
		backgroundColor: '#1B80C4',
		width: 70,
		height: 70,
	},
	leftButton: {
		right: 92,
	},
});

export default ChatWrapper;
