import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import httpService from '../../services/http.service';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserData } from '../../store/selectors/login.selectors';

import {
	validateForms,
	openModal,
	convertTimeToUtc,
	GetErrorLable,
	getTotalReportedByDate,
	formatStandardHours,
	exitAndEntryDateSetter,
	errorPopUp,
} from './AddRequestModal.utils';
import AddRequestModalComponent from './AddRequestModal.component';

interface Props {
	onSubmit: Function;
	onCancel: Function;
	submitBtnText: string;
	cancelBtnText: string;
	onNext: Function;
	route: any;
	isEdit: boolean;
	prevValues?: prevValuesForm;
	openEditModal?: any;
	workLogId?: any;
	isManager: boolean;
	shiftHours?: number;
	totalReported?: number;
	hideName?: boolean;
	addNew?: boolean;
	hideProjects?: boolean;
	selectedDateForEvent?: string;
	finishWork?: string;
	unitIds?: number[];
	businessUnitsList: any[];
	businessUnitId?: number;
	businessUnitName?: string;
}
interface prevValuesForm {
	employee: {
		id: any;
		name: string;
	};
	originalExitEntry: {
		exitTime: string;
		entryTime: string;
	};
	projectId: string;
	eventId: string;
	date: string;
	entryTime: string;
	exitTime: string;
	fullDay: boolean;
	employeeId: string;
}
const AddRequestContainer = ({
	cancelBtnText,
	onSubmit,
	onCancel,
	onNext,
	submitBtnText,
	route,
	isEdit,
	prevValues,
	openEditModal,
	workLogId,
	isManager,
	shiftHours,
	totalReported,
	hideName,
	addNew,
	hideProjects,
	selectedDateForEvent,
	haveEvents,
	fullEvent,
	finishWork,
	unitIds,
	businessUnitsList,
	businessUnitId,
	businessUnitName,
}: Props) => {
	const userData = useSelector(getUserData);
	const employerEndWork = useMemo(
		() => userData?.operative_module[101]?.finish_work,
		[userData?.operative_module[101]],
	);

	const formMethods = useForm(
		prevValues
			? {
					defaultValues: {
						...prevValues,
						employeeId: prevValues?.employeeId || '',
						projectId: prevValues?.projectId || '',
						eventId: prevValues?.eventId || '',
						date: prevValues?.date || '',
						entryTime: prevValues?.entryTime || '',
						exitTime: prevValues?.exitTime || '',
						fullDay: prevValues?.fullDay || false,
						fromDate: selectedDateForEvent
							? moment(selectedDateForEvent, 'YYYY-MM-DD').format(
									'DD/MM/YYYY',
							  )
							: '',
						toDate: selectedDateForEvent
							? moment(selectedDateForEvent, 'YYYY-MM-DD').format(
									'DD/MM/YYYY',
							  )
							: '',
					},
					mode: 'onBlur',
			  }
			: {
					defaultValues: {
						employeeIds: undefined,
						employeeId: '',
						unitId: unitIds?.length !== 1 ? undefined : unitIds[0],
						projectId: '',
						eventId: '',
						date: '',
						entryTime: '',
						exitTime: '',
						fullDay: false,
						fromDate: '',
						toDate: '',
						includeEmployees: true,
					},
					mode: 'onBlur',
			  },
	);
	const [isEventsFieldsOnView, setIsEventsFieldsOnView] = useState(
		prevValues?.eventId ? true : false,
	);
	const [eventsOptions, setEventsOptions] = useState([]);
	const [finishWorkDate, setFinishWorkDate] = useState<null | string>();
	const [projectsOptions, setProjectsOptions] = useState([]);
	const [isFullDayReport, toggleIsFullDayReport] = useState(
		isEdit ? prevValues?.fullDay : false,
	);
	const [disableTimePickers, setDisableTimePicker] = useState(false);
	const [isSubmitButtonDisable, setIsSubmitDisable] = useState(false);
	const [totalReportedForDate, setTotalReported] =
		useState<any>(totalReported);
	const employeeId = formMethods.watch('employeeId');
	const employeeIds = formMethods.watch('employeeIds');
	const includeEmployees = formMethods.watch('includeEmployees');
	const projectId = formMethods.watch('projectId');
	const eventId = formMethods.watch('eventId');
	const date = formMethods.watch('date');
	const fromDate = formMethods.watch('fromDate');
	const toDate = formMethods.watch('toDate');
	const entryTime = formMethods.watch('entryTime');
	const exitTime = formMethods.watch('exitTime');
	const fullDay = formMethods.watch('fullDay');
	const unitId = formMethods.watch('unitId');

	const employeeIdString = useMemo(
		() => !employeeId || JSON.stringify(employeeId),
		[employeeId],
	);
	useEffect(() => {
		const subscription = formMethods.watch((value, { name, type }) => {
			if (type === 'change' && name === 'unitId' && value[name]) {
				formMethods.reset({
					unitId: value[name],
					projectId: '',
					eventId: '',
					employeeIds: undefined,
					employeeId: '',
					entryTime: '',
					exitTime: '',
					fullDay: false,
					fromDate: '',
					toDate: '',
				});
				getEvents();
			}
		});
		return () => subscription.unsubscribe();
	}, [formMethods.watch]);

	const maxDate = useMemo(
		() =>
			finishWork ||
			(!isManager && employerEndWork) ||
			finishWorkDate ||
			employeeId?.extra?.finishWork
				? new Date(
						moment(
							finishWork ||
								(!isManager && employerEndWork) ||
								finishWorkDate ||
								employeeId?.extra?.finishWork,
							'YYYY-MM-DD',
						).valueOf(),
				  )
				: undefined,
		[isManager, employerEndWork, finishWork, finishWorkDate, employeeId],
	);

	const [standardHours, setStandardHours] = useState<any>(null);
	const [erroredFields, setErroredFields] = useState({
		employeeId: false,
		projectId: false,
		eventId: false,
		date: false,
		entryTime: false,
		exitTime: false,
		showErrorMassege: '',
		fromDate: false,
		toDate: false,
	});

	const isFirstInit = useRef(true);
	const getEvents = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getAllEventNameList',
				query: !isManager
					? {
							date: prevValues?.date,
					  }
					: {
							unitId:
								businessUnitId ||
								formMethods.getValues().unitId,
					  },
			});
			if (res) {
				setEventsOptions(res);
			}
		} catch (e) {}
	};

	const getProjects = async id => {
		try {
			const res = await httpService.api<any>({
				type: 'getAllProjectNameList',
				query: {
					pageName: isManager
						? 'worklogReports'
						: 'empWorklogReports',
					...(isManager && {
						emp: id,
						unitIds:
							businessUnitId || formMethods.getValues().unitId,
					}),
					...(!isManager && {
						date: prevValues?.date,
					}),
				},
			});
			if (res) {
				setProjectsOptions(res);
			}
		} catch (e) {}
	};

	const hadleSubmit = async () => {
		const forms = formMethods.getValues();
		const entry = moment(forms.entryTime, 'HH:mm');
		const exit = moment(forms.exitTime, 'HH:mm');
		const format =
			isEdit || prevValues?.date || addNew ? 'YYYY-MM-DD' : 'DD/MM/YYYY';

		const prevExitDate = isEdit
			? prevValues?.entryTime && !prevValues.exitTime
				? moment
						.utc(
							prevValues?.originalExitEntry.entryTime,
							'YYYY-MM-DDTHH:mm:ss',
						)
						.local()
						.format(format)
				: moment
						.utc(
							prevValues?.originalExitEntry.exitTime,
							'YYYY-MM-DDTHH:mm:ss',
						)
						.local()
						.format(format)
			: '';
		const prevEntryDate = isEdit
			? moment
					.utc(
						prevValues?.originalExitEntry.entryTime,
						'YYYY-MM-DDTHH:mm:ss',
					)
					.local()
					.format(format)
			: '';
		const isEntryChanged = forms.entryTime !== prevValues?.entryTime;
		const isExitChanged =
			prevValues?.entryTime && !prevValues.exitTime
				? true
				: forms.exitTime !== prevValues?.exitTime;
		let formatedDate =
			isEventsFieldsOnView && !!forms.fromDate
				? forms.fromDate
				: forms.date;
		if (!isEdit && !prevValues?.date)
			formatedDate = moment(formatedDate, 'DD/MM/YYYY').format(
				'YYYY-MM-DD',
			);
		let fromDate = formatedDate;
		let toDate = formatedDate;
		if (isEventsFieldsOnView && addNew) {
			fromDate = moment(forms.fromDate, 'DD/MM/YYYY').format(
				'YYYY-MM-DD',
			);
			toDate = moment(forms.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
		}

		//is entry was bigger than exit before edit
		const isDiffWasNegBefore = isEdit
			? moment(prevValues?.exitTime, 'HH:mm').diff(
					moment(prevValues?.entryTime, 'HH:mm'),
			  ) < 0
			: false;
		const exitAndEntryDates = isEdit
			? exitAndEntryDateSetter(
					entry,
					exit,
					isExitChanged,
					isEntryChanged,
					prevExitDate,
					prevEntryDate,
					isDiffWasNegBefore,
					forms.date,
					format,
			  )
			: { entryDate: formatedDate, exitDate: formatedDate };

		const utcEntryTime = convertTimeToUtc(
			forms.entryTime,
			isEdit ? exitAndEntryDates.entryDate : formatedDate,
			'YYYY-MM-DD',
			true,
		);
		const utcExitTime = convertTimeToUtc(
			forms.exitTime,
			isEdit
				? exitAndEntryDates.exitDate
				: exit.diff(entry) < 0
				? moment(formatedDate, 'YYYY-MM-DD')
						.add(1, 'day')
						.format('YYYY-MM-DD')
				: formatedDate,
			'YYYY-MM-DD',
			true,
		);

		const id = prevValues?.employeeId
			? [prevValues?.employeeId]
			: prevValues?.employee
			? [prevValues.employee.id]
			: isEventsFieldsOnView
			? forms.employeeIds
			: [forms.employeeId.value];

		let data: any = {
			employeeId: isManager ? id : undefined,
			includeEmployees:
				forms.includeEmployees === undefined
					? true
					: forms.includeEmployees,
			entryTime:
				isFullDayReport && isEventsFieldsOnView
					? !!forms.fromDate && forms.fromDate != forms.toDate
						? null
						: convertTimeToUtc(
								isFullDayReport && isEventsFieldsOnView
									? standardHours?.enterTime
									: forms.entryTime,
								isEventsFieldsOnView && !!forms.fromDate
									? forms.fromDate
									: forms.date,
								format,
								true,
						  )
					: utcEntryTime,
			exitTime:
				isFullDayReport && isEventsFieldsOnView
					? !!forms.fromDate && forms.fromDate != forms.toDate
						? null
						: convertTimeToUtc(
								isFullDayReport && isEventsFieldsOnView
									? standardHours?.exitTime
									: forms.exitTime,
								isEventsFieldsOnView && !!forms.fromDate
									? forms.fromDate
									: forms.date,
								format,
								true,
						  )
					: utcExitTime,

			fromDate: isEdit ? exitAndEntryDates.date : fromDate,
			toDate: isEdit ? exitAndEntryDates.date : toDate,

			eventId: isEventsFieldsOnView ? forms.eventId : null,
			projectId: isEventsFieldsOnView
				? null
				: forms.projectId
				? forms.projectId
				: null,
			fullDay: fullEvent
				? fullEvent
				: isEventsFieldsOnView
				? isFullDayReport
				: false,
		};

		if (isManager) {
			data = { ...data, unitId: forms.unitId || businessUnitId };
		}

		try {
			if (isEdit) {
				const res = await httpService.api<any>({
					type: isManager
						? 'editWorkLogManagerById'
						: 'editWorkLogEmployeeById',
					params: { workLogId: workLogId },
					data,
				});
			} else {
				const res = await httpService.api<any>({
					type: isManager
						? 'createWorkLogByManager'
						: 'createWorkLogByEmployee',
					data,
				});
				if (isManager && isEventsFieldsOnView && res?.jobId) {
					await openModal(
						openEditModal,
						'general.validation.WorkLogInProgress',
						true,
						false,
					);
					onSubmit(onNext);
					return;
				} else if (
					isEventsFieldsOnView &&
					(fromDate != toDate ||
						(employeeIds && employeeIds?.length !== 1))
				) {
					await openModal(
						openEditModal,
						'general.validation.WorkLogAddedByManager',
						true,
						false,
					);
					onSubmit(onNext);
					return;
				}
			}

			await openModal(
				openEditModal,
				isEdit
					? 'general.validation.WorkLogEditedSuccessfully'
					: 'general.validation.WorkLogAddedSuccessfully',
				true,
				false,
			);
			onSubmit(onNext);
		} catch (e) {
			errorPopUp(
				e?.data?.error,
				data.fromDate,
				openEditModal,
				e?.data?.workLogId,
				isEdit,
				addNew && isEventsFieldsOnView && fromDate != toDate,
			);

			onCancel(onNext);
			return;
		}
	};

	useEffect(() => {
		if (isFirstInit && prevValues) {
			if (prevValues.employee) {
				getProjects(prevValues?.employee?.id);
				return;
			}
			if (prevValues.employeeId) {
				getProjects(prevValues.employeeId);
				return;
			}
		}
		const empId = formMethods.getValues().employeeId;
		if (empId) getProjects(empId.value);
	}, [employeeIdString]);

	useEffect(() => {
		const format = isEdit || prevValues?.date ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
		if (isFirstInit && prevValues) {
			if (prevValues.employee?.id && prevValues.date) {
				getEmployeeStandardHours(
					prevValues.employee.id,
					prevValues.date,
				);
				getTotalReportedByDate(
					moment(prevValues.date, format).format('YYYY-MM-DD'),
					isManager,
					prevValues.employee.id,
					businessUnitId || formMethods.getValues().unitId,
				).then(total => setTotalReported(total));
				return;
			}
			if (prevValues.employeeId && prevValues.date) {
				getEmployeeStandardHours(
					prevValues.employeeId.value,
					prevValues.date,
				);
				getTotalReportedByDate(
					moment(prevValues.date, format).format('YYYY-MM-DD'),
					isManager,
					prevValues.employeeId.value,
					businessUnitId || formMethods.getValues().unitId,
				).then(total => setTotalReported(total));

				return;
			}
		}
		const values = formMethods.getValues();
		const id =
			values.employeeIds?.length === 1
				? employeeIds[0]
				: prevValues?.employeeId ||
				  values.employeeId?.value ||
				  values.employee?.id;
		const newDate =
			!!values.fromDate && isEventsFieldsOnView
				? values.fromDate
				: values.date;
		if (id && newDate) {
			getEmployeeStandardHours(id, newDate);
			getTotalReportedByDate(
				moment(newDate, format).format('YYYY-MM-DD'),
				isManager,
				id,
				businessUnitId || formMethods.getValues().unitId,
			).then(total => setTotalReported(total));
			return;
		}
	}, [employeeId, date, fromDate, employeeIds]);

	useEffect(() => {
		getEvents();
	}, []);

	useEffect(() => {
		if (
			(isEdit && prevValues?.fullDay) ||
			(isEventsFieldsOnView && totalReportedForDate === 0)
		) {
			formMethods.setValue(
				'fullDay',
				isEdit ? prevValues?.fullDay : true,
			);
			toggleIsFullDayReport(isEdit ? prevValues?.fullDay : true);
		} else if (fullEvent) {
			toggleIsFullDayReport(true);
			formMethods.setValue('fullDay', true);
		} else if (!!fromDate && fromDate != toDate) {
			toggleIsFullDayReport(true);
			formMethods.setValue('fullDay', true);
		} else if (employeeIds && employeeIds?.length !== 1) {
			toggleIsFullDayReport(true);
			formMethods.setValue('fullDay', true);
		} else {
			toggleIsFullDayReport(false);
			formMethods.setValue('fullDay', false);
		}
	}, [isEventsFieldsOnView, fromDate, toDate, employeeIds]);

	useEffect(() => {
		if (isEventsFieldsOnView && isFullDayReport) {
			setDisableTimePicker(true);
		} else if (
			isEventsFieldsOnView &&
			moment(moment(fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD')).isAfter(
				moment().format('YYYY-MM-DD'),
			)
		) {
			setDisableTimePicker(true);
			toggleIsFullDayReport(true);
			formMethods.setValue('fullDay', true);
		} else {
			setDisableTimePicker(false);
		}
	}, [isEventsFieldsOnView, isFullDayReport, fromDate]);

	useEffect(() => {
		const forms = formMethods.getValues();
		if (!isFirstInit.current && formMethods.formState.isDirty) {
			const errorsHandler = validateForms(
				forms,
				projectsOptions,
				eventsOptions,
				isEventsFieldsOnView,
				isFullDayReport,
				shiftHours,
				totalReportedForDate,
				userData?.customer_setting.lockDay,
				userData?.customer_setting.worklogLockType,
				isEdit || prevValues?.date ? 'YYYY-MM-DD' : 'DD/MM/YYYY',
				prevValues,
				standardHours,
				isEdit,
			);
			setErroredFields(errorsHandler.errorFields);
			setIsSubmitDisable(errorsHandler.disableSubmit);
		}
		isFirstInit.current = false;
	}, [
		employeeIdString,
		projectId,
		eventId,
		date,
		entryTime,
		exitTime,
		fullDay,
		isFullDayReport,
		isEventsFieldsOnView,
		toDate,
		fromDate,
		eventsOptions,
		unitId,
	]);
	useEffect(() => {
		if (isEventsFieldsOnView && !prevValues?.date) {
			formMethods.setValue('date', '');
			// formMethods.setValue('exitTime', standardHours?.exitTime);
		} else if (!isEventsFieldsOnView) {
			// formMethods.setValue('entryTime', '');
			// formMethods.setValue('exitTime', '');
		}
	}, [isEventsFieldsOnView]);
	const getEmployeeStandardHours = async (
		profileAid: string,
		formsDate: string,
	) => {
		const date = prevValues?.date
			? prevValues.date
			: moment(formsDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
		try {
			const res = await httpService.api<any>({
				type: isManager
					? 'getStandardByDateManager'
					: 'getEmployeeStandardByDate',
				params: isManager
					? {
							profileAid,
							date,
					  }
					: { date },
				query: isManager
					? {
							unitId:
								businessUnitId ||
								formMethods.getValues().unitId,
					  }
					: {},
			});
			if (res) {
				setStandardHours(formatStandardHours(res));
			} else {
				setStandardHours(formatStandardHours(null));
			}
		} catch (e) {}
	};

	const canReportFullDay = useMemo(() => {
		if (
			!!fromDate &&
			moment(moment(fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD')).isAfter(
				moment().format('YYYY-MM-DD'),
			)
		) {
			return false;
		}
		if (isEdit) {
			if (prevValues?.fullDay) {
				return !moment(prevValues?.date).isAfter(
					moment().format('YYYY-MM-DD'),
				);
			}
			const entry = moment(entryTime, 'HH:mm');
			const exit = moment(exitTime, 'HH:mm');
			const diff = exit.diff(entry, 'minutes') / 60;
			return (
				totalReportedForDate === diff &&
				(employeeIds?.length === 1 || hideName)
			);
		}
		if (
			totalReportedForDate === 0 &&
			(employeeIds?.length === 1 || hideName)
		) {
			toggleIsFullDayReport(true);
			formMethods.setValue('fullDay', true);
			return true;
		}
		return false;
	}, [totalReportedForDate, totalReported, fromDate, employeeIds]);

	const onChangeEmployees = ({
		value,
		unselectedValues,
		prevHasAll,
	}: any) => {
		const hasAll = value.find((v: any) => v.id === 'all');
		const tempUnselectedValues =
			unselectedValues &&
			unselectedValues
				.filter((v: any) => v.id !== 'all')
				.map((v: any) => v.id);

		formMethods.setValue(
			'includeEmployees',
			!value?.length || (!hasAll && !unselectedValues?.length),
		);

		if (
			!hasAll &&
			value &&
			!unselectedValues?.length &&
			value.length === 1
		) {
			setFinishWorkDate(value[0]?.extra?.finishWork);
		} else {
			setFinishWorkDate(null);
		}

		formMethods.setValue(
			'employeeIds',
			(!value && !unselectedValues) || prevHasAll
				? undefined
				: unselectedValues?.length
				? tempUnselectedValues
				: hasAll
				? []
				: [...value.map((v: any) => v.id || v)],
		);
	};

	return (
		<FormProvider {...formMethods}>
			<AddRequestModalComponent
				businessUnitsList={businessUnitsList}
				businessUnitId={businessUnitId}
				businessUnitName={businessUnitName}
				formMethods={formMethods}
				hideProjects={hideProjects}
				hideEvents={
					userData?.customer_setting?.eventSelectionType === 'NONE' ||
					eventsOptions.length === 0
				}
				canReportFullDay={canReportFullDay}
				cancelBtnText={cancelBtnText}
				submitBtnText={submitBtnText}
				disableTimePickers={disableTimePickers}
				erroredFields={erroredFields}
				eventsOptions={eventsOptions}
				projectsOptions={projectsOptions}
				prevValues={prevValues}
				forms={formMethods.getValues()}
				onCancel={() => onCancel(onNext)}
				onSubmit={hadleSubmit}
				handleFulldayToggle={() => {
					formMethods.setValue('fullDay', !isFullDayReport);
					toggleIsFullDayReport(!isFullDayReport);
					formMethods.setValue('entryTime', prevValues?.entryTime);
					formMethods.setValue('exitTime', prevValues?.exitTime);
				}}
				getErrorLabel={
					<GetErrorLable
						errorType={erroredFields.showErrorMassege}
						shiftHours={shiftHours}
					/>
				}
				isEdit={isEdit}
				isEventsFieldsOnView={isEventsFieldsOnView}
				shiftHours={shiftHours}
				isFullDayReport={isFullDayReport}
				maxDate={maxDate}
				standardHours={standardHours}
				totalReported={totalReported}
				setIsEventsFieldsOnView={(value: boolean) => {
					setIsEventsFieldsOnView(value);
					!prevValues?.employeeId &&
						formMethods.setValue('employeeId', '');
				}}
				isSubmitButtonDisable={isSubmitButtonDisable}
				route={route}
				hideName={hideName}
				addNew={addNew}
				haveEvents={haveEvents}
				onChangeEmployees={onChangeEmployees}
				includeEmployees={includeEmployees}
			/>
		</FormProvider>
	);
};

export default AddRequestContainer;
