import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useRoute } from '@react-navigation/core';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { getDocumentTitle } from '../../utils/globals.utils';

import EmploeesTableContainer from './FromMonthToMonthReportsEmploeesTable.container';
import DataFormByEmployee from './ByEmployeeform/FromMonthToMonthReportsSalaryDataFormByEmployee';
import ComprehensiveForm from './ComprehensiveForm/FromMonthToMonthReportsComprehensiveForm';

const Tab = createBottomTabNavigator();

const FromMonthToMonthReportsWrapper = ({ navigation }: any) => {
	const route: any = useRoute();

	useEffect(() => {
		if (route?.params?.screen === 'workPaperDraft') {
			navigation.navigate('fromMonthToMonthReports', {
				screen: 'workPaperDraft',
			});
		}
	}, []);

	return (
		<View style={{ flex: 1 }}>
			<Tab.Navigator
				tabBar={({ navigation }: any) => null}
				sceneContainerStyle={{ backgroundColor: 'transparent' }}
				initialRouteName='workPaperDraft'
				screenOptions={{ unmountOnBlur: true }}
			>
				<Tab.Screen
					name='workPaperDraft'
					component={EmploeesTableContainer}
					options={{ title: getDocumentTitle('workPaperDraft') }}
				/>
				<Tab.Screen
					name='dataform'
					component={DataFormByEmployee}
					options={{ title: getDocumentTitle('dataform') }}
				/>
				<Tab.Screen
					name='comprehensiveForm'
					component={ComprehensiveForm}
					options={{ title: getDocumentTitle('comprehensiveForm') }}
				/>
			</Tab.Navigator>
		</View>
	);
};

export default FromMonthToMonthReportsWrapper;
