import React from 'react';
import {
	Dimensions,
	View,
	StyleSheet,
	TouchableHighlight,
	TouchableOpacity,
	useWindowDimensions,
} from 'react-native';
import moment from 'moment';
import colors from '../../../config/colors';
import I18n from '../../../components/shared/i18n/I18n.component';
import Header from '../../../components/shared/Header/Header.container';
import WebHeader from '../../../components/shared/Header/WebHeader.component';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import { Columns } from './EmployeeHoursReportSummary.utils';
import getShadowStyle from '../../../config/shadow';
import Typography from '../../../components/shared/Typography/Typography.component';
import { i18nService } from '../../../services/i18n.service';
import Filters from '../../../components/web/Filters/Filters.component';
import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
export interface Props {
	navigation: any;
	data: any[];
	onSort?: any;
	currentEmployee: any;
	onLoadMore: any;
	totalData: any;
	filtersConfig: any;
	isSelectMode: boolean;
	selectedRows: any[];
	isAllSelected: boolean;
	onSelectAll: (cheacked: boolean) => {};
	onSelect: () => {};
	onConfirmSelect: () => {};
	onCancelSelect: () => {};
	reqForSelect: () => {};
	onFilter: () => {};
	OnPrevEmployeePress: () => {};
	OnNextEmployeePress: () => {};
	onDelete: (id: any) => {};
	onEdit: (id: any) => {};
	reqForWorkLogREports: () => void;
	backToAllHoursReports: () => void;
	addReport: () => void;
	disableNextButton: boolean;
	worklogReportFormat: any;
	canEdit: boolean;
	businessUnitsList: any[];
}

const EmployeeHoursReportSummary = ({
	data,
	totalData,
	navigation,
	currentEmployee,
	onSort,
	onLoadMore,
	filtersConfig,
	isSelectMode,
	selectedRows,
	isAllSelected,
	disableNextButton,
	worklogReportFormat,
	addReport,
	backToAllHoursReports,
	reqForWorkLogREports,
	onSelectAll,
	onSelect,
	onConfirmSelect,
	onCancelSelect,
	reqForSelect,
	onFilter,
	OnPrevEmployeePress,
	OnNextEmployeePress,
	onEdit,
	onDelete,
	canEdit,
	businessUnitsList,
}: Props) => {
	const { height: windowHeight } = useWindowDimensions();

	const getButton = (
		innerText: string,
		action: () => {},
		disabled: boolean,
		isRounded?: boolean,
		isBackButton?: boolean,
	) =>
		isRounded ? (
			<TouchableHighlight
				underlayColor='transparent'
				onPress={action}
				disabled={disabled}
			>
				<View style={styles.newRequestButton}>
					<Typography
						style={{
							marginLeft: 10,
							marginRight: 10,
						}}
						color={'white'}
						weight='400'
						size={14}
					>
						{i18nService.translate(innerText)}
					</Typography>
				</View>
			</TouchableHighlight>
		) : (
			<TouchableOpacity
				style={{
					marginRight: 20,
					opacity: [disabled ? 0.3 : 1],
					flexDirection: [isBackButton ? 'row' : 'row-reverse'],
					color: colors.lightPrimary,
					alignItems: 'center',
				}}
				onPress={action}
				disabled={disabled}
			>
				<I18n>{innerText}</I18n>
				<ArrowBackIos
					style={
						isBackButton
							? {
									transform: 'rotate(180deg)',
									color: colors.lightPrimary,
							  }
							: {}
					}
					color='inherit'
				/>
			</TouchableOpacity>
		);

	return (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<>
						<TouchableHighlight
							underlayColor='transparent'
							onPress={backToAllHoursReports}
						>
							<I18n
								size={36}
								weight='normal'
								color='darkBlue'
								style={{ flex: 4 }}
							>
								employeeHoursReport.title
							</I18n>
						</TouchableHighlight>
						<I18n
							size={24}
							weight='normal'
							style={{
								marginRight: 10,
								marginLeft: 10,
								paddingTop: 7,
							}}
						>
							{'<'}
						</I18n>
						<View
							style={{
								alignItems: 'flex-end',
							}}
						>
							<I18n
								size={24}
								weight='normal'
								style={{ paddingTop: 7, flex: 5 }}
							>
								{` ${currentEmployee.fullName} ${currentEmployee.teudatZeut}`}
							</I18n>
							{currentEmployee.finishWork ? (
								<View style={{ flexDirection: 'row-reverse' }}>
									<I18n
										size={12}
										// color='white'
										weight='normal'
										style={{ marginLeft: 5 }}
									>
										general.endWorkDate
									</I18n>
									<I18n size={12} weight='normal'>
										{moment(
											currentEmployee.finishWork,
											'YYYY-MM-DD',
										).format('DD/MM/YYYY')}
									</I18n>
								</View>
							) : null}
						</View>
						<View style={{ flex: 8, flexDirection: 'row-reverse' }}>
							{getButton(
								'employeeHoursReport.prevButton',
								() => OnPrevEmployeePress(),
								currentEmployee.itemIndex === 0 ? true : false,
								false,
								true,
							)}
							{getButton(
								'employeeHoursReport.nextButton',
								() => OnNextEmployeePress(),
								disableNextButton,
							)}
						</View>
						<TouchableHighlight
							underlayColor='transparent'
							onPress={reqForWorkLogREports}
						>
							<View style={styles.newRequestButton}>
								<Typography
									style={{ marginLeft: 10, marginRight: 10 }}
									color={'white'}
									weight='400'
									size={14}
								>
									{i18nService.translate(
										'hoursReport.toAllReports',
									)}
								</Typography>
							</View>
						</TouchableHighlight>
						{isSelectMode ? (
							<View style={{ flexDirection: 'row' }}>
								{getButton(
									'employeeHoursReport.confirmFilterButton',
									() => onConfirmSelect(),
									false,
									true,
								)}
								{getButton(
									'employeeHoursReport.cancelFilterButton',
									() => onCancelSelect(),
									false,
									true,
								)}
							</View>
						) : (
							<TouchableHighlight
								underlayColor='transparent'
								style={{ opacity: canEdit ? 1 : 0.7 }}
								onPress={reqForSelect}
								disabled={!canEdit}
							>
								<View style={styles.newRequestButton}>
									<Typography
										style={{
											marginLeft: 10,
											marginRight: 10,
										}}
										color={'white'}
										weight='400'
										size={14}
									>
										{i18nService.translate(
											'employeeHoursReport.reqForConfirmButton',
										)}
									</Typography>
								</View>
							</TouchableHighlight>
						)}
						{canEdit && (
							<TouchableHighlight
								style={styles.fabButton}
								underlayColor='transparent'
								onPress={addReport}
							>
								<ImageIcon
									name={'add'}
									width={40}
									height={40}
								></ImageIcon>
							</TouchableHighlight>
						)}
					</>
				}
				noHeader={true}
			>
				<View
					style={{
						flexDirection: 'row-reverse',
						width: '100%',
						justifyContent: 'space-between',
						zIndex: 100,
					}}
				>
					<View style={{ flexDirection: 'row-reverse' }}>
						<Filters
							config={filtersConfig}
							onChange={onFilter}
							customStyles={{
								container: {
									display: 'flex',
									flexDirection: 'row-reverse',
									justifyContent: 'flex-end',
									alignSelf: 'center',
								},
								filterContainer: {
									marginLeft: 10,
									marginVertical: 10,
									zIndex: 10001,
								},
							}}
						/>
					</View>
				</View>

				<View
					style={{
						maxHeight: windowHeight - 300,
					}}
				>
					<TableContainer
						keyName={''}
						headers={Columns(
							totalData ? totalData.total : null,
							isSelectMode,
							selectedRows,
							isAllSelected,
							onSelectAll,
							onSelect,
							onDelete,
							onEdit,
							worklogReportFormat,
							canEdit,
							businessUnitsList,
						)}
						data={data || []}
						getNextData={onLoadMore}
						onSort={onSort}
						hasFooter={true}
						selectedRowId={0}
					/>
				</View>
			</ReportsPageWrapper>
		</View>
	);
};

const styles = StyleSheet.create({
	newRequestButton: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
		marginTop: 10,
		marginLeft: 10,
	},
	fabButton: {
		borderRadius: 30,
		width: 40,
		height: 40,
		marginTop: 10,
		marginLeft: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	nextEmpButton: {
		marginRight: 10,
		marginTop: 10,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		paddingHorizontal: 5,
		backgroundColor: colors.lightPrimary,
		borderRadius: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default EmployeeHoursReportSummary;
