import React, { useEffect, useState, useMemo } from 'react';
import {
	View,
	StyleSheet,
	ScrollView,
	Dimensions,
	Platform,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import FieldBuilder from '../../../components/shared/FieldBuilder/FieldBuilder';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import colors from '../../../config/colors';
import { Privileges } from '../../../constants/roleTypes.constant';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../../store/selectors/login.selectors';

const sendingFrequencyOptions = [
	{ label: 'general.daily', value: 'DAILY' },
	{ label: 'general.weekly', value: 'WEEKLY' },
	{ label: 'general.monthly', value: 'MONTHLY' },
];
const requestEmployeeIdFileOptions = [
	{ label: 'general.must', value: true },
	{ label: 'general.NotMust', value: false },
];

interface Props {
	disableSubmit: boolean;
	setDisableSubmit: any;
	eventsOptions: string[];
	onUpdate(data: string[]): void;
	onCancel(): void;
	selectedBusinessUnit: number;
	form101ModuleStatus: string;
	canNotEdit: boolean;
}

export const Form101 = ({
	data,
	eventsOptions,
	onCancel,
	onUpdate,
	disableSubmit,
	setDisableSubmit,
	form101ModuleStatus,
	selectedBusinessUnit,
	canNotEdit,
}: Props) => {
	const platform = useDevicePlatform();

	const canEdit = useMemo(
		() =>
			hasPermissionOnBusinessUnit(
				selectedBusinessUnit,
				Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
			),
		[selectedBusinessUnit],
	);

	const isWeb = platform === DeviceType.WEB;
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		setIsActive(form101ModuleStatus === 'ACTIVE' && !canNotEdit);
	}, [form101ModuleStatus, canNotEdit]);

	return (
		<ScrollView
			style={{ paddingHorizontal: 20 }}
			contentContainerStyle={styles.container}
		>
			<View style={styles.body}>
				<I18n size={22} weight='normal' style={styles.title}>
					businessSettings.employer
				</I18n>

				<View style={isWeb ? styles.row : styles.mobileRow}>
					<I18n size={16} weight='normal' style={styles.text}>
						businessSettings.101File.sendingFrequency
					</I18n>
					<FieldBuilder
						label={() => ''}
						name={'employeeFormMailRemainderFrequency'}
						type={'buttonsGroup'}
						options={sendingFrequencyOptions}
						disabled={!isActive || !canEdit}
					/>
				</View>

				<I18n size={22} weight='normal' style={styles.title}>
					businessSettings.employee
				</I18n>

				<View style={isWeb ? styles.row : styles.mobileRow}>
					<I18n size={16} weight='normal' style={styles.text}>
						businessSettings.101File.addId
					</I18n>
					<FieldBuilder
						label={() => ''}
						name={'requestEmployeeIdFile'}
						type={'buttonsGroup'}
						options={requestEmployeeIdFileOptions}
						disabled={!isActive || !canEdit}
					/>
				</View>
			</View>
			<View style={{ flexDirection: 'row', marginVertical: 30 }}>
				<SolidButtonContainer
					size={isWeb ? 'medium' : 'small'}
					textOptions={{ color: colors.lightPrimary }}
					overrideStyle={[
						styles.cancelButton,
						!isWeb && {
							paddingHorizontal: 22,
							paddingVertical: 10,
						},
					]}
					onPress={onCancel}
				>
					general.cancel
				</SolidButtonContainer>
				<SolidButtonContainer
					size={isWeb ? 'medium' : 'small'}
					disabled={disableSubmit || !canEdit || !isActive}
					overrideStyle={
						!isWeb && { paddingHorizontal: 22, paddingVertical: 10 }
					}
					onPress={() => {
						onUpdate(eventsOptions);
						setDisableSubmit(true);
					}}
				>
					general.save
				</SolidButtonContainer>
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingHorizontal: 20,
		flex: Platform.OS === 'web' ? 1 : undefined,
		justifyContent: 'space-between',
		height:
			Platform.OS === 'web'
				? '100%'
				: Dimensions.get('screen').height - 300,
	},
	body: {
		flexGrow: 1,
	},
	title: {
		marginTop: 50,
		paddingBottom: 20,
	},
	row: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 55,
	},
	mobileRow: {
		flexDirection: 'column',
	},
	text: {
		width: 330,
		paddingLeft: 30,
	},
	cancelButton: {
		marginHorizontal: 10,
		backgroundColor: colors.white,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
	},
});
