import React, { useMemo, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	useWindowDimensions,
	Platform,
	Dimensions,
	TouchableOpacity,
} from 'react-native';

import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { SendingDocumentProps } from './SendingDocument.component';
import colors from '../../../config/colors';
import { Divider, Portal } from 'react-native-paper';
import Typography from '../../../components/shared/Typography/Typography.component';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import OutlineButtonContainer from '../../../components/shared/Buttons/OutlineButton.container';
import ModalContainer from '../../../components/shared/Modal/Modal.container';
import SelectEmpMobileModal from './SelectEmpMobileModal/SelectEmpMobileModal.component';
import Select from '../../../components/shared/FieldBuilder/Select/ReactSelect.component';

const SendingDocument = ({
	getEmployeesFilter,
	onChooseEmployee,
	chosenEmp,
	chosenRecipienats,
	onChooseRecipienats,
	onRemoveRecipienats,
	recipienatsKey,
	onCancel,
	onSubmit,
	id,
	isMobileWeb,
	businessUnitsList,
	onChooseUnit,
	chosenUnit,
}: SendingDocumentProps) => {
	const dimentions = useWindowDimensions();
	const [isModalOpen, setIsModalOpen] = useState<'multi' | 'single' | false>(
		false,
	);

	const onSubmitModal = (value: any) => {
		if (isModalOpen === 'multi') {
			onChooseRecipienats({
				value: value.map((val: any) => ({
					id: val.value,
					name: val.label,
				})),
			});
		} else {
			onChooseEmployee(value);
		}
		setIsModalOpen(false);
	};

	const selectRecip = useMemo(
		() =>
			chosenRecipienats?.map(val => ({ value: val.id, label: val.name })),
		[chosenRecipienats],
	);

	return (
		<Portal.Host>
			<View
				style={{
					width: isMobileWeb
						? Dimensions.get('window').width + 2
						: Dimensions.get('window').width,
					paddingHorizontal: 0,
					paddingVertical: 22,
					backgroundColor: colors.lightPageBackground,
					height: isMobileWeb
						? Dimensions.get('window').height + 42
						: Platform.OS === 'android'
						? Dimensions.get('window').height + 45
						: Dimensions.get('window').height + 20,
					marginTop: isMobileWeb
						? -22
						: Platform.OS === 'android'
						? -88
						: -115,
				}}
			>
				<View
					style={[
						styles.rowContainer,
						{
							paddingTop: isMobileWeb
								? 40
								: Platform.OS === 'android'
								? 20
								: 50,
						},
					]}
				>
					<I18n weight='normal' size={28}>
						sendingDocument.mobile.title
					</I18n>
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => onCancel()}
					>
						<View style={styles.rowContainerData}>
							<I18n
								weight='400'
								size={20}
								style={{ marginLeft: 8 }}
							>
								{'documents.title'}
							</I18n>
							<ImageIcon
								name={'leftArrow'}
								width={13}
								height={24}
							/>
						</View>
					</TouchableHighlight>
				</View>
				<View style={{ marginRight: 16, marginBottom: 30 }}>
					<I18n weight='normal' size={18}>
						{!id ? 'sendingDocument.mobile.sub' : chosenEmp.label}
					</I18n>
				</View>
				{businessUnitsList.length > 1 && (
					<View
						style={{
							marginBottom: 20,
							marginRight: 16,
							alignItems: 'flex-end',
							zIndex: 10,
							// width: Dimensions.get('screen').width - 32,
						}}
					>
						<I18n style={{ marginBottom: 10 }}>
							sendingDocument.chooseUnit
						</I18n>
						<Select
							key={`${JSON.stringify(chosenUnit)}`}
							isSearchable={false}
							disabled={!!id}
							noPlaceholder
							styles={{
								control: {
									borderWidth: 0,
									backgroundColor: colors.white,
									color: colors.darkGrey,
									borderColor: colors.darkBlue,
									borderBottomWidth: 1,
									boxShadow: 'none',
									width: Dimensions.get('window').width - 32,
								},
								container: {
									width: Dimensions.get('window').width - 32,
								},
								mobile: {
									backgroundColor: colors.white,
									height: 40,
									width: Dimensions.get('window').width - 32,
									marginLeft: 16,
								},
							}}
							options={businessUnitsList}
							onChange={onChooseUnit}
							value={chosenUnit}
							captureMenuScroll={false}
							maxMenuHeight={145}
						/>
					</View>
				)}
				<View
					style={{
						marginBottom: 20,
						marginRight: 16,
						alignItems: 'flex-end',
					}}
				>
					<I18n>sendingDocument.chooseEmployee</I18n>
					<TouchableOpacity
						style={[styles.fakeInput, id ? { opacity: 0.5 } : {}]}
						disabled={!!id}
						onPress={() => setIsModalOpen('single')}
					>
						<I18n size={14}>{chosenEmp?.label}</I18n>
					</TouchableOpacity>
				</View>
				<View
					style={{
						marginRight: 16,
						alignItems: 'flex-end',
					}}
				>
					<I18n>sendingDocument.chooseExtraRecipients</I18n>
					<I18n size={14} color='primary'>
						sendingDocument.chooseExtraRecipients.note
					</I18n>
					<TouchableOpacity
						style={[
							styles.fakeInput,
							{ flexDirection: 'row-reverse' },
						]}
						onPress={() => setIsModalOpen('multi')}
					>
						{!!chosenRecipienats?.length && (
							<View style={styles.chip}>
								<I18n size={10}>
									{chosenRecipienats[0].name}
								</I18n>
							</View>
						)}
						{chosenRecipienats?.length > 1 && (
							<View style={styles.chip}>
								<I18n size={10}>
									+{chosenRecipienats.length - 1}
								</I18n>
							</View>
						)}
					</TouchableOpacity>
				</View>
				<View
					style={{
						flexDirection: 'row-reverse',
						alignItems: 'center',
						flexWrap: 'wrap',
						marginTop: 15,
						marginHorizontal: 8,
					}}
				>
					{chosenRecipienats?.map(
						(recip: { id: number; name: string }) => (
							<View
								key={recip.id}
								style={{
									flexDirection: 'row-reverse',
									height: 35,
									borderRadius: 20,
									borderWidth: 1,
									borderColor: colors.darkBlue,
									padding: 10,
									paddingHorizontal: 20,
									margin: 8,
								}}
							>
								<Typography
									size={12}
									style={{ marginLeft: 10 }}
								>
									{recip.name}
								</Typography>
								<TouchableOpacity
									onPress={() =>
										onRemoveRecipienats(recip.id)
									}
								>
									<ImageIcon
										name='remove'
										width={12}
										height={12}
									/>
								</TouchableOpacity>
							</View>
						),
					)}
				</View>
				<View style={{ flex: 1 }} />
				<Divider
					style={{
						backgroundColor: colors.darkGrey,
						opacity: 0.2,
						marginTop: 24,
						marginBottom: 10,
						paddingHorizontal: 16,
					}}
				/>
				<View style={styles.actionButtons}>
					<SolidButtonContainer
						overrideStyle={styles.buttonOk}
						onPress={onSubmit}
						disabled={!chosenEmp}
					>
						<I18n
							size={16}
							style={{ minHeight: 40 }}
							weight='normal'
							color='white'
						>
							general.continue
						</I18n>
					</SolidButtonContainer>
					<OutlineButtonContainer
						overrideStyle={styles.buttonCancel}
						onPress={() => onCancel()}
					>
						<I18n
							size={16}
							style={{ minHeight: 40 }}
							weight='normal'
							color='primary'
						>
							general.cancel
						</I18n>
					</OutlineButtonContainer>
				</View>
			</View>
			<Portal>
				<ModalContainer
					disabledKeyboard
					visible={isModalOpen}
					component={SelectEmpMobileModal}
					notDisplayModal
					{...{
						selectedData: isModalOpen === 'multi' && selectRecip,
						onSubmit: onSubmitModal,
						close: () => {
							setIsModalOpen(false);
						},
						onFilter: getEmployeesFilter,
						isMulti: isModalOpen === 'multi',
					}}
				/>
			</Portal>
		</Portal.Host>
	);
};

const styles = StyleSheet.create({
	title: {
		marginLeft: 8,
	},
	rowContainer: {
		paddingHorizontal: 16,
		marginBottom: 30,
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	rowContainerData: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	chip: {
		borderRadius: 2,
		padding: 3,
		paddingLeft: 6,
		margin: 2,
		backgroundColor: '#E6E6E6',
	},
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: 16,
	},
	buttonOk: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
		paddingHorizontal: 15,
		padding: 10,
		marginLeft: 15,
	},
	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		padding: 10,
	},
	fakeInput: {
		borderBottomWidth: 1,
		borderColor: colors.primary,
		borderRadius: 5,
		height: 40,
		width: Dimensions.get('window').width - 32,
		backgroundColor: colors.white,
		marginTop: 10,
		padding: 10,
	},
});

export default SendingDocument;
