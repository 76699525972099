import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useWindowDimensions } from 'react-native';
import { WebView } from 'react-native-web-webview';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const GlobalStyle = createGlobalStyle`
  .react-pdf__Document {
	overflow: auto;
	height: 100%;
  }
  .react-pdf__Page {
	  margin: 10px
  }
`;

const PDFViewer = ({ pdfUrl, file }: { pdfUrl?: string; file?: string }) => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}
	const platform = useDevicePlatform();
	const { width, height } = useWindowDimensions();

	const isWeb = platform === DeviceType.WEB;
	const isMobileWeb = platform === DeviceType.MOBILE_WEB;
	return isWeb && pdfUrl ? (
		<WebView
			source={{
				uri: pdfUrl,
			}}
			builtInZoomControls={true}
		/>
	) : isMobileWeb ? (
		<div style={{ overflow: 'hidden', zIndex: 0 }}>
			<GlobalStyle />
			<Document
				loading=''
				file={file ? file : { url: pdfUrl }}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				{Array.from(new Array(numPages), (el, index) => (
					<Page
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						width={width - 20}
					/>
				))}
			</Document>
		</div>
	) : file ? (
		<iframe src={file} height='100%' width='100%'></iframe>
	) : null;
};

export default PDFViewer;
