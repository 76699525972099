import React, { useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ReactDatePicker from '../../../../components/web/ReactDatePicker/ReactDatePicker.component';
import httpService from '../../../../services/http.service';
import Card from '../Card/Card.component';
import { getDefaultYearlyFilter } from '../RepHomePage.utils';

const Form101 = ({ filters, customersFullDataJson }: any) => {
	const [localFilters, setLocalFilters] = useState<any>();
	const [data, setData] = useState<any>();

	const getData = async () => {
		const res = await httpService.api({
			type: 'getRepDashboardForm101',
			data: { f: { ...filters, ...localFilters } },
		});
		setData(res);
	};

	useEffect(() => {
		const tempFilters = sessionStorage.getItem('repDashboardForm101');

		if (tempFilters && tempFilters !== '') {
			setLocalFilters(JSON.parse(tempFilters));
		} else {
			setLocalFilters({
				year: getDefaultYearlyFilter(),
			});
		}
	}, []);

	useEffect(() => {
		if (localFilters?.year.length) {
			getData();
		}
	}, [filters, localFilters]);

	const onChange = ({ name, value }: any) => {
		const newData: any = { ...localFilters };
		newData.year = [value.getFullYear()];

		sessionStorage.setItem('repDashboardForm101', JSON.stringify(newData));
		setLocalFilters(newData);
	};

	return (
		<View style={{ paddingVertical: 30, width: 'calc(50% - 12px)' }}>
			<View
				style={{
					flexDirection: 'row-reverse',
					alignItems: 'baseline',
					marginBottom: 24,
				}}
			>
				<I18n weight='normal' size={36} style={{ marginLeft: 5 }}>
					repDashboard.form101
				</I18n>
				<I18n size={14} weight='400'>
					repDashboard.units
				</I18n>
			</View>
			<View
				style={{
					zIndex: 100,
					flexDirection: 'row-reverse',
				}}
			>
				<View
					style={{
						width: 130,
						zIndex: 100,
						flexDirection: 'row-reverse',
					}}
				>
					<ReactDatePicker
						onChange={onChange}
						config={
							{
								name: 'year',
								type: '',
								date:
									localFilters && new Date(localFilters.year),
								dashboardViewMode: true,
								showYearPicker: true,
								placeholder: localFilters?.year?.[0],
							} as any
						}
					/>
				</View>
			</View>
			<View
				style={{
					marginTop: 16,
					flexDirection: 'row-reverse',
					...Platform.select({ web: { gap: 24 } }),
				}}
			>
				<Card
					title='repDashboard.form101.reports'
					data={data}
					type='rows'
					width={'calc(100%)'}
					colorsObj={{
						PENDING_EMPLOYEE: 'hsl(360, 79%, 63%)',
						PENDING_EMPLOYER: '#EB8B2E',
						APPROVED: '#F2C94C',
						APPROVED_AND_VIEWED: 'hsl(145, 50%, 62%)',
					}}
					navigateTo='repForm101Reports'
					defaultParams={{ ...localFilters, ...filters }}
					moreParams={{
						customersFullData: customersFullDataJson,
					}}
				/>
			</View>
		</View>
	);
};

export default Form101;
