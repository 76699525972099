import React from 'react';
import { Platform } from 'react-native';
import CheckBox from '@react-native-community/checkbox';
import { Checkbox as MuiCheckbox, makeStyles } from '@material-ui/core';
import colors from '../../../../config/colors';

const useStyles = makeStyles(() => ({
	checkbox: {
		marginRight: -10,

		color: '#6d7278',
		'&$checked': {
			color: colors.primary,
		},
	},
	checked: {},
}));

const Checkbox = ({ value, onChange, isDisabled, ...restProps }: any) => {
	const classes = useStyles();
	return Platform.OS === 'ios' || Platform.OS === 'android' ? (
		<CheckBox
			disabled={isDisabled}
			value={value}
			onValueChange={onChange}
			boxType='square'
			onCheckColor={colors.white}
			onFillColor={colors.primary}
			onTintColor={colors.primary}
			tintColors={{ true: colors.primary }}
			style={{
				width: 22,
				height: 22,
				borderRadius: 2,
				marginLeft: 5,
			}}
		/>
	) : (
		<MuiCheckbox
			size='small'
			color='default'
			checked={!!value}
			onChange={() => onChange(!value)}
			disabled={isDisabled}
			classes={{
				root: classes?.checkbox,
				checked: classes?.checked,
			}}
		/>
	);
};

export default Checkbox;
