import { Checkbox as MuiCheckbox, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, TouchableOpacity, View } from 'react-native';
import { Divider } from 'react-native-paper';
import OutlineButtonContainer from '../../../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../../../components/shared/Buttons/SolidButton.container';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import colors from '../../../../../config/colors';

const useStyles = makeStyles(() => ({
	checkbox: {
		marginRight: -10,

		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
			backgroundColor: colors.white,
		},
	},
	checked: {},
}));

const Row = ({ isChecked, item, onCheck, disabled }: any) => {
	const classes = useStyles();

	return (
		<TouchableOpacity
			accessibilityRole='button'
			onPress={e => {
				e?.stopPropagation();
				onCheck(item.id, !isChecked);
			}}
			style={{
				alignItems: 'center',
				flexDirection: 'row-reverse',
				flex: 1,
			}}
			disabled={disabled}
		>
			<MuiCheckbox
				size='small'
				color='default'
				checked={!!isChecked}
				classes={{
					root: classes?.checkbox,
					checked: classes?.checked,
				}}
			/>
			<I18n
				weight='400'
				size={14}
				style={{
					textAlign: 'right',
					marginRight: 13,
				}}
				color='darkGrey'
			>
				{item.name}
			</I18n>
		</TouchableOpacity>
	);
};
const SelectDepartmentsModal = ({
	selectedData,
	allData,
	close,
	role,
	disabled,
	onNext,
	isSelected,
	onSubmit,
	title,
	subTitle,
}: any) => {
	const [selected, setSelected] = useState(selectedData || []);
	const onCheck = (itemId: number | string, value: boolean) => {
		if (itemId === 'all') {
			if (value) {
				setSelected(allData.map((d: any) => d.id));
			} else {
				setSelected([]);
			}
			return;
		}

		if (value) {
			setSelected([...selected, itemId]);
		} else {
			setSelected(
				selected.filter((s: any) => s !== itemId && s !== 'all'),
			);
		}
	};

	const _onSubmit = () => {
		onSubmit(selected.filter((i: any) => i !== 'all'));
		onNext();
		close();
	};

	useEffect(() => {
		setSelected(selectedData || []);
	}, [selectedData]);

	return (
		<View
			style={{
				borderRadius: 15,
				width: 390,
				paddingHorizontal: 38,
				paddingVertical: 22,
				backgroundColor: colors.white,
			}}
		>
			<I18n size={30} style={{ marginBottom: 19 }} weight='normal'>
				{title ? title : 'editUserModal.addDepartments'}
			</I18n>
			<Divider />
			<I18n size={16} style={{ marginTop: 19 }} weight='normal'>
				{subTitle ? subTitle : 'editUserModal.addDepartmentsSubTitle'}
			</I18n>
			<FlatList
				style={{ marginTop: 17, flex: 1, marginBottom: 30 }}
				data={allData}
				contentContainerStyle={{
					paddingBottom: 30,
					maxHeight: Dimensions.get('screen').height - 600,
				}}
				renderItem={({ item, ...rest }) => (
					<Row
						isChecked={
							(item?.id === 'all' &&
								selected.length === allData.length - 1) ||
							isSelected?.(item, selected) ||
							!!selected.includes(item.id) ||
							(!selected.length && allData[0]?.id !== 'all')
						}
						disabled={disabled}
						item={item}
						roleId={role.id}
						onCheck={onCheck}
						{...rest}
					/>
				)}
				keyExtractor={item => item.id}
			/>
			<Divider />
			<View style={{ flexDirection: 'row-reverse', marginTop: 24 }}>
				<SolidButtonContainer
					disabled={!selected?.length || disabled}
					overrideStyle={{ minWidth: 90 }}
					onPress={_onSubmit}
				>
					general.update
				</SolidButtonContainer>
				<OutlineButtonContainer
					size='medium'
					onPress={onNext}
					overrideStyle={{ minWidth: 90, marginRight: 30 }}
				>
					general.cancel
				</OutlineButtonContainer>
			</View>
		</View>
	);
};

export default SelectDepartmentsModal;
