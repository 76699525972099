import httpService from '../../../services/http.service';
import { downloadFile as downloadFileUtil } from '../../../utils/downloadFile.web.utils';

export const getFiles = async ({ pdf, font }: any) => {
	const formPdfBytes = await fetch(pdf).then(res => res.arrayBuffer());
	const fontBytes = await fetch(font).then(res => res.arrayBuffer());
	return { formPdfBytes, fontBytes };
};

export const downloadFile = (pdfBytes: any, fileName?: string) => {
	// const blob = new Blob([pdfBytes], { type: 'application/pdf' });
	const link = document.createElement('a');
	link.href = pdfBytes;
	link.download = fileName || '';
	link.click();
};

export const downloadEmployeeAttachmentsHandler = async (
	fieldId: number,
	extraData: any,
) => {
	try {
		const res: any = await httpService.api({
			type: 'getEmployeeForm101Attachments',
			params: { formId: fieldId },
			responseType: 'blob',
		});
		let elm = document.createElement('a');
		elm.href = URL.createObjectURL(res);
		elm.setAttribute('download', `${extraData?.taxYear}_attachments.zip`);
		elm.click();
	} catch (error) {
		console.log('error download attachments', error);
	}
};

export const downloadUrlApprovedManagerFile = async (
	id: number,
	extraData: any,
) => {
	const res: any = await httpService.api({
		type: 'getWorkerCardFinalFileManager',
		params: { formId: id },
	});
	const fileName = `tofes101_${extraData?.taxYear}_${extraData?.teudatZeut}.pdf`;

	downloadFileUtil(res, fileName);
};
