import React, { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

import FieldBuilder from '../../shared/FieldBuilder/FieldBuilder';
import colors from '../../../config/colors';
import ImageIcon from '../../shared/Icon/ImageIcon.component';
import { i18nService } from '../../../services/i18n.service';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import _, { debounce } from 'lodash';

interface FlexTableProps {
	data?: any;
	header?: any;
	disabled: boolean;
	headerBackgroundColor: string;
	headerColor: string;
	color: string;
	onBlur: Function;
	onChange: Function;
	onPreesAdd: Function;
	onPress: Function;
	tableName: string;
	boxNumber: number;
	maxLength: number;
	keyboardType: [string, string] | string;
	toFixed: number | [number, Object?];
	type: string;
	componentType: string;
	userId: any;
	activeSort: { name: string; direction: string };
	setActiveSort: Function;
	onSort: Function;
	forceMin: boolean;
	index: number;
	nextUserId: any;
	editCurrentLineOnlyMode: boolean;
}
const useStyles = makeStyles((theme: any) => ({
	header: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
		fontFamily: 'RubikMedium',
		whiteSpace: ({ type }: any) => (type === 'table' ? 'normal' : 'nowrap'),
		padding: '8px',
		height: ({ type }: any) => (type === 'table' ? '44px' : '22px'),
		borderLeft: ({ type }: any) =>
			type === 'table'
				? `1px solid ${colors.primary}`
				: `1px solid ${colors.filterBorder}`,
		borderBottom: `1px solid ${colors.filterBorder}`,
		backgroundColor: ({ headerBackgroundColor }: any) =>
			headerBackgroundColor,
		color: ({ headerColor }: any) => headerColor,
		maxWidth: ({ componentType, type }: any) =>
			componentType === 'optionalDeductionsComponent'
				? 160
				: type === 'table'
				? 69
				: 'auto',
		width: ({ componentType, type }: any) =>
			componentType === 'optionalDeductionsComponent'
				? 160
				: type === 'table'
				? 69
				: 'auto',
	},
	addNewHeader: {
		borderRadius: '22px 0 0 22px',
	},
	list: {
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: ({ tableName }) =>
			tableName === 'lastMonth' ? 'flex-end' : 'flex-start',
		direction: ({ tableName }) =>
			tableName === 'lastMonth' ? 'ltr' : 'rtl',
		alignItems: 'center',
		padding: ({ type }: any) => (type === 'table' ? '5px 8px' : '5px'),
		height: ({ type }: any) => (type === 'table' ? '41px' : '22px'),
		borderLeft: `1px solid ${colors.filterBorder}`,
		borderBottom: `1px solid ${colors.filterBorder}`,
		color: ({ color }: any) => color,
		fontFamily: 'RubikRegular',
		width: ({ componentType, type }: any) =>
			componentType === 'optionalDeductionsComponent'
				? 160
				: type === 'table'
				? 69
				: 'auto',
	},
	onPressLine: {
		background: 'none',
		border: 'none',
		cursor: ({ type }: any) => (type === 'table' ? 'pointer' : 'auto'),
		padding: 0,
		margin: 0,
		height: '100%',
		width: '100%',
	},
	addNewBtn: {
		background: 'none',
		border: 'none',
		cursor: 'pointer',
		padding: 0,
		margin: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': { opacity: 0.8 },
	},
	headerText: {
		width: '84%',
	},
	iconsBox: {
		display: 'flex',
		flexDirection: 'column',
		width: '16%',
	},
	thBox: {
		display: 'flex',
		alignItems: 'center',
	},
	arrowIcon: {
		fontSize: '1.3rem',
		cursor: 'pointer',
		color: colors.white,
	},
	down: {
		marginTop: -9,
	},
	arrowClicked: {
		color: colors.lightPrimary,
	},
}));

const FlexTableComponent = (props: FlexTableProps) => {
	const {
		data,
		header,
		disabled = false,
		headerBackgroundColor,
		headerColor,
		color,
		onBlur,
		onChange,
		onPreesAdd,
		onPress,
		onSort,
		tableName,
		boxNumber,
		maxLength,
		keyboardType,
		toFixed,
		type,
		componentType,
		userId,
		activeSort,
		setActiveSort,
		forceMin,
		index,
		nextUserId,
		editCurrentLineOnlyMode,
	} = props;

	const classes = useStyles({
		headerBackgroundColor,
		headerColor,
		color,
		type,
		tableName,
		componentType,
	});

	const _onSort = (name: string, direction: string, code: number) => {
		onSort && onSort(name, direction, code);
		setActiveSort({ name, direction });
	};

	const showData = useMemo(
		() =>
			type === 'table'
				? data
				: Array.isArray(data?.[tableName][boxNumber])
				? data?.[tableName][boxNumber]
				: data?.[tableName],
		[type, data, tableName, boxNumber],
	);

	return (
		<>
			{type === 'table' &&
				header?.map(
					(item: any) =>
						item.type === 'header' && (
							<div className={classes.header} key={item.name}>
								<span className={classes.headerText}>
									{i18nService.translate(item.value)}
								</span>
								<span className={classes.iconsBox}>
									<ArrowDropUp
										classes={{
											root: classnames(
												classes.arrowIcon,
												activeSort.name === item.name &&
													activeSort.direction ===
														'ASC'
													? classes.arrowClicked
													: null,
											),
										}}
										onClick={e => {
											e.preventDefault();
											_onSort(
												item.name,
												'ASC',
												item.code,
											);
										}}
									/>
									<ArrowDropDown
										classes={{
											root: classnames(
												classes.down,
												classes.arrowIcon,
												activeSort.name === item.name &&
													activeSort.direction ===
														'DESC'
													? classes.arrowClicked
													: null,
											),
										}}
										onClick={e => {
											e.preventDefault();
											_onSort(
												item.name,
												'DESC',
												item.code,
											);
										}}
									/>
								</span>
								{item.addNew && !disabled && (
									<span>
										<button
											tabIndex={-1}
											className={classes.addNewBtn}
											onClick={() =>
												onPreesAdd(item.name)
											}
										>
											<ImageIcon
												width={22}
												height={22}
												name={'whitePlus'}
											/>
										</button>
									</span>
								)}
							</div>
						),
				)}
			{showData?.map((item: any) => (
				<FlexTableItem
					key={`${item.name}${item.id}`}
					isNextUser={
						nextUserId === item.id && editCurrentLineOnlyMode
					}
					isCurrentUser={userId === item.id}
					isNotCurrentUser={
						editCurrentLineOnlyMode && userId !== item.id
					}
					item={item}
					onPress={onPress}
					tableName={tableName}
					{...{
						disabled,
						headerBackgroundColor,
						headerColor,
						color,
						onBlur,
						onChange,
						onPreesAdd,
						maxLength,
						keyboardType,
						toFixed,
						type,
						componentType,
						forceMin,
						index,
					}}
				/>
			))}
		</>
	);
};

const FlexTableItem = React.memo(
	(props: any) => {
		const {
			disabled = false,
			headerBackgroundColor,
			headerColor,
			color,
			onBlur,
			onChange,
			onPreesAdd,
			onPress,
			maxLength,
			keyboardType,
			toFixed,
			type,
			componentType,
			forceMin,
			index,
			item,
			isNextUser,
			isNotCurrentUser,
			isCurrentUser,
			tableName,
		} = props;

		const classes = useStyles({
			headerBackgroundColor,
			headerColor,
			color,
			type,
			tableName,
			componentType,
		});

		const _onChange = useCallback(
			debounce((...args) => {
				onChange(...args);
			}, 300),
			[onChange],
		);

		return item.type === 'header' && type !== 'table' ? (
			<div
				className={classnames(
					classes.header,
					item.addNew && classes.addNewHeader,
				)}
				key={`${item.name},${index}`}
			>
				<span>{i18nService.translate(item.value)}</span>
				{item.addNew && !disabled && (
					<span>
						<button
							className={classes.addNewBtn}
							tabIndex={-1}
							onClick={() => onPreesAdd(item.name)}
						>
							<ImageIcon
								width={22}
								height={22}
								name={'whitePlus'}
							/>
						</button>
					</span>
				)}
			</div>
		) : item.type !== 'header' ? (
			<button
				key={`${item.id}${item.name}`}
				onClick={() =>
					type === 'table' ? onPress(item.order) : undefined
				}
				tabIndex={isNextUser ? 0 : -1}
				onFocus={() =>
					type === 'table' && isNextUser
						? onPress(item.order)
						: undefined
				}
				className={classes.onPressLine}
			>
				<div
					className={classes.list}
					style={{
						backgroundColor:
							type === 'table' && isCurrentUser
								? colors.selectRowColor
								: index % 2 !== 0
								? disabled
									? colors.tableDisableBackgroundOpacity
									: colors.tableBackgroundOpacity
								: colors.white,
						textAlign: 'right',
					}}
				>
					{disabled ? (
						item.value ? (
							i18nService.translate(item.value)
						) : item.placeholder ? (
							item.placeholder
						) : null
					) : item.type === 'input' ? (
						<FieldBuilder
							styles={{
								wrapper: {
									width: '100%',
									height: '100%',
									border: 'none',
									fontSize: '14px',
									margin: 0,
									padding: type === 'table' ? 8 : 0,
								},
								labelWrapper: {
									height: 0,
									marginTop: 0,
									marginRight: 0,
									marginLeft: 0,
									marginBottom: 0,
									padding: 0,
								},
								errorCustomStyle: {
									backgroundColor: colors.white,
									position: 'relative',
									maxWidth: 900,
									zIndex: 999,
									whiteSpace: 'nowrap',
									top: -3,
								},
							}}
							height={'100%'}
							padding={0}
							key={item.name}
							name={item.name}
							disabled={isNotCurrentUser}
							type='input'
							placeholder={item.placeholder}
							onBlur={onBlur}
							onChange={_onChange}
							maxLength={maxLength}
							keyboardType={
								Array.isArray(keyboardType)
									? keyboardType[1] === item.name ||
									  item.name.includes(keyboardType[1])
										? undefined
										: keyboardType[0]
									: keyboardType
							}
							forceMin={forceMin}
							toFixed={
								Array.isArray(toFixed)
									? item.name.includes(
											Object.keys(toFixed[1])[0],
									  )
										? Object.values(toFixed[1])[0]
										: toFixed[0]
									: toFixed
							}
						/>
					) : item.title ? (
						i18nService.translate(item.title)
					) : item.value ? (
						i18nService.translate(item.value)
					) : item.placeholder ? (
						i18nService.translate(item.placeholder)
					) : item.name?.includes('_neto') ? (
						i18nService.translate(
							'FromMonthToMonthReports.formats.WageComponent.gross',
						)
					) : null}
				</div>
			</button>
		) : null;
	},
	(prev, next) => {
		if (!_.isEqual(_.omit(prev), _.omit(next))) {
			return false;
		}

		return true;
	},
);

export default React.memo(FlexTableComponent);
