import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import {
	CommonActions,
	DefaultTheme,
	NavigationContainer,
} from '@react-navigation/native';
import messaging from '@react-native-firebase/messaging';
import { useSelector } from 'react-redux';
import DeviceInfo from 'react-native-device-info';
import SplashScreen from 'react-native-splash-screen';

import routes from '../../config/routes';
import SignInContainer from '../SignIn/SignIn.container';
import ForgotPasswordContainer from '../ForgotPassword/ForgotPassword.container';
import ResetPasswordContainer from '../ResetPassword/ResetPassword.container';
import Loader from '../../components/shared/Loader/Loader.component';
import {
	getEmployer,
	getEmployerRolesPrivileges,
	getEmployeeRolesPrivileges,
	isLoggedIn,
	getRepRolesPrivileges,
	getIsLoginComplete,
} from '../../store/selectors/login.selectors';
import Router from './Router';
import { dispatch, getState } from '../../store/store';
import { AppState, I18nManager, Linking, Platform } from 'react-native';
import { pushNotificationsService } from '../../services/pushNotifications.service';
import {
	getStoreDataByKey,
	getUserData,
	initStore,
	logout,
} from '../../store/actions/login.actions';
import EmployerPage from '../EmployeePage/EmployeePage.component';
import { AppLoading } from 'expo';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Privileges } from '../../constants/roleTypes.constant';
import {
	resetNavigateTo,
	setDefaultDisplayMode,
	setIsMaintenance,
	setNavigateTo,
} from '../../store/actions/config.actions';
import {
	getDefaultDisplayMode,
	getNavigationTo,
} from '../../store/selectors/config.selectors';
import {
	getDocumentTitle,
	getIsVersionLower,
	openStore,
} from '../../utils/globals.utils';
import ChatWrapper from '../../components/shared/Chat/ChatWrapper';
import httpService from '../../services/http.service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ForceUpdate from './ForceUpdate';
import { modalService } from '../../services/modal.service';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import I18n from '../../components/shared/i18n/I18n.component';
import UpdateEmailContainer from '../UpdateEmail/UpdateEmail.container';
import { getServerDateTime } from '../../store/selectors/dateTime.selectors';
import moment from 'moment';
import timeTimerService from '../../services/timeTimerService';
import logoutWhenNoAction from '../../services/logoutWhenNoAction.service';
import { getIsEnterMode } from '../../store/selectors/repEnterMode.selector';
import { fetchBusinessUnitList } from '../../store/actions/reportsFilters.actions';
import { setRepEnterCustomerMode } from '../../store/actions/repEnterMode.actions';
import { AsyncLocalStorage } from '../../config/localStorage';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const getUrlObj = (arr: any, index: number) => {
	const obj = { screen: arr[index] };
	if (!arr[index + 1]) {
		return obj;
	}

	return {
		screen: arr[index],
		params: getUrlObj(arr, index + 1),
	};
};

const MyTheme = {
	...DefaultTheme,
	colors: {
		...DefaultTheme.colors,
		background: '#d3e3ed66',
	},
};

const Tabs = ({ navigation }: any) => {
	const loggedIn = useSelector(isLoggedIn);
	const employerData = useSelector(getEmployer);
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);
	const repRolesPrivileges = useSelector(getRepRolesPrivileges);
	const navigationTo = useSelector(getNavigationTo);
	const defaultDisplayMode = useSelector(getDefaultDisplayMode);
	const isRepEnterMode = useSelector(getIsEnterMode);

	const employer = useMemo(
		() =>
			typeof employerData === 'string'
				? JSON.parse(employerData)
				: employerData,
		[employerData],
	);

	const setDefaultDisplay = () => {
		if (
			defaultDisplayMode === 'EMPLOYER' &&
			employerRolesPrivileges?.length
		) {
			navigation.dispatch(
				CommonActions.reset({
					index: 0,
					routes: [{ name: 'employer' }],
				}),
			);
		} else if (defaultDisplayMode === 'EMPLOYEE') {
			navigation.dispatch(
				CommonActions.reset({
					index: 0,
					routes: [{ name: 'employee' }],
				}),
			);
		}
		dispatch(setDefaultDisplayMode(undefined));
	};
	const navigateToScreen = async () => {
		const getData = await getStoreDataByKey(
			AsyncLocalStorage.REP_EXIT_FROM,
		);
		const goBackTo = JSON.parse(getData)?.goBackTo;
		navigation.navigate('representative', {
			screen: goBackTo || 'repCustomers',
		});
	};

	useEffect(() => {
		if (getState().reportsFilters.businessUnitsList?.length) {
			dispatch(fetchBusinessUnitList());
			if (!isRepEnterMode) {
				navigateToScreen();
			}
		}
	}, [isRepEnterMode]);

	useEffect(() => {
		if (navigationTo && employer?.id) {
			const data = navigationTo.split('/');
			navigation.navigate(
				data[1],
				data[2] ? getUrlObj(data, 2) : undefined,
			);
			dispatch(setDefaultDisplayMode(undefined));
			dispatch(resetNavigateTo());
		} else if (defaultDisplayMode && employer?.id) {
			if (Platform.OS === 'web') {
				if (!window.location.pathname.startsWith('/portal')) {
					setDefaultDisplay();
				}
			} else {
				setDefaultDisplay();
			}
		}
	}, [employer?.id, navigationTo, defaultDisplayMode]);

	return loggedIn ? (
		<Tab.Navigator
			tabBar={() => null}
			screenOptions={{ tabBarStyle: { display: 'none' } }}
			sceneContainerStyle={{ backgroundColor: 'transparent' }}
			initialRouteName={
				employeeRolesPrivileges?.includes(
					Privileges.ROLE_LOG_WORK_WRITE,
				) ||
				employeeRolesPrivileges?.includes(Privileges.ROLE_LOG_WORK_READ)
					? 'employee'
					: 'employer'
			}
		>
			{employeeRolesPrivileges?.length ? (
				<Tab.Screen
					name='employee'
					component={Router}
					options={{
						unmountOnBlur: true,
						title: getDocumentTitle('employee'),
					}}
				/>
			) : null}
			{employerRolesPrivileges?.length ? (
				<Tab.Screen
					name='employer'
					component={Router}
					options={{
						unmountOnBlur: true,
						title: getDocumentTitle('employer'),
					}}
				/>
			) : null}
			{repRolesPrivileges?.length ? (
				<Tab.Screen
					name='representative'
					component={Router}
					options={{
						unmountOnBlur: true,
						title: getDocumentTitle('representative'),
					}}
				/>
			) : null}
		</Tab.Navigator>
	) : null;
};

const RouterWrapper = () => {
	const loggedIn = useSelector(isLoggedIn);
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);
	const repRolesPrivileges = useSelector(getRepRolesPrivileges);
	const loginDataComplete = useSelector(getIsLoginComplete);
	const employerData = useSelector(getEmployer);
	const [forceUpdate, setForceUpdate] = useState(false);
	const shouldFetchTime = useMemo(
		() =>
			employeeRolesPrivileges?.find(privileg =>
				[
					Privileges.ROLE_LOG_WORK_READ,
					Privileges.ROLE_LOG_WORK_WRITE,
				].includes(privileg),
			),
		[employeeRolesPrivileges],
	);
	const shouldFetchTimeRef = useRef<any>();
	shouldFetchTimeRef.current = shouldFetchTime;
	const employer =
		typeof employerData === 'string'
			? JSON.parse(employerData)
			: employerData;

	const timer = useRef<any>();

	const linking = {
		prefixes: ['michpalhrs://', 'https://michpalhrs.com'],
		async getInitialURL() {
			// Check if app was opened from a deep link
			const url = await Linking.getInitialURL();
			if (url != null) {
				return url;
			}

			// Check if there is an initial firebase notification
			const message = await messaging().getInitialNotification();

			// Get deep link from data
			// if this is undefined, the app will open the default/home page
			return message?.data?.link;
		},
		subscribe(listener: any) {
			const onReceiveURL = ({ url }: { url: string }) => listener(url);

			// Listen to incoming links from deep linking
			Linking.addEventListener('url', onReceiveURL);

			// Listen to firebase push notifications
			const unsubscribeNotification = messaging().onNotificationOpenedApp(
				message => {
					const url = message?.data?.link;

					if (url) {
						// Any custom logic to check whether the URL needs to be handled

						// Call the listener to let React Navigation handle the URL
						listener(url);
					}
				},
			);

			return () => {
				// Clean up the event listeners
				Linking.removeEventListener('url', onReceiveURL);
				unsubscribeNotification();
			};
		},
	};

	const getPortalStatus = async () => {
		try {
			const portalStatus = await httpService.api({
				type: 'getPortalStatus',
			});
			if (portalStatus) {
				dispatch(logout());
				dispatch(setIsMaintenance(true));
			}
		} catch (e) {}
	};

	useEffect(() => {
		dispatch(initStore());
		I18nManager.allowRTL(false);
		getPortalStatus();

		(async () => {
			try {
				const repData = await getStoreDataByKey(
					AsyncLocalStorage.REP_DATA,
				);
				repData &&
					dispatch(setRepEnterCustomerMode(JSON.parse(repData)));

				if (Platform.OS !== 'web') {
					const res: {
						minVersion: string;
						storeVersion: string;
					} = await httpService.api({
						type: 'getMinAppVersion',
						params: { platform: Platform.OS.toUpperCase() },
					});
					const forceUpdate = getIsVersionLower(res.minVersion);
					setForceUpdate(forceUpdate);
					if (!forceUpdate) {
						const lastVersion = await AsyncStorage.getItem(
							'lastVersion',
						);
						if (lastVersion !== res.storeVersion) {
							await AsyncStorage.setItem(
								'lastVersion',
								res.storeVersion,
							);
							const shouldUpdate = getIsVersionLower(
								res.storeVersion,
							);
							if (shouldUpdate) {
								modalService.openModal(
									null,
									{
										submitBtnText:
											'forceUpdate.updateButton',
										onSubmit: openStore,
										iconName: 'attention',
										overrideStyle: { width: 180 },
									},
									(props: any) => (
										<AlertModal {...props}>
											<I18n>updateApp.message</I18n>
										</AlertModal>
									),
								);
							}
						}
					}
				}
			} catch (e) {
				console.log({ e });
			}
		})();
	}, []);

	useEffect(() => {
		if (loggedIn && employer?.id) {
			// initial call
			dispatch(getUserData());

			if (Platform.OS === 'android' || Platform.OS === 'ios') {
				pushNotificationsService.register();
			}
		} else {
			if (Platform.OS === 'web') {
				if (window.location.pathname.startsWith('/portal')) {
					dispatch(setNavigateTo(window.location.pathname));
				}
			}
			timeTimerService.clearTimer();
		}
		return () => {
			timeTimerService.clearTimer();
		};
	}, [loggedIn, employer?.id]);

	useEffect(() => {
		if (shouldFetchTime && !timeTimerService.isActive) {
			timeTimerService.startTimer();
		}
	}, [shouldFetchTime]);

	useEffect(() => {
		const subscription: any = AppState.addEventListener(
			'change',
			nextAppState => {
				if (Platform.OS !== 'web' && shouldFetchTimeRef.current) {
					nextAppState === 'active' &&
						!timeTimerService.isActive &&
						timeTimerService.startTimer();
					nextAppState === 'background' &&
						timeTimerService.clearTimer();
				}
				if (Platform.OS !== 'web' && nextAppState === 'active') {
					logoutWhenNoAction.onBackFromBackground();
				}
			},
		);

		return () => {
			subscription?.remove();
		};
	}, []);

	useEffect(() => {
		if (
			!loggedIn ||
			!(
				employer?.id &&
				!employeeRolesPrivileges?.length &&
				!employerRolesPrivileges?.length &&
				!repRolesPrivileges?.length
			)
		) {
			SplashScreen?.hide();
		}
	}, []);

	if (forceUpdate) {
		return <ForceUpdate />;
	}

	if (!loginDataComplete) {
		return <AppLoading />;
	}

	if (
		loggedIn &&
		employer?.id &&
		!employeeRolesPrivileges?.length &&
		!employerRolesPrivileges?.length &&
		!repRolesPrivileges?.length
	) {
		return null;
	}

	return (
		<NavigationContainer theme={MyTheme} linking={linking}>
			<Stack.Navigator
				screenOptions={{
					header: ({ navigation }: any) => null,
				}}
			>
				{!loggedIn ? (
					<>
						<Stack.Screen
							name={routes.signIn.name}
							component={SignInContainer}
							options={{
								title: getDocumentTitle(routes.signIn.name),
							}}
						/>
						<Stack.Screen
							name={routes.forgotPassword.name}
							component={ForgotPasswordContainer}
							options={{
								title: getDocumentTitle(
									routes.forgotPassword.name,
								),
							}}
						/>
						<Stack.Screen
							name={routes.fp.name}
							component={ResetPasswordContainer}
							options={{
								title: getDocumentTitle(routes.fp.name),
							}}
						/>
						<Stack.Screen
							name={routes.mc.name}
							component={UpdateEmailContainer}
							options={{
								title: '',
							}}
						/>
					</>
				) : (
					<>
						{!employer?.id ? (
							<Stack.Screen
								name={routes.employeeList.name}
								component={EmployerPage}
								options={{
									title: getDocumentTitle(
										routes.employeeList.name,
									),
								}}
							/>
						) : null}

						{employeeRolesPrivileges?.length ||
						employerRolesPrivileges?.length ||
						repRolesPrivileges?.length ? (
							<Stack.Screen name='portal' component={Tabs} />
						) : null}
					</>
				)}
			</Stack.Navigator>
			<ChatWrapper />
			<Loader />
		</NavigationContainer>
	);
};

export default RouterWrapper;
