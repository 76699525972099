import React from 'react';
import { Image } from 'react-native';
import iconsMap from '../../../config/icons';
interface ImageIconProps {
	name: keyof typeof iconsMap;
	width?: number;
	height?: number;
	style?: any;
	src?: any;
}

const ImageIcon = ({
	name,
	width = 20,
	height = 20,
	style,
	src,
}: ImageIconProps) => {
	return (
		<Image
			source={src || iconsMap[name]}
			fadeDuration={0}
			style={{ width, height, ...style }}
		/>
	);
};

export default ImageIcon;
