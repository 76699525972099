import React, { useEffect, useState } from 'react';
import { TouchableHighlight, View, StyleSheet } from 'react-native';
import Select from '../../components/web/Select/Select.component';
import { modalService } from '../../services/modal.service';
import { i18nService } from '../../services/i18n.service';
import { EmplooyeeRequestsData } from './EmplooyeeRequests.container';
import httpService from '../../services/http.service';
import { FormProvider, useForm } from 'react-hook-form';
import I18n from '../../components/shared/i18n/I18n.component';
import FieldBuilder from '../../components/shared/FieldBuilder/FieldBuilder';
import getShadowStyle from '../../config/shadow';
import colors from '../../config/colors';
import { CommentModal, StatusSection } from './EmplooyeeRequests.utils';
import { Privileges } from '../../constants/roleTypes.constant';
import { hasPermissionOnBusinessUnit } from '../../utils/globals.utils';

const EmployerCommentAlert = ({ onNext, requestId, updateStatus }) => {
	const [employerComment, setEmployerComment] = useState('');

	const formMethods = useForm({
		defaultValues: {},
		mode: 'onBlur',
	});

	const onAccept = async () => {
		try {
			await httpService.api({
				type: 'rejectRequest',
				params: { requestId },
				data: {
					employerComment,
				},
			});
			updateStatus();
			onNext();
		} catch (e) {}
	};

	return (
		<View style={styles.sendNewRequestContainer}>
			<FormProvider {...formMethods}>
				<View style={{ width: 200 }}>
					<FieldBuilder
						key={'employerComment'}
						name={'employerComment'}
						multiline
						label={() => (
							<I18n size={14} weight='normal'>
								employeeRequests.addComment
							</I18n>
						)}
						isRequired={false}
						editable={true}
						maxLength={300}
						height={91}
						limitMargin={15}
						styles={{ wrapper: { margin: 0, marginBottom: 15 } }}
						onChange={(a, b, val) => setEmployerComment(val)}
					/>
				</View>
			</FormProvider>

			<View style={styles.buttonContainer}>
				<TouchableHighlight
					onPress={() => onAccept(onNext, employerComment)}
					underlayColor='transparent'
				>
					<View style={styles.button}>
						<I18n size={16} weight='normal' color='white'>
							employeeRequests.save
						</I18n>
					</View>
				</TouchableHighlight>

				<TouchableHighlight
					onPress={onNext}
					underlayColor='transparent'
				>
					<View style={styles.cancelButton}>
						<I18n size={16} weight='normal' color='lightPrimary'>
							general.cancel
						</I18n>
					</View>
				</TouchableHighlight>
			</View>
		</View>
	);
};

export const StatusTableSection = ({
	data,
	refresh,
}: {
	data: EmplooyeeRequestsData;
	refresh(): void;
}) => {
	const [status, setStatus] = useState(data.status);

	const vacationPermition =
		hasPermissionOnBusinessUnit(
			data.businessUnitId,
			Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
		) &&
		data.type ===
			i18nService.translate(
				'applicationsToEmployerContainer.vacationRequest',
			);
	const siknessPermition =
		hasPermissionOnBusinessUnit(
			data.businessUnitId,
			Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
		) &&
		data.type ===
			i18nService.translate(
				'applicationsToEmployerContainer.sicknessReport',
			);

	useEffect(() => {
		setStatus(data.status);
	}, [data.status, data.id]);

	const onSelect = async val => {
		try {
			if (val.id === 'APPROVE') {
				await httpService.api({
					type: 'approveRequest',
					params: { requestId: data.id },
					data: {
						employerComment: null,
					},
				});

				setStatus(i18nService.translate('general.APPROVED'));
				refresh();
			} else if (
				data.status != i18nService.translate('general.REJECTED')
			) {
				await modalService.openModal(null, {}, (props: any) => (
					<EmployerCommentAlert
						requestId={data.id}
						updateStatus={() =>
							setStatus(i18nService.translate('general.REJECTED'))
						}
						{...props}
					/>
				));
				refresh();
			}
		} catch (e) {}
	};
	return (
		<View key={data.id} style={{ flexDirection: 'row' }}>
			{status === i18nService.translate('general.CANCELED') ? (
				<StatusSection status={status} style={{ padding: 7 }} />
			) : (
				<View style={{ flexDirection: 'row' }}>
					<StatusSection status={status} onleyDot />
					<Select
						key={data.id}
						dontTranslate
						options={[
							{
								name: i18nService.translate(
									'employeeRequests.approveButton',
								),
								id: 'APPROVE',
							},
							{
								name: i18nService.translate(
									'employeeRequests.rejectButton',
								),
								id: 'REJECT',
							},
						]}
						menuPlacement={'auto'}
						menuPosition={'absolute'}
						placeholder={status}
						selectedOption={{ name: status }}
						style={{ width: 130 }}
						onSelection={onSelect}
						menuPortalTarget={document.body}
						closeMenuOnScroll={v => true}
						styles={{
							option: { justifyContent: 'flex-end' },
							menu: { top: undefined },
							indicatorsContainer: {
								opacity: vacationPermition
									? 1
									: siknessPermition
									? 1
									: 0.1,
							},
						}}
						disabled={!vacationPermition && !siknessPermition}
					/>
				</View>
			)}
			{/* {!!data?.employerComment && (<CommentModal text={data.employerComment}/>)} */}
		</View>
	);
};

const styles = StyleSheet.create({
	statusSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	statusDot: {
		width: 10,
		height: 10,
		borderRadius: 5,
		marginLeft: 5,
	},
	textCell: {
		minWidth: 50,
	},
	approveAllButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 141,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
		width: 350,
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	cancelButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: 'white',
		borderRadius: 45,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	sendNewRequestContainer: {
		paddingVertical: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		height: 239,
		width: 334,
		alignItems: 'center',
		justifyContent: 'space-around',
	},
});
