import CommentIconButton from '../../../pages/WorkerCard/WorkerCardEditor/CommentIconButton/CommentIconButton.component';
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import React, { useState } from 'react';
import iconsMap from '../../../config/icons';
import {
	Platform,
	View,
	Image,
	TouchableHighlight,
	useWindowDimensions,
} from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import Typography from '../Typography/Typography.component';
import I18n from '../i18n/I18n.component';
import { useFormContext } from 'react-hook-form';
import { getTabsMap } from '../../../pages/WorkerCard/WorkerCardEditor/WorkerCardTabs';
import _ from 'lodash';

const getLabelViewOnly = (extraProps: any, extraData?: any) => {
	const { type, value, name, options, pageRoute, additionalValue } =
		extraProps;

	switch (type) {
		case 'reactselect':
			const selectVal =
				options?.find((el: any) => el.id === value) ||
				extraData?.options?.find((el: any) => el.id === value);
			return selectVal?.name;
		case 'buttonsGroup':
		case 'multiButtonsGroup':
			const val =
				options.find((el: any) => el.value === value) ||
				extraData?.options?.find((el: any) => el.value === value);
			return val?.label;
		case 'inlineCheckbox':
			if (
				(pageRoute === 'taxCreditRequest' ||
					pageRoute === 'taxCreditReason') &&
				value === false
			) {
				return `general.null`;
			}

			return `general.${value}`;
		case 'checkbox':
			if (name === 'otherEmployerIncome.noKerenHishtalmutPayment') {
				const newValue: {
					value: string;
					label: string;
				}[] = [
					{
						value: '',
						label: 'workerCard.editor.otherEmployerIncome.noKerenHishtalmutPayment',
					},
					{
						value: '',
						label: 'workerCard.editor.otherEmployerIncome.noDisabilityPayment',
					},
				];
				if (value === true) {
					newValue[0].value = 'general.true';
				} else {
					newValue[0].value = 'general.null';
				}

				if (additionalValue === true) {
					newValue[1].value = 'general.true';
				} else {
					newValue[1].value = 'general.null';
				}

				return newValue;
			} else {
				return value;
			}
		default:
			return value;
	}
};

const FilesContainer = ({
	fieldName,
	data,
	isMobile,
	downloadFile,
}: {
	fieldName: string;
	data: any;
	isMobile: boolean;
	downloadFile: any;
}) => {
	const fieldsMap = {
		idNumber: 'idFile',
		maritalStatus: 'genderFile',
		taxAssessorApproved: 'taxAssessorApprovedFile',
		academicDegree: 'academicDegreeFile',
		alimonyPayment: 'alimonyPayment',
		childrenNotInMyCustody: 'childrenNotInMyCustodyFile',
		childrenWithDisability: 'childrenWithDisabilityFile',
		handicapped: 'handicappedFile',
		handicappedCompensation: 'handicappedCompensationFile',
		immigrant: 'immigrantFile',
		serviceStart: 'serviceFile',
		spouseHasNoIncome: 'spouseHasNoIncomeFile',
		taxCreditAddress: 'taxCreditAddressFile',
		noIncome: 'noIncomeFile',
		taxCreditRequestReason: 'noIncomeFile',
		'taxCreditRequest.additionalIncome': 'additionalIncomeFile',
	};
	const file = fieldsMap[fieldName] as string;
	const [openFiles, setOpenFiles] = useState(false);
	if (data[file] && data[file].length) {
		if (isMobile && data[file].length > 1) {
			return (
				<>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<TouchableHighlight
							onPress={() => setOpenFiles(!openFiles)}
						>
							<Image
								source={iconsMap.multipleFile}
								fadeDuration={0}
								style={{
									height: 20,
									width: 16,
									marginLeft: 10,
								}}
							/>
						</TouchableHighlight>
						{openFiles ? (
							<View
								style={{
									marginLeft: 5,
								}}
							>
								<Typography
									size={14}
									color='primary'
									weight='normal'
								>
									{i18nService.translate(
										'workerCard.viewer.showLess',
									)}
								</Typography>
							</View>
						) : (
							<View
								style={{
									marginLeft: 5,
								}}
							>
								<Typography
									size={14}
									color='primary'
									weight='normal'
								>
									{'+' + data[file].length}
								</Typography>
							</View>
						)}
					</View>
					<View
						style={{
							display: 'flex',
							marginHorizontal: 10,
							paddingTop: 10,
							height: 'auto',
							width: '100%',
						}}
					>
						{openFiles &&
							data[file]?.map((el: any, i: number) => {
								if (
									typeof el === 'object' &&
									Object.keys(el).length
								) {
									return (
										<View
											key={el.extraData?.id}
											style={{
												display: 'flex',
												flexDirection: 'row-reverse',
												justifyContent: 'space-between',
												marginHorizontal: 10,
												height: 30,
												marginTop: 10,
												//width: '100%'
											}}
										>
											<View
												style={{
													...Platform.select({
														web: {
															whiteSpace:
																'nowrap',
															overflow: 'hidden',
															textOverflow:
																'ellipsis',
															display: 'block',
														},
													}),
												}}
											>
												<Typography
													size={16}
													weight='normal'
												>
													{el.extraData.fileName}
												</Typography>
											</View>

											{el.extraData.status ===
												'READY' && (
												<TouchableHighlight
													onPress={() =>
														downloadFile(
															el.extraData,
														)
													}
												>
													<Image
														source={iconsMap.pdf}
														fadeDuration={0}
														style={{
															height: 20,
															width: 16,
															marginLeft: 10,
															marginRight: 10,
														}}
													/>
												</TouchableHighlight>
											)}
										</View>
									);
								}
							})}
					</View>
				</>
			);
		} else {
			return (
				data[file] &&
				data[file]?.map((el: any, i: number) => {
					if (typeof el === 'object' && Object.keys(el).length) {
						return (
							<View
								key={el.extraData?.id}
								style={{
									display: 'flex',
									flexDirection: isMobile
										? 'row'
										: 'row-reverse',
									marginHorizontal: 10,
									marginTop: isMobile ? 10 : 0,
								}}
							>
								<View
									style={{
										overflow: 'hidden',
										maxWidth: '90%',
										width: isMobile ? 'auto' : 100,
										...Platform.select({
											web: {
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												display: 'block',
											},
										}),
									}}
								>
									<Typography size={16} weight='normal'>
										{el.extraData.fileName}
									</Typography>
								</View>

								{el.extraData.status === 'READY' && (
									<TouchableHighlight
										onPress={() =>
											downloadFile(el.extraData)
										}
									>
										<Image
											source={iconsMap.pdf}
											fadeDuration={0}
											style={{
												height: 20,
												width: 16,
												marginLeft: 10,
												marginRight: 10,
											}}
										/>
									</TouchableHighlight>
								)}
							</View>
						);
					} else {
						return null;
					}
				})
			);
		}
	} else {
		return null;
	}
};

const FieldNameAndValueView = ({
	name,
	isMobile,
	extraProps,
	value,
	getTitleWidth,
}: any) => {
	const { getValues } = useFormContext();
	const formValues = getValues();
	let extraData: any;
	let additionalValue;

	const tabsMap = getTabsMap([]);

	if (
		name === 'otherEmployerIncome.noKerenHishtalmutPayment' &&
		formValues?.otherEmployerIncome
	) {
		const newValue = getLabelViewOnly({
			...extraProps,
			value,
			name,
			additionalValue:
				formValues?.otherEmployerIncome?.noDisabilityPayment,
		});
		const styles = isMobile
			? {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
			  }
			: {
					flexDirection: 'row-reverse',
					position: 'relative',
					left: -230,
					top: -16,
					justifyContent: 'flex-start',
			  };

		const Component = () => (
			<>
				<I18n
					size={isMobile ? 12 : 14}
					weight='normal'
					style={{
						marginBottom: isMobile ? 8 : 0,
						width: getTitleWidth(),
						marginLeft: 30,
					}}
				>
					{extraProps.label || extraProps.labelViewOnly}
				</I18n>
				{newValue.map((val: any, i: number, arr: any[]) => {
					return (
						<View style={styles}>
							<I18n
								size={16}
								weight='normal'
								style={{
									marginLeft: 10,
									marginBottom: 12,
									width: isMobile ? 'auto' : 900,
								}}
							>
								{val.label}
							</I18n>
							<I18n size={16} weight='normal'>
								{val.value}
							</I18n>
						</View>
					);
				})}
			</>
		);
		return isMobile ? (
			<Component />
		) : (
			<View
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
				}}
			>
				<Component />
			</View>
		);
	}

	if (name === 'currentEmployerIncome.incomeType') {
		extraData = tabsMap[3].fields[0][1];
		additionalValue =
			formValues?.currentEmployerIncome?.additionalIncomeType;
	}

	return (
		<>
			<I18n
				size={isMobile ? 12 : 14}
				weight='normal'
				style={{
					marginBottom: isMobile ? 8 : 0,
					width: getTitleWidth(),
					marginLeft: 30,
				}}
			>
				{extraProps.label || extraProps.labelViewOnly}
			</I18n>

			{Array.isArray(value) || additionalValue ? (
				(additionalValue
					? _.flattenDeep([value, additionalValue])
					: value
				).map((val: any, i: number, arr: any[]) => {
					return (
						<>
							<I18n size={16} weight='normal'>
								{getLabelViewOnly(
									{
										...extraProps,
										value: val,
										name,
									},
									extraData,
								)}
							</I18n>
							<Typography>
								{i !== arr.length - 1 ? ', ' : ''}
							</Typography>
						</>
					);
				})
			) : (
				<I18n size={16} weight='normal'>
					{getLabelViewOnly({
						...extraProps,
						value,
						name,
					})}
				</I18n>
			)}
		</>
	);
};

const ViewOnly = ({ extraProps, value, name, hideInViewMode }: any) => {
	const platform = useDevicePlatform();
	const dimensions = useWindowDimensions();
	const isMobile = platform !== DeviceType.WEB;
	const { commentReadOnly } = extraProps;

	if (name === 'taxCreditReason.taxCreditAddressCode') {
		return null;
	}

	if (!name || extraProps.hideInViewMode) {
		return null;
	}

	const arr = name.split('.');
	const fileFields = [
		'idFile',
		'genderFile',
		'taxAssessorApprovedFile',
		'academicDegreeFile',
		'alimonyPaymentFile',
		'childrenNotInMyCustodyFile',
		'childrenWithDisabilityFile',
		'handicappedFile',
		'handicappedCompensationFile',
		'immigrantFile',
		'serviceFile',
		'spouseHasNoIncomeFile',
		'taxCreditAddressFile',
		'noIncomeFile',
		'additionalIncomeFile',
	];

	const fieldsWithFile = [
		'idNumber',
		'maritalStatus',
		// 'taxCreditAddress',
		'academicDegree',
		'alimonyPayment',
		'childrenNotInMyCustody',
		'childrenWithDisability',
		'handicapped',
		'immigrant',
		'serviceStart',
		'spouseHasNoIncome',
		'taxCreditAddress',
		'noIncome',
		'taxCreditRequestReason',
		'taxAssessorApproved',
		'taxCreditRequest.additionalIncome',
	];
	if (fileFields.includes(arr[arr?.length - 1])) {
		return null;
	}

	const getTitleWidth = () => {
		if (isMobile) {
			return dimensions.width - 70;
		}

		if (extraProps.pageRoute === 'taxCreditReason') {
			return 425;
		}

		if (extraProps.pageRoute === 'taxCreditRequest') {
			return 180;
		}

		if (extraProps.pageRoute === 'otherEmployerIncome') {
			return 200;
		}

		return 162;
	};

	switch (name) {
		case 'signature':
			const Component = extraProps.Component;
			return (
				<Component
					value={value}
					notEditable={true}
					hasComment={extraProps.hasComment}
					onCommentClick={extraProps.onCommentClick}
					isEmployer={true}
					commentReadOnly={commentReadOnly}
				/>
			);
		case 'hasChildren':
			return (
				<View
					style={{
						flexDirection: isMobile ? 'column' : 'row-reverse',
						marginBottom: 41,
						justifyContent: 'flex-start',
						alignItems: isMobile ? 'stretch' : 'baseline',
						paddingRight: !isMobile ? 20 : 0,
					}}
				>
					<CommentIconButton
						disabled={commentReadOnly && !extraProps.hasComment}
						style={{
							position: isMobile ? 'absolute' : 'static',
							top: isMobile ? 0 : undefined,
							bottom: isMobile ? undefined : 0,
							height: isMobile ? 20 : 14,
							width: isMobile ? 22 : 14,
						}}
						hasComment={extraProps.hasComment}
						color={undefined}
						onPress={() =>
							extraProps.onCommentClick(
								extraProps.label ?? extraProps.labelViewOnly,
								name,
								value,
							)
						}
					/>
					<I18n
						size={isMobile ? 12 : 14}
						weight='normal'
						style={{
							marginBottom: isMobile ? 8 : 0,
							width: isMobile ? getTitleWidth() : 425,
							marginLeft: 30,
						}}
					>
						{extraProps.label || extraProps.labelViewOnly}
					</I18n>

					{Array.isArray(value) ? (
						value.map((val: any, i: number, arr: any[]) => {
							return (
								<>
									<I18n size={16} weight='normal'>
										{getLabelViewOnly({
											...extraProps,
											value: val,
											name,
										})}
									</I18n>
									<Typography>
										{i !== arr.length - 1 ? ', ' : ''}
									</Typography>
								</>
							);
						})
					) : (
						<I18n size={16} weight='normal'>
							{getLabelViewOnly({
								...extraProps,
								value,
								name,
							})}
						</I18n>
					)}
				</View>
			);
		case 'children':
		case 'taxCreditRequest.additionalIncome':
			if (extraProps.fullData) {
				const title = i18nService.translate(
					name === 'children' ? 'general.child' : 'general.employer',
				);
				const employeeChildren = _.get(extraProps.fullData, name).map(
					(el: any, i: number) => {
						return (
							<>
								<View
									style={{
										flexDirection: isMobile
											? 'column'
											: 'row-reverse',
										justifyContent: 'flex-start',
										alignItems: isMobile
											? 'stretch'
											: 'baseline',
										marginBottom: isMobile ? 8 : 30,
									}}
								>
									<View
										style={{
											marginBottom: isMobile ? 8 : 0,
											width: isMobile ? '100%' : 160,
											display: 'flex',
											alignItems: isMobile
												? 'stretch'
												: 'baseline',
											justifyContent: isMobile
												? 'space-between'
												: 'flex-start',
											flexDirection: isMobile
												? 'row'
												: 'row-reverse',
										}}
									>
										<CommentIconButton
											disabled={
												commentReadOnly &&
												!el.comment?.length
											}
											style={{
												position: isMobile
													? undefined
													: 'static',
												top: isMobile ? 0 : undefined,
												bottom: isMobile
													? undefined
													: 0,
												height: isMobile ? 20 : 14,
												width: isMobile ? 22 : 14,
											}}
											hasComment={
												el.comment?.length ?? false
											}
											color={undefined}
											onPress={() => {
												extraProps.onChildCommentClick(
													title + ' ' + (i + 1),
													el.id,
													name,
												);
											}}
										/>
										<Typography
											size={20}
											style={{ width: getTitleWidth() }}
											weight='normal'
										>
											{title + ' ' + (i + 1)}
										</Typography>
									</View>
									{fieldsWithFile.includes(name) &&
										extraProps.routeData && (
											<FilesContainer
												fieldName={name}
												data={el}
												isMobile={isMobile}
												downloadFile={
													extraProps.downloadFile
												}
											/>
										)}
								</View>
								{Object.keys(el).map(field => {
									if (
										![
											'id',
											'comment',
											'additionalIncomeFile',
										].includes(field)
									) {
										return (
											<View
												style={{
													display: 'flex',
													flexDirection: isMobile
														? 'column'
														: 'row-reverse',
													alignItems: isMobile
														? 'stretch'
														: 'baseline',
													marginBottom: 41,
												}}
											>
												<I18n
													size={isMobile ? 12 : 14}
													weight='normal'
													style={{
														marginBottom: isMobile
															? 8
															: 0,
														width: isMobile
															? 'auto'
															: 170,
													}}
												>
													{
														extraProps?.fields?.find(
															(f: any) =>
																f.name ===
																field,
														)?.label
													}
												</I18n>
												<I18n size={16} weight='normal'>
													{getLabelViewOnly({
														...extraProps,
														value: el[field],
														type: extraProps?.fields?.find(
															(f: any) =>
																f.name ===
																field,
														)?.type,
														options:
															extraProps?.fields?.find(
																(f: any) =>
																	f.name ===
																	field,
															)?.options,
														name,
													})}
												</I18n>
											</View>
										);
									}
								})}
							</>
						);
					},
				);

				return (
					<View
						style={{
							flexDirection: 'column',
							marginBottom: 41,
							alignItems: 'flex-end',
							paddingRight: !isMobile ? 20 : 0,
							flex: 1,
						}}
					>
						{employeeChildren}
					</View>
				);
			}
		default: {
			return (
				<>
					<View
						style={{
							flexDirection: isMobile ? 'column' : 'row-reverse',
							marginBottom: 20,
							justifyContent: 'flex-start',
							alignItems: isMobile ? 'stretch' : 'baseline',
							paddingRight: !isMobile ? 20 : 0,
						}}
					>
						{extraProps.pageRoute !== 'employerDetails' &&
							name !== 'taxCreditRequest.noIncomeFile' &&
							name !== 'taxCreditReason.serviceFile' &&
							name !==
								'otherEmployerIncome.noDisabilityPayment' && (
								<CommentIconButton
									disabled={
										commentReadOnly &&
										!extraProps.hasComment
									}
									style={{
										position: isMobile
											? 'absolute'
											: 'static',
										top: isMobile ? 0 : undefined,
										bottom: isMobile ? undefined : 0,
										height: isMobile ? 20 : 14,
										width: isMobile ? 22 : 14,
									}}
									hasComment={extraProps.hasComment}
									color={undefined}
									onPress={() =>
										extraProps.onCommentClick(
											extraProps.label ??
												extraProps.labelViewOnly,
											name,
											value,
										)
									}
								/>
							)}
						<FieldNameAndValueView
							name={name}
							value={value}
							extraProps={extraProps}
							isMobile={isMobile}
							getTitleWidth={getTitleWidth}
						/>
						{fieldsWithFile.includes(arr[arr.length - 1]) &&
							extraProps.routeData && (
								<FilesContainer
									fieldName={
										value === 'taxAssessorApproved'
											? value
											: arr[arr.length - 1]
									}
									data={extraProps.routeData}
									isMobile={isMobile}
									downloadFile={extraProps.downloadFile}
								/>
							)}
					</View>
				</>
			);
		}
	}
};

export default ViewOnly;
