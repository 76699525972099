import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import {
	onPressCreate,
	onPressSubmit,
	onPressUpdate,
} from '../WorkerCard/workerCard.utils';
import { downloadEmployeeAttachmentsHandler } from '../WorkerCard/WorkerCardPreview/utils';
import { Employee101Data } from './ReportPDFPage.utils';
import { useSelector } from 'react-redux';
import { getEmployeeRolesPrivileges } from '../../store/selectors/login.selectors';
import { Privileges } from '../../constants/roleTypes.constant';
import I18n from '../../components/shared/i18n/I18n.component';

const WorkerCardHeader = ({
	innerHeaderData,
	enableCreate,
	getRange,
}: {
	innerHeaderData: Employee101Data;
	enableCreate: boolean;
	getRange: Function;
}) => {
	const navigation = useNavigation();
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

	const downloadBtnStyle = StyleSheet.flatten([
		styles.btn,
		!employeeRolesPrivileges?.includes(
			Privileges.ROLE_EMPLOYEE_FORM_WRITE,
		) && styles.btnDisabled,
	]);

	const updateFormHandler = () => {
		onPressUpdate(
			navigation,
			innerHeaderData.id,
			// innerHeaderData.statusCode,
		);
		// }
	};

	const createFormHandler = () => {
		onPressCreate(navigation);
	};

	return (
		<View style={styles.dataContainer}>
			{innerHeaderData.enableUpdate &&
				[
					'PENDING_EMPLOYEE_CORRECTION',
					'PENDING_EMPLOYEE_SUBMIT',
				].includes(innerHeaderData.statusCode) && (
					<View style={styles.innerButtonItemContainer}>
						<TouchableHighlight
							disabled={
								!employeeRolesPrivileges?.includes(
									Privileges.ROLE_EMPLOYEE_FORM_WRITE,
								)
							}
							style={downloadBtnStyle}
							underlayColor='transparent'
							onPress={() =>
								onPressSubmit(innerHeaderData.id, getRange)
							}
						>
							<View style={styles.innerButtonItem}>
								<I18n color='white' weight='normal' size={16}>
									report101ReportPage.submit
								</I18n>
							</View>
						</TouchableHighlight>
					</View>
				)}
			{innerHeaderData.enableUpdate && (
				<View style={styles.innerButtonItemContainer}>
					<TouchableHighlight
						disabled={
							!employeeRolesPrivileges?.includes(
								Privileges.ROLE_EMPLOYEE_FORM_WRITE,
							)
						}
						style={downloadBtnStyle}
						underlayColor='transparent'
						onPress={() => updateFormHandler()}
					>
						<View style={styles.innerButtonItem}>
							<Typography color='white' weight='normal' size={16}>
								{'עדכן טופס 101'}
							</Typography>
						</View>
					</TouchableHighlight>
				</View>
			)}
			{enableCreate && (
				<View style={styles.innerButtonItemContainer}>
					<TouchableHighlight
						style={downloadBtnStyle}
						underlayColor='transparent'
						onPress={() => createFormHandler()}
					>
						<View style={styles.innerButtonItem}>
							<I18n color='white' weight='normal' size={16}>
								report101ReportPage.add
							</I18n>
						</View>
					</TouchableHighlight>
				</View>
			)}
			{!enableCreate && !innerHeaderData.enableUpdate && (
				<View style={{ flex: 1 }} />
			)}
			<View style={styles.innerTextItemContainer}>
				<View style={styles.innerHeaderContent}>
					<TouchableHighlight
						disabled={!innerHeaderData.attachedFiles}
						style={downloadBtnStyle}
						underlayColor='transparent'
						onPress={() => {
							downloadEmployeeAttachmentsHandler(
								innerHeaderData.id,
								innerHeaderData,
							);
						}}
					>
						<ImageIcon
							name='downloadPaycheck'
							height={20}
							width={20}
						/>
					</TouchableHighlight>

					<Typography
						color='darkGrey'
						weight='400'
						size={14}
						style={{ margin: 10 }}
					>
						{innerHeaderData?.attachedFiles
							? innerHeaderData?.attachedFiles
							: 0}
					</Typography>
					<Typography color='darkGrey' weight='normal' size={14}>
						{'קבצים מצורפים'}
					</Typography>
				</View>

				<View style={styles.innerHeaderContent}>
					<Typography color='darkGrey' weight='400' size={14}>
						{innerHeaderData?.lastUpdate}
					</Typography>
					<Typography
						color='darkGrey'
						weight='normal'
						size={14}
						style={{ margin: 10 }}
					>
						{'תאריך עדכון אחרון'}
					</Typography>
				</View>

				<View style={styles.innerHeaderContent}>
					<Typography color='darkGrey' weight='400' size={14}>
						{innerHeaderData?.submitDate}
					</Typography>
					<Typography
						color='darkGrey'
						weight='normal'
						size={14}
						style={{ margin: 10 }}
					>
						{'תאריך הגשה'}
					</Typography>
				</View>

				<View style={styles.innerHeaderContent}>
					<Typography color='darkGrey' weight='400' size={14}>
						{innerHeaderData?.status}
					</Typography>
					<Typography
						color='darkGrey'
						weight='normal'
						size={14}
						style={{ margin: 10 }}
					>
						{'סטטוס'}
					</Typography>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	innerHeaderContentContainer: {
		display: 'flex',
		flexDirection: 'row',
	},

	innerHeaderContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginLeft: 20,
		minWidth: 130,
	},
	dataContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '75%',
	},
	innerButtonItemContainer: {
		display: 'flex',
		flexDirection: 'row',
		minWidth: 176,
		alignItems: 'center',
		marginLeft: 10,
	},
	innerTextItemContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	innerButtonItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 176,
		backgroundColor: '#4aaeef',
		borderRadius: 25,
	},
	listWrapper: {
		height: '100%',
	},
	btn: {
		height: 20,
		width: 20,
		justifyContent: 'center',
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	btnDisabled: {
		opacity: 0.5,
	},
	errorMessage: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default WorkerCardHeader;
