import React from 'react';
import { View } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';

export const Maintenance = () => {
	const currPlatform = useDevicePlatform();
	const isMobile: boolean = currPlatform !== DeviceType.WEB;

	return (
		<View
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				height: isMobile ? 275 : 304,
			}}
		>
			<I18n size={22} weight='normal' style={{ textAlign: 'center' }}>
				general.maintenance.title
			</I18n>
			<I18n
				size={22}
				style={{
					marginTop: 30,
					textAlign: 'center',
				}}
				weight='normal'
			>
				general.maintenance.sub
			</I18n>
		</View>
	);
};
