import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import colors from '../../config/colors';
import I18n from '../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import Header from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import { Divider } from 'react-native-paper';
import BusinessSettingsMobile from './BusinessSettings.component.native';
import { useFormContext } from 'react-hook-form';
import {
	getDocumentTitle,
	hasPermissionOnBusinessUnit,
} from '../../utils/globals.utils';
import Select from '../../components/shared/FieldBuilder/Select/ReactSelect.component';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../store/selectors/login.selectors';
import { Privileges } from '../../constants/roleTypes.constant';

export interface Props {
	navigation: any;
	data: any;
	onSend(data: any, events: any): void;
	onCancel(): void;
	eventsOptions: any;
	setEventsOptions: any;
	selectedEvents: any;
	setSelectedEvents: Function;
	showEvents: any;
	setShowEvents: any;
	showDatePicker: any;
	setShowDatePicker: any;
	editLockDay: any;
	setEditLockDay: any;
	maxShiftHour: any;
	setMaxShiftHour: any;
	presenceModuleExists: boolean;
	presenceModuleStatus: 'ACTIVE' | 'NOT_ACTIVE';
	businessUnitsList: any[];
	selectedBusinessUnits: any[];
	onChangeBusinessUnits: Function;
	form101ModuleStatus: string;
	payCheckModuleExists: string;
	formMethods: any;
	userData: any;
	tabs: any;
	notRender: boolean;
}

const Tab = createBottomTabNavigator();

const BusinessSettings = ({
	navigation,
	data,
	onSend,
	onCancel,
	eventsOptions,
	setEventsOptions,
	selectedEvents,
	setSelectedEvents,
	showEvents,
	setShowEvents,
	showDatePicker,
	setShowDatePicker,
	editLockDay,
	setEditLockDay,
	maxShiftHour,
	setMaxShiftHour,
	presenceModuleExists,
	presenceModuleStatus,
	businessUnitsList,
	selectedBusinessUnits,
	onChangeBusinessUnits,
	form101ModuleStatus,
	payCheckModuleExists,
	formMethods,
	userData,
	tabs,
	notRender,
}: Props) => {
	const { watch } = useFormContext();

	const [selectedTab, setSelectedTab] = useState(0);
	const [disableSubmit, setDisableSubmit] = useState(true);
	const platform = useDevicePlatform();
	const isMobileWeb = platform === DeviceType.MOBILE_WEB;
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);

	useEffect(() => {
		if (!presenceModuleExists && tabs[0].name === 'presence') {
			return;
		} else {
			setDisableSubmit(true);
			if (!notRender) {
				setSelectedTab(0);
				setTimeout(() => {
					tabs[0]?.onPress();
				}, 10);
			}
			const subscription = watch(
				(value, { name, type }) =>
					type === 'change' && setDisableSubmit(false),
			);
			return () => subscription.unsubscribe();
		}
	}, [userData, tabs, presenceModuleExists]);

	return isMobileWeb ? (
		<BusinessSettingsMobile
			navigation={navigation}
			data={data}
			onSend={onSend}
			onCancel={onCancel}
			eventsOptions={eventsOptions}
			setEventsOptions={setEventsOptions}
			selectedEvents={selectedEvents}
			setSelectedEvents={setSelectedEvents}
			showEvents={showEvents}
			setShowEvents={setShowEvents}
			showDatePicker={showDatePicker}
			setShowDatePicker={setShowDatePicker}
			editLockDay={editLockDay}
			setEditLockDay={setEditLockDay}
			maxShiftHour={maxShiftHour}
			setMaxShiftHour={setMaxShiftHour}
			presenceModuleExists={presenceModuleExists}
			presenceModuleStatus={presenceModuleStatus}
			businessUnitsList={businessUnitsList}
			selectedBusinessUnits={selectedBusinessUnits}
			onChangeBusinessUnits={onChangeBusinessUnits}
			form101ModuleStatus={form101ModuleStatus}
			payCheckModuleExists={payCheckModuleExists}
			formMethods={formMethods}
		/>
	) : (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>

			<View style={styles.container}>
				<I18n
					size={36}
					weight='normal'
					style={{ paddingHorizontal: 20 }}
				>
					businessSettings.title
				</I18n>
				<View
					style={{
						paddingTop: 20,
						paddingBottom: 20,
						alignItems: 'flex-end',
						zIndex: 1,
					}}
				>
					{businessUnitsList.length > 1 ? (
						<View
							style={{
								zIndex: 1,
								width: '40%',
							}}
						>
							<Select
								options={businessUnitsList}
								onChange={(value: any) =>
									onChangeBusinessUnits({ value })
								}
								horizontalPadding={60}
								value={selectedBusinessUnits?.[0]}
							/>
						</View>
					) : null}
				</View>
				<View style={styles.tabs}>
					{tabs.map((tab, index) => (
						<TouchableOpacity
							onPress={() => {
								setSelectedTab(index);
								tab.onPress();
							}}
							style={[
								styles.button,
								{
									borderBottomColor:
										index === selectedTab
											? colors.primary
											: colors.white,
								},
							]}
						>
							<I18n size={14} weight='normal'>
								{tab.label}
							</I18n>
						</TouchableOpacity>
					))}
				</View>
				<Divider
					style={{
						backgroundColor: colors.primary,
						opacity: 0.5,
					}}
				/>
				<Tab.Navigator
					tabBar={() => null}
					sceneContainerStyle={{ backgroundColor: colors.white }}
					screenOptions={{ unmountOnBlur: true }}
				>
					{tabs.map((item, index) => {
						const Component = item.tabComponent;

						return (
							<Tab.Screen
								key={`${item.key}-screen`}
								options={{
									title: getDocumentTitle(item.name),
									unmountOnBlur: true,
								}}
								name={item.name}
								children={() => (
									<Component
										data={data}
										onCancel={onCancel}
										onUpdate={onSend}
										disableSubmit={disableSubmit}
										canNotEdit={
											!hasPermissionOnBusinessUnit(
												selectedBusinessUnits?.[0],
												Privileges.ROLE_CUSTOMER_SETTING_WRITE,
											)
										}
										setDisableSubmit={setDisableSubmit}
										eventsOptions={eventsOptions}
										setEventsOptions={setEventsOptions}
										selectedEvents={selectedEvents}
										setSelectedEvents={setSelectedEvents}
										showEvents={showEvents}
										setShowEvents={setShowEvents}
										showDatePicker={showDatePicker}
										setShowDatePicker={setShowDatePicker}
										editLockDay={editLockDay}
										setEditLockDay={setEditLockDay}
										maxShiftHour={maxShiftHour}
										setMaxShiftHour={setMaxShiftHour}
										presenceModuleStatus={
											presenceModuleStatus
										}
										selectedBusinessUnit={
											selectedBusinessUnits?.[0]
										}
										form101ModuleStatus={
											form101ModuleStatus
										}
										payCheckModuleExists={
											payCheckModuleExists
										}
										formMethods={formMethods}
									/>
								)}
							/>
						);
					})}
				</Tab.Navigator>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		marginVertical: 27,
		marginHorizontal: 70,
		backgroundColor: colors.white,
		paddingHorizontal: 40,
		paddingTop: 30,
		paddingBottom: 30,
	},
	tabs: {
		flexDirection: 'row-reverse',
	},
	button: {
		width: 150,
		borderBottomWidth: 6,
		alignItems: 'center',
		padding: 3,
		marginTop: 40,
	},
});

export default BusinessSettings;
