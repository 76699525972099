import { createStackNavigator } from '@react-navigation/stack';
import React, { useState } from 'react';
import { UserEditorContext } from './userEditorContext';
import CreateUser from './CreateUpdateUser.container';
import CustomerManagementContainer from './CustomersManagement/CustomersManagement.container';

const Stack = createStackNavigator();

export const Editor = () => {
	const [updatedCustomers, setUpdatedCustomers] = useState({});

	return (
		<UserEditorContext.Provider
			value={{
				updatedCustomers,
				setUpdatedCustomers,
			}}
		>
			<Stack.Navigator screenOptions={{ headerShown: false }}>
				<Stack.Screen name='home' component={CreateUser} />
				<Stack.Screen
					name='customers'
					component={CustomerManagementContainer}
				/>
			</Stack.Navigator>
		</UserEditorContext.Provider>
	);
};
