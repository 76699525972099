export type Employee101Data = {
	id: number;
	taxYear: string;
	statusCode:
		| 'PENDING_EMPLOYEE_SUBMIT'
		| 'PENDING_EMPLOYER'
		| 'PENDING_EMPLOYEE_CORRECTION'
		| 'APPROVED';
	status: string;
	submitDate: Date;
	lastUpdate: Date;
	attachedFiles: number;
	enableUpdate: boolean;
};

export type Employee101YearStatus = Record<string, string>;
export type PDFDateRange = { first: string; last: string };
export type BusinessUnit = {
	id: string;
	name: string;
	numericId: number;
};
export const pageNameToReportTypeNumber: any = {
	attendance: 10001,
	form106: 106,
};
