import React, { useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import WebCheckBox from 'expo-checkbox';
import { makeStyles } from '@material-ui/core';
import I18n from "../../../../components/shared/i18n/I18n.component";
import colors from "../../../../config/colors";
import { DepFilterData, UsersFilter } from "../../../../pages/web/LocationSettingsPage/LocationSettingsModal/LocationSettingsModal.utils";

const useStyles = makeStyles(() => ({
  chip: {
    borderRadius: 2,
    color: 'hsl(0, 0%, 20%)',
    fontSize: '85%',
    padding: 3,
    paddingLeft: 6,
    margin: 2,
    backgroundColor: 'hsl(0, 0%, 90%)',
  },
  checkbox: {
    color: '#6d7278',
    '&$checked': {
      color: colors.lightPrimary,
    },
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      color: colors.grey,
    }
  },
  checked: {},
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: ({ isSelected }: any) =>
      isSelected ? colors.lightBlue : 'transparent',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.lightBlue,
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center'
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  arrow: {
    color: colors.primary,
    marginRight: 5
  }
}));

interface TreeDropDownProp {
  departmentData: {
    name: string;
    id: string;
    isChecked?: boolean;
    users: UsersFilter[]
  },
  departmentIndex: number,
  disabled?: boolean,
  setOpenDropDown(val: string): void,
  openDropDown: string,
  openOnLoad: boolean,
  onUpdateDepartment(departmentData: DepFilterData, departmentIndex: number, isChecked: boolean): void,
  onUpdateUser(val: boolean, user: UsersFilter, userIndex: number, departmentData: DepFilterData, departmentIndex: number): void
}
interface SelectedUserItemProps {
  key: any,
  title?: string,
  number?: string,
  onPress?: () => void
}

export const SelectedUserItem = ({ key, title, number, onPress }: SelectedUserItemProps) => {
  return (
    <View key={key} style={styles.container}>
      <I18n size={10} style={{ paddingTop: 1 }}>
        {title || number}
      </I18n>
      <TouchableOpacity onPress={onPress}>
        {!number && <I18n size={12} weight={'bold'} style={{ marginHorizontal: 5 }}>
          x
        </I18n>}
      </TouchableOpacity>
    </View>
  )
};

export const TreeDropDown = ({
  departmentData,
  departmentIndex,
  onUpdateDepartment,
  onUpdateUser,
  disabled,
  setOpenDropDown,
  openOnLoad,
  openDropDown }: TreeDropDownProp) => {
  const [dropdownHeight, setDropdownHeight] = useState(37);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isDepartmentChecked, setIsDepartmentChecked] = useState(departmentData?.isChecked);
  const [usersList, setUsersList] = useState(departmentData.users);
  const [isNotAllUsers, setIsNotAllUsers] = useState(false);

  useEffect(() => {
    openOnLoad && onRowPress();
  },[openOnLoad])

  useEffect(() => {
    if (!openOnLoad && !!departmentData.id && (openDropDown != departmentData.id)) {
      setDropdownHeight(37);
      setIsDropdownOpen(false);
    }
  }, [openDropDown])

  useEffect(() => {
    setIsDepartmentChecked(departmentData?.isChecked);
    setUsersList(departmentData?.users);
  }, [departmentData]);

  useEffect(() => {
    const isAll = usersList.filter((user) => !user.isChecked);
    setIsNotAllUsers(!!isAll.length);
  }, [usersList]);

  const onRowPress = () => {
    setDropdownHeight(isDropdownOpen ? 37 : departmentData?.users?.length * 40 + 40 > 170 ? 170 : departmentData?.users?.length * 40 + 40);
    setIsDropdownOpen(!isDropdownOpen);
    setOpenDropDown(!isDropdownOpen ? departmentData.id : '');
  };

  const onCheckDepartment = (val: boolean) => {
    setIsDepartmentChecked(val);
    setDropdownHeight(val ? departmentData?.users?.length * 40 + 40 > 170 ? 170 : departmentData?.users?.length * 40 + 40 : 40);
    setIsDropdownOpen(val);
    setOpenDropDown(val ? departmentData.id : '');
    onUpdateDepartment(departmentData, departmentIndex, val);
  };

  const onCheckUser = (val: boolean, user: UsersFilter, userIndex: number) => {
    onUpdateUser(val, user, userIndex, departmentData, departmentIndex);
  };

  const classes = useStyles({ isSelected: isDropdownOpen });

  return (
    <View key={departmentData.id} style={[styles.dataRowContainer, { height: dropdownHeight }]}>
      <TouchableOpacity onPress={onRowPress}>
        <div className={classes.wrapper}>
          <View style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
            {
              isDepartmentChecked &&
              isNotAllUsers && <I18n size={10} color="warning">editUserModal.rolesTable.partial</I18n>
            }
            {
              isDropdownOpen ? (
                <KeyboardArrowDownRoundedIcon
                  classes={{ root: classes.arrow }}
                  fontSize='small'
                />
              ) : (
                <KeyboardArrowLeftRoundedIcon
                  classes={{ root: classes.arrow }}
                  fontSize='small'
                />
              )}
          </View>
          <View style={styles.dataRowHeader}>
            <WebCheckBox
              value={isDepartmentChecked}
              onValueChange={onCheckDepartment}
              color={colors.lightPrimary}
              style={styles.checkBox}
              disabled={disabled}
            />
            <I18n size={16} style={{ zIndex: 'unset', maxWidth: 250 }}>
              {departmentData?.name}
            </I18n>
          </View>
        </div>
      </TouchableOpacity>
      <ScrollView>
        {
          usersList.map((item: UsersFilter, index: number) => (
            <View key={item?.id} style={[styles.dataRowHeader, { borderWidth: 1, borderColor: '#e7e7e8', paddingRight: 20 }]}>
              <WebCheckBox
                value={item?.isChecked}
                onValueChange={(val) => onCheckUser(val, item, index)}
                color={colors.lightPrimary}
                style={styles.checkBox}
                disabled={disabled}
              />
              <I18n size={16} style={{ zIndex: 'unset' }}>
                {`${item?.name} (${item?.teudatZeut})`}
              </I18n>
            </View>
          ))}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row-reverse',
    padding: 4,
    backgroundColor: '#d9d9d9',
    marginRight: 3,
    height: 22,
    justifyContent: 'center'
  },
  dropDown: {
    position: 'absolute',
    overflow: 'hidden',
    borderWidth: 1,
    backgroundColor: colors.white,
    top: 40,
    width: 340
  },
  checkBox: {
    width: 15,
    height: 15,
    borderRadius: 4,
    margin: 12
  },
  dataRowContainer: {
    marginVertical: 3,
    overflow: 'hidden',
  },
  dataRowHeader: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: 40
  }
})