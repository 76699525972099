import { StoreAction } from '../../interfaces/redux.interface';
import { Report101Request } from '../../pages/Report101Page/Report101Page.reducer';

const SET_FILTERS_STATE = 'SET_FILTERS_STATE';
const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const setInitialReport101FiltersState = () => ({
	type: SET_INITIAL_STATE,
	payload: null,
});
export const setAllReport101FiltersState = (
	state: Report101Request,
): StoreAction<Report101Request> => ({
	type: SET_FILTERS_STATE,
	payload: state,
});

const initialState = (): Report101Request => ({
	page: 0,
	pageSize: 15,
	sortBy: 'submitDate',
	sortDirection: 'DESC',
	search: '',
	filter: {
		dep: [],
		user_id: [],
		year: [],
		status: [],
	},
});

const reports101FiltersReducer = (
	state = initialState() as Report101Request,
	action: StoreAction<Report101Request>,
): Report101Request => {
	switch (action.type) {
		case SET_FILTERS_STATE:
			return {
				...action.payload,
				page: 0
			};
		case SET_INITIAL_STATE:
			return initialState();
		default:
			return state;
	}
};

export default reports101FiltersReducer;
