import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ReactDatePicker from '../../../../components/web/ReactDatePicker/ReactDatePicker.component';
import httpService from '../../../../services/http.service';
import Card from '../Card/Card.component';
import { getDefaultReportFromMonthToMonthFilter } from '../RepHomePage.utils';

const ReportFromMonthToMonth = ({ filters, customersFullDataJson }: any) => {
	const [localFilters, setLocalFilters] = useState<any>();
	const [data, setData] = useState<any>();

	const onChange = ({ value }: any) => {
		const newData = {
			month: [value.getMonth() + 1],
			year: [value.getFullYear()],
		};
		sessionStorage.setItem(
			'repDashboardReportFromMonthToMonth',
			JSON.stringify(newData),
		);
		setLocalFilters(newData);
	};

	const getData = async () => {
		const res = await httpService.api({
			type: 'getRepDashboardReportFromMonthToMonth',
			data: { f: { ...filters, ...localFilters } },
		});
		setData(res);
	};

	useEffect(() => {
		const tempFilters = sessionStorage.getItem(
			'repDashboardReportFromMonthToMonth',
		);

		if (tempFilters && tempFilters !== '') {
			setLocalFilters(JSON.parse(tempFilters));
		} else {
			setLocalFilters(getDefaultReportFromMonthToMonthFilter());
		}
	}, []);

	useEffect(() => {
		if (localFilters?.month.length) {
			getData();
		}
	}, [filters, localFilters]);

	return (
		<View style={{ paddingVertical: 30, width: 'calc(50% - 12px)' }}>
			<View
				style={{
					flexDirection: 'row-reverse',
					alignItems: 'baseline',
					marginBottom: 24,
				}}
			>
				<I18n weight='normal' size={36} style={{ marginLeft: 5 }}>
					repDashboard.reportFromMonthToMonth
				</I18n>
				<I18n size={14} weight='400' style={{ marginBottom: 2 }}>
					repDashboard.units
				</I18n>
			</View>
			<View
				style={{
					zIndex: 100,
					flexDirection: 'row-reverse',
				}}
			>
				<View
					style={{
						width: 200,
						zIndex: 100,
						flexDirection: 'row-reverse',
					}}
				>
					<ReactDatePicker
						onChange={onChange}
						key={`${localFilters}`}
						config={
							{
								name: 'test',
								type: '',
								dashboardViewMode: true,
								date:
									localFilters &&
									new Date(
										localFilters.year,
										localFilters.month - 1,
									),
							} as any
						}
					/>
				</View>
			</View>
			<View
				style={{
					marginTop: 16,
					flexDirection: 'row-reverse',
					...Platform.select({ web: { gap: 24 } }),
				}}
			>
				<Card
					title='repDashboard.ReportFromMonthToMonth.reports'
					data={data}
					width={'calc(100%)'}
					type='rows'
					colorsObj={{
						NO_RESULTS: 'hsl(360, 79%, 63%)',
						AWAITING_APPROVAL: '#EB8B2E',
						APPROVED: '#F2C94C',
						EXPORTED: 'hsl(145, 50%, 62%)',
					}}
					navigateTo='repWorkPaperReports'
					defaultParams={{
						...localFilters,
						...filters,
					}}
					moreParams={{
						customersFullData: customersFullDataJson,
					}}
				/>
			</View>
		</View>
	);
};

export default ReportFromMonthToMonth;
