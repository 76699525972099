import React, { useCallback, useEffect, useRef } from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import colors from '../../../../config/colors';
import classnames from 'classnames';
import { SubRows } from './SubRows.component';
import { View } from 'react-native';
import I18n from '../../../shared/i18n/I18n.component';
import moment from 'moment';

const useStyles = makeStyles((theme: any) => ({
	row: {
		height: 52,
		backgroundColor: ({ index, specialRowColor }: any) =>
			index % 2 === 0 ? colors.coloredTableRow : colors.white,
		cursor: ({ onRowClick }: any) => (onRowClick ? 'pointer' : 'auto'),
		position: 'relative',
	},
	tableCell: {
		fontFamily: 'RubikRegular',
		fontSize: 14,
		padding: '8px 16px 8px 8px',
		lineHeight: '1rem',
		textAlign: 'right',
		color: colors.darkGrey,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		backgroundColor: ({ index, specialRowColor }: any) =>
			index % 2 === 0
				? specialRowColor || colors.coloredTableRow
				: colors.white,
		transform: 'scale(1)',
		willChange: 'transform',
		isolation: 'isolate',
	},
	expander: {
		padding: '8px 16px 8px 0px',
	},
	selectedRow: {
		backgroundColor: () => colors.selectRowColor,
	},
}));

const defaultStickyRight = {
	position: 'sticky',
	zIndex: 3,
	boxShadow: '-3px 0 0 0 #0000001e',
};

type OuterClasses = {
	rowRoot?: string;
	cellRoot?: string;
	subRowRoot?: string;
	subCellRoot?: string;
	specialRow?: string;
};

export const renderRowSubComponent = ({
	row,
	rowProps,
	outerClasses,
	onSubRowClick,
	prepareRow,
	onDownArrowClick,
	onLeftArrowClick,
}: any) => (
	<SubRows
		row={row}
		rowProps={rowProps}
		onSubRowClick={onSubRowClick}
		outerClasses={outerClasses}
		prepareRow={prepareRow}
		onDownArrowClick={onDownArrowClick}
		onLeftArrowClick={onLeftArrowClick}
	/>
);
const Row = ({
	row,
	onRowClick,
	index,
	outerClasses,
	onSubRowClick,
	prepareRow,
	onLeftArrowClick,
	onDownArrowClick,
	selectedRowId,
	scrollToRow,
	scrollToRight,
	keyName,
	specialRowColor,
}: {
	row: any;
	onRowClick: Function;
	index: number;
	outerClasses?: OuterClasses;
	onSubRowClick: Function;
	prepareRow: Function;
	onLeftArrowClick?: Function;
	onDownArrowClick?: Function;
	scrollToRow: Function;
	scrollToRight: Function;
	selectedRowId?: string | number;
	keyName: string;
	specialRowColor?: string;
}) => {
	const classes = useStyles({ index, onRowClick, specialRowColor });
	const rowProps = row.getRowProps();
	const rowRef = useRef<any>();

	useEffect(() => {
		if (
			selectedRowId !== undefined &&
			(row.original.id === selectedRowId ||
				row.original[keyName] === selectedRowId)
		) {
			scrollToRow(rowRef?.current);
		}
	}, [selectedRowId, row.original.id]);

	useEffect(() => {
		scrollToRight(rowRef?.current);
	}, [{ ...row.original }]);

	const _onLeftArrowClick = useCallback(
		(e: any, row: any) => {
			if (onLeftArrowClick) {
				onLeftArrowClick(row);
			} else {
				e.stopPropagation();
				row.toggleRowExpanded();
			}
		},
		[row, onLeftArrowClick],
	);
	const _onDownArrowClick = useCallback(
		(e: any, row: any) => {
			if (onDownArrowClick) {
				onDownArrowClick(row);
			} else {
				e.stopPropagation();
				row.toggleRowExpanded();
			}
		},
		[row, onDownArrowClick],
	);
	const renderRowSubComponent = React.useCallback(
		({ row, rowProps, outerClasses, onSubRowClick }) => (
			<SubRows
				row={row}
				rowProps={rowProps}
				onSubRowClick={onSubRowClick}
				outerClasses={outerClasses}
				prepareRow={prepareRow}
				onDownArrowClick={_onDownArrowClick}
				onLeftArrowClick={_onLeftArrowClick}
			/>
		),
		[row, onSubRowClick, outerClasses],
	);
	return (
		<>
			<TableRow
				{...rowProps}
				ref={rowRef}
				onClick={() => onRowClick && onRowClick(row.original)}
				classes={{
					root: classnames(
						classes.row,
						outerClasses?.rowRoot,
						row.original.specialRow
							? outerClasses?.specialRow
							: null,
						selectedRowId !== undefined &&
							(row.original.id === selectedRowId ||
								row.original[keyName] === selectedRowId) &&
							classes.selectedRow,
					),
				}}
				style={{
					...(rowProps.style || {}),
					...(row.original.style || {}),
				}}
			>
				{row.cells.map((cell: any, index: number) => {
					return (
						<TableCell
							classes={{
								root: classnames(
									classes.tableCell,
									outerClasses?.cellRoot,
									cell.column.id === 'expander'
										? classes.expander
										: '',
									selectedRowId !== undefined &&
										(row.original.id === selectedRowId ||
											row.original[keyName] ===
												selectedRowId) &&
										classes.selectedRow,
								),
							}}
							{...cell.getCellProps()}
							style={{
								...cell.getCellProps().style,
								...cell.column.style,
								...(cell.column.sticky === 'right'
									? defaultStickyRight
									: {}),
								...(row.original.style || {}),
								...(cell.column.cellStyle?.(row) || {}),
							}}
						>
							{cell.column.hasBudget?.(row.original) && (
								<View
									style={{
										position: 'absolute',
										backgroundColor: '#AFB2B5',
										padding: 7,
										width: '85%',
										right: 0,
										top: 0,
										zIndex: 1,
										alignItems: 'center',
										borderBottomRightRadius: 7,
										borderBottomLeftRadius: 7,
										flexDirection: 'row',
										...(cell.column.budgetStyle || {}),
									}}
								>
									{cell.column.textBlock(row)}
								</View>
							)}
							{cell.column.id === 'expander' ? (
								row.canExpand || row.original.canHaveSubRows ? (
									<span
										{...row.getToggleRowExpandedProps({
											style: {
												position: 'relative',
												...(row.original.style || {}),
											},
										})}
									>
										{row.isExpanded ? (
											<ArrowDropDownIcon
												color='inherit'
												onClick={e =>
													_onDownArrowClick(e, row)
												}
											/>
										) : (
											<ArrowLeftIcon
												color='inherit'
												classes={{}}
												onClick={e =>
													_onLeftArrowClick(e, row)
												}
											/>
										)}
									</span>
								) : null
							) : (
								cell.render('Cell')
							)}
						</TableCell>
					);
				})}
			</TableRow>
			{row.isExpanded &&
				renderRowSubComponent({
					row,
					rowProps,
					outerClasses,
					onSubRowClick,
					prepareRow,
					onLeftArrowClick,
					onDownArrowClick,
				})}
		</>
	);
};

export default Row;
