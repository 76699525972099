import React, { useEffect, useState } from 'react';
import { TouchableHighlight, View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import EmployerList from '../../../pages/EmployerList/EmployerList.container';
import { i18nService } from '../../../services/i18n.service';
import {
	getEmployer,
	getEmployersData,
} from '../../../store/selectors/login.selectors';
import { getEmployersList } from '../../../store/actions/login.actions';
import { dispatch } from '../../../store/store';
import ImageIcon from '../Icon/ImageIcon.component';
import ModalContainer from '../Modal/Modal.container';
import Typography from '../Typography/Typography.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { modalService } from '../../../services/modal.service';
import { useNavigation, useRoute } from '@react-navigation/native';

const EmployerName = () => {
	const [isShowEmployerList, setShowEmployerList] = useState(false);
	const employerData = useSelector(getEmployer);
	const platform = useDevicePlatform();
	const navigation = useNavigation();
	const route = useRoute();
	const employer =
		typeof employerData === 'string'
			? JSON.parse(employerData)
			: employerData;
	const employerListData = useSelector(getEmployersData);
	useEffect(() => {
		!employerListData?.length && dispatch(getEmployersList());
	}, [employerListData]);

	const openEmployerModal = () => {
		modalService.openModal(
			null,
			{
				title: i18nService.translate(`employee.choose`),
				containerStyle: styles.employeeListContainer,
				navigation,
				route,
			},
			EmployerList,
		);
	};

	return (
		!employerListData?.length || (
			<>
				<TouchableHighlight
					disabled={
						!employerListData?.length ||
						employerListData?.length === 1
					}
					underlayColor='transparent'
					onPress={openEmployerModal}
				>
					<View style={styles.employeeName}>
						{employerListData?.length > 1 && (
							<ImageIcon
								name='arrowDown'
								width={14}
								height={9}
								style={{ marginRight: 5 }}
							/>
						)}
						<Typography
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								direction: 'rtl',
								textAlign: 'left',
								maxWidth:
									platform === DeviceType.WEB ? 'none' : 100,
								minWidth:
									platform === DeviceType.WEB ? 100 : 100,
								overflow: 'hidden',
								whiteSpace: 'break-spaces',
							}}
							numberOfLines={1}
							color={'darkGrey'}
							weight='normal'
							size={14}
						>
							{employer?.name}
						</Typography>
					</View>
				</TouchableHighlight>
			</>
		)
	);
};
const styles = StyleSheet.create({
	employeeName: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		// textDecorationLine: 'underline',
	},
	employeeListContainer: {
		width: 420,
	},
});
export default EmployerName;
