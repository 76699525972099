import React, { useEffect, useRef, useState } from 'react';
import ForgotPassword from './ForgotPassword.component';
import { forgotPassword } from '../../store/actions/login.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Platform } from 'react-native';
import { getIsMaintenance } from '../../store/selectors/config.selectors';

interface ForgotPasswordContainerProps {
	navigation: any;
}

const ForgotPasswordContainer = ({
	navigation,
}: ForgotPasswordContainerProps) => {
	const dispatch = useDispatch();
	const [teudatZeut, setTeudatZeut] = useState('');
	const [isValidTZ, setIsValidTZ] = useState(false);
	const [didResetPassword, setDidResetPassword] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [captchaVerified, setCaptchaVerified] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState(false);
	const recaptchaRef = useRef();
	const isMaintenance = useSelector(getIsMaintenance);

	useEffect(() => {
		isMaintenance && navigation.navigate('signIn');
	}, [isMaintenance]);

	const setEmailHandler = (val: string): void => {
		setHasError(false);
		setTeudatZeut(val);
		setIsValidTZ(true);
	};

	const checkIsValidEmail = (val: string): void => {
		const regExp = /\S+@\S+\.\S+/;
		setIsValidTZ(regExp.test(val));
	};

	const sendEmailForgotPswHandler = async () => {
		setSubmitted(true);
		if (Platform.OS !== 'web' || captchaVerified) {
			const res: any = await dispatch(forgotPassword(teudatZeut));
			if (res) {
				setDidResetPassword(true);
			} else {
				setHasError(true);
			}
		}
	};

	const onCaptchaChange = (value: any) => {
		setCaptchaVerified(true);
	};

	const onExpiredCaptcha = () => {
		setCaptchaVerified(false);
	};

	const setInitState = () => {
		setTeudatZeut('');
		setIsValidTZ(false);
		setDidResetPassword(false);
	};

	return (
		<ForgotPassword
			didResetPassword={didResetPassword}
			navigation={navigation}
			teudatZeut={teudatZeut}
			isValidTZ={isValidTZ}
			sendEmailForgotPsw={sendEmailForgotPswHandler}
			setInitState={setInitState}
			setEmail={setEmailHandler}
			hasError={hasError}
			onCaptchaChange={onCaptchaChange}
			onExpiredCaptcha={onExpiredCaptcha}
			captchaVerified={captchaVerified}
			submitted={submitted}
			ref={recaptchaRef}
		/>
	);
};

export default ForgotPasswordContainer;
