import React from 'react';
import { View, useWindowDimensions } from 'react-native';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import NoResults from '../../../components/shared/NoResults/NoResults';
import Typography from '../../../components/shared/Typography/Typography.component';
import FiltersContainer from '../../../components/web/Filters/Filters.container';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import colors from '../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../services/i18n.service';
import { modalService } from '../../../services/modal.service';
import CustomerDetailsContainer from './CustomerDetails/CustomerDetails.container';
import EditCustomerContainer from './EditCustomer/EditCustomer.container';
import RepCustomersMobile from './RepCustomers.component.native';

const RepCustomers = ({
	navigation,
	filtersConfig,
	getMoreData,
	tableData,
	onSort,
	onFilter,
	onRowClick,
	customerId,
	onSearch,
	canEditCustomer,
	updateRow,
	refreshData,
	forceRefresh,
	selectedFilters,
}: any) => {
	const dimensions = useWindowDimensions();
	const platform = useDevicePlatform();

	const openEditModal = () => {
		modalService.openModal(
			null,
			{ callback: refreshData, selectedCustomer: customerId },
			EditCustomerContainer,
		);
	};

	return platform === DeviceType.MOBILE_WEB ? (
		<RepCustomersMobile
			{...{
				navigation,
				filtersConfig,
				getMoreData,
				tableData,
				onSort,
				onFilter,
				onRowClick,
				customerId,
				onSearch,
				canEditCustomer,
				selectedFilters,
			}}
		/>
	) : (
		<View>
			<ReportsPageWrapper
				navigation={navigation}
				title='repCustomersPage.title'
			>
				<View
					style={{
						maxHeight: dimensions.height - 241,
						minHeight: dimensions.height - 170,
					}}
				>
					<View
						style={{
							marginBottom: 16,
							zIndex: 3,
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
						}}
					>
						<View
							style={{
								zIndex: 3,
								flexDirection: 'row-reverse',
								alignItems: 'baseline',
							}}
						>
							{filtersConfig[1]?.name === 'unitIds' && (
								<I18n style={{ marginLeft: 5 }}>
									repCustomersPage.unitFilter
								</I18n>
							)}
							<FiltersContainer
								config={filtersConfig}
								onChange={onFilter}
								customStyles={{
									container: {
										flexDirection: 'row-reverse',
									},
									filterContainer: {
										marginRight: 0,
										marginLeft: 15,
										zIndex: 3,
										flexDirection: 'row-reverse',
										alignItems: 'center',
									},
								}}
							/>
						</View>
						{/* TODO - VERSION 2.0 REQUEST */}
						{/* {canEditCustomer && (
							<SolidButtonContainer
								overrideStyle={{ marginLeft: 10 }}
								onPress={openEditModal}
							>
								<I18n color='white'>
									repCustomersPage.addCustomer
								</I18n>
							</SolidButtonContainer>
						)} */}
					</View>
					{Boolean(tableData.data?.length) && (
						<View
							style={{
								flexDirection: 'row-reverse',
								alignSelf: 'flex-start',
								marginBottom: 5,
							}}
						>
							<I18n size={14}>general.numberOfRecords</I18n>
							<Typography size={14} weight='normal'>
								{` ${tableData.total || '0'} `}
							</Typography>
						</View>
					)}
					{Boolean(tableData.data?.length) && (
						<View
							style={{
								flexDirection: 'row-reverse',
								flex: 1,
							}}
						>
							<View
								style={{
									backgroundColor: colors.white,
									width: '17%',
								}}
							>
								<TableContainer
									selectedRowId={customerId}
									headers={
										[
											{
												accessor: 'name',
												Header: i18nService.translate(
													'repCustomersPage.table.idAndName',
												),
												isSortable: true,
												CustomCell: ({
													value,
													row,
												}: any) => {
													return (
														<>
															<Typography
																weight='bold'
																size={14}
																color='lightPrimary'
															>
																{value}
																{row.original
																	.id && (
																	<Typography
																		weight='400'
																		size={
																			14
																		}
																		color='darkGrey'
																		style={{
																			marginRight: 7,
																		}}
																	>
																		{` (${row.original.id})`}
																	</Typography>
																)}
															</Typography>
														</>
													);
												},
											},
										] as any
									}
									data={tableData.data || []}
									count={40}
									onSort={onSort}
									getNextData={getMoreData}
									onRowClick={onRowClick}
									keyName='numericId'
								/>
							</View>
							<CustomerDetailsContainer
								canEditCustomer={canEditCustomer}
								customerId={customerId}
								updateRow={updateRow}
								forceRefresh={forceRefresh}
							/>
						</View>
					)}
					{tableData.data && Boolean(!tableData.data?.length) && (
						<NoResults title='repCustomersPage.noCustomers' />
					)}
				</View>
			</ReportsPageWrapper>
		</View>
	);
};

export default RepCustomers;
