import { StoreAction } from '../../interfaces/redux.interface';
import { Report101Request } from './Report101Page.reducer';
import { Report101FiltersType } from './Report101Page.utils';

export const SET_SORT = 'SET_SORT';
export const SET_FILTER = 'SET_FILTER';
export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const UPDATE_UNITIDS = 'UPDATE_UNITIDS';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_ALL_FILTERS = 'SET_ALL_FILTERS';
export const RESET_PAGE = 'RESET_PAGEs';
export const RESET_FILTERS = 'RESET_FILTERS';

export const sort = (
	field: string,
	direction: string,
): StoreAction<{ name: string; direction?: string }> => ({
	type: SET_SORT,
	payload: {
		name: field,
		direction: direction,
	},
});

export const updateUnitIds = (data: any): StoreAction<any> => ({
	type: UPDATE_UNITIDS,
	payload: data,
});

export const setAllFiltersSavedState = (
	state: Report101Request,
): StoreAction<Report101Request> => ({
	type: SET_ALL_FILTERS,
	payload: state,
});
export const incrementPage = (): StoreAction<undefined> => ({
	type: INCREMENT_PAGE,
	payload: undefined,
});
export const updateDepartment = (): StoreAction<any> => ({
	type: UPDATE_DEPARTMENT,
	payload: [],
});

export const search = (query: string): StoreAction<string> => ({
	type: SET_SEARCH_QUERY,
	payload: query,
});

export const resetPage = (): StoreAction<undefined> => ({
	type: RESET_PAGE,
	payload: undefined,
});

export const resetFiltersAndSort = (): StoreAction<undefined> => ({
	type: RESET_FILTERS,
	payload: undefined,
});

export const filter = (
	filterType: Report101FiltersType,
	value: string[] | string,
	isMultiselect: boolean = false,
): StoreAction<{
	filter: Report101FiltersType;
	value: string[] | string;
	isMultiselect: boolean;
}> => ({
	type: SET_FILTER,
	payload: {
		filter: filterType,
		value,
		isMultiselect,
	},
});
