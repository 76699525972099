import { StoreAction } from './../../interfaces/redux.interface';
import { ThunkedAction } from '../../interfaces/redux.interface';
import * as dateTimeActions from '../constants/dateTimeActions';
import httpService from '../../services/http.service';

export const dateTimeRequest = (): StoreAction<undefined> => ({
	type: dateTimeActions.CURRENT_DATE_TIME_REQUEST,
	payload: undefined,
});

export const dateTimeFailure = (payload: string): StoreAction<string> => ({
	type: dateTimeActions.CURRENT_DATE_TIME_FAILURE,
	payload,
});

export const dateTimeSuccess = (payload: string): StoreAction<any> => ({
	type: dateTimeActions.CURRENT_DATE_TIME_SUCCESS,
	payload,
});

export const getCurrentDateTime = (): ThunkedAction => dispatch => {
	dispatch(dateTimeRequest());
	httpService
		.api({ type: 'getCurrentDateTime' })
		.then((res: any) => {
			dispatch(dateTimeSuccess(res));
		})
		.catch(err => {
			dispatch(dateTimeFailure(err));
		});
};
