import { compact } from 'lodash';
import React from 'react';
import * as yup from 'yup';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import colors from '../../../../../config/colors';
import AlertModal from '../../../../../modals/AlertModal/AlertModal.component';
import { i18nService } from '../../../../../services/i18n.service';
import { modalService } from '../../../../../services/modal.service';
import { hasPermissionOnBusinessUnit } from '../../../../../utils/globals.utils';
import { onDeleteRole } from '../../createUpdateUser.utils';

export const getFields = (
	departmentOptions: { id: any; name: any }[],
	isMobile: boolean,
	isEditUser: boolean,
	disabled: boolean,
	unitId: number,
	has113Module: boolean,
	disableAll: boolean,
	showEmployeeType: boolean,
	unitData: any,
	setRoles: Function,
) => {
	const canEditUser = hasPermissionOnBusinessUnit(
		unitId,
		'ROLE_CREATE_USER_WRITE',
	);

	return compact([
		showEmployeeType && {
			type: 'radio',
			name: 'employee_type',
			editable: !disabled && !isEditUser && canEditUser && !disableAll,
			options: employeeTypeOptions,
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 265,
					marginBottom: isMobile ? 20 : 0,
					marginLeft: isMobile ? 0 : 23,
				},
			},
			buttonStyles: {
				button: {
					height: 40,
					borderRadius: 20,
					borderWidth: 2,
					borderColor: colors.filterBorder,
				},
				buttonsGroup: {
					alignItems: 'center',
					height: '100%',
				},
			},
			forceMobile: true,
			onChange: (
				setValue: Function,
				getValues: Function,
				selectedValue: any,
			) => {
				if (
					selectedValue === 'INTERNAL' &&
					departmentOptions.length === 1
				) {
					setValue('department_id', departmentOptions[0].id);
				} else if (
					selectedValue === 'EXTERNAL' &&
					unitData.user_roles?.includes(6)
				) {
					if (unitData.user_roles.length > 1) {
						setValue('department_id', null);
						onDeleteRole({
							roleId: 6,
							unitId,
							roles: unitData,
							setRoles,
						});
					} else {
						modalService.openModal(
							null,
							{
								submitBtnText: 'general.close',
								iconName: 'attention',
							},
							(props: any) => (
								<AlertModal {...props}>
									<I18n>
										editUserModal.employeeType.error
									</I18n>
								</AlertModal>
							),
						);
						setValue('employee_type', 'INTERNAL');
					}
				} else {
					setValue('department_id', null);
				}
			},
		},
		departmentOptions.length > 1 && {
			type: 'reactselect',
			name: 'department_id',
			options: departmentOptions,
			maxMenuHeight: 200,
			isRequired: true,
			disabled:
				disabled ||
				(isEditUser && (!canEditUser || has113Module)) ||
				disableAll,
			label: 'editUserModal.userDetails.department',
			shouldDisplay: () => ({
				watch: ['employee_type'],
				callback: (values: any) => values[0] !== 'EXTERNAL',
			}),
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 320,
					marginBottom: isMobile ? 20 : 0,
				},
				labelWrapper: {
					flexDirection: 'row',
				},
			},
			width: isMobile && '100%',
		},
	]);
};

export const employeeTypeOptions = [
	{
		id: 'INTERNAL',
		name: i18nService.translate(
			'editUserModal.userDetails.internalEmployee',
		),
	},
	{
		id: 'EXTERNAL',
		name: i18nService.translate(
			'editUserModal.userDetails.externalEmployee',
		),
	},
];

export const unitDataValidation = yup.object().shape({
	department_id: yup
		.string()
		.when('employee_type', (employee_type: string, schema: any) => {
			if (employee_type === 'INTERNAL') {
				return schema.required('general.validation.required');
			}

			return;
		})
		.nullable(),
	employee_type: yup.string().required(),
});
