import AsyncMultiSelect from './AsyncMultiSelect.component';
import React, { useCallback, useMemo, useState, useRef } from 'react';
import _ from 'lodash';
import { i18nService } from '../../../services/i18n.service';

interface AsyncMultiSelectContainerProps {
	config: {
		loadOptions: any;
		placeholder?: string;
		name: string;
		defaultLabel?: string;
		options?: any;
		value?: any;
		disabled?: boolean;
		additional?: {
			page: number;
		};
		styles?: any;
		isAllValues?: boolean;
	};
	onChange: any;
	shouldCheckNew?: boolean;
	styles?: any;
	noSelection?: any;
	menuIsOpen?: boolean;
	forceShowResults?: boolean;
	disabled?: boolean;
	resetActiveSortByProps?: [];
	isOptionDisabled?: Function;
	key?: string; // this field is needed to force the component to re-render, for example,
	// if the filter options depend on other filters
	// Don't memoize this value!
}

interface SelectedOptions {
	label: string;
	value: string;
}

const AsyncMultiSelectContainer = ({
	config,
	onChange,
	key,
	shouldCheckNew,
	resetActiveSortByProps = [],
	...restProps
}: AsyncMultiSelectContainerProps) => {
	const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>(
		config.value || [],
	);

	const [showList, setShowList] = useState(false);
	const [theList, setTheList] = useState([]);
	const [total, setTotal] = useState(null);
	const excludeMode = useRef<boolean | null>(null);

	const onShowHideListHandler = useCallback(() => {
		setShowList(!showList);
	}, [showList]);

	const [prevOptions, setPrevOptions] = useState<SelectedOptions[]>([]);

	useMemo(() => {
		if (resetActiveSortByProps.length > 0) {
			setSelectedOptions([]);
		}
	}, resetActiveSortByProps);

	const transformSelectedOptions = (arr: SelectedOptions[]) => {
		return arr.map(selectedOption => {
			return {
				id: selectedOption.value || selectedOption,
				name: selectedOption.label,
				...(selectedOption.extra
					? { extra: selectedOption.extra }
					: {}),
			};
		});
	};

	const selectionsChangeHandler = (selections: any, selectRef: any) => {
		let tempSelectedOptions: any = [];
		let prevHasAll = false;

		const isSelectionsContainAllOpt =
			selectedOptions?.findIndex((item: any) => item.value === 'all') >=
			0;
		//select all on All option click
		if (
			!isSelectionsContainAllOpt &&
			selections.some((v: any) => v?.value === 'all')
		) {
			const allOptionSelected = selectRef.current?.props.options;
			tempSelectedOptions = [...allOptionSelected];
			onShowHideListHandler();
			excludeMode.current = false;
			//unselect all on All option click
		} else if (
			prevOptions.length &&
			prevOptions.findIndex(
				(prevSelectedOpt: any) => prevSelectedOpt.value === 'all',
			) >= 0 &&
			selections.findIndex(
				(currSelect: any) => currSelect.value === 'all',
			) < 0
		) {
			tempSelectedOptions = [];
			prevHasAll = true;
			excludeMode.current = null;
		} else {
			tempSelectedOptions = selections.filter(
				(sel: any) => sel.value !== 'all',
			);

			if (excludeMode.current !== null) {
				excludeMode.current = true;
			}
		}

		const unselectedValues =
			shouldCheckNew &&
			selectRef.current?.props.options &&
			_.differenceBy(
				selectRef.current?.props.options,
				tempSelectedOptions,
				'value',
			);
		setPrevOptions(tempSelectedOptions);
		setSelectedOptions(tempSelectedOptions);

		onChange({
			name: config.name,
			value: transformSelectedOptions(tempSelectedOptions),
			unselectedValues:
				unselectedValues && transformSelectedOptions(unselectedValues),
			prevHasAll,
			excludeMode,
			theList,
			isMulti: true,
		});
	};

	const loadOptions = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res = await config.loadOptions(search, loadedOptions, {
			page,
		});
		setTotal(res.total);
		setTheList(res.options);
		if (selectedOptions.length) {
			if (selectedOptions[0]?.value === 'all' || excludeMode.current) {
				setSelectedOptions(prev => [...prev, ...res.options]);
			} else {
				const tempSelected = selectedOptions.map(opt =>
					opt?.value
						? opt
						: res.options.find((o: any) => o.value === opt) || opt,
				);
				if (
					JSON.stringify(selectedOptions) !==
					JSON.stringify(tempSelected)
				) {
					setSelectedOptions(tempSelected);
				}
			}
		}

		if (shouldCheckNew) {
			setPrevOptions([...selectedOptions, ...res.options]);
			setSelectedOptions([...selectedOptions, ...res.options]);
			// onChange({
			// 	name: config.name,
			// 	value: transformSelectedOptions([
			// 		...selectedOptions,
			// 		...res.options,
			// 	]),
			// 	isMulti: true,
			// });
		}
		if (config.isAllValues && page === 0 && res.options.length > 0) {
			const all = [
				{
					value: 'all',
					label: i18nService.translate('general.all'),
				},
			];

			const allList = [...all, ...res.options];
			res.options = allList;
		}
		return res;
	};

	return (
		<AsyncMultiSelect
			onChangeCheckBox={selectionsChangeHandler}
			onShowHideList={onShowHideListHandler}
			selectedOptions={selectedOptions}
			config={config}
			key={key}
			{...restProps}
			loadOptions={loadOptions}
			total={total}
			excludeMode={excludeMode.current}
		/>
	);
};

export default AsyncMultiSelectContainer;
