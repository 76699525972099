import React, { useMemo, useState } from 'react';
import {
	View,
	useWindowDimensions,
	FlatList,
	Platform,
	StyleSheet,
	Dimensions,
	TouchableHighlight,
} from 'react-native';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import Select from '../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import FiltersDrawer from '../../../components/shared/FiltersDrawer/FiltersDrawer';
import HeaderContainer from '../../../components/shared/Header/Header.container';
import MobileHeader from '../../../components/shared/Header/MobileHeader.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import NoResults from '../../../components/shared/NoResults/NoResults';
import SearchInputContainer from '../../../components/shared/SearchInput/SearchInput.container';
import Typography from '../../../components/shared/Typography/Typography.component';
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import RepCustomerItem from './RepCustomerItem.component.native';

const RepCustomers = ({
	navigation,
	filtersConfig,
	getMoreData,
	tableData,
	onFilter,
	onSearch,
	onRowClick,
	customerId,
	canEditCustomer,
	selectedFilters,
}: any) => {
	const dimensions = useWindowDimensions();
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

	const config = useMemo(
		() => filtersConfig.filter((d: any) => 'repCustomerIds' !== d.name),
		[filtersConfig],
	);

	const filterStatusIsActive = useMemo(
		() =>
			selectedFilters &&
			Object.keys(selectedFilters).find(
				(key: string) =>
					selectedFilters[key].length &&
					key !== 'repCustomerIds' &&
					config.find((d: any) => d.name === key),
			),
		[selectedFilters, config],
	);

	return (
		<FiltersDrawer
			isOpen={isSubMenuOpen}
			onToggleSubMenu={setIsSubMenuOpen}
			config={config}
			onFilter={onFilter}
			selectedFilters={selectedFilters}
		>
			<HeaderContainer navigation={navigation} component={MobileHeader} />
			<View style={{ marginRight: 16, marginBottom: 20 }}>
				<I18n weight='normal' size={28}>
					repCustomersPage.title
				</I18n>
			</View>
			<View nativeID='#searchWrapper' style={styles.searchWrapper}>
				<SearchInputContainer
					onChange={onSearch}
					placeholder={i18nService.translate(
						'filter.multiselect.customers',
					)}
					config={{}}
					styleConf={{ container: { flex: 1 } }}
				/>
				<View style={styles.filterSortingSelectButtons}>
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => {
							setIsSubMenuOpen(true);
						}}
					>
						{!filterStatusIsActive ? (
							<ImageIcon name='filter' width={20} height={20} />
						) : (
							<ImageIcon
								name='filterActive'
								width={20}
								height={20}
							/>
						)}
					</TouchableHighlight>
				</View>
			</View>
			{/* TODO - VERSION 2.0 REQUEST */}
			{/* {canEditCustomer && (
				<View style={styles.buttonsLayerContainer}>
					<SolidButtonContainer
						overrideStyle={{ marginLeft: 10 }}
						onPress={() => navigation.navigate('editCustomer')}
					>
						<I18n color='white'>repCustomersPage.addCustomer</I18n>
					</SolidButtonContainer>
				</View>
			)} */}
			{!Boolean(tableData.data?.length) ? (
				<NoResults title='repCustomersPage.noCustomers' />
			) : (
				<View style={styles.dataContainer}>
					<View
						style={{
							flexDirection: 'row-reverse',
							alignSelf: 'flex-start',
							marginHorizontal: 10,
						}}
					>
						<I18n size={14}>general.numberOfRecords</I18n>
						<Typography size={14} weight='normal'>
							{` ${tableData.total || '0'} `}
						</Typography>
					</View>
					<FlatList
						data={tableData?.data}
						renderItem={data => (
							<RepCustomerItem
								{...{
									customerId,
									...data,
									onRowClick,
									canEditCustomer,
								}}
							/>
						)}
						style={{
							height:
								dimensions.height -
								(Platform.OS === 'web' ? 250 : 300),
						}}
						contentContainerStyle={{ paddingBottom: 110 }}
						onEndReached={
							tableData.hasNext ? getMoreData : undefined
						}
						keyExtractor={item => `${item.numericId}`}
					/>
				</View>
			)}
		</FiltersDrawer>
	);
};

const styles = StyleSheet.create({
	title: {
		marginLeft: 8,
	},
	searchWrapper: {
		display: 'flex',
		// alignItems: 'flex-end',
		paddingRight: 10,
		paddingLeft: 10,
		flexDirection: 'row-reverse',
	},
	buttonsLayerContainer: {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	filterSortingSelectButtons: {
		marginHorizontal: 10,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dataContainer: {
		paddingTop: 10,
		paddingBottom: 70,
		height: 'auto',
	},
});

export default RepCustomers;
