import React from 'react';
import { View, StyleSheet } from 'react-native';
import { FAB } from 'react-native-paper';

import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';
import HideOnKeyboardOpen from '../../../../components/shared/HideOnKeyboardOpen/HideOnKeyboardOpen.component';
import I18n from '../../../../components/shared/i18n/I18n.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import EditCustomer from './EditCustomer.component';
import { useNavigation } from '@react-navigation/native';
import { goBack } from '../../../../utils/goBack.utils';
import { useFormContext } from 'react-hook-form';

const EditCustomerWrapper = ({
	onSubmit,
	onCancel,
	disabledSubmit,
	id,
	...rest
}: any) => {
	const { formState } = useFormContext();
	const navigation = useNavigation();

	return (
		<View style={styles.wrapper}>
			<TouchableOpacity onPress={() => goBack(navigation)}>
				<View style={styles.header}>
					<I18n size={20} weight='400' style={{}}>
						repCustomersPage.title
					</I18n>

					<ImageIcon name='leftArrow' />
				</View>
			</TouchableOpacity>
			<View style={{ alignItems: 'center' }}>
				<I18n size={28} weight='normal' style={{ marginTop: 18 }}>
					{`editCustomer.title${id ? 'Edit' : 'Create'}`}
				</I18n>
			</View>
			<EditCustomer {...rest} id={id} />
			<HideOnKeyboardOpen>
				<FAB
					style={styles.cancel}
					small
					color={colors.lightPrimary}
					icon=''
					label={i18nService.translate('general.cancel')}
					onPress={onCancel}
				/>
			</HideOnKeyboardOpen>
			<HideOnKeyboardOpen>
				<FAB
					style={StyleSheet.flatten([
						styles.save,
						{ opacity: disabledSubmit ? 0.7 : 1 },
					])}
					small
					color={colors.white}
					icon=''
					label={i18nService.translate('general.save')}
					onPress={onSubmit}
					disabled={!formState.isDirty}
				/>
			</HideOnKeyboardOpen>
		</View>
	);
};

const styles = StyleSheet.create({
	save: {
		position: 'absolute',
		margin: 16,
		width: 100,
		left: 10,
		bottom: 15,
		borderColor: colors.primary,
		backgroundColor: colors.primary,
		borderWidth: 1,
	},
	cancel: {
		left: 130,
		position: 'absolute',
		margin: 16,
		width: 100,
		bottom: 15,
		borderColor: colors.lightPrimary,
		backgroundColor: colors.white,
		borderWidth: 1,
	},
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingHorizontal: 16,
	},
	wrapper: {
		flex: 1,
		backgroundColor: colors.white,
		opacity: 1,
		paddingVertical: 25,
	},
});

export default EditCustomerWrapper;
