const WageComponent = (
	data: any,
	componentList: any = [],
	addNew?: boolean,
) => {
	const initialValues: any = {};
	if (addNew) {
		// Add new line to the form
		return {
			[data.code]: {
				header: [
					{
						name: data.code,
						value: data.name,
						title: `${data.code} - ${data.name}`,
						type: 'text',
					},
				],

				currentMonth: [
					{
						name: `${data.code}_neto`,
						value: 'FromMonthToMonthReports.formats.WageComponent.gross',
						type: 'text',
					},
					{
						name: `${data.code}_amount`,
						value: 0,
						type: 'input',
						placeholder: '0.00',
					},
					{
						name: `${data.code}_rate`,
						value: 0,
						type: 'input',
						placeholder: '0.00',
					},
					{
						name: `${data.code}_sum`,
						value: 0,
						type: 'input',
						placeholder: '0.00',
					},
				],

				lastMonth: [
					{
						name: `${data.code}_neto_last`,
						value: '',
						type: 'text',
					},
					{
						name: `${data.code}_amount_last`,
						value: '',
						type: 'input',
						placeholder: '0.00',
					},
					{
						name: `${data.code}_rate_last`,
						value: '',
						type: 'input',
						placeholder: '0.00',
					},
					{
						name: `${data.code}_sum_last`,
						value: '',
						type: 'input',
						placeholder: '0.00',
					},
				],
			},
		};
	}
	// Table Header
	const format: any = {};
	format.wageComponent = {
		header: [
			{
				name: 'wageComponent',
				value: 'FromMonthToMonthReports.formats.WageComponent.header',
				addNew: true,
				type: 'header',
			},
		],

		currentMonth: [
			{
				name: 'neto',
				value: 'FromMonthToMonthReports.formats.WageComponent.netGross',
				type: 'header',
			},
			{
				name: 'amount',
				value: 'FromMonthToMonthReports.formats.WageComponent.amount',
				type: 'header',
			},
			{
				name: 'rate',
				value: 'FromMonthToMonthReports.formats.WageComponent.rate',
				type: 'header',
			},
			{
				name: 'sum',
				value: 'FromMonthToMonthReports.formats.WageComponent.sum',
				type: 'header',
			},
		],

		lastMonth: [
			{
				name: 'neto_last',
				value: 'FromMonthToMonthReports.formats.WageComponent.netGross',
				type: 'header',
			},
			{
				name: 'amount_last',
				value: 'FromMonthToMonthReports.formats.WageComponent.amount',
				type: 'header',
			},
			{
				name: 'rate_last',
				value: 'FromMonthToMonthReports.formats.WageComponent.rate',
				type: 'header',
			},
			{
				name: 'sum_last',
				value: 'FromMonthToMonthReports.formats.WageComponent.sum',
				type: 'header',
			},
		],
	};
	if (data.totalComponents.wageComponent.length > 0) {
		// table body (lines) data
		data.totalComponents.wageComponent.forEach((element: any) => {
			initialValues[`${element.newCode || element.code}_amount`] =
				getElementValue(
					data.draft,
					element.newCode || element.code,
					'amount',
				);
			initialValues[`${element.newCode || element.code}_rate`] =
				getElementValue(
					data.draft,
					element.newCode || element.code,
					'rate',
				);
			initialValues[`${element.newCode || element.code}_sum`] =
				getElementValue(
					data.draft,
					element.newCode || element.code,
					'value',
				);

			format[element.newCode || element.code] = {
				header: [
					{
						name: element.newCode || element.code,
						value: element.name,
						title: `${element.code} - ${getNameByCode(
							element.code,
							element.name,
							componentList,
						)}`,
						type: 'text',
					},
				],

				currentMonth: [
					{
						name: `${element.newCode || element.code}_neto`,
						value: getElementValue(
							data.draft,
							element.newCode || element.code,
							'net',
						),
						type: 'text',
					},
					{
						name: `${element.newCode || element.code}_amount`,
						value: getElementValue(
							data.draft,
							element.newCode || element.code,
							'amount',
						),
						type: getTypeByComponent(componentList, element.code),
						placeholder: '0.00',
					},
					{
						name: `${element.newCode || element.code}_rate`,
						value: getElementValue(
							data.draft,
							element.newCode || element.code,
							'rate',
						),
						type: getTypeByComponent(componentList, element.code),
						placeholder: '0.00',
					},
					{
						name: `${element.newCode || element.code}_sum`,
						value: getElementValue(
							data.draft,
							element.newCode || element.code,
							'value',
						),
						type: getTypeByComponent(componentList, element.code),
						placeholder: '0.00',
					},
				],

				lastMonth: data.paycheck.map((el: any, index: number) => {
					return [
						{
							name: `${element.code}_neto_last_${el.yearMonth}`,
							value: getLastElementValue(
								data.paycheck,
								element.newCode || element.code,
								'net',
								index,
							),
							type: 'text',
						},
						{
							name: `${element.code}_amount_last_${el.yearMonth}`,
							value: getLastElementValue(
								data.paycheck,
								element.newCode || element.code,
								'amount',
								index,
							),
							type: 'input',
							placeholder: '0.00',
						},
						{
							name: `${element.code}_rate_last_${el.yearMonth}`,
							value: getLastElementValue(
								data.paycheck,
								element.newCode || element.code,
								'rate',
								index,
							),
							type: 'input',
							placeholder: '0.00',
						},
						{
							name: `${element.code}_sum_last_${el.yearMonth}`,
							value: getLastElementValue(
								data.paycheck,
								element.newCode || element.code,
								'value',
								index,
							),
							type: 'input',
							placeholder: '0.00',
						},
					];
				}),
			};
		});
	}
	return { format, initialValues };
};

const getElementValue = (data: any, code: string, val: string): string => {
	if (
		!data ||
		data.length === 0 ||
		data.length === 0 ||
		data.wageComponent.length === 0
	) {
		return '';
	}
	const lastName = data.wageComponent.find((item: any) => {
		return (item.newCode || item.code) === code;
	});
	if (val === 'net') {
		return lastName?.[val]
			? 'FromMonthToMonthReports.formats.WageComponent.net'
			: 'FromMonthToMonthReports.formats.WageComponent.gross';
	}
	return lastName?.[val] || '';
};

const getNameByCode = (code: string, name: string, list?: any): string => {
	if (list.length === 0) return name;
	const newName = list.find((c: any) => c.code === code)?.name;
	return newName ? newName : name;
};

const getTypeByComponent = (list: any, code: string): string => {
	if (list.length === 0) return 'text';
	const showEdit = list.find((c: any) => c.code === code)?.showEdit;
	return showEdit === 2 ? 'input' : 'text';
};

const getLastElementValue = (
	data: any,
	code: string,
	val: string,
	index: number,
): string => {
	if (
		!data ||
		data.length === 0 ||
		data[index].length === 0 ||
		data[index].wageComponent.length === 0
	) {
		return '';
	}
	const lastName = data[index].wageComponent.find((item: any) => {
		return (item.newCode || item.code) === code;
	});
	if (val === 'net') {
		return lastName?.[val]
			? 'FromMonthToMonthReports.formats.WageComponent.net'
			: 'FromMonthToMonthReports.formats.WageComponent.gross';
	}
	if (val === 'value') {
		if (!lastName?.rate && !lastName?.amount) {
			lastName?.[val];
		} else {
			return '';
		}
	}

	return lastName?.[val] || '';
};

export default WageComponent;
