import moment from 'moment';
import {
	dateTimeSuccess,
	getCurrentDateTime,
} from '../store/actions/dateTime.actions';
import { dispatch, getState } from '../store/store';

class TimeTimerService {
	timer: any;
	isActive: boolean = false;
	public startTimer() {
		this.isActive = true;
		dispatch(getCurrentDateTime());
		this.timer = setInterval(() => {
			dispatch(
				dateTimeSuccess(
					moment(getState().dateTime.currentDateTime)
						.add(30, 'seconds')
						.format(),
				),
			);
		}, 30000);
	}

	public clearTimer() {
		this.isActive = false;
		clearInterval(this.timer);
	}

	public restartTimer() {
		clearInterval(this.timer);
		this.startTimer();
	}
}

const timeTimerService = new TimeTimerService();
export default timeTimerService;
