import { FontAwesome } from '@expo/vector-icons';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { TouchableHighlight } from 'react-native';
import { Badge } from 'react-native-paper';
import colors from '../../../config/colors';
import Typography, { TextProps } from '../Typography/Typography.component';

interface ReportDayProps {
	day: number;
	reportedTime: string;
	entrances?: number;
	exits?: number;
	isReportAcceptable?: boolean;
	isDaySelected?: boolean;
	isDisabled?: boolean;
	isCurrentDate?: boolean;
	dateTitle?: string;
	onPress?: () => void;
	showAsDisabled?: boolean;
	notDisplayTotalHours?: boolean;
}

const ReportsCalendarDay = ({
	day,
	reportedTime,
	entrances,
	exits,
	isDisabled = false,
	isDaySelected = false,
	isCurrentDate = false,
	isReportAcceptable,
	dateTitle,
	showAsDisabled,
	onPress = () => {},
	notDisplayTotalHours,
}: ReportDayProps) => {
	const windowHeight = useWindowDimensions().height;
	const [dateTypographyOptions, setDateTypographyOptions] = useState<
		Partial<TextProps>
	>();

	useEffect(() => {
		setDateTypographyOptions({
			color: isCurrentDate || isDaySelected ? 'white' : 'darkGrey',
			weight: isDisabled || showAsDisabled ? '400' : 'normal',
		});
	}, [isDisabled, isCurrentDate, isDaySelected, showAsDisabled]);

	const dateContainerStyle = StyleSheet.flatten([
		styles.dateContainer,
		isDisabled || showAsDisabled ? styles.disabledContainer : {},
		isCurrentDate ? styles.currentDayContainer : {},
		isDaySelected ? styles.selectedDay : {},
	]);

	const rootS = StyleSheet.flatten([
		styles.root,
		windowHeight >= 1000 ? { height: 86 } : { height: 56 },
	]);
	return (
		<View style={rootS}>
			<Typography size={10} style={styles.dateTitle}>
				{dateTitle}
			</Typography>
			<TouchableHighlight
				disabled={isDisabled}
				underlayColor='transparent'
				onPress={onPress}
			>
				<View style={dateContainerStyle}>
					{typeof isReportAcceptable === 'boolean' && (
						<Badge
							size={14}
							visible={!isReportAcceptable}
							style={styles.badge}
						>
							!
						</Badge>
					)}
					<Typography {...dateTypographyOptions}>{day}</Typography>
					<Typography
						color={
							isDaySelected
								? 'white'
								: isReportAcceptable
								? 'success'
								: 'failure'
						}
						style={styles.reportedTime}
						weight='normal'
						size={8}
					>
						{notDisplayTotalHours ? '' : reportedTime}
					</Typography>
				</View>
			</TouchableHighlight>
			<View style={styles.reportsCountContainer}>
				{!!exits ? (
					<>
						<FontAwesome name='sign-out' style={styles.signOut} />
						<Typography size={10}>{exits}</Typography>
					</>
				) : null}
				{!!entrances ? (
					<>
						<FontAwesome name='sign-in' style={styles.signIn} />
						<Typography size={10}>{entrances}</Typography>
					</>
				) : null}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		height: 86,
		width: 44,
		marginHorizontal: 8,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dateContainer: {
		position: 'relative',
		backgroundColor: colors.lightBlue,
		height: 44,
		width: 44,
		borderRadius: 8,
		justifyContent: 'center',
		alignItems: 'center',
	},
	dateTitle: {
		textAlign: 'center',
		height: 24,
	},
	disabledContainer: {
		backgroundColor: colors.white,
		borderColor: colors.lightBlue,
		borderWidth: 1,
	},
	selectedDay: {
		backgroundColor: colors.lightPrimary,
	},
	currentDayContainer: {
		backgroundColor: colors.primary,
	},
	badge: {
		position: 'absolute',
		top: -8,
		right: -8,
		fontSize: 12,
		fontWeight: 'bold',
		backgroundColor: colors.failure,
	},
	reportedTime: {
		position: 'absolute',
		bottom: 2,
	},
	reportsCountContainer: {
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		width: '100%',
		height: 12,
		marginTop: 8,
	},
	signIn: {
		fontSize: 10,
		color: colors.lightPrimary,
	},
	signOut: {
		fontSize: 10,
		color: colors.warning,
		transform: [{ rotateY: '180deg' }],
	},
});

export default ReportsCalendarDay;
