import React, { useRef, useState } from 'react';
import { View, StyleSheet, ScrollView, TouchableHighlight } from 'react-native';
import Typography from '../Typography/Typography.component';
import colors from '../../../config/colors';
import moment from 'moment';
import getShadowStyle from '../../../config/shadow';
import { FontAwesome } from '@expo/vector-icons';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import I18n from '../i18n/I18n.component';
import { ListType } from '../../../interfaces/core.interface';
import { Employee101YearStatus } from '../../../pages/ReportPDFPage/ReportPDFPage.utils';

interface ReportRangeListProps {
	list: string[] | Employee101YearStatus[];
	selectedReport: string | Employee101YearStatus;
	onSelectReportDate: (date: string | Employee101YearStatus) => void;
	title?: string;
	listType: ListType;
}

const monthsMap: any = {
	january: 'ינואר',
	february: 'פברואר',
	march: 'מרץ',
	april: 'אפריל',
	may: 'מאי',
	june: 'יוני',
	july: 'יולי',
	august: 'אוגוסט',
	september: 'ספטמבר',
	october: 'אוקטובר',
	november: 'נובמבר',
	december: 'דצמבר',
};

const ReportRangeList = ({
	title,
	list,
	listType,
	selectedReport,
	onSelectReportDate,
}: ReportRangeListProps) => {
	const scrollableSections = useRef<any>(null);
	const isWeb = useDevicePlatform() === DeviceType.WEB;
	const [scrollCount, setScrollCount] = useState(0);

	const scrollUp = () => {
		const scrollToPx = scrollCount > 0 ? scrollCount - 100 : 0;
		scrollableSections.current.scrollTo({
			x: scrollToPx,
			y: scrollToPx,
			animated: 1,
		});
		setScrollCount(scrollToPx);
	};

	const scrollDown = () => {
		const parentView =
			scrollableSections?.current?._innerViewRef?.parentElement;
		if (
			parentView?.scrollHeight - parentView?.offsetHeight <=
			parentView?.scrollTop
		) {
			return;
		}
		const scrollToPx = scrollCount + 100;
		scrollableSections.current.scrollTo({
			x: scrollToPx,
			y: scrollToPx,
			animated: 1,
		});
		setScrollCount(scrollToPx);
	};

	const mainContainerStyle = StyleSheet.flatten([styles.mainContainer]);

	const listTitleStyle = StyleSheet.flatten([styles.listTitleWeb]);

	return (
		<View
			style={mainContainerStyle}
			nativeID='reportRangeListMainContainer'
		>
			<View style={listTitleStyle}>
				<I18n
					style={{
						marginBottom: isWeb ? 25 : 21.5,
						textAlign: 'right',
					}}
					size={isWeb ? 18 : 20}
					weight={isWeb ? '400' : 'normal'}
					color='darkGrey'
				>
					{title}
				</I18n>
				{!isWeb && <View style={styles.separator} />}
			</View>
			{isWeb ? (
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => scrollUp()}
				>
					<FontAwesome style={styles.arrow} name='chevron-up' />
				</TouchableHighlight>
			) : null}
			<ScrollView
				showsVerticalScrollIndicator={false}
				contentContainerStyle={{
					...styles.container,
					height: 60 * (list?.length + (!isWeb ? 1 : 0)),
					paddingHorizontal: 18,
				}}
				nativeID='reportRangeListScrollView'
				scrollEventThrottle={0.6}
				decelerationRate='normal'
				ref={ref => {
					scrollableSections.current = ref;
				}}
			>
				{list?.length > 0 &&
					list.map((item, idx) => {
						return (
							<TouchableHighlight
								key={
									listType === 'status'
										? `${Object.entries(item)}`
										: `${item}`
								}
								underlayColor='transparent'
								onPress={() => onSelectReportDate(item)}
							>
								<View
									key={idx}
									style={StyleSheet.flatten([
										styles.item,
										listType === 'status'
											? { justifyContent: 'flex-end' }
											: {},
										selectedReport === item ||
										(listType === 'status' &&
											selectedReport?.id === item?.id)
											? styles.selectedItem
											: {},
										{ width: isWeb ? 227 : 335 },
									])}
								>
									{listType === 'status' ? (
										<>
											<Typography
												style={styles.status}
												color={
													selectedReport?.id ===
													item?.id
														? 'white'
														: 'primary'
												}
												weight='400'
												size={14}
											>
												{`${item.status}`}
											</Typography>
											<Typography
												style={styles.status}
												color={
													selectedReport?.id ===
													item?.id
														? 'white'
														: 'primary'
												}
												weight='normal'
												size={20}
											>
												{`${item.taxYear}`}
											</Typography>
										</>
									) : (
										<Typography
											color={
												selectedReport === item
													? 'white'
													: 'primary'
											}
											weight={
												listType === 'months'
													? '400'
													: 'normal'
											}
											size={
												listType === 'months' ? 14 : 20
											}
										>
											{moment(item).format('YYYY')}
										</Typography>
									)}

									{listType === 'months' ? (
										<Typography
											style={styles.month}
											color={
												selectedReport === item
													? 'white'
													: 'primary'
											}
											weight='normal'
											size={20}
										>
											{
												monthsMap[
													moment(item)
														.format('MMMM')
														.toLowerCase()
												]
											}
										</Typography>
									) : null}
								</View>
							</TouchableHighlight>
						);
					})}
			</ScrollView>
			{isWeb ? (
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => scrollDown()}
				>
					<FontAwesome style={styles.arrow} name='chevron-down' />
				</TouchableHighlight>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		backgroundColor: colors.white,
	},
	container: {
		alignItems: 'center',
		width: 360,
		backgroundColor: colors.white,
	},
	listTitleWeb: {
		width: '100%',
		textAlign: 'right',
		paddingRight: 20,
		paddingTop: 16,
	},
	item: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 50,
		marginBottom: 10,
		backgroundColor: colors.white,
		borderRadius: 11,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	month: {
		marginLeft: 10,
	},
	status: {
		marginRight: 10,
	},
	selectedItem: {
		backgroundColor: colors.primary,
	},
	arrow: {
		fontSize: 16,
		width: '100%',
		color: colors.primary,
		paddingVertical: 14,
	},
	separator: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
		marginBottom: 16.5,
		marginLeft: 20,
	},
});

export default ReportRangeList;
