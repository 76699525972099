import React, { useEffect, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	FlatList,
	useWindowDimensions,
	Platform,
} from 'react-native';

import HeaderContainer from '../../components/shared/Header/Header.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import { SingleConfig } from '../../components/web/Filters/Filters.component';
import { FilterData } from '../../components/web/Filters/Filters.container';
import SearchInputContainer from '../../components/shared/SearchInput/SearchInput.container';
import {
	isFilterActive,
	Report101FiltersDataType,
} from './Report101Page.utils';
import MobileItem from './ReportMobileItem/ReportMobileItem.component';
import Report101MobileDrawer from './Report101PageMobile.drawer';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import { dispatch } from '../../store/store';
import { setHideChat } from '../../store/actions/config.actions';
import ActionButtonsMenu from './ActionButtonsMenu';
import Typography from '../../components/shared/Typography/Typography.component';

const Report101PageMobile = ({
	data,
	navigation,
	onFilter,
	filtersData,
	selectedFilters,
	onDisselectAllReports,
	onSelectRowCheckboxPressed,
	isCheckboxSelected,
	onSelectAllCheckboxReports,
	isSelectAllReportsCheckbox,
	onRowClick,
	onSelectedCancelButtonPressed,
	onApproveSelectedButtonPressed,
	onLoadMore,
	noReports,
	activeActionButtonType,
	setActiveActionButtonType,
	onSendReminderButtonHandler,
	total,
	disabled,
	businessUnitsList,
	form101ModuleActive,
}: {
	data: any[];
	navigation: any;
	onFilter: (data: FilterData) => void;
	filtersData: any;
	selectedFilters: Report101FiltersDataType;
	onDisselectAllReports: () => void;
	onSelectAllCheckboxReports: () => void;
	onSelectRowCheckboxPressed: (value: any) => void;
	isCheckboxSelected: (value: any) => boolean;
	isSelectAllReportsCheckbox: boolean;
	onRowClick: (dataItem: any) => void;
	onSelectedCancelButtonPressed: () => void;
	onLoadMore: Function;
	noReports: boolean;
	activeActionButtonType: any;
	setActiveActionButtonType: any;
	onApproveSelectedButtonPressed: any;
	onSendReminderButtonHandler: any;
	total: number;
	disabled: boolean;
	businessUnitsList: any[];
	form101ModuleActive: boolean;
}) => {
	const dimentions = useWindowDimensions();
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

	useEffect(() => {
		dispatch(setHideChat(isSubMenuOpen));
	}, [isSubMenuOpen]);

	const mobileItemHandler = (item: any) => {
		return (
			<MobileItem
				dataRow={item}
				activeActionButtonType={activeActionButtonType}
				onSelectRowCheckboxPressed={onSelectRowCheckboxPressed}
				isRowSelected={isCheckboxSelected(item?.item?.id)}
				onRowClick={onRowClick}
				businessUnitsList={businessUnitsList}
			/>
		);
	};

	const filterStatusIsActive = isFilterActive(selectedFilters);

	return (
		<Report101MobileDrawer
			isOpen={isSubMenuOpen}
			onToggleSubMenu={setIsSubMenuOpen}
			config={filtersData}
			onFilter={onFilter}
			selectedFilters={selectedFilters}
		>
			<View>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
			</View>

			<View style={{ marginRight: 16, marginBottom: 20 }}>
				<I18n weight='normal' size={28}>
					{'report101ReportPage.mobile.title'}
				</I18n>
			</View>

			<View nativeID='#searchWrapper' style={styles.searchWrapper}>
				<SearchInputContainer
					onChange={onFilter}
					config={
						filtersData.find(
							(singleConf: SingleConfig) =>
								singleConf.type === 'searchInput',
						)!
					}
					styleConf={{ container: { width: '100%' } }}
				/>
			</View>

			<View style={styles.buttonsLayerContainer}>
				{!disabled ? (
					<View>
						<ActionButtonsMenu
							{...{
								activeActionButtonType,
								setActiveActionButtonType,
								onDisselectAllReports,
								onApproveSelectedButtonPressed,
								onSelectedCancelButtonPressed,
								onSendReminderButtonHandler,
								form101ModuleActive,
							}}
							stylesConf={{
								containerIncludesOneBtn: {
									width: 176,
									marginLeft: 10,
								},
								containerIncludesTwoBtn: { width: 260 },
								buttonApprove: { marginLeft: 10 },
							}}
						/>
					</View>
				) : (
					<View />
				)}

				<View style={styles.filterSortingSelectButtons}>
					{!activeActionButtonType && (
						<TouchableHighlight
							underlayColor='transparent'
							onPress={() => {
								setIsSubMenuOpen(true);
							}}
						>
							{!filterStatusIsActive ? (
								<ImageIcon
									name='filter'
									width={20}
									height={20}
								/>
							) : (
								<ImageIcon
									name='filterActive'
									width={20}
									height={20}
								/>
							)}
						</TouchableHighlight>
					)}

					{activeActionButtonType && (
						<>
							{!isSelectAllReportsCheckbox && (
								<TouchableHighlight
									underlayColor='transparent'
									style={{ marginRight: 5 }}
									onPress={onSelectAllCheckboxReports}
								>
									{isSelectAllReportsCheckbox ? (
										<ImageIcon
											name={'checkedMark'}
											width={20}
											height={20}
										/>
									) : (
										<ImageIcon
											name={'checkboxUnselected'}
											width={20}
											height={20}
										/>
									)}
								</TouchableHighlight>
							)}
							{isSelectAllReportsCheckbox && (
								<TouchableHighlight
									underlayColor='transparent'
									style={{ marginRight: 5 }}
									onPress={onSelectAllCheckboxReports}
								>
									<ImageIcon
										name={'checkboxSelected'}
										width={20}
										height={20}
									/>
								</TouchableHighlight>
							)}
						</>
					)}
				</View>
			</View>
			{noReports ? (
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<I18n
						weight='bold'
						size={24}
						style={{ marginTop: 36, marginRight: 26 }}
					>
						report101ReportPage.noReports
					</I18n>
				</View>
			) : (
				<View style={styles.dataContainer}>
					<View
						style={{
							flexDirection: 'row-reverse',
							alignSelf: 'flex-start',
							marginHorizontal: 10,
						}}
					>
						<I18n size={14}>general.numberOfRecords</I18n>
						<Typography size={14} weight='normal'>
							{` ${total || '0'} `}
						</Typography>
					</View>
					<FlatList
						data={data}
						renderItem={mobileItemHandler}
						style={{
							height:
								dimentions.height -
								(Platform.OS === 'web' ? 250 : 260),
						}}
						onEndReached={() => onLoadMore()}
						keyExtractor={item => `${item.id}`}
						contentContainerStyle={{ paddingBottom: 80 }}
					/>
				</View>
			)}
		</Report101MobileDrawer>
	);
};

const styles = StyleSheet.create({
	title: {
		marginLeft: 8,
	},
	searchWrapper: {
		display: 'flex',
		// alignItems: 'flex-end',
		paddingRight: 10,
		paddingLeft: 10,
	},
	buttonsLayerContainer: {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	filterSortingSelectButtons: {
		marginRight: 20,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dataContainer: {
		marginTop: 10,
	},
});

export default Report101PageMobile;
