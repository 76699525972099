import React from 'react';
import { StyleSheet, Image, View } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';

const ActionItem = ({
	color,
	icon,
	buttonStyle,
	tintColor,
	position = 'right',
	textContainerStyle,
	text,
	textStyle,
}: any) => {
	const propStyles = {
		tintColor: tintColor,
		backgroundColor: color,
	};

	return (
		<>
			<View key='button' style={[styles.button, propStyles, buttonStyle]}>
				{React.isValidElement(icon) ? (
					icon
				) : (
					<Image
						style={[styles.icon, { tintColor: tintColor }]}
						source={icon}
					/>
				)}
			</View>
			<View
				key='text'
				style={[
					styles.textContainer,
					styles[`${position}TextContainer`],
					textContainerStyle,
				]}
			>
				<I18n style={[styles.text, textStyle]} weight='normal'>
					{text}
				</I18n>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	textContainer: {
		backgroundColor: colors.white,
		paddingHorizontal: 8,
		elevation: 5,
		borderRadius: 4,
		height: 28,
		width: 92,
		shadowOpacity: 0.35,
		shadowOffset: { width: 0, height: 5 },
		shadowColor: '#000000',
		shadowRadius: 3,
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 10,
	},
	leftTextContainer: {
		marginLeft: 14,
	},
	rightTextContainer: {
		marginRight: 14,
	},
	text: {
		fontSize: 16,
		lineHeight: 20,
		color: colors.darkGrey,
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 70,
		width: 70,
		borderRadius: 35,
		elevation: 5,
		shadowOpacity: 0.35,
		shadowOffset: { width: 0, height: 5 },
		shadowColor: '#000000',
		shadowRadius: 3,
	},
	iconLogo: {
		resizeMode: 'cover',
		width: 40,
		height: 40,
		borderRadius: 20,
	},
	icon: {
		resizeMode: 'contain',
		width: 20,
		height: 20,
	},
});

export default ActionItem;
