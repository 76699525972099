import _ from 'lodash';
import React from 'react';
import I18n from '../../../components/shared/i18n/I18n.component';
import Typography from '../../../components/shared/Typography/Typography.component';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import CommentModal from '../../../modals/CommentModal/EmployeeCommentModal.component';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import httpService from '../../../services/http.service';
import { i18nService } from '../../../services/i18n.service';
import { modalService } from '../../../services/modal.service';
import { fillPdf } from '../WorkerCardPreview/workerCardPreviewBuilder';
import { getTabsMap } from './WorkerCardTabs';

const checkIfTabHasComments = (tab: any, data: any) => {
	if (!data) {
		return;
	}

	if (tab.pageRoute === 'children') {
		if (
			data.comment &&
			data.comment.length &&
			data.comment.find((el: any) => el.fieldName === 'hasChildren')
		) {
			return true;
		}
		if (
			data &&
			data?.children &&
			data?.children?.filter(
				(child: any) => child.comment && child.comment.length,
			).length
		) {
			return true;
		}

		return false;
	}

	if (tab.pageRoute === 'disclaimer') {
		if (
			data.comment &&
			data.comment.length &&
			data.comment.find((el: any) => el.fieldName === 'signature')
		) {
			return true;
		}
		return false;
	}

	return (
		data &&
		data[tab.pageRoute]?.comment &&
		data[tab.pageRoute]?.comment.length
	);
};

export const getFieldComment = (data: any, field: any, screen: string) => {
	if (screen === 'otherEmployerIncome') {
		return data
			? data[screen]?.comment?.find((el: any) => {
					const arr = field.split('.');
					if (arr[1] === el.fieldName) {
						return true;
					}
			  })
			: null;
	} else if (field === 'hasChildren' || field === 'signature') {
		return data && data.comment && data.comment.length
			? data.comment.find((el: any) => el.fieldName === field)
			: null;
	} else {
		return data
			? data[screen]?.comment?.find((el: any) =>
					field.includes(el.fieldName),
			  )
			: null;
	}
};

export const getTabsWithCommentsList = (tabsMap: any, data: any) => {
	const result: Record<string, boolean> = {
		employerDetails: false,
	};

	tabsMap.forEach((tab: any) => {
		if (tab.pageRoute === 'employerDetails') {
			return;
		}
		if (Boolean(checkIfTabHasComments(tab, data))) {
			result[tab.pageRoute] = true;
		}
	});

	return result;
};

export const getCommentsForLeftPanel = (data: any) => {
	if (!data) {
		return [];
	}
	const rootComments: any[] =
		data.comment && data.comment.length ? [...data.comment] : [];

	const fieldComments = Object.keys(data).reduce(
		(result: any[], el: any, i: number) => {
			if (Array.isArray(data[el])) {
				if (el === 'comment') {
					return result;
				}
				const filteredComments = data[el]
					.filter((item: any) => item.comment && item.comment.length)
					.map((elem: any) => elem.comment);

				return filteredComments.length
					? (result = [...result, ...filteredComments].flat())
					: result;
			} else {
				const extraComments =
					data[el] &&
					typeof data[el] === 'object' &&
					Object.keys(data[el]).reduce((res: any[], key: any) => {
						if (Array.isArray(data[el][key])) {
							if (key === 'comment') {
								return res;
							}
							const filteredComments = data[el][key]
								.filter(
									(item: any) =>
										item.comment && item.comment.length,
								)
								.map((elem: any) => elem.comment);

							return filteredComments.length
								? [...res, ...filteredComments].flat()
								: res;
						}
						return res;
					}, []);
				return [
					...result,
					...(data[el]?.comment || []),
					..._.compact(extraComments || []),
				];
			}
		},
		[],
	);

	const comments = [...rootComments, ...fieldComments];

	return comments;
};

export const getRequiredFields = (data: any) => {
	const tabsMap = getTabsMap([]);

	const findRequiredFields = (fields: any, parentName?: string) =>
		fields.reduce((acc: any[], field: any) => {
			if (field.isRequired || field.name === 'signature') {
				return [...acc, { ...field, parentName }];
			}
			if (field.type === 'array') {
				const shouldDisplay = checkIfConditionalFieldIsRequired(
					data,
					field,
				);
				if (shouldDisplay) {
					const fields = _.flattenDeep(field.fields);
					return [...acc, ...findRequiredFields(fields, field.name)];
				}
			}
			return acc;
		}, []);

	const requiredFields = Object.keys(tabsMap).reduce(
		(res: any[], current: string) => {
			const fields = _.flattenDeep(tabsMap[current].fields);
			const filtered = findRequiredFields(fields);
			return [...res, ...filtered];
		},
		[],
	);

	return requiredFields;
};

export const getMissingFields = (data: any) => {
	if (!data || !Object.keys(data).length) {
		return [];
	}

	const requiredFields = getRequiredFields(data);

	const missingFields = requiredFields.reduce((res, field) => {
		const parentName =
			field.parentName === 'additionalIncomeFile'
				? 'taxCreditRequest.additionalIncome'
				: field.parentName;
		if (field.parentName) {
			const fieldsArray =
				_.get(data, parentName)?.reduce(
					(acc: any[], f: any, index: number) => {
						const value =
							field.parentName === 'additionalIncomeFile'
								? f.additionalIncomeFile[0] &&
								  f.additionalIncomeFile[0][field.name]
								: f[field.name];
						const position =
							field.parentName === 'additionalIncomeFile'
								? 0
								: index;
						if ((!value && value !== false) || value === '') {
							return [
								...acc,
								{
									...field,
									parentName,
									position,
									fieldTitle:
										parentName === 'children'
											? `${i18nService.translate(
													'general.child',
											  )} ${index + 1} -`
											: parentName ===
											  'taxCreditRequest.additionalIncome'
											? `${i18nService.translate(
													'general.employer',
											  )} ${index + 1} -${
													field.parentName ===
													'additionalIncomeFile'
														? ` ${field?.label({
																index: 0,
														  })}`
														: ''
											  }`
											: field?.label({ index }),
								},
							];
						}
						return acc;
					},
					[],
				) || [];
			return [...res, ...fieldsArray];
		} else {
			const isRequired = checkIfConditionalFieldIsRequired(data, field);
			const arr = field.name.split('.');
			const objectName = arr[0];
			const fieldName = arr.slice(1).join('.');
			let value;
			if (field.name === 'hasChildren') {
				value = data.hasChildren;
			} else if (arr.includes('address')) {
				value = data[objectName]
					? data[objectName][arr[1]][arr[2]]
					: null;
			} else if (field.name === 'signature') {
				value = data.signature;
			} else {
				value = data[objectName] ? data[objectName][fieldName] : null;
			}

			if (isRequired && (value === undefined || value === null)) {
				return [
					...res,
					field.name === 'signature'
						? {
								...field,
								label: 'workerCard.editor.disclaimer.signature',
								name: 'disclaimer.signature',
						  }
						: field,
				];
			} else {
				return res;
			}
		}
	}, []);

	return missingFields;
};

export const fieldsMap = {
	employee: 'EMPLOYEE',
	ROOT: 'ROOT',
	employer: 'EMPLOYER',
	employee_address: 'EMPLOYEE_ADDRESS',
	children: 'CHILDREN',
	currentEmployerIncome: 'CURRENT_EMPLOYER_INCOME',
	otherEmployerIncome: 'OTHER_EMPLOYER_INCOME',
	spouse: 'SPOUSE',
	taxCreditReason: 'TAX_CREDIT_REASON',
	taxCreditRequest: 'TAX_CREDIT_REQUEST',
	additionalIncome: 'ADDITIONAL_INCOME',
};

export const addNewCommentToExistComments = (
	data: any,
	setData: any,
	tabName: string,
	newComment: any,
) => {
	setData({
		...data,
		[tabName]: {
			...data[tabName],
			comment: [
				...data[tabName].comment,
				{
					...newComment,
				},
			],
		},
	});
};

export const getFancyValue = (fieldName: string, fieldValue: string) => {
	const tabsMap = getTabsMap([]);

	const allFields = tabsMap.reduce((result: any[], el: any) => {
		return [...result, ...el.fields.flat()];
	}, []);

	const field = allFields.find((el: any) => el.name === fieldName);

	if (field?.options && fieldName !== 'employee.cellPrefix') {
		if (Array.isArray(fieldValue)) {
			const values: string[] = [];
			fieldValue.forEach((val: any, i: number, arr: string[]) => {
				values.push(
					i18nService.translate(
						field.options.find((el: any) => el.value === val)
							?.label,
					),
				);
			});
			return values.join(', ');
		}
		return i18nService.translate(
			field.options.find((el: any) => el.value === fieldValue)?.label,
		);
	} else if (fieldName === 'signature') {
		return '';
	}

	return fieldValue;
};

const setCommentsToRoot = (data: any, setData: any, newComment: any) => {
	const comment =
		data.comment && data.comment.length
			? data.comment.find((el: any) => el.id === newComment.id)
			: null;
	if (comment) {
		setData({
			...data,
			comment: data.comment.map((el: any) => {
				if (el.id === newComment.id) {
					return {
						...el,
						comment: newComment.comment,
					};
				}

				return el;
			}),
		});
	} else {
		setData({
			...data,
			comment: data.comment
				? [...data.comment, newComment]
				: [newComment],
		});
	}
};

export const setComments = (
	data: any,
	setData: any,
	newComment: any,
	childId: number,
	fieldName?: string,
) => {
	if (newComment.objectName === 'ROOT') {
		setCommentsToRoot(data, setData, newComment);
	} else {
		const tabName = Object.keys(fieldsMap).find(
			(key: string) => fieldsMap[key] === newComment.objectName,
		);

		if (data[tabName]?.comment && !childId) {
			if (
				data[tabName].comment.find((el: any) => el.id === newComment.id)
			) {
				updateComment(data, setData, tabName, newComment);
			} else {
				addNewCommentToExistComments(
					data,
					setData,
					tabName,
					newComment,
				);
			}
		} else {
			if (
				(tabName === 'children' || tabName === 'additionalIncome') &&
				childId
			) {
				addCommentToChild(
					data,
					setData,
					childId,
					newComment,
					fieldName,
				);
			} else {
				addFirstCommentToTab(data, setData, tabName, newComment);
			}
		}
	}
};

export const updateComment = (
	data: any,
	setData: any,
	tabName: string,
	newComment: any,
) => {
	setData({
		...data,
		[tabName]: {
			...data[tabName],
			comment: data[tabName].comment.map((el: any) => {
				if (el.id === newComment.id) {
					return {
						...el,
						comment: newComment.comment,
					};
				}

				return el;
			}),
		},
	});
};

export const addFirstCommentToTab = (
	data: any,
	setData: any,
	tabName: string,
	newComment: any,
) => {
	setData({
		...data,
		[tabName]: {
			...data[tabName],
			comment: [newComment],
		},
	});
};

const checkIfConditionalFieldIsRequired = (data: any, field: any) => {
	if ((field.name || field)?.includes('spouse')) {
		return data.employee.maritalStatus === 'MARRIED' ? true : false;
	}
	if (field.name === 'currentEmployerIncome.incomeType') {
		return !data.currentEmployerIncome.additionalIncomeType?.length;
	}
	if (field.shouldDisplay) {
		const targetFields = field.shouldDisplay().watch;
		const allFields = targetFields.map((targetField: string) =>
			_.get(data || {}, targetField),
		);

		return field.shouldDisplay().callback(allFields);
	} else {
		return true;
	}
};

export const addCommentToChild = (
	data: any,
	setData: any,
	childId: number,
	newComment: any,
	fieldName = '',
) => {
	const children = _.get(data, fieldName);

	const child = children.find((child: any) => child.id === childId);
	const index = children.findIndex((child: any) => child.id === childId);

	let newChild = {
		...child,
		comment: [newComment],
	};

	children.splice(index, 1, newChild);

	setData(_.set({ ...data }, fieldName, children));
};

export const approveForm = async ({ formId, data, cancel }: any) => {
	try {
		// const fileData = await fillPdf({ formId, data });
		const res: any = await httpService.api({
			type: 'approve101Form',
			params: {
				formId,
			},
			// data: { pdfForm: fileData?.file },
		});
		cancel && cancel();
	} catch (e) {
		let errorMessage = 'workerCard.viewer.comment.error';
		if (
			typeof e?.data === 'string' &&
			e?.data?.includes('Missing mandatory')
		) {
			errorMessage = 'workerCard.viewer.approve.errorMessage';
		}
		if (
			typeof e?.data === 'string' &&
			e?.data?.includes('Tax credit address code is not valid')
		) {
			errorMessage = 'workerCard.editor.save.addressCodeNotValid';
		}
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
				iconName: 'attention',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>{errorMessage}</I18n>
				</AlertModal>
			),
		);
	}
};

export const openApproveModal = async ({ formId, data, cancel }: any) => {
	await modalService.openModal(
		null,
		{
			onCancel: (callback: Function) => {
				callback();
			},
			onSubmit: (callback: Function) => {
				approveForm({ formId, data, cancel });
				callback();
			},
			submitBtnText: 'general.true',
			cancelBtnText: 'general.false',
			iconName: 'question',
		},
		(props: any) => (
			<ModalConfirmActions {...props}>
				<Typography>
					{`${
						i18nService.translate(
							'workerCard.viewer.approve.confirmationMessage',
						) +
						' ' +
						(data?.employee?.firstName ?? data?.fullName ?? '') +
						' ' +
						(data?.employee?.lastName ?? '') +
						'?'
					}`}
				</Typography>
			</ModalConfirmActions>
		),
	);
};

export const getIsHasComment = ({ data }: any) => {
	let hasComments = false;
	for (const field in data) {
		if (field === 'children' && data.children) {
			const childComments = data.children.reduce((res: any, el: any) => {
				if (el.comment) {
					return [...res, ...el.comment];
				}

				return res;
			}, []);
			if (childComments.length) {
				hasComments = true;
				break;
			}
		} else if (data[field]?.comment && data[field]?.comment.length) {
			hasComments = true;
			break;
		} else if (
			['signature', 'hasChildren'].includes(field) &&
			data?.comment?.find((c: any) => c.fieldName === field)
		) {
			hasComments = true;
			break;
		}
	}

	return hasComments;
};

export const openRejectFormModal = async ({ rejectForm, data }: any) => {
	await modalService.openModal(
		null,
		{
			onCancel: (callback: Function) => {
				callback();
			},
			onSubmit: (callback: Function) => {
				rejectForm();
				callback();
			},
			submitBtnText: 'general.true',
			cancelBtnText: 'general.false',
			iconName: 'question',
		},
		(props: any) => (
			<ModalConfirmActions {...props}>
				<Typography>
					{`${
						i18nService.translate(
							'workerCard.viewer.rejection.message',
						) +
						' ' +
						data.employee.firstName +
						' ' +
						data.employee.lastName +
						'?'
					}`}
				</Typography>
			</ModalConfirmActions>
		),
	);
};

export const rejectForm = async ({ formId, cancel }: any) => {
	try {
		const res: any = await httpService.api({
			type: 'reject101Form',
			params: {
				formId,
			},
		});
		cancel && cancel();
	} catch (e) {
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
				iconName: 'attention',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>workerCard.viewer.comment.error</I18n>
				</AlertModal>
			),
		);
	}
};
