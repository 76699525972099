import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import {rootReducer} from './reducers/root.reducer';

const composeEnhancer = composeWithDevTools({
	trace: true,
	traceLimit: 25,
});

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
