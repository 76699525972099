import React, { useState } from 'react';
import { Dimensions, TouchableOpacity, View, StyleSheet } from 'react-native';
import { FAB, Portal } from 'react-native-paper';
import HideOnKeyboardOpen from '../../../../../components/shared/HideOnKeyboardOpen/HideOnKeyboardOpen.component';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import colors from '../../../../../config/colors';
import { i18nService } from '../../../../../services/i18n.service';
import CustomerRolesWrapper from '../../RolesSection/CustomersManagment/CustomerRolesWrapper';

const CustomerManagementModal = React.forwardRef(
	(
		{
			setCustomersInfo,
			customersInfo,
			onSubmit,
			onCancel,
			disabledSubmit,
			route,
			id,
			unitId,
			isAdmin,
			openModal,
			disabled,
			setDeletedArray,
			deleteArray,
		}: any,
		ref: any,
	) => {
		const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

		return (
			<>
				<Portal.Host>
					<View style={styles.wrapper}>
						<View style={styles.header}>
							<I18n size={28} weight='normal' style={{ flex: 1 }}>
								{`customerManagementModal.title`}
							</I18n>
							<TouchableOpacity
								style={{ flexDirection: 'row-reverse' }}
								onPress={onCancel}
							>
								<I18n size={20} weight='400'>
									customerManagementModal.goBackScreenTitle
								</I18n>
								<ImageIcon name='leftArrow' />
							</TouchableOpacity>
						</View>
						<CustomerRolesWrapper
							isAdmin={isAdmin}
							setCustomersInfo={setCustomersInfo}
							customersInfo={customersInfo}
							route={route}
							setDeletedArray={setDeletedArray}
							deleteArray={deleteArray}
							isEditUser={id}
							openModal={openModal}
							unitId={unitId}
							setIsModalOpen={setIsModalOpen}
							disabled={disabled}
						/>
					</View>
					{!isModalOpen && (
						<HideOnKeyboardOpen>
							<FAB
								style={StyleSheet.flatten([
									styles.save,
									{ opacity: disabledSubmit ? 0.7 : 1 },
								])}
								small
								color={colors.white}
								icon=''
								label={i18nService.translate('general.update')}
								onPress={onSubmit}
								disabled={disabledSubmit || disabled}
							/>
						</HideOnKeyboardOpen>
					)}
					{!isModalOpen && (
						<HideOnKeyboardOpen>
							<FAB
								style={styles.cancel}
								small
								color={colors.lightPrimary}
								icon=''
								label={i18nService.translate('general.cancel')}
								onPress={onCancel}
							/>
						</HideOnKeyboardOpen>
					)}
				</Portal.Host>
			</>
		);
	},
);

const styles = StyleSheet.create({
	save: {
		position: 'absolute',
		margin: 16,
		width: 100,
		left: 10,
		bottom: 15,
		borderColor: colors.lightPrimary,
		backgroundColor: colors.lightPrimary,
		borderWidth: 1,
	},
	cancel: {
		left: 125,
		position: 'absolute',
		margin: 16,
		width: 100,
		bottom: 15,
		borderColor: colors.lightPrimary,
		backgroundColor: colors.white,
		borderWidth: 1,
	},
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		paddingHorizontal: 16,
	},
	wrapper: {
		flex: 1,
		backgroundColor: colors.lightBlue,
		opacity: 1,
		paddingTop: 25,
	},
});

export default CustomerManagementModal;
