import React, { useEffect, useState } from 'react';
import {
	Dimensions,
	Platform,
	StyleSheet,
	TouchableHighlight,
	View,
} from 'react-native';
import colors from '../../../config/colors';
import I18n from '../../../components/shared/i18n/I18n.component';
import { getRequestData, RequestData } from './RequestDetails.utils';
import VacationRequest from './RequestFields/VacationRequest';
import SiknessRequest from './RequestFields/SiknessRequest';
import moment from 'moment';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

interface ModalProps {
	navigation: any;
	requestId?: string;
	route: any;
}

const windowHeight = Dimensions.get('window').height;

const RequestDetailsMobile = ({ navigation, route }: ModalProps) => {
	const requestId = route?.params?.requestId || null;
	const validationDate =
		route?.params?.validationDate ||
		moment().subtract(1, 'months').startOf('month').valueOf();

	const businessUnitId = route?.params?.businessUnitId;
	const businessUnitName = route?.params?.businessUnitName;

	const [requestData, setRequestData] = useState<RequestData | null>(null);

	useEffect(() => {
		getData();
		onMount();
	}, [requestId]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('RequestDetailsMobile-Employer');
			await crashlytics().setAttributes({
				requestId: `${requestId}` || '',
			});
		}
	};

	const getData = async () => {
		const data: RequestData = await getRequestData(requestId);
		setRequestData(data);
	};

	return (
		<View style={styles.backdrop}>
			<View style={styles.buttonContainer}>
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() =>
						navigation.navigate('emploeeRequests', {
							goBackMode: true,
						})
					}
				>
					<I18n size={14} weight={'normal'} color='white'>
						general.close
					</I18n>
				</TouchableHighlight>
			</View>
			<KeyboardAwareScrollView
				contentContainerStyle={styles.container}
				style={{
					height: windowHeight - 76,
					backgroundColor: colors.white,
				}}
				extraHeight={100}
				showsVerticalScrollIndicator={false}
			>
				{requestData ? (
					requestData?.type === 'VACATION' ? (
						<VacationRequest
							requestData={requestData}
							onClose={() => navigation.goBack()}
							validationDate={validationDate}
							businessUnitId={businessUnitId}
							businessUnitName={businessUnitName}
						/>
					) : (
						<SiknessRequest
							requestData={requestData}
							onClose={() => navigation.goBack()}
							validationDate={validationDate}
							businessUnitId={businessUnitId}
							businessUnitName={businessUnitName}
						/>
					)
				) : (
					<View />
				)}
			</KeyboardAwareScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	backdrop: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		flex: 1,
	},
	container: {
		backgroundColor: 'white',
		paddingVertical: 30,
		paddingHorizontal: 20,
		width: '100%',
	},
	buttonContainer: {
		height: 38,
		width: 140,
		backgroundColor: colors.lightPrimary,
		alignItems: 'center',
		justifyContent: 'center',
		alignSelf: 'center',
		borderTopRightRadius: 98,
		borderTopLeftRadius: 98,
		marginTop: 38,
	},
});

export default RequestDetailsMobile;
