import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from '../../../../components/web/ReactDatePicker/ReactDatePicker.component';
import { i18nService } from '../../../../services/i18n.service';

const SettingsDatePicker = ({ value, onChange, disabled }) => {
  const [date, setDate] = useState(value);
  
  useEffect(() => {
    setDate(value)
  }, [value]);

  const onDateChange = (date) => {
    setDate(date);
    onChange(date)
  };

  return (
    <DatePicker
      config={{
        date: date,
        name: 'date_to',
        type: 'datepicker',
        label: 'filter.to',
        minDate: new Date(moment().subtract(1, 'months').format()),
        placeholder: date ? i18nService.translate(
          `months.${date.toLocaleString('en', {
            month: 'long',
          })}`,
        ) + ` ${date.getFullYear()}` : ' ',
        extraProps: {
          style: { zIndex: 1000000 },
          disabled
        }
      }}
      onChange={(date) => !disabled && onDateChange(date.value)}
    />
  )
}

export default SettingsDatePicker;