import React, { useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Platform, View, Image, TouchableHighlight } from 'react-native';
import _ from 'lodash';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import I18n from '../i18n/I18n.component';
import Typography from '../Typography/Typography.component';
import FormTextInput from './TextInput/TextInput.component';
import ButtonsGroupe from './ButtonsGroup/ButtonsGroup.component';
import UploadFile from './UploadFile/UploadFile.component';
import DatePicker from './DatePicker/DatePicker.component';
import FieldArray from './FieldArray/FieldArray.component';
import Select from './Select/Select.component';
import AsyncMultiSelect from './AsyncSelect/AsyncSelect.component';
import ReactSelect from './Select/ReactSelect.component';
import RadioButtonsGroup from './RadioButtonsGroup/RadioButtonsGroup.component';
import CheckboxCard from './CheckboxCard/CheckboxCard.component';
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import Checkbox from './Checkbox/Checkbox';
import TimePicker from './TimePicker/TimePicker.component';
import ExtraInfo from '../ExtraInfo/ExtraInfo.component';
import ViewOnly from './ViewOnly';
import CommentIconButton from '../../../pages/WorkerCard/WorkerCardEditor/CommentIconButton/CommentIconButton.component';
import SettingsDatePickerContainer from './SettingsDatePicker/SettingsDatePicker.container';
import UserTreeSelect from './TreeSelect/TreeSelect.component';

const InputBuilder = ({
	type,
	label,
	isMobile,
	isRequired,
	error,
	styles,
	shouldDisabled,
	sublabel,
	fieldWrapperStyle,
	disabled,
	Component,
	noTitle,
	tooltip,
	hasComment,
	onCommentClick,
	hideErrorlabel,
	notDisplayComment,
	...restProps
}: any) => {
	const { getValues } = useFormContext();
	const labelRef = useRef<any>();
	const watchShouldDisabled = useWatch({
		name: shouldDisabled && shouldDisabled().watch,
	});

	const isDisabled = useMemo(
		() =>
			(typeof disabled === 'function' ? disabled(getValues) : disabled) ||
			(shouldDisabled && shouldDisabled().callback(watchShouldDisabled)),
		[watchShouldDisabled, disabled],
	);
	let component = null;
	switch (type) {
		case 'inlineCheckbox':
			break;
		case 'buttonsGroup':
		case 'multiButtonsGroup':
			component = (
				<ButtonsGroupe
					{...restProps}
					error={error}
					isMulti={type === 'multiButtonsGroup'}
					isDisabled={isDisabled}
				/>
			);
			break;
		case 'googlePlacesAutocomplete':
			component = (
				<GooglePlacesAutocomplete
					{...restProps}
					error={error}
					isDisabled={isDisabled}
				/>
			);
			break;
		case 'checkbox':
			component = (
				<CheckboxCard
					{...restProps}
					error={error}
					isDisabled={isDisabled}
				/>
			);
			break;
		case 'uploadFile':
			component = (
				<UploadFile
					{...restProps}
					error={error}
					isDisabled={isDisabled}
				/>
			);
			break;
		case 'datepicker':
			component = (
				<DatePicker
					{...restProps}
					error={error}
					disabled={isDisabled}
				/>
			);
			break;
		case 'timePicker':
			component = <TimePicker {...restProps} error={error} />;
			break;
		case 'array':
			component = <FieldArray {...restProps} error={error} />;
			break;
		case 'customInput':
			component = (
				<Component
					{...restProps}
					error={error}
					onChange={restProps.customOnChange || restProps.onChange}
					hasComment={hasComment}
					onPressComment={() =>
						onCommentClick(label, restProps.name, restProps.value)
					}
					{...(restProps.getConfig?.({ ...restProps, disabled }) ||
						{})}
					styles={restProps.innerstyles}
				/>
			);
			break;
		case 'select':
			component = <Select {...restProps} error={error} />;
			break;
		case 'reactselect':
			component = (
				<ReactSelect
					disabled={isDisabled}
					{...restProps}
					error={error}
				/>
			);
			break;
		case 'asyncSelect':
			component = (
				<AsyncMultiSelect
					{...restProps}
					styles={styles}
					error={error}
				/>
			);
			break;
		case 'radio':
			component = <RadioButtonsGroup {...restProps} error={error} />;
			break;
		case 'monthPicker':
			component = (
				<SettingsDatePickerContainer
					{...restProps}
					disabled={isDisabled}
				/>
			);
			break;
		case 'treeSelect':
			component = <UserTreeSelect {...restProps} disabled={isDisabled} />;
			break;
		default:
			component = (
				<FormTextInput
					{...restProps}
					error={error}
					editable={
						'editable' in restProps
							? restProps.editable
							: !isDisabled
					}
				/>
			);
	}

	const inputWrapperStyles = {
		marginBottom: type === 'inlineCheckbox' ? 30 : 47,
		marginLeft: isMobile ? 0 : 30,
		marginRight:
			!isMobile && !label && type !== 'array' && !noTitle ? -30 : 0,
		marginTop: isMobile && !label && type !== 'array' && !noTitle ? -47 : 0,
		width: isMobile || type === 'array' ? '100%' : 'unset',
		maxWidth: '100%',
		opacity: isDisabled ? 0.6 : 1,
		zIndex:
			Platform.OS === 'android' || Platform.OS === 'ios' ? 0 : 'unset',
		alignItems: 'flex-end',
		...(fieldWrapperStyle ? fieldWrapperStyle(isMobile) : {}),
	} as any;

	const labelWrapperStyles = {
		flexDirection: 'row-reverse',
		marginBottom: type === 'array' ? 0 : 7,
		alignItems: 'center',
		marginop: type === 'inlineCheckbox' && !isMobile ? -11 : 0,
	};

	useEffect(() => {
		if (
			restProps?.toFocus === restProps?.name &&
			labelRef.current &&
			Platform.OS === 'web'
		) {
			labelRef.current?.scrollIntoView();
		}
	}, [restProps?.toFocus, restProps?.name]);

	return (
		<View style={styles?.wrapper || inputWrapperStyles} ref={labelRef}>
			<View
				style={
					styles?.labelWrapper
						? {
								...labelWrapperStyles,
								...styles?.labelWrapper,
						  }
						: labelWrapperStyles
				}
			>
				{type === 'inlineCheckbox' && (
					<Checkbox
						{...restProps}
						error={error}
						isDisabled={isDisabled}
					/>
				)}
				<I18n
					size={isMobile ? 12 : 14}
					style={{
						textAlign: 'right',
						height:
							label || isMobile || type === 'array' || noTitle
								? 'auto'
								: 17,
					}}
					weight='normal'
				>
					{typeof label === 'function' ? label(restProps) : label}
				</I18n>
				<I18n
					size={isMobile ? 12 : 14}
					weight='400'
					style={{
						textAlign: 'right',
						height:
							sublabel || isMobile || type === 'array' || noTitle
								? 'auto'
								: 17,
					}}
				>
					{sublabel}
				</I18n>
				{(
					typeof isRequired === 'function'
						? isRequired(restProps)
						: isRequired
				) ? (
					<Typography
						style={{ marginLeft: 2, textAlign: 'right' }}
						size={14}
						color='red'
					>
						*
					</Typography>
				) : null}
				{hasComment && !notDisplayComment && (
					<CommentIconButton
						style={{
							//position: isMobile ? 'relative' : 'static',
							//bottom: isMobile ? -21 : 0,
							//left: isMobile ? 0 : -25,
							height: isMobile ? 20 : 14,
							marginRight: 5,
							top: isMobile ? 0 : -2,
							width: isMobile ? 22 : 14,
						}}
						hasComment={true}
						color={undefined}
						onPress={() =>
							onCommentClick(
								label,
								restProps.name,
								restProps.value,
							)
						}
					/>
				)}
				{tooltip && <ExtraInfo infoText={tooltip} />}
			</View>

			{component}
			{error && type !== 'array' && !hideErrorlabel && (
				<I18n
					size={14}
					weight='normal'
					style={[
						{
							marginTop: 4,
							textAlign: 'right',
							maxWidth: styles?.errorCustomStyle?.maxWidth
								? styles.errorCustomStyle.maxWidth
								: isMobile
								? '100%'
								: 190,
						},
						styles?.errorCustomStyle || {},
					]}
					color='red'
				>
					{error.message}
				</I18n>
			)}
		</View>
	);
};

const FieldBuilder = ({
	name,
	displayOtherFieldError,
	viewOnly,
	shouldDisplay,
	isDisabled,
	position,
	//defaultValue,
	onChange,
	shouldDisplayError,
	onRemove,
	...extraProps
}: any) => {
	const {
		control,
		setValue,
		getValues,
		trigger,
		reset,
		formState: { errors },
	} = useFormContext(); // retrieve all hook methods
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const watchShouldDiplay = useWatch({
		name: shouldDisplay && shouldDisplay(position).watch,
	});

	if (extraProps.noDisplay && !viewOnly) {
		return null;
	}

	return !shouldDisplay || shouldDisplay().callback(watchShouldDiplay) ? (
		extraProps.type === 'array' || !name ? (
			viewOnly ? (
				<ViewOnly name={name} {...{ extraProps }} />
			) : (
				<InputBuilder
					{...{
						// onChange,
						// onBlur,
						// value,
						isMobile,
						onRemove: () => {
							onRemove?.(setValue, getValues, reset);
						},
						error:
							(!shouldDisplayError ||
								shouldDisplayError(isMobile)) &&
							_.get(
								errors,
								displayOtherFieldError
									? displayOtherFieldError(isMobile)
									: name,
							),
						name,
						viewOnly,
						shouldDisplay,
						isDisabled,
						...extraProps,
					}}
				/>
			)
		) : (
			<Controller
				control={control}
				render={({
					field: { onChange: _onChange, onBlur, value, name },
				}) =>
					viewOnly ? (
						<ViewOnly {...{ extraProps, value, name }} />
					) : (
						<InputBuilder
							{...{
								onChange: (newValue: any) => {
									_onChange(newValue);

									onChange &&
										onChange(
											setValue,
											getValues,
											newValue,
											value,
											trigger,
											reset,
											name,
										);
								},
								onBlur,
								value,
								isMobile,
								error:
									(!shouldDisplayError ||
										shouldDisplayError(isMobile)) &&
									_.get(
										errors,
										displayOtherFieldError
											? displayOtherFieldError(isMobile)
											: name,
									),
								name,
								viewOnly,
								shouldDisplay,
								isDisabled,
								setValue,
								...extraProps,
							}}
						/>
					)
				}
				name={name}
			/>
		)
	) : null;
};

export default React.memo(FieldBuilder);
