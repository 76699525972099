import React, { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { StackActions } from '@react-navigation/native';
import { logout } from '../../store/actions/login.actions';
import routes from '../../config/routes';
import UpdateEmail from './UpdateEmail.component';
import httpService from '../../services/http.service';
import { getIsMaintenance } from '../../store/selectors/config.selectors';

interface Response {
	succes: boolean;
	expired: boolean;
	errorMessage: string;
}

const UpdateEmailContainer = ({ navigation }: { navigation: any }) => {
	const dispatch = useDispatch();
	const [alertText, setAlertText] = useState('');
	const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
	const isMaintenance = useSelector(getIsMaintenance);

	useEffect(() => {
		isMaintenance && navigation.navigate('signIn');
	}, [isMaintenance]);

	useEffect(() => {
		Linking.getInitialURL().then(url => {
			navigateHandler(url);
		});
	}, []);

	const navigateHandler = async (url: any) => {
		try {
			const key = new URL(url).searchParams.get('key') || '';

			const res: Response = await httpService.api<any>({
				type: 'verifyUserEmail',
				params: { key },
			});
			if (res) {
				if (res.errorMessage === 'Invalid token') {
					setAlertText('personalInformation.updateEmail.failed');
					setIsSuccess(false);
				} else if (res.expired) {
					setAlertText('personalInformation.updateEmail.expired');
					setIsSuccess(false);
				} else {
					if (res.succes) {
						setAlertText('personalInformation.updateEmail.success');
						setIsSuccess(true);
					} else {
						setAlertText('personalInformation.updateEmail.failed');
						setIsSuccess(false);
					}
				}
			}
		} catch (e) {
			setAlertText('personalInformation.updateEmail.failed');
			setIsSuccess(false);
		}
	};

	const onBackToLogin = async () => {
		navigation.dispatch(StackActions.replace(routes.signIn.name));
		await dispatch(logout());
	};

	return (
		<UpdateEmail
			alertText={alertText}
			isSuccess={isSuccess}
			navigation={navigation}
			backToLogin={onBackToLogin}
		/>
	);
};

export default UpdateEmailContainer;
