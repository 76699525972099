import { toNumber } from 'lodash';
import moment from 'moment';

export const getDefaultDateFilter = () => {
	const currentDay = toNumber(moment().format('DD'));
	const currentYear = toNumber(moment().format('YYYY'));
	const currentMonth = toNumber(moment().format('MM'));

	if (currentDay >= 15) {
		return { year: [currentYear], month: [currentMonth] };
	}
	return {
		year: [moment().subtract(1, 'months').format('YYYY')],
		month: [toNumber(moment().subtract(1, 'months').format('MM'))],
	};
};

export const getDefaultReportFromMonthToMonthFilter = () => {
	const currentDay = toNumber(moment().format('DD'));
	const currentYear = toNumber(moment().format('YYYY'));
	const currentMonth = toNumber(moment().format('MM'));

	if (currentDay >= 20) {
		return { year: [currentYear], month: [currentMonth] };
	}
	return {
		year: [moment().subtract(1, 'months').format('YYYY')],
		month: [toNumber(moment().subtract(1, 'months').format('MM'))],
	};
};

export const getDefaultYearlyFilter = () => {
	const currentYear = toNumber(moment().format('YYYY'));
	const currentMonth = toNumber(moment().format('MM'));

	if (currentMonth !== 1) {
		return [currentYear];
	}
	return [currentYear - 1];
};
