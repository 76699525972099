import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import ReportsViewHeader from '../../../components/shared/ModalHeaders/ReportsViewHeader.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import PDFView from '../../../components/shared/PDFViewer/PDFViewer.component';
import { fillPdf } from '../../../pages/WorkerCard/WorkerCardPreview/workerCardPreviewBuilder';
import { downloadFile } from './utils';
import httpService from '../../../services/http.service';

const WorkerCardPreview = (props: any) => {
	const [pdfUrl, setPdfUrl] = useState();
	const [file, setFile] = useState();
	const [fileName, setFileName] = useState<string>();
	const [hasError, setHasError] = useState(false);
	const isWeb = useDevicePlatform() === DeviceType.WEB;
	const isMobileWeb = useDevicePlatform() === DeviceType.MOBILE_WEB;

	const selectedDateReport =
		props?.selectedDate || props?.route?.params?.selectedDate;
	const innerHeaderData =
		props?.innerHeaderData ||
		(props?.route?.params?.innerHeaderDataObj &&
			JSON.parse(props?.route?.params?.innerHeaderDataObj));
	const reportId = innerHeaderData?.id;
	const currentText = useMemo(
		() => props?.currentText || props?.route?.params?.currentText,
		[props.tite, props?.route?.params?.currentText],
	);

	useEffect(() => {
		reportId && getPdfUrl();
		if (!reportId) {
			setPdfUrl(undefined);
			setFile(undefined);
			setFileName(undefined);
			props.setFileUrl && props.setFileUrl(undefined);
			props.setIsRequestCompleted?.(true);
		}
	}, [reportId]);

	useEffect(() => {
		onMount();
	}, []);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('WorkerCardPreview');
		}
	};

	const setError = () => {
		setHasError(true);
		setPdfUrl(undefined);
		setFile(undefined);
		setFileName(undefined);
		props.setFileUrl && props.setFileUrl(undefined);
	};

	const getPdfUrl = async () => {
		try {
			if (
				innerHeaderData?.statusCode === 'APPROVED' ||
				innerHeaderData?.statusCode === 'PENDING_EMPLOYER'
			) {
				//get Pdf url
				const res: any = await httpService.api({
					type: 'getWorkerCardFinalFile',
					params: { formId: reportId },
				});
				if (res) {
					const builderData: any = await httpService.api({
						type: 'getWorkerCardData',
						params: { formId: reportId },
					});
					const fileName = `tofes101_${builderData.taxYear}_${
						builderData.employee?.idNumber ||
						builderData.employee?.passport
					}.pdf`;
					setPdfUrl(res);
					setFileName(fileName);
					props.setFileUrl &&
						props.setFileUrl({
							url: res,
							name: fileName,
						});
					setHasError(false);
					return;
				} else if (innerHeaderData?.statusCode === 'APPROVED') {
					setError();
					return;
				}
			}
			setPdfUrl(undefined);
			props.setFileUrl && props.setFileUrl(undefined);
			const data: any = await fillPdf({
				formId: reportId,
				isEmployee: true,
			});
			setFile(data?.file);
			setFileName(data?.fileName);
			setHasError(false);
			props?.setFileData && props?.setFileData(data);
		} catch (e) {
			setError();
		} finally {
			props?.setIsRequestCompleted?.(true);
		}
	};

	const download = async () => {
		if (pdfUrl) {
			let module;
			if (isWeb || isMobileWeb) {
				module = await import('../../../utils/downloadFile.web.utils');
			} else {
				module = await import(
					'../../../utils/downloadFile.moblie.utils'
				);
			}

			module.downloadFile(pdfUrl, fileName || '');
		} else if (file) {
			downloadFile(file, fileName);
		}
	};

	return (
		<View style={{ flex: 1, marginRight: isWeb ? 30 : 0 }}>
			{!isWeb && (
				<ReportsViewHeader
					dateMode=''
					download={download}
					navigation={props.navigation}
					isError={!pdfUrl && !file}
					selectedDate={selectedDateReport}
					title={currentText}
					backText='form101.backText'
					downloadOnMobile={false}
					innerHeaderData={innerHeaderData}
				/>
			)}
			{hasError ? (
				<View style={styles.errorMessage}>
					<I18n weight='bold' size={24}>
						form101.noReports
					</I18n>
				</View>
			) : !pdfUrl && !file ? null : (
				<PDFView file={file} pdfUrl={pdfUrl} />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	errorMessage: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default WorkerCardPreview;
