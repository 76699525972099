import React, { useEffect, useRef, useState } from 'react';
import { Image, View, useWindowDimensions } from 'react-native';
import { TouchableHighlight } from 'react-native-gesture-handler';
import SignatureCanvas from 'react-signature-canvas';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import colors from '../../../../../config/colors';
import CommentIconButton from '../../../../../pages/WorkerCard/WorkerCardEditor/CommentIconButton/CommentIconButton.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../../../hooks/useDevicePlatform.hook';

const Signature = ({
	value,
	onChange,
	onCommentClick,
	hasComment,
	commentReadOnly,
	isEmployer,
	notEditable,
	onPressComment,
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const dimension = useWindowDimensions();
	const ref: any = useRef();

	useEffect(() => {
		value && ref.current?.fromDataURL(value);
	}, [value]);

	const handleConfirm = () => {
		if (!ref.current?.isEmpty()) {
			const val = ref.current?.toDataURL();
			if (val !== 'data:,') {
				onChange(ref.current?.toDataURL());
			}
		}
	};

	const deleteSignature = () => {
		onChange('');
		ref.current?.clear();
	};

	const Buttons = () => (
		<View style={{ flexDirection: 'row-reverse' }}>
			{!isMobile ? (
				<View style={{ flexDirection: 'row-reverse', flex: 1 }} />
			) : null}
			{value && !notEditable && (
				<TouchableHighlight onPress={deleteSignature}>
					<I18n
						style={{ marginRight: 15 }}
						size={isMobile ? 12 : 16}
						color='primary'
						weight='normal'
					>
						general.delete
					</I18n>
				</TouchableHighlight>
			)}
		</View>
	);

	return (
		<View style={{ alignItems: 'flex-end' }}>
			<I18n
				size={12}
				weight='normal'
				style={{ textAlign: 'right', marginBottom: 17 }}
			>
				workerCard.editor.disclaimer.firstRow
			</I18n>
			<I18n
				size={12}
				weight='normal'
				style={{ textAlign: 'right', marginBottom: 17 }}
			>
				workerCard.editor.disclaimer.secondRow
			</I18n>
			<View style={{ maxWidth: 485 }}>
				<View style={{ flexDirection: 'row-reverse' }}>
					<View style={{ flexDirection: 'row-reverse', flex: 1 }}>
						{(isEmployer || hasComment) && (
							<CommentIconButton
								disabled={commentReadOnly && !hasComment}
								style={{
									position: isMobile ? 'relative' : 'static',
									bottom: isMobile ? 1 : 0,
									height: isMobile ? 14 : 14,
									marginTop: 0,
									width: isMobile ? 16 : 14,
								}}
								hasComment={hasComment}
								color={undefined}
								onPress={() =>
									onPressComment?.() ||
									onCommentClick(
										'workerCard.editor.disclaimer.signature',
										'signature',
										'',
									)
								}
							/>
						)}
						<I18n
							size={isMobile ? 12 : 14}
							style={{
								textAlign: 'right',
								height: 17,
							}}
							weight='normal'
						>
							workerCard.editor.disclaimer.signature
						</I18n>
						<Typography
							style={{ marginLeft: 2, textAlign: 'right' }}
							size={14}
							color='red'
						>
							*
						</Typography>
					</View>
					{isMobile ? <Buttons /> : null}
				</View>
				<View style={{ backgroundColor: colors.lightBlue }}>
					{!notEditable ? (
						<SignatureCanvas
							ref={ref}
							canvasProps={{
								style: {
									width: isMobile
										? dimension.width - 40
										: 485,
									height: 198,
								},
							}}
							backgroundColor={'transparent'}
							penColor={colors.primary}
							onEnd={() => handleConfirm()}
						/>
					) : (
						<Image
							source={{ uri: value }}
							style={{
								width: isMobile ? dimension.width - 40 : 485,
								height: 198,
								resizeMode: 'contain',
							}}
						/>
					)}
				</View>
				{!isMobile ? <Buttons /> : null}
			</View>
		</View>
	);
};

export default Signature;
