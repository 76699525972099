import { EventName, logEvent } from '../../services/analyticsAndLogs.service';
import { tamplate } from './payCheckTemplate';

export const paycheckGenerator = (data: any = {}) => {
	try {
		const paycheckData = Object.keys(data)
			.sort()
			.reduce((result: any, current: any) => {
				const pageNumber = current.split('-')[0]; //1
				const sectionNumber = current.split('-')[1].toLowerCase(); //a
				const rowNumber = current.split('-')[2]; //1
				const columnNumber = current.split('-')[3]; //1

				// const currentPage = result[pageNumber]
				// 	? { ...result[pageNumber] }
				// 	: {};
				// const section = currentPage[sectionNumber]
				// 	? { ...currentPage[sectionNumber] }
				// 	: {};
				// section[rowNumber] = section[rowNumber]
				// 	? { ...section[rowNumber], [columnNumber]: data[current] }
				// 	: { [columnNumber]: data[current] };
				// return {
				// 	...result,
				// 	[pageNumber]: section,
				// };

				const currentPage = result[pageNumber]
					? { [pageNumber]: { ...result[pageNumber] } }
					: { [pageNumber]: {} };

				const section = currentPage[pageNumber]
					? { ...currentPage[pageNumber] }
					: { [sectionNumber]: {} };

				section[sectionNumber] = section[sectionNumber]
					? { ...section[sectionNumber] }
					: { [rowNumber]: {} };

				section[sectionNumber][rowNumber] = section[sectionNumber][
					rowNumber
				]
					? { ...section[sectionNumber][rowNumber] }
					: { [columnNumber]: {} };

				section[sectionNumber][rowNumber][columnNumber] = data[current];

				return {
					...result,
					[pageNumber]: { ...section },
				};
			}, {} as any);
		return paycheckData;
	} catch (err) {
		logEvent({
			eventName: EventName.paycheckGenerateFailure,
			data: {
				data,
				err,
			},
		});
	}
};

export const generateTemplate = (
	data: any,
	size: number,
	isMobile?: boolean,
	safariMargin?: number,
	isMobileWeb?: boolean,
): string => {
	return tamplate(paycheckGenerator(data), size, isMobile, safariMargin);
};
