import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { View, StyleSheet, Platform, useWindowDimensions } from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import {
	downloadFile,
	onSendNewRequest,
	SendButton,
	showUpdateButton,
	TwoButtonsContainer,
} from './RequestForm.utils';
import { RequestDetails } from '../EditRequest';
import { StatusOptions } from '../../ApplicationsToEmployer.utils';
import moment from 'moment';
import { getFileData } from '../../../WorkerCard/WorkerCardEditor/localToServerFiles/localToServerFiles';
import { useSelector } from 'react-redux';
import { getEmployeeRolesPrivileges } from '../../../../store/selectors/login.selectors';
import { Privileges } from '../../../../constants/roleTypes.constant';

interface RequestProps {
	requestData?: RequestDetails;
	onClose: any;
	from: string | undefined;
	to: string | undefined;
	savedDescription?: string | undefined;
	validationDate: number;
	disabled?: boolean;
	setSavedDescription?: Dispatch<SetStateAction<undefined>>;
	setFrom: Dispatch<SetStateAction<undefined>>;
	setTo: Dispatch<SetStateAction<undefined>>;
}

export interface SiknessRequestData {
	fromDate: string;
	toDate: string;
	description?: string;
	uploadFile: {
		fileName?: string;
		fileSize?: number;
		height?: number;
		type?: string;
		uri?: string;
		width?: number;
	};
}

const SiknessRequest = ({
	requestData,
	onClose,
	from,
	setFrom,
	to,
	setTo,
	savedDescription = '',
	setSavedDescription,
	onrefresh,
	validationDate,
	disabled,
}: RequestProps) => {
	const [showEditButton, setShowEditButton] = useState(false);
	const [showError, setShowError] = useState(false);
	const [formData, setFormData] = useState(null);
	const [updatedForm, setUpdatedForm] = useState(
		requestData ? { ...requestData } : {},
	);
	const [removedFiles, setRemovedFiles] = useState([]);

	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

	const platform = useDevicePlatform();
	const dimensions = useWindowDimensions();
	const isMobile = platform === DeviceType.MOBILE;

	const formMethods = useForm({
		defaultValues: requestData
			? {
					...requestData,
					toDate: requestData.toDate
						? requestData.toDate
						: requestData.fromDate,
					files: requestData?.files?.map(file => ({
						uploadFile: file,
					})),
			  }
			: {
					fromDate: from,
					toDate: to,
					files: [{}],
					description: savedDescription,
			  },
		mode: 'onBlur',
	});

	useEffect(() => {
		if (requestData) {
			setTo(
				requestData.toDate ? requestData.toDate : requestData.fromDate,
			);
			setFrom(requestData.fromDate);
		}
	}, []);

	const creaateForm = async () => {
		const values: any = formMethods.getValues();

		let formValues = {
			type: 'SICKNESS',
			fromDate: values.fromDate,
			toDate: values.fromDate === values.toDate ? null : values.toDate,
			description: values?.description || '',
		};

		if (requestData) {
			formValues = { ...formValues, deletedFiles: removedFiles };
		}
		let dataToSend;

		if (Platform.OS === 'android' || Platform.OS === 'ios') {
			dataToSend = [];

			values.files.forEach(file => {
				if (
					!(file.uploadFile?.status === 'READY') &&
					file?.uploadFile
				) {
					const fileData = getFileData('file', file.uploadFile);
					dataToSend.push(fileData);
				}
			});

			dataToSend.push({
				name: 'request-data',
				type: 'application/json',
				data: JSON.stringify(formValues),
			});
		} else {
			const formData: any = new FormData();
			const data = new Blob([JSON.stringify(formValues)], {
				type: 'application/json',
			});
			formData.append('request-data', data);

			values.files.forEach(file => {
				if (!file.uploadFile?.status) {
					file.uploadFile &&
						formData.append(
							'files',
							file.uploadFile,
							file?.uploadFile?.fileName ||
								file?.uploadFile?.name,
						);
				}
			});

			dataToSend = formData;
		}

		return { dataToSend, values };
	};

	const onSubmit = async () => {
		try {
			const formValues: any = formMethods.getValues();

			if (!formValues?.fromDate || !formValues?.toDate) {
				setFormData(formValues);
				return setShowError(true);
			}

			const { dataToSend, values } = await creaateForm();
			onSendNewRequest(
				values,
				'SICKNESS',
				onClose,
				dataToSend,
				onrefresh,
			);
		} catch {}
	};

	const onFormUpdate = (field: string, val: string) => {
		setShowError(false);
		if (field === 'fromDate') {
			setFrom(val);
			if (!to) {
				formMethods.setValue('toDate', val);
				setTo(val);
			}
		} else if (field === 'toDate') {
			setTo(val);
			if (!from) {
				formMethods.setValue('fromDate', val);
				setFrom(val);
			}
		}

		if (requestData) {
			const form = { ...updatedForm, [field]: val };
			let isUpdate = false;

			if (
				field === 'files' ||
				form?.['fromDate'] !== requestData['fromDate'] ||
				form?.['toDate'] !== requestData['toDate'] ||
				form?.['description'] !== requestData['description']
			) {
				isUpdate = true;
			}
			setShowEditButton(isUpdate);
			setUpdatedForm(form);
		}

		setShowError(false);
	};

	return (
		<FormProvider {...formMethods}>
			<View
				style={[
					styles.vacationContainer,
					{ marginBottom: isMobile ? 20 : 70 },
				]}
			>
				<I18n size={14} weight='normal'>
					vacationRequest.selectDates
				</I18n>

				<View style={styles.vacationDates}>
					<View style={{ width: 140 }}>
						<FieldBuilder
							styles={{
								wrapper: { margin: 0, marginBottom: 30 },
							}}
							key={'fromDate'}
							name={'fromDate'}
							type={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
										? 'datepicker'
										: undefined
									: 'datepicker'
							}
							maxDate={
								to
									? new Date(
											moment(to, 'DD/MM/YYYY').valueOf(),
									  )
									: undefined
							}
							minDate={new Date(validationDate)}
							label={() => (
								<I18n size={14} weight='normal'>
									vacationRequest.fromDate
								</I18n>
							)}
							placeholder={'general.datePickerPlaceholder'}
							isRequired={true}
							disabled={disabled}
							editable={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
									: true
							}
							onChange={(val, a, b) => {
								onFormUpdate(
									'fromDate',
									typeof b === 'string'
										? b
										: b?.target?.value,
								);
							}}
							error={showError && !formData?.fromDate}
						/>
					</View>
					<View style={{ width: 140 }}>
						<FieldBuilder
							styles={{
								wrapper: { margin: 0, marginBottom: 30 },
							}}
							key={'toDate'}
							name={'toDate'}
							type={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
										? 'datepicker'
										: undefined
									: 'datepicker'
							}
							minDate={
								from
									? new Date(
											moment(
												from,
												'DD/MM/YYYY',
											).valueOf(),
									  )
									: new Date(validationDate)
							}
							label={() => (
								<I18n size={14} weight='normal'>
									vacationRequest.toDate
								</I18n>
							)}
							placeholder={'general.datePickerPlaceholder'}
							isRequired={true}
							disabled={disabled}
							editable={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
									: true
							}
							onChange={(val, a, b) => {
								onFormUpdate(
									'toDate',
									typeof b === 'string'
										? b
										: b?.target?.value,
								);
							}}
							error={showError && !formData?.toDate}
						/>
					</View>
				</View>

				<View
					style={[
						styles.uploadFile,
						{
							height:
								requestData &&
								!showUpdateButton(
									requestData,
									validationDate,
								) &&
								requestData.files.length === 0
									? 0
									: undefined,
						},
					]}
				>
					<FieldBuilder
						key={'array'}
						type={'array'}
						name={'files'}
						label={() =>
							requestData &&
							!showUpdateButton(requestData, validationDate) &&
							requestData.files.length === 0 ? (
								<View />
							) : (
								<I18n size={14} weight='normal'>
									siknessRequest.fileUpload
								</I18n>
							)
						}
						editable={
							requestData
								? showUpdateButton(requestData, validationDate)
								: true
						}
						maxLength={3}
						styles={{ wrapper: { margin: 0 } }}
						getDefautValue={(getValues: Function) => ({
							name: '',
							uploadFile: null,
						})}
						fields={[
							{
								key: 'uploadFile',
								type: 'uploadFile',
								name: 'uploadFile',
								styles: { wrapper: { margin: 0 } },
								buttonStyle: {
									width: isMobile
										? dimensions.width - 40
										: 322,
								},
								downloadType: 'getEmployerRequestFileUrl',
								extraDownloadParams: {
									requestId: requestData?.id || '',
								},
								editable: disabled
									? false
									: requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
									: true,
								onDownloadPressed:
									requestData && !isMobile
										? async fileID =>
												await downloadFile(
													fileID,
													requestData,
												)
										: null,
								onChange: (
									setValue: Function,
									getValues: Function,
									selectedValue: any,
									prevValue: any,
									trigger: Function,
								) => {
									onFormUpdate('files', selectedValue);
								},
							},
						]}
						onRemoveFile={val => {
							if (val?.id) {
								setRemovedFiles([...removedFiles, val.id]);
								setShowEditButton(true);
							}
						}}
						addButtonStyle={styles.addButton}
						buttonWidth={30}
						buttonHeight={30}
						onChange={(
							setValue: Function,
							getValues: Function,
							selectedValue: any,
							prevValue: any,
							trigger: Function,
						) => {
							onFormUpdate('files', selectedValue);
						}}
					/>
				</View>

				<FieldBuilder
					key={'description'}
					name={'description'}
					multiline
					label={() => (
						<I18n size={14} weight='normal'>
							vacationRequest.description
						</I18n>
					)}
					placeholder={'vacationRequest.addDescription'}
					isRequired={false}
					editable={
						disabled
							? false
							: requestData
							? showUpdateButton(requestData, validationDate)
							: true
					}
					maxLength={300}
					height={91}
					limitMargin={15}
					styles={{ wrapper: { margin: 0 } }}
					onChange={(val, a, b) => {
						onFormUpdate('description', b || '');
						setSavedDescription && setSavedDescription(b);
					}}
				/>

				{requestData && (
					<View>
						<View
							style={[styles.fieldsContainer, { marginTop: 15 }]}
						>
							<View style={{ width: 160 }}>
								<I18n size={14} weight='normal'>
									applicationsToEmployerContainer.status
								</I18n>
							</View>
							<Typography weight='normal' size={14}>
								{StatusOptions[requestData.status]}
							</Typography>
						</View>

						<View style={styles.fieldsContainer}>
							<View style={{ width: 160 }}>
								<I18n size={14} weight='normal'>
									applicationsToEmployerContainer.lastUpdated
								</I18n>
							</View>
							<Typography weight='normal' size={14}>
								{requestData.lastUpdate}
							</Typography>
						</View>

						{!!requestData?.employerComment && (
							<View style={styles.fieldsContainer}>
								<View style={{ width: 100 }}>
									<I18n size={14} weight='normal'>
										vacationRequest.employerNote
									</I18n>
								</View>
								<View style={{ width: 230 }}>
									<Typography weight='normal' size={14}>
										{requestData.employerComment}
									</Typography>
								</View>
							</View>
						)}
					</View>
				)}
			</View>
			<View>
				{requestData &&
				employeeRolesPrivileges?.includes(
					Privileges.ROLE_SICKNESS_REQUEST_WRITE,
				) ? (
					<TwoButtonsContainer
						showEditButton={showEditButton}
						requestData={requestData}
						onClose={onClose}
						sendRequestUpdate={creaateForm}
						to={to}
						from={from}
						validationDate={validationDate}
					/>
				) : (
					<SendButton disabled={disabled} onSend={onSubmit} />
				)}
			</View>
		</FormProvider>
	);
};
const styles = StyleSheet.create({
	vacationContainer: {
		alignContent: 'space-between',
		marginTop: 17,
		marginBottom: 20,
		justifyContent: 'space-between',
	},
	vacationDates: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 90,
		justifyContent: 'space-between',
		zIndex: 1000,
		marginTop: 15,
	},
	uploadFile: {
		marginBottom: 3,
	},
	fieldsContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		marginTop: 15,
		marginBottom: 10,
	},
	alertContainer: {
		paddingVertical: 39,
		paddingHorizontal: 50,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
	},
	addButton: {
		borderRadius: 15,
		width: 30,
		height: 30,
		overflow: 'hidden',
		alignSelf: 'flex-end',
		marginBottom: 10,
		marginTop: 3,
	},
});

export default SiknessRequest;
