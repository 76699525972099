export enum LogColors {
  Red = "\u001b[1;31m",
  Green = "\u001b[1;32m",
  Yellow = "\u001b[1;33m",
  Blue = "\u001b[1;34m",
  Purple = "\u001b[1;35m",
  Cyan = "\u001b[1;36m",
}

export const logRequest = (message: string, value?: any) =>
  log({message, value, color: LogColors.Blue})

export const logResponseNeutral = (message: string, value?: any) =>
  log({message, value, color: LogColors.Cyan})

export const logError = (message: string, value?: any) =>
  log({message, value, color: LogColors.Red})

export const logSuccess = (message: string, value?: any) =>
  log({message, value, color: LogColors.Green})

export const logDebug = (message: string, value?: any) =>
  log({message, value, color: LogColors.Purple})

export const log = (
  data:
    | {
        message: string
        value?: any
        color?: LogColors
      }
    | string,
) => {
  //if (!Configs.showLogs) return
  if (typeof data === "string") {
    console.log(data)
  } else {
    const {message, value, color} = data
    const displayMessage = `${color || ""} ${message}`
    value ? console.log(displayMessage, value) : console.log(displayMessage)
  }
}

