import { phonePrefixOptions } from '../../../../utils/globals.utils';
import { i18nService } from '../../../../services/i18n.service';
import _, { compact } from 'lodash';
import colors from '../../../../config/colors';

export type FieldConfigType = {
	type: string;
	name: string;
	label?: string;
	editable?: boolean;
	value: any;
	isRequired?: boolean;
	options?: { id: any; name: any }[];
	isDisabled?: boolean;
	styles?: any;
	modifyValue?: Function | null;
};

export const getFieldsConfig = (
	identityType: string,
	isMobile: boolean,
	outerClasses?: any,
	isEditUser?: boolean,
	userPriveleges?: any,
	has113Module?: boolean,
	isActive?: boolean,
	canEdit?: boolean,
): any[] => {
	const phonePrefix = {
		type: 'reactselect',
		name: 'phone_prefix',
		disabled: isEditUser && (isActive || !canEdit),
		options: phonePrefixOptions,
		styles: {
			wrapper: {
				marginBottom: isMobile ? undefined : 20,
				minWidth: 40,
				width: isMobile ? '100%' : 84,
				zIndex: 10,
			},
		},
		width: isMobile && '100%',
		style: {
			minWidth: 40,
		},
	};

	const identityTypeObj = {
		type: isMobile ? 'radio' : 'reactselect',
		name: 'identity_type',
		label: isMobile
			? 'editUserModal.userDetails.indentityNumberLabel'
			: undefined,
		isSearchable: false,
		options: [
			{
				id: 'ID',
				name: i18nService.translate(
					`editUserModal.userDetails.indentityNumber${
						isMobile ? 'Mobile' : ''
					}`,
				),
			},
			{
				id: 'PASSPORT',
				name: i18nService.translate(
					`editUserModal.userDetails.passportNumber${
						isMobile ? 'Mobile' : ''
					}`,
				),
			},
		],
		isRequired: isMobile,
		editable: !isEditUser,
		disabled: isEditUser,
		required: true,
		outerClasses: outerClasses?.radio,
		style: {
			borderTopRightRadius: isMobile ? undefined : 0,
			borderBottomRightRadius: isMobile ? undefined : 0,
			width: isMobile ? '100%' : 75,
			minWidth: isMobile ? '100%' : 75,
		},
		styles: {
			wrapper: {
				zIndex: 90,
				width: isMobile ? '100%' : 75,
				minWidth: isMobile ? '100%' : 75,
			},
		},
		buttonStyles: {
			button: {
				height: 40,
				borderRadius: 20,
				borderWidth: 2,
				borderColor: colors.filterBorder,
			},
			buttonsGroup: {
				alignItems: 'center',
				height: '100%',
			},
		},
		onChange: (
			setValue: Function,
			getValues: Function,
			selectedValue: any,
		) => {
			if (selectedValue === 'ID') {
				setValue('darkon', '');
			} else {
				setValue('teudat_zeut', '');
			}
		},
	};

	const fields = compact([
		isMobile && identityTypeObj,
		{
			type: 'input',
			name: 'teudat_zeut',
			label: !isMobile
				? 'editUserModal.userDetails.indentityNumberLabel'
				: undefined,
			isRequired: !isMobile,
			shouldDisabled:
				!isMobile &&
				(() => ({
					watch: ['identity_type'],
					callback: (values: any) => values[0] !== 'ID',
				})),
			shouldDisplay: () => ({
				watch: ['identity_type'],
				callback: (values: any) => values[0] === 'ID',
			}),
			editable: !isEditUser,
			hasEditableColorText: true,
			keyboardType: 'numeric',
			maxLength: 9,
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 110,
				},
			},
			style: {
				borderTopLeftRadius: isMobile ? undefined : 0,
				borderBottomLeftRadius: isMobile ? undefined : 0,
			},
		},
		{
			type: 'input',
			name: !isEditUser ? 'darkon' : 'teudat_zeut',
			label: !isMobile
				? 'editUserModal.userDetails.indentityNumberLabel'
				: undefined,
			isRequired: true,
			shouldDisplay: () => ({
				watch: ['identity_type'],
				callback: (values: any) => values[0] === 'PASSPORT',
			}),
			editable: !isEditUser,
			hasEditableColorText: true,
			styles: {
				wrapper: {
					margin: isMobile ? 0 : '0!important',
					width: isMobile ? '100%' : 110,
				},
			},
			style: {
				borderTopLeftRadius: isMobile ? undefined : 0,
				borderBottomLeftRadius: isMobile ? undefined : 0,
			},
		},
		!isMobile && identityTypeObj,
		{
			type: 'input',
			name: 'first_name',
			label: 'editUserModal.userDetails.firstName',
			hasEditableColorText: true,
			isRequired: true,
			modifyValue: identityType === 'PASSPORT' ? onNameFieldChange : null,
			editable: canEdit,
			styles: isMobile
				? {
						wrapper: {
							width: '100%',
						},
				  }
				: undefined,
		},
		{
			type: 'input',
			name: 'last_name',
			label: 'editUserModal.userDetails.lastName',
			hasEditableColorText: true,
			isRequired: true,
			editable: canEdit,
			modifyValue: identityType === 'PASSPORT' ? onNameFieldChange : null,
			styles: isMobile
				? {
						wrapper: {
							width: '100%',
						},
				  }
				: undefined,
		},
		{
			type: 'input',
			name: 'email',
			isRequired: true,
			hasEditableColorText: true,
			label: 'editUserModal.userDetails.email',
			editable: !isEditUser || (!isActive && canEdit),
			styles: isMobile
				? {
						wrapper: {
							width: '100%',
						},
				  }
				: undefined,
		},
		isMobile && phonePrefix,
		{
			type: 'input',
			name: 'phone',
			label: 'editUserModal.userDetails.mobilePhone',
			isRequired: true,
			hasEditableColorText: true,
			editable: !isEditUser || (!isActive && canEdit),
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 138,
				},
				labelWrapper: {
					flexDirection: 'row',
				},
			},
		},
		!isMobile && phonePrefix,
	]);

	return fields;
};

const onNameFieldChange = (value: string) => {
	const newValue = value.toUpperCase();
	return newValue;
};
