import React, { useState } from 'react';
import { useWindowDimensions, View } from 'react-native';
import FiltersContainer from '../../../components/web/Filters/Filters.container';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import ModalContainer from '../../../components/shared/Modal/Modal.container';
import EmployeesModal from '../../../modals/web/EmployeesModal/EmployeesModal.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { Button, makeStyles } from '@material-ui/core';
import { i18nService } from '../../../services/i18n.service';
import Typography from '../../../components/shared/Typography/Typography.component';
import I18n from '../../../components/shared/i18n/I18n.component';

type VacationReportProps = {
	navigation: any;
	onFilter: (data: any) => void;
	onSubRowClick: (row: any) => void;
	filtersData: any;
	headers: any[];
	data: any[];
	onSort: (field: string, direction: string) => void;
	getExcel: () => void;
	modalTitle: string;
	modalTableRows: any[];
	onEmployeesSort: (field: string, direction: string) => void;
	getNextData: () => void;
	onModalBtnPressedHandler: () => void;
	modalOpened: boolean;
	allEmployeesFetched: boolean;
	defaultExpandedRows: any;
	onLeftArrowClick: (row: any) => void;
	setCurrentTableState: any;
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			padding: '0!important',
			minWidth: '20px',

			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
	};
});

const VacationReportPage = ({
	navigation,
	data,
	headers,
	filtersData,
	onSort,
	onFilter,
	onSubRowClick,
	modalTitle,
	modalTableRows,
	onEmployeesSort,
	getNextData,
	onModalBtnPressedHandler,
	modalOpened,
	getExcel,
	allEmployeesFetched,
	defaultExpandedRows,
	onLeftArrowClick,
	setCurrentTableState,
}: VacationReportProps) => {
	const { height } = useWindowDimensions();
	const classes = useStyles();
	return (
		<>
			<ReportsPageWrapper
				navigation={navigation}
				title='vacationReportPage.title'
			>
				<View
					style={{
						maxHeight: height - 241,
						minHeight: height - 241,
					}}
				>
					<View
						style={{
							marginBottom: 31,
							zIndex: 3,
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
						}}
					>
						<FiltersContainer
							config={filtersData}
							onChange={onFilter}
							customStyles={{
								container: {
									flexDirection: 'row-reverse',
								},
								filterContainer: {
									marginRight: 0,
									marginLeft: 15,
									zIndex: 3,
									flexDirection: 'row-reverse',
									alignItems: 'center',
								},
								label: {
									marginLeft: 10,
								},
							}}
						/>
						<Button
							classes={{
								root: classes.root,
							}}
							onClick={getExcel}
						>
							<ImageIcon name='excel' />
						</Button>
					</View>
					{Boolean(data.length) && (
						<TableContainer
							onSubRowClick={onSubRowClick}
							headers={headers}
							data={data || []}
							count={40}
							keyName={''}
							onSort={onSort}
							getNextData={() => {}}
							hasFooter={true}
							defaultExpandedRows={defaultExpandedRows}
							onLeftArrowClick={onLeftArrowClick}
							setCurrentTableState={setCurrentTableState}
						/>
					)}
					{Boolean(!data.length) && (
						<View
							style={{
								flex: 1,
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<I18n weight='bold' size={24}>
								attendanceReportPage.noReports
							</I18n>
						</View>
					)}
				</View>
			</ReportsPageWrapper>
			<ModalContainer
				visible={modalOpened}
				component={EmployeesModal}
				headers={headers}
				tableRows={modalTableRows}
				onRowClick={onSubRowClick}
				modalTitle={modalTitle}
				onSort={onEmployeesSort}
				getNextData={getNextData}
				onNext={() => onModalBtnPressedHandler()}
				allDataFetched={allEmployeesFetched}
			/>
			)
		</>
	);
};

export default VacationReportPage;
