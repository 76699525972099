import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import colors from '../../../config/colors';
import SalaryReportPage from './SalaryReportPage.component';

import reducer, {
	fetchReportLastDate,
	initialState,
	SalaryReportsFilterTypes,
	SalaryReportsRequest,
	Action,
} from './SalaryReportPage.reducer';
import {
	FullSalaryReportList,
	FullSalaryReportListLike,
} from '../../../models/fullSalaryReportList.model';
import {
	filter,
	incrementPage,
	sort,
	updateBusinessUnits,
	updateDepartment,
} from './SalaryReportPage.actions';
import { FilterData } from '../../../components/web/Filters/Filters.container';
import { debounce } from 'lodash';
import { getUserData } from '../../../store/selectors/login.selectors';
import httpService from '../../../services/http.service';
import { download } from '../../PaycheckPage/PaycheckView/PaycheckUtils.web';

import { getDepartmentsFilterList } from '../../../store/selectors/reportsFilters.selectors';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
} from '../../../store/actions/reportsFilters.actions';
import { dispatch, getState } from '../../../store/store';
import {
	createFilterOptionsForTable,
	FiltersDataType,
	initSalaryFilters,
	initSalaryListReport,
	pageFilterSize,
	PAGINATION_PAGES_VISIBLE,
	SalaryReportDataType,
	salaryReportHeaders,
	TemplateDataType,
} from './salaryReportPage.utils';
import { useRoute } from '@react-navigation/native';
import { getExcelFileFromResponse } from '../../../utils/downloadFile.web.utils';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
} from '../../../store/actions/config.actions';
import { i18nService } from '../../../services/i18n.service';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';

const SalaryReportsPageContainer = ({ navigation }: { navigation: any }) => {
	const departmentsFilterList = useSelector(getDepartmentsFilterList);

	const [salaryListReport, setSalaryListReport] =
		useState<FullSalaryReportListLike>(initSalaryListReport);
	const [salaryFilters, setSalaryFilters] =
		useState<FiltersDataType>(initSalaryFilters);

	let templateDataWeb = useMemo<any>(() => ({}), []);
	const [selectedSalaryReport, setSelectedSalaryReport] =
		useState<SalaryReportDataType | null>(null);

	const [isRowSelected, setIsRowSelected] = useState<boolean>(false);

	const [tableState, tableDispatch] = useReducer<
		React.Reducer<SalaryReportsRequest, Action>
	>(reducer, initialState);

	const [selectedDepartments, setSelectedDepartments] = useState<any>({
		dep: [],
	});

	const [reportNotFound, setReportNotFound] = useState<boolean>(false);
	const route = useRoute() as any;
	const userData = useSelector(getUserData);

	const [currentPageSelected, setCurrentPageSelected] = useState<number>(0);
	const [selectedPaycheckFilter, setSelectedPaycheckFilter] = useState([
		{
			id: 'VIEW',
			name: i18nService.translate('salaryReportPage.filter.VIEW'),
		},
		{
			id: 'NOT_VIEW',
			name: i18nService.translate('salaryReportPage.filter.NOT_VIEW'),
		},
	]);
	const isLoadLast = useRef<boolean>();
	const counter = useRef<any[]>([]);

	const handleFilter = useCallback((data: FilterData) => {
		if (data.name === 'unitIds') {
			tableDispatch(updateBusinessUnits(data));
			return;
		} else {
			isLoadLast.current = true;
			if (data.name === 'paycheck-view') {
				setSelectedPaycheckFilter(data.value);
			}
			setSelectedSalaryReport(null);
			tableDispatch(
				filter(
					data.name as SalaryReportsFilterTypes,
					data.value,
					data.isMulti,
				),
			);
			if (data.name === 'department') {
				setSelectedDepartments({
					dep: data.value.map((el: any) => el.id),
				});
				tableDispatch(updateDepartment());
			}
		}
	}, []);

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.salaryReports.privileges,
		onChangeFilter: handleFilter,
	});

	useEffect(() => {
		if (route.params?.screen === 'details') {
			setIsRowSelected(true);
		}
		if (route?.params?.isFromDashboard) {
			setSelectedPaycheckFilter([
				{
					id: 'NOT_VIEW',
					name: i18nService.translate(
						'salaryReportPage.filter.NOT_VIEW',
					),
				},
			]);
			tableDispatch(sort('firstViewedSlipDate', 'ASC'));
			tableDispatch(filter('month', route?.params?.isFromDashboard));
			tableDispatch(filter('paycheck-view', 'NOT_VIEW'));
			isLoadLast.current = true;
		} else {
			tableDispatch({ type: 'RESET_FILTERS', payload: null });
			setSelectedDepartments({
				dep: [],
			});
			isLoadLast.current = true;
		}
		dispatch(incrementBusyIndicator());
		counter.current.push(1);
	}, [userData]);

	const updateIsLoadlast = () => {
		isLoadLast.current = true;
	};

	useEffect(() => {
		if (tableState.filter.unitIds.length || multiValue) {
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(tableState.filter.unitIds),
				),
			);
			loadSalaryListYearsFilter();
			fetchReportLastDate(
				tableDispatch,
				getSelectedUnits(tableState.filter.unitIds),
				updateIsLoadlast,
			);
		}
	}, [tableState.filter.unitIds]);

	useEffect(() => {
		if (
			!route?.params?.isFromDashboard ||
			(route?.params?.isFromDashboard &&
				tableState.filter.month.length &&
				tableState.filter.year.length)
		) {
			if (
				tableState !== initialState &&
				(tableState.filter.unitIds.length || multiValue) &&
				isLoadLast.current
			) {
				debouncedUpdateData(tableState);
				isLoadLast.current = false;
			}
			navigation.setParams({ isFromDashboard: undefined });
		}
	}, [tableState]);

	useEffect(() => {
		setSalaryFilters(state => ({
			...state,
			department: departmentsFilterList,
		}));
	}, [departmentsFilterList]);

	useEffect(() => {
		const pageNumber = pageNumberCalc();
		setCurrentPageSelected(pageNumber);
	}, [selectedSalaryReport]);

	const disabledLoadMore = useMemo(
		() => salaryListReport.hasNext,
		[salaryListReport, tableState.page],
	);

	const onLoadMore = () => {
		if (disabledLoadMore) {
			isLoadLast.current = true;
			tableDispatch(incrementPage());
		}
	};

	const debouncedUpdateData = useCallback(
		debounce((data: SalaryReportsRequest) => {
			loadSalaryList(data);
		}, 500),
		[businessUnitsList],
	);

	const loadSalaryList = ({
		page,
		pageSize,
		sortBy,
		sortDirection,
		filter,
	}: SalaryReportsRequest) => {
		httpService
			.api<FullSalaryReportListLike>({
				type: 'getSalaryReportList',
				data: {
					p: page,
					ps: pageSize,
					s: sortBy,
					sd: sortDirection,
					f: {
						...filter,
						unitIds: getSelectedUnits(filter.unitIds),
					},
				},
			})
			.then(async (res: any) => {
				if (res) {
					const reqData = res.data.map(
						(fw: FullSalaryReportList) =>
							new FullSalaryReportList(fw),
					);
					if (!res.data[0]) {
						setIsRowSelected(false);
						setSelectedSalaryReport(null);
						setReportNotFound(true);
					} else setReportNotFound(false);
					if (page === 0) {
						setSalaryListReport(state => ({
							hasNext: res.hasNext,
							total: res.total,
							data: reqData,
						}));
					} else {
						setSalaryListReport(state => ({
							hasNext: res.hasNext,
							total: res.total,
							data: [...state.data, ...reqData],
						}));
					}
					setSelectedSalaryReport(reqData[0]);
				}
			})
			.catch(err => setSalaryListReport(initSalaryListReport))
			.finally(() => {
				getState().config.busyCounter &&
					counter.current.forEach(() =>
						dispatch(decrementBusyIndicator()),
					);
				counter.current = [];
			});
	};

	const loadSalaryListYearsFilter = () => {
		httpService
			.api<number[]>({
				type: 'getSalaryReportListYearsFilter',
				query: {
					unitIds: getSelectedUnits(tableState.filter.unitIds).join(),
				},
			})
			.then(async (res: any) => {
				if (res) {
					setSalaryFilters(state => ({ ...state, years: res }));
				}
			})
			.catch(err => setSalaryFilters({ ...salaryFilters, years: [] }));
	};

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: pageFilterSize,
			search,
			filter: selectedDepartments,
			unitIds: getSelectedUnits(tableState.filter.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: pageFilterSize === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const pageNumberCalc = () => {
		if (
			isRowSelected &&
			salaryListReport.data.length &&
			selectedSalaryReport
		) {
			const indexNumber = salaryListReport.data.findIndex(current => {
				return (
					current.teudatZeut === selectedSalaryReport.teudatZeut &&
					current.fullName === selectedSalaryReport.fullName &&
					current.departmentName ===
						selectedSalaryReport.departmentName &&
					current.salaryMonth === selectedSalaryReport.salaryMonth
				);
			});
			return indexNumber + 1;
		}
		return 1;
	};

	const handleSorting = useCallback((field: any, direction: any) => {
		isLoadLast.current = true;
		tableDispatch(sort(field, direction));
	}, []);

	const filtersData = useMemo(
		() =>
			createFilterOptionsForTable(
				salaryFilters,
				getEmployeesFilter,
				selectedDepartments,
				tableState,
				businessUnitsList,
				isBusinessUnitsMulti,
				selectedPaycheckFilter,
				`${JSON.stringify(userData)}${JSON.stringify(
					tableState.filter.unitIds,
				)}`,
			),
		[
			selectedDepartments,
			salaryFilters,
			userData,
			tableState,
			selectedPaycheckFilter,
		],
	);

	const exportExcel = async () => {
		const res: any = await httpService.api({
			type: 'exportPaycheckList',
			data: {
				f: {
					...tableState.filter,
					unitIds: getSelectedUnits(tableState.filter.unitIds),
				},
			},
			responseType: 'blob',
			returnAllRes: true,
		});
		getExcelFileFromResponse(res);
	};

	const downloadPaycheckHandler = async () => {
		const yearMonthArr = selectedSalaryReport
			? selectedSalaryReport.salaryMonth.split('/')
			: ['Month', 'Year'];
		const name = `${yearMonthArr[0]} - ${yearMonthArr[1]} - ${selectedSalaryReport?.teudatZeut}`;
		download(templateDataWeb.template, name);
	};

	const onFetchPaycheckDataHandler = useCallback(
		(templateData: TemplateDataType) => {
			templateDataWeb = { ...templateData };
		},
		[],
	);

	const selectedRowHandler = (rowData: SalaryReportDataType) => {
		setSelectedSalaryReport(rowData);
		setIsRowSelected(true);
		navigation.navigate('salaryReports', { screen: 'details' });
	};

	const navigateBack = () => {
		setSelectedSalaryReport(null);
		setIsRowSelected(false);
		navigation.navigate('salaryReports', { screen: 'table' });
	};

	const handlePaginationChange = (curentPage: number) => {
		const total = salaryListReport.data.length;
		const hasNext = salaryListReport.hasNext;
		const visible =
			length < PAGINATION_PAGES_VISIBLE
				? length
				: PAGINATION_PAGES_VISIBLE;

		if (curentPage <= total) {
			const selectedRow = salaryListReport.data[curentPage - 1];
			setSelectedSalaryReport(selectedRow);
		} else if (hasNext && total - curentPage < visible + 1) {
			const selectedRow = salaryListReport.data[curentPage - 1];
			setSelectedSalaryReport(selectedRow);
			isLoadLast.current = true;
			tableDispatch(incrementPage());
		}
	};

	return (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<SalaryReportPage
				navigation={navigation}
				data={salaryListReport.data}
				reportNotFound={reportNotFound}
				headers={salaryReportHeaders(businessUnitsList)}
				filtersData={filtersData}
				onSort={handleSorting}
				onLoadMore={onLoadMore}
				onFilter={handleFilter}
				onRowClick={data => selectedRowHandler(data)}
				isRowSelected={isRowSelected}
				selectedRowData={selectedSalaryReport}
				onFetchPaycheckData={onFetchPaycheckDataHandler}
				onDownloadPaycheck={downloadPaycheckHandler}
				pageCurrent={currentPageSelected}
				pageTotal={salaryListReport.total}
				navigateBack={navigateBack}
				onPaginationChange={handlePaginationChange}
				exportExcel={exportExcel}
			/>
		</View>
	);
};

export default SalaryReportsPageContainer;
