import React from 'react';
import CompleteEntryComponent from './CompleteEntryModal.component';
import {Option} from "../EventsAndProjectsSelect/EventsAndProjectsSelect.utils"
interface Props {
	submitBtnText: string;
	cancelBtnText: string;
	prevValues: PrevValues;
	projectsOptions:any[],
	eventsOptions:any[],
	shiftHours:number;
	onSubmit: Function;
	onCancel: Function;
	onNext: Function;
	checkIfCanEntry: Function
	totalReported:number
}
interface PrevValues {
	date: string;
	exitTime: string;
	projectId: string;
	eventId: string;
}

const CompleteEntryContainer = ({
	prevValues,
	submitBtnText,
	cancelBtnText,
	onSubmit,
	onCancel,
	projectsOptions,
	eventsOptions,
	shiftHours,
	onNext,
	checkIfCanEntry,
	totalReported,
}: Props) => {
	return (
		<CompleteEntryComponent
			totalReported = {totalReported}
			onNext = {onNext}
			shiftHours={shiftHours}
			projectsOptions={projectsOptions}
			eventsOptions = {eventsOptions}
			cancelBtnText={cancelBtnText}
			handleSubmit={onSubmit}
			submitBtnText={submitBtnText}
			onCancel={onCancel}
			prevValues={prevValues}
			checkIfCanEntry={checkIfCanEntry}
		/>
	);
};
export default CompleteEntryContainer;
