import { yupResolver } from '@hookform/resolvers/yup';
import Collapsible from 'react-native-collapsible';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Dimensions, Platform, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import FieldBuilder from '../../../../../components/shared/FieldBuilder/FieldBuilder';
import { Privileges } from '../../../../../constants/roleTypes.constant';
import useDevicePlatform, {
	DeviceType,
} from '../../../../../hooks/useDevicePlatform.hook';
import {
	getBussinesUnitsModules,
	getUserData,
} from '../../../../../store/selectors/login.selectors';
import { getFields, unitDataValidation } from './UnitDataSection.utils';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import Select from '../../../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import getShadowStyle from '../../../../../config/shadow';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import { hasPermissionOnBusinessUnit } from '../../../../../utils/globals.utils';
import FormTextInput from '../../../../../components/shared/FieldBuilder/TextInput/TextInput.component';
import { getBusinessUnitsList } from '../../../../../store/selectors/reportsFilters.selectors';
import { adminRoles, repRoles } from '../../createUpdateUser.utils';

const wrapperStyles = {
	width: 227,
};

const labelWrapperStyles = {
	flexDirection: 'row',
};

const UnitsDataSection = React.memo(
	({
		departments,
		unitData,
		children,
		validationFunctions,
		getValuesFunctions,
		shouldShowTitle,
		businessUnitsList,
		onSelectUnit,
		selectedUnitsList,
		onDeleteUnit,
		disabled,
		canDelete,
		defaultOpenUnit,
		setRoles,
		index,
		isUpdateHimself,
	}: any) => {
		const platform = useDevicePlatform();
		const isMobile = platform !== DeviceType.WEB;
		const [isCollapse, setIsCollapse] = useState(
			businessUnitsList?.length > 1 &&
				defaultOpenUnit !== unitData.id &&
				canDelete,
		);
		const modules = useSelector(getBussinesUnitsModules);
		const fullBusinessUnitsList = useSelector(getBusinessUnitsList);
		const userData = useSelector(getUserData);
		const setValidation = useRef<any>(false);
		const unitDataRef = useRef<any>(unitData);
		const selectedUnitData = useMemo(
			() =>
				fullBusinessUnitsList.find(
					(unit: any) =>
						unit.id.toString() === unitData.id.toString(),
				),
			[fullBusinessUnitsList, unitData.id],
		);

		const businessUnitsOptions = useMemo(
			() =>
				businessUnitsList.filter(
					(unit: any) =>
						unit.id === unitData.id ||
						!selectedUnitsList.includes(unit.id),
				),
			[businessUnitsList, unitData, selectedUnitsList],
		);

		unitDataRef.current = unitData;

		const has113Module = useMemo(() => {
			if (selectedUnitData && modules) {
				if (!modules || !selectedUnitData) {
					return false;
				}

				const module = modules?.[selectedUnitData.id];
				const module113 = module?.find(
					(module: any) => module.id === 113,
				);

				if (module113) {
					return true;
				}

				return false;
			}

			return true;
		}, [modules]);

		const fields = useMemo(
			() =>
				getFields(
					departments ?? [],
					isMobile,
					unitData.isEditUnit,
					disabled,
					unitData.id,
					has113Module,
					!userData?.employerRolePrivileges?.includes(
						Privileges.ROLE_CREATE_USER_READ,
					) &&
						!userData?.employerRolePrivileges?.includes(
							Privileges.ROLE_CREATE_USER_WRITE,
						) &&
						!userData?.repRolePrivileges?.includes(
							Privileges.ROLE_CREATE_USER_READ,
						) &&
						!userData?.repRolePrivileges?.includes(
							Privileges.ROLE_CREATE_USER_WRITE,
						),
					modules[unitData.id]?.find(
						(modul: any) => modul.id !== 115,
					),
					unitData,
					setRoles,
				),
			[
				departments,
				userData,
				isMobile,
				unitData,
				has113Module,
				businessUnitsList,
				modules[unitData.id],
			],
		);

		const formMethods = useForm({
			defaultValues: {
				department_id: unitData.department_id,
				employee_type: unitData.employee_type,
			},
			resolver: yupResolver(unitDataValidation),
			mode: 'onBlur',
			reValidateMode: 'onChange',
		});

		const getIsNotValidate = async () => {
			const result = await formMethods.trigger();

			if (Object.keys(formMethods.formState.errors).length) {
				setIsCollapse(false);
				return true;
			}

			if (
				!hasPermissionOnBusinessUnit(
					parseInt(unitData.id),
					Privileges.ROLE_MGM_USER_WRITE,
				) ||
				isUpdateHimself
			) {
				return false;
			}

			if (!unitDataRef.current.user_roles?.length) {
				return true;
			}

			if (
				formMethods.getValues()?.employee_type === 'EXTERNAL' &&
				unitDataRef.current.user_roles.includes(6)
			) {
				return { showErrModal: 'editUserModal.employeeType.error' };
			}

			if (
				unitDataRef.current.user_roles.filter((role: number) =>
					adminRoles.includes(role),
				).length > 1
			) {
				return { showErrModal: 'editUserModal.dupAdminRoles' };
			}

			if (
				unitDataRef.current.user_roles?.filter((role: number) =>
					repRoles.includes(role),
				).length > 1
			) {
				return { showErrModal: 'editUserModal.dupRepRoles' };
			}

			const rolesWithAllocatedDepartment = unitDataRef.current.roles
				?.filter((role: any) => role.allocate_department)
				.map((el: any) => el.id);

			let isNotValid = false;
			if (rolesWithAllocatedDepartment.length) {
				for (const el of rolesWithAllocatedDepartment) {
					if (unitDataRef.current.user_roles.includes(el)) {
						if (
							!Object.keys(
								unitDataRef.current.user_role_department,
							).length
						) {
							isNotValid = true;
						}
						if (
							unitDataRef.current.user_roles.includes(el) &&
							!unitDataRef.current?.user_role_department[el]
								?.length
						) {
							isNotValid = true;
						}
					}
				}
			}

			isNotValid && setIsCollapse(false);

			return isNotValid;
		};

		const getValues = (initialData: any) => {
			let update = true;
			const user_role_rep_customer_info = unitDataRef.current
				.user_role_rep_customer_info
				? Object.keys(
						unitDataRef.current.user_role_rep_customer_info,
				  ).reduce(
						(res, current) => ({
							[current]: _.omit(
								unitDataRef.current.user_role_rep_customer_info[
									current
								],
								['all_customer'],
							),
						}),
						{},
				  )
				: null;
			const omitArr = [
				'roles',
				'departments',
				'id',
				'allow_delete',
				'isEditUnit',
				!hasPermissionOnBusinessUnit(
					parseInt(unitData.id),
					Privileges.ROLE_REP_CUSTOMER_READ,
				) &&
				!hasPermissionOnBusinessUnit(
					parseInt(unitData.id),
					Privileges.ROLE_REP_CUSTOMER_WRITE,
				)
					? 'user_role_rep_customer_info'
					: '',
			];
			const data = {
				..._.omit(unitDataRef.current, omitArr),
				...(!hasPermissionOnBusinessUnit(
					parseInt(unitData.id),
					Privileges.ROLE_REP_CUSTOMER_WRITE,
				)
					? {}
					: { user_role_rep_customer_info }),
				...formMethods.getValues(),
			};

			if (
				JSON.stringify(_.omit(initialData || {}, omitArr)) ===
				JSON.stringify(data)
			) {
				update = false;
			}
			return hasPermissionOnBusinessUnit(
				parseInt(unitData.id),
				Privileges.ROLE_MGM_USER_WRITE,
			) && !isUpdateHimself
				? {
						[unitDataRef.current.id]: {
							...data,
							update,
						},
				  }
				: {
						[unitDataRef.current.id]: {
							...formMethods.getValues(),
							update,
						},
				  };
		};

		useEffect(() => {
			if (
				!setValidation.current &&
				validationFunctions?.current &&
				getValuesFunctions?.current &&
				(!getValuesFunctions?.current.has(unitData.id) ||
					!validationFunctions?.current.has(unitData.id))
			) {
				getValuesFunctions.current.set(unitData.id, getValues);
				validationFunctions.current.set(unitData.id, getIsNotValidate);
				setValidation.current = true;
			}
		}, []);

		useEffect(() => {
			if (
				!formMethods.getValues('employee_type') &&
				unitData.employee_type
			) {
				formMethods.setValue('employee_type', unitData.employee_type);
			}
		}, [unitData.employee_type]);

		useEffect(() => {
			if (
				!formMethods.getValues('department_id') &&
				unitData.department_id
			) {
				formMethods.setValue('department_id', unitData.department_id);
			}
		}, [unitData.department_id]);

		return (
			<View
				style={{
					borderWidth: !isMobile && !shouldShowTitle ? 1 : 0,
					borderRadius: !isMobile ? 5 : 0,
					borderColor: '#E0E0E0',
					marginLeft: !isMobile ? 5 : 0,
					marginBottom: 9,
					...(isMobile && !shouldShowTitle
						? getShadowStyle(
								Platform.OS === 'web' ? 10 : 1,
								'rgb(59, 112, 164)',
						  )
						: {}),
					zIndex: selectedUnitsList?.length - index,
				}}
			>
				{!shouldShowTitle && (
					<TouchableOpacity
						onPress={() => setIsCollapse(!isCollapse)}
						style={{
							flexDirection: isMobile ? 'row-reverse' : 'row',
							paddingVertical: 13,
							paddingHorizontal: 18,
						}}
					>
						{!isMobile && (
							<ImageIcon
								name='leftArrow'
								width={8.5}
								height={16}
								style={{
									transform: [
										{
											rotate: isCollapse
												? '0deg'
												: '-90deg',
										},
									],
									marginLeft: 10,
								}}
							/>
						)}
						{isCollapse ? (
							<Typography weight='normal' size={16}>
								{selectedUnitData?.name}
							</Typography>
						) : null}
						<View style={{ flex: 1 }} />
						{canDelete &&
							!unitData.isEditUnit &&
							hasPermissionOnBusinessUnit(
								parseInt(unitData.id),
								Privileges.ROLE_CREATE_USER_WRITE,
							) &&
							(unitData.allow_delete ||
								!unitData.hasOwnProperty('allow_delete')) && (
								<TouchableOpacity
									style={{ paddingRight: 10 }}
									onPress={() => onDeleteUnit(unitData.id)}
								>
									<ImageIcon
										name='delete'
										width={17.5}
										height={20}
									/>
								</TouchableOpacity>
							)}
					</TouchableOpacity>
				)}
				<Collapsible collapsed={isCollapse}>
					<View
						style={{
							paddingBottom: isMobile ? 30 : 80,
							width: isMobile
								? Dimensions.get('window').width
								: undefined,
							paddingHorizontal: isMobile
								? 20
								: !shouldShowTitle
								? 40
								: 0,
						}}
					>
						<FormProvider {...formMethods}>
							<View
								style={{
									flexDirection: isMobile ? 'column' : 'row',
									zIndex: 10,
								}}
							>
								{businessUnitsList.length > 1 ? (
									<View
										style={{
											width: isMobile
												? Dimensions.get('screen')
														.width - 40
												: 227,
											maxWidth: '100%',
											zIndex:
												Platform.OS === 'android' ||
												Platform.OS === 'ios'
													? 10
													: 'unset',
											alignItems: isMobile
												? 'flex-end'
												: 'flex-start',
											marginBottom: isMobile ? 21 : 0,
											marginLeft: isMobile ? 0 : 10,
										}}
									>
										<View
											style={{
												flexDirection: isMobile
													? 'row-reverse'
													: 'row',
												marginBottom: 7,
												alignItems: 'center',
											}}
										>
											<I18n
												size={12}
												style={{
													textAlign: 'right',
													height: 'auto',
												}}
												weight='normal'
											>
												general.businessUnits
											</I18n>
											<Typography
												style={{
													marginLeft: 2,
													textAlign: 'right',
												}}
												size={14}
												color='red'
											>
												*
											</Typography>
										</View>
										{unitData.isEditUnit ? (
											<FormTextInput
												editable={false}
												value={selectedUnitData?.name}
											/>
										) : (
											<Select
												options={businessUnitsOptions}
												onChange={(value: any) =>
													onSelectUnit(
														value,
														unitData.id,
													)
												}
												noPlaceholder
												value={unitData.id}
												disabled={
													disabled ||
													(unitData.hasOwnProperty(
														'allow_delete',
													) &&
														!unitData.allow_delete)
												}
											/>
										)}
									</View>
								) : null}
								{fields.map((field: any) => (
									<FieldBuilder
										key={field.name}
										viewOnly={field.viewOnly}
										{...field}
										styles={{
											wrapper: field.styles?.wrapper
												? field.styles?.wrapper
												: wrapperStyles,
											labelWrapper: isMobile
												? undefined
												: field.styles?.labelWrapper
												? field.styles?.labelWrapper
												: labelWrapperStyles,
										}}
									/>
								))}
							</View>
							{hasPermissionOnBusinessUnit(
								parseInt(unitData.id),
								Privileges.ROLE_MGM_USER_WRITE,
							) && !isUpdateHimself ? (
								<>
									{shouldShowTitle && (
										<I18n
											size={20}
											weight='normal'
											style={{ marginTop: 36 }}
										>
											editUserModal.permissions.title
										</I18n>
									)}
									{children}
								</>
							) : null}
						</FormProvider>
					</View>
				</Collapsible>
			</View>
		);
	},
);

export default UnitsDataSection;
