import React, { useEffect, useState } from 'react';
import {
	FlatList,
	TouchableOpacity,
	View,
	StyleSheet,
	Dimensions,
	Platform,
} from 'react-native';
import OutlineButtonContainer from '../../../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../../../components/shared/Buttons/SolidButton.container';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import colors from '../../../../../config/colors';
import ExtraDataRow from './ExtraDataRow';

const SelectExtraDataModal = ({
	selectedData,
	allData,
	close,
	role,
	title,
	subTitle,
	disabled,
	checkAll,
	isSelected,
	onSubmit,
}: any) => {
	const [selected, setSelected] = useState(selectedData || []);
	const onCheck = (itemId: number | string, value: boolean) => {
		if (itemId === 'all') {
			if (!value) {
				setSelected([]);
			} else {
				setSelected(allData.map((d: any) => d.id));
			}
			return;
		}

		if (value) {
			setSelected([...selected, itemId]);
		} else {
			setSelected(selected.filter((s: any) => s !== itemId));
		}
	};

	const _onSubmit = () => {
		onSubmit(selected.filter((i: any) => i !== 'all'));
		close();
	};

	useEffect(() => {
		setSelected(selectedData || []);
	}, [selectedData]);

	return (
		<View style={styles.wrapper}>
			<View style={styles.header}>
				<Typography size={28} weight='normal' style={{ flex: 1 }}>
					{role.name}
				</Typography>
				<TouchableOpacity onPress={() => close()}>
					<ImageIcon name='leftArrow' />
				</TouchableOpacity>
			</View>
			<I18n size={18} weight='normal' style={{ marginTop: 21 }}>
				{title}
			</I18n>
			<I18n size={14} style={{ marginTop: 6 }}>
				{subTitle}
			</I18n>
			<FlatList
				style={{ marginTop: 17, flex: 1, marginBottom: 60 }}
				data={allData}
				contentContainerStyle={{ paddingBottom: 50 }}
				renderItem={({ item, ...rest }) => (
					<ExtraDataRow
						isChecked={
							(item?.id === 'all' &&
								selected.length === allData.length - 1) ||
							isSelected?.(item, selected) ||
							!!selected.includes(item.id) ||
							(!selected.length && allData[0]?.id !== 'all')
						}
						disabled={disabled}
						item={item}
						roleId={role.id}
						onCheck={onCheck}
						{...rest}
					/>
				)}
				keyExtractor={item => item.id}
			/>
			<View
				style={{
					flexDirection: 'row-reverse',
					height: 120,
					marginTop: 10,
				}}
			>
				<SolidButtonContainer
					disabled={!selected?.length || disabled}
					overrideStyle={{ minWidth: 90 }}
					onPress={_onSubmit}
				>
					general.update
				</SolidButtonContainer>
				<OutlineButtonContainer
					size='medium'
					onPress={close}
					overrideStyle={{ minWidth: 90, marginRight: 15 }}
				>
					general.cancel
				</OutlineButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		paddingLeft: 3,
	},
	wrapper: {
		width: Dimensions.get('screen').width,
		height: Dimensions.get('window').height,
		backgroundColor: colors.white,
		opacity: 1,
		paddingVertical: 25,
		paddingHorizontal: 16,
		top: 0,
		left: 0,
		position: Platform.OS !== 'web' ? 'absolute' : undefined,
	},
});

export default SelectExtraDataModal;
