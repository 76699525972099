import React, { useState, useEffect } from 'react';
import ReportsCalendar from './ReportsCalendar.component';
import { DateObject } from 'react-native-calendars';
import { useDispatch, useSelector } from 'react-redux';
import { setChosenDate } from '../../../store/actions/clockReport.actions';
import httpService from '../../../services/http.service';
import moment from 'moment';
import ReportsTimeSumContainer from '../../../components/shared/ReportsCalendar/ReportsTimeSum.container';
import { useNavigationState } from '@react-navigation/native';
import {
	getReFetchedFlag,
	getChosenDate,
} from '../../../store/selectors/clockReport.selector';
import { getUserData } from '../../../store/selectors/login.selectors';
interface CalendarReport {
	date: string;
	standard: number;
	workLogs: number;
	enters: number;
	exits: number;
}
export const CalendarData = React.createContext([]);

const ReportsCalendarContainer = ({ navigation }: { navigation?: any }) => {
	const routeName = useNavigationState(
		state => state.routeNames[state.index],
	);
	const isRefetched = useSelector(getReFetchedFlag);
	const chosenDate = useSelector(getChosenDate);
	const userData = useSelector(getUserData);
	const dispatch = useDispatch();
	const [calendarData, setData] = useState<any>({});

	const isDisabled = (date: string) =>
		moment(date, 'YYYY-MM-DD').isAfter(
			moment(
				userData?.operative_module?.[101]?.finish_work,
				'YYYY-MM-DD',
			),
		);

	const handleDateChange = async (date: DateObject) => {
		dispatch(setChosenDate(date.dateString));
		getCalendarData(date.dateString);
	};

	const getCalendarData = async date => {
		if (routeName !== 'chosenDate') {
			try {
				if (isDisabled(date)) {
					setData([]);
					return;
				}
				const res = await httpService.api<any>({
					type: 'reportsByMonth',
					params: {
						month: moment(date, 'YYYY-MM-DD').month() + 1, // +1 because it is an array [0 - 11] (dec = 11)
						year: moment(date, 'YYYY-MM-DD').year(),
					},
				});
				if (res) {
					setData(res);
				}
			} catch (e) {}
		}
	};

	useEffect(() => {
		getCalendarData(chosenDate || moment().format('YYYY-MM-DD'));
	}, [isRefetched, userData, userData?.operative_module?.[101]?.finish_work]);

	return (
		<CalendarData.Provider value={calendarData}>
			<ReportsTimeSumContainer />
			<ReportsCalendar
				onDateChange={handleDateChange}
				navigation={navigation}
			/>
		</CalendarData.Provider>
	);
};

export default ReportsCalendarContainer;
