import { Checkbox as MuiCheckbox, makeStyles } from '@material-ui/core';
import React from 'react';
import colors from '../../../config/colors';

const useStyles = makeStyles(() => ({
	chip: {
		borderRadius: 2,
		color: 'hsl(0, 0%, 20%)',
		fontSize: '85%',
		padding: 3,
		paddingLeft: 6,
		margin: 2,
		backgroundColor: 'hsl(0, 0%, 90%)',
	},
	checkbox: {
		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
		},
	},
	checked: {},
}));

const Checkbox = ({
	isChecked,
	onClick,
	disabled,
}: {
	isChecked?: boolean;
	onClick: any;
	disabled?: boolean;
}) => {
	const classes = useStyles();

	return (
		<MuiCheckbox
			size='small'
			color='default'
			checked={isChecked}
			disabled={disabled}
			onClick={onClick}
			classes={{
				root: classes.checkbox,
				checked: classes.checked,
			}}
		/>
	);
};

export default Checkbox;
