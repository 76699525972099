import React, { useCallback, useEffect, useRef, useState } from 'react';
import _, { debounce } from 'lodash';
import {
	FlatList,
	TouchableOpacity,
	View,
	StyleSheet,
	Dimensions,
	Platform,
} from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import SearchInputContainer from '../../../../components/shared/SearchInput/SearchInput.container';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import Row from './Row.component';
import SolidButtonContainer from '../../../../components/shared/Buttons/SolidButton.container';
import OutlineButtonContainer from '../../../../components/shared/Buttons/OutlineButton.container';

const SelectEmpMobileModal = ({
	selectedData,
	onSubmit,
	close,
	disabled,
	onFilter,
	isMulti,
}: any) => {
	const [options, setOptions] = useState<any[]>([]);
	const [search, setSearch] = useState();
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [selectedOptions, setSelectedOptions] = useState<any[]>(
		selectedData || [],
	);
	const debounceRef = useRef<any>();

	useEffect(() => {
		loadOptions();
	}, []);

	const selectionsChangeHandler = (
		id: number | string,
		value?: boolean,
		item?: any,
	) => {
		if (isMulti) {
			const selections = value
				? [...selectedOptions, item]
				: selectedOptions.filter(
						(selected: any) => selected.value !== id,
				  );

			setSelectedOptions(selections);
		} else {
			onSubmit(item);
		}
	};

	const loadOptions = useCallback(
		async (localSearch?: string, resetPage?: boolean) => {
			const res = await onFilter(
				localSearch || localSearch === '' ? localSearch : search,
				null,
				{
					page: resetPage ? 0 : page,
				},
				!isMulti,
			);

			if (selectedOptions.length) {
				const tempSelected = selectedOptions.map(opt =>
					opt?.value
						? opt
						: res.options.find((o: any) => o.value === opt) || opt,
				);
				if (
					JSON.stringify(selectedOptions) !==
					JSON.stringify(tempSelected)
				) {
					setSelectedOptions(tempSelected);
				}
			}

			setHasMore(res.hasMore);
			if (resetPage || !page) {
				setPage(1);
				setOptions([...res.options]);
			} else {
				setPage(page + 1);
				setOptions([...options, ...res.options]);
			}
		},

		[search, page, selectedOptions, options],
	);

	const debouncedLoadMore = useCallback(
		(localSearch?: string, resetPage?: boolean) => {
			if (debounceRef.current) {
				clearTimeout(debounceRef.current);
			}
			debounceRef.current = setTimeout(() => {
				loadOptions(localSearch, resetPage);
			}, 500);
		},
		[search, page, selectedOptions, options],
	);

	const _onSubmit = () => {
		onSubmit(selectedOptions);
		close();
	};

	return (
		<View style={styles.wrapper}>
			<View style={styles.header}>
				<Typography size={28} weight='normal' style={{ flex: 1 }}>
					{/* {role.name} */}
				</Typography>
				<TouchableOpacity onPress={() => close()}>
					<ImageIcon name='leftArrow' />
				</TouchableOpacity>
			</View>
			<View style={{ flexDirection: 'row-reverse', marginTop: 21 }}>
				<I18n size={18} weight='normal'>
					{`sendingDocument.${
						isMulti ? 'chooseExtraRecipients' : 'chooseEmployee'
					}`}
				</I18n>
				<View style={{ flex: 1 }} />
			</View>

			<View style={{ flexDirection: 'row-reverse', marginTop: 14 }}>
				<SearchInputContainer
					onChange={s => {
						debouncedLoadMore(s.value, true);
						setSearch(s.value);
					}}
					config={{ type: '', name: '', defaultValue: undefined }}
					styleConf={{
						container: {
							flex: 1,
							borderRadius: 9,
							backgroundColor: colors.brightBlue,
						},
						input: {
							borderRadius: 9,
							backgroundColor: colors.brightBlue,
						},
					}}
				/>
			</View>
			<FlatList
				style={{ marginTop: 20, flex: 1 }}
				onEndReached={() => {
					hasMore &&
						options?.length &&
						page &&
						debouncedLoadMore?.(undefined);
				}}
				data={options}
				contentContainerStyle={{ paddingBottom: 50 }}
				renderItem={({ item, ...rest }) => {
					const isChecked = !!selectedOptions.find(
						opt => opt.value === item.value,
					);
					return (
						<Row
							isMulti={isMulti}
							isChecked={isChecked}
							disabled={
								!isChecked && selectedOptions.length === 3
							}
							item={item}
							onCheck={selectionsChangeHandler}
							{...rest}
						/>
					);
				}}
				keyExtractor={(item, index) => `${item.value}-${index}`}
			/>
			{isMulti && (
				<View
					style={{
						flexDirection: 'row-reverse',
						height: 40,
						marginTop: 10,
					}}
				>
					<SolidButtonContainer
						overrideStyle={{ minWidth: 90 }}
						onPress={_onSubmit}
						disabled={disabled}
					>
						general.save
					</SolidButtonContainer>
					<OutlineButtonContainer
						size='medium'
						onPress={close}
						overrideStyle={{ minWidth: 90, marginRight: 15 }}
					>
						general.cancel
					</OutlineButtonContainer>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		paddingLeft: 3,
	},
	wrapper: {
		width: Dimensions.get('screen').width,
		height:
			Dimensions.get('window').height - (Platform.OS === 'ios' ? 50 : 0),
		backgroundColor: colors.white,
		opacity: 1,
		paddingVertical: 25,
		paddingHorizontal: 16,
		position: Platform.OS !== 'web' ? 'absolute' : undefined,
		top: 0,
		left: 0,
	},
});

export default SelectEmpMobileModal;
