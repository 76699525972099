import { backgroundRepeat } from 'html2canvas/dist/types/css/property-descriptors/background-repeat';
import moment from 'moment';
import React from 'react';
import { View, StyleSheet, useWindowDimensions, Platform } from 'react-native';
import { FAB } from 'react-native-paper';
import HeaderContainer from '../../components/shared/Header/Header.container';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import I18n from '../../components/shared/i18n/I18n.component';
import ReportRangeListContainer from '../../components/shared/ReportRangeList/ReportRangeList.container';
import colors from '../../config/colors';
import routes from '../../config/routes';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { ListType } from '../../interfaces/core.interface';
import { i18nService } from '../../services/i18n.service';
import { onPressCreate } from '../WorkerCard/workerCard.utils';
import {
	Employee101Data,
	Employee101YearStatus,
	PDFDateRange,
} from './ReportPDFPage.utils';

const ReportPDFPage = ({
	navigation,
	dateRange,
	innerHeaderData,
	noReports,
	isLoading,
	displayHeader = true,
	enableCreate,
	title,
	is101Page,
	...restProps
}: {
	navigation: any;
	dateRange: PDFDateRange | Employee101YearStatus[];
	innerHeaderData: (selectedDate: Employee101YearStatus) => Employee101Data;
	listType: ListType;
	title: string;
	rangeTitle: string;
	noReports: string;
	isLoading: boolean;
	is101Page: boolean;
	enableCreate: boolean;
	displayHeader: boolean;
}) => {
	const windowHeight = useWindowDimensions().height;
	const isInMobileView = useDevicePlatform() !== DeviceType.WEB;
	const containerStyle = StyleSheet.flatten([
		styles.container,
		{ height: windowHeight - 100 },
		{
			paddingRight: isInMobileView ? 0 : 22,
			paddingLeft: isInMobileView ? 0 : 37,
		},
	]);
	return (
		<>
			{displayHeader && (
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
			)}
			<View style={containerStyle} nativeID='ReportPDFPageMobile'>
				{isLoading ? null : (dateRange as PDFDateRange).first &&
				  (dateRange as PDFDateRange).last ? (
					<ReportRangeListContainer
						navigation={navigation}
						minDate={`${(dateRange as PDFDateRange).first}-01`}
						maxDate={`${(dateRange as PDFDateRange).last}-01`}
						navigateToRoute={routes.pdfReportView.name}
						is101Page={is101Page}
						{...restProps}
					/>
				) : (dateRange as Employee101YearStatus[]).length ? (
					<ReportRangeListContainer
						navigation={navigation}
						dateList={dateRange as Employee101YearStatus[]}
						navigateToRoute={
							restProps.is101 && routes.report101View.name
						}
						is101Page={is101Page}
						innerHeaderData={innerHeaderData}
						enableCreate={enableCreate}
						{...restProps}
					/>
				) : (
					<>
						<View style={styles.titleSection}>
							<I18n
								size={36}
								weight='normal'
								style={{
									flex: 1,
									paddingTop: 20,
									paddingBottom: 10,
									paddingRight: 22,
								}}
							>
								{title}
							</I18n>
						</View>
						<View style={styles.errorMessage}>
							<I18n weight='bold' size={24}>
								{noReports}
							</I18n>
						</View>
						{is101Page && enableCreate ? (
							<FAB
								style={styles.fab}
								small
								color={colors.white}
								icon=''
								label={i18nService.translate(
									'report101ReportPage.add',
								)}
								onPress={() => onPressCreate(navigation)}
							/>
						) : null}
					</>
				)}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: colors.tableBackground,
		paddingRight: 22,
		paddingLeft: 37,
	},
	titleSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	errorMessage: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},

	dataContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginLeft: '21px',
		marginRight: '21px',
	},
	firstRowContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	firstRowDowload: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	firstRowTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	secondRowContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	secondRowContent: {
		display: 'flex',
		flexDirection: 'column-reverse',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	btn: {
		height: 20,
		width: 20,
		justifyContent: 'center',
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	btnDisabled: {
		opacity: 0.5,
	},
	fab: {
		position: 'absolute',
		margin: 16,
		left: 0,
		bottom: Platform.OS === 'ios' ? 80 : 15,
		borderColor: colors.primary,
		backgroundColor: colors.primary,
		borderWidth: 1,
		width: 176,
	},
});

export default ReportPDFPage;
