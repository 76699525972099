import React, { useEffect } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import WorkerCardViewer from '../WorkerCard/WorkerCardEditor/WorkerCardViewer';
import Header from '../../components/shared/Header/Header.container';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import colors from '../../config/colors';
import Report101Page from './Report101Page.container';
import { Platform, View } from 'react-native';
import { useRoute } from '@react-navigation/core';
import { getDocumentTitle } from '../../utils/globals.utils';
import { dispatch } from '../../store/store';
import { setInitialReport101FiltersState } from '../../store/reducers/report101Filter.reducer';

const Tab = createBottomTabNavigator();

const Report101Wrapper = ({ navigation }: any) => {
	const platform = useDevicePlatform();
	const route: any = useRoute();
	const isMobile = platform !== DeviceType.WEB;

	useEffect(() => {
		onMount();
		if (route?.params?.screen === 'table101') {
			navigation.navigate('reports101', { screen: 'table101' });
		}
		return () => {
			dispatch(setInitialReport101FiltersState());
		};
	}, []);

	const onMount = async() => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('Report101Wrapper');
		}
	}

	return (
		<View style={{ flex: 1 }}>
			<Tab.Navigator
				tabBar={({ navigation }: any) => null}
				sceneContainerStyle={{ backgroundColor: 'transparent' }}
				initialRouteName='table101'
				screenOptions={{ unmountOnBlur: true }}
			>
				<Tab.Screen
					name='table101'
					component={Report101Page}
					options={{ title: getDocumentTitle('table101') }}
				/>
				<Tab.Screen
					name='viewer'
					component={WorkerCardViewer}
					options={{ title: getDocumentTitle('viewer') }}
				/>
			</Tab.Navigator>
		</View>
	);
};

export default Report101Wrapper;
