import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import { Dimensions } from 'react-native';
import colors from '../../../config/colors';
import ImageIcon from '../../shared/Icon/ImageIcon.component';

const ModalWrapper = React.forwardRef((props: any, ref: any) => {
	return (
		<div
			style={{
				background: '#fff',
				borderRadius: '20px',
				direction: 'rtl',
				height: '80vh',
				padding: '25px 45px',
				width: '80vw',
			}}
			className='modal-wrapper'
		>
			{typeof props.title === 'string' ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						borderBottom: `5px solid ${colors.primary}`,
						marginBottom: '20px',
					}}
				>
					<h3
						style={{
							fontSize: 22,
							fontFamily: 'RubikRegular',
							color: colors.darkGrey,
						}}
					>
						{props.title}
					</h3>
					{props.titleGoBack && (
						<button
							style={{
								background: 'none',
								border: 'none',
								cursor: 'pointer',
								padding: 0,
								margin: 0,
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							onClick={() => props.onGoBack()}
						>
							<p
								style={{
									fontSize: 16,
									fontFamily: 'RubikRegular',
									color: colors.darkGrey,
									paddingLeft: 10,
								}}
							>
								{props.titleGoBack}
							</p>
							<ImageIcon name='leftArrow' width={10} />
						</button>
					)}
				</div>
			) : (
				props.title
			)}
			<div
				style={{
					height: props.buttons
						? typeof props.title === 'string' &&
						  Dimensions.get('window').height > 728
							? '75%'
							: '65%'
						: 'calc(100% - 85px)',
					overflow: 'auto',
				}}
				ref={ref}
			>
				{props.children}
			</div>
			{props.buttons ? props.buttons : null}
		</div>
	);
});

export default ModalWrapper;
