import React, { useEffect, useMemo } from 'react';
import { default as ReactSelect, components } from 'react-select';
import { StyleProp } from 'react-native';
import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';

interface SelectOption {
	id: string | undefined;
	name: string;
}

interface SelectProps {
	options: SelectOption[];
	placeholder: string;
	style: StyleProp<any>;
	selectedOption?: SelectOption;
	disabled?: boolean;
	onSelection: (option: SelectOption) => void;
	styles?: any;
	forceCustomPlaceholder: boolean;
}

const customStyles = (props: any) => {
	// You can use the 'styles' prop to override the styling of the component.
	const {
		control = {},
		menu,
		option,
		indicatorsContainer,
		indicatorSeparator,
		clearIndicator,
		container,
		placeholder = {},
		singleValue = {},
		input,
		menuPortal,
	} = props.styles || {};

	return {
		control: (base: any) => ({
			...base,
			opacity: props.disabled ? 0.8 : 1,
			minWidth: 170,
			minHeight: 40,
			height: 40,
			borderRadius: 5,
			backgroundColor: colors.lightBlue,
			color: colors.darkGrey,
			borderColor: colors.lightBlue,
			fontSize: 14,
			borderWidth: 1,
			textAlign: 'right',
			boxShadow: 'none',
			width: '100%',
			'&:hover': {
				borderColor: colors.lightBlue,
			},
			...props.style,
			...control,
		}),
		menu: (provided: any) => ({
			...provided,
			top: 42,
			left: 0,
			width: '100%',
			backgroundColor: colors.white,
			borderWidth: 1,
			borderRadius: 2,
			borderColor: 'transparent',
			margin: 0,
			zIndex: 9999999999999,
			...menu,
		}),
		menuPortal: (base: any) => ({
			...base,
			...menuPortal,
		}),
		valueContainer: (base: any) => ({
			...base,
			height: 'inherit',
			whiteSpace: 'nowrap',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			display: 'flex',
			alignItems: 'center',
			height: 29,
			marginTop: 6,
			textAlign: 'right',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			overflow: 'hidden',
			paddingLeft: 5,

			backgroundColor: state.isSelected
				? colors.selectOptionSelected
				: state.isFocused
				? colors.selectOptionFocused
				: colors.white,
			...option,
		}),
		indicatorsContainer: (base: any) => ({
			...base,
			height: 'inherit',
			...indicatorsContainer,
		}),
		dropdownIndicator: (base: any) => ({
			...base,
			color: colors.primary,
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			visibility: 'hidden',
			...indicatorSeparator,
		}),
		clearIndicator: (base: any) => ({
			...base,
			visibility: 'hidden',
			...clearIndicator,
		}),
		container: (provided: any) => ({
			...provided,
			width: '100%',
			...container,
		}),
		placeholder: (base: any) => ({
			...base,
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			...placeholder,
		}),
		singleValue: (provided: any) =>
			// props.iconName ||
			// props.selectType === 'icons' ||
			// props.selectType === 'onlyIcons'
			// 	? {
			// 			...provided,
			// 			marginLeft: props.selectType === 'icons' ? 40 : 27,
			// 			fontSize: 12,
			// 			color: 'var(--systemFont)',
			// 			fontWeight: 500,
			// 			whiteSpace: 'nowrap',
			// 			textOverflow: 'ellipsis',
			// 			display:
			// 				props.selectType === 'onlyIcons' ? 'none' : 'unset',
			// 			overflow: 'hidden',
			// 			...singleValue,
			// 	  }
			({
				...provided,
				fontSize: 14,
				color: colors.darkGrey,
				fontFamily: 'RubikRegular',
				visibility: 'visible',
				...singleValue,
			}),
		input: (provided: any) => ({
			...provided,
			// ...icon(props),
			...input,
			padding: 0,
			margin: 0,
			color: colors.darkGrey,
		}),
	};
};

const SingleValue = (props: any) => {
	return (
		<div title={props.children || ''}>
			<components.SingleValue {...props}>
				{props.children}
			</components.SingleValue>
		</div>
	);
};

const Select = ({
	style,
	options,
	placeholder,
	selectedOption,
	disabled = false,
	onChange,
	onBlur,
	styles,
	value,
	isSearchable,
	forceCustomPlaceholder,
	captureMenuScroll,
	...restProps
}: SelectProps | any) => {
	const renderedPlaceholder = useMemo(
		() =>
			placeholder
				? forceCustomPlaceholder
					? placeholder
					: i18nService.translate(`filter.select.${placeholder}`)
				: '',
		[placeholder],
	);

	// const selectValue = useMemo(() => {
	// 	if (selectedOption && !restProps?.getOptionLabel) {
	// 		return {
	// 			...selectedOption,
	// 			label: i18nService.translate(selectedOption.name),
	// 		};
	// 	}
	// 	return selectedOption;
	// }, [selectedOption]);

	useEffect(() => {
		if (value) {
			onBlur?.();
		}
	}, [value]);

	const defaultGetOptionLabel = (option: SelectOption) => {
		return i18nService.translate(option.name);
	};

	return (
		<ReactSelect
			components={{ SingleValue }}
			isRtl={true}
			placeholder={renderedPlaceholder || ''}
			onChange={(opt: any) => {
				onChange(opt.id);
			}}
			value={
				value
					? options?.find(
							(opt: any) =>
								opt.id?.toString() === value?.toString(),
					  )
					: undefined
			}
			menuPortalTarget={restProps?.menuPortalTarget}
			backspaceRemovesValue
			isClearable={false}
			defaultValue={
				restProps?.defaultValue &&
				!options.find((opt: any) => opt.id === value)
					? options.find(
							(opt: any) => opt.id === restProps?.defaultValue,
					  )
					: undefined
			}
			isDisabled={disabled}
			options={options}
			styles={customStyles({ styles, style, disabled })}
			maxMenuHeight={restProps?.maxMenuHeight}
			getOptionLabel={defaultGetOptionLabel}
			getOptionValue={(opt: any) => opt.id}
			onInputChange={restProps?.onInputChanged}
			onBlur={onBlur}
			noOptionsMessage={() => null}
			onFocus={restProps?.onFocus}
			blurInputOnSelect={restProps?.blurInputOnSelect}
			isSearchable={isSearchable}
			captureMenuScroll={captureMenuScroll}
		/>
	);
};

export default Select;
