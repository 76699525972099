import React from 'react';
import Typography from '../../Typography/Typography.component';
import {
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	makeStyles,
} from '@material-ui/core';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import RadioButtonsGroupMobile from './RadioButtonsGroup.component.native';
import classnames from 'classnames';

type RadioButtonsGroupType = {
	value: any;
	onChange: () => void;
	onBlur: () => void;
	required?: boolean;
	error: any;
	editable?: boolean;
	options: {
		id: any;
		name: string;
	}[];
	outerClasses?: {
		radioButton: any;
		radioButtonLabel: any;
		radioGroup: any;
	};
	styles?: {
		buttonsGroup: any;
		button: any;
	};
	buttonStyles?: {
		buttonsGroup: any;
		button: any;
	};
	forceMobile?: boolean;
};

const useStyles = makeStyles(theme => {
	return {
		radioButton: {
			'&.Mui-checked': {
				color: `${colors.primary}!important`,
			},
			'&.Mui-disabled': {
				color: `${colors.grey}!important`,
			},
		},
		radioButtonLabel: {
			marginLeft: 16,
			marginRight: 0,
		},
		radioGroup: {
			position: 'relative',
		},
		formControlLabel: {
			color: colors.darkGrey,
			fontFamily: 'RubikMedium',
			fontSize: 14,
			fontWeight: 'normal',
		},
	};
});

const RadioButtonsGroup = ({
	value,
	onChange,
	onBlur,
	error,
	required,
	options,
	editable,
	outerClasses,
	styles,
	buttonStyles,
	forceMobile,
}: RadioButtonsGroupType) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	if (isMobile || forceMobile) {
		return (
			<RadioButtonsGroupMobile
				{...{
					value,
					onChange,
					onBlur,
					error,
					required,
					options,
					editable,
					isMobile,
					buttonStyles,
				}}
			/>
		);
	}
	const classes = useStyles();
	const radioButtonsList = options?.map((el: { id: any; name: string }) => {
		return (
			<FormControlLabel
				disabled={editable === false ? true : false}
				value={el.id}
				classes={{
					root: classnames(
						classes.radioButtonLabel,
						outerClasses?.radioButtonLabel,
					),
					label: classes.formControlLabel,
				}}
				style={
					styles?.button
						? {
								...styles?.button,
						  }
						: {}
				}
				control={
					<Radio
						size='small'
						classes={{
							root: classnames(
								classes.radioButton,
								outerClasses?.radioButton,
							),
						}}
					/>
				}
				label={el.name}
			/>
		);
	});
	return (
		<FormControl component='fieldset'>
			{required ? (
				<Typography
					style={{
						marginLeft: 2,
						position: 'absolute',
						left: -8,
						top: 9,
						textAlign: 'right',
					}}
					size={14}
					color='red'
				>
					*
				</Typography>
			) : null}
			<RadioGroup
				row
				value={value}
				onChange={onChange}
				classes={{
					root: classnames(
						classes.radioGroup,
						outerClasses?.radioGroup,
					),
				}}
			>
				{radioButtonsList}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioButtonsGroup;
