import Tooltip from 'rc-tooltip';
import React from 'react';
import { TouchableHighlight } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import httpService from '../../../services/http.service';
import { setRepEnterCustomerMode } from '../../../store/actions/repEnterMode.actions';
import { dispatch } from '../../../store/store';
import { getExcelFileFromResponse } from '../../../utils/downloadFile.web.utils';

const fetchExcel = async ({ filter, reportName, type = null }: any) => {
	try {
		const res = await httpService.api<any>({
			type: type
				? 'exportRepresentsOrgReports'
				: 'exportRepresentsReports',
			responseType: 'blob',
			returnAllRes: true,
			data: {
				f: filter,
			},
			params: {
				reportName,
				type,
			},
		});
		return res;
	} catch (e) {
		console.log(e);
	}
};

export const getExcel = async (
	filter: any[],
	fileName: string,
	reportName: string,
	reportType?: number,
) => {
	const res = await fetchExcel({
		filter,
		reportName,
		type: reportType,
	});
	if (res) {
		getExcelFileFromResponse(res, fileName);
	}
};

export const monthMap: any = {
	1: 'months.January',
	2: 'months.February',
	3: 'months.March',
	4: 'months.April',
	5: 'months.May',
	6: 'months.June',
	7: 'months.July',
	8: 'months.August',
	9: 'months.September',
	10: 'months.October',
	11: 'months.November',
	12: 'months.December',
};
export const getCustomerData = async (customerId: number) => {
	const res: any = await httpService.api({
		type: 'getRepCustomerDetails',
		params: { customerId },
	});
	if (res) {
		return {
			...res,
			contactName: `${res.contactFirstName || ''} ${
				res.contactLastName || ''
			}`,
			contactPhone: `${res.contactCellPrefix || ''}${
				res.contactCellPhone || ''
			}`,
			businessUnits: res?.businessUnits
				?.map((unit: any) => ({
					...unit,
					contactPhone: `${unit.contactCellPrefix || ''}${
						unit.contactCellPhone || ''
					}`,
				}))
				.sort((a: any, b: any) => a.name.localeCompare(b.name)),
		};
	}
};

const enterCustomer = async (userData: any, routeName: string) => {
	const customerData = await getCustomerData(userData.repCustomerId);
	const accessData: any = await httpService.api({
		type: 'getRepEnterData',
		params: { customerId: userData.repCustomerId },
		customerHp: customerData.hp || customerData.id,
		customerId: userData.repCustomerId,
	});
	dispatch(
		setRepEnterCustomerMode({
			...customerData,
			goBackTo: routeName,
			customer_privilege: accessData?.filter(
				(unit: any) => unit.customerId === userData.businessUnitId,
			),
			customerId: userData.repCustomerId,
		}),
	);
};

export const EnterCustomerComponenet = ({ data, routeName }: any) => {
	return (
		<Tooltip
			trigger={['hover', 'click']}
			overlayInnerStyle={{ minHeight: 14 }}
			placement='left'
			overlay={<I18n size={14}>enterCustomer.toolTip</I18n>}
		>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => enterCustomer(data, routeName)}
			>
				<ImageIcon name='enterCustomer' height={20} width={20} />
			</TouchableHighlight>
		</Tooltip>
	);
};
