import React from 'react';
import SendingDocumentNative from './SendingDocument.component.native';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Divider } from 'react-native-paper';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import AsyncSelect from '../../../components/shared/FieldBuilder/AsyncSelect/AsyncSelect.component';
import AsyncMultiSelect from '../../../components/shared/AsyncMultiSelect/AsyncMultiSelect.container';
import Typography from '../../../components/shared/Typography/Typography.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import OutlineButtonContainer from '../../../components/shared/Buttons/OutlineButton.container';
import Select from '../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import { selectStyle } from './SendingDocument.container';

export interface SendingDocumentProps {
	onRowClick: Function;
	isMobileWeb?: boolean;
	getEmployeesFilter: Function;
	onChooseEmployee: Function;
	chosenEmp: any;
	chosenRecipienats: any[];
	onChooseRecipienats: Function;
	onRemoveRecipienats: Function;
	recipienatsKey: boolean;
	onCancel: Function;
	onSubmit: Function;
	onChooseUnit: Function;
	id: number;
	businessUnitsList: any[];
	chosenUnit: any;
}
const SendingDocument = ({
	getEmployeesFilter,
	onChooseEmployee,
	chosenEmp,
	chosenRecipienats,
	onChooseRecipienats,
	onRemoveRecipienats,
	recipienatsKey,
	onCancel,
	onSubmit,
	id,
	businessUnitsList,
	onChooseUnit,
	chosenUnit,
}: SendingDocumentProps) => {
	const platform = useDevicePlatform();
	const isMobileWeb = platform === DeviceType.MOBILE_WEB;

	return isMobileWeb ? (
		<SendingDocumentNative
			{...{
				getEmployeesFilter,
				onChooseEmployee,
				chosenEmp,
				chosenRecipienats,
				onChooseRecipienats,
				onRemoveRecipienats,
				recipienatsKey,
				onCancel,
				onSubmit,
				id,
				businessUnitsList,
				onChooseUnit,
				chosenUnit,
			}}
			isMobileWeb={isMobileWeb}
		/>
	) : (
		<View
			style={{
				maxHeight: 350,
				minHeight: 350,
				// marginBottom: 160,
			}}
		>
			<View>
				<View style={{ flexDirection: 'row-reverse' }}>
					<I18n size={30} weight={'normal'}>
						{id
							? 'sendingDocument.web.titleEmp'
							: `sendingDocument.web.title`}
					</I18n>
					{id && (
						<Typography size={30} weight={'normal'}>
							{chosenEmp.label}
						</Typography>
					)}
				</View>
				<Divider
					style={{
						backgroundColor: colors.primary,
						opacity: 1,
						height: 4,
						marginTop: 16,
						marginBottom: 20,
						borderRadius: 2,
					}}
				/>
			</View>
			{businessUnitsList.length > 1 && (
				<View
					style={{
						flexDirection: 'row-reverse',
						alignItems: 'center',
						zIndex: 11,
						marginBottom: 20,
					}}
				>
					<I18n style={{ width: 350 }}>
						sendingDocument.chooseUnit
					</I18n>
					<Select
						key={`${JSON.stringify(chosenUnit)}`}
						isSearchable={false}
						noPlaceholder
						styles={{ ...selectStyle, container: { width: 340 } }}
						options={businessUnitsList}
						onChange={onChooseUnit}
						value={chosenUnit}
						captureMenuScroll={false}
						maxMenuHeight={145}
						disabled={!!id}
					/>
				</View>
			)}
			<View
				style={{
					flexDirection: 'row-reverse',
					alignItems: 'center',
					zIndex: 10,
					marginBottom: 20,
				}}
			>
				<I18n style={{ width: 350 }}>
					sendingDocument.chooseEmployee
				</I18n>
				<AsyncSelect
					key={`${JSON.stringify(chosenUnit)}`}
					options={(...rest: any) =>
						getEmployeesFilter(...rest, true)
					}
					onChange={onChooseEmployee}
					value={chosenEmp}
					isDisabled={id}
				/>
			</View>
			<View
				style={{
					flexDirection: 'row-reverse',
					alignItems: 'center',
					zIndex: 7,
				}}
			>
				<View>
					<I18n style={{ width: 350 }}>
						sendingDocument.chooseExtraRecipients
					</I18n>
					<I18n size={14} color='primary'>
						sendingDocument.chooseExtraRecipients.note
					</I18n>
				</View>
				<AsyncMultiSelect
					config={{
						name: 'recipienats',
						loadOptions: getEmployeesFilter,
						styles: { width: 350 },
						value: chosenRecipienats?.map((data: any) => ({
							label: data.name,
							value: data.id,
						})),
					}}
					styles={selectStyle}
					onChange={onChooseRecipienats}
					isOptionDisabled={(
						opt: { label: string; value: number },
						value: { label: string; value: number }[],
					) => {
						return (
							value?.length === 3 &&
							!value.some(val => val.value === opt.value)
						);
					}}
					key={recipienatsKey}
				/>
			</View>
			<View
				style={{
					flexDirection: 'row-reverse',
					alignItems: 'center',
					flexWrap: 'wrap',
					marginTop: 15,
				}}
			>
				{chosenRecipienats?.map(
					(recip: { id: number; name: string }) => (
						<View
							key={recip.id}
							style={{
								flexDirection: 'row-reverse',
								height: 35,
								borderRadius: 20,
								borderWidth: 1,
								borderColor: colors.darkBlue,
								padding: 10,
								paddingHorizontal: 20,
								marginLeft: 15,
							}}
						>
							<Typography size={12} style={{ marginLeft: 10 }}>
								{recip.name}
							</Typography>
							<TouchableOpacity
								onPress={() => onRemoveRecipienats(recip.id)}
							>
								<ImageIcon
									name='remove'
									width={12}
									height={12}
								/>
							</TouchableOpacity>
						</View>
					),
				)}
			</View>
			<View style={{ flex: 1 }} />
			<Divider
				style={{
					backgroundColor: colors.darkGrey,
					opacity: 0.2,
					marginVertical: 24,
				}}
			/>
			<View style={styles.actionButtons}>
				<SolidButtonContainer
					overrideStyle={styles.buttonOk}
					onPress={onSubmit}
					disabled={!chosenEmp}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='white'
					>
						general.continue
					</I18n>
				</SolidButtonContainer>
				<OutlineButtonContainer
					overrideStyle={styles.buttonCancel}
					onPress={() => onCancel()}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='primary'
					>
						general.cancel
					</I18n>
				</OutlineButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		paddingBottom: 17,
	},
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	buttonOk: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
		paddingHorizontal: 15,
		padding: 10,
		marginLeft: 15,
	},
	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		padding: 10,
	},
});

export default SendingDocument;
