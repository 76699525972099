import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Dimensions,
	StyleSheet,
	TouchableHighlight,
	useWindowDimensions,
	View,
} from 'react-native';
import HeaderContainer from '../../../components/shared/Header/Header.container';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import WebHeader from '../../../components/shared/Header/WebHeader.component';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import Typography from '../../../components/shared/Typography/Typography.component';
import { i18nService } from '../../../services/i18n.service';
import Filters from '../../../components/web/Filters/Filters.component';
import getShadowStyle from '../../../config/shadow';
import { Columns } from './WorklogReportsPage.utils';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';

export interface WorklogReportsPageProps {
	navigation: any;
	data: any[];
	onSort?: any;
	currentEmployee: any;
	onLoadMore: any;
	totalData: any;
	filtersConfig1: any;
	filtersConfig2: any;
	isSelectMode: boolean;
	selectedRows: any[];
	isAllSelected: boolean;
	worklogReportFormat: any;
	onSelectAll: (cheacked: boolean) => {};
	onSelect: () => {};
	onConfirmSelect: () => {};
	onCancelSelect: () => {};
	reqForSelect: () => {};
	onFilter: () => {};
	onDelete: (id: any) => {};
	onEdit: (id: any) => {};
	backTohoursReportSummery: () => void;
	onAddNewLog: () => void;
	canEdit: boolean;
	businessUnitsList: any[];
}

const WorklogReportsPage = ({
	onAddNewLog,
	navigation,
	backTohoursReportSummery,
	data,
	totalData,
	onSort,
	onLoadMore,
	filtersConfig1,
	filtersConfig2,
	isSelectMode,
	selectedRows,
	isAllSelected,
	onSelectAll,
	onSelect,
	onConfirmSelect,
	onCancelSelect,
	reqForSelect,
	onFilter,
	onEdit,
	onDelete,
	worklogReportFormat,
	canEdit,
	businessUnitsList,
}: WorklogReportsPageProps) => {
	const { height: windowHeight } = useWindowDimensions();
	const [isSmallDimensions, setIsSmallDimensions] = useState(
		window.innerWidth <= 1600,
	);

	const getButton = (
		innerText: string,
		action: () => {},
		disabled: boolean,
	) => (
		<TouchableHighlight
			underlayColor='transparent'
			onPress={action}
			disabled={disabled}
		>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					opacity: disabled ? 0.7 : 1,
					height: 40,
					paddingHorizontal: 3,
					backgroundColor: colors.lightPrimary,
					borderRadius: 45,
					marginHorizontal: 3,
					...getShadowStyle(20, 'rgb(59, 112, 164)'),
				}}
			>
				<Typography
					style={{ marginLeft: 10, marginRight: 15 }}
					color={'white'}
					weight='400'
					size={14}
				>
					{i18nService.translate(innerText)}
				</Typography>
			</View>
		</TouchableHighlight>
	);

	return (
		<>
			<HeaderContainer
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<View
						style={{
							flexDirection: 'row-reverse',
							alignItems: 'center',
							paddingLeft: 27,
							paddingRight: isSmallDimensions ? 11 : 0,
							paddingBottom: isSmallDimensions ? 9 : 17,
							width: '100%',
						}}
					>
						<I18n size={36} weight='normal'>
							reportView.reportsTitle
						</I18n>
						<View
							style={{
								flexDirection: 'row-reverse',
								flex: 1,
								justifyContent: 'flex-end',
							}}
						>
							<TouchableHighlight
								underlayColor='transparent'
								onPress={backTohoursReportSummery}
							>
								<View style={styles.newRequestButton}>
									<Typography
										style={{
											marginLeft: 10,
											marginRight: 10,
										}}
										color={'white'}
										weight='400'
										size={14}
									>
										{i18nService.translate(
											'hoursReport.toAllHoursReports',
										)}
									</Typography>
								</View>
							</TouchableHighlight>
							{isSelectMode ? (
								<View style={{ flexDirection: 'row' }}>
									{getButton(
										'employeeHoursReport.confirmFilterButton',
										() => onConfirmSelect(),
										false,
									)}
									{getButton(
										'employeeHoursReport.cancelFilterButton',
										() => onCancelSelect(),
										false,
									)}
								</View>
							) : (
								getButton(
									'employeeHoursReport.reqForConfirmButton',
									() => reqForSelect(),
									!canEdit,
								)
							)}

							{canEdit && (
								<TouchableHighlight
									style={styles.fabButton}
									underlayColor='transparent'
									onPress={onAddNewLog}
								>
									<ImageIcon
										name={'add'}
										width={40}
										height={40}
									></ImageIcon>
								</TouchableHighlight>
							)}
						</View>
					</View>
				}
				noHeader={true}
			>
				<View style={styles.tableHeader} nativeID='#tableHeader'>
					<View
						style={{ flexDirection: 'row-reverse', zIndex: 10001 }}
					>
						<Filters
							config={filtersConfig1}
							onChange={onFilter}
							customStyles={{
								container: {
									display: 'flex',
									flexDirection: 'row-reverse',
									justifyContent: 'flex-end',
									alignSelf: 'center',
								},
								filterContainer: {
									marginVertical: 5,
									marginLeft: 5,
									zIndex: 10001,
								},
							}}
						/>
					</View>
					<View
						style={{ flexDirection: 'row-reverse', zIndex: 1000 }}
					>
						<Filters
							config={filtersConfig2}
							onChange={onFilter}
							customStyles={{
								container: {
									display: 'flex',
									flexDirection: 'row-reverse',
									justifyContent: 'flex-end',
									alignSelf: 'center',
								},
								filterContainer: {
									marginBottom: 10,
									marginLeft: 5,
									zIndex: 1000,
								},
							}}
						/>
					</View>
					<View
						style={{
							maxHeight: windowHeight - 300,
						}}
					>
						<TableContainer
							keyName={'localId'}
							headers={Columns(
								totalData ? totalData.total : null,
								isSelectMode,
								selectedRows,
								isAllSelected,
								onSelectAll,
								onSelect,
								onDelete,
								onEdit,
								worklogReportFormat,
								canEdit,
								businessUnitsList,
							)}
							data={data || []}
							getNextData={onLoadMore}
							onSort={onSort}
							hasFooter={true}
							selectedRowId={0}
						/>
					</View>
				</View>
			</ReportsPageWrapper>
		</>
	);
};

const styles = StyleSheet.create({
	tableHeader: {
		width: '100%',
		zIndex: 100,
	},
	filtersContainer: {
		flexDirection: 'row',
		width: '100%',
		// alignItems: 'center',
		// overflow: 'hidden'
	},

	fabButton: {
		borderRadius: 30,
		width: 40,
		height: 40,
		marginHorizontal: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	newRequestButton: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		paddingHorizontal: 3,
		marginLeft: 10,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default WorklogReportsPage;
