import * as eventListActions from '../constants/eventListActions';
import { EventListState, StoreAction } from '../../interfaces/redux.interface';
import { EventData } from '../../models/event.model';

const initialState: EventListState = {
	events: [],
	error: false,
	errorMessage: '',
	completed: false,
	currentEvent: undefined,
    eventForceMode: false
};

const eventListReducer = (
	state: typeof initialState = initialState,
	action: StoreAction<any>,
): EventListState => {
	switch (action.type) {
		case eventListActions.START_WORKLOG_NOW_SUCCESS:
			return {
				...state,
				completed: true,
				currentEvent: action.payload,
			};
		case eventListActions.STOP_WORKLOG_NOW_SUCCESS:
			return {
				...state,
				completed: true,
				currentEvent: action.payload,
			};
		case eventListActions.SET_CURRENT_EVENT:
			return {
				...state,
				currentEvent: action.payload,
			};
        case eventListActions.SET_EVENT_FORCE_MODE:
            return {
                ...state,
                eventForceMode: action.payload
            }
		default:
			return state;
	}
};

export default eventListReducer;
