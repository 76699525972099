import { toNumber } from 'lodash';
import { Platform, StyleSheet } from 'react-native';
import colors from '../../../../config/colors';
import { clipboard } from '../../../../services/Clipboard/Clipboard';

export const checkPinCodeFromClipBoard = async (
	setPassword: (val: string) => void,
	interval: React.MutableRefObject<number | undefined>,
	lastClipboardText: React.MutableRefObject<string>,
	clipboardDisabled: React.MutableRefObject<boolean>,
) => {
	try {
		const regexp = new RegExp(`^\\d{6}$`);
		const code = await clipboard.getClipboardText();
		if (typeof code === 'boolean') {
			clipboardDisabled.current = true;
			clearInterval(interval.current);
			interval.current = undefined;
			return;
		}
		if (regexp.test(code) && code !== lastClipboardText.current) {
			clearInterval(interval.current);
			interval.current = undefined;
			setPassword(code);
			lastClipboardText.current = code;
		}
	} catch (e) {
		clipboardDisabled.current = true;
	}
};

export const getShouldDisplayCaptch = (
	dateArr: string[],
	attemptsNumber: number,
	periodInMiliSecond: number,
): { attempts: string[]; shouldDisplay: boolean } => {
	const currentTime = Date.now();

	const newDateArr: string[] = [];
	dateArr.forEach(d => {
		currentTime - toNumber(d) <= periodInMiliSecond && newDateArr.push(d);
	});
	newDateArr.push(currentTime.toString());

	return {
		attempts: newDateArr,
		shouldDisplay: newDateArr.length >= attemptsNumber,
	};
};

export const styles = StyleSheet.create({
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		height: '100%',
		width: '100%',
		backgroundColor: '#efefef',
	},
	image: {
		resizeMode: 'center',
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: 500,
	},
	outerContainer: {
		backgroundColor: colors.white,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		paddingHorizontal: 16,
		paddingTop: 18,
		paddingBottom: 47,
	},
	container: {
		flexDirection: 'column',
		width: 301,
	},
	logoSection: {
		height: 70,
		paddingBottom: 10,
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	separatorLine: {
		backgroundColor: colors.primary,
		height: 1,
		opacity: 0.38,
	},
	credentials: {
		height: 115,
		marginBottom: 10,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	inputContainer: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 300,
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: 220,
		height: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		...Platform.select({
			web: {
				outlineWidth: 0,
				outlineStyle: 'none',
			},
		}),
	},
	errorInput: {
		borderWidth: 1,
		borderColor: colors.failure,
	},
	errorText: {
		alignItems: 'center',
		marginBottom: 16,
	},
});
