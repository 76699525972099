import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { Portal } from 'react-native-paper';
import { useSelector } from 'react-redux';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ModalContainer from '../../../../../components/shared/Modal/Modal.container';
import colors from '../../../../../config/colors';
import getShadowStyle from '../../../../../config/shadow';
import { Privileges } from '../../../../../constants/roleTypes.constant';
import useDevicePlatform, {
	DeviceType,
} from '../../../../../hooks/useDevicePlatform.hook';
import httpService from '../../../../../services/http.service';
import { modalService } from '../../../../../services/modal.service';
import { getUserData } from '../../../../../store/selectors/login.selectors';
import SelectEmployeeExtra from '../RolesSectionMobile/SelectEmployeeExtra';
import SelectExtraDataModal from '../RolesSectionMobile/SelectExtraDataModal';
import SelectPermissionsModal from '../RolesSectionMobile/SelectPermissionsModal';
import SelectPermissionsModalWeb from '../RolesSectionModalsWeb/SelectPermissionsModal';
import SelectDepartmentsModal from '../RolesSectionModalsWeb/SelectDepartmentsModal';
import { getUsermodal } from '../getUsermodal.util';
import CustomerRow from './CustomerRow.component';
import { i18nService } from '../../../../../services/i18n.service';

const CustomerRolesSection = ({
	customerInfo,
	onChangUnits,
	onChangeUsers,
	getEmployeesFilter,
	setIsModalOpen,
	onChangePermissions,
	unitId,
	hasOnlyOneUnit,
	handleSelectRole,
	index,
	disabled,
}: any) => {
	const userData = useSelector(getUserData);
	const [showSelectModal, setShowSelectModal] = useState<boolean | any>(
		false,
	);
	const [permissionsMap, setPermissionMap] = useState<any>({});

	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;

	const openModal = async (
		roleId: number,
		type: 'permissions' | 'users' | 'units',
		isAdmin?: boolean,
		customerUnits?: any,
	) => {
		setShowSelectModal({ type, roleId, isAdmin, customerUnits });
		isMobile && setIsModalOpen?.(true);
	};

	const closeModal = () => {
		setShowSelectModal(false);
		isMobile && setIsModalOpen?.(false);
	};

	useEffect(() => {
		if (showSelectModal?.type && !isMobile) {
			(async () => {
				const Component =
					(showSelectModal?.type === 'users' &&
						(await getUsermodal(true))) ||
					(() => <View />);
				const modalData = getModalsData();
				modalService.openModal(
					null,
					{
						onNext: (callback: Function) => {
							setShowSelectModal(false);
							callback();
						},
						...modalData,
					},
					(props: any) =>
						showSelectModal?.type === 'users' ? (
							<Component {...props} />
						) : showSelectModal?.type === 'permissions' ? (
							<SelectPermissionsModalWeb {...props} />
						) : (
							<SelectDepartmentsModal {...props} />
						),
				);
			})();
		}
	}, [showSelectModal]);

	useEffect(() => {
		if (
			!permissionsMap[customerInfo.role_id] &&
			customerInfo.customerUnits !== undefined
		) {
			getPermissionsList();
		}
	}, [customerInfo.role_id, customerInfo.customerUnits]);

	const getPermissionsList = async () => {
		const permissions: any = await httpService.api({
			type: 'getRolePermissions',
			params: { id: customerInfo.role_id },
			query: { unitId },
		});
		setPermissionMap({
			...permissionsMap,
			[customerInfo.role_id]: permissions.sort((a: any, b: any) =>
				a.allow_read_only !== b.allow_read_only
					? a.allow_read_only
						? -1
						: 1
					: a.name > b.name
					? 1
					: -1,
			),
		});
	};

	const getModalsData = () => {
		const selectedRoleData =
			customerInfo.roles?.find(
				(role: any) => role.id === showSelectModal?.roleId,
			) || {};
		switch (showSelectModal?.type) {
			case 'permissions':
				return {
					selectedData: customerInfo.user_role_permission?.length
						? customerInfo.user_role_permission
						: permissionsMap[String(showSelectModal?.roleId)].map(
								d => ({ permission_id: d.id }),
						  ),
					allData: permissionsMap[String(showSelectModal?.roleId)],
					onSubmit: (value: any[]) => {
						onChangePermissions(
							value,
							permissionsMap[String(showSelectModal?.roleId)],
							customerInfo.customer_id,
						);
					},
					close: closeModal,
					role: {
						name:
							showSelectModal?.roleId === 10
								? i18nService.translate(
										'customerManagementModal.fieldsForm.role.manager',
								  )
								: i18nService.translate(
										'customerManagementModal.fieldsForm.role.admin',
								  ),
					},
					title: selectedRoleData?.name,
					disabled,
					dontUseRoleDisable: true,
					checkAll:
						!customerInfo.user_role_permission?.[
							showSelectModal?.roleId
						]?.length,
					showSwitch: true,
				};
			case 'units':
				return {
					selectedData: customerInfo.unit_ids?.length
						? customerInfo.unit_ids
						: customerInfo.customerUnits.map(
								(unit: any) => unit.id,
						  ),
					allData: [
						{ id: 'all', name: 'general.all' },
						...showSelectModal?.customerUnits,
					],
					onSubmit: (value: any[]) => {
						onChangUnits(value, customerInfo.customer_id);
					},
					close: closeModal,
					role: selectedRoleData,
					title: 'customerManagementModal.addUnits',
					subTitle: 'customerManagementModal.addUnitsSubTitle',
					disabled,
					isSelected: (item: any, selected: any) =>
						!!selected.find((val: any) => val.id === item.id),
				};
			case 'users':
				const shouldCheckNew =
					(customerInfo?.excluded_user &&
						!customerInfo?.excluded_user.length) ||
					(customerInfo?.excluded_user &&
						!customerInfo?.exclude_selected);
				return {
					selectedData: customerInfo?.excluded_user || [],
					disabled,
					onFilter: getEmployeesFilter,
					onSubmit: (value: any[]) => {
						onChangeUsers(value, customerInfo.customer_id);
					},
					close: closeModal,
					role: selectedRoleData,
					shouldCheckNew,
					isSelected: (item: any, selected: any) =>
						!!selected.find((val: any) => val.id === item.id),
					displayExcluded: customerInfo?.excluded_user?.length,
					initialData: {
						exclude_selected: customerInfo?.exclude_selected,
						excluded_user: customerInfo?.excluded_user,
					},
					unitId: customerInfo?.unit_ids?.length
						? customerInfo?.unit_ids?.join(',')
						: customerInfo?.customerUnits?.length
						? customerInfo?.customerUnits
								.map((data: any) => data.id)
								.join(',')
						: unitId,
					repCustomerId: customerInfo.customer_id,
				};
		}
	};

	const handleOnChangePermissionsByRole = useCallback(
		async (role: any, customer: number) => {
			if (permissionsMap[String(role)]) {
				const result = permissionsMap[String(role)].map(d => ({
					permission_id: d.id,
				}));
				onChangePermissions(result, null, customer);
			}
		},
		[permissionsMap],
	);

	const modalData = useMemo(() => {
		if (!showSelectModal) return;
		return getModalsData();
	}, [showSelectModal, userData, customerInfo, disabled]);

	return (
		<View
			style={{
				marginTop: 16,
				marginRight:
					customerInfo.user_roles?.length > 1 &&
					!isMobile &&
					hasOnlyOneUnit
						? 30
						: 0,
			}}
		>
			<CustomerRow
				index={index}
				roles={{
					...customerInfo,
					permissionTotal:
						permissionsMap[customerInfo.role_id]?.length,
				}}
				isMobile={isMobile}
				handleSelectRole={handleSelectRole}
				openModal={openModal}
				disabled={disabled}
				handleOnChangePermissionsByRole={
					handleOnChangePermissionsByRole
				}
			/>
			<View
				style={{
					flexDirection: isMobile ? 'row-reverse' : 'column',
					width: isMobile
						? Dimensions.get('window')?.width - 40
						: '100%',
				}}
			>
				<TouchableOpacity
					onPress={() => openModal(undefined, 'users')}
					style={[
						isMobile
							? styles.actionButtons
							: styles.buttonMuchragWeb,
						{
							backgroundColor: colors.primary,
							marginTop: 20,
							flexDirection: isMobile ? 'row-reverse' : 'row',
						},
					]}
				>
					<I18n color='white' weight='normal' size={14}>
						customerManagementModal.displayExcluded
					</I18n>
					{customerInfo?.excluded_user?.length ? (
						<I18n
							style={{ marginRight: 2 }}
							color='white'
							weight='normal'
							size={14}
						>
							({customerInfo?.excluded_user.length})
						</I18n>
					) : null}
				</TouchableOpacity>
			</View>
			{isMobile && (
				<Portal>
					<ModalContainer
						disabledKeyboard
						visible={showSelectModal}
						component={
							showSelectModal?.type === 'users'
								? SelectEmployeeExtra
								: showSelectModal?.type === 'permissions'
								? SelectPermissionsModal
								: SelectExtraDataModal
						}
						onNext={() => setShowSelectModal(false)}
						onSubmit={() => setShowSelectModal(false)}
						notDisplayModal
						{...modalData}
					/>
				</Portal>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	centeredText: {
		textAlign: 'center',
	},
	containerStyle: {
		width: 150,
	},
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 180,
		backgroundColor: colors.lightPrimary,
		borderRadius: 35,
		minHeight: 45,
		paddingHorizontal: 24,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
	buttonMuchragWeb: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 150,
		backgroundColor: colors.lightPrimary,
		borderRadius: 35,
		minHeight: 38,
		paddingHorizontal: 7,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default CustomerRolesSection;
