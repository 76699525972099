import React from 'react';
import { ImageBackground, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import colors from '../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { logout } from '../../store/actions/login.actions';
import { getEmployersData } from '../../store/selectors/login.selectors';
import EmployerList from '../EmployerList/EmployerList.container';

const imageSrc = require('../../../assets/images/login/bg.png');

const EmployerPage = () => {
	const dispatch = useDispatch();
	const currPlatform = useDevicePlatform();
	const isMobile: boolean = currPlatform !== DeviceType.WEB;

	const employerListData = useSelector(getEmployersData);
	return (
		<>
			{employerListData?.length ? (
				<View style={styles.wrapper}>
					<ImageBackground
						source={imageSrc}
						style={[
							styles.image,
							{
								height: isMobile ? 300 : 700,
								width: isMobile ? 430 : 700,
								bottom: isMobile ? -50 : 0,
							},
						]}
					></ImageBackground>
					<View
						style={[
							styles.outerContainer,
							{
								width: isMobile ? 334 : 448,
								height: isMobile ? 435 : 474,
							},
						]}
					>
						<View style={styles.container}>
							<View style={styles.logoSection}>
								<ImageIcon
									name={'liram'}
									width={96}
									height={29}
								></ImageIcon>
								<ImageIcon
									name={'michpal'}
									width={83}
									height={59}
								></ImageIcon>
							</View>
							<View style={styles.separatorLine}></View>
							<View style={styles.credentials}>
								<EmployerList
									containerStyle={styles.employeeContainer}
								/>
							</View>
							<SolidButtonContainer
								overrideStyle={{
									height: 61,
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: 20,
								}}
								onPress={() => dispatch(logout())}
							>
								<I18n
									size={16}
									weight='normal'
									color='white'
									numberOfLines={1}
								>
									general.back
								</I18n>
							</SolidButtonContainer>
						</View>
					</View>
				</View>
			) : (
				<View />
			)}
		</>
	);
};

const styles = StyleSheet.create({
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		height: '100%',
		width: '100%',
		overflow: 'hidden',
	},
	image: {
		resizeMode: 'cover',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
	},
	outerContainer: {
		backgroundColor: colors.white,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		paddingHorizontal: 16,
		paddingTop: 10,
		// paddingBottom: 47,
	},
	container: {
		flexDirection: 'column',
		width: 301,
	},
	logoSection: {
		height: 70,
		paddingBottom: 10,
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	separatorLine: {
		backgroundColor: colors.primary,
		height: 1,
		opacity: 0.38,
		marginBottom: 47,
	},
	credentials: {
		height: 115,
		marginBottom: 70,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	inputContainer: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 300,
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		backgroundColor: '#ecf3f7',
	},
	errorInput: {
		borderWidth: 1,
		borderColor: colors.failure,
	},
	errorText: {
		alignItems: 'center',
		marginBottom: 16,
	},
	employeeContainer: {
		width: 'auto',
		height: 190,
		padding: 0,
	},
});

export default EmployerPage;
