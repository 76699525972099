import React, { useMemo, useRef } from 'react';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import TableContainer from '../../../../../components/web/ReactTable/Table.container';
import colors from '../../../../../config/colors';
import { i18nService } from '../../../../../services/i18n.service';
import httpService from '../../../../../services/http.service';
import { Switch } from 'react-native-paper';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../../store/selectors/login.selectors';
import { Privileges } from '../../../../../constants/roleTypes.constant';
import I18n from '../../../../../components/shared/i18n/I18n.component';

const BusinessUnits = ({ businessUnits, customerId, updateData }: any) => {
	const userData = useSelector(getUserData);
	const custId = useRef(customerId?.customerId);
	custId.current = customerId?.customerId;
	const businessUnitsRef = useRef(businessUnits);
	businessUnitsRef.current = businessUnits;

	const canEditCustomer = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_CREATE_REP_CUSTOMER_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	const updateViewOnDashboard = async (
		id: number,
		value: boolean,
		row: any,
	) => {
		const res = httpService.api({
			type: 'updateRepCustomerBusinessUnit',
			params: { unitId: id, customerId: custId.current },
			disableBI: true,
			data: {
				representDashboardDisplay: value,
			},
		});

		const tempBusinessUnits = [...businessUnitsRef.current];
		tempBusinessUnits[row.index] = {
			...tempBusinessUnits[row.index],
			representDashboardDisplay: value,
		};
		updateData({ businessUnits: [...tempBusinessUnits] });
		console.log('row', row);
	};

	const tableConfig = useMemo(
		() => [
			{
				accessor: 'name',
				Header: i18nService.translate(
					'customerDatails.businessUnits.name',
				),
			},
			{
				accessor: 'address',
				Header: i18nService.translate(
					'customerDatails.businessUnits.address',
				),
				CustomCell: ({ value, row }: any) => (
					<Typography size={14}>{value}</Typography>
				),
			},
			{
				accessor: 'phone',
				Header: i18nService.translate(
					'customerDatails.businessUnits.phone',
				),
			},
			{
				accessor: 'tikNikuim',
				Header: i18nService.translate(
					'customerDatails.businessUnits.tikNikuim',
				),
			},
			{
				accessor: 'modules',
				Header: i18nService.translate(
					'customerDatails.businessUnits.modules',
				),
				CustomCell: ({ value, row }: any) => (
					<Typography size={14}>{value}</Typography>
				),
			},
			{
				accessor: 'contactName',
				Header: i18nService.translate(
					'customerDatails.businessUnits.contactName',
				),
			},
			{
				accessor: 'contactPhone',
				Header: i18nService.translate(
					'customerDatails.businessUnits.contactPhone',
				),
			},
			{
				accessor: 'contactEmail',
				Header: i18nService.translate(
					'customerDatails.businessUnits.contactEmail',
				),
				CustomCell: ({ value, row }: any) => {
					return (
						<>
							<a
								href={`mailto:'${value}'`}
								style={{ color: colors.darkGrey }}
							>
								<Typography size={14} weight='400'>
									{value}
								</Typography>
							</a>
						</>
					);
				},
			},
			{
				accessor: 'representDashboardDisplay',
				Header: i18nService.translate(
					'customerDatails.businessUnits.viewOnDashboard',
				),
				CustomCell: ({ value, row }: any) => (
					<View style={{ direction: 'ltr' }}>
						{canEditCustomer ? (
							<Switch
								color={colors.lightPrimary}
								onValueChange={() => {
									updateViewOnDashboard(
										row.original.id,
										!value,
										row,
									);
								}}
								value={value}
							/>
						) : (
							<I18n>{`general.${value}`}</I18n>
						)}
					</View>
				),
				// ),
			},
		],
		[],
	);

	return (
		<TableContainer
			headers={tableConfig as any}
			data={businessUnits || []}
			count={0}
			onSort={() => {}}
			getNextData={() => {}}
			keyName=''
		/>
	);
};

export default BusinessUnits;
