import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ImageBackground, Linking, StyleSheet, View } from 'react-native';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import colors from '../../config/colors';
import useDevicePlatform, { DeviceType } from '../../hooks/useDevicePlatform.hook';
const imageSrc = require('../../../assets/images/login/bg.png');


const UpdateEmail = ({ navigation, alertText, isSuccess, backToLogin }: { navigation: any, alertText: string, isSuccess: boolean | null, backToLogin(): void }) => {
  const isMobile: boolean = useDevicePlatform() !== DeviceType.WEB;


  return (
    <View style={styles.wrapper} >
      <ImageBackground
        source={imageSrc}
        style={[styles.image, { height: isMobile ? 300 : 700, width: isMobile ? 430 : 700, bottom: isMobile ? -50 : 50 }]}
      ></ImageBackground>
      <View style={[styles.outerContainer, { width: isMobile ? 334 : 448 }]}>
        <View style={styles.container}>
          <View style={styles.logoSection}>
            <ImageIcon
              name={'liram'}
              width={96}
              height={29}
            ></ImageIcon>
            <ImageIcon
              name={'michpal'}
              width={83}
              height={59}
            ></ImageIcon>
          </View>

          <View style={styles.separatorLine} />
          <View style={styles.viewConainer}>
            <View style={{ height: 150, justifyContent: "center" }}>
              <ImageIcon
                name={isSuccess ? 'tick' : 'attention'}
                width={100}
                height={100}
              />
            </View>
            <I18n
              size={16}
              weight='bold'
              style={styles.userChangedPasswordMesagge}
            >
              {alertText}
            </I18n>
            <SolidButtonContainer
              overrideStyle={{ width: 200 }}
              onPress={() => backToLogin()}
            >
              <I18n
                size={14}
                weight='normal'
                color='white'
              >
                login.backToLogin
              </I18n>
            </SolidButtonContainer>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    height: 435,
    backgroundColor: '#efefef',
  },
  outerContainer: {
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 18,
    paddingBottom: 47,
  },
  image: {
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  container: {
    flexDirection: 'column',
    width: 301
  },
  logoSection: {
    height: 70,
    paddingBottom: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  separatorLine: {
    backgroundColor: colors.primary,
    height: 1,
    opacity: 0.38,
  },
  viewConainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userChangedPasswordMesagge: {
    height: 50,
    marginTop: 10,
    paddingBottom: 90,
    alignSelf: 'center',
    alignContent: "center",
    alignItems: "center",
    textAlign: "center"

  },
});

export default UpdateEmail;
