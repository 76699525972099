import React, { useEffect, useState } from 'react';
import {
	View,
	StyleSheet,
	useWindowDimensions,
	TouchableHighlight,
} from 'react-native';
// import PaycheckListContainer from './PaycheckList/PaycheckList.container'; //TODO: delete component
import HeaderContainer from '../../components/shared/Header/Header.container';
import colors from '../../config/colors';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import I18n from '../../components/shared/i18n/I18n.component';
import PaycheckView from './PaycheckView/PaycheckView.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import { isEmpty } from 'lodash';
import ReportRangeListContainer from '../../components/shared/ReportRangeList/ReportRangeList.container';
import Select from '../../components/shared/FieldBuilder/Select/ReactSelect.component';
import { BusinessUnit } from './PaycheckPage.utils';

const PaycheckPageWeb = ({
	navigation,
	paychecksRange,
	onSelectPaycheckDate,
	setIsRequestCompleted,
	businessUnitsList,
	selectedOptions,
	onChangeBusinessUnit,
	isErrorPaycheck,
	setIsErrorPaycheck,
}: {
	navigation: any;
	paychecksRange: { first: string; last: string };
	onSelectPaycheckDate: (date: string) => void;
	selectedOptions: {
		selectedBusinessUnit: BusinessUnit | null;
		selectedPaycheckDate: string;
	} | null;
	setIsRequestCompleted: any;
	businessUnitsList: BusinessUnit[];
	onChangeBusinessUnit: (id: string) => void;
	isErrorPaycheck: boolean;
	setIsErrorPaycheck: Function;
}) => {
	const shouldShowUnitSelect = businessUnitsList.length > 1;
	const [templateDataWeb, setTemplateDataWeb] = useState<any>({});
	const windowHeight = useWindowDimensions().height;
	const containerStyle = StyleSheet.flatten([
		styles.container,
		{ height: windowHeight - 200 },
	]);
	const downloadBtnStyle = StyleSheet.flatten([
		styles.btn,
		isEmpty(templateDataWeb) && styles.btnDisabled,
	]);

	const downloadPaycheckHandler = async () => {
		let iframes = document.getElementsByTagName('iframe');
		const arr = [...(iframes as any)].filter(f =>
			f.outerHTML.startsWith('<iframe style='),
		);
		const iframeEl: any = arr[arr.length - 1];
		const scrolableEl: any =
			iframeEl && iframeEl.contentDocument.documentElement;
		if (iframeEl && scrolableEl) {
			scrolableEl.scrollTo(0, 0); //fix bug with save pdf when paycheck view scroled to bottom
		}

		const d = await import('./PaycheckView/PaycheckUtils.web');
		d.download(templateDataWeb.template, templateDataWeb.fileName);
	};

	const onFetchPaycheckDataHandler = (templateData: {
		template: any;
		fileName: string;
	}) => {
		setTemplateDataWeb(templateData);
	};

	return (
		<>
			<HeaderContainer
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<View style={containerStyle} nativeID='PaycheckPageWeb'>
				<View style={styles.titleSection}>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							zIndex: 999,
							justifyContent: 'space-between',
							...(shouldShowUnitSelect && {
								width: 'calc(100% - 295px)',
							}),
						}}
					>
						<TouchableHighlight
							disabled={
								isErrorPaycheck ||
								(!paychecksRange.first && !paychecksRange.last)
							}
							style={[
								downloadBtnStyle,
								{
									opacity:
										isErrorPaycheck ||
										(!paychecksRange.first &&
											!paychecksRange.last)
											? 0.5
											: 1,
								},
							]}
							underlayColor='transparent'
							onPress={() => downloadPaycheckHandler()}
						>
							<ImageIcon
								name='downloadPaycheck'
								height={20}
								width={20}
							/>
						</TouchableHighlight>
						{shouldShowUnitSelect && (
							<View
								style={{
									zIndex: 1,
									width: 206,
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Select
									options={businessUnitsList}
									onChange={onChangeBusinessUnit}
									value={
										selectedOptions?.selectedBusinessUnit
											?.id
									}
								/>
								<I18n
									size={16}
									weight='400'
									color='darkGrey'
									style={{ marginHorizontal: 3 }}
								>
									:
								</I18n>
								<I18n size={16} weight='400' color='darkGrey'>
									general.business
								</I18n>
							</View>
						)}
					</View>
					<I18n
						size={36}
						weight='normal'
						style={{ flex: 1, paddingTop: 20, paddingBottom: 10 }}
					>
						paychecks
					</I18n>
				</View>
				<View style={styles.content}>
					{paychecksRange.first && paychecksRange.last ? (
						<>
							<PaycheckView
								selectedOptions={selectedOptions}
								onFetchPaycheckData={onFetchPaycheckDataHandler}
								setIsRequestCompleted={setIsRequestCompleted}
								isErrorPaycheck={isErrorPaycheck}
								setIsErrorPaycheck={setIsErrorPaycheck}
							/>
							<View
								nativeID='paycheckListWrapper'
								style={styles.paycheckListWrapper}
							>
								{paychecksRange.first &&
									paychecksRange.last && (
										<ReportRangeListContainer
											minDate={`${paychecksRange.first}-01`}
											maxDate={`${paychecksRange.last}-01`}
											onSelectDate={onSelectPaycheckDate}
											listType='months'
											rangeTitle='paycheckList.choosePaycheck'
										/>
									)}
							</View>
						</>
					) : (
						<View style={styles.errorMessage}>
							<I18n
								weight='bold'
								size={24}
								style={{ marginTop: 36, marginRight: 26 }}
							>
								paychecks.noPaychecks
							</I18n>
						</View>
					)}
				</View>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		// height: '100%',
		backgroundColor: colors.tableBackground,
		paddingRight: 22,
		paddingLeft: 37,
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '100%',
		zIndex: 0,
	},
	paycheckListWrapper: {
		height: '100%',
	},
	paycheckView: {
		position: 'relative',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		marginRight: 30,
		backgroundColor: colors.white,
	},
	titleSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		zIndex: 999,
	},
	btn: {
		height: 20,
		width: 20,
		justifyContent: 'center',
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	errorMessage: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	btnDisabled: {
		opacity: 0.5,
	},
});

export default PaycheckPageWeb;
