import { Platform, TouchableOpacity } from 'react-native';
import * as Location from 'expo-location';
import { i18nService } from '../services/i18n.service';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
} from '../store/actions/config.actions';
// import {
// 	setShowAlert,
// 	startStopWorklogNowFailure,
// } from '../store/actions/projectList.actions';
import { dispatch } from '../store/store';
import { getLoginData } from '../store/selectors/login.selectors';
import { useSelector } from 'react-redux';
import AlertModal from '../modals/AlertModal/AlertModal.component';
import React from 'react';
import I18n from '../components/shared/i18n/I18n.component';
import { modalService } from '../services/modal.service';

export const getLocationPermission = async () => {
	if (Platform.OS === 'android' || Platform.OS === 'ios') {
		const { status } = await Location.requestPermissionsAsync();

		if (status !== 'granted') {
			await modalService.openModal(
				null,
				{
					onSubmit: (callback: Function) => callback(),
					submitBtnText: "general.close",
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n style={{ textAlign: "center" }} >workLogError.location.alert</I18n>
					</AlertModal>
				)
			);
			return;
		}

		dispatch(incrementBusyIndicator());
		const timeoutId = setTimeout(() => {
			modalService.openModal(
				null,
				{
					onSubmit: (callback: Function) => callback(),
					submitBtnText: "general.close",
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n style={{ textAlign: "center" }} >workLogError.location.alertTimeout</I18n>
					</AlertModal>
				)
			);
			dispatch(decrementBusyIndicator());

		}, 30000);
		
		const location = await Location.getCurrentPositionAsync({
			accuracy: Location.Accuracy.Highest,
		});

		if (location) {
			clearTimeout(timeoutId)
		}
		dispatch(decrementBusyIndicator());
		return location;
	}
};


export const checkIfUserHasModule = (moduleId: number, loginData: any) => {
	// const loginData = useSelector(getLoginData);

	if (loginData?.employer && loginData?.modules) {
		const customer =
			typeof loginData?.employer === 'object'
				? loginData?.employer
				: JSON.parse(loginData?.employer);
		const modules =
			typeof loginData?.modules === 'object'
				? loginData?.modules
				: JSON.parse(loginData?.modules);
		if (!modules || !customer) {
			return false;
		}
		const selectedCustomerModule = modules?.find(
			(el: any) => el.customer === customer.id,
		).modules;
		const searchedModule = selectedCustomerModule?.find(
			(module: any) => module.id === moduleId,
		);
		if (searchedModule) {
			return true;
		}
		return false;
	}

	return true;
};
