import React from 'react';
import {
	TableFooter,
	TableCell,
	TableRow,
	makeStyles,
} from '@material-ui/core';
import colors from '../../../../config/colors';
import classnames from 'classnames';

interface FooterProps {
	footerGroups: any;
	outerClasses?: OuterClasses;
}

type OuterClasses = {
	footerRow?: string;
	footerCellRoot?: string;
	footerRowRoot?: string;
};

const useStyles = makeStyles((theme: any) => ({
	footer: {
		height: 58,
		left: 0,
		bottom: 0, // <-- KEY
		zIndex: 2,
		position: 'sticky',
		background: '#fff',
	},
	footerCell: {
		fontFamily: 'RubikRegular',
		fontSize: 14,
		fontWeight: 700,
		padding: '8px 16px 8px 8px',
		lineHeight: '1rem',
		textAlign: 'right',
		color: colors.darkGrey,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		background: '#fff',
	},
}));

const defaultStickyRight = {
	position: 'sticky',
	zIndex: 3,
	boxShadow: '-3px 0 0 0 #0000001e',
};

const Footer = ({ footerGroups, outerClasses }: FooterProps) => {
	const classes = useStyles();
	return (
		<TableFooter classes={{ root: classes.footer }}>
			{footerGroups.map((group: any) => (
				<TableRow {...group.getFooterGroupProps()}>
					{group.headers.map((column: any) => (
						<TableCell
							classes={{
								root: classnames(
									classes.footerCell,
									outerClasses?.footerCellRoot,
								),
							}}
							{...column.getFooterProps()}
							style={{
								...column.getFooterProps().style,
								...column.style,
								...(column.sticky === 'right'
									? defaultStickyRight
									: {}),
							}}
						>
							{column.render('Footer')}
						</TableCell>
					))}
				</TableRow>
			))}
		</TableFooter>
	);
};

export default Footer;
