import React from 'react';
import { TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import colors from '../../../../config/colors';
import classNames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
	headerRow: {
		height: 61,
	},
	tableCell: {
		fontFamily: 'RubikMedium',
		fontSize: 14,
		fontWeight: 500,
		padding: '8px 16px 8px 8px',
		textAlign: 'right',
		color: colors.darkGrey,
		lineHeight: '1rem',
		backgroundColor: colors.white,
		left: 'unset',
	},
}));

const defaultStickyRight = {
	position: 'sticky',
	zIndex: 4,
	boxShadow: '-3px 0 0 0 #0000001e',
};

const Header = ({
	headerGroups,
	isAllRowsExpanded,
	toggleAllRowsExpanded,
	showExpandHeader,
}: {
	headerGroups: any;
	showExpandHeader: boolean;
	isAllRowsExpanded: boolean;
	toggleAllRowsExpanded: Function;
}) => {
	const classes = useStyles();
	return (
		<TableHead>
			{headerGroups.map((headerGroup: any) => (
				<TableRow
					{...headerGroup.getHeaderGroupProps()}
					classes={{ root: classes.headerRow }}
				>
					{headerGroup.headers.map((column: any) => (
						<TableCell
							{...column.getHeaderProps()}
							classes={{
								root: classNames(classes.tableCell),
							}}
							style={{
								padding: `8px ${
									column.id === 'expander' && showExpandHeader
										? 32
										: 16
								}px 8px 8px`,
								...column.getFooterProps().style,
								...column.style,
								...(column.sticky === 'right'
									? defaultStickyRight
									: {}),
							}}
						>
							{column.id === 'expander' && showExpandHeader ? (
								<span>
									{isAllRowsExpanded ? (
										<ArrowDropDownIcon
											color='inherit'
											onClick={e =>
												toggleAllRowsExpanded(false)
											}
										/>
									) : (
										<ArrowLeftIcon
											color='inherit'
											onClick={e =>
												toggleAllRowsExpanded()
											}
										/>
									)}
								</span>
							) : null}
							{column.render('Header')}
						</TableCell>
					))}
				</TableRow>
			))}
		</TableHead>
	);
};

export default Header;
