import { FontAwesome } from '@expo/vector-icons';
import React, { ReactElement } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { TouchableHighlight } from 'react-native';
import colors from '../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';

interface CarouselProps {
	itemWidth: number;
	itemMargin: number;
	children: ReactElement[];
	intervals: number;
	currentInterval: number;
	isInMobileView: boolean;
	onChangePage: (direction: 'next' | 'prev') => void;
	containerMaxWidth?: number;
	carouselRef: any;
	marginRight: number;
	notDisplayHelpers?: boolean;
	shouldDisplayArrows?: boolean;
}

const Carousel = ({
	itemWidth,
	itemMargin,
	children,
	intervals,
	currentInterval,
	isInMobileView,
	onChangePage,
	containerMaxWidth,
	marginRight,
	carouselRef,
	notDisplayHelpers,
	shouldDisplayArrows,
}: CarouselProps) => {
	const platform = useDevicePlatform();
	const container = StyleSheet.flatten([
		styles.container,
		containerMaxWidth ? { maxWidth: containerMaxWidth } : {},
		isInMobileView ? { paddingHorizontal: 0 } : {},
	]);

	return (
		<View style={container}>
			<View>
				<ScrollView
					ref={ref => (carouselRef.current = ref)}
					horizontal
					showsHorizontalScrollIndicator={false}
					scrollEventThrottle={0.5}
					decelerationRate='normal'
					contentContainerStyle={{
						paddingVertical: 24,
						marginRight,
						width:
							platform === DeviceType.WEB
								? `${
										100 *
										(isInMobileView
											? intervals / 1.4
											: intervals)
								  }%`
								: undefined,
					}}
				>
					{children.map((child, i) => (
						<View
							key={i}
							style={{
								width: itemWidth,
								marginHorizontal: itemMargin,
							}}
						>
							{child}
						</View>
					))}
				</ScrollView>

				{shouldDisplayArrows ||
				(!isInMobileView && !notDisplayHelpers) ? (
					<View
						style={StyleSheet.flatten([
							styles.arrowsContainer,
							{ top: shouldDisplayArrows ? '42%' : '50%' },
						])}
					>
						<TouchableHighlight
							underlayColor='transparent'
							style={{ position: 'relative', left: -20 }}
							onPress={() => onChangePage('prev')}
						>
							<FontAwesome
								style={{ fontSize: 16, color: colors.primary }}
								name='chevron-left'
							/>
						</TouchableHighlight>
						<TouchableHighlight
							underlayColor='transparent'
							style={{ position: 'relative', left: 20 }}
							onPress={() => onChangePage('next')}
						>
							<FontAwesome
								style={{ fontSize: 16, color: colors.primary }}
								name='chevron-right'
							/>
						</TouchableHighlight>
					</View>
				) : null}
			</View>

			{!isInMobileView && !notDisplayHelpers ? (
				<View style={styles.dots}>
					{Array(intervals)
						.fill(null)
						.map((_, id) => (
							<View
								key={id}
								style={StyleSheet.flatten([
									styles.dot,
									currentInterval === id
										? styles.selectedDot
										: null,
								])}
							></View>
						))}
				</View>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: '100%',
		paddingHorizontal: 28,
		transform: [{ rotateY: '180deg' }],
	},
	arrowsContainer: {
		zIndex: -1,
		position: 'absolute',
		top: '50%',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	showAllButtonContainer: {
		position: 'relative',
		transform: [{ rotateY: '180deg' }],
		paddingHorizontal: 16,
	},
	dots: {
		flexDirection: 'row',
		justifyContent: 'center',
	},
	dot: {
		marginHorizontal: 4,
		width: 6,
		height: 6,
		backgroundColor: colors.grey,
		borderRadius: 3,
	},
	selectedDot: {
		backgroundColor: colors.darkGrey,
	},
});

export default Carousel;
