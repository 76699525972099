import React, { useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import ImageIcon from '../../Icon/ImageIcon.component';
import I18n from '../../i18n/I18n.component';
import { convertFromUtc } from '../Clock.utils';
import { i18nService } from '../../../../services/i18n.service';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../store/selectors/login.selectors';
export interface Record {
	workLogId: number;
	projectId: any;
	projectName: any;
	eventId: any;
	eventName: any;
	enterTime: any;
	exitTime: any;
	fullDay: boolean;
	total: number;
}
interface Props {
	record: Record;
	onDelete: Function;
	onEdit: Function;
	isToday?: any;
}

const RecordCard = ({ record, isToday, onEdit, onDelete }: Props) => {
	const convertMinutesToHours = (value: any, format?: string) => {
		if (value) {
			if (format === 'HH:mm') {
				const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
				const minutes =
					value % 60 !== 0
						? value % 60 < 10
							? `0${value % 60}`
							: `${value % 60}`
						: '00';
				return `${hours}:${minutes}`;
			}
			return `${(value / 60).toFixed(2)}`;
		}
		return '';
	};
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const userData = useSelector(getUserData);
	const canEdit = useMemo(
		() =>
			userData?.employeeRolePrivileges?.includes('ROLE_LOG_WORK_WRITE') &&
			userData.customer_setting.allowUpdateReport,
		[userData],
	);
	return (
		<View style={styles.recordHolder}>
			<View style={styles.recordLine} />
			<View style={styles.recordContent}>
				<View style={{ flex: 1 }}>
					<I18n size={12}>general.enterence</I18n>
					<I18n
						weight='bold'
						color={
							!isToday && record.enterTime === '00:00'
								? 'red'
								: 'darkGrey'
						}
					>
						{record.enterTime
							? convertFromUtc(record.enterTime, true)
							: '00:00'}
					</I18n>
				</View>
				<View style={{ borderLeftColor: '#525962' }}>
					<I18n size={12}>general.exit</I18n>
					<I18n
						weight={record.exitTime ? 'bold' : 'normal'}
						style={{ opacity: [record.exitTime ? 1 : 0.3] }}
					>
						{record.exitTime
							? convertFromUtc(record.exitTime, true)
							: '00:00'}
					</I18n>
				</View>
				<View style={styles.verticalRecordLine} />
				<View
					style={{
						flex: isMobile ? 3 : 4,
						flexDirection: 'row-reverse',
						alignItems: 'center',
					}}
				>
					<View>
						<I18n size={12}>reportCard.totalHours</I18n>
						<I18n
							weight={record.total ? 'bold' : 'normal'}
							style={{ opacity: [record.total ? 1 : 0.3] }}
						>
							{record.total
								? convertMinutesToHours(record.total, 'HH:mm')
								: '00:00'}
						</I18n>
					</View>
					{record.eventName || record.projectName ? (
						<I18n
							color={
								record.eventName ? 'warning' : 'lightPrimary'
							}
							size={14}
							style={{ marginRight: 30 }}
						>
							{record.projectName
								? record.projectName
								: record.eventName
								? record.fullDay
									? `${
											record.eventName
									  } ${i18nService.translate(
											'modal.report.fullDay',
									  )}`
									: record.eventName
								: null}
						</I18n>
					) : null}
				</View>
				{canEdit && (
					<>
						<View style={styles.verticalRecordLine} />
						<View
							style={{
								flexDirection: 'row',
								flex: isMobile ? 2 : 1,
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: 5,
							}}
						>
							<TouchableOpacity
								onPress={() => onDelete?.(record.workLogId)}
							>
								<ImageIcon name='delete' />
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() =>
									onEdit?.(record.workLogId, record)
								}
							>
								<ImageIcon name='edit' />
							</TouchableOpacity>
						</View>
					</>
				)}
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	recordHolder: {},
	recordLine: {
		opacity: 0.2,
		width: '100%',
		height: 0,
		borderTopColor: '#525962',
		borderTopWidth: 2,
		marginVertical: 5,
	},
	verticalRecordLine: {
		opacity: 0.2,
		height: 30,
		width: 0,
		borderLeftColor: '#525962',
		borderLeftWidth: 2,
		marginHorizontal: 10,
	},
	recordContent: {
		height: 50,
		width: '100%',
		flexDirection: 'row-reverse',
		justifyContent: 'center',
		alignContent: 'space-between',
		alignItems: 'center',
	},
});
export default RecordCard;
