import React, { useEffect } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import WorkerCardEditor from './WorkerCardEditor/WorkerCardEditor';
import Header from '../../components/shared/Header/Header.container';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import colors from '../../config/colors';
import ReportPDFPageContainer from '../ReportPDFPage/ReportPDFPage.container';
import { Platform, View } from 'react-native';
import { useRoute } from '@react-navigation/core';
import { getDocumentTitle } from '../../utils/globals.utils';

const Tab = createBottomTabNavigator();

const WorkerCard = ({ navigation }: any) => {
	const platform = useDevicePlatform();
	const route = useRoute();
	const isMobile = platform !== DeviceType.WEB;

	useEffect(() => {
		onMount();
		if (route?.params?.screen === '101table') {
			navigation.navigate('form101', { screen: '101table' });
		}
	}, []);

	const onMount = async() => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('WorkerCardComponent');
		}
	}

	return (
		<View style={{ flex: 1 }}>
			<Header
				navigation={navigation}
				containerStyle={{
					backgroundColor: isMobile ? '' : colors.white,
				}}
				component={isMobile ? MobileHeader : WebHeader}
			/>
			<Tab.Navigator
				tabBar={({ navigation }: any) => null}
				sceneContainerStyle={{ backgroundColor: 'transparent' }}
				initialRouteName='101table'
			>
				<Tab.Screen
					name='101table'
					component={ReportPDFPageContainer}
					options={{
						title: getDocumentTitle('101table'),
						unmountOnBlur: true,
					}}
				/>
				<Tab.Screen
					name='editor'
					component={WorkerCardEditor}
					options={{
						title: getDocumentTitle('editor'),
						unmountOnBlur: true,
					}}
				/>
			</Tab.Navigator>
		</View>
	);
};

export default WorkerCard;
