import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, View } from "react-native";
import { Calendar, LocaleConfig, DateObject } from "react-native-calendars";
import {
  rtlTheme,
  setCalendarLocale,
} from "../../../config/calendarLocaleConfig";
import colors from "../../../config/colors";
import Typography from "../Typography/Typography.component";
import ReportsCalendarDayContainer from "./ReportsCalendarDay.container";
import { useSelector } from "react-redux";
import { getChosenDate } from "../../../store/selectors/clockReport.selector";
setCalendarLocale(LocaleConfig);

interface ReportsCalendarProps {
  onDateChange: (date: DateObject) => void;
  navigation: any;
}

const ReportsCalendar = ({
  onDateChange,
  navigation,
}: ReportsCalendarProps) => {
  const chosenDate = useSelector(getChosenDate);
  return (
    <View style={styles.root}>
      <Calendar
        disableArrowLeft={false}
        disableArrowRight={false} //if true month in the feature will be disabled
        onMonthChange={(date: DateObject) => onDateChange(date)}
        dayComponent={(props) => (
          <ReportsCalendarDayContainer {...props} navigation={navigation} />
        )}
        hideExtraDays={true}
        style={styles.calendar}
        theme={rtlTheme}
        current={chosenDate}
        renderHeader={(date) => {
          const monthName = LocaleConfig.locales[LocaleConfig.defaultLocale]
            .monthNames![date.getMonth()];
          return (
            <View style={styles.headerContainer}>
              <Typography weight="bold" size={36} style={{ height: 48.5 }}>
                {monthName.slice(0, 1)}
              </Typography>
              <Typography
                weight="bold"
                size={36}
                color="lightPrimary"
                style={{ height: 48.5 }}
              >
                {monthName.slice(1, 2)}
              </Typography>
              <Typography weight="bold" size={36} style={{ height: 48.5 }}>
                {monthName.slice(2)}
              </Typography>
              <Typography
                weight="normal"
                size={22}
                style={{ paddingRight: 10 }}
              >
                {date.getFullYear()}
              </Typography>
            </View>
          );
        }}
        renderArrow={(direction) => (
          // Flip directional arrows for RTL
          <View style={styles.arrowContainer}>
            <FontAwesome
              style={{ color: colors.primary }}
              name={direction === "left" ? "chevron-right" : "chevron-left"}
            />
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.white,
    width: "100%",
    flex:1,
    paddingTop: 20,
  },
  calendar: {
    paddingHorizontal: 20,
  },
  headerContainer: {
    flexDirection: "row-reverse",
    alignItems: "baseline",
  },
  arrowContainer: {
    backgroundColor: colors.lightBlue,
    width: 24,
    height: 24,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ReportsCalendar;
