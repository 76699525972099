import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/core';
import httpService from '../../../services/http.service';
import { getDepartmentsFilterList } from '../../../store/selectors/reportsFilters.selectors';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
} from '../../../store/actions/reportsFilters.actions';
import { getUserData } from '../../../store/selectors/login.selectors';
import LocationSettingsPage from './LocationSettingsPage.component';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';
import { compact } from 'lodash';

const LocationSettingsPageContainer = ({ navigation }: { navigation: any }) => {
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const userData = useSelector(getUserData);

	const platform = useDevicePlatform();
	const isMobile = platform === DeviceType.MOBILE;

	const [locationSettingsData, setLocationSettingsData] = useState([]);
	const [projectsFilterList, setProjectsFilterList] = useState([]);
	const [projectsForFilters, setProjectsForFilters] = useState([]);
	const [locationFilterList, setLocationFilterList] = useState([]);
	const [disabledLoad, setDisableLoad] = useState(false);
	const [prevUnitFilterAll, setPrevUnitFilterAll] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('ASC');
	const [sortBy, setSortBy] = useState('name');
	const [filters, setFilters] = useState({
		location: [],
		emp: [],
		dep: [],
		project: [],
		unitIds: [],
	});
	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.locationSettings.privileges,
		setFilters,
	});

	const [selectedDepartments, setSelectedDepartments] = useState<any>({
		dep: [],
	});

	const route = useRoute() as any;
	const dispatch = useDispatch();

	const defaultSort = 'name';
	const defaultOrder = 'DESC';

	useEffect(() => {
		if (filters.unitIds.length || multiValue) {
			gerProjectsFilterList();
			gerLocationFilterList();
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(filters.unitIds),
				),
			);
			getLocationData(true, sortBy || defaultSort, order || defaultOrder);
		}
	}, [filters.unitIds]);

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: 20,
			search,
			filter: { ...selectedDepartments, internal: ['true'] },
			unitIds: getSelectedUnits(filters.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: 20 === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const gerProjectsFilterList = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getUnitProjectsFilter',
				query: {
					pageName: 'locationSettings',
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
			});

			if (res) {
				setProjectsForFilters(
					res.map(proj => ({ name: proj.name, id: proj.id })),
				);
				setProjectsFilterList(res);
			}
		} catch (e) {}
	};

	const gerLocationFilterList = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getLocationFilter',
				query: {
					pageName: 'locationSettings',
					searchText: '',
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
				disableBI: true,
			});
			if (res) {
				setLocationFilterList(res);
			}
		} catch (e) {}
	};

	const filtersConfig = useMemo(() => {
		let data = compact([
			{
				name: 'projects',
				key: `${JSON.stringify(filters.unitIds)}-projects`,
				options: projectsForFilters,
				placeholder: 'projects',
				styles: {
					control: {
						display:
							projectsFilterList.length > 0 ? 'flex' : 'none',
					},
					width: 134,
				},
				type: 'multiselect',
			},
			{
				name: 'departments',
				options: departmentsFilterList,
				placeholder: 'departments',
				styles: { width: 134 },
				type: 'multiselect',
				key: `${JSON.stringify(filters.unitIds)}-departments`,
			},
			{
				name: 'employees',
				type: 'asyncmultiselect',
				placeholder: 'employees',
				loadOptions: getEmployeesFilter,
				styles: {
					width: 274,
				},
				key: `${JSON.stringify(filters.unitIds)}-employees`,
			},
			{
				name: 'addresses',
				key: `${JSON.stringify(filters.unitIds)}-addresses`,
				placeholder: 'addresses',
				options: locationFilterList,
				type: 'multiselect',
				styles: {
					control: {
						width: 274,
						maxWidth: 274,
					},
				},
			},
			businessUnitsList.length > 1
				? isBusinessUnitsMulti
					? {
							name: 'unitIds',
							key: `${JSON.stringify(filters.unitIds)}-unitIds`,
							placeholder: 'businessUnits',
							value: filters.unitIds?.map(unit =>
								businessUnitsList.find(
									bunit => bunit.id === unit,
								),
							),
							options: businessUnitsList,
							type: 'multiselect',
							noSpecialAll: true,
							styles: {
								control: {
									width: 200,
									maxWidth: 200,
								},
							},
					  }
					: {
							name: 'unitIds',
							type: 'select',
							placeholder: 'businessUnits',
							defaultValue: filters.unitIds?.[0],
							options: businessUnitsList,
							hideDefaultOption: true,
							styles: {
								width: 150,
							},
					  }
				: undefined,
		]);

		return data;
	}, [
		departmentsFilterList,
		projectsFilterList,
		userData,
		locationFilterList,
		businessUnitsList,
		filters.unitIds,
		isBusinessUnitsMulti,
		multiValue,
	]);

	const getLocationData = async (
		firstInit: boolean,
		newSortBy?: string,
		neworder?: string,
		filterData?: any,
	) => {
		if (filters?.unitIds.length === 0 && prevUnitFilterAll) {
			setPrevUnitFilterAll(false);
			return;
		}
		try {
			const tempfilters = filterData || filters;
			const ps = isMobile ? 15 : 40;
			const res = await httpService.api<any>({
				type: 'getLocationsList',
				data: {
					p: firstInit ? 0 : nextPage,
					ps,
					s:
						newSortBy === 'businessUnitName'
							? 'employeer_name'
							: newSortBy
							? newSortBy
							: sortBy === 'businessUnitName'
							? 'employeer_name'
							: sortBy,
					sd: neworder ? neworder : order,
					f: {
						...tempfilters,
						unitIds: getSelectedUnits(tempfilters?.unitIds),
					},
				},
			});

			if (res) {
				setDisableLoad(ps !== res?.data?.length);
				if (firstInit) {
					setLocationSettingsData(res.data);
					setNextPage(1);
				} else {
					setLocationSettingsData([
						...locationSettingsData,
						...res.data,
					]);
					setNextPage(nextPage + 1);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onSort = (newSort: string) => {
		let newOrder = order;
		if (newSort === sortBy) {
			newOrder = order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			newOrder = 'DESC';
		}

		setOrder(newOrder);
		setSortBy(newSort);
		getLocationData(true, newSort, newOrder, filters);
	};

	const onFilter = (data: any) => {
		let fl = { ...filters };
		if (data.name === 'unitIds') {
			setPrevUnitFilterAll(false);
			if (filters.unitIds.length === businessUnitsList.length) {
				setPrevUnitFilterAll(true);
			}
			fl = {
				...fl,
				dep: [],
				emp: [],
				location: [],
				unitIds: data.isMulti
					? data.value
							.filter((val: any) => val.id !== 'all')
							.map((val: any) => val.id || val)
					: [data.value],
			};
			setSelectedDepartments({
				dep: [],
			});
		}
		if (data.name === 'departments') {
			setSelectedDepartments({
				dep: data.value.map((el: any) => el.id),
			});
			fl = { ...fl, dep: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'addresses') {
			fl = { ...fl, location: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'employees') {
			fl = { ...fl, emp: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'projects') {
			fl = { ...fl, project: data.value.map((el: any) => el.id) };
		}
		setFilters(fl);
		data.name !== 'unitIds' &&
			getLocationData(
				true,
				sortBy || defaultSort,
				order || defaultOrder,
				fl,
			);
	};

	const onRefresh = () => {
		gerLocationFilterList();
		getLocationData(true, sortBy, order, filters);
	};

	return (
		<LocationSettingsPage
			navigation={navigation}
			data={locationSettingsData}
			projectsFilterList={projectsFilterList}
			departmentsFilterList={departmentsFilterList}
			onSort={onSort}
			onLoadMore={() =>
				disabledLoad
					? null
					: getLocationData(false, sortBy, order, filters)
			}
			onFilter={onFilter}
			filtersConfig={filtersConfig}
			refresh={onRefresh}
			businessUnitsList={businessUnitsList}
			selectedUnitIds={getSelectedUnits(filters?.unitIds)}
		/>
	);
};

export default LocationSettingsPageContainer;
