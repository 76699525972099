import React, {
	useEffect,
	useState,
	useMemo,
	useRef,
	useCallback,
} from 'react';
import httpService from '../../../services/http.service';
import EmployeeHoursReportSummary from './EmployeeHoursReportSummary.component';
import { modalService } from '../../../services/modal.service';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import { useRoute } from '@react-navigation/native';
import AddRequest from '../../../modals/AddRequestModal/AddRequestModal.container';
import { compact, debounce } from 'lodash';
import { convertFromUtc } from './EmployeeHoursReportSummary.utils';
import { getUserData } from '../../../store/selectors/login.selectors';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { openAlertModal } from '../../../components/shared/ClockReport/Clock.utils';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import { Privileges } from '../../../constants/roleTypes.constant';
export interface tableData {
	id: number;
	teudatZeut: string;
	fullName: string;
	projectName: any;
	eventName: string;
	date: string;
	enterTime: string;
	exitTime: string;
	lastUpdate: string;
	total: number;
	updatedBy: string;
	updatedEnter: boolean;
	updatedExit: boolean;
	updatedProject: boolean;
	pendingStatus: string;
	businessUnitId: number;
}

const EmployeeHoursReportSummaryContainer = ({
	navigation,
	currentEmployee,
	OnPrevEmployeePress,
	OnNextEmployeePress,
	reqForWorkLogREports,
	backToAllHoursReports,
	monthFilter,
	yearFilter,
	disableLoad,
	disableNextButton,
	worklogReportFormat,
}: {
	navigation: any;
	currentEmployee: any;
	OnPrevEmployeePress: () => {};
	OnNextEmployeePress: () => {};
	reqForWorkLogREports: () => void;
	backToAllHoursReports: () => void;
	monthFilter: any[];
	yearFilter: any;
	disableLoad: boolean;
	disableNextButton: boolean;
	worklogReportFormat: any;
}) => {
	const [tableData, setTableData] = useState<tableData[]>([]);
	const [totalRow, setTotalRow] = useState({});
	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('DESC');
	const [sortBy, setSortBy] = useState(null);
	const [isSelectMode, setSelectMode] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [events, setEvents] = useState([]);
	const [projects, setProjects] = useState([]);
	const route = useRoute();
	const [filters, setFilters] = useState({
		year: yearFilter,
		month: monthFilter,
		event: [],
		status: [],
		special: [],
		project: [],
		unitIds: [],
	});
	const userData = useSelector(getUserData);
	const defaultSort = null;
	const defaultOrder = 'DESC';
	const prevEmployee = useRef(currentEmployee);
	const isFirstInit = useRef(true);
	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.hoursReportSummary.privileges,
		setFilters,
		defaultUnit: [currentEmployee.businessUnitId],
	});

	useEffect(() => {
		if (!isFirstInit.current) {
			setFilters({
				year: yearFilter,
				month: monthFilter,
				event: [],
				status: [],
				special: [],
				project: [],
				unitIds: [...filters.unitIds],
			});
			prevEmployee.current = currentEmployee;
		}
	}, [currentEmployee]);

	useEffect(() => {
		if (!isFirstInit.current) {
			if (currentEmployee === prevEmployee.current)
				getData(true, defaultSort, defaultOrder);
		}
	}, [filters]);

	useEffect(() => {
		const onlyPendingRows = tableData.filter(
			row => row.pendingStatus === 'ממתין לאישור',
		);
		if (selectedRows.length !== onlyPendingRows.length)
			setIsAllSelected(false);
		else if (selectedRows.length !== 0) setIsAllSelected(true);
	}, [selectedRows]);

	useEffect(() => {
		isFirstInit.current = false;
	}, []);

	useEffect(() => {
		if (filters.unitIds.length || multiValue) {
			getEventsOptions();
			getProjectsOptions();
		}
	}, [filters.unitIds]);

	const canEdit = useMemo(
		() =>
			!!getSelectedUnits(filters.unitIds).find((unit: number) =>
				hasPermissionOnBusinessUnit(unit, 'ROLE_LOG_WORK_REPORT_WRITE'),
			) && userData.customer_setting.allowUpdateReport,
		[filters.unitIds, userData],
	);

	const filtersConfig = useMemo(
		() =>
			compact([
				{
					name: 'status',
					options: [
						{ id: 'APPROVED', name: 'reportView.APPROVED' },
						{
							id: 'NO_APPROVAL_REQUIRED',
							name: 'reportView.NO_APPROVAL_REQUIRED',
						},
						{
							id: 'PENDING_APPROVAL',
							name: 'reportView.PENDING_APPROVAL',
						},
					],
					placeholder: 'status',
					styles: { width: 134 },
					type: 'multiselect',
					key: currentEmployee,
					alwaysRerender: true,
				},
				{
					name: 'special',
					options: [
						{ id: true, name: 'workerCard.editor.missingFields' },
					],
					placeholder: 'special',
					styles: { width: 134 },
					type: 'select',
					defaultValue: filters.special[0],
				},
				{
					name: 'event',
					options: events,
					placeholder: 'event',
					styles: { width: 134 },
					type: 'multiselect',
					key: `${currentEmployee}${JSON.stringify(filters.unitIds)}`,
					alwaysRerender: true,
				},
				{
					defaultValue: '',
					options: projects,
					name: 'project',
					placeholder: 'project',
					styles: { width: 134 },
					type: 'multiselect',
					key: `${currentEmployee}${JSON.stringify(filters.unitIds)}`,
					alwaysRerender: true,
				},
				// businessUnitsList?.length > 1
				// 	? isBusinessUnitsMulti
				// 		? {
				// 				name: 'unitIds',
				// 				type: 'multiselect',
				// 				placeholder: 'businessUnits',
				// 				value: filters.unitIds?.map((unit: any) =>
				// 					businessUnitsList.find(
				// 						(bunit: any) => bunit.id === unit,
				// 					),
				// 				),
				// 				options: businessUnitsList,
				// 				styles: {
				// 					control: {
				// 						width: 200,
				// 						maxWidth: 200,
				// 					},
				// 				},
				// 		  }
				// 		: {
				// 				name: 'unitIds',
				// 				type: 'select',
				// 				placeholder: 'businessUnits',
				// 				defaultValue: filters.unitIds?.[0],
				// 				options: businessUnitsList,
				// 				hideDefaultOption: true,
				// 				styles: {
				// 					width: 150,
				// 				},
				// 		  }
				// 	: undefined,
			]),
		[
			events,
			projects,
			currentEmployee,
			filters,
			businessUnitsList,
			isBusinessUnitsMulti,
		],
	);

	const getEventsOptions = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getEventNameList',
				query: {
					pageName: route.name,
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
			});
			if (res) {
				setEvents(res);
			}
		} catch (e) {}
	};

	const getProjectsOptions = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getProjectNameList',
				query: {
					pageName: route.name,
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
			});
			if (res) {
				setProjects(res);
			}
		} catch (e) {}
	};

	const getData = async (
		firstInit: boolean,
		newSortBy?: any,
		neworder?: string,
	) => {
		try {
			const res = await httpService.api<any>({
				type: 'getEmployeeReportSummery',
				params: { profileId: currentEmployee.profileId },
				data: {
					p: firstInit ? 0 : nextPage,
					ps: 20,
					s: newSortBy ? newSortBy : sortBy,
					sd: neworder ? neworder : order,
					f: {
						...filters,
						unitIds: getSelectedUnits(filters?.unitIds),
					},
				},
			});
			if (res) {
				if (res.totalRow) setTotalRow(res.totalRow);
				setDisableLoad(!res.hasNext);
				if (firstInit) {
					setTableData(res.data);
					setNextPage(1);
				} else {
					setTableData([...tableData, ...res.data]);
					setNextPage(nextPage + 1);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onSort = (newSort: string) => {
		let newOrder = order;
		if (newSort === sortBy) {
			newOrder = order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			newOrder = 'DESC';
		}
		setOrder(newOrder);
		setSortBy(newSort);
		getData(true, newSort, newOrder);
	};

	const onFilter = (filterData: any) => {
		let chosenFilters: any = {};
		Object.keys(filters).forEach(key => {
			if (key === 'year') chosenFilters[key] = filters[key];
			else chosenFilters[key] = [...filters[key]];
		});

		switch (filterData.name) {
			case 'unitIds':
				chosenFilters = {
					...chosenFilters,
					unitIds: filterData.isMulti
						? filterData.value.map((val: any) => val.id || val)
						: [filterData.value],
					project: [],
					event: [],
				};
			case 'special':
				chosenFilters[filterData.name] =
					filterData.value === undefined ? [] : [filterData.value];

				break;
			case 'status':
				chosenFilters[filterData.name] = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
			case 'project':
				if (filterData.isAllSelected) {
					chosenFilters[filterData.name] = projects.map(
						project => project.id,
					);
					break;
				}
				if (filterData.value.length === 0) {
					chosenFilters[filterData.name] = [];
					break;
				}
				chosenFilters[filterData.name] = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
			default:
				if (filterData.isAllSelected) {
					chosenFilters[filterData.name] = events.map(
						event => event.id,
					);
					break;
				}
				if (filterData.value.length === 0) {
					chosenFilters[filterData.name] = [];
					break;
				}
				chosenFilters[filterData.name] = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
		}
		setFilters(chosenFilters);
	};

	const handleRowSelect = ({ workLogId }) => {
		const indexOfRow = selectedRows.indexOf(workLogId);
		if (indexOfRow === -1)
			setSelectedRows(selectedRows => [...selectedRows, workLogId]);
		else {
			const tempSelectedRows = [...selectedRows];
			tempSelectedRows.splice(indexOfRow, 1);
			setSelectedRows(tempSelectedRows);
		}
	};

	const handleSelectAll = (isChecked: boolean) => {
		const tempRows = [];
		const onlyPendingRows = tableData.filter(
			row =>
				row.pendingStatus === 'ממתין לאישור' &&
				hasPermissionOnBusinessUnit(
					row.businessUnitId,
					Privileges.ROLE_LOG_WORK_REPORT_WRITE,
				),
		);
		if (isChecked) {
			onlyPendingRows.forEach(row => tempRows.push(row.workLogId));
			setSelectedRows(tempRows);
		} else setSelectedRows([]);

		setIsAllSelected(isChecked);
	};

	const approveSelected = async () => {
		if (selectedRows.length === 0) {
			setSelectMode(false);
			return;
		}
		setIsAllSelected(false);
		setSelectedRows([]);
		setSelectMode(false);
		try {
			const res = await httpService.api<any>({
				type: 'approveEmployeeReport',
				params: { profileId: currentEmployee.profileId },
				data: {
					f: filters,
					workLogIds: isAllSelected ? [] : [...selectedRows],
				},
			});
			getData(true, defaultSort, defaultOrder);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDelete = async ({ workLogId: id, lockReport }: any) => {
		if (lockReport) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else
			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						try {
							const res = await httpService.api<any>({
								type: 'deleteEmployeeReport',
								params: { workLogId: id },
							});
							getData(true, defaultSort, defaultOrder);
						} catch (error) {
							console.log(error);
						}
						callback();
					},
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},

				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>general.shouldDeleteItem</I18n>
					</ModalConfirmActions>
				),
			);
	};

	const handleEdit = async ({
		workLogId: id,
		userId,
		fullName,
		workLogDate: date,
		businessUnitId,
		businessUnitName,
		lockReport,
	}: any) => {
		if (lockReport) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else {
			let logValues = {};
			if (id) {
				logValues = await httpService.api<any>({
					type: 'workLogByIdManager',
					params: { workLogId: id },
				});
			}

			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						getData(true, sortBy, order);
						callback();
					},
					shiftHours: userData?.customer_setting.maxShiftHour,
					submitBtnText: 'general.accept',
					route: route,
					cancelBtnText: 'general.cancel',
					isEdit: id ? true : false,
					openEditModal: handleEdit,
					workLogId: id,
					finishWork: currentEmployee.finishWork,
					isManager: true,
					businessUnitId,
					businessUnitName,
					prevValues: id
						? {
								...logValues,
								originalExitEntry: {
									entryTime: logValues.entryTime,
									exitTime: logValues.exitTime,
								},
								entryTime: convertFromUtc(
									logValues.entryTime,
									true,
								),
								exitTime: convertFromUtc(
									logValues.exitTime,
									true,
								),
								employee: {
									id: logValues.employeeId,
									name: logValues.fullName,
								},
						  }
						: userId
						? {
								employee: {
									id: userId,
									name: fullName,
								},
								fullName,
								date,
						  }
						: null,
				},
				(props: any) => <AddRequest {...props} />,
			);
		}
	};

	const handleAddNewLog = async () => {
		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},

				onSubmit: async (callback: Function) => {
					getData(true, sortBy, order);
					callback();
				},
				shiftHours: userData?.customer_setting.maxShiftHour,
				submitBtnText: 'general.accept',
				route: route,
				cancelBtnText: 'general.cancel',
				isEdit: false,
				businessUnitId: filters.unitIds[0],
				finishWork: currentEmployee.finishWork,
				isManager: true,
				prevValues: {
					employeeId: currentEmployee.userId,
					fullName: currentEmployee.fullName,
				},
				openEditModal: handleEdit,
			},
			(props: any) => <AddRequest addNew {...props} />,
		);
	};

	const debouncedGetMoreData = debounce(() => {
		getData(false);
	}, 300);

	return (
		<EmployeeHoursReportSummary
			addReport={handleAddNewLog}
			backToAllHoursReports={backToAllHoursReports}
			reqForWorkLogREports={reqForWorkLogREports}
			disableLoad={disableLoad}
			disableNextButton={disableNextButton}
			navigation={navigation}
			currentEmployee={currentEmployee}
			data={tableData}
			totalData={totalRow}
			onSort={onSort}
			onLoadMore={() => (disabledLoad ? null : debouncedGetMoreData())}
			onFilter={onFilter}
			filtersConfig={filtersConfig}
			refresh={() => getData(true, defaultSort, defaultOrder)}
			OnPrevEmployeePress={OnPrevEmployeePress}
			OnNextEmployeePress={OnNextEmployeePress}
			reqForSelect={() => setSelectMode(true)}
			isSelectMode={isSelectMode}
			selectedRows={selectedRows}
			isAllSelected={isAllSelected}
			onSelectAll={handleSelectAll}
			onSelect={handleRowSelect}
			onConfirmSelect={approveSelected}
			onCancelSelect={() => {
				setIsAllSelected(false);
				setSelectedRows([]);
				setSelectMode(false);
			}}
			onEdit={handleEdit}
			onDelete={handleDelete}
			worklogReportFormat={worklogReportFormat}
			canEdit={canEdit}
			businessUnitsList={businessUnitsList}
		/>
	);
};

export default EmployeeHoursReportSummaryContainer;
