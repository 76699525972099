import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import { FilterData } from '../../../../components/web/Filters/Filters.container';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
} from '../../../../store/actions/reportsFilters.actions';
import { getDepartmentsFilterList } from '../../../../store/selectors/reportsFilters.selectors';
import {
	compareReportReducer,
	initialState,
	CompareReportReducerState,
	Action,
	fetchDepartments,
	fetchEmployees,
	fetchReportLastDate,
} from './compareReportReducer';
import CostCompareReportPage from './CostCompareReportPage.component';
import { useDispatch, useSelector } from 'react-redux';
import {
	addCommaSeparatorToNumber,
	addToTableCellCurrencySymbol,
} from '../../../../utils/reportsPagesHelpers.utils';
import {
	setTableHeaders,
	pageFilterSize,
	maxNumberOfEmployeesForAccordion,
	fetchExcel,
	getFiltersConfig,
} from './CostCompareReportPage.utils';
import { getExcelFileFromResponse } from '../../../../utils/downloadFile.web.utils';
import { useRoute } from '@react-navigation/native';
import { getUserData } from '../../../../store/selectors/login.selectors';
import useBusinessUnitsState from '../../../../hooks/useBusinessUnitsState';
import routes from '../../../../config/routes';

const CompareReportContainer = ({ navigation }: any) => {
	const dispatch = useDispatch();
	let date = new Date();
	date.setMonth(date.getMonth() - 6);
	const [tableData, setTableData] = useState<any>([]);
	const [employeesTableData, setEmployeesTableData] = useState<any>([]);
	const [defaultExpandedRows, setDefaultExpandedRows] = useState<any>({});
	const [currentTableState, setCurrentTableState] = useState<any>({});
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const [
		{
			filters,
			employees,
			departments,
			totalDepartmentsReports,
			sortEmployeesBy,
			employeesSortDirection,
			sortDepartmentsBy,
			departmentsSortDirection,
			periods,
			selectedDepartmentForEmployeesFetch,
			dateChanged,
			originalPeriods,
		},
		localDispatch,
	] = useReducer<React.Reducer<CompareReportReducerState, Action>>(
		compareReportReducer,
		initialState,
	);
	const [selectedDepartments, setSelectedDepartments] = useState<any>({
		dep: [],
	});
	const [departmentId, setDepartmentId] = useState('');
	const [headers, setHeaders] = useState<any>([]);
	const [itIsNotInitialRequest, setItIsNotInitialRequest] =
		useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [modalOpened, setModalOpened] = useState<boolean>(false);
	const [dashboardPageOpened, setDashboardPageOpened] =
		useState<boolean>(false);
	const route = useRoute() as any;
	const userData = useSelector(getUserData);

	const handleFilter = useCallback((data: FilterData) => {
		localDispatch({
			type: `update_${data.name}`,
			payload: data.name === 'unitIds' ? data : data.value,
		});
		if (data.name === 'departments') {
			setSelectedDepartments({
				dep: data.value.map((el: any) => el.id),
			});
		}
	}, []);

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.costingReports.privileges,
		onChangeFilter: handleFilter,
	});

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: pageFilterSize,
			search,
			filter: selectedDepartments,
			unitIds: getSelectedUnits(filters.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: pageFilterSize === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};
	const [filtersConfig, setFiltersConfig] = useState(
		getFiltersConfig(
			filters.period[0],
			departmentsFilterList,
			selectedDepartments,
			getEmployeesFilter,
			{
				toMonth: filters.to_month[0],
				toYear: filters.to_year[0],
				fromMonth: filters.from_month[0],
				fromYear: filters.from_year[0],
			},
			userData,
			dateChanged,
			true,
			businessUnitsList,
			isBusinessUnitsMulti,
			filters.unitIds,
			undefined,
			originalPeriods,
			filters.periods,
		),
	);
	const onModalBtnPressedHandler = () => {
		setModalOpened(false);
	};

	const onDepartmentsSort = useCallback((field, direction) => {
		localDispatch({
			type: 'set_departments_sort',
			payload: { field, direction },
		});
	}, []);

	const onEmployeesSort = useCallback((field, direction) => {
		localDispatch({
			type: 'set_employees_sort',
			payload: { field, direction },
		});
	}, []);

	useEffect(() => {
		if (periods.length) {
			const headers = setTableHeaders(periods, totalDepartmentsReports);
			setHeaders(headers);
		}
	}, [periods, totalDepartmentsReports]);

	useEffect(() => {
		if (!employees.length) {
			return;
		}

		const preparedEmployeesData = employees.map((item: any) => {
			for (const field in item.periods) {
				if (typeof item.periods[field] === 'number') {
					item.periods[field] = addCommaSeparatorToNumber(
						item.periods[field],
					);
				}
			}

			const preparedItem = addToTableCellCurrencySymbol({
				...item,
				...item.periods,
			});

			delete preparedItem.periods;

			return preparedItem;
		});

		if (employees.length > maxNumberOfEmployeesForAccordion) {
			setEmployeesTableData(preparedEmployeesData);
			setModalOpened(true);
		} else {
			setEmployeesTableData(preparedEmployeesData);

			const preparedTableData = tableData.map((row: any, i: number) => {
				if (row.id === departmentId) {
					row.subRows = preparedEmployeesData;
					const expandedRows = {
						[`${i}`]: true,
					};
					setDefaultExpandedRows({
						...currentTableState.expanded,
						...expandedRows,
					});
				}

				return row;
			});

			setTableData(preparedTableData);
		}
	}, [employees]);

	useEffect(() => {
		const preparedData = departments.map((item: any) => {
			for (const field in item.periods) {
				if (typeof item.periods[field] === 'number') {
					item.periods[field] = addCommaSeparatorToNumber(
						item.periods[field],
					);
				}
			}

			let preparedItem = {
				...item,
				...item.periods,
			};

			delete preparedItem.periods;
			preparedItem = addToTableCellCurrencySymbol(preparedItem);
			preparedItem.canHaveSubRows = true;

			return preparedItem;
		});
		setTableData(preparedData);
	}, [departments]);

	useEffect(() => {
		if (
			itIsNotInitialRequest &&
			filters.to_month.length > 0 &&
			filters.from_month.length > 0 &&
			(filters.unitIds.length || multiValue)
		) {
			fetchDepartments({
				sortBy: sortDepartmentsBy,
				sortDirection: departmentsSortDirection,
				filter: {
					...filters,
					unitIds: getSelectedUnits(filters.unitIds),
				},
				dispatch: localDispatch,
			});
		}
	}, [filters, sortDepartmentsBy, departmentsSortDirection]);

	useEffect(() => {
		if (
			filters !== initialState.filters ||
			sortDepartmentsBy !== initialState.sortDepartmentsBy ||
			departmentsSortDirection !== initialState.departmentsSortDirection
		) {
			localDispatch({ type: 'reset_filters', payload: null });
			setSelectedDepartments({
				dep: [],
			});
		} else {
			setItIsNotInitialRequest(true);
		}
	}, [userData]);

	useEffect(() => {
		if (filters.unitIds.length || multiValue) {
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(filters.unitIds),
				),
			);
			fetchReportLastDate(
				localDispatch,
				getSelectedUnits(filters.unitIds),
			);
		}
	}, [filters.unitIds]);

	useEffect(() => {
		if (selectedDepartmentForEmployeesFetch.length) {
			getEmployeesList(
				{
					...filters,
					dep: selectedDepartmentForEmployeesFetch,
				},
				localDispatch,
			);
		}
	}, [employeesSortDirection, sortEmployeesBy]);

	useEffect(() => {
		setFiltersConfig(
			getFiltersConfig(
				filters.period[0],
				departmentsFilterList,
				selectedDepartments,
				getEmployeesFilter,
				{
					toMonth: filters.to_month[0],
					toYear: filters.to_year[0],
					fromMonth: filters.from_month[0],
					fromYear: filters.from_year[0],
				},
				userData,
				dateChanged,
				false,
				businessUnitsList,
				isBusinessUnitsMulti,
				filters.unitIds,
				`${JSON.stringify(userData)}${JSON.stringify(filters.unitIds)}`,
				originalPeriods,
				filters.periods,
			),
		);
	}, [
		filters,
		departmentsFilterList,
		selectedDepartments,
		userData,
		originalPeriods,
		businessUnitsList,
		isBusinessUnitsMulti,
	]);

	const onChangePercent = useCallback(() => {
		localDispatch({
			type: `update_change_percent`,
			payload: !filters.change_percent[0],
		});
	}, [filters]);

	const getEmployeesList = (filter: any, dispatch: any) => {
		fetchEmployees({
			sortBy: sortEmployeesBy,
			sortDirection: employeesSortDirection,
			filter: {
				...filter,
				unitIds: getSelectedUnits(filter.unitIds),
			},
			dispatch,
		});
	};

	const onRowClick = useCallback(
		(row: any) => {
			if (row.subRows) {
				return;
			}

			setModalTitle(row.name);
			const filter = {
				...filters,
				dep: [row.id],
			};
			setDepartmentId(row.id);
			localDispatch({ type: 'set_selected_department', payload: row.id });
			getEmployeesList(filter, localDispatch);
		},
		[filters],
	);

	const getExcel = async () => {
		const res = await fetchExcel({
			sortBy: sortDepartmentsBy,
			sortDirection: departmentsSortDirection,
			filter: filters,
		});
		if (res) {
			getExcelFileFromResponse(res);
		}
	};

	const onLeftArrowClick = useCallback(
		(row: any) => {
			onRowClick(row);
		},
		[onRowClick],
	);

	const onTableButtonClick = (open: boolean) => {
		setDashboardPageOpened(!open);
	};

	return (
		<CostCompareReportPage
			filtersData={filtersConfig}
			onFilter={handleFilter}
			headers={headers}
			data={tableData}
			navigation={navigation}
			onRowClick={onRowClick}
			modalTitle={modalTitle}
			modalTableRows={employeesTableData}
			modalOpened={modalOpened}
			onModalBtnPressedHandler={onModalBtnPressedHandler}
			onSort={onDepartmentsSort}
			onEmployeesSort={onEmployeesSort}
			defaultExpandedRows={defaultExpandedRows}
			getExcel={getExcel}
			onLeftArrowClick={onLeftArrowClick}
			onTableButtonClick={onTableButtonClick}
			dashboardPageOpened={dashboardPageOpened}
			setCurrentTableState={setCurrentTableState}
			periods={periods}
			onChangePercent={onChangePercent}
			businessUnitsList={businessUnitsList}
		/>
	);
};

export default CompareReportContainer;
