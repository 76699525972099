import {
	CustomerSetting,
	ThunkedAction,
} from './../../interfaces/redux.interface';
import { AsyncLocalStorage } from '../../config/localStorage';
import { StoreAction } from '../../interfaces/redux.interface';
import * as loginActions from '../constants/loginActions';
import httpService from '../../services/http.service';
import {
	FailureActionArgs,
	LoginCredentials,
	UserCredentials,
} from '../../interfaces/redux.interface';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { pushNotificationsService } from '../../services/pushNotifications.service';
import {
	setDefaultDisplayMode,
	setDefaultdisplayWageData,
} from './config.actions';
import logoutWhenNoAction from '../../services/logoutWhenNoAction.service';
import { fetchBusinessUnitList } from './reportsFilters.actions';
import { modalService } from '../../services/modal.service';
import { EventName, logEvent } from '../../services/analyticsAndLogs.service';
import { omit } from 'lodash';
import { CodeType } from '../../pages/SignIn/components/LoginWithCode/LoginWithCode';

export const loginSuccess = (
	payload: LoginCredentials,
): StoreAction<LoginCredentials> => ({
	type: loginActions.LOGIN_SUCCESS,
	payload,
});

export const loginFailure = (
	payload: FailureActionArgs,
): StoreAction<FailureActionArgs> => ({
	type: loginActions.LOGIN_FAILURE,
	payload,
});

export const resetLoginError = (): StoreAction => ({
	type: loginActions.RESET_LOGIN_ERROR,
	payload: {},
});

export const loginRequest = (): StoreAction<undefined> => ({
	type: loginActions.LOGIN_REQUEST,
	payload: undefined,
});

export const loginRefreshToken = (payload: {
	token: string;
	refresh_token: string;
}): StoreAction<any> => ({
	type: loginActions.REFRESH_TOKEN,
	payload,
});

export const forgotPasswordRequest = (): StoreAction<undefined> => ({
	type: loginActions.FORGOT_PASSWORD_REQUEST,
	payload: undefined,
});

export const forgotPasswordFailure = (
	payload: FailureActionArgs,
): StoreAction<FailureActionArgs> => ({
	type: loginActions.FORGOT_PASSWORD_FAILURE,
	payload,
});

export const resetPasswordRequest = (): StoreAction<undefined> => ({
	type: loginActions.RESET_PASSWORD_REQUEST,
	payload: undefined,
});

export const resetPasswordFailure = (
	payload: FailureActionArgs,
): StoreAction<FailureActionArgs> => ({
	type: loginActions.RESET_PASSWORD_FAILURE,
	payload,
});

export const verifyForgetPasswordRequest = (): StoreAction<undefined> => ({
	type: loginActions.VERIFY_FORGOT_PASSWORD_REQUEST,
	payload: undefined,
});

export const setWorkerCardAlertOnChangedEmp = (
	setAlert: boolean,
): StoreAction<boolean> => ({
	type: loginActions.SET_WORKER_CARD_ALERT,
	payload: setAlert,
});

export const setIsInWorkerCard = (
	isInWorkerCard: boolean,
): StoreAction<boolean> => ({
	type: loginActions.SET_IS_IN_WORKER_CARD,
	payload: isInWorkerCard,
});

export const verifyForgetPasswordSuccess = (payload: {
	token: string;
}): StoreAction<any> => ({
	type: loginActions.VERIFY_FORGOT_PASSWORD_SUCCESS,
	payload,
});

export const localUpdateUserData = (payload: any): StoreAction<any> => ({
	type: loginActions.LOCAL_UPDATE_USER_DATA,
	payload,
});

export const login =
	(data: UserCredentials): ThunkedAction =>
	async dispatch => {
		try {
			dispatch(loginRequest());
			const res: any = await httpService.api({
				type: 'login',
				data,
				timeout: 30000,
			});
			await storeData(res);
			await dispatch(loginSuccess(res));
			await dispatch(getEmployersList());
		} catch (err) {
			logEvent({
				eventName: EventName.loginFailure,
				data: {
					data,
					error: err,
				},
			});
			dispatch(loginFailure(err || { errorMessage: 'NO_CONNECTION' }));
		}
	};

export const loginStep2SuccessCallback =
	(res: any): ThunkedAction =>
	async dispatch => {
		try {
			await storeData(res);
			await dispatch(loginSuccess(res));
			await dispatch(getEmployersList());
		} catch (err) {
			logEvent({
				eventName: EventName.loginStep2Failure,
				data: {
					error: err,
				},
			});
			dispatch(loginFailure(err || { errorMessage: 'NO_CONNECTION' }));
		}
	};

export const loginStep1 =
	(data: {
		login: string;
		phone: string;
		requestType: CodeType;
	}): ThunkedAction =>
	async dispatch => {
		try {
			dispatch(loginRequest());
			const res: any = await httpService.api({
				type: 'loginWithCode1',
				data: omit(data, 'httpName'),
				timeout: 30000,
			});
			return true;
		} catch (err) {
			logEvent({
				eventName: EventName.loginStep1Failure,
				data: {
					data,
					error: err,
				},
			});
			dispatch(loginFailure(err || { errorMessage: 'NO_CONNECTION' }));
		}
	};

export const refreshTokenSetData =
	(data: any): ThunkedAction =>
	async dispatch => {
		console.log('refreshTokenSetData', data);
		await storeData(data);
		dispatch(loginRefreshToken(data));
	};

export const forgotPassword =
	(teudatZeut: string): ThunkedAction =>
	async dispatch => {
		dispatch(forgotPasswordRequest());
		return await httpService
			.api({ type: 'forgotPassword', data: { teudatZeut } })
			.then(async (res: any) => {
				return true;
			})
			.catch(err => {
				dispatch(forgotPasswordFailure(err));
				return false;
			});
	};

export const resetPassword =
	(data: { password: string; password2: string }): ThunkedAction =>
	async dispatch => {
		dispatch(resetPasswordRequest());
		await httpService
			.api({ type: 'resetPassword', data })
			.then(res => console.log('password changed'))
			.catch(err => dispatch(resetPasswordFailure(err)));
	};

export const verifyForgetPassword =
	(data: { key: string; navigation: any }): ThunkedAction =>
	dispatch => {
		dispatch(verifyForgetPasswordRequest());
		httpService
			.api({ type: 'verifyForgetPassword', data: { key: data.key } })
			.then((res: any) => {
				dispatch(verifyForgetPasswordSuccess(res));
			})
			.catch(async () => {
				await dispatch(
					resetPasswordFailure({
						errorMessage: 'login.resetPasswordFailed',
						errorStatus: 400,
					}),
				);
			});
	};

const getItemFromStorage = async (name: string) => {
	let data = null;
	if (Platform.OS === 'android' || Platform.OS === 'ios') {
		data = await AsyncStorage.getItem(name);
	} else {
		data = sessionStorage.getItem(name);
	}
	return data;
};

const setItemToStorage = async (name: string, value: string) => {
	if (Platform.OS === 'android' || Platform.OS === 'ios') {
		await AsyncStorage.setItem(name, value);
	} else {
		sessionStorage.setItem(name, value);
	}
};

export const initStore = (): ThunkedAction => async dispatch => {
	const token: any = await getItemFromStorage(AsyncLocalStorage.TOKEN);
	const refresh_token: any = await getItemFromStorage(
		AsyncLocalStorage.REFRESH_TOKEN,
	);
	const roles: any = await getItemFromStorage(AsyncLocalStorage.ROLES);
	const employer: any = await getItemFromStorage(AsyncLocalStorage.EMPLOYER);
	const modules: any = await getItemFromStorage(AsyncLocalStorage.MODULES);

	if (token && refresh_token && roles && employer) {
		dispatch(
			loginSuccess({
				token,
				refresh_token,
				roles,
				employer,
				modules,
			}),
		);
	} else {
		dispatch({
			type: loginActions.LOGIN_INITIAL_STATE,
			payload: undefined,
		});
	}
};

export const logout = (): ThunkedAction => async dispatch => {
	modalService.closeAllModal();
	pushNotificationsService.destroy();
	logoutWhenNoAction.resetStartTime();
	dispatch({ type: loginActions.LOGOUT, payload: undefined });
	await httpService.api({ type: 'logout' }).catch(async error => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().recordError(error);
		}
	});
	clearStorage();
	// .finally(async () => {
	// 	await clearStorage();
	// 	dispatch({ type: loginActions.LOGOUT, payload: undefined });
	// });
};

const userDataRequest = (): StoreAction<undefined> => ({
	type: loginActions.USER_DATA_REQUEST,
	payload: undefined,
});

const userDataSuccess = (payload: {
	customer_setting: CustomerSetting;
	location: boolean;
	first_name: string;
	last_name: string;
}): StoreAction<any> => ({
	//TODO: change any
	type: loginActions.USER_DATA_SUCCESS,
	payload: { user: payload },
});

const userDataFailure = (
	payload: FailureActionArgs,
): StoreAction<FailureActionArgs> => ({
	type: loginActions.USER_DATA_FAILURE,
	payload,
});

export const getUserData =
	(config?: any): ThunkedAction =>
	dispatch => {
		dispatch(userDataRequest());
		httpService
			.api({ type: 'getUserData', disableBI: config?.disableBI })
			.then((res: any) => {
				dispatch(fetchBusinessUnitList());
				dispatch(userDataSuccess(res));
			})
			.catch(err => {
				logEvent({
					eventName: EventName.loginFailure,
					data: {
						error: err,
					},
				});
				dispatch(userDataFailure(err));
			});
	};

const selectEmployerRequest = (): StoreAction<undefined> => ({
	type: loginActions.SELECT_EMPLOYER_REQUEST,
	payload: undefined,
});

const selectEmployerSuccess = (payload: {
	id: string;
	name: string;
}): StoreAction<any> => ({
	type: loginActions.SELECT_EMPLOYER_SUCCESS,
	payload: { employer: payload },
});

const selectEmployerFailure = (
	payload: FailureActionArgs,
): StoreAction<FailureActionArgs> => ({
	type: loginActions.USER_DATA_FAILURE,
	payload,
});

export const selectEmployer =
	(employer: { id: string; name: string }): ThunkedAction =>
	dispatch => {
		dispatch(selectEmployerSuccess({ ...employer }));
		storeDataByKey(
			AsyncLocalStorage.EMPLOYER,
			JSON.stringify({ ...employer }),
		);
	};

const employerListRequest = (): StoreAction<undefined> => ({
	type: loginActions.EMPLOYER_LIST_REQUEST,
	payload: undefined,
});

const employerListSuccess = (payload: any[]): StoreAction<any> => ({
	type: loginActions.EMPLOYER_LIST_SUCCESS,
	payload: { employerList: payload },
});

const employerListFailure = (
	payload: FailureActionArgs,
): StoreAction<FailureActionArgs> => ({
	type: loginActions.EMPLOYER_LIST_FAILURE,
	payload,
});

export const getEmployersList = (): ThunkedAction => async dispatch => {
	await dispatch(employerListRequest());
	const employesRes = await httpService
		.api({ type: 'getEmployers' })
		.then(async (res: any) => {
			return res;
		})
		.catch(err => {
			dispatch(employerListFailure(err));
		});

	const userSettings: any = await httpService.api({
		type: 'getUserSettings',
	});

	dispatch(employerListSuccess(employesRes));
	dispatch(setDefaultDisplayMode(userSettings.displayMode));
	dispatch(setDefaultdisplayWageData(userSettings.displayWageData));
	const storageCustomer = await getStoreDataByKey(AsyncLocalStorage.EMPLOYER);
	const defaultCustomer = storageCustomer
		? JSON.parse(storageCustomer)
		: userSettings.defaultCustomerId &&
		  employesRes.find(
				(emp: any) =>
					emp.customer_id === userSettings.defaultCustomerId,
		  );
	if (defaultCustomer) {
		await dispatch(selectEmployer(defaultCustomer));
		return;
	}

	if (employesRes?.length) {
		const arrFinishedWorks = employesRes.filter(
			(employer: {
				id: string;
				name: string;
				start_work: string | null;
				finish_work: string | null;
			}) => {
				return !employer.finish_work;
			},
		);

		if (arrFinishedWorks.length === 1) {
			await dispatch(selectEmployer(arrFinishedWorks[0]));
		}
		if (employesRes.length === 1) {
			await dispatch(selectEmployer(employesRes[0]));
		}
	}
};

const storeData = async (data: LoginCredentials) => {
	const { token, refresh_token, roles, modules } = data || {};
	try {
		await setItemToStorage(AsyncLocalStorage.TOKEN, token);
		await setItemToStorage(AsyncLocalStorage.REFRESH_TOKEN, refresh_token);
		roles &&
			(await setItemToStorage(
				AsyncLocalStorage.ROLES,
				JSON.stringify(roles),
			));
		modules &&
			(await setItemToStorage(
				AsyncLocalStorage.MODULES,
				JSON.stringify(modules),
			));
	} catch (e) {
		console.log({ e });
		// saving error
	}
};

export const storeDataByKey = async (key: AsyncLocalStorage, value: string) => {
	try {
		await setItemToStorage(AsyncLocalStorage[key], value);
	} catch (e) {
		// saving error
	}
};

export const getStoreDataByKey = async (storageKey: AsyncLocalStorage) => {
	try {
		const value = await getItemFromStorage(storageKey);
		if (value !== null) {
			// value previously stored
			return value;
		}
	} catch (e) {
		// error reading value
	}
};

export const clearStorage = async () => {
	if (Platform.OS === 'android' || Platform.OS === 'ios') {
		AsyncStorage.multiRemove(await AsyncStorage.getAllKeys());
	} else {
		sessionStorage.clear();
	}
};
