import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import _, { debounce } from 'lodash';
import {
	FlatList,
	TouchableOpacity,
	View,
	StyleSheet,
	Dimensions,
	Platform,
} from 'react-native';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import SearchInputContainer from '../../../../../components/shared/SearchInput/SearchInput.container';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import colors from '../../../../../config/colors';
import ExtraDataRow from './ExtraDataRow';
import { i18nService } from '../../../../../services/i18n.service';
import { Switch } from 'react-native-paper';
import SolidButtonContainer from '../../../../../components/shared/Buttons/SolidButton.container';
import OutlineButtonContainer from '../../../../../components/shared/Buttons/OutlineButton.container';

const SelectEmployeeExtra = ({
	selectedData,
	onSubmit,
	close,
	role,
	disabled,
	isSelected,
	onFilter,
	shouldCheckNew,
	displayExcluded,
	initialData,
	repCustomerId = null,
	unitId,
	departments,
}: any) => {
	const [options, setOptions] = useState<any[]>([]);
	const [displayExclud, setDisplayExclud] =
		useState<boolean>(displayExcluded);
	const [search, setSearch] = useState();
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [values, setValues] = useState<any>(initialData);
	const [selectedOptions, setSelectedOptions] = useState<any[]>(
		selectedData || [],
	);
	const [showList, setShowList] = useState(false);
	const refDisplayExclud = useRef<any>();
	refDisplayExclud.current = displayExclud;
	const refValues = useRef<any>();
	refValues.current = values;

	const onShowHideListHandler = useCallback(() => {
		setShowList(!showList);
	}, [showList]);

	const [prevOptions, setPrevOptions] = useState<any[]>([]);
	const allOption = useMemo(
		() => ({
			id: 'all',
			name: i18nService.translate('general.all'),
		}),
		[],
	);

	useEffect(() => {
		debouncedLoadMore(undefined, null, true);
	}, [displayExclud]);

	const onChangeUsers = ({ value, unselectedValues }: any) => {
		const hasAll = value.find((v: any) => v.id === 'all');
		const prevValue = { ...values };
		const hasAllPrevVal =
			values?.excluded_user && !values?.excluded_user.length;
		const excludeNotSelected =
			!hasAll &&
			((prevValue && !prevValue?.exclude_selected) || hasAllPrevVal);
		const tempUnselectedValues =
			unselectedValues &&
			unselectedValues
				.filter((v: any) => v.id !== 'all')
				.map((v: any) => v.id);

		setValues((prev: any) => ({
			exclude_selected: !excludeNotSelected,
			excluded_user: hasAll
				? []
				: excludeNotSelected
				? tempUnselectedValues || prevValue?.excluded_user
				: !value?.length
				? null
				: [...value.map((v: any) => v.id || v)],
		}));
	};

	const selectionsChangeHandler = (
		id: number | string,
		value?: boolean,
		item?: any,
	) => {
		const selections =
			id === 'all' && selectedOptions[0]?.id !== 'all'
				? [item, ...selectedOptions]
				: id !== 'all' && value
				? [...selectedOptions, item]
				: selectedOptions.filter((selected: any) => selected.id !== id);

		let tempSelectedOptions: any = [];

		const isSelectionsContainAllOpt =
			selectedOptions?.findIndex((item: any) => item.id === 'all') >= 0;

		//select all on All option click
		if (
			!isSelectionsContainAllOpt &&
			selections.some((v: any) => v?.id === 'all')
		) {
			const allOptionSelected = options || [];
			tempSelectedOptions = [allOption, ...allOptionSelected];
			onShowHideListHandler();
			//unselect all on All option click
		} else if (
			prevOptions.length &&
			prevOptions.findIndex(
				(prevSelectedOpt: any) => prevSelectedOpt.id === 'all',
			) >= 0 &&
			selections.findIndex((currSelect: any) => currSelect.id === 'all') <
				0
		) {
			tempSelectedOptions = [];
		} else {
			tempSelectedOptions = selections.filter(
				(sel: any) => sel.id !== 'all',
			);
		}

		const unselectedValues =
			shouldCheckNew &&
			options &&
			_.differenceBy(options, tempSelectedOptions);

		setPrevOptions(tempSelectedOptions);
		setSelectedOptions(tempSelectedOptions);
		onChangeUsers({
			value: tempSelectedOptions,
			unselectedValues,
		});
	};

	const loadOptions = useCallback(
		async (
			localSearch?: string,
			loadedOptions?: null,
			resetPage?: boolean,
		) => {
			const res = await onFilter(
				localSearch || localSearch === '' ? localSearch : search,
				loadedOptions,
				{
					page: resetPage ? 0 : page,
					dep: departments,
				},
				true,
				refDisplayExclud.current,
				refValues.current?.excluded_user,
				refValues.current?.exclude_selected,
				repCustomerId,
				unitId,
			);

			if (selectedOptions.length) {
				const tempSelected = selectedOptions.map(opt =>
					opt?.id
						? opt
						: res.options.find((o: any) => o.id === opt) || opt,
				);
				if (
					JSON.stringify(selectedOptions) !==
					JSON.stringify(tempSelected)
				) {
					setSelectedOptions(tempSelected);
				}
			}

			setHasMore(res.hasMore);
			if (shouldCheckNew) {
				setPrevOptions([...selectedOptions, ...res.options]);
				setSelectedOptions([...selectedOptions, ...res.options]);
			}

			if (resetPage || !page) {
				setPage(1);
				setOptions([...res.options]);
			} else {
				setPage(page + 1);
				setOptions([...options, ...res.options]);
			}
		},

		[
			search,
			page,
			selectedOptions,
			options,
			repCustomerId,
			unitId,
			departments,
		],
	);

	const debouncedLoadMore = useCallback(
		debounce(
			(
				localSearch?: string,
				loadedOptions?: null,
				resetPage?: boolean,
			) => {
				loadOptions(localSearch, loadedOptions, resetPage);
			},
			500,
		),
		[
			search,
			page,
			selectedOptions,
			options,
			repCustomerId,
			unitId,
			departments,
		],
	);

	const _onSubmit = () => {
		onSubmit(values);
		close();
	};

	return (
		<View style={styles.wrapper}>
			<View style={styles.header}>
				<Typography size={28} weight='normal' style={{ flex: 1 }}>
					{role.name}
				</Typography>
				<TouchableOpacity onPress={() => close()}>
					<ImageIcon name='leftArrow' />
				</TouchableOpacity>
			</View>
			<View style={{ flexDirection: 'row-reverse', marginTop: 21 }}>
				<I18n size={18} weight='normal'>
					editUserModal.permissions.roles.employee
				</I18n>
				<View style={{ flex: 1 }} />
				<TouchableOpacity
					onPress={() => {
						setDisplayExclud(!displayExclud);
					}}
					style={{
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'row-reverse',
					}}
				>
					<Switch
						onValueChange={() => {
							setDisplayExclud(!displayExclud);
						}}
						color={colors.lightPrimary}
						value={!!displayExclud}
					/>
					<I18n size={14} weight='400' style={{ marginRight: 10 }}>
						editUserModal.displayExcluded
					</I18n>
				</TouchableOpacity>
			</View>

			<View style={{ flexDirection: 'row-reverse', marginTop: 14 }}>
				<SearchInputContainer
					onChange={s => {
						debouncedLoadMore(s.value, null, true);
						setSearch(s.value);
					}}
					config={{ type: '', name: '', defaultValue: undefined }}
					styleConf={{
						container: {
							flex: 1,
							borderRadius: 9,
							backgroundColor: colors.brightBlue,
						},
						input: {
							borderRadius: 9,
							backgroundColor: colors.brightBlue,
						},
					}}
				/>
			</View>
			<FlatList
				style={{ marginTop: 20, flex: 1 }}
				onEndReached={() =>
					hasMore &&
					options?.length &&
					page &&
					debouncedLoadMore?.(undefined, null)
				}
				data={options}
				contentContainerStyle={{ paddingBottom: 50 }}
				renderItem={({ item, ...rest }) => (
					<ExtraDataRow
						isChecked={isSelected?.(item, selectedOptions)}
						disabled={disabled}
						item={item}
						roleId={role.id}
						onCheck={selectionsChangeHandler}
						isCustomer={!!repCustomerId}
						{...rest}
					/>
				)}
				keyExtractor={(item, index) =>
					`${item.id}-${index}-${repCustomerId}`
				}
			/>
			<View
				style={{
					flexDirection: 'row-reverse',
					height: 120,
					marginTop: 10,
				}}
			>
				<SolidButtonContainer
					overrideStyle={{ minWidth: 90 }}
					onPress={_onSubmit}
					disabled={disabled}
				>
					general.update
				</SolidButtonContainer>
				<OutlineButtonContainer
					size='medium'
					onPress={close}
					overrideStyle={{ minWidth: 90, marginRight: 15 }}
				>
					general.cancel
				</OutlineButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		paddingLeft: 3,
	},
	wrapper: {
		width: Dimensions.get('screen').width,
		height: Dimensions.get('window').height,
		backgroundColor: colors.white,
		opacity: 1,
		paddingVertical: 25,
		paddingHorizontal: 16,
		position: Platform.OS !== 'web' ? 'absolute' : undefined,
		top: 0,
		left: 0,
	},
});

export default SelectEmployeeExtra;
