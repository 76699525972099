import moment from 'moment';
import * as reportsFiltersActions from '../constants/reportsFilters';
import { ReportsFiltersReducer, StoreAction } from '../../interfaces/redux.interface';

const initialState = (): ReportsFiltersReducer => ({
    employeesFilterList: {
        total: 0,
        hasNext: false,
        data: []
    },
    departmentsFilterList: [],
    rolesFilterList: [],
    requestsFilters: {
        requestsFilters: {},
        requestsSearch: ''
    },
    businessUnitsList: []
});

const reportsFiltersReducer = (
    state = initialState() as ReportsFiltersReducer,
    action: StoreAction<any>,
): ReportsFiltersReducer => {
    switch (action.type) {
        case reportsFiltersActions.FETCH_DEPARTMENTS_LIST_OPTIONS_SUCCESS:
            return {
                ...state,
                departmentsFilterList: action.payload,
            };
        case reportsFiltersActions.FETCH_EMPLOYEES_LIST_OPTIONS_SUCCESS:
            return {
                ...state,
                employeesFilterList: action.payload,
            };
        case reportsFiltersActions.FETCH_BUSINESS_UNITS_LIST_SUCCESS:
            return {
                ...state,
                businessUnitsList: action.payload,
            };
        case reportsFiltersActions.FETCH_ROLES_LIST_OPTIONS_SUCCESS:
            return {
                ...state,
                rolesFilterList: [{ id: -1, name: "roles.noRole" }, ...action.payload],
            };
        case reportsFiltersActions.UPDATE_REQUESTS_FILTERS:
            return {
                ...state,
                requestsFilters: action.payload,
            };
        default:
            return state;
    }
};

export default reportsFiltersReducer;
