import { Table, TableBody, makeStyles } from '@material-ui/core';
import { ScrollSyncPane } from 'react-scroll-sync';
import React, { useMemo, useEffect, useRef } from 'react';
import { useTable, useExpanded } from 'react-table';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { createGlobalStyle } from 'styled-components';
import classnames from 'classnames';
import Header from './Header/Header.component';
import Row from './Row/Row.component';
import Footer from './Footer/Footer.component';
import colors from '../../../config/colors';

interface TableProps {
	headers: TableHeader[];
	tableBody: any[];
	currentTableState?: any;
	next: () => void; // This callback is required to load the next pages with infinite scroll or pagination.
	allDataFetched?: boolean;
	onRowClick?: (data: any) => void;
	onSubRowClick: Function;
	onSort: (field: string, direction: string) => void;
	noInfinityScroll?: boolean;
	keyName: string;
	hasFooter?: boolean;
	outerClasses: OuterClasses;
	activeSort: { name: string; direction: string };
	onLeftArrowClick?: Function;
	onDownArrowClick?: Function;
	height?: string | number;
	selectedRowId?: string | number;
	setCurrentTableState?: any; //If you want to get access to table state from your page, you can use this prop
	showExpandHeader?: boolean;
	defaultScrollRight?: boolean;
	scrollToTop?: boolean;
	setScrollToTop?: Function;
	updateMyData?: Function;
	scrollSyncMode?: boolean;
	specialRowColor?: string;
	windowScale: number;
	// skipPageReset?: boolean;
	EditableCell?: Function;
	defaultExpandedRows?: {
		//if you need some sub rows to be expanded by default,
		[key: string]: boolean; //use this property, keys - row id (index in the array),
	}; // value - if true - the row will be expanded by default
	//If you need to expand sub row, key is needed to be in next format:
	// [i.j] where i is parent row index, j is sub row index, for example: '0.1'
}

interface UseTableProps {
	getTableProps: Function;
	getTableBodyProps: Function;
	headerGroups: any;
	footerGroups: any;
	rows: any;
	prepareRow: Function;
	visibleColumns: Function;
	toggleAllRowsExpanded: any;
	isAllRowsExpanded: boolean;
	state: Function;
	autoResetPage?: boolean;
}

export interface TableHeader {
	Header: string;
	accessor: string;
	isSortable: boolean;
}

type OuterClasses = {
	tableRoot?: string;
	rowRoot?: string;
	subRowRoot?: string;
	cellRoot?: string;
	subCellRoot?: string;
	footerRow?: string;
	footerCellRoot?: string;
	footerRowRoot?: string;
	specialRow?: string;
};

const GlobalStyle = createGlobalStyle`
  .infinite-scroll-component__outerdiv {
	height: 100%;
	background-color: #fff;
  }
`;

const useStyles = makeStyles(theme => {
	return {
		iconsBox: {
			display: 'flex',
			flexDirection: 'column',
			marginRight: '10px',
		},
		thBox: {
			display: 'flex',
			alignItems: 'center',
		},
		arrowIcon: {
			fontSize: '1.5rem',
			cursor: 'pointer',
			color: colors.filterIcon,
		},
		down: {
			marginTop: -9,
		},
		arrowClicked: {
			color: colors.primary,
		},
		tableRoot: {
			backgroundColor: '#fff',
		},
	};
});

const CustomTable = ({
	headers,
	tableBody,
	next,
	keyName,
	allDataFetched,
	onSort,
	onRowClick,
	onSubRowClick,
	outerClasses,
	noInfinityScroll,
	hasFooter,
	defaultExpandedRows,
	onLeftArrowClick,
	onDownArrowClick,
	setCurrentTableState,
	currentTableState,
	activeSort,
	height,
	windowScale,
	selectedRowId,
	showExpandHeader,
	defaultScrollRight,
	scrollToTop,
	setScrollToTop,
	updateMyData,
	// skipPageReset,
	EditableCell,
	scrollSyncMode,
	specialRowColor,
}: TableProps) => {
	const classes = useStyles();
	const tableRef = useRef<any>();

	const Cell = ({ cell: { value } }: { cell: { value: any } }) => {
		if (typeof value === 'boolean') {
			return value ? <CheckRoundedIcon /> : null;
		} else {
			return value ?? '';
		}
	};

	const data = useMemo(() => tableBody, [tableBody]);
	const columns = useMemo(
		() =>
			headers.map((el: any) => {
				if (el.isSortable) {
					return {
						...el,
						Header: ({ column }: { column: any }) => {
							return (
								<div className={classes.thBox}>
									{el.Header}
									<div className={classes.iconsBox}>
										<ArrowDropUp
											classes={{
												root: classnames(
													classes.arrowIcon,
													activeSort.name ===
														column.id &&
														activeSort.direction ===
															'ASC'
														? classes.arrowClicked
														: null,
												),
											}}
											onClick={e => {
												e.preventDefault();
												onSort(el.accessor, 'ASC');
											}}
										/>
										<ArrowDropDown
											classes={{
												root: classnames(
													classes.down,
													classes.arrowIcon,
													activeSort.name ===
														column.id &&
														activeSort.direction ===
															'DESC'
														? classes.arrowClicked
														: null,
												),
											}}
											onClick={e => {
												e.preventDefault();
												onSort(el.accessor, 'DESC');
											}}
										/>
									</div>
								</div>
							);
						},
						accessor: el.accessor,
						Cell: el.isEditable
							? EditableCell
							: el.CustomCell || Cell,
						Footer: el.Footer ?? '',
						SubCell: el.isEditable
							? EditableCell
							: el.SubCell || Cell,
					};
				}

				return {
					...el,
					Header: el.Header,
					accessor: el.accessor,
					Cell: el.isEditable ? EditableCell : el.CustomCell || Cell,
					Footer: el.Footer ?? '',
					SubCell: el.isEditable ? EditableCell : el.SubCell || Cell,
				};
			}),
		[headers, activeSort],
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
		visibleColumns,
		toggleAllRowsExpanded,
		isAllRowsExpanded,
		state,
	} = useTable<UseTableProps>(
		{
			// autoResetPage: !skipPageReset,
			columns,
			data,
			expandSubRows: false,
			updateMyData,
			initialState: currentTableState
				? currentTableState
				: {
						expanded: defaultExpandedRows
							? defaultExpandedRows
							: false,
				  },
		},
		useExpanded,
	);

	useEffect(() => {
		if (setCurrentTableState) {
			setCurrentTableState(state);
		}
	}, [state]);

	useEffect(() => {
		if (scrollToTop) {
			tableRef.current?.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
			setScrollToTop && setScrollToTop(false);
		}
	}, [scrollToTop]);

	const getNextPage = (event: any) => {
		const { scrollHeight, offsetHeight, scrollTop } = event.target;
		const distanceFromBottom = scrollHeight - (scrollTop + offsetHeight);

		if (distanceFromBottom <= (windowScale + 1 || 0) && !allDataFetched) {
			next && next();
		}
	};

	const scrollToRow = (e: any) => {
		let position = tableRef.current?.scrollTop - e.offsetTop - 60;
		if (Math.abs(position) > tableRef.current?.clientHeight - 20) {
			tableRef.current?.scrollTo({
				top: tableRef.current.scrollTop + 52,
				behavior: 'smooth',
			});
		} else if (tableRef.current?.scrollTop && position < 0) {
			tableRef.current.scrollTo({
				top: tableRef.current.scrollTop - 60,
				behavior: 'smooth',
			});
		}
	};

	const scrollToRight = (e: any) => {
		if (defaultScrollRight) {
			tableRef.current?.scrollTo({
				left: 0,
				behavior: 'smooth',
			});
		}
	};

	return (
		<>
			<GlobalStyle />
			{scrollSyncMode ? (
				<ScrollSyncPane>
					<div
						onScroll={e => !noInfinityScroll && getNextPage(e)}
						ref={tableRef}
						style={{
							height: height || '100%',
							maxHeight: '100%',
							width: '100%',
							overflow: 'auto',
						}}
					>
						<Table
							{...getTableProps()}
							stickyHeader
							aria-label='sticky table'
							classes={{
								root: classnames(
									classes.tableRoot,
									outerClasses?.tableRoot,
								),
							}}
						>
							<Header
								headerGroups={headerGroups}
								toggleAllRowsExpanded={toggleAllRowsExpanded}
								isAllRowsExpanded={isAllRowsExpanded}
								showExpandHeader={showExpandHeader}
							/>
							<TableBody {...getTableBodyProps()}>
								{rows.map((row: any, i: number) => {
									prepareRow(row);
									return (
										<Row
											key={row[keyName] || i}
											row={row}
											outerClasses={{
												rowRoot: classnames(),
												specialRow:
													outerClasses?.specialRow,
											}}
											scrollToRow={scrollToRow}
											index={i}
											specialRowColor={specialRowColor}
											onRowClick={onRowClick}
											keyName={keyName}
											onSubRowClick={onSubRowClick}
											prepareRow={prepareRow}
											onLeftArrowClick={onLeftArrowClick}
											onDownArrowClick={onDownArrowClick}
											selectedRowId={selectedRowId}
											scrollToRight={scrollToRight}
										/>
									);
								})}
							</TableBody>
							{hasFooter && (
								<Footer
									footerGroups={footerGroups}
									outerClasses={outerClasses}
								/>
							)}
						</Table>
					</div>
				</ScrollSyncPane>
			) : (
				<div
					onScroll={e => !noInfinityScroll && getNextPage(e)}
					ref={tableRef}
					style={{
						height: height || '100%',
						maxHeight: '100%',
						width: '100%',
						overflow: 'auto',
					}}
				>
					<Table
						{...getTableProps()}
						stickyHeader
						aria-label='sticky table'
						classes={{
							root: classnames(
								classes.tableRoot,
								outerClasses?.tableRoot,
							),
						}}
					>
						<Header
							headerGroups={headerGroups}
							toggleAllRowsExpanded={toggleAllRowsExpanded}
							isAllRowsExpanded={isAllRowsExpanded}
							showExpandHeader={showExpandHeader}
						/>
						<TableBody {...getTableBodyProps()}>
							{rows.map((row: any, i: number) => {
								prepareRow(row);
								return (
									<Row
										key={row[keyName] || i}
										row={row}
										outerClasses={{
											rowRoot: classnames(),
											specialRow:
												outerClasses?.specialRow,
										}}
										scrollToRow={scrollToRow}
										index={i}
										keyName={keyName}
										onRowClick={onRowClick}
										onSubRowClick={onSubRowClick}
										prepareRow={prepareRow}
										specialRowColor={specialRowColor}
										onLeftArrowClick={onLeftArrowClick}
										onDownArrowClick={onDownArrowClick}
										selectedRowId={selectedRowId}
										scrollToRight={scrollToRight}
									/>
								);
							})}
						</TableBody>
						{hasFooter && (
							<Footer
								footerGroups={footerGroups}
								outerClasses={outerClasses}
							/>
						)}
					</Table>
				</div>
			)}
		</>
	);
};
export default CustomTable;
