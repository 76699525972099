import React, { useEffect, useMemo, useRef, useState } from "react";
import { Platform } from "react-native";
import useDevicePlatform, {
  DeviceType,
} from "../../hooks/useDevicePlatform.hook";
import moment from "moment";
import CalendarAttendanceReportsWeb from "./CalendarAttendanceReportsWeb.component";
import CalendarAttendanceReportsMobileContainer from "./CalendarAttendanceReportsMobileWeb.container";
import { useDispatch } from "react-redux";
import { setChosenDate } from "../../store/actions/clockReport.actions";

const CalendarAttendanceReports = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch();

  const devicePlatformRef = useRef<DeviceType>(useDevicePlatform());
  const isMobile = devicePlatformRef.current !== DeviceType.WEB;

  useEffect(() => {
    onMount();
    return () => {
      dispatch(setChosenDate(moment().format('YYYY-MM-DD')))
    }
  }, []);

  const onMount = async () => {
    if (Platform.OS !== 'web') {
      const crashlytics = (
        await import('@react-native-firebase/crashlytics')
      ).default;
      crashlytics().log('CalendarAttendanceReports');
    }
  }

  return (
    <>
      {isMobile ? (
        <CalendarAttendanceReportsMobileContainer navigation={navigation} />
      ) : (
        <CalendarAttendanceReportsWeb navigation={navigation} />
      )}
    </>
  );
};

export default CalendarAttendanceReports;
