import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import I18n from '../../components/shared/i18n/I18n.component';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import { Props } from './BusinessSettings.component';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import colors from '../../config/colors';
import { Divider } from 'react-native-paper';
import { tabsOptions } from './BusinessSettings.utils';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../store/selectors/login.selectors';
import { useFormContext } from 'react-hook-form';
import {
	getDocumentTitle,
	hasPermissionOnBusinessUnit,
} from '../../utils/globals.utils';
import { useNavigationState } from '@react-navigation/native';
import Select from '../../components/shared/FieldBuilder/Select/ReactSelect.component';
import { Privileges } from '../../constants/roleTypes.constant';

const Tab = createBottomTabNavigator();

const BusinessSettingsMobile = ({
	data,
	navigation,
	onSend,
	onCancel,
	eventsOptions,
	setEventsOptions,
	selectedEvents,
	setSelectedEvents,
	showEvents,
	setShowEvents,
	showDatePicker,
	setShowDatePicker,
	editLockDay,
	setEditLockDay,
	maxShiftHour,
	setMaxShiftHour,
	presenceModuleExists,
	presenceModuleStatus,
	businessUnitsList,
	selectedBusinessUnits,
	onChangeBusinessUnits,
	payCheckModuleExists,
	formMethods,
	form101ModuleStatus,
}: Props) => {
	const { watch } = useFormContext();

	const routeState = useNavigationState(
		navigationState => navigationState.routes[navigationState.index].state,
	);

	const [selectedTab, setSelectedTab] = useState(0);
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);
	const [disableSubmit, setDisableSubmit] = useState(true);

	const tabs = useMemo(
		() =>
			tabsOptions(
				navigation,
				employerRolesPrivileges,
				presenceModuleExists,
			),
		[navigation, employerRolesPrivileges, presenceModuleExists],
	);

	useEffect(() => {
		const subscription = watch(
			(value, { name, type }) =>
				type === 'change' && setDisableSubmit(false),
		);
		return () => subscription.unsubscribe();
	}, []);

	useEffect(() => {
		const currentRoute = routeState?.routes?.[routeState?.index || 0];
		if (currentRoute) {
			const index = tabs.findIndex(
				(tab: any) => tab.name === currentRoute?.name,
			);
			index > -1 && index !== selectedTab && setSelectedTab(index);
		}
	}, [selectedBusinessUnits, tabs, routeState]);

	return (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<View style={{ backgroundColor: colors.white }}>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
			</View>
			<View style={{ paddingRight: 16, backgroundColor: colors.white }}>
				<I18n weight='normal' size={28}>
					businessSettings.title
				</I18n>
			</View>
			<View
				style={{
					zIndex: 1,
					flexDirection: 'row-reverse',
					backgroundColor: colors.white,
					padding: 20,
					alignItems: 'center',
				}}
			>
				{businessUnitsList.length > 1 ? (
					<>
						<I18n style={{ width: 120 }} size={14} weight='normal'>
							general.businessUnits
						</I18n>
						<Select
							width={Dimensions.get('screen').width - 160}
							options={businessUnitsList}
							onChange={(value: any) =>
								onChangeBusinessUnits({ value })
							}
							value={selectedBusinessUnits?.[0]}
						/>
					</>
				) : null}
			</View>
			<View style={styles.tabs}>
				{tabsOptions(
					navigation,
					employerRolesPrivileges,
					presenceModuleExists,
				).map((tab, index) => (
					<TouchableOpacity
						onPress={() => {
							setSelectedTab(index);
							tab.onPress();
						}}
						style={[
							styles.button,
							{
								borderBottomColor:
									index === selectedTab
										? colors.primary
										: colors.white,
							},
						]}
					>
						<I18n size={14} weight='normal'>
							{tab.label}
						</I18n>
					</TouchableOpacity>
				))}
			</View>
			<Divider
				style={{
					backgroundColor: colors.primary,
					opacity: 0.5,
				}}
			/>
			<Tab.Navigator
				tabBar={() => null}
				sceneContainerStyle={{ backgroundColor: colors.white }}
				initialRouteName='presence'
				screenOptions={{ unmountOnBlur: true }}
			>
				{tabsOptions(
					navigation,
					employerRolesPrivileges,
					presenceModuleExists,
				).map((item, index) => {
					const Component = item.tabComponent;
					return (
						<Tab.Screen
							options={{
								title: getDocumentTitle(item.name),
								unmountOnBlur: true,
							}}
							name={item.name}
							children={() => (
								<Component
									data={data}
									onCancel={onCancel}
									onUpdate={onSend}
									disableSubmit={disableSubmit}
									canNotEdit={
										!hasPermissionOnBusinessUnit(
											selectedBusinessUnits?.[0],
											Privileges.ROLE_CUSTOMER_SETTING_WRITE,
										)
									}
									setDisableSubmit={setDisableSubmit}
									eventsOptions={eventsOptions}
									setEventsOptions={setEventsOptions}
									selectedEvents={selectedEvents}
									setSelectedEvents={setSelectedEvents}
									showEvents={showEvents}
									setShowEvents={setShowEvents}
									showDatePicker={showDatePicker}
									setShowDatePicker={setShowDatePicker}
									editLockDay={editLockDay}
									setEditLockDay={setEditLockDay}
									maxShiftHour={maxShiftHour}
									setMaxShiftHour={setMaxShiftHour}
									selectedBusinessUnit={
										selectedBusinessUnits?.[0]
									}
									presenceModuleStatus={presenceModuleStatus}
									payCheckModuleExists={payCheckModuleExists}
									formMethods={formMethods}
									form101ModuleStatus={form101ModuleStatus}
								/>
							)}
						/>
					);
				})}
			</Tab.Navigator>
		</View>
	);
};

const styles = StyleSheet.create({
	tabs: {
		flexDirection: 'row-reverse',
		paddingHorizontal: 20,
		backgroundColor: colors.white,
	},
	button: {
		paddingHorizontal: 10,
		paddingVertical: 5,
		backgroundColor: colors.white,
		borderBottomWidth: 6,
		alignItems: 'center',
		marginTop: 40,
	},
});

export default BusinessSettingsMobile;
