import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Platform, TextInput } from 'react-native';
import MaskInput from 'react-native-mask-input';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../../services/i18n.service';

const FormTextInput = ({
	value,
	onChange,
	onBlur,
	error,
	maxLength,
	toFixed = 2,
	forceMin = false,
	editable,
	setValue,
	modifyValue,
	name,
	inputMaskType,
	inputMaskProps,
	convertToLocalNumber,
	toFocus,
	placeholder,
	hasEditableColorText,
	backgroundColor,
	borderColor,
	keyboardType,
	multiline,
	height,
	padding,
	paddingVertical,
	maxVal,
	minVal,
	style,
	...restProps
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const [hideShowPlaceholder, setHideShowPlaceholder] = useState(false);
	const styleObj: any = useMemo(
		() => ({
			height: height ? height : 40,
			fontSize: 14,
			width: '100%',
			backgroundColor: backgroundColor
				? backgroundColor
				: colors[error ? 'brightRed' : 'lightBlue'],
			color:
				hasEditableColorText && !editable
					? colors.grey
					: colors.darkGrey,
			borderBottomWidth: isMobile || borderColor ? 1 : 0,
			borderWidth: borderColor ? 1 : 0,
			borderColor: borderColor
				? borderColor
				: colors[error ? 'red' : 'primary'],
			borderRadius: 5,
			padding: padding !== undefined ? padding : 10,
			paddingVertical: paddingVertical,
			textAlign: 'right',
			...Platform.select({
				web: {
					outlineWidth: 0,
				},
			}),
			...(style || {}),
		}),
		[error, editable, style],
	);

	const _onChange = (val: any) => {
		const limit = forceMin
			? toFixed > 0
				? `(^-?)\\d*(\\.\\d{1,${toFixed}})*$`
				: `(^-?)\\d*(\\})*$`
			: toFixed > 0
			? `^\\d+(\\.\\d{1,${toFixed}})*$`
			: `^\\d+(\\})*$`;

		const numberRegex = new RegExp(limit, 'g');
		if (
			keyboardType === 'numeric' &&
			val &&
			val !== '' &&
			!val?.endsWith('.') &&
			((val === '-' && !forceMin) ||
				val === '+' ||
				!val.match(numberRegex))
		) {
			if (val.length === 1) {
				onChange('');
			}
			return;
		}

		if (keyboardType === 'numeric' && val.includes('..')) {
			onChange(val.replace('..', '.'));
			return;
		}
		if (keyboardType === 'numeric' && val.includes('--')) {
			onChange(val.replace('--', '-'));
			return;
		}
		if (keyboardType === 'numeric' && val.includes('.') && toFixed <= 0) {
			onChange(val.replace('.', ''));
			return;
		}
		if (!!maxVal && val > maxVal) {
			onChange(maxVal);
			return;
		}
		if (!!minVal && val < minVal) {
			onChange('');
			return;
		}

		onChange(val === '' ? undefined : val);
		if (modifyValue) {
			const newValue = modifyValue(val);
			setValue(name, newValue, { shouldValidate: true });
		}
	};

	return !inputMaskType ? (
		<TextInput
			style={styleObj}
			onChangeText={_onChange}
			onBlur={() => {
				convertToLocalNumber &&
					value &&
					value !== '' &&
					onChange(parseFloat(value).toLocaleString());
				editable && onBlur();
				setHideShowPlaceholder(false);
			}}
			onFocus={() => {
				editable &&
					value &&
					onChange(value.toString().split(',').join(''));
				!value &&
					placeholder &&
					!toFocus &&
					setHideShowPlaceholder(true);
			}}
			value={value}
			maxLength={maxLength}
			editable={editable}
			placeholder={
				placeholder && !hideShowPlaceholder
					? i18nService.translate(placeholder)
					: undefined
			}
			keyboardType={keyboardType}
			multiline={multiline}
		/>
	) : (
		<MaskInput
			type={inputMaskType}
			style={styleObj}
			onChangeText={_onChange}
			onBlur={onBlur}
			value={value}
			maxLength={maxLength}
			editable={editable}
			keyboardType={keyboardType}
			{...inputMaskProps}
		/>
	);
};

export default FormTextInput;
