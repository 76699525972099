export const instructionsEmployee = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap" rel="stylesheet">
    <title>מסמך הנחיות לעובד</title>
    <style>
        ::-webkit-scrollbar {
            width: 6px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
            /* background: #f1f1f1; */
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #d9e0ef;
            border-radius: 10px;
        }
        body {
            background-color: #E2EDF4;
        }
        .container {
            max-width: 900px;
            padding: 30px;
            font-family: 'Rubik';
            color: #525962;
            margin:30px auto;
            direction: rtl;
            letter-spacing: 0.25px;
            background-color: #fff;
        }
        .header {
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid rgba(82, 89, 98, 0.20);
        }
        .title-1 {
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 0.25px;
            margin: 0px;
        }

        .title-2 {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.25px;
            margin: 20px 0px 20px 20px;
        }
        .title-3 {
            font-size: 18px;
            font-weight: 400;
            margin: 20px 0 20px 0;
        }
        .body-1 {
            font-size: 14px;
            font-weight:400px;
            line-height: 24px;
        }
        .body-1.bold {
            font-weight: 700;
        }
        .content-wrapper {
            box-sizing:border-box;
            padding: 10px 113px; 
            width: 794px;
            margin:0;
            padding:0;
        }
        a {
            color: #3B70A4;
        }
    </style>
</head>
<body>
    <div class="container" style="line-height: normal">
        <div class="header">
            <h2 class="title-1" >"חרבות ברזל" – חוק שירות עבודה בשעת חירום – שאלות & תשובות</h2>
        </div>

        <div>
            <div>
                <h3 class="title-2">מה המשמעות של חוק שירות עבודה בשעת חירום?</h3>
                <p class="body-1">
                    חוק שירות העבודה בשעת חירום הוא חוק המאפשר לגייס בצו (קריאה לשירות עבודה) עובד תושב קבוע
                    במפעל חיוני או במפעל למתן שירותים קיומיים לצורך שמירה על הרציפות התפקודית של המשק החיוני.
                </p>
            </div>
            <div>
                <h3 class="title-2">את מי רשאים לגייס בצו לשירות עבודה (לרתק ריתוק משקי)?</h3>
                <p class="body-1" style="margin-bottom: 0;">ניתן לגייס בצו לשירות עבודה כל תושב קבוע (למעט חייל, שוטר, אישה הרה או לפני תום שנה אחת אחרי לידתה), אם הוא באחד מן הגילים האלה : גבר מגיל 16 עד גיל 67 ועד בכלל, אישה מגיל 16 עד 62 ועד בכלל. 
                </p>
                <p class="body-1" style="margin: 0;">לשירות עבודה במפעל חיוני אפשר לגייס את עובדי המפעל עצמו (מגויסי פנים) ואנשים שאינם מועסקים בשגרה במפעל (מגויסי חוץ).</p>
            </div>
            <div>
                <h3 class="title-2">איך יודעים שמקום עבודתי מוגדר כמפעל למתן שירותים קיומיים?</h3>
                <p class="body-1" style="margin-bottom: 0;"">מקום עבודה שאושר כמפעל למתן שירותים קיומיים מחזיק באישור רלוונטי ממשרד העבודה ובאחריות המפעל לעדכן את כלל העובדים על כך שהמפעל מוגדר כמפעל למתן שירותים קיומיים. ניתן לבדוק האם מפעל אושר כמפעל למתן שירותים קיומיים במנוע החיפוש באתר אגף כח אדם לשעת חירום, בהזנת מספר החברה, או מספר המפעל או שם המפעל בקישור הזה במנוע החיפוש . יש לשים לב שמנוע החיפוש אינו כולל מפעלים של מערכת הביטחון ומפעלים שהינם עוסקים מורשים.
                </p>
                <p class="body-1" style="margin: 0;">כמו כן, ניתן לבדוק עם המעסיק האם המפעל אושר כמפעל והאם העובד נמצא ברשימת העובדים החיוניים במפעל למתן שירותים קיומיים.
                </p>
            </div>

            <div>
                <h3 class="title-2">מקום העבודה עובד כרגיל אבל אני מפחד ללכת לעבודה. האם אני זכאי לשכר?
                </h3>
               <p class="body-1">
                מעסיק אינו מחויב לשלם שכר לעובד שלא הגיע לעבודה ככל שההיעדרות לא מוסדרת בחוק או בהסכם. על כן, מעסיק יכול לדרוש מעובד להגיע למקום העבודה, למעט אם הוראות פיקוד העורף הנחו אחרת במפורש. עם זאת, עובד ומעסיק יכולים להסכים על היעדרות בתשלום, לדוגמה, מימוש ימי חופשה.
               </p>
            </div>

            <div>
                <h3 class="title-2" >מה המשמעות של הוצאת צו גיוס לשירות העבודה?</h3>
                <p class="body-1" style="margin-bottom: 0;">מי שנקרא לשירות עבודה חייב להתייצב בזמן, במקום ולפני הרשות שנקבעו לכך בצו ולבצע כל עבודה שתוטל עליו. 
                </p>
                <p class="body-1" style="margin: 0;">אי התייצבות לעבודה בשעת חירום, מהווה עבירה לפי סעיף 35 לחוק האמור. בנוסף, עובד כאמור אשר יעדר מעבודה לא יהא זכאי לשכר בתקופת היעדרותו.</p>
                <p class="body-1" style="margin: 0;">עובד שחל עליו צו כזה אינו זכאי לקחת חופשה בתקופה זו. בין העובד למעביד מתקיימים יחסי עובד–מעביד על כל המשתמע מכך, אך לא יחולו הוראותיהם של אחדים מדיני העבודה, ובפרט חוק שעות עבודה ומנוחה, חוק עבודת נשים, עבודת נוער, חוק שירות התעסוקה ועוד. עד להכרזה על סוף מצב החירום או על כך שהמפעל אינו חיוני יותר. 
                </p>
                <p class="body-1" style="margin: 0;">
                    אחת התוצאות הנובעות מכך: בשעת חירום מותר  (בהינתן אישור מיוחד לנושא) להעסיק עובדים במפעל חיוני גם במנוחה שבועית ובחג, ואין הגבלה על כמות השעות הנוספות.
                </p>
            </div>
            <div >
                <h3 class="title-2">איך יודעים אם הופעל צו במקום העבודה?</h3>
                <p class="body-1">הצו נמסר לנציג המפעל. באחריות נציג המפעל להעביר את הצו לידיעת העובדים במפעל, למסור לנציג הוועד את הצו בצירוף רשימת העובדים שנקראו לשירות עבודה, ובאין ועד עובדים תיראה הדבקת הצו על לוח המודעות שבמפעל והצגת רשימת העובדים, כמסירת הצו לידי הנוגעים בדבר.</p>
            </div>
            <div>
                <h3 class="title-2">מה גובר ? צו מילואים או צו גיוס לשירות עבודה (ריתוק משקי)?</h3>
                <p class="body-1">
                    עובדים שנקראו לשירות מילואים פטורים מלהופיע לעבודה בשעת חירום, כל זמן שהם בשירות פעיל.
                </p>
            </div>
            <div>
                <h3 class="title-2">עובדים שמקום עבודתם היה סגור על פי הוראת פיקוד העורף, אמורים לקבל פיצוי?</h3>
                <p class="body-1">
                    בעבר נקבע בהסכמים קיבוציים שהוסכמו בין העובדים, המעסיקים והממשלה כי המעסיק, שמקום עבודתו היה סגור על פי הוראות פיקוד העורף, ישלם לעובדיו את שכרם הרגיל ויוכל לקבל שיפוי בהתאם להסדרים שנקבעו בחוק מס רכוש וקרן פיצויים. יש להבהיר כי נכון למועד זה, לא קיים הסכם כאמור ביחס למבצע . עם זאת, עובד הנעדר מהעבודה בנסיבות האמורות יכול  להגיע להסכמות עם המעסיק בנוגע להיעדרות, לרבות עבודה מרחוק ככל שהנושא מוסכם עם המעסיק.
                </p>
            </div>
            <div>
                <h3 class="title-2">אני לא יכול ללכת לעבודה מכיוון שהמסגרות החינוכיות של ילדי סגורות. האם אקבל שכר או פיצויים?
                </h3>
                <p class="body-1" >
                    הזכות לשכר והפיצויים עקב אי-הגעה לעבודה נוכח אירועי חירום מוסדר בחוק מס רכוש וקרן פיצויים של משרד האוצר. הזכות לפיצוי נבחנת על ידי הממשלה, בדרך כלל, לקראת או בסיום אירוע החירום. יש להבהיר כי נכון למועד זה, לא קיים הסכם כאמור ביחס למבצע. עם זאת, עובד הנעדר מהעבודה בנסיבות האמורות יכול  להגיע להסכמות עם המעסיק בנוגע להיעדרות, לרבות עבודה מרחוק ככל שהנושא מוסכם עם המעסיק.
                </p>
            </div>
            <div>
                <h3 class="title-2">
                    התייצבתי לעבודה בכפוף לצו גיוס לשירות עבודה, אולם המעסיק לא משלם את כל זכויותיי. למי עליי לפנות?
                </h3>
                <p class="body-1">בנושא פגיעה בזכויות העובדים ניתן לפנות לאתר "כל זכות" באינטרנט.
                </p>
            </div>
            <div>
                <h3 class="title-2">לכמה זמן הצו תקף?
                </h3>
                <p class="body-1">
                    עם פקיעת מצב החירום (מצב מיוחד בעורף, מלחמה, אירוע חירום אזרחי) עפ"י החלטת ממשלת ישראל, יפקע תוקף צווי הקריאה לגיוס העובדים, מכוח חוק שרות עבודה בשע"ח תשכ"ז-1967 הודעה תימסר לנציג המפעל.
                </p>
            </div>
            <div>
                <h3 class="title-2" >במידה ואני מגויס חוץ, כיצד נקבע שכרי וממי אקבל את השכר עבור הימים בהם נקראתי בצו
                </h3>
                <p class="body-1" style="margin-bottom: 0;">
                    שכרו של מגויס חוץ משולם ע"י המפעל אליו הוא נקרא לשירות עבודה.
                </p>
                <p class="body-1" style="margin: 0;">
                    בהתאם לחוק, השכר בעת הגיוס יהיה השכר הנהוג אצל המעסיק הקולט בהתאם לתפקיד שהמגויס נקרא למלא, אלא אם כן נקבע אחרת בהסכם קיבוצי שנחתם לעניין זה. ככל שיש מחלוקת בנושא זה ניתן לפנות למפקח הכללי על כוח האדם בשעת חירום.
                </p>
                <p class="body-1" style="margin: 0;">
                    יודגש כי חל איסור לפטר מגויס מעבודתו כמגויס או להעסיק מגויס בניגוד לצו לקריאה לשירות עבודה אלא בהיתר בכתב ממפקח עבודה בשעת חירום. כמו כן, בהתאם לחוק הגנה על עובדים לשעת חירום, תשס"ו-2006 ולסעיף 25 לחוק שירות עבודה המעסיק המקורי של מי שנקרא לגיוס חוץ חייב לשמור על זכויותיו התלויות בוותק וכן להפריש עבורו לקרנות פנסיה. כמו כן יש איסור פיטורים בתקופה זו.
                </p>
            </div>
        </div>
    </div>
</body>
</html>`;
