import React, { useMemo } from 'react';
import {
	Image,
	ScrollView,
	View,
	StyleSheet,
	Dimensions,
	TouchableHighlight,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import { toNumber } from 'lodash';
import WorkLog from '../../../components/shared/ClockReport/MobileReportsList/WorkLog.component';
import { Record } from '../../../components/shared/ClockReport/Record/Record.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import getShadowStyle from '../../../config/shadow';
import Typography from '../../../components/shared/Typography/Typography.component';
import { i18nService } from '../../../services/i18n.service';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenChosenDateModal } from '../../../store/actions/clockReport.actions';
import moment from 'moment';

const imageEmptyState = require('../../../../assets/images/reportEmptyState/report-empty.png');

const convertToHoursFormat = time => {
	if (!time) return '00:00';
	const [hours, minutes, seconds] = time.split(':');
	return `${toNumber(hours).toLocaleString()}:${minutes}`;
};

const convertHoursFormat = (
	minutesValue: any,
	format?: string,
	inminutes?: boolean,
) => {
	if (minutesValue) {
		const value = inminutes ? minutesValue : Math.floor(minutesValue * 60);
		if (format === 'HH:mm') {
			const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
			const minutes =
				value % 60 !== 0
					? value % 60 < 10
						? `0${value % 60}`
						: `${value % 60}`
					: '00';
			return `${hours}:${minutes}`;
		}
		return `${(value / 60).toFixed(2)}`;
	}
	return '00:00';
};
const windowHeight = Dimensions.get('window').height;

interface Props {
	reportSelectedDate: any[];
	openDrawer: any;
	onDelete: Function;
	onEdit: Function;
	hideButton: boolean;
	addWorkLog: Function;
	standardHours: any;
	totalReported: any;
	isToday: boolean;
	selectedDay: any;
	navigation: any;
	canEdit:boolean;
}
const DayReportsMobile = ({
	onDelete,
	onEdit,
	hideButton,
	addWorkLog,
	standardHours,
	totalReported,
	isToday,
	selectedDay,
	reportSelectedDate,
	navigation,
	canEdit
}: Props) => {
	const dispatch = useDispatch();
	const isTotalReportedSmallerThanStandard = useMemo(()=>{
		let standard = 0;
		if(standardHours?.fixHours){
		  standard = moment(standardHours.fixHours, "HH:mm:ss").diff(moment("00:00","HH:mm"),"minutes")/60
		}
	
		return  totalReported < standard
		
	  },[totalReported , standardHours])
	  const standard = useMemo(()=>{
		return convertToHoursFormat(standardHours?.fixHours)
	  },[standardHours])
	return (
		<>
			<View style={styles.container} nativeID='#MobileWebContainer'>
				<View
					style={{
						width: '100%',
						flexDirection: 'row-reverse',
						justifyContent: 'flex-end',
						paddingTop: 20,
						paddingBottom: 10,
						paddingHorizontal: 15,
					}}
				>
					<TouchableHighlight
						style={{ flexDirection: 'row-reverse', width: 100 }}
						underlayColor='transparent'
						onPress={() => {
							dispatch(setOpenChosenDateModal(false));
							navigation.pop();
						}}
					>
						<View
							style={{
								flexDirection: 'row-reverse',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<I18n>reportView.tableReports</I18n>
							<ImageIcon
								name={'leftArrow'}
								width={10}
								height={10}
							></ImageIcon>
						</View>
					</TouchableHighlight>
				</View>
				<View style={{ width: '100%', paddingVertical: 20 }}>
					<Typography
						style={{ textAlign: 'center' }}
						size={18}
						color={'darkGrey'}
						weight='400'
					>
						{`${i18nService.translate(
							'reportView.reportFrom',
						)} ${selectedDay}`}
					</Typography>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'center',
						width: '100%',
						marginTop: 15,
						marginBottom: 40,
					}}
				>
					<Typography size={18} color={standard=== "0:00"?"darkGrey":isTotalReportedSmallerThanStandard?"red":"green"} weight='400'>
						{`${convertHoursFormat(totalReported, 'HH:mm', false)}`}
					</Typography>
					<Typography size={18} color={'darkGrey'} weight='normal'>
						{`/${standard}`}
					</Typography>
				</View>
				{reportSelectedDate?.length ? (
					<View style={{ flex: 5, width: '100%' }}>
						<I18n
							style={{
								width: '95%',
								textAlign: 'right',
								paddingHorizontal: 10,
							}}
							size={18}
							color='darkGrey'
							weight='normal'
						>
							{isToday
								? 'general.startDay'
								: 'general.worklogsForDay'}
						</I18n>
						<ScrollView
							nativeID='tableHolder'
							style={styles.tableHolder}
						>
							{reportSelectedDate?.map((record: Record) => (
								<WorkLog
									onEdit={onEdit}
									onDelete={onDelete}
									isToday={isToday}
									key={record.workLogId}
									record={record}
								/>
							))}
						</ScrollView>
					</View>
				) : (
					<View
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							flex: 2,
						}}
					>
						<Image
							source={imageEmptyState}
							fadeDuration={0}
							style={{
								height: 86,
								width: 178,
								marginTop: 50,
							}}
						/>
						<I18n size={18} color={'darkGrey'} weight='400'>
							{isToday
								? 'reportView.noWorklogsToday'
								: 'general.noLogsForThisDate'}
						</I18n>
					</View>
				)}
				<View style={styles.addButtonHolder}>
					{!hideButton && canEdit&&(
						<TouchableHighlight
							style={styles.fabButton}
							underlayColor='transparent'
							onPress={addWorkLog}
						>
							<ImageIcon
								name={'add'}
								width={40}
								height={40}
							></ImageIcon>
						</TouchableHighlight>
					)}
				</View>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		overflow: 'hidden',
		backgroundColor: colors.lightBlue,
	},
	line: {
		opacity: 0.2,
		width: '95%',
		height: 0,
		borderTopColor: '#525962',
		borderTopWidth: 2,
		marginVertical: 5,
	},
	recordLine: {
		opacity: 0.2,
		width: '100%',
		height: 0,
		borderTopColor: '#525962',
		borderTopWidth: 2,
		marginVertical: 5,
	},
	contentBlock: {
		flex: 1,
		marginTop: 28,
		marginBottom: 48,
		height: windowHeight - 167,
		backgroundColor: colors.white,
		alignItems: 'center',
	},
	detailsHeader: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 20,
		padding: 30,
	},
	addButtonHolder: {
		flexDirection: 'row',
		width: '100%',
		padding: 30,
	},
	tableHolder: {
		width: '100%',
		minHeight: 140,
		direction: 'ltr',
		flex: 1,
	},
	fabButton: {
		borderRadius: 30,
		width: 40,
		height: 40,
		marginTop: 10,
		marginLeft: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default DayReportsMobile;
