import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Platform } from 'react-native';
import { compact, debounce } from 'lodash';
import moment from 'moment';

import { getUserData } from '../../../../store/selectors/login.selectors';
import httpService from '../../../../services/http.service';
import useEffectNotInitial from '../../../../hooks/useEffectNotInitial';
import RepOrgReports from './RepPaycheckReports.component';
import { i18nService } from '../../../../services/i18n.service';
import {
	statusOptions,
	defaultFilters,
	handleFilter,
} from './RepPaycheckReports.utils';
import { FilterData } from '../../../../components/web/Filters/Filters.container';
import { getSelectedUnits } from '../../rep.utils';
import { useRoute } from '@react-navigation/native';
import { Privileges } from '../../../../constants/roleTypes.constant';

const RepPaycheckReportsContainer = ({ navigation }: { navigation: any }) => {
	const newDate = new Date();
	const [requestsData, setRequestsData] = useState([]);
	const [noOperators, setNoOperators] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [operatorsList, setOperatorsList] = useState<any[]>([]);
	const [businessUnitsList, setBusinessUnitsList] = useState([]);
	const [disabledLoad, setDisableLoad] = useState(false);
	const [repCustData, setRepCustData] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('ASC');
	const [sortBy, setSortBy] = useState('repCustomer');
	const defaultSort = 'repCustomer';
	const defaultOrder = 'ASC';
	const [filters, setFilters] = useState(defaultFilters);
	const route = useRoute();

	const userData = useSelector(getUserData);

	const canEnterCustomer = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ACCESS_CUSTOMER_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	useEffect(() => {
		if (route.params?.filtersData) {
			setFilters(state => ({
				...state,
				...JSON.parse(route.params?.filtersData),
			}));
			navigation.setParams({ filtersData: undefined });
		}
		if (route.params?.customersFullData) {
			const repCustData = JSON.parse(route.params?.customersFullData);
			setRepCustData(
				repCustData.map((d: any) => ({ label: d.name, value: d.id })),
			);
			navigation.setParams({ customersFullData: undefined });
		}
	}, [route.params]);

	const getBusinessUnitsList = async () => {
		const res: any = await httpService.api<string[]>({
			type: 'getRepresntUnits',
			query: { pageName: 'repPaycheckReport' },
			disableBI: true,
		});
		setBusinessUnitsList(
			res.map((item: any) => ({
				...item,
				id: item.numericId,
				stringId: item.id,
			})),
		);
		setFilters(state => ({ ...state, unitIds: [res[0]?.numericId] }));
	};

	const getRepCustomerUnitsList = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res: any = await httpService.api<string[]>({
			type: 'getRepCustomerUnitsList',
			data: {
				p: page,
				ps: 15,
				search,
				f: {
					unitIds: getSelectedUnits(
						filters.unitIds,
						businessUnitsList,
					),
					repCustomerIds: filters.repCustomerIds,
				},
			},
			query: { pageName: 'repPaycheckReport' },
			disableBI: true,
		});
		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.id,
					label: el.name,
				};
			}),
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	const getOperatorList = async () => {
		const res: any = await httpService.api({
			type: 'getCustomerOperatorsList',
			query: {
				repCustomerIds: filters.repCustomerIds.join(','),
				unitIds: getSelectedUnits(
					filters.unitIds,
					businessUnitsList,
				).join(','),
			},
			disableBI: true,
		});
		if (res?.length) {
			setNoOperators(false);
			setOperatorsList([
				{
					id: -1,
					name: i18nService.translate(
						'repCustomersPage.operator.noManager',
					),
				},
				...res,
			]);
		} else {
			setOperatorsList([
				{
					id: -1,
					name: i18nService.translate(
						'repCustomersPage.operator.noManager',
					),
				},
			]);
			setNoOperators(true);
		}
	};

	const onFilter = (data: FilterData) => {
		handleFilter(data, setFilters, filters, setRepCustData);
	};

	useEffect(() => {
		setIsLoading(true);
		getBusinessUnitsList();
	}, [userData]);

	useEffectNotInitial(() => {
		if (businessUnitsList.length && filters.unitIds.length) {
			getOperatorList();
			onMount();
		}
	}, [filters.unitIds, filters.repCustomerIds, businessUnitsList]);

	useEffectNotInitial(() => {
		debouncedGetMoreData(true, defaultSort, defaultOrder, filters);
	}, [filters]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('EmplooyeeRequests');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};
	const debouncedGetMoreData = debounce((...args) => {
		getRequestsData(...args);
	}, 300);

	const getRepCustomerIdsData = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: page,
				ps: 15,
				search,
				f: {
					unitIds: getSelectedUnits(
						filters.unitIds,
						businessUnitsList,
					),
				},
			},
			disableBI: true,
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.numericId,
					label: el.name + ` (${el.id})`,
				};
			}),
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	const filtersConfig = useMemo(() => {
		const dateVal = filters.year.length
			? moment()
					.set('year', filters.year[0])
					.set('month', filters.month[0] - 1)
					.toDate()
			: moment(newDate).subtract(1, 'months').toDate();

		return compact([
			{
				key: `status`,
				name: 'status',
				options: statusOptions,
				noSpecialAll: true,
				value: filters.status.map(id =>
					statusOptions.find(item => item.id === id),
				),
				placeholder: 'status',
				alwaysRerender: true,
				forceNoAll: true,
				styles: { width: 180, control: { maxWidth: 180 } },
				type: 'multiselect',
			},
			{
				key: `${JSON.stringify(filters.unitIds)}-${JSON.stringify(
					filters.repCustomerIds,
				)}-${JSON.stringify(noOperators)}-operatorIds`,
				name: 'operatorIds',
				type: 'multiselect',
				options: operatorsList,
				forceNoAll: noOperators ? true : false,
				value:
					operatorsList?.length === 1
						? operatorsList
						: filters.operatorIds.map(id =>
								operatorsList.find(item => item.id === id),
						  ),
				placeholder: 'operators',
				styles: {
					width: 200,
				},
			},
			{
				key: `${JSON.stringify(filters.unitIds)}-${JSON.stringify(
					filters.repCustomerIds,
				)}-repCustomerUnitIds`,
				name: 'repCustomerUnitIds',
				type: 'asyncmultiselect',
				placeholder: 'businessUnits.placeholder',
				loadOptions: getRepCustomerUnitsList,
				styles: { width: 220 },
			},
			{
				key: `${JSON.stringify(filters.unitIds)}-repCustomerIds`,
				name: 'repCustomerIds',
				loadOptions: getRepCustomerIdsData,
				type: 'asyncmultiselect',
				placeholder: 'customers',
				value: repCustData,
				styles: { width: 250 },
			},
			{
				key: `monthYear`,
				name: 'monthYear',
				type: 'datepicker',
				date: dateVal,
				placeholder:
					filters.year.length &&
					i18nService.translate(
						`months.${moment(dateVal).format('MMMM')}`,
					) + ` ${dateVal.getFullYear()}`,
				onlyMonthYear: true,
				showYearPicker: false,
				showQuarterYearPicker: false,
				styles: {
					width: 140,
				},
			},
			businessUnitsList?.length > 1
				? {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: filters.unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
				: undefined,
		]);
	}, [
		userData,
		businessUnitsList,
		operatorsList,
		filters.unitIds,
		filters.repCustomerIds,
		filters.year,
		noOperators,
		repCustData,
	]);

	const getRequestsData = async (
		first: boolean,
		newSortBy?: string,
		neworder?: string,
		filterData?: any,
		search?: string,
	) => {
		if (!filters?.unitIds.length) {
			return;
		}
		try {
			const tempFilter = filterData || filters;
			const ps = 40;
			const res = await httpService.api<any>({
				type: 'getRepresentPaycheckReport',
				data: {
					p: first ? 0 : nextPage,
					ps: ps,
					s: newSortBy ? newSortBy : sortBy,
					sd: neworder ? neworder : order,
					f: {
						...tempFilter,
						unitIds: getSelectedUnits(
							tempFilter?.unitIds,
							businessUnitsList,
						),
						status:
							tempFilter?.status?.length > 1
								? []
								: tempFilter?.status,
					},
					search: search,
				},
			});

			const data = res.data;

			if (res) {
				setDisableLoad(data?.length < ps);
				if (data.length !== 0) {
					if (first) {
						setRequestsData(data);
						setNextPage(1);
					} else {
						setRequestsData([...requestsData, ...data]);
						setNextPage(nextPage + 1);
					}
				} else {
					setRequestsData([]);
				}
				setIsLoading(false);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	};

	const onSort = (newSort: string, order: string) => {
		setOrder(order);
		setSortBy(newSort);
		getRequestsData(true, newSort, order, filters);
	};

	return (
		<RepOrgReports
			navigation={navigation}
			route={route}
			data={requestsData}
			onSort={onSort}
			onLoadMore={() =>
				disabledLoad
					? null
					: debouncedGetMoreData(false, sortBy, order, filters)
			}
			refresh={() => debouncedGetMoreData(true, sortBy, order, filters)}
			onFilter={onFilter}
			filtersConfig={filtersConfig}
			sortBy={sortBy}
			order={order}
			filters={filters}
			isLoading={isLoading}
			canEnterCustomer={canEnterCustomer}
		/>
	);
};

export default React.memo(RepPaycheckReportsContainer);
