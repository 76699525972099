import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Period } from '../../../../interfaces/redux.interface';
import {
	DetailedCostingDepartmentModel,
	DetailedCostingEmployeeModel,
} from '../../../../models/detailedCostingReport.model';
import httpService from '../../../../services/http.service';
import {
	getDateFrom,
	getPeriodNumber,
	parseLastDate,
} from '../../../../utils/reportsPagesHelpers.utils';
import MonthYearFormat from '../../../FromMonthToMonthReports/ByEmployeeform/Formats/monthYearFormat';

export type FilterState = {
	period: Period[];
	dep: string[];
	emp: string[];
	from_month: number[];
	from_year: number[];
	to_month: number[];
	to_year: number[];
	unitIds: number[];
};

function setMonth(monthNumber: number | string): string {
	const month = Number(monthNumber) + 1;
	return month > 9 ? String(month) : '0' + month;
}

function setYear() {
	const currentMonth = new Date().getMonth();
	if (currentMonth < 5) {
		return new Date().getFullYear() - 1;
	}

	return new Date().getFullYear();
}

export const initialFilterState: FilterState = {
	period: [Period.MONTH],
	dep: [],
	emp: [],
	from_month: [],
	from_year: [],
	to_month: [],
	to_year: [],
	unitIds: [],
};

export const initialState: DetailedReportReducerState = {
	filters: initialFilterState,
	departments: [],
	employees: [],
	periods: [],
	errorMessage: '',
	lastMonth: [],
	lastYear: [],
	completed: true,
	totalDepartmentsReports: undefined,
	sortDepartmentsBy: null,
	departmentsSortDirection: null,
	sortEmployeesBy: null,
	employeesSortDirection: null,
	selectedDepartmentForEmployeesFetch: [],
	hasMoreEmployees: false,
	totalEmployeesReports: null,
	employeesPageNumber: 0,
	employeesPageSize: 15,
};

export type DetailedReportReducerState = {
	filters: FilterState;
	departments: any[];
	employees: any[];
	errorMessage: string;
	completed: boolean;
	totalDepartmentsReports?: any;
	sortDepartmentsBy: string | null;
	departmentsSortDirection: string | null;
	sortEmployeesBy: string | null;
	employeesSortDirection: string | null;
	periods: string[];
	selectedDepartmentForEmployeesFetch: string[];
	hasMoreEmployees: boolean;
	totalEmployeesReports: number | null;
	employeesPageNumber: number;
	employeesPageSize: number;
	lastMonth: number[];
	lastYear: number[];
};
export type Action = {
	type: string;
	payload?: any;
};

export const detailedReportReducer: React.Reducer<
	DetailedReportReducerState,
	Action
> = (state, action) => {
	switch (action.type) {
		case 'reset_filters': {
			return cloneDeep(initialState);
		}
		case 'increment_page':
			return {
				...state,
				employeesPageNumber: state.employeesPageNumber + 1,
			};
		case 'update_unitIds':
			return {
				...state,
				filters: {
					...state.filters,
					unitIds: action.payload.isMulti
						? action.payload.value.map((val: any) => val.id || val)
						: [action.payload.value],
					dep: [],
					emp: [],
				},
			};
		case 'update_date_from': {
			const period = state.filters.period[0];
			const to_month = state.filters.to_month[0];
			const to_year = state.filters.to_year[0];

			let perNumber = 1;
			if (period === Period.QUARTERLY) {
				perNumber = getPeriodNumber(action.payload.getMonth() + 1);
			}
			const dateObj = getDateFrom(
				{
					year: action.payload.getFullYear(),
					month: action.payload.getMonth() + 1,
				},
				period,
				perNumber,
			);

			const lastDate = moment(action.payload).add(11, 'month');

			return {
				...state,
				filters: {
					...state.filters,
					from_year: [new Date(action.payload).getFullYear()],
					to_year:
						period === Period.MONTH
							? [
									to_year > lastDate.year()
										? lastDate.year()
										: to_year,
							  ]
							: [to_year],
					to_month:
						period === Period.MONTH
							? [
									to_month > lastDate.month() + 1 &&
									to_year > lastDate.year()
										? lastDate.month() + 1
										: to_month,
							  ]
							: [to_month],
					from_month: [new Date(action.payload).getMonth() + 1],
				},
			};
		}
		case 'update_date_to': {
			const period = state.filters.period[0];
			let perNumber = 1;
			if (period === Period.QUARTERLY) {
				perNumber = getPeriodNumber(action.payload.getMonth() + 1);
			}

			return {
				...state,
				filters: {
					...state.filters,
					to_year: [new Date(action.payload).getFullYear()],
					to_month: [
						new Date(action.payload).getMonth() +
							(period === Period.QUARTERLY ? 3 : 1),
					],
				},
			};
		}
		case 'update_period':
			let periodNumber = 1;

			if (action.payload === Period.QUARTERLY) {
				periodNumber = getPeriodNumber(state.lastMonth[0]);
			}
			const dateFrom = getDateFrom(
				{
					year: state.filters.to_year[0],
					month: state.lastMonth[0],
				},
				action.payload,
				periodNumber,
			);

			return {
				...state,
				filters: {
					...state.filters,
					period: action.payload ? [action.payload] : [],
					to_year: [dateFrom.year],
					from_month: [dateFrom.month],
					from_year: [dateFrom.year],
					to_month:
						action.payload === Period.YEAR
							? [12]
							: action.payload === Period.QUARTERLY
							? [dateFrom.month + 2]
							: state.lastMonth,
				},
			};
		case 'update_departments':
			return {
				...state,
				filters: {
					...state.filters,
					dep: action.payload.map((el: any) => el.id),
					emp: [],
				},
			};
		case 'update_employees':
			return {
				...state,
				filters: {
					...state.filters,
					emp: action.payload.map((el: any) => el.id),
				},
			};
		case 'set_departments_sort':
			return {
				...state,
				sortDepartmentsBy: action.payload.field,
				departmentsSortDirection: action.payload.direction,
			};
		case 'set_employees_sort':
			return {
				...state,
				employeesPageNumber: 0,
				sortEmployeesBy: action.payload.field,
				employeesSortDirection: action.payload.direction,
			};
		case 'update_departments':
			return {
				...state,
				filters: {
					...state.filters,
					dep: action.payload.map((el: any) => el.id),
				},
			};
		case 'update_employees':
			return {
				...state,
				filters: {
					...state.filters,
					user: action.payload.map((el: any) => el.id),
				},
			};
		case 'set_selected_department':
			return {
				...state,
				selectedDepartmentForEmployeesFetch: [action.payload],
			};
		case 'update_role':
			return {
				...state,
				filters: {
					...state.filters,
					role: action.payload.map((el: any) => el.id),
				},
			};
		case 'update_status':
			return {
				...state,
				filters: { ...state.filters, status: [action.payload] },
			};
		case 'fetch_departments_request':
			return {
				...state,
				completed: false,
			};
		case 'fetch_employees_request':
			return {
				...state,
				completed: false,
			};
		case 'fetch_departments_success':
			return {
				...state,
				errorMessage: '',
				completed: true,
				totalDepartmentsReports: action.payload.totalDepartmentsReports,
				departments: action.payload.data,
			};
		case 'fetch_employees_success':
			return {
				...state,
				errorMessage: '',
				completed: true,
				employees: action.payload.data,
				totalEmployeesReports: action.payload.totalEmployeesReports,
				hasMoreEmployees: action.payload.hasMoreEmployees,
			};
		case 'fetch_report_last_date_request_success':
			const dateObjTo = parseLastDate(action.payload.date);
			const dateObjFrom = {
				month: MonthYearFormat(action.payload.date, -5, 'month', true),
				year: MonthYearFormat(action.payload.date, -5, 'year', true),
			};

			return {
				...state,
				filters: {
					...state.filters,
					from_year: [dateObjFrom.year],
					from_month: [dateObjFrom.month],
					to_year: [dateObjTo.year],
					to_month: [dateObjTo.month],
				},
				lastMonth: [dateObjTo.month],
				lastYear: [dateObjTo.year],
			};
		default:
			return state;
	}
};

export const fetchDepartments = ({
	sortBy,
	sortDirection,
	filter,
	dispatch,
}: any) => {
	dispatch({ type: 'fetch_departments_request' });
	httpService
		.api<any>({
			type: 'getDetailedCostingReports',
			data: {
				s: sortBy,
				sd: sortDirection,
				f: filter,
			},
		})
		.then(data => {
			dispatch({
				type: 'fetch_departments_success',
				payload: {
					totalDepartmentsReports: data.total,
					data: data.data.map(
						(report: any) =>
							new DetailedCostingDepartmentModel(report),
					),
				},
			});
		});
	//.catch(err => dispatch({}));
};

export const fetchEmployees = ({
	page,
	pageSize,
	sortBy,
	sortDirection,
	filter,
	dispatch,
}: any) => {
	console.log({
		p: page,
		ps: pageSize,
		s: sortBy,
		sd: sortDirection,
		f: filter,
	});

	dispatch({ type: 'fetch_employees_request' });
	httpService
		.api<any>({
			type: 'getDetailedCostingReportsForEmployees',
			data: {
				p: page,
				ps: pageSize,
				s: sortBy,
				sd: sortDirection,
				f: filter,
			},
		})
		.then(data => {
			dispatch({
				type: 'fetch_employees_success',
				payload: {
					totalEmployeesReports: data.total,
					hasMoreEmployees: data.hasNext,
					data: data.data.map(
						(report: any) =>
							new DetailedCostingEmployeeModel(report),
					),
				},
			});
		});
	//.catch(err => dispatch({});
};

export const fetchReportLastDate = (
	dispatch: any,
	unitIds: number[] | string[],
) => {
	httpService
		.api<any>({
			type: 'getCostingReportLastDate',
			query: { unitIds: unitIds?.map(unit => `${unit}`).join() },
		})
		.then(data => {
			dispatch({
				type: 'fetch_report_last_date_request_success',
				payload: {
					date: data,
				},
			});
		});
};
