import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import I18n from '../../components/shared/i18n/I18n.component';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import { PersonalInformationProps } from './PersonalInformation.component';
import { DataContainer } from './PersonalInformation.utils';

const PersonalInformationMobile = ({
	navigation,
	data,
	onUpdatePassword,
	onUpdateEmail,
	...restProps
}: PersonalInformationProps) => {
	return (
		<ScrollView>
			<View>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
			</View>
			<View style={styles.container}>
				<I18n weight='normal' size={28} style={{ marginBottom: 30 }}>
					personalInformation.title
				</I18n>
				<DataContainer
					isMobileWeb
					userData={data}
					onUpdatePassword={onUpdatePassword}
					onUpdateEmail={onUpdateEmail}
					{...restProps}
				/>
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: 27,
		paddingHorizontal: 30,
	},
});

export default PersonalInformationMobile;
