import React, { useMemo } from 'react';
import { StyleProp, Text } from 'react-native';
import { Color, FontSize, Weight } from '../../../interfaces/core.interface';
import { i18nService } from '../../../services/i18n.service';
import Typography from '../Typography/Typography.component';

export interface I18nProps extends Record<string, any> {
	style?: StyleProp<any>;
	children: any;
	size?: FontSize;
	color?: Color;
	weight?: Weight;
}

const I18n = (props: I18nProps) => {
	const {
		size,
		color,
		weight,
		style,
		element,
		title,
		children,
		numberOfLines,
		...rest
	} = props;

	// If children is not a string, transparently render the children as usual.
	const text = useMemo(() => {
		return typeof children === 'string'
			? i18nService.translate(children, undefined, rest)
			: children;
	}, [children, rest]);

	return React.createElement(
		Typography,
		{ style, children, size, color, weight, numberOfLines },
		text,
	);
};

export default I18n;
