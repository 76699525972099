import React from 'react';
import colors from '../../../../config/colors';
import { Platform, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import I18n from '../../i18n/I18n.component';

type RadioButtonsGroupType = {
	value: any;
	onChange: any;
	onBlur: () => void;
	required?: boolean;
	error: any;
	editable?: boolean;
	options: {
		id: any;
		name: string;
	}[];
	outerClasses?: {
		radioButton: any;
		radioButtonLabel: any;
		radioGroup: any;
	};
	buttonStyles?: {
		buttonsGroup: any;
		button: any;
	};
	isMobile?: boolean;
};

const RadioButtonsGroup = ({
	value,
	onChange,
	onBlur,
	error,
	required,
	options,
	editable,
	isMobile,
	buttonStyles,
}: RadioButtonsGroupType) => {
	return (
		<View
			style={{
				width: '100%',
				flex: 1,
				flexDirection:
					!isMobile && Platform.OS === 'web' ? 'row' : 'row-reverse',
				...buttonStyles?.buttonsGroup,
			}}
		>
			{options.map((opt: { id: any; name: string }, i: number) => (
				<View style={{ flex: 1 }}>
					<TouchableOpacity
						onPress={() => onChange(opt.id)}
						key={`${opt.id}${editable}`}
						disabled={!editable}
						style={{
							opacity: !editable ? 0.5 : 1,
							backgroundColor:
								value === opt.id
									? colors.primary
									: colors.white,
							height: buttonStyles?.button?.height || 40,
							flex: Platform.OS === 'web' ? undefined : 1,
							borderWidth: buttonStyles?.button?.borderWidth || 1,
							borderColor:
								buttonStyles?.button?.borderColor ||
								colors.primary,
							borderTopRightRadius: !i
								? buttonStyles?.button?.borderRadius || 5
								: 0,
							borderBottomRightRadius: !i
								? buttonStyles?.button?.borderRadius || 5
								: 0,
							borderTopLeftRadius:
								i === options.length - 1
									? buttonStyles?.button?.borderRadius || 5
									: 0,
							borderBottomLeftRadius:
								i === options.length - 1
									? buttonStyles?.button?.borderRadius || 5
									: 0,
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<I18n
							size={12}
							weight='normal'
							color={value === opt.id ? 'white' : 'primary'}
						>
							{opt.name}
						</I18n>
					</TouchableOpacity>
				</View>
			))}
		</View>
	);
};

export default RadioButtonsGroup;
