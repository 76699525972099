import React, { useCallback, useState, useEffect } from 'react';
import I18n from '../../shared/i18n/I18n.component';
import Table, { TableHeader } from './Table.component';

interface Props {
	headers: TableHeader[];
	filters?: Object;
	resetData?: Function;
	onRowClick?: (dataItem: any, event?: any) => void;
	onSubRowClick?: Function;
	noDataMessage?: string;
	noInfinityScroll?: boolean;
	getNextData: () => void;
	outerClasses?: any;
	data: any;
	count?: number;
	keyName: string;
	onSort: (field: string, direction: string) => void;
	allDataFetched?: boolean;
	hasFooter: boolean;
	errorMessage?: string;
	onLeftArrowClick?: Function;
	onDownArrowClick?: Function;
	setCurrentTableState?: any;
	height?: string | number;
	defaultExpandedRows?: {
		[key: string]: boolean;
	};
	selectedRowId?: string | number;
	showExpandHeader?: boolean;
	refresh?: () => void;
	defaultScrollRight?: boolean;
	scrollToTop?: boolean;
	setScrollToTop?: any;
	currentTableState?: any;
	getUpdatedDataFromTable: Function;
	scrollSyncMode?: boolean;
	resetActiveSortByProps?: any;
	specialRowColor?: string;
	windowScale: number;
}

interface EditableCellProps {
	value: string;
	row: any;
	column: any;
	style: object;
}

const TableContainer = ({
	headers,
	filters,
	getNextData,
	data,
	resetData,
	onRowClick,
	onSubRowClick,
	outerClasses,
	noDataMessage,
	noInfinityScroll,
	onSort,
	keyName,
	count,
	allDataFetched,
	hasFooter,
	errorMessage,
	defaultExpandedRows,
	onLeftArrowClick,
	onDownArrowClick,
	setCurrentTableState,
	currentTableState,
	height,
	selectedRowId,
	showExpandHeader,
	defaultScrollRight,
	scrollToTop,
	setScrollToTop,
	windowScale,
	refresh,
	getUpdatedDataFromTable,
	scrollSyncMode,
	resetActiveSortByProps = [],
	specialRowColor,
}: Props) => {
	const [activeSort, setActiveSort] = useState({ name: '', direction: '' });
	const updateTableData = (
		rowIndex: number,
		columnId: string,
		value: string,
		data: any,
	): void => {
		const newData = data.map((row: any, index: any) => {
			if (index === rowIndex) {
				return {
					...data[rowIndex],
					[columnId]: value,
				};
			}
			return row;
		});

		getUpdatedDataFromTable({
			rowIndex,
			columnId,
			value,
			newData,
		});
	};

	const EditableCell = ({
		value: initialValue,
		row: { index },
		column: { id },
	}: EditableCellProps) => {
		const [value, setValue] = useState(initialValue);

		const _onChange = (e: any) => {
			setValue(e.target.value);
		};

		const _onBlur = () => {
			updateTableData(index, id, value, data);
		};

		useEffect(() => {
			setValue(initialValue);
		}, [initialValue]);

		return <input value={value} onChange={_onChange} onBlur={_onBlur} />;
	};

	useEffect(() => {
		setActiveSort({ name: '', direction: '' });
	}, resetActiveSortByProps);

	const _onSubRowClick = useCallback(
		(dataItem: any) => {
			onSubRowClick ? onSubRowClick(dataItem) : null;
		},
		[onSubRowClick],
	);

	const _onSort = (name: string, direction: string) => {
		onSort && onSort(name, direction);
		setActiveSort({ name, direction });
	};

	return (
		<div dir='rtl' style={{ height: '100%' }}>
			{errorMessage ? (
				<div
					style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						zIndex: -1,
					}}
				>
					{
						<I18n weight={'bold'} size={24}>
							{errorMessage}
						</I18n>
					}
				</div>
			) : (
				<Table
					selectedRowId={selectedRowId}
					tableBody={data}
					headers={headers}
					allDataFetched={allDataFetched}
					next={getNextData}
					onSort={_onSort}
					activeSort={activeSort}
					outerClasses={outerClasses}
					onRowClick={onRowClick}
					onSubRowClick={_onSubRowClick}
					noInfinityScroll={noInfinityScroll}
					keyName={keyName}
					hasFooter={hasFooter}
					onLeftArrowClick={onLeftArrowClick}
					onDownArrowClick={onDownArrowClick}
					defaultExpandedRows={defaultExpandedRows}
					setCurrentTableState={setCurrentTableState}
					currentTableState={currentTableState}
					scrollToTop={scrollToTop}
					setScrollToTop={setScrollToTop}
					height={height}
					windowScale={windowScale}
					showExpandHeader={showExpandHeader}
					defaultScrollRight={defaultScrollRight}
					updateMyData={updateTableData}
					// skipPageReset={skipPageReset}
					EditableCell={EditableCell}
					scrollSyncMode={scrollSyncMode}
					specialRowColor={specialRowColor}
				/>
			)}
		</div>
	);
};

export default TableContainer;
