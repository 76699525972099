import React, { useState } from 'react';
import { Dimensions, useWindowDimensions, View } from 'react-native';
import { Breadcrumbs, Link } from '@material-ui/core';
import colors from '../../config/colors';
import I18n from '../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import Header from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import ReportsPageWrapper from '../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { i18nService } from '../../services/i18n.service';
import {
	ApproveAllButton,
	ApproveButtons,
	approveMultiple,
	CheckBox,
	DescroptionText,
	DownloadSection,
	EditButton,
} from './EmplooyeeRequests.utils';
import EmplooyeeRequestsMobile from './EmplooyeeRequests.component.native';
import Filters from '../../components/web/Filters/Filters.component';
import { StatusTableSection } from './StatusTableSection';
import TableContainer from '../../components/web/ReactTable/Table.container';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../store/selectors/login.selectors';
import { Privileges } from '../../constants/roleTypes.constant';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import { modalService } from '../../services/modal.service';
import { compact } from 'lodash';
import { hasPermissionOnBusinessUnit } from '../../utils/globals.utils';

export interface EmplooyeeRequestsProps {
	navigation: any;
	data: any[];
	selectedFilters: any;
	filtersConfig: any;
	finishFirstLoad: boolean;
	filtesSelected: boolean;
	validationDate: number;
	onSort: (field: string, direction: string) => void;
	onLoadMore(): void;
	onFilter(filterData: any, byClick: boolean): void;
	refresh(): void;
	onAcceptRequests(isStarted: boolean, filterData?: any): void;
	businessUnitsList: any[];
}

const EmplooyeeRequests = ({
	navigation,
	data,
	selectedFilters,
	filtersConfig,
	finishFirstLoad,
	filtesSelected,
	validationDate,
	onSort,
	onLoadMore,
	onFilter,
	refresh,
	onAcceptRequests,
	businessUnitsList,
}: EmplooyeeRequestsProps) => {
	const { height: windowHeight } = useWindowDimensions();

	const platform = useDevicePlatform();
	const [startSelect, setStartSelect] = useState(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState(false);

	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);

	const isMobileWeb = platform === DeviceType.MOBILE_WEB;
	const headers = compact([
		{
			accessor: 'fullName',
			Header: i18nService.translate('employeeRequests.name'),
			isSortable: true,
		},
		{
			accessor: 'teudatZeut',
			Header: i18nService.translate('employeeRequests.id'),
			isSortable: true,
		},
		businessUnitsList.length > 1
			? {
					accessor: 'businessUnitName',
					Header: i18nService.translate('general.businessUnits'),
					isSortable: true,
			  }
			: null,
		{
			accessor: 'departmentName',
			Header: i18nService.translate('employeeRequests.department'),
			isSortable: true,
		},
		{
			accessor: 'type',
			Header: i18nService.translate(
				'applicationsToEmployerContainer.requestType',
			),
			isSortable: true,
		},
		{
			accessor: 'description',
			Header: i18nService.translate(
				'applicationsToEmployerContainer.requestDescription',
			),
			isSortable: true,
			CustomCell: ({ value }: any) => <DescroptionText value={value} />,
		},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate(
				'applicationsToEmployerContainer.lastUpdated',
			),
			isSortable: true,
		},
		{
			accessor: 'status',
			Header: i18nService.translate(
				'applicationsToEmployerContainer.status',
			),
			isSortable: true,
			CustomCell: ({ row, column }: any) => (
				<StatusTableSection data={row.original} refresh={refresh} />
			),
		},
		{
			accessor: 'dateRange',
			Header: i18nService.translate(
				'applicationsToEmployerContainer.dates',
			),
			isSortable: true,
		},

		{
			accessor: 'employerComment',
			Header: i18nService.translate(
				'applicationsToEmployerContainer.notes',
			),
			isSortable: true,
			CustomCell: ({ value }: any) => <DescroptionText value={value} />,
		},
		{
			accessor: 'attachedFiles',
			Header: i18nService.translate(
				'applicationsToEmployerContainer.files',
			),
			isSortable: false,
			CustomCell: ({ row }: any) => (
				<DownloadSection data={row.original} />
			),
		},
		{
			accessor: 'edit',
			Header: '',
			sortable: false,
			CustomCell: ({ row }: any) => (
				<EditButton
					data={row.original}
					onRefresh={refresh}
					validationDate={validationDate}
				/>
			),
		},
	]);
	const updateFilters = val => {
		startSelect ? onAcceptRequests(true, val) : onFilter(val, true);
	};

	const onApproveAll = async () => {
		if (selectedItems.length === 0) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>employeeRequests.selectUsers</I18n>
					</AlertModal>
				),
			);
		} else {
			setStartSelect(!startSelect);
			approveMultiple(selectedItems, refresh);
			onAcceptRequests(false);
		}
	};

	const onStartSelect = () => {
		setStartSelect(!startSelect);
		setSelectedItems([]);
		setSelectAll(false);
		onAcceptRequests(true, {
			name: 'status',
			value: [{ id: 'PENDING', name: 'PENDING' }],
		});
	};

	const requestsColumns = (
		startSelect: boolean,
		selectAll: boolean,
		onSelectAll: (val: boolean) => void,
		onRowsChecked: (val: boolean, requestId: number) => void,
		selectedItems: number[],
		writeVacationPermition: boolean,
		writeSiknessPermition: boolean,
	) => {
		const checkboxHeader = {
			accessor: 'checkbox',
			Header: () => (
				<CheckBox value={selectAll} onValueChange={onSelectAll} />
			),
			isSortable: false,
			CustomCell: ({ row }: any) => {
				if (
					(hasPermissionOnBusinessUnit(
						row.original.businessUnitId,
						Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
					) &&
						row.original.type ===
							i18nService.translate(
								'applicationsToEmployerContainer.vacationRequest',
							)) ||
					(hasPermissionOnBusinessUnit(
						row.original.businessUnitId,
						Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
					) &&
						row.original.type ===
							i18nService.translate(
								'applicationsToEmployerContainer.sicknessReport',
							))
				) {
					return (
						<CheckBox
							value={
								!!selectedItems.find(
									el => el === row.original.id,
								) || false
							}
							onValueChange={val =>
								onRowsChecked(val, row.original.id)
							}
						/>
					);
				}
				return <View />;
			},
		};

		return startSelect ? [checkboxHeader, ...headers] : headers;
	};

	const onRowsChecked = (val: boolean, requestId: number) => {
		let data = [...selectedItems];
		if (val) {
			data.push(requestId);
		} else {
			setSelectAll(false);
			data = data.filter(el => el !== requestId);
		}
		setSelectedItems(data);
	};

	const onSelectAll = val => {
		setSelectAll(val);
		const selected = [];
		if (val) {
			data.forEach(item => {
				const vacationPermition =
					hasPermissionOnBusinessUnit(
						item.businessUnitId,
						Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
					) &&
					item.type ===
						i18nService.translate(
							'applicationsToEmployerContainer.vacationRequest',
						);
				const siknessPermition =
					hasPermissionOnBusinessUnit(
						item.businessUnitId,
						Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
					) &&
					item.type ===
						i18nService.translate(
							'applicationsToEmployerContainer.sicknessReport',
						);
				if (!!vacationPermition || !!siknessPermition) {
					selected.push(item?.id);
				}
			});
		}
		setSelectedItems(selected);
	};

	return isMobileWeb ? (
		<EmplooyeeRequestsMobile
			navigation={navigation}
			data={data}
			onSort={onSort}
			onLoadMore={onLoadMore}
			onFilter={onFilter}
			filtersConfig={filtersConfig}
			selectedFilters={selectedFilters}
			refresh={refresh}
			finishFirstLoad={finishFirstLoad}
			filtesSelected={filtesSelected}
			validationDate={validationDate}
			onAcceptRequests={onAcceptRequests}
		/>
	) : (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<Breadcrumbs separator='<'>
						<Link color='inherit' underline={'none'}>
							<I18n size={36} weight='normal' style={{ flex: 1 }}>
								applicationsToEmployerContainer.title
							</I18n>
						</Link>
					</Breadcrumbs>
				}
				noHeader={true}
			>
				<View
					style={{
						marginBottom: 20,
						flexDirection: 'row-reverse',
						zIndex: 100,
					}}
				>
					{startSelect ? (
						<View
							style={{
								marginBottom: 20,
								flexDirection: 'row-reverse',
								zIndex: 100,
							}}
						>
							<ApproveButtons
								onApprove={() => onApproveAll()}
								onCancel={() => {
									setStartSelect(false);
									onAcceptRequests(false);
								}}
							/>
						</View>
					) : (
						<ApproveAllButton
							disabled={
								!employerRolesPrivileges?.includes(
									Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
								) &&
								!employerRolesPrivileges?.includes(
									Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
								)
							}
							onStartSelect={() => onStartSelect()}
						/>
					)}
					<Filters
						config={filtersConfig}
						onChange={updateFilters}
						hideSearchInput
						customStyles={{
							container: {
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
							},
							filterContainer: {
								marginRight: 15,
								zIndex: 10001,
							},
						}}
					/>
				</View>

				{data.length > 0 ? (
					<View style={{ maxHeight: windowHeight - 220 }}>
						<TableContainer
							keyName={''}
							headers={requestsColumns(
								startSelect,
								selectAll,
								onSelectAll,
								onRowsChecked,
								selectedItems,
								!!employerRolesPrivileges?.includes(
									Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
								),
								!!employerRolesPrivileges?.includes(
									Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
								),
							)}
							data={data || []}
							getNextData={onLoadMore}
							onSort={onSort}
							hasFooter={false}
						/>
					</View>
				) : finishFirstLoad ? (
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<I18n
							weight='bold'
							size={24}
							style={{ marginRight: 26 }}
						>
							employeeRequests.noReportsFound
						</I18n>
					</View>
				) : (
					<View />
				)}
			</ReportsPageWrapper>
		</View>
	);
};

export default EmplooyeeRequests;
