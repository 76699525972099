import React from 'react';
import { StyleSheet } from 'react-native';
import Button from './Button.component';
import { ButtonProps } from '../../../interfaces/buttonProps.interface';

const LinkButtonContainer = ({
	children,
	onPress,
	disabled,
	overrideStyle,
}: ButtonProps) => {
	const buttonStyles = StyleSheet.flatten([
		styles.defaultLink,
		overrideStyle,
	]);
	return (
		<Button
			onPress={onPress}
			disabled={disabled}
			buttonStyle={buttonStyles}
		>
			{children}
		</Button>
	);
};

const styles = StyleSheet.create({
	defaultLink: {
		textAlign: 'center',
		marginTop: 15,
		width: 90,
		alignSelf: 'center',
		padding: 0,
		borderRadius: 0,
	},
});

export default LinkButtonContainer;
