import moment from 'moment';
import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { i18nService } from '../../../services/i18n.service';
import { CheckBox } from '../../EmplooyeeRequests/EmplooyeeRequests.utils';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import { Privileges } from '../../../constants/roleTypes.constant';

const convertMinutesToHours = (value: any, format?: string) => {
	if (value) {
		if (format !== 'DECIMAL') {
			const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
			const minutes =
				value % 60 !== 0
					? value % 60 < 10
						? `0${value % 60}`
						: `${value % 60}`
					: '00';
			return `${hours}:${minutes}`;
		}
		return `${(value / 60).toLocaleString(undefined, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		})}`;
	}
	return '';
};
export const convertFromUtc = (time: string, getHoursAndMinutes: boolean) => {
	if (!time) return null;
	const format = getHoursAndMinutes ? 'HH:mm' : "'YYYY-MM-DD HH:mm'";
	const utcTime = moment.utc(time, 'YYYY-MM-DDTHH:mm');
	const local = moment(utcTime).local().format(format);
	return local;
};
const headers = (
	total,
	onEdit: Function,
	onDelete: Function,
	worklogReportFormat,
	canEdit: boolean,
	businessUnitsList: any[],
) =>
	_.compact([
		{
			accessor: 'projectName',
			Header: i18nService.translate('EmployeeHoursReport.table.project'),
			isSortable: true,
			Footer: i18nService.translate('EmployeeHoursReport.table.total'),
		},
		{
			accessor: 'eventName',
			Header: i18nService.translate('EmployeeHoursReport.table.event'),
			isSortable: true,
		},
		businessUnitsList.length > 1
			? {
					accessor: 'businessUnitName',
					Header: i18nService.translate('general.businessUnits'),
					isSortable: true,
			  }
			: null,
		{
			accessor: 'workLogDate',
			Header: i18nService.translate('EmployeeHoursReport.table.date'),
			isSortable: true,
			CustomCell: ({ value, row }) =>
				value ? moment(value).format('DD/MM/YYYY') : '',
		},
		{
			accessor: 'enterTime',
			Header: i18nService.translate('EmployeeHoursReport.table.entry'),
			isSortable: true,
			CustomCell: ({ value, row }) => convertFromUtc(value, true),
		},
		{
			accessor: 'exitTime',
			Header: i18nService.translate('EmployeeHoursReport.table.exit'),
			isSortable: true,
			CustomCell: ({ value, row }) => convertFromUtc(value, true),
		},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.lastUpdatedAt',
			),
			isSortable: true,
			CustomCell: ({ value, row }) =>
				value ? moment(value).format('DD/MM/YYYY') : '',
		},
		{
			accessor: 'total',
			Header: i18nService.translate('EmployeeHoursReport.table.total'),
			isSortable: true,
			CustomCell: ({ value, row }) =>
				convertMinutesToHours(value, worklogReportFormat),
			Footer: () =>
				` ${
					total
						? `(${(total / 60).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
						  })})`
						: ''
				} ${convertMinutesToHours(total, 'HH:mm')} `,
		},
		{
			accessor: 'updatedBy',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.updatedBy',
			),
			isSortable: true,
		},
		{
			accessor: 'updatedEnter',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.manualEntry',
			),
			isSortable: true,
		},
		{
			accessor: 'updatedExit',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.manualExit',
			),
			isSortable: true,
		},
		{
			accessor: 'updatedProject',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.manualProjectChange',
			),
			isSortable: true,
		},
		{
			accessor: 'pendingStatus',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.confirmStatus',
			),
			isSortable: true,
		},
		canEdit && {
			accessor: 'edit',
			Header: i18nService.translate('EmployeeHoursReport.table.edit'),
			isSortable: false,
			CustomCell: ({ value, row }) =>
				hasPermissionOnBusinessUnit(
					row.original.businessUnitId,
					Privileges.ROLE_LOG_WORK_REPORT_WRITE,
				) ? (
					<TouchableOpacity
						disabled={false}
						style={{ opacity: false ? 0.7 : 1 }}
						onPress={() => onEdit(row.original)}
					>
						<ImageIcon name='edit' />
					</TouchableOpacity>
				) : null,
		},
		canEdit && {
			accessor: 'delete',
			Header: i18nService.translate('EmployeeHoursReport.table.delete'),
			isSortable: false,
			CustomCell: ({ value, row }) =>
				row.original.workLogId &&
				hasPermissionOnBusinessUnit(
					row.original.businessUnitId,
					Privileges.ROLE_LOG_WORK_REPORT_WRITE,
				) ? (
					<TouchableOpacity
						disabled={false}
						style={{ opacity: false ? 0.7 : 1 }}
						onPress={() => onDelete(row.original)}
					>
						<ImageIcon name='delete' />
					</TouchableOpacity>
				) : null,
		},
	]);

const checkboxHeader = (
	isAllSelected: boolean,
	selectedRows: any[],
	onSelectAll: (cheacked: boolean) => {},
	onSelect: (index: any) => {},
) => {
	const shouldBeMarkedAsChecked = ({ workLogId }) => {
		if (isAllSelected) return true;
		if (selectedRows.includes(workLogId)) return true;
		return false;
	};
	return {
		accessor: 'checkbox',
		Header: () => (
			<CheckBox
				value={isAllSelected}
				onValueChange={(item: any) => {
					onSelectAll(item);
				}}
			/>
		),
		isSortable: false,
		CustomCell: ({ value, row }) =>
			row.original.pendingStatus === 'ממתין לאישור' &&
			hasPermissionOnBusinessUnit(
				row.original.businessUnitId,
				Privileges.ROLE_LOG_WORK_REPORT_WRITE,
			) ? (
				<CheckBox
					value={shouldBeMarkedAsChecked(row.original)}
					onValueChange={() => onSelect(row.original)}
				/>
			) : (
				<View />
			),
	};
};

export const Columns = (
	total: number,
	isSelectMode: boolean,
	selectedRows: any[],
	isAllSelected: boolean,
	onSelectAll: (cheacked: boolean) => {},
	onSelect: (index: any) => {},
	onDelete: Function,
	onEdit: Function,
	worklogReportFormat: any,
	canEdit: boolean,
	businessUnitsList: any[],
) => {
	return isSelectMode
		? [
				checkboxHeader(
					isAllSelected,
					selectedRows,
					onSelectAll,
					onSelect,
				),
				...headers(
					total,
					onEdit,
					onDelete,
					worklogReportFormat,
					canEdit,
					businessUnitsList,
				),
		  ]
		: headers(
				total,
				onEdit,
				onDelete,
				worklogReportFormat,
				canEdit,
				businessUnitsList,
		  );
};
