import _, { compact } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, ScrollView, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import HeaderContainer from '../../../components/shared/Header/Header.container';
import WebHeader from '../../../components/shared/Header/WebHeader.component';
import FiltersContainer, {
	FilterData,
} from '../../../components/web/Filters/Filters.container';
import colors from '../../../config/colors';
import httpService from '../../../services/http.service';
import { i18nService } from '../../../services/i18n.service';
import { getUserData } from '../../../store/selectors/login.selectors';
import HideViewButtons from './HideViewButtons/HideViewButtons.component';
import MonthlyData from './MonthlyData/MonthlyData.component';
import Report101 from './Form101/Form101.component';
import YearlyData from './YearlyData/YearlyData.component';
import { getSelectedUnits } from '../rep.utils';
import ReportFromMonthToMonth from './ReportFromMonthToMonth/ReportFromMonthToMonth.component';
import { Privileges } from '../../../constants/roleTypes.constant';
import useEffectNotInitial from '../../../hooks/useEffectNotInitial';

const initialDisplay = {
	monthly: true,
	yearly: false,
	employeeForm: false,
	workPaperDraft: false,
};

const RepHomePage = ({ navigation }: any) => {
	const [operatorsList, setOperatorsList] = useState<any[]>([]);
	const [displayCardMap, setDisplayCardMap] = useState<{
		monthly: boolean;
		yearly: boolean;
		employeeForm: boolean;
		workPaperDraft: boolean;
	}>();
	const [businessUnitsList, setBusinessUnitsList] = useState([]);
	const [filters, setFilters] = useState<any>({
		repCustomerIds: [],
		operatorIds: [],
		unitIds: [],
	});
	const userData = useSelector(getUserData);
	const filtersRef = useRef<any>();
	filtersRef.current = filters;
	const businessUnitsListRef = useRef<any>();
	businessUnitsListRef.current = businessUnitsList;
	const repCustomerIdsFullDataRef = useRef<any>();

	const customersFullDataJson = useMemo(
		() => JSON.stringify(repCustomerIdsFullDataRef.current || []),
		[repCustomerIdsFullDataRef.current],
	);

	const dashboardFilter = useMemo(
		() => ({
			...filters,
			unitIds: getSelectedUnits(filters.unitIds, businessUnitsList),
		}),
		[filters],
	);

	const canDisplay = useMemo(
		() => ({
			yearly:
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_FORM_106_REPORT_READ,
				) ||
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_FORM_106_REPORT_WRITE,
				) ||
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_ORG_126_REPORT_READ,
				) ||
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_ORG_126_REPORT_WRITE,
				),
			monthly:
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_PAYCHECK_REPORT_READ,
				) ||
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_PAYCHECK_REPORT_WRITE,
				),
			employeeForm:
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_FORM_101_REPORT_READ,
				) ||
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_FORM_101_REPORT_WRITE,
				),
			workPaperDraft:
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_WORK_PAPER_REPORT_READ,
				) ||
				userData?.repRolePrivileges?.includes(
					Privileges.ROLE_REP_WORK_PAPER_REPORT_WRITE,
				),
		}),
		[userData?.repRolePrivileges],
	);

	const getBusinessUnitsList = async () => {
		const res: any = await httpService.api<string[]>({
			type: 'getRepresntUnits',
			query: { pageName: 'repCustomers' },
		});
		setBusinessUnitsList(
			res?.map((item: any) => ({
				...item,
				id: item.numericId,
				stringId: item.id,
			})),
		);
		setFilters(state => ({
			...state,
			unitIds: [res[0]?.numericId],
		}));
	};

	const onFilter = (data: FilterData) => {
		if (data.name === 'unitIds') {
			setFilters({
				unitIds: [data.value],
				repCustomerIds: [],
				operatorIds: [],
			});
		} else if (data.name === 'repCustomerIds') {
			repCustomerIdsFullDataRef.current = data.value;
			const chosenIds = data.value.map((val: any) => val.id || val);
			setFilters({
				...filters,
				repCustomerIds: chosenIds?.includes?.('all') ? [] : chosenIds,
			});
		} else if (data.name === 'operatorIds') {
			const chosenIds = data.value.map((val: any) => val.id || val);
			setFilters({
				...filters,
				operatorIds: chosenIds?.includes?.('all') ? [] : chosenIds,
			});
		}
	};

	useEffectNotInitial(() => {
		sessionStorage.setItem('repDashboardFilters', JSON.stringify(filters));
	}, [filters]);

	useEffectNotInitial(() => {
		sessionStorage.setItem(
			'repDashboardCustomersFullData',
			customersFullDataJson,
		);
	}, [customersFullDataJson]);

	const getDisplayView = async () => {
		const res: any = await httpService.api({ type: 'getRepDashboardView' });
		if (res) {
			setDisplayCardMap(res);
		} else {
			setDisplayCardMap({
				...initialDisplay,
			});
		}
	};

	const onDisplayViewChange = async (
		type: 'monthly' | 'yearly' | 'employeeForm' | 'workPaperDraft',
		status: boolean,
	) => {
		const res: any = await httpService.api({
			type: 'updateRepDashboardView',
			params: { type: _.kebabCase(type) },
			data: { status },
		});
		setDisplayCardMap(state => ({
			...(state || initialDisplay),
			[type]: status,
		}));
	};

	useEffect(() => {
		const tempFilters = sessionStorage.getItem('repDashboardFilters');
		if (tempFilters && tempFilters !== '') {
			setFilters(JSON.parse(tempFilters));
		}
		const repDashboardCustomersFullData = sessionStorage.getItem(
			'repDashboardCustomersFullData',
		);
		repCustomerIdsFullDataRef.current = repDashboardCustomersFullData
			? JSON.parse(repDashboardCustomersFullData)
			: [];
		getDisplayView();
	}, []);

	const getOperatorList = async () => {
		const res: any = await httpService.api({
			type: 'getCustomerOperatorsList',
			query: {
				unitIds: getSelectedUnits(
					filters.unitIds,
					businessUnitsList,
				).join(','),
			},
		});

		setOperatorsList([
			{
				id: -1,
				name: i18nService.translate(
					'repCustomersPage.operator.noManager',
				),
			},
			...res,
		]);
	};

	useEffect(() => {
		getBusinessUnitsList();
	}, [userData]);

	useEffect(() => {
		if (businessUnitsList.length && filters.unitIds?.length) {
			getOperatorList();
		}
	}, [filters.unitIds, businessUnitsList]);

	const getRepCustomerIdsData = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: page,
				ps: 15,
				search,
				f: {
					unitIds: getSelectedUnits(
						filtersRef.current?.unitIds,
						businessUnitsListRef.current,
					),
				},
			},
			disableBI: true,
		});

		return {
			options: (page === 0
				? [
						{
							value: 'all',
							label: i18nService.translate('general.all'),
						},
				  ]
				: []
			).concat(
				res?.data?.map((el: any) => {
					return {
						value: el.numericId,
						label: el.name + ` (${el.id})`,
					};
				}),
			),
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	const filtersConfig = useMemo(
		() =>
			compact([
				{
					name: 'operatorIds',
					type: 'multiselect',
					options: operatorsList,
					value: filters.operatorIds?.map(id =>
						operatorsList.find(item => item.id === id),
					),
					placeholder: 'operators',
					styles: { width: 276 },
				},
				{
					name: 'repCustomerIds',
					loadOptions: getRepCustomerIdsData,
					value: repCustomerIdsFullDataRef.current?.map((d: any) => ({
						label: d.name,
						value: d.id,
					})),
					key: `${JSON.stringify(filters.unitIds)}`,
					type: 'asyncmultiselect',
					placeholder: 'customers',
					styles: { width: 276 },
				},
				businessUnitsList?.length > 1
					? {
							name: 'unitIds',
							type: 'select',
							placeholder: 'businessUnits',
							defaultValue: filters.unitIds?.[0],
							options: businessUnitsList,
							hideDefaultOption: true,
							styles: {
								width: 200,
							},
					  }
					: undefined,
			]),
		[
			operatorsList,
			businessUnitsList,
			filters.unitIds,
			filters.operatorIds,
			repCustomerIdsFullDataRef.current,
		],
	);

	return !displayCardMap ? null : (
		<View style={{ width: '100%', height: '100%' }}>
			<HeaderContainer
				containerStyle={{ backgroundColor: colors.white }}
				navigation={navigation}
				component={WebHeader}
			/>
			<ScrollView
				style={{
					backgroundColor: colors.white,
					marginTop: 84,
					marginHorizontal: 20,
					flex: 1,
					padding: 30,
				}}
			>
				<View
					style={{
						zIndex: 100,
						flexDirection: 'row-reverse',
						alignItems: 'flex-end',
					}}
				>
					<FiltersContainer
						config={filtersConfig}
						onChange={onFilter}
						customStyles={{
							container: {
								flexDirection: 'row-reverse',
							},
							filterContainer: {
								marginRight: 0,
								marginLeft: 15,
								zIndex: 3,
								flexDirection: 'row-reverse',
								alignItems: 'center',
							},
						}}
					/>
					<HideViewButtons
						displayCardMap={displayCardMap}
						setDisplayCardMap={onDisplayViewChange}
						canDisplay={canDisplay}
					/>
				</View>
				{displayCardMap.monthly && canDisplay.monthly && (
					<MonthlyData
						filters={dashboardFilter}
						customersFullDataJson={customersFullDataJson}
					/>
				)}
				<View
					style={{
						flexDirection: 'row-reverse',
						...Platform.select({ web: { gap: 24 } }),
					}}
				>
					{displayCardMap.employeeForm && canDisplay.employeeForm && (
						<Report101
							filters={dashboardFilter}
							customersFullDataJson={customersFullDataJson}
						/>
					)}
					{displayCardMap.workPaperDraft &&
						canDisplay.workPaperDraft && (
							<ReportFromMonthToMonth
								filters={dashboardFilter}
								customersFullDataJson={customersFullDataJson}
							/>
						)}
				</View>
				{displayCardMap.yearly && canDisplay.yearly && (
					<YearlyData
						filters={dashboardFilter}
						customersFullDataJson={customersFullDataJson}
					/>
				)}
			</ScrollView>
		</View>
	);
};

export default RepHomePage;
