import { useMemo } from 'react';
import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import {
	getEmployeeRolesPrivileges,
	getEmployerRolesPrivileges,
	getRepRolesPrivileges,
} from '../store/selectors/login.selectors';

export const useGetCurrentPrivilegs = () => {
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);
	const repRolesPrivileges = useSelector(getRepRolesPrivileges);

	const route = useRoute();
	const userPrivileges = useMemo(
		() =>
			route.name === 'employee'
				? employeeRolesPrivileges
				: route.name === 'representative'
				? repRolesPrivileges
				: employerRolesPrivileges,
		[
			route,
			employeeRolesPrivileges,
			employerRolesPrivileges,
			repRolesPrivileges,
		],
	);

	return userPrivileges;
};
