import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import httpService from '../../../services/http.service';
import WorklogReportsPage from './WorklogReportsPage.component';
import { modalService } from '../../../services/modal.service';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import { fetchEmployeesFilterList } from '../../../store/actions/reportsFilters.actions';
import moment from 'moment';
import { i18nService } from '../../../services/i18n.service';
import { compact, toNumber } from 'lodash';
import AddRequest from '../../../modals/AddRequestModal/AddRequestModal.container';
import { getUserData } from '../../../store/selectors/login.selectors';
import { usePrevious } from '../../../hooks/usePrevious.hook';
import { convertFromUtc } from './WorklogReportsPage.utils';
import { debounce } from 'lodash';
import { openAlertModal } from '../../../components/shared/ClockReport/Clock.utils';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import { Privileges } from '../../../constants/roleTypes.constant';
const getDefaultDateFilter = filter => {
	const currentDay = toNumber(moment().format('DD'));
	const currentYear = toNumber(moment().format('YYYY'));
	const currentMonth = toNumber(moment().format('MM'));
	switch (filter) {
		case 'year':
			if (currentDay >= 15) return currentYear;
			return moment().subtract(1, 'months').format('YYYY');
		case 'month':
			if (currentDay >= 15) return currentMonth;
			return toNumber(moment().subtract(1, 'months').format('MM'));
		default:
			return moment().format('YYYY-MM-DD');
	}
};
export interface tableData {
	workLogId: number;
	teudatZeut: string;
	fullName: string;
	departmentName: any;
	projectName: any;
	eventName: string;
	workLogDate: string;
	enterTime: string;
	exitTime: string;
	lastUpdate: string;
	total: number;
	updatedBy: string;
	updatedEnter: boolean;
	updatedExit: boolean;
	updatedProject: boolean;
	pendingStatus: string;
	businessUnitId: number;
}

const WorklogReportsPageContainer = ({
	backTohoursReportSummery,
	route,
	navigation,
	yearFilter,
	monthFilter,
}: {
	route: any;
	navigation: any;
	yearFilter: any;
	monthFilter: any;
	backTohoursReportSummery: () => void;
}) => {
	const monthMap = {
		1: 'months.January',
		2: 'months.February',
		3: 'months.March',
		4: 'months.April',
		5: 'months.May',
		6: 'months.June',
		7: 'months.July',
		8: 'months.August',
		9: 'months.September',
		10: 'months.October',
		11: 'months.November',
		12: 'months.December',
	};
	const [departments, setDepartments] = useState([]);
	const [tableData, setTableData] = useState<tableData[]>([]);
	const [totalRow, setTotalRow] = useState({});
	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('ASC');
	const [sortBy, setSortBy] = useState('teudatZeut');
	const [isSelectMode, setSelectMode] = useState<any>(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [events, setEvents] = useState([]);
	const [projects, setProjects] = useState([]);
	const [month, setMonth] = useState([]);
	const [year, setYear] = useState(...yearFilter);
	const [filters, setFilters] = useState({
		year: yearFilter,
		month: monthFilter
			.filter((month: any) => {
				return month.id !== 'all';
			})
			.map((month: any) => month.id),
		event: [],
		status: [],
		special: [],
		project: [],
		emp: [],
		from_date: [],
		to_date: [],
		unitIds: [],
	});

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.hoursReportSummary.privileges,
		onChangeFilter: ({ value, isMulti }: any) => {
			isFirstInit.current = true;
			setFilters((state: any) => ({
				...state,
				unitIds: isMulti
					? value.map((val: any) => val.id || val)
					: [value],
			}));
		},
	});

	const [resetEmpFilter, setResetEmpFilter] = useState(false);
	const [resetMonthFilter, setResetMonthFilter] = useState(false);
	const datePickerKey = useRef(true);
	const defaultSort = 'teudatZeut';
	const defaultOrder = 'ASC';
	const userData = useSelector(getUserData);
	const isFirstInit = useRef(true);
	const prevUserData = useRef(userData);
	const prevMonthFilter = usePrevious(filters.month);
	const prevMonthOptions = useRef(month.length);
	const isMonthSelected = useRef(false);

	useEffect(() => {
		if (
			!isFirstInit.current &&
			(filters.unitIds.length || multiValue) &&
			!isMonthSelected.current
		) {
			getData(true, defaultSort, defaultOrder);
		}
	}, [filters]);
	useEffect(() => {
		if (!isFirstInit.current && year) getMonth();
	}, [year]);
	useEffect(() => {
		if (!isFirstInit.current) {
			if (prevUserData.current === userData) {
				let isValidsMonthsChoosed = true;
				if (filters.month.length === 0) isValidsMonthsChoosed = false;
				else
					filters.month.forEach(singleMonth => {
						if (!month.includes(singleMonth))
							isValidsMonthsChoosed = false;
					});
				setFilters({
					...filters,
					year: [year],
					month: isValidsMonthsChoosed
						? [...filters.month]
						: month.includes(getDefaultDateFilter('month'))
						? [getDefaultDateFilter('month')]
						: month[month.length - 1]
						? [month[month.length - 1]]
						: [],
					from_date: [],
					to_date: [],
				});
			}
			setResetMonthFilter(!resetMonthFilter);
			isMonthSelected.current = false;
		}
	}, [month]);

	useEffect(() => {
		if (!isFirstInit.current) {
			setYear(getDefaultDateFilter('year'));
		}
		if (!isFirstInit.current) {
			datePickerKey.current = !datePickerKey.current;
			prevUserData.current = userData;
			setFilters({
				year: [year],
				month: month.includes(getDefaultDateFilter('month'))
					? [getDefaultDateFilter('month')]
					: month[month.length - 1]
					? [month[month.length - 1]]
					: [],
				event: [],
				status: [],
				special: [],
				project: [],
				emp: [],
				from_date: [],
				to_date: [],
				unitIds: [],
			});

			setResetEmpFilter(!resetEmpFilter);
			setResetMonthFilter(true);
		}
	}, [userData]);

	useEffect(() => {
		if (filters.unitIds.length || multiValue) {
			getEventsOptions();
			getProjectsOptions();
			getDepartmentsFilter();
			year && getMonth();
			isFirstInit.current = false;
		}
	}, [filters.unitIds]);

	useEffect(() => {
		const onlyPendingRows = tableData.filter(
			row => row.pendingStatus === 'ממתין לאישור',
		);

		if (selectedRows.length !== onlyPendingRows.length)
			setIsAllSelected(false);
		else if (selectedRows.length !== 0) setIsAllSelected(true);
	}, [selectedRows]);

	useEffect(() => {
		if (!isFirstInit.current && resetMonthFilter)
			setResetMonthFilter(false);
	}, [resetMonthFilter]);

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res = await fetchEmployeesFilterList('worklogReports', {
			page,
			pageSize: 20,
			search,
			filter: { dep: [] },
			unitIds: filters.unitIds?.length
				? filters.unitIds
				: businessUnitsList.map((unit: any) => unit.id),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: 20 === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};
	const getDepartmentsFilter = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getDepartmentsFilterList',
				query: {
					pageName: 'worklogReports',
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
			});
			if (res) {
				setDepartments(res);
			}
		} catch (e) {
			console.log(e);
		}
	};
	const filtersConfig1 = useMemo(() => {
		return [
			{
				name: 'employees',
				type: 'asyncmultiselect',
				placeholder: 'employees',
				loadOptions: getEmployeesFilter,
				styles: {
					width: 274,
				},
				key: `${resetEmpFilter}${JSON.stringify(filters.unitIds)}`,
			},
		];
	}, [userData, filters.unitIds]);

	const canEdit = useMemo(
		() =>
			!!getSelectedUnits(filters.unitIds).find((unit: number) =>
				hasPermissionOnBusinessUnit(unit, 'ROLE_LOG_WORK_REPORT_WRITE'),
			),
		[filters.unitIds],
	);

	const filtersConfig2 = useMemo(() => {
		const reRenderMonthFilter =
			prevMonthFilter?.length === prevMonthOptions.current &&
			(!month.length || filters.month.length !== month.length);

		const monthOptions = month.map(month => ({
			id: month,
			name: i18nService.translate(monthMap[month]),
		}));
		return compact([
			{
				name: 'status',
				options: [
					{ id: 'APPROVED', name: 'reportView.APPROVED' },
					{
						id: 'NO_APPROVAL_REQUIRED',
						name: 'reportView.NO_APPROVAL_REQUIRED',
					},
					{ id: 'PENDING_APPROVAL', name: 'ממתין לאישור' },
				],
				placeholder: 'status',
				styles: { width: 134 },
				type: 'multiselect',
				key: userData,
				alwaysRerender: true,
			},
			{
				name: 'special',
				options: [
					{ id: true, name: 'workerCard.editor.missingFields' },
				],
				placeholder: 'special',
				styles: { width: 134 },
				type: 'select',
				defaultValue: filters.special[0],
			},

			{
				name: 'department',
				options: departments,
				placeholder: 'department',
				styles: { width: 134 },
				type: 'multiselect',
				key: `${userData}${JSON.stringify(filters.unitIds)}`,
				alwaysRerender: true,
			},
			{
				name: 'event',
				options: events,
				placeholder: 'event',
				styles: { width: 134 },
				type: 'multiselect',
				key: `${userData}${JSON.stringify(filters.unitIds)}`,
				alwaysRerender: true,
			},
			{
				defaultValue: '',
				options: projects,
				name: 'project',
				placeholder: 'project',
				styles: { width: 134 },
				type: 'multiselect',
				key: `${userData}${JSON.stringify(filters.unitIds)}`,
				alwaysRerender: true,
			},
			{
				name: 'customDatepicker',
				type: 'customDatePicker',
				styles: {
					width: 140,
				},
				extraProps: {
					opens: 'left',
					key: datePickerKey.current,
				},
			},
			{
				name: 'year',
				type: 'datepicker',
				placeholder:
					filters.year.length !== 0
						? filters.year
						: i18nService.translate('filter.select.year'),
				showYearPicker: true,
				showQuarterYearPicker: false,
				value: filters.year,
				styles: {
					width: 140,
				},
			},
			{
				name: 'month',
				options: monthOptions,
				placeholder: 'month',
				styles: { width: 134 },
				type: 'multiselect',
				value: filters.month.map(month => ({
					id: month,
					name: i18nService.translate(monthMap[month]),
				})),
				alwaysRerender: reRenderMonthFilter,
			},
			businessUnitsList?.length > 1
				? isBusinessUnitsMulti
					? {
							name: 'unitIds',
							type: 'multiselect',
							placeholder: 'businessUnits',
							value: filters.unitIds?.map((unit: any) =>
								businessUnitsList.find(
									(bunit: any) => bunit.id === unit,
								),
							),
							options: businessUnitsList,
							styles: {
								control: {
									width: 200,
									maxWidth: 200,
								},
							},
					  }
					: {
							name: 'unitIds',
							type: 'select',
							placeholder: 'businessUnits',
							defaultValue: filters.unitIds?.[0],
							options: businessUnitsList,
							hideDefaultOption: true,
							styles: {
								width: 150,
							},
					  }
				: undefined,
		]);
	}, [
		events,
		projects,
		year,
		departments,
		filters,
		datePickerKey.current,
		userData,
		resetMonthFilter,
		prevMonthFilter,
	]);
	const getEventsOptions = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getEventNameList',
				query: {
					pageName: 'worklogReports',
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
			});
			if (res) {
				setEvents(res);
			}
		} catch (e) {}
	};
	const getProjectsOptions = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getProjectNameList',
				query: {
					pageName: 'worklogReports',
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
			});
			if (res) {
				setProjects(res);
			}
		} catch (e) {}
	};
	const getData = async (
		firstInit: boolean,
		newSortBy?: any,
		neworder?: string,
	) => {
		try {
			const res = await httpService.api<any>({
				type: 'getReportsSummery',
				data: {
					p: firstInit ? 0 : nextPage,
					ps: 20,
					s: newSortBy ? newSortBy : sortBy,
					sd: neworder ? neworder : order,
					f: {
						...filters,
						unitIds: getSelectedUnits(filters?.unitIds),
					},
				},
			});

			if (res) {
				if (res.totalRow || firstInit) setTotalRow(res.totalRow || {});
				setDisableLoad(res.data.length < 20 ? true : false);

				const data = res.data?.map((item: any, index: number) => ({
					...item,
					localId: `${index}-${Date.now()}`,
				}));
				if (firstInit) {
					setTableData(data);
					setNextPage(1);
				} else {
					setTableData([...tableData, ...data]);
					setNextPage(nextPage + 1);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};
	const getMonth = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getMonthOfHoursReportSummery',
				query: {
					year,
					unitIds: getSelectedUnits(filters.unitIds).join(),
				},
			});
			if (res) {
				prevMonthOptions.current = month.length;
				setMonth(res);
			}
		} catch (e) {}
	};
	const onSort = (newSort: string) => {
		let newOrder = order;
		if (newSort === sortBy) {
			newOrder = order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			newOrder = 'DESC';
		}
		setOrder(newOrder);
		setSortBy(newSort);
		getData(true, newSort, newOrder);
	};
	const onFilter = (filterData: any) => {
		let chosenFilters: any = {};
		let isYearChanged = false;
		Object.keys(filters).forEach(key => {
			if (key === 'year' || key === 'from_date' || key === 'to_date')
				chosenFilters[key] = filters[key];
			else chosenFilters[key] = [...filters[key]];
		});
		const key = filterData.name;
		switch (key) {
			case 'unitIds':
				isFirstInit.current = true;
				chosenFilters = {
					...chosenFilters,
					unitIds: filterData.isMulti
						? filterData.value.map((val: any) => val.id || val)
						: [filterData.value],
					project: [],
					event: [],
					dep: [],
					emp: [],
				};
				break;
			case 'customDatepicker':
				chosenFilters.from_date = [filterData.value.start];
				chosenFilters.to_date = [filterData.value.end];
				chosenFilters.year = [];
				chosenFilters.month = [];
				setYear(null);
				break;
			case 'year':
				isYearChanged = true;
				const chosenYear = moment(filterData.value).year().toString();
				datePickerKey.current = !datePickerKey.current;
				chosenFilters.from_date = [];
				chosenFilters.to_date = [];
				setYear(chosenYear);

				break;
			case 'department':
				chosenFilters.dep = filterData.value.map(dep => dep.id);
				break;
			case 'employees':
				chosenFilters.emp = filterData.value.map(emp => emp.id);
				break;
			case 'project':
				if (filterData.isAllSelected) {
					chosenFilters.project = projects.map(project => project.id);
					break;
				}
				if (filterData.value.length === 0) {
					chosenFilters.project = [];
					break;
				}
				chosenFilters.project = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
			case 'event':
				if (filterData.isAllSelected) {
					chosenFilters.event = events.map(event => event.id);
					break;
				}
				if (filterData.value.length === 0) {
					chosenFilters.event = [];
					break;
				}
				chosenFilters.event = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
			case 'month':
				chosenFilters.from_date = [];
				chosenFilters.to_date = [];
				datePickerKey.current = !datePickerKey.current;

				if (filterData.isAllSelected) {
					chosenFilters[key] = month.map(month => month);
					break;
				}
				chosenFilters[key] = filterData.value.map(month => month.id);
				isMonthSelected.current = true;
				break;
			default:
				if (filterData.name === 'special')
					chosenFilters['special'] =
						filterData.value === undefined
							? []
							: [filterData.value];
				else {
					chosenFilters[filterData.name] = filterData.value.map(
						valueObj => valueObj.id,
					);
				}
				break;
		}
		if (!isYearChanged) setFilters(chosenFilters);
	};
	const handleRowSelect = ({ workLogId }) => {
		const indexOfRow = selectedRows.indexOf(workLogId);
		if (indexOfRow === -1)
			setSelectedRows(selectedRows => [...selectedRows, workLogId]);
		else {
			const tempSelectedRows = [...selectedRows];
			tempSelectedRows.splice(indexOfRow, 1);
			setSelectedRows(tempSelectedRows);
		}
	};

	const handleSelectAll = (isChecked: boolean) => {
		const tempRows: any = [];
		const onlyPendingRows = tableData.filter(
			row =>
				row.pendingStatus === 'ממתין לאישור' &&
				hasPermissionOnBusinessUnit(
					row.businessUnitId,
					Privileges.ROLE_LOG_WORK_REPORT_WRITE,
				),
		);
		if (isChecked) {
			onlyPendingRows.forEach(row => tempRows.push(row.workLogId));
			setSelectedRows(tempRows);
		} else setSelectedRows([]);

		setIsAllSelected(isChecked);
	};

	const approveSelected = async () => {
		if (selectedRows.length === 0) {
			setSelectMode(false);
			return;
		}
		setIsAllSelected(false);
		setSelectedRows([]);
		setSelectMode(false);
		try {
			const res = await httpService.api<any>({
				type: 'approveReport',
				data: {
					f: {
						...filters,
						unitIds: getSelectedUnits(filters?.unitIds),
					},
					workLogIds: isAllSelected ? [] : [...selectedRows],
				},
			});
			getData(true, sortBy, order);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDelete = async ({ workLogId: id, lockReport }: any) => {
		if (lockReport) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else
			await modalService.openModal(
				null,

				{
					onCancel: (callback: Function) => {
						callback();
					},

					onSubmit: async (callback: Function) => {
						try {
							const res = await httpService.api<any>({
								type: 'deleteEmployeeReport',
								params: { workLogId: id },
							});
							getData(true, defaultSort, defaultOrder);
						} catch (error) {
							console.log(error);
						}
						callback();
					},

					submitBtnText: 'general.true',

					cancelBtnText: 'general.false',

					iconName: 'question',
				},

				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>general.shouldDeleteItem</I18n>
					</ModalConfirmActions>
				),
			);
	};

	const handleEdit = async ({
		workLogId: id,
		profileAid,
		fullName,
		workLogDate: date,
		businessUnitId,
		businessUnitName,
		lockReport,
	}: any) => {
		if (lockReport) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else {
			let logValues = {};
			if (id) {
				logValues = await httpService.api<any>({
					type: 'workLogByIdManager',
					params: { workLogId: id },
				});
			}

			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},

					onSubmit: async (callback: Function) => {
						getData(true, sortBy, order);
						callback();
					},
					shiftHours: userData?.customer_setting.maxShiftHour,
					submitBtnText: 'general.accept',
					route: route,
					cancelBtnText: 'general.cancel',
					isEdit: id ? true : false,
					openEditModal: handleEdit,
					workLogId: id,
					isManager: true,
					businessUnitId,
					businessUnitName,
					prevValues: id
						? {
								...logValues,
								originalExitEntry: {
									entryTime: logValues.entryTime,
									exitTime: logValues.exitTime,
								},
								entryTime: convertFromUtc(
									logValues.entryTime,
									true,
								),
								exitTime: convertFromUtc(
									logValues.exitTime,
									true,
								),
								employee: {
									id: logValues.employeeId,
									name: logValues.fullName,
								},
						  }
						: profileAid
						? {
								employee: {
									id: profileAid,
									name: fullName,
								},
								fullName,
								date,
						  }
						: null,
				},
				(props: any) => <AddRequest {...props} />,
			);
		}
	};

	const handleAddNewLog = async () => {
		const allowedBusinessUnitsList = businessUnitsList.filter(unit =>
			hasPermissionOnBusinessUnit(
				unit.id,
				Privileges.ROLE_LOG_WORK_REPORT_WRITE,
			),
		);
		const allowedSelectedUnitIds = filters.unitIds.filter(unit =>
			hasPermissionOnBusinessUnit(
				unit,
				Privileges.ROLE_LOG_WORK_REPORT_WRITE,
			),
		);

		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},

				onSubmit: async (callback: Function) => {
					getData(true, sortBy, order);
					callback();
				},
				shiftHours: userData?.customer_setting.maxShiftHour,
				isManager: true,
				submitBtnText: 'general.accept',
				route: route,
				cancelBtnText: 'general.cancel',
				isEdit: false,
				openEditModal: handleEdit,
				unitIds: allowedSelectedUnitIds,
				businessUnitsList: allowedBusinessUnitsList,
			},
			(props: any) => <AddRequest addNew {...props} />,
		);
	};

	const debouncedGetMoreData = debounce(() => {
		getData(false);
	}, 300);

	return (
		<WorklogReportsPage
			navigation={navigation}
			backTohoursReportSummery={backTohoursReportSummery}
			data={tableData}
			totalData={totalRow}
			onSort={onSort}
			onLoadMore={() => (disabledLoad ? null : debouncedGetMoreData())}
			onFilter={onFilter}
			filtersConfig1={filtersConfig1}
			filtersConfig2={filtersConfig2}
			refresh={() => getData(true, defaultSort, defaultOrder)}
			reqForSelect={() => setSelectMode(true)}
			isSelectMode={isSelectMode}
			selectedRows={selectedRows}
			isAllSelected={isAllSelected}
			onSelectAll={handleSelectAll}
			onSelect={handleRowSelect}
			onConfirmSelect={approveSelected}
			onCancelSelect={() => {
				setIsAllSelected(false);
				setSelectedRows([]);
				setSelectMode(false);
			}}
			onEdit={handleEdit}
			onDelete={handleDelete}
			onAddNewLog={handleAddNewLog}
			canEdit={canEdit}
			worklogReportFormat={userData?.customer_setting.worklogReportFormat}
			businessUnitsList={businessUnitsList}
		/>
	);
};

export default WorklogReportsPageContainer;
