import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import LeftDrawer from '../../../../components/shared/Drawers/LeftDrawer';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import CommentsAndErrors from '../CommentsAndErrors/CommentsAndErrors';

const Wrapper = ({
	children,
	year,
	missingFields,
	setIsDrawerOpen,
	comments,
	isEmployee,
	goToCommentScreen,
	removeCommentFromChildrenTab,
	goToFieldScreen,
	isMobile,
	removeComment,
	screen,
	selectedEmplName,
	setOpenDrawerFunc,
	icon,
	readOnly,
}: any) => {
	const platform = useDevicePlatform();
	const isDesktop = platform === DeviceType.WEB;
	const [isSmallDimensions, setIsSmallDimensions] = useState(
		window.innerWidth <= 1600,
	);

	const onResize = () => {
		setIsSmallDimensions(window.innerWidth <= 1600);
	};

	useEffect(() => {
		isDesktop && window.addEventListener('resize', onResize);
		return () => {
			isDesktop && removeEventListener('resize', onResize);
		};
	}, []);

	return isDesktop ? (
		<>
			<View
				style={{
					paddingTop: 16,
					paddingRight: isSmallDimensions ? 10 : 21,
					flex: 1,
					height: '100%',
				}}
			>
				<View style={styles.tableHeader} nativeID='#tableHeader'>
					<View
						style={{
							// flexDirection: 'row-reverse',
							// alignItems: 'center',
							paddingLeft: 27,
							paddingRight: isSmallDimensions ? 11 : 0,
						}}
					>
						<I18n
							size={36}
							weight='normal'
							style={{ flex: 1, marginBottom: 22 }}
						>
							workerCard.editor.title
						</I18n>

						<View
							style={{
								flexDirection: 'row-reverse',
								// alignItems: 'center',
							}}
						>
							{selectedEmplName && (
								<>
									<Typography
										size={22}
										weight='bold'
										style={{ marginLeft: 5 }}
									>
										{`${selectedEmplName}`}
									</Typography>

									<Typography
										size={16}
										weight='normal'
										style={{
											marginLeft: 5,
											alignSelf: 'center',
										}}
									>
										|
									</Typography>
								</>
							)}

							<I18n size={22} weight='normal'>
								workerCard.editor.subtitle
							</I18n>
							<Typography size={22} weight='normal'>
								{year}
							</Typography>
						</View>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						flex: 1,
					}}
				>
					<LeftDrawer
						icon={icon}
						setIsDrawerOpen={setIsDrawerOpen}
						setOpenDrawerFunc={setOpenDrawerFunc}
					>
						<View
							style={{
								width: 208,
								paddingHorizontal: 5,
								flex: 1,
								height: '100%',
								backgroundColor: colors.brightBlue,
								overflowY: 'auto',
								...getShadowStyle(30, '#3b70a4'),
							}}
						>
							<CommentsAndErrors
								readOnly={readOnly}
								comments={comments}
								missingFields={missingFields}
								isMobile={isMobile}
								removeComment={removeComment}
								goToCommentScreen={goToCommentScreen}
								isEmployee={isEmployee}
								goToFieldScreen={goToFieldScreen}
								removeCommentFromChildrenTab={
									removeCommentFromChildrenTab
								}
							/>
						</View>
					</LeftDrawer>

					{children}
				</View>
			</View>
		</>
	) : (
		<View style={{ flex: 1 }}>
			<View style={styles.headerText}>
				<View
					style={{
						flexDirection: 'row-reverse',
						// alignItems: 'center',
					}}
				>
					<I18n size={14} weight='normal'>
						workerCard.editor.subtitle
					</I18n>
					<Typography size={14} weight='normal'>
						{year}
					</Typography>
				</View>
				<I18n
					style={styles.title}
					size={28}
					weight='normal'
					color='darkGrey'
				>
					workerCard.editor.title
				</I18n>
			</View>
			{children}
		</View>
	);
};

const styles = StyleSheet.create({
	tableHeader: {
		width: '100%',
		zIndex: 100,
		marginBottom: 31,
	},
	title: {
		marginLeft: 10,
	},
	headerText: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'baseline',
		backgroundColor: colors.white,
		paddingRight: 18,
	},
});

export default Wrapper;
