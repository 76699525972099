import React, { useEffect, useMemo, useState } from 'react';
import {
	useWindowDimensions,
	View,
	Dimensions,
	StyleSheet,
} from 'react-native';
import FiltersContainer from '../../../components/web/Filters/Filters.container';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import ModalContainer from '../../../components/shared/Modal/Modal.container';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { Breadcrumbs, Button, Link, makeStyles } from '@material-ui/core';
import CreateUserModal from '../CreateUpdateUser/CreateAndUpdateUserWeb/CreateUserModal/CreateUserModal.container';
import UsersTablePageNative from './UsersTablePage.component.native';
import { i18nService } from '../../../services/i18n.service';
import I18n from '../../../components/shared/i18n/I18n.component';
import AddIcon from '@material-ui/icons/Add';
import colors from '../../../config/colors';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { modalService } from '../../../services/modal.service';
import UpdateMultiplePermisionsModal from '../../../modals/web/UpdateMultiplePermisionsModal/UpdateMultiplePermisionsModal.component';
import { initialHeaders } from './UsersTablePage.constants';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import { Privileges } from '../../../constants/roleTypes.constant';

type UsersTableProps = {
	navigation: any;
	onFilter: (data: any) => void;
	filtersData: any;
	data: any[];
	onSort: (field: string, direction: string) => void;
	getExcel: () => void;
	getNextData: () => void;
	errorMessage?: string;
	goBack: () => void;
	isRolesPageOpened: boolean;
	modalOpened: boolean;
	modalData: any;
	goToRolesPage: () => void;
	route: any;
	onModalBtnPressedHandler: () => void;
	userData: any;
	updateUsers: () => void;
	updateRow: (data: any) => void;
	selectedFilters: any;
	refreshPage: () => void;
	businessUnitsList: any[];
	selectedFiltersForMobile: any;
	getSelectedUnits: any;
	docPermission: boolean;
	showCreateUserBtn: boolean;
	modules: any;
	employerRolePrivileges: any;
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			padding: '0!important',
			minWidth: '20px',

			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		linkMain: {
			color: ({ isRolesPageOpened }: any) =>
				isRolesPageOpened ? colors.primary : colors.darkGrey,
			cursor: ({ isRolesPageOpened }: any) =>
				isRolesPageOpened ? 'pointer' : 'auto',
		},
		mainHeader: {
			fontFamily: 'RubikMedium',
			fontSize: 36,
			color: colors.darkGrey,
		},
		mainHeaderWrapper: {
			display: 'flex',
			flexDirection: 'row-reverse',
			justifyContent: 'space-between',
			width: '100%',
		},
		questionButton: {
			color: colors.primary,
			padding: '0!important',
			position: 'relative',
			left: '-27px',

			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		questionButtonText: {
			marginRight: '7px',
		},
		buttonsContainer: {
			display: 'flex',
			flexDirection: 'row-reverse',
		},
		addIcon: {
			marginLeft: 2,
			marginTop: 2,
		},
	};
});

const styles = StyleSheet.create({
	actionButton: {
		width: 160,
		height: 40,
		padding: 0,
		marginLeft: 15,
	},
	smallActionButton: {
		width: 160,
		height: 40,
		padding: 0,
		marginLeft: 8,
	},
});

const UsersTablePage = ({
	navigation,
	userData,
	data,
	filtersData,
	onSort,
	onFilter,
	getNextData,
	getExcel,
	errorMessage,
	isRolesPageOpened,
	modalOpened,
	modalData,
	goToRolesPage,
	route,
	onModalBtnPressedHandler,
	updateUsers,
	updateRow,
	selectedFilters,
	refreshPage,
	selectedFiltersForMobile,
	businessUnitsList,
	getSelectedUnits,
	docPermission,
	showCreateUserBtn,
	modules,
	employerRolePrivileges,
}: UsersTableProps) => {
	const { height } = useWindowDimensions();
	const classes = useStyles(isRolesPageOpened);

	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [selectAll, setSelectAll] = useState(false);

	const platform = useDevicePlatform();
	const isMobileWeb = platform === DeviceType.MOBILE_WEB;

	const showComprehensivePermissionUpdateBtn = useMemo(() => {
		if (modules) {
			const modulesForUpdate = Object.keys(modules).find(key =>
				modules[key]?.find(
					(module: any) =>
						module.id === 113 ||
						module.id === 101 ||
						module.id === 102,
				),
			);

			if (modulesForUpdate) {
				return true;
			}
			return false;
		}
		return false;
	}, [modules]);

	const { businessUnitsList: businessUnitsListWrite } = useBusinessUnitsState(
		{
			privileges: [
				Privileges.ROLE_MGM_USER_WRITE,
				Privileges.ROLE_MGM_USER_READ,
				Privileges.ROLE_CREATE_USER_WRITE,
				Privileges.ROLE_CREATE_USER_READ,
			],
		},
	);

	const onRowClick = (rowData: any) => {
		modalService.openModal(
			null,
			{
				...rowData,
				callback: updateRow,
				businessUnitsList: businessUnitsListWrite,
				showLoader: true,
			},
			CreateUserModal,
		);
	};

	const onCreateUserButtonClick = async () => {
		modalService.openModal(
			null,
			{
				callback: updateUsers,
				businessUnitsList: businessUnitsListWrite,
				showLoader: true,
			},
			CreateUserModal,
		);
	};
	const startRefresh = () => {
		setSelectedItems([]);
		setSelectAll(false);
		refreshPage();
	};

	const onUpdateMultiple = async () => {
		await modalService.openModal(
			null,
			{
				selectAll,
				selectedItems,
				selectedFilters: {
					...selectedFilters,
					unitIds: getSelectedUnits(selectedFilters?.unitIds),
				},
				refreshPage: () => startRefresh(),
			},
			UpdateMultiplePermisionsModal,
		);
	};

	const onSelectAll = val => {
		setSelectAll(val);
		setSelectedItems([]);
	};

	const onRowsChecked = (v, id, row) => {
		let newData = [...selectedItems];
		if (v) {
			newData.push(row.original.profileId);
		} else {
			newData = newData.filter(el => el !== row.original.profileId);
		}
		setSelectedItems(newData);
	};

	useEffect(() => {
		if (route?.params?.isFromDashboard) {
			onCreateUserButtonClick();
			navigation.setParams({ isFromDashboard: undefined });
		}
	}, [route.params]);

	return isMobileWeb ? (
		<UsersTablePageNative
			navigation={navigation}
			filtersData={filtersData}
			onFilter={onFilter}
			data={data}
			getNextData={getNextData}
			userData={userData}
			selectedFilters={selectedFilters}
			selectedFiltersForMobile={selectedFiltersForMobile}
			businessUnitsList={businessUnitsList}
			docPermission={docPermission}
			showCreateUserBtn={showCreateUserBtn}
			modules={modules}
		/>
	) : (
		<>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<div className={classes.mainHeaderWrapper}>
						<div className={classes.mainHeader}>
							{i18nService.translate(
								'usersManagement.table.title',
							)}
						</div>
						{(userData?.employerRolePrivileges?.includes(
							'ROLE_MGM_USER_READ',
						) ||
							userData?.employerRolePrivileges?.includes(
								'ROLE_MGM_USER_WRITE',
							)) && (
							<Button
								classes={{
									root: classes.questionButton,
								}}
								onClick={goToRolesPage}
							>
								<span className={classes.questionButtonText}>
									{i18nService.translate(
										'usersManagement.table.permissionsButton',
									)}
								</span>
								<ImageIcon
									name='questionMark'
									style={{
										height: 14,
										width: 14,
										marginTop: '2px',
									}}
								/>
							</Button>
						)}
					</div>
				}
			>
				<View
					style={{
						maxHeight: height - 241,
						minHeight: height - 241,
					}}
				>
					<View
						style={{
							marginBottom: 31,
							zIndex: 3,
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
						}}
					>
						<FiltersContainer
							hideSearchInput
							config={filtersData}
							onChange={onFilter}
							customStyles={{
								container: {
									flexDirection: 'row-reverse',
								},
								filterContainer: {
									marginRight: 0,
									marginLeft: 15,
									zIndex: 3,
								},
							}}
						/>
						<div className={classes.buttonsContainer}>
							{showComprehensivePermissionUpdateBtn && (
								<SolidButtonContainer
									overrideStyle={{ marginLeft: 10 }}
									onPress={onUpdateMultiple}
									disabled={
										selectedItems.length === 0 && !selectAll
									}
								>
									<I18n color='white'>
										usersManagement.updateMultiple
									</I18n>
								</SolidButtonContainer>
							)}
							{showCreateUserBtn && (
								<SolidButtonContainer
									overrideStyle={{
										justifyContent: 'center',
										paddingVertical: 4,
										paddingRight: 11,
										marginLeft: 10,
									}}
									onPress={onCreateUserButtonClick}
								>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											paddingTop: 1,
										}}
									>
										<AddIcon
											classes={{ root: classes.addIcon }}
										/>
										<I18n color='white'>
											createUserButton.title
										</I18n>
									</View>
								</SolidButtonContainer>
							)}
							<Button
								classes={{ root: classes.root }}
								onClick={getExcel}
							>
								<ImageIcon name='excel' />
							</Button>
						</div>
					</View>

					<TableContainer
						getNextData={getNextData}
						// onRowClick={onRowClick}
						headers={initialHeaders(
							selectAll,
							onSelectAll,
							onRowsChecked,
							selectedItems,
							onRowClick,
							businessUnitsList,
							employerRolePrivileges,
							docPermission,
							modules,
							showComprehensivePermissionUpdateBtn,
						)}
						data={data || []}
						count={15}
						keyName={''}
						onSort={onSort}
						hasFooter={false}
						errorMessage={errorMessage}
						resetActiveSortByProps={userData.id}
					/>
				</View>
			</ReportsPageWrapper>
			<ModalContainer
				visible={modalOpened}
				component={CreateUserModal}
				data={modalData}
				onNext={() => onModalBtnPressedHandler()}
				route={route}
			/>
		</>
	);
};

export default UsersTablePage;
