import React from 'react';
import { View } from 'react-native';
import ButtonsGroupe from '../FieldBuilder/ButtonsGroup/ButtonsGroup.component';
import I18n from '../i18n/I18n.component';

interface dataProps {
	disabled?: boolean;
	name: string;
	title?: string | null;
	options: any;
	onChange: Function;
	value: string;
	isMobile: boolean | undefined;
	width?: number;
	direction?: string | undefined;
	forceMargin?: boolean;
}

const SelectGroup = ({
	disabled = false,
	name,
	title = null,
	options,
	onChange,
	value,
	isMobile = false,
	width,
	direction = 'row-reverse',
	forceMargin,
}: dataProps) => {
	return (
		<>
			{!disabled ? (
				<View
					style={{
						flexDirection: isMobile ? 'column' : direction,
						alignItems: 'center',
						justifyContent: isMobile ? 'center' : 'space-between',
						marginTop: !forceMargin && isMobile ? 0 : 20,
						width: width ? (isMobile ? '100%' : width) : '100%',
					}}
				>
					{title && (
						<I18n
							size={14}
							weight={isMobile ? 'bold' : 'normal'}
							numberOfLines={isMobile ? 2 : 1}
							style={{
								width: isMobile ? '100%' : width * 2.5,
								lineHeight: 20,
								textAlign: isMobile ? 'center' : 'right',
								paddingBottom: isMobile ? 10 : undefined,
							}}
						>
							{title}
						</I18n>
					)}
					<View
						style={{
							marginTop: isMobile ? 5 : 0,
							marginBottom: isMobile ? 10 : 0,
							// alignItems: 'center',
							// justifyContent: 'center',
							width: isMobile ? '100%' : 'auto',
						}}
					>
						<ButtonsGroupe
							name={name}
							options={options}
							onChange={onChange}
							value={value}
						/>
					</View>
				</View>
			) : null}
		</>
	);
};

export default SelectGroup;
