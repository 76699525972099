import React from 'react';
import Filters from './Filters.component';

export type FilterTypes =
	| 'searchInput'
	| 'multiselect'
	| 'select'
	| 'datepicker'
	| 'asyncmultiselect'
	| 'customDatePicker'
	| 'checkbox';

export interface FilterData {
	name: string;
	value: any;
	isMulti?: boolean;
}

interface FiltersContainerProps {
	config: {
		type: FilterTypes;
		name: string;
		options: { id: string; name: string }[];
		placeholder?: string;
		styles?: { width: number };
		key?: string;
		// onSearchTextChange?: (text: string) => void;
	}[];
	customStyles?: {
		container: any;
		filterContainer: any;
		otherFiltersWrapper: any;
	};
	resetActiveSortByProps?: [];
	hideSearchInput?: boolean;
	onChange: (data: FilterData) => void;
	onSearchTextChange?: (data: string) => void; //for auctocomplete filter
	onSelection?: (id: any) => void; //for auctocomplete filter
}

const FiltersContainer = ({
	config,
	onChange,
	onSearchTextChange,
	onSelection,
	customStyles,
	hideSearchInput,
	resetActiveSortByProps,
}: FiltersContainerProps) => {
	const sendFilterHandler = (data: FilterData) => {
		onChange && onChange(data);
	};

	return (
		<Filters
			customStyles={customStyles}
			config={config}
			resetActiveSortByProps={resetActiveSortByProps}
			onChange={sendFilterHandler}
			onSearchTextChange={onSearchTextChange}
			onSelection={onSelection}
			hideSearchInput={hideSearchInput}
		/>
	);
};

export default FiltersContainer;
