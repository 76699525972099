import moment from 'moment';
import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { i18nService } from '../../../services/i18n.service';
import { CheckBox } from '../../EmplooyeeRequests/EmplooyeeRequests.utils';
import { toNumber } from 'lodash';
export const getDefaultDateFilter = (filter) => {
	const currentDay = toNumber(moment().format("DD"));
	const currentYear = toNumber(moment().format("YYYY"));
	const currentMonth = toNumber(moment().format("MM"));
	switch (filter) {
	  case "year":
		if (currentDay >= 15) return currentYear;
		return moment().subtract(1, "months").format("YYYY");
	  case "month":
		if (currentDay >= 15) return currentMonth;
		return toNumber(moment().subtract(1, "months").format("MM"));
	  default:
		return moment().format("YYYY-MM-DD");
	}
  };
export const monthMap = {
    1: "months.January",
    2: "months.February",
    3: "months.March",
    4: "months.April",
    5: "months.May",
    6: "months.June",
    7: "months.July",
    8: "months.August",
    9: "months.September",
    10: "months.October",
    11: "months.November",
    12: "months.December",
  };
  const convertMinutesToHours = (value:any , format?:string) => {
	if (value) {
		if(format!=="DECIMAL")
			{
				const hours = `${Math.floor(value/60)?.toLocaleString()}`
				const minutes = value%60!==0? (value%60)<10?`0${value%60}`:`${value%60}`:"00"
				return `${hours}:${minutes}`
			}
		return `${(value/ 60).toLocaleString(undefined, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
	  })}`;
	}
	return '';
};
export const convertFromUtc = (time:string ,getHoursAndMinutes:boolean , timeFormat?:string) => {
	if(!time) return null
	const format = getHoursAndMinutes?"HH:mm":"'YYYY-MM-DD HH:mm'"
	const utcTime = moment.utc(time,timeFormat?timeFormat:'YYYY-MM-DDTHH:mm');
	const local = moment(utcTime).local().format(format);
	return local
	
}
const headers = (
	total,
	onEdit:Function,
	onDelete: Function,
	canEdit,
	worklogReportFormat
) =>{
	const readOnlyHeaders =  [
		{
			accessor: 'projectName',
			Header: i18nService.translate('EmployeeHoursReport.table.project'),
			isSortable: true,
			Footer: i18nService.translate('EmployeeHoursReport.table.total'),
		},
		{
			accessor: 'eventName',
			Header: i18nService.translate('EmployeeHoursReport.table.event'),
			isSortable: true,
		},
		{
			accessor: 'workLogDate',
			Header: i18nService.translate('EmployeeHoursReport.table.date'),
			isSortable: true,
			CustomCell: ({ value, row }) =>
				value ? moment(value).format('DD/MM/YYYY') : '',
		},
		{
			accessor: 'enterTime',
			Header: i18nService.translate('EmployeeHoursReport.table.entry'),
			isSortable: false,
			CustomCell: ({ value, row }) => convertFromUtc(value,true)
		},
		{
			accessor: 'exitTime',
			Header: i18nService.translate('EmployeeHoursReport.table.exit'),
			isSortable: false,
			CustomCell: ({ value, row }) => convertFromUtc(value,true)
		},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.lastUpdatedAt',
			),
			isSortable: true,
			CustomCell: ({ value, row }) =>
				value ? moment(value).format('DD/MM/YYYY') : '',
		},
		{
			accessor: 'total',
			Header: i18nService.translate('EmployeeHoursReport.table.total'),
			isSortable: true,
			CustomCell: ({ value, row }) =>convertMinutesToHours(value,worklogReportFormat),
			Footer: () => ` ${total?`(${(total / 60).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })})`:""} ${convertMinutesToHours(total,"HH:mm")} `
		},
		{
			accessor: 'updatedBy',
			Header: i18nService.translate('EmployeeHoursReport.table.updatedBy'),
			isSortable: true,
		},
		{
			accessor: 'updatedEnter',
			Header: i18nService.translate('EmployeeHoursReport.table.manualEntry'),
			isSortable: true,
		},
		{
			accessor: 'updatedExit',
			Header: i18nService.translate('EmployeeHoursReport.table.manualExit'),
			isSortable: true,
		},
		{
			accessor: 'updatedProject',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.manualProjectChange',
			),
			isSortable: true,
		},
		{
			accessor: 'pendingStatus',
			Header: i18nService.translate(
				'EmployeeHoursReport.table.confirmStatus',
			),
			isSortable: true,
		}]
	const writeHeaders = [	{
		accessor: 'edit',
		Header: i18nService.translate('EmployeeHoursReport.table.edit'),
		isSortable: false,
		CustomCell: ({ value, row }) => (
			<TouchableOpacity
				disabled={false}
				style={{ opacity: false ? 0.7 : 1 }}
				onPress={() => onEdit(row.original.workLogId , row.original.profileAid , row.original.fullName , row.original.workLogDate )}
			>
				<ImageIcon name='edit' />
			</TouchableOpacity>
		),
	},
	{
		accessor: 'delete',
		Header: i18nService.translate('EmployeeHoursReport.table.delete'),
		isSortable: false,
		CustomCell: ({ value, row }) =>
			row.original.workLogId ? (
				<TouchableOpacity
					disabled={false}
					style={{ opacity: false ? 0.7 : 1 }}
					onPress={() => onDelete(row.original.workLogId,row.original.workLogDate)}
				>
					<ImageIcon name='delete' />
				</TouchableOpacity>
			) : null,
}]

return canEdit? readOnlyHeaders.concat(writeHeaders) : readOnlyHeaders
}

;

export const Columns = (
	total: number,
	onDelete: (rowId: number) => {},
	onEdit: (rowId: number) => {},
	canEdit:boolean,
	worklogReportFormat:any,
) => {
	return  headers(total, onEdit, onDelete,canEdit,worklogReportFormat);
};
