import { useRoute } from '@react-navigation/native';
import React, { useContext, useMemo } from 'react';
import { Platform } from 'react-native';
import { goBack } from '../../../../../utils/goBack.utils';
import CreateUpdateCustomerWrapper from '../../RolesSection/CustomersManagment/CustomerManagmentWrapper.container';
import { UserEditorContext } from '../userEditorContext';
import CustomerManagement from './CustomersManagement.component';

export interface CustomerManagementModalProps {
	id: number;
	onNext: () => void;
	route: any;
	addCustomer: () => void;
	disabledSubmit: boolean;
	customersInfo: any;
	setCustomersInfo: () => void;
	isAdmin: boolean;
	role: number;
	openModal: () => void;
	unitId: number;
	onSubmit: any;
	data: any;
}

const CustomerManagementContainer = ({ navigation }: any) => {
	const route: any = useRoute();
	const { setUpdatedCustomers } = useContext(UserEditorContext);

	const urlParams: any = useMemo(
		() =>
			Platform.OS === 'web' &&
			new URL((document as any).location).searchParams,
		[Platform.OS === 'web' && document.location],
	);

	const id = useMemo(() => {
		return (
			route.params?.id ||
			route.params?.params?.id ||
			(Platform.OS === 'web' && urlParams?.get('id'))
		);
	}, [route.params?.id, route.params?.params?.id, urlParams]);

	const unitId = useMemo(() => {
		return (
			route.params?.unitId ||
			route.params?.params?.unitId ||
			(Platform.OS === 'web' && urlParams?.get('unitId'))
		);
	}, [route.params?.unitId, route.params?.params?.unitId, urlParams]);

	const data = useMemo(() => {
		return (
			route.params?.data ||
			route.params?.params?.data ||
			(Platform.OS === 'web' && urlParams?.get('data'))
		);
	}, [route.params?.data, route.params?.params?.data, urlParams]);

	const isAdmin = useMemo(() => {
		return (
			route.params?.isAdmin ||
			route.params?.params?.isAdmin ||
			(Platform.OS === 'web' && urlParams?.get('isAdmin'))
		);
	}, [route.params?.isAdmin, route.params?.params?.isAdmin, urlParams]);

	const disabled = useMemo(() => {
		return (
			route.params?.disabled ||
			route.params?.params?.disabled ||
			(Platform.OS === 'web' && urlParams?.get('disabled'))
		);
	}, [route.params?.disabled, route.params?.params?.disabled, urlParams]);

	const onSubmit = (data: any) => {
		setUpdatedCustomers?.({ data: { [id]: data }, unitId, roleId: id });
	};

	const onNext = () => {
		goBack(navigation);
	};

	return (
		<CreateUpdateCustomerWrapper
			Component={CustomerManagement}
			{...{
				id,
				unitId,
				onNext,
				onSubmit,
				data,
				isAdmin,
				disabled,
			}}
		/>
	);
};

export default CustomerManagementContainer;
