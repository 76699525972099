import React, { useEffect, useRef } from 'react';
import {
	TouchableHighlight,
	View,
	Image,
	Platform,
	PermissionsAndroid,
	Dimensions,
} from 'react-native';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import { useActionSheet } from '@expo/react-native-action-sheet';
import DocumentPicker from 'react-native-document-picker';
import { PDFDocument } from 'pdf-lib';

import colors from '../../../../config/colors';
import icons from '../../../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import I18n from '../../i18n/I18n.component';
import Typography from '../../Typography/Typography.component';
import { i18nService } from '../../../../services/i18n.service';
import httpService from '../../../../services/http.service';
import { modalService } from '../../../../services/modal.service';
import AlertModal from '../../../../modals/AlertModal/AlertModal.component';

const UploadFile = ({
	value,
	onChange,
	onBlur,
	downloadType,
	error,
	extraDownloadParams = {},
	arrayData,
	index,
	buttonStyle,
	blockDownload,
	onUpate,
	editable = true,
	onDownloadPressed,
	onRemoveFile,
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const { showActionSheetWithOptions } = useActionSheet();
	const input = useRef();

	useEffect(() => {
		value && onBlur();
	}, [value]);

	const onChangeMobile = async (fileData: any) => {
		const validation = { isValid: true, err: '' };
		const key = fileData.name ? 'name' : 'fileName';
		const sizeKey = fileData.size ? 'size' : 'fileSize';
		const nameArr = fileData[key].split('.');

		if (fileData[sizeKey] >= 10485760) {
			validation.isValid = false;
			validation.err = 'general.validation.fileTooBig';
		} else if (
			['heic', 'mpeg4', 'dng', 'mp4', 'mov'].includes(
				nameArr[nameArr.length - 1].toLowerCase(),
			)
		) {
			validation.isValid = false;
			validation.err = 'general.validation.formatNotSupported';
		} else if (nameArr[nameArr.length - 1] === 'pdf') {
			const RNFS = await import('react-native-fs');
			const file = await RNFS.readFile(fileData.uri, 'base64');
			const pdfDoc = await PDFDocument.load(file, {
				ignoreEncryption: true,
			});
			if (pdfDoc.isEncrypted) {
				validation.isValid = false;
				validation.err = 'general.validation.encryptedFile';
			}
		}

		if (validation.isValid) {
			onChange({
				...(Platform.OS === 'ios'
					? {
							...fileData,
							uri: decodeURIComponent(fileData.uri),
					  }
					: fileData),
				[key]: (fileData.name || fileData.fileName).replace(
					/[^A-Za-z\u0590-\u05FF0-9+\-_.() ]/g,
					'_',
				),
			});
		} else {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>{validation.err}</I18n>
					</AlertModal>
				),
			);
		}
	};

	const onChangeWEB = async event => {
		const validation = { isValid: true, err: '' };

		if (event?.target?.files[0]) {
			const file = event?.target?.files[0];
			const nameArr = file.name.split('.');

			if (file.size >= 10485760) {
				validation.isValid = false;
				validation.err = 'general.validation.fileTooBig';
			} else if (nameArr[nameArr.length - 1] === 'pdf') {
				await new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = async () => {
						const pdfDoc = await PDFDocument.load(reader.result, {
							ignoreEncryption: true,
						});
						if (pdfDoc.isEncrypted) {
							validation.isValid = false;
							validation.err = 'general.validation.encryptedFile';
						}
						resolve(null);
					};
					reader.readAsDataURL(file);
				});
			} else if (
				!['GIF', 'TIFF', 'TIF', 'JPG', 'JPEG', 'PDF', 'PNG'].includes(
					nameArr[nameArr.length - 1].toUpperCase(),
				)
			) {
				validation.isValid = false;
				validation.err = 'general.validation.formatNotSupported';
			}
			if (validation.isValid) {
				const blob = file.slice(0, file.size, file.type);
				const newFile = new File(
					[blob],
					file.name?.replace(
						/[^A-Za-z\u0590-\u05FF0-9+\-_.() ]/g,
						'_',
					),
					{ type: file.type },
				);
				onChange(newFile);
			} else {
				if (input?.current) {
					input.current.value = '';
				}
				modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>{validation.err}</I18n>
						</AlertModal>
					),
				);
			}
		}
	};

	const openUploader = () => {
		if (platform === DeviceType.MOBILE) {
			showActionSheetWithOptions(
				{
					title: i18nService.translate('general.filePicker.title'),
					textStyle: {
						textAlign: 'right',
						minWidth: Dimensions.get('screen').width - 30,
					},
					titleTextStyle: {
						textAlign: 'right',
						minWidth: Dimensions.get('screen').width - 30,
					},
					options: [
						i18nService.translate(
							'general.filePicker.selectCamera',
						),
						i18nService.translate(
							'general.filePicker.selectCameraLib',
						),
						i18nService.translate(
							'general.filePicker.selectFileLib',
						),
						i18nService.translate('general.cancel'),
					],
					destructiveButtonIndex: 3,
				},
				async (buttonIndex?: number) => {
					if (buttonIndex === 0) {
						let granted = 'ios';
						if (Platform.OS === 'android') {
							granted = await PermissionsAndroid.request(
								PermissionsAndroid.PERMISSIONS.CAMERA,
							);
						}
						if (
							Platform.OS === 'ios' ||
							granted === PermissionsAndroid.RESULTS.GRANTED
						) {
							launchCamera(
								{ mediaType: 'photo' },
								({ assets }) => {
									assets && onChangeMobile(assets[0]);
								},
							);
						}
					} else if (buttonIndex === 1) {
						launchImageLibrary(
							{ mediaType: 'photo' },
							({ assets }) => {
								assets && onChangeMobile(assets[0]);
							},
						);
					} else if (buttonIndex === 2) {
						const res = await DocumentPicker.pickSingle({
							type: [
								DocumentPicker.types.images,
								DocumentPicker.types.pdf,
							],
						});

						onChangeMobile(res);
					}
				},
			);
		} else {
			input.current.click();
		}
	};

	const download = async () => {
		if (onDownloadPressed) {
			await onDownloadPressed(value.id);
		} else {
			if (value.id && value.status === 'READY') {
				const res: string = await httpService.api({
					type: downloadType,
					params: { fileId: value.id, ...extraDownloadParams },
				});

				let module;
				if (
					platform === DeviceType.WEB ||
					platform === DeviceType.MOBILE_WEB
				) {
					module = await import(
						'../../../../utils/downloadFile.web.utils'
					);
				} else {
					module = await import(
						'../../../../utils/downloadFile.moblie.utils'
					);
				}
				module.downloadFile(res, value.fileName || value.name);
			}
		}
	};

	return value ? (
		isMobile ? (
			<View
				style={{
					height: 54,
					backgroundColor: colors.filterBorder,
					borderRadius: 27,
					padding: 15,
					flexDirection: 'row-reverse',
					justifyContent: 'flex-end',
					alignItems: 'center',
					width: '100%',
					maxWidth: '100%',
				}}
			>
				<TouchableHighlight onPress={download} disabled={blockDownload}>
					<Typography
						weight='normal'
						size={16}
						style={{
							marginLeft: 11,
							maxWidth:
								Platform.OS === 'android' ||
								Platform.OS === 'ios'
									? '85%'
									: '70%',
							marginRight: 20,
						}}
					>
						{value.name || value.fileName}
					</Typography>
				</TouchableHighlight>
				<View style={{ flex: 1 }} />
				<TouchableHighlight
					onPress={() => {
						arrayData?.length > 1
							? arrayData?.remove([arrayData?.index])
							: onChange(null);
						onRemoveFile?.(value);
					}}
					style={{
						width: 34,
						height: 34,
						backgroundColor: colors.white,
						borderRadius: 17,
						alignItems: 'center',
						justifyContent: 'center',
						opacity: editable ? 1 : 0,
					}}
					disabled={!editable}
				>
					<Image
						style={{ width: 12, height: 12 }}
						source={icons.remove}
					/>
				</TouchableHighlight>
			</View>
		) : (
			<View
				style={{
					flexDirection: 'row-reverse',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<TouchableHighlight
					onPress={() => download()}
					style={{ maxWidth: 200, overflow: 'hidden' }}
				>
					<Typography weight='normal' size={16}>
						{value.name || value.fileName}
					</Typography>
				</TouchableHighlight>
				<TouchableHighlight
					onPress={() => {
						arrayData?.length > 1
							? arrayData?.remove([arrayData?.index])
							: onChange(null);
						onRemoveFile?.(value);
					}}
					style={{
						opacity: editable ? 1 : 0,
					}}
					disabled={!editable}
				>
					<Image
						style={{ width: 12, height: 12, marginRight: 5 }}
						source={icons.remove}
					/>
				</TouchableHighlight>
			</View>
		)
	) : (
		<TouchableHighlight
			// key={opt.value}
			underlayColor='none'
			style={[
				{
					borderRadius: 10,
					borderWidth: 1,
					borderColor: error ? colors.red : colors.generalSeparator,
					borderStyle: error ? 'solid' : 'dashed',
					width: isMobile ? '100%' : 180,
				},
				buttonStyle,
			]}
			accessibilityRole='button'
			onPress={openUploader}
			disabled={!editable}
		>
			<View
				style={{
					margin: 8,
					backgroundColor: colors.uploadBright,
					borderRadius: 10,
					padding: isMobile ? 9 : 7,
					alignItems: 'center',
				}}
			>
				{Platform.OS === 'web' ? (
					<input
						type='file'
						ref={input}
						name='input'
						accept='.GIF, .TIFF, .JPG, .JPEG, .PDF, .PNG'
						style={{ visibility: 'hidden', width: 0, height: 0 }}
						onChange={onChangeWEB}
					></input>
				) : null}
				<Image
					style={{ width: 30, height: 19, marginBottom: 5 }}
					source={icons.upload}
				/>
				<I18n
					weight='normal'
					size={isMobile ? 16 : 12}
					style={{ textDecorationLine: 'underline' }}
					{...{
						color: 'primary',
					}}
				>
					general.uploadFile
				</I18n>
			</View>
		</TouchableHighlight>
	);
};

export default UploadFile;
