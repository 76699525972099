export const downloadFile = (url: string = '', fileName: string) => {
	fetch(url, { headers: { 'Cache-Control': 'no-cache' } })
		.then(response => response.blob())
		.then(blob => {
			if (navigator.msSaveBlob) {
				// IE 10+
				navigator.msSaveBlob(blob, fileName);
			} else {
				const blobURL = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = blobURL;
				// a.style = "display: none";

				if (fileName && fileName.length) {
					a.download = fileName;
				}
				document.body.appendChild(a);
				setTimeout(function () {
					// Cleanup the DOM
					a.click();
					document.body.removeChild(a);
				}, 500);
			}
		})
		.catch(e => console.log(e));
};

export const getFile = (
	url: string = '',
	fileName: string,
	setFileUrl?: Function,
) => {
	fetch(url, { headers: { 'Cache-Control': 'no-cache' } })
		.then(response => response.blob())
		.then(blob => {
			if (navigator.msSaveBlob) {
				// IE 10+
				navigator.msSaveBlob(blob, fileName);
			} else {
				const blobURL = URL.createObjectURL(blob);
				setFileUrl && setFileUrl(blobURL);
			}
		});
};

export const getExcelFileFromResponse = (response: any, fileName?: string) => {
	console.log('res:', response);
	let filename;
	if (response.headers && response.headers['content-disposition']) {
		filename = response.headers['content-disposition']
			.split(';')[1]
			.split('=')[1]
			.replace(/\"/g, '');
		filename = decodeURIComponent(filename);
	} else {
		filename = fileName;
	}
	//filename = decodeURIComponent(filename);
	let blob: Blob = new Blob([response.data], { type: '' });

	//detect whether the browser is IE/Edge or another browser
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		//To IE or Edge browser, using msSaveorOpenBlob method to download file.
		window.navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		//To another browser, create a tag to downlad file.
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.setAttribute('style', 'display: none');
		a.href = url;
		a.download = filename;
		a.click();

		window.URL.revokeObjectURL(url);
		a.remove();
	}
};
