import React from 'react';
import moment from 'moment';
import I18n from '../../../../components/shared/i18n/I18n.component';
import { i18nService } from '../../../../services/i18n.service';
import { EnterCustomerComponenet, monthMap } from '../RepReports.utils';
import { FilterData } from '../../../../components/web/Filters/Filters.container';
import { compact } from 'lodash';

const newDate = new Date();
export const defaultFilters = {
	unitIds: [],
	month:
		Number(moment(newDate).month().toString()) === 0
			? [12]
			: [Number(moment(newDate).month().toString())],
	year: [Number(moment(newDate).year().toString())],
	repCustomerIds: [],
	repCustomerUnitIds: [],
	operatorIds: [],
	status: [],
};

export const statusOptions = [
	{
		id: 'NO_RESULTS',
		name: i18nService.translate(
			'repReports.repWorkPaperReports.status.NO_RESULTS',
		),
	},
	{
		id: 'AWAITING_APPROVAL',
		name: i18nService.translate(
			'repReports.repWorkPaperReports.status.AWAITING_APPROVAL',
		),
	},
	{
		id: 'APPROVED',
		name: i18nService.translate(
			'repReports.repWorkPaperReports.status.APPROVED',
		),
	},
	{
		id: 'EXPORTED',
		name: i18nService.translate(
			'repReports.repWorkPaperReports.status.EXPORTED',
		),
	},
];

export const getHeaders = (canEnterCustomer: boolean, routeName: string) => {
	return compact([
		{
			accessor: 'year',
			Header: i18nService.translate(
				'repReports.general.tableHeader.year',
			),
		},
		{
			accessor: 'month',
			Header: i18nService.translate(
				'repReports.general.tableHeader.month',
			),
			CustomCell: ({ row }: any) => (
				<I18n size={14}>{monthMap[row.original?.month]}</I18n>
			),
		},
		{
			accessor: 'repCustomer',
			Header: i18nService.translate(
				'repReports.general.tableHeader.repCustomer',
			),
			isSortable: true,
		},
		{
			accessor: 'hp',
			Header: i18nService.translate('repReports.general.tableHeader.hp'),
			isSortable: true,
		},
		{
			accessor: 'businessUnitName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.businessUnitName',
			),
			isSortable: true,
		},
		{
			accessor: 'operatorName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.operatorName',
			),
			isSortable: true,
		},
		{
			accessor: 'awaitingApproval',
			Header: i18nService.translate(
				'repReports.repWorkPaperReports.tableHeader.awaitingApproval',
			),
			isSortable: true,
		},
		{
			accessor: 'approved',
			Header: i18nService.translate(
				'repReports.repWorkPaperReports.tableHeader.approved',
			),
			isSortable: true,
		},
		{
			accessor: 'exported',
			Header: i18nService.translate(
				'repReports.repWorkPaperReports.tableHeader.exported',
			),
			isSortable: true,
		},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate(
				'repReports.general.tableHeader.lastUpdate',
			),
			isSortable: true,
		},
		canEnterCustomer && {
			accessor: 'enterCustomer',
			Header: '',
			CustomCell: ({ row }: any) => {
				return (
					<EnterCustomerComponenet
						data={row.original}
						routeName={routeName}
					/>
				);
			},
		},
	]);
};

export const handleFilter = (
	data: FilterData,
	setFilters: Function,
	filters: any,
	setRepCustData: Function,
) => {
	if (data.name === 'unitIds') {
		setRepCustData([]);
		setFilters({
			...defaultFilters,
			unitIds: [data.value],
		});
	} else if (data.name === 'monthYear') {
		setFilters({
			...filters,
			month:
				Number(
					moment(data.value).add(1, 'months').month().toString(),
				) === 0
					? [12]
					: [
							Number(
								moment(data.value)
									.add(1, 'months')
									.month()
									.toString(),
							),
					  ],
			year: [Number(moment(data.value).year().toString())],
		});
	} else if (data.name === 'repCustomerIds') {
		setRepCustData([]);
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerIds: chosenIds,
		});
	} else if (data.name === 'repCustomerUnitIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerUnitIds: chosenIds,
		});
	} else if (data.name === 'operatorIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			operatorIds: chosenIds?.includes?.('all') ? [] : chosenIds,
		});
	} else if (data.name === 'status') {
		const chosenIds = data.value.map((val: any) => val.id);
		setFilters({
			...filters,
			status: chosenIds,
		});
	}
};
