import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, TouchableHighlight } from 'react-native';
import { BoxShadow } from 'react-native-shadow';
import colors from '../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { TextOptions } from '../../../interfaces/buttonProps.interface';
import I18n from '../i18n/I18n.component';

interface CaruselItemProps {
	title: string;
	onPress: (tabiD?: any) => void;
	containerStyle?: StyleProp<any>;
	buttonStyle?: StyleProp<any>;
	textOptions?: TextOptions;
	selected?: boolean;
	isCompact?: boolean;
	tabId?: any;
	width?: number;
}

const CaruselItem = ({
	title,
	containerStyle,
	buttonStyle,
	textOptions,
	selected,
	onPress,
	isCompact = true,
	tabId,
	width,
}: CaruselItemProps) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;

	const container = useMemo(
		() =>
			StyleSheet.flatten([
				{ width: width || (isMobile ? 86 : 150) },
				styles.root,
			]),
		[containerStyle],
	);
	const button = useMemo(
		() =>
			StyleSheet.compose(
				styles.buttonStyle,
				selected ? styles.buttonSelected : null,
			),
		[selected],
	);
	const text = useMemo(
		() =>
			StyleSheet.flatten([
				styles.centeredText,
				isCompact ? styles.rotateText : null,
			]),
		[isCompact],
	);

	const handlePress = () => onPress(tabId);
	const shadowOpt = {
		width: width || (isMobile ? 86 : 150),
		height: 52,
		color: '#3b70a4',
		border: 10,
		radius: 12,
		opacity: 0.1,
		x: 0,
		y: 0,
	};
	return (
		<View style={container}>
			<TouchableHighlight
				underlayColor='none'
				accessibilityRole='button'
				onPress={handlePress}
			>
				<BoxShadow setting={shadowOpt}>
					<View style={button}>
						<I18n
							style={text}
							weight='normal'
							size={12}
							{...{
								...textOptions,
								color: selected ? 'white' : textOptions?.color,
							}}
						>
							{title}
						</I18n>
					</View>
				</BoxShadow>
			</TouchableHighlight>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	centeredText: {
		textAlign: 'center',
	},
	rotateText: {
		transform: [{ rotateY: '180deg' }],
	},
	buttonStyle: {
		backgroundColor: colors.white,
		height: 52,
		justifyContent: 'space-evenly',
		alignItems: 'center',
		textAlign: 'center',
		paddingHorizontal: 16,
		paddingVertical: 10,
		borderRadius: 12,
	},
	containerStyle: {
		width: 150,
	},
});

export default CaruselItem;
