import React, { useEffect, useMemo } from 'react';
import { Dimensions, useWindowDimensions, View } from 'react-native';
import { Breadcrumbs, Link } from '@material-ui/core';
import colors from '../../config/colors';
import I18n from '../../components/shared/i18n/I18n.component';
import useDevicePlatform, { DeviceType } from '../../hooks/useDevicePlatform.hook';
import Header from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import ApplicationsToEmployerMobile from './ApplicationsToEmployer.component.native';
import ReportsPageWrapper from '../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { DownloadSection, EditButton, GeneralText, OpenNewRequestButton, StatusSection } from './ApplicationsToEmployer.utils';
import { modalService } from '../../services/modal.service';
import RequestWrapper from './CreateNewRequest/RequestWrapper';
import TableContainer from '../../components/web/ReactTable/Table.container';
import { useSelector } from 'react-redux';
import { getEmployeeRolesPrivileges, getEmployerRolesPrivileges } from '../../store/selectors/login.selectors';
import { Privileges } from '../../constants/roleTypes.constant';
import { i18nService } from '../../services/i18n.service';
import { compact } from 'lodash';

export interface ApplicationsToEmployerProps {
  navigation: any;
  data: any[];
  onSort?: any,
  onLoadMore: any,
  finishFirstLoad: boolean,
  validationDate: number,
  onrefresh(): void
  disableActions:boolean
}

const ApplicationsToEmployer = ({
  navigation,
  data,
  onSort,
  onLoadMore,
  onrefresh,
  finishFirstLoad,
  validationDate,
  disableActions,
}: ApplicationsToEmployerProps) => {
	const { height: windowHeight } = useWindowDimensions();
	const platform = useDevicePlatform();
  const isMobileWeb = platform === DeviceType.MOBILE_WEB;
  const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

  const applicationsColumns = useMemo(()=>compact([
    {
      accessor: 'type',
      Header: i18nService.translate('applicationsToEmployerContainer.requestType'),
      isSortable: true,
    },
    {
      accessor: 'lastUpdate',
      Header: i18nService.translate('applicationsToEmployerContainer.lastUpdated'),
      isSortable: true,
    },
    {
      accessor: 'status',
      Header: i18nService.translate('applicationsToEmployerContainer.status'),
      isSortable: true,
      CustomCell: ({ row }: any) => <StatusSection item={row.original} />
    },
    {
      accessor: 'dateRange',
      Header: i18nService.translate('applicationsToEmployerContainer.dates'),
      isSortable: true,
    },
    {
      accessor: 'description',
      Header: i18nService.translate('applicationsToEmployerContainer.requestDescription'),
      isSortable: true,
      CustomCell: ({ value }: any) => <GeneralText value={value} />
    },
    {
      accessor: 'employerComment',
      Header: i18nService.translate('applicationsToEmployerContainer.notes'),
      isSortable: true,
      CustomCell: ({ value }: any) => <GeneralText value={value} />
    },
    {
      accessor: 'attachedFiles',
      Header: i18nService.translate('applicationsToEmployerContainer.files'),
      isSortable: false,
      CustomCell: ({ row }: any) => <DownloadSection data={row.original} />
    },
    !disableActions && {
      accessor: 'edit',
      Header: '',
      isSortable: false,
      CustomCell: ({ row }: any) => <EditButton data={row.original} onrefresh={onrefresh} validationDate={validationDate} />,
    }
  ]),[disableActions]);

  const onCreateNewRequest = async (requestId?: string) => {
    await modalService.openModal(
      null,
      {},
      (props: any) => (
        <RequestWrapper requestId={requestId} {...props} onrefresh={onrefresh} validationDate={validationDate}/>
      ),
    );
  };

  return (isMobileWeb ? (
    <ApplicationsToEmployerMobile
      navigation={navigation}
      data={data}
      onSort={onSort}
      onLoadMore={onLoadMore}
      onrefresh={onrefresh}
      finishFirstLoad={finishFirstLoad}
      validationDate={validationDate}
      disableActions={disableActions}
    />
  ) : (
    <View
      style={{
        backgroundColor: colors.tableBackground,
        height: '100%',
      }}
    >
      <Header
        navigation={navigation}
        containerStyle={{ backgroundColor: colors.white }}
        component={WebHeader}
      />
      <ReportsPageWrapper
        navigation={navigation}
        title={
          <Breadcrumbs separator='<'>
            <Link
              color='inherit'
              underline={'none'}
            >
              <I18n size={36} weight='normal' style={{ flex: 1 }}>
                applicationsToEmployerContainer.title
              </I18n>
            </Link>
          </Breadcrumbs>
        }
        noHeader={true}
      >
        {disableActions?null: <OpenNewRequestButton
          disabled={
            (!employeeRolesPrivileges?.includes(Privileges.ROLE_VACATION_REQUEST_WRITE) && 
            !employeeRolesPrivileges?.includes(Privileges.ROLE_SICKNESS_REQUEST_WRITE))
          }
          style={{
            opacity: (!employeeRolesPrivileges?.includes(Privileges.ROLE_VACATION_REQUEST_WRITE) && !employeeRolesPrivileges?.includes(Privileges.ROLE_SICKNESS_REQUEST_WRITE))
              ? 0.7
              : 1,
          }}
          onNewRequest={() => onCreateNewRequest()}
        />}
        {data.length > 0 ? (<View
          style={{
            maxHeight:
              windowHeight - 220,
          }}
        >
          <TableContainer
            keyName={''}
            headers={applicationsColumns}
            data={data || []}
            getNextData={onLoadMore}
            onSort={onSort}
            hasFooter={false}
          />
        </View>
        ) : finishFirstLoad ? (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <I18n
              weight='bold'
              size={24}
              style={{ marginTop: 50, marginRight: 26 }}
            >
              applicationsToEmployerContainer.noReports
            </I18n>
          </View>
        ) : <View />}
      </ReportsPageWrapper>
    </View>
  )
  );
};

export default ApplicationsToEmployer;
