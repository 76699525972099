import { makeStyles } from '@material-ui/core';
import React from 'react';
import SolidButton from '../../../../../components/shared/Buttons/SolidButton.container';
import OutlineButton from '../../../../../components/shared/Buttons/OutlineButton.container';

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'center',
		minHeight: '85px',
	},
}));

const ButtonsRow = ({
	onSubmit,
	onCancel,
	disabledSubmit,
	submitButtonText,
}: any) => {
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<SolidButton
				onPress={onSubmit}
				size={'medium'}
				overrideStyle={{ marginLeft: 23 }}
				disabled={disabledSubmit}
			>
				{submitButtonText ? submitButtonText : 'general.save'}
			</SolidButton>
			<OutlineButton onPress={onCancel} size='medium'>
				general.cancel
			</OutlineButton>
		</div>
	);
};

export default ButtonsRow;
