import React from 'react';
import {
  View,
  StyleSheet,
  TouchableHighlight,
  FlatList,
  useWindowDimensions,
  Platform,
} from 'react-native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import Typography from '../../components/shared/Typography/Typography.component';
import I18n from '../../components/shared/i18n/I18n.component';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import { ApplicationsData } from './ApplicationsToEmployer.container';
import { ApplicationsToEmployerProps } from './ApplicationsToEmployer.component';
import { OpenNewRequestButton, StatusSection } from './ApplicationsToEmployer.utils';
import { Privileges } from '../../constants/roleTypes.constant';
import { useSelector } from 'react-redux';
import { getEmployeeRolesPrivileges } from '../../store/selectors/login.selectors';

const ApplicationsToEmployerMobile = ({
  navigation,
  data,
  onLoadMore,
  onrefresh,
  finishFirstLoad,
  validationDate,
  disableActions
}: ApplicationsToEmployerProps) => {
  const dimentions = useWindowDimensions();
  const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

  const mobileRowItem = (item: ApplicationsData, index: number) => {
    return (
      <TouchableHighlight
        disabled={disableActions}
        onPress={() => navigation.navigate('emploeeRequestForm', { requestId: item.id, onrefresh, validationDate })}
        underlayColor='transparent' hitSlop={{ top: 20, bottom: 5, left: 20, right: 20 }}
      >
        <View style={styles.rowContainer}>
          <View>
            <Typography weight='bold' size={14}>
              {item.type}
            </Typography>
            <Typography size={14}>
              {item.dateRange}
            </Typography>
          </View>
          <StatusSection item={item} style={{ flexDirection: 'row-reverse' }} isMobile />
        </View>
      </TouchableHighlight>
    );
  };

  return (
    <View>
      <View >
        <HeaderContainer
          navigation={navigation}
          component={MobileHeader}
        />
      </View>
      <View style={{ marginRight: 16 }}>
        <I18n weight='normal' size={28}>
          {'applicationsToEmployerContainer.title'}
        </I18n>
      </View>

      <View style={styles.buttonsLayerContainer}>
        {disableActions?null:   
        <OpenNewRequestButton
          disabled={
            (!employeeRolesPrivileges?.includes(Privileges.ROLE_VACATION_REQUEST_WRITE) && 
            !employeeRolesPrivileges?.includes(Privileges.ROLE_SICKNESS_REQUEST_WRITE))
          }
          style={{
            opacity: (!employeeRolesPrivileges?.includes(Privileges.ROLE_VACATION_REQUEST_WRITE) && !employeeRolesPrivileges?.includes(Privileges.ROLE_SICKNESS_REQUEST_WRITE))
              ? 0.7
              : 1,
          }}
          onNewRequest={() => navigation.navigate('emploeeRequestForm', { requestId: null, onrefresh })}
        />}
      </View>
      {data.length > 0 ? (
        <View style={styles.dataContainer}>
          <View style={styles.listHeader}>
            <I18n weight='normal'>
              {'applicationsToEmployerContainer.requestType'}
            </I18n>
            <I18n weight='normal' >
              {'applicationsToEmployerContainer.status'}
            </I18n>
          </View>
          
          <FlatList
            data={data}
            renderItem={({ item, index }) => mobileRowItem(item, index)}
            style={{
              height:
                dimentions.height -
                (Platform.OS === 'web' ? 250 : 260),
            }}
            contentContainerStyle={{paddingBottom: 150}}
            onEndReached={() => onLoadMore()}
            keyExtractor={item => `${item.id}`}
          />
        </View>
      ) : finishFirstLoad ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <I18n
            weight='bold'
            size={24}
            style={{ marginTop: 50, marginRight: 26 }}
          >
            applicationsToEmployerContainer.noReports
          </I18n>
        </View>
      ) : <View />}
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    marginLeft: 8,
  },
  buttonsLayerContainer: {
    marginTop: 6,
    display: 'flex',
    alignItems: 'flex-end'
  },
  dataContainer: {
    marginTop: 10,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 70,
    backgroundColor: 'white',
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  listHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    paddingHorizontal: 20
  },
});

export default ApplicationsToEmployerMobile;
