import React from 'react';
import { TouchableHighlight, TouchableOpacity, View } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';

const Rows = ({ title, data, colorsObj, navigate }: any) => {
	console.log({ data });
	return (
		<View style={{ marginTop: 15 }}>
			{data?.map((item: any) => (
				<TouchableOpacity
					key={item.key}
					style={{
						marginVertical: 15,
						flexDirection: 'row-reverse',
					}}
					onPress={() => {
						navigate({ status: [item?.key] });
					}}
				>
					<View
						style={{
							backgroundColor: colorsObj[item.key],
							width: 19,
							height: 19,
							borderRadius: 5,
							marginLeft: 20,
						}}
					/>
					<I18n
						weight='400'
						style={{ width: 250, marginLeft: 25 }}
						size={14}
					>{`${title}.${item.key}`}</I18n>
					<Typography weight='bold'>{item.val}</Typography>
				</TouchableOpacity>
			))}
		</View>
	);
};

export default Rows;
