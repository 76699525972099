import { cloneDeep } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { i18nService } from '../../../../services/i18n.service';
import { fetchEmployeesFilterList } from '../../../../store/actions/reportsFilters.actions';
import { adminRoles, onDeleteRole, repRoles } from '../createUpdateUser.utils';

const RolesWrapper = ({
	modalRef,
	departments,
	roles,
	setRoles,
	Component,
	setIsModalOpen,
	businessUnitsList,
	rolesInfo,
	unitId,
	userId,
	hasOnlyOneUnit,
	disableSelectEmployeeRole,
	...restProps
}: any) => {
	const [totalUsers, setTotalUsers] = useState();
	const rolesRef = useRef<any>();
	rolesRef.current = roles;
	const hasAdminRole = useMemo(
		() =>
			!!roles.user_roles?.find((role: number) =>
				adminRoles.includes(role),
			),
		[roles.user_roles],
	);

	const hasRepRole = useMemo(
		() =>
			!!roles.user_roles?.find((role: number) => repRoles.includes(role)),
		[roles.user_roles],
	);

	const watchEmployeeType = useWatch({
		name: 'employee_type',
	});

	const cleanRolesOptions = useMemo(
		() =>
			roles.roles?.filter(
				(rol: any) =>
					!roles.user_roles.includes(rol.id) &&
					(hasAdminRole ? !adminRoles.includes(rol.id) : true) &&
					(hasRepRole ? !repRoles.includes(rol.id) : true) &&
					((watchEmployeeType === 'EXTERNAL' ||
						disableSelectEmployeeRole) &&
					!roles.user_roles?.includes(6)
						? rol.id !== 6
						: true),
			),
		[
			roles.user_roles,
			roles.roles,
			hasAdminRole,
			hasRepRole,
			disableSelectEmployeeRole,
		],
	);

	const _onDeleteRole = (roleId: number) => {
		onDeleteRole({ roleId, roles, unitId, setRoles });
	};

	const onAddRole = () => {
		const nextRole = cleanRolesOptions?.find(
			(role: any) => !roles.user_roles.includes(role.id),
		);
		const user_role_rep_customer_info =
			nextRole.id === 7
				? { 7: { all_customer: true } }
				: roles.user_role_rep_customer_info;

		setRoles((prev: any) => ({
			...prev,
			[unitId]: {
				...roles,
				user_roles: [...roles.user_roles, nextRole.id],
				user_role_rep_customer_info,
			},
		}));
	};

	const onSelectRole = (roleId: number, prevId: number) => {
		const prevRoleIndex = roles.user_roles?.findIndex(
			(role: any) => role === prevId,
		);
		const tempRoles = cloneDeep(roles);
		tempRoles.user_roles[prevRoleIndex] = roleId;
		tempRoles.user_role_department &&
			delete tempRoles.user_role_department[prevId];
		tempRoles.user_role_permission &&
			delete tempRoles.user_role_permission[prevId];
		tempRoles.user_role_permission &&
			delete tempRoles.user_roles_indicator[prevId];

		if (
			!tempRoles.user_role_rep_customer_info?.[roleId]
				?.user_role_rep_customer?.length &&
			tempRoles.user_role_rep_customer_info?.[prevId]
				?.user_role_rep_customer?.length
		) {
			tempRoles.user_role_rep_customer_info = {
				[roleId]: {
					user_role_rep_customer: [
						...tempRoles.user_role_rep_customer_info?.[prevId]
							?.user_role_rep_customer,
					],
					deleted_ids: null,
				},
			};
		} else if (roleId === 7) {
			tempRoles.user_role_rep_customer_info = {
				7: { all_customer: true },
			};
		}
		delete tempRoles.user_role_rep_customer_info?.[prevId];

		setRoles((prev: any) => ({
			...prev,
			[unitId]: { ...roles, ...tempRoles },
		}));
	};

	const onChangeDep = (value: any, roleId: number) => {
		setRoles((prev: any) => ({
			...prev,
			[unitId]: {
				...roles,
				user_role_department: {
					...roles?.user_role_department,
					[roleId]: value,
				},
				...(!roles.user_roles.find(
					(id: number) =>
						!roles.roles?.find((el: any) => el.id === id)
							?.allocate_department,
				)
					? { excluded_user: null, exclude_selected: true }
					: {}),
			},
		}));
	};

	const onChangePermissions = (value: any, roleId: number) => {
		setRoles((prev: any) => ({
			...prev,
			[unitId]: {
				...roles,
				user_role_permission: {
					...roles?.user_role_permission,
					[roleId]: value,
				},
			},
		}));
	};

	const onChangeUsers = (value: any) => {
		setRoles((prev: any) => ({
			...prev,
			[unitId]: {
				...roles,
				...value,
			},
		}));
	};

	const onChangeCustomers = (value: any, roleId: number) => {
		setRoles((prev: any) => ({
			...prev,
			[unitId]: {
				...roles,
				user_role_rep_customer_info: {
					...(roles.user_role_rep_customer || {}),
					[roleId]: value,
				},
			},
		}));
	};

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
			dep,
		}: {
			page: number;
			dep: any;
		},
		noConvert?: boolean,
		displayExcluded?: boolean,
		excludedUser?: any,
		excludeSelected?: any,
	) => {
		const users = excludedUser;
		const exclude_selected = !excludedUser || excludeSelected;

		const res = await fetchEmployeesFilterList('users', {
			page,
			pageSize: 20,
			search,
			filter: {
				user_id:
					displayExcluded && exclude_selected && users?.length
						? users
						: [],
				dep,
			},
			unitIds: [unitId],
		});

		(!displayExcluded || !exclude_selected) &&
			(!search || search === '') &&
			setTotalUsers(res.total);

		let options = res?.data?.map((el: any) => {
			return {
				[noConvert ? 'id' : 'value']: el.employeeId,
				[noConvert ? 'name' : 'label']: el.name + ` (${el.teudatZeut})`,
			};
		});

		if (displayExcluded && !exclude_selected) {
			options = options.filter(
				(opt: any) => !users.includes(opt.value || opt.id),
			);
		}

		if (displayExcluded && !users?.length) {
			options = [];
		}

		return {
			options:
				!page && !displayExcluded
					? [
							{
								[noConvert ? 'id' : 'value']: 'all',
								[noConvert ? 'name' : 'label']:
									i18nService.translate('general.all'),
							},
							...options,
					  ]
					: options,
			hasMore: 20 === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	return (
		<Component
			{...{
				modalRef,
				departments,
				roles,
				setRoles,
				onChangePermissions,
				onSelectRole,
				onChangeDep,
				onChangeUsers,
				getEmployeesFilter,
				totalUsers,
				setIsModalOpen,
				businessUnitsList,
				onAddRole,
				onDeleteRole: _onDeleteRole,
				unitId,
				userId,
				hasOnlyOneUnit,
				onChangeCustomers,
				cleanRolesOptions,
				disableSelectEmployeeRole,
			}}
		/>
	);
};

export default RolesWrapper;
