import React, { FunctionComponent } from 'react';
import { StyleProp } from 'react-native';
import Header from './Header.component';

interface HeaderContainerProps {
	navigation: any;
	component?: FunctionComponent;
	containerStyle?: StyleProp<any>;
	isDrawerShown?: boolean;
}

const HeaderContainer = ({
	navigation,
	component,
	containerStyle,
	isDrawerShown,
}: HeaderContainerProps) => {
	return (
		<Header
			navigation={navigation}
			containerStyle={containerStyle}
			component={component}
			openDrawer={() => navigation.openDrawer()}
			isDrawerShown={isDrawerShown}
		/>
	);
};

export default HeaderContainer;
