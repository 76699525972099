import React, { useMemo } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import colors from '../../../../config/colors';

const HideViewButtons = ({
	setDisplayCardMap,
	displayCardMap,
	canDisplay,
}: any) => {
	const mapArr = useMemo(
		() => ['monthly', 'yearly', 'employeeForm', 'workPaperDraft'],
		[],
	);

	return (
		<>
			{mapArr.map(
				key =>
					canDisplay[key] && (
						<TouchableOpacity
							onPress={() => {
								setDisplayCardMap(key, !displayCardMap[key]);
							}}
							style={{
								flexDirection: 'row-reverse',
								backgroundColor: displayCardMap[key]
									? colors.primary
									: colors.white,
								paddingHorizontal: 25,
								paddingVertical: 9,
								marginLeft: 15,
								borderRadius: 6,
								height: 31,
								...Platform.select({
									web: {
										boxShadow:
											'0px 4px 15px rgba(0, 0, 0, 0.15)',
									},
								}),
							}}
						>
							<ImageIcon
								name={displayCardMap[key] ? 'hide' : 'view'}
								height={displayCardMap[key] ? 16 : 11.5}
								width={16}
								style={{ marginLeft: 8 }}
							/>
							<I18n
								weight='normal'
								size={12}
								color={
									displayCardMap[key] ? 'white' : 'primary'
								}
							>{`repDashboard.buttons.${key}`}</I18n>
						</TouchableOpacity>
					),
			)}
		</>
	);
};

export default HideViewButtons;
