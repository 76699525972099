// import { VacationReport } from '../interfaces/redux.interface';

// {
//     "id": Number,
//     "taxYear": 2021,
// “statusCode": "PENDING_EMPLOYEE_SUBMIT",
// "status": "ממתין להגשה של עובד",
// }

export class WorkerCardModel {
	public taxYear: number;
	public submitDate: string;
	public comment: Record<string, any>;
	public employer: {
		name: string;
		address: string;
		phone: string;
		deductionFileId: string;
		comment: Record<string, string>;
	};
	public employee: {
		idNumber: string;
		idFile: { extraData: any }[];
		firstName: string;
		lastName: string;
		birthDate: string;
		immigrationDate: string;
		passport: string;
		address: {
			street: string;
			houseNumber: string;
			city: string;
			postalCode: string;
		};
		phone: string;
		cellPrefix: string;
		cellPhone: string;
		gender: 'MALE' | 'FEMALE';
		genderFile: { extraData: any }[];
		maritalStatus:
			| 'SINGLE'
			| 'MARRIED'
			| 'WIDOWED'
			| 'DIVORCED'
			| 'SEPARATED';
		israeliResident: boolean;
		kibbutzMember: boolean;
		revenuesTransferredToKibbutz: boolean;
		healthOrgMember: boolean;
		healthOrgName: 'MACCABI' | 'CLALIT' | 'MEUHEDET' | 'LEUMIT';
		email: string;
		bankId: string;
		branchId: string;
		accountNumber: string;
		comment: Record<string, any>;
	};
	public hasChildren: boolean;
	public children: {
		id: number;
		idNumber: string;
		name: string;
		birthDate: string;
		myCustody: boolean;
		childAllowance: boolean;
		singleParent: boolean;
		comment: Record<string, string>;
	}[];
	public currentEmployerIncome: {
		startDate: string;
		incomeType:
			| 'MONTHLY_SALARY'
			| 'ADDITIONAL_POSITION_SALARY'
			| 'PARTIAL_SALARY'
			| 'DAILY_WORKER';
		additionalIncomeType: string[];
		allowUpdateIncomeStartDate: boolean;
		comment: Record<string, string>;
	};
	public otherEmployerIncome: {
		otherEmployerIncome: boolean;
		incomeType: string[];
		taxCreditRequest: boolean;
		noKerenHishtalmutPayment: boolean;
		noDisabilityPayment: boolean;
		comment: Record<string, string>;
	};
	public spouse: {
		idNumber: string;
		identityType: 'ID' | 'PASSPORT';
		firstName: string;
		lastName: string;
		birthDate: string;
		immigrationDate: string;
		hasIncome: boolean;
		incomeType: 'WORK_ALLOWANCE_BUSINESS' | 'OTHER';
		comment: Record<string, string>;
	};
	public taxCreditReason: {
		israeliResident: boolean;
		handicapped: boolean;
		handicappedFile: { extraData: any }[];
		handicappedCompensation: boolean,
		handicappedCompensationFile: { extraData: any }[];
		taxCreditAddress: boolean;
		taxCreditAddressFromDate: string;
		taxCreditAddressName: string;
		taxCreditAddressCode: string;
		taxCreditAddressFile: { extraData: any }[];
		immigrant: boolean;
		immigrantFromDate: string;
		immigrantFile: { extraData: any }[];
		noIncome: boolean;
		noIncomeUntilDate: string;
		spouseHasNoIncome: boolean;
		spouseHasNoIncomeFile: { extraData: any }[];
		singleParentLivingSeparately: boolean;
		childrenNotInMyCustody: boolean;
		childrenNotInMyCustodyFile: { extraData: any }[];
		childrenWithDisability: boolean;
		numberOfChildrenWithDisability: number;
		childrenWithDisabilityFile: { extraData: any }[];
		alimonyPayment: boolean;
		alimonyPaymentFile: { extraData: any }[];
		dischargedSoldier: boolean;
		serviceStart: string;
		serviceEnd: string;
		serviceFile: { extraData: any }[];
		academicDegree: boolean;
		academicDegreeFile: { extraData: any }[];
		comment: Record<string, string>;
	};

	public taxCreditRequest: {
		wantTaxCredit: boolean;
		taxCreditRequestReason?: string; //not send to BE
		noIncome: boolean;
		noIncomeFile: { extraData: any }[];
		hasAdditionalIncome: boolean;
		additionalIncome: {
			name: string;
			address: string;
			deductionFileId: string;
			incomeType: 'WORK' | 'ALLOWANCE' | 'SCHOLARSHIP' | 'OTHER';
			monthlyIncome: string;
			taxReduce: string;
			additionalIncomeFile: { extraData: any }[];
		}[];
		taxAssessorApproved: boolean;
		taxAssessorApprovedFile: { extraData: any }[];
		comment: Record<string, string>;
	};

	public signature?: string;

	constructor(props?: any) {
		const {
			employer,
			employee,
			currentEmployerIncome,
			otherEmployerIncome,
			spouse,
			taxCreditReason,
			taxCreditRequest,
			children,
			signature,
			hasChildren,
			taxYear,
			submitDate,
			comment,
			isViewer,
			taxCreditAddressList,
		} = props || {};
		this.taxYear = taxYear;
		this.submitDate = submitDate;
		this.hasChildren = hasChildren ?? undefined;
		this.signature =
			signature && signature !== 'data:,' ? signature : undefined;
		this.comment = comment || undefined;
		this.employer = {
			name: employer?.name || undefined,
			address: employer?.address || undefined,
			phone: employer?.phone || undefined,
			deductionFileId: employer?.deductionFileId || undefined,
			comment: employer?.comment || undefined,
		};
		this.employee = {
			idNumber: employee?.idNumber || undefined,
			idFile: employee?.idFile?.map((file: any) => ({
				extraData: file,
			})) || [{}],
			firstName: employee?.firstName || undefined,
			lastName: employee?.lastName || undefined,
			birthDate: employee?.birthDate || undefined,
			immigrationDate: employee?.immigrationDate || undefined,
			passport: employee?.passport || undefined,
			address: {
				street: employee?.address?.street || undefined,
				houseNumber: employee?.address?.houseNumber || undefined,
				city: employee?.address?.city || undefined,
				postalCode: employee?.address?.postalCode || undefined,
			},
			phone: employee?.phone || undefined,
			cellPrefix: employee?.cellPrefix || undefined,
			cellPhone: employee?.cellPhone || undefined,
			gender: employee?.gender || undefined,
			genderFile:
				employee?.genderFile?.map((file: any) => ({
					extraData: file,
				})) || (employee?.maritalStatus === 'SEPARATED' ? [{}] : []),
			maritalStatus: employee?.maritalStatus || undefined,
			israeliResident: employee?.israeliResident ?? undefined,
			kibbutzMember: employee?.kibbutzMember ?? undefined,
			revenuesTransferredToKibbutz: employee?.revenuesTransferredToKibbutz || false,
			healthOrgMember: employee?.healthOrgName
				? true
				: employee?.healthOrgMember ?? undefined,
			healthOrgName: employee?.healthOrgName || undefined,
			email: employee?.email || undefined,
			bankId: employee?.bankId || undefined,
			branchId: employee?.branchId || undefined,
			accountNumber: employee?.accountNumber || undefined,
			comment: employee?.comment || undefined,
		};
		this.children =
			children?.map((child: any) => ({
				id: child.id || undefined,
				idNumber: child.idNumber || undefined,
				name: child.name || undefined,
				birthDate: child.birthDate || undefined,
				myCustody: child.myCustody ?? undefined,
				childAllowance: child.childAllowance ?? undefined,
				singleParent: child.singleParent ?? undefined,
				comment: child?.comment ?? undefined,
			})) || [];
		this.currentEmployerIncome = {
			startDate: currentEmployerIncome?.startDate || undefined,
			incomeType: currentEmployerIncome?.incomeType || undefined,
			additionalIncomeType: currentEmployerIncome?.additionalIncomeType
				?.length
				? currentEmployerIncome?.additionalIncomeType
				: undefined,
			allowUpdateIncomeStartDate:
				currentEmployerIncome?.allowUpdateIncomeStartDate || false,
			comment: currentEmployerIncome?.comment || undefined,
		};
		this.otherEmployerIncome = {
			otherEmployerIncome:
				otherEmployerIncome?.otherEmployerIncome ?? undefined,
			incomeType: otherEmployerIncome?.incomeType || undefined,
			taxCreditRequest:
				otherEmployerIncome?.taxCreditRequest ?? undefined,
			noKerenHishtalmutPayment:
				otherEmployerIncome?.noKerenHishtalmutPayment || undefined,
			noDisabilityPayment:
				otherEmployerIncome?.noDisabilityPayment || undefined,
			comment: otherEmployerIncome?.comment || undefined,
		};
		this.spouse = {
			idNumber:
				spouse?.identityType === 'ID'
					? spouse?.idNumber || undefined
					: spouse?.passport || undefined,
			firstName: spouse?.firstName || undefined,
			lastName: spouse?.lastName || undefined,
			birthDate: spouse?.birthDate || undefined,
			immigrationDate: spouse?.immigrationDate || undefined,
			hasIncome: spouse?.hasIncome ?? undefined,
			incomeType: spouse?.incomeType || undefined,
			comment: spouse?.comment || undefined,
			identityType: spouse?.identityType || undefined,
		};
		const taxCreditAddress =
			taxCreditReason?.taxCreditAddressName &&
			taxCreditAddressList?.length &&
			taxCreditAddressList.find(
				(address: any) =>
					address.address === taxCreditReason?.taxCreditAddressName ||
					address.addressCode ===
						taxCreditReason?.taxCreditAddressCode,
			);
		const taxCreditAddressName = taxCreditAddressList?.length
			? taxCreditAddress
				? taxCreditAddress.address
				: undefined
			: taxCreditReason?.taxCreditAddressName;
		
		this.taxCreditReason = {
			...(isViewer ? taxCreditReason : {}),
			israeliResident: taxCreditReason?.israeliResident ?? undefined,
			handicapped: taxCreditReason?.handicapped || false,
			handicappedFile:
				taxCreditReason?.handicappedFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.handicapped ? [{}] : []),
			handicappedCompensation: taxCreditReason?.handicappedCompensation || false,
			handicappedCompensationFile: 
				taxCreditReason?.handicappedCompensationFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.handicappedCompensation ? [{}] : []),
			taxCreditAddress: taxCreditReason?.taxCreditAddress || false,
			taxCreditAddressFromDate:
				taxCreditReason?.taxCreditAddressFromDate || undefined,
			taxCreditAddressName: taxCreditAddressName || undefined,
			taxCreditAddressCode:
				(taxCreditAddress && taxCreditAddress?.addressCode) ||
				undefined,
			taxCreditAddressFile:
				taxCreditReason?.taxCreditAddressFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.taxCreditAddress ? [{}] : []),
			immigrant: taxCreditReason?.immigrant || false,
			immigrantFromDate: taxCreditReason?.immigrantFromDate || undefined,
			immigrantFile:
				taxCreditReason?.immigrantFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.immigrant ? [{}] : []),
			noIncome: !taxCreditReason?.immigrant
				? false
				: taxCreditReason?.noIncome || false,
			noIncomeUntilDate: !taxCreditReason?.immigrant
				? undefined
				: taxCreditReason?.noIncomeUntilDate || undefined,
			spouseHasNoIncome: taxCreditReason?.spouseHasNoIncome || false,
			spouseHasNoIncomeFile:
				taxCreditReason?.spouseHasNoIncomeFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.spouseHasNoIncome ? [{}] : []),
			singleParentLivingSeparately:
				taxCreditReason?.singleParentLivingSeparately || false,
			childrenNotInMyCustody:
				taxCreditReason?.childrenNotInMyCustody || false,
			childrenNotInMyCustodyFile:
				taxCreditReason?.childrenNotInMyCustodyFile?.map(
					(file: any) => ({ extraData: file }),
				) || (taxCreditReason?.childrenNotInMyCustody ? [{}] : []),
			childrenWithDisability:
				taxCreditReason?.childrenWithDisability || false,
			numberOfChildrenWithDisability:
				taxCreditReason?.numberOfChildrenWithDisability || undefined,
			childrenWithDisabilityFile:
				taxCreditReason?.childrenWithDisabilityFile?.map(
					(file: any) => ({ extraData: file }),
				) || (taxCreditReason?.childrenWithDisability ? [{}] : []),
			alimonyPayment: taxCreditReason?.alimonyPayment || false,
			alimonyPaymentFile:
				taxCreditReason?.alimonyPaymentFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.alimonyPayment ? [{}] : []),
			dischargedSoldier: taxCreditReason?.dischargedSoldier || false,
			serviceStart: taxCreditReason?.serviceStart || undefined,
			serviceEnd: taxCreditReason?.serviceEnd || undefined,
			serviceFile:
				taxCreditReason?.serviceFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.dischargedSoldier ? [{}] : []),
			academicDegree: taxCreditReason?.academicDegree || false,
			academicDegreeFile:
				taxCreditReason?.academicDegreeFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditReason?.academicDegree ? [{}] : []),
			comment: taxCreditReason?.comment || undefined,
		};

		this.taxCreditRequest = {
			wantTaxCredit:
				taxCreditRequest?.noIncome ||
				taxCreditRequest?.hasAdditionalIncome ||
				taxCreditRequest?.taxAssessorApproved
					? true
					: taxCreditRequest?.wantTaxCredit || false,
			taxCreditRequestReason: taxCreditRequest?.noIncome
				? 'noIncome'
				: taxCreditRequest?.hasAdditionalIncome
				? 'additionalIncome'
				: taxCreditRequest?.taxAssessorApproved
				? 'taxAssessorApproved'
				: undefined,
			noIncome: taxCreditRequest?.noIncome ?? undefined,
			noIncomeFile:
				taxCreditRequest?.noIncomeFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditRequest?.noIncome ? [{}] : []),
			hasAdditionalIncome:
				taxCreditRequest?.hasAdditionalIncome ?? undefined,
			additionalIncome: taxCreditRequest?.additionalIncome?.map(
				(income: any) => ({
					id: income.id || undefined,
					name: income.name || undefined,
					address: income.address || undefined,
					deductionFileId: income.deductionFileId || undefined,
					incomeType: income.incomeType || undefined,
					monthlyIncome: income.monthlyIncome || undefined,
					taxReduce: income.taxReduce || undefined,
					additionalIncomeFile: income.additionalIncomeFile?.map(
						(file: any) => ({ extraData: file }),
					) || [{}],
					comment: income?.comment ?? undefined,
				}),
			),
			taxAssessorApproved:
				taxCreditRequest?.taxAssessorApproved ?? undefined,
			taxAssessorApprovedFile:
				taxCreditRequest?.taxAssessorApprovedFile?.map((file: any) => ({
					extraData: file,
				})) || (taxCreditRequest?.taxAssessorApproved ? [{}] : []),
			comment: taxCreditRequest?.comment || undefined,
		};
	}
}

export class ServerWorkerCardModel {
	public employer: {
		name: string;
		address: string;
		phone: string;
		deductionFileId: string;
	};
	public employee: {
		idNumber: string;
		firstName: string;
		lastName: string;
		birthDate: string;
		immigrationDate: string;
		passport: string;
		address: {
			street: string;
			houseNumber: string;
			city: string;
			postalCode: string;
		};
		phone: string;
		cellPrefix: string;
		cellPhone: string;
		gender: 'MALE' | 'FEMALE';
		maritalStatus:
			| 'SINGLE'
			| 'MARRIED'
			| 'WIDOWED'
			| 'DIVORCED'
			| 'SEPARATED';
		israeliResident: boolean;
		kibbutzMember: boolean;
		revenuesTransferredToKibbutz: boolean;
		healthOrgMember: boolean;
		healthOrgName: 'MACCABI' | 'CLALIT' | 'MEUHEDET' | 'LEUMIT';
		email: string;
		bankId: string;
		branchId: string;
		accountNumber: string;
	};
	public hasChildren: boolean;
	public children: {
		id: number;
		idNumber: string;
		name: string;
		birthDate: string;
		myCustody: boolean;
		childAllowance: boolean;
		singleParent: boolean;
	}[];
	public currentEmployerIncome: {
		startDate: string;
		incomeType:
			| 'MONTHLY_SALARY'
			| 'ADDITIONAL_POSITION_SALARY'
			| 'PARTIAL_SALARY'
			| 'DAILY_WORKER';
		additionalIncomeType: string[];
	};
	public otherEmployerIncome: {
		otherEmployerIncome: boolean;
		incomeType: string[];
		taxCreditRequest: boolean;
		noKerenHishtalmutPayment: boolean;
		noDisabilityPayment: boolean;
	};
	public spouse: {
		idNumber: string;
		passport: string;
		firstName: string;
		lastName: string;
		birthDate: string;
		immigrationDate: string;
		hasIncome: boolean;
		incomeType: 'WORK_ALLOWANCE_BUSINESS' | 'OTHER';
		identityType: 'ID' | 'PASSPORT';
	};
	public taxCreditReason: {
		israeliResident: boolean;
		handicapped: boolean;
		taxCreditAddress: boolean;
		taxCreditAddressFromDate: string;
		taxCreditAddressName: string;
		taxCreditAddressCode: string;
		immigrant: boolean;
		immigrantFromDate: string;
		noIncome: boolean;
		noIncomeUntilDate: string;
		spouseHasNoIncome: boolean;
		singleParentLivingSeparately: boolean;
		childrenNotInMyCustody: boolean;
		childrenWithDisability: boolean;
		numberOfChildrenWithDisability: number;
		alimonyPayment: boolean;
		dischargedSoldier: boolean;
		serviceStart: string;
		serviceEnd: string;
		academicDegree: boolean;
	};

	public taxCreditRequest: {
		wantTaxCredit: boolean;
		noIncome: boolean;
		hasAdditionalIncome: boolean;
		additionalIncome: {
			name: string;
			address: string;
			deductionFileId: string;
			incomeType: 'WORK' | 'ALLOWANCE' | 'SCHOLARSHIP' | 'OTHER';
			monthlyIncome: string;
			taxReduce: string;
		}[];
		taxAssessorApproved: boolean;
	};

	public signature?: string;

	constructor(props?: any) {
		const {
			employer,
			employee,
			currentEmployerIncome,
			otherEmployerIncome,
			spouse,
			taxCreditReason,
			taxCreditRequest,
			children,
			signature,
			hasChildren,
		} = props || {};

		this.hasChildren = hasChildren ?? null;
		this.signature = signature || null;
		this.employer = {
			name: employer?.name || undefined,
			address: employer?.address || undefined,
			phone: employer?.phone || undefined,
			deductionFileId: employer?.deductionFileId || undefined,
		};
		this.employee = {
			idNumber: employee?.idNumber || undefined,
			firstName: employee?.firstName || undefined,
			lastName: employee?.lastName || undefined,
			birthDate: employee?.birthDate || null,
			immigrationDate: employee?.immigrationDate || null,
			passport: employee?.passport || null,
			address: {
				street: employee?.address?.street || null,
				houseNumber: employee?.address?.houseNumber || null,
				city: employee?.address?.city || null,
				postalCode: employee?.address?.postalCode || null,
			},
			phone: employee?.phone || null,
			cellPrefix: employee?.cellPrefix || null,
			cellPhone: employee?.cellPhone || null,
			gender: employee?.gender || null,
			maritalStatus: employee?.maritalStatus || null,
			israeliResident: employee?.israeliResident ?? null,
			kibbutzMember: employee?.kibbutzMember ?? null,
			revenuesTransferredToKibbutz: employee?.revenuesTransferredToKibbutz || false,
			healthOrgMember: employee?.healthOrgMember ?? null,
			healthOrgName: employee?.healthOrgName || null,
			email: employee?.email || null,
			bankId: employee?.bankId || null,
			branchId: employee?.branchId || null,
			accountNumber: employee?.accountNumber || null,
		};
		this.children =
			children?.map((child: any) => ({
				id: child.id || null,
				idNumber: child.idNumber || null,
				name: child.name || null,
				birthDate: child.birthDate || null,
				myCustody: child.myCustody ?? null,
				childAllowance: child.childAllowance ?? null,
				singleParent: child.singleParent ?? null,
				comment: child.comment ?? null,
			})) || [];
		this.currentEmployerIncome = {
			startDate: currentEmployerIncome?.startDate || null,
			incomeType: currentEmployerIncome?.incomeType || null,
			additionalIncomeType:
				currentEmployerIncome?.additionalIncomeType || null,
		};
		this.otherEmployerIncome = {
			otherEmployerIncome:
				otherEmployerIncome?.otherEmployerIncome || null,
			incomeType: otherEmployerIncome?.incomeType || null,
			taxCreditRequest: otherEmployerIncome?.taxCreditRequest ?? null,
			noKerenHishtalmutPayment:
				otherEmployerIncome?.noKerenHishtalmutPayment ?? null,
			noDisabilityPayment:
				otherEmployerIncome?.noDisabilityPayment || null,
		};
		this.spouse = {
			idNumber:
				spouse?.identityType === 'ID' ? spouse?.idNumber || null : null,
			passport:
				spouse?.identityType === 'PASSPORT'
					? spouse?.idNumber || undefined
					: null,
			firstName: spouse?.firstName || null,
			lastName: spouse?.lastName || null,
			birthDate: spouse?.birthDate || null,
			immigrationDate: spouse?.immigrationDate || null,
			hasIncome: spouse?.hasIncome ?? null,
			incomeType: spouse?.incomeType || null,
			identityType: spouse?.identityType || null,
		};
		this.taxCreditReason = {
			israeliResident: taxCreditReason?.israeliResident || null,
			handicapped: taxCreditReason?.handicapped || false,
			taxCreditAddress: taxCreditReason?.taxCreditAddress || false,
			taxCreditAddressFromDate:
				taxCreditReason?.taxCreditAddressFromDate || null,
			taxCreditAddressName: taxCreditReason?.taxCreditAddressName || null,
			taxCreditAddressCode: taxCreditReason?.taxCreditAddressCode || null,
			immigrant: taxCreditReason?.immigrant || false,
			immigrantFromDate: taxCreditReason?.immigrantFromDate || null,
			noIncome: taxCreditReason?.noIncomeUntilDate || false,
			noIncomeUntilDate: taxCreditReason?.noIncomeUntilDate || null,
			spouseHasNoIncome: taxCreditReason?.spouseHasNoIncome || false,
			singleParentLivingSeparately:
				taxCreditReason?.singleParentLivingSeparately || false,
			childrenNotInMyCustody:
				taxCreditReason?.childrenNotInMyCustody || false,
			childrenWithDisability:
				taxCreditReason?.childrenWithDisability || false,
			numberOfChildrenWithDisability:
				taxCreditReason?.numberOfChildrenWithDisability || null,
			alimonyPayment: taxCreditReason?.alimonyPayment || false,
			dischargedSoldier: taxCreditReason?.dischargedSoldier || false,
			serviceStart: taxCreditReason?.serviceStart || null,
			serviceEnd: taxCreditReason?.serviceEnd || null,
			academicDegree: taxCreditReason?.academicDegree || false,
		};
		this.taxCreditRequest = {
			wantTaxCredit:
				taxCreditRequest?.noIncom ||
				taxCreditRequest?.hasAdditionalIncome ||
				taxCreditRequest?.taxAssessorApproved
					? true
					: false,
			noIncome: taxCreditRequest?.noIncome ?? null,
			hasAdditionalIncome: taxCreditRequest?.hasAdditionalIncome ?? null,
			additionalIncome: taxCreditRequest?.additionalIncome?.map(
				(income: any) => ({
					name: income.name || null,
					address: income.address || null,
					deductionFileId: income.deductionFileId || null,
					incomeType: income.incomeType || null,
					monthlyIncome: income.monthlyIncome || null,
					taxReduce: income.taxReduce || null,
				}),
			),
			taxAssessorApproved: taxCreditRequest?.taxAssessorApproved ?? null,
		};
	}
}
