import React, { useState, useEffect, useRef } from 'react';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	FlatList,
	useWindowDimensions,
	Platform,
} from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import {
	FilterData,
	FilterTypes,
} from '../../components/web/Filters/Filters.container';

import Drawer from 'react-native-drawer';
import Typography from '../../components/shared/Typography/Typography.component';
import {
	Report101FiltersDataType,
	Report101FiltersType,
	statusReport101MatcherByResolve,
	SubMenuMobileItem,
} from './Report101Page.utils';
import colors from '../../config/colors';

const drawerStyles = {
	drawer: {
		// shadowColor: '#000000',
		// shadowOpacity: 0.52,
		// shadowRadius: 30,
		// boxShadow: '-20px 0px 20px 20px black',
		height: '100%',
		backgroundColor: 'white',
	},
	// main: { paddingLeft: 3 },
};

const Report101MobileDrawer = (props: any) => {
	const closeControlPanel = () => {
		props.onToggleSubMenu(false);
	};
	const ref = useRef();

	return (
		<Drawer
			styles={drawerStyles}
			type='overlay'
			openDrawerOffset={(viewport: any) => viewport.width - 300}
			side={'right'}
			open={props.isOpen}
			ref={ref}
			tapToClose={true}
			onClose={closeControlPanel}
			content={
				<DrawerContentSelectWrapper
					config={props.config}
					onFilter={data => {
						props.onFilter(data);
						// closeControlPanel();
					}}
					onClose={closeControlPanel}
					selectedFilters={props.selectedFilters}
				/>
			}
		>
			{props.children}
		</Drawer>
	);
};

export default Report101MobileDrawer;

type drawerContentType = {
	config: FilterConfigType[];
	onFilter: (data: FilterData) => void; // (el: string) => void;
	onClose: () => void;
	selectedFilters: Report101FiltersDataType;
};

const DrawerContentSelectWrapper = ({
	config,
	onFilter,
	onClose,
	selectedFilters,
}: drawerContentType) => {
	const [filterContentMapperValue, setFilterContentMapperValue] =
		useState<string>('default');

	return (
		<>
			{filterContentMapperValue === 'default' && (
				<DrawerContentDefaultFilters
					config={config}
					setFilterContentMapper={setFilterContentMapperValue}
					onClose={onClose}
				/>
			)}
			{filterContentMapperValue != 'undefined' &&
				filterContentMapperValue != 'default' && (
					<View>
						<DrawerContentSelectedFilters
							config={config}
							filterContentMapperValue={filterContentMapperValue}
							setFilterContentMapperValue={
								setFilterContentMapperValue
							}
							onFilter={onFilter}
							selectedFilters={selectedFilters}
						/>
					</View>
				)}
		</>
	);
};

type FilterConfigType = {
	type: FilterTypes;
	name: string;
	options: { id: string; name: string }[];
	placeholder?: string;
	styles?: { width: number };
	key?: string;
	// onSearchTextChange?: (text: string) => void
};

const DrawerContentDefaultFilters = ({
	config,
	setFilterContentMapper,
	onClose,
}: {
	config: FilterConfigType[];
	setFilterContentMapper: (field: string) => void;
	onClose: () => void;
}) => {
	return (
		<View style={styles.filtersContainer}>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => {
					onClose();
				}}
			>
				<View style={styles.title}>
					<I18n
						weight='normal'
						size={30}
						style={{ marginBottom: 19 }}
					>
						{'report101ReportPage.mobile.filters.title'}
					</I18n>
				</View>
			</TouchableHighlight>
			<View style={styles.separator}></View>
			{config?.map(el => {
				if (typeof el.name === 'string' && el.name != 'search') {
					return (
						<View key={el.name}>
							<TouchableHighlight
								underlayColor='transparent'
								onPress={() => {
									setFilterContentMapper(el.name);
								}}
							>
								<View style={styles.itemContainer}>
									{el.name === 'year' ? (
										<ImageIcon
											name={'calendar'}
											width={22}
											height={18}
										/>
									) : (
										<ImageIcon
											name={'leftArrow'}
											width={15}
											height={20}
										/>
									)}
									<I18n weight='normal' size={20}>
										{`report101ReportPage.mobile.filters.${el.name}`}
									</I18n>
								</View>
							</TouchableHighlight>
							<View style={styles.separator}></View>
						</View>
					);
				}
			})}
		</View>
	);
};

const DrawerContentSelectedFilters = ({
	config,
	filterContentMapperValue,
	setFilterContentMapperValue,
	onFilter,
	selectedFilters,
}: {
	config: FilterConfigType[];
	filterContentMapperValue: string;
	setFilterContentMapperValue: (field: string) => void;
	onFilter: (data: FilterData) => void;
	selectedFilters: Report101FiltersDataType;
}) => {
	const dimentions = useWindowDimensions();

	const [chosenRowsValue, setChosenRowsValue] = useState<string[]>([]);

	const selects =
		selectedFilters[filterContentMapperValue as Report101FiltersType];

	useEffect(() => {
		if (filterContentMapperValue === 'status') {
			const mappedArray: string[] = [];
			(selectedFilters['status'] as string[]).map(el => {
				const selectedItem = statusReport101MatcherByResolve(el);
				mappedArray.push(selectedItem as string);
			});

			setChosenRowsValue(mappedArray);
		} else setChosenRowsValue(selects as []);
	}, [filterContentMapperValue, selectedFilters]);

	const acceptFiltersButtonHandler = () => {
		const filteredRows = chosenRowsValue.reduce(
			(acc: FilterData, el: any) => {
				acc.value = [...acc.value, { id: el, name: el }];
				return acc;
			},
			{
				value: [],
				name: filterContentMapperValue,
				isMulti: true,
			} as FilterData,
		);
		onFilter(filteredRows);
	};

	const removeFiltersButtonHandler = () => {
		const clearFilter = {
			value: [],
			name: filterContentMapperValue,
			isMulti: true,
		};
		onFilter(clearFilter);
	};

	const selectedFilterSubMenu = config.filter(
		el => el.name != 'search' && el.name === filterContentMapperValue,
	);

	const subMenuFlatListItemHandler = (item: any) => {
		return (
			<SubMenuMobileItem
				item={item.item}
				filterContentMapperValue={filterContentMapperValue}
				chosenRowsValue={chosenRowsValue}
				setChosenRowsValue={setChosenRowsValue}
				isSingle={selectedFilterSubMenu?.[0].type === 'select'}
			/>
		);
	};

	return (
		<View style={styles.filtersSubMenuContainer}>
			<View style={styles.titleSubMenu}>
				<I18n weight='normal' size={28} style={{ marginBottom: 19 }}>
					{'report101ReportPage.mobile.filters.title'}
				</I18n>
				<View
					style={{
						marginBottom: 25,
						marginLeft: 10,
						marginRight: 10,
					}}
				>
					<ImageIcon name={'leftArrow'} width={15} height={20} />
				</View>

				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => setFilterContentMapperValue('default')}
				>
					<I18n
						weight='normal'
						color='primary'
						size={28}
						style={{ marginBottom: 19 }}
					>
						{`report101ReportPage.mobile.filters.${filterContentMapperValue}`}
					</I18n>
				</TouchableHighlight>
			</View>
			<View style={styles.separator}></View>

			<View>
				<FlatList
					data={selectedFilterSubMenu[0]?.options}
					renderItem={subMenuFlatListItemHandler}
					contentContainerStyle={{
						height:
							dimentions.height -
							(Platform.OS === 'web' ? 200 : 290),
					}}
					keyExtractor={item => item.id}
				/>
			</View>
			<View style={styles.separator}></View>
			<View style={styles.manageSelectButtonsContainer}>
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => {
						removeFiltersButtonHandler();
					}}
				>
					<View style={styles.resetSelectButton}>
						<Typography
							style={{ marginLeft: 20, marginRight: 20 }}
							color={'lightPrimary'}
							weight='400'
							size={14}
						>
							{`ביטול`}
						</Typography>
					</View>
				</TouchableHighlight>
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => {
						acceptFiltersButtonHandler();
					}}
				>
					<View style={styles.approveSelectButton}>
						<Typography
							style={{ marginLeft: 20, marginRight: 20 }}
							color={'white'}
							weight='400'
							size={14}
						>
							{`שמירה`}
						</Typography>
					</View>
				</TouchableHighlight>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		width: '100%',
		height: '100%',
		// alignItems: 'center',
		// justifyContent: 'center',
		zIndex: 1000,
	},
	animatedBox: {
		flex: 1,
		width: '100%',
		// backgroundColor: '#38C8EC',
		backgroundColor: '#000000',
		// padding: 10,
	},
	body: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#F04812',
	},
	separator: {
		backgroundColor: 'rgb(82, 89, 98)',
		height: 1,
		opacity: 0.2,
	},
	filtersContainer: {
		backgroundColor: 'white',
		height: '100%',
	},
	filtersSubMenuContainer: {
		backgroundColor: 'white',
		height: '100%',
	},
	title: {
		display: 'flex',
		height: 140,
		justifyContent: 'flex-end',
		marginRight: 28,
	},
	titleSubMenu: {
		display: 'flex',
		marginRight: 28,
		height: 140,
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
	itemContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginRight: 28,
		marginLeft: 17,
		height: 65,
	},
	manageSelectButtonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
	},
	approveSelectButton: {
		height: 40,
		marginLeft: 18,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		justifyContent: 'center',
	},
	resetSelectButton: {
		height: 40,
		backgroundColor: colors.white,
		borderRadius: 45,
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: '#4aaeef',
	},
});
