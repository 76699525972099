import moment from 'moment';
import React, {
	forwardRef,
	useMemo,
	useRef,
	useState,
	useCallback,
} from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import he from 'date-fns/locale/he';
import { i18nService } from '../../../services/i18n.service';
import ImageIcon from '../../shared/Icon/ImageIcon.component';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core';
import colors from '../../../config/colors';

registerLocale('he', he);

const useStyles = makeStyles(() => ({
	picker: {
		width: 140,
		height: 32,
		fontSize: 14,
		backgroundColor: colors.white,
		flexDirection: 'column',
		color: colors.darkGrey,
		borderColor: colors.filterBorder,
		borderWidth: 1,
		borderRadius: 2,
		fontFamily: 'RubikRegular',
		boxSizing: 'border-box',
		boxShadow: 'none',
		outline: 'none',
		'-webkit-appearance': 'none',
		'&:focus': {
			outline: 'none',
			borderColor: colors.filterBorder,
		},
	},
	popper: {
		'& .react-datepicker__year-wrapper': {
			justifyContent: 'center',
		},
		'& .react-datepicker__monthPicker': {
			display: 'flex',
			flexDirection: 'column',
			padding: '0.25rem 0.5rem',
		},
		'& .react-datepicker__month-container': {
			width: '140px',
		},
		'& .react-datepicker__month-wrapper': {
			display: 'flex',
			flexDirection: 'column',
		},
		'& .react-datepicker__month .react-datepicker__month-text': {
			margin: '2px 0',
			width: '100%',
		},
	},
}));

type Config = {
	name: string;
	type: string;
	styles?: any;
	extraProps?: any;
	placeholder?: string;
	date?: Date;
	showYearPicker: boolean;
	showQuarterYearPicker: boolean;
	label?: string;
	minDate?: Date;
	maxDate?: Date;
	dashboardViewMode?: boolean;
	onlyMonthYear: boolean;
};

type DatePickerProps = {
	config: Config;
	disabled?: boolean;
	onChange: (data: any) => void;
};

const DatePicker = ({ config, onChange }: DatePickerProps) => {
	const defaultDate = config.date ?? new Date();
	const [startDate, setStartDate] = useState(defaultDate);
	const handleCalendarOpen = useCallback(() => {
		if (config.showQuarterYearPicker) {
			setLocaleToQuarterManually();
		}
	}, [config.showQuarterYearPicker]);

	let isOpen = useMemo(() => false, []);
	const pickerRef = useRef<any>();
	const { extraProps = {} } = config;
	const classes = useStyles();
	const placeholder = config?.placeholder
		? i18nService.translate(config?.placeholder)
		: i18nService.translate(
				`${
					config.dashboardViewMode
						? 'months'
						: config.onlyMonthYear
						? 'filter.datepicker.onlyMonth'
						: 'filter.datepicker'
				}.${startDate.toLocaleString('default', {
					month: 'long',
				})}`,
		  ) + ` ${startDate.getFullYear()}`;

	const CustomInput = forwardRef(({ placeholder, onClick }, ref) => {
		return (
			<div
				ref={ref}
				onClick={() => {
					if (isOpen) {
						pickerRef?.current?.setOpen(false);
						isOpen = false;
					} else {
						onClick();
						isOpen = true;
					}
				}}
				style={
					config.dashboardViewMode
						? {
								flexDirection: 'row',
								fontFamily: 'RubikMedium',
								fontSize: 22,
								alignItems: 'center',
								color: colors.darkGrey,
								display: 'flex',
						  }
						: {
								direction: 'rtl',
								display: 'flex',
								cursor: 'pointer',
								alignItems: 'center',
								boxSizing: 'border-box',
								justifyContent: 'space-between',
								background: '#fff',
								border: '1px solid #e7e7e8',
								height: '32px',
								padding: '0 5px',
								fontFamily: 'RubikRegular',
								fontSize: '0.875rem',
								width: '150px',
						  }
				}
			>
				<span style={{ marginLeft: config.dashboardViewMode ? 9 : 0 }}>
					{placeholder}
				</span>
				<ImageIcon
					name={!config.dashboardViewMode ? 'calendar_web' : ''}
					src={
						config.dashboardViewMode &&
						require('../../../../assets/icons/downArrow/downArrow@3x.png')
					}
					width={config.dashboardViewMode ? 16 : 20}
					height={config.dashboardViewMode ? 9 : 20}
				/>
			</div>
		);
	});

	const setLocaleToQuarterManually = () => {
		const elems = document.getElementsByClassName(
			'react-datepicker__quarter-text',
		);
		for (let item of Array.from(elems)) {
			const oldInnerHtml = item.innerHTML;
			const arr = oldInnerHtml.split('');
			const newInnerHtml = 'רבעון ' + arr[1];
			item.innerHTML = newInnerHtml;
		}
	};

	const onSelect = (date: Date) => {
		setStartDate(date);
		onChange({
			name: config.name,
			value: date,
		});
	};
	const dateFormat = useMemo(() => {
		if (config.showQuarterYearPicker) {
			return 'yyyy, QQQ';
		}
		if (config.showYearPicker) {
			return 'yyyy';
		}

		return 'MM/yyyy';
	}, [config.showQuarterYearPicker, config.showYearPicker]);

	return (
		<div
			style={{
				direction: 'rtl',
				pointerEvents: extraProps?.disabled ? 'none' : 'auto',
			}}
		>
			<ReactDatePicker
				ref={pickerRef}
				minDate={config.minDate ? config.minDate : null}
				maxDate={config.maxDate ? config.maxDate : null}
				selected={config.date ? config.date : startDate}
				customInput={<CustomInput />}
				onChange={onSelect}
				placeholderText={placeholder}
				dateFormat={dateFormat}
				className={classes.picker}
				showMonthYearPicker={
					config.showQuarterYearPicker || config.showYearPicker
						? false
						: true
				}
				showQuarterYearPicker={config.showQuarterYearPicker}
				showYearPicker={config.showYearPicker}
				onCalendarOpen={handleCalendarOpen}
				popperClassName={classes.popper}
				locale='he'
				{...extraProps}
			/>
		</div>
	);
};

export default DatePicker;
