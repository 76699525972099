import { useNavigation } from '@react-navigation/core';
import { useRoute, CommonActions } from '@react-navigation/native';
import _, { compact, isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { UserRolesNames } from '../../../../config/routes';
import {
	getUserData,
	getUserTypes,
} from '../../../../store/selectors/login.selectors';
import { DoubleButton } from './DoubleButton.component';

type DoubleButtonContainerProps = {
	styleConf: { width: number; height: number; textSize: number };
	onSelect?: (user: string) => void;
};

const getPortalNavigationTabs = (navigation: any): any => {
	const state = navigation.getState();
	if (
		isEqual(state.routeNames, ['employee', 'employer', 'representative']) ||
		isEqual(state.routeNames, ['employee', 'employer']) ||
		isEqual(state.routeNames, ['employer', 'representative']) ||
		isEqual(state.routeNames, ['employee', 'representative'])
	) {
		return state;
	}
	const parent = navigation.getParent();
	return parent ? getPortalNavigationTabs(parent) : undefined;
};

export const DoubleButtonContainer = ({
	onSelect,
	styleConf,
}: DoubleButtonContainerProps) => {
	const navigation = useNavigation();
	const userData = useSelector(getUserData);
	const { isEmployer, isEmployee, isRep } = useSelector(getUserTypes);
	const isMobile = useMemo(
		() => Platform.OS === 'android' || Platform.OS === 'ios',
		[Platform],
	);

	const buttons = useMemo(
		() =>
			compact([
				isRep && { name: 'representative' },
				isEmployer && { name: 'employer' },
				isEmployee && { name: 'employee' },
			]),
		[isEmployer, isEmployee, isRep],
	);

	const route = useRoute();
	const [activeButton, setActiveButton] = useState<string>(route.name);

	useEffect(() => {
		const navigationState = getPortalNavigationTabs(navigation);
		const currentRouteRole =
			navigationState?.routeNames[navigationState?.index];
		const buttonNames = buttons.map(btn => btn.name);

		if (!buttonNames.includes(currentRouteRole)) {
			navigation.dispatch(
				CommonActions.reset({
					index: 0,
					routes: [{ name: UserRolesNames.EMPLOYEE }],
				}),
			);
		} else {
			setActiveButton(currentRouteRole);
			onSelect?.(currentRouteRole);
		}
	}, [route, userData]);

	const onButtonClick = (name: string) => {
		navigation.dispatch(
			CommonActions.reset({
				index: 0,
				routes: [{ name }],
			}),
		);
		onSelect?.(name);
	};

	return buttons.length > 1 ? (
		<DoubleButton
			activeButton={activeButton}
			onButtonClick={onButtonClick}
			buttons={buttons}
			stylesConf={styleConf}
		/>
	) : null;
};
