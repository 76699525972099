import React, { useEffect, useState } from 'react';
import { GOOGLE_API_KEY, GEOCODING_LANG } from '@env';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../../services/i18n.service';

const GooglePlacesInput = ({
	onChange,
	onBlur,
	error,
	style = {},
	value,
	autocompleteTypes,
	placeholder,
	getAllData,
	showFullName,
	staticWidth,
	isDisabled,
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		if (value !== inputValue) {
			onBlur();
		}
		setInputValue(value);
	}, [value]);

	return (
		<div
			style={{
				width: '100%',
				zIndex: 800,
				...style,
				pointerEvents: isDisabled ? 'none' : undefined,
			}}
		>
			<GooglePlacesAutocomplete
				key={value}
				apiKey={GOOGLE_API_KEY}
				apiOptions={{
					language: GEOCODING_LANG,
					region: GEOCODING_LANG,
				}}
				autocompletionRequest={{
					types: autocompleteTypes,
					// componentRestrictions: { country: 'il' },
				}}
				selectProps={{
					noOptionsMessage: () => null,
					loadingMessage: () => null,
					onChange: (data: any) => {
						const val = showFullName
							? data?.value?.description
							: data?.value?.structured_formatting?.main_text.trim();
						onChange(val);
						getAllData && getAllData(data);
					},
					placeholder: placeholder
						? i18nService.translate(placeholder)
						: '',
					// value,
					inputValue,
					onInputChange: (val, extra) => {
						if (
							!['input-blur', 'menu-close'].includes(extra.action)
						) {
							setInputValue(val);
						}
					},
					onFocus: () => {
						setInputValue(value);
					},
					blurInputOnSelect: true,
					menuIsOpen: true,
					onBlur: () => {
						setInputValue(value);
						onBlur();
					},
					styles: {
						menu: (provided: any, state: any) => ({
							...provided,
							zIndex: 999,
						}),
						indicatorsContainer: (base: any) => ({
							...base,
							height: 'inherit',
						}),
						dropdownIndicator: (base: any) => ({
							...base,
							visibility: 'hidden',
							color: colors.primary,
						}),
						indicatorSeparator: (provided: any) => ({
							...provided,
							visibility: 'hidden',
						}),
						clearIndicator: (base: any) => ({
							...base,
							visibility: 'hidden',
						}),
						control: (base: any) => ({
							...base,
							minWidth: 174,
							minHeight: 32,
							height: 40,
							borderWidth: 0,
							backgroundColor:
								colors[error ? 'brightRed' : 'lightBlue'],
							color: colors.darkGrey,
							borderBottomWidth: isMobile ? 1 : 0,
							borderColor: colors[error ? 'red' : 'primary'],
							borderRadius: 5,
							boxShadow: 'none',
							width: staticWidth ? 250 : '100%',
							direction: 'rtl',
							'&:hover': {
								borderColor: colors.filterBorder,
							},
						}),
						input: base => ({
							...base,
							fontSize: 14,
							fontFamily: 'RubikRegular',
							color: colors.darkGrey,
						}),
						placeholder: (provided: any) => ({
							...provided,
							fontSize: 14,
							fontFamily: 'RubikRegular',
							color: colors.darkGrey,
						}),
						option: (provided: any, state: any) => ({
							...provided,
							display: 'flex',
							alignItems: 'center',
							height: 29,
							marginTop: 6,
							textAlign: 'right',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							fontSize: 14,
							color: colors.darkGrey,
							fontFamily: 'RubikRegular',
							overflow: 'hidden',
							paddingLeft: 5,
							direction: 'rtl',
							backgroundColor: state.isSelected
								? colors.selectOptionSelected
								: state.isFocused
								? colors.selectOptionFocused
								: colors.white,
						}),
						singleValue: (provided: any) => ({
							...provided,
							fontSize: 14,
							color: colors.darkGrey,
							fontFamily: 'RubikRegular',
							visibility: 'visible',
						}),
					},
					// loadingMessage: () => 'טוען...',
					// noOptionsMessage: () => 'אין תוצאות',
				}}
				debounce={500} // debounce the requests in ms. Set to 0 to remove debounce. By default 0ms.
			/>
		</div>
	);
};

export default GooglePlacesInput;
