import { makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import { getFieldsConfig, FieldConfigType } from './UserDetailsSection.utils';
import { useFormContext } from 'react-hook-form';
import {
	getBussinesUnitsModules,
	getEmployerRolesPrivileges,
	getLoginData,
} from '../../../../store/selectors/login.selectors';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Platform, View } from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { Privileges } from '../../../../constants/roleTypes.constant';
import { hasPermissionOnBusinessUnit } from '../../../../utils/globals.utils';

const useStyles = makeStyles(() => ({
	bottom: {
		display: 'flex',
		marginTop: 40,
	},
	bottomSide: {
		flex: 1,
		flexDirection: 'column',
		display: 'flex',
	},
	rolesSelect: {
		minHeight: 268,
		borderRadius: 2,
		marginTop: 16,
		border: `solid 1px #e7e7e8`,
	},
	excluded: {
		marginTop: 5,
		display: 'flex',
		alignItems: 'center',
	},
	departmentsHeader: {
		color: colors.darkGrey,
		fontFamily: 'RubikMedium',
		fontSize: 20,
		marginBottom: 16,
		fontWeight: 'normal',
		marginTop: 0,
	},
	headerComment: {
		fontFamily: 'RubikRegular',
		fontSize: 14,
	},
	userDetailsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		width: '100%',
		marginTop: 14,
		paddingHorizontal: 20,
	},
	radioGroup: {
		'& label:first-child': {
			position: 'relative',
			right: 0,
			width: 60,
		},
		'& label:last-child': {
			position: 'relative',
			right: 339,
			top: -36,
		},
	},
	darkon: {
		marginLeft: '0 !important',
	},
	fieldWrapper: {
		//marginBottom: 20,
		marginLeft: 18,
	},
	teudatZeut: {
		marginLeft: 0,
	},
}));

const wrapperStyles = {
	width: 227,
};

const labelWrapperStyles = {
	flexDirection: 'row',
};

const WrapperComponent = ({ children }: any) => {
	const classes = useStyles();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	if (isMobile) {
		return (
			<View
				style={{
					//paddingBottom: 70,
					paddingHorizontal: 20,
					width: '100%',
					flexWrap: 'wrap',
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					zIndex: 1,
				}}
			>
				{children}
			</View>
		);
	}
	return <div className={classes.userDetailsContainer}>{children}</div>;
};

const FieldWrapper = ({ children, field }: any) => {
	const classes = useStyles();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	if (isMobile) {
		return (
			<View
				style={{
					width:
						field.name === 'phone'
							? '65%'
							: field.name === 'phone_prefix'
							? '30%'
							: '100%',
					marginTop:
						field.name === 'phone_prefix'
							? Platform.OS === 'web'
								? 16
								: 3
							: field.name === 'first_name'
							? 20
							: 0,
					marginBottom:
						'identity_type' === field.name
							? Platform.OS === 'web'
								? 10
								: -5
							: 'teudat_zeut' === field.name
							? 0
							: field.name === 'phone_prefix'
							? 10
							: 20,
					zIndex: Platform.OS === 'web' ? 'unset' : undefined,
				}}
			>
				{children}
			</View>
		);
	}
	return (
		<div
			className={classnames({
				[classes.fieldWrapper]: classes.fieldWrapper,
				[classes.teudatZeut]:
					field.name === 'teudat_zeut' ? classes.teudatZeut : null,
				[classes.darkon]:
					field.name === 'darkon' ? classes.darkon : null,
			})}
		>
			{children}
		</div>
	);
};

const UserDetailsSection = ({ isActive, isEditUser, roles }: any) => {
	const { watch } = useFormContext();
	const employerRolePrivileges = useSelector(getEmployerRolesPrivileges);
	const modules = useSelector(getBussinesUnitsModules);
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const watchIdentityType = watch('identity_type');
	const classes = useStyles();
	const selectedUnitIds = useMemo(() => Object.keys(roles), [roles]);
	const canEdit = useMemo(
		() =>
			!!selectedUnitIds.find(
				unitId =>
					(!isEditUser || roles[unitId].isEditUnit) &&
					hasPermissionOnBusinessUnit(
						parseInt(unitId),
						Privileges.ROLE_CREATE_USER_WRITE,
					),
			),
		[selectedUnitIds],
	);
	const outerClasses = {
		radio: {
			radioGroup: classes.radioGroup,
		},
	};

	const has113Module = useMemo(() => {
		if (modules) {
			const module113 = Object.keys(modules).find(key =>
				modules[key]?.find((module: any) => module.id === 113),
			);

			if (module113) {
				return true;
			}

			return false;
		}

		return true;
	}, [modules]);

	const [fields, setFields] = useState<FieldConfigType[]>(
		getFieldsConfig(
			watchIdentityType,
			isMobile,
			outerClasses,
			isEditUser,
			employerRolePrivileges,
			has113Module,
			isActive,
			canEdit,
		),
	);

	useEffect(() => {
		setFields(
			getFieldsConfig(
				watchIdentityType,
				isMobile,
				outerClasses,
				isEditUser,
				employerRolePrivileges,
				has113Module,
				isActive,
				canEdit,
			),
		);
	}, [
		watchIdentityType,
		isMobile,
		isEditUser,
		isActive,
		has113Module,
		employerRolePrivileges,
		canEdit,
	]);

	return (
		<>
			<View
				style={{
					flexDirection: isMobile ? 'row-reverse' : 'row',
					width: '100%',
					paddingHorizontal: isMobile ? 20 : 0,
				}}
			>
				<I18n
					size={isMobile ? 16 : 20}
					style={{
						marginBottom: !isMobile ? 0 : 10,
						marginTop: isMobile ? 21 : undefined,
						// paddingHorizontal: isMobile ? 16 : undefined,
					}}
					weight={isMobile ? 'bold' : 'normal'}
				>
					editUserModal.userDetails.title
				</I18n>
			</View>
			<WrapperComponent>
				{fields.map((field: any) => {
					return (
						<FieldWrapper field={field}>
							<FieldBuilder
								key={field.name}
								viewOnly={field.viewOnly}
								{...field}
								styles={{
									wrapper: field.styles?.wrapper
										? field.styles?.wrapper
										: wrapperStyles,
									labelWrapper: isMobile
										? undefined
										: field.styles?.labelWrapper
										? field.styles?.labelWrapper
										: labelWrapperStyles,
								}}
							/>
						</FieldWrapper>
					);
				})}
			</WrapperComponent>
		</>
	);
};

export default UserDetailsSection;
