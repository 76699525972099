import TableContainer from '../../../components/web/ReactTable/Table.container';
import React from 'react';
import ModalWrapper from '../../../components/web/ModalWrapper/ModalWrapper.component';

interface EmployeesModalProps {
	onRowClick: (row: any) => void;
	headers: any[];
	tableRows: any[];
	onSort: (field: string, direction: string) => void;
	getNextData: () => void;
	modalTitle: string;
	allDataFetched: boolean;
}

const styles = {
	container: {
		direction: 'rtl',
		height: '80vh',
		padding: '25px',
		width: '80vw',
	},
};

const EmployeesModal = ({
	headers,
	tableRows,
	onSort,
	getNextData,
	modalTitle,
	allDataFetched,
}: EmployeesModalProps) => {
	return (
		<ModalWrapper title={modalTitle}>
			<TableContainer
				headers={headers}
				data={tableRows || []}
				count={40}
				keyName={''}
				onSort={onSort}
				getNextData={getNextData}
				noInfinityScroll={false}
				hasFooter={false}
				allDataFetched={allDataFetched}
			/>
		</ModalWrapper>
	);
};

export default EmployeesModal;
