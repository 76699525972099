export const mockDataMap = {
	testMock: {
		author: 'Yegor',
		age: '29',
		gender: 'male',
	},
	employersNames: [
		{
			id: 1,
			name: 'Bla 1',
			start_work: '25.04.21',
			finish_work: '29.04.21'
		},
		{
			id: 2,
			name: 'Bla 2',
			start_work: '12.04.21',
			finish_work: '23.04.21'
		},
		{
			id: 1,
			name: 'Bla 3',
			start_work: '01.05.21',
			finish_work: null
		},
	],
	startWorlog: {
		project_id: 123,
	},
	workLogById: {
		work_log_id: 554,
		project_name: 'Project 2',
		project_id: 123,
		entry_time: 'Tue Dec 15 2020 10:45:08 ',
		exit_time: '',
	},
	fullReportList: [
		{
			work_log_id: '123',
			teudat_zeut: '1111',
			employee_full_name: 'ori silber',
			project_name: 'Project 1',
			date: '1997-03-09',
			enter: '12:00',
			exit: '13:00',
			last_update: 'Tue Dec 15 2020 10:45:08',
			worked_hours: 'P1DT100H30M0S',
			updated_by: 'ori silber',
			updated_enter: false,
			updated_exit: true,
			updated_project: true,
			pending_approval: 'APPROVED',
		},
	],
	filterOptions: [
		{
			id: '1',
			name: 'hello',
		},
		{
			id: '2',
			name: 'world',
		},
	],
	filterStringOptions: ['1', '2', '3'],
	allProjects: [
		{
			project_id: 123,
			project_name: 'Project 1',
			last_work_log_id: 12,
		},
		{
			project_id: 122,
			project_name: 'Project 2',
			last_work_log_id: 11,
		},
		{
			project_id: 121,
			project_name: 'Project 3',
			last_work_log_id: 11,
		},
		{
			project_id: 120,
			project_name: 'Project 4',
			last_work_log_id: 11,
		},
		{
			project_id: 119,
			project_name: 'Project 5',
			last_work_log_id: 11,
		},
		{
			project_id: 118,
			project_name: 'Project 6',
			last_work_log_id: 11,
		},
		{
			project_id: 117,
			project_name: 'Project 7',
			last_work_log_id: 11,
		},
		{
			project_id: 116,
			project_name: 'Project 8',
			last_work_log_id: 11,
		},
		{
			project_id: 115,
			project_name: 'Project 9',
			last_work_log_id: 11,
		},
		{
			project_id: 114,
			project_name: 'Project 10',
			last_work_log_id: 11,
		},
		{
			project_id: 113,
			project_name: 'Project 11',
			last_work_log_id: 11,
		},
	],
	login: {
		token: 'bla123TokenJwt',
		refresh_token: 'JWT233RefreSh',
		roles: ['REGULAR'],
		feature_flag: [{ product: 'HRS', version: '0.0.1' }],
		customer: 'john',
	},
	refreshToken: {
		token: 'bla123TokenJw777777',
		refresh_token: 'JWT233RefreSh0000000',
	},
	forgotPassword: {},
	reportsByMonth: {
		working_hours: 'P1DT100H30M0S',
		month: [
			{
				day: '2020/12/01',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09 ',
						exit_time: 'Tue Dec 15 2020 22:36:09 ',
					},
				],
			},
			{
				day: '2020/12/02',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1600',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09 ',
						exit_time: 'Tue Dec 15 2020 22:36:09 ',
					},
				],
			},
			{
				day: '2020/12/03',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [],
			},
			{
				day: '2020/12/04',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1442',
						project_name: 'Project 2',
						project_id: 124,
						entry_time: 'Tue Dec 04 2020 12:36:09 ',
						exit_time: 'Tue Dec 05 2020 22:36:09 ',
					},
					{
						work_log_id: '1443',
						project_name: 'Project 2',
						project_id: 125,
						entry_time: 'Tue Dec 04 2020 12:38:09 ',
						exit_time: 'Tue Dec 06 2020 22:36:09 ',
					},
				],
			},
			{
				day: '2020/12/05',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/06',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/07',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
					{
						work_log_id: '1445',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
					{
						work_log_id: '1446',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
					{
						work_log_id: '1447',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
					{
						work_log_id: '1448',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/08',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/09',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/10',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/11',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/12',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/13',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/14',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/15',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/16',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/17',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/18',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/19',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/20',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/21',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/22',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/23',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/24',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/25',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/26',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/27',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/28',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 12:36:09',
						exit_time: 'Tue Dec 15 2020 22:36:09',
					},
				],
			},
			{
				day: '2020/12/29',
				holiday: true,
				holiday_name: 'חנוכה נר ראשון',
				working_hours: 'P0DT10H0M0S',
				work_logs: [
					{
						work_log_id: '1444',
						project_name: 'Project 2',
						project_id: 123,
						entry_time: 'Tue Dec 15 2020 14:36:09',
						exit_time: 'Tue Dec 15 2020 20:36:09',
					},
				],
			},
		],
	},
	addWorkLogByProjectId: {
		work_log_id: '7777',
	},
	currentTime: {
		time: `2020-12-27'T'14:30:00.000000`,
	},
	verifyNewPassword: {
		token: 'tokenJWtsdfsdg123',
	},
	employeeDataTable: [
		{
			employee_id: 'empl1',
			employee_name: 'John Smith',
			teudat_zeut: '323112454',
			confirm_location_reporting: true,
			location: [
				{
					location_id: 'loc123',
					lat: 1.222211,
					lon: 45.224442,
					location_name: 'Tel-Aviv, Good Place to Be 55',
					place_id: 'someplacenum1212121',
				},
			],
		},
	],
	user: {
		location: true,
		projects: true,
		roles: ['REGULAR'],
		feature_flag: [{ product: 'HRS', version: '0.0.1' }],
	},
	peychecksRange: {
		first: '2021-11',
		last: '2022-03',
	},
	ohsRange: {
		first: '2019-11',
		last: '2022-03',
	},
	attendanceRange: {
		first: '2019-11',
		last: '2022-03',
	},
	paycheckData: {
		teudat_zeut: 341111989,
		data: {
			'1-a-1-1': {
				value: 'חברה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-2-1': {
				value: 'כתובת',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-3-1': {
				value: 'ישוב',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-1-2': {
				value: ': 028 - חברה להדגמה מסכים חדשים אאא',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-2-2': {
				value: ' :הבנאי 24',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-3-2': {
				value: ': חולון 5885212',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-1-3': {
				value: "תיק בי''ל",
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-2-3': {
				value: 'מספר תאגיד',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-3-3': {
				value: "תיק בי''ל",
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-1-4': {
				value: ':93500000000',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-2-4': {
				value: '512333444',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-3-4': {
				value: '93500000000',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-b-1-1': {
				value: 'תלוש שכר לחודש',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-b-2-1': {
				value: 'הודפס בתאריך',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-b-3-1': {
				value: 'דף 1 מתוך',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-b-1-2': {
				value: '12/20/2020',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-b-2-2': {
				value: '17/01/2021',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-b-3-2': {
				value: '1',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-c-1-1': {
				value: 'מאיר גולדה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-c-2-1': {
				value: '17 לבונטין',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-c-3-1': {
				value: '7531524 ראשון לציון',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-d-1-1': {
				value: ':מספר זהות',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-2-1': {
				value: ':ס.משרה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-3-1': {
				value: ':בסיס השכר',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-4-1': {
				value: ':מחלקה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-5-1': {
				value: ':מצב משפחתי',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-7-1': {
				value: ':דרוג',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-8-1': {
				value: ':בנק',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-1-2': {
				value: '82828211',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-2-2': {
				value: 'יחידה-1',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-3-2': {
				value: 'חודשי',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-4-2': {
				value: 'בעלים 009',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-5-2': {
				value: 'נ+0',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-7-2': {
				value: '000',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-8-2': {
				value: '00/000',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-d-2-3': {
				value: ':משרה בי~ל',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-3-3': {
				value: ':חלקיות המשרה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-7-3': {
				value: ':דרגה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-8-3': {
				value: ':חשבון',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-d-2-4': {
				value: 'עיקרית',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-3-4': {
				value: '1.0000',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-7-4': {
				value: '000',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-8-4': {
				value: '000000000',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-d-1-5': {
				value: ':מספר העובד',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-2-5': {
				value: ':תושב',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-3-5': {
				value: ':וותק',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-4-5': {
				value: ':תחילת עבודה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-7-5': {
				value: ':-וותק מ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-1-6': {
				value: '0004',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-2-6': {
				value: 'כן',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-3-6': {
				value: '03.11.24',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-d-4-6': {
				value: '08/01/2017',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-1-1': {
				value: '001',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-2-1': {
				value: '023',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-3-1': {
				value: '007',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-4-1': {
				value: '065',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-5-1': {
				value: '025',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-6-1': {
				value: '062',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-7-1': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-13-1': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-e-1-2': {
				value: 'משכורת',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-2-2': {
				value: 'שווי ריבית',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-3-2': {
				value: '125% ש.נוס',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-4-2': {
				value: '150% ש.נוס',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-5-2': {
				value: 'פיצוי שווי',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-6-2': {
				value: 'שווי יום כיף',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-7-2': {
				value: 'זק.תגמולים',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-13-2': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-e-3-3': {
				value: '35.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-4-3': {
				value: '12.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-13-3': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-e-3-4': {
				value: '200.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-4-4': {
				value: '300.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-13-4': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-e-3-5': {
				value: '7000.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-4-5': {
				value: '3600.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-13-5': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-e-6-6': {
				value: '1000.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-7-6': {
				value: '69263.32',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-13-6': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-e-1-7': {
				value: '500000.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-3-7': {
				value: '14000.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-4-7': {
				value: '3600.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-e-5-7': {
				value: '3600.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-a-13-7': {
				value: '093',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-f-4-1': {
				value: '006',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-5-1': {
				value: '025',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-f-1-2': {
				value: 'מס הכנסה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-2-2': {
				value: '.לאומ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-3-2': {
				value: 'בריאות',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-4-2': {
				value: 'פניקס חברה לביטו',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-5-2': {
				value: 'יטב דש השתלמו',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-f-1-3': {
				value: '285013.20',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-2-3': {
				value: '2583.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-3-3': {
				value: '2081.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-4-3': {
				value: '37500.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-f-5-3': {
				value: '392.80',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-1': {
				value: 'ימי עבודה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-1': {
				value: 'שעות עבודה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-1': {
				value: 'שעות העדרות',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-1': {
				value: 'שעות ליום',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-5-1': {
				value: 'נק. רגילות',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-6-1': {
				value: 'אחוז מס שולי',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-7-1': {
				value: 'קוד מהדורה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-8-1': {
				value: 'חישוב מצטבר',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-9-1': {
				value: 'בעל שליטה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-10-1': {
				value: 'אופן תשלום',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-2': {
				value: '24',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-2': {
				value: '30(א)(6)',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-2': {
				value: '0.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-2': {
				value: '7.44',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-5-2': {
				value: '2.75',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-6-2': {
				value: '50%',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-7-2': {
				value: 'ל9.123',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-8-2': {
				value: 'לא',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-9-2': {
				value: 'כן',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-10-2': {
				value: 'ישירות',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-3': {
				value: '24',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-3': {
				value: '30(א)(6)',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-3': {
				value: '0.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-3': {
				value: '7.44',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-5-3': {
				value: '2.75',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-6-3': {
				value: '50%',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-7-3': {
				value: 'ל9.123',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-8-3': {
				value: 'לא',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-9-3': {
				value: 'כן',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-10-3': {
				value: 'ישירות',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-11-3': {
				value: 'לא',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-12-3': {
				value: 'כן',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-13-3': {
				value: 'ישירות',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-4': {
				value: 'בחברה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-4': {
				value: 'בחברה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-4': {
				value: 'שכר חייב מס',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-4': {
				value: 'שכר לאומי',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-5-4': {
				value: 'שכר מבוטח',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-6-4': {
				value: 'בסיס קרה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-7-4': {
				value: 'מעביד גמל',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-8-4': {
				value: "רה''ש מעבי",
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-9-4': {
				value: 'פיצ. לא חויב מ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-10-4': {
				value: 'פיצ. חויב מ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-11-4': {
				value: '.לאומי מעבי',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-12-4': {
				value: 'כר מינ.חוד',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-13-4': {
				value: 'כר מינ.שע',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-5': {
				value: 'כר חייב מ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-5': {
				value: 'ווי למ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-5': {
				value: 'כ.ב.לאומ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-5': {
				value: 'ס הכנסה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-5-5': {
				value: 'ט. לאומ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-6-5': {
				value: 'ס בריאו',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-7-5': {
				value: 'הפניקס חברה לב',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-8-5': {
				value: 'יטב דש השתלמו',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-9-5': {
				value: 'יפיניט',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-6': {
				value: '725162.32',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-6': {
				value: '70263.32',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-6': {
				value: '127283.30',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-6': {
				value: '293239.37',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-5-6': {
				value: '5217.21',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-6-6': {
				value: '2245.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-7-6': {
				value: '37500.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-8-6': {
				value: '1883.68',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-9-6': {
				value: '6624.95',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-7': {
				value: 'תרה קודמ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-7': {
				value: 'בירה ח.ז.',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-7': {
				value: 'יצול ח.ז.',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-7': {
				value: 'תרה חדש',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-8': {
				value: '-5.70',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-8': {
				value: '1.92',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-8': {
				value: '.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-8': {
				value: '-3.78',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-9': {
				value: 'יתרה קודמת',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-9': {
				value: 'צבירה ',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-9': {
				value: 'ניצול',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-9': {
				value: 'תרה חדש',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-g-1-10': {
				value: '0.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-2-10': {
				value: '.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-3-10': {
				value: '.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-g-4-10': {
				value: '0.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-h-1-1': {
				value: 'שכר נטו',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-h-1-2': {
				value: '193630.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-i-1-1': {
				value: '01',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-i-2-1': {
				value: 'מפרעה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-i-3-1': {
				value: '555.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-i-1-2': {
				value: 'נכויי רשות',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-i-2-2': {
				value: '555.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-j-1-1': {
				value: 'לתשלום',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'1-j-1-2': {
				value: '193075.00',
				meta: { 'font-name': '', 'font-size': '6' },
			},

			'1-e-999-1': { value: 'לתשלום' }, //custom FE example
			'1-e-999-2': { value: '1לתשלום' }, //custom FE example
			'1-e-999-3': { value: '2לתשלום' }, //custom FE example
			'1-f-999-1': { value: 'לתשלום' }, //custom FE example
			'1-f-999-2': { value: '1לתשלום' }, //custom FE example
			'1-i-999-1': { value: 'לתשלום' }, //custom FE example
			'1-i-999-2': { value: '1לתשלום' }, //custom FE example
			'1-l-1-1': { value: 'blablabal 03-935-55-45' }, //custom FE example
			'1-k-1-1': {
				value: `omment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment comment Im comment commentIm comment commentIm comment omment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment comment Im comment commentIm comment commentIm comment comment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment commentIm comment comment Im comment commentIm comment commentIm comment comment`,
			}, //custom FE example
			'2-a-1-1': {
				value: 'חברה',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'2-a-2-1': {
				value: 'כתובת',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'2-a-3-1': {
				value: 'ישוב',
				meta: { 'font-name': '', 'font-size': '6' },
			},
			'2-a-1-2': {
				value: ': 028 - חברה להדגמה מסכים חדשים אאא',
				meta: { 'font-name': '', 'font-size': '6' },
			},
		},
	},
	eventList: [
		{
			id: '1',
			name: 'מכלה',
		},
		{
			id: '2',
			name: 'תקלה',
		},
	],
	costingEmployees: {
		total: { 
			tashlumim: 123.21, 
			tagmuley_maasik: 10.25, 
			keren_hishtalmut: 11.55, 
			compensation: 2.98, 
			shonut: 8.45, 
			b_leumi_maasik: 70.85, 
			mas_maasikim: 1.11, 
			mas_sachar: 4.65, 
			total: 233.05 
		}, 
			data: [
				{
					id: "1d63f8e2-bff8-4ffc-bde6-d3eb348951e3", 
					name: "חופשהמחלקה", 
					tashlumim: 123.21, 
					tagmuley_maasik: 10.25, 
					keren_hishtalmut: 11.55, 
					compensation: 2.98, 
					shonut: 8.45, 
					b_leumi_maasik: 70.85, 
					mas_maasikim: 1.11, 
					mas_sachar: 4.65, 
					total: 233.05, 
					periods: [
						{ 
							id: "1d63f8e2-bff8-4ffc-bde6-d3eb348951e3", 
							name: "202102", 
							tashlumim: 123.21, 
							tagmuley_maasik: 10.25, 
							keren_hishtalmut: 11.55, 
							compensation: 2.98, 
							shonut: 8.45, 
							b_leumi_maasik: 70.85,
							mas_maasikim: 1.11, 
							mas_sachar: 4.65, 
							total: 233.05        
						}
					]
				}  
			]
	},

	salaryReportListfiltersDataYears: ['2001', '2002', '2003', '2004'],

	fullSalaryReportListData: {
		hasNext: true,
		total: 11,
		data: [
			{
				teudatZeut: '953300031',
				fullName: 'ישראל אהוני',
				departmentName: 'Intel',
				salaryMonth: 202101,
				firstViewedSlipDate: '2021-06-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300032',
				fullName: 'ישראל אהוני',
				departmentName: 'AMD',
				salaryMonth: 202102,
				firstViewedSlipDate: '2021-07-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300034',
				fullName: 'ישראל אהוני',
				departmentName: 'BMW',
				salaryMonth: 202103,
				firstViewedSlipDate: '2021-08-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300035',
				fullName: 'ישראל אהוני',
				departmentName: 'Intel',
				salaryMonth: 202104,
				firstViewedSlipDate: '2021-06-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300036',
				fullName: 'ישראל אהוני',
				departmentName: 'AMD',
				salaryMonth: 202105,
				firstViewedSlipDate: '2021-07-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300037',
				fullName: 'ישראל אהוני',
				departmentName: 'BMW',
				salaryMonth: 202106,
				firstViewedSlipDate: '2021-08-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300038',
				fullName: 'ישראל אהוני',
				departmentName: 'Intel',
				salaryMonth: 202107,
				firstViewedSlipDate: '2021-06-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300039',
				fullName: 'ישראל אהוני',
				departmentName: 'AMD',
				salaryMonth: 202108,
				firstViewedSlipDate: '2021-07-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300040',
				fullName: 'ישראל אהוני',
				departmentName: 'BMW',
				salaryMonth: 202109,
				firstViewedSlipDate: '2021-08-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300041',
				fullName: 'ישראל אהוני',
				departmentName: 'Intel',
				salaryMonth: 202110,
				firstViewedSlipDate: '2021-06-13T06:27:55.274+00:00',
			},
			{
				teudatZeut: '953300042',
				fullName: 'ישראל אהוני',
				departmentName: 'AMD',
				salaryMonth: 202111,
				firstViewedSlipDate: '2021-07-13T06:27:55.274+00:00',
			},
		],
	},
};
