import React from 'react';
import { Record } from './Record/Record.component';
import moment from 'moment';
import { modalService } from '../../../services/modal.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import I18n from '../i18n/I18n.component';
import { Linking, TouchableOpacity } from 'react-native';
import httpService from '../../../services/http.service';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
interface Option {
	name: string;
	id: string;
	lastReported: boolean;
}
interface SelectedItem {
	name: any;
	isProject: boolean;
}
interface DisableButtons {
	disable: boolean;
	eventName: string;
}
export interface Props {
	disableEntryButton: boolean;
	showYomView: any;
	initialTime: number;
	records: Record[];
	shiftTime: number;
	projectsOptions: any[];
	eventsOptions: any[];
	onPressEntry: () => void;
	onPressExit: () => void;
	reachedMax: () => void;
	onSelectProjectOrEvent: Function;
	isProjectSelected: boolean;
	totalReported: number;
	label: any;
	defaultProjectOption: Option;
	defaultEventOption: Option;
	selectedItem: SelectedItem;
	canReportFullDay: boolean;
	disableButtons: DisableButtons;
	standardHours: string;
	onDelete: Function;
	onEdit: Function;
	report101warning?: boolean;
	selectedItemType: 'projects' | 'events' | null;
	handleOpenOptions: any;
	eventPlaceholdar: string;
	currentRunnigWorkLog: any;
	handleAddNewLog: any;
}
export const convertTimeToUtc = (
	time: string,
	date: string,
	dateFormat: string,
	getTime: boolean,
) => {
	const utcTime = moment(`${date}T${time}`, `${dateFormat}THH:mm`).utc();
	if (getTime) return utcTime.format('YYYY-MM-DDTHH:mm');
	return utcTime.format('YYYY-MM-DD');
};
export const convertFromUtc = (
	time: string,
	getHoursAndMinutes: boolean,
	getDate?: boolean,
	timeFormat?: string,
	dateFormat?: string,
) => {
	if (!time) return null;
	let format = getHoursAndMinutes ? 'HH:mm' : "'YYYY-MM-DD HH:mm:ss'";
	const utcTime = moment.utc(
		time,
		timeFormat ? timeFormat : 'YYYY-MM-DDTHH:mm:ss',
	);
	const local = getDate
		? moment(utcTime)
				.local()
				.format(dateFormat ? dateFormat : 'YYYY-MM-DD')
		: moment(utcTime).local().format(format);
	return local;
};
export const calcTimerInitValue = (
	currentRunnigWorkLog: Record,
	shiftTime: number,
) => {
	if (currentRunnigWorkLog) {
		let startTimeLocale = convertFromUtc(
			currentRunnigWorkLog.enterTime,
			false,
		);
		const currentTime = moment();
		const startTime = moment(startTimeLocale, 'YYYY-MM-DD HH:mm:ss');
		let seconds = Math.floor(
			moment.duration(currentTime.diff(startTime)).asSeconds(),
		);

		const min = Math.floor(seconds / 60);
		const m = min % 60;
		const h = Math.floor(min / 60) % 60;
		const s = seconds % 60;
		if (shiftTime * 3600 < seconds) return null; // stop the clock

		return { h, m, s };
	} else return null;
};
export const openAlertModal = async (
	message: string,
	iconName: string,
	closeButtonLable?: string,
	I18nProps?: any,
) => {
	try {
		await modalService.openModal(
			null,
			{
				onSubmit: async (callback: Function) => {
					callback();
				},
				submitBtnText: closeButtonLable
					? closeButtonLable
					: 'general.close',
				iconName,
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n
						{...I18nProps}
						style={{ textAlign: 'center' }}
						size={18}
					>
						{message}
					</I18n>
				</AlertModal>
			),
		);
	} catch {}
};

export const cantReportModal = async (isMobile, projectId) => {
	let alertLink = '';

	if (isMobile) {
		try {
			const res = await httpService.api<any>({
				type: /Android|android/i.test(navigator.userAgent)
					? 'getAndroidLink'
					: 'getIosLink',
			});
			if (res) {
				alertLink = res;
			}
		} catch (e) {}
	}

	await modalService.openModal(
		null,
		{
			onSubmit: (callback: Function) => callback(),
			submitBtnText: 'general.close',
			iconName: 'attention',
		},
		(props: any) => (
			<AlertModal {...props}>
				<I18n style={{ textAlign: 'center' }}>
					{!!projectId
						? 'workLogError.project'
						: 'workLogError.location'}
				</I18n>
				{isMobile && !!alertLink && (
					<TouchableOpacity
						onPress={() => Linking.openURL(alertLink)}
					>
						<I18n
							style={{
								textAlign: 'center',
								textDecorationLine: 'underline',
								marginTop: 10,
							}}
							color='primary'
						>
							general.forDownload
						</I18n>
					</TouchableOpacity>
				)}
			</AlertModal>
		),
	);
};

export const onDelete = async (id: string, onSuccess: Function) => {
	await modalService.openModal(
		null,
		{
			onCancel: (callback: Function) => {
				callback();
			},
			onSubmit: async (callback: Function) => {
				try {
					const res = await httpService.api<any>({
						type: 'deleteEmployeeReportByEmployee',
						params: { workLogId: id },
					});
				} catch (error) {
					console.log(error);
				}
				onSuccess();
				callback();
			},
			submitBtnText: 'general.true',
			cancelBtnText: 'general.false',
			iconName: 'question',
		},
		(props: any) => (
			<ModalConfirmActions {...props}>
				<I18n>modal.deleteReport.message</I18n>
			</ModalConfirmActions>
		),
	);
};
