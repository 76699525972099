const AdditionalData = (data: any) => {
	const format: any = {};
	const initialValues: any = {};
	if (!data || data.length === 0) return { format, initialValues };
	format.additionalData = {};
	format.additionalData.data = {
		code: '',
		componentType: 'headerAdditionalData',
	};
	format.additionalData.header = [
		{
			name: 'payworkdays',
			type: 'header',
			value: 'FromMonthToMonthReports.formats.AdditionalData.payworkdays',
		},
		{
			name: 'workdays',
			type: 'header',
			value: 'FromMonthToMonthReports.formats.AdditionalData.workdays',
		},
		{
			name: 'workingHours',
			type: 'header',
			value: 'FromMonthToMonthReports.formats.AdditionalData.workingHours.short',
		},
		{
			name: 'vacationUsed',
			type: 'header',
			value: 'FromMonthToMonthReports.formats.AdditionalData.vacationUsed',
		},
		{
			name: 'sickLeaveUsed',
			type: 'header',
			value: 'FromMonthToMonthReports.formats.AdditionalData.sickLeaveUsed',
		},
	];
	data.forEach((item: any, index: number) => {
		initialValues[`${item.id}_payworkdays`] = item.payworkdays;
		initialValues[`${item.id}_workdays`] = item.workdays;
		initialValues[`${item.id}_workingHours`] = item.workingHours;
		initialValues[`${item.id}_vacationUsed`] = item.vacationUsed;
		initialValues[`${item.id}_sickLeaveUsed`] = item.sickLeaveUsed;
		format.additionalData[`user_${item.id}`] = [
			{
				id: item.id,
				name: `${item.id}_payworkdays`,
				placeholder: '0.00',
				type: 'input',
				value: item.payworkdays || '',
				order: index,
			},
			{
				id: item.id,
				name: `${item.id}_workdays`,
				placeholder: '0.00',
				type: 'input',
				value: item.workdays || '',
				order: index,
			},
			{
				id: item.id,
				name: `${item.id}_workingHours`,
				placeholder: '0.00',
				type: 'input',
				value: item.workingHours || '',
				order: index,
			},
			{
				id: item.id,
				name: `${item.id}_vacationUsed`,
				placeholder: '0.00',
				type: 'input',
				value: item.vacationUsed || '',
				order: index,
			},
			{
				id: item.id,
				name: `${item.id}_sickLeaveUsed`,
				placeholder: '0.00',
				type: 'input',
				value: item.sickLeaveUsed || '',
				order: index,
			},
		];
	});
	return { format, initialValues };
};

export default AdditionalData;
