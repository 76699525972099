import moment from 'moment';
import React from 'react';
import { compact, toNumber } from 'lodash';
import { TouchableHighlight } from 'react-native';
import Typography from '../../components/shared/Typography/Typography.component';
import { i18nService } from '../../services/i18n.service';
import { useNavigation } from '@react-navigation/native';
import { taxCreditRequestFields } from '../WorkerCard/WorkerCardEditor/fields/taxCreditRequestFields';
import I18n from '../../components/shared/i18n/I18n.component';
const convertMinutesToHours = (value: any, format?: string) => {
	if (value) {
		if (format !== 'DECIMAL') {
			const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
			const minutes =
				value % 60 !== 0
					? value % 60 < 10
						? `0${value % 60}`
						: `${value % 60}`
					: '00';
			return `${hours}:${minutes}`;
		}
		return `${(value / 60).toLocaleString(undefined, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		})}`;
	}
	return '';
};

export const hoursColumns = (
	{ missDays, standardDays, standardMinutes, workLogDays, workLogMinutes },
	onRowPress,
	worklogReportFormat,
	businessUnitsList: any[],
) => {
	const navigation = useNavigation();

	return compact([
		{
			accessor: 'fullName',
			Header: i18nService.translate('hoursReport.table.name'),
			isSortable: true,
			CustomCell: ({ value, row }: any) => (
				<EmploeeName
					name={value}
					onPress={() => {
						onRowPress(row);
						navigation.navigate('hoursReportSummary', {
							singleEmployee: taxCreditRequestFields,
						});
					}}
				/>
			), //change the params
			Footer: i18nService.translate('hoursReport.table.total'),
			hasBudget: (row: any) => row.finishWork,
			textBlock: (row: any) => (
				<>
					<I18n
						size={8}
						color='white'
						weight='normal'
						style={{ marginLeft: 5 }}
					>
						general.endWorkDate
					</I18n>
					<I18n size={8} weight='normal' color='white'>
						{moment(row.original.finishWork, 'YYYY-MM-DD').format(
							'DD/MM/YYYY',
						)}
					</I18n>
				</>
			),
			budgetStyle: {
				paddingVertical: 3,
				paddingHorizontal: 6,
				minWidth: 110,
			},
		},
		{
			accessor: 'teudatZeut',
			Header: i18nService.translate('hoursReport.table.id'),
			isSortable: true,
		},
		businessUnitsList.length > 1
			? {
					accessor: 'businessUnitName',
					Header: i18nService.translate('general.businessUnits'),
					isSortable: true,
			  }
			: null,
		{
			accessor: 'departmentName',
			Header: i18nService.translate('employeeRequests.department'),
			isSortable: true,
		},
		{
			accessor: 'standardMinutes',
			Header: i18nService.translate('hoursReport.table.hoursStandart'),
			isSortable: true,
			CustomCell: ({ value, row }) =>
				convertMinutesToHours(value, worklogReportFormat),
			Footer: () =>
				` ${
					standardMinutes
						? `(${(standardMinutes / 60).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
						  })})`
						: ''
				} ${convertMinutesToHours(standardMinutes, 'HH:mm')} `,
		},
		{
			accessor: 'workLogMinutes',
			Header: i18nService.translate('hoursReport.table.hoursWorked'),
			isSortable: true,
			CustomCell: ({ value, row }) =>
				convertMinutesToHours(value, worklogReportFormat),
			Footer: () =>
				` ${
					workLogMinutes
						? `(${(workLogMinutes / 60).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
						  })})`
						: ''
				} ${convertMinutesToHours(workLogMinutes, 'HH:mm')} `,
		},
		{
			accessor: 'standardDays',
			Header: i18nService.translate('hoursReport.table.daysStandart'),
			isSortable: true,
			Footer: () =>
				!!standardDays ? Number(standardDays).toLocaleString() : '',
		},

		{
			accessor: 'workLogDays',
			Header: i18nService.translate('hoursReport.table.daysWorked'),
			isSortable: true,
			Footer: () =>
				!!workLogDays ? Number(workLogDays).toLocaleString() : '',
		},
		{
			accessor: 'missDays',
			Header: i18nService.translate('hoursReport.table.daysOf'),
			isSortable: true,
			Footer: () => (!!missDays ? Number(missDays).toLocaleString() : ''),
		},
	]);
};

const EmploeeName = ({ name, onPress }: { name: string; onPress(): void }) => {
	return (
		<TouchableHighlight underlayColor='transparent' onPress={onPress}>
			<Typography size={14} color='primary' weight='bold'>
				{name}
			</Typography>
		</TouchableHighlight>
	);
};

export const getDefaultDateFilter = filter => {
	const currentDay = toNumber(moment().format('DD'));
	const currentYear = toNumber(moment().format('YYYY'));
	const currentMonth = toNumber(moment().format('MM'));
	switch (filter) {
		case 'year':
			if (currentDay >= 15) return currentYear;
			return moment().subtract(1, 'months').format('YYYY');
		case 'month':
			if (currentDay >= 15) return currentMonth;
			return toNumber(moment().subtract(1, 'months').format('MM'));
		default:
			return moment().format('YYYY-MM-DD');
	}
};

export const monthMap = {
	1: 'months.January',
	2: 'months.February',
	3: 'months.March',
	4: 'months.April',
	5: 'months.May',
	6: 'months.June',
	7: 'months.July',
	8: 'months.August',
	9: 'months.September',
	10: 'months.October',
	11: 'months.November',
	12: 'months.December',
};
