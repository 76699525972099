import { LocaleConfig } from 'react-native-calendars';


export const setCalendarLocale = (localeConfig: typeof LocaleConfig) => {
    localeConfig.locales['he'] = {
        monthNames: [
            'ינואר',
            'פברואר',
            'מרץ',
            'אפריל',
            'מאי',
            'יוני',
            'יולי',
            'אוגוסט',
            'ספטמבר',
            'אוקטובר',
            'נובמבר',
            'דצמבר',
        ],
        monthNamesShort: [
            'ינו.',
            'פבר.',
            'מרץ',
            'אפר.',
            'מאי',
            'יוני',
            'יולי',
            'אוג.',
            'ספט.',
            'אוק.',
            'נוב.',
            'דצמ.',
        ],
        dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
        dayNamesShort: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
    };
    localeConfig.defaultLocale = 'he';
}

export const rtlTheme: any = {
    // Flip calendar for rtl
    'stylesheet.calendar.main': {
        week: {
            marginTop: 7,
            marginBottom: 7,
            flexDirection: 'row-reverse',
            justifyContent: 'space-around',
        },
    },
    'stylesheet.calendar.header': {
        header: {
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 20,
            alignItems: 'center',
            height: 45,
            borderBottomWidth: 1,
            borderBottomColor: '#dde0e3',
        },
        week: {
            marginTop: 7,
            flexDirection: 'row-reverse',
            justifyContent: 'space-around',
        },
        dayHeader: {
            marginTop: 2,
            marginBottom: 7,
            width: 35,
            textAlign: 'center',
            fontSize: 13,
            color: '#b6c1cd'       
        }
    },
}