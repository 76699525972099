import React from 'react';
import {
	TouchableHighlight,
	View,
	StyleSheet,
	Dimensions,
	Platform,
} from 'react-native';
import CheckBox from '@react-native-community/checkbox';
import { Checkbox as MuiCheckbox, makeStyles } from '@material-ui/core';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import I18n from '../../i18n/I18n.component';

const useStyles = makeStyles(() => ({
	checkbox: {
		marginRight: -10,

		color: '#6d7278',
		'&$checked': {
			color: colors.primary,
		},
	},
	checked: {},
}));

const CheckboxCard = ({ value, onChange, checkboxLabel, isDisabled }: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const classes = useStyles();
	return (
		<View
			style={{
				flexDirection: 'row-reverse',
				alignItems: isMobile ? 'center' : 'flex-start',
				flexWrap: 'wrap',
				justifyContent: isMobile ? 'center' : 'flex-start',
				marginBottom: -20,
			}}
		>
			<TouchableHighlight
				underlayColor='none'
				style={{
					marginLeft: isMobile ? 18 : 10,
					opacity: isDisabled ? 0.7 : 1,
				}}
				accessibilityRole='button'
				onPress={() => onChange(!value)}
				disabled={isDisabled}
			>
				<View
					style={StyleSheet.flatten([
						styles.buttonStyle,
						{
							maxWidth:
								Dimensions.get('window')?.width -
								(Platform.OS === 'android'
									? 60
									: !isMobile
									? 280
									: 40),
						},
					])}
				>
					{Platform.OS === 'ios' || Platform.OS === 'android' ? (
						<CheckBox
							disabled={isDisabled}
							value={value}
							onValueChange={onChange}
							boxType='square'
							onCheckColor={colors.white}
							onFillColor={colors.primary}
							onTintColor={colors.primary}
							tintColors={{ true: colors.primary }}
							style={{
								width: 22,
								height: 22,
								borderRadius: 2,
							}}
						/>
					) : (
						<MuiCheckbox
							size='small'
							color='default'
							checked={value}
							classes={{
								root: classes?.checkbox,
								checked: classes?.checked,
							}}
						/>
					)}
					<I18n
						weight='normal'
						size={isMobile ? 16 : 14}
						style={{
							textAlign: 'right',
							marginRight:
								Platform.OS === 'ios' ||
								Platform.OS === 'android'
									? 16
									: 8,
						}}
						{...{
							color: 'darkGrey',
						}}
					>
						{checkboxLabel}
					</I18n>
				</View>
			</TouchableHighlight>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	centeredText: {
		textAlign: 'center',
	},
	buttonStyle: {
		backgroundColor: colors.white,
		flexDirection: 'row-reverse',
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		minHeight: 40,
		minWidth: 70,
		justifyContent: 'space-evenly',
		alignItems: 'center',
		textAlign: 'center',
		paddingHorizontal: 12,
		paddingVertical: 16,
		borderRadius: 11,
		marginBottom: 20,
	},
	containerStyle: {
		width: 150,
	},
});
export default CheckboxCard;
