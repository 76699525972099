import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { View, StyleSheet, Dimensions, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import CheckBox from '@react-native-community/checkbox';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import colors from '../../../../config/colors';
import I18n from '../../../../components/shared/i18n/I18n.component';
import getShadowStyle from '../../../../config/shadow';

interface RoleRowProps {
	isChecked: boolean;
	onCheck: (id: number, value?: boolean, item?: any) => void;
	disabled?: boolean;
	item: any;
	isMulti: boolean;
}

const useStyles = makeStyles(() => ({
	checkbox: {
		marginRight: -10,

		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
			backgroundColor: colors.white,
		},
	},
	checked: {},
}));

const ExtraDataRow = ({
	isChecked,
	item,
	onCheck,
	disabled,
	isMulti,
}: RoleRowProps) => {
	const classes = useStyles();
	const [toolTipVisible, setToolTipVisible] = useState<boolean>(false);

	const _onCheck = (e: any) => {
		// e.stopPropagation();
		onCheck(item.value, !isChecked, item);
	};

	return (
		<>
			<View
				style={StyleSheet.flatten([
					styles.buttonStyle,
					{
						backgroundColor: colors.white,
						marginLeft: 18,
						marginRight: 10,
						opacity: disabled ? 0.7 : 1,
					},
				])}
			>
				<TouchableOpacity
					accessibilityRole='button'
					onPress={e => {
						e?.stopPropagation();
						onCheck(item.value, !isChecked, item);
					}}
					style={{
						alignItems: 'center',
						flexDirection: 'row-reverse',
						width: 240,
						maxWidth: Dimensions.get('window')?.width - 40,
					}}
					disabled={disabled}
				>
					{isMulti ? (
						Platform.OS === 'ios' || Platform.OS === 'android' ? (
							<CheckBox
								disabled={disabled}
								value={!!isChecked}
								onValueChange={e => _onCheck(e)}
								boxType='square'
								onCheckColor={colors.white}
								onFillColor={colors.lightPrimary}
								onTintColor={colors.white}
								tintColors={{ true: colors.lightPrimary }}
								style={{
									width: 25,
									height: 22,
									borderRadius: 2,
								}}
							/>
						) : (
							<MuiCheckbox
								size='small'
								color='default'
								checked={!!isChecked}
								classes={{
									root: classes?.checkbox,
									checked: classes?.checked,
								}}
							/>
						)
					) : null}
					<View>
						<I18n
							weight='bold'
							size={16}
							style={{
								textAlign: 'right',
								marginRight:
									Platform.OS === 'ios'
										? 2
										: Platform.OS === 'android'
										? 0
										: 7,
							}}
							color='darkGrey'
						>
							{item.label}
						</I18n>
					</View>
				</TouchableOpacity>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	root: {
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	centeredText: {
		textAlign: 'center',
	},
	buttonStyle: {
		backgroundColor: colors.white,
		flexDirection: 'row-reverse',
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		minHeight: 54,
		minWidth: 70,
		alignItems: 'center',
		textAlign: 'center',
		paddingLeft: 20,
		paddingRight: 10,
		borderRadius: 35,
		marginBottom: 10,
	},
	containerStyle: {
		width: 150,
	},
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		width: 103,
		backgroundColor: colors.greyBlue,
		borderRadius: 35,
		minHeight: 54,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default ExtraDataRow;
