import React from 'react';
import { UserModel } from '../../../../../models/users.model';
import CreateUpdateUserWrapper from '../../CreateUpdateUserWrapper.container';
import CreateUserModal from './CreateUserModal.component';
export interface UserData {
	role_info: {
		roles: Role[];
		user_roles: number[];
		user_role_department: {
			[key: string]: number[];
		};
		user_role_excluded_user: any;
		user_role_permission: {
			[key: string]: number[];
		};
	};
	info: {
		attending_report_required: boolean;
		department_id: number;
		email: string;
		employee101_required: boolean;
		employee_type: string;
		first_name: string;
		id: number;
		identity_type: string;
		last_name: string;
		phone: string;
		status: boolean;
		teudat_zeut: string;
	};
	departments: [{ id: number; name: string }];
}

export type Role = {
	id: number;
	name: string;
	allocate_department: boolean;
	description: null | string;
	permissions?: any[];
};

const CreateUserModalContainer = ({
	id,
	onNext,
	callback,
	route,
	businessUnitsList,
	businessUnitId,
}: UserModel | any) => {
	return (
		<CreateUpdateUserWrapper
			Component={CreateUserModal}
			{...{
				id,
				onNext,
				callback,
				route,
				pageName: 'users',
				businessUnitsList,
				unitId: businessUnitId,
			}}
		/>
	);
};

export default CreateUserModalContainer;
