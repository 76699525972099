import { childrenFields } from './fields/childrenFields';
import { employeeFields } from './fields/employeeFields';
import { spouseFields } from './fields/spouseFields';
import { taxCreditReasonFields } from './fields/taxCreditReasonFields';
import { taxCreditRequestFields } from './fields/taxCreditRequestFields';
import Signature from './TabsComponents/Signature/Signature';

export function getTabsMap(taxCreditAddressList: any[]) {
	return [
		{
			pageRoute: 'employerDetails',
			viewOnly: true,
			fields: [
				[
					{
						name: 'employer.name',
						type: 'input',
						label: 'workerCard.editor.stepOne.companyName',
						viewOnly: true,
					},
					{
						name: 'employer.address',
						type: 'input',
						label: 'workerCard.editor.stepOne.address',
						viewOnly: true,
					},
					{
						name: 'employer.phone',
						type: 'input',
						label: 'workerCard.editor.stepOne.phoneNumber',

						viewOnly: true,
					},
					{
						name: 'employer.deductionFileId',
						type: 'input',
						label: 'workerCard.editor.stepOne.tikNikuiim',
						viewOnly: true,
					},
				],
			],
		},
		employeeFields,
		childrenFields,
		{
			pageRoute: 'currentEmployerIncome',
			fields: [
				[
					{
						name: 'currentEmployerIncome.incomeType',
						label:
							'workerCard.editor.currentEmployerIncome.incomeType',
						tooltip: [
							'workerCard.editor.currentEmployerIncome.incomeType.tooltip.1',
							'workerCard.editor.currentEmployerIncome.incomeType.tooltip.2',
							'workerCard.editor.currentEmployerIncome.incomeType.tooltip.3',
							'workerCard.editor.currentEmployerIncome.incomeType.tooltip.4',
							'workerCard.editor.currentEmployerIncome.incomeType.tooltip.5',
							'workerCard.editor.currentEmployerIncome.incomeType.tooltip.6',
							'workerCard.editor.currentEmployerIncome.incomeType.tooltip.7',
						],
						type: 'buttonsGroup',
						enableUnselect: true,
						options: [
							{
								label:
									'workerCard.editor.currentEmployerIncome.monthlySalary',
								value: 'MONTHLY_SALARY',
							},
							{
								label:
									'workerCard.editor.currentEmployerIncome.additionalPositionSalary',
								value: 'ADDITIONAL_POSITION_SALARY',
							},
							{
								label:
									'workerCard.editor.currentEmployerIncome.partialSalary',
								value: 'PARTIAL_SALARY',
							},
							{
								label:
									'workerCard.editor.currentEmployerIncome.dailyWorker',
								value: 'DAILY_WORKER',
							},
						],
						onChange: (
							setValue: Function,
							getValues: Function,
							selectedValue: any,
							prevValue: any,
							trigger: Function,
						) => {
							if (
								selectedValue === 'ADDITIONAL_POSITION_SALARY'
							) {
								setValue(
									'otherEmployerIncome.otherEmployerIncome',
									true,
								);
							} else {
								//setValue('otherEmployerIncome', {});
								setValue(
									'otherEmployerIncome.otherEmployerIncome',
									undefined,
								);
							}
							trigger([
								'currentEmployerIncome.additionalIncomeType',
								'currentEmployerIncome.incomeType',
							]);
						},
						isRequired: true,
					},
					{
						name: 'currentEmployerIncome.additionalIncomeType',
						type: 'multiButtonsGroup',
						hideInViewMode: true,
						hideErrorlabel: true,
						options: [
							{ label: 'general.allowance', value: 'ALLOWANCE' },
							{
								label: 'general.scholarship',
								value: 'SCHOLARSHIP',
							},
						],
						onChange: (
							setValue: Function,
							getValues: Function,
							selectedValue: any,
							prevValue: any,
							trigger: Function,
						) => {
							trigger([
								'currentEmployerIncome.incomeType',
								'currentEmployerIncome.additionalIncomeType',
							]);
						},
					},
				],
				[
					{
						name: 'currentEmployerIncome.startDate',
						sublabel:
							'workerCard.editor.currentEmployerIncome.startDate.sublabel',
						placeholder: 'general.datePickerPlaceholder',
						type: 'datepicker',
						label:
							'workerCard.editor.currentEmployerIncome.startDate',
						isRequired: true,
						disabled: (getValues: any) =>
							!getValues()?.currentEmployerIncome
							?.allowUpdateIncomeStartDate || 
							new Date(getValues()?.currentEmployerIncome?.startDate) <= new Date(`01/01/${getValues()?.taxYear}`),
					},
				],
			],
		},
		{
			pageRoute: 'otherEmployerIncome',
			fields: [
				[
					{
						name: 'otherEmployerIncome.otherEmployerIncome',
						type: 'buttonsGroup',
						options: [
							{ label: 'general.true', value: true },
							{ label: 'general.false', value: false },
						],
						label:
							'workerCard.editor.otherEmployerIncome.otherEmployerIncome',
						isRequired: true,
						shouldDisabled: () => ({
							watch: ['currentEmployerIncome.incomeType'],
							callback: (values: any) =>
								values[0] === 'ADDITIONAL_POSITION_SALARY',
						}),
						onChange: (
							setValue: Function,
							getValues: Function,
							selectedValue: any,
						) => {
							if (!selectedValue) {
								setValue('otherEmployerIncome', {otherEmployerIncome: selectedValue});
							}
						},
					},
				],
				[
					{
						name: 'otherEmployerIncome.incomeType',
						label:
							'workerCard.editor.otherEmployerIncome.incomeType',
						tooltip: [
							'workerCard.editor.otherEmployerIncome.incomeType.tooltip.1',
							'workerCard.editor.otherEmployerIncome.incomeType.tooltip.2',
							'workerCard.editor.otherEmployerIncome.incomeType.tooltip.3',
							'workerCard.editor.otherEmployerIncome.incomeType.tooltip.4',
							'workerCard.editor.otherEmployerIncome.incomeType.tooltip.5',
							'workerCard.editor.otherEmployerIncome.incomeType.tooltip.6',
							'workerCard.editor.otherEmployerIncome.incomeType.tooltip.7',
						],
						type: 'multiButtonsGroup',
						options: [
							{
								label:
									'workerCard.editor.currentEmployerIncome.monthlySalary',
								value: 'MONTHLY_SALARY',
							},
							{
								label:
									'workerCard.editor.currentEmployerIncome.additionalPositionSalary',
								value: 'ADDITIONAL_POSITION_SALARY',
							},
							{
								label:
									'workerCard.editor.currentEmployerIncome.partialSalary',
								value: 'PARTIAL_SALARY',
							},
							{
								label:
									'workerCard.editor.currentEmployerIncome.dailyWorker',
								value: 'DAILY_WORKER',
							},
							{ label: 'general.allowance', value: 'ALLOWANCE' },
							{
								label: 'general.scholarship',
								value: 'SCHOLARSHIP',
							},
						],
						isRequired: true,
						shouldDisplay: () => ({
							watch: ['otherEmployerIncome.otherEmployerIncome'],
							callback: (values: any) => values[0],
						}),
					},
				],
				[
					{
						name: 'otherEmployerIncome.taxCreditRequest',
						label:
							'workerCard.editor.otherEmployerIncome.taxCreditRequest',
						tooltip: [
							'workerCard.editor.otherEmployerIncome.taxCreditRequest.tooltip.1',
							'workerCard.editor.otherEmployerIncome.taxCreditRequest.tooltip.2',
						],
						type: 'buttonsGroup',
						options: [
							{
								label:
									'workerCard.editor.otherEmployerIncome.taxCreditRequest.true',
								value: true,
							},
							{
								label:
									'workerCard.editor.otherEmployerIncome.taxCreditRequest.false',
								value: false,
							},
						],
						isRequired: true,
						shouldDisplay: () => ({
							watch: ['otherEmployerIncome.otherEmployerIncome'],
							callback: (values: any) => values[0],
						}),
						smallBorderRadius: (isMobile: boolean) => isMobile,
					},
				],
				[
					{
						name: 'otherEmployerIncome.noKerenHishtalmutPayment',
						label:
							'workerCard.editor.otherEmployerIncome.allowance',
						tooltip:
							'workerCard.editor.otherEmployerIncome.noKerenHishtalmutPayment.tooltip',
						type: 'checkbox',
						checkboxLabel:
							'workerCard.editor.otherEmployerIncome.noKerenHishtalmutPayment',
						shouldDisplay: () => ({
							watch: ['otherEmployerIncome.otherEmployerIncome'],
							callback: (values: any) => values[0],
						}),
						shouldDisplayError: (isMobile: boolean) => false,
						fieldWrapperStyle: (isMobile: boolean) =>
							isMobile ? {} : { marginBottom: 0 },
					},
					{
						name: 'otherEmployerIncome.noDisabilityPayment',
						type: 'checkbox',
						checkboxLabel:
							'workerCard.editor.otherEmployerIncome.noDisabilityPayment',
						shouldDisplay: () => ({
							watch: ['otherEmployerIncome.otherEmployerIncome'],
							callback: (values: any) => values[0],
						}),
						displayOtherFieldError: (isMobile: boolean) =>
							'otherEmployerIncome.noDisabilityPayment',
						fieldWrapperStyle: (isMobile: boolean) =>
							isMobile ? {} : { marginRight: 0 },
					},
				],
			],
		},
		spouseFields,
		taxCreditReasonFields(taxCreditAddressList),
		taxCreditRequestFields,
		{
			pageRoute: 'disclaimer',
			fields: [
				[
					{
						name: 'signature',
						type: 'customInput',
						notDisplayComment: true,
						noTitle: true,
						fieldWrapperStyle: (isMobile: string) => ({
							marginTop: 0,
						}),
						Component: Signature,
					},
				],
			],
		},
	];
}
