import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions, ScrollView } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import useDevicePlatform from '../../../hooks/useDevicePlatform.hook';
import { getRequestData, RequestData } from './RequestDetails.utils';
import SiknessRequest from './RequestFields/SiknessRequest';
import VacationRequest from './RequestFields/VacationRequest';

interface RequestWrapperProps {
	requestId: number;
	onNext: any;
	validationDate: number;
	onRefresh(): void;
}

const windowHeight = Dimensions.get('window').height;

const RequestDetails = ({
	requestId,
	onNext,
	...rest
}: RequestWrapperProps) => {
	const [requestData, setRequestData] = useState<RequestData | null>(null);

	useEffect(() => {
		getData();
	}, [requestId]);

	const getData = async () => {
		const data: RequestData = await getRequestData(requestId);
		setRequestData(data);
	};

	return (
		<View
			style={[
				styles.container,
				{ height: windowHeight > 740 ? 740 : windowHeight - 50 },
			]}
		>
			<ScrollView
				style={{ zIndex: -1, paddingHorizontal: 20 }}
				centerContent={true}
				nativeID='#LocationListContainer'
				contentContainerStyle={{ paddingBottom: 20 }}
			>
				{requestData ? (
					requestData?.type === 'VACATION' ? (
						<VacationRequest
							requestData={requestData}
							onClose={onNext}
							{...rest}
						/>
					) : (
						<SiknessRequest
							requestData={requestData}
							onClose={onNext}
							{...rest}
						/>
					)
				) : (
					<View />
				)}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: 408,
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		paddingVertical: 39,
		paddingHorizontal: 20,
	},
});

export default RequestDetails;
