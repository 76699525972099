import { cloneDeep } from 'lodash';
import httpService from '../../../services/http.service';
import { parseLastDate } from '../../../utils/reportsPagesHelpers.utils';
import * as salaryReportsActions from './SalaryReportPage.actions';

export type SalaryReportsFilterTypes =
	| 'emp'
	| 'department'
	| 'month'
	| 'year'
	| 'paycheck-view'
	| 'unitIds';

export interface SalaryReportsRequest {
	page: number;
	pageSize: number;
	sortBy: string | undefined;
	sortDirection: 'ASC' | 'DESC' | '';
	filter: Record<SalaryReportsFilterTypes, string[] | number[]>;
}

export const initialState: SalaryReportsRequest = {
	page: 0,
	pageSize: 20,
	sortBy: '',
	sortDirection: '',
	filter: {
		emp: [],
		department: [],
		month: [String(new Date().getMonth())],
		year: [],
		['paycheck-view']: ['VIEW', 'NOT_VIEW'],
		unitIds: [],
	},
};

export type Action = {
	type: string;
	payload?: any;
};

const reducer: React.Reducer<SalaryReportsRequest, Action> = (
	state: SalaryReportsRequest = initialState,
	action: any,
) => {
	switch (action.type) {
		case salaryReportsActions.RESET_FILTERS: {
			return {
				...initialState,
				filter: {
					...initialState.filter,
					unitIds: state.filter.unitIds,
				},
			};
		}
		case salaryReportsActions.UPDATE_DEPARTMENT:
			return {
				...state,
				filter: { ...state.filter, emp: action.payload },
			};
		case salaryReportsActions.INCREMENT_PAGE:
			return {
				...state,
				page: state.page + 1,
			};
		case salaryReportsActions.UPDATE_BUSINESSUNITS:
			return {
				...state,
				filter: {
					...state.filter,
					unitIds: action.payload.isMulti
						? action.payload.value.map((val: any) => val.id || val)
						: [action.payload.value],
					department: [],
					emp: [],
				},
			};
		case salaryReportsActions.SET_SORT:
			return {
				...state,
				page: 0,
				filter: state.filter,
				sortBy: action.payload.name,
				sortDirection: action.payload.direction,
			};
		case salaryReportsActions.SET_FILTER:
			const { isMultiselect } = action.payload;
			let filterData: string[] = [];
			if (isMultiselect && !action.payload.value.length) {
				filterData = [];
			} else if (isMultiselect) {
				filterData = action.payload.value
					.filter((v: any) => v.id !== 'all')
					.map((v: any) => v?.id);
			} else {
				filterData = action.payload.value ? [action.payload.value] : [];
			}
			return {
				...state,
				page: 0,
				filter: {
					...state.filter,
					[action.payload.filter]: filterData || [],
				},
			};
		case salaryReportsActions.REPORT_lAST_DATE_REQUEST_SUCCESS:
			const dateObjTo = parseLastDate(action.payload.date);
			action.payload?.callback?.();

			return {
				...state,
				filter: {
					...state.filter,
					year: [dateObjTo.year],
					month: [dateObjTo.month],
				},
			};
		default:
			return state;
	}
};

export const fetchReportLastDate = (
	dispatch: any,
	unitIds: any[],
	isLoadLast: any,
) => {
	httpService
		.api<any>({
			type: 'getPaycheckReportLastDate',
			query: { unitIds: unitIds?.map(unit => `${unit}`).join() },
		})
		.then(data => {
			dispatch({
				type: salaryReportsActions.REPORT_lAST_DATE_REQUEST_SUCCESS,
				payload: {
					date: data,
					callback: isLoadLast,
				},
			});
		});
};

export default reducer;
