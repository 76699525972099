export enum AsyncLocalStorage {
	TOKEN = 'TOKEN',
	REFRESH_TOKEN = 'REFRESH_TOKEN',
	ROLES = 'ROLES',
	CUSTOMER = 'CUSTOMER',
	VERSION = 'VERSION',
	EMPLOYER = 'EMPLOYER',
	MODULES = 'MODULES',
	REP_DATA = 'REP_DATA',
	REP_EXIT_FROM = 'REP_EXIT_FROM',
}
