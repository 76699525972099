import React, { useState } from 'react';
import {
	Dimensions,
	TouchableHighlight,
	TouchableOpacity,
	useWindowDimensions,
	View,
} from 'react-native';
import FiltersContainer, {
	FilterData,
} from '../../../components/web/Filters/Filters.container';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { i18nService } from '../../../services/i18n.service';
import PaycheckView from '../../PaycheckPage/PaycheckView/PaycheckView.component';
import { Breadcrumbs, Link, makeStyles } from '@material-ui/core';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import Pagination from '../../../components/web/Pagination/Pagination.component';
import {
	PAGINATION_PAGES_VISIBLE,
	SalaryReportDataType,
} from './salaryReportPage.utils';
import colors from '../../../config/colors';
import moment from 'moment';
import I18n from '../../../components/shared/i18n/I18n.component';
import Typography from '../../../components/shared/Typography/Typography.component';

const useStyles = makeStyles((theme: any) => ({
	linkMain: {
		color: ({ isRowSelected }: any) =>
			isRowSelected ? colors.primary : colors.darkGrey,
		cursor: ({ isRowSelected }: any) =>
			isRowSelected ? 'pointer' : 'auto',
	},
	linkSelected: {
		color: colors.darkGrey,
	},
	separator: {
		color: '#3b70a4',
		fontFamily: 'RubikRegular',
		fontSize: '23px',
		fontWeight: 600,
	},
}));

interface SalaryReportsPageProps {
	navigation: any;
	headers: any;
	data: any[];
	reportNotFound: boolean;
	filtersData: any;
	onSort: (field: any, direction: any) => void;
	onLoadMore: () => void;
	onFilter: (data: FilterData) => void;
	onRowClick: (dataItem: any) => void;
	isRowSelected: boolean;
	selectedRowData?: SalaryReportDataType | null;
	onFetchPaycheckData: any;
	onDownloadPaycheck: any;
	pageCurrent: number;
	pageTotal: number;
	onPaginationChange: (value: number) => void;
	exportExcel(): void;
	navigateBack: () => void;
}

const SalaryReportPage = ({
	navigation,
	data,
	reportNotFound,
	headers,
	filtersData,
	onSort,
	onLoadMore,
	onFilter,
	onRowClick,
	isRowSelected,
	selectedRowData,
	onFetchPaycheckData,
	onDownloadPaycheck,
	pageCurrent,
	pageTotal,
	exportExcel,
	onPaginationChange,
	navigateBack,
}: SalaryReportsPageProps) => {
	const { height: windowHeight } = useWindowDimensions();

	const [isSmallDimensions, setIsSmallDimensions] = useState(
		window.innerWidth <= 1600,
	);

	const classes = useStyles({ isRowSelected });

	return (
		<ReportsPageWrapper
			navigation={navigation}
			title={
				<Breadcrumbs separator='<' className={classes.separator}>
					{isRowSelected && (
						<Link
							color='inherit'
							underline='none'
							variant='h4'
							className={classes.linkSelected}
						>
							{
								<I18n
									size={36}
									weight='normal'
									style={{ flex: 1 }}
								>
									{selectedRowData?.fullName}
								</I18n>
							}
						</Link>
					)}
					<Link
						onClick={isRowSelected ? navigateBack : undefined}
						color='inherit'
						className={classes.linkMain}
						underline={isRowSelected ? 'always' : 'none'}
					>
						<View style={{ flexDirection: 'row-reverse' }}>
							<I18n
								size={36}
								color={isRowSelected ? 'primary' : 'darkGrey'}
								weight='normal'
							>
								salaryReportPage.title
							</I18n>
						</View>
					</Link>
				</Breadcrumbs>
			}
		>
			<View
				style={{
					marginBottom: 10,
					zIndex: 10001,
					flexDirection: 'row-reverse',
					justifyContent: 'space-between',
				}}
			>
				<FiltersContainer
					config={filtersData}
					onChange={onFilter}
					customStyles={{
						container: {},
						filterContainer: {
							marginRight: 0,
							marginLeft: 15,
							zIndex: 10001,
						},
					}}
				/>
				{!isRowSelected && (
					<TouchableOpacity
						onPress={pageTotal > 0 ? exportExcel : undefined}
						style={{ alignSelf: 'center', paddingHorizontal: 10 }}
					>
						<ImageIcon name='excel' />
					</TouchableOpacity>
				)}

				{isRowSelected && selectedRowData && !!pageCurrent && (
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<TouchableHighlight
							style={{ padding: 20 }}
							underlayColor='transparent'
							onPress={onDownloadPaycheck}
						>
							<ImageIcon
								name='downloadPaycheck'
								height={20}
								width={20}
							/>
						</TouchableHighlight>

						<Pagination
							pageTotal={pageTotal}
							pageCurrent={pageCurrent}
							onChange={onPaginationChange}
							pagesVisible={PAGINATION_PAGES_VISIBLE}
						/>
					</View>
				)}
			</View>

			{reportNotFound && !isRowSelected && (
				<View
					style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: -1,
					}}
				>
					{
						<div
							style={{
								color: '#525962',
								fontFamily: 'RubikBold',
								fontSize: '24px',
								fontWeight: 'bold',
							}}
						>
							{i18nService.translate(
								'salaryReportPage.noReports',
							)}
						</div>
					}
				</View>
			)}

			{isRowSelected && !reportNotFound && selectedRowData && (
				<View
					style={{
						zIndex: 3000,
						height: windowHeight - 260,
					}}
				>
					<PaycheckView
						selectedDate={moment(
							selectedRowData?.salaryMonth,
							'MM/YYYY',
						).format('YYYY/MM')}
						workerId={selectedRowData?.id}
						onFetchPaycheckData={onFetchPaycheckData}
						unitId={selectedRowData?.businessUnitId}
					/>
				</View>
			)}

			{!isRowSelected && !reportNotFound && (
				<View>
					<View
						style={{
							flexDirection: 'row-reverse',
							paddingBottom: 10,
							alignSelf: 'flex-start',
						}}
					>
						<I18n size={14}>general.numberOfRecords</I18n>
						<Typography size={14} weight='normal'>
							{` ${pageTotal || ''} `}
						</Typography>
					</View>

					<View
						style={{
							maxHeight:
								windowHeight - (isSmallDimensions ? 245 : 245),
						}}
					>
						<TableContainer
							keyName={''}
							headers={headers}
							data={data || []}
							count={12}
							getNextData={onLoadMore}
							onSort={onSort}
							onRowClick={onRowClick}
							hasFooter={false}
						/>
					</View>
				</View>
			)}
		</ReportsPageWrapper>
	);
};

export default SalaryReportPage;
