import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import { useSelector } from 'react-redux';
import { Privileges } from '../../../constants/roleTypes.constant';
import { Employee101Data } from '../../../pages/ReportPDFPage/ReportPDFPage.utils';
import { onPressUpdate } from '../../../pages/WorkerCard/workerCard.utils';
import { downloadEmployeeAttachmentsHandler } from '../../../pages/WorkerCard/WorkerCardPreview/utils';
import httpService from '../../../services/http.service';
import { getEmployeeRolesPrivileges } from '../../../store/selectors/login.selectors';
import ImageIcon from '../Icon/ImageIcon.component';
import Typography from '../Typography/Typography.component';

const MobileWorkerCardHeader = ({
	innerHeaderData,
}: {
	innerHeaderData: Employee101Data;
}) => {
	const navigation = useNavigation();
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

	return (
		<View style={styles.dataContainer}>
			<View style={styles.firstRowContainer}>
				<View style={styles.firstRowDowload}>
					<TouchableHighlight
						disabled={!innerHeaderData.attachedFiles}
						style={StyleSheet.flatten([
							styles.btn,
							!innerHeaderData.attachedFiles &&
								styles.btnDisabled,
						])}
						underlayColor='transparent'
						onPress={() =>
							downloadEmployeeAttachmentsHandler(
								innerHeaderData.id,
								innerHeaderData,
							)
						}
					>
						<ImageIcon
							name='downloadPaycheck'
							height={20}
							width={20}
						/>
					</TouchableHighlight>

					<Typography
						color='darkGrey'
						weight='400'
						size={14}
						style={{ margin: 10 }}
					>
						{innerHeaderData?.attachedFiles
							? innerHeaderData?.attachedFiles
							: 0}
					</Typography>
					{innerHeaderData.enableUpdate && (
						<TouchableHighlight
							disabled={
								!employeeRolesPrivileges?.includes(
									Privileges.ROLE_EMPLOYEE_FORM_WRITE,
								)
							}
							style={
								!employeeRolesPrivileges?.includes(
									Privileges.ROLE_EMPLOYEE_FORM_WRITE,
								)
									? styles.btnDisabled
									: undefined
							}
							underlayColor='transparent'
							onPress={() =>
								onPressUpdate(
									navigation,
									innerHeaderData.id,
									// innerHeaderData.statusCode,
								)
							}
						>
							<View style={styles.innerButtonItem}>
								<Typography
									color='white'
									weight='normal'
									size={16}
								>
									{'עדכן טופס 101'}
								</Typography>
							</View>
						</TouchableHighlight>
					)}
				</View>
				<View style={styles.firstRowTitle}>
					<Typography color='darkGrey' weight='bold' size={20}>
						{innerHeaderData?.taxYear}
					</Typography>
					<Typography
						color='darkGrey'
						weight='normal'
						size={12}
						style={{ marginLeft: 10 }}
					>
						{'טופס 101'}
					</Typography>
				</View>
			</View>

			<View style={styles.secondRowContainer}>
				<View style={styles.secondRowContent}>
					<Typography color='darkGrey' weight='400' size={12}>
						{innerHeaderData?.lastUpdate}
					</Typography>
					<Typography
						color='darkGrey'
						weight='normal'
						size={12}
						style={{ margin: 10 }}
					>
						{'תאריך עדכון אחרון'}
					</Typography>
				</View>

				<View style={styles.secondRowContent}>
					<Typography color='darkGrey' weight='400' size={12}>
						{innerHeaderData?.submitDate}
					</Typography>
					<Typography
						color='darkGrey'
						weight='normal'
						size={12}
						style={{ margin: 10 }}
					>
						{'תאריך הגשה'}
					</Typography>
				</View>

				<View style={styles.secondRowContent}>
					<Typography color='darkGrey' weight='400' size={12}>
						{innerHeaderData?.status}
					</Typography>
					<Typography
						color='darkGrey'
						weight='normal'
						size={12}
						style={{ margin: 10 }}
					>
						{'סטטוס'}
					</Typography>
				</View>
			</View>
		</View>
	);
};

export default MobileWorkerCardHeader;

const styles = StyleSheet.create({
	dataContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginLeft: 21,
		marginRight: 21,
	},
	firstRowContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	firstRowDowload: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
	},
	firstRowTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	secondRowContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	secondRowContent: {
		display: 'flex',
		flexDirection: 'column-reverse',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	btn: {
		height: 20,
		width: 20,
		justifyContent: 'center',
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	btnDisabled: {
		opacity: 0.5,
	},
	innerButtonItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 30,
		width: 120,
		backgroundColor: '#4aaeef',
		borderRadius: 25,
	},
});
