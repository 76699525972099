import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { View, StyleSheet } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import { getRequestData } from './CreateEditRequest.utils';
import SiknessRequest from './RequestForm/SiknessRequest';
import VacationRequest from './RequestForm/VacationRequest';
import { Privileges } from '../../../constants/roleTypes.constant';
import { getEmployeeRolesPrivileges } from '../../../store/selectors/login.selectors';

interface RequestFieldsProps {
	containerStyles?: any;
	requestId: string;
	onNext: any;
	validationDate: number;
}

export interface RequestDetails {
	id: number;
	type: 'VACATION' | 'SICKNESS';
	status: string;
	fromDate: string;
	toDate: string;
	lastUpdate: string;
	description: string;
	employerComment: string;
	lastVacationData: null | {
		vacationBalanceDate: string;
		vacationBalance: number;
	};
	files: { id: number }[];
	deletedFiles: any;
}

const EditRequest = ({
	requestId,
	containerStyles,
	onNext,
	onrefresh,
	validationDate,
}: RequestFieldsProps) => {
	const [requestData, setRequestData] = useState<RequestDetails | null>(null);
	const [from, setFrom] = useState(undefined);
	const [to, setTo] = useState(undefined);
	const minDate = moment().subtract(1, 'months').startOf('month').valueOf();

	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

	const vacationPermition = employeeRolesPrivileges?.includes(
		Privileges.ROLE_VACATION_REQUEST_WRITE,
	);
	const siknessPermition = employeeRolesPrivileges?.includes(
		Privileges.ROLE_SICKNESS_REQUEST_WRITE,
	);

	useEffect(() => {
		getRequest();
	}, []);

	const getRequest = async () => {
		const data = await getRequestData(requestId);

		setRequestData(data);
	};

	return (
		<View style={containerStyles}>
			<I18n size={24} weight='normal' color='darkGrey'>
				applicationsToEmployerContainer.updateRequest
			</I18n>

			<I18n
				size={20}
				weight='normal'
				color='darkGrey'
				style={{ marginTop: 7 }}
			>
				{requestData?.type === 'VACATION'
					? 'applicationsToEmployerContainer.vacationRequest'
					: 'applicationsToEmployerContainer.sicknessReport'}
			</I18n>

			{requestData ? (
				requestData?.type === 'VACATION' ? (
					<VacationRequest
						requestData={requestData}
						onClose={onNext}
						from={from}
						setFrom={setFrom}
						to={to}
						setTo={setTo}
						onrefresh={onrefresh}
						// minDate={minDate}
						validationDate={validationDate}
						disabled={!vacationPermition && !siknessPermition}
					/>
				) : (
					<SiknessRequest
						requestData={requestData}
						onClose={onNext}
						from={from}
						setFrom={setFrom}
						to={to}
						setTo={setTo}
						onrefresh={onrefresh}
						// minDate={minDate}
						validationDate={validationDate}
						disabled={!vacationPermition && !siknessPermition}
					/>
				)
			) : (
				<View />
			)}
		</View>
	);
};
const styles = StyleSheet.create({});

export default EditRequest;
