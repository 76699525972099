export const SET_TOKENS = 'SET_TOKENS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_INITIAL_STATE = 'LOGIN_INITIAL_STATE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const VERIFY_FORGOT_PASSWORD_REQUEST = 'VERIFY_FORGOT_PASSWORD_REQUEST';
export const VERIFY_FORGOT_PASSWORD_SUCCESS = 'VERIFY_FORGOT_PASSWORD_SUCCESS';

export const USER_DATA_REQUEST = 'USER_DATA_REQUEST';
export const USER_DATA_FAILURE = 'USER_DATA_FAILURE';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const LOCAL_UPDATE_USER_DATA = 'LOCAL_UPDATE_USER_DATA';

export const EMPLOYER_LIST_REQUEST = 'EMPLOYER_LIST_REQUEST';
export const EMPLOYER_LIST_SUCCESS = 'EMPLOYER_LIST_SUCCESS';
export const EMPLOYER_LIST_FAILURE = 'EMPLOYER_LIST_FAILURE';

export const SELECT_EMPLOYER_REQUEST = 'SELECT_EMPLOYER_REQUEST';
export const SELECT_EMPLOYER_FAILURE = 'SELECT_EMPLOYER_FAILURE';
export const SELECT_EMPLOYER_SUCCESS = 'SELECT_EMPLOYER_SUCCESS';

export const SET_WORKER_CARD_ALERT = 'SET_WORKER_CARD_ALERT';
export const SET_IS_IN_WORKER_CARD = 'SET_IS_IN_WORKER_CARD';

export const NO_PERMISSIONS = 'NO_PERMISSIONS';

export const OUTDATED_APP_VERSION = 'OUTDATED_APP_VERSION';

export const LOGOUT = 'LOGOUT';
