import { StoreAction } from '../../../interfaces/redux.interface';
import { SalaryReportsFilterTypes } from './SalaryReportPage.reducer';

const directionNameMap: Record<'ascending' | 'descending', 'ASC' | 'DESC'> = {
	ascending: 'ASC',
	descending: 'DESC',
};

export const SET_SORT = 'SET_SORT';
export const SET_FILTER = 'SET_FILTER';
export const SET_EMPLOYEE_ID = 'SET_EMPLOYEE_ID';
export const INCREMENT_PAGE = 'INCREMENT_PAGE';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const RESET_FILTERS = 'RESET_FILTERS';
export const REPORT_lAST_DATE_REQUEST_SUCCESS = 'REPORT_lAST_DATE_REQUEST_SUCCESS';
export const UPDATE_BUSINESSUNITS = 'UPDATE_BUSINESSUNITS';

export const sort = (
	field: string,
	direction: string,
): StoreAction<{ name: string; direction?: string }> => ({
	type: SET_SORT,
	payload: {
		name: field,
		direction: direction,
	},
});

export const incrementPage = (): StoreAction<undefined> => ({
	type: INCREMENT_PAGE,
	payload: undefined,
});

export const updateDepartment = (): StoreAction<any> => ({
	type: UPDATE_DEPARTMENT,
	payload: [],
});

export const updateBusinessUnits = (data: any): StoreAction<any> => ({
	type: UPDATE_BUSINESSUNITS,
	payload: data,
});

export const filter = (
	filterType: SalaryReportsFilterTypes,
	value: string[],
	isMultiselect: boolean = false,
): StoreAction<{
	filter: SalaryReportsFilterTypes;
	value: string[];
	isMultiselect: boolean;
}> => ({
	type: SET_FILTER,
	payload: {
		filter: filterType,
		value,
		isMultiselect,
	},
});
