import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ModalWrapper from '../../../../components/web/ModalWrapper/ModalWrapper.component';
import { i18nService } from '../../../../services/i18n.service';
import SolidButton from '../../../../components/shared/Buttons/SolidButton.container';
import OutlineButton from '../../../../components/shared/Buttons/OutlineButton.container';
import EditCustomer from './EditCustomer.component';
import EditCustomerWrapperMobile from './EditCustomerWrapper.component.native';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import colors from '../../../../config/colors';
import { Dimensions } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import { View } from '@react-pdf/renderer';

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginTop: 17,
	},
}));

const ButtonsRow = ({ onSubmit, onCancel, disabledSubmit }: any) => {
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<SolidButton
				onPress={onSubmit}
				size={'medium'}
				overrideStyle={{ marginLeft: 23 }}
				disabled={disabledSubmit}
			>
				general.save
			</SolidButton>
			<OutlineButton onPress={onCancel} size='medium'>
				general.cancel
			</OutlineButton>
		</div>
	);
};

const EditCustomerWrapper = ({ onSubmit, onCancel, id, ...rest }: any) => {
	const { formState } = useFormContext();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;

	return isMobile ? (
		<EditCustomerWrapperMobile {...{ onSubmit, onCancel, id }} {...rest} />
	) : (
		<View
			style={{
				backgroundColor: '#fff',
				borderRadius: '20px',
				direction: 'rtl',
				height: '80vh',
				padding: '22px 17px',
				width: 430,
			}}
		>
			<div
				style={{
					borderBottom: `1px solid #979797`,
					paddingBottom: '18px',
					width: '100%',
					backgroundColor: colors.white,
				}}
			>
				<I18n size={30} weight='normal'>
					{`editCustomer.title${id ? 'Edit' : 'Create'}`}
				</I18n>
			</div>
			<div
				style={{
					height:
						Dimensions.get('window').height > 728 ? '77%' : '70%',
					overflow: 'auto',
					marginTop: 25,
				}}
			>
				<EditCustomer {...rest} id={id} />
			</div>
			<ButtonsRow
				onSubmit={onSubmit}
				onCancel={onCancel}
				disabledSubmit={!formState.isDirty}
			/>
		</View>
	);
};

export default EditCustomerWrapper;
