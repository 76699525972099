import React, { useState, useMemo } from "react";
import { View, StyleSheet, TouchableHighlight } from "react-native";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Typography from "../../../components/shared/Typography/Typography.component";
import colors from "../../../config/colors";
import ImageIcon from "../../shared/Icon/ImageIcon.component";
import { i18nService } from "../../../services/i18n.service";
import { createGlobalStyle } from "styled-components";
import I18n from "../../shared/i18n/I18n.component";

const GlobalStyle = createGlobalStyle`
.daterangepicker.rtl .ranges{
	  text-align:right
  }
.applyBtn{
	display: inline-block;
    margin-bottom: 0; 
 	font-weight: normal; 
    text-align: center; 
    cursor: pointer; 
    background-image: none; 
    border: 1px solid transparent;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
	margin-left: 8px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
	color: #fff;
    background-color: #286090;
    border-color: #204d74;

}
.cancelBtn{
	display: inline-block;
    margin-bottom: 0; 
 	font-weight: normal; 
    text-align: center; 
    cursor: pointer; 
    background-image: none; 
    border: 1px solid transparent;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
	margin-left: 8px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
	color: #333;
    background-color: #fff;
    border-color: #ccc;
}
.daterangepicker th.prev.available ,.daterangepicker th.next.available{

  text-align: center;
      vertical-align: middle;
      min-width: 32px;
      width: 32px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid transparent;
      white-space: nowrap;
      cursor: pointer;
      transform: rotate(360deg) !important;
  }
  .daterangepicker table.table-condensed thead tr:nth-child(1)  {
    transform: rotate(180deg);

  }
  .daterangepicker table.table-condensed thead tr:nth-child(1) th {
    transform: rotate(180deg);

  }
.daterangepicker .drp-calendar{
  float:right;
}
`;

export interface settings {
  startDate: any;
  endDate: any;
  ranges: object;
  locale: locale;
}

export interface locale {
  direction?: string;
  applyLabel?: string;
  cancelLabel?: string;
  customRangeLabel?: string;
  daysOfWeek?: string[];
  monthNames?: string[];
  firstDay?: number;
}

export interface props {
  config?: any;
  key?: string;
  onApply?: Function;
  onCancel?: Function;
  onEvent?: Function;
  onHide?: Function;
  onHideCalendar?: Function;
  onShow?: Function;
  onShowCalendar?: Function;
  onChange?: Function;
  ref?: any;
  ranges?: any;
  lable: string;
}

const customDatePicker = ({
  config,
  key,
  onApply,
  onCancel,
  onEvent,
  onHide,
  onHideCalendar,
  onShow,
  onShowCalendar,
  onChange,
  ref,
  lable,
}: props) => {
  return (
    <>
      <GlobalStyle />
      <DateRangePicker
        initialSettings={{
          ...config.extraProps,
          applyButtonClasses: "applyBtn",
          cancelButtonClasses: "cancelBtn",
        }}
        onCallback={onChange}
        key={key}
        onApply={onApply}
        onCancel={onCancel}
        onEvent={onEvent}
        onHide={onHide}
        onHideCalendar={onHideCalendar}
        onShow={onShow}
        onShowCalendar={onShowCalendar}
        ref={ref}
      >
        <TouchableHighlight>
          <View style={styles.Button}>
            <ImageIcon name="calendar_web" width={20} height={20} />
            <I18n style={{ alignItems: "center", marginLeft: 10 }} size={14}>
              {lable}
            </I18n>
          </View>
        </TouchableHighlight>
      </DateRangePicker>
    </>
  );
};

export default customDatePicker;

const styles = StyleSheet.create({
  Button: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 5,
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "rgb(255, 255, 255)",
    borderColor: "rgb(231, 231, 232)",
    borderWidth: 1,
    height: 32,
    minWidth: 150,
  },
});
