import { Privileges } from '../../constants/roleTypes.constant';
import { i18nService } from '../../services/i18n.service';
import { Form101 } from './TabsComponents/Form101';
import { PayChecks } from './TabsComponents/PayChecks';
import { Presence } from './TabsComponents/Presence';

export const tabsOptions = (
	navigation: any,
	employerRolesPrivileges: any,
	presenceModuleExists: boolean,
) => {
	const tabs: any = [];
	const data = [
		{
			key: 'presence',
			name: 'presence',
			label: i18nService.translate('businessSettings.presence'),
			onPress: () => navigation.navigate('presence'),
			privilegesWrite: Privileges.ROLE_CUSTOMER_SETTING_WRITE,
			privilegesRead: Privileges.ROLE_CUSTOMER_SETTING_READ,
			modules: presenceModuleExists,
			tabComponent: Presence,
		},
		{
			key: 'form101',
			name: 'form101',
			label: i18nService.translate('businessSettings.101File'),
			onPress: () => navigation.navigate('form101'),
			privilegesWrite: Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
			privilegesRead: Privileges.ROLE_EMPLOYEE_FORM_REPORT_READ,
			tabComponent: Form101,
		},
		{
			key: 'payChecks',
			name: 'payChecks',
			label: i18nService.translate('businessSettings.payChecks'),
			onPress: () => navigation.navigate('payChecks'),
			privilegesWrite: Privileges.ROLE_EMP_PAYCHECKS_READ,
			privilegesRead: Privileges.ROLE_EMP_PAYCHECKS_WRITE,
			tabComponent: PayChecks,
		},
	];

	data.forEach(tab => {
		if (tab.name === 'presence') {
			if (tab?.modules) {
				tabs.push(tab);
			}
		} else if (
			employerRolesPrivileges?.includes(tab.privilegesWrite) ||
			employerRolesPrivileges?.includes(tab.privilegesRead)
		) {
			tabs.push(tab);
		}
	});
	return tabs;
};
