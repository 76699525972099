import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { FAB } from 'react-native-paper';
import { useSelector } from 'react-redux';
import { Portal } from 'react-native-paper';

import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';
import UserDetailsSection from '../UserDetailsSection/UserDetailsSection.container';
import {
	getEmployerRolesPrivileges,
	getRepRolesPrivileges,
	getUserData,
} from '../../../../store/selectors/login.selectors';
import HideOnKeyboardOpen from '../../../../components/shared/HideOnKeyboardOpen/HideOnKeyboardOpen.component';
import I18n from '../../../../components/shared/i18n/I18n.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import UnitsRolesWrapper from '../RolesSection/UnitsRolesWrapper';
import RolesSection from '../RolesSection/RolesSection.component';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { Privileges } from '../../../../constants/roleTypes.constant';

const CreateUser = React.forwardRef(
	(
		{
			roles,
			setRoles,
			onSubmit,
			onCancel,
			disabledSubmit,
			onRoleClick,
			route,
			isActive,
			id,
			businessUnitsList,
			rolesInfo,
			validationFunctions,
			getValuesFunctions,
			addBusinessUnit,
			defaultOpenUnit,
		}: any,
		ref: any,
	) => {
		const userData = useSelector(getUserData);
		const employerRolePrivileges = useSelector(getEmployerRolesPrivileges);
		const repRolePrivileges = useSelector(getRepRolesPrivileges);
		const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
		const platform = useDevicePlatform();

		return (
			<Portal.Host>
				<View style={styles.wrapper}>
					<View style={styles.header}>
						<I18n size={28} weight='normal' style={{ flex: 1 }}>
							{`editUserModal.title.${id ? 'edit' : 'add'}`}
						</I18n>
						<TouchableOpacity onPress={onCancel}>
							<ImageIcon name='leftArrow' />
						</TouchableOpacity>
					</View>
					<KeyboardAwareScrollView
						keyboardShouldPersistTaps='handled'
						contentContainerStyle={{
							paddingBottom: 70,
							width: '100%',
							flexWrap: 'wrap',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
						}}
						style={{
							flex: 1,
							marginTop: 19,
							paddingBottom: 30,
							maxHeight:
								Dimensions.get('screen').height -
								(platform === DeviceType.MOBILE_WEB
									? 240
									: 180),
							backgroundColor: colors.white,
							borderRadius: 10,
						}}
					>
						<UserDetailsSection
							userData={userData}
							roles={roles}
							isEditUser={id}
							isActive={isActive}
						/>
						<UnitsRolesWrapper
							getValuesFunctions={getValuesFunctions}
							isEditUser={id}
							userId={id}
							Component={RolesSection}
							rolesInfo={rolesInfo}
							roles={roles}
							businessUnitsList={businessUnitsList}
							setRoles={setRoles}
							onRoleClick={onRoleClick}
							route={route}
							addBusinessUnit={addBusinessUnit}
							setIsModalOpen={setIsModalOpen}
							validationFunctions={validationFunctions}
							defaultOpenUnit={defaultOpenUnit}
							disabled={
								!employerRolePrivileges?.includes(
									Privileges.ROLE_CREATE_USER_WRITE,
								) &&
								!repRolePrivileges?.includes(
									Privileges.ROLE_CREATE_USER_WRITE,
								)
							}
						/>
					</KeyboardAwareScrollView>
					{!isModalOpen && (
						<HideOnKeyboardOpen>
							<FAB
								style={[
									styles.cancel,
									platform === DeviceType.MOBILE_WEB && {
										right: 'auto',
										left:
											(Dimensions.get('screen').width -
												360) /
												2 +
											115,
									},
								]}
								small
								color={colors.lightPrimary}
								icon=''
								label={i18nService.translate('general.cancel')}
								onPress={onCancel}
							/>
						</HideOnKeyboardOpen>
					)}
					{!isModalOpen && (
						<HideOnKeyboardOpen>
							<FAB
								style={StyleSheet.flatten([
									styles.save,
									{ opacity: disabledSubmit ? 0.7 : 1 },
								])}
								small
								color={colors.white}
								icon=''
								label={i18nService.translate('general.save')}
								onPress={onSubmit}
								disabled={disabledSubmit}
							/>
						</HideOnKeyboardOpen>
					)}
				</View>
			</Portal.Host>
			//<>

			// </>
		);
	},
);

const styles = StyleSheet.create({
	save: {
		position: 'absolute',
		margin: 16,
		width: 100,
		left: 10,
		bottom: 15,
		borderColor: colors.primary,
		backgroundColor: colors.primary,
		borderWidth: 1,
	},
	next: {
		left: (Dimensions.get('screen').width - 360) / 2 + 115,
		position: 'absolute',
		margin: 16,
		width: 100,
		bottom: 15,
		borderColor: colors.lightPrimary,
		backgroundColor: colors.lightPrimary,
		borderWidth: 1,
	},
	cancel: {
		right: 10,
		position: 'absolute',
		margin: 16,
		width: 100,
		bottom: 15,
		borderColor: colors.lightPrimary,
		backgroundColor: colors.white,
		borderWidth: 1,
	},
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		paddingHorizontal: 16,
	},
	wrapper: {
		flex: 1,
		height: '100%',
		backgroundColor: colors.lightBlue,
		opacity: 1,
		paddingTop: 25,
	},
});

export default CreateUser;
