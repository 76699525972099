import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions, ScrollView, useWindowDimensions } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import PDFViewer from '../../../components/shared/PDFViewer/PDFViewer.component';
import colors from '../../../config/colors';

interface Props {
  data: any,
  prevPdfFile: any,
  currentPdfFile: any
}

const WorkerCardCompareModal = ({ data, prevPdfFile, currentPdfFile }: Props) => {
  const dimensions = useWindowDimensions();

  return (
    <View style={[styles.container, { height: dimensions.height - 200, width: dimensions.width - 200 }]}>
      <I18n weight='normal' size={18} userName={data.fullName} userId={data.teudatZeut} style={{paddingBottom: 30}}>report101ReportPage.compareModalTitle</I18n>
      
      <View style={{ flexDirection: 'row', height: 'calc(100% - 60px)' }}>
        {currentPdfFile && <PDFViewer file={currentPdfFile} />}
        {prevPdfFile && <PDFViewer file={prevPdfFile} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    shadowColor: 'rgba(0, 0, 0, 0)',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    borderRadius: 20,
    backgroundColor: colors.white,
    paddingHorizontal: 25,
    paddingTop: 30
  }
});

export default WorkerCardCompareModal;
