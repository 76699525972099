import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	FlatList,
	useWindowDimensions,
} from 'react-native';
import CheckBoxMobile from '@react-native-community/checkbox';
import WebCheckBox from 'expo-checkbox';
import HeaderContainer from '../../components/shared/Header/Header.container';
import Typography from '../../components/shared/Typography/Typography.component';
import I18n from '../../components/shared/i18n/I18n.component';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import { EmplooyeeRequestsProps } from './EmplooyeeRequests.component';
import {
	ApproveAllButton,
	ApproveButtons,
	approveMultiple,
	StatusSection,
} from './EmplooyeeRequests.utils';
import { EmplooyeeRequestsData } from './EmplooyeeRequests.container';
import FiltersDrawer from '../../components/shared/FiltersDrawer/FiltersDrawer';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import SearchInputContainer from '../../components/shared/SearchInput/SearchInput.container';
import colors from '../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../store/selectors/login.selectors';
import { Privileges } from '../../constants/roleTypes.constant';
import { i18nService } from '../../services/i18n.service';
import { modalService } from '../../services/modal.service';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import { hasPermissionOnBusinessUnit } from '../../utils/globals.utils';

const EmplooyeeRequestsMobile = ({
	navigation,
	data,
	selectedFilters,
	filtersConfig,
	finishFirstLoad,
	filtesSelected,
	validationDate,
	onFilter,
	onLoadMore,
	refresh,
	onAcceptRequests,
}: EmplooyeeRequestsProps) => {
	const dimentions = useWindowDimensions();
	const platform = useDevicePlatform();

	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [startSelect, setStartSelect] = useState(false);
	const [selectAll, setSelectAll] = useState(false);

	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);

	const startFiltering = value => {
		setIsSubMenuOpen(!isSubMenuOpen);
		startSelect ? onAcceptRequests(true, value) : onFilter(value, true);
	};

	const onStartSelect = () => {
		setStartSelect(!startSelect);
		setSelectedItems([]);
		setSelectAll(false);
		onAcceptRequests(true, {
			name: 'status',
			value: [{ id: 'PENDING', name: 'PENDING' }],
		});
	};

	const onApproveAll = async () => {
		if (selectedItems.length === 0) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>employeeRequests.selectUsers</I18n>
					</AlertModal>
				),
			);
		} else {
			setStartSelect(!startSelect);
			approveMultiple(selectedItems, refresh);
			onAcceptRequests(false);
		}
	};

	const onRowsChecked = (val: boolean, requestId: number) => {
		let data = [...selectedItems];
		if (val) {
			data.push(requestId);
		} else {
			setSelectAll(false);
			data = data.filter(el => el !== requestId);
		}
		setSelectedItems(data);
	};

	const onSelectAll = val => {
		setSelectAll(val);
		const selected = [];
		if (val) {
			data.forEach(item => {
				const vacationPermition =
					hasPermissionOnBusinessUnit(
						item.businessUnitId,
						Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
					) &&
					item.type ===
						i18nService.translate(
							'applicationsToEmployerContainer.vacationRequest',
						);
				const siknessPermition =
					hasPermissionOnBusinessUnit(
						item.businessUnitId,
						Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
					) &&
					item.type ===
						i18nService.translate(
							'applicationsToEmployerContainer.sicknessReport',
						);
				if (!!vacationPermition || !!siknessPermition) {
					selected.push(item?.id);
				}
			});
		}
		setSelectedItems(selected);
	};

	const mobileRowItem = (item: EmplooyeeRequestsData, index: number) => {
		const vacationPermition =
			hasPermissionOnBusinessUnit(
				item.businessUnitId,
				Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
			) &&
			item.type ===
				i18nService.translate(
					'applicationsToEmployerContainer.vacationRequest',
				);
		const siknessPermition =
			hasPermissionOnBusinessUnit(
				item.businessUnitId,
				Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
			) &&
			item.type ===
				i18nService.translate(
					'applicationsToEmployerContainer.sicknessReport',
				);

		return (
			<View
				style={[styles.rowContainer, { width: dimentions.width }]}
				key={item?.id}
			>
				{startSelect ? (
					vacationPermition || siknessPermition ? (
						platform === DeviceType.MOBILE_WEB ? (
							<WebCheckBox
								value={
									!!selectedItems.find(
										el => el === item?.id,
									) || false
								}
								onValueChange={val =>
									onRowsChecked(val, item?.id)
								}
								color={colors.primary}
								style={{
									width: 18,
									height: 18,
									borderRadius: 4,
									marginLeft: 10,
								}}
							/>
						) : (
							<CheckBoxMobile
								value={
									!!selectedItems.find(
										el => el === item?.id,
									) || false
								}
								onValueChange={val =>
									onRowsChecked(val, item?.id)
								}
								boxType='square'
								onCheckColor={colors.white}
								onFillColor={colors.primary}
								onTintColor={colors.primary}
								tintColors={{ true: colors.primary }}
								style={{
									width: 22,
									height: 22,
									borderRadius: 2,
								}}
							/>
						)
					) : (
						<View style={{ width: 18, marginLeft: 10 }} />
					)
				) : null}
				<TouchableHighlight
					key={item?.id}
					onPress={() =>
						navigation.navigate('requestDetailsMobile', {
							requestId: item.id,
							businessUnitId: item.businessUnitId,
							businessUnitName: item.businessUnitName,
							validationDate,
						})
					}
					underlayColor='transparent'
					style={{ width: '93%' }}
				>
					<View style={[styles.dataRowContainer]}>
						<View style={{ width: dimentions.width / 3 }}>
							<Typography weight='bold' size={14}>
								{item.fullName}
							</Typography>
							<Typography size={14}>{item.teudatZeut}</Typography>
						</View>

						<View
							style={{
								width: startSelect
									? dimentions.width / 3 - 8
									: dimentions.width / 3,
							}}
						>
							<Typography weight='bold' size={14}>
								{item.type}
							</Typography>
							<Typography size={14}>
								{item.dateRange.replace('-', '- ')}
							</Typography>
						</View>
						<View
							style={{
								width: dimentions.width / 3 - 13,
								alignItems: 'center',
							}}
						>
							<StatusSection
								status={item.status}
								style={{ flexDirection: 'row-reverse' }}
								isMobile
							/>
						</View>
					</View>
				</TouchableHighlight>
			</View>
		);
	};

	return (
		<FiltersDrawer
			isOpen={isSubMenuOpen}
			onToggleSubMenu={setIsSubMenuOpen}
			config={filtersConfig}
			onFilter={startFiltering}
			selectedFilters={selectedFilters}
		>
			<View>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
			</View>
			<View style={{ marginRight: 16 }}>
				<I18n weight='normal' size={28}>
					{'applicationsToEmployerContainer.title'}
				</I18n>
			</View>

			<View nativeID='#searchWrapper' style={styles.searchWrapper}>
				<SearchInputContainer
					onChange={onFilter}
					config={
						filtersConfig.find(
							singleConf => singleConf.type === 'searchInput',
						)!
					}
					styleConf={{ container: { width: '100%' } }}
					selectedFilters={selectedFilters}
				/>
			</View>
			<View
				style={[
					styles.filterSortingSelectButtons,
					{ paddingLeft: startSelect ? 0 : 20 },
				]}
			>
				{startSelect ? (
					<ApproveButtons
						style={{ width: 290 }}
						onApprove={() => onApproveAll()}
						onCancel={() => {
							setStartSelect(false);
							onAcceptRequests(false);
						}}
					/>
				) : (
					<ApproveAllButton
						disabled={
							!employerRolesPrivileges?.includes(
								Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
							) &&
							!employerRolesPrivileges?.includes(
								Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
							)
						}
						onStartSelect={() => onStartSelect()}
					/>
				)}
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => setIsSubMenuOpen(true)}
				>
					{!filtesSelected ? (
						<ImageIcon name='filter' width={20} height={20} />
					) : (
						<ImageIcon name='filterActive' width={20} height={20} />
					)}
				</TouchableHighlight>
			</View>

			{data.length > 0 ? (
				<View style={styles.dataContainer}>
					<View style={styles.listHeader}>
						{startSelect &&
							(platform === DeviceType.MOBILE_WEB ? (
								<WebCheckBox
									value={selectAll}
									onValueChange={val => onSelectAll(val)}
									color={colors.primary}
									style={{
										width: 18,
										height: 18,
										borderRadius: 4,
										marginLeft: 10,
									}}
								/>
							) : (
								<CheckBoxMobile
									value={selectAll}
									onValueChange={val => onSelectAll(val)}
									boxType='square'
									onCheckColor={colors.white}
									onFillColor={colors.primary}
									onTintColor={colors.primary}
									tintColors={{ true: colors.primary }}
									style={{
										width: 22,
										height: 22,
										borderRadius: 2,
									}}
								/>
							))}
						<I18n
							weight='normal'
							style={{ width: dimentions.width / 3 }}
						>
							{'employeeRequests.name'}
						</I18n>
						<I18n
							weight='normal'
							style={{ width: dimentions.width / 3 + 10 }}
						>
							{'applicationsToEmployerContainer.requestType'}
						</I18n>
						<I18n
							weight='normal'
							style={{
								width: dimentions.width / 3 - 15,
								textAlign: 'center',
							}}
						>
							{'applicationsToEmployerContainer.status'}
						</I18n>
					</View>
					<FlatList
						data={data}
						renderItem={({ item, index }) =>
							mobileRowItem(item, index)
						}
						style={{
							height:
								dimentions.height - (startSelect ? 240 : 300),
						}}
						contentContainerStyle={{ paddingBottom: 150 }}
						onEndReached={async () => await onLoadMore()}
						keyExtractor={item => `${item.id}`}
					/>
				</View>
			) : finishFirstLoad ? (
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<I18n
						weight='bold'
						size={24}
						style={{ marginTop: 50, marginRight: 26 }}
					>
						applicationsToEmployerContainer.noReports
					</I18n>
				</View>
			) : (
				<View />
			)}
		</FiltersDrawer>
	);
};

const styles = StyleSheet.create({
	title: {
		marginLeft: 8,
	},
	buttonsLayerContainer: {
		marginTop: 6,
		display: 'flex',
		alignItems: 'flex-end',
	},
	dataContainer: {
		marginTop: 10,
	},
	rowContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		height: 70,
		backgroundColor: 'white',
		marginTop: 10,
		marginBottom: 10,
		alignItems: 'center',
		paddingVertical: 10,
		paddingHorizontal: 20,
	},
	dataRowContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	listHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row-reverse',
		paddingHorizontal: 20,
		paddingBottom: 4,
	},
	filterSortingSelectButtons: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	searchWrapper: {
		paddingRight: 10,
		paddingLeft: 10,
		marginTop: 23,
		marginBottom: 13,
	},
});

export default EmplooyeeRequestsMobile;
