import { useEffect, useRef, useState } from 'react';
import { useWindowDimensions, Platform, Dimensions } from 'react-native';

export enum DeviceType {
	WEB = 'WEB',
	MOBILE = 'MOBILE',
	MOBILE_WEB = 'MOBILE_WEB',
}

const useDevicePlatform = () => {
	const { width, height } =
		Platform.OS === 'web'
			? Dimensions.get('screen')
			: useWindowDimensions();
	const [deviceType, setDeviceType] = useState(
		Platform.OS === 'android' || Platform.OS === 'ios'
			? DeviceType.MOBILE
			: width > height
			? DeviceType.WEB
			: DeviceType.MOBILE_WEB,
	);
	const deviceTypeRef = useRef(deviceType);

	useEffect(() => {
		if (Platform.OS === 'android' || Platform.OS === 'ios') {
			setDeviceType(DeviceType.MOBILE);
		} else {
			setDeviceType(
				width > height ? DeviceType.WEB : DeviceType.MOBILE_WEB,
			);
		}
	}, [width, height]);
	return deviceTypeRef?.current;
};

export default useDevicePlatform;
