// import { addCommaSeparatorToNumber } from '../../../utils/reportsPagesHelpers.utils';
import {
	HomePageReportContentElType,
	HomePageReportContentKeyType,
} from '../HomePage.container';

const addCommaSeparatorToNumber = (number: any) => {
	return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const stringDataTransform = (
	value: HomePageReportContentKeyType,
	dataArray: HomePageReportContentElType,
) => {
	const recievedValue = dataArray[`${value}` as HomePageReportContentKeyType];
	const transformedValue = addCommaSeparatorToNumber(Number(recievedValue));
	return transformedValue;
};
