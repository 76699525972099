import React, { useMemo } from 'react';
import { View, Text } from 'react-native';

const Files = ({ businessUnits }: any) => {
	return (
		<View>
			<Text>files</Text>
		</View>
	);
};

export default Files;
