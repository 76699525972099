import { Subscriber, Callback } from '../interfaces/core.interface';
// import { modalMap } from './modalMap';

export interface Modal {
	args: any;
	props: any;
	id: number;
	Component: any;
	resolve: (value?: any) => any;
}

class ModalService {
	private modals: Array<Modal> = [];
	private subscribers: Array<Subscriber> = [];
	private id = 0;

	/**
	 * Opens a configurable alert dialog.
	 */
	openAlert(cfg: {
		text: string;
		iconType?: string;
		confirmBtnText?: string;
		showCloseBtn?: boolean;
		headerText?: string;
		headerIconType?: string;
	}) {
		const withDefaults = Object.assign(
			{},
			{
				text: '',
				iconType: '',
				confirmBtnText: 'Ok',
				showCloseBtn: false,
				headerText: '',
			},
			cfg,
		);
		return this.openModal('alert', withDefaults);
	}

	/**
	 * Opens a configurable confirmation dialog.
	 */
	openConfirm(
		cfg: {
			text?: string;
			iconType?: string;
			confirmText?: string;
			cancelText?: string;
			showCloseBtn?: boolean;
			headerText?: string;
		},
		moreInfo?: any,
	) {
		const withDefaults = Object.assign(
			{
				text: '',
				iconType: '',
				cancelText: 'general.cancel',
				confirmText: 'general.confirm',
				showCloseBtn: false,
				headerText: '',
			},
			cfg,
		);
		return this.openModal('confirm', { ...withDefaults, ...moreInfo });
	}

	/**
	 * Opens a configurable confirmation With Multi Buttons dialog.
	 */
	openConfirmWithMultiButtons(cfg: {
		text?: string;
		iconType?: string;
		showCloseBtn?: boolean;
		cancelText?: string;
		multiButtons: { text: string; onPress: () => void }[];
	}) {
		const withDefaults = Object.assign(
			{
				text: '',
				iconType: '',
				cancelText: 'general.cancel',
				showCloseBtn: false,
			},
			cfg,
		);
		return this.openModal('ConfirmWithMultiButtons', withDefaults, );
	}

	// TODO: implement
	/**
	 * Open a modal. Receives a Component to put inside the modal, args
	 * to pass into the modal (as args prop), and props for the Material-UI Dialog Component.
	 * Returns a promise that resolves when the modal closes with an output value.
	 */
	openModal(args: any = {}, props: any = {}, component?: any): Promise<any> {
		// const cfg = modalMap[type];
		// if (!cfg) {
		//     throw new Error('ModalService.openModal: Unrecognized modal type!');
		// }
		return new Promise(resolve => {
		    this.modals = this.modals.concat({
		        args,
		        props: Object.assign({}, props),
		        id: this.id++,
		        Component: component,
		        resolve
		    });
		    this.subscribers.forEach(({ cb }) => {
		        return cb(this.modals);
		    });
		});
	}

	/**
	 * Close a modal. Requires a dialog-id (from modals array).
	 * Can supply an output value for the promise resolve of openModal().
	 */
	closeModal(id: number, value: any) {
		const modal = this.modals.find(m => m.id === id);
		this.modals = this.modals.filter(m => m.id !== id);
		this.subscribers.forEach(({ cb }) => cb(this.modals));
		modal && modal.resolve(value);
	}

	closeAllModal() {
		const temp = [...this.modals];
		this.modals = [];
		this.subscribers.forEach(({ cb }) => cb(this.modals));
		temp.forEach(m => m.resolve())
	}

	/**
	 * Subscribe to changes in modals array. Returns an unsubscribe function.
	 */
	subscribe(cb: Callback) {
		const ref = { cb };
		this.subscribers.push(ref);
		cb(this.modals);
		const unsubscribe = () => {
			this.subscribers = this.subscribers.filter(s => s !== ref);
		};
		return { unsubscribe };
	}
}

/**
 * Manages the app modals. Active modals are kept in an internal array.
 */
export const modalService = new ModalService();
