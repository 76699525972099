import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import CompareReportPage from './CompareReportPage/CompareReportPage.container';
import DetailedReportPage from './DetailedReportPage/DetailedReportPage.container';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import { useRoute } from '@react-navigation/native';
import { View } from 'react-native';
import { getDocumentTitle } from '../../../utils/globals.utils';

type CostingReportsProps = {
	navigation: any;
};

const useStyles = makeStyles(() => ({
	dropdown: {
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
	},
	arrowIcon: {
		fontSize: '2.5rem',
		cursor: 'pointer',
		marginRight: 5,
		marginTop: 5,
		color: colors.primary,
	},
	paper: {
		minWidth: 350,
		marginTop: 50,
		direction: 'rtl',
		borderRadius: 15,
		padding: '10px 0px',
		boxShadow: '0 6px 20px 0 rgba(59, 112, 164, 0.2)',
	},
	menuItem: {
		padding: '10px 21px',
	},
}));

const Tab = createBottomTabNavigator();

const CostingReportsPage = ({ navigation }: CostingReportsProps) => {
	const { height } = useWindowDimensions();
	const [selectedTab, setSelectedTab] = useState('detailed');
	const [anchorEl, setAnchorEl] = React.useState(null);
	
	const route = useRoute() as any;
	const open = Boolean(anchorEl);

	const classes = useStyles();

	useEffect(() => {
		route.state?.routes[route.state.index]?.name &&
			setSelectedTab(route.state?.routes[route.state.index]?.name);
	}, [route.state?.index]);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onClickDetails = () => {
		navigation.navigate('costingReports', { screen: 'detailed' });

		setSelectedTab('detailed');
		setAnchorEl(null);
	};

	const onClickCompare = () => {
		navigation.navigate('costingReports', { screen: 'compare' });
		setSelectedTab('compare');
		setAnchorEl(null);
	};

	return (
		<View
			style={{
				height: '100%',
			}}
		>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<>
						<div className={classes.dropdown} onClick={handleClick}>
							<I18n size={36} weight='normal' style={{ flex: 1 }}>
								{`costingReportPage.${selectedTab}.title`}
							</I18n>
							<KeyboardArrowDownRounded
								classes={{ root: classes.arrowIcon }}
							/>
						</div>
						<Menu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							classes={{ paper: classes.paper }}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
								dir: 'rtl',
							}}
						>
							<MenuItem
								classes={{ root: classes.menuItem }}
								onClick={onClickDetails}
							>
								<I18n
									size={18}
									weight='400'
									style={{ flex: 1, textAlign: 'right' }}
								>
									costingReportPage.detailed.title
								</I18n>
							</MenuItem>
							<MenuItem
								classes={{ root: classes.menuItem }}
								onClick={onClickCompare}
							>
								<I18n
									size={18}
									weight='400'
									style={{ flex: 1, textAlign: 'right' }}
								>
									costingReportPage.compare.title
								</I18n>
							</MenuItem>
						</Menu>
					</>
				}
			>
				<Tab.Navigator
					tabBar={() => null}
					initialRouteName='detailed'
					screenOptions={{ tabBarStyle: { display: 'none' } }}
					sceneContainerStyle={{ backgroundColor: 'transparent' }}
				>
					<Tab.Screen
						name='compare'
						component={CompareReportPage}
						options={{
							unmountOnBlur: true,
							title: getDocumentTitle('compare'),
						}}
					/>
					<Tab.Screen
						name='detailed'
						component={DetailedReportPage}
						options={{
							unmountOnBlur: true,
							title: getDocumentTitle('detailed'),
						}}
					/>
				</Tab.Navigator>
			</ReportsPageWrapper>
		</View>
	);
};

export default CostingReportsPage;
