import React, { useState } from 'react';
import {
	Dimensions,
	TouchableHighlight,
	useWindowDimensions,
	View,
} from 'react-native';
import FiltersContainer, {
	FilterData,
} from '../../../components/web/Filters/Filters.container';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { i18nService } from '../../../services/i18n.service';
import PaycheckView from '../../PaycheckPage/PaycheckView/PaycheckView.component';
import { Breadcrumbs, Link, makeStyles } from '@material-ui/core';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import Pagination from '../../../components/web/Pagination/Pagination.component';
import { AttendanceReportDataType } from './AttendanceReportPage.utils';
import PDFViewer from '../../../components/shared/PDFViewer/PDFViewer.container';
import colors from '../../../config/colors';
import moment from 'moment';
import I18n from '../../../components/shared/i18n/I18n.component';

const useStyles = makeStyles((theme: any) => ({
	linkMain: {
		color: ({ isRowSelected }: any) =>
			isRowSelected ? colors.primary : colors.darkGrey,
		cursor: ({ isRowSelected }: any) =>
			isRowSelected ? 'pointer' : 'auto',
	},
	linkSelected: {
		color: colors.darkGrey,
	},
	separator: {
		color: '#3b70a4',
		fontFamily: 'RubikRegular',
		fontSize: '23px',
		fontWeight: 600,
	},
}));
type BusinessUnit = {
	id: string;
	name: string;
	numericId: number;
};
interface AttendanceReportsPageProps {
	navigation: any;
	headers: any;
	data: any[];
	reportNotFound: boolean;
	filtersData: any;
	onSort: (field: any, direction: any) => void;
	onLoadMore: () => void;
	onFilter: (data: FilterData) => void;
	onRowClick: (dataItem: any) => void;
	isRowSelected: boolean;
	selectedRowData?: AttendanceReportDataType | null;
	navigateBack: () => void;
	pageCurrent: number;
	pageTotal: number;
	onPaginationChange: (value: number) => void;
	downloadPDF: any;
	filePDF: any;
	setFilePDF: any;
}

const AttendanceReportPage = ({
	navigation,
	data,
	reportNotFound,
	headers,
	filtersData,
	onSort,
	onLoadMore,
	onFilter,
	onRowClick,
	isRowSelected,
	selectedRowData,
	navigateBack,
	pageCurrent,
	pageTotal,
	onPaginationChange,
	downloadPDF,
	filePDF,
	setFilePDF,
}: AttendanceReportsPageProps) => {
	const { height: windowHeight } = useWindowDimensions();

	const classes = useStyles({ isRowSelected });
	return (
		<ReportsPageWrapper
			navigation={navigation}
			title={
				<Breadcrumbs separator='<' className={classes.separator}>
					{isRowSelected && (
						<Link
							color='inherit'
							underline='none'
							variant='h4'
							className={classes.linkSelected}
						>
							<I18n size={36} weight='normal' style={{ flex: 1 }}>
								{selectedRowData?.fullName}
							</I18n>
						</Link>
					)}
					<Link
						onClick={isRowSelected ? navigateBack : undefined}
						color='inherit'
						className={classes.linkMain}
						underline={isRowSelected ? 'always' : 'none'}
					>
						<I18n size={36} weight='normal' style={{ flex: 1 }}>
							{i18nService.translate(
								'attendanceReportPage.title',
							)}
						</I18n>
					</Link>
				</Breadcrumbs>
			}
		>
			<View
				style={{
					marginBottom: 31,
					zIndex: 10001,
					flexDirection: 'row-reverse',
					justifyContent: 'space-between',
				}}
			>
				<FiltersContainer
					config={filtersData}
					onChange={onFilter}
					customStyles={{
						container: {},
						filterContainer: {
							marginRight: 0,
							marginLeft: 15,
							zIndex: 10001,
						},
					}}
				/>

				{isRowSelected && selectedRowData && !!pageCurrent && (
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<TouchableHighlight
							disabled={!filePDF}
							underlayColor='transparent'
							onPress={downloadPDF}
						>
							<ImageIcon
								name='downloadPaycheck'
								height={20}
								width={20}
							/>
						</TouchableHighlight>

						<Pagination
							pageTotal={pageTotal}
							pageCurrent={pageCurrent}
							onChange={onPaginationChange}
							pagesVisible={3}
						/>
					</View>
				)}
			</View>

			{reportNotFound && !isRowSelected && (
				<View
					style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: -1,
					}}
				>
					{
						<div
							style={{
								color: '#525962',
								fontFamily: 'RubikBold',
								fontSize: '24px',
								fontWeight: 'bold',
							}}
						>
							{i18nService.translate(
								'attendanceReportPage.noReports',
							)}
						</div>
					}
				</View>
			)}

			{isRowSelected && !reportNotFound && selectedRowData && (
				<View
					style={{
						zIndex: 3000,
						height: windowHeight - 235,
					}}
				>
					<PDFViewer
						setFileUrl={setFilePDF}
						// selectedDate={selectedRowData?.salaryMonth}
						selectedDate={moment(
							selectedRowData?.salaryMonth,
							'MM/YYYY',
						).format('YYYY/MM')}
						selectedUser={selectedRowData?.id}
						userUnit={selectedRowData?.businessUnitId}
						// {...restProps}
					/>
				</View>
			)}

			{!isRowSelected && !reportNotFound && (
				<View
					style={{
						maxHeight: windowHeight - 250,
					}}
				>
					<TableContainer
						keyName={''}
						headers={headers}
						data={data || []}
						count={12}
						getNextData={onLoadMore}
						onSort={onSort}
						onRowClick={onRowClick}
						hasFooter={false}
					/>
				</View>
			)}
		</ReportsPageWrapper>
	);
};

export default AttendanceReportPage;
