import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { Divider } from 'react-native-paper';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import MultiselectContainer from '../../../components/shared/FieldBuilder/MultiSelect/Multiselect.container';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';

interface Event {
	name: string;
	id: string;
}

interface Props {
	eventsOptions: Event[];
	onUpdateEvents(events: Event[]): void;
	onNext(): void;
	isMobile: Boolean;
	selectedEvents: Event[];
	setSelectedEvents: Function;
	setShowEvents: Function;
	formMethods: any;
}

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const UpdateEventModal = ({
	eventsOptions,
	onNext,
	isMobile,
	selectedEvents,
	setSelectedEvents,
	setShowEvents,
	formMethods,
}: Props) => {
	const [disabled, setDisable] = useState(true);
	const [selectedEventsTemp, setSelectedEventsTemp] = useState([]);

	const addRemoveEvent = (events: any) => {
		const newEvents = events.value.map((event: any) => {
			return {
				value: event.id,
				label: event.name,
			};
		});
		setSelectedEventsTemp(
			events?.isAllSelected
				? [
						{
							value: 'all',
						},
				  ]
				: newEvents,
		);
		checkBeforeSave(newEvents, events?.isAllSelected);
	};

	const checkBeforeSave = (
		newEvents: Event[] | undefined,
		isAllSelected: boolean = false,
	) => {
		if ((newEvents && newEvents.length > 0) || isAllSelected === true) {
			return setDisable(false);
		} else {
			setDisable(true);
		}
		if (
			selectedEvents &&
			selectedEvents?.length > 0 &&
			newEvents === undefined
		) {
			setDisable(false);
		} else {
			setDisable(true);
		}
	};

	const handleOnNext = () => {
		if (selectedEvents.length === 0) {
			formMethods.setValue('eventSelectionType', 'ALL');
		}
		onNext();
	};

	const _onSave = () => {
		if (selectedEventsTemp.length > 0) {
			setSelectedEvents(selectedEventsTemp);
			if (selectedEventsTemp[0]?.value === 'all') {
				setSelectedEvents([]);
				setShowEvents(false);
				formMethods.setValue('eventSelectionType', 'ALL');
			}
		}
		onNext();
	};

	useEffect(() => {
		checkBeforeSave(undefined);
	}, []);

	const selectStyle = {
		control: {
			marginTop: 1,
			height: 40,
		},
		menu: {
			top: 39,
			boxShadow: 'none',
			border: `solid 1px #e7e7e8`,
			height: 266,
		},
		menuList: {
			height: 258,
			minHeight: 258,
		},
		placeholder: {
			paddingRight: 20,
		},
		newInput: {
			paddingRight: 20,
		},
		dropdownIndicator: { color: colors.lightBlue },
	};

	return (
		<View style={[styles.container, { height: isMobile ? 450 : 'auto' }]}>
			<I18n
				size={isMobile ? 24 : 30}
				weight='normal'
				style={[
					styles.title,
					{ textAlign: isMobile ? 'center' : 'right' },
				]}
			>
				businessSettings.presence.eddEvent
			</I18n>
			<Divider style={styles.headerDivider} />
			<View
				style={[
					styles.body,
					isMobile ? { minHeight: 260 } : { minHeight: 300 },
				]}
			>
				<MultiselectContainer
					key={``}
					config={{
						name: 'businessSettingsEventListMobile',
						returnAll: true,
						options: eventsOptions.sort((a: any, b: any) => {
							if (a.name < b.name) return -1;
							if (a.name > b.name) return 1;
							return 0;
						}),
						placeholder:
							'businessSettings.presence.eddEvent.placeholder',
						styles: {
							width: isMobile ? 40 : 350,
						},
						value: selectedEvents,
						showArrowIcon: false,
						showSaveIcon: true,
					}}
					listMode={'SCROLLVIEW'}
					isMenuOpen
					forceShowResults
					styles={selectStyle}
					onChange={addRemoveEvent}
				/>
			</View>
			<Divider style={styles.buttonsDivider} />
			<View style={styles.buttonContainer}>
				<SolidButtonContainer
					overrideStyle={{}}
					disabled={disabled}
					onPress={_onSave}
				>
					general.confirm
				</SolidButtonContainer>
				<SolidButtonContainer
					textOptions={{ color: colors.lightPrimary }}
					overrideStyle={styles.cancelButton}
					onPress={handleOnNext}
				>
					general.cancel
				</SolidButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		maxHeight: windowHeight - 76,
		width: windowWidth - 50,
		maxWidth: 408,
		padding: 30,
		flexDirection: 'column',
		justifyContent: 'space-between',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 12,
	},
	title: {
		marginBottom: 10,
		width: '100%',
	},
	headerDivider: {
		backgroundColor: colors.darkGrey,
		opacity: 1,
		marginVertical: 0,
	},
	body: {
		paddingVertical: 20,
		flex: 1,
		alignItems: 'center',
		justifyContent: 'flex-start',
		zIndex: 1,
	},
	buttonsDivider: {
		backgroundColor: colors.darkGrey,
		opacity: 0.2,
		marginVertical: 24,
	},
	buttonContainer: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		marginBottom: 10,
	},
	cancelButton: {
		backgroundColor: colors.white,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
	},
});

export default UpdateEventModal;
