import React from 'react';
import I18n from '../../../components/shared/i18n/I18n.component';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import { modalService } from '../../../services/modal.service';

export type Role = {
	id: number;
	name: string;
	allocate_department: boolean;
	description: null | string;
	permissions?: any[];
};

export interface UserData {
	role_info: {
		roles: Role[];
		user_roles: number[];
		user_role_department: {
			[key: string]: number[];
		};
		user_role_excluded_user: any;
		user_role_permission: {
			[key: string]: number[];
		};
		user_roles_indicator: {
			[key: string]: boolean;
		};
	};
	info: {
		attending_report_required: boolean;
		department_id: number;
		email: string;
		employee101_required: boolean;
		employee_type: string;
		first_name: string;
		id: number;
		identity_type: string;
		last_name: string;
		phone: string;
		status: boolean;
		teudat_zeut: string;
	};
	departments: [{ id: number; name: string }];
}

export const onSubmitFail = async (error: any) => {
	const errorMessage =
		typeof error?.data === 'string' && error?.data
			? error?.data
			: 'GENERAL_ERROR';
	await modalService.openModal(
		null,
		{
			submitBtnText: 'general.close',
			iconName: 'attention',
		},
		(props: any) => (
			<AlertModal {...props}>
				<I18n>{`editUserModal.serverError.${errorMessage}`}</I18n>
			</AlertModal>
		),
	);
};

export const onSubmitSuccess = async (key: string) => {
	await modalService.openModal(
		null,
		{
			submitBtnText: 'general.close',
			iconName: 'attention',
		},
		(props: any) => (
			<AlertModal {...props}>
				<I18n>{`editUserModal.${key}.success`}</I18n>
			</AlertModal>
		),
	);
};

export const onDeleteRole = ({
	roleId,
	roles,
	unitId,
	setRoles,
}: {
	roleId: number;
	roles: any;
	unitId: number;
	setRoles: Function;
}) => {
	const tempRoles = { ...roles };
	tempRoles.user_roles = tempRoles.user_roles?.filter(
		(id: number) => id !== roleId,
	);
	delete tempRoles.user_role_department?.[roleId];
	delete tempRoles.user_role_permission?.[roleId];
	delete tempRoles.user_roles_indicator?.[roleId];
	delete tempRoles.user_role_rep_customer_info?.[roleId];

	setRoles((prev: any) => ({ ...prev, [unitId]: tempRoles }));
};

export const adminRoles = [1, 2, 3];
export const repRoles = [7, 9];
