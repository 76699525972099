import { CostCompareReport } from '../interfaces/redux.interface';
export class CostCompareDepartmentModel implements CostCompareReport{
	public id: string;
    public name: string;
    public periods: Record<string, number>;
    public business_unit_name: string;

	constructor(props: any) {
		this.id = props.id;
		this.name = props.name;
		this.periods = props.periods;
		this.business_unit_name = props.business_unit_name
	}
}

export class CostCompareEmployeeModel implements CostCompareReport{
	public id: string;
    public name: string;
    public periods: Record<string, number>;

	constructor(props: any) {
		this.id = props.id;
		this.name = props.name + ` (${props.teudat_zeut})`;
		this.periods = props.periods;
	}
}