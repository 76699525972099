import { useNavigation } from '@react-navigation/native';
import { compact } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { View, TouchableHighlight, Platform, Linking } from 'react-native';
import { Divider, Menu } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import OutlineButtonContainer from '../../../components/shared/Buttons/OutlineButton.container';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../components/shared/Typography/Typography.component';
import colors from '../../../config/colors';
import { Privileges } from '../../../constants/roleTypes.constant';
import httpService from '../../../services/http.service';
import { i18nService } from '../../../services/i18n.service';
import { updateCustomerStatus } from './EditCustomer/EditCustomer.utils';
import { setRepEnterCustomerMode } from '../../../store/actions/repEnterMode.actions';
import { getUserData } from '../../../store/selectors/login.selectors';
import { RepCustomersContext } from './repCustomersContext';
import { getState } from '../../../store/store';

const RepCustomerItem = ({
	customerId,
	item,
	onRowClick,
	canEditCustomer,
}: any) => {
	const [customerData, setCustomerData] = useState<any>();
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const navigation = useNavigation();
	const { repCustomersData, updateRepCustomersData } =
		useContext(RepCustomersContext);
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);

	const canEnterCustomer = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ACCESS_CUSTOMER_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	const isRowSelected = useMemo(
		() => customerId === item.numericId,
		[customerId, item.numericId],
	);

	const detailsConfig = useMemo(
		() =>
			!customerData
				? []
				: [
						{ key: 'address', title: 'address' },
						{ key: 'phone', title: 'phone' },
						{ key: 'tikNikuim', title: 'tikNikuim' },
						{ key: 'licensedNumber', title: 'osekMurshe' },
				  ].filter((item: any) => !!customerData[item.key]),
		[customerData],
	);

	const contactDetailsConfig = useMemo(
		() =>
			!customerData
				? []
				: ['contactName', 'contactEmail', 'contactPhone'].filter(
						(item: any) => !!customerData[item],
				  ),
		[customerData],
	);

	const extraDetailsConfig = useMemo(
		() =>
			!customerData
				? []
				: [
						{
							fields: [
								'contactName',
								'contactEmail',
								'contactPhone',
							].filter((item: any) => !!customerData[item]),
							title: 'customerDetails.contactDetails',
						},
						{
							fields: [
								'operatorName',
								'operatorEmail',
								'operatorCell',
							].filter((item: any) => !!customerData[item]),
							title: 'customerDetails.operatorDetails',
						},
				  ],
		[customerData],
	);

	const buttons = useMemo(
		() =>
			compact([
				canEnterCustomer &&
					customerData?.businessUnits?.length > 0 && {
						name: 'enter',
						action: async () => {
							const data = { ...item, ...customerData };
							const accessData: any = await httpService.api({
								type: 'getRepEnterData',
								params: { customerId: item.numericId },
								customerHp: data.hp || data.id,
								customerId: item.numericId,
							});

							setOpenMenu(false);
							dispatch(
								setRepEnterCustomerMode({
									...data,
									customer_privilege: accessData,
									customerId: item.numericId,
								}),
							);
						},
					},
				canEditCustomer && {
					name: 'update',
					action: () => {
						setOpenMenu(false);
						navigation.navigate('editCustomer', {
							id: item.numericId,
						});
					},
				},

				canEditCustomer && {
					name: `set.status${item?.status ? 'False' : 'True'}`,
					action: () => {
						setOpenMenu(false);
						updateCustomerStatus(
							item.numericId,
							!item?.status,
							() =>
								updateRepCustomersData({ shouldRefresh: true }),
						);
					},
				},
				// { name: 'docs' },
			]),
		[canEditCustomer, canEnterCustomer, item, customerData],
	);

	const getCustomerData = async (id?: number) => {
		const res: any = await httpService.api({
			type: 'getRepCustomerDetails',
			params: { customerId: id || customerId },
			disableBI: true,
		});
		if (res) {
			setCustomerData({
				...res,
				contactName: `${res.contactFirstName || ''} ${
					res.contactLastName || ''
				}`,
				contactPhone: `${res.contactCellPrefix || ''}${
					res.contactCellPhone || ''
				}`,
				businessUnits: res?.businessUnits
					?.map((unit: any) => ({
						...unit,
						contactPhone: `${unit.contactCellPrefix || ''}${
							unit.contactCellPhone || ''
						}`,
					}))
					.sort((a: any, b: any) => a.name.localeCompare(b.name)),
			});
			return res;
		}
	};

	useEffect(() => {
		if (
			repCustomersData &&
			customerId === (repCustomersData as any)?.numericId
		) {
			setCustomerData({
				...customerData,
				...repCustomersData,
				contactName: `${repCustomersData.contactFirstName || ''} ${
					repCustomersData.contactLastName || ''
				}`,
				contactPhone: `${repCustomersData.contactCellPrefix || ''}${
					repCustomersData.contactCellPhone || ''
				}`,
			});
		}
	}, [repCustomersData]);

	useEffect(() => {
		if (item.numericId === customerId) {
			getCustomerData();
		}
	}, [customerId]);

	return (
		<>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => onRowClick(!isRowSelected ? item : undefined)}
			>
				<View
					style={{
						display: 'flex',
						height: !isRowSelected
							? 70
							: extraDetailsConfig[0]?.fields.length &&
							  extraDetailsConfig[1]?.fields.length
							? 550
							: 400,
						backgroundColor: 'white',
						marginTop: 10,
						marginBottom: 10,
						alignItems: 'center',
						paddingVertical: 15,
						paddingHorizontal: 20,
						width: '100%',
					}}
				>
					<View
						style={{
							flexDirection: 'row-reverse',
							width: '100%',
						}}
					>
						<View
							style={{
								flexDirection:
									item.name?.length >= 30
										? 'column'
										: 'row-reverse',
								alignItems:
									item.name?.length >= 30
										? 'flex-end'
										: 'center',
								flex: 1,
							}}
						>
							<Typography
								color='lightPrimary'
								weight='bold'
								size={16}
								numberOfLines={
									isRowSelected
										? 2
										: item.name?.length >= 30
										? 1
										: undefined
								}
							>
								{item.name}
							</Typography>
							{item.name?.length < 30 ? (
								<View
									style={{
										height: 21,
										width: 1,
										backgroundColor: colors.darkGrey,
										marginHorizontal: 11,
									}}
								/>
							) : null}
							<Typography color='darkGrey' weight='400' size={14}>
								{item.id}
							</Typography>
						</View>
						<Menu
							visible={openMenu}
							onDismiss={() => setOpenMenu(false)}
							anchor={
								<TouchableHighlight
									activeOpacity={1}
									underlayColor={colors.white}
									onPress={e => {
										e?.persist?.();
										getCustomerData(item.numericId);
										setOpenMenu(true);
									}}
									style={{ padding: 10 }}
								>
									<ImageIcon
										name='openMenu'
										height={22}
										width={4}
									/>
								</TouchableHighlight>
							}
						>
							{buttons.map((action: any, index) => (
								<>
									<Menu.Item
										key={action.name}
										onPress={action.action}
										title={i18nService.translate(
											`customerDetails.${action.name}`,
										)}
										style={{
											maxWidth: 170,
										}}
										contentStyle={{
											width: '100%',
											alignItems: 'flex-end',
											paddingHorizontal: 10,
										}}
									/>
									{index !== 3 ? <Divider /> : null}
								</>
							))}
						</Menu>
					</View>
					{isRowSelected ? (
						<>
							{detailsConfig.map((item: any) => (
								<View
									key={item.key}
									style={{
										flexDirection: 'row-reverse',
										marginTop: 14,
										width: '100%',
									}}
								>
									<I18n
										size={14}
										weight='normal'
										style={{ width: 93 }}
									>{`customerDetails.${item.title}`}</I18n>
									<Typography size={14} weight='400'>
										{customerData[item.key]}
									</Typography>
								</View>
							))}
							<View
								style={{
									width: '100%',
									flex: 1,
								}}
							>
								{extraDetailsConfig.map((extra: any) =>
									extra.fields.length ? (
										<View
											style={{ flex: 1 }}
											key={extra.title}
										>
											<I18n
												size={14}
												weight='bold'
												style={{
													marginTop: 24,
													width: '100%',
												}}
											>
												{extra.title}
											</I18n>
											{extra.fields.map((item: any) => (
												<View
													key={item}
													style={{
														flexDirection:
															'row-reverse',
														marginTop: 14,
														width: '100%',
													}}
												>
													<I18n
														size={14}
														weight='normal'
														style={{
															width: 80,
														}}
													>{`customerDetails.${item}`}</I18n>
													{[
														'contactEmail',
														'contactPhone',
														'operatorPhone',
														'operatorEmail',
													].includes(item) ? (
														Platform.OS ===
														'web' ? (
															<a
																href={`${
																	item.includes(
																		'Phone',
																	)
																		? 'tel'
																		: 'mailto'
																}:'${
																	customerData?.[
																		item
																	]
																}'`}
																style={{
																	color: colors.darkGrey,
																}}
															>
																<Typography
																	size={14}
																	weight='400'
																>
																	{
																		customerData?.[
																			item
																		]
																	}
																</Typography>
															</a>
														) : (
															<TouchableHighlight
																activeOpacity={
																	1
																}
																underlayColor={
																	colors.white
																}
																onPress={e => {
																	e?.persist?.();
																	Linking.openURL(
																		`${
																			item ===
																			'contactPhone'
																				? 'tel'
																				: 'mailto'
																		}:${
																			customerData?.[
																				item
																			]
																		}`,
																	);
																}}
															>
																<Typography
																	size={14}
																	weight='400'
																>
																	{
																		customerData?.[
																			item
																		]
																	}
																</Typography>
															</TouchableHighlight>
														)
													) : (
														<Typography
															size={14}
															weight='400'
														>
															{customerData[item]}
														</Typography>
													)}
												</View>
											))}
										</View>
									) : null,
								)}
							</View>
							<View
								style={{
									width: '100%',
									marginBottom: 15,
									flexDirection: 'row-reverse',
								}}
							>
								{customerData?.businessUnits?.length ? (
									<OutlineButtonContainer
										size='medium'
										onPress={() =>
											navigation.navigate(
												'businessUnits',
												{
													customerData,
												},
											)
										}
										overrideStyle={{
											paddingHorizontal: 9,
											paddingVertical: 6,
											borderColor: colors.lightPrimary,
											height: 34,
											width: 105,
										}}
									>
										<I18n
											size={14}
											weight='normal'
											color='lightPrimary'
										>
											customerDetails.businessUnits
										</I18n>
									</OutlineButtonContainer>
								) : null}
							</View>
						</>
					) : null}
				</View>
			</TouchableHighlight>
		</>
	);
};

export default RepCustomerItem;
