import React, { useState } from 'react';
import { useWindowDimensions, View } from 'react-native';
import FiltersContainer from '../../../../components/web/Filters/Filters.container';
import TableContainer from '../../../../components/web/ReactTable/Table.container';
import ModalContainer from '../../../../components/shared/Modal/Modal.container';
import { i18nService } from '../../../../services/i18n.service';
import Typography from '../../../../components/shared/Typography/Typography.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import { Button, makeStyles } from '@material-ui/core';
import EmployeesModal from '../../../../modals/web/EmployeesModal/EmployeesModal.component';
import CostCompareDashboardPage from './CostCompareDashboardPage.component';
import Checkbox from '../../../../components/web/Checkbox/Checkbox.component';
import I18n from '../../../../components/shared/i18n/I18n.component';

type CostCompareReportPageProps = {
	navigation: any;
	onFilter: (data: any) => void;
	onRowClick: (row: any) => void;
	filtersData: any;
	headers: any[];
	data: any[];
	onSort: (field: string, direction: string) => void;
	modalTitle: string;
	modalTableRows: any[];
	onEmployeesSort: (field: string, direction: string) => void;
	onModalBtnPressedHandler: () => void;
	modalOpened: boolean;
	defaultExpandedRows: any;
	getExcel: () => void;
	onLeftArrowClick: (row: any) => void;
	onTableButtonClick: Function;
	dashboardPageOpened: boolean;
	setCurrentTableState: any;
	periods: string[];
	onChangePercent: () => void;
	businessUnitsList?: any[];
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			padding: '0!important',
			minWidth: '20px',

			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		tableButton: {
			paddingLeft: '10px',
			position: 'relative',
			minWidth: '20px',
			marginLeft: '10px',

			'&:hover': {
				backgroundColor: 'transparent',
			},

			'&:before': {
				backgroundColor: '#979797',
				display: 'block',
				content: '""',
				left: '0',
				position: 'absolute',
				opacity: '0.7',
				height: '15px',
				width: '2px',
			},
		},
	};
});

const CostCompareReportPage = ({
	navigation,
	data,
	headers,
	filtersData,
	onSort,
	onFilter,
	onRowClick,
	modalTitle,
	modalTableRows,
	onEmployeesSort,
	onModalBtnPressedHandler,
	modalOpened,
	defaultExpandedRows,
	getExcel,
	onLeftArrowClick,
	onTableButtonClick,
	dashboardPageOpened,
	setCurrentTableState,
	periods,
	businessUnitsList,
	onChangePercent,
}: CostCompareReportPageProps) => {
	const { height } = useWindowDimensions();
	const classes = useStyles();

	return (
		<>
			<View
				style={{
					maxHeight: height - 285,
					minHeight: height - 285,
				}}
			>
				<View
					style={{
						marginBottom: 31,
						zIndex: 3,
						flexDirection: 'row-reverse',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<View
						style={{
							flexDirection: 'row-reverse',
							alignItems: 'center',
							flex: 1,
							flexWrap: 'wrap',
						}}
					>
						<FiltersContainer
							config={filtersData}
							onChange={onFilter}
							customStyles={{
								container: {
									flexDirection: 'row-reverse',
									marginLeft: -8,
								},
								filterContainer: {
									marginRight: 0,
									marginLeft: 15,
									zIndex: 3,
									flexDirection: 'row-reverse',
									alignItems: 'center',
								},
								label: {
									marginLeft: 10,
								},
							}}
						/>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								marginRight: -5,
								marginTop: 5,
								zIndex: -1,
							}}
						>
							<I18n>
								costingReportPage.compare.filter.showPrecentage
							</I18n>
							<Checkbox onClick={onChangePercent} />
						</View>
					</View>
					<div>
						<Button
							classes={{
								root: classes.root,
							}}
							onClick={getExcel}
						>
							<ImageIcon name='excel' />
						</Button>
						{!dashboardPageOpened && (
							<Button
								classes={{
									root: classes.tableButton,
								}}
								onClick={e =>
									onTableButtonClick(dashboardPageOpened)
								}
							>
								<ImageIcon
									name='chart'
									style={{
										width: '23px',
									}}
								/>
							</Button>
						)}
						{dashboardPageOpened && (
							<Button
								classes={{
									root: classes.tableButton,
								}}
								onClick={e =>
									onTableButtonClick(dashboardPageOpened)
								}
							>
								<ImageIcon
									name='table'
									style={{
										width: '24px',
									}}
								/>
							</Button>
						)}
					</div>
				</View>
				{Boolean(data.length) && !dashboardPageOpened && (
					<TableContainer
						onRowClick={onRowClick}
						headers={headers}
						data={data}
						count={40}
						keyName={''}
						onSort={onSort}
						getNextData={() => {}}
						hasFooter={true}
						defaultExpandedRows={defaultExpandedRows}
						setCurrentTableState={setCurrentTableState}
						onLeftArrowClick={onLeftArrowClick}
						height='auto'
					/>
				)}
				{Boolean(data.length) && dashboardPageOpened && (
					<CostCompareDashboardPage
						data={data}
						periods={periods}
						businessUnitsList={businessUnitsList}
					/>
				)}
				{Boolean(!data.length) && (
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<I18n weight='bold' size={24}>
							attendanceReportPage.noReports
						</I18n>
					</View>
				)}
			</View>
			<ModalContainer
				visible={modalOpened}
				component={EmployeesModal}
				headers={headers}
				tableRows={modalTableRows}
				modalTitle={modalTitle}
				onSort={onEmployeesSort}
				onNext={() => onModalBtnPressedHandler()}
			/>
		</>
	);
};

export default CostCompareReportPage;
