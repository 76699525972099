import fontkit from '@pdf-lib/fontkit';
import _ from 'lodash';
import {
	PDFDocument,
	PDFTextField,
	PDFCheckBox,
	PDFRadioGroup,
	colorToComponents,
	rgb,
} from 'pdf-lib';
import httpService from '../../../services/http.service';
import { i18nService } from '../../../services/i18n.service';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
	set101Files,
} from '../../../store/actions/config.actions';
import { dispatch, getState } from '../../../store/store';
import { downloadFile, getFiles } from './utils';
import { dataToServer } from '../WorkerCardEditor/workerCardEditor.utils';

const unformattedDates = [
	'taxCreditReason.taxCreditAddressFromDate',
	'taxCreditReason.immigrantFromDate',
	'taxCreditReason.noIncomeUntilDate',
	'taxCreditReason.serviceStart',
	'taxCreditReason.serviceEnd',
	'submitDate',
];

const fieldsToHide = [
	'taxYear',
	'employer.name',
	'employer.address',
	'employer.phone',
	'employer.deductionFileId',
	'employer.comment',
	'signature',
	'submitDate',
];

const markerColor = colorToComponents(rgb(235 / 255, 64 / 255, 52 / 255));

export const fillPdf = async ({
	formId,
	data = undefined,
	isEmployee = true,
	showHighligh,
	originalPdf,
}: any) => {
	try {
		dispatch(incrementBusyIndicator());

		const builderData: any =
			data ||
			(await httpService.api({
				type: isEmployee
					? 'getWorkerCardData'
					: 'getWorkerCardDataManager',
				params: { formId },
			})) ||
			{};
		let filesData = getState().config.files101;
		if (!filesData?.formPdfBytes || !filesData?.fontBytes) {
			const templaeData: {
				pdf: string;
				font: string;
			} = await httpService.api({ type: 'getTemplateFiles' });
			filesData = await getFiles(templaeData);
			dispatch(set101Files(filesData));
		}
		const { formPdfBytes, fontBytes } = filesData;
		// Load a PDF with form fields
		const pdfDoc = await PDFDocument.load(formPdfBytes);

		// Register the `fontkit` instance
		pdfDoc.registerFontkit(fontkit);

		// Embed our custom font in the document
		const customFont = await pdfDoc.embedFont(fontBytes);

		// Get the form containing all the fields
		const form = pdfDoc.getForm();
		const fields = form.getFields();
		fields.forEach(field => {
			const type = field.constructor.name;
			const name = field.getName();
			const isMarker = name.includes('marker');
			builderData['employee']['kibbutzMemberAndRevenuesTransferred'] =
				builderData['employee']['kibbutzMember'] &&
				builderData['employee']['revenuesTransferredToKibbutz'];
			builderData['employee']['kibbutzMemberAndRevenuesNotTransferred'] =
				builderData['employee']['kibbutzMember'] &&
				!builderData['employee']['revenuesTransferredToKibbutz'];
			//for the previous form support
			builderData['employee']['kibbutzMemberAndRevenuesTransferred'] =
				builderData['employee']['kibbutzMember'] &&
				builderData['employee']['revenuesTransferredToKibbutz'];
			builderData['employee']['kibbutzMemberAndRevenuesNotTransferred'] =
				builderData['employee']['kibbutzMember'] &&
				!builderData['employee']['revenuesTransferredToKibbutz'];
			const nameString =
				type === 'PDFCheckBox' || name.includes('-')
					? name.split('-')[0].replace('-marker', '')
					: name === 'employee.phonePrefix'
					? 'employee.phone'
					: name;
			const data = _.get(builderData, nameString);
			const originalData = originalPdf
				? _.get(originalPdf, nameString)
				: null;
			name === 'spouse.passport' &&
				console.log('spouse.passport', {
					nameString,
					data,
					builderData,
				});
			if (originalPdf && showHighligh && !fieldsToHide.includes(name)) {
				const [trmpwidget, ...widgets] = field.acroField.getWidgets();
				let widget = trmpwidget;
				if (widgets.length) {
					widget = widgets[0];
				}

				const appear = widget.getOrCreateAppearanceCharacteristics();

				const compareData =
					!Array.isArray(data) &&
					!Array.isArray(originalData) &&
					data !== originalData;
				const compareArray =
					(Array.isArray(data) || Array.isArray(originalData)) &&
					data?.join() !== originalData?.join();
				const isBoolean =
					typeof originalData === 'boolean' ||
					typeof data === 'boolean';

				if (isBoolean && originalData !== data) {
					if (
						isMarker &&
						data != undefined &&
						((!!originalData && name.includes(`false`)) ||
							(name.includes(`true`) && !!data))
					) {
						appear?.setBorderColor(markerColor);
						const borderStyle = widget.getOrCreateBorderStyle();
						borderStyle.setWidth(1.5);
					}
				} else if (compareData) {
					if (
						isMarker &&
						name.includes(`-${originalData}-`) &&
						!name.includes('maritalStatus')
					) {
						appear?.setBorderColor(markerColor);
						const borderStyle = widget.getOrCreateBorderStyle();
						borderStyle.setWidth(1.5);
					} else if (
						name === 'employee.healthOrgName' &&
						data &&
						data !== originalData
					) {
						appear?.setBorderColor(markerColor);
						//borderStyle.setWidth(1.5)
					} else {
						// if (name === 'currentEmployerIncome.incomeType') {
						// }
						if (
							type !== 'PDFRadioGroup' &&
							type !== 'PDFCheckBox' &&
							(!name.includes('maritalStatus') ||
								name.includes(data))

							// originalData
						) {
							appear?.setBorderColor(markerColor);
							const borderStyle = widget.getOrCreateBorderStyle();
							borderStyle.setWidth(1.5);
						}
					}
				} else if (compareArray && isMarker) {
					data?.forEach(fi => {
						if (
							name.includes(fi) &&
							!originalData?.includes(fi) &&
							!!originalData
						) {
							appear?.setBorderColor(markerColor);
							const borderStyle = widget.getOrCreateBorderStyle();
							borderStyle.setWidth(1.5);
						}
					});
				}
			}

			if ((!data && data !== false) || isMarker) {
				return;
			}

			if (field instanceof PDFCheckBox) {
				const value = name.split('-')[1];
				if (Array.isArray(data)) {
					data.includes(value) && field.check();
				} else {
					(value === 'length' ? value : `${data}` === value) &&
						field.check();
				}
			} else if (field instanceof PDFRadioGroup) {
				if (
					name == 'employee.kibbutzMember' &&
					data == true &&
					!field.getOptions().includes('true')
				) {
					return;
				} else {
					field.select(`${data || 'false'}`);
				}
			} else if (field instanceof PDFTextField) {			
				if (name === 'employee.phone') {
					field.setText(data.substring(data.length - 7));
				} else if (name === 'employee.phonePrefix') {
					const prefixDigitis =
						data.substring(0, data.length - 7) || '000';
					field.setText(
						prefixDigitis.length === 1
							? `00${prefixDigitis}`
							: prefixDigitis.length === 2
							? `0${prefixDigitis}`
							: prefixDigitis,
					);
				} else if (name === 'employer.deductionFileId') {
					field.setText(data.slice(1));
				} else if (name.includes('Date') && data) {
					field.setText(
						unformattedDates.includes(name)
							? data
							: data.split('/').join(''),
					);
				} else if (name.includes('children_')) {
					const number = name.split('_')[1];
					field.setText(`${parseInt(number) + 1}`);
				} else if (
					name.endsWith('healthOrgName') ||
					name.endsWith('].incomeType')
				) {
					field.setText(
						i18nService.translate(`general.${data.toLowerCase()}`),
					);
				} else if (
					typeof data === 'string' &&
					(name.includes('employer.address') || name.includes('employer.name'))
				) {
					//fix for strings with hebrew+english+numbers cominations
					let value = data;
					value = handleHebrewAndEnglishLetters(value.trim());

					field.setText(
						`${
							typeof value === 'string'
								? value.trim()
								: value || ''
						}`,
					);
				} else {
					let value = data;
					field.setText(
						`${
							typeof value === 'string'
								? value.trim()
								: value || ''
						}`,
					);
				}
			}
		});
		if (
			builderData.signature &&
			builderData.signature !== 'data:,' &&
			builderData.signature !== ''
		) {
			const pdfLibSigImg = await pdfDoc.embedPng(builderData.signature);
			const jpgDims = pdfLibSigImg.scale(0.25);
			const page = pdfDoc.getPage(1);
			page.drawImage(pdfLibSigImg, {
				x: 30,
				y: page.getHeight() - 690,
				width: 100,
				height: 50,
			});
		}
		form.updateFieldAppearances(customFont);
		form.flatten();
		const file = await pdfDoc.saveAsBase64({ dataUri: true });
		const fileName = `tofes101_${builderData.taxYear}_${
			builderData.employee?.idNumber || builderData.employee?.passport
		}`;
		return { file, fileName };
	} catch (e) {
		console.error(e);
	} finally {
		dispatch(decrementBusyIndicator());
	}
};

export const createAndDownload101 = async (
	formId: number,
	isEmployee: boolean,
) => {
	const data: any = await fillPdf({ formId, isEmployee });
	if (data?.file) {
		downloadFile(data?.file, data?.fileName);
	}
};

function handleHebrewAndEnglishLetters(value: string) {
	const hebrewLetters = new RegExp(/[\u0590-\u05FF]/g);
	const englishLetters = new RegExp(/([a-zA-Z])/g);
	const numbers = new RegExp(/([0-9])/g);
	const specialChar = new RegExp(/([$&+,:;=?@#|'<>.^*()%!-])/g);

	if (
		(hebrewLetters.test(value) && englishLetters.test(value)) ||
		((hebrewLetters.test(value) || englishLetters.test(value)) &&
			numbers.test(value))
	) {
		let arr = value.split(' ');
		const firstWord = arr[0];
		const lastWord = arr[arr.length - 1];

		arr = arr.map(word => {
			//word needs to be reversed in the following cases:
			//english word if it's- 2nd+ word, but not when its the last word and the first word is also in english
			//hebrew word if it's- *last word
			//                     *2nd+ word in a sentence that start and ends with english words
			if (englishLetters.test(word) && arr.indexOf(word) > 0) {
				if (
					arr.indexOf(word) == arr.length - 1 &&
					englishLetters.test(firstWord)
				) {
					return word;
				}
				word = word.split('').reverse().join('');
			} else if (
				(hebrewLetters.test(word) &&
					arr.indexOf(word) == arr.length - 1 &&
					englishLetters.test(firstWord)) ||
				(arr.indexOf(word) > 0 &&
					englishLetters.test(firstWord) &&
					englishLetters.test(lastWord))
			) {
				word = word.split('').reverse().join('');
			} else if (numbers.test(word)) {
				//when last character of a number is a special character and we reverse the number, the special char becoms the first char.
				//so we need to put it back at the end
				const splittedWord = word.split('');
				const lastChar = splittedWord[splittedWord.length - 1];
				const isLastCharSpecial = specialChar.test(lastChar);
				if (isLastCharSpecial) {
					word =
						word
							.slice(0, word.length - 1)
							.split('')
							.reverse()
							.join('') + lastChar;
				} else if (!specialChar.test(word)) {
					word = word.split('').reverse().join('');
				}
			}
			return word;
		});
		value = arr.join(' ');
	}
	return value;
}
