import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Dimensions } from 'react-native';
import WebCheckBox from 'expo-checkbox';
import { Checkbox, makeStyles } from '@material-ui/core';
import I18n from '../../../../components/shared/i18n/I18n.component';
import SearchInputContainer from '../../../../components/shared/SearchInput/SearchInput.container';
import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';
import httpService from '../../../../services/http.service';
import { TreeComponent } from '../TreeComponent/TreeComponent';
import AsyncMultiSelectContainer from '../../../../components/shared/AsyncMultiSelect/AsyncMultiSelect.container';

const windowHeight = Dimensions.get('window').height;

const useStyles = makeStyles(() => ({
	checkbox: {
		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
		},
	},
	checked: {},
}));

const selectStyle = {
	control: {
		maxWidth: '100%',
		width: '100%',
		marginTop: 3,
		borderColor: '#fff',
		height: 40,
	},
	menu: {
		boxShadow: 'none',
		border: `solid 1px #e7e7e8`,
		height: windowHeight - 520,
	},
	menuList: {
		height: windowHeight - 528,
		minHeight: windowHeight - 528,
	},
	placeholder: {
		paddingRight: 20,
	},
	newInput: {
		paddingRight: 20,
	},
	indicatorsContainer: { display: 'none' },
	indicatorSeparator: { display: 'none' },
	clearIndicator: { display: 'none' },
};

export const EmployeeSelector = ({
	locations,
	userIds,
	locationIds,
	setLocationIds,
	setUserIds,
	asyncSelected,
	setAsyncSelected,
	getEmployeesFilter,
	unitId,
}: {
	userIds: number[];
	locationIds: string[];
	locations: any[];
	setLocationIds: any;
	setUserIds: any;
	getEmployeesFilter: Function;
	unitId: number;
}) => {
	const [locationsList, setLocationsList] = useState([]);

	useEffect(() => {
		const tempLoc = locations.map(loc => ({
			...loc,
			isChecked: !!locationIds.includes(loc.id),
		}));
		setLocationsList(tempLoc);
	}, [locations]);

	const updateUsers = (users: any) => {
		const newUsers = users?.value?.map((user: any) => user.id);
		setUserIds(newUsers);
		setAsyncSelected(
			users.value.map(i => ({ value: i.id, label: i.name })),
		);
	};

	return (
		<View style={styles.dataContainer}>
			<CheckListSelector
				unitId={unitId}
				data={locationsList}
				isLocation
				searchLabel={'filter.multiselect.searchLocation'}
				setUserData={setLocationIds}
			/>

			<View style={{ width: '57%' }}>
				<AsyncMultiSelectContainer
					key={`${unitId}`}
					config={{
						name: 'employees',
						loadOptions: getEmployeesFilter,
						placeholder: 'employees',
					}}
					styles={selectStyle}
					onChange={updateUsers}
					menuIsOpen
					fontSize={16}
				/>
			</View>
		</View>
	);
};

export const ProjectSelector = ({
	projects,
	locations,
	locationIds,
	setProjectLocationIds,
	setProjectIds,
	projectIds,
	unitId,
}: {
	locationIds: string[];
	projectIds: string[];
	projects: any[];
	locations: any[];
	setProjectLocationIds: any;
	setProjectIds: any;
	unitId: number;
}) => {
	const [projectsList, setProjectsList] = useState([]);
	const [locationsList, setLocationsList] = useState([]);

	useEffect(() => {
		const tempPro = projects.map(pro => ({
			...pro,
			isChecked: !!locationIds.includes(pro.id),
		}));
		setProjectsList(tempPro);

		const tempLoc = locations.map(loc => ({
			...loc,
			isChecked: !!locationIds.includes(loc.id),
		}));
		setLocationsList(tempLoc);
	}, [locations]);

	return (
		<View style={styles.dataContainer}>
			<CheckListSelector
				data={locationsList}
				unitId={unitId}
				setUserData={setProjectLocationIds}
				isLocation
				searchLabel={'filter.multiselect.searchLocation'}
			/>

			<CheckListSelector
				data={projectsList}
				setUserData={setProjectIds}
				searchLabel={'filter.multiselect.searchProject'}
			/>
		</View>
	);
};

export const searchLocation = async (search: string, unitId: number) => {
	try {
		const res = await httpService.api<any>({
			type: 'getLocationFilter',
			query: {
				pageName: 'locationSettings',
				searchText: search,
				unitIds: unitId,
			},
		});

		return res;
	} catch (e) {}
};

const CheckListSelector = ({
	data,
	searchLabel,
	isLocation,
	setUserData,
	unitId,
}: {
	data: any[];
	searchLabel: string;
	isLocation?: boolean;
	setUserData(data: any): void;
	unitId?: number;
}) => {
	const [dataList, setDataList] = useState(data);
	const [isAllProjectSelected, setIsAllProjectSelected] = useState(false);

	useEffect(() => {
		setDataList(data);
	}, [data]);

	useEffect(() => {
		setIsAllProjectSelected(false);
	}, [unitId]);

	const onSearch = async val => {
		try {
			if (isLocation) {
				const searchData = await searchLocation(val.value, unitId);
				setDataList(() => searchData);
			} else {
				const newSearch = data.filter(pro =>
					pro.name.includes(val.value),
				);
				setDataList(newSearch);
			}
		} catch (e) {}
	};

	const onAllSelect = val => {
		setIsAllProjectSelected(val);
		const temp = dataList.map(v => ({ ...v, isChecked: val }));
		setDataList(temp);
		setUserData(val ? dataList.map(v => v.id) : []);
	};

	const onSelectProject = (item, index, val) => {
		const temp = [...dataList];
		temp[index] = { ...item, isChecked: val };
		setDataList(temp);
		setUserData(temp.filter(us => !!us.isChecked).map(us => us.id));
		if (!val && isAllProjectSelected) {
			setIsAllProjectSelected(false);
		}
	};
	const classes = useStyles();
	return (
		<View style={{ width: '45%' }}>
			<SearchInputContainer
				placeholder={i18nService.translate(searchLabel)}
				config={{ name: 'search', type: 'searchInput' }}
				onChange={onSearch}
				styleConf={{ container: { marginBottom: 5 } }}
				hideIcon
			/>
			<FlatList
				data={dataList}
				ListHeaderComponent={() => (
					<View style={styles.allRow}>
						<Checkbox
							size='small'
							classes={{
								root: classes.checkbox,
								checked: classes.checked,
							}}
							color='default'
							checked={isAllProjectSelected}
							onChange={event =>
								onAllSelect(event.target.checked)
							}
						/>
						<I18n
							size={16}
							style={{ zIndex: 'unset' }}
							color='darkGrey'
						>
							general.all
						</I18n>
					</View>
				)}
				renderItem={({ item, index }) => (
					<View
						key={item.id}
						style={[styles.dataRowHeader, { marginRight: 5 }]}
					>
						<Checkbox
							size='small'
							classes={{
								root: classes.checkbox,
								checked: classes.checked,
							}}
							color='default'
							checked={item?.isChecked}
							onChange={event =>
								onSelectProject(
									item,
									index,
									event.target.checked,
								)
							}
						/>
						<I18n
							size={16}
							style={{ zIndex: 'unset' }}
							color='darkGrey'
						>
							{item.name}
						</I18n>
					</View>
				)}
				style={{
					overflow: 'hidden',
					borderWidth: 1,
					height: windowHeight - 540,
					borderColor: '#e7e7e8',
					marginLeft: 10,
				}}
				contentContainerStyle={{ paddingBottom: 20 }}
				keyExtractor={item => `${item.id}`}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	dataContainer: {
		zIndex: 'unset',
		width: '100%',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
	},
	allRow: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		marginHorizontal: 5,
	},
	checkBox: {
		width: 15,
		height: 15,
		borderRadius: 4,
		margin: 9,
	},
	dataRowHeader: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
	},
});
