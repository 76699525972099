import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Dimensions } from 'react-native';
import Carousel from './Carousel.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';

const windowWidth = Dimensions.get('window').width;

interface CarouselContainerProps {
	itemWidth?: number;
	itemMargin?: number;
	containerMaxWidth?: number;
	maxPages?: number;
	setResetCaruselFunc: any;
	children: any[];
	notDisplayHelpers?: boolean;
	setGoToPosition?: any;
	shouldDisplayArrows?: boolean;
}

const CarouselContainer = ({
	itemWidth = 86,
	itemMargin = 10.5,
	setResetCaruselFunc,
	children,
	containerMaxWidth,
	maxPages,
	notDisplayHelpers,
	setGoToPosition,
	shouldDisplayArrows,
}: CarouselContainerProps) => {
	const carouselRef = useRef<any>();
	const [interval, setInterval] = useState(0);
	const [intervals, setIntervals] = useState(2);
	const isMobileWeb = useDevicePlatform() === DeviceType.MOBILE_WEB;

	const marginRight = useMemo(
		() => (isMobileWeb ? -(windowWidth + 280) : 0),
		[isMobileWeb],
	);

	const goToPosition = (index: number) => {
		if (index - 1 >= 0) {
			const offset = (index - 1) * (itemWidth + itemMargin * 2);
			carouselRef.current.scrollTo({
				y: 0,
				animated: true,
				x: offset,
			});
		}
	};

	useEffect(() => {
		setResetCaruselFunc(() => () => {
			const offset = getOffsetByInterval(0);
			carouselRef.current.scrollTo({
				y: 0,
				animated: true,
				x: offset,
			});
			setInterval(0);
		});
		setGoToPosition && setGoToPosition(() => goToPosition);
	}, []);

	// Calculate max number of items in a carousel page
	const itemsPerInterval = useMemo(() => {
		return Math.max(
			Math.floor(
				(containerMaxWidth || windowWidth) /
					(itemWidth + itemMargin * 2),
			) - 1,
			0,
		);
	}, [itemWidth, itemMargin, containerMaxWidth]);

	useEffect(() => {
		setIntervals(Math.ceil(children.length / itemsPerInterval));
	}, [children.length, itemsPerInterval]);

	const onChangePage = useCallback(
		(direction: 'next' | 'prev') => {
			const newInterval =
				direction === 'next'
					? Math.min(interval + 1, intervals)
					: Math.max(interval - 1, 0);

			// Prevent overscroll
			if (newInterval <= intervals - 1) {
				setInterval(newInterval);
				const offset = getOffsetByInterval(newInterval);
				carouselRef.current.scrollTo({
					y: 0,
					animated: true,
					x: offset,
				});
			}
		},
		[interval, intervals, itemsPerInterval, setInterval],
	);

	const getOffsetByInterval = (selectedInterval: number) => {
		return (
			selectedInterval * itemsPerInterval * (itemWidth + itemMargin * 2)
		);
	};

	return (
		<Carousel
			carouselRef={carouselRef}
			onChangePage={onChangePage}
			intervals={intervals}
			currentInterval={interval}
			itemMargin={itemMargin}
			itemWidth={itemWidth}
			containerMaxWidth={containerMaxWidth}
			marginRight={marginRight}
			notDisplayHelpers={notDisplayHelpers}
			shouldDisplayArrows={shouldDisplayArrows}
			// Show error if window is smaller than container max width + some padding for arrows
			isInMobileView={useDevicePlatform() !== DeviceType.WEB}
		>
			{maxPages
				? children.slice(0, itemsPerInterval * maxPages - 1)
				: children}
		</Carousel>
	);
};

export default CarouselContainer;
