import React, { useEffect } from 'react';
import { TouchableHighlight, View, StyleSheet, Dimensions } from 'react-native';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import I18n from '../../i18n/I18n.component';
import ImageIcon from '../../Icon/ImageIcon.component';

const ButtonsGroupe = ({
	value,
	onChange,
	onBlur = () => {},
	options,
	isDisabled,
	isMulti,
	smallBorderRadius,
	error,
	enableUnselect,
	selectedItems,
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;

	const onChangeMulty = (val: any) => {
		const tempVal = value ? [...value] : [];
		const valIndex = val && tempVal.findIndex((v: any) => v === val);

		if (valIndex > -1) {
			tempVal.splice(valIndex, 1);
			onChange(tempVal);
		} else {
			tempVal.push(val);
			onChange(tempVal);
		}
	};

	const handleOnChange = (val: any) => {
		if (enableUnselect && val === value) {
			onChange(null);
		} else {
			onChange(val);
		}
	};

	useEffect(() => {
		(value || value === false) && onBlur();
	}, [value]);

	return (
		<View
			style={{
				flexDirection: 'row-reverse',
				alignItems: isMobile ? 'center' : 'flex-start',
				flexWrap: 'wrap',
				// width: isMobile ? '100%' : 'unset',
				justifyContent: isMobile ? 'center' : 'flex-start',
				marginBottom: -20,
				width: '100%',
			}}
		>
			{options.map((opt: { label: string; value: any }) => (
				<TouchableHighlight
					key={opt.value}
					underlayColor='none'
					style={{
						marginLeft:
							smallBorderRadius &&
							smallBorderRadius(isMobile) &&
							isMobile
								? 0
								: isMobile
								? 18
								: 10,
						width:
							smallBorderRadius && smallBorderRadius(isMobile)
								? '100%'
								: undefined,

						opacity: isDisabled ? 0.7 : 1,
					}}
					accessibilityRole='button'
					onPress={() => {
						(isMulti ? onChangeMulty : handleOnChange)(opt.value);
					}}
					disabled={isDisabled}
				>
					<View
						style={StyleSheet.flatten([
							styles.buttonStyle,
							smallBorderRadius &&
								smallBorderRadius(isMobile) && {
									borderRadius: 11,
									width: isMobile ? '100%' : 'unset',
									minHeight: isMobile ? 63 : 40,
								},
							error && {
								borderColor: colors.red,
								borderWidth: 1,
							},
							(isMulti
								? value?.includes(opt.value)
								: opt.value === value) && styles.buttonSelected,
							!!selectedItems && { flexDirection: 'row-reverse' },
						])}
					>
						<I18n
							weight='normal'
							size={isMobile ? 16 : 14}
							style={{ textAlign: 'right' }}
							{...{
								color: (
									isMulti
										? value?.includes(opt.value)
										: opt.value === value
								)
									? 'white'
									: error
									? 'red'
									: 'darkGrey',
							}}
						>
							{opt.label}
						</I18n>
						{!!selectedItems && opt?.showCounter && (
							<View
								style={{
									flexDirection: 'row-reverse',
									marginRight: 5,
									marginLeft: 5,
									alignItems: 'center',
								}}
							>
								<I18n
									weight='normal'
									size={isMobile ? 15 : 13}
									style={{ textAlign: 'right' }}
									{...{
										color: (
											isMulti
												? value?.includes(opt.value)
												: opt.value === value
										)
											? 'white'
											: error
											? 'red'
											: 'darkGrey',
									}}
								>{`(${selectedItems})`}</I18n>
								<ImageIcon
									name='edtitWhite'
									height={14}
									width={14}
									style={{ marginRight: 5, marginLeft: 5 }}
								/>
							</View>
						)}
					</View>
				</TouchableHighlight>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	centeredText: {
		textAlign: 'center',
	},
	buttonStyle: {
		backgroundColor: colors.white,
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		minHeight: 40,
		minWidth: 70,
		maxWidth: Dimensions.get('screen')?.width - 40,
		justifyContent: 'space-evenly',
		alignItems: 'center',
		textAlign: 'center',
		paddingHorizontal: 12,
		paddingVertical: 11,
		borderRadius: 45,
		marginBottom: 20,
	},
	containerStyle: {
		width: 150,
	},
});
export default ButtonsGroupe;
