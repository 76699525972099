import React, { useMemo } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import ImageIcon from '../../Icon/ImageIcon.component';
import I18n from '../../i18n/I18n.component';
import Typography from '../../Typography/Typography.component';
import { convertFromUtc } from '../Clock.utils';
import colors from '../../../../config/colors';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../store/selectors/login.selectors';
interface Record {
	workLogId: number;
	projectId: any;
	projectName: any;
	eventId: any;
	eventName: any;
	enterTime: any;
	exitTime: any;
	total: number;
}
interface Props {
	isToday: boolean;
	record: Record;
	onDelete?: Function;
	onEdit?: Function;
}
const convertMinutesToHours = (value: any, format?: string) => {
	if (value) {
		if (format === 'HH:mm') {
			const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
			const minutes =
				value % 60 !== 0
					? value % 60 < 10
						? `0${value % 60}`
						: `${value % 60}`
					: '00';
			return `${hours}:${minutes}`;
		}
		return `${(value / 60).toFixed(2)}`;
	}
	return '';
};
const WorkLog = ({ record, onEdit, onDelete, isToday }: Props) => {
	const userData = useSelector(getUserData);
	const canEdit = useMemo(
		() =>
			userData?.employeeRolePrivileges?.includes('ROLE_LOG_WORK_WRITE') &&
			userData.customer_setting.allowUpdateReport,
		[userData],
	);
	return (
		<View key={record.workLogId}>
			<View key={record.workLogId} style={styles.report}>
				<View style={styles.reportTopSection}>
					{canEdit && (
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: 80,
								padding: 10,
								alignItems: 'center',
							}}
						>
							<TouchableOpacity
								onPress={() => onDelete?.(record.workLogId)}
							>
								<ImageIcon name='delete' />
							</TouchableOpacity>
							<TouchableOpacity
								onPress={() =>
									onEdit?.(record.workLogId, record)
								}
							>
								<ImageIcon name='edit' />
							</TouchableOpacity>
						</View>
					)}
					<Typography
						numberOfLines={1}
						size={16}
						weight='normal'
						color={record.eventName ? 'warning' : 'primary'}
						style={{
							alignItems: 'center',
							alignSelf: canEdit ? 'center' : 'flex-end',
							...(!canEdit && { flex: 1, margin: 10 }),
						}}
					>
						{record.eventName
							? record.eventName
							: record.projectName
							? record.projectName
							: null}
					</Typography>
				</View>

				<View style={styles.reportBottomSection}>
					<View
						style={{
							flex: 1,
							flexDirection: 'row-reverse',
							alignItems: 'center',
						}}
					>
						<I18n
							weight='normal'
							style={{
								marginHorizontal: 5,
							}}
							size={14}
						>
							general.enterence
						</I18n>
						<I18n size={14} weight='bold'>
							{record.enterTime
								? convertFromUtc(record.enterTime, true)
								: '00:00'}
						</I18n>
					</View>
					<View
						style={{
							flex: 1,
							flexDirection: 'row-reverse',
							alignItems: 'center',
						}}
					>
						<I18n
							weight='normal'
							style={{
								marginHorizontal: 5,
							}}
							size={14}
						>
							general.exit
						</I18n>
						<I18n
							weight={record.exitTime ? 'bold' : 'normal'}
							size={14}
							color={
								!isToday && !record.exitTime
									? 'red'
									: 'darkGrey'
							}
						>
							{record.exitTime
								? convertFromUtc(record.exitTime, true)
								: '00:00'}
						</I18n>
					</View>
					<View
						style={{
							flex: 1,
							flexDirection: 'row-reverse',
							alignItems: 'center',
						}}
					>
						<I18n
							size={14}
							weight='normal'
							style={{
								marginHorizontal: 5,
							}}
							color='darkGrey'
						>
							reportCard.totalHours
						</I18n>
						<I18n
							size={14}
							color='darkGrey'
							weight={record.total ? 'bold' : 'normal'}
						>
							{record.total
								? convertMinutesToHours(record.total, 'HH:mm')
								: '00:00'}
						</I18n>
					</View>
				</View>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	buttonContainer: {
		height: 38,
		width: 140,
		backgroundColor: colors.warning,
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: { width: 197, alignItems: 'center', justifyContent: 'center' },
	report101Container: {
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
		marginBottom: 30,
	},
	header: {
		backgroundColor: colors.white,
		width: '100%',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 10,
		paddingHorizontal: 25,
	},

	report: {
		marginVertical: 10,
		backgroundColor: colors.white,
		height: 88,
		paddingHorizontal: 15,
		paddingVertical: 5,
		width: '100%',
	},
	reportTopSection: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	reportBottomSection: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	verticalRecordLine: {
		opacity: 0.2,
		height: 30,
		width: 0,
		borderLeftColor: '#525962',
		borderLeftWidth: 2,
		marginHorizontal: 10,
	},
});
export default WorkLog;
