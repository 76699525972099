import * as clockReportActions from '../constants/clockReportActions';
import { WorkLogsState } from '../../interfaces/redux.interface';
import httpService from '../../services/http.service';
import moment from 'moment';

const setDate = (payload: WorkLogsState) => {
	return {
		type: clockReportActions.SET_DATE,
		payload,
	};
};

export const setTodayWorkLogsAction = (payload: WorkLogsState) => {
	return {
		type: clockReportActions.SET_TODAY_WORKLOGS,
		payload,
	};
};

export const toggleReFetch = () => {
	return {
		type: clockReportActions.TOGGLE_REFETCH_FLAG,
	};
};

const setOpenDateModal = (payload: WorkLogsState) => {
	return {
		type: clockReportActions.SET_OPEN_CHOSEN_DATE_MODAL,
		payload,
	};
};

export const setChosenDate = (date: string) => dispatch =>
	dispatch(setDate({ chosenDate: date }));

export const setTodayWorklogs = () => async dispatch => {
	try {
		const res = await httpService.api<any>({
			type: 'getWorkLogListForClock',
			params: {
				date: moment().local().format('YYYY-MM-DD'),
			},
		});
		if (res) {
			await dispatch(setTodayWorkLogsAction({ ...res }));
		}
	} catch (err) {
		console.log(err);
	}
};

export const setOpenChosenDateModal = (isOpen: boolean) => dispatch =>
	dispatch(setOpenDateModal({ openChosenDateModal: isOpen }));

//export const toggleReFetch = () => dispatch => dispatch(toggleReFetchFlag())
