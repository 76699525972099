import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  StyleSheet,
  Image,
  useWindowDimensions,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import I18n from '../../../../components/shared/i18n/I18n.component';
import { TimeMode } from '../../../../components/shared/FieldBuilder/TimePicker/TimePicker.component';
import colors from '../../../../config/colors';
import OutlineButtonContainer from '../../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../../components/shared/Buttons/SolidButton.container';
import moment, { duration } from 'moment';
import { getState } from '../../../../store/store';
import useDevicePlatform, {
  DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import httpService from '../../../../services/http.service';
import { convertTimeToUtc } from '../../../../modals/AddRequestModal/AddRequestModal.utils';
import { cantReportModal, convertFromUtc, openAlertModal } from '../Clock.utils';
const timeIcon = require('../../../../../assets/icons/sandTime/time.png');
import { getTotalReportedByDate } from '../../../../modals/AddRequestModal/AddRequestModal.utils';
import { modalService } from '../../../../services/modal.service';
import AlertModal from '../../../../modals/AlertModal/AlertModal.component';
import { openStore } from '../../../../utils/globals.utils';
import { getLocationPermission } from '../../../../utils/permissions.utils';
import { ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../store/selectors/login.selectors';
import Loader from '../../Loader/Loader.component';
export interface formValues {
  date: string;
  exitTime: string;
  entryTime: string;
  projectId: string;
  eventId: string;
}
interface Props {
  submitBtnText: string;
  cancelBtnText: string;
  prevValues: PrevValues;
  projectsOptions: any[];
  eventsOptions: any[];
  shiftHours: number;
  handleSubmit: Function;
  onCancel: Function;
  onNext: Function;
  checkIfCanEntry: Function;
  totalReported: number;
}
interface PrevValues {
  date: string;
  projectId: string;
  eventId: string;
}

const CompleteEntryComponent = ({
  prevValues,
  submitBtnText,
  cancelBtnText,
  projectsOptions,
  eventsOptions,
  shiftHours,
  handleSubmit,
  onCancel,
  onNext,
  checkIfCanEntry,
  totalReported,
}: Props) => {
  const hasEventOrProjectsFilter =
    projectsOptions?.length || eventsOptions?.length ? true : false;
  const userData = useSelector(getUserData);

  const screenWidth = useWindowDimensions().width - 50;
  const platform = useDevicePlatform();
  const isMobile = platform !== DeviceType.WEB;
  const isWebOrMobileweb = platform !== DeviceType.MOBILE;
  const [entryTimeError, setEntryTimeError] = useState(false);
  const [HoursError, setHoursError] = useState(
    <View style={{ height: 17 }} />,
  );
  const isPrevDayShift = useRef(false);
  const [date, setDate] = useState(prevValues.date);
  const [yesterdayTotalReported, setYesterdayTotalReported] = useState(0);
  const formMethods = useForm({
    defaultValues: {
      ...prevValues,
      date: moment().format('DD/MM/YY'),
      entryTime: '',
    },
    mode: 'onBlur',
  });
  const entryTime = formMethods.watch('entryTime');
  useEffect(() => {
    getTotalReportedByDate(
      moment().subtract(1, 'day').format('YYYY-MM-DD'),
      false,
      null,
    ).then(total => {
      setYesterdayTotalReported(total);
    });
  }, []);

  useEffect(() => {
    const forms = formMethods.getValues();
    if (!forms.entryTime) return;
    const entry = moment(forms.entryTime, 'HH:mm');
    const exit = moment(
      convertFromUtc(
        getState().dateTime.currentDateTime,
        true,
        false,
        'YYYY-MM-DDTHH:mm',
      ),
      'HH:mm',
    );

    const currentAndEntryDiff = exit.diff(entry, 'minutes') / 60;
    const diffForPrevDate = entry.diff(exit, 'minutes') / 60;
    const remainShiftHours = shiftHours - totalReported; //parameter x - reportedHours
    const yesterdayRemainShiftHours = shiftHours - yesterdayTotalReported;

    if (currentAndEntryDiff > 0) {
      //if entry < exit
      if (isPrevDayShift.current) {
        const currentDate = moment(prevValues.date, 'DD/MM/YY').format(
          'DD/MM/YY',
        );
        formMethods.setValue('date', currentDate);
        setDate(currentDate);
        isPrevDayShift.current = false;
      }
      if (currentAndEntryDiff > remainShiftHours) {
        setEntryTimeError(true);
        setHoursError(hoursErrorHandler('shiftTimeOverflow'));
      } else {
        setEntryTimeError(false);
        setHoursError(hoursErrorHandler(''));
      }
      return;
    }
    if (diffForPrevDate > 0) {
      if (!isPrevDayShift.current) {
        //setting the date to yesterday
        const prevDate = moment(forms.date, 'DD/MM/YY')
          .subtract(1, 'day')
          .format('DD/MM/YY');
        formMethods.setValue('date', prevDate);
        setDate(prevDate);
        isPrevDayShift.current = true;
        setEntryTimeError(false);
        setHoursError(hoursErrorHandler(null));
      }
      if (24 - diffForPrevDate > yesterdayRemainShiftHours) {
        //if entry is grater then exit => yesterday report shift max hour check
        // check yesterday remain hours
        setEntryTimeError(true);
        setHoursError(hoursErrorHandler('shiftTimeOverflow'));
      } else {
        setEntryTimeError(false);
        setHoursError(hoursErrorHandler(null));
      }
      return;
    } else {
      if (isPrevDayShift.current) {
        const currentDate = moment(prevValues.date, 'DD/MM/YY').format(
          'DD/MM/YY',
        );
        formMethods.setValue('date', currentDate);
        setDate(currentDate);
        isPrevDayShift.current = false;
      }
      setEntryTimeError(false);
      setHoursError(hoursErrorHandler(''));
    }
  }, [entryTime]);

  const hoursErrorHandler = (errorType: string) => {
    switch (errorType) {
      case 'entryOverflowExit':
        return (
          <I18n size={14} color={'red'} weight='bold'>
            general.overTimeError2
          </I18n>
        );

      case 'shiftTimeOverflow':
        return (
          <View style={{ flexDirection: 'row-reverse' }}>
            <I18n size={14} color={'red'} weight='bold'>
              general.maxShiftTimeError
            </I18n>
            <I18n size={14} color={'red'} weight='bold'>
              {` ${shiftHours} `}
            </I18n>
            <I18n size={14} color={'red'} weight='bold'>
              general.maxShiftTimeError2
            </I18n>
          </View>
        );
      case 'OVERLLAPING':
        return (
          <I18n size={14} color={'red'} weight='bold'>
            general.validation.overlappingReport
          </I18n>
        );
      default:
        return <View style={{ height: 17 }} />;
    }
  };
  const Submit = async (forms: formValues) => {
    try {
      if (isWebOrMobileweb && !checkIfCanEntry({ id: forms.projectId })) {
        cantReportModal(isMobile, userData?.location.find(pr => pr.projectId === forms.projectId))
      } else {
        let lat = null;
        let lon = null;
        if (
          platform === DeviceType.MOBILE &&
          !checkIfCanEntry({ id: forms.projectId })
        ) {
          const location = await getLocationPermission();
          lat = location?.coords?.latitude || null;
          lon = location?.coords?.longitude || null;
          if (!location) {
            onCancel(onNext);
            return;
          }
        }

        const data = {
          date: moment(forms.date, 'DD/MM/YY').format('YYYY-MM-DD'),
          enterTime: convertTimeToUtc(
            forms.entryTime,
            forms.date,
            'DD/MM/YY',
            true,
          ),
          projectId: forms.projectId || null,
          exitTime: moment().utc().format('YYYY-MM-DDTHH:mm'),
          //later just spread the {...forms} so that the projects and event will be at the body to..
          mobile: !isWebOrMobileweb,
          lat,
          lon,
        };

        if (Platform.OS !== 'web') {
          const crashlytics = (
            await import('@react-native-firebase/crashlytics')
          ).default;
          crashlytics().recordError(
            new Error(
              `exitClockWorkLog from complete entry data: ${JSON.stringify(data)} `,
            ),
          );
        }

        const res = await httpService.api<any>({
          type: 'exitClockWorkLog',
          data,
        });
        handleSubmit(onNext, forms.date);
      }
    } catch (error) {
      if (error.data.error)
        switch (error.data.error) {
          case 'OVERLAPPING_ERROR':
            openAlertModal(
              'general.validation.overlappingReport',
              'attention',
              'general.close',
            );
            onCancel(onNext);
            break;
          case 'PARTIAL_ERROR':
            openAlertModal(
              'general.validation.overlappingReport',
              'attention',
              'general.close',
            );
            onCancel(onNext);
            break;
          case 'LOCATION_PROJECT_ERROR':
            platform === DeviceType.MOBILE ? openAlertModal(
              'workLogError.project.phone',
              'attention',
              'general.close',
            ) : cantReportModal(isMobile, userData?.location.find(pr => pr.projectId === forms.projectId))
            onCancel(onNext);
            break;
          case 'LOCATION_ERROR':
            platform === DeviceType.MOBILE ? openAlertModal(
              'workLogError.location.phone',
              'attention',
              'general.close',
            ) : cantReportModal(isMobile, userData?.location.find(pr => pr.projectId === forms.projectId))
            onCancel(onNext);
            break;
          default:
            onCancel(onNext);
            break;
        }
    }
  };
  return (
    <FormProvider {...formMethods}>
      <ScrollView
        showsVerticalScrollIndicator
        style={{
          shadowColor: 'rgba(0, 0, 0, 0)',
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 2 },
          backgroundColor: colors.white,
          borderRadius: 20,
          width: isMobile ? screenWidth : 408,
          height: isMobile
            ? useWindowDimensions().height - 100
            : 'auto',
        }}
        contentContainerStyle={{
          paddingVertical: 39,
          paddingHorizontal: 20,
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Image
          source={timeIcon}
          fadeDuration={0}
          style={{
            height: 120,
            width: 120,
            margin: 10,
          }}
        />
        {hasEventOrProjectsFilter && (
          <FieldBuilder
            styles={{
              wrapper: {
                margin: 10,
                alignSelf: 'center',
                zIndex: 1111,
              },
            }}
            type={'reactselect'}
            label={() => (
              <I18n size={14} weight='normal'>
                {projectsOptions?.length
                  ? 'menu.projects'
                  : 'menu.events'}
              </I18n>
            )}
            name={projectsOptions?.length ? 'projectId' : 'eventId'}
            key={projectsOptions?.length ? 'project' : 'event'}
            editable={true}
            options={
              projectsOptions?.length
                ? projectsOptions
                : eventsOptions
            }
            horizontalPadding={100}
            isSearchable={!isMobile}
          />
        )}
        <I18n
          style={{ marginVertical: 10, textAlign: 'center' }}
          weight='bold'
          size={24}
        >
          general.CompleteEntry
        </I18n>
        <View style={{ marginTop: 10, alignItems: 'center' }}>
          <I18n weight='400' size={14} style={{ opacity: 0.6 }}>
            general.reportDate
          </I18n>
          <I18n weight='400' size={20}>
            {date}
          </I18n>
        </View>
        <FieldBuilder
          type={'timePicker'}
          name={'entryTime'}
          defaultTime={null}
          styles={{
            wrapper: { margin: 10 },
          }}
          editable={true}
          mode={TimeMode.Entry}
          error={entryTimeError}
        />
        {HoursError}
        <View style={styles.actionButtons}>
          <SolidButtonContainer
            disabled={
              !formMethods.getValues().entryTime || entryTimeError
            }
            overrideStyle={{
              minWidth: 92,
              height: 40,
              justifyContent: 'center',
              backgroundColor: entryTimeError
                ? '#cacaca'
                : colors.lightPrimary,
              paddingHorizontal: 15,
              padding: 10,
            }}
            onPress={() => Submit(formMethods.getValues())}
          >
            <I18n
              size={16}
              style={{ minHeight: 40 }}
              weight='normal'
              color='white'
            >
              {submitBtnText}
            </I18n>
          </SolidButtonContainer>
          <OutlineButtonContainer
            overrideStyle={styles.buttonCancel}
            onPress={() => onCancel(onNext)}
          >
            <I18n
              size={16}
              style={{ minHeight: 40 }}
              weight='normal'
              color='primary'
            >
              {cancelBtnText}
            </I18n>
          </OutlineButtonContainer>
        </View>
      </ScrollView>
      <Loader />
    </FormProvider>
  );
};
const styles = StyleSheet.create({
  actionButtons: {
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 20,
  },
  buttonOk: {
    minWidth: 92,
    height: 40,
    justifyContent: 'center',
    backgroundColor: colors.lightPrimary,
    paddingHorizontal: 15,
    padding: 10,
  },
  buttonCancel: {
    minWidth: 92,
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: 15,
    padding: 10,
  },
});
export default CompleteEntryComponent;
