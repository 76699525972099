import React from 'react';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import ModalConfirmActions from '../ModalConfirmActions/ModalConfirmActions.component';
import I18n from '../../components/shared/i18n/I18n.component';
import { modalService } from '../../services/modal.service';
import moment from 'moment';
import { View } from 'react-native';
import httpService from '../../services/http.service';
import { debounce, toNumber } from 'lodash';
import { i18nService } from '../../services/i18n.service';
import { openAlertModal } from '../../components/shared/ClockReport/Clock.utils';
interface formsModel {
	projectId: string;
	eventId: string;
	date: string;
	entryTime: string;
	exitTime: string;
	fullDay: boolean;
	employeeId: string;
	employee: object;
}
export const convertTimeToUtc = (
	time: string,
	date: string,
	dateFormat: string,
	getTime: boolean,
) => {
	const format = date.includes('-') ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
	const utcTime = moment(`${date}T${time}`, `${format}THH:mm`).utc();
	if (getTime) return utcTime.format('YYYY-MM-DDTHH:mm');
	return utcTime.format('YYYY-MM-DD');
};
export const openModal = async (
	openEditModal: any,
	message: string = '',
	isAlertModal: boolean,
	openEdit: boolean,
	icon?: string,
	cancelButtonLabel?: string,
	confirmButtonLabel?: string,
	workLogId?: any,
) => {
	await modalService.openModal(
		null,
		{
			submitBtnText: confirmButtonLabel
				? confirmButtonLabel
				: 'general.close',
			iconName: icon ? icon : isAlertModal ? 'tick' : 'attention',
			cancelBtnText: cancelButtonLabel
				? cancelButtonLabel
				: 'general.cancel',

			onCancel: (callback: Function) => {
				callback();
			},

			onSubmit: (callback: Function) => {
				if (openEdit) {
					openEditModal(workLogId);
					callback();
				} else {
					callback();
				}
			},
		},
		(props: any) =>
			isAlertModal ? (
				<AlertModal {...props}>
					<I18n>{message}</I18n>
				</AlertModal>
			) : (
				<ModalConfirmActions {...props}>
					<I18n>{message}</I18n>
				</ModalConfirmActions>
			),
	);
};

export interface prevValuesForm {
	employee: {
		id: any;
		name: string;
	};
	projectId: string;
	eventId: string;
	date: string;
	entryTime: string;
	exitTime: string;
	fullDay: boolean;
	employeeId: string;
	total: any;
}
export const validateForms = (
	forms: formsModel,
	projects: any[],
	events: any[],
	isEventsOnView: boolean,
	isFullDayReport: any,
	shiftHours: number,
	totalReported: number,
	lockDay: number,
	worklogLockType: string,
	dateFormat: string,
	prevValues: prevValuesForm,
	standardHours: number,
	isEdit: boolean,
) => {
	const errorsHandler = {
		errorFields: {
			employeeId: false,
			projectId: false,
			eventId: false,
			date: false,
			entryTime: false,
			exitTime: false,
			isMissingEntryOrExit: false,
			showErrorMassege: '',
			fromDate: false,
			toDate: false,
		},
		disableSubmit: true,
	};
	const currentWorkLogTotal = prevValues?.total;
	const entry = moment(forms.entryTime, 'HH:mm');
	const exit = moment(forms.exitTime, 'HH:mm');

	const exitAndEntryDiff = exit.diff(entry, 'minutes'); // in minutes
	const remainingHours =
		shiftHours -
		(currentWorkLogTotal
			? totalReported - currentWorkLogTotal / 60
			: totalReported);
	const empID =
		forms?.employeeIds && isEventsOnView
			? true
			: forms.employeeId || forms.employee;

	const checkStandardHours = () => {
		//checks if full day standard is bigger then max shift hours
		if (!standardHours) return;
		const enter = moment(standardHours.enterTime, 'HH:mm');
		const exit = moment(standardHours.exitTime, 'HH:mm');
		if (exit.diff(enter, 'minutes') / 60 > shiftHours) {
			errorsHandler.errorFields.showErrorMassege = 'shiftHoursError';
		}
	};

	const isLocked = (date: string) => {
		switch (worklogLockType) {
			case 'SELECTED_DAY':
				const lockDate = `${moment().format('YYYY-MM')}-${
					lockDay < 10 ? `0${lockDay}` : lockDay
				}`;

				return (
					moment().isSameOrAfter(moment(lockDate, 'YYYY-MM-DD')) &&
					moment(date, dateFormat).isBefore(moment().startOf('month'))
				);

			case 'END_OF_MONTH':
				return moment(date, dateFormat).isBefore(
					moment().startOf('month'),
				);
			default:
				//NO LOCK
				return false;
		}
	};

	const isErroredDateInput = (date: any) => {
		if (!date) return true;
		if (isLocked(date)) {
			return true;
		}

		const currentDate = moment().format('DD/MM/YYYY');
		return isEdit
			? false
			: moment(date, dateFormat).isAfter(
					moment(currentDate, 'DD/MM/YYYY'),
			  );
	};

	const ValidateExitAndEntry = (date: string) => {
		if (isFullDayReport && isEventsOnView) {
			errorsHandler.errorFields.entryTime = false;
			errorsHandler.errorFields.exitTime = false;
			return;
		}

		//if one of the fields is missing return with an error | check only if it is not an event report with full day
		if (!isEventsOnView || !isFullDayReport) {
			errorsHandler.errorFields.isMissingEntryOrExit =
				!forms.exitTime || !forms.entryTime ? true : false;
			if (errorsHandler.errorFields.isMissingEntryOrExit) return;
		}
		//if date is today , you cannot report feuture entry & exit time
		if (date === moment().format(dateFormat)) {
			let tempExit = exit;
			if (exit.isBefore(entry)) {
				tempExit = exit.add(1, 'day');
			}
			errorsHandler.errorFields.entryTime = entry.isAfter(moment());
			errorsHandler.errorFields.exitTime = tempExit.isAfter(moment());
			if (
				errorsHandler.errorFields.entryTime ||
				errorsHandler.errorFields.exitTime
			)
				//exit function because fields has errors
				return;
		}
		if (exitAndEntryDiff < 0) {
			if (24 + exitAndEntryDiff / 60 > remainingHours) {
				errorsHandler.errorFields.entryTime = true;
				errorsHandler.errorFields.showErrorMassege = 'shiftHoursError';
				return;
			}
			return;
		}
		if (exitAndEntryDiff / 60 > remainingHours) {
			errorsHandler.errorFields.entryTime = true;
			errorsHandler.errorFields.exitTime = true;
			errorsHandler.errorFields.showErrorMassege = 'shiftHoursError';
			return;
		}
		return;
	};

	if ((!!forms.fromDate || !!forms.toDate) && isEventsOnView) {
		errorsHandler.errorFields.fromDate = !forms.fromDate;
		errorsHandler.errorFields.toDate = !forms.toDate;
	}

	if (isEventsOnView && isFullDayReport) {
		// if ((!forms.fromDate && !forms.toDate)) {
		checkStandardHours();
		// }
	} else
		ValidateExitAndEntry(
			!!forms.fromDate && isEventsOnView
				? forms.fromDate
				: forms?.date || prevValues?.date,
		);

	errorsHandler.errorFields.date =
		isEventsOnView && (!!forms.fromDate || !!forms.toDate)
			? false
			: isErroredDateInput(forms?.date || prevValues?.date);

	//checking if some other the fields are missing
	errorsHandler.errorFields.employeeId = !empID;
	errorsHandler.errorFields.eventId =
		isEventsOnView && events.length
			? forms.eventId
				? false
				: true
			: false;
	errorsHandler.errorFields.projectId =
		!isEventsOnView && projects.length
			? forms.projectId
				? false
				: true
			: false;

	//disable submit button if any field has an error
	let hasError = false;
	for (let key in errorsHandler.errorFields) {
		if (errorsHandler.errorFields[key]) {
			hasError = true;
		}
	}
	errorsHandler.disableSubmit = hasError;
	return errorsHandler;
};

export const GetErrorLable = ({
	errorType,
	shiftHours,
}: {
	errorType: String;
	shiftHours: any;
}) => {
	switch (errorType) {
		case 'shiftHoursError':
			return (
				<I18n
					size={14}
					color={'red'}
					weight='bold'
					style={{ textAlign: 'center', marginVertical: 10 }}
				>
					{`${i18nService.translate(
						'general.maxShiftTimeError',
					)} ${shiftHours} ${i18nService.translate(
						'general.maxShiftTimeError2',
					)}`}
				</I18n>
			);
		case 'entryGraterExit':
			return (
				<I18n
					size={14}
					color={'red'}
					weight='bold'
					style={{ textAlign: 'center', marginVertical: 10 }}
				>
					general.overTimeError2
				</I18n>
			);
		default:
			return <View />;
	}
};

export const formatStandardHours = standardHours => {
	let enterTime = '08:00';
	let exitTime = '08:00';

	if (standardHours) {
		if (
			standardHours.from === '00:00:00' &&
			standardHours.to === '00:00:00'
		) {
			const [hours, minutes, seconds] = standardHours.fixHours
				.toString()
				.split(':');
			exitTime = moment('08:00', 'HH:mm')
				.add(toNumber(hours), 'h')
				.format('HH:mm');
			exitTime = moment(exitTime, 'HH:mm')
				.add(toNumber(minutes), 'm')
				.format('HH:mm');
		} else {
			enterTime = moment(standardHours.from, 'HH:mm:ss').format('HH:mm');
			exitTime = moment(standardHours.to, 'HH:mm:ss').format('HH:mm');
		}
	}
	return { exitTime, enterTime };
};

export const getTotalReportedByDate = async (
	date: string,
	isManager: boolean,
	profileAid: any,
	unitId: number,
) => {
	try {
		const res = await httpService.api<any>({
			type: isManager
				? 'managerGetTotalReportedByDate'
				: 'employeeGetTotalReportedByDate',
			params: isManager ? { date, profileAid } : { date },
			query: isManager
				? {
						unitId,
				  }
				: {},
		});
		if (res) {
			return res / 60;
		}
		return 0;
	} catch (e) {
		return 0;
	}
};

export const exitAndEntryDateSetter = (
	entry: any,
	exit: any,
	isExitChanged: boolean,
	isEntryChanged: boolean,
	prevExitDate: any,
	prevEntryDate: any,
	isDiffWasNegBefore: boolean,
	formsDate: string,
	dateFormat: string,
) => {
	let entryDate = prevEntryDate;
	let exitDate = prevExitDate;
	let date = formsDate;
	const exitAndEntryDiff = exit.diff(entry, 'minutes');
	//exit & entry should be a moment object type

	if (isEntryChanged && isExitChanged) {
		if (exitAndEntryDiff < 0) {
			//exit is on the next day , cross night shift
			entryDate = formsDate;
			exitDate = moment(formsDate, dateFormat)
				.add(1, 'day')
				.format(dateFormat);
		} else {
			//chosen date
			entryDate = formsDate;
			exitDate = formsDate;
		}
		return { entryDate, exitDate, date };
	}

	if (isEntryChanged) {
		if (isDiffWasNegBefore) {
			//if entry was bigger than exit before changed
			if (exitAndEntryDiff < 0) {
				//entry stays bigger than exit
				entryDate = prevEntryDate;
			} //entry was bigger but now is smaller than exit
			else {
				entryDate = moment(prevEntryDate, dateFormat)
					.add(1, 'day')
					.format(dateFormat);
				date = entryDate;
			}
		} else {
			if (exitAndEntryDiff < 0) {
				//entry was smaller but now is bigger than exit
				entryDate = moment(prevExitDate, dateFormat)
					.subtract(1, 'day')
					.format(dateFormat);
				date = entryDate;
			} //entry was  smaller and stays smaller than exit
			else {
				entryDate = prevExitDate;
			}
		}
		return { entryDate, exitDate, date };
	}

	if (isExitChanged) {
		if (isDiffWasNegBefore) {
			//if exit was smaller than exit before changed
			if (exitAndEntryDiff < 0) {
				//exit stays  smaller than entry
				entryDate = prevEntryDate;
			} //entry was bigger but now is smaller than exit
			else {
				exitDate = prevEntryDate;
			}
		} else {
			if (exitAndEntryDiff > 0) {
				//exit was bigger and stays bigger
				exitDate = prevExitDate;
			} //entry was  smaller and stays smaller than exit
			else {
				exitDate = moment(prevEntryDate, dateFormat)
					.add(1, 'day')
					.format(dateFormat);
			}
		}
		return { entryDate, exitDate, date };
	}

	return { entryDate, exitDate, date };
};

export const errorPopUp = (
	errorType: any = null,
	date: string,
	openEditModal: Function,
	workLogId: any = null,
	isEdit: boolean,
	isMultipleEvent,
) => {
	switch (errorType) {
		case 'PARTIAL_ERROR':
			if (workLogId && !isMultipleEvent) {
				openModal(
					openEditModal,
					'general.validation.missingWorkLogExists',
					false,
					true,
					'',
					'general.cancel',
					'EmployeeHoursReport.table.edit',
					workLogId,
				);
			} else {
				openModal(
					openEditModal,
					isMultipleEvent
						? 'general.validation.missingWorkLogExistsMultiple'
						: 'general.validation.multipleMissingWorkLogExists',
					true,
					false,
					'attention',
				);
			}
			break;
		case 'OVERLAPPING_ERROR':
			openModal(
				openEditModal,
				isMultipleEvent
					? 'general.validation.WorkLogAlreadyExistMultiple'
					: 'general.validation.WorkLogAlreadyExist',
				true,
				false,
				'attention',
			);
			break;
		case 'BUSINESS_UNIT_ERROR':
			openAlertModal('general.editFailedCauseBusinessUnit', 'attention');
			break;
		case 'SHIFT_HOURS_ERROR':
			openAlertModal(
				'general.editFailedCauseShiftHoursMax',
				'attention',
				'',
				{
					erroredDate: moment(date, 'YYYY-MM-DD').format('DD/MM/YY'),
				},
			);
			break;
		default:
			openAlertModal(
				isEdit ? 'general.editFailed' : 'general.addWworkLogFailed',
				'attention',
				'',
			);
			break;
	}
};
