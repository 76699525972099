import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import httpService from '../../../services/http.service';
import { buildReportData } from './WorkPaperPage.utils';

export const initialState: WorkPaperState = {
	filters: {
		dep: [],
		emp: [],
		from_month: [],
		from_year: [],
		to_month: [],
		to_year: [],
		unitIds: [],
	},
	rows: [],
	columns: [],
	errorMessage: '',
	completed: true,
	totalReports: undefined,
	hasMore: false,
	page: 0,
	pageSize: 10,
	showNumbers: true,
	originalData: [],
};

export type WorkPaperState = {
	filters: {
		dep: string[];
		emp: string[];
		from_month: number[];
		from_year: number[];
		to_month: number[];
		to_year: number[];
		unitIds: number[];
	};
	rows: any[];
	columns: any[];
	errorMessage?: string;
	completed: boolean;
	totalReports?: number;
	hasMore: boolean;
	page: number;
	pageSize: number;
	showNumbers: boolean;
	originalData: any;
};
export type Action = {
	type: string;
	payload: any;
};

export const workPaperReducer: React.Reducer<WorkPaperState, Action> = (
	state,
	action,
) => {
	switch (action.type) {
		case 'reset_filters': {
			return {
				...initialState,
				filters: {
					...initialState.filters,
					unitIds: state.filters.unitIds,
				},
			};
		}
		case 'increment_page':
			return {
				...state,
				showNumbers: false,
				page: state.page + 1,
			};
		case 'decrement_page':
			return {
				...state,
				showNumbers: false,
				page: state.page - 1,
			};
		case 'inital_date':
			return {
				...state,
				page: 0,
				filters: {
					...state.filters,
					from_month: [action.payload.month],
					from_year: [action.payload.year],
					to_month: [action.payload.month],
					to_year: [action.payload.year],
				},
			};
		case 'update_unitIds':
			return {
				...state,
				filters: {
					...state.filters,
					unitIds: action.payload.isMulti
						? action.payload.value.map((val: any) => val.id || val)
						: [action.payload.value],
					dep: [],
					emp: [],
					from_month: [],
					from_year: [],
					to_month: [],
					to_year: [],
				},
			};
		case 'update_date_from':
			return {
				...state,
				page: 0,
				filters: {
					...state.filters,
					from_month: [moment(action.payload).get('month') + 1],
					from_year: [moment(action.payload).get('year')],
				},
			};
		case 'update_date_to':
			return {
				...state,
				page: 0,
				filters: {
					...state.filters,
					to_month: [moment(action.payload).get('month') + 1],
					to_year: [moment(action.payload).get('year')],
				},
			};
		case 'update_departments':
			return {
				...state,
				page: 0,
				filters: {
					...state.filters,
					emp: [],
					dep: action.payload.map((el: any) => el.id),
				},
			};
		case 'update_employees':
			return {
				...state,
				page: 0,
				filters: {
					...state.filters,
					emp: action.payload.map((el: any) => el.id),
				},
			};

		case 'fetch_reports_request':
			return {
				...state,
				completed: false,
			};
		case 'fetch_reports_success':
			return {
				...state,
				errorMessage:
					state.page === 0 && !action.payload.data.rows?.length
						? 'workPaper.table.noItems'
						: undefined,
				completed: true,
				totalReports: action.payload.total,
				hasMore: action.payload.hasNext,
				columns: action.payload.data.columns,
				rows: action.payload.data.rows,
				showNumbers: true,
			};
		default:
			return state;
	}
};

export const fetchReports = async ({
	page,
	pageSize,
	filter,
	dispatch,
}: any) => {
	dispatch({ action: 'fetch_reports_request' });
	const res: { data: any; totalRow: any } = await httpService.api({
		type: 'getWorkPaper',
		data: {
			p: page,
			ps: pageSize,
			f: filter,
		},
	});

	const data = buildReportData(res);
	dispatch({
		type: 'fetch_reports_success',
		payload: {
			...res,
			data,
		},
	});
};
