import React from 'react';
import { Dimensions, View, StyleSheet, ScrollView } from 'react-native';
import colors from '../../config/colors';
import { UserDetails } from './PersonalInformation.container';
import I18n from '../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import Header from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import PersonalInformationMobile from './PersonalInformation.component.native';
import { DataContainer } from './PersonalInformation.utils';
import { Divider } from 'react-native-paper';

export interface PersonalInformationProps {
	navigation: any;
	data: UserDetails | {};
	onUpdatePassword(): void;
	onUpdatePhone(): void;
	onUpdateEmail(): void;
	employerList?: any[];
	isEmployerAndEmployee?: boolean;
}

const windowHeight = Dimensions.get('window').height;

const PersonalInformation = ({
	navigation,
	data,
	onUpdatePassword,
	onUpdateEmail,
	...restProps
}: PersonalInformationProps) => {
	const platform = useDevicePlatform();

	const isMobileWeb = platform === DeviceType.MOBILE_WEB;

	return isMobileWeb ? (
		<PersonalInformationMobile
			navigation={navigation}
			data={data}
			onUpdatePassword={onUpdatePassword}
			onUpdateEmail={onUpdateEmail}
			{...restProps}
		/>
	) : (
		<ScrollView
			style={{
				backgroundColor: colors.tableBackground,
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<View style={styles.container}>
				<I18n size={36} weight='normal' style={{ flex: 1 }}>
					personalInformation.title
				</I18n>
				<Divider
					style={{
						backgroundColor: colors.darkGrey,
						opacity: 0.2,
						marginVertical: 20,
					}}
				/>
				<DataContainer
					userData={data}
					onUpdatePassword={onUpdatePassword}
					onUpdateEmail={onUpdateEmail}
					{...restProps}
				/>
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: 27,
		marginHorizontal: 100,
		backgroundColor: colors.white,
		padding: 50,
		paddingBottom: 100,
	},
});

export default PersonalInformation;
