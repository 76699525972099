import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import CustomDatePicker from "./CustomDatePicker.component";
import { props } from "./CustomDatePicker.component";
import { i18nService } from "../../../services/i18n.service";

const customDatePickerContainer = ({ config, onChange }: props) => {
  const [lable, setLable] = useState("allReports.datePicker");
  const rangeSpanText = useRef("");
  const runningObserver = useRef(null);
  useEffect(() => {
    if (lable !== "allReports.datePicker") setLable("allReports.datePicker");
  }, [config.extraProps.key]);

  const settings = {
    ...config,
    extraProps: {
      ...config.extraProps,

      locale: {
        format: "DD/MM/YYYY",
        direction: "rtl",
        applyLabel: i18nService.translate("general.accept"),
        cancelLabel: i18nService.translate("general.cancel"),
        customRangeLabel: i18nService.translate("employeeRequests.dates"),
        daysOfWeek: [
          i18nService.translate("filter.datepicker.sunday"),
          i18nService.translate("filter.datepicker.monday"),
          i18nService.translate("filter.datepicker.tuesday"),
          i18nService.translate("filter.datepicker.wednesday"),
          i18nService.translate("filter.datepicker.thursday"),
          i18nService.translate("filter.datepicker.friday"),
          i18nService.translate("filter.datepicker.saturday"),
        ],
        monthNames: [
          i18nService.translate("months.January"),
          i18nService.translate("months.February"),
          i18nService.translate("months.March"),
          i18nService.translate("months.April"),
          i18nService.translate("months.May"),
          i18nService.translate("months.June"),
          i18nService.translate("months.July"),
          i18nService.translate("months.August"),
          i18nService.translate("months.September"),
          i18nService.translate("months.October"),
          i18nService.translate("months.November"),
          i18nService.translate("months.December"),
        ],
        firstDay: 0,
      },
      ranges: {
        [i18nService.translate("filter.datepicker.today")]: [
          moment().toDate(),
          moment().toDate(),
        ],
        [i18nService.translate("filter.datepicker.yesterday")]: [
          moment().subtract(1, "days").toDate(),
          moment().subtract(1, "days").toDate(),
        ],
        [i18nService.translate("filter.datepicker.lastWeek")]: [
          moment().subtract(1, "weeks").startOf("week").toDate(),
          moment().subtract(1, "weeks").endOf("week").toDate(),
        ],
        [i18nService.translate("filter.datepicker.currentWeek")]: [
          moment().startOf("week").toDate(),
          moment().endOf("week").toDate(),
        ],
      },
    },
  };

  const handleApply = (event, picker) => {
    const formatedStart = moment(picker.startDate).format("YYYY-MM-DD");
    const formatedEnd = moment(picker.endDate).format("YYYY-MM-DD");
    if (
      picker.chosenLabel === i18nService.translate("employeeRequests.dates")
    ) {
      const start = moment(picker.startDate).format("DD/MM/YY");
      const end = moment(picker.endDate).format("DD/MM/YY");
      setLable(`${end} - ${start}`);
    } else setLable(picker.chosenLabel);
    onChange({
      name: config.name,
      value: {
        start: formatedStart,
        end: formatedEnd,
      },
    });
  };
  const onShow = () => {
    const createObserver = (node) => {
      const configuration = {
        attributes: true,
        childList: true,
        subtree: true,
      };
      if (!runningObserver.current) {
        const observer = new MutationObserver((mutationsList, observer) => {
          let currentText =
            mutationsList[mutationsList.length - 1].addedNodes[0].textContent;
          if (rangeSpanText.current !== currentText) {
            const reorderedText = currentText?.split("-").reverse().join(" - ");
            node.innerText = reorderedText;
            rangeSpanText.current = reorderedText;
          }
        });

        observer.observe(node, configuration);
        runningObserver.current = observer;
      }
    };

    if (document.querySelector(".drp-selected")) {
      //case when the menu is already open on calendars
      const dateRangeSpan = document.querySelector(".drp-selected");
      createObserver(dateRangeSpan);
    } else {
      // catch the span from the DOM only when the user press range picker and the span exists
      const rangeButton = document.querySelectorAll(
        ".daterangepicker .ranges li"
      )[4];
      rangeButton.addEventListener("click", () => {
        const dateRangeSpan = document.querySelector(".drp-selected");
        createObserver(dateRangeSpan);
      });
    }
  };
  const cleanUpObserver = () => {
    if (runningObserver.current) {
      runningObserver.current?.disconnect();
      runningObserver.current = null;
    }
  };
  return (
    <CustomDatePicker
      config={settings}
      lable={lable}
      onApply={handleApply}
      onShow={onShow}
      onHide={cleanUpObserver}
    />
  );
};

export default customDatePickerContainer;
