import Collapsible from 'react-native-collapsible';
import React, { useState } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import _ from 'lodash';
import useDevicePlatform, {
	DeviceType,
} from '../../../../../hooks/useDevicePlatform.hook';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import getShadowStyle from '../../../../../config/shadow';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';

const CustomerDataSection = React.memo(
	({
		data,
		children,
		onSelectCustomer,
		onDeleteCustomer,
		index,
		disabled,
	}: any) => {
		const platform = useDevicePlatform();
		const isMobile = platform !== DeviceType.WEB;
		const [isCollapse, setIsCollapse] = useState(true);

		const handlesPressCollapse = () => {
			setIsCollapse(!isCollapse);
			isCollapse && onSelectCustomer(data, index);
		};

		return (
			<View
				style={{
					borderWidth: !isMobile ? 1 : 0,
					borderRadius: !isMobile ? 5 : 0,
					borderColor: '#E0E0E0',
					marginLeft: isMobile ? 0 : 20,
					marginBottom: 9,
					...(isMobile
						? getShadowStyle(
								Platform.OS === 'web' ? 10 : 1,
								'rgb(59, 112, 164)',
						  )
						: {}),
				}}
			>
				<TouchableOpacity
					onPress={handlesPressCollapse}
					style={{
						flexDirection: isMobile ? 'row-reverse' : 'row',
						paddingVertical: 13,
						paddingHorizontal: 18,
					}}
				>
					{!isMobile && (
						<ImageIcon
							name='leftArrow'
							width={8.5}
							height={16}
							style={{
								transform: [
									{
										rotate: isCollapse ? '0deg' : '-90deg',
									},
								],
								marginLeft: 10,
							}}
						/>
					)}
					{isCollapse ? (
						<View
							style={{
								flexDirection: isMobile ? 'row-reverse' : 'row',
								alignItems: 'center',
							}}
						>
							<Typography
								weight='normal'
								style={{ maxWidth: 220 }}
								numberOfLines={2}
								size={16}
							>
								{data?.customer_name}
							</Typography>
							{data?.customer_hp && (
								<Typography
									weight='400'
									size={14}
									color={'grey'}
									style={{ marginRight: 5 }}
								>
									{`(${data.customer_hp})`}
								</Typography>
							)}
						</View>
					) : null}
					<View style={{ flex: 1 }} />

					<TouchableOpacity
						style={{ paddingRight: 10 }}
						onPress={() => onDeleteCustomer(data.customer_id)}
						disabled={disabled}
					>
						<ImageIcon name='delete' width={17.5} height={20} />
					</TouchableOpacity>
				</TouchableOpacity>
				<Collapsible collapsed={isCollapse}>
					<View
						style={{
							paddingBottom: 30,
							paddingHorizontal: isMobile
								? 20
								: !isCollapse
								? 40
								: 0,
						}}
					>
						{children}
					</View>
				</Collapsible>
			</View>
		);
	},
);

export default CustomerDataSection;
