import React, { ReactText, useMemo } from 'react';
import { View, StyleProp, StyleSheet, TouchableHighlight } from 'react-native';
import { ButtonProps } from '../../../interfaces/buttonProps.interface';
import getShadowStyle from '../../../config/shadow';
import I18n from '../i18n/I18n.component';
import colors from '../../../config/colors';

const Button = ({
	children,
	onPress,
	disabled = false,
	buttonStyle,
	textOptions,
}: ButtonProps) => {
	const containerStyle = useMemo(
		() =>
			StyleSheet.flatten([
				buttonStyle,
				disabled ? styles.disabledButton : null,
			]),
		[disabled, buttonStyle],
	);

	return (
		<TouchableHighlight
			underlayColor='none'
			accessibilityRole='button'
			disabled={disabled}
			onPress={onPress}
		>
			<View style={containerStyle}>
				<I18n {...textOptions} style={styles.defaultText}>
					{children}
				</I18n>
			</View>
		</TouchableHighlight>
	);
};

const styles = StyleSheet.create({
	disabledButton: {
		opacity: 0.5,
	},
	defaultText: {
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 14.5,
	},
});

export default Button;
