import template from 'lodash/template';
import defaultDictionary from '../../assets/defaultDictionary.json';

class I18nService {
	/**
	 * Translates a key into a string based on the selected language
	 * and the dictionary.
	 */
	translate(key: string, lang?: any, args?: Record<string, any>): string {
		lang = null;
		const dictionary = lang || defaultDictionary;

		const translation = dictionary[key];
		if (!translation) {
			return key;
		}
		return template(translation)(args || {});
	}
}

export const i18nService = new I18nService();
