import { AsyncPaginate } from 'react-select-async-paginate';
import React, { useEffect, useMemo, useRef } from 'react';
import { default as ReactSelect, components } from 'react-select';
import { Checkbox, makeStyles } from '@material-ui/core';
// @ts-ignore
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import { usePrevious } from '../../../hooks/usePrevious.hook';

interface AsyncMultiSelectProps {
	loadOptions: () => {
		options: any[];
		hasMore: boolean;
	};
	onShowHideList: any;
	selectedOptions: SelectedOptions[];
	onChangeCheckBox: (selections: any, item: any) => void;
	config: any;
	key: any;
}

interface SelectedOptions {
	label: string;
	value: string;
}

const useStyles = makeStyles(() => ({
	chip: {
		borderRadius: 2,
		color: 'hsl(0, 0%, 20%)',
		fontSize: '85%',
		padding: 3,
		paddingLeft: 6,
		margin: 2,
		backgroundColor: 'hsl(0, 0%, 90%)',
	},
	checkbox: {
		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
		},
	},
	checked: {},
}));

const customStyles = (props: any) => {
	// You can use the 'styles' prop to override the styling of the component.
	const {
		control = {},
		menu,
		option,
		indicatorsContainer,
		indicatorSeparator,
		clearIndicator,
		container,
		placeholder = {},
		singleValue = {},
		input,
		menuPortal,
		menuList = {},
		newInput = {},
	} = props.styles || {};

	return {
		control: (base: any) => ({
			...base,
			minHeight: 32,
			height: 32,
			backgroundColor: colors.white,
			color: colors.darkGrey,
			borderColor: colors.filterBorder,
			borderWidth: 1,
			boxShadow: 'none',
			minWidth: 99,
			borderRadius: 2,
			'&:hover': {
				borderColor: colors.filterBorder,
			},
			width: props.config.styles?.width
				? props.config.styles?.width + 2
				: 136,
			...props.style,
			...control,
		}),
		menu: (provided: any) => ({
			...provided,
			top: 34,
			left: 0,
			width: '100%',
			backgroundColor: colors.white,
			borderWidth: 1,
			borderRadius: 2,
			borderColor: 'transparent',
			margin: 0,
			zIndex: 9999999999999,
			...menu,
			// backgroundColor: 'red'
		}),
		menuPortal: (base: any) => ({
			...base,
			...menuPortal,
		}),
		valueContainer: (base: any) => ({
			...base,
			height: 'inherit',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			visibility: 'visible',
			flexWrap: 'unset',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			display: 'flex',
			alignItems: 'center',
			height: 29,
			marginTop: 6,
			padding: 0,
			backgroundColor: state.isSelected
				? colors.selectOptionSelected
				: state.isFocused
				? colors.selectOptionFocused
				: colors.white,
			...option,
		}),
		indicatorsContainer: (base: any) => ({
			...base,
			height: 'inherit',
			...indicatorsContainer,
		}),
		dropdownIndicator: (base: any) => ({
			...base,
			padding: 6,
			color: colors.primary,
		}),
		clearIndicator: (base: any) => ({
			...base,
			padding: 6,
		}),
		container: (provided: any) => ({
			...provided,
			width: '100%',
			...container,
		}),
		menuList: (provided: any) => ({
			...provided,
			width: '100%',
			...menuList,
		}),
		placeholder: (base: any) => ({
			...base,
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			...placeholder,
		}),
		input: (provided: any) => ({
			...provided,
			...input,
			padding: 0,
			margin: 0,
			color: colors.darkGrey,
			...newInput,
		}),
	};
};

const CheckboxOption = (props: any) => {
	const classes = useStyles();

	return (
		<components.Option {...props}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					padding: '4px 0',
				}}
				title={props.children}
			>
				<Checkbox
					size='small'
					classes={{
						root: classes.checkbox,
						checked: classes.checked,
					}}
					color='default'
					checked={props.isSelected}
				/>
				<div
					style={{
						textAlign: 'right',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						fontSize: props?.fontSize ? props.fontSize : 14,
						color: colors.darkGrey,
						fontFamily: 'RubikRegular',
						overflow: 'hidden',
						paddingLeft: 5,
					}}
				>
					{props.children}
				</div>
			</div>
		</components.Option>
	);
};

const ValueContainer = (props: any) => {
	const hiddenChips = useMemo(
		// this function return the number of selected options.
		() =>
			// first, check if "all" is selected.
			props.selectProps.value?.filter(item => item.value !== 'all')
				.length === props.selectProps.value.length
				? // if not, check if "all" was selected before and the user just unselect specific options.
				  props.selectProps.excludeMode
					? // if so, return the Total minus the unselect options.
					  props.selectProps.total -
					  (props.selectProps.options.length -
							props.selectProps.value.length)
					: // if the "all" option not selected before, return the arry value length.
					  props.selectProps.value.length - 1
				: // if "all" is selected, return the Total or the the arry value length minus the "all".
				  props.selectProps.total ||
				  props.selectProps.value?.filter(item => item.value !== 'all')
						.length,
		[
			props.selectProps.value,
			props.selectProps.excludeMode,
			props.selectProps.options,
			props.selectProps.total,
		],
	);

	const prevMenuIsOpen = usePrevious(props.selectProps.menuIsOpen);
	const prevValue = usePrevious(props.selectProps.value?.length);

	useEffect(() => {
		if (
			props.selectProps.menuIsOpen &&
			(props.selectProps.menuIsOpen !== prevMenuIsOpen ||
				props.selectProps.value?.length !== prevValue)
		) {
			props.selectProps.selectRef.current?.focus();
		}
	}, [props.selectProps.menuIsOpen, props.selectProps.value?.length]);

	const classes = useStyles();
	return (
		<components.ValueContainer {...props}>
			{props.selectProps.value?.length
				? React.cloneElement(props.children[1])
				: null}
			{props.selectProps.value?.length ? (
				!props.selectProps.menuIsOpen ||
				props.selectProps.forceShowResults ? (
					<>
						{props.children[0].slice(0, 1)}
						{hiddenChips ? (
							<div
								className={classes.chip}
							>{`+${hiddenChips}`}</div>
						) : null}
					</>
				) : null
			) : (
				props.children
			)}
		</components.ValueContainer>
	);
};

const ValueContainerNoChips = (props: any) => {
	return (
		<components.ValueContainer {...props}>
			{props.children[1]}
			{!props.selectProps?.selectRef?.current?.select?.state
				?.isFocused && (
				<components.Placeholder {...props}>
					{props.selectProps.placeholder}
				</components.Placeholder>
			)}
		</components.ValueContainer>
	);
};

const AsyncMultiSelect = ({
	loadOptions,
	config,
	key,
	onChangeCheckBox,
	selectedOptions,
	onShowHideList,
	styles,
	disabled,
	specialPlaceholder,
	...restProps
}: AsyncMultiSelectProps | any) => {
	const renderedPlaceholder = specialPlaceholder
		? config?.placeholder
		: config?.placeholder
		? i18nService.translate(`filter.multiselect.${config.placeholder}`)
		: '';
	const selectRef = useRef();

	return (
		<div dir='rtl'>
			<AsyncPaginate
				key={key}
				selectRef={selectRef}
				name={config.name}
				loadOptions={loadOptions}
				isClearable={true}
				isMulti
				defaultValue={restProps?.defaultValue}
				onChange={(options: any) =>
					onChangeCheckBox(options, selectRef)
				}
				isDisabled={config.disabled}
				closeMenuOnSelect={false}
				styles={customStyles({ config, ...restProps, styles })}
				placeholder={renderedPlaceholder}
				components={{
					Option: props => (
						<CheckboxOption {...props} {...restProps} />
					),
					ValueContainer: restProps.noSelection
						? ValueContainerNoChips
						: ValueContainer,
				}}
				hideSelectedOptions={false}
				loadingMessage={() => null}
				noOptionsMessage={() => null}
				additional={{
					page: 0,
					filter: {
						dep: [],
					},
				}}
				{...restProps}
				value={selectedOptions}
				debounceTimeout={700}
			/>
		</div>
	);
};

export default AsyncMultiSelect;
