import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	useWindowDimensions,
	TouchableHighlight,
	FlatList,
} from 'react-native';
import { DocumentsProps } from './Documents.component';
import { SendDocumentModal, Total } from './Documents.utils';
import FiltersDrawer from '../../components/shared/FiltersDrawer/FiltersDrawer';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import SearchInputContainer from '../../components/shared/SearchInput/SearchInput.container';
import { i18nService } from '../../services/i18n.service';
import NoResults from '../../components/shared/NoResults/NoResults';
import HeaderContainer from '../../components/shared/Header/Header.container';
import I18n from '../../components/shared/i18n/I18n.component';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import mobileRowItem from './Documents.mobileRowItem';
import { SingleConfig } from '../../components/web/Filters/Filters.component';
import { debounce } from 'lodash';
import { Portal } from 'react-native-paper';
import ModalContainer from '../../components/shared/Modal/Modal.container';
import SendingDocument from './SendingDocument/SendingDocument.container';

const Documents = ({
	navigation,
	data,
	selectedFilters,
	filtersConfig,
	filtesSelected,
	isEmployer,
	onFilter,
	onLoadMore,
	disabled,
	businessUnitsList,
	setRemoveDoc,
	total,
}: DocumentsProps) => {
	const dimentions = useWindowDimensions();
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const startFiltering = value => {
		setIsSubMenuOpen(!isSubMenuOpen);
		onFilter(value);
	};

	const onSearch = debounce(onFilter, 700);

	return (
		<FiltersDrawer
			isOpen={isSubMenuOpen}
			onToggleSubMenu={setIsSubMenuOpen}
			config={filtersConfig}
			onFilter={startFiltering}
			selectedFilters={selectedFilters}
		>
			<View>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
			</View>
			<View style={{ marginRight: 16 }}>
				<I18n weight='normal' size={28}>
					documents.title
				</I18n>
			</View>

			{isEmployer && (
				<View nativeID='#searchWrapper' style={styles.searchWrapper}>
					<SearchInputContainer
						onChange={onSearch}
						placeholder={i18nService.translate(
							'filter.multiselect.usersManagement.employeesShort',
						)}
						config={
							filtersConfig.find(
								(singleConf: SingleConfig) =>
									singleConf.type === 'searchInput',
							)!
						}
						styleConf={{
							container: { width: '100%' },
						}}
					/>
				</View>
			)}
			{isEmployer && (
				<View
					style={[
						styles.filterSortingSelectButtons,
						{ paddingHorizontal: 20 },
					]}
				>
					<SendDocumentModal
						disabled={disabled}
						isMobile={true}
						// onPress={() => navigation.navigate('sendDocument')}
						onPress={() => setShowModal(true)}
					/>
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => setIsSubMenuOpen(true)}
					>
						{!filtesSelected ? (
							<ImageIcon name='filter' width={24} height={24} />
						) : (
							<ImageIcon
								name='filterActive'
								width={24}
								height={24}
							/>
						)}
					</TouchableHighlight>
				</View>
			)}

			{data?.length > 0 ? (
				<View style={styles.dataContainer}>
					<Total total={total} isMobile={true} />
					<FlatList
						data={data}
						renderItem={({ item, index }) =>
							mobileRowItem(
								item,
								businessUnitsList,
								isEmployer,
								disabled,
								dimentions,
								setRemoveDoc,
								setShowModal,
							)
						}
						style={{
							height: isEmployer
								? dimentions.height - 250
								: dimentions.height - 100,
						}}
						contentContainerStyle={{ paddingBottom: 150 }}
						onEndReached={async () => await onLoadMore()}
						keyExtractor={item => `${item.id}`}
					/>
				</View>
			) : (
				<NoResults title='documents.table.noResult' />
			)}
			<Portal>
				<ModalContainer
					disabledKeyboard
					visible={showModal}
					component={SendingDocument}
					notDisplayModal
					{...{
						onNext: (callback: Function) => {
							setShowModal(false);
						},
						...(typeof showModal !== 'boolean' ? showModal : {}),
					}}
				/>
			</Portal>
		</FiltersDrawer>
	);
};

const styles = StyleSheet.create({
	title: {
		marginLeft: 8,
	},
	dataContainer: {
		marginTop: 10,
	},
	rowContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		backgroundColor: 'white',
		marginBottom: 16,
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingVertical: 16,
		paddingHorizontal: 20,
	},
	filterSortingSelectButtons: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: 10,
	},
	searchWrapper: {
		paddingHorizontal: 20,
		marginTop: 23,
		marginBottom: 13,
	},
	downloadSection: {
		flex: 1,
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		alignItems: 'center',
	},
});

export default React.memo(Documents);
