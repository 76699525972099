import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';

import OutlineButtonContainer from '../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Loader from '../../components/shared/Loader/Loader.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import iconsMap from '../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';

const ModalConfirmActionsCustomStyle = ({
	children,
	submitBtnText,
	cancelBtnText,
	iconName,
	onSubmit,
	onCancel,
	onNext,
	loader = false,
	separator,
	containerCustomStyle,
	buttonsCustomStyle,
	buttonsCustomStyleContainer,
	hideButtons,
}: {
	children: any;
	iconName: keyof typeof iconsMap;
	onSubmit: Function;
	onCancel: Function;
	submitBtnText: string;
	cancelBtnText: string;
	onNext: Function;
	loader: boolean;
	separator: boolean;
	containerCustomStyle: object;
	buttonsCustomStyle: object;
	buttonsCustomStyleContainer: object;
	hideButtons: boolean;
}) => {
	return (
		<View style={[styles.container, containerCustomStyle]}>
			{iconName && (
				<View style={styles.icon}>
					<ImageIcon name={iconName} width={100} height={100} />
				</View>
			)}
			<View style={styles.body}>{children}</View>
			{!hideButtons && separator && (
				<Divider
					style={{
						backgroundColor: colors.darkGrey,
						opacity: 0.2,
						marginVertical: 24,
					}}
				/>
			)}
			{!hideButtons && (
				<View
					style={[styles.actionButtons, buttonsCustomStyleContainer]}
				>
					{onSubmit && (
						<SolidButtonContainer
							overrideStyle={[
								styles.buttonOk,
								buttonsCustomStyle,
							]}
							onPress={() => onSubmit(onNext)}
						>
							<I18n
								size={16}
								style={{ minHeight: 40 }}
								weight='normal'
								color='white'
							>
								{submitBtnText}
							</I18n>
						</SolidButtonContainer>
					)}
					{onCancel && (
						<OutlineButtonContainer
							overrideStyle={[
								styles.buttonCancel,
								buttonsCustomStyle,
							]}
							onPress={() => onCancel(onNext)}
						>
							<I18n
								size={16}
								style={{ minHeight: 40 }}
								weight='normal'
								color='primary'
							>
								{cancelBtnText}
							</I18n>
						</OutlineButtonContainer>
					)}
				</View>
			)}
			{loader && <Loader />}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: 408,
		padding: 30,
		flexDirection: 'column',
		justifyContent: 'space-between',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 12,
	},
	icon: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	body: {
		flex: 1,
		zIndex: 1,
	},
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	buttonOk: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
		paddingHorizontal: 15,
		padding: 10,
	},
	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		padding: 10,
	},
});

export default ModalConfirmActionsCustomStyle;
