import { useEffect, useRef } from 'react';

// this hook its the same as react useEffect hook but its not render the component in the first render (...,[]), only by the deps in the function.

const useEffectNotInitial = (func: any, deps: any[]) => {
	const didMount = useRef(false);

	useEffect(() => {
		if (didMount.current) func();
		else didMount.current = true;
	}, deps);
};

export default useEffectNotInitial;
