import React from 'react';
import { Option } from '../../../interfaces/option.interface';
import { FullAttendanceReportList } from '../../../models/fullAttendanceReportList.model';
import { i18nService } from '../../../services/i18n.service';
import Typography from '../../../components/shared/Typography/Typography.component';
import { compact } from 'lodash';

export type FiltersDataType = Record<
	'department' | 'emp' | 'year' | 'month',
	string[] | Option[] | Record<number, string>
>;

export type AttendanceReportsFilterTypes =
	| 'department'
	| 'emp'
	| 'year'
	| 'month'
	| 'unitIds';

export type AttendanceFiltersDataType = Record<
	'department' | 'emp' | 'year' | 'month',
	string[] | Option[] | Record<number, string> | number[]
>;

export type AttendanceReportDataType = Record<
	keyof FullAttendanceReportList,
	string
>;
export type AttendanceReportRowType = Record<
	keyof Omit<FullAttendanceReportList, 'id'>,
	string
>;

export type TemplateDataType = {
	template: any;
	fileName: string;
};

export const monthsMapFilter: Record<number, string> = {
	1: 'ינואר',
	2: 'פברואר',
	3: 'מרץ',
	4: 'אפריל',
	5: 'מאי',
	6: 'יוני',
	7: 'יולי',
	8: 'אוגוסט',
	9: 'ספטמבר',
	10: 'אוקטובר',
	11: 'נובמבר',
	12: 'דצמבר',
};

export const initAttendanceListReport = {
	hasNext: false,
	total: 0,
	data: [],
};

export const initAttendanceFilters = {
	department: [],
	emp: [],
	year: [],
	month: monthsMapFilter,
};

export const initAttendanceRow = {
	id: '',
	teudatZeut: '',
	fullName: '',
	departmentName: '',
	salaryMonth: '',
};

export const attendancePageFilterSize = 20;

export const PAGINATION_PAGES_VISIBLE = 3;

export const fieldToLabelMap: AttendanceReportRowType = {
	teudatZeut: i18nService.translate('attendanceReportPage.id'),
	fullName: i18nService.translate('attendanceReportPage.workerName'),
	businessUnitName: i18nService.translate('general.businessUnits'),
	departmentName: i18nService.translate('attendanceReportPage.department'),
	salaryMonth: i18nService.translate('attendanceReportPage.salaryMonth'),
};

export const attendanceReportHeaders: any = (businessUnitsList: any) =>
	compact(
		Object.entries(fieldToLabelMap).map(([key, value]) =>
			key !== 'businessUnitName' || businessUnitsList.length > 1
				? ({
						accessor: key,
						Header: value,
						isSortable: true,
						CustomCell:
							key === 'teudatZeut'
								? ({ value, row }: any) => {
										return (
											<Typography
												color='primary'
												weight='bold'
												size={14}
											>
												{value}
											</Typography>
										);
								  }
								: null,
				  } as any)
				: undefined,
		),
	);

export const createFilterOptionsForTable = (
	filtersData: FiltersDataType,
	getEmployeesFilter: any,
	selectedDepartments: any,
	filterKey: string,
	tableState: any,
	businessUnitsList: any[],
	isBusinessUnitsMulti: boolean,
) => {
	let mnth: number = tableState?.filter?.month[0]
		? Number(tableState?.filter?.month[0])
		: 0;
	let defVal = mnth ? monthsMapFilter[mnth] : null;
	let defValYear = tableState?.filter?.year[0]
		? Number(tableState?.filter?.year[0])
		: null;
	return compact([
		{
			name: 'year',
			options: (filtersData.year as string[])?.map((val: string) => ({
				id: val,
				name: val,
			})),
			defaultValue: defValYear,
			type: 'select',
			placeholder: 'year',
			styles: { width: 114 },
		},
		{
			name: 'month',
			defaultValue: defVal,
			options: Object.entries(filtersData.month).map(([key, value]) => ({
				id: key,
				name: value,
			})),
			type: 'select',
			placeholder: 'month',
			styles: { width: 114 },
		},
		{
			name: 'emp',
			loadOptions: getEmployeesFilter,
			type: 'asyncmultiselect',
			key: `${JSON.stringify(selectedDepartments)}${
				filtersData.department
			}${JSON.stringify(tableState.filter.unitIds)}`,
			placeholder: 'employees',
			styles: { width: 276 },
		},
		{
			name: 'department',
			options: filtersData.department,
			type: 'multiselect',
			placeholder: 'departments',
			defaultLabel: i18nService.translate('general.all'),
			styles: { width: 134 },
			key: filterKey,
		},
		businessUnitsList?.length > 1
			? isBusinessUnitsMulti
				? {
						name: 'unitIds',
						type: 'multiselect',
						placeholder: 'businessUnits',
						value: tableState.filter.unitIds?.map((unit: any) =>
							businessUnitsList.find(
								(bunit: any) => bunit.id === unit,
							),
						),
						options: businessUnitsList,
						styles: {
							control: {
								width: 200,
								maxWidth: 200,
							},
						},
				  }
				: {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: tableState.filter.unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
			: undefined,
	]);
};
