export const noPaymentVacation = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap" rel="stylesheet">
    <title>חלת חרבות ברזל</title>
    <style>
        body {
            background-color: #E2EDF4;
        }
        .container {
            max-width: 900px;
            padding: 30px;
            font-family: 'Rubik';
            color: #525962;
			direction: rtl;
            margin:30px auto;
			letter-spacing: 0.25px;
            background-color: #fff;
        }
        .header {
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid rgba(82, 89, 98, 0.20);
        }
        .title-1 {
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 0.25px;
            margin: 0px;
        }

        .title-2 {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.25px;
            margin: 20px 0px 20px 20px;
        }
        .title-3 {
            font-size: 18px;
            font-weight: 400;
            margin: 20px 0 20px 0;
        }
        .body-1 {
            font-size: 14px;
            font-weight:400px;
            line-height: 24px;
        }
		.body-1.bold {
            font-weight: 700;
        }
		.instructions-list {
            padding-right: 22px;
            margin-top:0;
        }
        .content-wrapper {
            box-sizing:border-box;
            padding: 10px 113px; 
            width: 794px;
            margin:0;
            padding:0;
        }
		.content-introduction {
			border-bottom: 2px solid rgba(82, 89, 98, 0.20);
		}
		a {
            color: #3B70A4;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="header">
            <h2 class="title-1" style="line-height: normal;">הקלות לקבלת דמי אבטלה בעקבות יציאה לחל"ת</h2>
            <p class="title-3">12/11/2023</p>
        </div>
		<div class="content-introduction" >
			<p class="body-1">
				מליאת הכנסת אישרה בתאריך (8.11.2023) את מתווה ההקלות ליציאת עובדים לחל"ת.
			</p>
		</div>
		<div>
			<h3 class="title-2">מתווה ההקלות שאושר במליאת הכנסת ביום 08.11.2023 </h3>
			<ul class="instructions-list">
				<li class="body-1">הוצאת עובד לחל"ת תזכה אותו בדמי אבטלה רק אם תקופת החל"ת תהיה לפחות 14 ימים רצופים.</li>
				<li class="body-1">מי שהוצא לחל"ת לא צריך לנצל את ימי החופשה שנותרו לו אצל המעסיק, כדי לקבל דמי אבטלה. (ביטול תנאי קיזוז ימי חופשה צבורים עד לקבלת דמי האבטלה).</li>
				<li class="body-1">לא יקוזזו 5 ימי אבטלה ראשונים.</li>
				<li class="body-1">זכאות לדמי אבטלה - תקופת האכשרה המינימלית תקטן מ-12 חודשים ל-6 חודשים, מתוך 18 חודשים שקדמו ליציאה לחל"ת.</li>
				<li class="body-1">הארכה אוטומטית של ימי האבטלה למי שניצל 180% מימי הזכאות ב- 4 השנים האחרונות.</li>
				<li  class="body-1">ביטול גביית דמי ביטוח מהמעסיק בתקופת החל“ת.</li>
            </ul>
            <p class="body-1" style="margin-bottom:0">
                בכל הוצאת עובד לחל"ת:
			</p>
            <ul class="instructions-list">
				<li  class="body-1">יש לציין בתוכנה בכפתור עובדים > משרה > קוד הפסקה - <strong>3 חופשה ללא תשלום</strong></li>

				<li  class="body-1"><strong>לרשום תאריך תחילת החל"ת </strong>(שימו לב, יש לרשום בשדה זה את יום העבודה האחרון לפני היציאה לחל"ת).</li>
				<li  class="body-1"> יש לשדר טופס 100 ייעודי, ללא המתנה לדרישה מהביטוח הלאומי וליידע את העובד:
                    דוחות > דוחות אחרים > נושאים בביטוח לאומי > 6 פירוט שכר וניכויים (טופס 100).</li>
				<li  class="body-1">
                    חובה על העובד להגיש <a href="https://www.gov.il/he/multiservice/unemployment-payment-and-job-seeking#1" target="_blank">בקשה לדמי אבטלה באתר שירות התעסוקה</a>. (מועד היציאה לחל"ת שנקבע לפי שירות התעסוקה יהיה החל מהיציאה בפועל של העובד לחל״ת רטרו).
				</li>
            </ul>    
            <p class="body-1" style="margin-bottom:0">
                בחזרת העובד מחל"ת:
            </p>
            <ul class="instructions-list">
				<li class="body-1" >
                    יש לציין בתוכנה בכפתור עובדים > משרה > <strong>תאריך חזרה</strong> - את התאריך של יום החל"ת האחרון לפני החזרה לעבודה.
                </li>
				<li  class="body-1">
                    <strong>קוד הפסקה 0</strong> (תאריך היציאה יימחק).
                </li>
                <li  class="body-1">
                    לשדר טופס 100 ייעודי לביטוח לאומי: דוחות > דוחות אחרים > נושאים בביטוח לאומי > 6 פירוט שכר וניכויים (טופס 100).
                </li>
                <li class="body-1">
                    יינתן מענק מיוחד לבני 67 ומעלה שהוצאו לחל"ת (ע"י המוסד לביטוח לאומי).
                </li>
                <li class="body-1">
                    ​לא ניתן לדווח חל"ת על עובדים זרים.
                </li>
                <li class="body-1 bold" >
                    בשלב זה, המתווה נקבע לתקופה שבין 07.10.2023-30.11.2023 אך שר האוצר יכול להאריך תקופה זו.
                </li>
				<li  class="body-1 bold">מי שהוצא או יצא לחל"ת לפני 07.10.2023 תביעת דמי האבטלה שלו תהיה לפי התנאים הקודמים למתווה.</li>
                <li class="body-1 bold" >
                    אנו נמשיך לעדכן אתכם במידת הצורך וככל שיידרש.
                </li>
            </ul>
		</div>
    </div>
</body>
</html>`;
