import moment from 'moment';
import React from 'react';
import { Linking } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import httpService from '../../../services/http.service';
import { i18nService } from '../../../services/i18n.service';
import { modalService } from '../../../services/modal.service';
// import { downloadFile } from '../../../utils/downloadFile.moblie.utils';

export interface RequestData {
	deletedFiles: null;
	description: string;
	employerComment: null | string;
	files: { id: number; status: string; fileName: string }[];
	fromDate: string;
	fullName: string;
	id: number;
	lastUpdate: string;
	lastVacationData: null | {
		vacationBalanceDate: string;
		vacationBalance: number;
	};
	status: string;
	teudatZeut: string;
	toDate: string;
	type: 'SICKNESS' | 'VACATION';
}

export const getRequestData = async (requestId: number) => {
	try {
		const res: RequestData = await httpService.api({
			type: 'getManagerRequestDetails',
			params: { requestId },
		});
		return res;
	} catch { }
};

export const isVacationInThePast = (date: string) => {
	return (
		new Date().getTime() -
		new Date(moment(date, 'DD/MM/YYYY').valueOf()).getTime() >
		0
	);
};

export const onUpdateRequestStatus = async (
	ans: string,
	requestData: RequestData,
	updatedData: RequestData,
	onClose: () => void,
	onRefresh: () => void,
	isUpdated: boolean,
) => {
	const closeAndRefresh = () => {
		onClose && onClose();
		onRefresh && onRefresh();
	};
	if (ans === 'CONFIRM') {
		onAcceptRequest(
			requestData.id,
			updatedData,
			closeAndRefresh,
			isUpdated,
		);
	} else {
		onDeclineRequest(
			requestData.id,
			updatedData.employerComment,
			closeAndRefresh,
		);
	}
};

export const onAcceptRequest = async (
	requestId: number,
	data: RequestData,
	onClose: () => void,
	isUpdated: boolean,
) => {
	console.log('t');
	try {
		await httpService.api({
			type: 'approveEditRequest',
			params: { requestId },
			data: {
				fromDate: data.fromDate,
				toDate: data.toDate === data.fromDate ? null : data.toDate,
				employerComment: isUpdated
					? !!data.employerComment
						? data.employerComment
						: i18nService.translate('employeeRequests.datesUpdated')
					: null,
			},
		});
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>employeeRequests.requestApproved</I18n>
				</AlertModal>
			),
		);
		onClose && onClose();
	} catch (e) {
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>vacationRequest.updateRequestFailed</I18n>
				</AlertModal>
			),
		);
		onClose && onClose();
	}
};

export const onDeclineRequest = async (
	requestId: number,
	employerComment: string | null,
	onClose: () => void,
) => {
	try {
		const res = await httpService.api({
			type: 'rejectRequest',
			params: { requestId },
			data: {
				employerComment: !!employerComment ? employerComment : null,
			},
		});

		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>employeeRequests.requestRejected</I18n>
				</AlertModal>
			),
		);
		onClose();
	} catch (e) {
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>vacationRequest.updateRequestFailed</I18n>
				</AlertModal>
			),
		);
		onClose();
	}
};

export const downloadRequestFile = async (
	requestId: number,
	fileId: number,
	teudatZeut: string,
	dateRange: string,
	isMobile: boolean,
	fileName: string
) => {
	try {
		const res: string = await httpService.api({
			type: 'getManagerRequestFiles',
			params: { requestId, fileId },
		});

		if (isMobile) {
			const module = await import(
				'../../../utils/downloadFile.moblie.utils'
			);
			module.downloadFile(res, fileName)

		} else {
			let elm = document.createElement('a');
      elm.href = res;
      elm.click();
		}
	} catch { }
};
