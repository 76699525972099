import React, { useMemo, useState } from 'react';
import {
	Dimensions,
	TouchableOpacity,
	useWindowDimensions,
	View,
} from 'react-native';
import FiltersContainer, {
	FilterData,
} from '../../components/web/Filters/Filters.container';
import TableContainer from '../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { Breadcrumbs, Divider, Link, makeStyles } from '@material-ui/core';
import colors from '../../config/colors';
import I18n from '../../components/shared/i18n/I18n.component';
import { Report101RowType } from './Report101Page.utils';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import Report101PageMobile from './Report101Page.component.native';
import Header from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import Report101PdfView from './Report101PdfView/Report101PdfView.component';
import ActionButtonsMenu from './ActionButtonsMenu';
import Typography from '../../components/shared/Typography/Typography.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';

const useStyles = makeStyles((theme: any) => ({
	linkMain: {
		color: ({ isRowSelected }: any) =>
			isRowSelected ? colors.primary : colors.darkGrey,
		cursor: ({ isRowSelected }: any) =>
			isRowSelected ? 'pointer' : 'auto',
	},
	linkSelected: {
		color: colors.darkGrey,
	},
	separator: {
		color: '#3b70a4',
		fontFamily: 'RubikRegular',
		fontSize: '23px',
		fontWeight: 600,
	},
}));

interface Report101PageProps {
	navigation: any;
	headers: any;
	data: any[];
	reportNotFound: boolean;
	filtersData: any;
	total: number;
	onSort: (field: any, direction: any) => void;
	onLoadMore: () => void;
	onFilter: (data: FilterData) => void;
	onRowClick: (dataItem: any) => void;
	isRowSelected: boolean;
	selectedRowData?: Report101RowType | null;
	navigateBack: () => void;
	onSearchOptionSelected: (obj: {
		name: string;
		value: string | undefined;
		isMulti: false;
	}) => void;
	onSearchTextChange: (value: string) => void;
	onDisselectAllReports: () => void;
	onSelectAllReports: () => void;
	isSelectAllReportsCheckbox: boolean;
	onApproveSelectedButtonPressed: () => void;
	isChooseBtnSelected: boolean;
	setIsChooseBtnSelected: (state: boolean) => void;
	onSelectedCancelButtonPressed: () => void;
	noReports?: boolean;
	isPdfMode?: boolean;
	setIsPdfMode?: (value: boolean) => void;
	resetTablePage?: () => void;
	moveToNextEmp?: () => void;
	moveToPrevEmp?: () => void;
	exportExcel(): void;
	selectedRowIndex?: number;
	disableNext?: boolean;
}

const Report101Page = (props: Report101PageProps | any) => {
	const {
		navigation,
		data,
		reportNotFound,
		headers,
		filtersData,
		onSort,
		onLoadMore,
		onFilter,
		onRowClick,
		isRowSelected,
		selectedRowData,
		navigateBack,
		onSearchOptionSelected,
		onSearchTextChange,
		onDisselectAllReports,
		onSelectAllReports,
		isSelectAllReportsCheckbox,
		onApproveSelectedButtonPressed,
		onSelectedCancelButtonPressed,
		noReports,
		isPdfMode,
		setIsPdfMode,
		resetTablePage,
		moveToNextEmp,
		moveToPrevEmp,
		selectedRowIndex,
		disableNext,
		setActiveActionButtonType,
		activeActionButtonType,
		onSendReminderButtonHandler,
		total,
		form101ModuleActive,
		exportExcel,
		userData,
		disabled,
		...restProps
	} = props;
	const { height: windowHeight } = useWindowDimensions();

	const platform = useDevicePlatform();
	const isSmallDimensions = useMemo(
		() => window.innerWidth <= 1600,
		[window.innerWidth],
	);

	const isMobileWeb = platform === DeviceType.MOBILE_WEB;

	const classes = useStyles({ isRowSelected });

	return isMobileWeb ? (
		<Report101PageMobile
			navigation={navigation}
			total={total}
			filtersData={filtersData}
			onFilter={onFilter}
			onSearchTextChange={onSearchTextChange}
			onSearchOptionSelected={onSearchOptionSelected}
			onSelectAllCheckboxReports={onSelectAllReports}
			onDisselectAllReports={onDisselectAllReports}
			isSelectAllReportsCheckbox={isSelectAllReportsCheckbox}
			data={data}
			onRowClick={onRowClick}
			onLoadMore={onLoadMore}
			noReports={noReports}
			setActiveActionButtonType={setActiveActionButtonType}
			activeActionButtonType={activeActionButtonType}
			onApproveSelectedButtonPressed={onApproveSelectedButtonPressed}
			onSendReminderButtonHandler={onSendReminderButtonHandler}
			onSelectedCancelButtonPressed={onSelectedCancelButtonPressed}
			form101ModuleActive={form101ModuleActive}
			disabled={disabled}
			{...restProps}
		/>
	) : (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<Breadcrumbs separator='<' className={classes.separator}>
						<Link
							onClick={isRowSelected ? navigateBack : undefined}
							color='inherit'
							className={classes.linkMain}
							underline={isRowSelected ? 'always' : 'none'}
						>
							<I18n size={36} weight='normal' style={{ flex: 1 }}>
								report101ReportPage.title
							</I18n>
						</Link>
					</Breadcrumbs>
				}
				noHeader={true}
			>
				<View
					style={{
						marginBottom: 20,
						zIndex: 10001,
						flexDirection: 'row-reverse',
						justifyContent: 'space-between',
					}}
				>
					<FiltersContainer
						config={filtersData}
						onChange={onFilter}
						onSearchTextChange={onSearchTextChange}
						onSelection={id => onSearchOptionSelected(id!)}
						customStyles={{
							container: {
								display: 'flex',
								flexDirection: 'row-reverse',
							},
							filterContainer: {
								marginRight: 15,
								zIndex: 10001,
							},
						}}
						resetActiveSortByProps={[userData.id]}
					/>
					<View></View>
					<View
						style={{
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
						}}
					>
						<SolidButtonContainer
							size='medium'
							onPress={() => {
								setIsPdfMode(!isPdfMode);
							}}
							overrideStyle={{
								marginLeft: 10,
								width: 150,
								height: 40,
							}}
						>
							{`report101ReportPage.${
								isPdfMode ? 'hide' : `display`
							}PdfView`}
						</SolidButtonContainer>
						{!disabled && (
							<ActionButtonsMenu
								{...{
									activeActionButtonType,
									setActiveActionButtonType,
									onDisselectAllReports,
									onApproveSelectedButtonPressed,
									onSelectedCancelButtonPressed,
									onSendReminderButtonHandler,
									form101ModuleActive,
								}}
							/>
						)}

						<TouchableOpacity
							onPress={total > 0 ? exportExcel : undefined}
							style={{
								alignSelf: 'center',
								paddingHorizontal: 10,
							}}
						>
							<ImageIcon name='excel' />
						</TouchableOpacity>
					</View>
				</View>

				{isRowSelected && !reportNotFound && selectedRowData && (
					<View
						style={{
							zIndex: 3000,
							height: windowHeight,
						}}
					></View>
				)}

				{noReports ? (
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<I18n
							weight='bold'
							size={24}
							style={{ marginTop: 36, marginRight: 26 }}
						>
							report101ReportPage.noReports
						</I18n>
					</View>
				) : (
					<View>
						<View
							style={{
								flexDirection: 'row-reverse',
								paddingBottom: 10,
								alignSelf: 'flex-start',
							}}
						>
							<I18n size={14}>general.numberOfRecords</I18n>
							<Typography size={14} weight='normal'>
								{` ${total || ''} `}
							</Typography>
						</View>

						<View
							style={{
								height: windowHeight - 255,
								flexDirection: isPdfMode
									? 'row-reverse'
									: 'column',
							}}
						>
							<TableContainer
								keyName={''}
								headers={headers}
								data={data || []}
								count={12}
								getNextData={onLoadMore}
								onSort={onSort}
								onRowClick={onRowClick}
								hasFooter={false}
								selectedRowId={isPdfMode && selectedRowData?.id}
							/>
							{isPdfMode && selectedRowData && (
								<Report101PdfView
									onRowClick={onRowClick}
									data={selectedRowData}
									resetTablePage={resetTablePage}
									moveToNextEmp={moveToNextEmp}
									moveToPrevEmp={moveToPrevEmp}
									selectedRowIndex={selectedRowIndex}
									disableNext={disableNext}
								/>
							)}
						</View>
					</View>
				)}
			</ReportsPageWrapper>
		</View>
	);
};

export default Report101Page;
