import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Dimensions } from 'react-native';
import WebCheckBox from 'expo-checkbox';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import SearchInputContainer from '../../../../components/shared/SearchInput/SearchInput.container';
import { i18nService } from '../../../../services/i18n.service';
import { TreeComponent } from '../TreeComponent/TreeComponent';

interface Projects {
	id: string;
	name: string;
	isChecked?: boolean;
}

export interface UserData {
	projects?: string[];
	users: Users[];
}

export interface Users {
	id?: number;
	departmentAid: string;
	selected: boolean;
	users: number[];
}

export interface DepFilterData {
	id: string;
	name: string;
	users: UsersFilter[];
	isChecked?: boolean;
}

export interface UsersFilter {
	id: string;
	name: string;
	teudatZeut: string;
	employeeId: number;
	isChecked?: boolean;
}
const windowHeight = Dimensions.get('window').height;

export const EmployeeSelector = ({
	userList,
	setUserData,
	userData,
	disabled,
}: {
	userList: DepFilterData[];
	setUserData(data: UserData): void;
	userData: UserData;
	disabled: boolean;
}) => {
	return (
		<View style={styles.dataContainer}>
			<I18n size={16} style={{ zIndex: 'unset', marginBottom: 10 }}>
				locationSettings.modal.employeeDescription
			</I18n>
			<TreeComponent
				treeHeight={windowHeight - 650}
				userList={userList}
				setUserData={setUserData}
				userData={userData}
				disabled={disabled}
			/>
		</View>
	);
};

export const ProjectSelector = ({
	userList,
	projects,
	setUserData,
	userData,
	disabled,
	refreshUsersByProjects,
}: {
	userList: DepFilterData[];
	projects: Projects[];
	userData: UserData;
	disabled: boolean;
	setUserData(data: UserData): void;
}) => {
	const [isAllProjectSelected, setIsAllProjectSelected] = useState(false);
	const [projectsList, setProjectsList] = useState(projects);

	useEffect(() => {
		const temp = projects.map(pro => ({
			...pro,
			isChecked: !!userData.projects?.includes(pro?.id),
		}));
		setProjectsList(temp);
	}, [projects]);

	const onSearchProject = val => {
		if (!!val.value) {
			const newSearch = projectsList.filter(pro =>
				pro.name.includes(val.value),
			);
			setProjectsList(newSearch);
		} else {
			const temp = projects.map(pro => ({
				...pro,
				isChecked: !!userData.projects?.includes(pro?.id),
			}));
			setProjectsList(temp);
		}
	};

	const onSelectProject = (item: Projects, index: number, val: boolean) => {
		const temp = [...projectsList];
		temp[index] = { ...item, isChecked: val };
		setUserData({
			projects: temp.filter(poj => !!poj.isChecked).map(poj => poj.id),
		});

		setProjectsList(temp);
		if (!val && isAllProjectSelected) {
			setIsAllProjectSelected(false);
		}
	};

	const onAllSelect = (val: boolean) => {
		setIsAllProjectSelected(val);
		const temp = projectsList.map(v => ({ ...v, isChecked: val }));
		setUserData({ projects: val ? projectsList.map(v => v.id) : [] });

		setProjectsList(temp);
	};

	return (
		<View style={styles.dataContainer}>
			<I18n size={16} style={{ zIndex: 'unset', marginBottom: 10 }}>
				locationSettings.modal.projectsDescription
			</I18n>
			<View style={{ flexDirection: 'row-reverse' }}>
				<View style={{ width: '40%' }}>
					<SearchInputContainer
						placeholder={i18nService.translate(
							'filter.multiselect.searchProject',
						)}
						config={{ name: 'search', type: 'searchInput' }}
						onChange={onSearchProject}
						styleConf={{ container: { marginBottom: 10 } }}
					/>
					<FlatList
						data={projectsList}
						ListHeaderComponent={() => (
							<View style={styles.allRow}>
								<WebCheckBox
									value={isAllProjectSelected}
									onValueChange={val => onAllSelect(val)}
									color={colors.lightPrimary}
									style={styles.checkBox}
									disabled={disabled}
								/>
								<I18n size={16} style={{ zIndex: 'unset' }}>
									general.all
								</I18n>
							</View>
						)}
						renderItem={({ item, index }) => (
							<View
								style={[
									styles.dataRowHeader,
									{ marginRight: 20 },
								]}
							>
								<WebCheckBox
									value={item?.isChecked}
									onValueChange={val =>
										onSelectProject(item, index, val)
									}
									color={colors.lightPrimary}
									style={styles.checkBox}
									disabled={disabled}
								/>
								<I18n size={16} style={{ zIndex: 'unset' }}>
									{item.name}
								</I18n>
							</View>
						)}
						style={{
							overflow: 'hidden',
							height: windowHeight - 650,
							borderWidth: 1,
							borderColor: '#e7e7e8',
							marginLeft: 10,
						}}
						contentContainerStyle={{ paddingBottom: 20 }}
						keyExtractor={item => `${item.id}`}
					/>
				</View>
				<View style={{ width: '60%' }}>
					<TreeComponent
						treeHeight={windowHeight - 650}
						userList={userList}
						userData={userData}
						setUserData={setUserData}
						disabled={disabled}
						refreshUsersByProjects={refreshUsersByProjects}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	dataContainer: {
		zIndex: 'unset',
		width: '100%',
	},
	allRow: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		marginHorizontal: 20,
		marginBottom: 5,
	},
	checkBox: {
		width: 15,
		height: 15,
		borderRadius: 4,
		margin: 12,
	},
	text: {
		paddingRight: 8,
	},
	dataRowContainer: {
		...getShadowStyle(5, 'rgb(59, 112, 164)'),
		marginHorizontal: 20,
		marginVertical: 3,
		borderRadius: 8,
		overflow: 'hidden',
		backgroundColor: colors.white,
	},
	dataButton: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: colors.white,
	},
	dataRowHeader: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 40,
	},
});
