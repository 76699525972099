import { useNavigation, useRoute } from '@react-navigation/core';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n from '../../components/shared/i18n/I18n.component';
import { usePrevious } from '../../hooks/usePrevious.hook';
import ModalConfirmActions from '../../modals/ModalConfirmActions/ModalConfirmActions.component';
import { modalService } from '../../services/modal.service';
import {
	selectEmployer,
	getEmployersList,
} from '../../store/actions/login.actions';
import {
	getEmployer,
	getEmployersData,
	getSetWorkerCardAlert,
	getUserData,
} from '../../store/selectors/login.selectors';
import EmployerListComponent from './EmployerList.component';

interface IEmoloyeeList {
	title?: string;
	containerStyle?: any;
	onEmployerSelected?: (employee: any) => void;
	onNext: any;
	navigation: any;
	route: any;
}

const EmployeeList = ({
	title,
	containerStyle,
	onEmployerSelected,
	onNext,
	navigation,
	route,
}: IEmoloyeeList) => {
	const dispatch = useDispatch();
	const employerData = useSelector(getEmployer);
	const userData = useSelector(getUserData);
	const setWorkerCardAlert = useSelector(getSetWorkerCardAlert);
	const prevUserData = usePrevious(userData);
	const employer =
		typeof employerData === 'string'
			? JSON.parse(employerData)
			: employerData;
	const employerListData = useSelector(getEmployersData);

	useEffect(() => {
		!employerListData?.length && dispatch(getEmployersList());
	}, [employerListData]);

	useEffect(() => {
		if (prevUserData && !isEqual(prevUserData, userData)) {
			route?.name === 'form101' &&
				route.params?.screen !== '101table' &&
				navigation?.reset({
					index: 0,
					routes: [{ name: 'portal' }],
				});

			route?.name === 'reports101' &&
				navigation?.reset({
					index: 0,
					routes: [{ name: 'employer' }],
				});
			onNext && onNext();
		}
	}, [userData]);

	const onSelectEmployerHandler = async (employer: {
		id: string;
		name: string;
	}) => {
		if (setWorkerCardAlert) {
			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						onEmployerSelected && onEmployerSelected(employer);
						onNext && onNext();
						callback();
					},
					onSubmit: async (callback: Function) => {
						await dispatch(selectEmployer(employer));
						onEmployerSelected && onEmployerSelected(employer);
						//onNext && onNext();
						// navigation?.reset({
						// 	index: 0,
						// 	routes: [{ name: 'portal' }],
						// });
						callback();
					},
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},
				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>workerCard.editor.changeEmployerAlert</I18n>
					</ModalConfirmActions>
				),
			);
		} else {
			await dispatch(selectEmployer(employer));
			onEmployerSelected && onEmployerSelected(employer);
			// route?.name === 'form101' &&
			// 	navigation?.reset({
			// 		index: 0,
			// 		routes: [{ name: 'portal' }],
			// 	});

			// route?.name === 'reports101' &&
			// 	navigation?.reset({
			// 		index: 0,
			// 		routes: [{ name: 'employer' }],
			// 	});
		}
	};

	return (
		<>
			{employerListData?.length ? (
				<EmployerListComponent
					title={title}
					containerStyle={containerStyle}
					selectedEmployer={employer}
					employers={employerListData}
					onPress={onSelectEmployerHandler}
				/>
			) : null}
		</>
	);
};

export default EmployeeList;
