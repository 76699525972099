import React from 'react';
import {
	Dimensions,
	View,
	StyleSheet,
	TouchableHighlight,
	TouchableOpacity,
	useWindowDimensions,
} from 'react-native';
import colors from '../../../config/colors';
import I18n from '../../../components/shared/i18n/I18n.component';
import Header from '../../../components/shared/Header/Header.container';
import WebHeader from '../../../components/shared/Header/WebHeader.component';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import { Columns } from './EmployeeHoursReport.utils';
import getShadowStyle from '../../../config/shadow';
import Typography from '../../../components/shared/Typography/Typography.component';
import { i18nService } from '../../../services/i18n.service';
import Filters from '../../../components/web/Filters/Filters.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
export interface Props {
	worklogReportFormat: any;
	navigation: any;
	data: any[];
	onSort?: any;
	onLoadMore: any;
	totalData: any;
	filtersConfig: any;
	isSelectMode: boolean;
	selectedRows: any[];
	isAllSelected: boolean;
	canEdit: boolean;
	onSelectAll: (cheacked: boolean) => {};
	onSelect: () => {};
	onConfirmSelect: () => {};
	onCancelSelect: () => {};
	reqForSelect: () => {};
	onFilter: () => {};
	onDelete: (id: any) => {};
	onEdit: (id: any) => {};
	addReport: () => void;
}

const EmployeeHoursReportSummary = ({
	data,
	totalData,
	navigation,
	onSort,
	onLoadMore,
	filtersConfig,
	canEdit,
	addReport,
	onFilter,
	onEdit,
	onDelete,
	worklogReportFormat,
}: Props) => {
	const { height: windowHeight } = useWindowDimensions();

	return (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<View
						style={{
							flexDirection: 'row-reverse',
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<I18n size={36} weight='normal'>
							employeeHoursReport.employee.title
						</I18n>

						<View style={{ flexDirection: 'row-reverse' }}>
							{canEdit && (
								<TouchableHighlight
									style={styles.fabButton}
									underlayColor='transparent'
									onPress={addReport}
								>
									<ImageIcon
										name={'add'}
										width={40}
										height={40}
									></ImageIcon>
								</TouchableHighlight>
							)}
						</View>
					</View>
				}
				noHeader={true}
			>
				<View
					style={{
						flexDirection: 'row-reverse',
						width: '100%',
						justifyContent: 'space-between',
						zIndex: 100,
					}}
				>
					<View style={{ flexDirection: 'row-reverse' }}>
						<Filters
							config={filtersConfig}
							onChange={onFilter}
							customStyles={{
								container: {
									display: 'flex',
									flexDirection: 'row-reverse',
									justifyContent: 'flex-end',
									alignSelf: 'center',
								},
								filterContainer: {
									marginLeft: 10,
									marginVertical: 10,
									zIndex: 10001,
								},
							}}
						/>
					</View>
				</View>

				<View
					style={{
						maxHeight: windowHeight - 300,
					}}
				>
					<TableContainer
						keyName={''}
						headers={Columns(
							totalData ? totalData.total : null,
							onDelete,
							onEdit,
							canEdit,
							worklogReportFormat,
						)}
						data={data || []}
						getNextData={onLoadMore}
						onSort={onSort}
						hasFooter={true}
						selectedRowId={0}
					/>
				</View>
			</ReportsPageWrapper>
		</View>
	);
};

const styles = StyleSheet.create({
	newRequestButton: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
		marginTop: 10,
		marginLeft: 10,
	},
	fabButton: {
		borderRadius: 30,
		width: 40,
		height: 40,
		marginTop: 10,
		marginLeft: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	nextEmpButton: {
		marginRight: 10,
		marginTop: 10,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		paddingHorizontal: 5,
		backgroundColor: colors.lightPrimary,
		borderRadius: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default EmployeeHoursReportSummary;
