import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import HomePageWeb from './HomePageWeb.component';
import HomePageMobileWeb from './HomePageMobileWeb.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { HomePageReportContentElType } from './HomePage.container';
export interface HomePageProps {
	openDrawer: () => void;
	showProjectList: boolean;
	navigation: any;
	setShowProjectList?: (val: boolean) => void;
	isSummaryOnly: boolean;
	isPaycheckBlockShown: boolean;
	reportDateYear: string;
	reportContentData: HomePageReportContentElType;
	report101warning: boolean;
	form101Id: string | null
}
const HomePageContainer = ({
	navigation,
	openDrawer,
	showProjectList,
	setShowProjectList,
	isSummaryOnly,
	isPaycheckBlockShown,
	reportDateYear,
	reportContentData,
	report101warning,
	form101Id
}: HomePageProps) => {
	const devicePlatformRef = useRef<DeviceType>(useDevicePlatform());

	return (
		<>
			{devicePlatformRef.current === DeviceType.MOBILE_WEB ? (
				<HomePageMobileWeb
					navigation={navigation}
					openDrawer={openDrawer}
					showProjectList={showProjectList}
					setShowProjectList={setShowProjectList}
					isSummaryOnly={isSummaryOnly}
					isPaycheckBlockShown={isPaycheckBlockShown}
					reportDateYear={reportDateYear}
					reportContentData={reportContentData}
					report101warning={report101warning}
					form101Id={form101Id}
				/>
			) : (
				<HomePageWeb
					navigation={navigation}
					openDrawer={openDrawer}
					showProjectList={showProjectList}
					setShowProjectList={setShowProjectList}
					isSummaryOnly={isSummaryOnly}
					isPaycheckBlockShown={isPaycheckBlockShown}
					reportDateYear={reportDateYear}
					reportContentData={reportContentData}
					report101warning={report101warning}
					form101Id={form101Id}
				/>
			)}
		</>
	);
};

export default HomePageContainer;
