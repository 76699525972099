import React, { useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { i18nService } from '../../../../services/i18n.service';
import colors from '../../../../config/colors';

const Pie = ({ title, data, colorsObj, navigate, customKeys }: any) => {
	const computedData = useMemo(
		() =>
			data?.map((item: any) => ({
				id: i18nService.translate(`${title}.${item.key}`),
				label: i18nService.translate(`${title}.${item.key}`),
				value: item.val,
				color: colorsObj[item.key],
				key: customKeys ? customKeys[item.key] : item.key,
			})),
		[data, colorsObj, customKeys],
	);

	const getPadAngle = (data: any[]): number => {
		return data.filter((item: any) => item.value !== 0)?.length === 1
			? 0
			: 0.7;
	};

	return (
		<ResponsivePie
			onClick={datum => {
				navigate({ status: [datum.data?.key] });
			}}
			theme={{
				fontFamily: 'RubikBold',
				fontSize: 14,
				tooltip: {
					container: {
						fontFamily: 'RubikRegular',
						fontSize: 14,
					},
				},
				legends: {
					text: {
						fontSize: 14,
						color: colors.darkGrey,
						fontFamily: 'RubikRegular',
					},
				},
			}}
			height={220}
			colors={datum => {
				return datum.data?.color;
			}}
			data={computedData || []}
			margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
			innerRadius={0.4}
			padAngle={computedData ? getPadAngle(computedData) : 0}
			cornerRadius={3}
			activeOuterRadiusOffset={8}
			borderWidth={0}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 0.2]],
			}}
			enableArcLinkLabels={false}
			arcLabelsSkipAngle={10}
			legends={[
				{
					anchor: 'top-left',
					direction: 'column',
					justify: false,
					translateX: 0,
					translateY: 56,
					itemsSpacing: 6,
					itemWidth: 100,
					itemHeight: 24,
					itemDirection: 'left-to-right',
					itemOpacity: 1,
					symbolSize: 19,
					symbolShape: ({ x, y, size, fill }) => (
						<rect
							x={x}
							y={y}
							fill={fill}
							rx='5'
							width={size}
							height={size}
							style={{
								pointerEvents: 'none',
							}}
						/>
					),
				},
			]}
		/>
	);
};

export default Pie;
