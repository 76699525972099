import { Platform } from '@unimodules/react-native-adapter';
import React from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	TouchableHighlight,
	Dimensions,
} from 'react-native';
import { useSelector } from 'react-redux';
import colors from '../../../config/colors';
import { setRepEnterCustomerMode } from '../../../store/actions/repEnterMode.actions';
import { getUserData } from '../../../store/selectors/login.selectors';
import {
	getEnterModeName,
	getIsEnterMode,
} from '../../../store/selectors/repEnterMode.selector';
import { dispatch } from '../../../store/store';
import EmployerName from '../EmployerName/EmployerName';
import I18n from '../i18n/I18n.component';
import ImageIcon from '../Icon/ImageIcon.component';
import Typography from '../Typography/Typography.component';

const MobileHeader = ({ navigation }) => {
	const userData = useSelector(getUserData);
	const isRepEnterMode = useSelector(getIsEnterMode);
	const repEnterModeName = useSelector(getEnterModeName);

	return (
		<>
			<View
				style={[
					{
						justifyContent: 'space-between',
						alignItems: 'center',
						flexDirection: 'row-reverse',
					},
					isRepEnterMode ? { flex: 1 } : {},
				]}
			>
				{isRepEnterMode && (
					<View
						style={{
							flexDirection: 'row-reverse',
							justifyContent: 'center',
							flex: 1,
						}}
					>
						<View style={styles.centerSide}>
							<I18n
								size={14}
								weight='normal'
								color='white'
								style={{ marginBottom: 3 }}
							>
								enterCustomer.details
							</I18n>
							<View
								style={{
									flexDirection: 'row-reverse',
									alignItems: 'center',
								}}
							>
								<Typography
									size={18}
									weight='normal'
									color='white'
									numberOfLines={1}
								>
									{repEnterModeName}
								</Typography>
								<Typography
									style={{ marginRight: 4 }}
									size={14}
									weight='400'
									color='white'
								>
									{isRepEnterMode}
								</Typography>
							</View>
						</View>
					</View>
				)}
				{isRepEnterMode && (
					<TouchableHighlight
						style={{ padding: 15, paddingLeft: 0 }}
						underlayColor='transparent'
						onPress={() => dispatch(setRepEnterCustomerMode({}))}
					>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row-reverse',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<ImageIcon
								name={'enterCustomer'}
								width={20}
								height={20}
								style={{
									transform: [{ rotateY: '180deg' }],
								}}
							/>
						</View>
					</TouchableHighlight>
				)}
				{!isRepEnterMode && (
					<View
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							flex: 1,
							justifyContent: 'flex-end',
							marginRight: 15,
						}}
					>
						<TouchableOpacity
							style={styles.userInfo}
							disabled={!!isRepEnterMode}
							onPress={() =>
								navigation.navigate('personalInformation')
							}
						>
							<Typography size={14} weight='400' color='darkGrey'>
								!
							</Typography>
							<Typography
								style={{
									marginRight: 5,
									maxWidth: 75,
								}}
								size={14}
								weight='normal'
								color='darkGrey'
								numberOfLines={1}
							>
								{`${userData?.first_name} ${userData?.last_name}`}
							</Typography>
							<I18n
								size={14}
								style={{ marginRight: 5 }}
								weight='400'
								color='darkGrey'
							>
								general.hi
							</I18n>
							<ImageIcon
								name={'profileDefault'}
								width={12}
								height={12}
							/>
						</TouchableOpacity>
						<EmployerName />
					</View>
				)}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	userInfo: {
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: 15,
		maxWidth: Platform.OS === 'web' ? '47%' : '40%',
	},
	centerSide: {
		backgroundColor: colors.warning,
		height: 55,
		paddingHorizontal: 35,
		borderRadius: 10,
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: Dimensions.get('window').width - 120,
	},
});

export default MobileHeader;
