import React, { useEffect, useRef, useState } from 'react';
import SignIn from '../SignIn/SignIn.component';
import { getLoginData } from '../../store/selectors/login.selectors';
import {
	login,
	logout,
	resetLoginError,
} from '../../store/actions/login.actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import I18n from '../../components/shared/i18n/I18n.component';
import { View } from 'react-native';
import { i18nService } from '../../services/i18n.service';

export interface UserCredentials {
	login: string;
	password: string;
}

interface SignInContainerProps {
	navigation: any;
}

const SignInContainer = ({ navigation }: SignInContainerProps) => {
	const dispatch = useDispatch();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [shouldShowPassword, setShouldShowPassword] = useState(false);
	const lastClipboardText = useRef<string>('');

	const { error, errorMessage } = useSelector(getLoginData);

	useEffect(() => {
		error && dispatch(resetLoginError());
	}, [username, password]);

	const signInHandler = (data: UserCredentials) => {
		dispatch(login(data));
	};

	const hasErrorLoginPassword =
		error &&
		(errorMessage !== 'OUTDATED_APP_VERSION' ||
			errorMessage === 'NO_PERMISSIONS');

	return (
		<>
			{/* message NO_PERMISSIONS will be provided when the feature_flas array is empty */}
			{error && errorMessage === 'NO_PERMISSIONS' ? (
				<View
					style={{
						height: '100%',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<AlertModal
						submitBtnText={i18nService.translate(
							'general.understood',
						)}
						children={<I18n>error.noPermissions</I18n>}
						iconName='attention'
						onSubmit={() => dispatch(logout())}
					/>
				</View>
			) : (
				<SignIn
					navigation={navigation}
					errorMessage={errorMessage}
					username={username}
					password={password}
					hasErrorLoginPassword={hasErrorLoginPassword}
					shouldShowPassword={shouldShowPassword}
					setUsername={setUsername}
					setPassword={setPassword}
					signIn={signInHandler}
					lastClipboardText={lastClipboardText}
					toggleShowPassword={() =>
						setShouldShowPassword(state => !state)
					}
				/>
			)}
		</>
	);
};

export default SignInContainer;
