import React, { useEffect } from 'react';
import {
	Dimensions,
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	TouchableHighlight,
	View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import colors from '../../../config/colors';
import I18n from '../../../components/shared/i18n/I18n.component';
import CreateNewRequest from './CreateNewRequest';
import EditRequest from './EditRequest';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import moment from 'moment';

interface ModalProps {
	navigation: any;
	requestId?: string;
	onClose(): void;
}

const windowHeight = Dimensions.get('window').height;

const RequestMobileWrapper = ({ navigation, route }: ModalProps) => {
	const requestId = route?.params?.requestId || null;
	const validationDate =
		route?.params?.validationDate ||
		moment().subtract(1, 'months').startOf('month').valueOf();

	const platform = useDevicePlatform();
	const isMobileWeb = platform === DeviceType.MOBILE_WEB;

	useEffect(() => {
		onMount();
	}, []);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('RequestMobileWrapper-employee');
			await crashlytics().setAttributes({
				requestId: `${requestId}` || 'newRequest',
			});
		}
	};

	const onGoBack = () => {
		if (isMobileWeb) {
			navigation.navigate('applications');
		} else {
			navigation.goBack();
		}
	};

	return (
		<View style={styles.backdrop}>
			<View style={styles.buttonContainer}>
				<TouchableHighlight
					underlayColor='transparent'
					onPress={onGoBack}
				>
					<I18n size={14} weight={'normal'} color='white'>
						general.close
					</I18n>
				</TouchableHighlight>
			</View>
			<KeyboardAwareScrollView
				contentContainerStyle={styles.container}
				style={{
					height: windowHeight - 76,
					backgroundColor: colors.white,
				}}
				extraHeight={100}
				showsVerticalScrollIndicator={false}
			>
				{!!requestId ? (
					<EditRequest
						requestId={requestId}
						onNext={onGoBack}
						validationDate={validationDate}
					/>
				) : (
					<CreateNewRequest
						onNext={onGoBack}
						validationDate={validationDate}
					/>
				)}
			</KeyboardAwareScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	backdrop: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		flex: 1,
	},
	container: {
		paddingVertical: 30,
		paddingHorizontal: 20,
		width: '100%',
	},
	buttonContainer: {
		height: 38,
		width: 140,
		backgroundColor: colors.lightPrimary,
		alignItems: 'center',
		justifyContent: 'center',
		alignSelf: 'center',
		borderTopRightRadius: 98,
		borderTopLeftRadius: 98,
		marginTop: 38,
	},
});

export default RequestMobileWrapper;
