import React, { useEffect, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	ScrollView,
	Dimensions,
	TouchableOpacity,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import getShadowStyle from '../../../config/shadow';
import httpService from '../../../services/http.service';
import {
	NotificationModal,
	onDownload,
	RenderRow,
} from './UpdateMultiplePermisionsModal.utils';
import { modalService } from '../../../services/modal.service';
import AlertModal from '../../AlertModal/AlertModal.component';

const windowHeight = Dimensions.get('window').height;

const UpdateMultiplePermisionsModal = ({
	onNext,
	selectAll,
	selectedItems,
	selectedFilters,
	refreshPage,
}: any) => {
	const [roles, setRoles] = useState([]);
	const [checkedRoles, setCheckedRoles] = useState({});
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		getRolePermissions();
	}, []);

	const getRolePermissions = async () => {
		const res: any = await httpService.api({
			type: 'getRolePermissions',
			params: { id: 6 },
			query: { unitId: selectedFilters?.unitIds.join(',') },
		});
		if (res) {
			setRoles(res);
		}
	};

	const sortPermisions = () => {
		setShowError(false);
		let isError = false;

		const added = [];
		const removed = [];
		Object.keys(checkedRoles).forEach(roleId => {
			if (checkedRoles[roleId] === 'ADD') {
				added.push(parseInt(roleId));
			} else if (checkedRoles[roleId] === 'REMOVE') {
				removed.push(parseInt(roleId));
			} else {
				setShowError(true);
				isError = true;
			}
		});

		return { added, removed, isError };
	};

	const onSendPressed = async () => {
		const { added, removed, isError } = sortPermisions();

		if (!isError) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<NotificationModal
						{...props}
						onAccept={onNext => onNext(onUpdate(added, removed))}
					/>
				),
			);
		}
	};

	const onUpdate = async (added, removed) => {
		try {
			const res = await httpService.api({
				type: 'updateMultiplePermisions',
				data: {
					f: selectedFilters,
					profileIds: selectAll ? [] : selectedItems,
					addPermissionIds: added,
					removePermissionIds: removed,
				},
			});
			if (!!res?.jobId) {
				modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
					},
					(props: any) => (
						<AlertModal
							{...props}
							onSubmit={next => next(onNext(refreshPage()))}
						>
							<View style={{ alignItems: 'flex-end' }}>
								<I18n>updateUserermisions.requestLoading</I18n>
							</View>
						</AlertModal>
					),
				);
			} else {
				const textToShow =
					res?.totalFailed === 0 && res?.totalSucess > 0
						? 'updateUserermisions.UPDATE_ROLE_SUCCESS'
						: res?.totalSucess === 0 && res?.totalFailed > 0
						? 'updateUserermisions.UPDATE_ROLE_FAILED'
						: 'updateUserermisions.UPDATE_ROLE_PARTIAL';

				modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
					},
					(props: any) => (
						<AlertModal
							{...props}
							onSubmit={next => next(onNext(refreshPage()))}
						>
							<View style={{ alignItems: 'flex-end' }}>
								<I18n
									users={res?.totalElements}
									usersSucces={res?.totalSucess || ''}
									usersFailed={res.totalFailed || ''}
								>
									{textToShow}
								</I18n>
								<TouchableOpacity
									onPress={() => onDownload(res?.result)}
									style={{ marginTop: 5 }}
								>
									<I18n
										size={16}
										weight='normal'
										color='primary'
									>
										updateUserermisions.details
									</I18n>
								</TouchableOpacity>
							</View>
						</AlertModal>
					),
				);
			}
		} catch (e) {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
				},
				(props: any) => (
					<AlertModal {...props} onSubmit={next => next(onNext())}>
						<View style={{ alignItems: 'flex-end' }}>
							<I18n>updateUserermisions.requestFailed</I18n>
						</View>
					</AlertModal>
				),
			);
		}
	};

	return (
		<View
			style={[
				styles.container,
				{ height: windowHeight > 670 ? 670 : windowHeight - 50 },
			]}
		>
			<I18n size={30}>updateUserermisions.title</I18n>
			<View style={styles.divider} />

			<I18n size={14}>updateUserermisions.description</I18n>
			<ScrollView
				style={{ zIndex: -1, marginTop: 20, paddingHorizontal: 20 }}
				centerContent={true}
				nativeID='#LocationListContainer'
			>
				{roles.map(item => (
					<RenderRow
						data={item}
						setCheckedRoles={setCheckedRoles}
						checkedRoles={checkedRoles}
						showError={showError}
					/>
				))}
			</ScrollView>
			<View style={styles.divider} />

			<View style={styles.buttonContainer}>
				<TouchableHighlight
					onPress={onSendPressed}
					underlayColor='transparent'
					disabled={Object.keys(checkedRoles).length === 0}
				>
					<View
						style={[
							styles.button,
							Object.keys(checkedRoles).length === 0 && {
								opacity: 0.5,
							},
						]}
					>
						<I18n size={16} weight='normal' color='white'>
							general.update
						</I18n>
					</View>
				</TouchableHighlight>

				<TouchableHighlight
					onPress={onNext}
					underlayColor='transparent'
				>
					<View style={styles.cancelButton}>
						<I18n size={16} weight='normal' color='lightPrimary'>
							general.cancel
						</I18n>
					</View>
				</TouchableHighlight>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: 450,
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		paddingVertical: 39,
		paddingHorizontal: 50,
		alignItems: 'center',
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
		width: '100%',
	},
	cancelButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: 'white',
		borderRadius: 45,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	divider: {
		height: 1,
		width: 374,
		backgroundColor: colors.grey,
		marginVertical: 17,
		opacity: 0.2,
	},
});

export default UpdateMultiplePermisionsModal;
