import * as clockReportActions from '../constants/clockReportActions';
import {WorkLogsState , StoreAction} from '../../interfaces/redux.interface';
import moment from 'moment';

const initialState: WorkLogsState = {
	todayWorkLogs:{
		activeWorkLog:null,
		data :[]
	},
	chosenDate: moment().format("YYYY-MM-DD"),
    openChosenDateModal:false,
    reFetchedFlag:false,
};

const ClockReportReducer = (
	state: typeof initialState = initialState,
	action: StoreAction<WorkLogsState> | any,
): WorkLogsState => {
	switch (action.type) {
		case clockReportActions.SET_DATE:
            return {...state , chosenDate:action.payload.chosenDate}

        case clockReportActions.SET_TODAY_WORKLOGS:
            const tempTodayData = {
                activeWorkLog:action.payload.activeWorkLog? {
                    ...action.payload.activeWorkLog
                }:null,
                data:action.payload.data.map( (log:any) => ({...log}))
            }
            return {...state , todayWorkLogs:tempTodayData}

        case clockReportActions.SET_OPEN_CHOSEN_DATE_MODAL:
                return {...state ,openChosenDateModal:action.payload.openChosenDateModal}
        case clockReportActions.TOGGLE_REFETCH_FLAG:
            return {...state , reFetchedFlag:!state.reFetchedFlag}
		default:
			return {...state};
	}
};

export default ClockReportReducer;
