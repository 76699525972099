import React, { useState, useEffect, useMemo } from 'react';
import {
	View,
	Platform,
	StyleSheet,
	ScrollView,
	TextInput,
	TouchableOpacity,
	Dimensions,
	useWindowDimensions,
} from 'react-native';
import ImageIcon from '../../Icon/ImageIcon.component';
import I18n from '../../i18n/I18n.component';
import OutlineButtonContainer from '../../Buttons/OutlineButton.container';
import SolidButtonContainer from '../../Buttons/SolidButton.container';
import colors from '../../../../config/colors';
import { Switch } from 'react-native-paper';
interface Option {
	name: string;
	id: string;
	lastReported?: boolean;
}
interface Props {
	onSelect?: Function;
	onSubmit?: Function;
	onCancel?: Function;
	isModal: boolean;
	options: Option[];
	isEventSelect: boolean;
	defaultOption: Option;
	canReportFullDay?: boolean;
	onNext?: Function;
	closed?: Function;
	currentRunnigWorkLog: any
}

const EventsAndProjectsSelectComponent = ({
	isModal,
	onCancel,
	onSubmit,
	options,
	isEventSelect,
	defaultOption,
	onNext,
	closed,
	canReportFullDay,
	currentRunnigWorkLog
}: Props) => {
	useEffect(() => {
		return () => {
			if (isModal) {
				closed?.();
			}
		};
	}, []);
	const { height, width } = Dimensions.get('window');
	const [selectedOption, setSelectedOption] = useState(defaultOption);
	const [searchPattern, setSearchPattern] = useState('');
	const [selectedIndex, setSelectedIndex] = useState<any>(null);
	const [isFullDaySelected, setIsFullDaySelected] = useState(
		canReportFullDay,
	);
	
	const optionsOnView = useMemo(() => {
		const reOrderedOptions = options?.filter(option =>
			option.name.includes(searchPattern),
		);
		reOrderedOptions.map((option: Option, index: number) => {
			if (option.id === defaultOption.id) setSelectedIndex(index);
		});
		return reOrderedOptions;
	}, [searchPattern]);

	const CreateOption = ({
		option,
		index,
	}: {
		option: Option;
		index: number;
	}) => {
		return (
			<View
				style={{
					flexDirection: 'row-reverse',
					backgroundColor:
						index === selectedIndex ? '#edf4f7' : 'transperent',
				}}
			>
				<TouchableOpacity
					style={{
						flex: 1,
						paddingHorizontal: 20,
						paddingVertical: 10,
						height: canReportFullDay && isEventSelect && index === selectedIndex ? 'auto' : 40,
						width: '100%',
						alignItems: 'flex-end',

						flexDirection: 'row-reverse',

						...Platform.select({
							web: {
								':hover': {
									backgroundColor: '#edf4f7',
									cursor: 'pointer',
								},
							},
						}),
					}}
					underlayColor='transparent'
					onPress={() => {
						setSelectedIndex(index);
						setSelectedOption(option);
					}}
				>
					<I18n size={16} weight='400'>
						{option.name}
					</I18n>
				</TouchableOpacity>
				{canReportFullDay && isEventSelect && index === selectedIndex && (
					<View
						style={{
							margin: 10,
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<I18n
							size={12}
							weight={'bold'}
							style={{ marginHorizontal: 5 }}
						>
							modal.report.fullDay
						</I18n>
						<Switch
							color={colors.lightPrimary}
							onValueChange={() => {
								setIsFullDaySelected(prev => !prev);
							}}
							value={isFullDaySelected}
						/>
					</View>
				)}
			</View>
		);
	};

	return isModal ? (
		<ScrollView
			showsVerticalScrollIndicator
			style={{
				shadowColor: 'rgba(0, 0, 0, 0)',
				shadowOpacity: 0.1,
				shadowOffset: { width: 0, height: 2 },
				backgroundColor: colors.white,
				maxHeight: height - 150,
				borderRadius: 20,
			}}
		>
			<View
				style={{
					width: width - 100,
					height: useWindowDimensions().height - 150,
					paddingVertical: 39,
					paddingHorizontal: 20,
				}}
			>
				<I18n weight='bold' size={30} style={{ marginVertical: 4 }}>
					{isEventSelect ? 'general.events' : 'general.projects'}
				</I18n>
				<View style={styles.Line} />
				<View style={styles.search}>
					<ImageIcon
						name='search'
						style={{
							height: 25,
							width: 25,
							alignSelf: 'center',
							marginLeft: 5,
						}}
					/>
					<TextInput
						style={{
							width: '100%',
							borderColor: 'transparent',
							textAlign: 'right',
							direction: 'rtl',
							...Platform.select({
								web: {
									outlineWidth: 0,
								},
							}),
						}}
						onChangeText={pattern => {
							setSearchPattern(pattern);
						}}
					/>
				</View>
				<ScrollView
					style={{ flex: 1, width: '100%', marginVertical: 10 }}
				>
					{optionsOnView.map((option: Option, index) => (
						<CreateOption
							key={option.id}
							option={option}
							index={index}
						/>
					))}
				</ScrollView>
				<View style={styles.actionButtons}>
					<SolidButtonContainer
						disabled={
							selectedOption.id === currentRunnigWorkLog?.eventId ||
							selectedOption.id === currentRunnigWorkLog?.projectId
						}
						overrideStyle={{
							minWidth: 92,
							height: 40,
							justifyContent: 'center',
							paddingHorizontal: 15,
							padding: 10,
							backgroundColor: (selectedOption.id === currentRunnigWorkLog?.eventId ||
								selectedOption.id === currentRunnigWorkLog?.projectId) ?
								'rgb(202, 202, 202)' : colors.lightPrimary
						}}
						onPress={() =>
							onSubmit?.(
								onNext,
								selectedOption,
								isEventSelect,
								isFullDaySelected,
							)
						}
					>
						<I18n
							size={16}
							style={{ minHeight: 40 }}
							weight='normal'
							color='white'
						>
							general.enterence
						</I18n>
					</SolidButtonContainer>
					<OutlineButtonContainer
						overrideStyle={styles.buttonCancel}
						onPress={() => onCancel?.(onNext)}
					>
						<I18n
							size={16}
							style={{ minHeight: 40 }}
							weight='normal'
							color='primary'
						>
							general.cancel
						</I18n>
					</OutlineButtonContainer>
				</View>
			</View>
		</ScrollView>
	) : (
		<View
			nativeID='selectWebContainer'
			style={{
				zIndex: 1222,
				position: 'absolute',
				height: 293,
				width: 400,
				backgroundColor: colors.white,
				...Platform.select({
					web: {
						boxShadow: '0 10px 30px -7px rgba(59, 112, 164, 0.5)',
						backgroundColor: '#fff',
					},
				}),
				borderRadius: 8,
				top: '100%',
				right: 0,
				left: 0,
				paddingVertical: 10,
				justifyContent: 'center',
			}}
		>
			<View style={styles.search}>
				<ImageIcon
					name='search'
					style={{
						height: 25,
						width: 25,
						alignSelf: 'center',
						marginLeft: 5,
					}}
				/>
				<TextInput
					style={{
						width: '100%',
						borderColor: 'transparent',
						textAlign: 'right',
						direction: 'rtl',
						...Platform.select({
							web: {
								outlineWidth: 0,
							},
						}),
					}}
					onChangeText={pattern => {
						setSearchPattern(pattern);
					}}
				/>
			</View>
			<ScrollView style={styles.optionsHolder}>
				{optionsOnView.map((option: Option, index) => (
					<CreateOption
						key={option.id}
						index={index}
						option={option}
					/>
				))}
			</ScrollView>
			<View
				style={{
					flexDirection: 'row',
					alignSelf: 'flex-end',
					justifyContent: 'space-between',
					width: '55%',
					paddingHorizontal: 10,
					marginTop: 5,
				}}
			>
				<OutlineButtonContainer
					overrideStyle={styles.buttonCancel}
					onPress={() => onCancel?.()}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='primary'
					>
						general.cancel
					</I18n>
				</OutlineButtonContainer>
				<SolidButtonContainer
					disabled={
						selectedOption.id === currentRunnigWorkLog?.eventId ||
						selectedOption.id === currentRunnigWorkLog?.projectId
					}
					overrideStyle={{
						minWidth: 92,
						height: 40,
						justifyContent: 'center',
						paddingHorizontal: 15,
						padding: 10,
						backgroundColor: (selectedOption.id === currentRunnigWorkLog?.eventId ||
							selectedOption.id === currentRunnigWorkLog?.projectId) ?
							'rgb(202, 202, 202)' : colors.lightPrimary,
					}}
					onPress={() =>
						onSubmit?.(
							selectedOption,
							isEventSelect,
							isFullDaySelected,
						)
					}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='white'
					>
						general.enterence
					</I18n>
				</SolidButtonContainer>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	search: {
		backgroundColor: '#edf4f7',
		width: '95%',
		height: 40,
		alignSelf: 'center',
		borderRadius: 5,
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		paddingHorizontal: 10,
	},
	optionsHolder: {
		flex: 1,
		maxHeight: 180,
		marginVertical: 4,
	},
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		marginTop: 10,
	},

	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		paddingHorizontal: 15,
		padding: 10,
	},
	Line: {
		opacity: 0.2,
		width: '100%',
		height: 0,
		borderTopColor: '#525962',
		borderTopWidth: 2,
		marginBottom: 10,
	},
});

export default EventsAndProjectsSelectComponent;
