import React, { useState } from 'react';
import { Platform } from 'react-native';
import { Divider, Menu } from 'react-native-paper';
import colors from '../../config/colors';
import { i18nService } from '../../services/i18n.service';
import { SelectReportsButtonMobileContainer } from './Report101Page.utils';

const actionsButton = [
	{
		title: 'report101ReportPage.cancelReports',
		type: 'cancelReports',
	},
	{
		title: 'report101ReportPage.approveReports',
		type: 'approveReports',
	},
	{
		title: 'reportView.sendReminder',
		type: 'sendReminder',
	},
];

const ActionButtonsMenu = ({
	activeActionButtonType,
	setActiveActionButtonType,
	onDisselectAllReports,
	onApproveSelectedButtonPressed,
	onSelectedCancelButtonPressed,
	onSendReminderButtonHandler,
	form101ModuleActive,
	stylesConf = {},
}: any) => {
	const [isSelectedButtonsMenuOpen, setIsSelectedButtonsMenuOpen] =
		useState<boolean>(false);

	return (
		<Menu
			visible={isSelectedButtonsMenuOpen}
			contentStyle={{
				marginTop: Platform.OS === 'web' ? 45 : 0,
				borderRadius: 25,
				marginLeft: 5,
				backgroundColor: colors.white,
				width: 180,
			}}
			onDismiss={() => setIsSelectedButtonsMenuOpen(false)}
			anchor={
				<SelectReportsButtonMobileContainer
					activeActionButtonType={activeActionButtonType}
					setActiveActionButtonType={setActiveActionButtonType}
					onDisselectAll={onDisselectAllReports}
					form101ModuleActive={form101ModuleActive}
					onApproveAllSelectedButtonPressed={
						onApproveSelectedButtonPressed
					}
					onSelectedCancelButtonPressed={
						onSelectedCancelButtonPressed
					}
					onPressButton={() =>
						setIsSelectedButtonsMenuOpen(!isSelectedButtonsMenuOpen)
					}
					onSendReminderButtonHandler={onSendReminderButtonHandler}
					stylesConf={{
						containerIncludesOneBtn: {
							width: 178,
							marginLeft: 10,
						},
						buttonApprove: { marginLeft: 10 },
						...stylesConf,
					}}
				/>
			}
		>
			{actionsButton.map(
				(
					action: {
						title: string;
						type: string;
					},
					index: number,
				) => (
					<>
						<Menu.Item
							onPress={() => {
								setActiveActionButtonType(action.type);
								setIsSelectedButtonsMenuOpen(false);
							}}
							style={{
								alignItems: 'center',
								...Platform.select({
									web: {
										cursor: 'pointer',
									},
								}),
							}}
							titleStyle={{
								color: colors.darkGrey,
							}}
							title={i18nService.translate(action.title)}
						/>
						{index !== actionsButton.length - 1 && (
							<Divider
								style={{
									backgroundColor: colors.darkGrey,
									opacity: 0.2,
									marginHorizontal: 5,
								}}
							/>
						)}
					</>
				),
			)}
		</Menu>
	);
};

export default ActionButtonsMenu;
