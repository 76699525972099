import React, { useEffect, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	FlatList,
	ScrollView,
} from 'react-native';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import { makeStyles } from '@material-ui/core';
import WebCheckBox from 'expo-checkbox';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import SearchInputContainer from '../../../../components/shared/SearchInput/SearchInput.container';
import { i18nService } from '../../../../services/i18n.service';
import httpService from '../../../../services/http.service';
import {
	DepFilterData,
	UserData,
	UsersFilter,
} from '../LocationSettingsModal/LocationSettingsModal.utils';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() => ({
	chip: {
		borderRadius: 2,
		color: 'hsl(0, 0%, 20%)',
		fontSize: '85%',
		padding: 3,
		paddingLeft: 6,
		margin: 2,
		backgroundColor: 'hsl(0, 0%, 90%)',
	},
	checkbox: {
		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
		},
		'&.Mui-disabled': {
			pointerEvents: 'auto',
			color: colors.grey,
		},
	},
	checked: {},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: ({ isSelected }: any) =>
			isSelected ? colors.lightBlue : 'transparent',

		'&:hover': {
			cursor: 'pointer',
			backgroundColor: colors.lightBlue,
		},
	},
	box: {
		display: 'flex',
		alignItems: 'center',
	},
	statusWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	arrow: {
		color: colors.primary,
		marginRight: 5,
	},
}));

interface Props {
	userList: DepFilterData[];
	userData: UserData;
	setUserData(data: any): void;
	disabled?: boolean;
	treeHeight?: number;
	refreshUsersByProjects: boolean;
}

interface TreeDropDownProp {
	departmentData: {
		name: string;
		id: string;
		isChecked?: boolean;
		users: UsersFilter[];
	};
	departmentIndex: number;
	disabled?: boolean;
	setOpenDropDown(val: string[]): void;
	openDropDown: string[];
	onUpdateDepartment(
		departmentData: DepFilterData,
		departmentIndex: number,
		isChecked: boolean,
	): void;
	onUpdateUser(
		val: boolean,
		user: UsersFilter,
		userIndex: number,
		departmentData: DepFilterData,
		departmentIndex: number,
	): void;
}

export const TreeComponent = ({
	userList,
	userData,
	setUserData,
	treeHeight,
	disabled,
	refreshUsersByProjects,
}: Props) => {
	const { getValues } = useFormContext();
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [listState, setListState] = useState(userList);
	const [openDepartment, setOpenDepartment] = useState<string[]>([]);

	useEffect(() => {
		arrangeDepartmentUserList(userList);
	}, [userList, refreshUsersByProjects]);

	const getUsers = async (searchText: any) => {
		try {
			const res: DepFilterData[] = await httpService.api<any>({
				type: 'getUsersByDepartments',
				query: {
					pageName: 'locationSettings',
					unitId: getValues().unitId,
				},
				data: {
					search: searchText.value,
					f: { dep: [], internal: ['true'] },
				},
			});
			if (res) {
				arrangeDepartmentUserList(res);
				if (searchText.value && searchText.value !== '' && res.length) {
					setOpenDepartment(res.map(dep => dep?.id));
				}
			}
		} catch (e) {}
	};

	const arrangeDepartmentUserList = (list: DepFilterData[]) => {
		const openDepArr = [];
		const newList = list.map(item => {
			const isDepartmentExist = userData?.users?.find(
				user => user.departmentAid === item.id,
			);
			let isDepChecked = false;
			let newUsers = [];
			if (!!isDepartmentExist) {
				isDepChecked = isDepartmentExist.selected;
				newUsers =
					item?.users?.map(user => {
						const isUserExist =
							!!isDepartmentExist?.users?.includes(
								user.employeeId,
							);
						return {
							...user,
							isChecked: isDepartmentExist.selected
								? !isUserExist
								: isUserExist,
						};
					}) || [];
				openDepArr.push(item.id);
			} else {
				newUsers =
					item?.users?.map(user => ({ ...user, isChecked: false })) ||
					[];
			}
			return { ...item, isChecked: isDepChecked, users: newUsers };
		});
		openDepArr.length && setOpenDepartment(openDepArr);
		setListState(newList);
	};

	const onDepartmentRowPress = (
		departmentData: DepFilterData,
		departmentIndex: number,
		isChecked: boolean,
	) => {
		const temp = [...listState];
		let newUserData = { ...userData };
		temp[departmentIndex] = {
			...departmentData,
			isChecked,
			users: departmentData.users.map(user => ({
				...user,
				isChecked: isChecked,
			})),
		};
		setListState(temp);

		const findDepartmentIndex = userData?.users?.findIndex(
			user => user.departmentAid === departmentData.id,
		);
		if (findDepartmentIndex > -1) {
			if (isChecked) {
				newUserData.users[findDepartmentIndex] = {
					...newUserData.users[findDepartmentIndex],
					selected: isChecked,
					users: [],
				};
			} else {
				newUserData.users.splice(findDepartmentIndex, 1);
			}
		} else {
			newUserData.users.push({
				departmentAid: departmentData.id,
				selected: isChecked,
				users: [],
			});
		}
		setUserData(newUserData);

		if (!isChecked && isAllSelected) {
			setIsAllSelected(false);
		}
	};

	const onUpdateUser = (
		val: boolean,
		user: UsersFilter,
		userIndex: number,
		departmentData: DepFilterData,
		departmentIndex: number,
	) => {
		const temp = [...listState];
		let newUsers = [...departmentData?.users] || [];
		newUsers[userIndex] = { ...user, isChecked: val };
		temp[departmentIndex] = { ...departmentData, users: newUsers };

		// for the api structure
		let newUserData = { ...userData };

		const findDepartmentIndex = newUserData?.users?.findIndex(
			user => user.departmentAid === departmentData.id,
		);
		if (findDepartmentIndex > -1) {
			let usersForApi = [
				...(newUserData?.users[findDepartmentIndex]?.users || []),
			];
			if (newUserData.users[findDepartmentIndex].selected) {
				usersForApi = val
					? usersForApi.filter(use => use != user.employeeId)
					: [...usersForApi, user.employeeId];
				newUserData.users[findDepartmentIndex] = {
					...newUserData.users[findDepartmentIndex],
					users: usersForApi,
				};
			} else {
				usersForApi = val
					? [...usersForApi, user.employeeId]
					: usersForApi.filter(use => use != user.employeeId);
				if (usersForApi.length) {
					newUserData.users[findDepartmentIndex] = {
						...newUserData.users[findDepartmentIndex],
						users: usersForApi,
					};
				} else {
					newUserData.users.splice(findDepartmentIndex, 1);
				}
			}
		} else {
			newUserData.users.push({
				departmentAid: departmentData.id,
				selected: false,
				users: [user.employeeId],
			});
		}
		const departmentIsEmpty =
			listState?.find(
				(dep: any) =>
					dep.id ===
					newUserData?.users[findDepartmentIndex]?.departmentAid,
			)?.users.length ===
				newUserData?.users[findDepartmentIndex]?.users?.length && !val
				? true
				: false;

		if (
			departmentIsEmpty &&
			newUserData.users[findDepartmentIndex]?.selected
		) {
			newUserData.users[findDepartmentIndex].selected = false;
			temp[departmentIndex].isChecked = false;
		}

		setUserData({ ...newUserData });
		setListState(temp);
	};

	const onAllSelect = (isChecked: boolean) => {
		setIsAllSelected(isChecked);
		const newList = listState.map(item => ({
			...item,
			isChecked: isChecked,
			users: item.users.map(user => ({ ...user, isChecked: isChecked })),
		}));
		setListState(newList);

		const newUserData = isChecked
			? listState.map(item => ({
					departmentAid: item.id,
					selected: isChecked,
					users: [],
			  }))
			: [];
		setUserData({ users: newUserData });
	};

	return (
		<View>
			<SearchInputContainer
				placeholder={i18nService.translate(
					'filter.multiselect.usersManagement.employees',
				)}
				config={{ name: 'search', type: 'searchInput' }}
				onChange={getUsers}
				styleConf={{ container: { marginBottom: 10 } }}
			/>
			<FlatList
				data={listState}
				ListHeaderComponent={() => (
					<View style={styles.allRow}>
						<WebCheckBox
							value={isAllSelected}
							onValueChange={val => onAllSelect(val)}
							color={colors.lightPrimary}
							style={styles.checkBox}
							disabled={disabled}
						/>
						<I18n size={16} style={{ zIndex: 'unset' }}>
							general.all
						</I18n>
					</View>
				)}
				renderItem={({ item, index }) => (
					<View key={item.id}>
						<TreeDropDown
							departmentData={item}
							departmentIndex={index}
							onUpdateDepartment={onDepartmentRowPress}
							disabled={disabled}
							onUpdateUser={onUpdateUser}
							openDropDown={openDepartment}
							setOpenDropDown={setOpenDepartment}
						/>
					</View>
				)}
				style={{
					overflow: 'scroll',
					borderWidth: 1,
					height: treeHeight || 250,
					borderColor: '#e7e7e8',
					minHeight: 250,
				}}
				contentContainerStyle={{ paddingBottom: 15 }}
				keyExtractor={item => `${item.id}`}
			/>
		</View>
	);
};

const TreeDropDown = ({
	departmentData,
	departmentIndex,
	onUpdateDepartment,
	onUpdateUser,
	disabled,
	setOpenDropDown,
	openDropDown,
}: TreeDropDownProp) => {
	const [dropdownHeight, setDropdownHeight] = useState(37);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const [isDepartmentChecked, setIsDepartmentChecked] = useState(
		departmentData?.isChecked,
	);
	const [usersList, setUsersList] = useState(departmentData.users);
	const [isNotAllUsers, setIsNotAllUsers] = useState(false);

	useEffect(() => {
		if (!!departmentData.id && !openDropDown.includes(departmentData.id)) {
			setDropdownHeight(37);
			setIsDropdownOpen(false);
		} else if (
			!!departmentData.id &&
			openDropDown.includes(departmentData.id)
		) {
			setIsDropdownOpen(true);
			setDropdownHeight(
				departmentData?.users?.length * 40 + 40 > 170
					? 170
					: departmentData?.users?.length * 40 + 40,
			);
		}
	}, [openDropDown, departmentData?.users?.length]);

	useEffect(() => {
		setIsDepartmentChecked(departmentData?.isChecked);
		setUsersList(departmentData?.users);
	}, [departmentData]);

	useEffect(() => {
		const isAll = usersList.filter(user => !user.isChecked);
		setIsNotAllUsers(!!isAll.length);
	}, [usersList]);

	const onRowPress = () => {
		setDropdownHeight(
			isDropdownOpen
				? 37
				: departmentData?.users?.length * 40 + 40 > 170
				? 170
				: departmentData?.users?.length * 40 + 40,
		);
		setIsDropdownOpen(!isDropdownOpen);
		setOpenDropDown(
			!isDropdownOpen
				? [...openDropDown, departmentData.id]
				: openDropDown.filter(
						(dep: string) => dep !== departmentData.id,
				  ),
		);
	};

	const onCheckDepartment = (val: boolean) => {
		setIsDepartmentChecked(val);
		setDropdownHeight(
			val
				? departmentData?.users?.length * 40 + 40 > 170
					? 170
					: departmentData?.users?.length * 40 + 40
				: 40,
		);
		setIsDropdownOpen(val);
		setOpenDropDown(
			val
				? [...openDropDown, departmentData.id]
				: openDropDown.filter(
						(dep: any) => dep.id !== departmentData.id,
				  ),
		);
		onUpdateDepartment(departmentData, departmentIndex, val);
	};

	const onCheckUser = (
		val: boolean,
		user: UsersFilter,
		userIndex: number,
	) => {
		onUpdateUser(val, user, userIndex, departmentData, departmentIndex);
	};

	const classes = useStyles({ isSelected: isDropdownOpen });

	return (
		<View
			key={departmentData.id}
			style={[styles.dataRowContainer, { height: dropdownHeight }]}
		>
			<TouchableOpacity onPress={onRowPress}>
				<div className={classes.wrapper}>
					<View
						style={{
							flexDirection: 'row-reverse',
							alignItems: 'center',
						}}
					>
						{isDepartmentChecked && isNotAllUsers && (
							<I18n size={10} color='warning'>
								editUserModal.rolesTable.partial
							</I18n>
						)}
						{isDropdownOpen ? (
							<KeyboardArrowDownRoundedIcon
								classes={{ root: classes.arrow }}
								fontSize='small'
							/>
						) : (
							<KeyboardArrowLeftRoundedIcon
								classes={{ root: classes.arrow }}
								fontSize='small'
							/>
						)}
					</View>
					<View style={styles.dataRowHeader}>
						<WebCheckBox
							value={isDepartmentChecked}
							onValueChange={onCheckDepartment}
							color={colors.lightPrimary}
							style={styles.checkBox}
							disabled={disabled}
						/>
						<I18n size={16} style={{ zIndex: 'unset' }}>
							{departmentData?.name}
						</I18n>
					</View>
				</div>
			</TouchableOpacity>
			<ScrollView>
				{usersList.map((item: UsersFilter, index: number) => (
					<View
						key={item?.id}
						style={[
							styles.dataRowHeader,
							{
								borderWidth: 1,
								borderColor: '#e7e7e8',
								paddingRight: 20,
							},
						]}
					>
						<WebCheckBox
							value={item?.isChecked}
							onValueChange={val => onCheckUser(val, item, index)}
							color={colors.lightPrimary}
							style={styles.checkBox}
							disabled={disabled}
						/>
						<I18n size={16} style={{ zIndex: 'unset' }}>
							{`${item?.name} (${item?.teudatZeut})`}
						</I18n>
					</View>
				))}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	dataContainer: {
		zIndex: 'unset',
		width: '100%',
	},
	allRow: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		marginBottom: 5,
	},
	checkBox: {
		width: 15,
		height: 15,
		borderRadius: 4,
		margin: 12,
	},
	text: {
		paddingRight: 8,
	},
	dataRowContainer: {
		marginVertical: 3,
		overflow: 'hidden',
	},
	dataRowHeader: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 40,
	},
});
