import React, { useEffect, useMemo, useRef } from 'react';
import {
	View,
	StyleSheet,
	Dimensions,
	Platform,
	TouchableOpacity,
} from 'react-native';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import Select from '../../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { useWatch } from 'react-hook-form';
import { adminRoles, repRoles } from '../createUpdateUser.utils';

interface RoleRowProps {
	disabled?: boolean;
	onSelectRole: (id: number, prevId: number) => void;
	role: any;
	hasDepError: boolean;
	roles: any;
	canDelete: boolean;
	openModal: (
		id: number,
		type: 'departments' | 'permissions' | 'users' | 'customers',
		isAdmin?: boolean,
	) => void;
	onDeleteRole: any;
	departmentsLength?: number;
	permissions?: any;
	getPermissions: any;
	showCustomersButton?: boolean;
	disableSelectEmployeeRole?: boolean;
	getRepCustomers: Function;
	customersLength: number;
}

const RoleRow = ({
	role,
	disabled,
	roles,
	openModal,
	onSelectRole,
	onDeleteRole,
	canDelete,
	departmentsLength,
	permissions,
	getPermissions,
	showCustomersButton,
	disableSelectEmployeeRole,
	customersLength,
	getRepCustomers,
}: RoleRowProps) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const sendRolesPermissionsRef = useRef<number[]>([]);
	const watchEmployeeType = useWatch({
		name: 'employee_type',
	});
	const isNotDisabledPermissions = useMemo(
		() => permissions?.filter((per: any) => !per.disable),
		[permissions],
	);

	useEffect(() => {
		if (
			(!permissions || !permissions.length) &&
			!roles?.user_role_permission?.[role]?.length
		) {
			if (sendRolesPermissionsRef.current.includes(role)) {
				return;
			}
			sendRolesPermissionsRef.current.push(role);
			getPermissions(role);
		}
	}, [permissions]);

	const hasAdminRole = useMemo(
		() =>
			!!roles.user_roles?.find((role: number) =>
				adminRoles.includes(role),
			),
		[roles.user_roles],
	);

	const hasRepRole = useMemo(
		() =>
			!!roles.user_roles?.find((role: number) => repRoles.includes(role)),
		[roles.user_roles],
	);

	const rolesOptions = useMemo(
		() =>
			roles.roles.filter(
				(rol: any) =>
					(role === rol.id || !roles.user_roles.includes(rol.id)) &&
					(hasAdminRole && !adminRoles.includes(role)
						? !adminRoles.includes(rol.id)
						: true) &&
					(hasRepRole && !repRoles.includes(role)
						? !repRoles.includes(rol.id)
						: true) &&
					((watchEmployeeType === 'EXTERNAL' ||
						disableSelectEmployeeRole) &&
					!roles.user_roles?.includes(6)
						? rol.id !== 6
						: true),
			),
		[
			roles.user_roles,
			roles.roles,
			role,
			watchEmployeeType,
			hasAdminRole,
			hasRepRole,
			disableSelectEmployeeRole,
		],
	);
	const selectedRoleData = useMemo(
		() => roles.roles.find((rol: any) => rol.id === role),
		[roles.roles, role],
	);

	const hasDepError = useMemo(
		() =>
			!roles?.user_role_department?.[role]?.length &&
			roles.roles?.find((el: any) => el.id === role)?.allocate_department,
		[roles?.user_role_department, roles.roles, role],
	);

	const enableDepartments = useMemo(
		() =>
			roles.roles?.find((el: any) => el.id === role)?.allocate_department,
		[roles.roles, role],
	);

	const enableCustomerManagement = useMemo(() => {
		const result = roles.roles?.find((el: any) => el.id === role)?.id;
		return result === 7 ? 'admin' : result === 9 ? 'user' : false;
	}, [roles.roles, role]);

	useEffect(() => {
		if (
			enableCustomerManagement &&
			showCustomersButton &&
			!customersLength
		) {
			getRepCustomers();
		}
	}, [enableCustomerManagement, showCustomersButton]);

	return (
		<>
			<View
				style={StyleSheet.flatten([
					styles.buttonStyle,
					{
						width: isMobile
							? Dimensions.get('window')?.width - 40
							: 'unset',
						backgroundColor: colors.white,
						padding: 13,
						marginBottom: isMobile ? 15 : 0,
						alignItems: 'flex-end',
						zIndex:
							Platform.OS === 'android' || Platform.OS === 'ios'
								? 10
								: 'unset',
						paddingBottom: isMobile
							? enableDepartments || enableCustomerManagement
								? 20
								: 23
							: 11,
						paddingRight: isMobile ? 13 : 0,
						marginRight: !isMobile && canDelete ? -30 : 0,
						borderWidth: isMobile ? 1 : 0,
						flexDirection: isMobile ? 'column' : 'row',
					},
				])}
			>
				{canDelete && !disabled && (
					<View
						style={{
							flex: isMobile ? 1 : 'unset',
							width: isMobile ? '100%' : 'unset',
							marginLeft: isMobile ? 0 : 10,
							paddingBottom: isMobile ? 0 : 10,
						}}
					>
						<TouchableOpacity
							onPress={() => onDeleteRole(role)}
							style={{ width: 30 }}
						>
							<ImageIcon
								name='deleteCircle'
								width={22}
								height={22}
							/>
						</TouchableOpacity>
					</View>
				)}
				<View
					style={{
						flexDirection: isMobile ? 'row-reverse' : 'row',
						flexWrap: 'wrap',
						alignItems: 'flex-end',
						zIndex:
							Platform.OS === 'android' || Platform.OS === 'ios'
								? 10
								: 'unset',
						width: isMobile ? '100%' : 'unset',
					}}
				>
					<View
						style={{
							width: isMobile
								? enableDepartments || enableCustomerManagement
									? Dimensions.get('screen').width - 70
									: 183
								: 227,
							maxWidth: '100%',
							zIndex:
								Platform.OS === 'android' ||
								Platform.OS === 'ios'
									? 10
									: 'unset',
							alignItems: isMobile ? 'flex-end' : 'flex-start',
							marginBottom:
								(enableDepartments ||
									enableCustomerManagement) &&
								isMobile
									? 21
									: 0,
							marginLeft:
								(enableDepartments ||
									enableCustomerManagement) &&
								isMobile
									? 0
									: 10,
						}}
					>
						<View
							style={{
								flexDirection: isMobile ? 'row-reverse' : 'row',
								marginBottom: 7,
								alignItems: 'center',
							}}
						>
							<I18n
								size={12}
								style={{
									textAlign: 'right',
									height: 'auto',
								}}
								weight='normal'
							>
								general.role
							</I18n>
							<Typography
								style={{ marginLeft: 2, textAlign: 'right' }}
								size={14}
								color='red'
							>
								*
							</Typography>
						</View>
						<Select
							isSearchable={false}
							disabled={disabled}
							noPlaceholder
							width={
								isMobile
									? enableDepartments ||
									  enableCustomerManagement
										? Dimensions.get('screen').width - 70
										: 183
									: 227
							}
							noPlaceholder
							options={rolesOptions}
							onChange={(value: any) => onSelectRole(value, role)}
							value={role}
							captureMenuScroll={false}
							maxMenuHeight={145}
						/>
					</View>
					<TouchableOpacity
						onPress={() => openModal(role, 'permissions')}
						style={[
							styles.actionButtons,
							{
								paddingHorizontal:
									enableDepartments ||
									enableCustomerManagement
										? 20
										: 13,
								flexDirection: isMobile ? 'row-reverse' : 'row',
							},
						]}
					>
						<I18n
							color='white'
							weight='normal'
							size={14}
						>{`editUserModal.permissions`}</I18n>
						{
							<I18n
								style={{ marginRight: 2 }}
								color='white'
								weight='normal'
								size={14}
							>
								(
								{roles?.user_role_permission?.[role]?.length ||
									isNotDisabledPermissions?.length}
								/{selectedRoleData?.permission_count})
							</I18n>
						}
					</TouchableOpacity>
					{enableDepartments && (
						<>
							<View style={{ width: 6 }} />
							<TouchableOpacity
								onPress={() => openModal(role, 'departments')}
								style={[
									styles.actionButtons,
									{
										paddingHorizontal: 20,
										flexDirection: isMobile
											? 'row-reverse'
											: 'row',
									},
								]}
							>
								<I18n color='white' weight='normal' size={14}>
									general.departments
								</I18n>
								{roles?.user_role_department?.[role]?.length ? (
									<I18n
										style={{ marginRight: 2 }}
										color='white'
										weight='normal'
										size={14}
									>
										(
										{
											roles?.user_role_department[role]
												?.length
										}
										/{departmentsLength})
									</I18n>
								) : null}
								{hasDepError && (
									<ImageIcon
										width={14}
										height={14}
										style={{ marginRight: 3 }}
										name='errorIndicaror'
									/>
								)}
							</TouchableOpacity>
						</>
					)}
					{enableCustomerManagement && showCustomersButton && (
						<>
							<View style={{ width: 6 }} />
							<TouchableOpacity
								onPress={() =>
									openModal(
										role,
										'customers',
										enableCustomerManagement === 'admin'
											? true
											: false,
									)
								}
								style={[
									styles.actionButtons,
									{
										paddingHorizontal: 30,
										backgroundColor: colors.lightPrimary,
										flexDirection: isMobile
											? 'row-reverse'
											: 'row',
									},
								]}
							>
								<I18n color='white' weight='normal' size={14}>
									editUserModal.userDetails.customerManagement
								</I18n>
								{roles?.user_role_rep_customer_info?.[role]
									?.user_role_rep_customer?.length ||
								roles?.user_role_rep_customer_info?.[role]
									?.all_customer ? (
									<I18n
										style={{ marginRight: 2 }}
										color='white'
										weight='normal'
										size={14}
									>
										(
										{roles?.user_role_rep_customer_info?.[
											role
										]?.user_role_rep_customer?.length ||
											customersLength}
										/{customersLength})
									</I18n>
								) : null}
							</TouchableOpacity>
						</>
					)}
				</View>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	root: {
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	centeredText: {
		textAlign: 'center',
	},
	buttonStyle: {
		backgroundColor: colors.white,
		//minHeight: 45,
		alignItems: 'center',
		textAlign: 'center',
		paddingHorizontal: 13,
		borderRadius: 10,
		borderWidth: 1,
		borderStyle: 'dashed',
		borderColor: colors.generalSeparator,
	},
	containerStyle: {
		width: 150,
	},
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		minWidth: 103,
		paddingHorizontal: 13,
		backgroundColor: colors.primary,
		borderRadius: 35,
		minHeight: 45,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default RoleRow;
