import React, { useState } from 'react';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import {
	isVacationInThePast,
	onUpdateRequestStatus,
	RequestData,
} from '../RequestDetails.utils';
import { StatusOptions } from '../../../ApplicationsToEmployer/ApplicationsToEmployer.utils';
import getShadowStyle from '../../../../config/shadow';
import { Privileges } from '../../../../constants/roleTypes.constant';
import moment from 'moment';
import { hasPermissionOnBusinessUnit } from '../../../../utils/globals.utils';
import routes from '../../../../config/routes';
import useBusinessUnitsState from '../../../../hooks/useBusinessUnitsState';

interface RequestProps {
	requestData: RequestData;
	validationDate: number;
	onClose: any;
	onRefresh(): void;
	businessUnitId?: number;
	businessUnitName?: string;
}

const VacationRequest = ({
	requestData,
	onClose,
	onRefresh,
	validationDate,
	businessUnitId,
	businessUnitName,
}: RequestProps) => {
	const [from, setFrom] = useState(requestData.fromDate);
	const [to, setTo] = useState(
		requestData?.toDate ? requestData.toDate : requestData.fromDate,
	);
	const [updated, setUpdated] = useState(false);

	const { businessUnitsList } = useBusinessUnitsState({
		privileges: routes.emploeeRequests.privileges,
		noRoute: true,
	});

	const canEdit =
		new Date(validationDate).getTime() -
			new Date(
				moment(requestData.fromDate, 'DD/MM/YYYY').valueOf(),
			).getTime() >
		0;

	const formMethods = useForm({
		defaultValues: {
			...requestData,
			toDate: requestData.toDate
				? requestData.toDate
				: requestData.fromDate,
		},
		mode: 'onBlur',
	});

	const onResponse = (ans: 'CONFIRM' | 'DECLINE') => {
		const formValues: any = formMethods.getValues();
		onUpdateRequestStatus(
			ans,
			requestData,
			formValues,
			onClose,
			onRefresh,
			updated,
		);
	};

	const onFormUpdate = (field: string, val: string) => {
		let isUpdated = false;
		if (field === 'fromDate') {
			setFrom(val);
			isUpdated = !!val && requestData && val !== requestData?.fromDate;
		}
		if (field === 'toDate') {
			setTo(val);
			isUpdated =
				!!val &&
				(requestData && requestData?.toDate
					? val !== requestData.toDate
					: val !== requestData.fromDate);
		}
		setUpdated(isUpdated);
	};

	return (
		<FormProvider {...formMethods}>
			<View style={styles.title}>
				<I18n size={24} weight='normal' color='darkGrey'>
					employeeRequests.vacationRequestTitle
				</I18n>
			</View>

			{businessUnitsList.length > 1 ? (
				<Typography size={14} weight='normal'>
					<I18n size={14} weight='normal' color='darkGrey'>
						general.businessUnits
					</I18n>
					{`:  ${businessUnitName}`}
				</Typography>
			) : undefined}
			<Typography size={14} weight='normal' style={{ marginTop: 5 }}>
				{`${requestData?.fullName} ${requestData?.teudatZeut}`}
			</Typography>

			<View style={styles.vacationDates}>
				<View style={{ width: 140 }}>
					<FieldBuilder
						styles={{ wrapper: { margin: 0 } }}
						type={!canEdit ? 'datepicker' : undefined}
						key={'fromDate'}
						name={'fromDate'}
						maxDate={
							to
								? new Date(moment(to, 'DD/MM/YYYY').valueOf())
								: ''
						}
						minDate={new Date(validationDate)}
						label={() => (
							<I18n size={12} weight='normal'>
								vacationRequest.fromDate
							</I18n>
						)}
						placeholder={'general.datePickerPlaceholder'}
						onChange={(val, a, b) => {
							onFormUpdate(
								'fromDate',
								typeof b === 'string' ? b : b?.target?.value,
							);
						}}
						editable={!canEdit}
					/>
				</View>
				<View style={{ width: 140 }}>
					<FieldBuilder
						styles={{ wrapper: { margin: 0 } }}
						type={!canEdit ? 'datepicker' : undefined}
						key={'toDate'}
						name={'toDate'}
						minDate={
							from
								? new Date(moment(from, 'DD/MM/YYYY').valueOf())
								: new Date(validationDate)
						}
						label={() => (
							<I18n size={12} weight='normal'>
								vacationRequest.toDate
							</I18n>
						)}
						placeholder={'general.datePickerPlaceholder'}
						onChange={(val, a, b) => {
							onFormUpdate(
								'toDate',
								typeof b === 'string' ? b : b?.target?.value,
							);
						}}
						editable={!canEdit}
					/>
				</View>
			</View>
			{!isVacationInThePast(requestData.fromDate) &&
				(requestData.lastVacationData ? (
					<View style={styles.fieldsContainer}>
						<View
							style={{ width: 170, flexDirection: 'row-reverse' }}
						>
							<I18n size={14} weight='normal'>
								vacationRequest.vacationRest
							</I18n>
							<I18n size={14} weight='normal'>
								{`months.${moment(
									requestData.lastVacationData
										?.vacationBalanceDate,
									'YYYY-MM',
								).format('MMMM')}`}
							</I18n>
						</View>
						<Typography weight='normal' size={14}>
							{requestData.lastVacationData?.vacationBalance ||
								'--'}
						</Typography>
					</View>
				) : (
					<View style={styles.fieldsContainer}>
						<View style={{ width: 160 }}>
							<I18n size={14} weight='normal'>
								vacationRequest.noVacationRest
							</I18n>
						</View>
						<Typography weight='normal' size={14}>
							--
						</Typography>
					</View>
				))}

			<FieldBuilder
				key={'description'}
				name={'description'}
				multiline
				label={() => (
					<I18n size={12} weight='normal'>
						vacationRequest.description
					</I18n>
				)}
				height={91}
				styles={{ wrapper: { margin: 0 } }}
				editable={false}
				hasEditableColorText
			/>

			<View style={[styles.fieldsContainer, { marginTop: 15 }]}>
				<View style={{ width: 160 }}>
					<I18n size={14} weight='normal'>
						applicationsToEmployerContainer.status
					</I18n>
				</View>
				<Typography weight='normal' size={14}>
					{StatusOptions[requestData.status]}
				</Typography>
			</View>

			<View style={styles.fieldsContainer}>
				<View style={{ width: 160 }}>
					<I18n size={14} weight='normal'>
						applicationsToEmployerContainer.lastUpdated
					</I18n>
				</View>
				<Typography weight='normal' size={14}>
					{requestData.lastUpdate}
				</Typography>
			</View>

			<FieldBuilder
				key={'employerComment'}
				name={'employerComment'}
				multiline
				label={() => (
					<I18n size={14} weight='normal'>
						employeeRequests.employerDescriptionTitle
					</I18n>
				)}
				placeholder={'employeeRequests.employerDescription'}
				isRequired={false}
				editable={true}
				maxLength={300}
				height={91}
				limitMargin={15}
				styles={{ wrapper: { margin: 0, marginBottom: 30 } }}
			/>

			{['PENDING', 'REJECTED', 'APPROVED'].includes(requestData.status) &&
				hasPermissionOnBusinessUnit(
					businessUnitId,
					Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
				) && (
					<View style={styles.buttonContainer}>
						{['PENDING', 'REJECTED'].includes(
							requestData.status,
						) && (
							<TouchableHighlight
								onPress={() => onResponse('CONFIRM')}
								underlayColor='transparent'
							>
								<View style={styles.button}>
									<I18n
										size={16}
										weight='normal'
										color='white'
									>
										employeeRequests.approveButton
									</I18n>
								</View>
							</TouchableHighlight>
						)}

						{['PENDING', 'APPROVED'].includes(
							requestData.status,
						) && (
							<TouchableHighlight
								onPress={() => onResponse('DECLINE')}
								underlayColor='transparent'
							>
								<View style={styles.cancelButton}>
									<I18n
										size={16}
										weight='normal'
										color='lightPrimary'
									>
										employeeRequests.rejectButton
									</I18n>
								</View>
							</TouchableHighlight>
						)}
					</View>
				)}
		</FormProvider>
	);
};
const styles = StyleSheet.create({
	vacationContainer: {
		alignContent: 'space-between',
		marginTop: 17,
		marginBottom: 20,
		justifyContent: 'space-between',
	},
	title: {
		marginBottom: 5,
	},
	fieldsContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		marginBottom: 15,
	},
	vacationDates: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 90,
		justifyContent: 'space-between',
		zIndex: 1000,
	},
	alertContainer: {
		paddingVertical: 39,
		paddingHorizontal: 50,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
		width: '100%',
		marginTop: 20,
	},
	cancelButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: 'white',
		borderRadius: 45,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default VacationRequest;
