import React, { useMemo, useRef } from 'react';
import { RECAPTCHA_SITE_KEY } from '@env';
import {
	Dimensions,
	ImageBackground,
	KeyboardAvoidingView,
	Platform,
	StyleSheet,
	TextInput,
	View,
} from 'react-native';
import LinkButtonContainer from '../../components/shared/Buttons/LinkButton.container';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import colors from '../../config/colors';
import routes from '../../config/routes';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../services/i18n.service';
import ReCAPTCHA from 'react-google-recaptcha';

const imageSrc = require('../../../assets/images/login/bg.png');

interface ForgotPasswordProps {
	navigation: any;
	teudatZeut: string;
	isValidTZ: boolean;
	didResetPassword: boolean;
	setInitState: () => void;
	setEmail: (val: string) => void;
	sendEmailForgotPsw: () => void;
	hasError: boolean;
	onCaptchaChange: (value: any) => void;
	onExpiredCaptcha: () => void;
	captchaVerified: boolean;
	submitted: boolean;
}

const ForgotPassword = React.forwardRef(
	(
		{
			navigation,
			teudatZeut,
			isValidTZ,
			didResetPassword,
			sendEmailForgotPsw,
			setEmail,
			setInitState,
			hasError,
			onCaptchaChange,
			onExpiredCaptcha,
			captchaVerified,
			submitted,
		}: ForgotPasswordProps,
		ref: any,
	) => {
		const errorInputStyle = useMemo(
			() => (hasError ? styles.errorInput : {}),
			[hasError],
		);
		console.log('recaptcha', RECAPTCHA_SITE_KEY);
		const currPlatform = useDevicePlatform();
		const isWeb: boolean =
			currPlatform === DeviceType.WEB ||
			currPlatform === DeviceType.MOBILE_WEB;
		return (
			<View style={styles.wrapper}>
				<ImageBackground
					source={imageSrc}
					style={styles.image}
				></ImageBackground>
				<KeyboardAvoidingView
					behavior='padding'
					enabled={Platform.OS === 'ios'}
					keyboardVerticalOffset={100}
				>
					<View style={styles.outerContainer}>
						<View style={styles.container}>
							<View style={styles.logoSection}>
								<ImageIcon
									name={'liram'}
									width={96}
									height={29}
								></ImageIcon>
								<ImageIcon
									name={'michpal'}
									width={83}
									height={59}
								></ImageIcon>
							</View>
							<View style={styles.separatorLine}></View>
							{didResetPassword ? (
								<>
									<View style={styles.titleSection}>
										<ImageIcon
											name='tick'
											width={100}
											height={100}
										/>
									</View>
									<View
										style={{
											marginTop: 25,
											alignItems: 'center',
										}}
									>
										<I18n
											size={14}
											weight='normal'
											color='darkGrey'
										>
											login.resetPasswordSuccessfully
										</I18n>
									</View>
									<View style={{ marginTop: 40 }}>
										<SolidButtonContainer
											onPress={() => {
												setInitState();
												navigation.navigate(
													routes.signIn.name,
												);
											}}
											overrideStyle={{
												height: 61,
												alignItems: 'center',
												justifyContent: 'center',
											}}
											
										>
											<I18n
												size={16}
												weight='normal'
												color='white'
											>
												general.close
											</I18n>
										</SolidButtonContainer>
									</View>
								</>
							) : (
								<>
									<View style={styles.titleSection}>
										<I18n
											size={20}
											weight='normal'
											color='darkGrey'
										>
											login.forgotPassword
										</I18n>
									</View>
									<View style={styles.subTitle}>
										<I18n
											size={14}
											weight='normal'
											color='darkGrey'
											style={{ textAlign: 'right' }}
										>
											login.forgotInfo
										</I18n>
									</View>
									<View style={styles.inputSection}>
										<View
											style={[
												styles.inputContainer,
												errorInputStyle,
											]}
										>
											<TextInput
												style={styles.textInput}
												keyboardType={'numeric'}
												underlineColorAndroid='transparent'
												placeholder={i18nService.translate(
													isWeb
														? 'signin.teudatZehut'
														: 'signin.web.teudatZehut',
												)}
												value={teudatZeut}
												onChangeText={setEmail}
											/>
										</View>
										{Platform.OS === 'web' ? (
											<View
												style={{
													marginTop: 15,
												}}
											>
												<ReCAPTCHA
													ref={ref}
													onChange={onCaptchaChange}
													onExpired={onExpiredCaptcha}
													//sitekey={process.env.RECAPTCHA_SITE_KEY}
													sitekey={RECAPTCHA_SITE_KEY}
												/>
											</View>
										) : null}
									</View>
									{Platform.OS === 'web'
										? !captchaVerified &&
										  submitted && (
												<View style={styles.errorInfo}>
													<I18n
														color='failure'
														weight='normal'
														size={12}
													>
														forgetPassword.invalidCaptcha
													</I18n>
												</View>
										  )
										: null}
									<View style={styles.errorInfo}>
										{hasError && (
											<I18n
												color='failure'
												weight='normal'
												size={12}
											>
												forgetPassword.invalidTZ
											</I18n>
										)}
									</View>
									<SolidButtonContainer
										disabled={
											!isValidTZ ||
											(Platform.OS === 'web' &&
												!captchaVerified)
										}
										overrideStyle={{
											height: 61,
											alignItems: 'center',
											justifyContent: 'center',
										}}
										onPress={() => sendEmailForgotPsw()}
									>
										<I18n
											size={16}
											weight='normal'
											color='white'
										>
											login.sendEmail
										</I18n>
									</SolidButtonContainer>
									<LinkButtonContainer
										overrideStyle={{ width: 110 }}
										onPress={() => {
											setInitState();
											navigation.navigate(
												routes.signIn.name,
											);
										}}
									>
										<I18n
											size={14}
											weight='500'
											color='lightPrimary'
										>
											login.backToLogin
										</I18n>
									</LinkButtonContainer>
								</>
							)}
						</View>
					</View>
				</KeyboardAvoidingView>
			</View>
		);
	},
);

const windowWidth: number = Dimensions.get('window').width;
const isMobile: boolean = windowWidth <= 600;

const styles = StyleSheet.create({
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		flex: 1,
		backgroundColor: '#efefef',
	},
	image: {
		resizeMode: 'cover',
		height: isMobile ? 300 : 700,
		width: isMobile ? 430 : 700,
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		bottom: isMobile ? -50 : 0,
	},
	outerContainer: {
		backgroundColor: colors.white,
		width: isMobile ? 334 : 448,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 10,
		paddingHorizontal: 16,
		paddingTop: 18,
		paddingBottom: 47,
	},
	container: {
		flexDirection: 'column',
		width: 301,
	},
	logoSection: {
		height: 70,
		paddingBottom: 10,
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	separatorLine: {
		backgroundColor: colors.primary,
		height: 1,
		opacity: 0.38,
	},
	titleSection: {
		marginTop: 19,
		textAlign: 'center',
		alignItems: 'center',
	},
	subTitle: {
		marginTop: 25,
	},
	inputSection: {
		marginTop: 10,
		marginBottom: 15,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: 300,
		height: 46,
		borderRadius: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: 300,
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		paddingTop: 12,
		paddingBottom: 14,
		fontWeight: '500',
		color: colors.darkGrey,
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
	imageIcon: {
		paddingLeft: 15,
		paddingTop: 12,
		paddingBottom: 14,
	},
	errorInput: {
		borderWidth: 1,
		borderColor: colors.failure,
	},
	errorText: {
		alignItems: 'center',
		marginBottom: 16,
	},
	errorInfo: {
		height: 20,
		alignItems: 'center',
		justifyContent: 'center',
	},
});

export default ForgotPassword;
