import React, { useState, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import DashboardBottomSheet from '../../components/mobile/DashboardBottomSheet/DashboardBottomSheet.component';
import ClockReport from '../../components/shared/ClockReport/Clock.container';
import ReportsListContainer from '../../components/shared/ClockReport/MobileReportsList/ReportsList.container';
import WageDataSummaryMobile from './SubComponents/WageDataSummaryMobile';
import colors from '../../config/colors';
import moment from 'moment';
import httpService from '../../services/http.service';
import { getUserData } from '../../store/selectors/login.selectors';
import { useSelector } from 'react-redux';
import { Privileges } from '../../constants/roleTypes.constant';

const HomePageMobileWeb = ({
	navigation,
	isSummaryOnly,
	isPaycheckBlockShown,
	reportDateYear,
	reportContentData,
	report101warning,
	form101Id,
}: HomePageProps) => {
	const userData = useSelector(getUserData);
	const [show, setShow] = useState();
	const [standardHours, setStandardHours] = useState(0);
	const [aId, setAid] = useState(null);

	const clockReportOnly = useMemo(() => {
		const privileges = userData?.employeeRolePrivileges;
		return !(
			privileges?.includes(Privileges.ROLE_MY_PAYCHECKS_READ) ||
			privileges?.includes(Privileges.ROLE_MY_PAYCHECKS_WRITE)
		);
	}, [userData]);

	const getStandardHours = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getStandardByDate',
				params: { date: moment().local().format('YYYY-MM-DD') },
			});
			if (res) {
				setStandardHours(res);
			}
		} catch (e) {}
	};
	useEffect(() => {
		if (
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_READ,
			) ||
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_WRITE,
			)
		) {
			setAid(userData?.id);
			getStandardHours();
		}
	}, [userData]);

	if (clockReportOnly) {
		return (
			<>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
				<ClockReport report101warning={report101warning} />
				{show !== 'dashbourd' && (
					<ReportsListContainer
						aId={aId}
						standardHours={standardHours}
						setShow={setShow}
					/>
				)}
			</>
		);
	}
	if (isSummaryOnly) {
		return (
			<>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
				<WageDataSummaryMobile
					isSummaryOnly={true}
					report101warning={report101warning}
					reportContentData={reportContentData}
					reportDateYear={reportDateYear}
					isPaycheckBlockShown={isPaycheckBlockShown}
					form101Id={form101Id}
				/>
			</>
		);
	}

	return (
		<>
			<HeaderContainer navigation={navigation} component={MobileHeader} />
			<ClockReport report101warning={report101warning} />
			<View style={!show && styles.footerActionsContainer}>
				{show !== 'dashboard' && (
					<ReportsListContainer
						aId={aId}
						standardHours={standardHours}
						setShow={setShow}
					/>
				)}
				{show !== 'reports' && (
					<DashboardBottomSheet
						report101warning={report101warning}
						reportContentData={reportContentData}
						reportDateYear={reportDateYear}
						isPaycheckBlockShown={isPaycheckBlockShown}
						setShow={setShow}
					/>
				)}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'space-between',
		overflow: 'hidden',
		marginBottom: 10,
	},
	innerContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	bottomSheet: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonContainer: {
		height: 38,
		width: 140,
		backgroundColor: colors.warning,
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: { width: 197, alignItems: 'center', justifyContent: 'center' },
	footerActionsContainer: {
		width: '100%',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
	},
});

export default HomePageMobileWeb;
