import { AsyncPaginate } from 'react-select-async-paginate';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { default as ReactSelect, components } from 'react-select';
import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';
import { usePrevious } from '../../../../hooks/usePrevious.hook';
import { View, StyleSheet } from 'react-native';
import I18n from '../../i18n/I18n.component';

interface AsyncMultiSelectProps {
	loadOptions: () => {
		options: any[];
		hasMore: boolean;
	};
	onShowHideList: any;
	selectedOptions: SelectedOptions[];
	onChangeCheckBox: (selections: any, item: any) => void;
	config: any;
	key: any;
}

interface SelectedOptions {
	value: string;
}

const customStyles = (props: any) => {
	// You can use the 'styles' prop to override the styling of the component.
	const {
		control = {},
		menu,
		option,
		indicatorsContainer,
		indicatorSeparator,
		clearIndicator,
		container,
		placeholder = {},
		singleValue = {},
		input,
		menuPortal,
		menuList = {},
	} = props.styles || {};
	return {
		control: (base: any) => ({
			...base,
			minHeight: 32,
			height: 32,
			backgroundColor: '#eff5f8',
			color: colors.darkGrey,
			borderColor: colors.filterBorder,
			borderWidth: 1,
			boxShadow: 'none',
			minWidth: 99,
			borderRadius: 2,
			'&:hover': {
				borderColor: colors.filterBorder,
			},
			width: props.config?.styles?.width
				? props.config?.styles?.width + 2
				: '100',
			...props.style,
			...control,
		}),
		menu: (provided: any) => ({
			...provided,
			top: 34,
			left: 0,
			width: '100%',
			backgroundColor: colors.white,
			borderWidth: 1,
			borderRadius: 2,
			borderColor: 'transparent',
			margin: 0,
			zIndex: 9999999999999,
			...menu,
		}),
		menuPortal: (base: any) => ({
			...base,
			...menuPortal,
		}),
		valueContainer: (base: any) => ({
			...base,
			height: 'inherit',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			visibility: 'visible',
			flexWrap: 'unset',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			display: 'flex',
			alignItems: 'center',
			height: 29,
			marginTop: 6,
			padding: 0,
			backgroundColor: state.isSelected
				? colors.selectOptionSelected
				: state.isFocused
				? colors.selectOptionFocused
				: colors.white,
			...option,
		}),
		indicatorsContainer: (base: any) => ({
			...base,
			height: 'inherit',
			...indicatorsContainer,
		}),
		dropdownIndicator: (base: any) => ({
			...base,
			padding: 6,
			color: colors.primary,
		}),
		clearIndicator: (base: any) => ({
			...base,
			padding: 6,
		}),
		container: (provided: any) => ({
			...provided,
			width: '100%',
			...container,
		}),
		menuList: (provided: any) => ({
			...provided,
			width: '100%',
			...menuList,
		}),
		placeholder: (base: any) => ({
			...base,
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			...placeholder,
		}),
		input: (provided: any) => ({
			...provided,
			...input,
			padding: 0,
			margin: 0,
			color: colors.darkGrey,
		}),
	};
};

const selectOption = (props: any) => {
	return (
		<components.Option {...props}>
			<I18n
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					justifyContent: 'right',
					padding: '4px 0',
				}}
				title={props.children}
			>
				<I18n
					size={14}
					style={{
						textAlign: 'right',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						color: colors.darkGrey,
						fontFamily: 'RubikRegular',
						overflow: 'hidden',
						paddingHorizontal: 5,
					}}
				>
					{props.children}
				</I18n>
			</I18n>
		</components.Option>
	);
};

const ValueContainer = (props: any) => {
	const prevMenuIsOpen = usePrevious(props.selectProps.menuIsOpen);
	const prevValue = usePrevious(props.selectProps.value?.length);

	useEffect(() => {
		if (
			props.selectProps.menuIsOpen &&
			(props.selectProps.menuIsOpen !== prevMenuIsOpen ||
				props.selectProps.value?.length !== prevValue)
		) {
			props.selectProps.selectRef.current?.focus();
		}
	}, [props.selectProps.menuIsOpen, props.selectProps.value?.length]);
	return (
		<components.ValueContainer {...props}>
			<I18n style={{ flex: 1 }}>
				{props.selectProps.value?.length
					? React.cloneElement(props.children[1])
					: null}
				{props.selectProps.value?.length ? (
					!props.selectProps.menuIsOpen ? (
						<>{props.children[0]}</>
					) : null
				) : (
					props.children
				)}
			</I18n>
		</components.ValueContainer>
	);
};

const ValueContainerNoChips = (props: any) => {
	return (
		<components.ValueContainer {...props}>
			<I18n>
				{props.children[1]}
				{!props.selectProps?.selectRef?.current?.select?.state
					?.isFocused && (
					<components.Placeholder {...props}>
						{props.selectProps.placeholder}
					</components.Placeholder>
				)}
			</I18n>
		</components.ValueContainer>
	);
};

const AsyncMultiSelect = ({
	options,
	placeholder,
	key,
	name,
	onChangeCheckBox,
	onShowHideList,
	styles,
	disabled,
	onChangeChoice,
	shouldCheckNew,
	...restProps
}: any) => {
	const renderedPlaceholder = placeholder
		? i18nService.translate(`filter.multiselect.${placeholder}`)
		: '';
	const selectRef = useRef();
	const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>(
		[],
	);
	const loadOptions = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res = await options(search, loadedOptions, {
			page,
		});

		if (selectedOptions.length) {
			const tempSelected = selectedOptions.map(opt =>
				opt?.value
					? opt.value
					: res.options.find((o: any) => o.value === opt) || opt,
			);
			setSelectedOptions(tempSelected);
		}
		return res;
	};

	return (
		<View style={{ direction: 'rtl', zIndex: 11111, flex: 1 }}>
			<AsyncPaginate
				key={key}
				selectRef={selectRef}
				name={name}
				loadOptions={loadOptions}
				isClearable={true}
				isMulti={false}
				value={selectedOptions}
				isDisabled={false}
				closeMenuOnSelect={true}
				styles={customStyles({
					...restProps,
					styles,
					zIndex: 1111,
					height: 40,
				})}
				placeholder={renderedPlaceholder}
				components={{
					Option: selectOption,
					ValueContainer: restProps.noSelection
						? ValueContainerNoChips
						: ValueContainer,
				}}
				hideSelectedOptions={false}
				loadingMessage={() => null}
				noOptionsMessage={() => null}
				additional={{
					page: 0,
					filter: {
						dep: [],
					},
				}}
				debounceTimeout={300}
				{...restProps}
			/>
		</View>
	);
};

export default AsyncMultiSelect;
