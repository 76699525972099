import React, { useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { compact } from 'lodash';
import moment from 'moment';

import { FilterData } from '../../../../components/web/Filters/Filters.container';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';
import Select from '../../../../components/web/Select/Select.component';
import httpService from '../../../../services/http.service';
import { EnterCustomerComponenet, monthMap } from '../RepReports.utils';

const newDate = new Date();
export const defaultFilters = {
	unitIds: [],
	month:
		Number(moment(newDate).month().toString()) === 0
			? [12]
			: [Number(moment(newDate).month().toString())],
	year: [Number(moment(newDate).year().toString())],
	repCustomerIds: [],
	repCustomerUnitIds: [],
	operatorIds: [],
	type: ['SOCIAL_SECURITY', 'IRS'],
	status: [],
};

export const reportTypeList = [
	{
		id: 100,
		name: 100,
	},
	{
		id: 102,
		name: 102,
	},
];

export const institutionTypeList = [
	{
		id: 'SOCIAL_SECURITY',
		name: i18nService.translate(
			'repReports.repOrgReports.status.filter.SOCIAL_SECURITY',
		),
	},
	{
		id: 'IRS',
		name: i18nService.translate(
			'repReports.repOrgReports.status.filter.IRS',
		),
	},
];

export const statusOptions100 = (isFilter: boolean) => {
	return compact([
		isFilter && {
			id: 'NO_RESULTS',
			name: i18nService.translate(
				'repReports.repOrgReports.status.filter.NO_RESULTS',
			),
		},
		{
			id: 'BEFORE_TRANS',
			name: i18nService.translate(
				'repDashboard.monthly.100.BEFORE_TRANS',
			),
		},
		{
			id: 'TRANS_PENDING_CHECK',
			name: i18nService.translate(
				'repDashboard.monthly.100.TRANS_PENDING_CHECK',
			),
		},
		{
			id: 'TRANS_VALID',
			name: i18nService.translate('repDashboard.monthly.100.TRANS_VALID'),
		},
	]);
};

export const statusOptions102 = (typeCode: string, isFilter: boolean) => {
	return compact([
		isFilter && {
			id: 'NO_RESULTS',
			name: i18nService.translate(
				'repReports.repOrgReports.status.filter.NO_RESULTS',
			),
		},
		typeCode === 'IRS' &&
			isFilter && {
				id: 'NO_TRANS_REQUIRED',
				name: i18nService.translate(
					'repDashboard.monthly.102MS.NO_TRANS_REQUIRED',
				),
			},
		{
			id: 'BEFORE_TRANS',
			name: i18nService.translate(
				'repDashboard.monthly.102BL.BEFORE_TRANS',
			),
		},
		{
			id: 'TRANS',
			name: i18nService.translate('repDashboard.monthly.102BL.TRANS'),
		},
		{
			id: 'TRANS_PAID',
			name: i18nService.translate(
				'repDashboard.monthly.102BL.TRANS_PAID',
			),
		},
	]);
};

export const StatusSection = ({
	status,
	statusName,
	dotOnly,
}: {
	status: any;
	statusName: string;
	dotOnly?: boolean;
}) => {
	return (
		<View style={[styles.statusSection]}>
			<View
				style={[
					styles.statusDot,
					{ backgroundColor: colors.repStatus[status] },
				]}
			></View>
			{!dotOnly && (
				<View>
					<I18n size={14}>{statusName}</I18n>
				</View>
			)}
		</View>
	);
};

export const StatusTableSection = ({
	data,
	disabled,
	refresh,
}: {
	data: any;
	disabled: boolean;
	refresh(): void;
}) => {
	const statusRef = useRef<any>();
	statusRef.current = { status: data.statusCode, statusName: data.status };

	const onSelect = async (val: any) => {
		try {
			const res = await httpService.api<any>({
				type: 'updateOrgReportStatus',
				returnAllRes: true,
				data: {
					status: val.id,
				},
				params: {
					id: data.id,
					type: data.reportType,
				},
			});
			if (res.status === 200) {
				refresh();
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<View key={data.id} style={{ flexDirection: 'row', minWidth: 120 }}>
			{disabled ||
			statusRef.current?.status === 'NO_RESULTS' ||
			statusRef.current?.status === 'NO_TRANS_REQUIRED' ? (
				<StatusSection
					status={statusRef.current?.status}
					statusName={statusRef.current?.statusName}
				/>
			) : (
				<View style={{ flexDirection: 'row' }}>
					<StatusSection
						status={statusRef.current?.status}
						statusName={statusRef.current?.statusName}
						dotOnly={true}
					/>
					<Select
						key={`${data.id}-${statusRef.current?.status}`}
						dontTranslate
						options={
							data.reportType === 100
								? statusOptions100(false)
								: statusOptions102(data.typeCode, false)
						}
						menuPlacement={'auto'}
						menuPosition={'absolute'}
						placeholder={statusRef.current?.statusName}
						selectedOption={{ name: statusRef.current?.statusName }}
						style={{ minWidth: 130 }}
						onSelection={onSelect}
						menuPortalTarget={document.body}
						closeMenuOnScroll={v => true}
						styles={{
							option: { justifyContent: 'flex-end' },
							menu: { top: undefined },
						}}
					/>
				</View>
			)}
		</View>
	);
};

export const getHeaders = (
	disabled: boolean,
	refresh: any,
	canEnterCustomer: boolean,
	routeName: string,
) => {
	return compact([
		{
			accessor: 'year',
			Header: i18nService.translate(
				'repReports.general.tableHeader.year',
			),
		},
		{
			accessor: 'month',
			Header: i18nService.translate(
				'repReports.general.tableHeader.month',
			),
			CustomCell: ({ row }: any) => (
				<I18n size={14}>{monthMap[row.original?.month]}</I18n>
			),
		},
		{
			accessor: 'repCustomer',
			Header: i18nService.translate(
				'repReports.general.tableHeader.repCustomer',
			),
			isSortable: true,
		},
		{
			accessor: 'hp',
			Header: i18nService.translate('repReports.general.tableHeader.hp'),
			isSortable: true,
		},
		{
			accessor: 'businessUnitName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.businessUnitName',
			),
			isSortable: true,
		},
		{
			accessor: 'operatorName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.operatorName',
			),
			isSortable: true,
		},
		{
			accessor: 'reportType',
			Header: i18nService.translate(
				'repReports.repOrgReports.tableHeader.reportType',
			),
		},
		{
			accessor: 'type',
			Header: i18nService.translate(
				'repReports.repOrgReports.tableHeader.type',
			),
			isSortable: true,
		},
		{
			accessor: 'value',
			Header: i18nService.translate(
				'repReports.repOrgReports.tableHeader.value',
			),
			isSortable: true,
		},
		{
			accessor: 'status',
			Header: i18nService.translate(
				'repReports.repOrgReports.tableHeader.status',
			),
			isSortable: true,
			CustomCell: ({ row }: any) => (
				<StatusTableSection
					data={row.original}
					disabled={disabled}
					refresh={refresh}
				/>
			),
		},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate(
				'repReports.general.tableHeader.lastUpdate',
			),
			isSortable: true,
		},
		canEnterCustomer && {
			accessor: 'enterCustomer',
			Header: '',
			CustomCell: ({ row }: any) => {
				return (
					<EnterCustomerComponenet
						data={row.original}
						routeName={routeName}
					/>
				);
			},
		},
	]);
};

const styles = StyleSheet.create({
	statusSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	statusDot: {
		width: 12,
		height: 12,
		borderRadius: 6,
		marginLeft: 6,
	},
});

export const handleFilter = (
	data: FilterData,
	setFilters: Function,
	filters: any,
	prevInstitutionType: any,
	setPrevInstitutionType: Function,
	reportType: any,
	setReportType: Function,
	setRepCustData: Function,
) => {
	if (data.name === 'unitIds') {
		setRepCustData([]);
		setFilters({
			...defaultFilters,
			unitIds: [data.value],
		});
	} else if (data.name === 'monthYear') {
		setFilters({
			...filters,
			month:
				Number(
					moment(data.value).add(1, 'months').month().toString(),
				) === 0
					? [12]
					: [
							Number(
								moment(data.value)
									.add(1, 'months')
									.month()
									.toString(),
							),
					  ],
			year: [Number(moment(data.value).year().toString())],
		});
	} else if (data.name === 'repCustomerIds') {
		setRepCustData([]);
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerIds: chosenIds,
			repCustomerUnitIds: [],
			operatorIds: [],
		});
	} else if (data.name === 'repCustomerUnitIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerUnitIds: chosenIds,
			operatorIds: [],
		});
	} else if (data.name === 'operatorIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			operatorIds: chosenIds?.includes?.('all') ? [] : chosenIds,
		});
	} else if (data.name === 'reportType') {
		if (data.value === 100 && reportType !== 100) {
			setPrevInstitutionType(filters.type);
		}
		setReportType(data.value);
		setFilters({
			...filters,
			status: [],
			type:
				data.value === 100 ? ['SOCIAL_SECURITY'] : prevInstitutionType,
		});
	} else if (data.name === 'type') {
		if (data.value?.length) {
			const chosenIds = data.value.map((val: any) => val.id || val);
			setFilters({
				...filters,
				type: chosenIds,
				status: [],
			});
		} else {
			setFilters({
				...filters,
				type: defaultFilters.type,
				status: [],
			});
		}
	} else if (data.name === 'status') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			status: chosenIds,
		});
	}
};
