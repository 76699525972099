import moment from 'moment';
import { convertDateToLocal } from '../utils/convertDateToLocal.utils';

export type FullAttendanceReportListLike = {
	hasNext: boolean;
	total: number;
	data: FullAttendanceReportList[];
};

export class FullAttendanceReportList {
	id: string;
	teudatZeut: string;
	fullName: string;
	departmentName: string;
	salaryMonth: string;
	businessUnitName: string;
	businessUnitId: number;
	constructor(f: any) {
		this.businessUnitId = f.businessUnitId;
		this.id = f.id;
		this.teudatZeut = f.teudatZeut;
		this.fullName = f.fullName;
		this.departmentName = f.departmentName;
		// this.salaryMonth = moment(f.salaryMonth, 'YYYY/MM').format('YYYY/MM');
		this.salaryMonth = moment(f.salaryMonth, 'YYYY/MM').format('MM/YYYY');
		this.businessUnitName = f.businessUnitName;
	}
}
