import React, { useEffect, useRef, useState } from 'react';
import {
	Dimensions,
	View,
	StyleSheet,
	ScrollView,
	Platform,
} from 'react-native';
import FieldBuilder from '../../components/shared/FieldBuilder/FieldBuilder';
import I18n from '../../components/shared/i18n/I18n.component';
import { TimeMode } from '../../components/shared/FieldBuilder/TimePicker/TimePicker.component';
import colors from '../../config/colors';
import OutlineButtonContainer from '../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import { TouchableOpacity } from 'react-native';
import { fetchEmployeesFilterList } from '../../store/actions/reportsFilters.actions';
import moment from 'moment';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../services/i18n.service';
import { Switch } from 'react-native-paper';
import AsyncMultiSelectContainer from '../../components/shared/AsyncMultiSelect/AsyncMultiSelect.container';
import { useWatch } from 'react-hook-form';

interface prevValuesForm {
	employee: {
		id: any;
		name: string;
	};
	projectId: string;
	eventId: string;
	date: string;
	entryTime: string;
	exitTime: string;
	fullDay: boolean;
	employeeId: string;
}

interface Props {
	onSubmit: Function;
	onCancel: Function;
	submitBtnText: string;
	cancelBtnText: string;
	shiftHours?: number;
	prevValues?: prevValuesForm;
	totalReported?: number;
	setIsEventsFieldsOnView: Function;
	isEventsFieldsOnView: boolean;
	erroredFields: any;
	eventsOptions: any[];
	projectsOptions: any[];
	isEdit: boolean;
	isFullDayReport: boolean;
	standardHours: number;
	disableTimePickers: boolean;
	isSubmitButtonDisable: boolean;
	forms: any;
	handleFulldayToggle: Function;
	getErrorLabel: any;
	route: any;
	canReportFullDay: boolean;
	hideName?: boolean;
	addNew?: boolean;
	hideProjects?: boolean;
	hideEvents?: boolean;
	maxDate?: Date;
	businessUnitsList: any[];
	businessUnitId?: number;
	businessUnitName?: string;
}

const selectStyle = {
	control: {
		maxWidth: '100%',
		width: '100%',
		marginBottom: 3,
		backgroundColor: '#eff5f8',
		borderRadius: 2,
		height: 40,
	},
	menu: {
		//position: 'auto',
		boxShadow: 'none',
		border: `solid 1px #e7e7e8`,
	},
};

const AddRequestComponent = ({
	onSubmit,
	onCancel,
	submitBtnText,
	cancelBtnText,
	prevValues,
	route,
	setIsEventsFieldsOnView,
	isEventsFieldsOnView,
	erroredFields,
	eventsOptions,
	projectsOptions,
	isEdit,
	isFullDayReport,
	standardHours,
	disableTimePickers,
	isSubmitButtonDisable,
	forms,
	handleFulldayToggle,
	getErrorLabel,
	canReportFullDay,
	hideName,
	addNew,
	hideProjects,
	haveEvents,
	formMethods,
	hideEvents,
	onChangeEmployees,
	includeEmployees,
	maxDate,
	businessUnitsList,
	businessUnitId,
	businessUnitName,
}: Props) => {
	const [from, setFrom] = useState(forms.fromDate);
	const [to, setTo] = useState(forms.toDate);

	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const windowHeight = Dimensions.get('window').height;
	const windowWidth = Dimensions.get('window').width;
	const scrollView = useRef<any>();
	const unitId = useWatch({
		name: 'unitId',
	});
	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: 20,
			search,
			filter: { dep: [] },
			unitIds: [
				!isEdit ? formMethods.getValues().unitId : businessUnitId,
			],
		});

		return {
			options: (isEventsFieldsOnView && page === 0
				? [
						{
							value: 'all',
							label: i18nService.translate('general.all'),
						},
				  ]
				: []
			).concat(
				res?.data?.map((el: any) => {
					return {
						value: el.employeeId,
						label: el.name + ` (${el.teudatZeut})`,
						extra: { ...el },
					};
				}),
			),
			hasMore: 20 === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const onChangeProjectEvent = (
		getValues: any,
		newValue: string,
		reset: any,
		name: string,
	) => {
		reset(
			{ ...getValues(), [name]: newValue },
			{ keepErrors: true, keepDirty: true },
		);
	};

	useEffect(() => {
		if (getErrorLabel?.props?.errorType) {
			//scroll to the end only if error label exists
			scrollView.current?.scrollToEnd?.({ animated: true });
		}
	}, [getErrorLabel]);

	useEffect(() => {
		hideProjects && setIsEventsFieldsOnView(true);
	}, [hideProjects]);

	const onFormUpdate = (field: string, val: string) => {
		if (field === 'fromDate') {
			setFrom(val);
			if (!to) {
				formMethods.setValue('toDate', val);
				setTo(val);
			}
		} else if (field === 'toDate') {
			setTo(val);
			if (!from) {
				formMethods.setValue('fromDate', val);
				setFrom(val);
			}
		}
	};

	return (
		<View
			style={{
				paddingVertical: 35,
				paddingHorizontal: isMobile ? 5 : 20,
				flexDirection: 'column',
				shadowColor: 'rgba(0, 0, 0, 0)',
				shadowOpacity: 0.1,
				shadowOffset: { width: 0, height: 2 },
				backgroundColor: colors.white,
				borderRadius: 20,
				maxWidth: 408,
				width: isMobile ? 340 : 408,
			}}
		>
			<I18n
				size={24}
				weight='normal'
				color='darkGrey'
				style={{
					paddingHorizontal: isMobile ? 15 : 0,
					marginBottom: 20,
				}}
			>
				{isEdit
					? 'addReportModal.updateRequest'
					: 'addReportModal.createNewRequest'}
			</I18n>
			{!isEdit && businessUnitsList?.length > 1 ? (
				<FieldBuilder
					name='unitId'
					type='reactselect'
					label='general.unitIds'
					key={`${unitId}-unitIds`}
					showFullName
					isRequired
					options={businessUnitsList}
					styles={{
						wrapper: {
							marginVertical: 10,
							zIndex: 11111,
						},
						control: {
							height: 40,
						},
					}}
				/>
			) : businessUnitName ? (
				<View style={{ marginVertical: 10 }}>
					<I18n size={14} weight='normal'>
						general.unitIds
					</I18n>
					<I18n size={16} weight='normal'>
						{businessUnitName}
					</I18n>
				</View>
			) : null}
			{!hideProjects && !hideEvents && (
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						marginTop: 10,
						marginBottom: 10,
					}}
				>
					<TouchableOpacity
						style={{
							backgroundColor: isEventsFieldsOnView
								? colors.lightPrimary
								: '#cacaca',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							maxWidth: 175,
							width: isMobile ? 100 : '100%',
							paddingHorizontal: isMobile ? 10 : 20,
							borderRadius: 11,
						}}
						onPress={() => setIsEventsFieldsOnView(true)}
					>
						<I18n
							style={{ marginVertical: 5 }}
							size={14}
							weight='normal'
							color='white'
						>
							menu.events
						</I18n>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							backgroundColor: !isEventsFieldsOnView
								? colors.lightPrimary
								: '#cacaca',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							maxWidth: 175,
							width: isMobile ? 100 : '100%',
							paddingHorizontal: isMobile ? 10 : 20,
							borderRadius: 11,
						}}
						onPress={() => setIsEventsFieldsOnView(false)}
					>
						<I18n
							style={{ marginVertical: 5 }}
							size={14}
							weight='normal'
							color='white'
						>
							menu.reports
						</I18n>
					</TouchableOpacity>
				</View>
			)}
			<ScrollView
				style={{
					height: windowHeight - 350,
					maxHeight: 450,
					backgroundColor: colors.white,
					paddingHorizontal: 7,
				}}
				showsVerticalScrollIndicator={true}
				ref={scrollView}
			>
				{!hideName &&
					(prevValues ? (
						<View style={{ marginVertical: 10 }}>
							<I18n size={14} weight='normal'>
								filter.multiselect.workerName
							</I18n>
							<I18n size={16} weight='normal'>
								{prevValues.fullName}
							</I18n>
						</View>
					) : isEventsFieldsOnView ? (
						<FieldBuilder
							styles={{
								wrapper: { marginVertical: 10, zIndex: 1112 },
							}}
							Component={AsyncMultiSelectContainer}
							loadOptions={getEmployeesFilter}
							innerstyles={selectStyle}
							customOnChange={onChangeEmployees}
							key={`${unitId}-${forms.employeeIds}-employeeIdsMoreTOne`}
							type={'customInput'}
							name={'employeeIds'}
							noSelection
							label={() => (
								<I18n size={14} weight='normal'>
									filter.multiselect.workerName
								</I18n>
							)}
							error={erroredFields.employeeId}
							getConfig={(restProps: any) => ({
								config: {
									value: restProps.value,
									loadOptions: restProps.loadOptions,
									disabled: restProps.disabled,
									placeholder:
										restProps.value?.length === 0
											? i18nService.translate(
													'addRequestModal.allChosen',
											  )
											: restProps.value?.length &&
											  includeEmployees
											? `${
													restProps.value?.length
											  } ${i18nService.translate(
													'addRequestModal.partialChosen',
											  )}`
											: restProps.value?.length
											? `${i18nService.translate(
													'addRequestModal.allChosenExept',
											  )} ${restProps.value?.length}`
											: i18nService.translate(
													'filter.multiselect.employees',
											  ),
									name: '',
								},
							})}
							specialPlaceholder
							isClearable={false}
							isRequired
							editable
							isDisabled={!unitId}
						/>
					) : (
						<FieldBuilder
							styles={{
								wrapper: {
									marginVertical: 10,
									zIndex: 1112,
								},
								control: {
									height: 40,
								},
							}}
							type={'asyncSelect'}
							key={`${unitId}-${forms.employeeIds}-employeeId`}
							name={'employeeId'}
							label={() => (
								<I18n size={14} weight='normal'>
									filter.multiselect.workerName
								</I18n>
							)}
							error={erroredFields.employeeId}
							isRequired={true}
							isDisabled={!unitId}
							options={getEmployeesFilter}
							placeholder={'employees'}
						/>
					))}
				{isEventsFieldsOnView ? (
					<View style={{ height: 70, zIndex: 1111 }}>
						<FieldBuilder
							type='reactselect'
							styles={
								!isMobile ? { wrapper: { margin: 0 } } : null
							}
							label={() => (
								<I18n size={14} weight='normal'>
									menu.events
								</I18n>
							)}
							name='eventId'
							key={`${unitId}-${
								Platform.OS === 'ios' ? '' : forms.eventId
							}-event`}
							isRequired={true}
							editable={true}
							options={eventsOptions}
							error={erroredFields.eventId}
							isSearchable={!isMobile}
							onChange={(
								setValue,
								getValues,
								newValue,
								value,
								trigger,
								reset,
							) => {
								onChangeProjectEvent(
									getValues,
									newValue,
									reset,
									'eventId',
								);
							}}
						/>
					</View>
				) : projectsOptions.length ? (
					<View style={{ height: 70, zIndex: 1111 }}>
						<FieldBuilder
							key={`${unitId}-${
								Platform.OS === 'ios' ? '' : forms.projectId
							}-project`}
							error={erroredFields.projectId}
							name='projectId'
							type='reactselect'
							styles={
								!isMobile ? { wrapper: { margin: 0 } } : null
							}
							label={() => (
								<I18n size={14} weight='normal'>
									filter.multiselect.project
								</I18n>
							)}
							isRequired={true}
							editable={true}
							options={projectsOptions}
							// horizontalPadding={180}
							isSearchable={!isMobile}
							onChange={(
								setValue,
								getValues,
								newValue,
								value,
								trigger,
								reset,
							) => {
								onChangeProjectEvent(
									getValues,
									newValue,
									reset,
									'projectId',
								);
							}}
						/>
					</View>
				) : null}
				{isEventsFieldsOnView && addNew && !haveEvents ? (
					<View style={styles.requestDates}>
						<View style={{ width: 140 }}>
							<FieldBuilder
								styles={{ wrapper: { margin: 0 } }}
								type={'datepicker'}
								key={'fromDate'}
								name={'fromDate'}
								isRequired={true}
								popperPlacement='bottom-end'
								popperModifiers={[
									{
										name: 'flip',
										options: {
											fallbackPlacements: ['bottom'],
										},
									},
								]}
								maxDate={
									to
										? new Date(
												moment(
													to,
													'DD/MM/YYYY',
												).valueOf(),
										  )
										: maxDate || undefined
								}
								label={() => (
									<I18n size={12} weight='normal'>
										vacationRequest.fromDate
									</I18n>
								)}
								placeholder={'general.datePickerPlaceholder'}
								onChange={(val, a, b) => {
									onFormUpdate(
										'fromDate',
										typeof b === 'string'
											? b
											: b?.target?.value,
									);
								}}
								error={erroredFields.fromDate}
							/>
						</View>
						<View style={{ width: 140 }}>
							<FieldBuilder
								styles={{ wrapper: { margin: 0 } }}
								type={'datepicker'}
								isRequired={true}
								key={'toDate'}
								name={'toDate'}
								popperModifiers={[
									{
										name: 'flip',
										options: {
											fallbackPlacements: ['bottom'],
										},
									},
								]}
								minDate={
									from
										? new Date(
												moment(
													from,
													'DD/MM/YYYY',
												).valueOf(),
										  )
										: undefined
								}
								maxDate={maxDate || undefined}
								label={() => (
									<I18n size={12} weight='normal'>
										vacationRequest.toDate
									</I18n>
								)}
								placeholder={'general.datePickerPlaceholder'}
								onChange={(val, a, b) => {
									onFormUpdate(
										'toDate',
										typeof b === 'string'
											? b
											: b?.target?.value,
									);
								}}
								error={erroredFields.toDate}
							/>
						</View>
					</View>
				) : prevValues?.date ? (
					<View style={{ marginVertical: 15 }}>
						<I18n size={14} weight='normal'>
							worklogReportPage.date
						</I18n>

						<I18n size={16} weight='normal'>
							{moment(prevValues?.date, 'YYYY-MM-DD').format(
								'DD/MM/YYYY',
							)}
						</I18n>
					</View>
				) : (
					<View
						style={{
							flexDirection: 'row-reverse',
							marginBottom: 10,
							zIndex: 111,
						}}
					>
						<FieldBuilder
							styles={{
								wrapper: {
									marginVertical: 10,
								},
							}}
							key={'date'}
							name={'date'}
							type={'datepicker'}
							popperPlacement='bottom-end'
							popperModifiers={[
								{
									name: 'flip',
									options: {
										fallbackPlacements: ['bottom'],
									},
								},
							]}
							maxDate={maxDate || undefined}
							label={() => (
								<I18n size={14} weight='normal'>
									worklogReportPage.date
								</I18n>
							)}
							placeholder={'general.datePickerPlaceholder'}
							isRequired={true}
							editable={true}
							error={erroredFields.date}
						/>
					</View>
				)}
				{isEventsFieldsOnView && canReportFullDay && from === to ? (
					<View
						style={{
							alignItems: 'flex-end',
							marginBottom: 15,
						}}
					>
						<View
							style={{
								flexDirection: 'row-reverse',
								marginBottom: 5,
							}}
						>
							<I18n size={14} weight='normal' color='#525962'>
								modal.report.fullDay
							</I18n>
							<Switch
								color={colors.lightPrimary}
								onValueChange={handleFulldayToggle}
								value={isFullDayReport}
								style={{ marginRight: 5 }}
							/>
						</View>
						{(forms.date || (!!from && from === to)) &&
						standardHours ? (
							<I18n
								style={{ textAlign: 'center' }}
								size={14}
								weight='normal'
								color='#525962'
							>
								{`${i18nService.translate(
									'modal.AddRequest.fullday.description.entry',
								)} ${
									standardHours
										? standardHours.enterTime
										: ' 08:00 '
								} ${i18nService.translate(
									'modal.AddRequest.fullday.description.exit',
								)} ${
									standardHours
										? standardHours.exitTime
										: ' 08:00 '
								}`}
							</I18n>
						) : null}
					</View>
				) : null}
				{!moment(
					moment(from || forms.date, 'DD/MM/YYYY').format(
						'YYYY-MM-DD',
					),
				).isAfter(moment().format('YYYY-MM-DD')) && (
					<View
						style={{
							zIndex: 1,
						}}
					>
						<FieldBuilder
							disablePicker={
								disableTimePickers || (!!from && from != to)
							}
							key={`${unitId}-entryTime`}
							type={'timePicker'}
							name={'entryTime'}
							defaultTime={
								prevValues ? prevValues.entryTime : null
							}
							styles={{
								wrapper: { flex: 1 },
							}}
							isRequired={
								!disableTimePickers || !(!!from && from != to)
							}
							editable={true}
							mode={TimeMode.Entry}
							label={() => (
								<I18n size={14} weight='normal'>
									general.enterenceTime
								</I18n>
							)}
							error={erroredFields.entryTime}
						/>
						<FieldBuilder
							disablePicker={
								disableTimePickers || (!!from && from != to)
							}
							key={`${unitId}-exitTime`}
							type={'timePicker'}
							name={'exitTime'}
							defaultTime={
								prevValues ? prevValues.exitTime : null
							}
							styles={{
								wrapper: { flex: 1 },
							}}
							isRequired={
								!disableTimePickers || !(!!from && from != to)
							}
							editable={true}
							mode={TimeMode.Entry}
							label={() => (
								<I18n size={14} weight='normal'>
									general.exitTime
								</I18n>
							)}
							error={erroredFields.exitTime}
						/>
					</View>
				)}
				{getErrorLabel}
			</ScrollView>
			<View style={styles.actionButtons}>
				<SolidButtonContainer
					disabled={isSubmitButtonDisable}
					overrideStyle={{
						minWidth: 92,
						height: 40,
						justifyContent: 'center',
						backgroundColor: isSubmitButtonDisable
							? '#cacaca'
							: colors.lightPrimary,
						paddingHorizontal: 15,
						padding: 10,
					}}
					onPress={onSubmit}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='white'
					>
						{submitBtnText}
					</I18n>
				</SolidButtonContainer>
				<OutlineButtonContainer
					overrideStyle={styles.buttonCancel}
					onPress={onCancel}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='primary'
					>
						{cancelBtnText}
					</I18n>
				</OutlineButtonContainer>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		marginTop: 10,
	},
	buttonOk: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
		paddingHorizontal: 15,
		padding: 10,
	},
	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		paddingHorizontal: 15,
		padding: 10,
	},
	requestDates: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 90,
		justifyContent: 'space-between',
		zIndex: 1000,
	},
});

export default AddRequestComponent;
