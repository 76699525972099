import React, { useState, useEffect, useRef } from 'react';
import Timer from './Timer.component';
import { Color, FontSize } from '../../../interfaces/core.interface';
import { toNumber } from 'lodash';
interface TimerProps {
	initialTime: InitTime;
	textColor?: Color;
	fontSize?: FontSize;
	max?: number;
	maxAlert?: () => void;
}
interface InitTime {
	h: number;
	m: number;
	s: number;
}

const TimerContainer = ({
	initialTime,
	textColor,
	fontSize,
	max,
	maxAlert,
}: TimerProps) => {
	const [hours, setHours] = useState(initialTime.h || 0);
	const [minutes, setMinutes] = useState(initialTime.m || 0);
	const [seconds, setSeconds] = useState(initialTime.s || 0);

	const hoursRef = useRef<any>();
	const minutesRef = useRef<any>();
	const secondsRef = useRef<any>();

	hoursRef.current = hours;
	minutesRef.current = minutes;
	secondsRef.current = seconds;

	useEffect(() => {
		const h = initialTime ? initialTime.h : 0;
		const m = initialTime ? initialTime.m : 0;
		const s = initialTime ? initialTime.s : 0;

		setHours(h);
		setMinutes(m);
		setSeconds(s);
	}, [initialTime]);

	useEffect(() => {
		const passedhours = minutes / 60 + hours + seconds / 3600;
		if (max && passedhours >= max) {
			maxAlert?.();
		}
	}, [hours, minutes, seconds]);

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (minutesRef.current === 59 && secondsRef.current === 59) {
				setHours(hoursRef.current + 1);
				setMinutes(0);
				setSeconds(0);
			} else if (secondsRef.current === 59) {
				setMinutes(minutesRef.current + 1);
				setSeconds(0);
			}
			setSeconds(secondsRef.current + 1);
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	}, []);

	return (
		<Timer
			hours={hours}
			minutes={minutes}
			seconds={seconds}
			textColor={textColor}
			fontSize={fontSize}
		/>
	);
};

export default TimerContainer;
