import moment from 'moment';
import React, { useState } from 'react';
import { View, StyleSheet, TouchableHighlight, Linking } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import AlertModal from '../../../../modals/AlertModal/AlertModal.component';
import httpService from '../../../../services/http.service';
import { i18nService } from '../../../../services/i18n.service';
import { modalService } from '../../../../services/modal.service';
import Loader from '../../../../components/shared/Loader/Loader.component';
import { RequestDetails } from '../EditRequest';
import { SiknessRequestData } from './SiknessRequest';
import { VacationData } from './VacationRequest';

export const onSendNewRequest = async (
	requestDetails: VacationData | SiknessRequestData,
	requestType: string,
	onClose: () => void,
	data,
	onrefresh,
) => {
	const onAccept = async onNext => {
		try {
			await httpService.api({
				type: 'createEmployerRequest',
				isFormData: true,
				notGenerateFormData: true,
				data: data,
			});

			onNext(onRequestUpdated(onClose, onrefresh));
		} catch (e) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>vacationRequest.createRequestFailed</I18n>
					</AlertModal>
				),
			);
			onNext();
		}
	};

	await modalService.openModal(null, {}, (props: any) => (
		<NotificationModal
			title={
				requestType === 'VACATION'
					? 'vacationRequest.sureToSend'
					: 'siknessRequest.sureToSend'
			}
			text={`?${requestDetails.toDate} - ${requestDetails.fromDate}`}
			onAccept={onAccept}
			{...props}
		/>
	));
};

const onRequestUpdated = async (onClose, onrefresh) => {
	try {
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>vacationRequest.requestSent</I18n>
				</AlertModal>
			),
		);
		onClose(onrefresh());
	} catch (e) {
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>vacationRequest.updateRequestFailed</I18n>
				</AlertModal>
			),
		);
		onClose();
	}
};

const NotificationModal = ({
	title,
	text,
	onNext,
	onAccept,
	acceptButtonText,
	declineButtonText,
	onClose,
}: any) => {
	const [isSend, setIsSend] = useState(false);
	return (
		<View style={styles.sendNewRequestContainer}>
			{isSend ? (
				<Loader />
			) : (
				<>
					<View style={styles.sendNewRequestText}>
						<I18n>{title}</I18n>
						{!!text && (
							<Typography
								style={{ marginTop: 10 }}
								weight='400'
								size={14}
							>
								{text}
							</Typography>
						)}
					</View>

					<View style={styles.buttonContainer}>
						<TouchableHighlight
							onPress={() => {
								setIsSend(true);
								onAccept(onNext);
							}}
							underlayColor='transparent'
						>
							<View style={styles.button}>
								<I18n size={16} weight='normal' color='white'>
									{acceptButtonText || 'general.accept'}
								</I18n>
							</View>
						</TouchableHighlight>

						<TouchableHighlight
							onPress={() => {
								onNext();
							}}
							underlayColor='transparent'
						>
							<View style={styles.cancelButton}>
								<I18n
									size={16}
									weight='normal'
									color='lightPrimary'
								>
									{declineButtonText || 'general.cancel'}
								</I18n>
							</View>
						</TouchableHighlight>
					</View>
				</>
			)}
		</View>
	);
};

export const showUpdateButton = (
	requestData: RequestDetails,
	validationDate: number,
) => {
	if (
		requestData.status === 'REJECTED' ||
		requestData.status === 'CANCELED'
	) {
		return false;
	}
	if (
		new Date(validationDate).getTime() -
			new Date(
				moment(requestData.fromDate, 'DD/MM/YYYY').valueOf(),
			).getTime() >
		0
	) {
		return false;
	}

	return true;
};

const showDeleteRequestButton = (requestData: RequestDetails) => {
	if (
		requestData.status === 'REJECTED' ||
		requestData.status === 'CANCELED'
	) {
		return false;
	}

	if (
		new Date().getTime() -
			new Date(
				moment(requestData.fromDate, 'DD/MM/YYYY').valueOf(),
			).getTime() >
		0
	) {
		return false;
	}

	return true;
};

export const TwoButtonsContainer = ({
	showEditButton,
	requestData,
	onClose,
	sendRequestUpdate,
	to,
	from,
	validationDate,
}: {
	requestData: RequestDetails;
	showEditButton: boolean;
}) => {
	const updateVisible = showUpdateButton(requestData, validationDate);
	const deleteVisible = showDeleteRequestButton(requestData);

	const onUpdate = async () => {
		try {
			await modalService.openModal(null, {}, (props: any) => (
				<NotificationModal
					title={
						requestData.type === 'VACATION'
							? 'vacationRequest.wantToUpdate'
							: 'siknessRequest.wantToUpdate'
					}
					text={`?${to} - ${from}`}
					onAccept={next =>
						next(
							getRequestStatus(
								requestData.id,
								requestData.status,
								requestData,
								sendRequestUpdate,
								onClose,
							),
						)
					}
					acceptButtonText={'general.confirm'}
					declineButtonText={'general.cancel'}
					{...props}
				/>
			));
		} catch {}
	};

	const onCancel = async () => {
		try {
			await modalService.openModal(null, {}, (props: any) => (
				<NotificationModal
					title={'vacationRequest.shouldDelete'}
					onAccept={next => next(deleteRequest(requestData, onClose))}
					acceptButtonText={'vacationRequest.cancelRequest'}
					declineButtonText={'general.close'}
					{...props}
				/>
			));
		} catch {}
	};

	const deleteRequest = async (requestData, onClose) => {
		try {
			await httpService.api({
				type: 'cancelEmployerRequest',
				params: { requestId: requestData.id },
			});
			onClose();
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>
							{requestData.type === 'VACATION'
								? 'vacationRequest.deleted'
								: 'siknessRequest.deleted'}
						</I18n>
					</AlertModal>
				),
			);
		} catch (e) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>vacationRequest.updateRequestFailed</I18n>
					</AlertModal>
				),
			);
			onClose();
		}
	};

	return (
		<View style={styles.buttonContainer}>
			{updateVisible && (
				<TouchableHighlight
					onPress={onUpdate}
					underlayColor='transparent'
					disabled={!showEditButton}
					style={{ opacity: showEditButton ? 1 : 0.3 }}
				>
					<View style={styles.button}>
						<I18n size={16} weight='normal' color='white'>
							general.update
						</I18n>
					</View>
				</TouchableHighlight>
			)}

			{deleteVisible && (
				<TouchableHighlight
					onPress={onCancel}
					underlayColor='transparent'
				>
					<View style={styles.cancelButton}>
						<I18n size={16} weight='normal' color='lightPrimary'>
							vacationRequest.cancelRequest
						</I18n>
					</View>
				</TouchableHighlight>
			)}
		</View>
	);
};

const getRequestStatus = async (
	id,
	status,
	requestData,
	sendRequestUpdate,
	onClose,
) => {
	try {
		const res = await httpService.api({
			type: 'getEmployerRequestStatus',
			params: { requestId: id },
		});

		if (status !== res) {
			await modalService.openModal(null, {}, (props: any) => (
				<NotificationModal
					title={'vacationRequest.statusChanged'}
					onAccept={next =>
						next(() =>
							updateRequest(
								id,
								status,
								requestData,
								sendRequestUpdate,
								onClose,
							),
						)
					}
					{...props}
				/>
			));
		}

		return updateRequest(
			id,
			status,
			requestData,
			sendRequestUpdate,
			onClose,
		);
	} catch {}
};

const updateRequest = async (
	id,
	status,
	requestData,
	sendRequestUpdate,
	onClose,
) => {
	try {
		const { dataToSend } = await sendRequestUpdate();

		await httpService.api({
			type: 'editEmployerRequest',
			isFormData: true,
			notGenerateFormData: true,
			params: { requestId: requestData.id },
			data: dataToSend,
		});

		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>vacationRequest.requestSentUpdated</I18n>
				</AlertModal>
			),
		);

		onClose();
	} catch (e) {
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>vacationRequest.updateRequestFailed</I18n>
				</AlertModal>
			),
		);
		onClose();
	}
};

export const SendButton = ({
	onSend,
	containerStyles,
	disabled,
}: {
	onSend: () => void;
	containerStyles?: any;
	disabled?: boolean;
}) => {
	return (
		<View style={[{ alignItems: 'center' }, containerStyles]}>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={onSend}
				disabled={disabled}
			>
				<View style={[styles.button, disabled && styles.disabled]}>
					<Typography
						style={{ marginLeft: 10, marginRight: 10 }}
						color={disabled ? colors.lightBlue : 'white'}
						weight='400'
						size={14}
					>
						{i18nService.translate('general.send')}
					</Typography>
				</View>
			</TouchableHighlight>
		</View>
	);
};

export const downloadFile = async (
	fileID: number,
	request: SiknessRequestData,
) => {
	try {
		const res: any = await httpService.api({
			type: 'getEmployerRequestFileUrl',
			params: { requestId: request.id, fileId: fileID },
		});

		let elm = document.createElement('a');
		elm.href = res;
		elm.click();
	} catch (e) {}
};

const styles = StyleSheet.create({
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	disabled: {
		opacity: 0.6,
	},
	sendNewRequestContainer: {
		paddingVertical: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		height: 239,
		width: 334,
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
		width: '100%',
	},
	sendNewRequestText: {
		paddingHorizontal: 50,
		marginBottom: 20,
	},
	cancelButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: 'white',
		borderRadius: 45,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});
