import React, { useState } from 'react';
import { View } from 'react-native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import I18n from '../../components/shared/i18n/I18n.component';

const EmptyPage = ({ navigation }: { navigation: any }) => {
	return (
		<>
			<HeaderContainer navigation={navigation} component={MobileHeader} />
			<View
				style={{
					display: 'flex',
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					zIndex: -1,
				}}
			>
				{
					<View
						style={{
							marginLeft: 20,
							marginRight: 20,
						}}
					>
						<I18n
							weight='bold'
							size={20}
							style={{ textAlign: 'center' }}
						>
							emptyPage.emptyMassage
						</I18n>
					</View>
				}
			</View>
		</>
	);
};

export default EmptyPage;
