import { toNumber } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ReactDatePicker from '../../../../components/web/ReactDatePicker/ReactDatePicker.component';
import { Privileges } from '../../../../constants/roleTypes.constant';
import httpService from '../../../../services/http.service';
import { getUserData } from '../../../../store/selectors/login.selectors';
import Card from '../Card/Card.component';
import { getDefaultDateFilter } from '../RepHomePage.utils';

const MonthlyData = ({ filters, customersFullDataJson }: any) => {
	const [localFilters, setLocalFilters] = useState<any>();
	const [data, setData] = useState<any>();
	const userData = useSelector(getUserData);

	const displayPaycheck = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_PAYCHECK_REPORT_READ,
			) ||
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_PAYCHECK_REPORT_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	const display100And102 = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ORG_REPORT_READ,
			) ||
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ORG_REPORT_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	const onChange = ({ value }: any) => {
		const newData = {
			month: [value.getMonth() + 1],
			year: [value.getFullYear()],
		};
		sessionStorage.setItem('repDashboardMonthly', JSON.stringify(newData));
		setLocalFilters(newData);
	};

	const getData = async () => {
		const res = await httpService.api({
			type: 'getRepDashboardMonthlyData',
			data: { f: { ...filters, ...localFilters } },
		});
		setData(res);
	};

	useEffect(() => {
		const tempFilters = sessionStorage.getItem('repDashboardMonthly');

		if (tempFilters && tempFilters !== '') {
			setLocalFilters(JSON.parse(tempFilters));
		} else {
			setLocalFilters({
				year: [moment().subtract(1, 'months').format('YYYY')],
				month: [toNumber(moment().subtract(1, 'months').format('MM'))],
			});
		}
	}, []);

	useEffect(() => {
		if (localFilters?.month.length) {
			getData();
		}
	}, [filters, localFilters]);

	return (
		<View style={{ paddingVertical: 30 }}>
			<View
				style={{
					flexDirection: 'row-reverse',
					alignItems: 'baseline',
					marginBottom: 24,
				}}
			>
				<I18n weight='normal' size={36} style={{ marginLeft: 5 }}>
					repDashboard.monthly
				</I18n>
				<I18n size={14} weight='400' style={{ marginBottom: 2 }}>
					repDashboard.units
				</I18n>
			</View>
			<View
				style={{
					zIndex: 100,
					flexDirection: 'row-reverse',
				}}
			>
				<View
					style={{
						width: 200,
						zIndex: 100,
						flexDirection: 'row-reverse',
					}}
				>
					<ReactDatePicker
						onChange={onChange}
						key={`${localFilters}`}
						config={
							{
								name: 'test',
								type: '',
								dashboardViewMode: true,
								date:
									localFilters &&
									new Date(
										localFilters.year,
										localFilters.month - 1,
									),
							} as any
						}
					/>
				</View>
			</View>
			{display100And102 && (
				<View
					style={{
						marginTop: 24,
						flexDirection: 'row-reverse',
						...Platform.select({ web: { gap: 24 } }),
					}}
				>
					<Card
						title='repDashboard.monthly.102BL'
						colorsObj={{
							NO_RESULTS: 'hsl(360, 79%, 63%)',
							BEFORE_TRANS: '#EB8B2E',
							TRANS: '#F2C94C',
							TRANS_PAID: 'hsl(145, 50%, 62%)',
						}}
						data={data?.socialSecurity102}
						navigateTo='repOrgReports'
						defaultParams={{
							...localFilters,
							...filters,
							reportType: 102,
							type: ['SOCIAL_SECURITY'],
						}}
						moreParams={{
							customersFullData: customersFullDataJson,
						}}
					/>
					<Card
						title='repDashboard.monthly.102MS'
						colorsObj={{
							NO_RESULTS: 'hsl(360, 79%, 63%)',
							NO_TRANS_REQUIRED: '#BDBDBD',
							BEFORE_TRANS: '#EB8B2E',
							TRANS: '#F2C94C',
							TRANS_PAID: 'hsl(145, 50%, 62%)',
						}}
						data={data?.irs102}
						navigateTo='repOrgReports'
						defaultParams={{
							...localFilters,
							...filters,
							reportType: 102,
							type: ['IRS'],
						}}
						moreParams={{
							customersFullData: customersFullDataJson,
						}}
					/>
				</View>
			)}
			<View
				style={{
					marginTop: display100And102 ? 16 : 24,
					flexDirection: 'row-reverse',
					...Platform.select({ web: { gap: 24 } }),
				}}
			>
				{display100And102 && (
					<Card
						title='repDashboard.monthly.100'
						data={data?.monthly100}
						colorsObj={{
							NO_RESULTS: 'hsl(360, 79%, 63%)',
							BEFORE_TRANS: '#EB8B2E',
							TRANS_PENDING_CHECK: '#F2C94C',
							TRANS_VALID: 'hsl(145, 50%, 62%)',
						}}
						width={!displayPaycheck ? 'calc(50% - 75px)' : null}
						navigateTo='repOrgReports'
						defaultParams={{
							...localFilters,
							...filters,
							reportType: 100,
						}}
						moreParams={{
							customersFullData: customersFullDataJson,
						}}
					/>
				)}
				{displayPaycheck && (
					<Card
						title='repDashboard.monthly.paychecks'
						data={data?.paychecks}
						customKeys={{ NO_RESULTS: false, HAS_PAYCHECKS: true }}
						colorsObj={{
							NO_RESULTS: 'hsl(360, 79%, 63%)',
							HAS_PAYCHECKS: 'hsl(145, 50%, 62%)',
						}}
						width={!display100And102 ? 'calc(50% - 75px)' : null}
						navigateTo='repPaycheckReports'
						defaultParams={{ ...localFilters, ...filters }}
						moreParams={{
							customersFullData: customersFullDataJson,
						}}
					/>
				)}
			</View>
		</View>
	);
};

export default MonthlyData;
