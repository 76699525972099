import React from 'react';
import {
	TouchableHighlight,
	View,
	StyleSheet,
	Linking,
	TouchableOpacity,
	Platform,
} from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import getShadowStyle from '../../config/shadow';
import httpService from '../../services/http.service';
import { i18nService } from '../../services/i18n.service';
import { DocumentsData } from './Documents.container';
import { compact } from 'lodash';
import { modalService } from '../../services/modal.service';
import ActionButtonsMenu from '../UsersManagmentPage/UsersTablePage/UserTableMobileRow/ActionButtonsMenu';
import ModalConfirmActions from '../../modals/ModalConfirmActions/ModalConfirmActions.component';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import moment from 'moment';
import ModalConfirmActionsCustomStyle from '../../modals/ModalConfirmActionsCustomStyle/ModalConfirmActionsCustomStyle.component';
import SendingDocumentContainer from './SendingDocument/SendingDocument.container';

export const initialHeaders = (
	onRowClick: (row: any) => void,
	businessUnitsList: any,
) => {
	return compact([
		businessUnitsList?.length > 1 && {
			accessor: 'businessUnitName',
			Header: i18nService.translate('general.businessUnits'),
			isSortable: true,
		},
		{
			accessor: 'teudatZeut',
			Header: i18nService.translate('usersManagement.table.tz'),
			isSortable: true,
			style: { minWidth: 80 },
			hasBudget: (row: any) => row.finishWork,
			textBlock: (row: any) => (
				<>
					<I18n
						size={8}
						color='white'
						weight='normal'
						style={{ marginLeft: 5 }}
					>
						general.endWorkDate
					</I18n>
					<I18n size={8} weight='normal' color='white'>
						{moment(row.original.finishWork, 'YYYY-MM-DD').format(
							'DD/MM/YYYY',
						)}
					</I18n>
				</>
			),
			budgetStyle: {
				paddingVertical: 3,
				paddingHorizontal: 6,
				minWidth: 110,
			},
			CustomCell: ({ value, row }: any) => {
				return (
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => onRowClick(row.original)}
					>
						<Typography color='primary' weight='bold' size={14}>
							{value}
						</Typography>
					</TouchableHighlight>
				);
			},
		},
		{
			accessor: 'name',
			Header: i18nService.translate('usersManagement.table.emp_name'),
			isSortable: true,
		},
		{
			accessor: 'departmentName',
			Header: i18nService.translate('documents.table.header.department'),
			isSortable: true,
			CustomCell: ({ value, row }: any) => {
				return <I18n size={14}>{value}</I18n>;
			},
		},
		{
			accessor: 'email',
			Header: i18nService.translate('usersManagement.table.email'),
			isSortable: true,
		},
		{
			accessor: 'phone',
			Header: i18nService.translate('usersManagement.table.phone'),
			isSortable: true,
		},
	]);
};

const downloadFile = async (request: DocumentsData, isEmployer: boolean) => {
	const res: string = await httpService.api({
		type: isEmployer ? 'getDocsDownloadManagerFile' : 'getDocsDownloadFile',
		params: { docId: request.id },
		query: { unitIds: request.unitId },
		responseType: 'text',
	});
	let module;
	if (Platform.OS === 'web') {
		let elm = document.createElement('a');
		elm.href = res;
		elm.setAttribute('download', request.fileName);
		elm.click();
	} else {
		module = await import('../../utils/downloadFile.moblie.utils');
		module.downloadFile(res, `${request.fileName}.pdf`);
	}
};

export const DownloadSection = ({
	data,
	disabled,
	isMobile,
	maxWidth,
}: {
	data: DocumentsData;
	disabled: boolean;
	isMobile?: boolean;
	maxWidth?: number;
}) => {
	const isDisabled = disabled
		? true
		: data.status === 'FULL_SIGN' ||
		  data.status === 'ABANDONED' ||
		  data.status === 'AWAITING_SIGN'
		? false
		: true;

	return (
		<TouchableHighlight
			underlayColor='transparent'
			onPress={async (e: any) => downloadFile(data, true)}
			disabled={isDisabled}
		>
			<View
				style={[
					styles.downloadSection,
					isMobile && {
						flexDirection: 'row-reverse',
					},
				]}
			>
				<ImageIcon
					name={
						isDisabled ? 'downloadDisablePDF' : 'downloadActivePDF'
					}
					height={24}
					width={20.9}
				/>
				<Typography
					style={[
						styles.downloadTitle,
						isMobile && { marginTop: 4, maxWidth: maxWidth },
					]}
					color={isDisabled ? 'darkGrey' : 'lightPrimary'}
					weight={isMobile ? 'bold' : isDisabled ? '400' : 'bold'}
					size={isMobile ? 20 : 14}
					numberOfLines={1}
				>
					{data.fileName}
				</Typography>
			</View>
		</TouchableHighlight>
	);
};
export const DownloadEmployeeSection = ({
	data,
	isMobile,
}: {
	data: DocumentsData;
	isMobile?: boolean;
}) => {
	const isDisabled =
		data.status === 'FULL_SIGN' || data.status === 'AWAITING_SIGN'
			? false
			: true;

	return (
		<TouchableHighlight
			underlayColor='transparent'
			onPress={async (e: any) => downloadFile(data, false)}
			disabled={isDisabled}
		>
			<View
				style={[
					styles.downloadSection,
					isMobile && { flexDirection: 'row-reverse' },
				]}
			>
				<ImageIcon
					name={
						data.status === 'AWAITING_SIGN'
							? 'orangePDF'
							: isDisabled
							? 'downloadDisablePDF'
							: 'downloadActivePDF'
					}
					height={24}
					width={20.9}
				/>
				{isMobile ? (
					<Typography
						style={[
							styles.downloadTitle,
							{
								marginTop: 4,
								marginBottom: 10,
								textAlign: 'center',
							},
						]}
						color={isDisabled ? 'darkGrey' : 'lightPrimary'}
						weight={isDisabled ? '400' : 'bold'}
						size={20}
					>
						{isDisabled
							? data.status === 'SECURITY_FAILED' ||
							  data.status === 'PENDING_SECURITY'
								? data.clientStatus
									? data.clientStatus
									: i18nService.translate(
											`documents.status.discription.${data.status}`,
									  )
								: i18nService.translate(
										'documents.status.discription.AWAITING_SIGN',
								  )
							: i18nService.translate(
									'documents.downloadFile.text',
							  )}
					</Typography>
				) : (
					<Typography
						style={styles.downloadTitle}
						color={isDisabled ? 'darkGrey' : 'lightPrimary'}
						weight={isDisabled ? '400' : 'bold'}
						size={14}
					>
						{data.status === 'SECURITY_FAILED' ||
						data.status === 'PENDING_SECURITY'
							? `${data.fileName} (${
									data.clientStatus
										? data.clientStatus
										: i18nService.translate(
												`documents.status.discription.${data.status}`,
										  )
							  })`
							: data.fileName}
					</Typography>
				)}
			</View>
		</TouchableHighlight>
	);
};

export const SendReminderSection = ({
	data,
	disabled,
	isMobile,
	userId,
}: {
	data: DocumentsData;
	disabled: boolean;
	isMobile: boolean;
	userId: number;
}) => {
	const isDisabled = true;
	// const isDisabled = disabled
	// 	? true
	// 	: data.status === 'AWAITING_SIGN'
	// 	? false
	// 	: true;
	// open this section if remainder will be active in the future.

	return isDisabled ? null : (
		<TouchableOpacity
			onPress={async (e: any) => sendReminderToEmployee(userId)}
			disabled={isDisabled}
		>
			<View
				style={[
					styles.downloadSection,
					isMobile && { flexDirection: 'row-reverse' },
				]}
			>
				{isMobile && (
					<Typography
						size={14}
						style={{
							paddingHorizontal: 3,
						}}
					>
						-
					</Typography>
				)}
				<ImageIcon name='bell' height={16} width={12.8} />
				<I18n
					color={'primary'}
					size={12}
					style={{ marginHorizontal: 4 }}
				>
					documents.data.sendRemainder.msg
				</I18n>
			</View>
		</TouchableOpacity>
	);
};

const statusColorMatcher = {
	['AWAITING_SIGN']: {
		color: 'orange',
	},
	['ABANDONED']: {
		color: 'gray',
	},
	['PENDING_SECURITY']: {
		color: 'blue',
	},
	['SECURITY_FAILED']: {
		color: colors.opacity,
	},
	['FULL_SIGN']: {
		color: 'green',
	},
};

export const StatusSection = ({
	status,
	statusName,
	style,
	isMobile,
	onleyDot,
}: {
	status: any;
	style?: any;
	statusName: string;
	isMobile?: boolean;
	onleyDot?: boolean;
}) => {
	return (
		<View
			style={[
				styles.statusSection,
				isMobile && { flexDirection: 'row-reverse' },
			]}
		>
			<View
				style={[
					styles.statusDot,
					{ backgroundColor: statusColorMatcher[status]?.color },
				]}
			>
				{status === 'SECURITY_FAILED' && (
					<ImageIcon
						name={'redExclamationMark'}
						width={12}
						height={12}
					/>
				)}
			</View>
			{!onleyDot && (
				<View>
					<I18n size={14}>{statusName}</I18n>
				</View>
			)}
		</View>
	);
};

export const StatusOptions = [
	{
		id: 'AWAITING_SIGN',
		name: i18nService.translate('documents.filters.status.AWAITING_SIGN'),
	},
	{
		id: 'PENDING_SECURITY',
		name: i18nService.translate(
			'documents.filters.status.PENDING_SECURITY',
		),
	},
	{
		id: 'FULL_SIGN',
		name: i18nService.translate('documents.filters.status.FULL_SIGN.short'),
	},
	{
		id: 'SECURITY_FAILED',
		name: i18nService.translate('documents.filters.status.SECURITY_FAILED'),
	},
	{
		id: 'ABANDONED',
		name: i18nService.translate('documents.filters.status.ABANDONED.short'),
	},
];

export const SendDocumentModal = ({
	onPress,
	disabled,
	isMobile,
}: {
	onPress(): void;
	disabled?: boolean;
	isMobile?: boolean;
}) => {
	return (
		<TouchableHighlight
			underlayColor='transparent'
			disabled={disabled}
			style={{ opacity: disabled ? 0.7 : 1 }}
			onPress={onPress}
		>
			<View
				style={
					isMobile
						? styles.sendDocumentButtoMobile
						: styles.sendDocumentButton
				}
			>
				<Typography color={'white'} weight='500' size={16}>
					{i18nService.translate('documents.sendDocument.button')}
				</Typography>
			</View>
		</TouchableHighlight>
	);
};

export const CommentModal = ({ text }) => {
	const onShow = async (requestId?: number) => {
		await modalService.openModal(null, {}, (props: any) => (
			<View style={styles.commentContainer}>
				<View
					style={{
						width: 300,
						paddingHorizontal: 20,
						paddingVertical: 10,
						backgroundColor: colors.lightBlue,
						borderRadius: 5,
					}}
				>
					<I18n size={16} weight='normal'>
						{text}
					</I18n>
				</View>
			</View>
		));
	};
	return (
		<TouchableHighlight onPress={onShow} underlayColor='transparent'>
			<View style={styles.commentIconContainer}>
				<ImageIcon name={'message'} width={12} height={12} />
			</View>
		</TouchableHighlight>
	);
};
export const PenddingIndicator = () => {
	return (
		<View style={styles.penddingIndicator}>
			<I18n size={10} color='white'>
				documents.status.discription.AWAITING_SIGN
			</I18n>
		</View>
	);
};

export const Total = ({
	total,
	isMobile,
}: {
	total: number;
	isMobile: boolean;
}) => {
	return (
		<View style={styles.totalContainer}>
			<Typography
				size={isMobile ? 12 : 14}
				weight='bold'
				color={isMobile ? 'darkGrey' : 'primary'}
			>
				{total}
			</Typography>
			<I18n size={isMobile ? 12 : 14} style={{ paddingLeft: 3 }}>
				documents.total
			</I18n>
		</View>
	);
};

export const getHeaders = (
	isEmployer: boolean | undefined,
	businessUnitsList: any,
	isDisabled: boolean,
	setRemoveDoc: () => void,
	modules: any,
) => {
	return compact([
		businessUnitsList?.length > 1 &&
			isEmployer && {
				accessor: 'businessUnitName',
				Header: i18nService.translate('general.businessUnits'),
				isSortable: true,
			},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate('documents.table.header.lastUpdate'),
			isSortable: true,
			CustomCell: ({ row, column }: any) =>
				row.original.lastUpdate ? (
					<>
						{!isEmployer &&
							row.original.status === 'AWAITING_SIGN' && (
								<PenddingIndicator />
							)}
						<Typography size={14}>
							{row.original.lastUpdate}
						</Typography>
					</>
				) : null,
		},
		isEmployer && {
			accessor: 'teudatZeut',
			Header: i18nService.translate('documents.table.header.teudatZeut'),
			isSortable: true,
			CustomCell: ({ row, column }: any) =>
				row.original.teudatZeut ? (
					<Typography size={14}>{row.original.teudatZeut}</Typography>
				) : null,
		},
		isEmployer && {
			accessor: 'fullName',
			Header: i18nService.translate('documents.table.header.user'),
			isSortable: true,
			CustomCell: ({ row, column }: any) =>
				row.original.fullName ? (
					<Typography size={14}>{row.original.fullName}</Typography>
				) : null,
		},
		{
			accessor: 'type',
			Header: i18nService.translate('documents.table.header.docType'),
			isSortable: isEmployer ? true : false,
			CustomCell: ({ row, column }: any) => (
				<I18n size={14}>{row.original.type}</I18n>
			),
		},
		isEmployer && {
			accessor: 'clientStatus',
			Header: i18nService.translate('documents.table.header.status'),
			isSortable: true,
			CustomCell: ({ row, column }: any) => (
				<StatusSection
					status={row.original.status}
					statusName={
						row.original.clientStatus
							? row.original.clientStatus
							: i18nService.translate(
									`documents.filters.status.${row.original.status}`,
							  )
					}
				/>
			),
		},
		{
			accessor: 'fileName',
			Header: i18nService.translate('documents.table.header.document'),
			isSortable: isEmployer ? true : false,
			CustomCell: ({ row }: any) =>
				isEmployer ? (
					<DownloadSection
						data={row.original}
						disabled={isDisabled}
					/>
				) : (
					<DownloadEmployeeSection data={row.original} />
				),
		},
		!isDisabled &&
			isEmployer && {
				accessor: 'remainderButton',
				Header: '',
				isSortable: false,
				hide: true,
				CustomCell: ({ value, row }: any) => {
					return (
						<SendReminderSection
							data={row.original}
							disabled={isDisabled}
							isMobile={false}
							userId={row.original.userId}
						/>
					);
				},
			},
		!isDisabled &&
			isEmployer && {
				accessor: 'docsActionMenu',
				Header: '',
				isSortable: false,
				hide: true,
				CustomCell: ({ value, row }: any) => {
					const showMenu = modules[
						row.original?.businessUnitId
					]?.find(
						(module: any) =>
							module.id === 114 && module?.status === 'ACTIVE',
					)
						? row.original.identityType === 'ID'
							? true
							: false
						: false;
					return showMenu ? (
						<ActionButtonsMenu
							name={`${row.original.fullName} (${row.original.teudatZeut})`}
							userId={row.original.userId}
							unitId={row.original.businessUnitId}
							docId={row.original.id}
							showSendReminder={
								row.original.status === 'AWAITING_SIGN'
							}
							showDelete={
								row.original.status === 'ABANDONED' ||
								row.original.status === 'SECURITY_FAILED'
							}
							setRemoveDoc={setRemoveDoc}
						/>
					) : null;
				},
			},
	]);
};

export const openNewWindow = (url: any) => {
	const a = document.createElement('a');
	a.setAttribute('href', url);
	a.setAttribute('target', '_blank');
	a.click();
};

export const sendReminderToEmployee = (userId: number | undefined) => {
	httpService
		.api({
			type: 'sendReminderToEmployee',
			returnAllRes: true,
			params: { id: userId },
			disableBI: true,
		})
		.then(async (res: any) => {
			if (res.status === 200) {
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
						separator: true,
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n style={{ textAlign: 'center' }}>
								documents.modal.reminder.success
							</I18n>
						</AlertModal>
					),
				);
			}
		})
		.catch(async err => {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
					separator: true,
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n
							size={20}
							weight='bold'
							style={{
								textAlign: 'center',
								marginBottom: 15,
							}}
						>
							usersManagement.modal.documents.integration.error.title
						</I18n>
						<I18n style={{ textAlign: 'center' }}>
							usersManagement.modal.documents.integration.error.msg
						</I18n>
					</AlertModal>
				),
			);
		});
};
export const deleteDocumentApiModal = async (
	docId: number | null,
	setRemoveDoc: Function,
) =>
	await modalService.openModal(
		null,
		{
			onCancel: (callback: Function) => {
				callback();
			},
			onSubmit: (callback: Function) => {
				callback();
				deleteDocumentApi(docId, setRemoveDoc);
			},
			submitBtnText: 'general.true',
			cancelBtnText: 'general.false',
			iconName: 'question',
		},
		(props: any) => (
			<ModalConfirmActions {...props}>
				<I18n>documents.modal.delete.msg</I18n>
			</ModalConfirmActions>
		),
	);

export const deleteDocumentApi = (
	docId: number | null,
	setRemoveDoc: Function,
) => {
	httpService
		.api({
			type: 'deleteDocument',
			returnAllRes: true,
			params: { id: docId },
			disableBI: true,
		})
		.then(async (res: any) => {
			if (res.status === 200) {
				setRemoveDoc(true);
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
						separator: true,
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n style={{ textAlign: 'center' }}>
								documents.modal.delete.success
							</I18n>
						</AlertModal>
					),
				);
			}
		})
		.catch(async err => {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
					separator: true,
					height: 300,
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n style={{ textAlign: 'center' }}>
							documents.modal.delete.error
						</I18n>
					</AlertModal>
				),
			);
		});
};

export const ScreenSendDocumentsToEmployeeModal = async (
	id?: number,
	name?: string,
	unitId?: number,
) =>
	await modalService.openModal(
		null,
		{
			onCancel: (callback: Function) => {
				callback();
			},
			separator: true,
			loader: true,
			hideButtons: true,
			id,
			unitId,
			name,
			cancelBtnText: 'general.cancel',
			containerCustomStyle: {
				width: 750,
			},
			buttonsCustomStyleContainer: {
				justifyContent: 'flex-end',
			},
		},
		(props: any) => (
			<ModalConfirmActionsCustomStyle {...props}>
				<SendingDocumentContainer {...props} />
			</ModalConfirmActionsCustomStyle>
		),
	);

const styles = StyleSheet.create({
	statusSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	commentIconContainer: {
		backgroundColor: colors.lightPrimary,
		height: 18,
		width: 18,
		borderRadius: 9,
		alignItems: 'center',
		justifyContent: 'center',
		margin: 5,
		paddingTop: 2,
	},
	commentContainer: {
		paddingVertical: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		minHeight: 239,
		width: 334,
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	statusDot: {
		width: 12,
		height: 12,
		borderRadius: 6,
		marginLeft: 6,
	},
	sendDocumentButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 141,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	sendDocumentButtoMobile: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 45,
		width: 124,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	downloadSection: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
	},
	downloadTitle: {
		marginRight: 6,
	},
	penddingIndicator: {
		position: 'absolute',
		backgroundColor: '#eb8b2d',
		paddingTop: 3,
		width: 74,
		top: -3,
		right: 5,
		alignItems: 'center',
		borderBottomRightRadius: 7,
		borderBottomLeftRadius: 7,
	},
	totalContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: 15,
		marginBottom: 3,
	},
});
