import React, { useEffect, useState } from 'react';
import { View, Platform } from 'react-native';
import { WebView } from 'react-native-web-webview';
import { WebView as NativeWebView } from 'react-native-webview';
import { useNavigation, useRoute } from '@react-navigation/native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import colors from '../../config/colors';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import { instructionsEmployee } from './WebviewTemplates/InstructionsEmployee';
import { carInfo } from './WebviewTemplates/CarInfo';
import { noPaymentVacation } from './WebviewTemplates/NoPaymentVacation';
import { noPaymentVacationEmployee } from './WebviewTemplates/NoPaymentVacationEmployee';

const templateToPageName: any = {
	instructionsEmployee,
	noPaymentVacation,
	carInfo,
	noPaymentVacationEmployee,
};

const WebviewPage = () => {
	const route = useRoute();
	const navigation = useNavigation();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;

	return (
		<View style={{ flex: 1 }}>
			<HeaderContainer
				containerStyle={{ backgroundColor: colors.white }}
				navigation={navigation}
				component={isMobile ? MobileHeader : WebHeader}
			/>
			<View
				style={{
					flex: 1,
					padding: isMobile ? 10 : 0,
				}}
			>
				{Platform.OS === 'web' ? (
					<WebView
						originWhitelist={['*']}
						source={{
							html: templateToPageName[route.name],
						}}
						scrollEnabled={true}
					/>
				) : (
					<NativeWebView
						scalesPageToFit={true}
						automaticallyAdjustContentInsets={true}
						originWhitelist={['*']}
						source={{
							html: templateToPageName[route.name],
						}}
					/>
				)}
			</View>
		</View>
	);
};

export default WebviewPage;
