import moment from 'moment';
import React from 'react';
import { Dimensions, Platform } from 'react-native';
import I18n from '../components/shared/i18n/I18n.component';
import AlertModal from '../modals/AlertModal/AlertModal.component';
import { logout } from '../store/actions/login.actions';
import { dispatch, getState } from '../store/store';
import { modalService } from './modal.service';

class LogoutWhenNoAction {
	private intervalId: any;
	private waitingTime = 900000; //15 min
	private startTime: any;

	onBackFromBackground = () => {
		const timeDiff = moment().diff(this.startTime);
		if (this.startTime && timeDiff >= this.waitingTime) {
			if (this.intervalId) {
				clearInterval(this.intervalId);
			}
			this.onLogout();
		} else if (this.startTime) {
			this.startLogoutInterval({
				intervalTime: this.waitingTime - timeDiff,
			});
		}
	};

	startLogoutInterval = (obj?: any) => {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
		this.startTime = moment();
		this.intervalId = setTimeout(async () => {
			await this.onLogout();
		}, obj?.intervalTime || this.waitingTime);
	};

	resetStartTime = () => {
		this.startTime = undefined;
	};

	onLogout = async () => {
		await modalService.closeAllModal();

		if (getState().login.loggedIn) {
			dispatch(logout());

			setTimeout(async () => {
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
					},
					(props: any) => (
						<AlertModal
							height={
								Dimensions.get('window').height >
									Dimensions.get('window').width ||
								Platform.OS !== 'web'
									? 'auto'
									: undefined
							}
							{...props}
						>
							<I18n>genera.logoutWhenNoAction</I18n>
						</AlertModal>
					),
				);
			}, 1000);
		}
	};
}

const logoutWhenNoAction = new LogoutWhenNoAction();
export default logoutWhenNoAction;
