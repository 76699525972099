import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import Select from '../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import { Privileges } from '../../../constants/roleTypes.constant';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../services/i18n.service';
import { getEmployeeRolesPrivileges } from '../../../store/selectors/login.selectors';
import SiknessRequest from './RequestForm/SiknessRequest';
import VacationRequest from './RequestForm/VacationRequest';

interface RequestFieldsProps {
	onNext: any;
	validationDate: number;
	onrefresh?: () => void;
}

const CreateNewRequest = ({
	onNext,
	onrefresh = () => {},
	validationDate,
}: RequestFieldsProps) => {
	const [selectedRequestType, setSelectedRequestType] = useState('');
	const [from, setFrom] = useState(undefined);
	const [to, setTo] = useState(undefined);
	const [savedDescription, setSavedDescription] = useState(undefined);
	const platform = useDevicePlatform();
	const isMobile = platform === DeviceType.MOBILE;

	const [requestOptions, setRequestOptions] = useState([]);

	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

	useEffect(() => {
		checkPermisions();
	}, []);

	const checkPermisions = () => {
		const options = [];
		if (
			employeeRolesPrivileges?.includes(
				Privileges.ROLE_VACATION_REQUEST_WRITE,
			)
		) {
			options.push({
				id: 'VACATION',
				name: i18nService.translate(
					'applicationsToEmployerContainer.vacationRequest',
				),
			});
		}
		if (
			employeeRolesPrivileges?.includes(
				Privileges.ROLE_SICKNESS_REQUEST_WRITE,
			)
		) {
			options.push({
				id: 'SICKNESS',
				name: i18nService.translate(
					'applicationsToEmployerContainer.sicknessReport',
				),
			});
		}
		setRequestOptions(options);
	};

	const onSelectRequest = (requestType: string) => {
		setSelectedRequestType(requestType);
	};

	return (
		<View>
			<View style={styles.title}>
				<I18n size={24} weight='normal' color='darkGrey'>
					applicationsToEmployerContainer.createNewRequest
				</I18n>
			</View>
			<Select
				onChange={onSelectRequest}
				options={requestOptions}
				placeholder={'requestType'}
			/>

			{!!selectedRequestType ? (
				selectedRequestType === 'VACATION' ? (
					<VacationRequest
						onClose={onNext}
						from={from}
						setFrom={setFrom}
						to={to}
						setTo={setTo}
						savedDescription={savedDescription}
						setSavedDescription={setSavedDescription}
						onrefresh={onrefresh}
						validationDate={validationDate}
					/>
				) : selectedRequestType === 'SICKNESS' ? (
					<SiknessRequest
						onClose={onNext}
						from={from}
						setFrom={setFrom}
						to={to}
						setTo={setTo}
						savedDescription={savedDescription}
						setSavedDescription={setSavedDescription}
						onrefresh={onrefresh}
						validationDate={validationDate}
					/>
				) : (
					<View />
				)
			) : (
				<View />
			)}
		</View>
	);
};
const styles = StyleSheet.create({
	title: {
		marginBottom: 20,
	},
});

export default CreateNewRequest;
