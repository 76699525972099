import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import I18n from '../i18n/I18n.component';
import { FontSize, Weight } from '../../../interfaces/core.interface';

type DoubleButtonProps = {
	activeButton: number;
	onButtonClick: (buttonNumber: number) => void;
	stylesConf: {
		width: number;
		height: number;
		textSize: number;
		textWeight?: string;
		borderColor?: string;
	};
	leftButtonText: string;
	rightButtonText: string;
};

export const DoubleButton = ({
	activeButton,
	onButtonClick,
	stylesConf,
	leftButtonText,
	rightButtonText,
}: DoubleButtonProps) => {
	return (
		<View
			style={[
				styles(stylesConf).buttonsContainer,
				{ height: stylesConf?.height },
			]}
		>
			<TouchableOpacity
				style={[
					activeButton
						? styles(stylesConf).BTL
						: styles(stylesConf).BTLA,
					{
						borderColor: stylesConf?.borderColor
							? stylesConf?.borderColor
							: '#EDF3F7',
					},
				]}
				onPress={() => {
					onButtonClick(0);
				}}
				//labelStyle={{ marginHorizontal: 0, marginVertical: 0 }}
			>
				<I18n
					size={stylesConf.textSize as FontSize}
					weight={(stylesConf.textWeight as Weight) || '400'}
					color={activeButton ? 'darkGrey' : 'white'}
					style={{}}
				>
					{leftButtonText}
				</I18n>
			</TouchableOpacity>
			<TouchableOpacity
				style={[
					activeButton
						? styles(stylesConf).BTR
						: styles(stylesConf).BTRA,
					{
						borderColor: stylesConf?.borderColor
							? stylesConf?.borderColor
							: '#EDF3F7',
					},
				]}
				onPress={() => {
					onButtonClick(1);
				}}
				//labelStyle={{ marginHorizontal: 0, marginVertical: 0 }}
			>
				<I18n
					size={stylesConf.textSize as FontSize}
					weight={(stylesConf.textWeight as Weight) || '400'}
					color={activeButton ? 'white' : 'darkGrey'}
				>
					{rightButtonText}
				</I18n>
			</TouchableOpacity>
		</View>
	);
};

export const styles = (props: { width: number; height: number }) =>
	StyleSheet.create({
		buttonsContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
		},
		BTL: {
			width: props.width,
			height: props.height,
			backgroundColor: 'white',
			borderWidth: 3,
			borderTopLeftRadius: 50,
			borderBottomLeftRadius: 50,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginHorizontal: 0,
			marginVertical: 0,
		},
		BTLA: {
			width: props.width,
			height: props.height,
			backgroundColor: '#3B70A4',
			borderWidth: 3,
			borderTopLeftRadius: 50,
			borderBottomLeftRadius: 50,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginHorizontal: 0,
			marginVertical: 0,
		},
		BTR: {
			width: props.width,
			height: props.height,
			backgroundColor: '#3B70A4',
			borderWidth: 3,
			borderTopRightRadius: 50,
			borderBottomRightRadius: 50,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginHorizontal: 0,
			marginVertical: 0,
		},
		BTRA: {
			width: props.width,
			height: props.height,
			backgroundColor: 'white',
			borderWidth: 3,
			borderTopRightRadius: 50,
			borderBottomRightRadius: 50,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginHorizontal: 0,
			marginVertical: 0,
		},
	});
