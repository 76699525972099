import { VacationReport } from '../interfaces/redux.interface';
export class VacationReportDepartmentModel implements VacationReport{
	public id: string;
    public name: string;
    public prev_balance: number;
    public refund_days: number;
    public used: number;
    public new_balance: number;
    public periods: Omit<VacationReport, 'id'>[]
    public teudat_zeut?: string;
    public business_unit_name: string;

	constructor(props: any) {
		this.id = props.id;
		this.name = props.name;
		this.prev_balance = props.prev_balance;
        this.refund_days = props.refund_days;
        this.used = props.used;
        this.new_balance = props.new_balance;
		this.periods = props.periods;
        this.business_unit_name = props.business_unit_name
	}
}

export class VacationReportEmployeeModel {
	public id: string;
    public name: string;
    public prev_balance: number;
    public refund_days: number;
    public used: number;
    public new_balance: number;

	constructor(props: any) {
		this.id = props.id;
		this.name = props.name + ` (${props.teudat_zeut})`;
		this.prev_balance = props.prev_balance;
        this.refund_days = props.refund_days;
        this.used = props.used;
        this.new_balance = props.new_balance;
	}
}
