import moment from 'moment';
import { Platform } from 'react-native';
import { Period } from '../interfaces/redux.interface';
import { i18nService } from '../services/i18n.service';

export const parsePeriod = (name: string) => {
	if (name.length === 4) {
		return {
			year: [Number(name)]
		}
	} else {
		if (name.includes('_')) {
			const parsedDate = name.split('_');
			return {
				year: [Number(parsedDate[0])],
				quarter: [Number(parsedDate[1])]
			}
		} else {
			return {
				year: [Number(name.slice(0, 4))],
				month: [name.slice(4)]
			}
		}
	}
}

export const getMonthFromDate = (name: string): string => {
	const parsedPeriod = moment(name);
	return parsedPeriod.format('MMMM')
}

export const prepareRowNameForClient = (value: string, isShort?: boolean): string => {
	let parsedValue;
	const parsedPeriod = parsePeriod(value);
	if ('month' in parsedPeriod) {
		const month = getMonthFromDate(value);
		if (isShort) {
			return parsedValue =
				i18nService.translate(`shortMonths.${month}`)
				+ ' '
				+ parsedPeriod.year;
		}
		return parsedValue =
			i18nService.translate(`months.${month}`)
			+ ' '
			+ parsedPeriod.year;
	} else if ('quarter' in parsedPeriod) {
		return parsedValue =
			i18nService.translate('quarter')
			+ ' '
			+ i18nService.translate('number')
			+ ' '
			+ parsedPeriod.quarter
			+ ' ' +
			parsedPeriod.year
			;
	} else {
		return parsedValue = String(parsedPeriod.year);
	}
}

export const addToTableCellCurrencySymbol = (row: any): any => {
	const unnecessaryFields = ['name', 'periods', 'id', 'expander', 'business_unit_name'];
	for (const field in row) {
		if (!unnecessaryFields.includes(field)) {
			row[field] =  `${row[field]?.replace('-', '')}${(field?.includes('-') && row[field]?.includes('-') ? '%' : '')}${row[field]?.includes('-') ? '-' : ''}${field?.includes('-') ? !row[field]?.includes('-') ? '%' : '' : ' ₪'}`;
		}
	}

	return row;
}

export const addCommaSeparatorToNumber = (number: any) => {
	if (number > 999 && Platform.OS !== 'android') {
		return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return number?.toFixed(2);
}

export const fixMinus = (number: any) => {
	let newNumber = number?.toFixed(2);
	
	if (number < 0) {
		newNumber = `${newNumber.replace('-', '')}-`
	}

	return newNumber;
}

export const addCommaSeparatorToNumberAndFixMinus = (number: any) => {
	let newNumber = number?.toFixed(2);
	if (number > 999 || number < -999) {
		newNumber = number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	if (number < 0) {
		newNumber = `${newNumber.replace('-', '')}-`
	}

	return newNumber;
}

export const parseLastDate = (date: string) => {
	if (!date) {
		return {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1
		};
	}

	const array = date.split('-');
	let month: string | number = array[1]
	if (array[1][0] === '0') {
		month = Number(array[1][1]);
	} else {
		month = Number(array[1])
	}
	return {
		year: Number(array[0]),
		month: month
	};
}

export function getPeriodNumber(month: number): number {
    if (month > 0 && month < 4) {
        return 1;
    }

    if (month > 3 && month < 7) {
        return 2;
    }

    if (month > 6 && month < 10) {
        return 3;
    }

    if (month > 9 && month < 13) {
        return 4;
    }
    //set default
    return 1;
}

export const getMonthByQuaterNumber = (quarterNumber: number) => {
	switch (quarterNumber) {
		case 1: 
			return 1;
		case 2: 
			return  4;
		case 3:
			return 7;
		case 4:
			return 10;
		default:
			return 1;
	}
}

export function getDateFrom(dateObj: {
    year: number,
    month: number
}, periodType: string, quarterNumber?: number): any {
    switch (periodType) {
        case Period.MONTH: 
            return {
                year: dateObj.year,
                month: dateObj.month
            }
        case Period.QUARTERLY: 
            let month;
            switch (quarterNumber) {
                case 1: 
                    month = 1;
                    break;
                case 2: 
                    month = 4;
                    break;
                case 3:
                    month = 7;
                    break;
                case 4:
                    month = 10;
                    break;
                default:
                    month = 1;
                    break;
            }
            return {
                year: dateObj.month === 1 ? dateObj.year - 1 : dateObj.year,
                month: month
            }
        case Period.YEAR: {
            return {
                year: dateObj.year,
                month: 1
            }
        }
        default:
            break;
    }
}

export const reverseNumberWithPresentage = (str: string) =>
	str.replaceAll(/([0-9])*%/g, (number: string) =>
		number.split('').reverse().join(''),
	);