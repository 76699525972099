import React from 'react';
import I18n from '../../components/shared/i18n/I18n.component';
import {
	Dimensions,
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	View,
} from 'react-native';
import colors from '../../config/colors';
import { TouchableHighlight } from 'react-native';
import Typography from '../../components/shared/Typography/Typography.component';
import getShadowStyle from '../../config/shadow';
import moment from 'moment';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
interface IEmployeeList {
	title?: string;
	containerStyle?: any;
	selectedEmployer: { id: null | string; name: string };
	employers: any[];
	onPress: (employer: { id: string; name: string }) => void;
}

const EmployeeListComponent = ({
	title,
	containerStyle,
	employers,
	selectedEmployer,
	onPress,
}: IEmployeeList) => {
	const container = StyleSheet.flatten([styles.root, containerStyle]);

	return (
		<>
			<KeyboardAvoidingView
				nativeID='EmployerListComponent'
				style={container}
				behavior={Platform.OS === 'android' ? 'height' : 'padding'}
			>
				{title && (
					<I18n style={styles.title} size={18} weight='normal'>
						{title}
					</I18n>
				)}
				<ScrollView style={styles.list}>
					{employers.map(employer => (
						<View style={styles.containerStyle} key={employer.id}>
							<TouchableHighlight
								underlayColor='none'
								accessibilityRole='button'
								onPress={() => onPress(employer)}
							>
								<View
									style={[
										styles.buttonStyle,
										{
											backgroundColor:
												selectedEmployer?.id ===
												employer?.id
													? colors.primary
													: colors.white,
										},
									]}
								>
									<Typography
										style={styles.centeredText}
										color={
											selectedEmployer?.id ===
											employer?.id
												? 'white'
												: 'primary'
										}
										size={18}
										weight='normal'
										numberOfLines={1}
									>
										{employer.name}
									</Typography>
									{employer.finish_work ? (
										<View
											style={{
												flexDirection: 'row-reverse',
												marginTop: 3,
											}}
										>
											<I18n
												size={12}
												// color='white'
												weight='normal'
												style={{ marginLeft: 5 }}
											>
												general.endWorkDate
											</I18n>
											<I18n size={12} weight='normal'>
												{moment(
													employer.finish_work,
													'YYYY-MM-DD',
												).format('DD/MM/YYYY')}
											</I18n>
										</View>
									) : null}
								</View>
							</TouchableHighlight>
						</View>
					))}
					{!employers.length ? (
						<I18n style={styles.noResultsFoundText}>
							general.noResultsFound
						</I18n>
					) : null}
				</ScrollView>
			</KeyboardAvoidingView>
		</>
	);
};

const styles = StyleSheet.create({
	root: {
		width: '100%',
		height: windowHeight * 0.5,
		backgroundColor: colors.white,
		borderRadius: 20,
		padding: 30,
	},
	title: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	searchContainer: {
		height: 34,
		backgroundColor: colors.lightBlue,
		marginBottom: 12,
	},
	list: {
		flex: 1,
		marginTop: 10,
		width: '100%',
	},
	noResultsFoundText: {
		height: 35,
		position: 'relative',
		top: '50%',
		alignSelf: 'center',
	},
	buttonStyle: {
		backgroundColor: colors.white,
		...getShadowStyle(12, 'rgb(59, 112, 164)'),
		paddingHorizontal: 28,
		height: 72,
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	containerStyle: {
		width: '100%',
		marginVertical: 8,
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	centeredText: {
		textAlign: 'center',
	},
});
export default EmployeeListComponent;
