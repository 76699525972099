import React, { useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ReactDatePicker from '../../../../components/web/ReactDatePicker/ReactDatePicker.component';
import SelectContainer from '../../../../components/web/Select/Select.container';
import { Privileges } from '../../../../constants/roleTypes.constant';
import httpService from '../../../../services/http.service';
import { i18nService } from '../../../../services/i18n.service';
import { getUserData } from '../../../../store/selectors/login.selectors';
import Card from '../Card/Card.component';
import { getDefaultYearlyFilter } from '../RepHomePage.utils';

const YearlyData = ({ filters, customersFullDataJson }: any) => {
	const [localFilters, setLocalFilters] = useState<any>();
	const [data, setData] = useState<any>();
	const userData = useSelector(getUserData);

	const display126Report = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ORG_126_REPORT_READ,
			) ||
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ORG_126_REPORT_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	const display106Report = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_FORM_106_REPORT_READ,
			) ||
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_FORM_106_REPORT_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	const frequencyOptions = useMemo(
		() => [
			{
				id: 'SEMIANNUAL',
				name: i18nService.translate(
					'repDashboard.yearly.type.SEMIANNUAL',
				),
			},
			{
				id: 'ANNUAL',
				name: i18nService.translate('repDashboard.yearly.type.ANNUAL'),
			},
			{
				id: 'FINAL',
				name: i18nService.translate('repDashboard.yearly.type.FINAL'),
			},
		],
		[],
	);

	const getData = async () => {
		const res = await httpService.api({
			type: 'getRepDashboardYearlyData',
			data: { f: { ...filters, ...localFilters } },
		});
		setData(res);
	};

	useEffect(() => {
		const tempFilters = sessionStorage.getItem('repDashboardYearly');

		if (tempFilters && tempFilters !== '') {
			setLocalFilters(JSON.parse(tempFilters));
		} else {
			setLocalFilters({
				frequency: [frequencyOptions[0].id],
				year: getDefaultYearlyFilter(),
			});
		}
	}, []);

	useEffect(() => {
		if (localFilters?.year.length) {
			getData();
		}
	}, [filters, localFilters]);

	const onChange = ({ name, value }: any) => {
		const newData: any = { ...localFilters };
		if (name === 'year') {
			newData.year = [value.getFullYear()];
		} else {
			newData.frequency = [value];
		}

		sessionStorage.setItem('repDashboardYearly', JSON.stringify(newData));
		setLocalFilters(newData);
	};

	return (
		<View style={{ paddingVertical: 30 }}>
			<View
				style={{
					flexDirection: 'row-reverse',
					alignItems: 'baseline',
					marginBottom: 24,
				}}
			>
				<I18n weight='normal' size={36} style={{ marginLeft: 5 }}>
					repDashboard.yearly
				</I18n>
				<I18n size={14} weight='400'>
					repDashboard.units
				</I18n>
			</View>
			<View
				style={{
					zIndex: 100,
					flexDirection: 'row-reverse',
				}}
			>
				<View
					style={{
						width: 130,
						zIndex: 100,
						flexDirection: 'row-reverse',
					}}
				>
					<ReactDatePicker
						onChange={onChange}
						//key={`${localFilters?.year}`}
						config={
							{
								name: 'year',
								type: '',
								date:
									localFilters && new Date(localFilters.year),
								dashboardViewMode: true,
								showYearPicker: true,
								placeholder: localFilters?.year?.[0],
							} as any
						}
					/>
				</View>
				<View
					style={{
						width: 130,
						zIndex: 100,
						flexDirection: 'row-reverse',
					}}
				>
					<SelectContainer
						onChange={onChange}
						config={{
							hideDefaultOption: true,
							name: 'frequency',
							options: frequencyOptions,
							key: `${localFilters?.frequency}`,
						}}
						defaultValue={localFilters?.frequency?.[0]}
					/>
				</View>
			</View>
			<View
				key={JSON.stringify(localFilters?.frequency)}
				style={{
					marginTop: 16,
					flexDirection: 'row-reverse',
					...Platform.select({ web: { gap: 24 } }),
				}}
			>
				{display126Report && (
					<Card
						title='repDashboard.yearly.126BL'
						data={data?.socialSecurity126}
						width={
							localFilters?.frequency?.[0] === 'SEMIANNUAL' ||
							(!display106Report &&
								localFilters?.frequency?.[0] !== 'FINAL')
								? 'calc(50% - 75px)'
								: null
						}
						colorsObj={{
							NO_RESULTS: 'hsl(360, 79%, 63%)',
							BEFORE_TRANS: '#EB8B2E',
							TRANS_PENDING_CHECK: '#F2C94C',
							TRANS_VALID: 'hsl(145, 50%, 62%)',
						}}
						navigateTo='repOrg126Reports'
						defaultParams={{
							...localFilters,
							...filters,
							type: ['SOCIAL_SECURITY'],
						}}
						moreParams={{
							customersFullData: customersFullDataJson,
						}}
					/>
				)}
				{display106Report
					? localFilters?.frequency?.[0] !== 'SEMIANNUAL' && (
							<Card
								title='repDashboard.yearly.106'
								data={data?.form106}
								colorsObj={{
									NO_RESULTS: 'hsl(360, 79%, 63%)',
									HAS_FORMS: 'hsl(145, 50%, 62%)',
								}}
								width={
									!display126Report
										? 'calc(50% - 75px)'
										: null
								}
								customKeys={{
									NO_RESULTS: false,
									HAS_FORMS: true,
								}}
								navigateTo='repForm106Reports'
								defaultParams={{ ...localFilters, ...filters }}
								moreParams={{
									customersFullData: customersFullDataJson,
								}}
							/>
					  )
					: localFilters?.frequency?.[0] === 'FINAL' &&
					  display126Report && (
							<Card
								title='repDashboard.yearly.126MS'
								data={data?.irs126}
								colorsObj={{
									NO_RESULTS: 'hsl(360, 79%, 63%)',
									BEFORE_TRANS: '#EB8B2E',
									TRANS_PENDING_APPROVE: '#F2C94C',
									TRANS_APPROVE: 'hsl(145, 50%, 62%)',
								}}
								navigateTo='repOrg126Reports'
								defaultParams={{
									...localFilters,
									...filters,
									type: ['IRS'],
								}}
								moreParams={{
									customersFullData: customersFullDataJson,
								}}
							/>
					  )}
			</View>
			<View
				style={{
					marginTop: 24,
					flexDirection: 'row-reverse',
					...Platform.select({ web: { gap: 24 } }),
				}}
			>
				{localFilters?.frequency?.[0] === 'FINAL' &&
					display126Report &&
					display106Report && (
						<Card
							title='repDashboard.yearly.126MS'
							data={data?.irs126}
							colorsObj={{
								NO_RESULTS: 'hsl(360, 79%, 63%)',
								BEFORE_TRANS: '#EB8B2E',
								TRANS_PENDING_APPROVE: '#F2C94C',
								TRANS_APPROVE: 'hsl(145, 50%, 62%)',
							}}
							width='calc(50% - 75px)'
							navigateTo='repOrg126Reports'
							defaultParams={{
								...localFilters,
								...filters,
								type: ['IRS'],
							}}
							moreParams={{
								customersFullData: customersFullDataJson,
							}}
						/>
					)}
			</View>
		</View>
	);
};

export default YearlyData;
