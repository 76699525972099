const OptionalDeductions = (data: any) => {
	const format: any = {};
	const initialValues: any = {};
	if (!data) return { format, initialValues };
	data.forEach((element: any) => {
		format[`${element.componentType}_${element.code}`] = {};
		format[`${element.componentType}_${element.code}`].data = {
			code: element.code,
			componentType: 'optionalDeductionsComponent',
		};

		format[`${element.componentType}_${element.code}`].header = [
			{
				name: 'optional',
				type: 'header',
				code: element.code,
				value: `${element.code} - ${element.name}`,
			},
		];

		element.employees?.forEach((item: any, index: number) => {
			initialValues[`${item.id}_${element.code}_optional`] = item.value;
			format[`${element.componentType}_${element.code}`][
				`user_${item.id}`
			] = [
				{
					id: item.id,
					name: `${item.id}_${element.code}_optional`,
					placeholder: '0.00',
					type: 'input',
					value: item.value || '',
					order: index,
				},
			];
		});
	});
	return { format, initialValues };
};

export default OptionalDeductions;
