import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation, useRoute } from '@react-navigation/native';
import { compact } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'react-native-paper';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import { Privileges } from '../../../../constants/roleTypes.constant';
import { modalService } from '../../../../services/modal.service';
import { getUserData } from '../../../../store/selectors/login.selectors';
import { getDocumentTitle } from '../../../../utils/globals.utils';
import { updateCustomerStatus } from '../EditCustomer/EditCustomer.utils';
import EditCustomerContainer from '../EditCustomer/EditCustomer.container';
import BusinessUnits from './BusinessUnits/BusinessUnits.component';
import Files from './Files/Files';
import { setRepEnterCustomerMode } from '../../../../store/actions/repEnterMode.actions';
import httpService from '../../../../services/http.service';

const Tab = createBottomTabNavigator();
const tabs = [
	'businessUnits',
	// , 'files'
];

const CustomerDetails = ({
	customerData,
	canEditCustomer,
	customerId,
	updateData,
}: any) => {
	const [selectedTab, setSelectedTab] = useState('businessUnits');
	const route: any = useRoute();
	const navigation = useNavigation();
	const dispatch = useDispatch();
	const customerDataRef = useRef<any>(customerData);
	customerDataRef.current = customerData;

	const userData = useSelector(getUserData);

	const canEnterCustomer = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ACCESS_CUSTOMER_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	useEffect(() => {
		if (route.params?.screen) setSelectedTab(route.params?.screen);
	}, []);

	const detailsConfig = useMemo(
		() =>
			!customerData
				? []
				: [
						{ key: 'address' },
						{ key: 'phone' },
						{ key: 'tikNikuim', title: 'tikNikuim' },
						{ key: 'licensedNumber', title: 'osekMurshe' },
				  ].filter((item: any) => !!customerData[item.key]),
		[customerData],
	);

	const extraDetailsConfig = useMemo(
		() =>
			!customerData
				? []
				: [
						{
							fields: [
								'contactName',
								'contactEmail',
								'contactPhone',
							].filter((item: any) => !!customerData[item]),
							title: 'customerDetails.contactDetails',
						},
						{
							fields: [
								'operatorName',
								'operatorEmail',
								'operatorCell',
							].filter((item: any) => !!customerData[item]),
							title: 'customerDetails.operatorDetails',
						},
				  ],
		[customerData],
	);

	const buttons = useMemo(
		() =>
			compact([
				canEditCustomer && {
					name: `set.status${
						customerData?.status ? 'False' : 'True'
					}`,
					action: () => {
						const updatedData = {
							...customerData,
							status: !customerData?.status,
						};
						updateCustomerStatus(
							customerId,
							!customerData?.status,
							() => updateData(updatedData),
						);
					},
					icon: customerData?.status ? 'remove' : 'checkedMark',
				},
				canEditCustomer && {
					name: 'update',
					action: () => {
						modalService.openModal(
							null,
							{ id: customerId, callback: updateData },
							EditCustomerContainer,
						);
					},
					icon: 'edit',
				},
				canEnterCustomer &&
					customerData?.businessUnits?.length && {
						name: 'enter',
						action: async () => {
							const accessData: any = await httpService.api({
								type: 'getRepEnterData',
								params: { customerId },
								customerHp: customerData.hp || customerData.id,
								customerId,
							});
							dispatch(
								setRepEnterCustomerMode({
									...customerData,
									customer_privilege: accessData,
									customerId,
								}),
							);
						},
						icon: 'enterCustomer',
					},
			]),
		[canEditCustomer, canEnterCustomer, customerId, customerData],
	);

	const updateBusinessUnitsData = (data: any) => {
		updateData({ ...customerDataRef.current, ...data });
	};

	return (
		<View
			style={{
				flex: 1,
				paddingHorizontal: 16,
				paddingVertical: 19,
				marginRight: 9,
				backgroundColor: colors.white,
			}}
		>
			<View
				style={{ flexDirection: 'row-reverse', alignItems: 'center' }}
			>
				<View style={{ flex: 1 }}>
					<View
						style={{
							flexDirection: 'row-reverse',
							marginBottom: 5,
							alignItems: 'baseline',
						}}
					>
						<Typography size={18} weight='normal'>
							{customerData?.name}
						</Typography>
						<I18n
							size={14}
							weight='normal'
							style={{ marginRight: 9 }}
							color={customerData?.status ? 'green' : 'red'}
						>
							{`general.${
								customerData?.status ? 'active' : 'inactive'
							}`}
						</I18n>
					</View>
					{detailsConfig.length ? (
						<View
							style={{
								flexDirection: 'row-reverse',
								marginTop: 9,
							}}
						>
							{detailsConfig.map((item: any, index: number) => (
								<>
									{item.title && (
										<I18n
											size={14}
											weight='400'
											style={{ marginLeft: 5 }}
										>{`customerDetails.${item.title}`}</I18n>
									)}
									<Typography size={14} weight='400'>
										{customerData?.[item.key]}
									</Typography>
									{index !== detailsConfig.length - 1 && (
										<View
											style={{
												width: 1,
												height: 17,
												backgroundColor:
													colors.darkGrey,
												marginHorizontal: 7,
											}}
										/>
									)}
								</>
							))}
						</View>
					) : null}
					{extraDetailsConfig.map((extraDetail: any) =>
						extraDetail.fields.length ? (
							<View
								style={{
									flexDirection: 'row-reverse',
									marginTop: 9,
								}}
								key={extraDetail.title}
							>
								<I18n
									size={14}
									weight='bold'
									style={{ marginLeft: 5 }}
								>
									{extraDetail.title}
								</I18n>
								{extraDetail.fields.map(
									(item: any, index: number) => (
										<>
											{item.includes('Email') ? (
												<a
													href={`mailto:'${customerData?.[item]}'`}
													style={{
														color: colors.darkGrey,
													}}
												>
													<Typography
														size={14}
														weight='400'
													>
														{customerData?.[item]}
													</Typography>
												</a>
											) : (
												<Typography
													size={14}
													weight='400'
												>
													{customerData?.[item]}
												</Typography>
											)}
											{index !==
												extraDetail.fields.length -
													1 && (
												<View
													style={{
														width: 1,
														height: 17,
														backgroundColor:
															colors.darkGrey,
														marginHorizontal: 7,
													}}
												/>
											)}
										</>
									),
								)}
							</View>
						) : null,
					)}
				</View>
				{buttons.map((button: any) => (
					<TouchableOpacity
						style={{ marginRight: 35, alignItems: 'center' }}
						key={button.name}
						onPress={button.action}
					>
						<ImageIcon name={button.icon} width={20} height={20} />
						<I18n
							size={14}
							weight='normal'
							style={{ marginTop: 9 }}
						>{`customerDetails.${button.name}`}</I18n>
					</TouchableOpacity>
				))}
			</View>

			<View style={styles.tabs}>
				{tabs.map(tab => (
					<TouchableOpacity
						key={tab}
						onPress={() => {
							setSelectedTab(tab);
							navigation.navigate(tab);
						}}
						style={[
							styles.button,
							{
								borderBottomColor:
									tab === selectedTab
										? colors.primary
										: colors.white,
							},
						]}
					>
						<I18n size={14} weight='normal'>
							{`customerDetails.${tab}`}
						</I18n>
					</TouchableOpacity>
				))}
			</View>
			<Divider
				style={{
					backgroundColor: colors.primary,
					opacity: 0.5,
				}}
			/>
			<Tab.Navigator
				tabBar={() => null}
				sceneContainerStyle={{ backgroundColor: colors.white }}
				initialRouteName='presence'
				screenOptions={{ unmountOnBlur: true }}
			>
				{tabs.map((item, index) => {
					const Component = item;
					return (
						<Tab.Screen
							options={{
								title: getDocumentTitle(item),
								unmountOnBlur: true,
							}}
							name={item}
							children={() =>
								item === 'businessUnits' ? (
									<BusinessUnits
										businessUnits={
											customerData?.businessUnits
										}
										customerId={{ customerId }}
										updateData={updateBusinessUnitsData}
									/>
								) : (
									<Files />
								)
							}
						/>
					);
				})}
			</Tab.Navigator>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		marginVertical: 27,
		marginHorizontal: 70,
		backgroundColor: colors.white,
		paddingHorizontal: 40,
		paddingTop: 30,
		paddingBottom: 30,
	},
	tabs: {
		flexDirection: 'row-reverse',
		marginTop: 25,
	},
	button: {
		width: 150,
		borderBottomWidth: 6,
		alignItems: 'center',
		padding: 3,
	},
});

export default CustomerDetails;
