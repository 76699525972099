import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import SolidButtonContainer from '../../../../components/shared/Buttons/SolidButton.container';
import Select from '../../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import { Privileges } from '../../../../constants/roleTypes.constant';
import httpService from '../../../../services/http.service';
import { i18nService } from '../../../../services/i18n.service';
import { fetchEmployeesFilterList } from '../../../../store/actions/reportsFilters.actions';
import { hasPermissionOnBusinessUnit } from '../../../../utils/globals.utils';
import {
	EmployeeSelector,
	ProjectSelector,
	searchLocation,
} from './ExistingLocationSettingsModal.utils';

interface Props {
	usersList: [];
	locationsList: any[];
	projectsList: any;
	onNext: Function;
	refresh(): void;
	unitIds?: number[];
	businessUnitsList: any[];
}

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const ExistingLocationSettingsModal = ({
	locationsList,
	projectsList,
	onNext,
	refresh,
	unitIds,
	businessUnitsList,
}: Props) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [projectList, setProjectList] = useState(projectsList);
	const [locationList, setLocationsList] = useState(locationsList);

	const [userIds, setUserIds] = useState([]);
	const [locationIds, setLocationIds] = useState([]);
	const [projectLocationIds, setProjectLocationIds] = useState([]);
	const [projectIds, setProjectIds] = useState([]);
	const [disableSave, setDisableSave] = useState(true);
	const [asyncSelectedUsers, setAsyncSelectedUsers] = useState([]);
	const [unitId, setUnitId] = useState(unitIds?.length === 1 && unitIds?.[0]);

	useEffect(() => {
		let disable = true;
		if (!!projectLocationIds.length && !!projectIds.length) {
			if (!!locationIds.length === !!userIds.length) {
				disable = false;
			}
		}
		if (!!locationIds.length && !!userIds.length) {
			if (!!projectLocationIds.length === !!projectIds.length) {
				disable = false;
			}
		}
		setDisableSave(disable);
	}, [userIds, projectIds, locationIds, projectLocationIds]);

	useEffect(() => {
		if (unitId) {
			onSwitchTab(0);
			gerProjectsList();
			(async () => {
				const res = await searchLocation('', unitId);
				setLocationsList(res);
			})();
		}
	}, [unitId]);

	const gerProjectsList = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getUnitProjectsFilter',
				query: {
					pageName: 'locationSettings',
					unitIds: unitId,
				},
			});

			if (res) {
				setProjectList(res);
			}
		} catch (e) {}
	};

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res = await fetchEmployeesFilterList('locationSettings', {
			page,
			pageSize: 20,
			search,
			filter: { dep: [], internal: ['true'] },
			unitIds: [unitId],
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: 20 === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const onSave = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'associateLocation',
				data: {
					locationIds,
					userIds,
					projectLocationIds,
					projectIds,
					unitId,
				},
			});
			onNext(refresh());
		} catch (e) {}
	};

	const onSwitchTab = (index: number) => {
		setActiveIndex(index);
		setUserIds([]);
		setLocationIds([]);
		setProjectLocationIds([]);
		setProjectIds([]);
	};

	return (
		<View
			style={[
				styles.container,
				{ height: windowHeight - 170, width: windowWidth - 550 },
			]}
		>
			<I18n
				size={30}
				weight='normal'
				style={{ paddingBottom: 5, marginLeft: 20 }}
			>
				{`${i18nService.translate(
					'existingLocationSettingsModal.title',
				)} ${
					projectsList.length
						? i18nService.translate(
								'existingLocationSettingsModal.titleOrProject',
						  )
						: ''
				}`}
			</I18n>
			<View style={styles.separator} />
			<View
				style={{
					zIndex: 1,
					flexDirection: 'row-reverse',
					backgroundColor: colors.white,
					alignItems: 'center',
					paddingTop: 15,
					marginLeft: 300,
				}}
			>
				{businessUnitsList.length > 1 ? (
					<>
						<I18n style={{ width: 180 }} size={18} weight='normal'>
							general.businessUnits
						</I18n>
						<Select
							options={businessUnitsList}
							onChange={setUnitId}
							//horizontalPadding={60}
							value={unitId}
						/>
					</>
				) : null}
			</View>
			{unitId ? (
				<>
					<View style={styles.tabsContainer}>
						<TouchableOpacity
							onPress={() => onSwitchTab(0)}
							style={[
								styles.buttonTab,
								{
									borderBottomColor:
										activeIndex === 0
											? colors.primary
											: colors.white,
								},
							]}
						>
							<I18n weight='normal'>
								existingLocationSettingsModal.employees
							</I18n>
						</TouchableOpacity>

						{projectList.length ? (
							<TouchableOpacity
								onPress={() => onSwitchTab(1)}
								style={[
									styles.buttonTab,
									{
										borderBottomColor:
											activeIndex === 1
												? colors.primary
												: colors.white,
									},
								]}
							>
								<I18n weight='normal'>
									existingLocationSettingsModal.projects
								</I18n>
							</TouchableOpacity>
						) : (
							<View />
						)}
					</View>

					<View
						style={[
							styles.tabContainer,
							{ height: windowHeight - 440 },
						]}
					>
						{activeIndex === 0 ? (
							<EmployeeSelector
								locations={locationList}
								userIds={userIds}
								locationIds={locationIds}
								setUserIds={setUserIds}
								setLocationIds={setLocationIds}
								asyncSelected={asyncSelectedUsers}
								setAsyncSelected={val =>
									setAsyncSelectedUsers(val)
								}
								getEmployeesFilter={getEmployeesFilter}
								unitId={unitId}
							/>
						) : projectList.length ? (
							<ProjectSelector
								projects={projectList}
								locations={locationList}
								projectIds={projectIds}
								locationIds={projectLocationIds}
								setProjectLocationIds={setProjectLocationIds}
								setProjectIds={setProjectIds}
								unitId={unitId}
							/>
						) : (
							<View />
						)}
					</View>
				</>
			) : null}
			<View
				style={{
					flexDirection: 'row-reverse',
					marginVertical: 15,
					zIndex: -1,
				}}
			>
				<SolidButtonContainer
					size={'medium'}
					onPress={() => onSave()}
					disabled={
						disableSave ||
						(!hasPermissionOnBusinessUnit(
							unitId,
							Privileges.ROLE_LOCATION_ASSOCIATION_WRITE,
						) &&
							!hasPermissionOnBusinessUnit(
								unitId,
								Privileges.ROLE_LOCATION_WRITE,
							))
					}
				>
					general.save
				</SolidButtonContainer>
				<SolidButtonContainer
					size={'medium'}
					textOptions={{ color: colors.lightPrimary }}
					overrideStyle={styles.cancelButton}
					onPress={onNext}
				>
					general.cancel
				</SolidButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.white,
		borderRadius: 20,
		paddingVertical: 30,
		paddingHorizontal: 20,
		minWidth: 875,
		maxWidth: 1500,
	},
	separator: {
		backgroundColor: colors.primary,
		height: 2,
		width: '100%',
		marginBottom: 5,
	},
	tabsContainer: {
		flexDirection: 'row-reverse',
		borderBottomWidth: 1,
		borderBottomColor: '#e7e7e8',
	},
	buttonTab: {
		width: 150,
		borderBottomWidth: 6,
		alignItems: 'center',
		padding: 3,
		marginTop: 20,
	},
	tabContainer: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		padding: 20,
		marginBottom: 10,
		borderRadius: 8,
		borderTopRightRadius: 0,
		zIndex: 'unset',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	field: {
		width: 150,
		paddingBottom: 15,
	},
	dataContainer: {
		width: '50%',
	},
	cancelButton: {
		marginHorizontal: 10,
		backgroundColor: colors.white,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
	},
});

export default ExistingLocationSettingsModal;
