import { compact, debounce } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterData } from '../../../components/web/Filters/Filters.container';
import { AsyncLocalStorage } from '../../../config/localStorage';
import { Privileges } from '../../../constants/roleTypes.constant';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import httpService from '../../../services/http.service';
import { i18nService } from '../../../services/i18n.service';
import {
	getStoreDataByKey,
	storeDataByKey,
} from '../../../store/actions/login.actions';
import { getUserData } from '../../../store/selectors/login.selectors';
import RepCustomers from './RepCustomers.component';
import { RepCustomersContext } from './repCustomersContext';
import { getSelectedUnits } from '../rep.utils';

const RepCustomersContainer = ({ navigation, setSelected }: any) => {
	const userData = useSelector(getUserData);
	const [forceRefresh, setForceRefresh] = useState<Boolean>();
	const [tableData, setTableData] = useState({
		page: 0,
		sd: 'ASC',
		hasNext: true,
		s: 'name',
		data: undefined,
	} as { page: number; sd: string; hasNext: boolean; s: string; data?: any[] });
	const [filters, setFilters] = useState({
		unitIds: [],
		repCustomerIds: [],
		operatorIds: [],
	});
	const [search, setSearch] = useState('');
	const [customerId, setCustomerId] = useState();
	const [moreDataExitMode, setMoreDataExitMode] = useState<null | string>(
		null,
	);
	const [businessUnitsList, setBusinessUnitsList] = useState([]);
	const [operatorsList, setOperatorsList] = useState<any[]>([]);
	const { repCustomersData } = useContext(RepCustomersContext);
	const paginateHasNextRef = useRef<boolean>(false);
	const filtersRef = useRef<any>();
	filtersRef.current = filters;
	const businessUnitsListRef = useRef<any>();
	businessUnitsListRef.current = businessUnitsList;

	const platform = useDevicePlatform();

	const canEditCustomer = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_CREATE_REP_CUSTOMER_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	const getOperatorList = async () => {
		const res: any = await httpService.api({
			type: 'getCustomerOperatorsList',
			query: {
				unitIds: getSelectedUnits(
					filters.unitIds,
					businessUnitsList,
				).join(','),
			},
		});

		setOperatorsList([
			{
				id: -1,
				name: i18nService.translate(
					'repCustomersPage.operator.noManager',
				),
			},
			...res,
		]);
	};

	useEffect(() => {
		if ((repCustomersData as any)?.shouldRefresh) {
			refreshData();
		} else if (repCustomersData) {
			updateRow(repCustomersData);
		}
	}, [repCustomersData]);

	const refreshData = (forceRefresh?: boolean) => {
		if (forceRefresh) {
			setForceRefresh(prev => !prev);
		}
		if (tableData.page === 0) {
			if (!tableData.data?.length) {
				setTimeout(() => {
					getTableData();
				}, 2000);
			} else {
				getTableData();
			}
		} else {
			setTableData({ ...tableData, page: 0 });
		}
	};

	const updateRow = (data: any) => {
		const tempData = [...(tableData.data || [])];
		const rowId = tempData.findIndex(
			(d: any) => d.numericId === (data as any)?.numericId,
		);
		tempData[rowId] = {
			...tempData[rowId],
			...(data as any),
		};
		setTableData({ ...tableData, data: tempData });
	};

	const getBusinessUnitsList = async () => {
		const res: any = await httpService.api<string[]>({
			type: 'getRepresntUnits',
			query: { pageName: 'repCustomers' },
		});
		setBusinessUnitsList(
			res?.map((item: any) => ({
				...item,
				id: item.numericId,
				stringId: item.id,
			})),
		);
	};

	const getTableData = async () => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: tableData.page,
				ps: 25,
				sd: tableData.sd,
				s: tableData.s,
				f: {
					...filters,
					unitIds: getSelectedUnits(
						filters.unitIds,
						businessUnitsList,
					),
				},
				search,
			},
		});
		paginateHasNextRef.current = res?.hasNext;
		if (tableData.page === 0) {
			platform === DeviceType.WEB &&
				setCustomerId(res.data?.[0]?.numericId);
			setTableData({
				...tableData,
				...res,
			});
		} else {
			setTableData({
				...tableData,
				...res,
				data: [...tableData?.data, ...res.data],
			});
		}
	};

	const onRowClick = (row: any) => {
		setCustomerId(row?.numericId);
	};

	useEffect(() => {
		getBusinessUnitsList();
	}, [userData]);

	useEffect(() => {
		if (businessUnitsList.length) {
			getOperatorList();
		}
	}, [filters.unitIds, businessUnitsList]);

	useEffect(() => {
		if (businessUnitsList?.length) {
			getTableData();
		}
	}, [tableData.page]);

	useEffect(() => {
		if (businessUnitsList?.length) {
			getTestIsExitFrom();
		}
	}, [tableData.data?.length]);

	useEffect(() => {
		if (tableData.page === 0 && businessUnitsList?.length) {
			getTableData();
		}
	}, [filters, tableData.sd, search, businessUnitsList]);

	const onSort = (newSort: string, reqOrder: any) => {
		let newOrder = reqOrder;
		if (!(newSort === tableData.s)) {
			newOrder = 'ASC';
		}
		setTableData({
			...tableData,
			sd: newOrder,
			s: newSort,
			page: 0,
		});
	};

	const getTestIsExitFrom = async () => {
		const getData =
			moreDataExitMode ||
			(await getStoreDataByKey(AsyncLocalStorage.REP_EXIT_FROM));

		const getExitFrom = getData
			? JSON.parse(getData)?.customerHp
			: undefined;

		if (getExitFrom) {
			const result = tableData.data?.find((el: any) =>
				getExitFrom.includes(el.id),
			);

			if (result) {
				setCustomerId(result.numericId);
				updateRow(result);
				storeDataByKey(
					AsyncLocalStorage.REP_EXIT_FROM,
					JSON.stringify(undefined),
				);
				setMoreDataExitMode(null);
			} else {
				setMoreDataExitMode(getExitFrom);
				getMoreData();
			}
		}
	};

	const getRepCustomerIdsData = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: page,
				ps: 15,
				search,
				f: {
					unitIds: getSelectedUnits(
						filtersRef.current?.unitIds,
						businessUnitsListRef.current,
					),
				},
			},
			disableBI: true,
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.numericId,
					label: el.name + ` (${el.id})`,
				};
			}),
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	const onFilter = (data: FilterData) => {
		if (data.name === 'unitIds') {
			setFilters({
				unitIds: data.isMulti
					? data.value.map((val: any) => val.id || val)
					: [data.value],
				repCustomerIds: [],
			});
		} else if (data.name === 'repCustomerIds') {
			const chosenIds = data.value.map((val: any) => val.id || val);
			setFilters({
				...filters,
				repCustomerIds: chosenIds?.includes?.('all') ? [] : chosenIds,
			});
		} else if (data.name === 'operatorIds') {
			const chosenIds = data.value.map((val: any) => val.id || val);
			setFilters({
				...filters,
				operatorIds: chosenIds?.includes?.('all') ? [] : chosenIds,
			});
		}
		setTableData({ ...tableData, page: 0 });
	};
	const filtersConfig = useMemo(
		() =>
			compact([
				{
					name: 'operatorIds',
					type: 'multiselect',
					key: `${JSON.stringify(filters.unitIds)}`,
					options: operatorsList,
					placeholder: 'operators',
					styles: { width: 276 },
				},
				{
					name: 'repCustomerIds',
					loadOptions: getRepCustomerIdsData,
					type: 'asyncmultiselect',
					key: `${JSON.stringify(filters.unitIds)}`,
					placeholder: 'customers',
					styles: { width: 276 },
				},
				businessUnitsList?.length > 1
					? {
							name: 'unitIds',
							type: 'multiselect',
							placeholder: 'businessUnits',
							defaultValue: filters.unitIds?.[0],
							options: businessUnitsList,
							hideDefaultOption: true,
							styles: {
								width: 200,
							},
					  }
					: undefined,
			]),
		[businessUnitsList, filters.unitIds, operatorsList],
	);

	const onSearch = (data: any) => {
		setSearch(data?.value);
		setTableData({ ...tableData, page: 0 });
	};

	const getMoreData = debounce(() => {
		if (paginateHasNextRef.current) {
			setTableData({ ...tableData, page: tableData.page + 1 });
		}
	}, 500);
	return (
		<RepCustomers
			filtersConfig={filtersConfig}
			getMoreData={getMoreData}
			onSort={onSort}
			onFilter={onFilter}
			tableData={tableData}
			navigation={navigation}
			customerId={customerId}
			onRowClick={onRowClick}
			onSearch={onSearch}
			updateRow={updateRow}
			refreshData={refreshData}
			canEditCustomer={canEditCustomer}
			forceRefresh={forceRefresh}
			selectedFilters={filters}
		/>
	);
};

export default RepCustomersContainer;
