export default {
	locationAlert: require('../../assets/icons/locationAlert/location.png'),
	remove: require('../../assets/icons/remove/remove.png'),
	location: require('../../assets/icons/location/location.png'),
	roundPlus: require('../../assets/icons/roundPlus/addBtn.png'),
	mail: require('../../assets/icons/mail/mail.png'),
	down: require('../../assets/icons/down/down.png'),
	up: require('../../assets/icons/up/up.png'),
	exclamation: require('../../assets/icons/exclamation/exclamation.png'),
	edit: require('../../assets/icons/edit/edit.png'),
	delete: require('../../assets/icons/delete/delete.png'),
	tick: require('../../assets/icons/v/v.png'),
	plus: require('../../assets/icons/plus/plus.png'),
	minus: require('../../assets/icons/minus/minus.png'),
	lock: require('../../assets/icons/lock/lock.png'),
	user: require('../../assets/icons/user/user.png'),
	michpal: require('../../assets/icons/michpalLogo/logo-michpal.png'),
	liram: require('../../assets/icons/liramLogo/logo-liram.png'),
	login: require('../../assets/icons/login/bg.png'),
	phone: require('../../assets/icons/phone/phone.png'),
	phoneCode: require('../../assets/icons/phoneCode/phoneCode.png'),
	sandTime: require('../../assets/icons/sandTime/time.png'),
	closeWeb: require('../../assets/icons/closeWeb/combined-shape.png'),
	hamburger: require('../../assets/icons/hamburger/hamburger.png'),
	showPassword: require('../../assets/icons/show-password/show-password.png'),
	hidePassword: require('../../assets/icons/hide-password/hide-password.png'),
	calendar: require('../../assets/icons/calendar/calendar.png'),
	calendar_web: require('../../assets/icons/calendar/calendar_web.png'),
	add: require('../../assets/icons/add/add.png'),
	question: require('../../assets/icons/question/question.png'),
	downloadPaycheck: require('../../assets/icons/downloadPaycheck/download.png'),
	attention: require('../../assets/icons/attention/attention.png'),
	arrowDown: require('../../assets/icons/arrowDown/dropdown-blue.png'),
	excel: require('../../assets/icons/excel/excel.png'),
	save: require('../../assets/icons/save/save.png'),
	back: require('../../assets/icons/back/back.png'),
	reject: require('../../assets/icons/reject/reject.png'),
	checked: require('../../assets/icons/checked/checked.png'),
	errorIndicaror: require('../../assets/icons/errorIndicator/errorIndicaror.png'),
	upload: require('../../assets/icons/upload/upload.png'),
	table: require('../../assets/icons/table/table.png'),
	clickedTable: require('../../assets/icons/clickedTable/table.png'),
	exit: require('../../assets/icons/exit/exit.png'),
	questionMark: require('../../assets/icons/question_mark/invalid-name.png'),
	chart: require('../../assets/icons/chart/chart.png'),
	downloadPDF: require('../../assets/icons/downloadPDF/downloadPDF.png'),
	initialCommentIcon: require('../../assets/icons/comment_icon/comment_icon.png'),
	addedCommentIcon: require('../../assets/icons/added_comment_icon/added_comment_icon.png'),
	pdf: require('../../assets/icons/pdf/pdf.png'),
	sort: require('../../assets/icons/sort/sort.png'),
	sortActive: require('../../assets/icons/sortActive/sortActive.png'),
	filter: require('../../assets/icons/filter/filter.png'),
	filterActive: require('../../assets/icons/filterActive/filterActive.png'),
	checkboxUnselected: require('../../assets/icons/checkbox-unselected/checkbox-unselected.png'),
	checkboxSelected: require('../../assets/icons/checkbox-selected/checkbox-selected.png'),
	checkedMark: require('../../assets/icons/checkedMark/checkedMark.png'),
	leftArrow: require('../../assets/icons/leftArrow/leftArrow.png'),
	extraInfo: require('../../assets/icons/extraInfo/extraInfo.png'),
	exclamationMark: require('../../assets/icons/exclamationMark/i-icon.png'),
	multipleFile: require('../../assets/icons/file-multiple/icon-file-multiple.png'),
	message: require('../../assets/icons/message/message.png'),
	closeBig: require('../../assets/icons/closeBig/closeBig.png'),
	whatsappIcon: require('../../assets/icons/whatsapp/whatsapp.png'),
	excludedIcon: require('../../assets/icons/excluded-icon/excluded-icon.png'),
	brightBlueQuestionMark: require('../../assets/icons/bright-blue-question-mark/bright-blue-question-mark.png'),
	arrowRightDown: require('../../assets/icons/arrow-white-down/arrow-white-down.png'),
	exclamationMarkRed: require('../../assets/icons/exclamationMarkRed/exclamationMarkRed.png'),
	profileDefault: require('../../assets/icons/profileDefault/profileDefault.png'),
	settings: require('../../assets/icons/settings/settings.png'),
	compare: require('../../assets/icons/compare/compare.png'),
	file: require('../../assets/icons/file/file.png'),
	rejectFile: require('../../assets/icons/rejectFile/rejectFile.png'),
	approveFile: require('../../assets/icons/approveFile/approveFile.png'),
	upArrow: require('../../assets/icons/upArrow/upArrow.png'),
	downArrow: require('../../assets/icons/downArrow/downArrow.png'),
	reduceScreenArrow: require('../../assets/icons/reduceScreenArrow/ReduceScreen.png'),
	reduceScreenArrowLeft: require('../../assets/icons/reduceScreenArrowLeft/ReduceScreenLeft.png'),
	search: require('../../assets/icons/search/search.png'),
	edtitWhite: require('../../assets/icons/edtitWhite/edtitWhite.png'),
	noResults: require('../../assets/icons/noResults/noResults.png'),
	openMenu: require('../../assets/icons/openMenu/openMenu.png'),
	enterCustomer: require('../../assets/icons/enterCustomer/enterCustomer.png'),
	settingsOrange: require('../../assets/icons/settingsOrange/settingsOrange.png'),
	verticalDots: require('../../assets/icons/verticalDots/verticalDots.png'),
	redExclamationMark: require('../../assets/icons/redExclamationMark/redExclamationMark.png'),
	downloadActivePDF: require('../../assets/icons/downloadActivePDF/downloadActivePDF.png'),
	downloadDisablePDF: require('../../assets/icons/downloadDisablePDF/downloadDisablePDF.png'),
	orangePDF: require('../../assets/icons/orangePDF/orangePDF.png'),
	bell: require('../../assets/icons/bell/bell.png'),
	whitePlus: require('../../assets/icons/whitePlus/whitePlus.png'),
	checkFile: require('../../assets/icons/checkFile/checkFile.png'),
	undo: require('../../assets/icons/undo/undo.png'),
	plusWhite: require('../../assets/icons/plus_white/plus_white.png'),
	deleteCircle: require('../../assets/icons/delete_circle/delete_circle.png'),
	hide: require('../../assets/icons/hide/hide.png'),
	view: require('../../assets/icons/view/view.png'),
	enterPageArrow: require('../../assets/icons/enterPageArrow/enterPageArrow.png'),
};
