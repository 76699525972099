import React from 'react';
import { View, StyleSheet, Text, useWindowDimensions } from 'react-native';
import colors from '../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import Typography from '../Typography/Typography.component';
import { Color ,FontSize} from '../../../interfaces/core.interface';
interface TimerProps {
	hours: number;
	minutes: number;
	seconds: number;
	textColor?:Color;
	fontSize?:FontSize;
}

const Timer = ({ hours, minutes, seconds , textColor , fontSize }: TimerProps) => {
	const windowHeight = useWindowDimensions().height;
	const isMobileApp = useDevicePlatform() !== DeviceType.WEB;
	const size = isMobileApp ? 36 : windowHeight >= 1080 ? 30 : 24;
	const minWidth = isMobileApp ? 47 : 35;

	return (
		<View style={styles.container}>
			<Typography
				size={fontSize?fontSize:size}
				color={textColor?textColor:'white'}
				weight='normal'
				style={{ minWidth: minWidth, textAlign: 'center' }}
			>
				{hours < 10 ? `0${hours}` : hours}
			</Typography>
			<Typography size={fontSize?fontSize:size} color={textColor?textColor:'white'} weight='normal'>
				:
			</Typography>
			<Typography
				size={fontSize?fontSize:size}
				color={textColor?textColor:'white'}
				weight='normal'
				style={{ minWidth: minWidth, textAlign: 'center' }}
			>
				{minutes < 10 ? `0${minutes}` : minutes}
			</Typography>
			<Typography size={fontSize?fontSize:size} color={textColor?textColor:'white'} weight='normal'>
				:
			</Typography>
			<Typography
				size={fontSize?fontSize:size}
				color={textColor?textColor:'white'}
				weight='normal'
				style={{ minWidth: minWidth, textAlign: 'center' }}
			>
				{seconds < 10 ? `0${seconds}` : seconds}
			</Typography>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		fontWeight: '500',
		color: colors.white,
	},
});

export default Timer;
