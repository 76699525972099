import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import colors from '../../../config/colors';
import I18n from '../../../components/shared/i18n/I18n.component';
import FieldBuilder from '../../../components/shared/FieldBuilder/FieldBuilder';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import { modalService } from '../../../services/modal.service';
import UpdateEventModal from './TabsComponents.utils';

const approveManualReportOptions = [
	{ label: 'businessSettings.presence.must', value: true },
	{ label: 'businessSettings.presence.NotMust', value: false },
];

const worklogReportFormatOptions = [
	{ label: 'general.hours', value: 'HOUR' },
	{ label: 'general.decimal', value: 'DECIMAL' },
];
const allowUpdateReportOptions = [
	{ label: 'general.true', value: true },
	{ label: 'general.false', value: false },
];

const eventSelectionTypeOptions = [
	{ label: 'general.all', value: 'ALL' },
	{
		label: 'businessSettings.presence.part',
		value: 'PARTIAL',
		showCounter: true,
	},
	{ label: 'businessSettings.presence.noEvent', value: 'NONE' },
];

const worklogLockTypeOptions = [
	{ label: 'businessSettings.presence.without', value: 'NO_LOCK' },
	{
		label: 'businessSettings.presence.lastDayOfMonth',
		value: 'END_OF_MONTH',
	},
	{ label: 'businessSettings.presence.nextMonth', value: 'SELECTED_DAY' },
];

interface Props {
	data: any;
	disableSubmit: boolean;
	setDisableSubmit: any;
	onUpdate(eventsOptions?: Event[], showDatePicker?: string): void;
	onCancel(): void;
	eventsOptions: any;
	setEventsOptions: Function;
	showEvents: any;
	setShowEvents: Function;
	selectedEvents: any;
	setSelectedEvents: Function;
	showDatePicker: any;
	setShowDatePicker: any;
	editLockDay: any;
	setEditLockDay: any;
	maxShiftHour: any;
	setMaxShiftHour: any;
	presenceModuleStatus: any;
	formMethods: any;
	canNotEdit?: boolean;
	selectedBusinessUnit: any;
}

interface Event {
	name: string;
	id: string;
	isSelected?: boolean;
}

export const Presence = ({
	data,
	onCancel,
	onUpdate,
	disableSubmit,
	setDisableSubmit,
	eventsOptions,
	canNotEdit,
	selectedEvents,
	setSelectedEvents,
	showEvents,
	setShowEvents,
	showDatePicker,
	setShowDatePicker,
	editLockDay,
	setEditLockDay,
	maxShiftHour,
	setMaxShiftHour,
	presenceModuleStatus,
	selectedBusinessUnit,
	formMethods,
}: Props) => {
	const platform = useDevicePlatform();
	const isWeb = platform === DeviceType.WEB;
	const isMobile = platform === DeviceType.MOBILE;
	const [eventNumber, setEventNumber] = useState(0);
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		setEventNumber(selectedEvents?.length);
		if (selectedEvents?.length > 0) {
			formMethods.setValue('eventSelectionType', 'PARTIAL');
		}
	}, [selectedEvents, selectedBusinessUnit]);

	useEffect(() => {
		setIsActive(presenceModuleStatus === 'ACTIVE' && !canNotEdit);
	}, [presenceModuleStatus, canNotEdit]);

	const onUpdateEvent = async () => {
		modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
				isMobile: isMobile,
				onCustomNext: () => {
					if (selectedEvents?.length === 0) {
						formMethods.setValue('eventSelectionType', 'ALL');
					}
				},
			},
			(props: any) => (
				<UpdateEventModal
					{...props}
					eventsOptions={eventsOptions}
					selectedEvents={selectedEvents}
					setSelectedEvents={setSelectedEvents}
					setShowEvents={setShowEvents}
					formMethods={formMethods}
				/>
			),
		);
	};

	return (
		<ScrollView
			style={{ paddingHorizontal: 20 }}
			contentContainerStyle={{ paddingBottom: isWeb ? 0 : 50 }}
		>
			<I18n size={22} weight='normal' style={styles.title}>
				businessSettings.employer
			</I18n>

			<View style={isWeb ? styles.row : styles.mobileRow}>
				<I18n size={16} weight='normal' style={styles.text}>
					businessSettings.presence.mustToApprove
				</I18n>
				<FieldBuilder
					name={'approveManualReport'}
					type={'buttonsGroup'}
					label={() => ''}
					options={approveManualReportOptions}
					onChange={() =>
						!showDatePicker && setShowDatePicker(new Date())
					}
					disabled={!isActive}
				/>
				<View
					style={{
						flexDirection: 'row-reverse',
						alignItems: 'center',
						zIndex: 100,
					}}
				>
					<I18n
						size={16}
						weight='normal'
						style={{
							marginLeft: 15,
							paddingBottom: isMobile ? 20 : 20,
						}}
					>
						businessSettings.presence.from
					</I18n>
					<View
						style={
							!isWeb && {
								width: 200,
								height: isMobile ? 100 : 60,
							}
						}
					>
						<FieldBuilder
							name={'approveManualReportFromDate'}
							type={'monthPicker'}
							label={() => ''}
							value={showDatePicker}
							onChange={(a, b, c) => setShowDatePicker(c)}
							disabled={!isActive}
						/>
					</View>
				</View>
			</View>
			<View
				style={[
					isWeb ? styles.row : styles.mobileRow,
					!isMobile && { zIndex: 'unset' },
				]}
			>
				<I18n size={16} weight='normal' style={styles.text}>
					businessSettings.presence.maxHours
				</I18n>
				<FieldBuilder
					label={() => ''}
					name={'maxShiftHour'}
					value={maxShiftHour}
					placeholder={maxShiftHour.toString() || ''}
					type={'input'}
					keyboardType={'numeric'}
					onChange={(a, b, c) => setMaxShiftHour(c)}
					maxVal={24}
					minVal={1}
					disabled={!isActive}
				/>
			</View>
			<View
				style={[
					isWeb ? styles.row : styles.mobileRow,
					!isMobile && { zIndex: 'unset' },
				]}
			>
				<I18n size={16} weight='normal' style={styles.text}>
					businessSettings.presence.hoursFormat
				</I18n>
				<FieldBuilder
					label={() => ''}
					name={'worklogReportFormat'}
					type={'buttonsGroup'}
					options={worklogReportFormatOptions}
					disabled={!isActive}
				/>
			</View>

			<I18n size={22} weight='normal' style={styles.title}>
				businessSettings.employee
			</I18n>

			<View
				style={[
					isWeb ? styles.row : styles.mobileRow,
					!isMobile && { zIndex: 'unset' },
				]}
			>
				<I18n size={16} weight='normal' style={styles.text}>
					businessSettings.presence.enableToUpdate
				</I18n>
				<FieldBuilder
					label={() => ''}
					name={'allowUpdateReport'}
					type={'buttonsGroup'}
					options={allowUpdateReportOptions}
					disabled={!isActive}
				/>
			</View>
			<View
				style={[
					isWeb ? styles.row : styles.mobileRow,
					!isMobile && { zIndex: 'unset' },
				]}
			>
				<I18n size={16} weight='normal' style={styles.text}>
					businessSettings.presence.enableAllEventTypes
				</I18n>
				<FieldBuilder
					selectedItems={showEvents ? eventNumber : null}
					label={() => ''}
					name={'eventSelectionType'}
					type={'buttonsGroup'}
					options={eventSelectionTypeOptions}
					onChange={(a, b, c) => {
						setShowEvents(c === 'PARTIAL');
						if (c === 'PARTIAL') {
							onUpdateEvent();
						}
					}}
					disabled={!isActive}
				/>
			</View>

			<View style={[isWeb ? styles.row : styles.mobileRow]}>
				<View>
					<I18n size={16} weight='normal' style={{ width: 320 }}>
						businessSettings.presence.lockMonth
					</I18n>
					<I18n
						size={12}
						weight='normal'
						style={[styles.text, { paddingLeft: 30 }]}
					>
						businessSettings.presence.lockMonth.note
					</I18n>
				</View>
				<FieldBuilder
					label={() => ''}
					name={'worklogLockType'}
					type={'buttonsGroup'}
					options={worklogLockTypeOptions}
					onChange={(a, b, c) => {
						setEditLockDay(c === 'SELECTED_DAY');
					}}
					disabled={!isActive}
				/>
				<FieldBuilder
					name={'lockDay'}
					defaultValue={data?.lockDay?.toString()}
					type={'reactselect'}
					options={[...Array(31)].map((i, index) => ({
						id: (index + 1).toString(),
						name: (index + 1).toString(),
					}))}
					style={{
						pointerEvents: !editLockDay ? 'none' : undefined,
						width: isWeb ? 100 : '100%',
						marginTop: isWeb ? 0 : 15,
						zIndex: 1000000000,
					}}
					disabled={!editLockDay || !isActive}
					isDisabled={!editLockDay}
					maxMenuHeight={150}
					styles={{ dropdownIndicator: colors.white }}
				/>
			</View>

			<View
				style={{ flexDirection: 'row', marginVertical: 30, zIndex: -1 }}
			>
				<SolidButtonContainer
					size={isWeb ? 'medium' : 'small'}
					textOptions={{ color: colors.lightPrimary }}
					overrideStyle={[
						styles.cancelButton,
						!isWeb && {
							paddingHorizontal: 22,
							paddingVertical: 10,
						},
					]}
					onPress={onCancel}
				>
					general.cancel
				</SolidButtonContainer>
				<SolidButtonContainer
					size={isWeb ? 'medium' : 'small'}
					overrideStyle={
						!isWeb && { paddingHorizontal: 22, paddingVertical: 10 }
					}
					onPress={() => {
						onUpdate(eventsOptions);
						setDisableSubmit(true);
					}}
					disabled={disableSubmit || !isActive}
				>
					general.save
				</SolidButtonContainer>
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	title: {
		marginTop: 30,
		paddingBottom: 20,
	},
	row: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 55,
		zIndex: 100,
	},
	eventsRow: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		zIndex: 100,
	},
	mobileRow: {
		flexDirection: 'column',
		zIndex: 100,
	},
	text: {
		width: 320,
		paddingBottom: 20,
	},
	cancelButton: {
		marginHorizontal: 10,
		backgroundColor: colors.white,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
	},
});
