export type Full101ReportListLike = {
	hasNext: boolean;
	total: number;
	data: Full101ReportList[];
};

export class Full101ReportList {
	id: number;
	userId: number;
	fullName: string;
	taxYear: number;
	statusCode: string;
	status: string;
	departmentId: number;
	departmentAid: string;
	departmentName: string;
	teudatZeut: string;
	employeeCreateDate: string;
	submitDate: string;
	lastUpdate: string;
	attachedFiles: number;
	changed: boolean;
	businessUnitName: string;
	businessUnitId: number;


	constructor(f: any) {
		this.id = f.id;
		this.userId = f.userId;
		this.fullName = f.fullName;
		this.taxYear = f.taxYear;
		this.statusCode = f.statusCode;
		this.status = f.status;
		this.departmentId = f.departmentId;
		this.departmentAid = f.departmentAid;
		this.departmentName = f.departmentName;
		this.teudatZeut = f.teudatZeut;
		this.employeeCreateDate = f.employeeCreateDate;
		this.submitDate = f.submitDate;
		this.lastUpdate = f.lastUpdate;
		this.attachedFiles = f.attachedFiles;
		this.changed= f.changed;
		this.businessUnitName = f.businessUnitName;
		this.businessUnitId = f.businessUnitId
	}
}
