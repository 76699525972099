import {
	DrawerContentComponentProps,
	DrawerContentOptions,
	useIsDrawerOpen,
} from '@react-navigation/drawer';
import React, { useEffect } from 'react';
import routes from '../../../config/routes';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { setHideChat } from '../../../store/actions/config.actions';
import { dispatch } from '../../../store/store';
import DrawerItemsList from './DrawerItemsList.component';

const DrawerItemsListContainer = (
	props: DrawerContentComponentProps<DrawerContentOptions>,
) => {
	const isDrawerOpen = useIsDrawerOpen();
	const platform = useDevicePlatform();

	useEffect(() => {
		platform !== DeviceType.WEB && dispatch(setHideChat(isDrawerOpen));
	}, [isDrawerOpen]);

	const navigateTo = (routeName: string) => {
		props.navigation.navigate(
			routes[routeName]?.name || routeName,
			routes[routeName]?.navigateObj,
		);
	};

	const closeDrawer = () => {
		props.navigation.closeDrawer();
	};

	return (
		<DrawerItemsList
			{...props}
			navigate={navigateTo}
			closeDrawer={closeDrawer}
		/>
	);
};

export default DrawerItemsListContainer;
