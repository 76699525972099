import * as dateTimeActions from '../constants/dateTimeActions';
import {
    CurrentDateTime,
	StoreAction,
} from '../../interfaces/redux.interface';

const initialState: CurrentDateTime = {
    currentDateTime: ''
};

const dateTimeReducer = (
	state: typeof initialState = initialState,
	action: StoreAction<any>,
): any => {
	switch (action.type) {
		case dateTimeActions.CURRENT_DATE_TIME_SUCCESS:
            return {
                currentDateTime: action.payload
            }
		default:
			return { ...state };
	}
};

export default dateTimeReducer;
