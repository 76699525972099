import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FAB } from 'react-native-paper';
import {
	View,
	Image,
	StyleSheet,
	useWindowDimensions,
	Platform,
	ScrollView,
} from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import { useRoute } from '@react-navigation/core';
import _, { remove } from 'lodash';
import CarouselContainer from '../../../components/shared/Carousel/Carousel.container';
import CaruselItem from '../../../components/shared/Carousel/CaruselItem.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import Wrapper from './Wrapper/Wrapper.component';
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import ActionButtons from './ActionButtons/ActionButtons';
import TopDrawer from '../../../components/shared/Drawers/TopDrawer';
import CommentsAndErrors from './CommentsAndErrors/CommentsAndErrors';
import httpService from '../../../services/http.service';
import TabWrapper from './TabWrapper/TabWrapper';
import icons from '../../../config/icons';
import { SCREEN_SIZES } from '../../../constants';
import HideOnKeyboardOpen from '../../../components/shared/HideOnKeyboardOpen/HideOnKeyboardOpen.component';
import { WorkerCardModel } from '../../../models/workerCard.model';
import { modalService } from '../../../services/modal.service';
import I18n from '../../../components/shared/i18n/I18n.component';
import { getTabsMap } from './WorkerCardTabs';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import CommentModal from '../../../modals/CommentModal/CommentModal.component';
import {
	getTabsWithCommentsList,
	getFancyValue,
	setComments,
	getCommentsForLeftPanel,
	fieldsMap,
	getFieldComment,
	getIsHasComment,
	openRejectFormModal,
	rejectForm,
	openApproveModal,
} from './WorkerCardViewer.utils';
import { getDocumentTitle } from '../../../utils/globals.utils';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Header from '../../../components/shared/Header/Header.container';
import MobileHeader from '../../../components/shared/Header/MobileHeader.component';
import WebHeader from '../../../components/shared/Header/WebHeader.component';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../../store/selectors/login.selectors';
import { Privileges } from '../../../constants/roleTypes.constant';
import { dispatch } from '../../../store/store';
import { setIsInWorkerCard } from '../../../store/actions/login.actions';

const bigCaruselaWidth = 1500;
const smallCaruselaWidth = 1019;

const TabBar = ({ navigation, navigate }: any) => {
	const platform = useDevicePlatform();
	useEffect(() => {
		navigate(navigation.getState().index || 1);
	}, []);

	return null;
};

const Tab = createBottomTabNavigator();
const WorkerCardEditor = ({ navigation }: any) => {
	const platform = useDevicePlatform();
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);
	const isMobile = platform !== DeviceType.WEB;
	const isBrowser = platform !== DeviceType.MOBILE;
	const isMobileWeb = platform === DeviceType.MOBILE_WEB;

	const [currentPage, setCurrentPage] = useState<number>();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [goToPosition, setGoToPosition] = useState<Function>();
	const [data, setData] = useState<any>();
	const [taxYear, setTaxYear] = useState();
	const route = useRoute();
	const dimensions = useWindowDimensions();
	const [commentsForPanel, setCommentsForPanel] = useState<any[]>([]);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [openDrawerFunc, setOpenDrawerFunc] = useState<any>();
	const [tabsMap, setTabsMap] = useState<any[]>(getTabsMap([]));

	const openDrawerFuncRef = useRef<any>();
	openDrawerFuncRef.current = openDrawerFunc;

	const drawerDataStatus: any = useMemo(() => ({ value: undefined }), []);

	const menuWidth = useMemo(
		() => (dimensions.width >= SCREEN_SIZES.MEDIUM ? 320 : 0),
		[dimensions],
	);
	const urlParams: any = useMemo(
		() => isBrowser && new URL(document.location).searchParams,
		[isBrowser && document.location],
	);

	const formId = useMemo(
		() =>
			route.params?.formId ||
			route.params?.params?.formId ||
			(isBrowser && urlParams?.get('formId')),
		[route.params?.formId, route.params?.params?.formId, urlParams],
	);

	const screen = useMemo(
		() =>
			route.params?.screen ||
			route.params?.params?.screen ||
			(isBrowser && urlParams?.get('screen')),
		[route.params?.screen, route.params?.params?.screen, urlParams],
	);

	const [tabsWithCommentsList, setTabsWithCommentsList] = useState<any>(null);

	const readOnly = useMemo(() => {
		const value =
			route.params?.readOnly ||
			route.params?.params?.readOnly ||
			(isBrowser && urlParams?.get('readOnly'));
		return (
			!employerRolesPrivileges?.includes(
				Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
			) ||
			value === 'true' ||
			value === true
		);
	}, [
		route.params?.readOnly,
		route.params?.params?.readOnly,
		urlParams,
		employerRolesPrivileges,
	]);

	const selectedEmplName = useMemo(() => {
		const value =
			route.params?.selectedEmplName ||
			route.params?.params?.selectedEmplName;
		return value ? value : '';
	}, [
		route.params?.selectedEmplName,
		route.params?.params?.selectedEmplName,
		urlParams,
	]);

	const outdatedText = useMemo(() => {
		const value =
			route.params?.outdatedText ||
			(isBrowser && urlParams?.get('outdatedText'));

		return value && value !== ''
			? { text: true, value: value }
			: { text: false, value: null };
	}, [route.params?.outdatedText, urlParams]);

	let tempInputValue = '';

	useEffect(() => {
		if (!commentsForPanel.length && isDrawerOpen) {
			setIsDrawerOpen(false);
		}
		if (!drawerDataStatus.value) {
			drawerDataStatus.value = 'first_init';
		} else if (
			drawerDataStatus.value === 'first_init' &&
			openDrawerFuncRef.current
		) {
			drawerDataStatus.value = 'next';
			commentsForPanel.length && openDrawerFuncRef.current?.current();
		}
	}, [commentsForPanel, openDrawerFuncRef.current]);

	useEffect(() => {
		dispatch(setIsInWorkerCard(true));

		return () => {
			dispatch(setIsInWorkerCard(false));
		};
	}, []);

	const onChildCommentClick = async (
		fieldLabel: string,
		childId: number,
		fieldName: string,
	) => {
		const child = _.get(data, fieldName).find(
			(el: any) => el.id === childId,
		);
		tempInputValue =
			child && child.comment ? child?.comment[0]?.comment : '';
		const commentId = child && child.comment ? child?.comment[0]?.id : null;

		await modalService.openModal(
			null,
			{
				fieldLabel,
				fieldName: '',
				fieldValue: '',
				inputValue: tempInputValue,
				cancel: cancel,
				commentExist: child.comment ? true : false,
				errorMessage,
				readOnly,
				onInputChange: onCommentInputChange,
				saveComment: () => saveChildComment(childId, fieldName),
				removeComment: () =>
					removeCommentFromChildrenTab(commentId, childId, fieldName),
			},
			CommentModal,
		);
	};

	const onCommentClick = async (
		fieldLabel: string,
		fieldName: string,
		fieldValue: string,
		isRequired?: boolean,
	) => {
		const comment = getFieldComment(data, fieldName, screen);

		tempInputValue = comment?.comment || '';

		const objName = Object.keys(fieldsMap).find(
			(key: string) => fieldsMap[key] === comment?.objectName,
		);

		await modalService.openModal(
			null,
			{
				fieldLabel,
				fieldName,
				fieldValue: getFancyValue(fieldName, fieldValue),
				inputValue: tempInputValue,
				cancel: cancel,
				errorMessage,
				readOnly,
				onInputChange: onCommentInputChange,
				saveComment: () => saveComment(fieldName),
				commentExist: comment ? true : false,
				removeComment: () => removeComment(comment.id, objName),
			},
			CommentModal,
		);
	};

	const onCommentInputChange = (fieldName: string, value: string) => {
		tempInputValue = value;
	};

	const getPayloadForSaveComment = (fieldName: string) => {
		let payload: any;

		switch (fieldName) {
			case '':
				payload = {
					objectName: 'ROOT',
					fieldName: 'ROOT',
					comment: tempInputValue,
				};
				break;
			case 'hasChildren':
				payload = {
					objectName: 'ROOT',
					fieldName: 'hasChildren',
					comment: tempInputValue,
				};
				break;
			case 'signature':
				payload = {
					objectName: 'ROOT',
					fieldName: 'signature',
					comment: tempInputValue,
				};
				break;
			default:
				const arr = fieldName.split('.');
				arr.splice(0, 1);

				payload = {
					objectName: fieldsMap[route.params?.screen],
					fieldName: arr.join('.'),
					comment: tempInputValue,
				};
				break;
		}

		return payload;
	};

	const saveComment = async (fieldName: string) => {
		const payload = getPayloadForSaveComment(fieldName);

		const tabName = _.camelCase(fieldsMap[route.params?.screen]);

		let comment;

		if (fieldName === 'hasChildren' || fieldName === 'signature') {
			comment = data?.comment?.find(
				(el: any) => el.fieldName === fieldName,
			);
		} else {
			const nameArray = fieldName.split('.');
			const name = `${nameArray[1] === 'address' ? 'address.' : ''}${
				nameArray[nameArray.length - 1]
			}`;
			comment = data[tabName]?.comment?.find(
				(el: any) => el.fieldName === name,
			);
		}

		return await handleCommentRequest(payload, comment);
	};

	const saveChildComment = async (childId: number, fieldName: string) => {
		const child = _.get(data, fieldName).find(
			(el: any) => el.id === childId,
		);
		const comment = child?.comment ? child.comment[0] : null;
		const payload = {
			objectId: childId,
			objectName:
				fieldName === 'children' ? 'CHILDREN' : 'ADDITIONAL_INCOME',
			fieldName: fieldName === 'children' ? 'CHILD' : 'ADDITIONAL_INCOME',
			comment: tempInputValue,
		};

		await handleCommentRequest(payload, comment, childId, fieldName);
	};

	const handleCommentRequest = async (
		payload: any,
		comment: any,
		childId?: number,
		fieldName?: string,
	) => {
		try {
			if (comment) {
				const res: any = await httpService.api({
					type: 'updateEmployerComment',
					params: {
						formId,
						commentId: comment.id,
					},
					data: {
						comment: tempInputValue,
					},
				});
				setComments(
					data,
					setData,
					{
						...payload,
						id: comment.id,
						objectId: comment.objectId,
					},
					childId,
					fieldName,
				);
				tempInputValue = '';

				return res;
			} else {
				const res: any = await httpService.api({
					type: 'addEmployerComment',
					params: {
						formId,
					},
					data: payload,
				});
				setComments(data, setData, res, childId, fieldName);

				tempInputValue = '';

				return res;
			}
		} catch (e) {
			console.log(e);
			return e;
		}
	};

	const removeCommentFromChildrenTab = async (
		commentId: number,
		objectId: number,
		fieldName: string,
	) => {
		try {
			if (commentId) {
				const res: any = await httpService.api({
					type: 'deleteEmployerComment',
					params: {
						formId,
						commentId,
					},
				});

				const childIndex = _.get(data, fieldName).findIndex((el: any) =>
					el.comment?.find(c => c.id === commentId),
				);
				if (childIndex > -1) {
					const children = [..._.get(data, fieldName)];
					children[childIndex] = {
						...children[childIndex],
						comment: children[childIndex].comment?.filter(
							c => c.id !== commentId,
						),
					};

					setData(
						_.set(
							{
								...data,
							},
							fieldName,
							children,
						),
					);
				}
			}
		} catch (e) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>workerCard.viewer.comment.error</I18n>
					</AlertModal>
				),
			);
		}
	};

	const removeComment = async (commentId: number, objectName: string) => {
		try {
			const res: any = await httpService.api({
				type: 'deleteEmployerComment',
				params: {
					formId,
					commentId,
				},
			});

			if (objectName === 'ROOT') {
				setData({
					...data,
					comment: data.comment.filter(
						(el: any) => el.id !== commentId,
					).length
						? data.comment.filter((el: any) => el.id !== commentId)
						: null,
				});
			} else {
				setData({
					...data,
					[objectName]: {
						...data[objectName],
						comment: data[objectName].comment.filter(
							(el: any) => el.id !== commentId,
						),
					},
				});
			}
		} catch (e) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>workerCard.viewer.comment.error</I18n>
					</AlertModal>
				),
			);
		}
	};

	const isSmallCarusel = useMemo(
		() => bigCaruselaWidth > dimensions.width - 33 - menuWidth,
		[dimensions],
	);

	const shouldDisplayArrows = useMemo(
		() =>
			smallCaruselaWidth > dimensions.width - 33 - menuWidth ||
			(isDrawerOpen &&
				(isSmallCarusel ? smallCaruselaWidth : bigCaruselaWidth) >
					dimensions.width - 240 - menuWidth),
		[dimensions, isSmallCarusel, isDrawerOpen],
	);

	const formMethods = useForm({
		defaultValues: data,
		mode: 'onBlur',
	});

	useEffect(() => {
		formMethods.reset(data);
	}, [data]);

	useEffect(() => {
		(async () => {
			try {
				if (formId) {
					const res: any = await httpService.api({
						type: 'getEmployeeWorkerCard',
						params: {
							formId,
						},
					});
					const data = new WorkerCardModel({
						...res,
						isViewer: true,
					});
					setData(data);
					setTaxYear(res.taxYear);
				}
			} catch (e) {
				console.error(e);
			}
		})();
	}, [formId]);

	useEffect(() => {
		setTabsWithCommentsList(getTabsWithCommentsList(tabsMap, data));
		setCommentsForPanel(getCommentsForLeftPanel(data));
	}, [data]);

	const onNavigate = (tabId: number) => {
		const currentRoute = currentPage && tabsMap[currentPage];
		const route = tabsMap[tabId];

		if (tabId !== currentPage) {
			if (currentRoute) {
				formMethods.trigger(
					_.flattenDeep(currentRoute.fields).map(
						(filed: any) => filed.name,
					),
				);
			}
			setCurrentPage(tabId);
			navigation.navigate('viewer', {
				screen: route.pageRoute,
				params: {
					formId,
					outdatedText: outdatedText?.value,
					readOnly,
				},
			});
			if (isMobile || shouldDisplayArrows) {
				goToPosition && goToPosition(tabId);
			}
		}
	};

	const approve = async () => {
		openApproveModal({ formId, data, cancel });
	};

	const reject = async () => {
		if (!getIsHasComment({ data })) {
			tempInputValue = '';
			await modalService.openModal(
				null,
				{
					fieldLabel: i18nService.translate(
						'workerCard.viewer.rejection.addReasonMessage',
					),
					fieldName: '',
					fieldValue: '',
					inputValue: tempInputValue,
					cancel: cancel,
					errorMessage,
					commentExist: false,
					onInputChange: onCommentInputChange,
					readOnly,
					saveComment: async () => {
						const comment = data.comment?.find(
							c => c?.fieldName === 'ROOT',
						);
						if (comment) {
							const payload = getPayloadForSaveComment('');
							await handleCommentRequest(payload, comment);
						} else {
							await saveComment('');
						}

						rejectForm({ formId, cancel });
					},
					removeComment: () => {},
				},
				CommentModal,
			);
		} else {
			openRejectFormModal({
				rejectForm: () => rejectForm({ formId, cancel }),
				data,
			});
		}
	};

	const close = () => {
		navigation.navigate('table101');
	};

	const cancel = async () => {
		if (errorMessage) {
			setErrorMessage('');
		}
		close();
	};

	const goNext = () => {
		if (
			currentPage === 4 &&
			formMethods.getValues().employee?.maritalStatus !== 'MARRIED'
		) {
			onNavigate(6);
		} else {
			onNavigate((currentPage || 0) + 1);
		}
	};

	const goBack = () => {
		if (
			currentPage === 6 &&
			formMethods.getValues().employee?.maritalStatus !== 'MARRIED'
		) {
			onNavigate(4);
		} else {
			onNavigate((currentPage || 0) - 1);
		}
	};

	const goToCommentScreen = async (
		screen: string,
		comment: any,
		name: string,
	) => {
		tempInputValue = comment?.comment || '';
		let pageName: string | undefined;
		if (comment?.objectName === 'ROOT') {
			pageName =
				comment.fieldName === 'hasChildren' ? 'children' : 'disclaimer';
		} else if (comment?.objectName === 'ADDITIONAL_INCOME') {
			pageName = 'taxCreditRequest';
		} else {
			pageName = Object.keys(fieldsMap).find(
				(key: string) => fieldsMap[key] === comment?.objectName,
			);
		}

		const targetTabIndex = tabsMap.findIndex(
			(el: any) => el.pageRoute === pageName,
		);
		const fieldName =
			comment?.objectName === 'ROOT'
				? comment.fieldName
				: `${pageName}.${comment.fieldName}`;

		const value = _.get(data, fieldName);

		await onNavigate(targetTabIndex);
		openDrawerFuncRef.current?.current();
		await modalService.openModal(
			null,
			{
				fieldLabel: name,
				fieldName: comment.fieldName,
				fieldValue: getFancyValue(fieldName, value),
				inputValue: tempInputValue,
				cancel: cancel,
				errorMessage,
				readOnly,
				onInputChange: onCommentInputChange,
				saveComment: () => saveComment(comment.fieldName),
				commentExist: comment ? true : false,
				removeComment: () =>
					(comment?.fieldName === 'CHILD' ||
						comment?.fieldName === 'ADDITIONAL_INCOME'
						? removeCommentFromChildrenTab
						: removeComment)(
						comment.id,
						comment?.objectName === 'ROOT' ? 'ROOT' : pageName,
						fieldName,
					),
			},
			CommentModal,
		);
	};

	const tabsArrayComponent = useMemo(() => {
		return tabsMap.map((tab, index) => (
			<>
				{tabsWithCommentsList && tabsWithCommentsList[tab.pageRoute] ? (
					<Image
						source={icons.addedCommentIcon}
						fadeDuration={0}
						style={{
							height: 14,
							width: 14,
							marginLeft: 10,
							position: 'absolute',
							zIndex: 1,
							top: -5,
						}}
					/>
				) : null}
				<CaruselItem
					key={tab.pageRoute}
					selected={index === currentPage}
					title={`workerCard.editor.${tab.pageRoute}`}
					tabId={index}
					width={isMobile || isSmallCarusel ? 86 : 145}
					onPress={onNavigate}
				/>
			</>
		));
	}, [tabsWithCommentsList, currentPage, isSmallCarusel, isMobile]);

	return (
		<>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={isMobile ? MobileHeader : WebHeader}
			/>
			<FormProvider {...formMethods}>
				<Wrapper
					readOnly={readOnly}
					year={taxYear}
					setIsDrawerOpen={setIsDrawerOpen}
					removeComment={removeComment}
					isEmployee={false}
					isMobile={isMobile}
					comments={commentsForPanel}
					missingFields={[]}
					screen={screen}
					goToCommentScreen={goToCommentScreen}
					removeCommentFromChildrenTab={removeCommentFromChildrenTab}
					selectedEmplName={selectedEmplName}
					setOpenDrawerFunc={setOpenDrawerFunc}
				>
					{isMobile ? (
						<View
							style={{
								backgroundColor: colors.white,
								zIndex: 600,
							}}
						>
							<CarouselContainer
								notDisplayHelpers
								shouldDisplayArrows={
									!isMobile && shouldDisplayArrows
								}
								itemMargin={9}
								itemWidth={
									isMobile || isSmallCarusel ? 86 : 145
								}
								setResetCaruselFunc={() => {}}
								setGoToPosition={setGoToPosition}
							>
								{tabsArrayComponent}
							</CarouselContainer>
						</View>
					) : null}
					{isMobile && commentsForPanel.length ? (
						<TopDrawer
							setIsDrawerOpen={setIsDrawerOpen}
							setOpenDrawerFunc={setOpenDrawerFunc}
						>
							<ScrollView
								style={{
									flex: 1,
									backgroundColor: colors.white,
								}}
							>
								<CommentsAndErrors
									comments={commentsForPanel}
									missingFields={[]}
									isEmployee={false}
									readOnly={readOnly}
									removeComment={removeComment}
									isMobile={isMobile}
									goToCommentScreen={goToCommentScreen}
									removeCommentFromChildrenTab={
										removeCommentFromChildrenTab
									}
								/>
							</ScrollView>
						</TopDrawer>
					) : null}
					<View
						style={{
							flex: 1,
							marginLeft: !isMobile ? (isDrawerOpen ? 0 : 5) : 0,
							height:
								!isMobile &&
								dimensions.width < SCREEN_SIZES.MEDIUM
									? dimensions.height - 223
									: 'auto',
							backgroundColor: colors.white,
							marginTop:
								Platform.OS === 'ios' ? 49 : isMobile ? 16 : 0,
						}}
					>
						{!isMobile ? (
							<View
								style={{
									paddingLeft: !isMobile
										? isDrawerOpen
											? 25
											: 0
										: 0,
								}}
							>
								<CarouselContainer
									notDisplayHelpers
									shouldDisplayArrows={
										!isMobile && shouldDisplayArrows
									}
									itemMargin={9}
									itemWidth={
										isMobile || isSmallCarusel ? 86 : 145
									}
									setResetCaruselFunc={() => {}}
									setGoToPosition={setGoToPosition}
									containerMaxWidth={
										isDrawerOpen
											? dimensions.width - 240
											: undefined
									}
								>
									{tabsArrayComponent}
								</CarouselContainer>
							</View>
						) : null}
						<Tab.Navigator
							tabBar={props => (
								<TabBar
									{...props}
									navigate={onNavigate}
									setIsDrawerOpen={setIsDrawerOpen}
									comments={commentsForPanel}
									goToCommentScreen={goToCommentScreen}
									removeComment={removeComment}
									missingFields={[]}
									removeCommentFromChildrenTab={
										removeCommentFromChildrenTab
									}
								/>
							)}
							initialRouteName={tabsMap[1].pageRoute}
							lazy={true}
							swipeEnabled={false}
						>
							{tabsMap.map(tab => (
								<Tab.Screen
									key={`${tab.pageRoute}`}
									name={tab.pageRoute}
									options={{
										title: getDocumentTitle(tab.pageRoute),
									}}
									children={() => (
										<TabWrapper
											data={data}
											readOnly={readOnly}
											tabsMap={tabsMap}
											onCommentClick={onCommentClick}
											onChildCommentClick={
												onChildCommentClick
											}
										/>
									)}
								/>
							))}
						</Tab.Navigator>
						<HideOnKeyboardOpen>
							{currentPage !== tabsMap.length - 1 &&
							(!isDrawerOpen || !isMobile) ? (
								<FAB
									style={StyleSheet.compose(
										styles.fab,
										currentPage
											? styles.leftButton
											: undefined,
									)}
									small
									color={colors.lightPrimary}
									icon=''
									label={i18nService.translate(
										'general.next',
									)}
									onPress={goNext}
								/>
							) : null}
						</HideOnKeyboardOpen>
						<HideOnKeyboardOpen>
							{currentPage && (!isDrawerOpen || !isMobile) ? (
								<FAB
									style={styles.fab}
									small
									color={colors.lightPrimary}
									icon=''
									label={i18nService.translate(
										'general.prev',
									)}
									onPress={goBack}
								/>
							) : null}
						</HideOnKeyboardOpen>
						<HideOnKeyboardOpen>
							{!isDrawerOpen || !isMobile ? (
								<ActionButtons
									approve={approve}
									reject={reject}
									back={cancel}
									readOnly={readOnly}
									outdatedText={outdatedText.text}
									taxYear={outdatedText.value}
								/>
							) : null}
						</HideOnKeyboardOpen>
					</View>
				</Wrapper>
			</FormProvider>
		</>
	);
};

const styles = StyleSheet.create({
	fab: {
		position: 'absolute',
		margin: 16,
		right: 0,
		bottom: 15,
		borderColor: colors.lightPrimary,
		backgroundColor: colors.white,
		borderWidth: 1,
	},
	leftButton: {
		right: 92,
	},
	root: {
		padding: '0!important',
		minWidth: '20px',
		color: colors.primary,
		position: 'relative',
		left: '-27px',

		// '&:hover': {
		// 	backgroundColor: 'transparent',
		// },
	},
});

export default WorkerCardEditor;
