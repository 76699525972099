import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pushNotificationsService } from '../../services/pushNotifications.service';
import { logout } from '../../store/actions/login.actions';

const LogoutContainer = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logout());
	}, []);

	return null;
};

export default LogoutContainer;
