import React from 'react';
import {
	TouchableOpacity,
	View,
	StyleSheet,
	Platform,
	Dimensions,
} from 'react-native';
import Select from '../../../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import { i18nService } from '../../../../../services/i18n.service';
import colors from '../../../../../config/colors';
import getShadowStyle from '../../../../../config/shadow';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import FormTextInput from '../../../../../components/shared/FieldBuilder/TextInput/TextInput.component';

const CustomerRow = ({
	roles,
	isMobile,
	handleSelectRole,
	openModal,
	disabled,
	handleOnChangePermissionsByRole,
}: any) => {
	return (
		<>
			<View
				style={{
					flexDirection: isMobile ? 'column' : 'row',
					zIndex: 10,
					alignItems: 'flex-end',
				}}
			>
				<View
					style={{
						width: isMobile
							? Dimensions.get('screen').width - 40
							: 227,
						maxWidth: '100%',
						zIndex:
							Platform.OS === 'android' || Platform.OS === 'ios'
								? 10
								: 'unset',
						alignItems: isMobile ? 'flex-end' : 'flex-start',
						marginBottom: isMobile ? 21 : 0,
						marginLeft: isMobile ? 0 : 10,
					}}
				>
					<View
						style={{
							flexDirection: isMobile ? 'row-reverse' : 'row',
							marginBottom: 7,
							alignItems: 'center',
						}}
					>
						<I18n
							size={12}
							style={{
								textAlign: 'right',
								height: 'auto',
							}}
							weight='normal'
						>
							customerManagementModal.fieldsForm.name
						</I18n>
						<Typography
							style={{
								marginLeft: 2,
								textAlign: 'right',
							}}
							size={14}
							color='red'
						>
							*
						</Typography>
					</View>

					<FormTextInput
						editable={false}
						value={`${roles?.customer_name} ${
							roles.customer_hp ? `(${roles.customer_hp})` : ''
						}`}
					/>
				</View>
			</View>
			<View
				style={
					{
						flexDirection: isMobile ? 'row-reverse' : 'row',
						flexWrap: 'wrap',
						alignItems: 'flex-end',
						zIndex:
							Platform.OS === 'android' || Platform.OS === 'ios'
								? 10
								: 'unset',
					} as any
				}
			>
				<View
					style={{
						marginTop: 10,
						flexDirection: 'column',
						zIndex: 10,
						width:
							roles.customerUnits?.length <= 1 && isMobile
								? 200
								: 280,
					}}
				>
					<View
						style={{
							flexDirection: isMobile ? 'row-reverse' : 'row',
							marginBottom: 7,
							zIndex: 10,
							alignItems: 'center',
						}}
					>
						<I18n
							size={14}
							style={{
								textAlign: 'right',
								height: 'auto',
							}}
							weight='normal'
						>
							customerManagementModal.fieldsForm.role
						</I18n>
						<Typography
							style={{ marginLeft: 2, textAlign: 'right' }}
							size={14}
							color='red'
						>
							*
						</Typography>
					</View>
					<Select
						isSearchable={false}
						width={
							roles.customerUnits?.length <= 1 && isMobile
								? 183
								: 280
						}
						noPlaceholder
						disabled={disabled}
						noPlaceholder
						options={[
							{
								id: 10,
								name: i18nService.translate(
									'customerManagementModal.fieldsForm.role.manager',
								),
							},
							{
								id: 8,
								name: i18nService.translate(
									'customerManagementModal.fieldsForm.role.admin',
								),
							},
						]}
						onChange={(value: any) => {
							handleOnChangePermissionsByRole(
								value,
								roles.customer_id,
							);
							handleSelectRole(value, roles.customer_id);
						}}
						value={roles.role_id}
					/>
				</View>
				{/* <View
					style={{
						flexDirection: isMobile ? 'column' : 'row',
						zIndex: 9,
						marginTop: 30,
					}}
				> */}
				{roles.customerUnits?.length > 1 && (
					<TouchableOpacity
						onPress={() =>
							openModal(
								roles.role_id,
								'units',
								undefined,
								roles.customerUnits,
							)
						}
						style={[
							styles.actionButtons,
							{
								marginHorizontal: 10,
								paddingHorizontal: 20,
								flexDirection: isMobile ? 'row-reverse' : 'row',
								marginTop: isMobile ? 20 : 0,
								marginLeft: isMobile ? 0 : 10,
							},
						]}
					>
						<I18n color='white' weight='normal' size={14}>
							customerManagementModal.fieldsForm.units
						</I18n>
						<I18n
							style={{ marginRight: 2 }}
							color='white'
							weight='normal'
							size={14}
						>
							(
							{roles?.unit_ids?.length ||
								roles.customerUnits?.length}
							/{roles.customerUnits?.length})
						</I18n>
					</TouchableOpacity>
				)}
				<TouchableOpacity
					onPress={() =>
						openModal(roles.role_id, 'permissions', true)
					}
					style={[
						styles.actionButtons,
						{
							marginHorizontal: 10,
							paddingHorizontal: 20,
							flexDirection: isMobile ? 'row-reverse' : 'row',
							marginTop:
								roles.customerUnits?.length > 1 && isMobile
									? 20
									: 0,
						},
					]}
				>
					<I18n color='white' weight='normal' size={14}>
						customerManagementModal.permissions
					</I18n>
					<I18n
						style={{ marginRight: 2 }}
						color='white'
						weight='normal'
						size={14}
					>
						(
						{roles?.user_role_permission?.length ||
							roles?.permissionTotal}
						/{roles?.permissionTotal})
					</I18n>
				</TouchableOpacity>
				{/* </View> */}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 180,
		backgroundColor: colors.primary,
		borderRadius: 35,
		height: 40,
		paddingHorizontal: 24,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default CustomerRow;
