import { yupResolver } from '@hookform/resolvers/yup';
import { useRoute } from '@react-navigation/native';
import { omit } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Platform } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import AlertModal from '../../../../modals/AlertModal/AlertModal.component';
import ModalConfirmActions from '../../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import httpService from '../../../../services/http.service';
import { modalService } from '../../../../services/modal.service';
import { goBack } from '../../../../utils/goBack.utils';
import { RepCustomersContext } from '../repCustomersContext';
import { alertMessage, updateCustomerStatus } from './EditCustomer.utils';
import EditCustomerWrapper from './EditCustomerWrapper.component';
import { validationSchema } from './validation';

const EditCustomerContainer = (props: any) => {
	const {
		onNext: tempOnNext,
		callback: tempCallback,
		navigation,
		selectedCustomer,
	} = props;
	const [initialValues, setInitialValues] = useState({
		representId: undefined,
		hp: '',
		name: '',
		address: '',
		phone: '',
		tikNikuim: '',
		licensedNumber: '',
		contactFirstName: '',
		contactLastName: '',
		contactEmail: '',
		contactCellPrefix: '',
		contactCellPhone: '',
		operatorId: undefined,
	});

	const [businessUnitsList, setBusinessUnitsList] = useState([]);
	const operatorsListRef = useRef<any>();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const isBrowser = Platform.OS === 'web';

	const { updateRepCustomersData } = useContext(RepCustomersContext);
	const callback = useMemo(
		() => (isMobile ? updateRepCustomersData : tempCallback),
		[isMobile, tempCallback],
	);
	const onNext = useMemo(
		() => (isMobile ? () => goBack(navigation) : tempOnNext),
		[isMobile, tempOnNext, navigation],
	);

	const route: any = isMobile ? useRoute() : {};

	const urlParams: any = useMemo(
		() => isBrowser && new URL(document.location as any).searchParams,
		[isBrowser && document.location],
	);
	const id = useMemo(() => {
		return (
			props?.id ||
			route.params?.id ||
			route.params?.params?.id ||
			(isBrowser && urlParams?.get('id'))
		);
	}, [props?.id, route.params?.id, route.params?.params?.id, urlParams]);

	const formMethods = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema()),
		mode: 'onBlur',
		reValidateMode: 'onChange',
	});

	const getBusinessUnitsList = async () => {
		const res: any = await httpService.api<string[]>({
			type: 'getRepresntUnits',
			query: { pageName: 'repCustomers', 'rep-only': true },
		});
		setBusinessUnitsList(
			res?.map((item: any) => ({
				...item,
				id: item.numericId,
				stringId: item.id,
			})),
		);
		if (res.length === 1 && !id) {
			formMethods.setValue('representId', res[0].numericId);
		}
	};

	const getCustomerData = async () => {
		const res: any = await httpService.api({
			type: 'getRepCustomerDetails',
			params: { customerId: id },
			disableBI: true,
		});
		if (res) {
			delete res.businessUnits;
			res.operatorId = res.operatorId || -1;

			formMethods.reset({ ...res });
			setInitialValues({ ...res });
		}
	};

	useEffect(() => {
		getBusinessUnitsList();
		if (id) {
			getCustomerData();
		}
	}, [id]);

	const onSubmit = async () => {
		try {
			const result = await formMethods.trigger();

			if (Object.keys(formMethods.formState.errors).length) {
				return;
			}

			const data: any = formMethods.getValues();
			if (initialValues.operatorId !== data.operatorId) {
				const prevOperator = operatorsListRef.current?.find(
					(op: any) =>
						op.id === initialValues.operatorId &&
						initialValues.operatorId !== -1,
				);
				if (prevOperator && !prevOperator.allCustomer) {
					await modalService.openModal(
						null,
						{
							onCancel: (callback: Function) => {
								data.removeOperatorPrivileg = false;
								callback();
							},
							onSubmit: async (callback: Function) => {
								data.removeOperatorPrivileg = true;
								callback();
							},
							submitBtnText: 'general.true',
							cancelBtnText: 'general.false',
							iconName: 'question',
						},
						(props: any) => (
							<ModalConfirmActions {...props}>
								<I18n operatorName={prevOperator.name}>
									editCustomer.operatorAlert
								</I18n>
							</ModalConfirmActions>
						),
					);
				}
			}

			const tempData = id ? omit(data, ['representId', 'hp']) : data;
			const res = await httpService.api({
				type: id ? 'updateRepCustomer' : 'createRepCustomer',
				data: {
					...tempData,
					tikNikuim:
						tempData?.tikNikuim === '' ? null : tempData?.tikNikuim,
					licensedNumber:
						tempData?.licensedNumber === ''
							? null
							: tempData?.licensedNumber,
					operatorId:
						tempData.operatorId === -1 ? null : tempData.operatorId,
				},
				params: { customerId: id },
			});

			callback?.(
				id ? { ...tempData, numericId: id } : { shouldRefresh: true },
			);
			onNext?.();
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.accept',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>{`editCustomer.${
							id ? 'update' : 'create'
						}Success`}</I18n>
					</AlertModal>
				),
			);
		} catch (e: any) {
			try {
				if (e?.data?.hp === formMethods.getValues()?.hp) {
					if (e?.data?.status === false) {
						await modalService.openModal(
							null,
							{
								submitBtnText: 'general.confirm',
								iconName: 'attention',
								onSubmit: (onNextCallback: () => void) => {
									onNextCallback?.();
									updateCustomerStatus(
										e?.data?.id,
										true,
										() => {
											if (
												selectedCustomer === e?.data?.id
											) {
												callback?.(true);
												onNext?.();
											} else {
												onNext?.();
											}
										},
										undefined,
										{ notDisplaySuccessMessage: true },
									);
								},
							},
							(props: any) => (
								<AlertModal {...props}>
									<I18n>editCustomer.alreadyExists</I18n>
								</AlertModal>
							),
						);
					} else {
						alertMessage('editCustomer.IDENTITY_ALREADY_EXISTS');
					}
				} else {
					alertMessage('editCustomer.globalError');
				}
			} catch (error) {
				alertMessage('editCustomer.globalError');
			}
		}
	};

	const onCancel = () => {
		onNext?.();
	};

	return (
		<FormProvider {...formMethods}>
			{id &&
			(!initialValues.hp || initialValues.hp === '') &&
			!isBrowser ? null : (
				<EditCustomerWrapper
					onSubmit={onSubmit}
					onCancel={onCancel}
					businessUnitsList={businessUnitsList}
					id={id}
					operatorsListRef={operatorsListRef}
				/>
			)}
		</FormProvider>
	);
};

export default EditCustomerContainer;
