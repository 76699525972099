import { makeStyles } from '@material-ui/core';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import colors from '../../../../config/colors';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import classnames from 'classnames';
import { Dimensions, Platform, View } from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { getContactFields, getFields, getOperatorFields } from './filedsConfig';
import I18n from '../../../../components/shared/i18n/I18n.component';
import httpService from '../../../../services/http.service';
import { useFormContext, useWatch } from 'react-hook-form';
import { i18nService } from '../../../../services/i18n.service';

const useStyles = makeStyles(() => ({
	bottom: {
		display: 'flex',
		marginTop: 40,
	},
	userDetailsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		width: '100%',
	},
	fieldWrapper: {
		marginBottom: 20,
		marginLeft: '2%',
	},
}));

const wrapperStyles = {
	width: 320,
};

const labelWrapperStyles = {
	flexDirection: 'row',
};

const WrapperComponent = ({ children }: any) => {
	const classes = useStyles();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	if (isMobile) {
		return (
			<KeyboardAwareScrollView
				extraScrollHeight={Platform.OS === 'android' ? 140 : 0}
				scrollToOverflowEnabled={Platform.OS === 'android'}
				enableOnAndroid
				keyboardShouldPersistTaps='handled'
				keyboardOpeningTime={Number.MAX_SAFE_INTEGER}
				contentContainerStyle={{
					paddingBottom: 70,
					width: '100%',
					flexWrap: 'wrap',
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
				}}
				style={{
					flex: 1,
					paddingHorizontal: 20,
					paddingTop: 19,
					maxHeight:
						Dimensions.get('screen').height -
						(platform === DeviceType.MOBILE_WEB
							? 200
							: Platform.OS === 'android'
							? 250
							: 300),
					backgroundColor: colors.white,
					borderRadius: 10,
					zIndex: undefined,
				}}
			>
				{children}
			</KeyboardAwareScrollView>
		);
	}
	return <div className={classes.userDetailsContainer}>{children}</div>;
};

const FieldWrapper = ({ children, field }: any) => {
	const classes = useStyles();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	if (isMobile) {
		return (
			<View
				style={{
					width:
						field.name === 'contactCellPhone'
							? '65%'
							: field.name === 'contactCellPrefix'
							? '30%'
							: '100%',
					marginTop:
						field.name === 'contactCellPrefix'
							? Platform.OS === 'web'
								? 16
								: 3
							: 0,
					marginBottom: field.name === 'contactCellPrefix' ? 10 : 20,
					zIndex:
						Platform.OS === 'web'
							? field.name === 'contactCellPrefix'
								? 11
								: 'unset'
							: undefined,
				}}
			>
				{children}
			</View>
		);
	}
	return (
		<div
			className={classnames({
				[classes.fieldWrapper]: classes.fieldWrapper,
			})}
		>
			{children}
		</div>
	);
};

const EditCustomer = ({ businessUnitsList, id, operatorsListRef }: any) => {
	const [operatorsOptions, setOperatorsOptions] = useState<any>([]);
	const { setValue, getValues } = useFormContext();
	const dbounceTimeout = useRef<any>();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const representId = useWatch({
		name: 'representId',
	});

	const getOperatorslist = useCallback(async () => {
		const res: any = await httpService.api({
			type: 'getCustomerOperatorsList',
			query: {
				unitIds: getValues().representId,
			},
			disableBI: true,
		});

		const options = [
			{
				id: -1,
				name: i18nService.translate(
					'repCustomersPage.operator.noManager',
				),
			},
			...res,
		];
		operatorsListRef.current = options;
		setOperatorsOptions(options);
	}, []);

	const getOperatorslistDebounce = useCallback(() => {
		if (dbounceTimeout.current) {
			clearTimeout(dbounceTimeout.current);
		}
		dbounceTimeout.current = setTimeout(() => {
			getOperatorslist();
		}, 500);
	}, []);

	useEffect(() => {
		if (representId) {
			getOperatorslistDebounce();
		} else {
			operatorsListRef.current = [];
			setOperatorsOptions([]);
		}
	}, [representId]);

	const fields = useMemo(
		() => getFields(businessUnitsList, id, isMobile, setValue),
		[businessUnitsList, id, isMobile, setValue],
	);
	const contactFields = useMemo(() => getContactFields(isMobile), [isMobile]);
	const operatorFields = useMemo(
		() => getOperatorFields(operatorsOptions, isMobile),
		[operatorsOptions],
	);

	return (
		<>
			<WrapperComponent>
				{fields.map((field: any) => {
					return (
						<FieldWrapper field={field} key={field.name}>
							<FieldBuilder
								key={field.name}
								{...field}
								styles={{
									wrapper: field.styles?.wrapper
										? field.styles?.wrapper
										: wrapperStyles,
									labelWrapper: isMobile
										? undefined
										: field.styles?.labelWrapper
										? field.styles?.labelWrapper
										: labelWrapperStyles,
								}}
							/>
						</FieldWrapper>
					);
				})}
				<I18n
					weight='bold'
					size={16}
					style={{
						textAlign: 'right',
						marginTop: 15,
						width: '100%',
						marginBottom: 15,
					}}
				>
					editCustomer.contactInfo
				</I18n>
				{contactFields.map((field: any) => {
					return (
						<FieldWrapper field={field} key={field.name}>
							<FieldBuilder
								key={field.name}
								{...field}
								styles={{
									wrapper: field.styles?.wrapper
										? field.styles?.wrapper
										: wrapperStyles,
									labelWrapper: isMobile
										? undefined
										: field.styles?.labelWrapper
										? field.styles?.labelWrapper
										: labelWrapperStyles,
								}}
							/>
						</FieldWrapper>
					);
				})}
				{operatorFields.map((field: any) => {
					return (
						<FieldWrapper field={field} key={field.name}>
							<FieldBuilder
								key={field.name}
								{...field}
								styles={{
									wrapper: field.styles?.wrapper
										? field.styles?.wrapper
										: wrapperStyles,
									labelWrapper: isMobile
										? undefined
										: field.styles?.labelWrapper
										? field.styles?.labelWrapper
										: labelWrapperStyles,
								}}
							/>
						</FieldWrapper>
					);
				})}
			</WrapperComponent>
		</>
	);
};

export default React.memo(EditCustomer);
