import React, { useEffect, useState } from 'react';
import DocumentsContainer from './Documents.container';

const DocumentsWrapper = ({
	navigation,
	route,
}: {
	navigation: any;
	route: any;
}) => {
	const [isEmployer, setIsEmployer] = useState<undefined | boolean>(
		undefined,
	);

	useEffect(() => {
		setIsEmployer(route.name === 'documentsManagment' ? true : false);
	}, []);

	return isEmployer === undefined ? null : (
		<DocumentsContainer
			navigation={navigation}
			route={route}
			isEmployer={isEmployer}
		/>
	);
};

export default React.memo(DocumentsWrapper);
