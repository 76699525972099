import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { modalService } from '../../../services/modal.service';
import Modal from './Modal.component';

const ModalsContainer = (props: any) => {
	const [modals, setModals] = useState([]);

	useEffect(() => {
		const sub = modalService.subscribe((m: any) => setModals(m));
		return () => sub.unsubscribe();
	}, []);

	const onClose = (
		id: number, 
		type?: string, 
		data?: any, 
		onCustomNext?: Function
	) => {
		modalService.closeModal(id, data);
		props.onNext && props.onNext(type, data);
		onCustomNext && onCustomNext()
	};

	return (
		<>
			{modals.map(({ id, args, props, Component }, index) => (
				<Modal
					key={id}
					{...props}
					onNext={(type?: string, data?: any) =>
						onClose(id, type, data, props.onCustomNext)
					}
					visible={
						Platform.OS === 'web' || index === modals.length - 1
					}
					Component={Component}
				/>
			))}
		</>
	);
};

export default ModalsContainer;
