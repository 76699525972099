import React, {
	useCallback,
	useEffect,
	useState,
	useRef,
	useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { getUserData } from '../../../store/selectors/login.selectors';
import { toggleReFetch } from '../../../store/actions/clockReport.actions';
import { useRoute } from '@react-navigation/native';
import moment from 'moment';
import { getChosenDate } from '../../../store/selectors/clockReport.selector';
import I18n from '../../../components/shared/i18n/I18n.component';
import { Record } from '../../../components/shared/ClockReport/Record/Record.component';
import AddRequest from '../../../modals/AddRequestModal/AddRequestModal.container';
import httpService from '../../../services/http.service';
import { modalService } from '../../../services/modal.service';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import DayReportsMobile from './DayReportsMobile.component';
import DayReportsWeb from './DayReportsWeb.component';
import ClockReportContainer from '../../../components/shared/ClockReport/Clock.container';
import { openAlertModal } from '../../../components/shared/ClockReport/Clock.utils';
import { i18nService } from '../../../services/i18n.service';
import { Privileges } from '../../../constants/roleTypes.constant';

export const convertFromUtc = (time: string, getHoursAndMinutes: boolean) => {
	if (!time) return null;
	const format = getHoursAndMinutes ? 'HH:mm' : "'YYYY-MM-DD HH:mm'";
	const utcTime = moment.utc(time, 'YYYY-MM-DDTHH:mm');
	const local = moment(utcTime).local().format(format);
	return local;
};

const DayReportsContainer = ({ navigation }: { navigation: any }) => {
	const dispatch = useDispatch();
	const isFirstInit = useRef(true);
	const devicePlatformRef = useRef<DeviceType>(useDevicePlatform());
	const isMobile = devicePlatformRef.current !== DeviceType.WEB;
	const route = useRoute();
	const selectedDate = useSelector(getChosenDate);
	const userData = useSelector(getUserData);
	const isDisabled = useMemo(
		() =>
			moment(selectedDate, 'YYYY-MM-DD').isAfter(
				moment(
					userData?.operative_module?.[101]?.finish_work,
					'YYYY-MM-DD',
				),
			),
		[userData?.operative_module[101], selectedDate],
	);

	const isLocked = useMemo(() => {
		if (isDisabled) return true;
		switch (userData?.customer_setting.worklogLockType) {
			case 'SELECTED_DAY':
				const lockDay = userData.customer_setting.lockDay;
				const lockDate = `${moment().format('YYYY-MM')}-${
					lockDay < 10 ? `0${lockDay}` : lockDay
				}`;
				if (
					moment().isSameOrAfter(moment(lockDate, 'YYYY-MM-DD')) &&
					moment(selectedDate, 'YYYY-MM-DD').isSameOrBefore(
						moment().subtract(1, 'month').endOf('month'),
					)
				) {
					return true;
				} else {
					return false;
				}

			case 'END_OF_MONTH':
				return moment(selectedDate, 'YYYY-MM-DD').isBefore(
					moment().startOf('month'),
				);
			default:
				//NO LOCK
				return false;
		}
	}, [userData, selectedDate]);

	const canEdit = useMemo(
		() =>
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_WRITE,
			) && userData?.customer_setting.allowUpdateReport,
		[userData],
	);

	const openDrawer = useCallback(() => {
		navigation.openDrawer();
	}, []);
	const [aId, setAid] = useState(null);
	const [reportSelectedDate, setReports] = useState([]);
	const [isEvents, setIsEvents] = useState(false);
	const [eventsOptions, setEventsOptions] = useState<Option[] | null>(null);
	const [workLogListForClock, setWorkLogListForClock] = useState<{} | null>(
		null,
	);

	const selectedDay = selectedDate
		? moment(selectedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
		: moment().format('DD/MM/YYYY');

	const [standardHours, setStandartHours] = useState<any>(null);

	const isToday = useMemo(
		() => selectedDay === moment().format('DD/MM/YYYY'),
		[selectedDay],
	);

	const totalReported = useMemo(
		() =>
			reportSelectedDate.reduce((total: number, worklog: Record) => {
				return total + worklog.total / 60;
			}, 0),
		[reportSelectedDate],
	);

	const handleDelete = async id => {
		if (isLocked) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else
			await modalService.openModal(
				null,

				{
					onCancel: (callback: Function) => {
						callback();
					},

					onSubmit: async (callback: Function) => {
						try {
							const res = await httpService.api<any>({
								type: 'deleteEmployeeReportByEmployee',
								params: { workLogId: id },
							});
						} catch (error) {
							console.log(error);
						}
						getChosenDateReports();
						dispatch(toggleReFetch());
						callback();
					},

					submitBtnText: 'general.true',

					cancelBtnText: 'general.false',

					iconName: 'question',
				},

				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>modal.deleteReport.message</I18n>
					</ModalConfirmActions>
				),
			);
	};

	const handleAddNewLog = async () => {
		if (totalReported >= userData?.customer_setting.maxShiftHour) {
			openAlertModal(
				`${i18nService.translate('general.maxShiftTimeError')} ${
					userData?.customer_setting.maxShiftHour
				} ${i18nService.translate('general.maxShiftTimeError2')}`,
				'attention',
			);
			return;
		}
		if (isLocked) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else
			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},

					onSubmit: async (callback: Function) => {
						getChosenDateReports();
						dispatch(toggleReFetch());
						callback();
					},

					submitBtnText: 'general.accept',
					route: route,
					cancelBtnText: 'general.cancel',
					isEdit: false,
					isManager: false,
					prevValues: {
						date: selectedDate,
						employeeId: aId,
						fullName: `${userData?.first_name} ${userData?.last_name}`,
					},
					totalReported,
					shiftHours: userData?.customer_setting.maxShiftHour,
					openEditModal: handleEdit,
					selectedDateForEvent: selectedDate,
					haveEvents: !!reportSelectedDate.length,
					hideProjects: moment(
						moment(selectedDay, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					).isAfter(moment().format('YYYY-MM-DD')),
				},
				(props: any) => <AddRequest hideName addNew {...props} />,
			);
	};

	const handleEdit = async (id, workLog) => {
		const fullName = `${userData?.first_name} ${userData?.last_name}`;
		if (isLocked) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else {
			let logValues = {};
			if (id) {
				logValues = await httpService.api<any>({
					type: 'workLogByIdEmployee',
					params: { workLogId: id },
				});
			}

			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						getChosenDateReports();
						dispatch(toggleReFetch());
						callback();
					},

					submitBtnText: 'general.accept',
					route: route,
					cancelBtnText: 'general.cancel',
					isEdit: true,
					openEditModal: handleEdit,
					workLogId: id,
					isManager: false,
					prevValues: {
						...(workLog ? workLog : logValues),
						originalExitEntry: {
							entryTime: workLog
								? workLog?.enterTime
								: logValues?.entryTime,
							exitTime: workLog
								? workLog?.exitTime
								: logValues?.exitTime,
						},
						entryTime: convertFromUtc(
							workLog ? workLog?.enterTime : logValues?.entryTime,
							true,
						),
						exitTime: convertFromUtc(
							workLog ? workLog?.exitTime : logValues?.exitTime,
							true,
						),
						employee: {
							id: aId,
							name: fullName,
						},
						fullName,
						date: moment(selectedDay, 'DD/MM/YYYY').format(
							'YYYY-MM-DD',
						),
					},
					totalReported,
					shiftHours: userData?.customer_setting.maxShiftHour,
					hideProjects: moment(
						moment(selectedDay, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					).isAfter(moment().format('YYYY-MM-DD')),
				},
				(props: any) => <AddRequest {...props} />,
			);
		}
	};

	const getEvents = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getAllEventNameList',
				query: {
					date: moment(selectedDay, 'DD/MM/YYYY').format(
						'YYYY-MM-DD',
					),
				},
			});
			if (res) {
				setIsEvents(res.length > 0 ? true : false);
				setEventsOptions(res);
			} else {
				setIsEvents(false);
			}
		} catch (e) {}
	};

	const hideButton = useMemo(() => {
		let disable = moment(
			moment(selectedDay, 'DD/MM/YYYY').format('YYYY-MM-DD'),
		).isAfter(moment().format('YYYY-MM-DD'));

		const fulldayReport = reportSelectedDate.filter(
			(workLog: Record) => workLog.eventId && workLog.fullDay,
		);

		if (
			userData?.customer_setting?.eventSelectionType !== 'NONE' &&
			isEvents
		) {
			disable = false;
		}

		if (fulldayReport.length) {
			disable = true;
		}

		return disable;
	}, [reportSelectedDate, userData]);

	const getStandardHours = async () => {
		try {
			if (isDisabled) {
				setReports([]);
				return;
			}
			const res = await httpService.api<any>({
				type: 'getStandardByDate',
				params: {
					date: moment(selectedDay, 'DD/MM/YYYY').format(
						'YYYY-MM-DD',
					),
				},
			});
			setStandartHours(res);
		} catch (e) {}
	};

	const getChosenDateReports = async () => {
		try {
			if (isDisabled) {
				setReports([]);
				return;
			}
			const res = await httpService.api<any>({
				type: 'getWorkLogListForClock',
				params: {
					date: selectedDate,
				},
			});
			if (res) {
				setWorkLogListForClock(res);
				setReports(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getStandardHours();
		getChosenDateReports();
		setAid(userData?.id);
	}, [selectedDay, userData]);

	useEffect(() => {
		isFirstInit.current = false;
		getEvents();
	}, []);

	return (
		<>
			{isMobile ? (
				<DayReportsMobile
					canEdit={canEdit}
					navigation={navigation}
					openDrawer={openDrawer}
					onDelete={handleDelete}
					onEdit={handleEdit}
					hideButton={hideButton}
					addWorkLog={handleAddNewLog}
					standardHours={standardHours}
					totalReported={totalReported}
					isToday={isToday}
					selectedDay={selectedDay}
					reportSelectedDate={reportSelectedDate}
				/>
			) : isToday &&
			  !isDisabled &&
			  userData?.employeeRolePrivileges?.includes(
					Privileges.ROLE_LOG_WORK_WRITE,
			  ) &&
			  eventsOptions &&
			  workLogListForClock ? (
				<ClockReportContainer
					eventsOptionsProp={eventsOptions}
					standardHoursProp={standardHours}
					workLogListForClock={workLogListForClock}
				/>
			) : (
				<DayReportsWeb
					canEdit={canEdit}
					openDrawer={openDrawer}
					onDelete={handleDelete}
					onEdit={handleEdit}
					hideButton={hideButton}
					addWorkLog={handleAddNewLog}
					standardHours={standardHours}
					totalReported={totalReported}
					isToday={isToday}
					selectedDay={selectedDay}
					reportSelectedDate={reportSelectedDate}
				/>
			)}
		</>
	);
};

export default DayReportsContainer;
