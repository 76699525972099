import { FromMonthToMonthReportsFiltersType } from '../FromMonthToMonthReports.utils';
import * as FromMonthToMonthReportsActions from './FromMonthToMonthReports.actions';

export interface FromMonthToMonthReportsRequest {
	page: number;
	pageSize: number;
	sortBy: string | undefined;
	sortDirection: 'ASC' | 'DESC' | '';
	search: '';
	filter: Record<FromMonthToMonthReportsFiltersType, string[]>;
}

export const initialState: FromMonthToMonthReportsRequest = {
	page: 0,
	pageSize: 15,
	sortBy: '',
	sortDirection: 'DESC',
	search: '',
	filter: {
		dep: [],
		emp: [],
		status: [],
	},
};

const reducer = (
	state: FromMonthToMonthReportsRequest = initialState,
	action: any,
) => {
	switch (action.type) {
		case FromMonthToMonthReportsActions.RESET_PAGE:
			return { ...state, page: 0 };
		case FromMonthToMonthReportsActions.SET_ALL_FILTERS:
			return { ...action.payload };
		case FromMonthToMonthReportsActions.INCREMENT_PAGE:
			return {
				...state,
				page: state.page + 1,
			};
		case FromMonthToMonthReportsActions.SET_SORT:
			return {
				...state,
				page: 0,
				search: state.search,
				filter: state.filter,
				sortBy: action.payload.name,
				sortDirection: action.payload.direction,
			};
		case FromMonthToMonthReportsActions.SET_SEARCH_QUERY:
			return {
				...state,
				page: 0,
				search: action.payload,
			};
		case FromMonthToMonthReportsActions.SET_FILTER:
			const { isMultiselect } = action.payload;
			let filterData: string[] = [];
			if (isMultiselect && !action.payload.value.length) {
				filterData = [];
			} else if (isMultiselect || Array.isArray(action.payload.value)) {
				filterData = action.payload.value
					.filter((v: any) => v?.id !== 'all')
					.map((v: any) => v.id);
			} else {
				filterData = action.payload.value ? [action.payload.value] : [];
			}

			return {
				...state,
				page: 0,
				filter: {
					...state.filter,
					[action.payload.filter]: filterData,
				},
			};
		case FromMonthToMonthReportsActions.RESET_FILTERS:
			return {
				...state,
				sortBy: '',
				sortDirection: 'DESC',
				search: '',
				filter: {
					dep: [],
					emp: [],
					status: [],
				},
			};
		default:
			return state;
	}
};

export default reducer;
