import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/core';
import httpService from '../../services/http.service';
import EmplooyeeRequests from './EmplooyeeRequests.component';
import { StatusOptions, typeOptions } from './EmplooyeeRequests.utils';
import {
	getDepartmentsFilterList,
	getRequestFilterState,
} from '../../store/selectors/reportsFilters.selectors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
	updateRequestsFilters,
} from '../../store/actions/reportsFilters.actions';
import {
	getEmployerRolesPrivileges,
	getUserData,
} from '../../store/selectors/login.selectors';
import { Privileges } from '../../constants/roleTypes.constant';
import { i18nService } from '../../services/i18n.service';
import moment from 'moment';
import { Platform } from 'react-native';
import { debounce } from 'lodash';
import useBusinessUnitsState from '../../hooks/useBusinessUnitsState';
import routes from '../../config/routes';

export interface EmplooyeeRequestsData {
	id: number;
	fullName: string;
	teudatZeut: string;
	departmentName: string;
	type: string;
	status: string;
	dateRange: string;
	lastUpdate: string;
	description: string;
	employerComment: string | null;
	attachedFiles: number;
	businessUnitId: number;
	businessUnitName: string;
}

const EmplooyeeRequestsContainer = ({
	navigation,
	route,
}: {
	navigation: any;
	route: any;
}) => {
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);
	const sevedFilters = useSelector(getRequestFilterState);
	const userData = useSelector(getUserData);

	const platform = useDevicePlatform();
	const isWeb = platform === DeviceType.WEB;
	const isMobile = platform === DeviceType.MOBILE;

	const defaultFilters = {
		dep: [],
		type: [],
		emp: [],
		status: ['PENDING'],
		unitIds: [],
	};

	const [requestsData, setRequestsData] = useState<EmplooyeeRequestsData[]>(
		[],
	);

	const [validationDate, setValidationDate] = useState(
		moment().subtract(1, 'months').startOf('month').valueOf(),
	);
	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('DESC');
	const [sortBy, setSortBy] = useState('lastUpdate');
	const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
	const [filters, setFilters] = useState(defaultFilters);
	const [finishFirstLoad, setFinishFirstLoad] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [filtesSelected, setFiltesSelected] = useState(true);
	const [selectedDepartments, setSelectedDepartments] = useState<any>({
		dep: [],
	});
	const [selectRequestsFilters, setSelectRequestsFilters] = useState(null);

	const goBackMode = route.params?.goBackMode || false;
	const dispatch = useDispatch();

	const onSaveFilter = (filterData: any) => {
		const requestsFilters = {
			...sevedFilters.requestsFilters,
		};
		setSelectedFilters(prev => ({
			...prev,
			...requestsFilters,
		}));

		dispatch(
			updateRequestsFilters({
				requestsFilters,
				requestsSearch: searchText,
			}),
		);
		const filter = handleFilter(filterData);
		setFilters({ ...filter });
		navigation?.setParams({ goBackMode: undefined });
	};

	const onFilter = (filterData: any, byClick?: boolean) => {
		if (filterData.name === 'search') {
			setSearchText(filterData.value);
			dispatch(
				updateRequestsFilters({
					requestsFilters: { ...selectedFilters },
					requestsSearch: filterData.value,
				}),
			);
			getRequestsData(
				true,
				defaultSort,
				defaultOrder,
				filters,
				filterData.value,
			);
		} else {
			let requestsFilters: any;
			if (filterData.name === 'unitIds') {
				requestsFilters = {
					...filters,
					...sevedFilters.requestsFilters,
					unitIds: filterData.isMulti
						? filterData.value.map((val: any) => val.id || val)
						: [filterData.value],
					dep: [],
					emp: [],
				};
			} else {
				requestsFilters = {
					...filters,
					...sevedFilters.requestsFilters,
					[filterData.name]: filterData.value.map(
						(el: any) => el?.id,
					),
				};
			}

			setSelectedFilters(prev => ({
				...prev,
				...requestsFilters,
			}));
			dispatch(
				updateRequestsFilters({
					requestsFilters,
					requestsSearch: searchText,
				}),
			);
			const filter = handleFilter(filterData);
			setFilters({ ...filter });
			if (byClick && filterData.name !== 'unitIds') {
				getRequestsData(true, defaultSort, defaultOrder, filter);
			}
		}
	};

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.emploeeRequests.privileges,
		onChangeFilter: goBackMode ? onSaveFilter : onFilter,
	});

	const defaultSort = 'lastUpdate';
	const defaultOrder = 'DESC';

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: 20,
			search,
			filter: selectedDepartments,
			unitIds: getSelectedUnits(filters.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: 20 === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	useEffect(() => {
		onMount();
	}, [userData]);

	useEffect(() => {
		const fil =
			isWeb || Object.keys(sevedFilters.requestsFilters).length === 0
				? filters
					? filters
					: defaultFilters
				: handelFiltersForMobile(sevedFilters.requestsFilters);

		let ser = '';
		if (!(isWeb || !sevedFilters.requestsSearch)) {
			setSearchText(sevedFilters.requestsSearch);
			ser = sevedFilters.requestsSearch;
		}
		if (filters.unitIds.length || multiValue) {
			getRequestsData(true, defaultSort, defaultOrder, fil, ser);
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(filters.unitIds),
				),
			);
		}
	}, [filters.unitIds]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('EmplooyeeRequests');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};

	const debouncedGetMoreData = debounce((...args) => {
		getRequestsData(...args);
	}, 300);

	const filtersConfig = useMemo(() => {
		let data = [
			{
				name: 'requestType',
				options: typeOptions(
					employerRolesPrivileges?.includes(
						Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
					),
					employerRolesPrivileges?.includes(
						Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
					),
				),
				placeholder: 'requestType',
				styles: { width: 134 },
				type: 'multiselect',
				value: [],
			},
			{
				name: 'departments',
				options: departmentsFilterList,
				placeholder: 'departments',
				styles: { width: 134 },
				type: 'multiselect',
				value: [],
			},
			{
				name: 'status',
				options: StatusOptions,
				value: isWeb
					? [
							{
								id: 'PENDING',
								name: i18nService.translate('general.PENDING'),
							},
					  ]
					: [{ id: 'PENDING', name: 'PENDING' }],
				placeholder: 'status',
				styles: { width: 134 },
				type: 'multiselect',
			},
			{
				name: 'search',
				defaultValue: sevedFilters?.requestsSearch || '',
				options: [],
				type: 'searchInput',
				placeholder: i18nService.translate('filter.search'),
			},
		];
		if (isWeb) {
			data = [
				...data,
				{
					name: 'employees',
					type: 'asyncmultiselect',
					placeholder: 'employees',
					loadOptions: getEmployeesFilter,
					styles: {
						width: 274,
					},
				},
			];
		}
		data =
			businessUnitsList?.length > 1
				? [
						...data,
						isBusinessUnitsMulti
							? {
									name: 'unitIds',
									type: 'multiselect',
									placeholder: 'businessUnits',
									value: filters.unitIds?.map((unit: any) =>
										businessUnitsList.find(
											(bunit: any) => bunit.id === unit,
										),
									),
									options: businessUnitsList,
									styles: {
										control: {
											width: 200,
											maxWidth: 200,
										},
									},
							  }
							: {
									name: 'unitIds',
									type: 'select',
									placeholder: 'businessUnits',
									defaultValue: filters.unitIds?.[0],
									options: businessUnitsList,
									hideDefaultOption: true,
									styles: {
										width: 150,
									},
							  },
				  ]
				: data;

		return data;
	}, [departmentsFilterList, isWeb, userData]);

	const handleFilter = (data: any, filtersData?: any) => {
		let fl = filtersData ? { ...filtersData } : { ...filters };
		setFiltesSelected(true);

		if (data.name === 'unitIds') {
			fl = {
				...fl,
				unitIds: data.isMulti
					? data.value.map((val: any) => val.id || val)
					: [data.value],
				dep: [],
				emp: [],
			};
		}
		if (data.name === 'departments') {
			setSelectedDepartments({
				dep: data.value.map((el: any) => el.id),
			});
			fl = { ...fl, dep: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'requestType') {
			fl = { ...fl, type: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'employees') {
			fl = { ...fl, emp: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'status') {
			fl = { ...fl, status: data.value.map((el: any) => el.id) };
		}
		if (
			fl?.dep?.length === 0 &&
			fl?.type?.length === 0 &&
			fl?.emp?.length === 0 &&
			fl?.status?.length === 0 &&
			fl?.unitIds?.length === 0
		) {
			setFiltesSelected(false);
		}
		return fl;
	};

	const handelFiltersForMobile = data => {
		setSelectedFilters(data);
		let temp = {};

		if (data.hasOwnProperty('unitIds')) {
			temp = { ...temp, unitIds: data['unitIds'] };
		}
		if (data.hasOwnProperty('departments')) {
			temp = { ...temp, dep: data['departments'] };
		}
		if (data.hasOwnProperty('requestType')) {
			temp = { ...temp, type: data['requestType'] };
		}
		if (data.hasOwnProperty('status')) {
			temp = { ...temp, status: data['status'] };
		}
		if (data.hasOwnProperty('employees')) {
			temp = { ...temp, emp: data['employees'] };
		}
		if (
			data?.departments?.length > 0 ||
			data?.requestType?.length > 0 ||
			data?.status?.length > 0 ||
			data?.employees?.length > 0 ||
			data?.unitIds?.length > 0
		) {
			setFiltesSelected(true);
		}

		setFilters(prev => ({ ...prev, ...temp }));
		return temp;
	};

	const getRequestsData = async (
		firstInit: boolean,
		newSortBy?: string,
		neworder?: string,
		filterData?: any,
		search?: string,
	) => {
		try {
			const tempFilter = filterData || filters;
			const ps = isMobile ? 15 : 40;
			const res = await httpService.api<any>({
				type: 'getEmployeeRequests',
				data: {
					p: firstInit ? 0 : nextPage,
					ps: ps,
					s: newSortBy ? newSortBy : sortBy,
					sd: neworder ? neworder : order,
					f: {
						...tempFilter,
						unitIds: getSelectedUnits(tempFilter?.unitIds),
					},
					search: typeof search === 'string' ? search : searchText,
				},
			});

			if (res) {
				setFinishFirstLoad(true);
				setDisableLoad(res.data.length < ps ? true : false);
				!!res?.validationDate &&
					setValidationDate(
						moment(res.validationDate, 'DD/MM/YYYY').valueOf(),
					);

				if (firstInit) {
					setRequestsData(res.data);
					setNextPage(1);
				} else {
					setRequestsData([...requestsData, ...res.data]);
					setNextPage(nextPage + 1);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onSort = (newSort: string) => {
		let newOrder = order;
		if (newSort === sortBy) {
			newOrder = order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			newOrder = 'DESC';
		}
		setOrder(newOrder);
		setSortBy(newSort);
		getRequestsData(true, newSort, newOrder, filters);
	};

	const onAcceptRequests = (isStarted: boolean, filterData: any) => {
		if (isStarted) {
			const filter = handleFilter(filterData, selectRequestsFilters);
			setSelectRequestsFilters(filter);
			if (filterData.name !== 'status') {
				setSelectedFilters(prev => ({
					...prev,
					[filterData.name]: filterData.value.map(
						(el: any) => el?.id,
					),
				}));
				const filter = handleFilter(filterData);
				setFilters({ ...filter });
			}

			getRequestsData(true, defaultSort, defaultOrder, filter);
		} else {
			handleFilter({});
			setSelectRequestsFilters(null);
			getRequestsData(true, defaultSort, defaultOrder, filters);
		}
	};

	return (
		<EmplooyeeRequests
			finishFirstLoad={finishFirstLoad}
			navigation={navigation}
			data={requestsData}
			onSort={onSort}
			onLoadMore={() =>
				disabledLoad
					? null
					: debouncedGetMoreData(
							false,
							sortBy,
							order,
							selectRequestsFilters
								? selectRequestsFilters
								: filters,
					  )
			}
			onFilter={onFilter}
			selectedFilters={selectedFilters}
			filtersConfig={filtersConfig}
			refresh={() => getRequestsData(true, sortBy, order, filters)}
			filtesSelected={filtesSelected}
			validationDate={validationDate}
			onAcceptRequests={onAcceptRequests}
			businessUnitsList={businessUnitsList}
		/>
	);
};

export default EmplooyeeRequestsContainer;
