import { useNavigation } from '@react-navigation/core';
import React, { useMemo, useState } from 'react';
import { TouchableHighlight, View, StyleSheet } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../components/shared/Typography/Typography.component';
import { downloadUrlApprovedManagerFile } from '../../WorkerCard/WorkerCardPreview/utils';
import { createAndDownload101 } from '../../WorkerCard/WorkerCardPreview/workerCardPreviewBuilder';
import {
	checkIfCheckboxIsDisabled,
	statusReport101Matcher,
} from '../Report101Page.utils';
import { downloadAttachmentsHandler } from './downloadManagerAttachmentsFunction';

const MobileItem = ({
	dataRow,
	onSelectRowCheckboxPressed,
	isRowSelected,
	onRowClick,
	activeActionButtonType,
	businessUnitsList,
}: {
	dataRow: any;
	onSelectRowCheckboxPressed: (value: any) => void;
	isRowSelected: boolean;
	onRowClick: (dataItem: any) => void;
	activeActionButtonType: boolean;
	businessUnitsList: any[];
}) => {
	const navigation = useNavigation();
	const [isRowChosen, setIsRowChosen] = useState(false);
	const isCheckable = useMemo(
		() =>
			!checkIfCheckboxIsDisabled(
				{
					original: {
						...dataRow?.item,
						statusCode: dataRow?.item?.statusCode,
					},
				},
				activeActionButtonType,
			),
		[dataRow?.item?.statusCode, activeActionButtonType],
	);

	const fontSizeConf = 14;

	return (
		<>
			{!isRowChosen && (
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => setIsRowChosen(true)}
				>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							height: 70,
							backgroundColor: 'white',
							marginTop: 10,
							marginBottom: 10,
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: 10,
						}}
					>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<Typography color='primary' weight='bold' size={14}>
								{dataRow?.item?.fullName}
							</Typography>
							{activeActionButtonType && (
								<>
									{!isRowSelected && (
										<TouchableHighlight
											underlayColor='transparent'
											style={{
												marginLeft: 5,
												marginRight: 10,
												opacity: isCheckable ? 1 : 0.5,
											}}
											onPress={() =>
												isCheckable &&
												onSelectRowCheckboxPressed(
													dataRow?.item,
												)
											}
										>
											<ImageIcon
												name={'checkboxUnselected'}
												width={20}
												height={20}
											/>
										</TouchableHighlight>
									)}
									{isRowSelected && (
										<TouchableHighlight
											underlayColor='transparent'
											style={{
												marginLeft: 5,
												marginRight: 10,
											}}
											onPress={() =>
												onSelectRowCheckboxPressed(
													dataRow?.item,
												)
											}
										>
											<ImageIcon
												name={'checkboxSelected'}
												width={20}
												height={20}
											/>
										</TouchableHighlight>
									)}
								</>
							)}
						</View>
						<View>
							<Typography>{dataRow?.item?.teudatZeut}</Typography>
						</View>
						<View>
							<Typography>
								{dataRow?.item?.employeeCreateDate}
							</Typography>
						</View>
						<View>
							<View
								style={{
									width: 10,
									height: 10,
									backgroundColor: `${
										statusReport101Matcher[
											dataRow?.item?.status
										]?.color
									}`,
									borderRadius: 50,
									marginLeft: 3,
								}}
							></View>
						</View>
					</View>
				</TouchableHighlight>
			)}
			{isRowChosen && (
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => setIsRowChosen(false)}
				>
					<View
						style={[
							styles.selectedMobileRowContainer,
							businessUnitsList.length > 1 ? { height: 345 } : {},
						]}
					>
						<View style={styles.selectedMobileRowControlContainer}>
							<View
								style={
									styles.selectedMobileRowControlDownloadGroup
								}
							>
								<TouchableHighlight
									style={downloadBtnStyle}
									underlayColor='transparent'
									onPress={e => {
										e.persist();
										downloadAttachmentsHandler(
											dataRow.item.id,
											dataRow.item,
										);
									}}
								>
									<View
										style={{ flexDirection: 'row-reverse' }}
									>
										<Typography
											color='primary'
											weight='400'
											size={14}
										>
											{dataRow?.item?.attachedFiles}
										</Typography>
										<ImageIcon
											name='downloadPaycheck'
											height={20}
											width={20}
										/>
									</View>
								</TouchableHighlight>
								<TouchableHighlight
									style={downloadBtnStyle}
									underlayColor='transparent'
									onPress={async e => {
										e.persist();
										if (
											dataRow.item.statusCode !==
											'APPROVED'
										) {
											createAndDownload101(
												dataRow.item.id,
												false,
											);
										} else {
											downloadUrlApprovedManagerFile(
												dataRow.item.id,
												dataRow.item,
											);
										}
									}}
								>
									<ImageIcon
										name='downloadPDF'
										height={20}
										width={15}
									/>
								</TouchableHighlight>
							</View>

							<View>
								{activeActionButtonType && (
									<TouchableHighlight
										underlayColor='transparent'
										style={{
											marginLeft: 5,
											marginRight: 20,
										}}
										onPress={() =>
											onSelectRowCheckboxPressed(
												dataRow?.item,
											)
										}
									>
										<ImageIcon
											name={
												!isRowSelected
													? 'checkboxUnselected'
													: 'checkboxSelected'
											}
											width={20}
											height={20}
										/>
									</TouchableHighlight>
								)}
							</View>
						</View>

						<>
							{businessUnitsList.length > 1 ? (
								<View style={styles.selectedMobileRowItem}>
									<View
										style={styles.selectedMobileRowItemData}
									>
										<Typography
											color='darkGrey'
											weight='400'
											size={fontSizeConf}
										>
											{dataRow?.item?.businessUnitName}
										</Typography>
									</View>
									<View
										style={styles.selectedMobileRowItemName}
									>
										<I18n
											weight='normal'
											size={fontSizeConf}
										>
											{`general.unitIds`}
										</I18n>
									</View>
								</View>
							) : null}
							<View style={styles.selectedMobileRowItem}>
								<TouchableHighlight
									onPress={e => {
										onRowClick(dataRow?.item);
										e.persist();
									}}
									style={styles.selectedMobileRowItemData}
								>
									<Typography
										color='primary'
										weight='bold'
										size={fontSizeConf}
									>
										{dataRow?.item?.fullName}
									</Typography>
								</TouchableHighlight>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.fullName`}
									</I18n>
								</View>
							</View>
							<View style={styles.selectedMobileRowItem}>
								<View style={styles.selectedMobileRowItemData}>
									<Typography
										color='darkGrey'
										weight='400'
										size={fontSizeConf}
									>
										{dataRow?.item?.taxYear}
									</Typography>
								</View>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.taxYear`}
									</I18n>
								</View>
							</View>
							<View style={styles.selectedMobileRowItem}>
								<View style={styles.selectedMobileRowItemData}>
									<Typography
										color='darkGrey'
										weight='400'
										size={fontSizeConf}
									>
										{dataRow?.item?.status}
									</Typography>
									<View>
										<View
											style={{
												width: 10,
												height: 10,
												backgroundColor: `${
													statusReport101Matcher[
														dataRow?.item?.status
													]?.color
												}`,
												borderRadius: 50,
												marginLeft: 3,
											}}
										></View>
									</View>
								</View>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.status`}
									</I18n>
								</View>
							</View>
							<View style={styles.selectedMobileRowItem}>
								<View style={styles.selectedMobileRowItemData}>
									<Typography
										color='darkGrey'
										weight='400'
										size={fontSizeConf}
									>
										{dataRow?.item?.departmentName}
									</Typography>
								</View>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.departmentName`}
									</I18n>
								</View>
							</View>
							<View style={styles.selectedMobileRowItem}>
								<View style={styles.selectedMobileRowItemData}>
									<Typography
										color='darkGrey'
										weight='400'
										size={fontSizeConf}
									>
										{dataRow?.item?.teudatZeut}
									</Typography>
								</View>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.teudatZeut`}
									</I18n>
								</View>
							</View>
							<View style={styles.selectedMobileRowItem}>
								<View style={styles.selectedMobileRowItemData}>
									<Typography
										color='darkGrey'
										weight='400'
										size={fontSizeConf}
									>
										{dataRow?.item?.employeeCreateDate}
									</Typography>
								</View>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.employeeCreateDate`}
									</I18n>
								</View>
							</View>
							<View style={styles.selectedMobileRowItem}>
								<View style={styles.selectedMobileRowItemData}>
									<Typography
										color='darkGrey'
										weight='400'
										size={fontSizeConf}
									>
										{dataRow?.item?.submitDate}
									</Typography>
								</View>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.submitDate`}
									</I18n>
								</View>
							</View>
							<View style={styles.selectedMobileRowItem}>
								<View style={styles.selectedMobileRowItemData}>
									<Typography
										color='darkGrey'
										weight='400'
										size={fontSizeConf}
									>
										{dataRow?.item?.lastUpdate}
									</Typography>
								</View>
								<View style={styles.selectedMobileRowItemName}>
									<I18n weight='normal' size={fontSizeConf}>
										{`report101ReportPage.lastUpdate`}
									</I18n>
								</View>
							</View>
						</>
					</View>
				</TouchableHighlight>
			)}
		</>
	);
};

export default MobileItem;

const styles = StyleSheet.create({
	btn: {
		height: 20,
		width: 25,
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	selectedMobileRowContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		height: 325,
		backgroundColor: 'white',
		marginBottom: 10,
	},
	selectedMobileRowItem: {
		display: 'flex',
		flexDirection: 'row',
		marginRight: 20,
		marginBottom: 16,
		justifyContent: 'flex-end',
	},
	selectedMobileRowControlContainer: {
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	selectedMobileRowControlDownloadGroup: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'center',
	},
	selectedMobileRowItemName: {
		display: 'flex',
		minWidth: 150,
	},
	selectedMobileRowItemData: {
		display: 'flex',
		flexDirection: 'row',
	},
});

const downloadBtnStyle = StyleSheet.flatten([styles.btn, { marginRight: 20 }]);
