import React, { useState, useEffect, useRef, useMemo } from 'react';
import { StyleSheet, View, TextInput, Platform } from 'react-native';
import { prepareTime, addPaddingToTime } from '../../../../utils/timeConvert';
import moment from 'moment';
import { TouchableOpacity } from 'react-native';
import colors from '../../../../config/colors';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
export enum TimeMode {
	Entry = 'Entry',
	Exit = 'Exit',
}
interface TimePickerProps {
	onChange: (event: any) => void;
	minTime?: any;
	maxTime?: any;
	disablePicker?: boolean;
	defaultTime?: any;
	error: boolean;
}
const TimePicker = ({
	minTime,
	maxTime,
	disablePicker,
	onChange,
	error,
	defaultTime,
}: TimePickerProps) => {
	const [time, setTime] = useState(defaultTime ? defaultTime : '');
	const inputRef: any = useRef(null);
	const TIME_FORMAT = 'HH:mm';

	const setTimeInput = (value: string) => {
		const min = minTime ? moment(minTime).format('HH:mm') : '00:00';
		const max = maxTime ? moment(maxTime).format('HH:mm') : '23:59';

		setTime(value);
		if (value.length < 5) return;
		if (
			value.length === 5 &&
			moment(value, 'HH:mm').diff(moment(min, 'HH:mm')) >= 0 &&
			moment(value, 'HH:mm').diff(moment(max, 'HH:mm')) <= 0
		) {
			const t = moment(value, 'HH:mm').format('HH:mm');
			onChange(t);
		} else {
			setTime('');
		}
	};

	const handleTimeChanged = (value: string) => {
		const isValid = handleValidation(value);
		if (!isValid && value) return;
		const newVal = prepareTime(value);
		setTimeInput(newVal);
	};

	const addTime = () => {
		const val = time || '00:00';
		const max = maxTime ? moment(maxTime).format('HH:mm') : '23:59';
		const newVal = moment(val, TIME_FORMAT)
			.add(0.5, 'hours')
			.format(TIME_FORMAT);

		if (
			val.length === 5 &&
			moment(newVal, 'HH:mm').diff(moment(max, 'HH:mm')) <= 0
		) {
			setTimeInput(newVal);
		} else {
			setTimeInput(moment(max, 'HH:mm').format(TIME_FORMAT));
		}
	};

	const reduceTime = () => {
		const val = time || '00:00';
		const min = minTime ? moment(minTime).format('HH:mm') : '00:00';
		const newVal = moment(val, TIME_FORMAT)
			.subtract(0.5, 'hours')
			.format(TIME_FORMAT);
		if (
			val.length === 5 &&
			moment(newVal, 'HH:mm').diff(moment(min, 'HH:mm')) >= 0
		) {
			setTimeInput(newVal);
		} else {
			setTimeInput(moment(min, 'HH:mm').format(TIME_FORMAT));
		}
	};

	const handleFocus = (e: any) => {
		inputRef?.current?.focus();
	};
	const handleBlur = (e: any) => {
		if (disablePicker) return;
		const isValid = handleValidation(time);
		let correctedTime = time;
		if (!isValid) {
			correctedTime = '00:00';
		}
		correctedTime = addPaddingToTime(correctedTime);
		setTimeInput(correctedTime);
	};

	const handleValidation = (value: string) => {
		const isNum = /^\d+$/.test(value);
		const isNumWithColumn = /^[0-9]{1,2}\:[0-9]{1,2}$/.test(value);
		return isNum || isNumWithColumn;
	};
	const pickerColor = useMemo(
		() => (error ? 'rgba(240, 77, 77,0.3)' : colors.lightBlue),
		[error],
	);
	return (
		<View style={styles.mainContainer} nativeID='#mainContainerTimePicker'>
			<View style={styles.wrapper} nativeID='#wrapperTimePicker'>
				<View style={styles.container} nativeID='#containerTimePicker'>
					<View
						style={{
							flexDirection: 'row-reverse',
							alignItems: 'center',
							alignSelf: 'center',
						}}
					>
						<TouchableOpacity
							style={{
								marginHorizontal: 5,
								marginVertical: 20,
								height: 30,
								opacity: disablePicker ? 0.3 : 1,
								flexDirection: 'row',
								color: disablePicker
									? '#cacaca'
									: colors.lightPrimary,
								alignItems: 'center',
							}}
							onPress={addTime}
							disabled={disablePicker}
						>
							<ImageIcon name={'plus'} width={20} height={20} />
						</TouchableOpacity>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: pickerColor,
								height: 36,
							}}
						>
							<TextInput
								selectTextOnFocus
								pointerEvents={'auto'}
								caretHidden={Platform.OS === 'web'}
								style={{
									padding: 0,
									width: 85,
									fontSize: 20,
									fontWeight: '500',
									color: colors.darkGrey,
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
									opacity: disablePicker ? 0.3 : 1,
								}}
								keyboardType={'number-pad'}
								placeholder={'hh:mm'}
								maxLength={5}
								value={time}
								onBlur={handleBlur}
								onFocus={handleFocus}
								editable={!disablePicker}
								onKeyPress={e => {
									if (
										e.nativeEvent.key === 'Backspace' &&
										!disablePicker
									) {
										handleTimeChanged('');
									}
								}}
								onChangeText={val => {
									if (!disablePicker) handleTimeChanged(val);
								}}
							/>
						</View>
						<TouchableOpacity
							style={{
								marginHorizontal: 5,
								marginVertical: 20,
								opacity: disablePicker ? 0.3 : 1,
								height: 30,
								flexDirection: 'row',
								color: [
									disablePicker
										? '#cacaca'
										: colors.lightPrimary,
								],
								alignItems: 'center',
							}}
							onPress={reduceTime}
							disabled={disablePicker}
						>
							<ImageIcon name={'minus'} width={20} height={2} />
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
	wrapper: {
		alignSelf: 'center',
		minWidth: 100,
	},
	container: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		...Platform.select({
			web: {
				justifySelf: 'center',
			},
		}),
	},
});

export default TimePicker;
