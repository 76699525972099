import Constants from 'expo-constants';
import FileViewer from 'react-native-file-viewer';
import messaging from '@react-native-firebase/messaging';
import { Subscription } from '@unimodules/core';
import { Platform } from 'react-native';
import { requestNotifications } from 'react-native-permissions';
import httpService from './http.service';

// Notifications.setNotificationHandler({
//     handleNotification: async () => ({
//         shouldShowAlert: true,
//         shouldPlaySound: false,
//         shouldSetBadge: false,
//     }),
// });

class PushNotificationsService {
	expoPushToken?: string;
	notificationListener: Subscription;
	responseListener: Subscription;

	constructor() {}

	async register() {
		if (Platform.OS === 'android' || Platform.OS === 'ios') {
			const PushNotification = (await import(
				'react-native-push-notification'
			)) as any;
			PushNotification.createChannel({
				channelId: 'michpal-paycheck', // (required)
				channelName: 'Michpal HRS Paycheck', // (required)
			});
			PushNotification.configure({
				onNotification: async (notification: any) => {
					if (notification?.data?.filePath) {
						await FileViewer.open(notification?.data?.filePath);
					}
					// (required) Called when a remote is received or opened, or local notification is opened
					notification.finish();
				},
			});
		}
		this.registerForPushNotificationsAsync().then(token => {
			console.log('registerForPushNotificationsAsync', token);
			this.expoPushToken = token;
			// TODO: send to BE
			httpService.api<any>({
				type: 'connectToPushNotification',
				data: { token },
			});
		});
		// This listener is fired whenever a notification is received while the app is foregrounded
		// this.notificationListener = Notifications.addNotificationReceivedListener(notification => {
		//     console.log(notification)
		// });

		// // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
		// this.responseListener = Notifications.addNotificationResponseReceivedListener(response => {
		//     console.log(response);
		// });
	}

	destroy() {
		this.expoPushToken = undefined;
		if (Platform.OS === 'android' || Platform.OS === 'ios') {
			httpService.api<any>({
				type: 'connectToPushNotification',
				data: { token: null },
			});
		}
		// Notifications.removeNotificationSubscription(this.notificationListener);
		// Notifications.removeNotificationSubscription(this.responseListener);
	}

	async registerForPushNotificationsAsync() {
		let token;
		if (Platform.OS === 'android' || Platform.OS === 'ios') {
			const authStatus = await messaging().requestPermission();
			const granted =
				Platform.Version >= 33
					? await requestNotifications([])
					: { status: 'granted' };

			const enabled =
				(authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
					authStatus === messaging.AuthorizationStatus.PROVISIONAL) &&
				granted.status === 'granted';

			if (enabled) {
				token = await messaging().getToken();
			}
		} else {
			console.error('Must use physical device for Push Notifications');
		}

		// if (Platform.OS === 'android') {
		//     Notifications.setNotificationChannelAsync('default', {
		//         name: 'default',
		//         importance: Notifications.AndroidImportance.DEFAULT,
		//         vibrationPattern: [0, 250, 250, 250],
		//         lightColor: '#FF231F7C',
		//     });
		// }
		return token;
	}
}

export const pushNotificationsService = new PushNotificationsService();
