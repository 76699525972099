import { StoreAction } from '../../interfaces/redux.interface';
import * as configActions from '../constants/configActions';

export const incrementBusyIndicator = (): StoreAction<undefined> => ({
	type: configActions.INCREMENT_BUSY_COUNTER,
	payload: undefined,
});

export const decrementBusyIndicator = (): StoreAction<undefined> => ({
	type: configActions.DECREMENT_BUSY_COUNTER,
	payload: undefined,
});

export const resetBusyIndicator = (): StoreAction<undefined> => ({
	type: configActions.RESET_BUSY_COUNTER,
	payload: undefined,
});

export const setNavigateTo = (url: string): StoreAction<string> => ({
	type: configActions.SET_NAVIGATE_TO,
	payload: url,
});

export const resetNavigateTo = (): StoreAction<undefined> => ({
	type: configActions.RESET_NAVIGATE_TO,
	payload: undefined,
});

export const setHideChat = (flag: boolean): StoreAction<boolean> => ({
	type: configActions.SET_HIDE_CHAT,
	payload: flag,
});

export const setDefaultDisplayMode = (
	displayMode?: string,
): StoreAction<string | undefined> => ({
	type: configActions.SET_DEFAULT_DISPLAY_MODE,
	payload: displayMode,
});

export const set101Files = (filesData: any): StoreAction<any> => ({
	type: configActions.SET_101_FILES,
	payload: filesData,
});

export const setDefaultdisplayWageData = (
	displayWageData?: boolean,
): StoreAction<boolean | undefined> => ({
	type: configActions.SET_DEFAULT_DISPLAY_WAGE_MODE,
	payload: displayWageData,
});

export const setIsMaintenance = (
	isMaintenance?: boolean,
): StoreAction<boolean | undefined> => ({
	type: configActions.SET_IS_MAINTENANCE,
	payload: isMaintenance,
});
