import React, { FunctionComponent } from 'react';
import {
	StyleSheet,
	View,
	TouchableHighlight,
	StyleProp,
	useWindowDimensions,
} from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { SCREEN_SIZES } from '../../../constants';
import ImageIcon from '../Icon/ImageIcon.component';
import { useSelector } from 'react-redux';
import {
	getEmployeeRolesPrivileges,
	getUserData,
} from '../../../store/selectors/login.selectors';
import colors from '../../../config/colors';
import { Privileges } from '../../../constants/roleTypes.constant';

interface HeaderProps {
	navigation: any;
	openDrawer: () => void;
	component?: FunctionComponent;
	containerStyle?: StyleProp<any>;
	isDrawerShown?: boolean;
}

const Header = ({
	openDrawer,
	component,
	containerStyle,
	navigation,
	isDrawerShown = true,
}: HeaderProps) => {
	const dimensions = useWindowDimensions();
	const container = StyleSheet.flatten([
		styles.container,
		containerStyle,
		dimensions.width >= SCREEN_SIZES.MEDIUM && {
			justifyContent: 'space-between',
			marginRight: 0,
			padding: 20,
		},
	]);
	const userData = useSelector(getUserData);
	const platform = useDevicePlatform();
	const docPermission = useSelector(getEmployeeRolesPrivileges)?.some(
		(per: any) =>
			per === Privileges.ROLE_MY_DOCS_READ ||
			per === Privileges.ROLE_MY_DOCS_WRITE,
	);

	const HeaderComponent = component;

	return (
		<View style={container}>
			{isDrawerShown && dimensions.width < SCREEN_SIZES.MEDIUM ? (
				<TouchableHighlight
					style={{
						paddingVertical: 20,
						paddingHorizontal: platform === DeviceType.WEB ? 20 : 0,
					}}
					underlayColor='transparent'
					onPress={openDrawer}
				>
					<View>
						{navigation.getState()?.routeNames[0] === 'home' &&
							userData?.pending_docs &&
							docPermission && (
								<View style={styles.pendingDocs}></View>
							)}
						<ImageIcon name='hamburger' />
					</View>
				</TouchableHighlight>
			) : null}
			{HeaderComponent ? (
				<HeaderComponent navigation={navigation} />
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		height: 90,
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row-reverse',
		paddingHorizontal: 20,
	},
	pendingDocs: {
		position: 'absolute',
		top: -5,
		right: -5,
		width: 12,
		height: 12,
		borderRadius: 6,
		backgroundColor: colors.red,
		zIndex: 1,
	},
});

export default Header;
