import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import moment from 'moment';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import ReportsViewHeader from '../ModalHeaders/ReportsViewHeader.component';
import httpService from '../../../services/http.service';
import I18n from '../i18n/I18n.component';
import { generatePdfName } from '../../../utils/generatePdfName.utlis';
import PDFView from './PDFViewer.component';
import {
	BusinessUnit,
	pageNameToReportTypeNumber,
} from '../../../pages/ReportPDFPage/ReportPDFPage.utils';
import {
	EventName,
	logEvent,
} from '../../../services/analyticsAndLogs.service';

const PDFViewer = (props: any) => {
	const [pdfUrl, setPdfUrl] = useState();
	const [hasError, setHasError] = useState(false);
	const [businessUnitsList, setBusinessUnitsList] = useState<BusinessUnit[]>(
		[],
	);
	const [selectedBusinessUnit, setSelectedBusinessUnit] =
		useState<BusinessUnit | null>(null);
	const isWeb = useDevicePlatform() === DeviceType.WEB;
	const isMobileWeb = useDevicePlatform() === DeviceType.MOBILE_WEB;
	const selectedDateReport =
		props?.selectedOptions?.selectedPaycheckDate ||
		props?.selectedDate ||
		props?.route?.params?.selectedDate;
	const selectedOptions: {
		selectedBusinessUnit: BusinessUnit;
		selectedPaycheckDate: string;
	} | null = props?.selectedOptions;

	const innerHeaderData =
		props?.innerHeaderDataObj || props?.route?.params?.innerHeaderDataObj;

	const currentText = useMemo(
		() => props?.currentText || props?.route?.params?.currentText,
		[props.tite, props?.route?.params?.currentText],
	);

	const workerId = props?.selectedUser;

	const dateMode = useMemo(
		() => props?.dateMode || props?.route?.params?.dateMode,
		[props.dateMode, props?.route?.params?.dateMode],
	);
	const backText = useMemo(
		() => props?.backText || props?.route?.params?.backText,
		[props.backText, props?.route?.params?.backText],
	);
	const getReport = useMemo(
		() => props?.getReport || props?.route?.params?.getReport,
		[props.getReport, props?.route?.params?.getReport],
	);
	const noReport = useMemo(
		() => props?.noReport || props?.route?.params?.noReport,
		[props.noReport, props?.route?.params?.noReport],
	);

	useEffect(() => {
		if (!isWeb && selectedDateReport) {
			getBussinesUnitsByDate(selectedDateReport);
		} else {
			if (selectedDateReport) {
				getPdfUrl();
			} else {
				props?.setIsRequestCompleted?.(true);
			}
		}
	}, [selectedDateReport, workerId, selectedOptions]);

	useEffect(() => {
		onMount();
	}, []);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('PDFViewer');
		}
	};

	const getPdfUrl = async () => {
		if (
			//if there is no business Unit Selected
			!selectedOptions?.selectedBusinessUnit &&
			!selectedBusinessUnit &&
			!props?.userUnit
		) {
			setHasError(true);
			setPdfUrl(undefined);
			props?.setFileUrl?.(undefined);
			logEvent({
				eventName: EventName.getPdfFileFailure,
				data: {
					data: {
						ReportType:
							pageNameToReportTypeNumber[
								isWeb
									? props?.route?.name
									: props?.route?.params?.reportType
							],
						year: moment(selectedDateReport).format('YYYY'),
						month: moment(selectedDateReport).format('MM'),
					},
					error: 'no business Unit Selected',
				},
			});
			return;
		}
		try {
			const res: any = await httpService.api({
				type: workerId
					? 'getAttendanceReportWithWorker'
					: 'getExternalReportByDateAndUnit',
				params: workerId
					? {
							workerId: workerId,
							year: moment(props.selectedDate).format('YYYY'),
							month: moment(props.selectedDate).format('MM'),
							unitId: props?.userUnit,
					  }
					: {
							ReportType:
								pageNameToReportTypeNumber[
									isWeb
										? props?.route?.name
										: props?.route?.params?.reportType
								],
							year: moment(selectedDateReport).format('YYYY'),
							month: moment(selectedDateReport).format('MM'),
							unitId:
								selectedOptions?.selectedBusinessUnit
									.numericId ||
								selectedBusinessUnit?.numericId,
					  },
			});
			if (res) {
				setPdfUrl(res);
				props?.setFileUrl?.(res);
				setHasError(false);
			}
		} catch (error) {
			logEvent({
				eventName: EventName.getPdfFileFailure,
				data: {
					data: {
						ReportType:
							pageNameToReportTypeNumber[
								isWeb
									? props?.route?.name
									: props?.route?.params?.reportType
							],
						year: moment(selectedDateReport).format('YYYY'),
						month: moment(selectedDateReport).format('MM'),
						unitId:
							selectedOptions?.selectedBusinessUnit.numericId ||
							selectedBusinessUnit?.numericId,
					},
					error,
				},
			});
			setHasError(true);
			setPdfUrl(undefined);
			props.setFileUrl?.(undefined);
		} finally {
			props?.setIsRequestCompleted?.(true);
		}
	};

	const download = async () => {
		const fileName = generatePdfName(pdfUrl, dateMode);
		let module;
		if (isWeb || isMobileWeb) {
			module = await import('../../../utils/downloadFile.web.utils');
		} else {
			module = await import('../../../utils/downloadFile.moblie.utils');
		}

		module.downloadFile(pdfUrl, fileName);
	};

	const onChangeBusinessUnit = (id: string) => {
		const chosenUnit: BusinessUnit = businessUnitsList.filter(
			(unit: BusinessUnit) => unit.id === id,
		)[0];

		if (chosenUnit) {
			setSelectedBusinessUnit(chosenUnit);
		}
	};

	const getBussinesUnitsByDate = async (date: string) => {
		try {
			const res: {
				list: BusinessUnit[];
				data: any;
			} = await httpService.api({
				type: 'getExternalReportsBusinessUnits',
				params: {
					year: moment(date).format('YYYY'),
					month: moment(date).format('MM'),
					ReportType:
						pageNameToReportTypeNumber[
							isWeb
								? props?.route?.name
								: props?.route?.params?.reportType
						],
				},
			});
			setSelectedBusinessUnit(res?.list?.length ? res.list[0] : null);
			setBusinessUnitsList(res.list);
			if (!res?.list?.length) setHasError(true);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (selectedBusinessUnit) {
			getPdfUrl();
		}
	}, [selectedBusinessUnit]);
	return (
		<View style={{ flex: 1, marginRight: isWeb ? 30 : 0, zIndex: 0 }}>
			{!isWeb && (
				<ReportsViewHeader
					dateMode={dateMode}
					download={download}
					navigation={props.navigation}
					isError={!pdfUrl}
					selectedDate={selectedDateReport}
					title={currentText}
					backText={backText}
					downloadOnMobile={false}
					innerHeaderData={innerHeaderData}
					businessUnitsList={businessUnitsList}
					onChangeBusinessUnit={onChangeBusinessUnit}
					selectedBusinessUnit={selectedBusinessUnit}
				/>
			)}
			{hasError ? (
				<View style={styles.errorMessage}>
					<I18n weight='bold' size={24}>
						{noReport}
					</I18n>
				</View>
			) : !pdfUrl ? null : (
				<PDFView pdfUrl={pdfUrl} />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	errorMessage: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default PDFViewer;
