import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import httpService from '../../services/http.service';
import ReportPDFPageWeb from './ReportPDFPageWeb.component';
import ReportPDFPage from './ReportPDFPage.component';
import {
	getEmployeeRolesPrivileges,
	getEmployer,
	getUserData,
} from '../../store/selectors/login.selectors';
import moment from 'moment';
import {
	BusinessUnit,
	Employee101Data,
	Employee101YearStatus,
	pageNameToReportTypeNumber,
	PDFDateRange,
} from './ReportPDFPage.utils';
import {
	incrementBusyIndicator,
	decrementBusyIndicator,
} from '../../store/actions/config.actions';
import { dispatch, getState } from '../../store/store';
import { Platform } from 'react-native';
import { Privileges } from '../../constants/roleTypes.constant';
import { EventName, logEvent } from '../../services/analyticsAndLogs.service';

const requestTypeMap: any = {
	form106: {
		requestType: 'getOneHandredSixReportRange',
		getReport: 'getOneHandredSixReport',
		title: 'form106',
		rangeTitle: 'form106.choose',
		rangeTitleMobile: 'form106.chooseMobile',
		listType: 'years',
		backText: 'form106.backText',
		currentText: 'form106.current',
		noReports: 'form106.noReports',
		noReport: 'form106.noReport',
		reportType: 'form106',
	},
	attendance: {
		requestType: 'getAttendanceReportRange',
		getReport: 'getAttendanceReport',
		rangeTitle: 'attendance.choose',
		rangeTitleMobile: 'attendance.chooseMobile',
		title: 'attendance',
		listType: 'months',
		backText: 'attendance.backText',
		currentText: 'attendance.current',
		noReports: 'attendance.noReports',
		noReport: 'attendance.noReport',
		reportType: 'attendance',
	},
	'101table': {
		requestType: 'getOneHandredOneReportRange',
		getReport: 'getOneHandredOneReport',
		title: 'form101',
		rangeTitle: 'form101.choose',
		rangeTitleMobile: 'form101.chooseMobile',
		listType: 'status',
		backText: 'form101.backText',
		currentText: 'form101.current',
		noReports: 'form101.noReports',
		noReport: 'form101.noReport',
		displayHeader: false,
		is101: true,
	},
};

const ReportPDFPageContainer = ({
	navigation,
	route,
}: {
	navigation: any;
	route: any;
}) => {
	const userData = useSelector(getUserData);
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);
	const [dateRange, setDateRange] = useState<
		PDFDateRange | Employee101YearStatus
	>({
		first: '',
		last: '',
	});
	const [isLoading, setIsLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState<
		string | Employee101YearStatus
	>('');
	const isInMobileView = useDevicePlatform() !== DeviceType.WEB;
	const [recievedEmployee101List, setRecievedEmployee101List] =
		useState<Employee101Data[]>();
	const [isSecondRequestCompleted, setIsSecondRequestCompleted] =
		useState(false);
	const [isFirstRequestCompleted, setIsFirstRequestCompleted] =
		useState(false);
	const [businessUnitsList, setBusinessUnitsList] = useState<BusinessUnit[]>(
		[],
	);
	const [selectedOptions, setSelectedOptions] = useState<{
		selectedBusinessUnit: BusinessUnit | null;
		selectedPaycheckDate: string;
	} | null>(null);
	const [innerHeaderData, setInnerHeaderData] = useState<Employee101Data>();
	const [enableCreate, setEnableCreate] = useState<boolean>(false);
	const [itIsNotFirstInitComponent, setItIsNotFirstInitComponent] =
		useState(false);
	const [is101Page, setIs101Page] = useState(false);
	const employerData = useSelector(getEmployer);
	const employer =
		typeof employerData === 'string'
			? JSON.parse(employerData)
			: employerData;

	useEffect(() => {
		if (route?.name && requestTypeMap[route?.name]) {
			setIsLoading(true);
			getRange();
			setItIsNotFirstInitComponent(true);
		}
		if (
			route?.name &&
			route?.name === '101table' &&
			employeeRolesPrivileges?.includes(
				Privileges.ROLE_EMPLOYEE_FORM_WRITE,
			)
		) {
			checkIfAllowCreateNew101Report();
		}
	}, [route, userData]);

	useEffect(() => {
		onMount();
	}, []);

	useEffect(() => {
		if (typeof selectedDate !== 'string') {
			const expWorkDate =
				getState().login?.user?.operative_module?.[113]?.finish_work;
			const isFinishWorkDateExpired: boolean =
				selectedDate?.taxYear >
				moment(expWorkDate, 'YYYY-MM-DD').year();
			const reqEl = getSelectedReport(
				selectedDate,
				isFinishWorkDateExpired,
			);
			setInnerHeaderData(reqEl);
		}
	}, [selectedDate?.id || selectedDate]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('ReportPDFPageContainer');
		}
	};

	const getSelectedReport = (
		selectedDate: any,
		isFinishWorkDateExpired: boolean,
	) => {
		const SelectedReport: Employee101Data | undefined =
			recievedEmployee101List?.find(
				(it: Employee101Data) => it.id === selectedDate.id,
			);
		return {
			...SelectedReport,
			enableUpdate: isFinishWorkDateExpired
				? false
				: SelectedReport?.enableUpdate,
		};
	};

	const checkIfAllowCreateNew101Report = async () => {
		try {
			const res: any = await httpService.api({
				type: 'checkIfAllowCreateNew101Report',
			});

			setEnableCreate(res);
		} catch (e) {
			console.error(e);
		}
	};

	const getRange = async () => {
		try {
			if (!isInMobileView) {
				dispatch(incrementBusyIndicator());
			}
			setSelectedDate('');
			setInnerHeaderData(undefined);

			const res: any = await httpService.api({
				type: requestTypeMap[route?.name].requestType,
			});
			if (res) {
				if (route?.name === '101table') {
					const yearsStatusRange = res.reduce(
						(
							yearsList: any,
							item: Employee101Data,
							index: number,
						) => {
							return [...yearsList, { ...item }];
						},
						[],
					);
					setRecievedEmployee101List(res);
					setSelectedDate(yearsStatusRange[0]);
					setDateRange(yearsStatusRange);
					setIs101Page(true);
				} else {
					setDateRange(res);
				}
			}
		} catch (err) {
			logEvent({
				eventName: EventName.getPdfRangeFailure,
				data: {
					pdfPage: route?.name,
					err,
				},
			});
			setDateRange({
				first: '',
				last: '',
			});
		} finally {
			setIsLoading(false);
			setIsFirstRequestCompleted(true);
		}
	};

	const onSelectDateHandler = (date: string | Employee101YearStatus) => {
		if (typeof date === 'string') getBussinesUnitsByDate(date);
		else setSelectedDate(date);
	};

	useEffect(() => {
		if (
			Array.isArray(dateRange) &&
			!dateRange.length &&
			isFirstRequestCompleted
		) {
			setIsSecondRequestCompleted(true);
		}
		if (
			typeof dateRange === 'object' &&
			!dateRange.first &&
			!dateRange.last &&
			isFirstRequestCompleted
		) {
			setIsSecondRequestCompleted(true);
		}
	}, [dateRange, isFirstRequestCompleted]);

	useEffect(() => {
		if (isSecondRequestCompleted && isFirstRequestCompleted) {
			dispatch(decrementBusyIndicator());
			setIsSecondRequestCompleted(false);
			setIsFirstRequestCompleted(false);
		}
	}, [isSecondRequestCompleted, isFirstRequestCompleted]);

	const getBussinesUnitsByDate = async (date: string) => {
		if (!is101Page)
			try {
				const res: {
					list: BusinessUnit[];
					data: any;
				} = await httpService.api({
					type: 'getExternalReportsBusinessUnits',
					params: {
						year: moment(date).format('YYYY'),
						month: moment(date).format('MM'),
						ReportType: pageNameToReportTypeNumber[route?.name],
					},
				});
				setSelectedOptions({
					selectedBusinessUnit: res?.list?.length
						? res.list[0]
						: null,
					selectedPaycheckDate: date,
				});
				setBusinessUnitsList(res.list);
			} catch (e) {
				console.log(e);
			}
	};

	const onChangeBusinessUnit = (id: string) => {
		const chosenUnit: BusinessUnit = businessUnitsList.filter(
			(unit: BusinessUnit) => unit.id === id,
		)[0];

		if (chosenUnit) {
			setSelectedOptions(prevOptions => {
				if (!prevOptions) return prevOptions;
				return {
					...prevOptions,
					selectedBusinessUnit: chosenUnit,
				};
			});
		}
	};

	return isInMobileView ? (
		<ReportPDFPage
			navigation={navigation}
			dateRange={dateRange}
			isLoading={isLoading}
			enableCreate={enableCreate}
			innerHeaderData={getSelectedReport}
			{...requestTypeMap[route?.name]}
			is101Page={is101Page}
			rangeTitle={requestTypeMap[route?.name].rangeTitleMobile}
		/>
	) : (
		<ReportPDFPageWeb
			isLoading={isLoading}
			navigation={navigation}
			dateRange={dateRange}
			innerHeaderData={innerHeaderData}
			enableCreate={enableCreate}
			onSelectDate={onSelectDateHandler}
			selectedDate={selectedDate}
			getRange={getRange}
			{...requestTypeMap[route?.name]}
			is101Page={is101Page}
			setIsRequestCompleted={setIsSecondRequestCompleted}
			businessUnitsList={businessUnitsList}
			onChangeBusinessUnit={onChangeBusinessUnit}
			selectedOptions={selectedOptions}
			route={route}
		/>
	);
};

export default ReportPDFPageContainer;
