import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import httpService from '../../services/http.service';
import PersonalInformation from './PersonalInformation.component';
import UpdatePassworrdModal from './modals/UpdatePassworrdModal';
import UpdateEmailModal from './modals/UpdateEmailModal';
import { modalService } from '../../services/modal.service';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import I18n from '../../components/shared/i18n/I18n.component';
import { useDispatch, useSelector } from 'react-redux';
import {
	getEmployersData,
	getIsEmployerAndEmployee,
	getUserData,
} from '../../store/selectors/login.selectors';
import { getUserData as getUserUpdatedData } from '../../store/actions/login.actions';
import { setDefaultdisplayWageData } from '../../store/actions/config.actions';
import { getState } from '../../store/store';
import UpdatePhoneModal from './modals/UpdatePhoneModal';

export interface UserDetails {
	id: number;
	teudatZeut: string;
	fullName: string;
	departmentAid: string;
	departmentName: string;
	phone: string;
	email: string;
	roleNameList: string;
	defaultCustomerId?: number;
	displayMode?: string;
	displayWageData?: string;
	status?: boolean;
}

const PersonalInformationContainer = ({ navigation }: { navigation: any }) => {
	const [details, setDetails] = useState<UserDetails | any>({});
	const userData = useSelector(getUserData);
	const employerList = useSelector(getEmployersData);
	const isEmployerAndEmployee = useSelector(getIsEmployerAndEmployee);
	const dispatch = useDispatch();

	const showDefaultSalaryData = () => {
		return true;
	};

	useEffect(() => {
		onMount();
	}, []);

	useEffect(() => {
		getPersonalData();
	}, [userData]);

	useEffect(() => {
		if (
			details.defaultCustomerId &&
			employerList?.length &&
			!employerList?.find(
				({ customer_id }) => customer_id === details.defaultCustomerId,
			)
		) {
			onChangeCustomer(null);
		}
	}, [details, employerList]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('UsersTableContainer');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};

	const getPersonalData = async () => {
		try {
			const res: UserDetails = await httpService.api<any>({
				type: 'getPersonalDetails',
			});
			if (res) {
				setDetails({
					...res,
					defaultCustomerId: res.defaultCustomerId || 0,
					disableEmployeeFormAlert:
						getState().login.user.disable_employee_form_alert,
					disableDocumentAlert:
						getState().login.user.disable_document_alert,
				});
			}
		} catch (e) {}
	};
	const onChangeCustomer = async (value?: string | null) => {
		try {
			const val = value && parseInt(value);
			const res: UserDetails = await httpService.api<any>({
				type: 'updateCustomer',
				data: { id: val === 0 ? null : val },
			});

			setDetails({
				...details,
				defaultCustomerId: val === 0 ? null : val,
			});
		} catch (e) {}
	};

	const onChangeEnterMode = async (val: string) => {
		try {
			const res: UserDetails = await httpService.api<any>({
				type: 'updateDisplayMode',
				data: { displayMode: val },
			});

			setDetails({ ...details, displayMode: val });
		} catch (e) {}
	};

	const onChangeSalaryData = async (val: boolean) => {
		try {
			const res: UserDetails = await httpService.api<any>({
				type: 'updateDisplayWageData',
				data: { displayWageData: val },
			});
			dispatch(setDefaultdisplayWageData(val));
		} catch (e) {}
	};

	const onChangeEmployeeFormAlerts = async (val: boolean) => {
		try {
			const res: UserDetails = await httpService.api<any>({
				type: 'updateEmployeeFormAlert',
				data: { disableEmployeeFormAlert: val },
			});
			setDetails({ ...details, disableEmployeeFormAlert: val });
			dispatch(getUserUpdatedData({ disableBI: true }));
		} catch (e) {}
	};

	const onChangeDocumentsAlerts = async (val: boolean) => {
		try {
			const res: UserDetails = await httpService.api<any>({
				type: 'updateDocumentAlert',
				data: { disableAlert: val },
			});
			setDetails({ ...details, disableDocumentAlert: val });
			dispatch(getUserUpdatedData({ disableBI: true }));
		} catch (e) {}
	};

	const onShowAlert = (text: string) => {
		modalService.openModal(
			null,
			{
				submitBtnText: 'general.closing',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>{text}</I18n>
				</AlertModal>
			),
		);
	};

	const onUpdatePassword = async () => {
		await modalService.openModal(null, {}, (props: any) => (
			<UpdatePassworrdModal
				{...props}
				onResponse={isSaccess =>
					onShowAlert(
						isSaccess
							? 'personalInformation.updatePasswordModal.sendPasswordSuccess'
							: 'personalInformation.updatePasswordModal.sendPasswordFailed',
					)
				}
			/>
		));
	};

	const onUpdateEmail = async () => {
		await modalService.openModal(
			null,
			{
				userDetails: details,
			},
			(props: any) => (
				<UpdateEmailModal
					onResponse={isSaccess =>
						onShowAlert(
							isSaccess
								? 'personalInformation.updateEmailModal.sendEmailSuccess'
								: 'personalInformation.updateEmailModal.sendEmailFailed',
						)
					}
					{...props}
				/>
			),
		);
	};

	const onUpdatePhone = async () => {
		await modalService.openModal(null, {}, (props: any) => (
			<UpdatePhoneModal
				onResponse={phone => {
					phone && setDetails({ ...details, phone });
					onShowAlert(
						phone
							? 'personalInformation.updatePhoneModal.sendPhoneSuccess'
							: 'personalInformation.updatePhoneModal.sendPhoneFailed',
					);
				}}
				{...props}
			/>
		));
	};

	return (
		<PersonalInformation
			navigation={navigation}
			data={details}
			onUpdatePassword={onUpdatePassword}
			onUpdateEmail={onUpdateEmail}
			employerList={employerList}
			onUpdatePhone={onUpdatePhone}
			isEmployerAndEmployee={isEmployerAndEmployee}
			showDefaultSalaryData={showDefaultSalaryData}
			onChangeCustomer={onChangeCustomer}
			onChangeEnterMode={onChangeEnterMode}
			onChangeSalaryData={onChangeSalaryData}
			onChangeEmployeeFormAlerts={onChangeEmployeeFormAlerts}
			onChangeDocumentsAlerts={onChangeDocumentsAlerts}
		/>
	);
};

export default PersonalInformationContainer;
