import React, { useState, useEffect, useRef } from 'react';
import { Props } from './EventsAndProjectsSelect.utils';
import EventsAndProjectsSelectComponent from './EventsAndProjectsSelect.component';
import { View, TouchableHighlight, StyleSheet, Platform } from 'react-native';
import ImageIcon from '../../Icon/ImageIcon.component';
import I18n from '../../i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { modalService } from '../../../../services/modal.service';
import getShadowStyle from '../../../../config/shadow';
import { ScrollView } from 'react-native-gesture-handler';
import Typography from '../../Typography/Typography.component';
import { i18nService } from '../../../../services/i18n.service';

const EventsAndProjectsSelectContainer = ({
	placeHolder,
	isProject,
	onSubmit,
	onOpen,
	disabled,
	options,
	defaultOption,
	canReportFullDay,
	currentRunnigWorkLog,
}: Props) => {
	const isAlreadyOpened = useRef(false);
	const [isOpen, setIsOpen] = useState(false);
	const showOptions = async () => {
		isAlreadyOpened.current = true;
		try {
			isAlreadyOpened.current = true;
			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						onOpen?.(!isOpen);
						setIsOpen?.(false);
						callback();
					},
					onSubmit: async (
						callback: Function,
						selectedOption: any,
						isEventSelect: boolean,
						isFullDaySelected: boolean,
					) => {
						try {
							onOpen?.(!isOpen);
							setIsOpen(false);
							onSubmit(
								selectedOption,
								isEventSelect,
								isFullDaySelected,
							);
							callback();
						} catch (error) {
							console.log(error);
						}
					},
				},

				(props: any) => (
					<EventsAndProjectsSelectComponent
						{...props}
						currentRunnigWorkLog={currentRunnigWorkLog}
						canReportFullDay={canReportFullDay}
						defaultOption={defaultOption}
						options={options}
						isEventSelect={!isProject}
						isModal={isMobile}
						closed={() => {
							isAlreadyOpened.current = false;
							onOpen?.(!isOpen);
							setIsOpen(false);
						}}
					/>
				),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const style = {
		maxWidth: 300,
		flex: 1,
		height: 32,
		borderRadius: 45,
		justifyContent: 'center',
		backgroundColor: '#fff',
		borderWidth: 1,
		borderColor: isProject ? '#4aaeef' : '#eb8b2d',
		...getShadowStyle(
			20,
			isProject ? 'rgb(59, 112, 164)' : 'rgb(235, 139, 45)',
		),
	};

	if (isMobile && isOpen && !isAlreadyOpened.current) showOptions();

	return (
		<View
			style={{
				position: 'relative',
				flex: 1,
				maxWidth: 300,
				minWidth: 150,
				height: 32,
				zIndex: 1,
				marginHorizontal: 10,
			}}
		>
			<TouchableHighlight
				style={style}
				underlayColor='transparent'
				onPress={() => {
					if (!disabled) {
						onOpen(!isOpen);
						setIsOpen(prev => setIsOpen(!prev));
					}
				}}
			>
				<View style={styles.selectInnerLayout}>
					<Typography
						style={{ alignSelf: 'center', width: '85%' }}
						size={15}
						weight='normal'
						color={isProject ? '#4aaeef' : '#eb8b2d'}
						numberOfLines={1}
					>
						{i18nService.translate(placeHolder)}
					</Typography>
					<View style={{ width: '15%', flexDirection: 'row' }}>
						<ImageIcon
							name={isOpen ? 'upArrow' : 'downArrow'}
							width={14}
							height={7}
							style={{ alignSelf: 'center' }}
						></ImageIcon>
					</View>
				</View>
			</TouchableHighlight>
			{isOpen && !isMobile && (
				<EventsAndProjectsSelectComponent
					canReportFullDay={canReportFullDay}
					onCancel={() => {
						onOpen(!isOpen);
						setIsOpen(prev => setIsOpen(!prev));
					}}
					onSubmit={(
						selectedOption,
						isEventSelect,
						isFullDaySelected,
					) => {
						onOpen(!isOpen);
						setIsOpen(prev => setIsOpen(!prev));
						onSubmit(
							selectedOption,
							isEventSelect,
							isFullDaySelected,
						);
					}}
					defaultOption={defaultOption}
					options={options}
					isEventSelect={!isProject}
					isModal={isMobile}
					currentRunnigWorkLog={currentRunnigWorkLog}
				/>
			)}
		</View>
	);
};
const styles = StyleSheet.create({
	select: {
		flex: 1,
		marginHorizontal: 10,
		height: 32,
		borderRadius: 45,
		justifyContent: 'center',
		backgroundColor: '#fff',
		borderWidth: 1,
	},

	selectInnerLayout: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		paddingHorizontal: 10,
	},
});
export default EventsAndProjectsSelectContainer;
