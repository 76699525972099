import { loadAsync } from 'expo-font';
import { useEffect, useState } from 'react';
//@ts-ignore
import FontAwesome from '../../node_modules/@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf';

const useLoadFonts = () => {
	const [didLoadFont, setDidLoadFont] = useState(false);
	useEffect(() => {
		(async () => {
			await loadAsync({
				RubikRegular: require('../../assets/fonts/Rubik-Regular.ttf'),
				RubikMedium: require('../../assets/fonts/Rubik-Medium.ttf'),
				RubikBold: require('../../assets/fonts/Rubik-Bold.ttf'),
				fontello: require('../../assets/fontelloIcons/fontello.ttf'),
				FontAwesome,
			});
			setDidLoadFont(true);
		})();
	}, []);

	return didLoadFont;
};

export default useLoadFonts;
