import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from '../../../../hooks/usePrevious.hook';
import Multiselect from './Multiselect.component';
import { i18nService } from '../../../../services/i18n.service';

interface MultiselectContainerProps {
	onChange: (value: any) => void;
	config: {
		type?: string;
		name: string;
		options: { name: string; id: string | number; stringId?: string }[];
		placeholder?: string;
		styles?: any;
		defaultLabel?: string;
		value?: any;
		key?: string;
		alwaysRerender: boolean;
		returnAll?: boolean;
		showArrowIcon?: boolean;
		showSaveIcon?: boolean;
	};
	styles?: any;
	noSelection?: boolean;
	noSpecialAll?: boolean;
	menuIsOpenDefault?: boolean;
}

interface SelectedOptions {
	label: string;
	value: string;
}

const convertOption = (val: any) => {
	if (val?.value && val.label) {
		return val;
	} else {
		return {
			label: val?.name,
			value: val?.id,
		};
	}
};

const MultiselectContainer = ({
	onChange,
	config,
	...restProps
}: MultiselectContainerProps) => {
	const [showList, setShowList] = useState(false);
	const allOption = useMemo(
		() => ({
			value: 'all',
			label: config?.defaultLabel || i18nService.translate('general.all'),
		}),
		[config],
	);
	const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>(
		config?.value?.map(convertOption) || [],
	);

	const [prevOptions, setPrevOptions] = useState<SelectedOptions[]>([]);

	const prevSelectedOptions: any = usePrevious(selectedOptions);

	useEffect(() => {
		if (
			!selectedOptions.find((opt: any) => opt.value === 'all') ||
			config.alwaysRerender
		) {
			if (config?.value) {
				setSelectedOptions(config?.value?.map(convertOption));
			} else {
				setSelectedOptions([]);
			}
		}
	}, [config.alwaysRerender, config.key, config.value]);

	const selectionsChangeHandler = (selections: any, item: any) => {
		let tempSelectedOptions: any = [];
		let isAllSelected = false;
		const isSelectionsContainAllOpt =
			selectedOptions.findIndex((item: any) => item.value === 'all') >= 0;

		//select all on All option click
		if (
			!isSelectionsContainAllOpt &&
			selections.some((v: any) => v?.value === 'all')
		) {
			const allOptionSelected = config.options.map((v: any) => {
				return {
					value: v?.id,
					label: v?.name,
				};
			});
			isAllSelected = true;
			tempSelectedOptions = [allOption, ...allOptionSelected];
			onShowHideListHandler();
			//unselect all on All option click
		} else if (
			prevOptions.length &&
			prevOptions.findIndex(
				(prevSelectedOpt: any) => prevSelectedOpt.value === 'all',
			) >= 0 &&
			selections.findIndex(
				(currSelect: any) => currSelect.value === 'all',
			) < 0
		) {
			tempSelectedOptions = [];
		} else {
			tempSelectedOptions = selections.filter(
				(sel: any) => sel.value !== 'all',
			);
			if (tempSelectedOptions.length === config.options.length) {
				//if all options was selected than choose also "All" option
				tempSelectedOptions.unshift(allOption);
				isAllSelected = true;
			}
		}
		setPrevOptions(tempSelectedOptions);
		setSelectedOptions(tempSelectedOptions);
		onChange(
			config.returnAll
				? {
						value: transformSelectedOptions(tempSelectedOptions),
						isAllSelected,
				  }
				: transformSelectedOptions(tempSelectedOptions),
		);
	};
	const transformSelectedOptions = (arr: SelectedOptions[]) => {
		return arr.find(opt => opt.value === 'all') && !restProps.noSpecialAll
			? []
			: arr.map(selectedOption => {
					return {
						id: selectedOption.value,
						name: selectedOption.label,
					};
			  });
	};

	const onShowHideListHandler = useCallback(() => {
		setShowList(!showList);
	}, [showList]);

	const getAllOptions = () => {
		if (!config.options) {
			return;
		}
		let optionsCustom = config.options.map(
			(opt: { name: string; id: string }) => {
				return {
					label: opt.name,
					value: opt.id,
				};
			},
		);
		if (optionsCustom.length) {
			optionsCustom.unshift(allOption);
		}
		return optionsCustom;
	};

	return (
		<Multiselect
			showList={showList}
			selectedOptions={selectedOptions}
			config={{ ...config, options: getAllOptions() }}
			onChangeCheckBox={selectionsChangeHandler}
			onShowHideList={onShowHideListHandler}
			{...restProps}
		/>
	);
};

export default MultiselectContainer;
