import React from 'react';
import { TouchableHighlight, View, StyleSheet, Platform } from 'react-native';
import I18n from '../i18n/I18n.component';
import ImageIcon from '../Icon/ImageIcon.component';
import Typography from '../Typography/Typography.component';

const FilterSubMenuItem = ({
	item,
	filterContentMapperValue,
	chosenRowsValue = [],
	setChosenRowsValue,
	isSingle,
}: {
	item: { id: any; name: any };
	filterContentMapperValue: string;
	chosenRowsValue: string[];
	setChosenRowsValue: (selectedRows: string[]) => void;
	isSingle: boolean;
}) => {
	const selectItemHandler = () => {
		if (isSingle) {
			setChosenRowsValue([item.id]);
		} else {
			if (
				chosenRowsValue &&
				chosenRowsValue?.find(el => el === item.id)
			) {
				const filteredArray = chosenRowsValue.filter(
					el => el !== item.id,
				);
				setChosenRowsValue(filteredArray);
			} else {
				const updatedArray = [...(chosenRowsValue || []), item.id];
				setChosenRowsValue(updatedArray);
			}
		}
	};

	const isSelected =
		chosenRowsValue && chosenRowsValue.find(el => el === item.id);

	return (
		<View>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => selectItemHandler()}
			>
				<View style={styles.itemSubFilterContainer}>
					<View style={styles.itemSubFilterContainerMark}>
						{isSelected ? (
							<ImageIcon
								name={'checkedMark'}
								width={22}
								height={18}
							/>
						) : (
							<></>
						)}
					</View>
					<View style={styles.itemSubFilterContainerOptions}>
						<I18n
							color='darkGrey'
							weight='400'
							size={20}
							numberOfLines={1}
							style={{
								...Platform.select({
									web: {
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: 'block',
									},
								}),
							}}
						>
							{item.name}
						</I18n>
					</View>
				</View>
			</TouchableHighlight>

			<View style={styles.separator}></View>
		</View>
	);
};

export default FilterSubMenuItem;

const styles = StyleSheet.create({
	itemSubFilterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginLeft: 15,
		marginRight: 38,
		height: 65,
	},
	itemSubFilterContainerMark: {
		display: 'flex',
		justifyContent: 'center',
	},
	itemSubFilterContainerOptions: {
		display: 'flex',
		justifyContent: 'center',
		flex: 1,
	},
	separator: {
		backgroundColor: 'rgb(82, 89, 98)',
		height: 1,
		opacity: 0.2,
	},
});
