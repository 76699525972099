import React, { useCallback, useEffect, useState } from 'react';
import UserRolesAndPermissions from './UserRolesAndPermissions.component';
import {
	rolesHeaders,
	permissionHeaders,
} from './UserRolesAndPermissions.constants';
import httpService from '../../../services/http.service';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../store/selectors/login.selectors';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';

const UsersTableContainer = ({ navigation }: any) => {
	const [roles, setRoles] = useState<any>([]);
	const [roleId, setRoleId] = useState<number | null>(null);
	const userData = useSelector(getUserData);
	const { businessUnitsList } = useBusinessUnitsState({
		privileges: routes.usersManagment.privileges,
	});

	useEffect(() => {
		if (businessUnitsList.length) {
			(async () => {
				try {
					const res = await httpService.api({
						type: 'getRoles',
						query: {
							unitIds: businessUnitsList
								.map((unit: any) => unit.id)
								.join(','),
						},
					});
					if (res) {
						setRoles(res);
					}
				} catch (e) {
					console.log(e);
				}
			})();
		}
	}, [businessUnitsList]);

	const goBack = () => {
		navigation.navigate('users');
	};

	const onRowClick = async (row: any) => {
		try {
			const res = await httpService.api({
				type: 'getRolePermissions',
				params: { id: row.id },
				query: {
					unitId: businessUnitsList
						.map((unit: any) => unit.id)
						.join(','),
				},
			});
			if (res) {
				const rolesWithPermisssions = roles.map((role: any) => {
					if (role.id === row.id) {
						role.permissions = res;
						role.specialRow = true;

						return role;
					} else {
						if ('specialRow' in role) {
							delete role.specialRow;
						}
					}

					return role;
				});

				setRoles(rolesWithPermisssions);
				setRoleId(row.id);
			}
		} catch (e) {
			console.log(e);
		}
	};

	if (!roles.length) {
		return null;
	}

	return (
		<UserRolesAndPermissions
			onRowClick={onRowClick}
			rolesHeaders={rolesHeaders}
			permissionsHeaders={permissionHeaders}
			data={roles}
			navigation={navigation}
			goBack={goBack}
			isRolesPageOpened={true}
			goToRolesPage={() => {}}
			getNextData={() => {}}
			roleId={roleId}
		/>
	);
};

export default UsersTableContainer;
