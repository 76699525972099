import _ from 'lodash';
import { i18nService } from '../../../../services/i18n.service';
import { phonePrefixOptions } from '../../../../utils/globals.utils';
import { getTabsMap } from '../WorkerCardTabs';
import { taxCreditReasonFields } from './taxCreditReasonFields';
import { spouseFields } from './spouseFields';

export const employeeFields = {
	pageRoute: 'employee',
	fields: [
		[
			{
				name: 'employee.idNumber',
				type: 'input',
				label: 'workerCard.editor.employee.id',
				disabled: true,
				shouldDisplay: () => ({
					watch: ['employee.passport'],
					callback: (values: any) => !values[0],
				}),
			},
			{
				name: 'employee.passport',
				type: 'input',
				label: 'workerCard.editor.employee.passport',
				disabled: true,
				shouldDisplay: () => ({
					watch: ['employee.idNumber'],
					callback: (values: any) => !values[0],
				}),
			},
			{
				type: 'array',
				name: 'employee.idFile',
				getDefautValue: (getValues: Function) => ({
					name: '',
					extraData: null,
				}),
				maxLength: 3,
				fieldWrapperStyle: (isMobile: boolean) => ({
					width: !isMobile ? 'unset' : '100%',
					marginBottom: isMobile ? 30 : 0,
				}),
				arrayWrapperStyle: (isMobile: boolean) => ({
					flexDirection: !isMobile ? 'row-reverse' : 'column',
					alignItems: !isMobile ? 'center' : 'flex-end',
					width: '100%',
				}),
				fields: [
					[
						{
							name: 'extraData',
							type: 'uploadFile',
							label: ({ index }: any) =>
								!index
									? 'workerCard.editor.employee.idFile'
									: `${i18nService.translate(
											'general.file',
									  )} ${index + 1}`,
							fieldWrapperStyle: (isMobile: boolean) => ({
								width: isMobile ? '100%' : 'unset',
							}),
							isRequired: ({ index }: any) => !index,
						},
					],
				],
			},
		],
		[
			{
				name: 'employee.firstName',
				type: 'input',
				label: 'workerCard.editor.employee.firstName',
				maxLength: 50,
				isRequired: true,
			},
			{
				name: 'employee.lastName',
				type: 'input',
				label: 'workerCard.editor.employee.lastName',
				maxLength: 50,
				isRequired: true,
			},
			{
				name: 'employee.birthDate',
				type: 'datepicker',
				maxDate: new Date(),
				label: 'workerCard.editor.employee.birthDate',
				placeholder: 'general.datePickerPlaceholder',
				isRequired: true,
			},
			{
				name: 'employee.immigrationDate',
				type: 'datepicker',
				maxDate: new Date(),
				label: 'workerCard.editor.employee.immigrationDate',
				tooltip: 'workerCard.editor.employee.immigrationDate.tooltip',
				placeholder: 'general.datePickerPlaceholder',
				deleteable: true,
			},
			{
				name: 'employee.email',
				type: 'input',
				label: 'workerCard.editor.employee.email',
				maxLength: 50,
			},
		],
		[
			{
				name: 'employee.address.city',
				type: 'googlePlacesAutocomplete',
				label: 'workerCard.editor.employee.address.city',
				isRequired: true,
				autocompleteTypes: ['(cities)'],
			},
			{
				name: 'employee.address.street',
				type: 'input',
				label: 'workerCard.editor.employee.address.street',
				isRequired: true,
				maxLength: 50,
			},
			{
				name: 'employee.address.houseNumber',
				type: 'input',
				label: 'workerCard.editor.employee.address.houseNumber',
				isRequired: true,
				maxLength: 5,
			},
			{
				name: 'employee.address.postalCode',
				type: 'input',
				label: 'workerCard.editor.employee.address.postalCode',
				maxLength: 7,
			},
			{
				name: 'employee.phone',
				type: 'input',
				label: 'workerCard.editor.employee.phone',
				maxLength: 10,
			},
			{
				name: 'employee.cellPhone',
				type: 'input',
				label: 'workerCard.editor.employee.cellPhone',
				maxLength: 7,
			},
			{
				name: 'employee.cellPrefix',
				type: 'reactselect',
				label: 'workerCard.editor.employee.cellPrefix',
				options: phonePrefixOptions,
				blurInputOnSelect: true,
				maxMenuHeight: 200,
				isSearchable: false,
			},
		],
		[
			{
				name: 'employee.gender',
				type: 'buttonsGroup',
				label: 'workerCard.editor.employee.gender',
				options: [
					{ label: 'general.male', value: 'MALE' },
					{ label: 'general.female', value: 'FEMALE' },
				],
				isRequired: true,
			},
		],
		[
			{
				name: 'employee.maritalStatus',
				type: 'buttonsGroup',
				label: 'workerCard.editor.employee.maritalStatus',
				tooltip: 'workerCard.editor.employee.maritalStatus.tooltip',
				options: [
					{ label: 'general.single', value: 'SINGLE' },
					{ label: 'general.maried', value: 'MARRIED' },
					{ label: 'general.widowed', value: 'WIDOWED' },
					{ label: 'general.divorced', value: 'DIVORCED' },
					{ label: 'general.separated', value: 'SEPARATED' },
				],
				isRequired: true,
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
					prevValue: any,
					trigger: Function,
					reset: Function,
				) => {
					if (selectedValue === 'MARRIED') {
						const obj = getValues();
						const children = obj.children?.map((child: any) => ({
							...child,
							singleParent: false,
						}));
						reset(
							{
								...obj,
								employee: {
									...obj.employee,
									maritalStatus: selectedValue,
								},
								children,
								taxCreditReason: obj.taxCreditReason
									? {
											...obj.taxCreditReason,
											singleParentLivingSeparately: false,
									  }
									: undefined,
							},
							{ keepErrors: true, keepDirty: true },
						);
					} else if (prevValue === 'MARRIED') {
						const obj = getValues();
						reset(
							{
								...obj,
								employee: {
									...obj.employee,
									maritalStatus: selectedValue,
								},
								spouse: undefined,
								taxCreditReason: obj.taxCreditReason
									? {
											...obj.taxCreditReason,
											spouseHasNoIncomeFile: [],
											spouseHasNoIncome: false,
											alimonyPayment: false,
											alimonyPaymentFile: [],
									  }
									: undefined,
							},
							{ keepErrors: true, keepDirty: true },
						);
						setTimeout(
							() =>
								trigger(
									_.flattenDeep(spouseFields.fields).map(
										(filed: any) => filed.name,
									),
								),
							500,
						);
					}
					if (selectedValue === 'SEPARATED') {
						setValue('employee.genderFile', [
							{ name: '', extraData: undefined },
						]);
					} else {
						setValue('employee.genderFile', []);
					}
				},
			},
			{
				type: 'array',
				name: 'employee.genderFile',
				getDefautValue: (getValues: Function) => ({
					name: '',
					extraData: null,
				}),
				maxLength: 3,
				shouldDisplay: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] === 'SEPARATED',
				}),
				fieldWrapperStyle: (isMobile: boolean) => ({
					width: !isMobile ? 'unset' : '100%',
					marginBottom: isMobile ? 30 : 0,
				}),
				arrayWrapperStyle: (isMobile: boolean) => ({
					flexDirection: !isMobile ? 'row-reverse' : 'column',
					alignItems: !isMobile ? 'center' : 'flex-end',
					width: '100%',
				}),
				fields: [
					[
						{
							name: 'extraData',
							type: 'uploadFile',
							label: ({ index }: any) =>
								!index
									? 'workerCard.editor.employee.genderFile'
									: `${i18nService.translate(
											'general.file',
									  )} ${index + 1}`,
							fieldWrapperStyle: (isMobile: boolean) => ({
								width: isMobile ? '100%' : 'unset',
							}),
							isRequired: ({ index }: any) => !index,
						},
					],
				],
			},
		],
		[
			{
				name: 'employee.israeliResident',
				type: 'buttonsGroup',
				label: 'workerCard.editor.employee.israeliResident',
				tooltip: 'workerCard.editor.employee.israeliResident.tooltip',
				options: [
					{ label: 'general.true', value: true },
					{ label: 'general.false', value: false },
				],
				isRequired: true,
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
					prevValue: any,
					trigger: Function,
				) => {
					setValue('taxCreditReason.israeliResident', selectedValue);
					if (selectedValue == false) {
						setValue('taxCreditReason.immigrant', false);
						// setValue('taxCreditReason.immigrantFile', []);
						// setValue('taxCreditReason.immigrantFromDate', undefined);
					}
					trigger(['employee.firstName', 'employee.lastName', 'employee.immigrationDate']);
				},
			},
		],
		[
			{
				name: 'employee.kibbutzMember',
				type: 'buttonsGroup',
				label: 'workerCard.editor.employee.kibbutzMember',
				tooltip: 'workerCard.editor.employee.kibbutzMember.tooltip',
				options: [
					{ label: 'general.true', value: true },
					{ label: 'general.false', value: false },
				],
				isRequired: true,
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
				) => {
					!selectedValue &&
						setValue('employee.revenuesTransferredToKibbutz', false);
				},
			},
		],
		[
			{
				name: 'employee.revenuesTransferredToKibbutz',
				type: 'buttonsGroup',
				label: 'workerCard.editor.employee.revenuesTransferredToKibbutz',
				options: [
					{ label: 'general.true', value: true },
					{ label: 'general.false', value: false },
				],
				isRequired: true,
				shouldDisplay: () => ({
					watch: ['employee.kibbutzMember'],
					callback: (values: any) => values[0],
				}),
			}
		],
		[
			{
				name: 'employee.healthOrgMember',
				type: 'buttonsGroup',
				label: 'workerCard.editor.employee.healthOrgMember',
				tooltip: 'workerCard.editor.employee.healthOrgMember.tooltip',
				options: [
					{ label: 'general.true', value: true },
					{ label: 'general.false', value: false },
				],
				isRequired: true,
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
				) => {
					!selectedValue &&
						setValue('employee.healthOrgName', undefined);
				},
			},
			{
				name: 'employee.healthOrgName',
				type: 'buttonsGroup',
				label: 'workerCard.editor.employee.healthOrgName',
				options: [
					{
						label: 'general.clalit',
						value: 'CLALIT',
					},
					{
						label: 'general.leumit',
						value: 'LEUMIT',
					},
					{
						label: 'general.meuhedet',
						value: 'MEUHEDET',
					},
					{
						label: 'general.maccabi',
						value: 'MACCABI',
					},
				],
				shouldDisplay: () => ({
					watch: ['employee.healthOrgMember'],
					callback: (values: any) => values[0],
				}),
				isRequired: true,
			},
		],
		[
			{
				name: 'employee.bankId',
				type: 'input',
				label: 'workerCard.editor.employee.bankId',
				placeholder: 'workerCard.editor.employee.bankId.placeholder',
				maxLength: 2,
			},
			{
				name: 'employee.branchId',
				type: 'input',
				label: 'workerCard.editor.employee.branchId',
				placeholder: 'workerCard.editor.employee.branchId.placeholder',
				maxLength: 3,
			},
			{
				name: 'employee.accountNumber',
				type: 'input',
				label: 'workerCard.editor.employee.accountNumber',
				placeholder:
					'workerCard.editor.employee.accountNumber.placeholder',
				maxLength: 10,
			},
		],
	],
};
