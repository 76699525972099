import _ from 'lodash';
import React, { useMemo } from 'react';
import {
	TouchableHighlight,
	View,
	Image,
	TouchableOpacity,
	Dimensions,
	Platform,
} from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import iconsMap from '../../../../config/icons';
import { i18nService } from '../../../../services/i18n.service';
import { fieldsMap } from '../WorkerCardViewer.utils';
import { getTabsMap } from '../WorkerCardTabs';
import Typography from '../../../../components/shared/Typography/Typography.component';

const Comment = ({
	fieldName,
	objectName,
	comment,
	goToCommentScreen,
	removeComment,
	index,
	removeCommentFromChildrenTab,
	isEmployee,
	readOnly,
}: {
	fieldName: string;
	objectName: string;
	index: number;
	comment: any;
	goToCommentScreen: Function;
	removeComment: Function;
	isEmployee: boolean;
	removeCommentFromChildrenTab: Function;
	readOnly: boolean;
}) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const _removeComment = () => {
		if (fieldName === 'CHILD' || fieldName === 'ADDITIONAL_INCOME') {
			removeCommentFromChildrenTab(
				comment.id,
				comment.objectId,
				fieldName === 'CHILD'
					? 'children'
					: 'taxCreditRequest.additionalIncome',
			);
		} else {
			removeComment(comment.id, objectName);
		}
	};
	let name: string;

	switch (comment?.fieldName) {
		case 'CHILD':
			name = i18nService.translate('general.child') + ' ' + (index + 1);
			break;
		case 'ADDITIONAL_INCOME':
			name =
				i18nService.translate('general.employer') + ' ' + (index + 1);
			break;
		case 'ROOT':
			name = i18nService.translate('workerCard.editor.generalComment');
			break;
		case 'idNumber':
			name = i18nService.translate('workerCard.editor.employee.id');
			break;
		case 'hasChildren':
			name = i18nService.translate(
				'workerCard.editor.children.hasChildren',
			);
			break;
		case 'signature':
			name = i18nService.translate(
				'workerCard.editor.disclaimer.signature',
			);
			break;
		default: {
			name = i18nService.translate(
				`workerCard.editor.${objectName}.${fieldName}`,
			);
			break;
		}
	}

	return (
		<View
			key={index}
			style={{
				display: 'flex',
				flexDirection: isMobile ? 'row-reverse' : 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
				paddingTop: 20,
				width: '100%',
			}}
		>
			<View
				style={{
					width:
						Platform.OS === 'android'
							? Dimensions.get('screen').width - 50
							: '82%',
					marginLeft: 5,
					overflow: 'hidden',
					flexGrow: 1,
					justifyContent: 'flex-end',
				}}
			>
				<View
					style={{
						flexDirection:
							Platform.OS === 'android'
								? 'row-reverse'
								: undefined,
					}}
				>
					<Typography color='grey' size={10}>
						{name}
					</Typography>
				</View>

				<TouchableHighlight
					onPress={async () =>
						await goToCommentScreen(objectName, comment, name)
					}
				>
					<View>
						<Typography color='primary' size={14}>
							{comment?.comment}
						</Typography>
					</View>
				</TouchableHighlight>
			</View>

			{!isEmployee && !readOnly ? (
				<TouchableHighlight onPress={_removeComment}>
					<Image
						source={iconsMap.delete}
						fadeDuration={0}
						style={{
							height: 18,
							width: 15,
							//position: 'absolute',
							bottom: 0,
							right: 0,
						}}
					/>
				</TouchableHighlight>
			) : null}
		</View>
	);
};

const CommentsAndErrors = ({
	comments,
	missingFields,
	removeComment,
	removeCommentFromChildrenTab,
	goToCommentScreen,
	goToFieldScreen,
	isMobile,
	isEmployee,
	readOnly,
}: {
	comments?: any;
	missingFields: any;
	isMobile: boolean;
	removeCommentFromChildrenTab?: any;
	removeComment?: any;
	goToCommentScreen?: any;
	goToFieldScreen?: any;
	isEmployee: boolean;
	readOnly: boolean;
}) => {
	let allFields: any[] = [];
	const tabsMap = getTabsMap([]);

	for (const obj of tabsMap) {
		allFields = [...allFields, ..._.flattenDeep(obj.fields)];
	}
	const displayComments = useMemo(() => {
		if (comments.length === 1 && comments[0].fieldName === 'ROOT') {
			return comments.map((comment: any, index: number) => {
				return (
					<Comment
						comment={comment}
						readOnly={readOnly}
						index={index}
						goToCommentScreen={goToCommentScreen}
						fieldName='ROOT'
						objectName='ROOT'
						removeComment={removeComment}
						isEmployee={isEmployee}
						removeCommentFromChildrenTab={
							removeCommentFromChildrenTab
						}
					/>
				);
			});
		} else if (comments.length) {
			return allFields.map((field: any, index: number) => {
				const arr = field.name ? field.name.split('.') : '';
				const objName = arr[0];
				let fieldName = arr.length >= 2 ? arr.slice(1).join('.') : '';
				let comment;

				switch (field.name) {
					case 'children':
					case 'taxCreditRequest.additionalIncome':
						const objectName =
							field.name === 'children'
								? 'CHILDREN'
								: 'ADDITIONAL_INCOME';
						fieldName =
							field.name === 'children'
								? 'CHILD'
								: 'ADDITIONAL_INCOME';
						const tabComments = comments.filter(
							(el: any) => el.objectName === objectName,
						);

						return tabComments.map(
							(comment: any, index: number) => {
								return (
									<Comment
										readOnly={readOnly}
										comment={comment}
										index={index}
										goToCommentScreen={goToCommentScreen}
										fieldName={fieldName}
										objectName={field.name.split('.')[0]}
										removeComment={removeComment}
										isEmployee={isEmployee}
										removeCommentFromChildrenTab={
											removeCommentFromChildrenTab
										}
									/>
								);
							},
						);
					case 'signature': {
						const comment = comments.find(
							(el: any) => el.fieldName === 'signature',
						);

						if (comment) {
							return (
								<Comment
									index={index}
									readOnly={readOnly}
									fieldName={'signature'}
									objectName={'ROOT'}
									isEmployee={isEmployee}
									comment={comment}
									goToCommentScreen={goToCommentScreen}
									removeComment={removeComment}
									removeCommentFromChildrenTab={
										removeCommentFromChildrenTab
									}
								/>
							);
						}
						break;
					}

					case 'hasChildren': {
						const comment = comments.find(
							(el: any) => el.fieldName === 'hasChildren',
						);
						if (comment) {
							return (
								<Comment
									index={index}
									readOnly={readOnly}
									fieldName={'hasChildren'}
									objectName={'ROOT'}
									isEmployee={isEmployee}
									comment={comment}
									goToCommentScreen={goToCommentScreen}
									removeComment={removeComment}
									removeCommentFromChildrenTab={
										removeCommentFromChildrenTab
									}
								/>
							);
						}
						break;
					}
					default: {
						const tabComments = comments.filter(
							(el: any) => el.objectName === fieldsMap[objName],
						);
						comment = tabComments.find(
							(com: any) => com.fieldName === fieldName,
						);

						if (comment) {
							return (
								<Comment
									readOnly={readOnly}
									index={index}
									fieldName={fieldName}
									objectName={objName}
									isEmployee={isEmployee}
									comment={comment}
									goToCommentScreen={goToCommentScreen}
									removeComment={removeComment}
									removeCommentFromChildrenTab={
										removeCommentFromChildrenTab
									}
								/>
							);
						}
						break;
					}
				}
			});
		} else {
			return null;
		}
	}, [comments]);

	return (
		<View>
			{Boolean(comments.length) ? (
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						padding: 20,
					}}
				>
					<I18n size={20} weight='normal'>
						workerCard.editor.employerComments
					</I18n>
					<Image
						source={iconsMap.initialCommentIcon}
						fadeDuration={0}
						style={{
							height: 14,
							width: 14,
							marginTop: 2,
							marginLeft: 10,
						}}
					/>
				</View>
			) : null}
			<View
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					paddingHorizontal: 20,
					paddingBottom: 20,
				}}
			>
				<Typography
					style={{
						width: '100%',
					}}
				>
					{displayComments}
				</Typography>
			</View>
			{isEmployee && Boolean(missingFields.length) ? (
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						paddingTop: 10,
						paddingHorizontal: 20,
					}}
				>
					<I18n size={20} weight='normal'>
						workerCard.editor.missingFields
					</I18n>
					<Image
						source={iconsMap.exclamationMarkRed}
						fadeDuration={0}
						style={{
							height: 14,
							width: 14,
							marginTop: 2,
							marginLeft: 10,
						}}
					/>
				</View>
			) : null}
			{Boolean(missingFields.length) && isEmployee ? (
				<View
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						paddingHorizontal: 20,
					}}
				>
					{missingFields.map((field: any, index: number) => {
						let objectName: string;
						const arr = (field.parentName || field.name).split('.');
						objectName = arr[0];
						return (
							<TouchableOpacity
								onPress={() => {
									if (isEmployee) {
										goToFieldScreen(objectName, field);
									} else {
										goToCommentScreen(objectName);
									}
								}}
							>
								<View
									key={index}
									style={{
										flexDirection: 'row-reverse',
										paddingTop: 20,
										width: '100%',
									}}
								>
									<View
										style={{
											width: 130,
											marginLeft: 5,
											overflow: 'hidden',
										}}
										key={field.id}
									>
										<View key={field.id}>
											<Typography color='grey' size={10}>
												{objectName === 'hasChildren'
													? i18nService.translate(
															`workerCard.editor.children`,
													  )
													: i18nService.translate(
															`workerCard.editor.${objectName}`,
													  )}
											</Typography>
										</View>
										<View key={field.id}>
											<Typography
												color='primary'
												size={14}
											>
												{`${
													field.fieldTitle
														? `${i18nService.translate(
																field.fieldTitle,
														  )} `
														: ''
												}${
													typeof field.label ===
													'string'
														? i18nService.translate(
																field.label,
														  )
														: ''
												}`}
											</Typography>
										</View>
									</View>
								</View>
							</TouchableOpacity>
						);
					})}
				</View>
			) : null}
		</View>
	);
};

export default CommentsAndErrors;
