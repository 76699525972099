import React, { useEffect, useRef } from 'react';
import { Text, StyleSheet } from 'react-native';
import colors from '../../../config/colors';
import { Color, FontSize, Weight } from '../../../interfaces/core.interface';
export interface TextProps {
	children: any;
	size?: FontSize;
	color?: Color;
	weight?: Weight;
	style?: any;
	numberOfLines?: number;
	title?: string;
}

const fontFamilyMap = {
	bold: 'RubikBold',
	normal: 'RubikMedium',
	['400']: 'RubikRegular',
};

const Typography = ({
	children,
	style,
	color = 'darkGrey',
	weight = '400',
	size = 16,
	numberOfLines,
	title,
}: TextProps) => {
	const text = useRef(null) as any;

	useEffect(() => {
		title && text.current && text.current?.setNativeProps({ title });
	}, [text, title]);

	const textStyle = StyleSheet.flatten([
		{ textAlign: 'right' },
		style,
		{
			fontFamily: fontFamilyMap[weight],
			color: colors[color] || color,
			fontWeight: weight,
			fontSize: size,
		},
	]);

	return (
		<Text
			numberOfLines={numberOfLines}
			selectable={false}
			style={textStyle}
			ref={text}
		>
			{children}
		</Text>
	);
};

export default Typography;
