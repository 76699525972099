export const getUsermodal = async (isCustomer?: boolean) => {
	const Component = isCustomer
		? await import('./CustomersManagment/SelectEmployeesModalWeb')
		: await import('./RolesSectionModalsWeb/SelectEmployeesModal');

	return Component.default;
};

export const getCustomersModal = async () => {
	const Component = await import(
		'../CreateAndUpdateUserWeb/CustomerManagement/CustomerManagement.container'
	);

	return Component.default;
};
