import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	View,
	StyleSheet,
	Dimensions,
	TouchableOpacity,
	ScrollView,
} from 'react-native';
import SolidButtonContainer from '../../../../components/shared/Buttons/SolidButton.container';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import I18n from '../../../../components/shared/i18n/I18n.component';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import httpService from '../../../../services/http.service';
import {
	DepFilterData,
	EmployeeSelector,
	ProjectSelector,
	UserData,
} from './LocationSettingsModal.utils';
import { Privileges } from '../../../../constants/roleTypes.constant';
import { hasPermissionOnBusinessUnit } from '../../../../utils/globals.utils';

interface Props {
	users: DepFilterData[];
	locationId?: string;
	projectsFilterList: any[];
	departmentsFilterList: any[];
	locationData?: any;
	onNext: Function;
	refresh(): void;
	unitIds?: number[];
	businessUnitsList: any[];
	businessUnitId?: number;
}

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const LocationSettingsModal = ({
	users = [],
	locationId,
	locationData,
	projectsFilterList,
	refresh,
	onNext,
	unitIds,
	businessUnitsList,
	businessUnitId,
}: Props) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [userList, setUserList] = useState(users);
	const [projectList, setProjectList] = useState(projectsFilterList);

	const [usersData, setUsersData] = useState<UserData>({
		users: [],
	});
	const [projectsData, setProjectsData] = useState<UserData>({
		projects: [],
		users: [],
	});

	const [selectedLocation, setSelectedLocation] = useState<any>(null);
	const [selectedUnitdId, setSelectedUnitdId] = useState<any>(null);
	const [name, setName] = useState('');
	const [selectedRadius, setSelectedRadius] = useState<any>(500);
	const [disableSave, setDisableSave] = useState(true);
	const [refreshUsersByProjects, setRefreshUsersByProjects] = useState(false);
	const [showBottomSection, setShowBottomSection] = useState(
		!!locationId || unitIds?.length === 1,
	);
	const [{ radiusError, nameError }, setError] = useState({
		radiusError: false,
		nameError: false,
	});

	const gerProjectsList = async (unitId: any) => {
		try {
			const res = await httpService.api<any>({
				type: 'getUnitProjectsFilter',
				query: {
					pageName: 'locationSettings',
					unitIds: unitId,
				},
			});

			if (res) {
				setProjectList(res);
			}
		} catch (e) {}
	};

	const getUserList = async (unitId: number) => {
		const res = await httpService.api<any>({
			type: 'getUsersByDepartments',
			query: {
				pageName: 'locationSettings',
				unitId,
			},
			data: {
				search: '',
				f: { dep: [], internal: ['true'] },
			},
		});
		setUserList(res);
	};

	useEffect(() => {
		if (locationId) {
			setProjectsData(() => ({
				projects: locationData?.projectsData?.projects || [],
				users: locationData?.projectsData?.users || [],
			}));
			setUsersData(() => ({
				users: locationData?.usersData || [],
			}));
			setSelectedLocation(locationData?.location);
			setSelectedRadius(locationData?.radius);
			setName(locationData?.name);
		}

		setUserList(() => [...users]);
	}, [locationData]);

	useEffect(() => {
		let disable = true;
		if (!!usersData?.users?.length) {
			if (
				!!projectsData.users.length === !!projectsData?.projects?.length
			) {
				disable = false;
			}
		}
		if (!!projectsData?.projects?.length && !!projectsData.users.length) {
			disable = false;
		}

		setDisableSave(!selectedLocation || disable);
	}, [usersData, projectsData, selectedLocation]);

	const arrangeData = async () => {
		try {
			const data: any = formMethods.getValues();
			const latLon = await geocodeByPlaceId(selectedLocation?.placeId);

			let newData: any = {
				location: {
					...selectedLocation,
					lat: latLon[0].geometry.location.lat(),
					lon: latLon[0].geometry.location.lng(),
				},
				name: data?.name ? data?.name : null,
				radius: data.radius,
				status: data.status,
				usersData: usersData.users,
				unitId: data.unitId || businessUnitId,
				projectsData: {
					...projectsData,
				},
			};
			if (!!locationId) {
				newData = { ...newData, id: locationId };
			}
			return newData;
		} catch (e) {}
	};

	const validate = () => {
		let radErr = false;
		let nameErr = false;
		if (selectedRadius > 5000 || selectedRadius < 200) {
			radErr = true;
		}
		if (!!name && (name.length < 2 || name.length > 30)) {
			nameErr = true;
		}
		setError(prev => ({
			...prev,
			radiusError: radErr,
			nameError: nameErr,
		}));
		return radErr || nameErr;
	};

	const createNewLocation = async onNext => {
		try {
			const isError = validate();
			if (!isError) {
				const data = await arrangeData();

				const res = await httpService.api<any>({
					type: 'createNewLocation',
					data,
				});
				onNext(refresh());
			}
		} catch (e) {}
	};

	const updateLocation = async onNext => {
		try {
			const isError = validate();
			if (!isError) {
				const data = await arrangeData();

				const res = await httpService.api<any>({
					type: 'updateLocation',
					params: { locationId },
					data,
				});
				onNext(refresh());
			}
		} catch (e) {}
	};

	const setLocationData = val => {
		const newLocation = {
			locationName: val.label,
			placeId: val?.value?.place_id,
		};

		setSelectedLocation(prev => ({ ...prev, ...newLocation }));
	};

	const onRadiusChange = (rad: number = 0) => {
		setSelectedRadius(rad);
	};

	const formMethods = useForm({
		defaultValues: {
			radius: 500,
			status: true,
			address: locationData?.location?.locationName || '',
			unitId:
				locationId || unitIds?.length !== 1 ? undefined : unitIds[0],
			...locationData,
		},
		mode: 'onBlur',
	});

	const canEdit = useMemo(() => {
		const data = formMethods?.getValues();
		return (
			(data?.unitId || businessUnitId) &&
			hasPermissionOnBusinessUnit(
				data?.unitId || businessUnitId,
				Privileges.ROLE_LOCATION_WRITE,
			)
		);
	}, [businessUnitId, selectedUnitdId]);

	const canEditAssociation = useMemo(() => {
		const data = formMethods.getValues();
		return (
			(data.unitId || businessUnitId) &&
			hasPermissionOnBusinessUnit(
				data.unitId || businessUnitId,
				Privileges.ROLE_LOCATION_ASSOCIATION_WRITE,
			)
		);
	}, [businessUnitId, selectedUnitdId]);

	useEffect(() => {
		const subscription = formMethods.watch((value, { name, type }) => {
			console.log({ type, name });
			if (type === 'change' && name === 'unitId' && value[name]) {
				setShowBottomSection(true);
				setUsersData({ users: [] });
				setProjectsData({
					projects: [],
					users: [],
				});
				getUserList(value[name]);
				setSelectedUnitdId(value[name]);
				gerProjectsList(value[name]);
			}
		});
		return () => subscription.unsubscribe();
	}, [formMethods.watch]);

	const updateUsers = (newUserData: UserData) => {
		setUsersData((prev: UserData) => ({ ...prev, ...newUserData }));
	};

	const updateProjects = (newUserData: UserData) => {
		if (
			newUserData.projects?.length === 0 &&
			projectsData.projects?.length !== 0
		) {
			newUserData.users = [];
			setRefreshUsersByProjects(prev => !prev);
		}
		setProjectsData((prev: UserData) => ({ ...prev, ...newUserData }));
	};

	return (
		<FormProvider {...formMethods}>
			<View style={[styles.container, { height: windowHeight - 100 }]}>
				<View style={{ flexDirection: 'row-reverse' }}>
					<I18n
						size={30}
						weight='normal'
						style={{ paddingBottom: 5, marginLeft: 20 }}
					>
						{locationId
							? 'locationSettings.modalTitle.editLocation'
							: 'locationSettings.modalTitle.newLocation'}
					</I18n>
					<View style={{ height: 50 }}>
						<FieldBuilder
							name={'status'}
							type={'inlineCheckbox'}
							label={'general.active'}
							disabled={!canEdit}
						/>
					</View>
				</View>
				<View style={styles.separator} />
				<ScrollView
					contentContainerStyle={{
						minHeight: 550,
						paddingHorizontal: 5,
					}}
				>
					<View style={[styles.sectionContainer, { zIndex: 100 }]}>
						<I18n
							size={20}
							weight='normal'
							style={{ marginBottom: 50 }}
						>
							locationSettings.modal.general
						</I18n>
						<View style={{ flexDirection: 'row-reverse' }}>
							<View style={[styles.formRow, { zIndex: 100 }]}>
								{!locationId &&
									businessUnitsList.length > 1 && (
										<FieldBuilder
											name='unitId'
											type='reactselect'
											label='general.unitIds'
											showFullName
											isRequired
											options={businessUnitsList}
										/>
									)}
							</View>
							<View style={[styles.formRow, { zIndex: 100 }]}>
								<FieldBuilder
									name='address'
									type='googlePlacesAutocomplete'
									label='locationSettings.table.address'
									showFullName
									staticWidth={250}
									isRequired
									maxMenuHeight={150}
									getAllData={setLocationData}
									disabled={!canEdit}
								/>
							</View>
							<View style={styles.formRow}>
								<FieldBuilder
									name='name'
									type='input'
									label='locationSettings.table.addressName'
									onChange={(a, b, c) => setName(c)}
									error={
										nameError
											? {
													message:
														'locationSettings.modal.nameError',
											  }
											: null
									}
									disabled={!canEdit}
								/>
							</View>
							<View style={styles.formRow}>
								<FieldBuilder
									name='radius'
									type='input'
									keyboardType='numeric'
									label='locationSettings.table.radius'
									isRequired
									maxVal={5000}
									onChange={(a, b, c) => onRadiusChange(c)}
									error={
										radiusError
											? {
													message:
														'locationSettings.modal.radiusError',
											  }
											: null
									}
									disabled={!canEdit}
								/>
							</View>
						</View>
					</View>
					{showBottomSection && (
						<>
							<View style={styles.tabsContainer}>
								<TouchableOpacity
									onPress={() => setActiveIndex(0)}
									style={[
										styles.buttonTab,
										{
											borderBottomColor:
												activeIndex === 0
													? colors.primary
													: colors.white,
										},
									]}
								>
									<I18n weight='normal'>
										locationSettings.modal.employee
									</I18n>
								</TouchableOpacity>

								{projectList.length ? (
									<TouchableOpacity
										onPress={() => setActiveIndex(1)}
										style={[
											styles.buttonTab,
											{
												borderBottomColor:
													activeIndex === 1
														? colors.primary
														: colors.white,
											},
										]}
									>
										<I18n weight='normal'>
											locationSettings.modal.projects
										</I18n>
									</TouchableOpacity>
								) : (
									<View />
								)}
							</View>
							{userList.length ? (
								<View
									style={[
										styles.tabContainer,
										{
											minHeight: 400,
											height: windowHeight - 540,
										},
									]}
								>
									{activeIndex === 0 ? (
										<EmployeeSelector
											userList={userList}
											userData={usersData}
											setUserData={updateUsers}
											disabled={
												!canEdit && !canEditAssociation
											}
										/>
									) : projectList.length ? (
										<ProjectSelector
											userList={userList}
											projects={projectList}
											userData={projectsData}
											setUserData={updateProjects}
											refreshUsersByProjects={
												refreshUsersByProjects
											}
											disabled={
												!canEdit && !canEditAssociation
											}
										/>
									) : (
										<View />
									)}
								</View>
							) : (
								<View />
							)}
						</>
					)}
				</ScrollView>
				<View style={{ flexDirection: 'row', zIndex: -1 }}>
					<SolidButtonContainer
						size={'medium'}
						textOptions={{ color: colors.lightPrimary }}
						overrideStyle={styles.cancelButton}
						onPress={onNext}
					>
						general.cancel
					</SolidButtonContainer>
					<SolidButtonContainer
						size={'medium'}
						onPress={() =>
							locationId
								? updateLocation(onNext)
								: createNewLocation(onNext)
						}
						disabled={
							disableSave || (!canEdit && !canEditAssociation)
						}
					>
						general.save
					</SolidButtonContainer>
				</View>
			</View>
		</FormProvider>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.white,
		borderRadius: 20,
		paddingVertical: 20,
		paddingHorizontal: 20,
		minWidth: 875,
	},
	separator: {
		backgroundColor: colors.primary,
		height: 2,
		width: '100%',
		marginBottom: 5,
	},
	sectionContainer: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		padding: 20,
		borderRadius: 8,
	},
	formRow: {
		//marginLeft: 40,
		zIndex: 'unset',
		height: 100,
	},
	tabsContainer: {
		flexDirection: 'row-reverse',
		borderBottomWidth: 1,
		borderBottomColor: '#e7e7e8',
	},
	tabContainer: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		padding: 20,
		marginBottom: 20,
		borderRadius: 8,
		borderTopRightRadius: 0,
		zIndex: 'unset',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	field: {
		width: 150,
		paddingBottom: 15,
	},
	dataContainer: {
		width: '50%',
	},
	cancelButton: {
		marginHorizontal: 10,
		backgroundColor: colors.white,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
	},
	buttonTab: {
		width: 150,
		borderBottomWidth: 6,
		alignItems: 'center',
		padding: 3,
		marginTop: 20,
	},
});

export default LocationSettingsModal;
