import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import OutlineButtonContainer from '../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import iconsMap from '../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';

const ModalConfirmActions = ({
	children,
	submitBtnText,
	cancelBtnText,
	iconName,
	onSubmit,
	onCancel,
	onNext,
}: {
	children: any;
	iconName: keyof typeof iconsMap;
	onSubmit: Function;
	onCancel: Function;
	submitBtnText: string;
	cancelBtnText: string;
	onNext: Function;
}) => {
	const platform = useDevicePlatform();
	const containerStyle = useMemo(
		() =>
			StyleSheet.flatten([
				styles.container,
				{ width: platform === DeviceType.WEB ? 400 : '90%' },
			]),
		[platform],
	);

	return (
		<View style={containerStyle}>
			<View style={styles.icon}>
				<ImageIcon name={iconName} width={100} height={100} />
			</View>
			<View style={styles.body}>{children}</View>
			<View style={styles.actionButtons}>
				<SolidButtonContainer
					overrideStyle={styles.buttonOk}
					onPress={() => onSubmit(onNext)}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='white'
					>
						{submitBtnText}
					</I18n>
				</SolidButtonContainer>
				<OutlineButtonContainer
					overrideStyle={styles.buttonCancel}
					onPress={() => onCancel(onNext)}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='primary'
					>
						{cancelBtnText}
					</I18n>
				</OutlineButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: 408,
		paddingVertical: 39,
		paddingHorizontal: 50,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
	},
	icon: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	body: {
		marginTop: 20,
		alignItems: 'center',
		justifyContent: 'center',
	},
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		marginTop: 48,
	},
	buttonOk: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
		paddingHorizontal: 15,
		padding: 10,
	},
	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		padding: 10,
	},
});

export default ModalConfirmActions;
