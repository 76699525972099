import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import iconsMap from '../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';

const AlertModal = ({
	children,
	submitBtnText,
	iconName,
	onSubmit,
	height = 357,
	onNext,
	overrideStyle = {},
	separator,
}: {
	children: any;
	iconName: keyof typeof iconsMap;
	onSubmit: Function;
	submitBtnText: string;
	height?: number;
	onNext?: Function;
	separator?: boolean;
	overrideStyle?: any;
}) => {
	const patform = useDevicePlatform();
	const isMobile = patform === DeviceType.MOBILE;
	const isMobileWeb = patform === DeviceType.MOBILE_WEB;
	const containerS = StyleSheet.flatten([
		{
			width: isMobile ? '90%' : isMobileWeb ? '100%' : 408,
			height: isMobile ? height : 'auto',
		},
		styles.container,
	]);

	return (
		<View style={containerS}>
			{iconName && (
				<View style={styles.icon}>
					<ImageIcon name={iconName} width={100} height={100} />
				</View>
			)}
			<View style={styles.body}>{children}</View>
			{separator && (
				<Divider
					style={{
						backgroundColor: colors.darkGrey,
						opacity: 0.2,
						marginVertical: 24,
					}}
				/>
			)}
			<View
				style={[
					styles.actionButtons,
					{ marginTop: separator ? 0 : 48 },
				]}
			>
				<SolidButtonContainer
					overrideStyle={{ ...styles.buttonOk, ...overrideStyle }}
					onPress={() => (onSubmit ? onSubmit(onNext) : onNext())}
				>
					<I18n size={16} weight='normal' color='white'>
						{submitBtnText}
					</I18n>
				</SolidButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingVertical: 39,
		paddingHorizontal: 50,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
	},
	icon: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	body: {
		marginTop: 20,
		alignItems: 'center',
		justifyContent: 'center',
	},
	actionButtons: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonOk: {
		width: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
	},
});

export default AlertModal;
