import React, { useState } from 'react';
import { View, StyleSheet, TouchableHighlight, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import {
	downloadRequestFile,
	onUpdateRequestStatus,
	RequestData,
} from '../RequestDetails.utils';
import getShadowStyle from '../../../../config/shadow';
import icons from '../../../../config/icons';
import { StatusOptions } from '../../../ApplicationsToEmployer/ApplicationsToEmployer.utils';
import { Privileges } from '../../../../constants/roleTypes.constant';
import moment from 'moment';
import useBusinessUnitsState from '../../../../hooks/useBusinessUnitsState';
import routes from '../../../../config/routes';
import { hasPermissionOnBusinessUnit } from '../../../../utils/globals.utils';

interface RequestProps {
	requestData: RequestData;
	validationDate: number;
	onClose: any;
	onRefresh(): void;
	businessUnitId: number;
	businessUnitName: string;
}

const SiknessRequest = ({
	requestData,
	onClose,
	validationDate,
	onRefresh,
	businessUnitId,
	businessUnitName,
}: RequestProps) => {
	const [updated, setUpdated] = useState(false);
	const [from, setFrom] = useState(requestData.fromDate);
	const [to, setTo] = useState(
		requestData.toDate ? requestData.toDate : requestData.fromDate,
	);

	const { businessUnitsList } = useBusinessUnitsState({
		privileges: routes.emploeeRequests.privileges,
		noRoute: true,
	});

	const platform = useDevicePlatform();
	const isMobile = platform === DeviceType.MOBILE;

	const canEdit =
		new Date(validationDate).getTime() -
			new Date(
				moment(requestData.fromDate, 'DD/MM/YYYY').valueOf(),
			).getTime() >
		0;

	const formMethods = useForm({
		defaultValues: {
			...requestData,
			toDate: requestData.toDate
				? requestData.toDate
				: requestData.fromDate,
		},
		mode: 'onBlur',
	});

	const onResponse = (ans: 'CONFIRM' | 'DECLINE') => {
		const formValues: any = formMethods.getValues();
		onUpdateRequestStatus(
			ans,
			requestData,
			formValues,
			onClose,
			onRefresh,
			updated,
		);
	};

	const onFormUpdate = (field: string, val: string) => {
		let isUpdated = false;

		if (field === 'fromDate') {
			!!val && setFrom(val);
			isUpdated = !!val && requestData && val !== requestData.fromDate;
		}
		if (field === 'toDate') {
			!!val && setTo(val);
			isUpdated =
				!!val &&
				(requestData && requestData.toDate
					? val !== requestData.toDate
					: val !== requestData.fromDate);
		}

		setUpdated(isUpdated);
	};

	return (
		<FormProvider {...formMethods}>
			<View style={styles.title}>
				<I18n size={24} weight='normal' color='darkGrey'>
					employeeRequests.siknessRequestTitle
				</I18n>
			</View>
			{businessUnitsList.length > 1 ? (
				<Typography size={14} weight='normal'>
					<I18n size={14} weight='normal' color='darkGrey'>
						general.businessUnits
					</I18n>
					{`:  ${businessUnitName}`}
				</Typography>
			) : undefined}
			<Typography size={14} weight='normal' style={{ marginTop: 5 }}>
				{`${requestData?.fullName} ${requestData?.teudatZeut}`}
			</Typography>

			<View style={styles.vacationContainer}>
				<I18n size={14} weight='normal'>
					employeeRequests.dates
				</I18n>

				<View style={styles.vacationDates}>
					<View style={{ width: 140 }}>
						<FieldBuilder
							styles={{ wrapper: { margin: 0 } }}
							type={!canEdit ? 'datepicker' : undefined}
							key={'fromDate'}
							name={'fromDate'}
							maxDate={
								to
									? new Date(
											moment(to, 'DD/MM/YYYY').valueOf(),
									  )
									: ''
							}
							minDate={new Date(validationDate)}
							label={() => (
								<I18n size={14} weight='normal'>
									vacationRequest.fromDate
								</I18n>
							)}
							placeholder={'general.datePickerPlaceholder'}
							onChange={(val, a, b) => {
								onFormUpdate(
									'fromDate',
									typeof b === 'string'
										? b
										: b?.target?.value,
								);
							}}
							editable={!canEdit}
						/>
					</View>
					<View style={{ width: 140 }}>
						<FieldBuilder
							styles={{ wrapper: { margin: 0 } }}
							type={!canEdit ? 'datepicker' : undefined}
							key={'toDate'}
							name={'toDate'}
							minDate={
								from
									? new Date(
											moment(
												from,
												'DD/MM/YYYY',
											).valueOf(),
									  )
									: new Date(validationDate)
							}
							label={() => (
								<I18n size={14} weight='normal'>
									vacationRequest.toDate
								</I18n>
							)}
							placeholder={'general.datePickerPlaceholder'}
							onChange={(val, a, b) => {
								onFormUpdate(
									'toDate',
									typeof b === 'string'
										? b
										: b?.target?.value,
								);
							}}
							editable={!canEdit}
						/>
					</View>
				</View>
				<View>
					{requestData && requestData.files.length === 0 ? (
						<View />
					) : (
						<I18n size={12} weight='normal'>
							employeeRequests.approvalForm
						</I18n>
					)}
					{requestData.files.map((file, index) => {
						const range = `${requestData.fromDate}${
							requestData.toDate ? '-' : ''
						}${requestData.toDate || ''}`;
						return (
							<View style={{ marginTop: 15 }}>
								<TouchableHighlight
									key={index}
									underlayColor='transparent'
									onPress={async () =>
										await downloadRequestFile(
											requestData.id,
											file.id,
											requestData.teudatZeut,
											range,
											isMobile,
											file.fileName,
										)
									}
								>
									<View
										style={{
											display: 'flex',
											flexDirection: 'row-reverse',
											marginBottom: 10,
											paddingRight: 20,
										}}
									>
										<Typography weight='normal' size={14}>
											{file?.fileName}
										</Typography>
										<Image
											style={{
												width: 14,
												height: 18,
												marginHorizontal: 11,
											}}
											source={icons.pdf}
										/>
									</View>
								</TouchableHighlight>
							</View>
						);
					})}
				</View>

				<FieldBuilder
					key={'description'}
					name={'description'}
					multiline
					label={() => (
						<I18n size={12} weight='normal'>
							vacationRequest.description
						</I18n>
					)}
					height={91}
					styles={{ wrapper: { margin: 0 } }}
					editable={false}
					hasEditableColorText
				/>

				<View style={[styles.fieldsContainer, { marginTop: 15 }]}>
					<View style={{ width: 160 }}>
						<I18n size={14} weight='normal'>
							applicationsToEmployerContainer.status
						</I18n>
					</View>
					<Typography weight='normal' size={14}>
						{StatusOptions[requestData.status]}
					</Typography>
				</View>

				<View style={styles.fieldsContainer}>
					<View style={{ width: 160 }}>
						<I18n size={14} weight='normal'>
							applicationsToEmployerContainer.lastUpdated
						</I18n>
					</View>
					<Typography weight='normal' size={14}>
						{requestData.lastUpdate}
					</Typography>
				</View>

				<FieldBuilder
					key={'employerComment'}
					name={'employerComment'}
					multiline
					label={() => (
						<I18n size={14} weight='normal'>
							employeeRequests.employerDescriptionTitle
						</I18n>
					)}
					placeholder={'employeeRequests.employerDescription'}
					isRequired={false}
					editable={true}
					maxLength={300}
					height={91}
					limitMargin={15}
					styles={{ wrapper: { margin: 0, marginBottom: 15 } }}
				/>

				{['PENDING', 'REJECTED', 'APPROVED'].includes(
					requestData.status,
				) &&
					hasPermissionOnBusinessUnit(
						businessUnitId,
						Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
					) && (
						<View style={styles.buttonContainer}>
							{['PENDING', 'REJECTED'].includes(
								requestData.status,
							) && (
								<TouchableHighlight
									onPress={() => onResponse('CONFIRM')}
									underlayColor='transparent'
								>
									<View style={styles.button}>
										<I18n
											size={16}
											weight='normal'
											color='white'
										>
											employeeRequests.approveButton
										</I18n>
									</View>
								</TouchableHighlight>
							)}

							{['PENDING', 'APPROVED'].includes(
								requestData.status,
							) && (
								<TouchableHighlight
									onPress={() => onResponse('DECLINE')}
									underlayColor='transparent'
								>
									<View style={styles.cancelButton}>
										<I18n
											size={16}
											weight='normal'
											color='lightPrimary'
										>
											employeeRequests.rejectButton
										</I18n>
									</View>
								</TouchableHighlight>
							)}
						</View>
					)}
			</View>
		</FormProvider>
	);
};
const styles = StyleSheet.create({
	vacationContainer: {
		alignContent: 'space-between',
		marginTop: 17,
		marginBottom: 20,
		justifyContent: 'space-between',
	},
	title: {
		marginBottom: 5,
	},
	fieldsContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		marginBottom: 15,
	},
	vacationDates: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 90,
		justifyContent: 'space-between',
		zIndex: 1000,
	},
	alertContainer: {
		paddingVertical: 39,
		paddingHorizontal: 50,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
		width: '100%',
		marginTop: 25,
	},
	cancelButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: 'white',
		borderRadius: 45,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default SiknessRequest;
