import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { TouchableHighlight, View, StyleSheet } from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import getShadowStyle from '../../config/shadow';
import { Option } from '../../interfaces/option.interface';
import { Full101ReportList } from '../../models/full101ReportList.model';
import httpService from '../../services/http.service';
import { i18nService } from '../../services/i18n.service';
import { createAndDownload101 } from '../WorkerCard/WorkerCardPreview/workerCardPreviewBuilder';
import { downloadUrlApprovedManagerFile } from '../WorkerCard/WorkerCardPreview/utils';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../store/selectors/login.selectors';
import { Privileges } from '../../constants/roleTypes.constant';
import _, { compact } from 'lodash';
import { hasPermissionOnBusinessUnit } from '../../utils/globals.utils';
import { getState } from '../../store/store';

export type Report101FiltersType =
	| 'dep'
	| 'user_id'
	| 'year'
	| 'status'
	| 'unitIds';

export type Report101FiltersDataType = Record<
	'dep' | 'user_id' | 'year' | 'status',
	string[] | Option[] | Record<number, string>
>;

export type Report101DataType = Record<
	keyof Full101ReportList,
	string | number | JSX.Element
>;

export type Report101RowType = Partial<Report101DataType>;

export const report101PageFilterSize = 20;

const BlueCheckbox = withStyles({
	root: {
		color: 'default',
		'&$checked': {
			color: '#4AAEEF',
		},
	},
	checked: {},
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

export const getIsModuleActiveByUnitId = (unitId: number) => {
	const loginData = getState().login;
	const modules =
		typeof loginData?.modules === 'object'
			? loginData?.modules
			: JSON.parse(loginData?.modules);
	const modulesByUnit = modules?.find((el: any) => unitId === el.id);
	return !!modulesByUnit?.modules?.find(
		(module: any) => module.id === 113 && module.status === 'ACTIVE',
	);
};

const CheckboxHeader = ({
	onSelectAllCheckButton,
	isCheckboxAllSelected,
}: {
	onSelectAllCheckButton: () => void;
	isCheckboxAllSelected: boolean;
}) => {
	return isCheckboxAllSelected ? (
		<BlueCheckbox
			size={'small'}
			checked={true}
			onChange={(event: any) => {
				onSelectAllCheckButton();
			}}
		/>
	) : (
		<Checkbox onClick={() => onSelectAllCheckButton()} size={'small'} />
	);
};

export const checkIfCheckboxIsDisabled = (
	row: any,
	actionButtonType:
		| boolean
		| 'cancelReports'
		| 'approveReports'
		| 'sendReminder',
) => {
	if (
		!hasPermissionOnBusinessUnit(
			row.original.businessUnitId,
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
		)
	) {
		return true;
	}

	let disabled;
	if (actionButtonType === 'approveReports') {
		disabled =
			row.original.statusCode === 'PENDING_EMPLOYER' ? false : true;
	} else if (actionButtonType === 'cancelReports') {
		disabled = row.original.statusCode === 'APPROVED' ? true : false;
	} else if (actionButtonType === 'sendReminder') {
		disabled = ![
			'PENDING_EMPLOYEE_CORRECTION',
			'PENDING_EMPLOYEE_SUBMIT',
		].includes(row.original.statusCode);
	}

	return disabled;
};

export const createHeadersForTable = (
	actionButtonType:
		| boolean
		| 'cancelReports'
		| 'approveReports'
		| 'sendReminder',
	checkIfRowSelected: (id: any) => boolean,
	onCheckboxChange: (id: any) => void,
	onSelectAllCheckButton: () => void,
	isCheckboxAllSelected: boolean,
	isPdfMode: boolean,
	businessUnitsList: any[],
) => {
	let fieldToLabelMap: Report101RowType = {
		userId: actionButtonType ? (
			<CheckboxHeader
				isCheckboxAllSelected={isCheckboxAllSelected}
				onSelectAllCheckButton={onSelectAllCheckButton}
			/>
		) : (
			''
		),
		fullName: i18nService.translate('report101ReportPage.fullName'),
		teudatZeut: i18nService.translate('report101ReportPage.teudatZeut'),
		businessUnitName: i18nService.translate('general.businessUnits'),
		taxYear: i18nService.translate('report101ReportPage.taxYear'),
		status: i18nService.translate('report101ReportPage.status'),
		departmentName: i18nService.translate(
			'report101ReportPage.departmentName',
		),
		employeeCreateDate: i18nService.translate(
			'report101ReportPage.employeeCreateDate',
		),
		submitDate: i18nService.translate('report101ReportPage.submitDate'),
		lastUpdate: i18nService.translate('report101ReportPage.lastUpdate'),
		attachedFiles: i18nService.translate(
			'report101ReportPage.attachedFiles',
		),
	};

	fieldToLabelMap = isPdfMode
		? _.pick(fieldToLabelMap, [
				'userId',
				'fullName',
				'teudatZeut',
				'businessUnitName',
				'taxYear',
				'status',
		  ])
		: fieldToLabelMap;
	const report101Headers: any = compact(
		Object.entries(fieldToLabelMap).map(([key, value]) =>
			key !== 'businessUnitName' || businessUnitsList.length > 1
				? {
						accessor: key,
						Header: value,
						isSortable: key === 'userId' ? false : true,
						CustomCell:
							key === 'fullName'
								? ({ value, row }: any) => {
										return (
											<View>
												{row.original?.changed && (
													<ChangesIndicator />
												)}
												<Typography
													color='primary'
													weight='bold'
													size={14}
												>
													{value}
												</Typography>
											</View>
										);
								  }
								: key === 'status'
								? ({ value, row }: any) => {
										return (
											<StatusColorMatcher
												status={value}
											/>
										);
								  }
								: key === 'attachedFiles'
								? ({ value, row }: any) => {
										return (
											<DownloadSection
												value={value}
												row={row}
											/>
										);
								  }
								: key === 'userId' //&& isSelected
								? ({ value, row }: any) => {
										return actionButtonType ? (
											<div
												onClick={(event: any) =>
													event.stopPropagation()
												}
											>
												<BlueCheckbox
													disabled={checkIfCheckboxIsDisabled(
														row,
														actionButtonType,
													)}
													size={'small'}
													checked={checkIfRowSelected(
														row.original.id,
													)}
													onChange={(event: any) => {
														onCheckboxChange(row);
													}}
												/>
											</div>
										) : (
											<></>
										);
								  }
								: null,
				  }
				: null,
		),
	);
	return report101Headers;
};

export const createFilterOptionsForTable = (
	filtersData: Report101FiltersDataType,
	state: any,
	businessUnitsList: any[],
	isBusinessUnitsMulti: boolean,
) => {
	let defaultYear = state.filter.year[0];
	return compact([
		{
			name: 'search',
			defaultValue: state.search,
			options: [],
			type: 'searchInput',
			placeholder: i18nService.translate('filter.search'),
		},
		{
			name: 'status',
			options: Object.entries(statusReport101Matcher).map(
				([key, value]) => ({
					id: key,
					name: i18nService.translate(
						`report101ReportPage.status.${value?.resolve}`,
					),
				}),
			),
			value: state.filter?.status?.map((val: StatusCodeToStringKeys) => ({
				id: statusCodeToString[val],
				name: i18nService.translate(
					`report101ReportPage.status.${val}`,
				),
			})),
			placeholder: 'status',
			type: 'multiselect',
			styles: { control: { maxWidth: 200, minWidth: 200 } },
		},
		{
			name: 'dep',
			value: state.filter?.dep?.map((val: string) => ({
				id: val,
				name: (filtersData?.dep as any[]).find(
					(el: any) => String(el.id) === val,
				)?.name,
			})),
			key: JSON.stringify(state.filter.unitIds),
			options: filtersData.dep,
			type: 'multiselect',
			placeholder: 'departments',
			styles: { width: 134 },
		},
		{
			name: 'year',
			defaultValue: defaultYear || null,
			options: (filtersData.year as string[])?.map((val: string) => ({
				id: val,
				name: val,
			})),
			type: 'select',
			placeholder: 'taxyear',
			styles: { width: 114 },
		},
		businessUnitsList?.length > 1
			? isBusinessUnitsMulti
				? {
						name: 'unitIds',
						type: 'multiselect',
						placeholder: 'businessUnits',
						value: state.filter.unitIds?.map((unit: any) =>
							businessUnitsList.find(
								(bunit: any) => bunit.id === unit,
							),
						),
						options: businessUnitsList,
						styles: {
							control: {
								width: 200,
								maxWidth: 200,
							},
						},
				  }
				: {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: state.filter.unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
			: undefined,
	]);
};

export const init101ReportResponce = {
	hasNext: false,
	total: 0,
	data: [],
};

export const init101ReportFilters = {
	dep: [],
	user_id: [],
	year: [],
	status: [],
};

type statusReport101MatcherType = Record<string, Record<string, string>>;

type StatusCodeToStringKeys =
	| 'APPROVED'
	| 'PENDING_EMPLOYEE_CORRECTION'
	| 'PENDING_EMPLOYER'
	| 'PENDING_EMPLOYEE_SUBMIT';

const statusCodeToString: Record<StatusCodeToStringKeys, string> = {
	APPROVED: 'אושר ע"י מעסיק',
	PENDING_EMPLOYEE_CORRECTION: 'ממתין לתיקון של העובד',
	PENDING_EMPLOYER: 'בטיפול מעסיק',
	PENDING_EMPLOYEE_SUBMIT: 'ממתין להגשה של עובד',
};
export const statusReport101Matcher: statusReport101MatcherType = {
	'אושר ע"י מעסיק': { resolve: 'APPROVED', color: 'green' },
	'ממתין לתיקון של העובד': {
		resolve: 'PENDING_EMPLOYEE_CORRECTION',
		color: 'orange',
	},
	'בטיפול מעסיק': { resolve: 'PENDING_EMPLOYER', color: 'blue' },
	'ממתין להגשה של עובד': {
		resolve: 'PENDING_EMPLOYEE_SUBMIT',
		color: 'orange',
	},
};

export const statusReport101MatcherByResolve = (status: string) => {
	return Object.keys(statusReport101Matcher).find(
		key => statusReport101Matcher[key].resolve === status,
	);
};

export const statusReport101MatcherForReadOnly = (statusHebr: string) => {
	if (statusReport101Matcher[statusHebr].resolve === 'APPROVED') {
		return 'true';
	}
	return 'false';
};

const StatusColorMatcher = ({ status }: { status: any }) => {
	return (
		<View style={{ display: 'flex', flexDirection: 'row' }}>
			<View>
				<div
					style={{
						width: 10,
						height: 10,
						backgroundColor: `${statusReport101Matcher[status]?.color}`,
						borderRadius: 50,
						marginLeft: 3,
					}}
				></div>
			</View>
			<View>
				<I18n size={12} weight='normal'>
					{`report101ReportPage.status.${statusReport101Matcher[status]?.resolve}`}
				</I18n>
			</View>
		</View>
	);
};

export const downloadDesktopManagerAttachmentsHandler = async (
	fieldId: number,
	row: any,
) => {
	const res: any = await httpService.api({
		type: 'getManagerForm101Attachments',
		params: { formId: fieldId },
		responseType: 'blob',
	});
	let elm = document.createElement('a');
	elm.href = URL.createObjectURL(res);
	elm.setAttribute(
		'download',
		`tofes101_${row?.values?.teudatZeut}_${row?.values?.taxYear}_attachments.zip`,
	);
	elm.click();
};

const DownloadSection = ({ value, row }: { value: any; row: any }) => {
	return (
		<View style={styles.actionBtns}>
			{!!value ? (
				<View style={styles.downQuant}>
					<Typography
						color='primary'
						weight='400'
						size={14}
						style={{ minWidth: 15 }}
					>
						{value}
					</Typography>
					<div
						onClick={(e: any) => {
							e.stopPropagation();
							downloadDesktopManagerAttachmentsHandler(
								row.original.id,
								row,
							);
						}}
					>
						<TouchableHighlight
							disabled={value}
							style={downloadBtnStyle}
							underlayColor='transparent'
						>
							<ImageIcon
								name='downloadPaycheck'
								height={20}
								width={20}
							/>
						</TouchableHighlight>
					</div>
				</View>
			) : (
				<div />
			)}
			<div
				onClick={async (e: any) => {
					e.stopPropagation();
					if (row?.original?.statusCode !== 'APPROVED') {
						createAndDownload101(row?.original?.id, false);
					} else {
						downloadUrlApprovedManagerFile(
							row?.original?.id,
							row?.original,
						);
					}
				}}
			>
				<View style={downloadBtnStyle}>
					<ImageIcon name='downloadPDF' height={20} width={15} />
				</View>
			</div>
		</View>
	);
};

type selectGroupBtnsStylesType = {
	containerIncludesOneBtn: { [val: string]: any };
	containerIncludesTwoBtn: { [val: string]: any };
	buttonApprove: { [val: string]: any };
};

export const SelectReportsButtonMobileContainer = ({
	setActiveActionButtonType,
	onDisselectAll,
	onApproveAllSelectedButtonPressed,
	onSelectedCancelButtonPressed,
	onPressButton,
	stylesConf,
	activeActionButtonType,
	form101ModuleActive,
	onSendReminderButtonHandler,
}: any) => {
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);

	const selectReportButtonsContainer = StyleSheet.flatten([
		styles.selectReportButton,
		stylesConf?.containerIncludesOneBtn,
	]);

	const manageButtonsContainer = StyleSheet.flatten([
		styles.manageSelectButtonsContainer,
		stylesConf?.containerIncludesTwoBtn,
	]);

	const approveSelectButtonStyle = StyleSheet.flatten([
		styles.approveSelectButton,
		stylesConf?.buttonApprove,
	]);

	return (
		<>
			{!activeActionButtonType ? (
				<TouchableHighlight
					underlayColor='transparent'
					disabled={
						!employerRolesPrivileges?.includes(
							Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
						) || !form101ModuleActive
					}
					style={{
						opacity:
							!employerRolesPrivileges?.includes(
								Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
							) || !form101ModuleActive
								? 0.7
								: 1,
					}}
					onPress={e => {
						onPressButton(e);
					}}
				>
					<View style={selectReportButtonsContainer}>
						<I18n
							style={{ marginLeft: 10, marginRight: 10 }}
							color={'white'}
							weight='400'
							size={16}
						>
							reportView.action
						</I18n>
					</View>
				</TouchableHighlight>
			) : null}
			{activeActionButtonType ? (
				<View style={manageButtonsContainer}>
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => {
							setActiveActionButtonType(false);
							onDisselectAll();
						}}
					>
						<View style={styles.resetSelectButton}>
							<I18n
								style={{ marginLeft: 20, marginRight: 20 }}
								color={'lightPrimary'}
								weight='400'
								size={16}
							>
								general.back
							</I18n>
						</View>
					</TouchableHighlight>
					{
						<TouchableHighlight
							underlayColor='transparent'
							onPress={() => {
								activeActionButtonType === 'approveReports'
									? onApproveAllSelectedButtonPressed()
									: activeActionButtonType === 'cancelReports'
									? onSelectedCancelButtonPressed()
									: onSendReminderButtonHandler();
							}}
						>
							<View style={approveSelectButtonStyle}>
								<I18n
									style={{ marginLeft: 20, marginRight: 20 }}
									color={'white'}
									weight='400'
									size={16}
								>
									{`reportView.${
										activeActionButtonType ===
										'sendReminder'
											? 'sendReminderButton'
											: activeActionButtonType
									}`}
								</I18n>
							</View>
						</TouchableHighlight>
					}
				</View>
			) : null}
		</>
	);
};

export const SubMenuMobileItem = ({
	item,
	filterContentMapperValue,
	chosenRowsValue,
	setChosenRowsValue,
	isSingle,
}: {
	item: { id: any; name: any };
	filterContentMapperValue: string;
	chosenRowsValue: string[];
	setChosenRowsValue: (selectedRows: string[]) => void;
	isSingle: boolean;
}) => {
	const selectItemHandler = () => {
		if (isSingle) {
			setChosenRowsValue([item.id]);
		} else {
			if (chosenRowsValue?.find(el => el === item.id)) {
				const filteredArray = chosenRowsValue.filter(
					el => el !== item.id,
				);
				setChosenRowsValue(filteredArray);
			} else {
				const updatedArray = [...chosenRowsValue, item.id];
				setChosenRowsValue(updatedArray);
			}
		}
	};

	const isSelected = chosenRowsValue?.find(el => el === item.id)
		? true
		: false;

	return (
		<View>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => selectItemHandler()}
			>
				<View style={styles.itemSubFilterContainer}>
					<View style={styles.itemSubFilterContainerMark}>
						{isSelected ? (
							<ImageIcon
								name={'checkedMark'}
								width={22}
								height={18}
							/>
						) : (
							<></>
						)}
					</View>
					<View style={styles.itemSubFilterContainerOptions}>
						<Typography color='darkGrey' weight='400' size={20}>
							{filterContentMapperValue === 'status'
								? item.id
								: item.name}
						</Typography>
					</View>
				</View>
			</TouchableHighlight>

			<View style={styles.separator}></View>
		</View>
	);
};

export const isFormReport101Outdated = (dateToCheck: string) => {
	const deadline = `${dateToCheck + 1}-01-28`;
	const isOverdue = moment().isAfter(deadline);

	return isOverdue;
};

export const isFilterActive = (selectedFilters: Report101FiltersDataType) => {
	let isAcive = false;
	Object.values(selectedFilters).forEach((filter: any) => {
		if (filter?.length) {
			isAcive = true;
		}
	});
	return isAcive;
};

const ChangesIndicator = () => {
	return (
		<View style={styles.changesIndicator}>
			<I18n size={8} color='white'>
				general.changes
			</I18n>
		</View>
	);
};

const styles = StyleSheet.create({
	btn: {
		height: 20,
		width: 25,
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	btnDisabled: {
		opacity: 0.5,
	},
	downQuant: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	selectReportButton: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,

		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	manageSelectButtonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	approveSelectButton: {
		height: 40,
		marginLeft: 18,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		justifyContent: 'center',
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	resetSelectButton: {
		height: 40,
		backgroundColor: colors.white,
		borderRadius: 45,
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: '#4aaeef',
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	itemSubFilterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginLeft: 15,
		marginRight: 38,
		height: 65,
	},
	itemSubFilterContainerMark: {
		display: 'flex',
		justifyContent: 'center',
	},
	itemSubFilterContainerOptions: {
		display: 'flex',
		justifyContent: 'center',
	},
	separator: {
		backgroundColor: 'rgb(82, 89, 98)',
		height: 1,
		opacity: 0.2,
	},
	changesIndicator: {
		position: 'absolute',
		backgroundColor: '#eb8b2d',
		padding: 3,
		width: 38,
		top: -17,
		right: -15,
		alignItems: 'center',
		borderBottomRightRadius: 7,
		borderBottomLeftRadius: 7,
	},
});

const downloadBtnStyle = StyleSheet.flatten([
	styles.btn,
	// isError && styles.btnDisabled,
]);
