import React from 'react';
import { TouchableHighlight } from 'react-native';
import WebCheckBox from 'expo-checkbox';
import moment from 'moment';
import { compact } from 'lodash';
import { Tooltip } from '@material-ui/core';

import { i18nService } from '../../../services/i18n.service';
import Typography from '../../../components/shared/Typography/Typography.component';
import { VacationReport } from '../../../interfaces/redux.interface';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import { Privileges } from '../../../constants/roleTypes.constant';
import ActionButtonsMenu from './UserTableMobileRow/ActionButtonsMenu';

type AccessorsArray = Omit<keyof VacationReport, 'id' | 'periods' | 'name'>;
export interface TableHeader {
	Header: any;
	accessor: string;
	Footer?: string;
	CustomCell?: (props: any) => void;
	SubCell?: (props: any) => void;
	isSortable?: boolean;
	isEditable?: boolean;
	id?: string;
	style?: any;
}

export const initialHeaders = (
	selectAll: boolean,
	onSelectAll: (v: boolean) => void,
	onRowsChecked: (v: boolean, id: number, row: any) => void,
	selectedItems: number[],
	onRowClick: (row: any) => void,
	businessUnitsList: any[],
	employerRolePrivileges: any,
	docPermission: boolean,
	modules: any,
	showComprehensivePermissionUpdateBtn: boolean,
) => {
	return compact([
		showComprehensivePermissionUpdateBtn && {
			accessor: 'checkBox',
			Header: () => {
				return (
					<WebCheckBox
						value={selectAll}
						onValueChange={onSelectAll}
						color={colors.primary}
						disabled={
							!employerRolePrivileges?.includes(
								Privileges.ROLE_MGM_USER_WRITE,
							)
						}
						style={{
							width: 15,
							height: 15,
							borderRadius: 4,
						}}
					/>
				);
			},
			isSortable: false,
			style: { width: 20 },
			CustomCell: ({ value, row }: any) => {
				return (
					<WebCheckBox
						value={
							row.original.status &&
							row.original.employeeType === 'INTERNAL'
								? selectAll
									? true
									: !!selectedItems.find(
											el => el === row.original.profileId,
									  ) || false
								: false
						}
						onValueChange={v =>
							onRowsChecked(v, row.original.id, row)
						}
						color={colors.primary}
						disabled={
							!row.original.status ||
							row.original.employeeType !== 'INTERNAL' ||
							!hasPermissionOnBusinessUnit(
								row.original.businessUnitId,
								Privileges.ROLE_MGM_USER_WRITE,
							)
						}
						style={{
							width: 15,
							height: 15,
							borderRadius: 4,
						}}
					/>
				);
			},
		},
		{
			accessor: 'teudatZeut',
			Header: i18nService.translate('usersManagement.table.tz'),
			isSortable: true,
			style: { minWidth: 80 },
			hasBudget: (row: any) => row.finishWork,
			textBlock: (row: any) => (
				<>
					<I18n
						size={8}
						color='white'
						weight='normal'
						style={{ marginLeft: 5 }}
					>
						general.endWorkDate
					</I18n>
					<I18n size={8} weight='normal' color='white'>
						{moment(row.original.finishWork, 'YYYY-MM-DD').format(
							'DD/MM/YYYY',
						)}
					</I18n>
				</>
			),
			budgetStyle: {
				paddingVertical: 3,
				paddingHorizontal: 6,
				minWidth: 110,
			},
			CustomCell: ({ value, row }: any) => {
				return (
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => onRowClick(row.original)}
					>
						<Typography color='primary' weight='bold' size={14}>
							{value}
						</Typography>
					</TouchableHighlight>
				);
			},
		},
		{
			accessor: 'fullName',
			Header: i18nService.translate('usersManagement.table.emp_name'),
			isSortable: true,
		},
		businessUnitsList.length > 1
			? {
					accessor: 'businessUnitName',
					Header: i18nService.translate('general.businessUnits'),
					isSortable: true,
			  }
			: null,
		{
			accessor: 'departmentName',
			Header: i18nService.translate('usersManagement.table.dep'),
			isSortable: true,
		},
		{
			accessor: 'email',
			Header: i18nService.translate('usersManagement.table.email'),
			isSortable: true,
		},
		{
			accessor: 'phone',
			Header: i18nService.translate('usersManagement.table.phone'),
			isSortable: true,
		},
		{
			accessor: 'roleNameList',
			Header: i18nService.translate('usersManagement.table.roles'),
			CustomCell: ({ value, row }: any) => {
				if (!value) {
					return null;
				}
				const tempValue = value?.split(',');

				return tempValue.length <= 2 ? (
					<Typography size={14}>{tempValue.join(', ')}</Typography>
				) : (
					<Tooltip title={value} placement='top-start'>
						<Typography size={14}>
							{tempValue.slice(0, 2).join(', ')}
							{`, +${tempValue.length - 2}`}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			accessor: 'hasExcludedPermission',
			Header: i18nService.translate(
				'usersManagement.table.special_permissions',
			),
			isSortable: true,
			CustomCell: ({ value, row }: any) => {
				return <I18n size={14}>{`general.${value}`}</I18n>;
			},
		},
		{
			accessor: 'hasExcludedUser',
			Header: i18nService.translate(
				'usersManagement.table.special_employees',
			),
			isSortable: true,
			CustomCell: ({ value, row }: any) => {
				return <I18n size={14}>{`general.${value}`}</I18n>;
			},
		},
		{
			accessor: 'status',
			Header: i18nService.translate('usersManagement.table.status'),
			isSortable: true,
			CustomCell: ({ value, row }: any) => {
				return (
					<I18n
						size={14}
					>{`usersManagement.table.status.${value}`}</I18n>
				);
			},
		},
		docPermission && {
			accessor: 'docsActionMenu',
			Header: '',
			isSortable: false,
			hide: true,
			CustomCell: ({ value, row }: any) => {
				const showMenu = modules[row.original?.businessUnitId]?.find(
					(module: any) =>
						module.id === 114 && module?.status === 'ACTIVE',
				)
					? row.original?.identityType === 'ID'
						? true
						: false
					: false;

				return showMenu ? (
					<ActionButtonsMenu
						userId={row.original.id}
						name={`${row.original.fullName} (${row.original.teudatZeut})`}
						unitId={row.original.businessUnitId}
					/>
				) : null;
			},
		},
	]);
};
