import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import FieldBuilder from '../../../components/shared/FieldBuilder/FieldBuilder';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import colors from '../../../config/colors';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../../store/selectors/login.selectors';

interface Props {
	data: any;
	disableSubmit: boolean;
	setDisableSubmit: any;
	eventsOptions: string[];
	onUpdate(data: string[]): void;
	onCancel(): void;
	payCheckModuleExists: string;
	canNotEdit?: boolean;
}

export const PayChecks = ({
	data,
	eventsOptions,
	onCancel,
	onUpdate,
	disableSubmit,
	setDisableSubmit,
	payCheckModuleExists,
	canNotEdit,
}: Props) => {
	const platform = useDevicePlatform();
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);

	const isWeb = platform === DeviceType.WEB;
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		setIsActive(payCheckModuleExists === 'ACTIVE' && !canNotEdit);
	}, [payCheckModuleExists, canNotEdit]);

	return (
		<ScrollView contentContainerStyle={styles.container}>
			<View style={styles.body}>
				<I18n size={22} weight='normal' style={styles.title}>
					businessSettings.employer
				</I18n>

				<View style={[isWeb ? styles.row : styles.mobileRow]}>
					<View
						style={[
							isWeb
								? styles.payChecksRow
								: styles.payChecksMobileRow,
						]}
					>
						<I18n size={16} weight='normal' style={{ width: 320 }}>
							businessSettings.payChecks.FromMonthToMonthReport
						</I18n>
						<I18n
							size={12}
							weight='normal'
							style={[
								styles.text,
								{ paddingLeft: 30, paddingVertical: 5 },
							]}
						>
							businessSettings.payChecks.FromMonthToMonthReport.note
						</I18n>
					</View>
					<FieldBuilder
						name={'workPaperDraftOpenMonthDay'}
						disabled={!isActive}
						defaultValue={data?.workPaperDraftOpenMonthDay?.toString()}
						type={'reactselect'}
						isSearchable={false}
						options={[...Array(31)].map((i, index) => ({
							id: (index + 1).toString(),
							name: (index + 1).toString(),
						}))}
						style={{
							width: isWeb ? 100 : '100%',
							marginTop: isWeb ? 0 : 15,
							zIndex: 1000000000,
						}}
						maxMenuHeight={150}
						styles={{ dropdownIndicator: colors.white }}
					/>
				</View>
			</View>
			<View
				style={{
					flexDirection: 'row',
					marginVertical: 30,
				}}
			>
				<SolidButtonContainer
					size={isWeb ? 'medium' : 'small'}
					textOptions={{ color: colors.lightPrimary }}
					overrideStyle={[
						styles.cancelButton,
						!isWeb && {
							paddingHorizontal: 22,
							paddingVertical: 10,
						},
					]}
					onPress={onCancel}
				>
					general.cancel
				</SolidButtonContainer>
				<SolidButtonContainer
					size={isWeb ? 'medium' : 'small'}
					disabled={disableSubmit || !isActive}
					overrideStyle={
						!isWeb && {
							paddingHorizontal: 22,
							paddingVertical: 10,
						}
					}
					onPress={() => {
						onUpdate(eventsOptions);
						setDisableSubmit(true);
					}}
				>
					general.save
				</SolidButtonContainer>
			</View>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingHorizontal: 20,
		flex: 1,
		justifyContent: 'space-between',
		height: '100%',
	},
	body: {
		flexGrow: 1,
	},
	title: {
		marginTop: 30,
		paddingBottom: 20,
	},
	row: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 55,
		zIndex: 100,
	},
	mobileRow: {
		flexDirection: 'column',
		zIndex: 100,
	},
	payChecksRow: {
		marginLeft: 50,
	},
	payChecksMobileRow: {
		marginBottom: 20,
	},
	text: {
		width: 320,
		paddingBottom: 20,
	},
	cancelButton: {
		marginHorizontal: 10,
		backgroundColor: colors.white,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
	},
});
