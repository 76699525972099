import React from 'react';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import _, { debounce } from 'lodash';

interface FlexTableProps {}
const useStyles = makeStyles((theme: any) => ({
	header: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
		fontFamily: 'RubikMedium',
		whiteSpace: ({ type }: any) => (type === 'table' ? 'normal' : 'nowrap'),
		padding: '8px',
		height: ({ type }: any) => (type === 'table' ? '44px' : '22px'),
		borderLeft: ({ type }: any) =>
			type === 'table'
				? `1px solid ${colors.primary}`
				: `1px solid ${colors.filterBorder}`,
		borderBottom: `1px solid ${colors.filterBorder}`,
		backgroundColor: ({ headerBackgroundColor }: any) =>
			headerBackgroundColor,
		color: ({ headerColor }: any) => headerColor,
		maxWidth: ({ componentType, type }: any) =>
			componentType === 'OPTIONAL_DEDUCTION'
				? 160
				: type === 'table'
				? 69
				: 'auto',
		width: ({ componentType, type }: any) =>
			componentType === 'OPTIONAL_DEDUCTION'
				? 160
				: type === 'table'
				? 69
				: 'auto',
	},
	headerText: {
		width: '84%',
	},
	iconsBox: {
		display: 'flex',
		flexDirection: 'column',
		width: '16%',
	},
	thBox: {
		display: 'flex',
		alignItems: 'center',
	},
	arrowIcon: {
		fontSize: '1.3rem',
		cursor: 'pointer',
		color: colors.white,
	},
	down: {
		marginTop: -9,
	},
	arrowClicked: {
		color: colors.lightPrimary,
	},
}));

const HeaderRow = (props: any) => {
	const {
		header,
		headerBackgroundColor,
		headerColor,
		color,
		onSort,
		type,
		componentType,
		activeSort,
		setActiveSort,
		code,
		name,
	} = props;

	const classes = useStyles({
		headerBackgroundColor,
		headerColor,
		color,
		type,
		componentType,
	});

	const _onSort = (name: string, direction: string, code: number) => {
		onSort && onSort(name, direction, code);
		setActiveSort({ name, direction });
	};

	return (
		<>
			{header?.map((item: any) => (
				<div className={classes.header} key={item.name}>
					<span className={classes.headerText}>
						{i18nService.translate(
							typeof item.value === 'function'
								? item.value({ code, name })
								: item.value,
						)}
					</span>
					<span className={classes.iconsBox}>
						<ArrowDropUp
							classes={{
								root: classnames(
									classes.arrowIcon,
									activeSort.name === item.name &&
										activeSort.direction === 'ASC'
										? classes.arrowClicked
										: null,
								),
							}}
							onClick={e => {
								e.preventDefault();
								_onSort(item.name, 'ASC', item.code);
							}}
						/>
						<ArrowDropDown
							classes={{
								root: classnames(
									classes.down,
									classes.arrowIcon,
									activeSort.name === item.name &&
										activeSort.direction === 'DESC'
										? classes.arrowClicked
										: null,
								),
							}}
							onClick={e => {
								e.preventDefault();
								_onSort(item.name, 'DESC', item.code);
							}}
						/>
					</span>
				</div>
			))}
		</>
	);
};

export default React.memo(HeaderRow);
