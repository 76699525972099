import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ScrollSyncPane } from 'react-scroll-sync';
import colors from '../../../config/colors';
import FlexTableComponent from './TableRow';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import HeaderRow from './HeaderRow';

interface FlexTableProps {
	data: any;
	tableName: string;
	disabled: boolean;
	isLoading: boolean;
	headerBackgroundColor: string;
	headerColor: string;
	color: string;
	onBlur: Function;
	onPreesEdit: Function;
	onPress: Function;
	onPreesRemove: Function;
	maxLength: number;
	keyboardType: [string, string] | string;
	toFixed: number | [number, Object?];
	type: string;
	userId: any;
	next: () => void;
	allDataFetched?: boolean;
	tableRealTimeHight: number;
	scrollSyncMode?: boolean;
	onSort: Function;
	forceMin: boolean;
	nextUserId: any;
	editCurrentLineOnlyMode: boolean;
	selectedRowData: any;
	triggerUndo: number;
	lastUpdateRow: any;
}

interface FlexTableStyles {
	numberOfRows: number;
	headerBackgroundColor: string;
	headerColor: string;
	color: string;
	type: string;
	tableRealTimeHight: number;
	disabled: boolean;
}

const useStyles = makeStyles((theme: any) => ({
	superHeader: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: ({ disabled }: FlexTableStyles) =>
			disabled ? 'flex-start' : 'space-between',
		alignItems: 'center',
		fontFamily: 'RubikMedium',
		width: 'auto',
		whiteSpace: 'nowrap',
		padding: '8px',
		height: '36px',
		backgroundColor: colors.white,
		color: ({ headerColor }: any) => headerColor,
	},
	superHeaderTitle: {
		maxWidth: 'calc(100% - 60px)',
		width: '100%',
		clear: 'both',
		display: 'inline-block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	superHeaderIcons: {
		width: '50px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 5px',
	},
	editable: {
		background: 'none',
		border: 'none',
		cursor: 'pointer',
		padding: 0,
		margin: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': { opacity: 0.8 },
	},
	tableHeader: {
		height: '100%',
		width: '100%',
		position: 'relative',
		zIndex: 100,
	},
	tableBody: {
		marginTop: '-60px',
		height: ({ tableRealTimeHight }) => tableRealTimeHight,
		width: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	container: {
		display: 'grid',
		borderRight: `1px solid ${colors.filterBorder}`,
		gridTemplateColumns: ({ numberOfRows }: FlexTableStyles) =>
			`repeat(${numberOfRows}, auto)`,
		fontSize: '14px',
		direction: 'rtl',
		minWidth: 130,
	},
	bodyContainer: {
		display: 'grid',
		borderRight: `1px solid ${colors.filterBorder}`,
		gridTemplateColumns: ({ numberOfRows }: FlexTableStyles) =>
			`repeat(${numberOfRows}, auto)`,
		fontSize: '14px',
		direction: 'rtl',
		paddingTop: ({ type }) => (type === 'table' ? 60 : 0),
	},
}));

const headers = {
	WAGE: [
		{
			type: 'text',
			placeholder: '',
			name: 'neto',
			value: ({ data }: any) =>
				data
					? data.net
						? 'FromMonthToMonthReports.formats.WageComponent.net'
						: 'FromMonthToMonthReports.formats.WageComponent.gross'
					: 'FromMonthToMonthReports.formats.WageComponent.netGross',
		},
		{
			type: 'input',
			placeholder: '0.00',
			name: 'amount',
			value: 'FromMonthToMonthReports.formats.WageComponent.amount',
		},
		{
			type: 'input',
			placeholder: '0.00',
			name: 'rate',
			value: 'FromMonthToMonthReports.formats.WageComponent.rate',
		},
		{
			type: 'input',
			placeholder: '0.00',
			name: 'value',
			value: 'FromMonthToMonthReports.formats.WageComponent.sum',
		},
	],
	OPTIONAL_DEDUCTION: [
		{
			type: 'input',
			placeholder: '0.00',
			name: 'value',
			value: (item: any) => `${item.name} - ${item.code}`,
		},
	],
	ADDITIONAL: [
		{
			type: 'input',
			placeholder: '0.00',
			name: 'payworkdays',
			value: 'FromMonthToMonthReports.formats.AdditionalData.payworkdays',
		},
		{
			type: 'input',
			placeholder: '0.00',
			name: 'workdays',
			value: 'FromMonthToMonthReports.formats.AdditionalData.workdays',
		},
		{
			type: 'input',
			placeholder: '0.00',
			name: 'workingHours',
			value: 'FromMonthToMonthReports.formats.AdditionalData.workingHours.short',
		},
		{
			type: 'input',
			placeholder: '0.00',
			name: 'vacationUsed',
			value: 'FromMonthToMonthReports.formats.AdditionalData.vacationUsed',
		},
		{
			type: 'input',
			placeholder: '0.00',
			name: 'sickLeaveUsed',
			value: 'FromMonthToMonthReports.formats.AdditionalData.sickLeaveUsed',
		},
	],
};

const types = {
	WAGE: 'wageComponent',
	OPTIONAL_DEDUCTION: 'optionalDeductionsComponent',
	ADDITIONAL: 'headerAdditionalData',
};

const FlexTable = ({
	data,
	tableName,
	disabled,
	isLoading,
	headerBackgroundColor = colors.primary,
	headerColor = colors.white,
	color = colors.darkGrey,
	onBlur,
	onPreesEdit,
	onPress,
	onPreesRemove,
	maxLength,
	keyboardType,
	toFixed,
	type,
	userId,
	tableRealTimeHight,
	onSort,
	forceMin,
	nextUserId,
	editCurrentLineOnlyMode,
	selectedRowData,
	triggerUndo,
	lastUpdateRow,
}: FlexTableProps) => {
	const numberOfRowsFun = (type: string, data: any): number => {
		if (type === 'table') {
			return headers[data?.componentType].length;
		}

		return 1;
	};
	const numberOfRows = numberOfRowsFun(type, data);
	const [activeSort, setActiveSort] = useState({ name: '', direction: '' });

	const classes = useStyles({
		numberOfRows,
		headerBackgroundColor,
		headerColor,
		color,
		type,
		tableRealTimeHight,
		disabled,
	});

	return (
		<div className={classes.tableContainer}>
			<div className={classes.superHeader}>
				<div></div>
				<div className={classes.superHeaderTitle}>
					<I18n color={'darkGrey'} weight='normal' size={18}>
						{tableName}
					</I18n>
				</div>
				{!disabled && (
					<div className={classes.superHeaderIcons}>
						<button
							className={classes.editable}
							onClick={() =>
								onPreesEdit({
									...data,
									componentType: types[data.componentType],
								})
							}
							tabIndex={-1}
						>
							<ImageIcon width={16} height={16} name={'edit'} />
						</button>
						<button
							className={classes.editable}
							onClick={() => onPreesRemove(data)}
							tabIndex={-1}
						>
							<ImageIcon width={16} height={16} name={'remove'} />
						</button>
					</div>
				)}
			</div>

			<>
				<div className={classes.tableHeader}>
					<div className={classes.container}>
						{data && (
							<HeaderRow
								header={headers[data.componentType]}
								headerBackgroundColor={headerBackgroundColor}
								headerColor={headerColor}
								color={color}
								onSort={onSort}
								type={type}
								componentType={data?.componentType}
								activeSort={activeSort}
								setActiveSort={setActiveSort}
								name={data.name}
								code={data.code}
							/>
						)}
					</div>
				</div>
				<ScrollSyncPane>
					<div className={classes.tableBody}>
						<div className={classes.bodyContainer}>
							{data &&
								data.employees.map(
									(emp: any, index: number) => (
										<FlexTableComponent
											key={emp.id}
											index={index}
											data={emp}
											header={headers[data.componentType]}
											code={data.code}
											type={type}
											componentType={data?.componentType}
											tableName={tableName}
											disabled={disabled}
											isLoading={isLoading}
											headerBackgroundColor={
												headerBackgroundColor
											}
											headerColor={headerColor}
											color={color}
											onBlur={onBlur}
											onPress={onPress}
											maxLength={maxLength}
											keyboardType={keyboardType}
											toFixed={toFixed}
											userId={userId}
											forceMin={forceMin}
											editCurrentLineOnlyMode={
												editCurrentLineOnlyMode
											}
											nextUserId={nextUserId}
											name={data.name}
											selectedRowData={selectedRowData}
											triggerUndo={triggerUndo}
											lastUpdateRow={lastUpdateRow}
										/>
									),
								)}
						</div>
					</div>
				</ScrollSyncPane>
			</>
		</div>
	);
};

export default React.memo(FlexTable);
