import { useNavigation, useRoute } from '@react-navigation/native';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBusinessUnitsConfig } from '../store/selectors/login.selectors';
import { getBusinessUnitsListByPermissions } from '../store/selectors/reportsFilters.selectors';

const useBusinessUnitsState = ({
	privileges,
	setFilters,
	onChangeFilter,
	defaultUnit,
	forceSingle,
	noRoute,
	forceChangeOnlyInOnPress = false,
}: {
	privileges: string[];
	setFilters?: Function;
	onChangeFilter?: Function;
	defaultUnit?: number[];
	noRoute?: boolean;
	forceSingle?: boolean;
	forceChangeOnlyInOnPress?: boolean;
}) => {
	const route = noRoute ? {} : useRoute();
	const navigation = noRoute ? {} : useNavigation();

	const businessUnitConfig = useSelector(getBusinessUnitsConfig);
	const businessUnitsList = useSelector((state: any) =>
		getBusinessUnitsListByPermissions(state, privileges),
	);
	const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<
		number[] | undefined
	>(undefined);

	const isMulti = useMemo(
		() => forceSingle || businessUnitConfig.allowMultipleSelection,
		[businessUnitConfig, forceSingle],
	);

	const defaultUnitIds = useMemo(() => {
		let id;
		if (defaultUnit) {
			return defaultUnit;
		} else if (route.params?.unitIds) {
			return Array.isArray(route.params?.unitIds)
				? route.params?.unitIds.length
					? route.params?.unitIds
					: businessUnitsList.map((unit: any) => unit.id)
				: [route.params?.unitIds];
		}
		if (businessUnitsList.length) {
			const ids = businessUnitConfig.defaultUnitIds?.filter(
				(id: number) =>
					businessUnitsList.find((unit: any) => unit.id === id),
			);
			if (ids && ids.length) {
				id = ids;
			} else {
				id = [businessUnitsList?.[0]?.id];
			}
		}

		return id;
	}, [businessUnitConfig.defaultUnitIds, businessUnitsList, isMulti]);

	useEffect(() => {
		if (
			defaultUnitIds?.[0] &&
			(!selectedBusinessUnits ||
				(!_.isEqual(selectedBusinessUnits, defaultUnitIds) &&
					!forceChangeOnlyInOnPress))
		) {
			setSelectedBusinessUnits(defaultUnitIds);
			setFilters?.((state: any) => ({
				...state,
				unitIds: defaultUnitIds,
			}));
			onChangeFilter?.({
				name: 'unitIds',
				value:
					defaultUnit ||
					(isMulti ? defaultUnitIds : defaultUnitIds?.[0]),
				isMulti,
			});
			if (route.params?.unitIds) {
				navigation.setParams({
					unitIds: undefined,
				});
			}
		}
	}, [defaultUnitIds]);

	useEffect(() => {}, [businessUnitsList]);

	const onChangeBusinessUnits = useCallback(({ name, value, isMulti }) => {
		console.log('onChangeBusinessUnits', value);
		setSelectedBusinessUnits(
			isMulti ? value.map((val: any) => val.id) : [value],
		);
	}, []);

	const getSelectedUnits = (units?: number[] | string[]) => {
		return units?.length
			? units
			: businessUnitsList.map((unit: any) => unit.id);
	};

	return {
		businessUnitsList,
		selectedBusinessUnits,
		onChangeBusinessUnits,
		isBusinessUnitsMulti: isMulti,
		multiValue:
			Array.isArray(selectedBusinessUnits) &&
			selectedBusinessUnits?.map(unit =>
				businessUnitsList.find((bunit: any) => bunit.id === unit),
			),
		getSelectedUnits,
	};
};

export default useBusinessUnitsState;
