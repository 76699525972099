import { useNavigation, useRoute } from '@react-navigation/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Dimensions, Platform, useWindowDimensions, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import _ from 'lodash';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import I18n from '../../../../components/shared/i18n/I18n.component';
//import { tabsMap } from '../WorkerCardTabs';
import httpService from '../../../../services/http.service';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../store/selectors/login.selectors';

const TabWrapper = ({
	data,
	onCommentClick,
	onChildCommentClick,
	tabsMap,
	readOnly,
}: {
	data?: any;
	onCommentClick?: any;
	onChildCommentClick?: any;
	readOnly?: boolean;
	tabsMap: any[];
}) => {
	const userData = useSelector(getUserData);
	const { height } = useWindowDimensions();

	const platform = useDevicePlatform();
	const navigation = useNavigation();
	const isMobile = platform !== DeviceType.WEB;
	const isBrowser = platform !== DeviceType.MOBILE;
	const route = useRoute();
	const routeData: any = useMemo(
		() => tabsMap.find(tab => tab.pageRoute === route.name),
		[route, tabsMap],
	);

	const urlParams: any = useMemo(
		() => isBrowser && new URL(document.location).searchParams,
		[isBrowser && document.location],
	);
	const [comments, setComments] = useState<any[]>([]);

	const isEmployee = useMemo(() => route.params?.isEmployee, [route.params]);
	const formId = useMemo(
		() => route.params?.formId || route.params?.params?.formId,
		[route.params],
	);
	const screen = useMemo(
		() =>
			route.params?.screen ||
			route.params?.params?.screen ||
			(isBrowser && urlParams?.get('screen')),
		[route.params?.screen, route.params?.params?.screen, urlParams],
	);
	const toFocus = useMemo(
		() =>
			route.params?.focused ||
			route.params?.params?.focused ||
			(isBrowser && urlParams?.get('screen')),
		[route.params?.focused, route.params?.focused?.screen, urlParams],
	);

	const downloadFile = async (file: any) => {
		const res: string = await httpService.api({
			type: isEmployee ? 'getWorkerCardFile' : 'getWorkerCardFileManager',
			params: { fileId: file.id, formId: formId },
		});
		let module;
		if (platform === DeviceType.WEB || platform === DeviceType.MOBILE_WEB) {
			module = await import('../../../../utils/downloadFile.web.utils');
			module.downloadFile(res, file.fileName || file.name);
		} else {
			module = await import(
				'../../../../utils/downloadFile.moblie.utils'
			);
			module.downloadFile(res, file.fileName || file.name);
		}
	};

	useEffect(() => {
		if (toFocus) {
			setTimeout(() => {
				navigation.setParams({ focused: undefined });
			}, 1000);
		}
	}, [toFocus]);

	const checkIfFieldHasComment = (field: any) => {
		if (routeData.pageRoute === 'otherEmployerIncome') {
			return data
				? data[routeData.pageRoute]?.comment?.find((el: any) => {
					const arr = field?.name?.split('.');
					if (arr[1] === el.fieldName) {
						return true;
					}
				})
				: false;
		}

		switch (field.name) {
			case 'signature': {
				return data?.comment && data?.comment?.length
					? data.comment.find(
						(el: any) => el.fieldName === 'signature',
					)
					: false;
			}
			case 'hasChildren': {
				return data?.comment && data?.comment?.length
					? !!data.comment.find(
						(el: any) => el.fieldName === 'hasChildren',
					)
					: false;
			}
			default: {
				return data
					? data[routeData.pageRoute]?.comment?.find((el: any) =>
						field?.name?.endsWith(el.fieldName),
					)
					: false;
			}
		}
	};
	
	return (
		<View style={{ flex: 1 }}>
			<KeyboardAwareScrollView
				keyboardShouldPersistTaps='handled'
				scrollEnabled={
					routeData?.pageRoute !== 'disclaimer' ||
					Platform.OS === 'web'
				}
				contentContainerStyle={{
					paddingBottom: 70,
				}}
				style={{
					flex: 1,
					paddingHorizontal: 20,
					paddingTop: 19,
					maxHeight: isMobile ? height - 272 : undefined,
					backgroundColor: colors.white,
					borderRadius: isMobile ? 10 : 0,
					...(isMobile ? getShadowStyle(10, '#3b70a4') : {}),
				}}
			>
				{!isMobile && (
					<I18n
						size={20}
						style={{ marginBottom: 22 }}
						weight='normal'
					>{`workerCard.editor.${routeData?.pageRoute}`}</I18n>
				)}
				{isMobile
					? _.flattenDeep(routeData?.fields).map((field: any) => {
						const newField = { ...field }
						if (field.name === 'employee.idFile') {
							newField.fields.forEach((fi) => {
								fi.forEach((i) => {
									if (!userData?.customer_setting?.requestEmployeeIdFile) {
										i = Object.assign(i, { isRequired: false })
									}
								})
							})
						}
						return (
							<FieldBuilder
								key={field.name}
								viewOnly={!isEmployee || routeData?.viewOnly}
								toFocus={toFocus}
								downloadType={
									isEmployee
										? 'getWorkerCardFile'
										: 'getWorkerCardFileManager'
								}
								hasComment={checkIfFieldHasComment(field)}
								extraDownloadParams={{ formId }}
								pageRoute={routeData?.pageRoute}
								onCommentClick={onCommentClick}
								onChildCommentClick={onChildCommentClick}
								downloadFile={downloadFile}
								commentReadOnly={readOnly}
								routeData={
									data
										? data[
										routeData.pageRoute !==
											'disclaimer'
											? routeData.pageRoute
											: 'signature'
										]
										: null
								}
								fullData={data}
								{...newField}
							/>
						)
					})
					: routeData?.fields.map((row: any) => {
						return (
							<View
								style={{
									flexWrap: 'wrap',
									flexDirection:
										!isEmployee || routeData?.viewOnly
											? 'column'
											: 'row-reverse',
									zIndex: 'unset',
								}}
							>
								{row.map((field: any) => {
									const newField = { ...field }
									if (field.name === 'employee.idFile') {
										newField.fields.forEach((fi) => {
											fi.forEach((i) => {
												if (!userData?.customer_setting?.requestEmployeeIdFile) {
													i = Object.assign(i, { isRequired: false })
												}
											})
										})
									}
									return (
										<>
											<FieldBuilder
												key={field.name}
												viewOnly={
													!isEmployee ||
													routeData?.viewOnly
												}
												commentReadOnly={readOnly}
												hasComment={checkIfFieldHasComment(
													field,
												)}
												toFocus={toFocus}
												downloadType={
													isEmployee
														? 'getWorkerCardFile'
														: 'getWorkerCardFileManager'
												}
												downloadFile={downloadFile}
												extraDownloadParams={{
													formId,
												}}
												onCommentClick={
													onCommentClick
												}
												onChildCommentClick={
													onChildCommentClick
												}
												pageRoute={
													routeData.pageRoute
												}
												routeData={
													data
														? data[
														routeData
															.pageRoute
														]
														: null
												}
												fullData={data}
												{...newField}
											/>
										</>
									);
								})}
							</View>
						);
					})}
			</KeyboardAwareScrollView>
		</View>
	);
};

export default TabWrapper;
