import React from 'react';
import {
	View,
	StyleSheet,
	Dimensions,
	ScrollView,
	Platform,
} from 'react-native';
import Button from '../../components/shared/Buttons/Button.component';
import HeaderContainer from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import Multiselect from '../../components/web/Multiselect/Multiselect.container';
import Select from '../../components/web/Select/Select.container';
import colors from '../../config/colors';
import { i18nService } from '../../services/i18n.service';
import { monthsMapFilter } from '../web/SalaryReportPage/salaryReportPage.utils';
import {
	BoxAccessType,
	ManagerDashboardResponse,
} from './DashboardEmployer.container';

const windowHeight = Dimensions.get('window').height;

type DasboardEmployerComponentProps = {
	navigation: any;
	myTableBoxAccess: BoxAccessType;
	mySummaryBoxAccess: BoxAccessType;
	myWorkerBoxAccess: boolean;
	dashboardDataFetched: ManagerDashboardResponse;
	isMulti: boolean;
	businessUnitsList: { id: number; name: string; stringId: string }[];
	selectedBusinessUnits: number[];
	onChangeBusinessUnits: (obj: {
		name: string;
		value: any;
		isMulti: false | true;
		isAllSelected?: boolean;
	}) => void;
};

type MyTablesButtonsType = {
	title: string;
	access: boolean;
	action: () => void;
};

type MySummaryType = MyTablesButtonsType & {
	value: number;
};

const DasboardEmployerComponent = ({
	navigation,
	myTableBoxAccess,
	mySummaryBoxAccess,
	myWorkerBoxAccess,
	dashboardDataFetched,
	isMulti,
	businessUnitsList,
	selectedBusinessUnits,
	onChangeBusinessUnits,
}: DasboardEmployerComponentProps) => {
	const myTablesButtons: MyTablesButtonsType[] = [
		{
			title: 'dashboardEmployer.button.salaryReports',
			access: myTableBoxAccess.isSalaryReportsVisible,
			action: () =>
				navigation.navigate('salaryReports', {
					screen: 'table',
					unitIds: isMulti
						? selectedBusinessUnits
						: selectedBusinessUnits[0],
				}),
		},
		{
			title: 'dashboardEmployer.button.attendanceReports',
			access: myTableBoxAccess.isAttendanceReportsVisible,
			action: () =>
				navigation.navigate('attendanceReports', {
					screen: 'table',
					unitIds: isMulti
						? selectedBusinessUnits
						: selectedBusinessUnits[0],
				}),
		},
		{
			title: 'dashboardEmployer.button.costingReports',
			access: myTableBoxAccess.isCostingReportsVisible,
			action: () =>
				navigation.navigate('costingReports', {
					screen: 'detailed',
					params: {
						unitIds: isMulti
							? selectedBusinessUnits
							: selectedBusinessUnits[0],
					},
				}),
		},
		{
			title: 'dashboardEmployer.button.vocationReports',
			access: myTableBoxAccess.isVocationReportsVisible,
			action: () =>
				navigation.navigate('vacationReports', {
					unitIds: isMulti
						? selectedBusinessUnits
						: selectedBusinessUnits[0],
				}),
		},
	];

	const mySummaryButtons: MySummaryType[] = [
		{
			access: mySummaryBoxAccess.isRequestsSummaryVisible,
			action: () =>
				navigation.navigate('emploeeRequests', {
					unitIds: isMulti
						? selectedBusinessUnits
						: selectedBusinessUnits[0],
				}),
			title: `פניות למעסיק ממתינות לטיפולך`,
			value: dashboardDataFetched.employerRequest,
		},
		{
			access: mySummaryBoxAccess.isReport101SummaryVisible,
			action: () =>
				navigation.navigate('salaryReports', {
					screen: 'table',
					isFromDashboard: dashboardDataFetched?.paychecks?.month,
					unitIds: isMulti
						? selectedBusinessUnits
						: selectedBusinessUnits[0],
				}),
			title: monthsMapFilter[dashboardDataFetched?.paychecks?.month]
				? `עובדים שלא צפו בתלוש לחודש ${
						monthsMapFilter[dashboardDataFetched?.paychecks?.month]
				  }`
				: 'עובדים שלא צפו בתלוש',
			value: dashboardDataFetched.paychecks?.total || 0,
		},
		{
			access: mySummaryBoxAccess.isSalarySummaryVisible,
			action: () =>
				navigation.navigate('reports101', {
					screen: 'table101',
					params: {
						defaultYear: dashboardDataFetched?.employeeForm?.year,
						unitIds: isMulti
							? selectedBusinessUnits
							: selectedBusinessUnits[0],
					},
				}),
			title: dashboardDataFetched?.employeeForm?.year
				? `${i18nService.translate('dashboardEmployer.card.101.year')}${
						dashboardDataFetched?.employeeForm?.year
				  }`
				: i18nService.translate('dashboardEmployer.card.101.noYear'),
			value: dashboardDataFetched.employeeForm?.total || 0,
		},
	];

	return (
		<View>
			<HeaderContainer
				containerStyle={{ backgroundColor: colors.white }}
				navigation={navigation}
				component={WebHeader}
			/>
			{businessUnitsList.length > 1 ? (
				<View
					style={{
						marginTop: 27,
						marginHorizontal: 30,
						marginLeft: 'calc(100% - 330px)',
						zIndex: 1,
						width: 300,
					}}
				>
					{isMulti ? (
						<Multiselect
							config={{
								options: businessUnitsList,
								alwaysRerender: false,
								name: '',
								value: selectedBusinessUnits.map(unit =>
									businessUnitsList.find(
										bunit => bunit.id === unit,
									),
								),
								styles: { width: 300 },
								placeholder: 'businessUnits',
							}}
							onChange={onChangeBusinessUnits}
							styles={{
								control: {
									maxWidth: 300,
								},
							}}
						/>
					) : (
						<Select
							config={{
								hideDefaultOption: true,
								options: businessUnitsList,
								name: '',
								placeholder: 'businessUnits',
							}}
							styles={{
								control: {
									maxWidth: 300,
								},
							}}
							onChange={onChangeBusinessUnits}
							defaultValue={selectedBusinessUnits[0]}
						/>
					)}
				</View>
			) : null}
			<ScrollView
				contentContainerStyle={styles.containerWrapper}
				style={styles.container}
			>
				{myWorkerBoxAccess && (
					<View style={[styles.contentBox, { marginLeft: 20 }]}>
						<View style={styles.contentBoxTitle}>
							<I18n size={22} weight={'bold'} color={'primary'}>
								dashboardEmployer.whatToDo.title
							</I18n>
						</View>
						<Button
							buttonStyle={styles.button}
							onPress={() => {
								navigation.navigate('usersManagment', {
									screen: 'users',
									params: {
										isFromDashboard: true,
									},
								});
							}}
						>
							<I18n size={14} weight={'normal'} color='white'>
								dashboardEmployer.button.addNewEmployee
							</I18n>
						</Button>
					</View>
				)}
				{/* //2 */}
				{mySummaryBoxAccess.isBoxVisible && (
					<View
						style={[
							styles.contentBox,
							styles.summeryBoxesContainer,
						]}
					>
						{mySummaryButtons.map((el: MySummaryType) => {
							if (el.access) {
								return (
									<View
										style={[
											styles.contentBox,
											styles.innerBox,
										]}
									>
										<Typography
											size={14}
											weight={'normal'}
											color='darkGrey'
											style={{
												textAlign: 'center',
												marginBottom: 26,
												height: 28,
											}}
										>
											{el.title}
										</Typography>
										<Typography
											size={56}
											weight={'normal'}
											color='darkGrey'
										>
											{el.value}
										</Typography>
										<View
											style={[
												styles.underline,
												{
													backgroundColor: el.value
														? '#ff6b6b'
														: '#2dac50',
												},
											]}
										></View>
										<Button
											buttonStyle={styles.linkButton}
											onPress={() => el.action()}
										>
											<I18n
												size={14}
												weight={'normal'}
												color='lightPrimary'
											>
												dashboardEmployer.button.innerBox.link
											</I18n>

											<View style={{ marginRight: 6 }}>
												<ImageIcon
													name='back'
													height={8}
													width={8}
												/>
											</View>
										</Button>
									</View>
								);
							}
						})}
					</View>
				)}
				{/* //3 */}
				{myTableBoxAccess.isBoxVisible && (
					<View style={styles.contentBox}>
						<View style={styles.contentBoxTitle}>
							<I18n size={22} weight={'bold'} color={'primary'}>
								dashboardEmployer.myTables.title
							</I18n>
						</View>
						{myTablesButtons.map((el: MyTablesButtonsType) => {
							if (el.access) {
								return (
									<View
										style={styles.contentBoxButtonWrapper}
									>
										<Button
											buttonStyle={styles.button}
											onPress={() => el.action()}
										>
											<I18n
												size={14}
												weight={'normal'}
												color='white'
											>
												{el.title}
											</I18n>
										</Button>
									</View>
								);
							}
						})}
					</View>
				)}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginVertical: 37,
		marginTop: 10,
		marginHorizontal: 30,
		backgroundColor: colors.white,
		height: windowHeight - 200,
		...Platform.select({ web: { zIndex: 'unset' } }),
	},
	containerWrapper: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row-reverse',
		paddingVertical: 56,
		paddingBottom: 50,
		minWidth: 930,
		backgroundColor: colors.white,
		justifyContent: 'center',
		flexWrap: 'wrap',
		...Platform.select({ web: { zIndex: 'unset' } }),
	},
	contentBox: {
		padding: 20,
		backgroundColor: colors.white,
		border: 'solid 3px #dfe8f0',
		borderRadius: 8,
		boxShadow: '0 2px 20px 0 rgba(59, 112, 164, 0.16)',
		height: 357,
		marginBottom: 10,
	},
	summeryBoxesContainer: {
		flexDirection: 'row',
		marginLeft: 20,
	},
	innerBox: {
		height: 248,
		width: 200,
		alignItems: 'center',
		marginHorizontal: 3,
	},
	contentBoxTitle: {
		marginBottom: 32,
	},
	button: {
		alignSelf: 'center',
		height: 45,
		width: 190,
		backgroundColor: colors.lightPrimary,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
	contentBoxButtonWrapper: {
		marginBottom: 10,
	},
	mySummaryButtonsWrapper: {
		marginTop: 35,
		display: 'flex',
		flexDirection: 'row',
		gap: 44,
	},
	underline: {
		width: 89,
		height: 7,
		borderRadius: 5,
		marginTop: 9,
		marginBottom: 20,
	},
	linkButton: {
		alignSelf: 'center',
		height: 19,
		width: 90,
		alignItems: 'center',
		justifyContent: 'center',
	},
});

export default DasboardEmployerComponent;
