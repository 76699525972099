import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import FieldBuilder from '../../../components/shared/FieldBuilder/FieldBuilder';
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import _, { debounce } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { modalService } from '../../../services/modal.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import I18n from '../../../components/shared/i18n/I18n.component';

interface FlexTableProps {
	data?: any;
	header?: any;
	disabled: boolean;
	isLoading: boolean;
	headerBackgroundColor: string;
	headerColor: string;
	color: string;
	onBlur: Function;
	onPress: Function;
	tableName: string;
	maxLength: number;
	keyboardType: [string, string] | string;
	toFixed: number | [number, Object?];
	type: string;
	componentType: string;
	userId: any;
	activeSort: { name: string; direction: string };
	setActiveSort: Function;
	onSort: Function;
	forceMin: boolean;
	index: number;
	nextUserId: any;
	editCurrentLineOnlyMode: boolean;
	code: string;
	name: string;
	mode: string;
	selectedRowData: any;
	triggerUndo: number;
	lastUpdateRow: any;
}
const useStyles = makeStyles((theme: any) => ({
	header: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
		fontFamily: 'RubikMedium',
		whiteSpace: ({ type }: any) => (type === 'table' ? 'normal' : 'nowrap'),
		padding: '8px',
		height: ({ type }: any) => (type === 'table' ? '44px' : '22px'),
		borderLeft: ({ type }: any) =>
			type === 'table'
				? `1px solid ${colors.primary}`
				: `1px solid ${colors.filterBorder}`,
		borderBottom: `1px solid ${colors.filterBorder}`,
		backgroundColor: ({ headerBackgroundColor }: any) =>
			headerBackgroundColor,
		color: ({ headerColor }: any) => headerColor,
		maxWidth: ({ componentType, type }: any) =>
			componentType === 'OPTIONAL_DEDUCTION'
				? 160
				: type === 'table'
				? 69
				: 'auto',
		width: ({ componentType, type }: any) =>
			componentType === 'OPTIONAL_DEDUCTION'
				? 160
				: type === 'table'
				? 69
				: 'auto',
	},
	addNewHeader: {
		borderRadius: '22px 0 0 22px',
	},
	list: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		padding: ({ type }: any) => (type === 'table' ? '5px 8px' : '5px'),
		height: ({ type }: any) => (type === 'table' ? '41px' : '22px'),
		borderLeft: `1px solid ${colors.filterBorder}`,
		borderBottom: `1px solid ${colors.filterBorder}`,
		color: ({ color }: any) => color,
		fontFamily: 'RubikRegular',
		width: ({ componentType, type }: any) =>
			componentType === 'OPTIONAL_DEDUCTION'
				? 160
				: type === 'table'
				? 69
				: 'auto',
	},
	onPressLine: {
		background: 'none',
		border: 'none',
		cursor: ({ type }: any) => (type === 'table' ? 'pointer' : 'auto'),
		padding: 0,
		margin: 0,
		height: '100%',
		width: '100%',
	},
	addNewBtn: {
		background: 'none',
		border: 'none',
		cursor: 'pointer',
		padding: 0,
		margin: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': { opacity: 0.8 },
	},
	headerText: {
		width: '84%',
	},
	iconsBox: {
		display: 'flex',
		flexDirection: 'column',
		width: '16%',
	},
	thBox: {
		display: 'flex',
		alignItems: 'center',
	},
	arrowIcon: {
		fontSize: '1.3rem',
		cursor: 'pointer',
		color: colors.white,
	},
	down: {
		marginTop: -9,
	},
	arrowClicked: {
		color: colors.lightPrimary,
	},
}));

const FlexTableComponent = (props: FlexTableProps) => {
	const {
		data,
		header,
		disabled = false,
		isLoading = false,
		headerBackgroundColor,
		headerColor,
		color,
		onBlur,
		onPress,
		maxLength,
		keyboardType,
		toFixed,
		type,
		componentType,
		userId,
		forceMin,
		index,
		nextUserId,
		editCurrentLineOnlyMode,
		code,
		name,
		selectedRowData,
		triggerUndo,
		lastUpdateRow,
	} = props;

	const [initialValues, setInitialValues] = useState(data);
	const preInitialValues = useRef();
	const dinamicSchema = useMemo(
		() =>
			yup.lazy((): any => {
				const shapes: any = {};

				return yup.object().shape(shapes);
			}),
		[data, componentType],
	);

	useEffect(() => {
		if (!_.isEqual(data, initialValues)) {
			setInitialValues(data);
		}
	}, [data]);

	useEffect(() => {
		if (lastUpdateRow?.current === data.id) {
			setInitialValues(preInitialValues.current);
			formMethods.reset(preInitialValues.current);
		}
	}, [triggerUndo]);

	const _onBlur = useCallback(() => {
		onBlur(
			formMethods,
			{
				componentType,
				code,
				name,
			},
			initialValues,
			onBlurCallback,
			selectedRowData,
		);
	}, [initialValues, selectedRowData, componentType, code, name, data]);

	const onBlurCallback = (values: any) => {
		const tempState = { ...initialValues };
		Object.keys(tempState).forEach(key =>
			tempState[key] === null ? (tempState[key] = '') : tempState[key],
		);
		preInitialValues.current = tempState;

		setInitialValues(values);
		formMethods.reset(values);
	};

	const formMethods = useForm({
		defaultValues: data,
		mode: 'onBlur',
		reValidateMode: 'onChange',
		resolver: yupResolver(dinamicSchema),
	});

	return (
		<>
			<FormProvider {...formMethods}>
				{header?.map((item: any) => (
					<FlexTableItem
						key={`${item.name}${data.id}`}
						isNextUser={
							nextUserId === data.id && editCurrentLineOnlyMode
						}
						isCurrentUser={userId === data.id}
						isNotCurrentUser={
							editCurrentLineOnlyMode && userId !== data.id
						}
						item={{ ...item, order: index }}
						onPress={onPress}
						data={data}
						triggerUndo={triggerUndo}
						{...{
							disabled,
							isLoading,
							headerBackgroundColor,
							headerColor,
							color,
							onBlur: _onBlur,
							maxLength,
							keyboardType,
							toFixed,
							type,
							componentType,
							forceMin,
							index,
						}}
					/>
				))}
			</FormProvider>
		</>
	);
};

const FlexTableItem = React.memo(
	(props: any) => {
		const {
			disabled = false,
			isLoading = false,
			headerBackgroundColor,
			headerColor,
			color,
			onBlur,
			onChange,
			onPress,
			maxLength,
			keyboardType,
			toFixed,
			type,
			data,
			componentType,
			forceMin,
			index,
			item,
			isNextUser,
			isNotCurrentUser,
			isCurrentUser,
		} = props;
		const errorLine = useRef(false);

		const classes = useStyles({
			headerBackgroundColor,
			headerColor,
			color,
			type,
			componentType,
		});

		const UpdateTheFormState = useCallback(
			async (
				setValue: any,
				getValues: any,
				newValue: any,
				d: any,
				trigger: any,
				f: any,
				name: string,
			) => {
				const data: any = getValues();
				if (newValue === undefined) {
					setValue(name, '');
				}
				// take the form field name by onChange and trigger it for the Yup validations.

				if (name === 'amount' || name === 'rate') {
					// chack if amount or rate are set, and user try write something in the sum field, make sum value equal to 0 and return error.
					if (newValue) {
						if (
							data.hasOwnProperty('value') &&
							Number(data.value) !== 0 &&
							data.value
						) {
							setValue(name, '');
							if (!errorLine.current) {
								errorLine.current = true;
								await removeItem(
									'FromMonthToMonthReports.validations.form.amount.rate.msg',
								);
							}
						}

						// chack if sum are set, and user try write something in amount or rate fields, make amout and rate values equal to '' and return error.
					}
				} else if (name === 'value' && newValue !== 0) {
					if (
						(data.hasOwnProperty('amount') &&
							Number(data.amount) !== 0 &&
							data.amount) ||
						(data.hasOwnProperty('rate') &&
							Number(data.rate) !== 0 &&
							data.rate)
					) {
						setValue(name, '');
						if (!errorLine.current) {
							errorLine.current = true;
							await removeItem(
								'FromMonthToMonthReports.validations.form.sum.msg',
							);
						}
					}
				}
			},
			[errorLine.current],
		);

		const removeItem = useCallback(
			async (message: any) => {
				await modalService.openModal(
					null,
					{
						onSubmit: async (callback: Function) => {
							callback((errorLine.current = false));
						},
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n style={{ textAlign: 'center' }} size={18}>
								{message}
							</I18n>
						</AlertModal>
					),
				);
			},
			[errorLine.current],
		);

		const _onChange = useCallback(
			debounce((...args) => {
				UpdateTheFormState(...args);
			}, 300),
			[onChange],
		);

		return (
			<button
				key={`${item.id}${item.name}`}
				onClick={() => onPress(item.order)}
				tabIndex={isNextUser ? 0 : -1}
				onFocus={() => (isNextUser ? onPress(item.order) : undefined)}
				className={classes.onPressLine}
			>
				<div
					className={classes.list}
					style={{
						backgroundColor: isCurrentUser
							? colors.selectRowColor
							: index % 2 === 0
							? disabled
								? colors.tableDisableBackgroundOpacity
								: colors.tableBackgroundOpacity
							: colors.white,
					}}
				>
					{item.type === 'input' ? (
						<FieldBuilder
							styles={{
								wrapper: {
									width: '100%',
									height: '100%',
									border: 'none',
									fontSize: '14px',
									margin: 0,
									padding: type === 'table' ? 8 : 0,
								},
								labelWrapper: {
									height: 0,
									marginTop: 0,
									marginRight: 0,
									marginLeft: 0,
									marginBottom: 0,
									padding: 0,
								},
								errorCustomStyle: {
									backgroundColor: colors.white,
									position: 'relative',
									maxWidth: 900,
									zIndex: 999,
									whiteSpace: 'nowrap',
									top: -3,
								},
							}}
							height={'100%'}
							backgroundColor={
								disabled ? colors.opacity : colors.lightBlue
							}
							padding={0}
							key={item.name}
							name={item.name}
							disabled={isNotCurrentUser || isLoading || disabled}
							toFocus={disabled}
							type='input'
							placeholder={item.placeholder}
							onBlur={onBlur}
							onChange={_onChange}
							maxLength={maxLength}
							keyboardType={
								Array.isArray(keyboardType)
									? keyboardType[1] === item.name ||
									  item.name.includes(keyboardType[1])
										? undefined
										: keyboardType[0]
									: keyboardType
							}
							forceMin={forceMin}
							toFixed={
								Array.isArray(toFixed)
									? item.name.includes(
											Object.keys(toFixed[1])[0],
									  )
										? Object.values(toFixed[1])[0]
										: toFixed[0]
									: toFixed
							}
						/>
					) : item.title ? (
						i18nService.translate(item.title)
					) : item.value ? (
						i18nService.translate(
							typeof item.value === 'function'
								? item.value({ data })
								: item.value,
						)
					) : item.placeholder ? (
						i18nService.translate(item.placeholder)
					) : (
						'null'
					)}
				</div>
			</button>
		);
	},
	(prev, next) => {
		if (!_.isEqual(_.omit(prev), _.omit(next))) {
			return false;
		}

		return true;
	},
);

export default React.memo(FlexTableComponent);
