export const INCREMENT_BUSY_COUNTER = 'INCREMENT_BUSY_COUNTER';
export const DECREMENT_BUSY_COUNTER = 'DECREMENT_BUSY_COUNTER';
export const RESET_BUSY_COUNTER = 'RESET_BUSY_COUNTER';
export const RESET_NAVIGATE_TO = 'RESET_NAVIGATE_TO';
export const SET_NAVIGATE_TO = 'SET_NAVIGATE_TO';
export const SET_HIDE_CHAT = 'SET_HIDE_CHAT';
export const SET_DEFAULT_DISPLAY_MODE = 'SET_DEFAULT_DISPLAY_MODE';
export const SET_101_FILES = 'SET_101_FILES';
export const SET_DEFAULT_DISPLAY_WAGE_MODE = 'SET_DEFAULT_DISPLAY_WAGE_MODE';
export const SET_IS_MAINTENANCE = 'SET_IS_MAINTENANCE';
