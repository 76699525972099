export type fromMonthToMonthListLike = {
	hasNext: boolean;
	total: number;
	data: fromMonthToMonthList[];
	yearMonth: string;
};

export class fromMonthToMonthList {
	id: number;
	fullName: string;
	statusCode: string;
	status: string;
	departmentId: number;
	departmentNumericId: number;
	department: string | null;
	teudatZeut: string;
	employeeCreateDate: string;
	lastUpdate: string;
	changed: boolean;
	draftId: number;

	constructor(f: any) {
		this.id = f.id;
		this.fullName = f.fullName;
		this.statusCode = f.statusCode;
		this.status = f.status;
		this.departmentId = f.departmentId;
		this.departmentNumericId = f.department?.numericId;
		this.department = f.department;
		this.teudatZeut = f.teudatZeut;
		this.employeeCreateDate = f.employeeCreateDate;
		this.lastUpdate = f.lastUpdate;
		this.changed = f.changed;
		this.draftId = f.draftId;
	}
}

export class fromMonthToMonthListComprehensive {
	id: number;
	fullName: string;
	department: string | null;
	teudatZeut: string;
	draftId: number;
	status: string;
	changed: boolean;
	lastUpdate: string;
	workdays: number;
	payworkdays: number;
	workingHours: number;
	vacationUsed: number;
	sickLeaveUsed: number;

	constructor(f: any) {
		this.id = f.id;
		this.fullName = f.fullName;
		this.status = f.status;
		this.department = f.department;
		this.teudatZeut = f.teudatZeut;
		this.lastUpdate = f.lastUpdate;
		this.changed = f.changed;
		this.draftId = f.draftId;
		this.workdays = f.workdays;
		this.payworkdays = f.payworkdays;
		this.workingHours = f.workingHours;
		this.vacationUsed = f.vacationUsed;
		this.sickLeaveUsed = f.sickLeaveUsed;
	}
}
