import { i18nService } from '../../../../services/i18n.service';
import * as yup from 'yup';
import moment from 'moment';

const fileArrayFieldBuilder = (
	name: string,
	watchName: string,
	label?: string,
) => ({
	type: 'array',
	name,
	shouldDisplay: watchName
		? () => ({
				watch: [watchName],
				callback: (values: any) => values[0],
		  })
		: null,
	getDefautValue: (getValues: Function) => ({
		name: '',
		extraData: null,
	}),
	maxLength: 3,
	fieldWrapperStyle: (isMobile: boolean) => ({
		width: !isMobile ? 'unset' : '100%',
		marginBottom: isMobile ? 30 : 0,
	}),
	arrayWrapperStyle: (isMobile: boolean) => ({
		flexDirection: !isMobile ? 'row-reverse' : 'column',
		alignItems: !isMobile ? 'center' : 'flex-end',
		width: '100%',
	}),
	fields: [
		[
			{
				name: 'extraData',
				type: 'uploadFile',
				label: ({ index }: any) =>
					!index && label
						? i18nService.translate(label)
						: `${i18nService.translate('general.file')} ${
								index + 1
						  }`,
				fieldWrapperStyle: (isMobile: boolean) => ({
					maxWidth: isMobile ? '100%' : 318,
				}),
				isRequired: ({ index }: any) =>
					!index && name !== 'taxCreditReason.spouseHasNoIncomeFile',
			},
		],
	],
});

export const taxCreditReasonFields = (taxCreditAddressOptions: any[]) => {
	return {
		pageRoute: 'taxCreditReason',
		fields: [
			[
				{
					name: 'taxCreditReason.israeliResident',
					type: 'buttonsGroup',
					label: 'workerCard.editor.taxCreditReason.israeliResident',
					options: [
						{ label: 'general.true', value: true },
						{ label: 'general.false', value: false },
					],
					isRequired: true,
					disabled: true,
				},
			],
			[
				{
					name: 'taxCreditReason.handicapped',
					type: 'inlineCheckbox',
					label: 'workerCard.editor.taxCreditReason.handicapped',
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.handicappedFile', [{}]);
						} else {
							setValue('taxCreditReason.handicappedFile', []);
							setValue('taxCreditReason.handicappedCompensation', false);
							setValue('taxCreditReason.handicappedCompensationFile', []);
						}
					},
				},
				fileArrayFieldBuilder(
					'taxCreditReason.handicappedFile',
					'taxCreditReason.handicapped',
				),
			],
			[
				{
					name: 'taxCreditReason.handicappedCompensation',
					type: 'inlineCheckbox',
					label: 'workerCard.editor.taxCreditReason.handicappedCompensation',
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.handicappedCompensationFile', [{}]);
						} else {
							setValue('taxCreditReason.handicappedCompensationFile', []);
						}
					},
					shouldDisabled: () => ({
						watch: ['taxCreditReason.handicapped'],
						callback: (values: any) => values[0] == false,
					}),
				},
				fileArrayFieldBuilder(
					'taxCreditReason.handicappedCompensationFile',
					'taxCreditReason.handicappedCompensation',
				),
			],
			[
				{
					name: 'taxCreditReason.taxCreditAddress',
					type: 'inlineCheckbox',
					label: 'workerCard.editor.taxCreditReason.taxCreditAddress',
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.taxCreditAddressFile', [
								{},
							]);

							const address = getValues().employee.address.city;
							const addressOption = taxCreditAddressOptions.find(
								(el: any) => el.name === address,
							);
							if (addressOption) {
								setValue(
									'taxCreditReason.taxCreditAddressCode',
									addressOption.id,
								);
								setValue(
									'taxCreditReason.taxCreditAddressName',
									addressOption.name,
								);
							} else {
								setValue(
									'taxCreditReason.taxCreditAddressCode',
									undefined,
								);
								setValue(
									'taxCreditReason.taxCreditAddressName',
									undefined,
								);
							}
						} else {
							setValue(
								'taxCreditReason.taxCreditAddressFile',
								[],
							);
							setValue(
								'taxCreditReason.taxCreditAddressCode',
								undefined,
							);
							setValue(
								'taxCreditReason.taxCreditAddressName',
								undefined,
							);
							setValue(
								'taxCreditReason.taxCreditAddressFromDate',
								undefined,
							);
						}
					},
				},
			],
			[
				{
					name: 'taxCreditReason.taxCreditAddressCode',
					type: 'reactselect',
					label:
						'workerCard.editor.taxCreditReason.taxCreditAddressCode',
					isRequired: true,
					options: taxCreditAddressOptions,
					placeholder:
						'workerCard.editor.taxCreditReason.taxCreditAddressCode.placeholder',
					shouldDisplay: () => ({
						watch: ['taxCreditReason.taxCreditAddress'],
						callback: (values: any) => values[0],
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
						prevValue: any,
						trigger: Function,
					) => {
						if (selectedValue) {
							const address = taxCreditAddressOptions.find(
								(el: any) => el.id === selectedValue,
							);
							if (address) {
								setValue(
									'taxCreditReason.taxCreditAddressName',
									address.name,
								);
							} else {
								setValue(
									'taxCreditReason.taxCreditAddressName',
									undefined,
								);
							}
						} else {
							setValue(
								'taxCreditReason.taxCreditAddressName',
								undefined,
							);
						}
						trigger('taxCreditReason.taxCreditAddressCode');
					},
				},
				{
					name: 'taxCreditReason.taxCreditAddressName',
					type: 'input',
					label:
						'workerCard.editor.taxCreditReason.taxCreditAddressName',
					noDisplay: true,
				},
				{
					name: 'taxCreditReason.taxCreditAddressFromDate',
					type: 'datepicker',
					placeholder: 'general.datePickerPlaceholder',
					maxDate: new Date(),
					isRequired: true,
					label:
						'workerCard.editor.taxCreditReason.taxCreditAddressFromDate',
					shouldDisplay: () => ({
						watch: ['taxCreditReason.taxCreditAddress'],
						callback: (values: any) => values[0],
					}),
				},
				fileArrayFieldBuilder(
					'taxCreditReason.taxCreditAddressFile',
					'taxCreditReason.taxCreditAddress',
				),
			],
			[
				{
					name: 'taxCreditReason.immigrant',
					type: 'inlineCheckbox',
					label: 'workerCard.editor.taxCreditReason.immigrant',
					tooltip:
						'workerCard.editor.taxCreditReason.immigrant.tooltip',
					shouldDisabled: () => ({
							watch: ['employee.israeliResident'],
							callback: (values: any) => values[0] == false,
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.immigrantFile', [{}]);
						} else {
							setValue('taxCreditReason.immigrantFile', []);
							setValue(
								'taxCreditReason.immigrantFromDate',
								undefined,
							);
							setValue('taxCreditReason.noIncome', false);
							setValue(
								'taxCreditReason.noIncomeUntilDate',
								undefined,
							);
						}
					},
				},
				{
					name: 'taxCreditReason.immigrantFromDate',
					type: 'datepicker',
					placeholder: 'general.datePickerPlaceholder',
					isRequired: true,
					maxDate: new Date(),
					label:
						'workerCard.editor.taxCreditReason.immigrantFromDate',
					shouldDisplay: () => ({
						watch: ['taxCreditReason.immigrant'],
						callback: (values: any) => values[0],
					}),
				},
				fileArrayFieldBuilder(
					'taxCreditReason.immigrantFile',
					'taxCreditReason.immigrant',
				),
			],
			[
				{
					name: 'taxCreditReason.noIncome',
					type: 'inlineCheckbox',
					label: 'workerCard.editor.taxCreditReason.noIncome',
					tooltip: [
						'workerCard.editor.taxCreditReason.noIncome.tooltip1',
						'workerCard.editor.taxCreditReason.noIncome.tooltip2',
					],
					shouldDisplay: () => ({
						watch: ['taxCreditReason.immigrant'],
						callback: (values: any) => values[0],
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (!selectedValue) {
							setValue(
								'taxCreditReason.noIncomeUntilDate',
								undefined,
							);
						}
					},
				},
				{
					name: 'taxCreditReason.noIncomeUntilDate',
					type: 'datepicker',
					placeholder: 'general.datePickerPlaceholder',
					maxDate: new Date(),
					label:
						'workerCard.editor.taxCreditReason.noIncomeUntilDate',
					shouldDisplay: () => ({
						watch: ['taxCreditReason.noIncome'],
						callback: (values: any) => values[0],
					}),
				},
			],
			[
				{
					name: 'taxCreditReason.spouseHasNoIncome',
					type: 'inlineCheckbox',
					label:
						'workerCard.editor.taxCreditReason.spouseHasNoIncome',
					shouldDisabled: () => ({
						watch: ['employee.maritalStatus'],
						callback: (values: any) => values[0] !== 'MARRIED',
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.spouseHasNoIncomeFile', [
								{},
							]);
						} else {
							setValue(
								'taxCreditReason.spouseHasNoIncomeFile',
								[],
							);
						}
					},
				},
				fileArrayFieldBuilder(
					'taxCreditReason.spouseHasNoIncomeFile',
					'taxCreditReason.spouseHasNoIncome',
				),
			],
			[
				{
					name: 'taxCreditReason.singleParentLivingSeparately',
					type: 'inlineCheckbox',
					label:
						'workerCard.editor.taxCreditReason.singleParentLivingSeparately',
					shouldDisabled: () => ({
						watch: ['employee.maritalStatus', 'children'],
						callback: (values: any) =>
							values[0] === 'MARRIED' ||
							!values[1]?.length ||
							!values[1].find(
								(child: any) =>
									child.myCustody && child.childAllowance,
							),
					}),
				},
			],
			[
				{
					name: 'taxCreditReason.childrenNotInMyCustody',
					type: 'inlineCheckbox',
					label:
						'workerCard.editor.taxCreditReason.childrenNotInMyCustody',
					fieldWrapperStyle: (isMobile: boolean) => ({
						maxWidth: isMobile ? '100%' : 450,
					}),
					shouldDisabled: () => ({
						watch: ['children'],
						callback: (values: any) =>
							!values[0]?.length ||
							!values[0].find((child: any) => !child.myCustody),
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue(
								'taxCreditReason.childrenNotInMyCustodyFile',
								[{}],
							);
						} else {
							setValue(
								'taxCreditReason.childrenNotInMyCustodyFile',
								[],
							);
						}
					},
				},
				fileArrayFieldBuilder(
					'taxCreditReason.childrenNotInMyCustodyFile',
					'taxCreditReason.childrenNotInMyCustody',
					'workerCard.editor.taxCreditReason.childrenNotInMyCustodyFile',
				),
			],
			[
				{
					name: 'taxCreditReason.childrenWithDisability',
					type: 'inlineCheckbox',
					label:
						'workerCard.editor.taxCreditReason.childrenWithDisability',

					fieldWrapperStyle: (isMobile: boolean) => ({
						maxWidth: isMobile ? '100%' : 450,
					}),
					shouldDisabled: () => ({
						watch: ['children'],
						callback: (values: any) => {
							return !values[0]?.length;
						},
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue(
								'taxCreditReason.numberOfChildrenWithDisability',
								1,
							);
							setValue(
								'taxCreditReason.childrenWithDisabilityFile',
								[{}],
							);
						} else {
							setValue(
								'taxCreditReason.numberOfChildrenWithDisability',
								undefined,
							);
							setValue(
								'taxCreditReason.childrenWithDisabilityFile',
								[],
							);
						}
					},
				},
				{
					name: 'taxCreditReason.numberOfChildrenWithDisability',
					type: 'input',
					label:
						'workerCard.editor.taxCreditReason.numberOfChildrenWithDisability',
					tooltip:
						'workerCard.editor.taxCreditReason.numberOfChildrenWithDisability.tooltip',
					isRequired: true,
					maxLength: 2,
					shouldDisplay: () => ({
						watch: ['taxCreditReason.childrenWithDisability'],
						callback: (values: any) => values[0],
					}),
				},
				fileArrayFieldBuilder(
					'taxCreditReason.childrenWithDisabilityFile',
					'taxCreditReason.childrenWithDisability',
					'workerCard.editor.taxCreditReason.childrenWithDisabilityFile',
				),
			],
			[
				{
					name: 'taxCreditReason.alimonyPayment',
					type: 'inlineCheckbox',
					label: 'workerCard.editor.taxCreditReason.alimonyPayment',
					fieldWrapperStyle: (isMobile: boolean) => ({
						maxWidth: isMobile ? '100%' : 450,
					}),
					shouldDisabled: () => ({
						watch: ['employee.maritalStatus'],
						callback: (values: any) => values[0] !== 'MARRIED',
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.alimonyPaymentFile', [
								{},
							]);
						} else {
							setValue('taxCreditReason.alimonyPaymentFile', []);
						}
					},
				},
				fileArrayFieldBuilder(
					'taxCreditReason.alimonyPaymentFile',
					'taxCreditReason.alimonyPayment',
					'workerCard.editor.taxCreditReason.alimonyPaymentFile',
				),
			],
			[
				{
					name: 'taxCreditReason.dischargedSoldier',
					type: 'inlineCheckbox',
					label:
						'workerCard.editor.taxCreditReason.dischargedSoldier',
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.serviceFile', [{}]);
						} else {
							setValue('taxCreditReason.serviceFile', []);
							setValue('taxCreditReason.serviceStart', undefined);
							setValue('taxCreditReason.serviceEnd', undefined);
						}
					},
				},
				{
					name: 'taxCreditReason.serviceStart',
					tooltip:
						'workerCard.editor.taxCreditReason.serviceStart.tooltip',
					type: 'datepicker',
					placeholder: 'general.datePickerPlaceholder',
					isRequired: true,
					maxDate: new Date(),
					label: 'workerCard.editor.taxCreditReason.serviceStart',
					shouldDisplay: () => ({
						watch: ['taxCreditReason.dischargedSoldier'],
						callback: (values: any) => values[0],
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
						prevValue: any,
						trigger: Function,
					) => {
						if (
							selectedValue &&
							getValues().taxCreditReason.serviceEnd
						) {
							trigger(['taxCreditReason.serviceEnd']);
						}
					},
				},
				{
					name: 'taxCreditReason.serviceEnd',
					tooltip:
						'workerCard.editor.taxCreditReason.serviceEnd.tooltip',
					type: 'datepicker',
					placeholder: 'general.datePickerPlaceholder',
					isRequired: true,
					maxDate: new Date(),
					label: 'workerCard.editor.taxCreditReason.serviceEnd',
					shouldDisplay: () => ({
						watch: ['taxCreditReason.dischargedSoldier'],
						callback: (values: any) => values[0],
					}),
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
						prevValue: any,
						trigger: Function,
					) => {
						if (
							selectedValue &&
							getValues().taxCreditReason.serviceStart
						) {
							trigger(['taxCreditReason.serviceStart']);
						}
					},
				},
				fileArrayFieldBuilder(
					'taxCreditReason.serviceFile',
					'taxCreditReason.dischargedSoldier',
				),
			],
			[
				{
					name: 'taxCreditReason.academicDegree',
					type: 'inlineCheckbox',
					label: 'workerCard.editor.taxCreditReason.academicDegree',
					onChange: (
						setValue: Function,
						getValues: Function,
						selectedValue: any,
					) => {
						if (selectedValue) {
							setValue('taxCreditReason.academicDegreeFile', [
								{},
							]);
						} else {
							setValue('taxCreditReason.academicDegreeFile', []);
						}
					},
				},
				fileArrayFieldBuilder(
					'taxCreditReason.academicDegreeFile',
					'taxCreditReason.academicDegree',
					'workerCard.editor.taxCreditReason.academicDegreeFile',
				),
			],
		],
	};
};

const arrayVallidaion = (
	testName: string,
	pathName: string,
	watchName: string,
) =>
	yup.array().when(watchName, {
		is: true,
		then: yup.array().of(
			yup.object().shape({
				extraData: yup
					.object()
					.nullable()
					.test(testName, 'general.validation.required', function (
						value,
					) {
						return (
							pathName ===
								'taxCreditReason.spouseHasNoIncomeFile' ||
							this.path !== `${pathName}[0].extraData` ||
							this?.from[0]?.value
						);
					}),
			}),
		),
	});

export const taxCreditReasonVaidation = yup.object().shape({
	israeliResident: yup.boolean().required('general.validation.required'),
	handicapped: yup.boolean().required('general.validation.required'),
	handicappedFile: arrayVallidaion(
		'handicappedFileTest',
		'taxCreditReason.handicappedFile',
		'handicapped',
	),
	handicappedCompensation: yup
		.boolean()
		.when('handicapped', {
			is: true,
			then: yup.boolean().required('general.validation.required')
		}),
	handicappedCompensationFile: arrayVallidaion(
		'handicappedCompensationFileTest',
		'taxCreditReason.handicappedCompensationFile',
		'handicappedCompensation',
	),
	taxCreditAddress: yup.boolean().required('general.validation.required'),
	taxCreditAddressFromDate: yup
		.string()
		.when('taxCreditAddress', {
			is: true,
			then: yup
				.string()
				.matches(
					/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
					'general.validation.invalidValue',
				)
				.required('general.validation.required'),
			otherwise: yup.string(),
		})
		.nullable(),
	taxCreditAddressCode: yup
		.string()
		.when('taxCreditAddress', {
			is: true,
			then: yup.string().required('general.validation.required'),
			otherwise: yup.string(),
		})
		.nullable(),
	taxCreditAddressFile: arrayVallidaion(
		'taxCreditAddressFileTest',
		'taxCreditReason.taxCreditAddressFile',
		'taxCreditAddress',
	),
	immigrant: yup
		.boolean()
		.required('general.validation.required'),
	immigrantFromDate: yup
		.string()
		.when('immigrant', {
			is: true,
			then: yup
				.string()
				.matches(
					/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
					'general.validation.invalidValue',
				)
				.required('general.validation.required'),
			otherwise: yup.string(),
		})
		.nullable(),
	immigrantFile: arrayVallidaion(
		'immigrantFileTest',
		'taxCreditReason.immigrantFile',
		'immigrant',
	),
	noIncome: yup.boolean().required('general.validation.required'),
	noIncomeUntilDate: yup
		.string()
		.matches(
			/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
			'general.validation.invalidValue',
		)
		.nullable(),
	spouseHasNoIncome: yup.boolean().required('general.validation.required'),
	spouseHasNoIncomeFile: arrayVallidaion(
		'spouseHasNoIncomeFileTest',
		'taxCreditReason.spouseHasNoIncomeFile',
		'spouseHasNoIncome',
	),
	singleParentLivingSeparately: yup
		.boolean()
		.required('general.validation.required'),
	childrenNotInMyCustody: yup
		.boolean()
		.required('general.validation.required'),
	childrenNotInMyCustodyFile: arrayVallidaion(
		'childrenNotInMyCustodyFileTest',
		'taxCreditReason.childrenNotInMyCustodyFile',
		'childrenNotInMyCustody',
	),
	childrenWithDisability: yup
		.boolean()
		.required('general.validation.required'),
	numberOfChildrenWithDisability: yup
		.string()
		.when('childrenWithDisability', {
			is: true,
			then: yup
				.string()
				.required('general.validation.required')
				.test(
					'childrenNum',
					'general.validation.invalidValue',
					function (item?: string) {
						let childrenNum = item;
						childrenNum = String(childrenNum).trim();
						const dataValue = this?.from[1].value;

						if (
							!Number(childrenNum) ||
							Number(childrenNum) > dataValue.children?.length
						) {
							return false;
						}
						return true;
					},
				),
			otherwise: yup.string(),
		})
		.nullable(),
	childrenWithDisabilityFile: arrayVallidaion(
		'childrenWithDisabilityFileTest',
		'taxCreditReason.childrenWithDisabilityFile',
		'childrenWithDisability',
	),
	alimonyPayment: yup.boolean().required('general.validation.required'),
	alimonyPaymentFile: arrayVallidaion(
		'alimonyPaymentFileTest',
		'taxCreditReason.alimonyPaymentFile',
		'alimonyPayment',
	),
	dischargedSoldier: yup.boolean().required('general.validation.required'),
	serviceStart: yup
		.string()
		.when('dischargedSoldier', {
			is: true,
			then: yup
				.string()
				.required('general.validation.required')
				.test(
					'serviceStartValidate',
					'workerCard.editor.taxCreditReason.serviceStart.validation',
					function (value?: string) {
						const dataValue = this?.from[0].value;

						if (
							dataValue.serviceEnd &&
							dataValue.serviceEnd !== '' &&
							value &&
							value !== '' &&
							!moment(value, 'DD/MM/YYYY').isBefore(
								moment(dataValue.serviceEnd, 'DD/MM/YYYY'),
							)
						) {
							return false;
						}
						return true;
					},
				),
			otherwise: yup.string(),
		})
		.nullable(),
	serviceEnd: yup
		.string()
		.when('dischargedSoldier', {
			is: true,
			then: yup
				.string()
				.required('general.validation.required')
				.test(
					'serviceEndValidate',
					'workerCard.editor.taxCreditReason.serviceEnd.validation',
					function (value?: string) {
						const dataValue = this?.from[0].value;

						if (
							dataValue.serviceStart &&
							dataValue.serviceStart !== '' &&
							value &&
							value !== '' &&
							!moment(
								dataValue.serviceStart,
								'DD/MM/YYYY',
							).isBefore(moment(value, 'DD/MM/YYYY'))
						) {
							return false;
						}
						return true;
					},
				),
			otherwise: yup.string(),
		})
		.nullable(),
	serviceFile: arrayVallidaion(
		'serviceFileTest',
		'taxCreditReason.serviceFile',
		'dischargedSoldier',
	),
	academicDegree: yup.boolean().required('general.validation.required'),
	academicDegreeFile: arrayVallidaion(
		'academicDegreeFileTest',
		'taxCreditReason.academicDegreeFile',
		'academicDegree',
	),
});
