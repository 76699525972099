import moment from 'moment';
import { convertDateToLocal } from '../utils/convertDateToLocal.utils';

export type FullSalaryReportListLike = {
	hasNext: boolean;
	total: number;
	data: FullSalaryReportList[];
};

export class FullSalaryReportList {
	id: string;
	teudatZeut: string;
	fullName: string;
	departmentName: string;
	salaryMonth: string;
	firstViewedSlipDate: any;
	businessUnitName: string;
	businessUnitId: number;

	constructor(f: any) {
		this.id = f.id;
		this.teudatZeut = f.teudatZeut;
		this.fullName = f.fullName;
		this.departmentName = f.departmentName;
		// this.salaryMonth = moment(f.salaryMonth, 'YYYY/MM').format('YYYY/MM');
		this.salaryMonth = moment(f.salaryMonth, 'YYYY/MM').format('MM/YYYY');
		this.firstViewedSlipDate = this.dateConvertion(f.firstViewedSlipDate);
		this.businessUnitName = f.businessUnitName;
		this.businessUnitId = f.businessUnitId
	}

	private dateConvertion(date: any) {
		if (date === null) return '';
		return convertDateToLocal(date).format('DD/MM/YYYY');
	}
}
