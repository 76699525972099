import React from 'react';
import { Dimensions, Platform, View, Text } from 'react-native';
import { WebView } from 'react-native-web-webview';
import { WebView as NativeWebView } from 'react-native-webview';
import { useSelector } from 'react-redux';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { isLoggedIn } from '../../../store/selectors/login.selectors';

const Chat = () => {
	const loggedIn = useSelector(isLoggedIn);
	const platform = useDevicePlatform();

	return (
		<View
			style={{
				width:
					platform === DeviceType.WEB
						? 500
						: Dimensions.get('screen').width - 30,
				height:
					platform === DeviceType.WEB
						? 600
						: Dimensions.get('screen').height - 200,
				position: 'absolute',
				right: platform === DeviceType.WEB ? 0 : 20,
				bottom: platform === DeviceType.WEB ? 25 : 110,
			}}
		>
			{Platform.OS === 'web' ? (
				<WebView
					originWhitelist={['*']}
					source={{
						uri: loggedIn
							? 'https://app.glassix.com/Clients/widget-in-iframe.html?apiKey=5da0be59-8a3f-47af-9a65-94fa2a1c935e&snippetId=EP6v3BjaV0l0Z7USzhNV'
							: 'https://app.glassix.com/Clients/widget-in-iframe.html?apiKey=5da0be59-8a3f-47af-9a65-94fa2a1c935e&snippetId=ho7Lzv5qNpHDtM2JDGAH',
					}}
					scrollEnabled={true}
				/>
			) : (
				<NativeWebView
					// scalesPageToFit={true}
					automaticallyAdjustContentInsets={true}
					originWhitelist={['*']}
					containerStyle={{
						width: '100%',
						height: '100%',
						backgroundColor: 'red',
					}}
					onError={syntheticEvent => {
						const { nativeEvent } = syntheticEvent;
						console.warn('WebView error: ', nativeEvent);
					}}
					source={{
						uri: loggedIn
							? 'https://app.glassix.com/Clients/widget-in-iframe.html?apiKey=5da0be59-8a3f-47af-9a65-94fa2a1c935e&snippetId=EP6v3BjaV0l0Z7USzhNV'
							: 'https://app.glassix.com/Clients/widget-in-iframe.html?apiKey=5da0be59-8a3f-47af-9a65-94fa2a1c935e&snippetId=ho7Lzv5qNpHDtM2JDGAH',
					}}
				/>
			)}
		</View>
	);
};

export default Chat;
