import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import httpService from '../../services/http.service';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
} from '../../store/actions/config.actions';
import {
	getEmployer,
	getUserData,
} from '../../store/selectors/login.selectors';
import { dispatch } from '../../store/store';
import PaycheckPage from './PaycheckPage.component';
import { BusinessUnit } from './PaycheckPage.utils';
import PaycheckPageWeb from './PaycheckPageWeb.component';

const PaycheckPageContainer = ({ navigation }: { navigation: any }) => {
	const [paychecksRange, setPaychecksRange] = useState({
		first: '',
		last: '',
	});
	const [isErrorPaycheck, setIsErrorPaycheck] = useState(false);

	const [itIsNotFirstInitComponent, setItIsNotFirstInitComponent] =
		useState(false);
	const [isSecondRequestCompleted, setIsSecondRequestCompleted] =
		useState(false);
	const [isFirstRequestCompleted, setIsFirstRequestCompleted] =
		useState(false);
	const [businessUnitsList, setBusinessUnitsList] = useState<BusinessUnit[]>(
		[],
	);
	const [selectedOptions, setSelectedOptions] = useState<{
		selectedBusinessUnit: BusinessUnit | null;
		selectedPaycheckDate: string;
	} | null>(null);

	const userData = useSelector(getUserData);

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			loadPayCheckRange();
			setItIsNotFirstInitComponent(true);
		});

		return unsubscribe;
	}, [navigation]);

	useEffect(() => {
		if (itIsNotFirstInitComponent) {
			loadPayCheckRange();
		}
	}, [userData]);

	const loadPayCheckRange = () => {
		if (!isInMobileView) {
			dispatch(incrementBusyIndicator);
		}
		httpService
			.api({
				type: 'getPeychecksRange',
			})
			.then(async (res: any) => {
				if (res) {
					res.first &&
						res.first === paychecksRange.first &&
						res.last === paychecksRange.last &&
						onSelectPaycheckDateHandler(`${res.last}-01`);
					setPaychecksRange(
						res?.first ? res : { first: '', last: '' },
					);
				}
			})
			.catch(err =>
				setPaychecksRange({
					first: '',
					last: '',
				}),
			)
			.finally(() => {
				setIsFirstRequestCompleted(true);
			});
	};

	useEffect(() => {
		if (
			isFirstRequestCompleted &&
			!paychecksRange.last &&
			!paychecksRange.first
		) {
			dispatch(decrementBusyIndicator);
		}
	}, [paychecksRange, isFirstRequestCompleted]);

	useEffect(() => {
		onMount();
	}, []);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('Paycheck');
		}
	};
	const getBussinesUnitsByDate = async (date: string) => {
		try {
			const res: {
				list: BusinessUnit[];
				data: any;
			} = await httpService.api({
				type: 'getPaychecksBusinessUnits',
				params: {
					year: moment(date).format('YYYY'),
					month: moment(date).format('MM'),
				},
			});
			setSelectedOptions({
				selectedBusinessUnit: res?.list?.length ? res.list[0] : null,
				selectedPaycheckDate: date,
			});
			setBusinessUnitsList(res.list);
		} catch (e) {
			console.log(e);
		}
	};
	const onSelectPaycheckDateHandler = (date: string) => {
		getBussinesUnitsByDate(date);
	};

	const onChangeBusinessUnit = (id: string) => {
		const chosenUnit: BusinessUnit = businessUnitsList.filter(
			(unit: BusinessUnit) => unit.id === id,
		)[0];

		if (chosenUnit) {
			setSelectedOptions(prevOptions => {
				if (!prevOptions) return prevOptions;
				return {
					...prevOptions,
					selectedBusinessUnit: chosenUnit,
				};
			});
		}
	};
	const isInMobileView = useDevicePlatform() !== DeviceType.WEB;

	useEffect(() => {
		if (isSecondRequestCompleted && isFirstRequestCompleted) {
			dispatch(decrementBusyIndicator);
		}
	}, [isSecondRequestCompleted, isFirstRequestCompleted]);

	return isInMobileView ? (
		<PaycheckPage navigation={navigation} paychecksRange={paychecksRange} />
	) : (
		<PaycheckPageWeb
			navigation={navigation}
			paychecksRange={paychecksRange}
			onSelectPaycheckDate={onSelectPaycheckDateHandler}
			setIsRequestCompleted={setIsSecondRequestCompleted}
			businessUnitsList={businessUnitsList}
			onChangeBusinessUnit={onChangeBusinessUnit}
			selectedOptions={selectedOptions}
			isErrorPaycheck={isErrorPaycheck}
			setIsErrorPaycheck={setIsErrorPaycheck}
		/>
	);
};

export default PaycheckPageContainer;
