import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import { Platform, TextInput } from 'react-native';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import iconsMap from '../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import ActionButtons from './ActionButtons/ActionButtons';

const CommentModal = ({
	fieldLabel,
	fieldName,
	fieldValue,
	height = 357,
	onNext,
	cancel,
	isRequired,
	inputValue,
	onInputChange,
	saveComment,
	removeComment,
	commentExist,
	readOnly,
}: {
	fieldLabel: string;
	fieldName: string;
	fieldValue: string;
	children?: any;
	onSubmit?: Function;
	submitBtnText?: string;
	height?: number;
	onNext?: Function;
	isRequired?: boolean;
	inputValue?: any;
	cancel?: any;
	onInputChange: any;
	saveComment: Function;
	removeComment: Function;
	commentExist: boolean;
	errorMessage?: string;
	readOnly: boolean;
}) => {
	const patform = useDevicePlatform();
	const isMobile = patform !== DeviceType.WEB;
	const isMobileWeb = patform === DeviceType.MOBILE_WEB;
	const [value, setValue] = useState(inputValue);

	useEffect(() => {
		if (inputValue !== value) {
			setValue(inputValue);
		}
	}, [inputValue]);

	const styles = StyleSheet.create({
		container: {
			flexDirection: 'column',
			shadowColor: 'rgba(0, 0, 0, 0)',
			shadowOpacity: 0.1,
			shadowOffset: { width: 0, height: 2 },
			backgroundColor: colors.white,
			borderRadius: 20,
		},
		titleContainer: {
			paddingTop: 39,
			paddingHorizontal: isMobile ? 20 : 50,
		},
		inputContainer: {
			paddingTop: 10,
			paddingHorizontal: isMobile ? 20 : 50,
			marginBottom: 15,
			borderBottomWidth: 1,
			paddingBottom: 40,
			borderColor: '#ddd',
		},
		buttonsContainer: {
			paddingHorizontal: isMobile ? 20 : 50,
			flexDirection: 'row',
			paddingBottom: 21,
			...Platform.select({
				web: {
					position: 'static',
					display: 'block',
				},
			}),
		},
		fieldValue: {
			paddingTop: 20,
			paddingHorizontal: isMobile ? 20 : 50,
			color: colors.darkGrey,
			fontSize: 22,
			fontFamily: 'RubikMedium',
			fontWeight: 'normal',
			textAlign: 'right',
			marginTop: 2,
			marginBottom: 22,
		},
	});

	const inputStyle: any = StyleSheet.create({
		input: {
			height: 116,
			fontSize: 14,
			width: '100%',
			backgroundColor: colors[false ? 'brightRed' : 'lightBlue'],
			color: colors.darkGrey,
			borderBottomWidth: 0,
			borderColor: colors[false ? 'red' : 'primary'],
			borderRadius: 5,
			padding: 10,
			textAlign: 'right',
			opacity: !false ? 0.7 : 1,
			...Platform.select({
				web: {
					outlineWidth: 0,
				},
			}),
		},
	});

	const containerS = StyleSheet.flatten([
		{
			minWidth: 370,
			width: isMobile ? '90%' : isMobileWeb ? '100%' : 408,
			minHeight: '50%',
			height: 'auto',
		},
		styles.container,
	]);

	const _onInputChange = (value: string) => {
		if (onInputChange) {
			onInputChange(fieldName, value);
		}
		setValue(value);
	};

	return (
		<View style={containerS}>
			<View style={styles.titleContainer}>
				<I18n size={16} weight='normal'>
					{fieldLabel}
				</I18n>
				<Typography>{isRequired ? '*' : null}</Typography>
			</View>
			<Typography style={styles.fieldValue}>{fieldValue}</Typography>
			<View style={styles.inputContainer}>
				<TextInput
					style={inputStyle.input}
					editable={!readOnly}
					onChangeText={_onInputChange}
					defaultValue={value}
					multiline={true}
				/>
			</View>
			<View style={styles.buttonsContainer}>
				<ActionButtons
					save={async () => saveComment(value)}
					remove={removeComment}
					cancel={cancel}
					commentExist={commentExist}
					onNext={onNext}
					disabledSave={!value}
					readOnly={readOnly}
				/>
			</View>
		</View>
	);
};

export default CommentModal;
