import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import colors from '../../../config/colors';
import HeaderContainer from '../../shared/Header/Header.container';
import WebHeader from '../../shared/Header/WebHeader.component';
import I18n from '../../shared/i18n/I18n.component';

const ReportsPageWrapper = ({ title, navigation, children, noHeader }: any) => {
	const [isSmallDimensions, setIsSmallDimensions] = useState(
		window.innerWidth <= 1600,
	);

	const onResize = () => {
		setIsSmallDimensions(window.innerWidth <= 1600);
	};

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => removeEventListener('resize', onResize);
	}, []);

	return (
		<>
			{!noHeader && (<HeaderContainer
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>)}
			<View
				nativeID='#ReportsPageWrapper'
				style={{
					paddingTop: 16,
					paddingLeft: isSmallDimensions ? 10 : 38,
					paddingRight: isSmallDimensions ? 10 : 21,
					height: '100%',
				}}
			>
				<View style={styles.tableHeader} nativeID='#tableHeader'>
					<View
						style={{
							flexDirection: 'row-reverse',
							alignItems: 'center',
							paddingLeft: 27,
							paddingRight: isSmallDimensions ? 11 : 0,
							paddingBottom: isSmallDimensions ? 9 : 17,
						}}
					>
						{typeof title === 'string' ? (
							<I18n size={36} weight='normal' style={{ flex: 1 }}>
								{title}
							</I18n>
						) : (
							title
						)}
					</View>
				</View>
				{children}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	tableHeader: {
		width: '100%',
		zIndex: 100,
	},
});

export default ReportsPageWrapper;
