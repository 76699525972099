import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Keyboard, Platform, useWindowDimensions, View } from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';

const HideOnKeyboardOpen = ({ children, ...restProps }: any) => {
	const [isKeyboadUp, setIsKeyboadUp] = useState(false);
	const plaform = useDevicePlatform();
	const isMobilePlaform = plaform === DeviceType.MOBILE;
	const isMobieWeb = plaform === DeviceType.MOBILE_WEB;
	const dimensions = useWindowDimensions();

	useEffect(() => {
		if (isMobilePlaform) {
			const _keyboardDidShowListener = Keyboard.addListener(
				Platform.OS === 'android'
					? 'keyboardDidShow'
					: 'keyboardWillShow',
				() => setIsKeyboadUp(true),
			);
			const _keyboardDidHideListener = Keyboard.addListener(
				Platform.OS === 'android'
					? 'keyboardDidHide'
					: 'keyboardWillHide',
				() => setIsKeyboadUp(false),
			);

			return () => {
				_keyboardDidHideListener.remove();
				_keyboardDidShowListener.remove();
			};
		} else if (isMobieWeb) {
			const viewport = window.visualViewport;

			const resizeHandler = () => {
				if (viewport.height < dimensions.height) {
					setIsKeyboadUp(true);
				} else {
					setIsKeyboadUp(false);
				}
			};

			window.visualViewport.addEventListener('resize', resizeHandler);
			return () => {
				window.visualViewport.removeEventListener(
					'resize',
					resizeHandler,
				);
			};
		}
	}, []);

	return isKeyboadUp ? <View /> : <>{children}</>;
};

export default HideOnKeyboardOpen;
