import _ from 'lodash';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Platform, TouchableHighlight, View } from 'react-native';
import { BoxShadow } from 'react-native-shadow';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import ImageIcon from '../../Icon/ImageIcon.component';
import FieldBuilder from '../FieldBuilder';

const FieldArray = React.memo(
	({
		fields,
		RowTitle,
		name: parentName,
		viewOnly,
		getDefautValue,
		maxLength,
		arrayWrapperStyle,
		error,
		addButtonStyle,
		buttonWidth,
		buttonHeight,
		editable = true,
		onRemove,
		...restProps
	}: any) => {
		const { control, getValues } = useFormContext();
		const { fields: fieldsArray, append, remove } = useFieldArray({
			name: parentName,
			control,
		});

		const platform = useDevicePlatform();
		const isMobile = platform !== DeviceType.WEB;
		const shadowOpt = {
			width: 48,
			height: 48,
			color: '#3b70a4',
			border: 5,
			radius: 24,
			opacity: 0.4,
			x: 0,
			y: 0,
		};		
		return (
			<View
				style={{
					alignItems: isMobile ? 'stretch' : 'flex-end',
					...(arrayWrapperStyle ? arrayWrapperStyle(isMobile) : {}),
				}}
			>
				{fieldsArray.map((item: any, i: number) => {
					return (
						<View
							key={item?.id}
							style={{
								width: isMobile ? '100%' : 'unset',
								zIndex:
									Platform.OS === 'web' ? 'unset' : undefined,
							}}
						>
							{RowTitle && (
								<RowTitle
									index={i}
									remove={(index:number)=>{
										remove(index)
										onRemove()
									}}
									canRemove={fieldsArray.length > 1}
								/>
							)}
							<View
								style={{
									flexDirection: isMobile
										? 'column'
										: 'row-reverse',
									flexWrap: 'wrap',
									zIndex:
										Platform.OS === 'web'
											? 'unset'
											: undefined,
								}}
							>
								{_.flattenDeep(fields).map((field: any) => {
									return (
										<FieldBuilder
											{...restProps}
											{...field}
											key={`${parentName}[${i}].${field.name}`}
											viewOnly={viewOnly}
											name={`${parentName}[${i}].${field.name}`}
											position={`${parentName}[${i}]`}
											index={i}
											arrayData={{
												remove,
												length: fieldsArray.length,
												index: i,
											}}
										/>
									);
								})}
							</View>
						</View>
					);
				})}
				{maxLength > fieldsArray.length ? (
					<TouchableHighlight
						style={[{
							borderRadius: 30,
							width: buttonWidth || 48,
							height: buttonHeight || 48,
							overflow: 'hidden',
							opacity: editable ? 1 : 0
						}, addButtonStyle]}
						underlayColor='transparent'
						onPress={() =>{
							append(getDefautValue?.(getValues))
						}}
						disabled={!editable}
					>
						<BoxShadow setting={shadowOpt}>
							{/* <View> */}
							<ImageIcon
								name={'add'}
								width={buttonWidth || 48}
								height={buttonHeight || 48}
							></ImageIcon>
							{/* </View> */}
						</BoxShadow>
					</TouchableHighlight>
				) : null}
			</View>
		);
	},
);

export default FieldArray;
