class Clipboard {
	constructor() {}

	getClipboardText = async () => {
		try {
			return await navigator.clipboard.readText();
		} catch (e) {
			return false;
		}
	};
}

export const clipboard = new Clipboard();
