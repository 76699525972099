import { FontAwesome } from '@expo/vector-icons';
import React, { useState, useRef, useEffect } from 'react';
import { Animated, Platform, useWindowDimensions, View } from 'react-native';
import colors from '../../../config/colors';
import getShadowStyle from '../../../config/shadow';
import { getIsSafari } from '../../../utils/globals.utils';
import Button from '../Buttons/Button.component';

const TopDrawer = ({
	children,
	setIsDrawerOpen,
	setOpenDrawerFunc,
	icon,
}: any) => {
	const [showSlide, setShowSlide] = useState(false);
	const dimensions = useWindowDimensions();
	const drawerHeight = useRef(new Animated.Value(0)).current;
	const drawerMarginTop = useRef(new Animated.Value(0)).current;
	const onShowSlideHandlerRef = useRef<Function>();

	const onShowSlideHandler = () => {
		Animated.timing(drawerHeight, {
			toValue: showSlide
				? 0
				: dimensions.height - (Platform.OS === 'ios' ? 350 : 280),
			duration: showSlide ? 1500 : 1000,
			useNativeDriver: false,
		}).start();
		Animated.timing(drawerMarginTop, {
			toValue: showSlide ? 0 : 10,
			duration: showSlide ? 1000 : 1500,
			useNativeDriver: false,
		}).start();
		setTimeout(
			() => {
				setIsDrawerOpen(!showSlide);
				!showSlide && setShowSlide(prevState => !prevState);
			},
			showSlide ? 1000 : 500,
		);
		showSlide && setShowSlide(prevState => !prevState);
	};
	onShowSlideHandlerRef.current = onShowSlideHandler;

	useEffect(() => {
		setOpenDrawerFunc && setOpenDrawerFunc(onShowSlideHandlerRef);
	}, []);

	return (
		<View
			style={{
				zIndex: 300,
				position: 'relative',
			}}
		>
			<Animated.View
				style={{
					height: drawerHeight,
					borderRadius: 10,
					marginTop: drawerMarginTop,
					zIndex: 10,
					overflow:
						Platform.OS === 'android' ||
						Platform.OS === 'ios' ||
						getIsSafari()
							? 'visible'
							: 'auto',
					...getShadowStyle(10, '#3b70a4'),
				}}
			>
				<View
					style={{
						flex: 1,
						backgroundColor: colors.white,
						overflow: 'hidden',
					}}
				>
					{children}
				</View>
			</Animated.View>
			<Button
				buttonStyle={{
					position: Platform.OS === 'ios' ? 'absolute' : 'relative',
					alignSelf: 'center',
					left: dimensions.width / 2 - 58,
					marginRight:
						Platform.OS === 'ios' ? 0 : dimensions.width / 2 + 58,
					top: Platform.OS === 'ios' ? -33 : 0,
					height: Platform.OS === 'ios' ? 66 : 33,
					width: 116,
					backgroundColor: colors.warning,
					alignItems: 'center',
					justifyContent: 'flex-end',
					padding: 10,
					borderBottomLeftRadius: 30,
					borderBottomRightRadius: 30,
				}}
				onPress={onShowSlideHandler}
			>
				{icon || (
					<FontAwesome
						name='comment'
						size={14}
						color={colors.white}
					/>
				)}
			</Button>
		</View>
	);
};

export default TopDrawer;
