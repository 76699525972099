import { flatten, intersection } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../../config/routes';
import useBusinessUnitsState from '../../hooks/useBusinessUnitsState';
import httpService from '../../services/http.service';
import {
	getBussinesUnitsData,
	getBussinesUnitsModules,
} from '../../store/selectors/login.selectors';
import {
	getLoginData,
	getEmployeePrivileges,
} from '../../store/selectors/login.selectors';
import DasboardEmployerComponent from './DashboardEmployer.component';

export type BoxAccessType = { [value: string]: boolean };

export type ManagerDashboardResponse = {
	paychecks: { month: number; total: number };
	employeeForm: { year: number; total: number };
	employerRequest: number;
};

const myTableBoxAccessObj: BoxAccessType = {
	isBoxVisible: false,
	isSalaryReportsVisible: false,
	isAttendanceReportsVisible: false,
	isCostingReportsVisible: false,
	isVocationReportsVisible: false,
};

const mySummaryBoxAccessObj: BoxAccessType = {
	isBoxVisible: false,
	isSalarySummaryVisible: false,
	isReport101SummaryVisible: false,
};

const managerDashboardResponseObj: ManagerDashboardResponse = {
	paychecks: { month: 0, total: 0 },
	employeeForm: { year: 0, total: 0 },
	employerRequest: 0,
};

const tablesSalaryRepAcc = [
	'ROLE_EMP_PAYCHECKS_READ',
	'ROLE_EMP_PAYCHECKS_WRITE',
];
const tablesAttendancyRepAcc = ['ROLE_EMP_10001_READ', 'ROLE_EMP_10001_WRITE'];
const tablesCostingRepAcc = [
	'ROLE_COSTING_REPORT_READ',
	'ROLE_COSTING_REPORT_WRITE',
];
const tablesVocationRepAcc = [
	'ROLE_VACATION_REPORT_READ',
	'ROLE_VACATION_REPORT_WRITE',
];

const summary101RepAcc = [
	'ROLE_EMP_PAYCHECKS_READ',
	'ROLE_EMP_PAYCHECKS_WRITE',
];
const summarySalaryRepAcc = [
	'ROLE_EMPLOYEE_FORM_REPORT_READ',
	'ROLE_EMPLOYEE_FORM_REPORT_WRITE',
];
const requestsSummery = [
	'ROLE_MNG_VAC_REQUEST_READ',
	'ROLE_MNG_VAC_REQUEST_WRITE',
	'ROLE_MNG_SICK_REQUEST_READ',
	'ROLE_MNG_SICK_REQUEST_WRITE',
];

const workerRepAcc = ['ROLE_CREATE_USER_WRITE'];

const DasboardEmployerContainer = ({ navigation }: { navigation: any }) => {
	const [myTableBoxAccess, setMyTableBoxAccess] =
		useState<BoxAccessType>(myTableBoxAccessObj);
	const [mySummaryBoxAccess, setMySummaryBoxAccess] = useState<BoxAccessType>(
		mySummaryBoxAccessObj,
	);
	const [myWorkerBoxAccess, setMyWorkerBoxAccess] = useState(false);
	const [dashboardDataFetched, setDashboardDataFetched] =
		useState<ManagerDashboardResponse>(managerDashboardResponseObj);
	const businessUnitsData = useSelector(getBussinesUnitsData);
	const businessUnitsModules = useSelector(getBussinesUnitsModules);
	const {
		businessUnitsList,
		selectedBusinessUnits,
		onChangeBusinessUnits,
		isBusinessUnitsMulti,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.dashboardEmployer.privileges,
	});
	const loginData = useSelector(getLoginData);
	const userPrivilegesStatus = useSelector(getEmployeePrivileges);

	useEffect(() => {
		settings();
	}, [userPrivilegesStatus, selectedBusinessUnits]);

	const settings = () => {
		let tSal = false;
		let tAtt = false;
		let tCost = false;
		let tVoc = false;
		let s101 = false;
		let sSal = false;
		let wRep = false;
		let sReq = false;

		const allPrivilegs = !Array.isArray(selectedBusinessUnits)
			? []
			: intersection(
					flatten(
						(selectedBusinessUnits?.length
							? selectedBusinessUnits
							: businessUnitsList.map((val: any) => val.id)
						).map(unit => businessUnitsData[unit]),
					),
			  );

		allPrivilegs?.forEach(el => {
			if (tablesSalaryRepAcc.includes(el)) tSal = true;
			if (tablesAttendancyRepAcc.includes(el)) tAtt = true;
			if (tablesCostingRepAcc.includes(el)) tCost = true;
			if (tablesVocationRepAcc.includes(el)) tVoc = true;
			if (summary101RepAcc.includes(el)) s101 = true;
			if (summarySalaryRepAcc.includes(el)) sSal = true;
			if (workerRepAcc.includes(el)) wRep = true;
			if (requestsSummery.includes(el)) sReq = true;
		});

		if (tSal || tAtt || tCost || tVoc) {
			const newAccObj: BoxAccessType = {
				isBoxVisible: true,
				isSalaryReportsVisible: tSal,
				isAttendanceReportsVisible: tAtt,
				isCostingReportsVisible: tCost,
				isVocationReportsVisible: tVoc,
			};
			setMyTableBoxAccess(newAccObj);
		}

		if (s101 || sSal || sReq) {
			const newAccObj: BoxAccessType = {
				isBoxVisible: true,
				isSalarySummaryVisible: sSal,
				isReport101SummaryVisible: s101,
				isRequestsSummaryVisible: sReq,
			};
			setMySummaryBoxAccess(newAccObj);
			fetchManagerDashboard();
		}

		if (wRep) {
			setMyWorkerBoxAccess(true);
		} else {
			setMyWorkerBoxAccess(false);
		}
	};

	const fetchManagerDashboard = async () => {
		try {
			const res: ManagerDashboardResponse = await httpService.api<any>({
				type: 'getManagerDashboard',
				query: {
					unitIds: getSelectedUnits(selectedBusinessUnits).join(','),
				},
			});

			if (res) {
				setDashboardDataFetched(prev => ({
					employeeForm: res.employeeForm,
					paychecks: res.paychecks,
					employerRequest: res.employerRequest,
				}));
			}
		} catch (e) {
			console.log(
				`Error while fetching data from API: getManagerDashboard`,
			);
		}
	};

	return (
		<DasboardEmployerComponent
			navigation={navigation}
			mySummaryBoxAccess={mySummaryBoxAccess}
			myTableBoxAccess={myTableBoxAccess}
			myWorkerBoxAccess={myWorkerBoxAccess}
			dashboardDataFetched={dashboardDataFetched}
			isMulti={isBusinessUnitsMulti}
			businessUnitsList={businessUnitsList}
			selectedBusinessUnits={selectedBusinessUnits || []}
			onChangeBusinessUnits={onChangeBusinessUnits}
		/>
	);
};

export default DasboardEmployerContainer;
