import {
	Checkbox as MuiCheckbox,
	debounce,
	makeStyles,
} from '@material-ui/core';
import { compact } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	FlatList,
	TouchableOpacity,
	StyleSheet,
	View,
	Dimensions,
	Platform,
} from 'react-native';
import { Divider, Portal } from 'react-native-paper';
import OutlineButtonContainer from '../../../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../../../components/shared/Buttons/SolidButton.container';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import SearchInputContainer from '../../../../../components/shared/SearchInput/SearchInput.container';
import SelectGroup from '../../../../../components/shared/SelectGroup/SelectGroup.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import colors from '../../../../../config/colors';
import getShadowStyle from '../../../../../config/shadow';
import useDevicePlatform, {
	DeviceType,
} from '../../../../../hooks/useDevicePlatform.hook';
import httpService from '../../../../../services/http.service';
import { i18nService } from '../../../../../services/i18n.service';
import { modalService } from '../../../../../services/modal.service';
import SelectPermissionsModal from '../RolesSectionModalsWeb/SelectPermissionsModal';
import SelectPermissionsModalMobile from '../RolesSectionMobile/SelectPermissionsModal';
import AddCustomerMobileRow from './AddCustomerMobileRow';
import ModalContainer from '../../../../../components/shared/Modal/Modal.container';
import { DoubleButton } from '../../../../../components/shared/DoubleButton/DoubleButton.component';

const useStyles = makeStyles(() => ({
	checkbox: {
		marginRight: -10,

		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
			backgroundColor: colors.white,
		},
	},
	checked: {},
}));

const Row = ({ isChecked, item, onCheck, disabled }: any) => {
	const classes = useStyles();

	return (
		<View style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
			<TouchableOpacity
				accessibilityRole='button'
				onPress={e => {
					e?.stopPropagation();
					onCheck(item.numericId, !isChecked, item);
				}}
				style={{
					alignItems: 'center',
					flexDirection: 'row-reverse',
					flex: 2,
				}}
			>
				<MuiCheckbox
					size='small'
					color='default'
					checked={!!isChecked}
					classes={{
						root: classes?.checkbox,
						checked: classes?.checked,
					}}
				/>
				<I18n
					weight='400'
					size={14}
					style={{
						textAlign: 'right',
						marginRight: 13,
					}}
					color='darkGrey'
				>
					{item.name} {item.id && `(${item.id})`}
				</I18n>
			</TouchableOpacity>
		</View>
	);
};
const AddCustomerWeb = ({
	selectedData,
	close,
	unitId,
	onNext,
	onSubmit,
}: any) => {
	const [selected, setSelected] = useState<any[]>([]);
	const [selectedRole, setSelectedRole] = useState<number>(8);
	const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
	const [customers, setCustomers] = useState<any>([]);
	const [permissionsMap, setPermissionMap] = useState<any>({});
	const [showSelected, setShowSelected] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean | any>(false);
	const [noUsers, setNoUsers] = useState<boolean | any>(false);
	const originalData = useRef<any>();
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const options = useMemo(
		() => [
			{
				value: 10,
				label: i18nService.translate(
					'customerManagementModal.fieldsForm.role.manager',
				),
			},
			{
				value: 8,
				label: i18nService.translate(
					'customerManagementModal.fieldsForm.role.admin',
				),
			},
		],
		[isMobile],
	);
	const openModal = async () => {
		let permissions = permissionsMap[selectedRole];
		if (!permissionsMap[selectedRole]) {
			const permissionsNew: any = await httpService.api({
				type: 'getRolePermissions',
				params: { id: selectedRole },
				query: { unitId },
			});
			permissions = permissionsNew.sort((a: any, b: any) =>
				a.allow_read_only !== b.allow_read_only
					? a.allow_read_only
						? -1
						: 1
					: a.name > b.name
					? 1
					: -1,
			);
			setPermissionMap({
				...permissionsMap,
				[selectedRole]: [...permissions],
			});
		}

		if (isMobile) {
			setShowModal(true);
			return;
		}
		(async () => {
			const modalData = {
				selectedData: selectedPermissions?.length
					? selectedPermissions
					: permissions.map(d => ({ permission_id: d.id })),
				allData: permissions,
				onSubmit: (value: any[]) => {
					setSelectedPermissions(value);
				},
				close: () => {},
				role: {
					name:
						selectedRole === 10
							? i18nService.translate(
									'customerManagementModal.fieldsForm.role.manager',
							  )
							: i18nService.translate(
									'customerManagementModal.fieldsForm.role.admin',
							  ),
				},
				checkAll: !selectedPermissions.length,
				showSwitch: true,
			};
			modalService.openModal(
				null,
				{
					onNext: (callback: Function) => {
						callback();
					},
					...modalData,
				},
				(props: any) =>
					isMobile ? (
						<SelectPermissionsModalMobile {...props} />
					) : (
						<SelectPermissionsModal {...props} />
					),
			);
		})();
	};

	const onCheck = (itemId: number, value?: boolean, item?: any) => {
		if (value) {
			item.numericId === 'all'
				? setSelected([...customers])
				: setSelected([...selected, item]);
		} else {
			item.numericId === 'all'
				? setSelected([])
				: setSelected(
						selected.filter(
							(s: any) =>
								s?.numericId !== itemId &&
								s?.numericId !== 'all',
						),
				  );
		}
	};

	const getRepCustomers = async (search: any, notInit?: boolean) => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: 0,
				ps: 1000,
				search: search?.value,
				f: {
					repCustomerIdsNot: selectedData,
					unitIds: [unitId],
				},
			},
		});

		if (
			!notInit &&
			!res.data?.length &&
			(!search?.value || search?.value === '')
		) {
			setNoUsers(true);
		}

		const data = notInit
			? [...customers, ...res.data]
			: compact([
					res.data?.length && {
						numericId: 'all',
						name: i18nService.translate('general.all'),
					},
					...res.data,
			  ]);
		originalData.current = data;
		filterData(showSelected);

		if (res.hasNext) {
			getRepCustomers(search, true);
		}
	};

	const filterData = (showSelected: boolean) => {
		if (!showSelected) {
			setCustomers([...originalData.current]);
		} else {
			setCustomers(
				originalData.current.filter(d =>
					selected.find(select => select.numericId === d.numericId),
				),
			);
		}
	};
	const onChangeShow = () => {
		filterData(!showSelected);
		setShowSelected(!showSelected);
	};

	useEffect(() => {
		getRepCustomers('');
	}, []);

	const _onSubmit = () => {
		onSubmit(
			compact(
				selected.map(
					(item: any) =>
						item.numericId !== 'all' && {
							role_id: selectedRole,
							customer_id: item.numericId,
							customer_name: item.name,
							customer_hp: item.id,
							unit_ids: [],
							user_role_permission: selectedPermissions,
							excluded_user: null,
							exclude_selected: true,
						},
				),
			),
		);
		close?.();
		onNext();
	};

	return (
		<Portal.Host>
			<View
				style={{
					borderRadius: isMobile ? 0 : 15,
					width: isMobile ? Dimensions.get('window').width : 531,
					paddingHorizontal: isMobile ? 16 : 38,
					paddingVertical: 22,
					backgroundColor: colors.white,
					height: isMobile ? Dimensions.get('window').height : '85vh',
					marginTop: Platform.OS !== 'web' ? -65 : 0,
				}}
			>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: 19,
					}}
				>
					<I18n size={isMobile ? 24 : 30} weight='normal'>
						customerManagementModal.addCustomers.title
					</I18n>
					{isMobile && (
						<TouchableOpacity
							style={{ flexDirection: 'row-reverse' }}
							onPress={onNext}
						>
							<I18n size={20} weight='400'>
								customerManagementModal.addCustomers.goBackScreenTitle
							</I18n>
							<ImageIcon name='leftArrow' />
						</TouchableOpacity>
					)}
				</View>
				{!isMobile && <Divider />}

				{!noUsers ? (
					<>
						{!isMobile && (
							<View
								style={{
									flexDirection: 'row-reverse',
									marginLeft: 30,
								}}
							>
								<I18n
									size={16}
									style={{ marginTop: 19, flex: 2 }}
									weight='normal'
								>
									customerManagementModal.addCustomers.subTitle
								</I18n>
							</View>
						)}
						<View style={{ flexDirection: 'row-reverse' }}>
							<SearchInputContainer
								placeholder={i18nService.translate(
									'customerManagementModal.addCustomers.search',
								)}
								config={{ name: 'search', type: 'searchInput' }}
								onChange={debounce(getRepCustomers, 500)}
								styleConf={{
									container: {
										marginTop: 9,
										marginBottom: 30,
										borderWidth: 1,
										borderColor: '#E0E0E0',
										width: 338,
									},
								}}
							/>
						</View>
						<FlatList
							style={{ marginTop: 10, flex: 1 }}
							data={customers}
							contentContainerStyle={{ paddingBottom: 30 }}
							renderItem={({ item, ...rest }) =>
								isMobile ? (
									<AddCustomerMobileRow
										isChecked={
											(selected.length ===
												customers.length - 1 &&
												selected.length) ||
											!!selected.find(
												(s: any) =>
													s.numericId ===
													item.numericId,
											)
										}
										item={item}
										onCheck={onCheck}
										{...rest}
									/>
								) : (
									<Row
										isChecked={
											(selected.length ===
												customers.length - 1 &&
												selected.length) ||
											!!selected.find(
												(s: any) =>
													s.numericId ===
													item.numericId,
											)
										}
										item={item}
										onCheck={onCheck}
										{...rest}
									/>
								)
							}
							keyExtractor={item => item.numericId}
						/>
						{!isMobile && <Divider />}
						<View
							style={{
								zIndex: 10,
								marginBottom: 15,
								marginTop: isMobile ? 15 : 0,
								width: '100%',
								alignItems: 'flex-end',
							}}
						>
							{isMobile ? (
								<DoubleButton
									activeButton={options.findIndex(
										opt => opt.value === selectedRole,
									)}
									onButtonClick={(buttonPressed: number) => {
										setSelectedRole(
											options[buttonPressed]?.value,
										);
									}}
									// stylesConf={{
									// 	width: 90,
									// 	height: 32,
									// 	textSize: 12,
									// 	borderColor: colors.white,
									// }}
									stylesConf={{
										width:
											Dimensions.get('window').width / 2 -
											20,
										height: 40,
									}}
									leftButtonText={options[0].label}
									rightButtonText={options[1].label}
								/>
							) : (
								// <RadioButtonsGroup
								// 	options={options}
								// 	onChange={setSelectedRole}
								// 	editable={true}
								// 	value={selectedRole}
								// />
								<SelectGroup
									name='selectCustomerRole'
									title='customerManagementModal.fieldsForm.role'
									options={options}
									onChange={(value: any) => {
										setSelectedPermissions([]);
										setSelectedRole(value);
									}}
									value={selectedRole}
									width={280}
								/>
							)}
						</View>
						<View
							style={{
								flexDirection: 'row-reverse',
								marginBottom: isMobile ? 0 : 15,
								alignItems: 'center',
							}}
						>
							<TouchableOpacity
								onPress={() => openModal()}
								style={[
									styles.actionButtons,
									{
										marginHorizontal: isMobile ? 0 : 10,
										paddingHorizontal: 20,
										width: isMobile ? '100%' : 128,
										maxWidth: isMobile ? '100%' : 128,
									},
								]}
							>
								<I18n weight='normal' color='white' size={16}>
									customerManagementModal.permissions
								</I18n>
								{selectedPermissions?.length ? (
									<I18n
										style={{ marginRight: 2 }}
										color='white'
										weight='normal'
										size={14}
									>
										({selectedPermissions.length})
									</I18n>
								) : null}
							</TouchableOpacity>
						</View>
						{!isMobile && (
							<>
								<Divider />
								<View
									style={{
										flexDirection: 'row-reverse',
										marginVertical: 19,
									}}
								>
									<I18n size={14}>
										customerManagementModal.addCustomers.sum
									</I18n>
									<Typography
										weight='bold'
										size={14}
										style={{
											marginRight: 5,
											marginLeft: 25,
										}}
										color='primary'
									>
										{selected.length -
											(selected[0]?.numericId === 'all'
												? 1
												: 0)}
									</Typography>
									<TouchableOpacity
										onPress={onChangeShow}
										style={{ flexDirection: 'row-reverse' }}
									>
										<ImageIcon
											name={
												!showSelected
													? 'checkboxUnselected'
													: 'checkboxSelected'
											}
											width={15}
											height={15}
										/>
										<I18n
											size={14}
											style={{ marginRight: 6 }}
										>
											customerManagementModal.addCustomers.showSelected
										</I18n>
									</TouchableOpacity>
								</View>
								<Divider />
							</>
						)}
					</>
				) : (
					<>
						<View
							style={{
								flex: 1,
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<I18n size={14} weight='normal'>
								customerManagementModal.addCustomers.noMoreUsers
							</I18n>
						</View>
						<Divider />
					</>
				)}

				<View
					style={{
						flexDirection: 'row-reverse',
						marginTop: 24,
						height: Platform.OS === 'ios' ? 120 : undefined,
					}}
				>
					<SolidButtonContainer
						disabled={!selected?.length}
						overrideStyle={{ minWidth: 90 }}
						onPress={_onSubmit}
					>
						general.add
					</SolidButtonContainer>
					<OutlineButtonContainer
						size='medium'
						onPress={onNext}
						overrideStyle={{ minWidth: 90, marginRight: 30 }}
					>
						general.cancel
					</OutlineButtonContainer>
				</View>
				{isMobile && (
					<Portal>
						<ModalContainer
							disabledKeyboard
							visible={showModal && permissionsMap[selectedRole]}
							component={SelectPermissionsModalMobile}
							notDisplayModal
							{...{
								onNext: (callback: Function) => {
									//callback();
									setShowModal(false);
								},
								selectedData: selectedPermissions?.length
									? selectedPermissions
									: permissionsMap[selectedRole]?.map(d => ({
											permission_id: d.id,
									  })),
								allData: permissionsMap[selectedRole],
								onSubmit: (value: any[]) => {
									setSelectedPermissions(value);
									setShowModal(false);
								},
								role: {
									name:
										selectedRole === 10
											? i18nService.translate(
													'customerManagementModal.fieldsForm.role.manager',
											  )
											: i18nService.translate(
													'customerManagementModal.fieldsForm.role.admin',
											  ),
								},
								checkAll: !selectedPermissions.length,
								showSwitch: true,
							}}
						/>
					</Portal>
				)}
			</View>
		</Portal.Host>
	);
};

const styles = StyleSheet.create({
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 180,
		backgroundColor: colors.primary,
		borderRadius: 35,
		height: 40,
		paddingHorizontal: 24,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default AddCustomerWeb;
