import { i18nService } from '../../../../services/i18n.service';

const WageComponent = (data: any) => {
	const format: any = {};
	const initialValues: any = {};
	if (!data) return { format, initialValues };
	data.forEach((element: any) => {
		format[`${element.componentType}_${element.code}`] = {};
		format[`${element.componentType}_${element.code}`].data = {
			code: element.code,
			title: `${element.code} - ${element.name}`,
			componentType: 'wageComponent',
		};

		format[`${element.componentType}_${element.code}`].header = [
			{
				name: 'neto',
				type: 'header',
				code: element.code,
				value: 'FromMonthToMonthReports.formats.WageComponent.netGross',
			},
			{
				name: 'amount',
				type: 'header',
				code: element.code,
				value: 'FromMonthToMonthReports.formats.WageComponent.amount',
			},
			{
				name: 'rate',
				type: 'header',
				code: element.code,
				value: 'FromMonthToMonthReports.formats.WageComponent.rate',
			},
			{
				name: 'sum',
				type: 'header',
				code: element.code,
				value: 'FromMonthToMonthReports.formats.WageComponent.sum',
			},
		];
		element.employees?.forEach((item: any, index: number) => {
			initialValues[`${item.id}_${element.code}_neto`] = item.net
				? i18nService.translate(
						'FromMonthToMonthReports.formats.WageComponent.net',
				  )
				: i18nService.translate(
						'FromMonthToMonthReports.formats.WageComponent.gross',
				  );
			initialValues[`${item.id}_${element.code}_amount`] = item.amount;
			initialValues[`${item.id}_${element.code}_rate`] = item.rate;
			initialValues[`${item.id}_${element.code}_sum`] = item.value;
			format[`${element.componentType}_${element.code}`][
				`user_${item.id}`
			] = [
				{
					id: item.id,
					name: `${item.id}_${element.code}_neto`,
					placeholder: '',
					type: 'text',
					value: item.net
						? i18nService.translate(
								'FromMonthToMonthReports.formats.WageComponent.net',
						  )
						: i18nService.translate(
								'FromMonthToMonthReports.formats.WageComponent.gross',
						  ),
					order: index,
				},
				{
					id: item.id,
					name: `${item.id}_${element.code}_amount`,
					placeholder: '0.00',
					type: 'input',
					value: item.amount || '',
					order: index,
				},
				{
					id: item.id,
					name: `${item.id}_${element.code}_rate`,
					placeholder: '0.00',
					type: 'input',
					value: item.rate || '',
					order: index,
				},
				{
					id: item.id,
					name: `${item.id}_${element.code}_sum`,
					placeholder: '0.00',
					type: 'input',
					value: item.value || '',
					order: index,
				},
			];
		});
	});
	return { format, initialValues };
};

export default WageComponent;
