import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Searchbar } from 'react-native-paper';
import _ from 'lodash';
import { i18nService } from '../../../services/i18n.service';
import colors from '../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';

interface SearchInputProps {
	onChange: (obj: { name: string; value: string; isMulti: false }) => void;
	placeholder?: string;
	config: {
		type: string;
		name: string;
		defaultValue?: string;
		style?: any;
		minChars?: number;
	};
	styleConf?: {
		container: {};
		input?: {};
	};
	selectedFilters?: any;
	resetActiveSortByProps?: [];
}

const SearchInput = ({
	placeholder,
	onChange,
	config,
	styleConf,
	selectedFilters = {},
	hideIcon,
	resetActiveSortByProps = [],
}: SearchInputProps) => {
	const platform = useDevicePlatform();
	const isMobile = platform === DeviceType.MOBILE;

	const [searchQuery, setSearchQuery] = useState('');
	const delayedQuery = useCallback(
		_.debounce(
			(q: string) =>
				(!config.minChars ||
					config.minChars <= q.length ||
					!q.length) &&
				onChange({
					name: config.name,
					value: q,
					isMulti: false,
				}),
			90,
		),
		[{ ...selectedFilters }],
	);

	useEffect(() => {
		if (
			config.defaultValue ||
			config.defaultValue === '' ||
			resetActiveSortByProps.length
		) {
			setSearchQuery(config.defaultValue);
		}
	}, [config.defaultValue, resetActiveSortByProps]);

	const containerStyle = StyleSheet.flatten([
		styles.container,
		styleConf?.container,
		config.style?.container,
	]);

	const onChangeSearch = (searchQuery: string) => {
		setSearchQuery(searchQuery);
		delayedQuery(searchQuery);
	};

	return (
		<View style={containerStyle} nativeID='#searchInput'>
			<Searchbar
				placeholder={
					!!placeholder
						? placeholder
						: i18nService.translate('filter.search')
				}
				onChangeText={onChangeSearch}
				value={searchQuery}
				style={{
					flexDirection: 'row-reverse',
					height: isMobile ? 35 : 32,
					shadowColor: 'transparent',
					backgroundColor: colors.white,
					...(styleConf?.input || {}),
					...(config?.style?.input || {}),
				}}
				iconColor={hideIcon ? colors.white : colors.darkGrey}
				theme={{ dark: false }}
				inputStyle={{
					textAlign: 'right',
					direction: 'rtl',
					fontFamily: 'RubikRegular',
					height: isMobile ? 35 : 32,
					fontSize: 14,
					backgroundColor: colors.white,
					color: colors.darkGrey,
					...Platform.select({
						web: {
							outlineWidth: 0,
						},
					}),
					...(styleConf?.input || {}),
					...(config?.style?.input || {}),
				}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// maxWidth: 274,
	},
});

export default SearchInput;
