import React from 'react';
import { View } from 'react-native';
import { createNumberMask } from 'react-native-mask-input';
import * as yup from 'yup';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';
import EmployeeRowTitle from '../TabsComponents/EmployeeRowTitle/EmployeeRowTitle';
import { alphaNumeriRegex, numberOnlyRegex } from '../WorkerCardValidation';

const fileArrayFieldBuilder = (
	name: string,
	watchName?: string,
	label?: string,
	watchCallback?: Function,
) => ({
	type: 'array',
	name,
	shouldDisplay: watchName
		? () => ({
				watch: [watchName],
				callback: watchCallback
					? watchCallback
					: (values: any) => values[0],
		  })
		: null,
	getDefautValue: (getValues: Function) => ({
		name: '',
		extraData: null,
	}),
	maxLength: 3,
	fieldWrapperStyle: (isMobile: boolean) => ({
		width: !isMobile ? 'unset' : '100%',
		marginBottom: isMobile ? 30 : 0,
	}),
	arrayWrapperStyle: (isMobile: boolean) => ({
		flexDirection: !isMobile ? 'row-reverse' : 'column',
		alignItems: !isMobile ? 'center' : 'flex-end',
		width: '100%',
	}),
	fields: [
		[
			{
				name: 'extraData',
				type: 'uploadFile',
				label: ({ index }: any) =>
					!index && label
						? i18nService.translate(label)
						: `${i18nService.translate('general.file')} ${
								index + 1
						  }`,
				fieldWrapperStyle: (isMobile: boolean) => ({
					maxWidth: isMobile ? '100%' : 318,
				}),
				isRequired: ({ index }: any) => !index,
			},
		],
	],
});

export const taxCreditRequestFields = {
	pageRoute: 'taxCreditRequest',
	fields: [
		[
			{
				name: 'taxCreditRequest.wantTaxCredit',
				type: 'inlineCheckbox',
				label: 'workerCard.editor.taxCreditRequest.wantTaxCredit',
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
					prevValue: any,
					trigger: Function,
					reset: Function,
				) => {
					const obj = getValues();
					reset(
						{
							...obj,
							taxCreditRequest: obj.taxCreditRequest
								? {
										...obj.taxCreditRequest,
										wantTaxCredit: selectedValue,
										taxCreditRequestReason: undefined,
										noIncome: false,
										noIncomeFile: [],
										hasAdditionalIncome: false,
										additionalIncome: [],
										taxAssessorApprovedFile: [],
										taxAssessorApproved: false,
								  }
								: undefined,
						},
						{ keepErrors: true, keepDirty: true },
					);
				},
			},
		],
		[
			{
				name: 'taxCreditRequest.taxCreditRequestReason',
				type: 'buttonsGroup',
				label:
					'workerCard.editor.taxCreditRequest.taxCreditRequestReason',
				options: [
					{
						label:
							'workerCard.editor.taxCreditRequest.taxCreditRequestReason.noIncome',
						value: 'noIncome',
					},
					{
						label:
							'workerCard.editor.taxCreditRequest.taxCreditRequestReason.additionalIncome',
						value: 'additionalIncome',
					},
					{
						label:
							'workerCard.editor.taxCreditRequest.taxCreditRequestReason.taxAssessorApproved',
						value: 'taxAssessorApproved',
					},
				],
				shouldDisplay: () => ({
					watch: ['taxCreditRequest.wantTaxCredit'],
					callback: (values: any) => {
						return values[0];
					},
				}),
				smallBorderRadius: (isMobile: boolean) => isMobile,
				isRequired: true,
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
				) => {
					if (selectedValue === 'noIncome') {
						setValue('taxCreditRequest.noIncome', true);
						setValue('taxCreditRequest.noIncomeFile', [{}]);
						setValue('taxCreditRequest.hasAdditionalIncome', false);
						setValue('taxCreditRequest.additionalIncome', []);
						setValue(
							'taxCreditRequest.taxAssessorApprovedFile',
							[],
						);
						setValue('taxCreditRequest.taxAssessorApproved', false);
					} else if (selectedValue === 'additionalIncome') {
						setValue('taxCreditRequest.noIncome', false);
						setValue('taxCreditRequest.noIncomeFile', []);
						setValue('taxCreditRequest.hasAdditionalIncome', true);
						setValue('taxCreditRequest.additionalIncome', [
							{
								name: undefined,
								address: undefined,
								phone: undefined,
								deductionFileId: undefined,
								incomeType: 'WORK',
								monthlyIncome: undefined,
								taxReduce: undefined,
								additionalIncomeFile: [{}],
							},
						]);
						setValue(
							'taxCreditRequest.taxAssessorApprovedFile',
							[],
						);
						setValue('taxCreditRequest.taxAssessorApproved', false);
					} else {
						setValue('taxCreditRequest.noIncome', false);
						setValue('taxCreditRequest.noIncomeFile', []);
						setValue('taxCreditRequest.additionalIncome', []);
						setValue('taxCreditRequest.hasAdditionalIncome', false);
						setValue('taxCreditRequest.taxAssessorApprovedFile', [
							{},
						]);
						setValue('taxCreditRequest.taxAssessorApproved', true);
					}
				},
			},
		],
		[
			{
				type: 'customInput',
				label: 'workerCard.editor.taxCreditRequest.noIncome.title',
				shouldDisplay: () => ({
					watch: ['taxCreditRequest.taxCreditRequestReason'],
					callback: (values: any) => values[0] === 'noIncome',
				}),
				Component: () => (
					<View>
						{['typeOne', 'typeTwo', 'typeThree'].map(label => (
							<View
								style={{
									flexDirection: 'row-reverse',
									alignItems: 'center',
									marginBottom: 5,
									marginTop: 10,
								}}
							>
								<View
									style={{
										backgroundColor: colors.primary,
										width: 8,
										height: 8,
										borderRadius: 8,
										marginLeft: 10,
									}}
								/>
								<I18n
									size={14}
								>{`workerCard.editor.taxCreditRequest.noIncome.${label}`}</I18n>
							</View>
						))}
					</View>
				),
			},
		],
		[
			fileArrayFieldBuilder(
				'taxCreditRequest.noIncomeFile',
				'taxCreditRequest.taxCreditRequestReason',
				undefined,
				(values: any) => values[0] === 'noIncome',
			),
		],
		[
			{
				name: 'taxCreditRequest.additionalIncome',
				type: 'array',
				maxLength: 3,
				fieldWrapperStyle: (isMobile: boolean) => ({
					width: !isMobile ? 'unset' : '100%',
				}),
				arrayWrapperStyle: (isMobile: boolean) => ({
					width: '100%',
				}),
				getDefautValue: (getValues: Function) => ({
					name: '',
					address: '',
					phone: '',
					deductionFileId: '',
					incomeType: 'WORK',
					monthlyIncome: '',
					taxReduce: '',
					additionalIncomeFile: [{}],
				}),
				shouldDisplay: () => ({
					watch: ['taxCreditRequest.taxCreditRequestReason'],
					callback: (values: any) => values[0] === 'additionalIncome',
				}),
				RowTitle: EmployeeRowTitle,
				fields: [
					{
						name: 'name',
						type: 'input',
						label:
							'workerCard.editor.taxCreditRequest.additionalIncome.name',
						isRequired: true,
						maxLength: 50,
					},
					{
						name: 'address',
						type: 'input',
						label:
							'workerCard.editor.taxCreditRequest.additionalIncome.address',
						isRequired: true,
						maxLength: 50,
					},
					{
						name: 'deductionFileId',
						type: 'input',
						label:
							'workerCard.editor.taxCreditRequest.additionalIncome.deductionFileId',
						isRequired: true,
						maxLength: 10,
						keyboardType: 'numeric',
					},
					{
						name: 'incomeType',
						type: 'reactselect',
						options: [
							{ name: 'general.work', id: 'WORK' },
							{ name: 'general.allowance', id: 'ALLOWANCE' },
							{ name: 'general.scholarship', id: 'SCHOLARSHIP' },
							{ name: 'general.other', id: 'OTHER' },
						],
						label:
							'workerCard.editor.taxCreditRequest.additionalIncome.incomeType',
						isRequired: true,
					},
					{
						name: 'monthlyIncome',
						type: 'input',
						convertToLocalNumber: true,
						label:
							'workerCard.editor.taxCreditRequest.additionalIncome.monthlyIncome',
						isRequired: true,
						maxLength: 10,
						keyboardType: 'numeric',
					},
					{
						name: 'taxReduce',
						type: 'input',
						convertToLocalNumber: true,
						label:
							'workerCard.editor.taxCreditRequest.additionalIncome.taxReduce',
						isRequired: true,
						maxLength: 10,
						keyboardType: 'numeric',
					},
					fileArrayFieldBuilder(
						'additionalIncomeFile',
						undefined,
						'workerCard.editor.taxCreditRequest.additionalIncome.additionalIncomeFile',
					),
				],
			},
		],
		[
			fileArrayFieldBuilder(
				'taxCreditRequest.taxAssessorApprovedFile',
				'taxCreditRequest.taxCreditRequestReason',
				undefined,
				(values: any) => values[0] === 'taxAssessorApproved',
			),
		],
	],
};

// export const taxCreditRequestValidation = yup.object({
// 	wantTaxCredit: yup
// 		.boolean()
// 		.required('general.validation.required')
// 		.nullable(),
// 	taxCreditRequestReason: yup.string().when('wantTaxCredit', {
// 		is: true,
// 		then: yup.string().required('general.validation.required').nullable(),
// 	}),
// 	noIncome: yup
// 		.boolean()
// 		.when('taxCreditRequestReason', {
// 			is: 'noIncome',
// 			then: yup.boolean().required('general.validation.required'),
// 			otherwise: yup.boolean(),
// 		})
// 		.nullable(),
// 	noIncomeFile: yup.array().when('noIncome', {
// 		is: true,
// 		then: yup.array().of(
// 			yup.object().shape({
// 				extraData: yup
// 					.object()
// 					.nullable()
// 					.test('testName', 'general.validation.required', function (
// 						value,
// 					) {
// 						console.log('general.validation.required', value);
// 						return (
// 							this.path !==
// 								`taxCreditRequest.noIncomeFile[0].extraData` ||
// 							this?.from[0]?.value
// 						);
// 					}),
// 			}),
// 		),
// 	}),
// 	hasAdditionalIncome: yup
// 		.boolean()
// 		.when('taxCreditRequestReason', {
// 			is: 'additionalIncome',
// 			then: yup.boolean().required('general.validation.required'),
// 			otherwise: yup.boolean(),
// 		})
// 		.nullable(),
// 	additionalIncome: yup.array().when('hasAdditionalIncome', {
// 		is: true,
// 		then: yup
// 			.array()
// 			.of(
// 				yup.object().shape({
// 					name: yup
// 						.string()
// 						// .matches(
// 						// 	alphaNumeriRegex,
// 						// 	'general.validation.invalidValue',
// 						// )
// 						.required('general.validation.required')
// 						.nullable(),
// 					address: yup
// 						.string()
// 						// .matches(
// 						// 	alphaNumeriRegex,
// 						// 	'general.validation.invalidValue',
// 						// )
// 						.required('general.validation.required')
// 						.nullable(),
// 					deductionFileId: yup
// 						.string()
// 						.matches(
// 							numberOnlyRegex,
// 							'general.validation.invalidValue',
// 						)
// 						.required('general.validation.required')
// 						.nullable(),
// 					incomeType: yup
// 						.string()
// 						.required('general.validation.required')
// 						.nullable(),
// 					monthlyIncome: yup
// 						.string()
// 						.matches(
// 							numberOnlyRegex,
// 							'general.validation.invalidValue',
// 						)
// 						.required('general.validation.required')
// 						.nullable(),
// 					taxReduce: yup
// 						.string()
// 						.matches(
// 							numberOnlyRegex,
// 							'general.validation.invalidValue',
// 						)
// 						.required('general.validation.required')
// 						.nullable(),
// 					additionalIncomeFile: yup.array().of(
// 						yup.object().shape({
// 							extraData: yup
// 								.object()
// 								.nullable()
// 								.test(
// 									'testName',
// 									'general.validation.required',
// 									function (value) {
// 										return (
// 											!this.path.includes(
// 												'additionalIncomeFile[0].extraData',
// 											) || this?.from[0]?.value
// 										);
// 									},
// 								),
// 						}),
// 					),
// 				}),
// 			)
// 			.min(1)
// 			.max(3),
// 	}),

// 	taxAssessorApproved: yup
// 		.boolean()
// 		.when('taxCreditRequestReason', {
// 			is: 'taxAssessorApproved',
// 			then: yup.boolean().required('general.validation.required'),
// 			otherwise: yup.boolean(),
// 		})
// 		.nullable(),
// 	taxAssessorApprovedFile: yup.array().when('taxCreditRequestReason', {
// 		is: 'taxAssessorApproved',
// 		then: yup.array().of(
// 			yup.object().shape({
// 				extraData: yup
// 					.object()
// 					.nullable()
// 					.test('testName', 'general.validation.required', function (
// 						value,
// 					) {
// 						return (
// 							this.path !==
// 								`taxCreditRequest.taxAssessorApprovedFile[0].extraData` ||
// 							this?.from[0]?.value
// 						);
// 					}),
// 			}),
// 		),
// 	}),
// });
