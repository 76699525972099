import { createStackNavigator } from '@react-navigation/stack';
import React, { useState } from 'react';
import BusinessUnits from './CustomerDetails/BusinessUnits/BusinessUnits.component.native';
import EditCustomerContainer from './EditCustomer/EditCustomer.container';
import RepCustomersContainer from './RepCustomers.container';
import { RepCustomersContext } from './repCustomersContext';

const Stack = createStackNavigator();

const RepCustomerWrapperMobile = () => {
	const [repCustomersData, updateRepCustomersData] = useState();

	return (
		<RepCustomersContext.Provider
			value={{ repCustomersData, updateRepCustomersData }}
		>
			<Stack.Navigator
				mode='modal'
				screenOptions={{ headerShown: false }}
			>
				<Stack.Screen
					name='list'
					options={{
						cardStyle: { backgroundColor: 'transparent' },
					}}
					component={RepCustomersContainer}
				/>
				<Stack.Screen
					name='businessUnits'
					options={{
						cardOverlayEnabled: true,
						cardStyle: { backgroundColor: 'transparent' },
						cardStyleInterpolator: ({ current: { progress } }) => ({
							overlayStyle: {
								opacity: 1,
								backgroundColor: 'transparent',
							},
						}),
					}}
					component={BusinessUnits}
				/>
				<Stack.Screen
					name='editCustomer'
					options={{
						cardOverlayEnabled: true,
						cardStyle: { backgroundColor: 'transparent' },
						cardStyleInterpolator: ({ current: { progress } }) => ({
							overlayStyle: {
								opacity: 1,
								backgroundColor: 'transparent',
							},
						}),
					}}
					component={EditCustomerContainer}
				/>
			</Stack.Navigator>
		</RepCustomersContext.Provider>
	);
};

export default RepCustomerWrapperMobile;
