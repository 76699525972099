import analytics from '@react-native-firebase/analytics';
import firestore from '@react-native-firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAnalytics, logEvent as logEventWeb } from 'firebase/analytics';
import moment from 'moment';
import DeviceInfo from 'react-native-device-info';
import {
	FIREBASE_WEB_API_KEY,
	FIREBASE_WEB_PROJECT_ID,
	FIREBASE_WEB_APP_ID,
} from '@env';
import { Platform } from 'react-native';
import { getState } from '../store/store';
import { getStoreDataByKey } from '../store/actions/login.actions';
import { AsyncLocalStorage } from '../config/localStorage';

console.log('initializeApp firebase web');
const app =
	Platform.OS === 'web' &&
	initializeApp({
		apiKey: FIREBASE_WEB_API_KEY,
		projectId: FIREBASE_WEB_PROJECT_ID,
		appId: FIREBASE_WEB_APP_ID,
	});
const db = app && getFirestore(app);
const analyticsWeb = app && getAnalytics(app);

export enum EventName {
	saveWorkerCardFailure = 'save_worker_card_failure',
	submitWorkerCardFailure = 'submit_worker_card_failure',
	loginFailure = 'login_failure',
	loginStep1Failure = 'login_step1_failure',
	loginStep2ResendFailure = 'login_step2_resend_failure',
	loginStep2Failure = 'login_step2_failure',
	getUserDataFailure = 'get_user_data_failure',
	userHasNoRoutesMobileNative = 'user_has_no_routes_mobile_native',
	employerSubmitWorkerCardFailure = 'employer_submit_worker_card_failure',
	apiFailure = 'api_failure',
	paycheckGenerateFailure = 'paycheck_generate_failure',
	paycheckDownloadFailure = 'paycheck_download_failure',
	getPdfRangeFailure = 'get_pdf_range_failure',
	getPdfFileFailure = 'get_pdf_file_failure',
	downloadPdfFileFailure = 'download_pdf_file_failure',
	openPdfFileFailure = 'open_pdf_file_failure',
	workerCardDownloadFailure = 'worker_card_download_failure',
	workerCardAttachmentsDownloadFailure = 'worker_card_attachments_download_failure',
}

export const logEvent = async ({
	eventName,
	data,
	logDataToAnalytics,
}: {
	eventName: EventName;
	data: any;
	logDataToAnalytics?: boolean;
}) => {
	try {
		const date = moment().format();
		const employerDataStore: string | undefined = await getStoreDataByKey(
			AsyncLocalStorage.EMPLOYER,
		);
		const employeeHp =
			(getState()?.login as any)?.employee?.hp ||
			(employerDataStore && JSON.parse(employerDataStore)?.hp) ||
			'';

		if (Platform.OS !== 'web') {
			await firestore()
				.collection('Logs')
				.doc(`${eventName}-${date}`)
				.set({
					data: JSON.stringify(data),
					userId: getState().login.user?.id,
					date,
					employeeHp,
					eventName,
					deviceId:
						Platform.OS === 'android'
							? DeviceInfo.getModel()
							: DeviceInfo.getDeviceId(),
					systemVersion: DeviceInfo.getSystemVersion(),
				});
			await analytics().logEvent(
				eventName,
				logDataToAnalytics ? data : {},
			);
		} else if (db && analyticsWeb) {
			// await setDoc(doc(db, 'Logs', `${eventName}-${date}`), {
			// 	data: JSON.stringify(data),
			// 	userId: getState().login.user?.id,
			// 	date,
			// eventName,
			// 	employeeHp,
			// 	deviceId: 'web',
			// });
			logEventWeb(
				analyticsWeb,
				eventName,
				logDataToAnalytics ? data : {},
			);
		}
	} catch (e) {
		console.log({ e });
	}
};
