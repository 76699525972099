import React, { useEffect, useState } from 'react';
import { i18nService } from '../../../services/i18n.service';
import Select from './Select.component';

interface SelectOption {
	id: string | number | undefined;
	name: string;
	stringId?: string;
}

interface SelectContainerProps {
	onChange: (obj: {
		name: string;
		value: string | undefined;
		isMulti: false;
	}) => void;
	config: {
		name: string;
		options: SelectOption[];
		placeholder?: string;
		styles?: any;
		defaultLabel?: string;
		defaultValue?: any;
		hideDefaultOption?: boolean;
		key?: any;
	};
	disabled?: boolean;
	defaultValue?: string | number | boolean;
}

const SelectContainer = ({
	onChange,
	config,
	disabled,
	defaultValue,
}: SelectContainerProps) => {
	const [selectedOption, setSelectedOption] = useState<
		SelectOption | undefined
	>();

	useEffect(() => {
		const value = defaultValue ?? config.defaultValue;
		const selected = config.options.find(
			i => i.name === value || i.id === value,
		);
		setSelectedOption(selected);
	}, [defaultValue, config.defaultValue, config.options]);

	const handleSelection = (value: SelectOption) => {
		value.id ? setSelectedOption(value) : setSelectedOption(undefined);
		onChange({ name: config.name, value: value.id, isMulti: false });
	};

	const opt =
		config.options.length && !config.hideDefaultOption
			? [
					{
						id: undefined,
						name:
							config.defaultLabel ||
							i18nService.translate('general.all'),
					},
					...config.options,
			  ]
			: config.options.length && config.hideDefaultOption
			? [...config.options]
			: [];
	return (
		<Select
			key={config.key}
			options={opt}
			placeholder={config.placeholder || ''}
			style={config.styles}
			selectedOption={selectedOption}
			onSelection={handleSelection}
			disabled={disabled}
		/>
	);
};

export default SelectContainer;
