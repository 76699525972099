import _ from 'lodash';
import * as yup from 'yup';

export const idValidation = function (item?: any) {
	let id = item;
	id = String(id).trim();
	if (!item || item === '') {
		return true;
	}
	if (
		//!_.compact(['0', '2', '7', '3']).includes(id[0]) ||
		id.length !== 9 ||
		isNaN(Number(id))
	) {
		return false;
	}
	id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
	return (
		Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) %
			10 ===
		0
	);
};

export const numberOnlyRegex = /^[0-9]*$/g;
const tikNikuimRegex = /9(\d{8})+/g;

export const licensedNumberValidation = function (licensedNumber?: any) {
	if (!licensedNumber || licensedNumber === '') {
		return true;
	}
	if (licensedNumber.length !== 9 || !licensedNumber.match(numberOnlyRegex)) {
		return false;
	}

	return true;
};

export const tikNikuimValidation = function (tikNikuim?: any) {
	if (!tikNikuim || tikNikuim === '') {
		return true;
	}
	if (!tikNikuim.match(tikNikuimRegex)) {
		return false;
	}

	return true;
};

export const alphaNumeriRegex = /^[\u0590-\u05FF 0-9a-zA-Z"\-']+$/i;

export const validationSchema = () => {
	return yup.object().shape({
		representId: yup.string().required('general.validation.required'),
		hp: yup
			.string()
			.test('idTest', 'general.validation.invalidValue', idValidation)
			.required('general.validation.required'),
		name: yup
			.string()
			.min(1, 'general.validation.invalidValue')
			.max(50, 'general.validation.invalidValue')
			.required('general.validation.required')
			.nullable(),
		address: yup
			.string()
			.required('general.validation.required')
			.nullable(),
		phone: yup
			.string()
			.matches(numberOnlyRegex, 'general.validation.invalidValue')
			.min(1, 'general.validation.invalidValue')
			.max(10, 'general.validation.invalidValue')
			.required('general.validation.required'),
		tikNikuim: yup
			.string()
			.test(
				'idTest',
				'general.validation.invalidValue',
				tikNikuimValidation,
			)
			.nullable(),
		licensedNumber: yup
			.string()
			.test(
				'idTest',
				'general.validation.invalidValue',
				licensedNumberValidation,
			)
			.nullable(),
		contactFirstName: yup
			.string()
			.matches(alphaNumeriRegex, 'general.validation.invalidValue')
			.min(1, 'general.validation.invalidValue')
			.max(50, 'general.validation.invalidValue')
			.required('general.validation.required')
			.nullable(),
		contactLastName: yup
			.string()
			.matches(alphaNumeriRegex, 'general.validation.invalidValue')
			.min(1, 'general.validation.invalidValue')
			.max(50, 'general.validation.invalidValue')
			.required('general.validation.required')
			.nullable(),
		contactEmail: yup
			.string()
			.required('general.validation.required')
			.matches(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				'general.validation.invalidValue',
			)
			.nullable(),
		contactCellPrefix: yup
			.string()
			.required('general.validation.required')
			.nullable(),
		contactCellPhone: yup
			.string()
			.matches(numberOnlyRegex, 'general.validation.invalidValue')
			.min(7, 'general.validation.invalidValue')
			.max(7, 'general.validation.invalidValue')
			.required('general.validation.required')
			.nullable(),
		operatorId: yup
			.number()
			.required('general.validation.required')
			.nullable(),
	});
};
