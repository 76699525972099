import React from 'react';
import I18n from '../../components/shared/i18n/I18n.component';
import ModalConfirmActions from '../../modals/ModalConfirmActions/ModalConfirmActions.component';
import httpService from '../../services/http.service';
import { modalService } from '../../services/modal.service';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import { WorkerCardModel } from '../../models/workerCard.model';
import { validationSchema } from './WorkerCardEditor/WorkerCardValidation';
import { onSubmission } from './WorkerCardEditor/workerCardEditor.utils';
import { EventName, logEvent } from '../../services/analyticsAndLogs.service';

export const onPressUpdate = async (
	navigation: any,
	formId: number,
	// statusCode: string,
) => {
	const statusCode: string = await httpService.api({
		type: 'getWorkerCardStatus',
		params: { formId },
	});
	if (statusCode === 'PENDING_EMPLOYER') {
		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},
				onSubmit: async (callback: Function) => {
					try {
						const res = await httpService.api({
							type: 'pullWorkerCardFromEmployer',
							params: { formId },
						});
						navigation.navigate('form101', {
							screen: 'editor',
							isNew101Form: false,
							formData: null,
							params: {
								formId,
								originalStatus: statusCode,
							},
						});
					} catch (e) {
						await modalService.openModal(
							null,
							{
								onCancel: (callback: Function) => {
									callback();
								},
								onSubmit: async (callback: Function) => {
									navigation.navigate('form101', {
										screen: 'editor',
										params: {
											formId,
											originalStatus: 'APPROVED',
										},
									});
									callback();
								},
								submitBtnText: 'workerCard.pull',
								cancelBtnText: 'workerCard.pull.cancel',
								iconName: 'question',
							},
							(props: any) => (
								<ModalConfirmActions {...props}>
									<I18n>workerCard.pull.approved</I18n>
								</ModalConfirmActions>
							),
						);
					} finally {
						callback();
					}
				},
				submitBtnText: 'workerCard.pull',
				cancelBtnText: 'workerCard.pull.cancel',
				iconName: 'question',
			},
			(props: any) => (
				<ModalConfirmActions {...props}>
					<I18n>workerCard.pull.text</I18n>
				</ModalConfirmActions>
			),
		);
	} else if (statusCode === 'APPROVED') {
		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},
				onSubmit: async (callback: Function) => {
					navigation.navigate('form101', {
						screen: 'editor',
						isNew101Form: false,
						formData: null,
						params: {
							formId,
							originalStatus: 'APPROVED',
						},
					});
					callback();
				},
				submitBtnText: 'workerCard.pull',
				cancelBtnText: 'workerCard.pull.cancel',
				iconName: 'question',
			},
			(props: any) => (
				<ModalConfirmActions {...props}>
					<I18n>workerCard.pull.approved</I18n>
				</ModalConfirmActions>
			),
		);
	} else {
		navigation.navigate('form101', {
			screen: 'editor',
			params: {
				formId,
				originalStatus: statusCode,
				formData: null,
				isNew101Form: false,
				screen: 'employerDetails',
			},
		});
	}
};

export const onPressCreate = async (navigation: any) => {
	try {
		const formData: any = await httpService.api({
			type: 'getDataForNew101Report',
		});

		navigation.navigate('form101', {
			screen: 'editor',
			params: {
				formId: formData.id,
				originalStatus: formData.statusCode,
				screen: 'employerDetails',
				isNew101Form: true,
				formData,
			},
		});
	} catch (e) {
		console.error(e);

		if (e.status === 400) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>worderCard.editor.error.cantCreateNewReport</I18n>
					</AlertModal>
				),
			);
		}
		console.log('errorStatus', e.status);
	}
};

export const onPressWorkerCardYear = (
	navigation: any,
	navigateToRoute: any,
	rest: any,
	statusCode: string,
) => {
	const data = JSON.parse(rest?.innerHeaderDataObj);

	if (['PENDING_EMPLOYER', 'APPROVED'].includes(statusCode)) {
		navigation.navigate(navigateToRoute, rest);
	} else {
		navigation.navigate('form101', {
			screen: 'editor',
			params: {
				isNew101Form: false,
				formData: null,
				formId: data?.id,
				originalStatus: statusCode,
				screen: 'employerDetails',
			},
		});
	}
};

export const onPressSubmit = async (formId: number, callback: Function) => {
	try {
		const statusCode: string = await httpService.api({
			type: 'getWorkerCardStatus',
			params: { formId },
		});

		if (
			['PENDING_EMPLOYEE_CORRECTION', 'PENDING_EMPLOYEE_SUBMIT'].includes(
				statusCode,
			)
		) {
			const fullData: any = await httpService.api({
				type: 'getWorkerCardData',
				params: { formId },
			});
			const list: any = await httpService.api({
				type: 'getTaxCreditAddressList',
			});

			const workerCard = new WorkerCardModel({
				...fullData,
				taxCreditAddressList: list,
			});
			const isValid = await validationSchema().isValid(workerCard);

			if (isValid) {
				onSubmission({ fullData, ignoreSave: true, close: callback });
			} else {
				logEvent({
					eventName: EventName.employerSubmitWorkerCardFailure,
					data: workerCard,
				});
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>workerCard.submit.error</I18n>
						</AlertModal>
					),
				);
			}
		} else {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>workerCard.submit.errorStatus</I18n>
					</AlertModal>
				),
			);
		}
	} catch (e) {
		console.log({ e });
	}
};
