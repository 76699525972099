import { i18nService } from '../../../services/i18n.service';
import Typography from '../../../components/shared/Typography/Typography.component';
import {
	getPeriodNumber,
	prepareRowNameForClient,
} from '../../../utils/reportsPagesHelpers.utils';
import React from 'react';
import { Period } from '../../../interfaces/redux.interface';
import moment from 'moment';
import { compact } from 'lodash';
import { getBusinessUnitsListByPermissions } from '../../../store/selectors/reportsFilters.selectors';
import { useSelector } from 'react-redux';
import routes from '../../../config/routes';

type Accessor =
	| 'name'
	| 'prev_balance'
	| 'new_balance'
	| 'used'
	| 'refund_days'
	| 'expander';

export interface TableHeader {
	Header: string;
	accessor: Accessor;
	Footer?: string;
	CustomCell?: (props: any) => void;
	SubCell?: (props: any) => void;
	isSortable?: boolean;
	id?: string;
	style?: any;
}

export const initialHeaders: TableHeader[] = [
	{
		accessor: 'expander',
		Header: '',
		id: 'expander',
		style: { width: 0 },
	},
	{
		accessor: 'name',
		Header: '',
		CustomCell: ({ value, row }: any) => {
			const businessUnitsList = useSelector((state: any) =>
				getBusinessUnitsListByPermissions(
					state,
					routes.vacationReports.privileges,
				),
			);

			return (
				<Typography weight='bold' size={14}>
					{value}
					{businessUnitsList.length > 1
						? ` (${row.original.business_unit_name})`
						: ''}
				</Typography>
			);
		},
		SubCell: ({ value, row }: any) => {
			let parsedValue;
			if (row.depth > 1) {
				parsedValue = value;
			} else {
				parsedValue = prepareRowNameForClient(value);
			}

			return (
				<Typography
					weight='bold'
					size={14}
					color='primary'
					style={{
						marginRight: '5px',
					}}
				>
					{parsedValue}
				</Typography>
			);
		},
	},
	{
		accessor: 'prev_balance',
		Header: i18nService.translate('vacationReportPage.table.prev_balance'),
		isSortable: true,
	},
	{
		accessor: 'refund_days',
		Header: i18nService.translate('vacationReportPage.table.refund_days'),
		isSortable: true,
	},
	{
		accessor: 'used',
		Header: i18nService.translate('vacationReportPage.table.used'),
		isSortable: true,
	},
	{
		accessor: 'new_balance',
		Header: i18nService.translate('vacationReportPage.table.new_balance'),
		isSortable: true,
	},
];
const periodOptions = [
	{
		id: 'MONTH',
		name: 'period.month',
	},
	{
		id: 'QUARTERLY',
		name: 'period.quaterly',
	},
	{ id: 'YEAR', name: 'period.year' },
];
export const getFiltersConfig = (
	period: any,
	departmentsList: any,
	selectedDepartments: any,
	getEmployeesFilter: Function,
	date: {
		toMonth: number;
		toYear: number;
		fromMonth: number;
		fromYear: number;
	},
	businessUnitsList: any,
	isBusinessUnitsMulti: boolean,
	unitIds: any,
	departmentFilterKey?: string,
) => {
	let dateTo =
		date.toMonth && date.toYear
			? new Date(date.toYear, date.toMonth - 1)
			: new Date();
	let dateFrom =
		date.fromMonth && date.fromYear
			? new Date(date.fromYear, date.fromMonth - 1)
			: new Date();

	const periodNumberTo = getPeriodNumber(date.toMonth);
	const periodNumberFrom = getPeriodNumber(date.fromMonth);

	let dateToPlaceholder;
	let dateFromPlaceholder;

	switch (period) {
		case Period.QUARTERLY:
			dateFromPlaceholder =
				i18nService.translate('filter.quarter') +
				' ' +
				periodNumberFrom +
				` ,${dateFrom.getFullYear()}`;
			dateToPlaceholder =
				i18nService.translate('filter.quarter') +
				' ' +
				periodNumberTo +
				` ,${dateTo.getFullYear()}`;

			break;
		case Period.YEAR:
			dateFromPlaceholder = `${dateFrom.getFullYear()}`;
			dateToPlaceholder = `${dateTo.getFullYear()}`;
			break;
		default:
			dateFromPlaceholder =
				i18nService.translate(
					`months.${moment(dateFrom).format('MMMM')}`,
				) + ` ${dateFrom.getFullYear()}`;
			dateToPlaceholder =
				i18nService.translate(
					`months.${moment(dateTo).format('MMMM')}`,
				) + ` ${dateTo.getFullYear()}`;
			break;
	}

	return compact([
		{
			name: 'employees',
			type: 'asyncmultiselect',
			placeholder: 'employees',
			loadOptions: getEmployeesFilter,
			styles: {
				width: 274,
			},
			key: `${JSON.stringify(
				selectedDepartments,
			)}${departmentsList}${JSON.stringify(unitIds)}`,
		},
		{
			name: 'departments',
			type: 'multiselect',
			options: departmentsList,
			placeholder: 'departments',
			styles: {
				width: 200,
			},
			key: departmentFilterKey,
		},
		{
			name: 'date_to',
			type: 'datepicker',
			date: dateTo,
			label: 'filter.to',
			placeholder: dateToPlaceholder,
			showYearPicker: period === Period.YEAR ? true : false,
			showQuarterYearPicker: period === Period.QUARTERLY ? true : false,
			styles: {
				width: 140,
			},
		},
		{
			name: 'date_from',
			type: 'datepicker',
			date: dateFrom,
			label: 'filter.from',
			placeholder: dateFromPlaceholder,
			showYearPicker: period === Period.YEAR ? true : false,
			showQuarterYearPicker: period === Period.QUARTERLY ? true : false,
			styles: {
				width: 140,
			},
		},
		{
			name: 'period',
			options: periodOptions,
			hideDefaultOption: true,
			defaultValue: 'period.month',
			label: 'filter.select.period.label',
			type: 'select',
			placeholder: 'period',
			styles: {
				width: 116,
			},
		},
		businessUnitsList?.length > 1
			? isBusinessUnitsMulti
				? {
						name: 'unitIds',
						type: 'multiselect',
						placeholder: 'businessUnits',
						value: unitIds?.map((unit: any) =>
							businessUnitsList.find(
								(bunit: any) => bunit.id === unit,
							),
						),
						options: businessUnitsList,
						styles: {
							control: {
								width: 200,
								maxWidth: 200,
							},
						},
				  }
				: {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
			: undefined,
	]);
};
export const pageFilterSize = 20;
export const maxNumberOfEmployeesForAccordion = 15;
export const employeesPageSize = 30;
