import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	StyleSheet,
	View,
	Dimensions,
	ScrollView,
	TouchableOpacity,
	Image,
	TouchableHighlight,
} from 'react-native';

import colors from '../../../../config/colors';
import I18n from '../../i18n/I18n.component';
import Typography from '../../Typography/Typography.component';
import httpService from '../../../../services/http.service';
import { convertFromUtc, calcTimerInitValue } from '../Clock.utils';
import { getServerDateTime } from '../../../../store/selectors/dateTime.selectors';
import moment from 'moment';
const imageEmptyState = require('../../../../../assets/images/reportEmptyState/report-empty.png');
import { toNumber } from 'lodash';
import { getTodayWorkLogs } from '../../../../store/selectors/clockReport.selector';
import WorkLog from './WorkLog.component';
import { getUserData } from '../../../../store/selectors/login.selectors';
import AddRequest from '../../../../modals/AddRequestModal/AddRequestModal.container';
import { modalService } from '../../../../services/modal.service';
import ModalConfirmActions from '../../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import { useRoute } from '@react-navigation/native';
import { toggleReFetch } from '../../../../store/actions/clockReport.actions';
export interface Record {
	workLogId: number;
	projectId: any;
	projectName: any;
	eventId: any;
	eventName: any;
	enterTime: any;
	exitTime: any;
	total: number;
}
interface Props {
	onClose?: Function;
	standardHours: number;
	aId: string;
}
const ReportsListComponent = ({ onClose, standardHours, aId }: Props) => {
	const { height, width } = Dimensions.get('window');
	const userData = useSelector(getUserData);
	const dispatch = useDispatch();
	const route = useRoute();
	const currentServerTime = useSelector(getServerDateTime);
	const todayWorkLogs = useSelector(getTodayWorkLogs);

	const convertHoursFormat = (
		minutesValue: any,
		format?: string,
		inminutes?: boolean,
	) => {
		if (minutesValue) {
			const value = inminutes
				? minutesValue
				: Math.floor(minutesValue * 60);
			if (format === 'HH:mm') {
				const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
				const minutes =
					value % 60 !== 0
						? value % 60 < 10
							? `0${value % 60}`
							: `${value % 60}`
						: '00';
				return `${hours}:${minutes}`;
			}
			return `${(value / 60).toFixed(2)}`;
		}
		return '00:00';
	};

	const handleDelete = async id => {
		await modalService.openModal(
			null,

			{
				onCancel: (callback: Function) => {
					callback();
				},

				onSubmit: async (callback: Function) => {
					try {
						const res = await httpService.api<any>({
							type: 'deleteEmployeeReportByEmployee',
							params: { workLogId: id },
						});
					} catch (error) {
						console.log(error);
					}
					dispatch(toggleReFetch());
					callback();
				},

				submitBtnText: 'general.true',

				cancelBtnText: 'general.false',

				iconName: 'question',
			},

			(props: any) => (
				<ModalConfirmActions {...props}>
					<I18n>modal.deleteReport.message</I18n>
				</ModalConfirmActions>
			),
		);
	};

	const handleEdit = async (id, workLog) => {
		const fullName = `${userData?.first_name} ${userData?.last_name}`;
		let logValues = {};
      	if (id && !workLog) {
        	logValues = await httpService.api<any>({
          	type: "workLogByIdEmployee",
          	params: { workLogId: id },
        	});
      	}
		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},
				onSubmit: async (callback: Function) => {
					dispatch(toggleReFetch());
					callback();
				},

				submitBtnText: 'general.accept',
				route: route,
				cancelBtnText: 'general.cancel',
				isEdit: true,
				openEditModal: handleEdit,
				workLogId: id,
				isManager: false,
				prevValues: {
					...workLog || logValues,
					originalExitEntry: {
						entryTime:id && !workLog?logValues.entryTime : workLog.enterTime,
						exitTime:id && !workLog? logValues.exitTime : workLog.exitTime
					},
					entryTime: convertFromUtc(workLog ? workLog.enterTime : logValues.entryTime, true),
					exitTime: convertFromUtc( workLog ? workLog.exitTime : logValues.exitTime, true),
					employee: {
						id: aId,
						name: fullName,
					},
					fullName,
					date: moment().format('YYYY-MM-DD'),
				},
				totalReported,
				shiftHours: userData?.customer_setting.maxShiftHour,
			},
			(props: any) => <AddRequest {...props} />,
		);
	};

	const recordsOnView = useMemo(
		() =>
			todayWorkLogs.data.length ? (
				todayWorkLogs.data.map((record: Record) => (
					<WorkLog
						isToday={true}
						key={record.workLogId}
						record={record}
						onDelete={handleDelete}
						onEdit={handleEdit}
					/>
				))
			) : (
				<View
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						flex: 1,
						backgroundColor: colors.white,
					}}
				>
					<Image
						source={imageEmptyState}
						fadeDuration={0}
						style={{
							height: 86,
							width: 178,
							marginTop: 50,
						}}
					/>
					<I18n size={18} color={'darkGrey'} weight='400'>
						reportView.noWorklogsToday
					</I18n>
				</View>
			),
		[todayWorkLogs],
	);

	const convertToHoursFormat = time => {
		if (!time) return '00:00';
		const [hours, minutes, seconds] = time.split(':');
		return `${toNumber(hours).toLocaleString()}:${minutes}`;
	};

	const totalReported = todayWorkLogs.data.reduce(
		(total: number, worklog: Record) => {
			return total + worklog.total / 60;
		},
		0,
	);

	const isTotalReportedSmallerThanStandard = useMemo(() => {
		let standard = 0;
		if (standardHours?.fixHours) {
			standard =
				moment(standardHours.fixHours, 'HH:mm:ss').diff(
					moment('00:00', 'HH:mm'),
					'minutes',
				) / 60;
		}

		return totalReported < standard;
	}, [totalReported, standardHours]);

	const standard = useMemo(() => {
		return convertToHoursFormat(standardHours?.fixHours);
	}, [standardHours]);
	return (
		<View>
			{onClose ? (
				<View
					style={[
						styles.buttonContainer,
						{
							alignSelf: 'center',
							borderTopRightRadius: 98,
							borderTopLeftRadius: 98,
						},
					]}
				>
					<TouchableHighlight
						style={styles.button}
						underlayColor='transparent'
						onPress={() => onClose?.()}
					>
						<I18n size={14} weight={'normal'} color='white'>
							general.closing
						</I18n>
					</TouchableHighlight>
				</View>
			) : null}
			<View
				style={{
					height: height - 210,
					width,
					backgroundColor: colors.lightBlue,
				}}
			>
				<View style={styles.header}>
					<View
						style={{ flexDirection: 'row', alignItems: 'center' }}
					>
						<Typography size={18} color={'darkGrey'} weight='normal'>
							{convertFromUtc(
								currentServerTime,
								false,
								true,
								'YYYY-MM-DDTHH:mm',
								'DD/MM/YY',
							)}
						</Typography>
						<View style={styles.verticalRecordLine} />
						<Typography size={18} color={'darkGrey'} weight='normal'>
							{convertFromUtc(
								currentServerTime,
								true,
								false,
								'YYYY-MM-DDTHH:mm',
							)}
						</Typography>
					</View>
					<View style={{ flexDirection: 'row' }}>
						<Typography
							size={18}
							color={
								standard === '0:00'
									? 'darkGrey'
									: isTotalReportedSmallerThanStandard
									? 'red'
									: 'green'
							}
							weight='normal'
						>
							{convertHoursFormat(totalReported, 'HH:mm', false)}
						</Typography>
						<Typography
							size={18}
							color={'darkGrey'}
							weight='normal'
						>
							{`/${convertToHoursFormat(
								standardHours?.fixHours,
							)}`}
						</Typography>
					</View>
				</View>
				<I18n
					style={{
						textAlign: 'right',
						padding: 10,
						paddingHorizontal: 15,
						height: 50,
						backgroundColor: colors.white,
					}}
					weight={'normal'}
					size={18}
				>
					reportView.todayDayReports
				</I18n>

				<ScrollView
					style={{
						backgroundColor: todayWorkLogs.data.length
							? colors.lightBlue
							: colors.white,
						height: '100%',
					}}
				>
					{recordsOnView}
				</ScrollView>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	buttonContainer: {
		height: 38,
		width: 140,
		backgroundColor: colors.warning,
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: { width: 197, alignItems: 'center', justifyContent: 'center' },
	report101Container: {
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
		marginBottom: 30,
	},
	header: {
		backgroundColor: colors.white,
		width: '100%',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 10,
		paddingHorizontal: 25,
	},

	report: {
		marginVertical: 10,
		backgroundColor: colors.white,
		height: 88,
		paddingHorizontal: 15,
		paddingVertical: 5,
		width: '100%',
	},
	reportTopSection: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	reportBottomSection: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	verticalRecordLine: {
		opacity: 0.2,
		height: 30,
		width: 0,
		borderLeftColor: '#525962',
		borderLeftWidth: 2,
		marginHorizontal: 10,
	},
});

export default ReportsListComponent;
