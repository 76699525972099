import React from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import colors from '../../../../config/colors';
import classnames from 'classnames';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { renderRowSubComponent } from './Row.component';

const useStyles = makeStyles((theme: any) => ({
	subRow: {
		background: '#fff',
		height: 58,
	},
	clickable: {
		cursor: 'pointer',
	},
	subCell: {
		background: '#fff',
		fontFamily: 'RubikRegular',
		fontSize: 14,
		padding: '8px 16px 8px 8px',
		lineHeight: '1rem',
		textAlign: 'right',
		color: colors.primary,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
}));

const defaultStickyRight = {
	position: 'sticky',
	zIndex: 3,
	boxShadow: '-3px 0 0 0 #0000001e',
};

type OuterClasses = {
	subRowRoot?: string;
	subCellRoot?: string;
};

export const SubRows = ({
	row,
	rowProps,
	onSubRowClick,
	outerClasses,
	prepareRow,
	onLeftArrowClick,
	onDownArrowClick,
}: {
	row: any;
	rowProps: any;
	onSubRowClick: Function;
	outerClasses?: OuterClasses;
	prepareRow: Function;
	onLeftArrowClick: any;
	onDownArrowClick: any;
}) => {
	const classes = useStyles();
	return (
		<>
			{row.subRows.map((subRow: any, i: number) => {
				prepareRow(subRow);
				return (
					<>
						<TableRow
							{...rowProps}
							onClick={() => onSubRowClick(subRow.original)}
							classes={{
								root: classnames(
									classes.subRow,
									outerClasses?.subRowRoot,
									{
										[classes.clickable]:
											subRow.original.isClickable,
									},
								),
							}}
							style={{
								// ...(rowProps.style || {}),
								...(subRow.original.style || {}),
							}}
						>
							{subRow.cells.map((cell: any) => {
								return (
									<TableCell
										{...cell.getCellProps()}
										classes={{
											root: classnames(
												classes.subCell,
												outerClasses?.subCellRoot,
											),
										}}
										style={{
											...cell.getCellProps().style,
											...cell.column.style,
											...(cell.column.sticky === 'right'
												? defaultStickyRight
												: {}),
											...(subRow.original.style || {}),
											...(cell.column.subCellStyle || {}),
										}}
									>
										{cell.column.id === 'expander' ? (
											subRow.canExpand ||
											subRow.original.canHaveSubRows ? (
												<span
													{...subRow.getToggleRowExpandedProps(
														{
															style: {
																position:
																	'relative',
															},
														},
													)}
												>
													{subRow.isExpanded ? (
														<ArrowDropDownIcon
															color='inherit'
															onClick={e =>
																onDownArrowClick(
																	e,
																	subRow,
																)
															}
														/>
													) : (
														<ArrowLeftIcon
															color='inherit'
															classes={{}}
															onClick={e =>
																onLeftArrowClick(
																	e,
																	subRow,
																)
															}
															// style={{
															//   color: '#aaa',
															// }}
														/>
													)}
												</span>
											) : null
										) : (
											cell.render(
												cell.column.SubCell
													? 'SubCell'
													: 'Cell',
												{
													value: cell.value,
													row: {
														...subRow,
														original: subRow,
													},
												},
											)
										)}
									</TableCell>
								);
							})}
						</TableRow>
						{subRow.isExpanded &&
							renderRowSubComponent({
								row: subRow,
								rowProps,
								outerClasses,
								onSubRowClick,
								onLeftArrowClick,
								onDownArrowClick,
								prepareRow,
							})}
					</>
				);
			})}
		</>
	);
};
