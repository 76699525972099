import React, { useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import UsersTablePage from './UsersTablePage/UsersTablePage.container';
import UserRolesAndPermissions from './UserRolesAndPermissionsPage/UserRolesAndPermissions.container';
import { getDocumentTitle } from '../../utils/globals.utils';
import { createStackNavigator } from '@react-navigation/stack';
import { UserManagementContext } from './userManagementContext';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { Editor } from './CreateUpdateUser/CreateUpdateUserMobile/EditorWrapper.component';

type UsersManagmentProps = {
	navigation: any;
};

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const Users = () => {
	const [userManagementData, updateUserManagementData] = useState();

	return (
		<UserManagementContext.Provider
			value={{ userManagementData, updateUserManagementData }}
		>
			<Stack.Navigator
				mode='modal'
				screenOptions={{ headerShown: false }}
			>
				<Stack.Screen
					name='table'
					component={UsersTablePage}
					options={{
						title: getDocumentTitle('usersTable'),
					}}
				/>
				<Stack.Screen
					name='editor'
					component={Editor}
					options={{
						title: getDocumentTitle('usersEditor'),
					}}
				/>
			</Stack.Navigator>
		</UserManagementContext.Provider>
	);
};

const UsersManagmentPage = ({ navigation }: UsersManagmentProps) => {
	const platform = useDevicePlatform();

	return (
		<>
			<Tab.Navigator
				tabBar={() => null}
				initialRouteName='users'
				screenOptions={{ tabBarStyle: { display: 'none' } } as any}
				sceneContainerStyle={{ backgroundColor: 'transparent' }}
			>
				<Tab.Screen
					name='users'
					component={
						platform === DeviceType.WEB ? UsersTablePage : Users
					}
					options={{
						unmountOnBlur: true,
						title: getDocumentTitle('users'),
					}}
				/>
				<Tab.Screen
					name='rolesAndPermissions'
					component={UserRolesAndPermissions}
					options={{
						unmountOnBlur: true,
						title: getDocumentTitle('rolesAndPermissions'),
					}}
				/>
			</Tab.Navigator>
		</>
	);
};

export default UsersManagmentPage;
