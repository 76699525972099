import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useSelector } from 'react-redux';
import colors from '../../../config/colors';
import { getBusyCounter } from '../../../store/selectors/config.selectors';

const Loader = () => {
	const busyCounter = useSelector(getBusyCounter);
	return busyCounter ? (
		<View style={styles.background}>
			<ActivityIndicator color={colors.primary} size='large' />
		</View>
	) : null;
};

const styles = StyleSheet.create({
	background: {
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: colors.backgroundOpacity,
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: 99,
	},
});
export default Loader;
