import { useRoute } from '@react-navigation/native';
import React, { useContext, useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import { setHideChat } from '../../../../store/actions/config.actions';
import { dispatch } from '../../../../store/store';
import { goBack } from '../../../../utils/goBack.utils';
import { UserManagementContext } from '../../userManagementContext';
import CreateUserComponent from './CreateUpdateUser.component';
import useBusinessUnitsState from '../../../../hooks/useBusinessUnitsState';
import CreateUpdateUserWrapper from '../CreateUpdateUserWrapper.container';
import { Privileges } from '../../../../constants/roleTypes.constant';

const CreateUser = ({ navigation }: any) => {
	const route: any = useRoute();
	const { updateUserManagementData } = useContext(UserManagementContext);

	const { businessUnitsList } = useBusinessUnitsState({
		privileges: [
			Privileges.ROLE_MGM_USER_WRITE,
			Privileges.ROLE_MGM_USER_READ,
			Privileges.ROLE_CREATE_USER_WRITE,
			Privileges.ROLE_CREATE_USER_READ,
		],
	});
	const urlParams: any = useMemo(
		() =>
			Platform.OS === 'web' &&
			new URL((document as any).location).searchParams,
		[Platform.OS === 'web' && document.location],
	);

	const id = useMemo(() => {
		return (
			route.params?.id ||
			route.params?.params?.id ||
			(Platform.OS === 'web' && urlParams?.get('id'))
		);
	}, [route.params?.id, route.params?.params?.id, urlParams]);

	const unitId = useMemo(() => {
		return (
			route.params?.businessUnitId ||
			route.params?.params?.businessUnitId ||
			(Platform.OS === 'web' && urlParams?.get('businessUnitId'))
		);
	}, [
		route.params?.businessUnitId,
		route.params?.params?.businessUnitId,
		urlParams,
	]);

	const callback = (data: any) => {
		updateUserManagementData?.(data || { shouldRefresh: true });
	};
	const onNext = () => {
		goBack(navigation);
	};

	useEffect(() => {
		return () => {
			dispatch(setHideChat(false));
		};
	}, []);

	return !id ? (
		<CreateUpdateUserWrapper
			Component={CreateUserComponent}
			{...{
				id,
				onNext,
				callback,
				route,
				pageName: 'users',
				businessUnitsList,
			}}
		/>
	) : (
		<CreateUpdateUserWrapper
			Component={CreateUserComponent}
			{...{
				id,
				onNext,
				callback,
				route,
				unitId,
				pageName: 'users',
				businessUnitsList,
			}}
		/>
	);
};

export default CreateUser;
