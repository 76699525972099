import React, { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import { makeStyles } from '@material-ui/core';
import { prepareRowNameForClient } from '../../../../utils/reportsPagesHelpers.utils';
import { colors } from './CostCompareReportPage.utils';

type CostCompareReportPageProps = {
	navigation?: any;
	data: any[];
	periods: any[];
	businessUnitsList?: any[];
};

const useStyles = makeStyles(theme => {
	return {
		wrapper: {
			background: '#fff',
			fontFamily: 'RubikRegular',
			padding: '40px 10% 60px 10%',
			height: '100%',
			fontSize: '0.875rem',
		},
	};
});

const CostCompareDashboardPage = ({
	navigation,
	data,
	periods,
	businessUnitsList,
}: CostCompareReportPageProps) => {
	const { height } = useWindowDimensions();
	const classes = useStyles();

	const preparedData = useMemo(
		() =>
			periods
				.filter((period: string) => !period.includes('-'))
				.map((period: string) => {
					const preparedPeriod: any = {};
					data.forEach((el: any) => {
						let periodData;
						periodData = el[period] ? el[period].split(' ') : null;
						preparedPeriod[
							`${el.name}${
								businessUnitsList.length
									? ` (${el.business_unit_name})`
									: ''
							}`
						] = periodData
							? parseFloat(periodData[0].replace(/,/g, ''))
							: 'אין נתונים';
					});
					preparedPeriod.name = prepareRowNameForClient(period, true);

					return preparedPeriod;
				}),
		[periods, data],
	);

	const bars = data
		.map(
			el =>
				`${el.name}${
					businessUnitsList.length
						? ` (${el.business_unit_name})`
						: ''
				}`,
		)
		.map((el, i) => {
			if (i > colors.length) {
				const delta = i / colors.length;
				return (
					<Bar
						dataKey={el}
						fill={colors[i - colors.length * delta]}
					/>
				);
			}
			return <Bar dataKey={el} fill={colors[i]} />;
		});

	const formatYAxis = (tickItem: any) => {
		return `₪ ${tickItem}`;
	};

	return (
		<div className={classes.wrapper}>
			<ResponsiveContainer width='100%' height='100%'>
				<BarChart
					width={500}
					height={300}
					data={preparedData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
					barCategoryGap='15%'
					barGap={10}
				>
					<CartesianGrid vertical={false} />
					<XAxis dataKey='name' tickMargin={10} tickLine={false} />
					<YAxis
						tickFormatter={formatYAxis}
						tickMargin={10}
						tickLine={false}
					/>
					<Tooltip />
					<Legend
						iconSize={18}
						iconType='circle'
						wrapperStyle={{ bottom: -20 }}
					/>
					{bars}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default CostCompareDashboardPage;
