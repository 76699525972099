import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import OutlineButtonContainer from '../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import SelectContainer from '../../../components/web/Select/Select.container';
import { i18nService } from '../../../services/i18n.service';
import FieldBuilder from '../../../components/shared/FieldBuilder/FieldBuilder';
import SelectGroup from '../../../components/shared/SelectGroup/SelectGroup.component';
import AsyncMultiSelectContainer from '../../../components/shared/AsyncMultiSelect/AsyncMultiSelect.container';
import { getComponentTypeBeforeSend } from '../ByEmployeeform/FromMonthToMonthReportsSalaryDataFormByEmployee.utils';
import { fromMonthToMonthListLike } from '../../../models/fromMonthToMonth.model';
import httpService from '../../../services/http.service';
import { modalService } from '../../../services/modal.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import Typography from '../../../components/shared/Typography/Typography.component';

interface RequestFieldsProps {
	type: string;
	list: Array<object>;
	onCancel: Function;
	onSubmit: Function;
	submitBtnText: string;
	cancelBtnText: string;
	buttonsCustomStyle: Object;
	onNext: Function;
	flexTableData: Array<object>;
	error: string;
	disabled: boolean;
	getEmployeesFilter: Function;
	maxParameter: number;
	total: number;
	getDepartmentsFilter: Function;
	editMode: any;
	selectUnitId: any;
}

interface FormInputs<T> {
	[key: string]: T;
}

const schema = yup.object().shape({
	amount: yup.string().typeError('').required(''),
	rate: yup.string().typeError('').required(''),
	value: yup.string().typeError('').required(''),
	prev: yup.string().typeError('').required(''),
});

const initialValues = {
	amount: '',
	rate: '',
	value: '',
	prev: '',
};

const AddNewWage = ({
	list,
	onCancel,
	onSubmit,
	submitBtnText,
	cancelBtnText,
	buttonsCustomStyle,
	onNext,
	error,
	disabled,
	getEmployeesFilter,
	maxParameter,
	total,
	getDepartmentsFilter,
	selectUnitId,
	editMode,
}: RequestFieldsProps) => {
	const [listName, setListName] = useState(editMode?.componentType);
	const [code, setCode] = useState(editMode?.code);
	const [action, setAction] = useState('');
	const [employee, setEmployee] = useState('ALL');
	const [field, setField] = useState('');
	const [lineName, setLineName] = useState(null);
	const [disabledAmount, setDisabledAmount] = useState(false);
	const [disabledValue, setDisabledValuet] = useState(false);
	const [toFixed, setToFixed] = useState(2);
	const [userIds, setUserIds] = useState([]);
	const [excludeMode, setExcludeMode] = useState(false);
	const [theList, setTheList] = useState([]);
	const [asyncSelectedUsers, setAsyncSelectedUsers] = useState([]);
	const [departmentIds, setDepartmentIds] = useState([]);
	const [asyncSelectedDepartment, setAsyncSelectedDepartment] = useState([]);
	const [departmentsTotal, setDepartmentsTotal] = useState(0);

	const formMethods = useForm<FormInputs<string | null>>({
		defaultValues: initialValues,
		mode: 'onBlur',
		reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	});

	const getComponentsList = () => {
		if (!listName) return [];
		const sortlist = list[listName];
		const sortListArr = Object.entries(sortlist).sort((a: any, b: any) => {
			if (a[1].code < b[1].code) return -1;
			if (a[1].code > b[1].code) return 1;
			return 0;
		});

		const readyList = sortListArr.map((line: any) => line[1]);

		const showList =
			listName === 'headerAdditionalData' ? sortlist : readyList;

		return showList?.map((el: any) => ({
			id: el.code,
			name:
				listName === 'headerAdditionalData'
					? el.name
					: `${el.code} - ${el.name}`,
		}));
	};

	const getSectionList = () => {
		return [
			{
				id: 'headerAdditionalData',
				name: i18nService.translate(
					'FromMonthToMonthReports.formats.AdditionalData.header',
				),
			},
			{
				id: 'wageComponent',
				name: i18nService.translate(
					'FromMonthToMonthReports.formats.WageComponent.header',
				),
			},
			{
				id: 'optionalDeductionsComponent',
				name: i18nService.translate(
					'FromMonthToMonthReports.formats.OptionalDeductions.header',
				),
			},
		];
	};
	const getFieldList = () => {
		return [
			{
				id: 'amount',
				name: i18nService.translate(
					'FromMonthToMonthReports.formats.WageComponent.amount',
				),
			},
			{
				id: 'rate',
				name: i18nService.translate(
					'FromMonthToMonthReports.formats.WageComponent.rate',
				),
			},
			{
				id: 'value',
				name: i18nService.translate(
					'FromMonthToMonthReports.formats.WageComponent.sum',
				),
			},
		];
	};

	const getActionList = () => {
		const actionList = [
			{
				id: 'UPDATE',
				name: i18nService.translate(
					'FromMonthToMonthReports.comprehensive.editWage.selectAction.UPDATE',
				),
			},
			{
				id: 'MANUAL',
				name: i18nService.translate(
					'FromMonthToMonthReports.comprehensive.editWage.selectAction.MANUAL',
				),
			},
			{
				id: 'CHANGE',
				name: i18nService.translate(
					'FromMonthToMonthReports.comprehensive.editWage.selectAction.CHANGE',
				),
			},
			{
				id: 'RESET',
				name: i18nService.translate(
					'FromMonthToMonthReports.comprehensive.editWage.selectAction.RESET',
				),
			},
		];
		if (editMode) {
			actionList.splice(1, 1);
		}
		return actionList;
	};
	const getEmployeeOptions = () => {
		return [
			{
				value: 'ALL',
				label: i18nService.translate(
					'FromMonthToMonthReports.comprehensive.editWage.selectEmployees.option.ALL',
				),
			},
			{
				value: 'DEPARTMENTS',
				label: i18nService.translate(
					'FromMonthToMonthReports.comprehensive.editWage.selectEmployees.option.DEPARTMENTS',
				),
			},
			{
				value: 'ONLYSOME',
				label: i18nService.translate(
					'FromMonthToMonthReports.comprehensive.editWage.selectEmployees.option.ONLYSOME',
				),
			},
		];
	};

	const handleSelectSection = (select: any) => {
		formMethods.reset();
		setDisabledValuet(false);
		setDisabledAmount(false);
		setAction('');
		setCode('');
		setListName(select.value);
	};

	const handleSelectWage = (select: any) => {
		formMethods.reset();
		formMethods.setValue('value', '');
		formMethods.setValue('prev', '');
		formMethods.setValue('amount', '');
		formMethods.setValue('rate', '');
		setDisabledValuet(false);
		setDisabledAmount(false);
		setCode(select.value);
	};
	const handleSelectAction = (select: any) => {
		formMethods.reset();
		formMethods.setValue('value', '');
		formMethods.setValue('prev', '');
		formMethods.setValue('amount', '');
		formMethods.setValue('rate', '');
		setDisabledValuet(false);
		setDisabledAmount(false);
		setAction(select.value);
	};

	const handleSelectEmployee = (select: any) => {
		setDepartmentIds([]);
		setUserIds([]);
		setEmployee(select);
	};

	const getWageName = (action: string) => {
		if (action === 'CHANGE') return 'prev';
		return 'rate';
	};
	const getWagePlaceholder = (
		action: string,
		field: string,
		item?: string,
	) => {
		if (field === 'rate') {
			if (action === 'CHANGE')
				return 'filter.select.FromMonthToMonthReports.comprehensive.editWage.prev.placeholder';
			return 'FromMonthToMonthReports.formats.WageComponent.rate';
		} else {
			if (action === 'CHANGE')
				return 'filter.select.FromMonthToMonthReports.comprehensive.editWage.new.placeholder';
			if (item === 'wageComponent')
				return 'FromMonthToMonthReports.formats.WageComponent.sum';
			return 'filter.select.FromMonthToMonthReports.comprehensive.editWage.selectAction.b.placeholder';
		}
	};

	const getNameByCode = (
		code: string,
		name: string,
		onlyName?: boolean,
	): string => {
		if (name === 'headerAdditionalData') {
			return i18nService.translate(
				`FromMonthToMonthReports.formats.AdditionalData.${code}`,
			);
		} else {
			const item = list[name]?.find((el: any) => {
				return el.code === code;
			});
			if (onlyName) {
				return item.name;
			}
			return `${item.code} - ${item.name}`;
		}
	};

	const handleInputValue = async (
		setValue: any,
		getValues: any,
		c: any,
		d: any,
		e: any,
		f: any,
		lineName: string,
	) => {
		setLineName(lineName);
	};

	const handleOnBlur = async () => {
		const data = formMethods.getValues();
		if (listName === 'wageComponent' && action === 'UPDATE') {
			if (
				(lineName === 'amount' || lineName === 'rate') &&
				(data.amount || data.rate)
			) {
				setDisabledValuet(true);
				setDisabledAmount(false);
				formMethods.setValue('value', '');
			} else if (
				(lineName === 'amount' || lineName === 'rate') &&
				!data.amount &&
				!data.rate
			) {
				setDisabledValuet(false);
				setDisabledAmount(false);
			} else if (lineName === 'value' && data.value) {
				setDisabledValuet(false);
				setDisabledAmount(true);
				formMethods.setValue('amount', '');
				formMethods.setValue('rate', '');
			} else if (lineName === 'value' && !data.value) {
				setDisabledValuet(false);
				setDisabledAmount(false);
			}
		}
		formMethods.clearErrors();
	};

	const handleSelectField = (select: any) => {
		formMethods.setValue('value', '');
		formMethods.setValue('prev', '');
		setField(select.value);
	};

	const updateUsers = (users: any) => {
		setDepartmentIds([]);
		setTheList(users.theList);
		const newUsers = users?.value?.map((user: any) => user.id);
		setUserIds(newUsers);
		setAsyncSelectedUsers(
			users.value.map(i => ({ value: i.id, label: i.name })),
		);

		setExcludeMode(users.excludeMode.current);
	};

	const updateDepartment = (departments: any) => {
		setUserIds([]);
		const newDepartments = departments?.value?.map(
			(department: any) => department.id,
		);
		getTheNumberOfUsersInThisDepartments(newDepartments, selectUnitId);
		setDepartmentIds(newDepartments);
		setAsyncSelectedDepartment(
			departments.value.map(i => ({ value: i.id, label: i.name })),
		);
	};

	const getTheNumberOfUsersInThisDepartments = (
		newDepartments: any,
		selectUnitId: any,
	) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getFromMonthToMonthReportsList',
				query: { unitId: selectUnitId?.[0] },
				disableBI: false,
				data: {
					f: { dep: newDepartments, user_id: [], status: [] },
					p: 0,
					ps: 15,
					s: '',
					sd: 'DESC',
					search: '',
				},
			})
			.then(async (res: any) => {
				if (res) {
					setDepartmentsTotal(res.total);
				}
			});
	};

	const isErrorsBeforeSubmit = (disabled: boolean) => {
		if (!listName) return true;
		else if (!code) return true;
		else if (!action) return true;
		else if (!employee) return true;

		const formFields = formMethods.getValues();
		if (listName !== 'wageComponent' && action === 'UPDATE') {
			if (!formFields.value) {
				return true;
			}
		} else if (listName === 'wageComponent' && action === 'UPDATE') {
			if (!formFields.value && !formFields.amount && !formFields.rate)
				return true;
		} else if (listName === 'wageComponent' && action === 'CHANGE') {
			if (!field) return true;
			else if (!formFields.value || !formFields.prev) return true;
			else if (formFields.value === formFields.prev) return true;
			else if (field === 'amount' && !Number(formFields.value)) {
				return true;
			} else if (field === 'rate' && !Number(formFields.value)) {
				return true;
			}
		} else if (listName !== 'wageComponent' && action === 'CHANGE') {
			if (!formFields.value || !formFields.prev) return true;
			if (formFields.value === formFields.prev) return true;
		}

		if (
			formFields.value?.toString() === '.' ||
			formFields.amount?.toString() === '.' ||
			formFields.rate?.toString() === '.' ||
			formFields.prev?.toString() === '.' ||
			formFields.value?.toString() === '-' ||
			formFields.amount?.toString() === '-' ||
			formFields.rate?.toString() === '-' ||
			formFields.prev?.toString() === '-'
		) {
			return true;
		}

		if (employee !== 'ALL' && action !== 'MANUAL') {
			if (userIds.length === 0 && departmentIds.length === 0) return true;
		}

		return disabled;
	};

	const selectStyle = {
		control: {
			maxWidth: '100%',
			width: '100%',
			marginTop: 3,
			height: 40,
		},
		menu: {
			top: 39,
			boxShadow: 'none',
			border: `solid 1px #e7e7e8`,
			height: 200,
		},
		menuList: {
			height: 192,
			minHeight: 192,
		},
		placeholder: {
			paddingRight: 20,
		},
		newInput: {
			paddingRight: 20,
		},
	};

	const getTheExcludeUsers = (userIds: any) => {
		const allUsersList = theList.map((item: any) => item.value);
		return allUsersList.filter(x => userIds.indexOf(x) === -1);
	};

	const handleOnSubmit = async () => {
		const getformData: any = formMethods.getValues();
		const formData: any = {};
		formData.action = action;
		if (listName === 'headerAdditionalData') {
			formData[code] = action === 'RESET' ? 0 : getformData.value;
		} else {
			formData.code = code;
			formData.componentType = getComponentTypeBeforeSend(listName);
			if (action !== 'RESET') {
				formData.value = getformData.value ? getformData.value : null;
				if (action === 'UPDATE' && listName === 'wageComponent') {
					formData.amount = getformData.amount
						? getformData.amount
						: null;
					formData.rate = getformData.rate ? getformData.rate : null;
				} else if (action === 'CHANGE') {
					if (listName === 'wageComponent') {
						formData[field] = getformData.value;
						if (field !== 'value') {
							formData.value = null;
						}
					}
				}
			}
		}
		if (action === 'CHANGE') {
			formData.prev = getformData.prev;
		}

		formData.dep = departmentIds.find(item => item === 'all')
			? []
			: departmentIds;
		formData.employees = userIds.find(item => item === 'all')
			? []
			: excludeMode
			? getTheExcludeUsers(userIds)
			: userIds;
		formData.includeEmployees = !excludeMode;
		formData.name = getNameByCode(code, listName, true);

		if (action !== 'MANUAL') {
			if (
				(employee === 'ALL' && total > maxParameter) ||
				(employee === 'ONLYSOME' &&
					formData.employees.length === 0 &&
					total > maxParameter) ||
				(employee === 'ONLYSOME' && userIds.length > maxParameter) ||
				(employee === 'DEPARTMENTS' && departmentsTotal > maxParameter)
			) {
				return await modalService.openModal(
					null,
					{
						onSubmit: async (callback: Function) => {
							callback();
						},
						submitBtnText: 'general.back',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<Typography
								style={{ textAlign: 'center' }}
								size={16}
							>
								{`${i18nService.translate(
									'FromMonthToMonthReportsReportPage.comprehensive.maxParameter.modal.msg.a',
								)}${maxParameter} ${i18nService.translate(
									'FromMonthToMonthReportsReportPage.comprehensive.maxParameter.modal.msg.b',
								)}`}
							</Typography>
						</AlertModal>
					),
				);
			}
		}

		onSubmit(
			onNext,
			action,
			getNameByCode(code, listName),
			code,
			listName,
			formData,
		);
	};

	return (
		<View style={styles.container}>
			<View style={styles.title}>
				<I18n size={30} weight='normal' color='darkGrey'>
					{'FromMonthToMonthReports.comprehensive.editWage.title'}
				</I18n>
			</View>
			<Divider
				style={{
					backgroundColor: colors.darkGrey,
					opacity: 1,
					marginVertical: 0,
				}}
			/>
			<FormProvider {...formMethods}>
				<View style={styles.stepAcontainer}>
					<I18n
						size={14}
						weight='normal'
						color='darkGrey'
						style={{ paddingHorizontal: 5 }}
					>
						{
							'FromMonthToMonthReports.comprehensive.editWage.selectWage.title'
						}
					</I18n>
					<View style={styles.stepBody}>
						<View style={styles.selectionWrap}>
							<SelectContainer
								config={{
									name: 'selectSection',
									options: getSectionList(),
									placeholder:
										'FromMonthToMonthReports.comprehensive.editWage.selectWage.a.placeholder',
									styles: {
										width: '100%',
										height: 40,
										borderColor: colors.filterBorder,
										backgroundColor: colors.white,
										opacity: disabled || editMode ? 0.5 : 1,
									},
									key: 'selectSection',
									hideDefaultOption: true,
									defaultValue: listName,
								}}
								onChange={handleSelectSection}
								disabled={editMode}
							/>
						</View>
						<View style={styles.selectionWrap}>
							<SelectContainer
								config={{
									name: 'selectWage',
									options: getComponentsList(),
									placeholder:
										'FromMonthToMonthReports.comprehensive.editWage.selectWage.b.placeholder',
									styles: {
										width: '100%',
										height: 40,
										borderColor: colors.filterBorder,
										backgroundColor: colors.white,
										opacity:
											disabled ||
											!listName ||
											editMode?.code
												? 0.5
												: 1,
									},
									key: 'wageComponents',
									hideDefaultOption: true,
									defaultValue: code,
								}}
								onChange={handleSelectWage}
								disabled={
									disabled || !listName || editMode?.code
								}
							/>
						</View>
					</View>
				</View>
				<View style={styles.stepBcontainer}>
					<I18n
						size={14}
						weight='normal'
						color='darkGrey'
						style={{ paddingHorizontal: 5 }}
					>
						{
							'FromMonthToMonthReports.comprehensive.editWage.selectAction.title'
						}
					</I18n>
					<View style={styles.stepBody}>
						<View style={[styles.selectionWrap, { width: '100%' }]}>
							<SelectContainer
								config={{
									name: 'selectAction',
									options: getActionList(),
									placeholder:
										'FromMonthToMonthReports.comprehensive.editWage.selectAction.placeholder',
									styles: {
										width: '100%',
										height: 40,
										borderColor: colors.filterBorder,
										backgroundColor: colors.white,
										opacity: disabled || !code ? 0.5 : 1,
									},
									key: 'selectAction',
									hideDefaultOption: true,
									defaultValue: action,
								}}
								onChange={handleSelectAction}
								disabled={disabled || !code}
							/>
						</View>
						<View style={styles.selectionWrap}></View>
					</View>
				</View>
				{action ? (
					<View style={styles.information}>
						<I18n
							size={12}
							weight='normal'
							color='darkGrey'
							style={{ lineHeight: 18 }}
						>
							{`FromMonthToMonthReports.comprehensive.editWage.selectAction.${action}.info`}
						</I18n>
					</View>
				) : null}
				{(action === 'UPDATE' || action === 'CHANGE') &&
					listName &&
					code && (
						<View style={styles.inputContainer}>
							{listName === 'wageComponent' &&
								action === 'CHANGE' && (
									<View
										style={[
											styles.inputWrap,
											{
												width: '33%',
											},
										]}
									>
										<SelectContainer
											config={{
												name: 'selectField',
												options: getFieldList(),
												placeholder:
													'FromMonthToMonthReports.comprehensive.editWage.selectField.placeholder',
												styles: {
													width: '100%',
													height: 40,
													borderColor:
														colors.filterBorder,
													backgroundColor:
														colors.white,
													opacity: disabled ? 0.5 : 1,
												},
												key: 'selectField',
												hideDefaultOption: true,
												defaultValue: field,
											}}
											onChange={handleSelectField}
											disabled={false}
										/>
									</View>
								)}
							{listName === 'wageComponent' &&
								action === 'UPDATE' && (
									<View
										style={[
											styles.inputWrap,
											{
												width:
													listName === 'wageComponent'
														? '33%'
														: '50%',
											},
										]}
									>
										<FieldBuilder
											styles={{
												wrapper: {
													width: '100%',
													height: 40,
													fontSize: '14px',
													margin: 0,
													padding: 0,
													opacity: disabledAmount
														? 0.5
														: 1,
												},
												labelWrapper: {
													height: 0,
													marginTop: 0,
													marginRight: 0,
													marginLeft: 0,
													marginBottom: 0,
													padding: 0,
												},
												errorCustomStyle: {
													backgroundColor:
														colors.white,
													position: 'relative',
													maxWidth: 900,
													zIndex: 999,
													whiteSpace: 'nowrap',
													top: -3,
													marginTop: 0,
												},
											}}
											height={'100%'}
											paddingVertical={0}
											backgroundColor={colors.white}
											borderColor={colors.filterBorder}
											name={'amount'}
											type='input'
											disabled={disabledAmount}
											placeholder={
												'FromMonthToMonthReports.formats.WageComponent.amount'
											}
											onBlur={handleOnBlur}
											onChange={handleInputValue}
											keyboardType={'numeric'}
											toFixed={toFixed}
											forceMin={true}
											maxLength={10}
										/>
									</View>
								)}
							{(listName === 'wageComponent' ||
								action === 'CHANGE') && (
								<View
									style={[
										styles.inputWrap,
										{
											width:
												listName === 'wageComponent'
													? '33%'
													: '50%',
										},
									]}
								>
									<FieldBuilder
										styles={{
											wrapper: {
												width: '100%',
												height: 40,
												fontSize: '14px',
												margin: 0,
												padding: 0,
												opacity: disabledAmount
													? 0.5
													: 1,
											},
											labelWrapper: {
												height: 0,
												marginTop: 0,
												marginRight: 0,
												marginLeft: 0,
												marginBottom: 0,
												padding: 0,
											},
											errorCustomStyle: {
												backgroundColor: colors.white,
												position: 'relative',
												maxWidth: 900,
												zIndex: 999,
												whiteSpace: 'nowrap',
												top: -3,
												marginTop: 0,
											},
										}}
										height={'100%'}
										paddingVertical={0}
										backgroundColor={colors.white}
										borderColor={colors.filterBorder}
										name={getWageName(action)}
										type='input'
										placeholder={getWagePlaceholder(
											action,
											'rate',
										)}
										disabled={disabledAmount}
										onBlur={handleOnBlur}
										onChange={handleInputValue}
										keyboardType={'numeric'}
										toFixed={toFixed}
										forceMin={true}
										maxLength={10}
									/>
								</View>
							)}
							<View
								style={[
									styles.inputWrap,
									{
										width:
											listName === 'wageComponent'
												? '33%'
												: '50%',
									},
								]}
							>
								<FieldBuilder
									styles={{
										wrapper: {
											width: '100%',
											height: 40,
											fontSize: '14px',
											margin: 0,
											padding: 0,
											opacity: disabledValue ? 0.5 : 1,
										},
										labelWrapper: {
											height: 0,
											marginTop: 0,
											marginRight: 0,
											marginLeft: 0,
											marginBottom: 0,
											padding: 0,
										},
										errorCustomStyle: {
											backgroundColor: colors.white,
											position: 'relative',
											maxWidth: 900,
											zIndex: 999,
											whiteSpace: 'nowrap',
											top: -3,
											marginTop: 0,
										},
									}}
									height={'100%'}
									paddingVertical={0}
									backgroundColor={colors.white}
									borderColor={colors.filterBorder}
									name={'value'}
									type='input'
									placeholder={getWagePlaceholder(
										action,
										'value',
										listName,
									)}
									disabled={disabledValue}
									onBlur={handleOnBlur}
									onChange={handleInputValue}
									keyboardType={'numeric'}
									toFixed={toFixed}
									forceMin={true}
									maxLength={10}
								/>
							</View>
						</View>
					)}
				{action !== 'MANUAL' && (
					<View style={styles.stepCcontainer}>
						<I18n
							size={14}
							weight='normal'
							color='darkGrey'
							style={{ paddingHorizontal: 5 }}
						>
							{
								'FromMonthToMonthReports.comprehensive.editWage.selectEmployees.title'
							}
						</I18n>
						<View style={styles.stepBody}>
							<View style={styles.radioWrap}>
								<SelectGroup
									disabled={disabled}
									name='employeeType'
									options={getEmployeeOptions()}
									onChange={handleSelectEmployee}
									value={employee}
									isMobile={false}
								/>
							</View>
						</View>
						{employee !== 'ALL' && (
							<View style={styles.listWrap}>
								<AsyncMultiSelectContainer
									key={employee}
									config={{
										name:
											employee === 'ONLYSOME'
												? 'employees'
												: 'departments',
										loadOptions:
											employee === 'ONLYSOME'
												? getEmployeesFilter
												: getDepartmentsFilter,
										placeholder:
											employee === 'ONLYSOME'
												? 'employees'
												: 'departments',
										isAllValues:
											employee === 'ONLYSOME'
												? true
												: false,
									}}
									styles={selectStyle}
									onChange={
										employee === 'ONLYSOME'
											? updateUsers
											: updateDepartment
									}
									menuIsOpen
									forceShowResults
								/>
							</View>
						)}
					</View>
				)}
			</FormProvider>
			{error && (
				<I18n
					size={16}
					style={{ minHeight: 40, textAlign: 'center' }}
					weight='normal'
					color='red'
				>
					{error}
				</I18n>
			)}
			<Divider
				style={{
					backgroundColor: colors.darkGrey,
					opacity: 0.2,
					marginVertical: 24,
				}}
			/>
			<View style={styles.actionButtons}>
				<SolidButtonContainer
					overrideStyle={[styles.buttonOk, buttonsCustomStyle]}
					onPress={handleOnSubmit}
					disabled={isErrorsBeforeSubmit(disabled)}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='white'
					>
						{submitBtnText}
					</I18n>
				</SolidButtonContainer>
				<OutlineButtonContainer
					overrideStyle={[styles.buttonCancel, buttonsCustomStyle]}
					onPress={() => onCancel(onNext)}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='primary'
					>
						{cancelBtnText}
					</I18n>
				</OutlineButtonContainer>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	stepAcontainer: {
		paddingTop: 30,
		paddingBottom: 10,
		zIndex: 4,
	},
	stepBcontainer: {
		zIndex: 3,
	},
	stepCcontainer: {
		marginTop: 10,
		zIndex: 1,
	},
	stepBody: {
		flex: 1,
		marginVertical: 10,
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	inputContainer: {
		marginVertical: 10,
		width: '100%',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		zIndex: 2,
	},
	inputWrap: {
		paddingHorizontal: 5,
		width: '100%',
	},
	radioWrap: {
		width: '100%',
		marginTop: -15,
		alignItems: 'flex-start',
		paddingHorizontal: 10,
	},
	listWrap: {
		width: '100%',
		height: 210,
	},
	selectionWrap: {
		paddingHorizontal: 5,
		width: '50%',
	},
	information: {
		padding: 15,
		marginHorizontal: 5,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: colors.filterBorder,
	},
	title: {
		marginBottom: 20,
	},
	select: {
		width: '100%',
	},
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	buttonOk: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
		paddingHorizontal: 15,
		padding: 10,
	},
	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		padding: 10,
	},
});

export default AddNewWage;
