import { compact } from 'lodash';
import { phonePrefixOptions } from '../../../../utils/globals.utils';

export const getFields = (
	businessUnitsList: any[],
	id: number,
	isMobile: boolean,
	setValue: any,
) => {
	return compact([
		businessUnitsList?.length > 1
			? {
					name: 'representId',
					type: 'reactselect',
					label: 'editCustomer.representId',
					options: businessUnitsList,
					blurInputOnSelect: true,
					maxMenuHeight: 200,
					isRequired: true,
					styles: {
						wrapper: {
							width: isMobile ? '100%' : 195,
							zIndex: 10,
							opacity: !!id ? 0.6 : 1,
						},
					},
					disabled: !!id,
			  }
			: undefined,
		{
			name: 'hp',
			type: 'input',
			label: 'editCustomer.hp',
			isRequired: true,
			maxLength: 9,
			placeholder: 'enterCustomer.hp.placeholder',
			keyboardType: 'numeric',
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
					opacity: !!id ? 0.6 : 1,
				},
			},
			disabled: !!id,
			onBlur: () => {
				setValue('operatorId', -1);
			},
		},
		{
			name: 'name',
			type: 'input',
			label: 'editCustomer.name',
			isRequired: true,
			maxLength: 50,
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
				},
			},
		},

		{
			name: 'address',
			type: 'googlePlacesAutocomplete',
			label: 'editCustomer.address',
			isRequired: true,
			showFullName: true,
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
					zIndex: 10,
				},
			},
		},
		{
			name: 'phone',
			type: 'input',
			label: 'editCustomer.phone',
			isRequired: true,
			maxLength: 10,
			keyboardType: 'numeric',
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
				},
			},
		},

		{
			name: 'tikNikuim',
			type: 'input',
			label: 'editCustomer.tikNikuim',
			maxLength: 9,
			keyboardType: 'numeric',
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
				},
			},
		},
		{
			name: 'licensedNumber',
			type: 'input',
			label: 'editCustomer.licensedNumber',
			maxLength: 9,
			keyboardType: 'numeric',
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
				},
			},
		},
		// ],
	]);
};

export const getContactFields = (isMobile: boolean) => {
	const phonePrefix = {
		type: 'reactselect',
		name: 'contactCellPrefix',
		options: phonePrefixOptions,
		styles: {
			wrapper: {
				marginBottom: isMobile ? undefined : 20,
				minWidth: 40,
				width: isMobile ? '100%' : 80,
				zIndex: 10,
			},
		},
		width: isMobile && '100%',
		style: {
			minWidth: 40,
		},
	};
	return compact([
		{
			name: 'contactFirstName',
			type: 'input',
			label: 'editCustomer.contactFirstName',
			isRequired: true,
			maxLength: 50,
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
				},
			},
		},
		{
			name: 'contactLastName',
			type: 'input',
			label: 'editCustomer.contactLastName',
			isRequired: true,
			maxLength: 50,
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
				},
			},
		},

		{
			name: 'contactEmail',
			type: 'input',
			label: 'editCustomer.contactEmail',
			isRequired: true,
			maxLength: 50,
			styles: {
				wrapper: {
					width: isMobile ? '100%' : 195,
				},
			},
		},
		isMobile && phonePrefix,
		{
			type: 'input',
			name: 'contactCellPhone',
			label: 'editCustomer.contactCellPhone',
			isRequired: true,
			hasEditableColorText: true,
			styles: {
				wrapper: {
					marginBottom: isMobile ? undefined : 20,
					width: isMobile ? '100%' : 110,
					zIndex: 11,
				},
				labelWrapper: {
					flexDirection: 'row',
				},
			},
		},
		!isMobile && phonePrefix,
	]);
};

export const getOperatorFields = (options: any[], isMobile?: boolean) => [
	{
		name: 'operatorId',
		type: 'reactselect',
		label: 'editCustomer.operatorName',
		options: options,
		blurInputOnSelect: true,
		maxMenuHeight: 200,
		isRequired: true,
		styles: {
			wrapper: {
				width: isMobile ? '100%' : 195,
				zIndex: 10,
			},
		},
	},
];
