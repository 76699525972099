import React, { useMemo } from 'react';
import { View } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { FAB } from 'react-native-paper';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import icons from '../../../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../../services/i18n.service';
import ActionItem from './ActionButton';

const defaultActionStyles = {
	margin: 0,
};

const renderTaxYear = (taxYear: number) => {
	return () => (
		<I18n size={14} weight={'normal'} color='darkGrey' taxYear={taxYear}>
			workerCard.editor.outdatedText
		</I18n>
	);
};

const buttons = {
	outdatedText: {
		name: 'outdatedText',
		style: {
			backgroundColor: 'white',
			width: 300,
			borderRadius: 0,
			boxShadow: 'none',
		},
		color: 'black',
		text: '',
		render: () => <></>,
		// text: i18nService.translate('workerCard.editor.outdatedText') + `/2020`,
		// render: () => (
		// 	<>
		// 		<Typography size={14} color='darkGrey' weight={'normal'}>
		// 			`2020/`
		// 		</Typography>
		// 		<I18n size={14} weight={'normal'} color='darkGrey'>
		// 			workerCard.editor.outdatedText
		// 		</I18n>
		// 	</>
		// ),
	},
	save: {
		name: 'save',
		style: { backgroundColor: colors.lightPrimary },
		text: i18nService.translate('general.save'),
		render: () => (
			<ActionItem
				color={colors.primary}
				icon={icons.save}
				text={i18nService.translate('general.save')}
			/>
		),
		...defaultActionStyles,
	},
	cancel: {
		name: 'cancel',
		color: colors.primary,
		style: {
			borderWidth: 1,
			borderColor: colors.primary,
			backgroundColor: colors.white,
		},
		text: i18nService.translate('general.cancel'),
		render: () => (
			<ActionItem
				color={colors.white}
				buttonStyle={{ borderWidth: 1, borderColor: colors.primary }}
				icon={icons.back}
				text={i18nService.translate('general.cancel')}
			/>
		),
		...defaultActionStyles,
	},
	back: {
		name: 'back',
		color: colors.primary,
		style: {
			borderWidth: 1,
			borderColor: colors.primary,
			backgroundColor: colors.white,
		},
		text: i18nService.translate('general.back'),
		render: () => (
			<ActionItem
				color={colors.white}
				buttonStyle={{ borderWidth: 1, borderColor: colors.primary }}
				icon={icons.back}
				text={i18nService.translate('general.back')}
			/>
		),
		...defaultActionStyles,
	},
	submission: {
		name: 'submission',
		style: { backgroundColor: colors.warning },
		text: i18nService.translate('general.saveAndSubmission'),
		render: () => (
			<ActionItem
				color={colors.green}
				icon={icons.checked}
				text={i18nService.translate('general.saveAndSubmission')}
			/>
		),
		...defaultActionStyles,
	},
	remove: {
		name: 'remove',
		style: { backgroundColor: colors.warning },
		text: i18nService.translate('general.remove'),
		render: () => (
			<ActionItem
				color={colors.green}
				icon={icons.checked}
				text={i18nService.translate('general.remove')}
			/>
		),
		...defaultActionStyles,
	},
	rejection: {
		...defaultActionStyles,
		render: () => (
			<ActionItem
				color={colors.red}
				icon={icons.reject}
				text={i18nService.translate('general.rejection')}
			/>
		),
		style: { backgroundColor: colors.lightPrimary },
		text: i18nService.translate('general.rejection'),
		name: 'rejection',
	},
	accept: {
		style: { backgroundColor: colors.warning, width: 140 },
		text: i18nService.translate('workerCard.viewer.button'),
		name: 'approve',
		render: () => (
			<ActionItem
				color={colors.green}
				icon={icons.checked}
				text={i18nService.translate('workerCard.viewer.button')}
			/>
		),
		...defaultActionStyles,
	},
};

const ActionButtons = ({
	dispatch = () => {},
	isEmployee,
	cancel = () => {},
	save = () => {},
	submission = () => {},
	reject = () => {},
	approve = () => {},
	back = () => {},
	readOnly,
	outdatedText,
	taxYear,
}: any) => {
	const platform = useDevicePlatform();
	const isWeb = useMemo(() => platform === DeviceType.WEB, [platform]);
	const employeeActions = useMemo(
		() => [buttons.cancel, buttons.submission, buttons.save],
		[],
	);

	const onButtonClick = (buttonName?: string) => {
		switch (buttonName) {
			case 'cancel':
				cancel();
				break;
			case 'save':
				save();
				break;
			case 'submission':
				submission();
				break;
			case 'rejection':
				reject();
				break;
			case 'approve':
				approve();
				break;
			case 'back':
				back();
				break;
			default:
				dispatch({ type: buttonName });
		}
	};

	const addYearToTaxYear = (taxYear: any) => {
		return Number(taxYear) + 1;
	};

	const employerActions = useMemo(() => {
		if (readOnly) {
			if (outdatedText && taxYear) {
				buttons.outdatedText.text = i18nService.translate(
					'workerCard.editor.outdatedText',
					undefined,
					{ taxYear: addYearToTaxYear(taxYear) },
				);
				buttons.outdatedText.render = renderTaxYear(
					addYearToTaxYear(taxYear),
				);
			}
			return outdatedText
				? [buttons.back, buttons.outdatedText]
				: [buttons.back];
		} else {
			return [buttons.back, buttons.rejection, buttons.accept];
		}
	}, []);

	return isWeb ? (
		<>
			{[...(isEmployee ? employeeActions : employerActions)].map(
				({ name, text, style = {}, color }: any, index) => (
					<FAB
						key={name}
						style={{
							position: 'absolute',
							margin: 16,
							left: index ? index * (125 + 23) : 0,
							width: 125,
							bottom: 15,
							...style,
						}}
						icon=''
						small
						color={color || colors.white}
						label={text}
						onPress={() => onButtonClick(name)}
					/>
				),
			)}
		</>
	) : (
		<FloatingAction
			buttonSize={70}
			distanceToEdge={{ horizontal: 20, vertical: 25 }}
			iconWidth={20}
			iconHeight={20}
			color={colors.lightPrimary}
			actions={isEmployee ? employeeActions : employerActions}
			overlayColor='transparent'
			position='left'
			onPressItem={onButtonClick}
		/>
	);
};

export default ActionButtons;
