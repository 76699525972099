import MonthYearFormat from './monthYearFormat';

const AdditionalData = (data: any) => {
	const elements = [
		'header',
		'payworkdays',
		'workdays',
		'workingHours',
		'vacationUsed',
		'sickLeaveUsed',
		'comments',
	];
	const initialValues: any = {};
	const format: any = {};
	elements.forEach((element: string) => {
		if (element === 'header') {
			format.HeaderAdditionalData = {
				header: [
					{
						name: 'HeaderAdditionalData',
						value: 'FromMonthToMonthReports.formats.AdditionalData.header',
						addNew: false,
						type: element,
					},
				],

				currentMonth: [
					{
						name: 'currenMonth',
						value: MonthYearFormat(data?.draft?.yearMonth),
						type: element,
					},
				],

				lastMonth: data.paycheck.map((el: any, index: number) => {
					return [
						{
							name: 'lastMonth',
							value: MonthYearFormat(
								data?.paycheck?.[index].yearMonth,
							),
							type: element,
						},
					];
				}),
			};
		} else {
			initialValues[element] = data?.draft?.[element] || '';
			if (element !== 'comments') {
				format[element] = {
					header: [
						{
							name: `${element}_title`,
							value: getNameByElement(element),
							type: 'text',
						},
					],

					currentMonth: [
						{
							name: element,
							value: data?.draft?.[element] || '',
							type: 'input',
							placeholder: '0.00',
						},
					],

					lastMonth: data.paycheck.map((el: any, index: number) => {
						return [
							{
								name: `${element}_last_${el.yearMonth}`,
								value: data?.paycheck?.[index][element] || '',
								type: 'text',
							},
						];
					}),
				};
			}
		}
	});
	return { format, initialValues };
};

const getNameByElement = (element: string): string => {
	const elements = [
		{
			value: 'FromMonthToMonthReports.formats.AdditionalData.payworkdays',
			name: 'payworkdays',
		},
		{
			value: 'FromMonthToMonthReports.formats.AdditionalData.workdays',
			name: 'workdays',
		},
		{
			value: 'FromMonthToMonthReports.formats.AdditionalData.workingHours',
			name: 'workingHours',
		},
		{
			value: 'FromMonthToMonthReports.formats.AdditionalData.vacationUsed',
			name: 'vacationUsed',
		},
		{
			value: 'FromMonthToMonthReports.formats.AdditionalData.sickLeaveUsed',
			name: 'sickLeaveUsed',
		},
		{
			value: 'FromMonthToMonthReports.formats.AdditionalData.comments',
			name: 'comments',
		},
	];
	const name = elements.find(el => el.name === element);
	return name.value;
};

export default AdditionalData;
