export enum Privileges {
	ROLE_LOGOUT = 'ROLE_LOGOUT',
	ROLE_MGM_USER_READ = 'ROLE_MGM_USER_READ',
	ROLE_MGM_USER_WRITE = 'ROLE_MGM_USER_WRITE',
	ROLE_LOG_WORK_READ = 'ROLE_LOG_WORK_READ',
	ROLE_LOG_WORK_WRITE = 'ROLE_LOG_WORK_WRITE',
	ROLE_LOG_WORK_REPORT_READ = 'ROLE_LOG_WORK_REPORT_READ',
	ROLE_LOG_WORK_REPORT_WRITE = 'ROLE_LOG_WORK_REPORT_WRITE',
	ROLE_LOCATION_READ = 'ROLE_LOCATION_READ',
	ROLE_LOCATION_WRITE = 'ROLE_LOCATION_WRITE',
	ROLE_MY_PAYCHECKS_READ = 'ROLE_MY_PAYCHECKS_READ',
	ROLE_MY_PAYCHECKS_WRITE = 'ROLE_MY_PAYCHECKS_WRITE',
	ROLE_EMP_PAYCHECKS_READ = 'ROLE_EMP_PAYCHECKS_READ',
	ROLE_EMP_PAYCHECKS_WRITE = 'ROLE_EMP_PAYCHECKS_WRITE',
	ROLE_MY_10001_READ = 'ROLE_MY_10001_READ',
	ROLE_MY_10001_WRITE = 'ROLE_MY_10001_WRITE',
	ROLE_MY_106_READ = 'ROLE_MY_106_READ',
	ROLE_MY_106_WRITE = 'ROLE_MY_106_WRITE',
	ROLE_EMP_10001_READ = 'ROLE_EMP_10001_READ',
	ROLE_EMP_10001_WRITE = 'ROLE_EMP_10001_WRITE',
	ROLE_VACATION_REPORT_READ = 'ROLE_VACATION_REPORT_READ',
	ROLE_VACATION_REPORT_WRITE = 'ROLE_VACATION_REPORT_WRITE',
	ROLE_COSTING_REPORT_READ = 'ROLE_COSTING_REPORT_READ',
	ROLE_COSTING_REPORT_WRITE = 'ROLE_COSTING_REPORT_WRITE',
	ROLE_CREATE_USER_READ = 'ROLE_CREATE_USER_READ',
	ROLE_CREATE_USER_WRITE = 'ROLE_CREATE_USER_WRITE',
	ROLE_EMPLOYEE_FORM_READ = 'ROLE_EMPLOYEE_FORM_READ',
	ROLE_EMPLOYEE_FORM_WRITE = 'ROLE_EMPLOYEE_FORM_WRITE',
	ROLE_EMPLOYEE_FORM_REPORT_READ = 'ROLE_EMPLOYEE_FORM_REPORT_READ',
	ROLE_EMPLOYEE_FORM_REPORT_WRITE = 'ROLE_EMPLOYEE_FORM_REPORT_WRITE',
	ROLE_VACATION_REQUEST_READ = 'ROLE_VACATION_REQUEST_READ',
	ROLE_VACATION_REQUEST_WRITE = 'ROLE_VACATION_REQUEST_WRITE',
	ROLE_SICKNESS_REQUEST_READ = 'ROLE_SICKNESS_REQUEST_READ',
	ROLE_SICKNESS_REQUEST_WRITE = 'ROLE_SICKNESS_REQUEST_WRITE',
	ROLE_MNG_VAC_REQUEST_READ = 'ROLE_MNG_VAC_REQUEST_READ',
	ROLE_MNG_VAC_REQUEST_WRITE = 'ROLE_MNG_VAC_REQUEST_WRITE',
	ROLE_MNG_SICK_REQUEST_READ = 'ROLE_MNG_SICK_REQUEST_READ',
	ROLE_MNG_SICK_REQUEST_WRITE = 'ROLE_MNG_SICK_REQUEST_WRITE',
	ROLE_EMPLOYER_WAGE_REPORT_WRITE = 'ROLE_EMPLOYER_WAGE_REPORT_WRITE',
	ROLE_EMPLOYER_WAGE_REPORT_READ = 'ROLE_EMPLOYER_WAGE_REPORT_READ',
	ROLE_CUSTOMER_SETTING_WRITE = 'ROLE_CUSTOMER_SETTING_WRITE',
	ROLE_CUSTOMER_SETTING_READ = 'ROLE_CUSTOMER_SETTING_READ',
	ROLE_LOCATION_ASSOCIATION_READ = 'ROLE_LOCATION_ASSOCIATION_READ',
	ROLE_LOCATION_ASSOCIATION_WRITE = 'ROLE_LOCATION_ASSOCIATION_WRITE',
	ROLE_REP_HOME_PAGE_READ = 'ROLE_REP_HOME_PAGE_READ',
	ROLE_REP_HOME_PAGE_WRITE = 'ROLE_REP_HOME_PAGE_WRITE',
	ROLE_REP_CUSTOMER_READ = 'ROLE_REP_CUSTOMER_READ',
	ROLE_REP_CUSTOMER_WRITE = 'ROLE_REP_CUSTOMER_WRITE',
	ROLE_CREATE_REP_CUSTOMER_READ = 'ROLE_CREATE_REP_CUSTOMER_READ',
	ROLE_CREATE_REP_CUSTOMER_WRITE = 'ROLE_CREATE_REP_CUSTOMER_WRITE',
	ROLE_REP_ACCESS_CUSTOMER_READ = 'ROLE_REP_ACCESS_CUSTOMER_READ',
	ROLE_REP_ACCESS_CUSTOMER_WRITE = 'ROLE_REP_ACCESS_CUSTOMER_WRITE',
	ROLE_REP_PAYCHECK_REPORT_READ = 'ROLE_REP_PAYCHECK_REPORT_READ',
	ROLE_REP_PAYCHECK_REPORT_WRITE = 'ROLE_REP_PAYCHECK_REPORT_WRITE',
	ROLE_REP_ORG_REPORT_READ = 'ROLE_REP_ORG_REPORT_READ',
	ROLE_REP_ORG_REPORT_WRITE = 'ROLE_REP_ORG_REPORT_WRITE',
	ROLE_REP_ORG_126_REPORT_READ = 'ROLE_REP_ORG_126_REPORT_READ',
	ROLE_REP_ORG_126_REPORT_WRITE = 'ROLE_REP_ORG_126_REPORT_WRITE',
	ROLE_REP_FORM_106_REPORT_READ = 'ROLE_REP_FORM_106_REPORT_READ',
	ROLE_REP_FORM_106_REPORT_WRITE = 'ROLE_REP_FORM_106_REPORT_WRITE',
	ROLE_REP_FORM_101_REPORT_READ = 'ROLE_REP_FORM_101_REPORT_READ',
	ROLE_REP_FORM_101_REPORT_WRITE = 'ROLE_REP_FORM_101_REPORT_WRITE',
	ROLE_REP_WORK_PAPER_REPORT_READ = 'ROLE_REP_WORK_PAPER_REPORT_READ',
	ROLE_REP_WORK_PAPER_REPORT_WRITE = 'ROLE_REP_WORK_PAPER_REPORT_WRITE',
	ROLE_MY_DOCS_READ = 'ROLE_MY_DOCS_READ',
	ROLE_MY_DOCS_WRITE = 'ROLE_MY_DOCS_WRITE',
	ROLE_MNG_DOCS_READ = 'ROLE_MNG_DOCS_READ',
	ROLE_MNG_DOCS_WRITE = 'ROLE_MNG_DOCS_WRITE',
	ROLE_DRAFT_WAGE_REPORT_READ = 'ROLE_DRAFT_WAGE_REPORT_READ',
	ROLE_DRAFT_WAGE_REPORT_WRITE = 'ROLE_DRAFT_WAGE_REPORT_WRITE',
	ROLE_PERSONAL_INFORMATION_ONLY = 'ROLE_PERSONAL_INFORMATION_ONLY',
}

export const EmployeePrivileges = [
	Privileges.ROLE_LOG_WORK_READ,
	Privileges.ROLE_LOG_WORK_WRITE,
	Privileges.ROLE_MY_PAYCHECKS_READ,
	Privileges.ROLE_MY_PAYCHECKS_WRITE,
	Privileges.ROLE_MY_10001_READ,
	Privileges.ROLE_MY_10001_WRITE,
	Privileges.ROLE_MY_106_READ,
	Privileges.ROLE_MY_106_WRITE,
	Privileges.ROLE_MY_DOCS_READ,
	Privileges.ROLE_MY_DOCS_WRITE,
	Privileges.ROLE_EMPLOYEE_FORM_READ,
	Privileges.ROLE_EMPLOYEE_FORM_WRITE,
	Privileges.ROLE_VACATION_REQUEST_READ,
	Privileges.ROLE_VACATION_REQUEST_WRITE,
	Privileges.ROLE_SICKNESS_REQUEST_READ,
	Privileges.ROLE_SICKNESS_REQUEST_WRITE,
	Privileges.ROLE_PERSONAL_INFORMATION_ONLY,
];

export const EmployerPrivileges = [
	Privileges.ROLE_LOG_WORK_REPORT_READ,
	Privileges.ROLE_LOG_WORK_REPORT_WRITE,
	Privileges.ROLE_LOCATION_READ,
	Privileges.ROLE_LOCATION_WRITE,
	Privileges.ROLE_EMP_PAYCHECKS_READ,
	Privileges.ROLE_EMP_PAYCHECKS_WRITE,
	Privileges.ROLE_EMP_10001_READ,
	Privileges.ROLE_EMP_10001_WRITE,
	Privileges.ROLE_VACATION_REPORT_READ,
	Privileges.ROLE_VACATION_REPORT_WRITE,
	Privileges.ROLE_COSTING_REPORT_READ,
	Privileges.ROLE_COSTING_REPORT_WRITE,
	Privileges.ROLE_MGM_USER_READ,
	Privileges.ROLE_MGM_USER_WRITE,
	Privileges.ROLE_CREATE_USER_READ,
	Privileges.ROLE_CREATE_USER_WRITE,
	Privileges.ROLE_EMPLOYEE_FORM_REPORT_READ,
	Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
	Privileges.ROLE_MNG_VAC_REQUEST_READ,
	Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
	Privileges.ROLE_MNG_SICK_REQUEST_READ,
	Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
	Privileges.ROLE_EMPLOYER_WAGE_REPORT_READ,
	Privileges.ROLE_EMPLOYER_WAGE_REPORT_WRITE,
	Privileges.ROLE_CUSTOMER_SETTING_WRITE,
	Privileges.ROLE_CUSTOMER_SETTING_READ,
	Privileges.ROLE_LOCATION_ASSOCIATION_READ,
	Privileges.ROLE_LOCATION_ASSOCIATION_WRITE,
	Privileges.ROLE_MNG_DOCS_READ,
	Privileges.ROLE_MNG_DOCS_WRITE,
	Privileges.ROLE_DRAFT_WAGE_REPORT_READ,
	Privileges.ROLE_DRAFT_WAGE_REPORT_WRITE,
];

export const RepresentativePrivileges = [
	Privileges.ROLE_REP_HOME_PAGE_READ,
	Privileges.ROLE_REP_HOME_PAGE_WRITE,
	Privileges.ROLE_REP_CUSTOMER_READ,
	Privileges.ROLE_REP_CUSTOMER_WRITE,
	Privileges.ROLE_CREATE_REP_CUSTOMER_READ,
	Privileges.ROLE_CREATE_REP_CUSTOMER_WRITE,
	Privileges.ROLE_MGM_USER_READ,
	Privileges.ROLE_MGM_USER_WRITE,
	Privileges.ROLE_CREATE_USER_READ,
	Privileges.ROLE_CREATE_USER_WRITE,
	Privileges.ROLE_REP_ACCESS_CUSTOMER_READ,
	Privileges.ROLE_REP_ACCESS_CUSTOMER_WRITE,
	Privileges.ROLE_REP_PAYCHECK_REPORT_READ,
	Privileges.ROLE_REP_PAYCHECK_REPORT_WRITE,
	Privileges.ROLE_REP_ORG_REPORT_READ,
	Privileges.ROLE_REP_ORG_REPORT_WRITE,
	Privileges.ROLE_REP_ORG_126_REPORT_READ,
	Privileges.ROLE_REP_ORG_126_REPORT_WRITE,
	Privileges.ROLE_REP_FORM_106_REPORT_READ,
	Privileges.ROLE_REP_FORM_106_REPORT_WRITE,
	Privileges.ROLE_REP_FORM_101_REPORT_READ,
	Privileges.ROLE_REP_FORM_101_REPORT_WRITE,
	Privileges.ROLE_REP_WORK_PAPER_REPORT_READ,
	Privileges.ROLE_REP_WORK_PAPER_REPORT_WRITE,
];

export const Home_Both_Permissions = [
	'ROLE_LOG_WORK_READ',
	'ROLE_LOG_WORK_WRITE',
];
export const Home_One_Permissions = [
	'ROLE_MY_PAYCHECKS_READ',
	'ROLE_MY_PAYCHECKS_WRITE',
];
export const Home_Paycheck_Permissions = [
	'ROLE_EMPLOYEE_FORM_READ',
	'ROLE_EMPLOYEE_FORM_WRITE',
];
