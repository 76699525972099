import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import httpService from '../../services/http.service';
import { getUserData } from '../../store/selectors/login.selectors';
import { getState } from '../../store/store';
import ApplicationsToEmployer from './ApplicationsToEmployer.component';

export interface ApplicationsData {
	id: number;
	type: string;
	status: string;
	dateRange: string;
	lastUpdate: string;
	description: string;
	employerComment: string | null;
	attachedFiles: number;
}

const ApplicationsToEmployerContainer = ({
	navigation,
}: {
	navigation: any;
}) => {
	const [applicationsData, setApplicationsData] = useState<
		ApplicationsData[]
	>([]);
	const userData = useSelector(getUserData);
	const [validationDate, setValidationDate] = useState(
		moment().subtract(1, 'months').startOf('month').valueOf(),
	);

	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('DESC');
	const [sortBy, setSortBy] = useState('lastUpdate');
	const [finishFirstLoad, setFinishFirstLoad] = useState(false);
	const disableActions = moment(
		getState().dateTime.currentDateTime,
		'YYYY-MM-DD',
	).isAfter(
		moment(userData?.operative_module?.[101]?.finish_work, 'YYYY-MM-DD'),
	);

	useEffect(() => {
		getApplicationsData(true, sortBy);
		onMount();
	}, [userData]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('ApplicationsToEmployerContainer');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};

	const getApplicationsData = async (
		firstInit: boolean,
		newSortBy?: string,
		neworder?: string,
	) => {
		try {
			const res = await httpService.api<any>({
				type: 'getApplicationsToEmployerData',
				data: {
					p: firstInit ? 0 : nextPage,
					ps: 40,
					s: newSortBy ? newSortBy : sortBy,
					sd: neworder ? neworder : order,
				},
			});

			if (res) {
				setDisableLoad(res.data.length < 40 ? true : false);
				setFinishFirstLoad(true);
				!!res?.validationDate &&
					setValidationDate(
						moment(res.validationDate, 'DD/MM/YYYY').valueOf(),
					);

				if (firstInit) {
					setApplicationsData(res.data);
					setNextPage(1);
				} else {
					setApplicationsData([...applicationsData, ...res.data]);
					setNextPage(nextPage + 1);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onSort = (newSort: string) => {
		let newOrder = order;
		if (newSort === sortBy) {
			newOrder = order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			newOrder = 'DESC';
		}
		setOrder(newOrder);
		setSortBy(newSort);
		getApplicationsData(true, newSort, newOrder);
	};

	return (
		<ApplicationsToEmployer
			navigation={navigation}
			data={applicationsData}
			onSort={onSort}
			onLoadMore={() =>
				disabledLoad ? null : getApplicationsData(false)
			}
			onrefresh={() => getApplicationsData(true, sortBy)}
			finishFirstLoad={finishFirstLoad}
			disableActions={disableActions}
			validationDate={validationDate}
		/>
	);
};

export default ApplicationsToEmployerContainer;
