const OptionalDeductions = (
	data: any,
	componentList: any = [],
	addNew?: boolean,
) => {
	const initialValues: any = {};

	if (addNew) {
		// Add new line to the table
		return {
			[data.code]: {
				header: [
					{
						name: data.code,
						value: data.name,
						title: `${data.code} - ${data.name}`,
						type: 'text',
					},
				],

				currentMonth: [
					{
						name: `${data.code}_optional`,
						value: 0,
						type: 'input',
						placeholder: '0.00',
					},
				],

				lastMonth: [
					{
						name: `${data.code}_optional_last`,
						value: '',
						type: 'text',
					},
				],
			},
		};
	}
	const format: any = {};
	// Table Header

	format.optionalDeductionsComponent = {
		header: [
			{
				name: 'optionalDeductionsComponent',
				value: 'FromMonthToMonthReports.formats.OptionalDeductions.header',
				addNew: true,
				type: 'header',
			},
		],

		currentMonth: [
			{
				name: 'currenText',
				value: '',
				type: 'header',
			},
		],

		lastMonth: [
			{
				name: 'lastText',
				value: '',
				type: 'header',
			},
		],
	};

	if (data.totalComponents.optionalDeductionsComponent.length > 0) {
		data.totalComponents.optionalDeductionsComponent.forEach(
			(element: any) => {
				initialValues[`${element.newCode || element.code}_optional`] =
					getElementValue(
						data.draft,
						element.newCode || element.code,
					);

				format[element.newCode || element.code] = {
					header: [
						{
							name: element.newCode || element.code,
							value: element.name,
							title: `${element.code} - ${getNameByCode(
								element.code,
								element.name,
								componentList,
							)}`,
							type: 'text',
						},
					],

					currentMonth: [
						{
							name: `${element.newCode || element.code}_optional`,
							value: getElementValue(
								data.draft,
								element.newCode || element.code,
							),
							type: getTypeByComponent(
								componentList,
								element.code,
							),
							placeholder: '0.00',
						},
					],

					lastMonth: data.paycheck.map((el: any, index: number) => {
						return [
							{
								name: `${
									element.newCode || element.code
								}_optional_last_${el.yearMonth}`,
								value: getLastElementValue(
									data.paycheck,
									element.newCode || element.code,
									index,
								),
								type: 'text',
							},
						];
					}),
				};
			},
		);
	}
	return { format, initialValues };
};
const getNameByCode = (code: string, name: string, list?: any): string => {
	if (list.length === 0) return name;
	const newName = list.find((c: any) => c.code === code)?.name;
	return newName ? newName : name;
};

const getTypeByComponent = (list: any, code: string): string => {
	if (list.length === 0) return 'text';
	const showEdit = list.find((c: any) => c.code === code)?.showEdit;
	return showEdit === 2 ? 'input' : 'text';
};

const getElementValue = (data: any, code: string): string => {
	if (
		!data ||
		data.length === 0 ||
		data.optionalDeductionsComponent.length === 0
	) {
		return '';
	}
	const lastName = data.optionalDeductionsComponent.find((item: any) => {
		return (item.newCode || item.code) === code;
	});

	return lastName?.value || '';
};

const getLastElementValue = (
	data: any,
	code: string,
	index: number,
): string => {
	if (
		!data ||
		data[index].length === 0 ||
		data[index].optionalDeductionsComponent.length === 0
	) {
		return '';
	}
	const lastName = data[index].optionalDeductionsComponent.find(
		(item: any) => {
			return (item.newCode || item.code) === code;
		},
	);
	return lastName?.value || '';
};

export default OptionalDeductions;
