import { makeStyles } from '@material-ui/core';
import React from 'react';
import ModalWrapper from '../../../../../components/web/ModalWrapper/ModalWrapper.component';
import colors from '../../../../../config/colors';
import { i18nService } from '../../../../../services/i18n.service';
import ButtonsRow from '../ButtonsRow/ButtonsRow';
import {
	getEmployerRolesPrivileges,
	getRepRolesPrivileges,
	getUserData,
} from '../../../../../store/selectors/login.selectors';
import { useSelector } from 'react-redux';
import { Privileges } from '../../../../../constants/roleTypes.constant';
import UserDetailsSection from '../../UserDetailsSection/UserDetailsSection.container';
import UnitsRolesWrapper from '../../RolesSection/UnitsRolesWrapper';
import RolesSection from '../../RolesSection/RolesSection.component';

const useStyles = makeStyles(() => ({
	card: {
		boxShadow: '0 6px 20px 0 #3b70a433',
		backgroundColor: colors.white,
		display: 'flex',
		flexDirection: 'column',
		padding: '25px 36px',
		margin: 15,
	},
	bottom: {
		display: 'flex',
	},
	bottomSide: {
		flex: 1,
		flexDirection: 'column',
		display: 'flex',
	},
	modalTitle: {
		fontSize: 22,
		fontFamily: 'RubikMedium',
		fontWeight: 'normal',
	},
	modalHeader: {
		boxSizing: 'border-box',
		position: 'relative',
		fontFamily: 'RubikRegular',
		paddingBottom: 20,
		color: colors.darkGrey,
		margin: '0 15px 0 20px',

		'&:after': {
			backgroundColor: colors.primary,
			bottom: '7px',
			content: '""',
			position: 'absolute',
			borderRadius: '7px',
			height: '5px',
			width: '100%',
		},
	},
	userInfo: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	active: {
		color: '#83b252',
		fontSize: 16,
	},
	nonActive: {
		color: colors.grey,
		fontSize: 16,
	},
	fullName: {
		fontFamily: 'RubikMedium',
		fontSize: 22,
		color: colors.primary,
	},
	teudatZeut: {
		fontSize: 22,
		color: colors.primary,
	},
	secondPart: {
		display: 'flex',
		justifyContent: 'space-between',
		fontFamily: 'RubikMedium',
		alignItems: 'center',
		fontSize: 16,
	},
	departmentTitle: {
		fontFamily: 'RubikRegular',
	},
	phone: {
		borderLeft: '2px solid #e7e7e8',
		borderRight: '2px solid #e7e7e8',
		padding: '0 10px',
		lineHeight: 1,
		height: 16,
		margin: '0 10px',
	},
}));

type ModalTitleProps = {
	info: {
		first_name: string;
		last_name: string;
		phone: string;
		status: boolean;
		teudat_zeut: string;
		email: string;
		identity_type: string;
		department_id: number;
	};
	departments: {
		id: number;
		name: string;
	}[];
};

const CreateUserModal = React.forwardRef(
	(
		{
			departments,
			roles,
			setRoles,
			onSubmit,
			onCancel,
			disabledSubmit,
			route,
			id,
			businessUnitsList,
			rolesInfo,
			validationFunctions,
			getValuesFunctions,
			addBusinessUnit,
			defaultOpenUnit,
			isActive,
		}: any,
		ref: any,
	) => {
		const userData = useSelector(getUserData);
		const employerRolePrivileges = useSelector(getEmployerRolesPrivileges);
		const repRolePrivileges = useSelector(getRepRolesPrivileges);

		return (
			<ModalWrapper
				title={i18nService.translate(
					`editUserModal.title.${id ? 'edit' : 'add'}`,
				)}
				buttons={
					<ButtonsRow
						onSubmit={onSubmit}
						onCancel={onCancel}
						disabledSubmit={disabledSubmit}
					/>
				}
				ref={ref}
			>
				<>
					<UserDetailsSection
						departments={departments}
						roles={roles}
						userData={userData}
						isEditUser={id}
						isActive={isActive}
					/>
					<UnitsRolesWrapper
						departments={departments}
						userId={id}
						roles={roles}
						setRoles={setRoles}
						route={route}
						Component={RolesSection}
						validationFunctions={validationFunctions}
						getValuesFunctions={getValuesFunctions}
						isEditUser={id}
						businessUnitsList={businessUnitsList}
						rolesInfo={rolesInfo}
						disabled={
							!employerRolePrivileges?.includes(
								Privileges.ROLE_CREATE_USER_WRITE,
							) &&
							!repRolePrivileges?.includes(
								Privileges.ROLE_CREATE_USER_WRITE,
							)
						}
						addBusinessUnit={addBusinessUnit}
						defaultOpenUnit={defaultOpenUnit}
					/>
				</>
			</ModalWrapper>
		);
	},
);

export default CreateUserModal;
