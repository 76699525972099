import moment from 'moment';
import { LocaleConfig } from 'react-native-calendars';

import { setCalendarLocale } from '../../../../config/calendarLocaleConfig';
setCalendarLocale(LocaleConfig);

const MonthYearFormat = (
	value: string,
	fiexd: number = 0,
	returnOnly: string | null = null,
	returnOriginalValues: boolean = false,
): string => {
	value = value ? value : moment(new Date()).format('YYYY-MM');
	if (fiexd > 12 || fiexd < -12) {
		fiexd = 0;
	}
	let month;
	let year;
	if (returnOriginalValues) {
		if (Number(moment(value).format('MM')) - 1 + fiexd <= 0) {
			month = Number(moment(value).format('MM')) + 11 + fiexd;
			year = Number(moment(value).format('YYYY')) - 1;
		} else {
			month = Number(moment(value).format('MM')) - 1 + fiexd;
			year = Number(moment(value).format('YYYY'));
		}
	} else {
		if (Number(moment(value).format('MM')) - 1 + fiexd < 0) {
			month =
				LocaleConfig.locales[LocaleConfig.defaultLocale].monthNames![
					Number(moment(value).format('MM')) + 11 + fiexd
				];
			year = Number(moment(value).format('YYYY')) - 1;
		} else {
			month =
				LocaleConfig.locales[LocaleConfig.defaultLocale].monthNames![
					Number(moment(value).format('MM')) - 1 + fiexd
				];
			year = Number(moment(value).format('YYYY'));
		}
	}

	if (returnOnly === 'year') {
		return `${year}`;
	} else if (returnOnly === 'month') {
		return `${month}`;
	}

	return `${month} ${year}`;
};

export default MonthYearFormat;
