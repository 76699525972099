import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	TextInput,
	Platform,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { ButtonsContainer, Required } from '../PersonalInformation.utils';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import httpService from '../../../services/http.service';

interface Props {
	onNext(val: any): void;
	onResponse(ans: string | null): void;
}

const UpdatePhoneModal = ({ onNext, onResponse }: Props) => {
	const platform = useDevicePlatform();

	const [
		{ currentPassword, currentValid, showCurrentPassword },
		setCurrentPassword,
	] = useState({
		currentPassword: '',
		currentValid: true,
		showCurrentPassword: false,
	});
	const [newPhone, setNewPhone] = useState('');
	const [invalidPhone, setInvalidPhone] = useState(false);

	const isWeb = platform === DeviceType.WEB;

	const validatePhone = () => {
		const regExp = /^[0-9]*$/;
		const isValid = regExp.test(newPhone) && newPhone.length === 10;
		setInvalidPhone(!isValid);
		return isValid;
	};

	const onUpdateEmail = async () => {
		try {
			const isValid = validatePhone();
			if (isValid) {
				const res = await httpService.api<any>({
					type: 'updateUserPhone',
					data: {
						password: currentPassword,
						phone: newPhone,
					},
				});

				onNext(onResponse(!!res ? newPhone : null));
			} else {
				setInvalidPhone(true);
			}
		} catch (e) {}
	};

	return (
		<View style={[styles.modalContainer, { width: isWeb ? 476 : 350 }]}>
			<View>
				<I18n size={30} weight='normal'>
					personalInformation.updatePhoneModal.title
				</I18n>
				<View style={styles.divider} />
			</View>

			<View>
				<View style={{ height: 83 }}>
					<View style={{ flexDirection: 'row-reverse' }}>
						<I18n size={14} weight='normal'>
							personalInformation.updatePhoneModal.pressPassword
						</I18n>
						<Required />
					</View>
					<View style={styles.inputContainer}>
						<TouchableOpacity
							onPress={() =>
								setCurrentPassword(prev => ({
									...prev,
									showCurrentPassword: !showCurrentPassword,
								}))
							}
						>
							<ImageIcon
								name={
									showCurrentPassword
										? 'showPassword'
										: 'hidePassword'
								}
								width={22}
								height={14}
							/>
						</TouchableOpacity>
						<TextInput
							style={[styles.textInput, { width: 220 }]}
							underlineColorAndroid='transparent'
							value={currentPassword}
							onChangeText={val =>
								setCurrentPassword(prev => ({
									...prev,
									currentPassword: val,
								}))
							}
							secureTextEntry={!showCurrentPassword}
						/>
					</View>
					{!currentValid && (
						<I18n color='failure' weight='normal' size={12}>
							personalInformation.updatePhoneModal.wrongPassword
						</I18n>
					)}
				</View>

				<View style={{ height: 83 }}>
					<View style={{ flexDirection: 'row-reverse' }}>
						<I18n size={14} weight='normal'>
							personalInformation.updatePhoneModal.newPhone
						</I18n>
						<Required />
					</View>
					<View style={styles.inputContainer}>
						<TextInput
							style={styles.textInput}
							underlineColorAndroid='transparent'
							value={newPhone}
							maxLength={10}
							onChangeText={val => setNewPhone(val)}
							keyboardType='numeric'
							onFocus={() => setInvalidPhone(false)}
						/>
					</View>
					{invalidPhone && (
						<I18n color='failure' weight='normal' size={12}>
							personalInformation.updatePhoneModal.illegalPhone
						</I18n>
					)}
				</View>
			</View>

			<ButtonsContainer
				acceptText={'personalInformation.updatePhoneModal.update'}
				onAccept={onUpdateEmail}
				onCancel={onNext}
				disabled={!currentPassword || !newPhone}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		padding: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		minHeight: 400,
		justifyContent: 'space-between',
	},
	divider: {
		backgroundColor: colors.darkGrey,
		opacity: 0.2,
		height: 1,
		width: '100%',
		marginVertical: 20,
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		marginTop: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		height: '100%',
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
});

export default UpdatePhoneModal;
