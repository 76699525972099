export class UserModel {
	public id: string;
	public fullName: string;
	public email: number;
	public hasExcludedPermission: boolean;
	public hasExcludedUser: boolean;
	public phone: string;
	public roleNameList: string[];
	public status: boolean;
	public teudatZeut: string;
	public departmentAid: string;
	public departmentName: string;
	public finishWork: string;
	public businessUnitName: string;
	public businessUnitId: number;
	public profileId: number;
	public identityType: number;
	public employeeType: string;

	constructor(props: any) {
		this.id = props.id;
		this.fullName = props.fullName;
		this.email = props.email;
		this.hasExcludedPermission = props.hasExcludedPermission;
		this.hasExcludedUser = props.hasExcludedUser;
		this.phone = props.phone;
		this.roleNameList = props.roleNameList;
		this.phone = props.phone;
		this.status = props.status;
		this.teudatZeut = props.teudatZeut;
		this.departmentAid = props.departmentAid;
		this.departmentName = props.departmentName;
		this.finishWork = props.finishWork;
		this.businessUnitName = props.businessUnitName;
		this.businessUnitId = props.businessUnitId;
		this.profileId = props.profileId;
		this.identityType = props.identityType;
		this.employeeType = props.employeeType;
	}
}
