import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Platform } from 'react-native';

import HomePage from './HomePage.component';
import {
	getUserData,
	getEmployeePrivileges,
} from '../../store/selectors/login.selectors';
import httpService from '../../services/http.service';
import {
	Home_Both_Permissions,
	Home_One_Permissions,
	Home_Paycheck_Permissions,
	Privileges,
} from '../../constants/roleTypes.constant';
import { getState } from '../../store/store';
import moment from 'moment';

type EmployeeDashboardResponse = {
	yearMonth: string;
	total: string;
	deductions: string;
	otherDeductions: string;
	paymentNet: string;
	vacationBalance: string;
	employeeFormId: string;
	employeeFormStatus: boolean | null;
};

export type HomePageReportContentKeyType =
	| 'total'
	| 'deductions'
	| 'otherDeductions'
	| 'paymentNet'
	| 'vacationBalance';

export type HomePageReportContentElType = Record<
	HomePageReportContentKeyType,
	string
>;

const initContent = {
	total: '',
	deductions: '',
	otherDeductions: '',
	paymentNet: '',
	vacationBalance: '',
};

const HomePageContainer = ({ navigation }: { navigation: any }) => {
	const userData = useSelector(getUserData);
	const [showProjectList, setShowProjectList] = useState(true);
	const [isSummaryOnly, setIsSummaryOnly] = useState<boolean | null>(null);
	const [isPaycheckBlockShown, setIsPaycheckBlockShown] = useState(false);
	const [form101Id, setForm101Id] = useState<string | null>(null);

	const [reportDateYear, setDateYear] = useState<string>('');
	const [reportContentData, setReportContentData] =
		useState<HomePageReportContentElType>(initContent);
	const [report101warning, setReport101Warning] = useState<boolean>(false);

	const employeePrivilegesStatus = useSelector(getEmployeePrivileges);

	useEffect(() => {
		fetchEmployeeDashboard();
		onMount();
	}, [userData, employeePrivilegesStatus]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('HomePage');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};

	const fetchEmployeeDashboard = async () => {
		try {
			const res = await httpService
				.api<any>({
					type: 'getEmployeeDashboard',
				})
				.then(async (res: EmployeeDashboardResponse) => {
					let employeeFormStatus =
						res.employeeFormStatus === null ? false : true;
					accessMatcher(employeeFormStatus);

					const is101FinishWorkDateExpired =
						moment(
							getState().dateTime.currentDateTime,
							'YYYY-MM-DD',
						).year() >
						moment(
							userData?.operative_module?.[113]?.finish_work,
							'YYYY-MM-DD',
						).year();
					setForm101Id(
						is101FinishWorkDateExpired ? null : res?.employeeFormId,
					);

					const employeeRolesPrivileges =
						getState().login?.user?.employeeRolePrivileges;

					if (employeeFormStatus) {
						setReport101Warning(
							!res.employeeFormStatus &&
								!is101FinishWorkDateExpired &&
								!!employeeRolesPrivileges?.includes(
									Privileges.ROLE_EMPLOYEE_FORM_WRITE,
								),
						);
					}
					setDateYear(res.yearMonth);
					setReportContentData({
						deductions: res.deductions,
						otherDeductions: res.otherDeductions,
						paymentNet: res.paymentNet,
						total: res.total,
						vacationBalance: res.vacationBalance,
					});
				});
		} catch (e) {
			console.log(
				`Error while fetching data from API: getEmployeeDashboard`,
				e,
			);
		}
	};

	const accessMatcher = (employeeFormStatus: boolean) => {
		let bothP = false;
		let leftP = false;
		let paycheckP = false;
		let _isSummaryOnly = true;
		setIsPaycheckBlockShown(false);
		employeePrivilegesStatus?.map((priv: string) => {
			if (Home_Both_Permissions.includes(priv)) {
				bothP = true;
			}
			if (Home_One_Permissions.includes(priv)) {
				leftP = true;
			}
			if (Home_Paycheck_Permissions.includes(priv)) {
				paycheckP = true;
			}
		});
		if (
			bothP &&
			leftP &&
			employeePrivilegesStatus?.includes(Privileges.ROLE_LOG_WORK_WRITE)
		) {
			_isSummaryOnly = false;
		}
		if (
			moment
				.utc(getState().dateTime.currentDateTime)
				.local()
				.isAfter(
					moment(
						userData?.operative_module?.[101]?.finish_work,
						'YYYY-MM-DD',
					),
				)
		) {
			_isSummaryOnly = true;
		}
		setIsSummaryOnly(_isSummaryOnly);
		if (paycheckP && employeeFormStatus) {
			setIsPaycheckBlockShown(state => true);
		}
	};

	const setShowProjectListHandler = (val: boolean): void => {
		setShowProjectList(val);
	};

	const openDrawer = useCallback(() => {
		navigation.openDrawer();
	}, []);

	if (!userData || isSummaryOnly === null) {
		return null;
	}
	return (
		<>
			<HomePage
				navigation={navigation}
				openDrawer={openDrawer}
				showProjectList={showProjectList}
				setShowProjectList={setShowProjectListHandler}
				isSummaryOnly={isSummaryOnly}
				isPaycheckBlockShown={isPaycheckBlockShown}
				reportDateYear={reportDateYear}
				reportContentData={reportContentData}
				report101warning={report101warning}
				form101Id={form101Id}
			/>
		</>
	);
};

export default HomePageContainer;
