import React from 'react';
import {
	View,
	StyleSheet,
	useWindowDimensions,
	TouchableHighlight,
} from 'react-native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import ReportsCalendarContainer from '../../components/shared/ReportsCalendar/ReportsCalendar.container';
import colors from '../../config/colors';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import getShadowStyle from '../../config/shadow';
import DayReportsContainer from "./DayReports/DayReports.container"
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
interface Props {
	navigation: any;
}
const CalendarAttendanceReportsWeb = ({
	navigation,
}: Props) => {
	const windowWidth = useWindowDimensions().width;
	const windowHeight = useWindowDimensions().height;
	const maxWidth = windowWidth > 1700 ? 612 : windowWidth > 1500 ? 540 : 480;
	const contentBlock = {
		marginTop: 28,
		marginBottom: 48,
		height: windowHeight - 167,
		backgroundColor: colors.white,
		width: '100%',
		maxWidth: maxWidth,
		padding: 20
	};
	
	return (
		<>
			<HeaderContainer
				containerStyle={{ backgroundColor: colors.white }}
				navigation={navigation}
				component={WebHeader}
			/>
			<View style={styles.container}>
				<DayReportsContainer navigation={navigation} />
				<View style={contentBlock} nativeID='contanetBlockWeb2'>
					<ReportsCalendarContainer />
				</View>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	line: {
		opacity: 0.2,
		width: '95%',
		height: 0,
		borderTopColor: '#525962',
		borderTopWidth: 2,
		marginVertical: 5,
	},
	recordLine: {
		opacity: 0.2,
		width: '100%',
		height: 0,
		borderTopColor: '#525962',
		borderTopWidth: 2,
		marginVertical: 5,
	},
	container: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		height: 'calc(100vh - 90px)',
	},
	detailsHeader: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 20,

	},
	addButtonHolder: {
		flexDirection: 'row',
		width: '100%',
		padding: 10,
		flex: 2,
	},
	tableHolder: {
		width: '100%',
		minHeight: 140,
		maxHeight: 190,
		direction: 'ltr',
		paddingHorizontal: 15,
		flex: 2,
	},
	fabButton: {
		borderRadius: 30,
		width: 40,
		height: 40,
		marginTop: 10,
		marginLeft: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default CalendarAttendanceReportsWeb;
