import React, { useCallback, useMemo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportsCalendarDay from './ReportsCalendarDay.component';
import { DayComponentProps } from 'react-native-calendars';
import useDevicePlatform from '../../../hooks/useDevicePlatform.hook';
import moment from 'moment';
import {
	setChosenDate,
	setOpenChosenDateModal,
} from '../../../store/actions/clockReport.actions';
import { getChosenDate } from '../../../store/selectors/clockReport.selector';
import { CalendarData } from '../ReportsCalendar/ReportsCalendar.container';
import { getUserData } from '../../../store/selectors/login.selectors';
interface CalendarReport {
	date: string;
	standard: number;
	workLogs: number;
	enters: number;
	exits: number;
}
const convertMinutesToHours = (value: any, format?: string) => {
	if (value) {
		if (format === 'HH:mm') {
			const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
			const minutes =
				value % 60 !== 0
					? value % 60 < 10
						? `0${value % 60}`
						: `${value % 60}`
					: '00';
			return `${hours}:${minutes}`;
		}
		return `${(value / 60).toFixed(2)}`;
	}
	return '00:00';
};
const ReportsCalendarDayContainer = ({
	date,
	state,
}: DayComponentProps & { navigation: any }) => {
	const userData = useSelector(getUserData);
	const calendarReports = useContext(CalendarData)?.data;
	const dispatch = useDispatch();
	const chosenDate = useSelector(getChosenDate);
	const isDaySelected = chosenDate === date.dateString;
	const currentDeviceType = useDevicePlatform();
	const todayDay = moment();
	const dayReportData = useMemo(() => {
		const dayData = calendarReports?.filter((dayReport: CalendarReport) => {
			return dayReport.date === date.dateString;
		})[0];
		return {
			...dayData,
			formatedWorkHours: dayData?.workLogs
				? convertMinutesToHours(dayData?.workLogs, 'HH:mm')
				: '00:00',
			shouldDisplayTotalHours:
				dayData?.workLogs && dayData.standard ? true : false,
			didCompleteWorkingHours: dayData?.standard
				? dayData?.standard <= dayData.workLogs
				: true,
		};
	}, [calendarReports]);
	const isDisabled = useMemo(
		() =>moment(date.dateString, 'YYYY-MM-DD').isAfter(
			moment(userData?.operative_module?.[101]?.finish_work,"YYYY-MM-DD")),
		[userData?.operative_module[101], date.dateString],
	);
	const selectDate = useCallback(async () => {
		if (!isDisabled) {
			dispatch(setOpenChosenDateModal(true));
			await dispatch(setChosenDate(date.dateString));
		}
	}, [date.dateString, currentDeviceType]);

	const isDayInFeature = () => {
		return moment(date.dateString, 'YYYY-MM-DD').isAfter(todayDay);
	};

	return (
		<ReportsCalendarDay
			day={date.day}
			isDisabled={isDisabled}
			//showAsDisabled={}
			isDaySelected={isDaySelected}
			isCurrentDate={state === 'today'}
			reportedTime={dayReportData.formatedWorkHours}
			isReportAcceptable={
				isDayInFeature()
					? true
					: dayReportData?.didCompleteWorkingHours &&
					  dayReportData?.enters === dayReportData?.exits
			}
			notDisplayTotalHours={!dayReportData?.shouldDisplayTotalHours}
			entrances={dayReportData?.enters}
			exits={dayReportData?.exits}
			onPress={selectDate}
		/>
	);
};

export default ReportsCalendarDayContainer;
