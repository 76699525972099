import React from 'react';
import {
	Dimensions,
	View,
	StyleSheet,
	TouchableHighlight,
	useWindowDimensions,
} from 'react-native';
import { Breadcrumbs, Link } from '@material-ui/core';
import colors from '../../config/colors';
import I18n from '../../components/shared/i18n/I18n.component';
import Header from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import ReportsPageWrapper from '../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import TableContainer from '../../components/web/ReactTable/Table.container';
import { hoursColumns } from './HoursReportSummary.utils';
import getShadowStyle from '../../config/shadow';
import Typography from '../../components/shared/Typography/Typography.component';
import { i18nService } from '../../services/i18n.service';
import Filters from '../../components/web/Filters/Filters.component';

export interface Props {
	navigation: any;
	data: any[];
	onSort?: any;
	onLoadMore: any;
	totalData: any;
	filtersConfig: any;
	isLoadingData: boolean;
	onFilter: () => {};
	onRowPress: (value: any) => void;
	reqForWorkLogREports: () => void;
	worklogReportFormat: string;
	businessUnitsList: any[];
}

const HoursReportSummary = ({
	onRowPress,
	navigation,
	data,
	isLoadingData,
	onSort,
	onLoadMore,
	totalData,
	filtersConfig,
	worklogReportFormat,
	onFilter,
	reqForWorkLogREports,
	businessUnitsList,
}: Props) => {
	const { height: windowHeight } = useWindowDimensions();

	return (
		<View
			style={{
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>

			<ReportsPageWrapper
				navigation={navigation}
				title={
					<View
						style={{
							flexDirection: 'row-reverse',
							flex: 1,
							justifyContent: 'space-between',
						}}
					>
						<Breadcrumbs separator='<'>
							<Link color='inherit' underline={'none'}>
								<I18n
									size={36}
									weight='normal'
									style={{ flex: 1 }}
								>
									employeeRequests.title
								</I18n>
							</Link>
						</Breadcrumbs>
						<TouchableHighlight
							underlayColor='transparent'
							onPress={reqForWorkLogREports}
						>
							<View style={styles.newRequestButton}>
								<Typography
									style={{ marginLeft: 10, marginRight: 10 }}
									color={'white'}
									weight='400'
									size={14}
								>
									{i18nService.translate(
										'hoursReport.toAllReports',
									)}
								</Typography>
							</View>
						</TouchableHighlight>
					</View>
				}
				noHeader={true}
			>
				<View
					style={{
						flexDirection: 'row',
						alignSelf: 'flex-end',
						zIndex: 100,
					}}
				>
					<Filters
						config={filtersConfig}
						onChange={onFilter}
						customStyles={{
							container: {
								display: 'flex',
								flexDirection: 'row-reverse',
								justifyContent: 'flex-end',
							},
							filterContainer: {
								marginLeft: 10,
								marginVertical: 10,
								zIndex: 10001,
							},
						}}
					/>
				</View>

				<View
					style={{
						maxHeight: windowHeight - 300,
						flex: 1,
					}}
				>
					{data.length > 0 ? (
						<TableContainer
							keyName={''}
							headers={hoursColumns(
								totalData ? totalData : null,
								onRowPress,
								worklogReportFormat,
								businessUnitsList,
							)}
							data={data || []}
							getNextData={onLoadMore}
							onSort={onSort}
							hasFooter={true}
							selectedRowId={0}
						/>
					) : (
						!isLoadingData && (
							<View style={styles.noResultsFound}>
								<I18n weight='bold' size={24}>
									general.noResultsFound
								</I18n>
							</View>
						)
					)}
				</View>
			</ReportsPageWrapper>
		</View>
	);
};

const styles = StyleSheet.create({
	newRequestButton: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 174,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	noResultsFound: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default HoursReportSummary;
