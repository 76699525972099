import { useNavigation } from '@react-navigation/core';
import moment from 'moment';
import React, { useState } from 'react';
import { TouchableHighlight, View, Platform } from 'react-native';
import Tooltip from 'react-native-walkthrough-tooltip';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import ActionButtonsMenu from './ActionButtonsMenu';

const UserTableMobileRow = ({
	dataRow,
	showMenu,
	onRowClick,
	businessUnitsList,
	setShowModal,
}: {
	dataRow: any;
	showMenu: boolean | undefined;
	onRowClick: (dataItem: any) => void;
	businessUnitsList: any;
	setShowModal: Function;
}) => {
	const navigation = useNavigation();
	const roleNameList = dataRow?.item?.roleNameList?.split(',') || [];
	const [toolTipVisible, setToolTipVisible] = useState<boolean>(false);

	return (
		<>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => onRowClick(dataRow?.item)}
			>
				<View
					style={{
						height: 70,
						backgroundColor: 'white',
						marginTop: 10,
						marginBottom: 10,
						padding: 10,
						paddingTop: 0,
					}}
				>
					<View
						style={{
							alignItems: 'flex-end',
							height:
								!dataRow?.item?.finishWork &&
								businessUnitsList.length > 1
									? 7
									: 15,
						}}
					>
						{dataRow?.item?.finishWork && (
							<View
								style={{
									backgroundColor: '#AFB2B5',
									padding: 3,
									alignItems: 'center',
									justifyContent: 'center',
									borderBottomRightRadius: 7,
									borderBottomLeftRadius: 7,
									flexDirection: 'row-reverse',
									width: 120,
								}}
							>
								<I18n
									size={8}
									color='white'
									weight={
										Platform.OS === 'android'
											? 'bold'
											: 'normal'
									}
									style={{ marginLeft: 5 }}
								>
									general.endWorkDate
								</I18n>
								<I18n
									size={8}
									weight={
										Platform.OS === 'android'
											? 'bold'
											: 'normal'
									}
									color='white'
								>
									{moment(
										dataRow?.item?.finishWork,
										'YYYY-MM-DD',
									).format('DD/MM/YYYY')}
								</I18n>
							</View>
						)}
					</View>
					<View
						style={{
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingTop: 3,
						}}
					>
						<View
							style={{
								flex: 1.5,
							}}
						>
							{businessUnitsList.length > 1 && (
								<Typography weight='bold' size={14}>
									{dataRow?.item.businessUnitName}
								</Typography>
							)}
							<Typography weight='normal' size={14}>
								{dataRow?.item?.fullName}
							</Typography>
							<Typography weight='400' size={14}>
								{dataRow?.item?.teudatZeut}
							</Typography>
						</View>
						<View
							style={{
								flex: 1,
								marginLeft: 10,
								alignItems: 'flex-end',
							}}
						>
							{roleNameList.length <= 2 ? (
								<Typography weight='normal' size={14}>
									{roleNameList.join(', ')}
								</Typography>
							) : (
								<Tooltip
									isVisible={toolTipVisible}
									content={
										<Typography>
											{roleNameList.join(', ')}
										</Typography>
									}
									placement='top'
									onClose={() => {
										setToolTipVisible(false);
									}}
								>
									<TouchableHighlight
										onPress={() => {
											setToolTipVisible(!toolTipVisible);
										}}
									>
										<Typography weight='normal' size={14}>
											{roleNameList
												.slice(0, 2)
												.join(', ')}
											{`, +${roleNameList.length - 2}`}
										</Typography>
									</TouchableHighlight>
								</Tooltip>
							)}
						</View>
						<View
							style={{
								flex: 0.5,
								alignItems: 'center',
								minWidth: 20,
							}}
						>
							<I18n>
								{`usersManagement.table.status.${dataRow?.item?.status}`}
							</I18n>
						</View>
						{showMenu && (
							<View style={{ width: 20, zIndex: 1 }}>
								<ActionButtonsMenu
									userId={dataRow?.item?.id}
									unitId={dataRow?.item?.businessUnitId}
									name={`${dataRow?.item?.fullName} (${dataRow?.item?.teudatZeut})`}
									setShowModal={setShowModal}
								/>
							</View>
						)}
					</View>
				</View>
			</TouchableHighlight>
		</>
	);
};

export default UserTableMobileRow;
