import { StoreAction, ThunkedAction } from '../../interfaces/redux.interface';
import httpService from '../../services/http.service';
import * as reportsFiltersActions from '../constants/reportsFilters';

const fetchDepartmentsListSuccess = (
	payload: string[],
): StoreAction<string[]> => ({
	type: reportsFiltersActions.FETCH_DEPARTMENTS_LIST_OPTIONS_SUCCESS,
	payload,
});

const fetchBusinessUnitListSuccess = (
	payload: string[],
): StoreAction<string[]> => ({
	type: reportsFiltersActions.FETCH_BUSINESS_UNITS_LIST_SUCCESS,
	payload,
});

const fetchRolesListSuccess = (payload: string[]): StoreAction<string[]> => ({
	type: reportsFiltersActions.FETCH_ROLES_LIST_OPTIONS_SUCCESS,
	payload,
});

export const updateRequestsFilters = (payload: any): StoreAction<string[]> => ({
	type: reportsFiltersActions.UPDATE_REQUESTS_FILTERS,
	payload,
});

export const fetchEmployeesFilterList = async (
	pageName: string,
	{
		page,
		pageSize,
		search,
		filter,
		unitIds = [],
		repCustomerId,
		flat,
		excludePassport,
	}: any,
) => {
	try {
		const res = await httpService.api<any>({
			type: 'getEmployeesFilterList',
			params: { pageName },
			query: {
				unitIds:
					typeof unitIds === 'string'
						? unitIds
						: unitIds?.map(unit => `${unit}`).join(),
				pageName,
				repCustomerId,
				flat,
				excludePassport,
			},
			disableBI: true,
			data: {
				p: page,
				ps: pageSize,
				search,
				f: filter,
			},
		});
		return res;
	} catch (e) {
		console.log(e);
	}
};

export const fetchDepartmentsFilterList =
	(pageName: string, unitIds?: number[] | string[]): ThunkedAction =>
	dispatch => {
		httpService
			.api<string[]>({
				type: 'getDepartmentsFilterList',
				query: {
					unitIds: unitIds?.map(unit => `${unit}`).join(),
					pageName,
				},
			})
			.then(data => {
				dispatch(fetchDepartmentsListSuccess(data));
			})
			.catch(() => {});
	};

export const fetchBusinessUnitList = (): ThunkedAction => dispatch => {
	httpService
		.api<string[]>({
			type: 'getBusinessUnitList',
		})
		.then(data => {
			dispatch(
				fetchBusinessUnitListSuccess(
					data.map((item: any) => ({
						...item,
						id: item.numericId,
						stringId: item.id,
					})),
				),
			);
		})
		.catch(() => {});
};

export const fetchRolesFilterList =
	(unitIds?: number[] | string[]): ThunkedAction =>
	dispatch => {
		httpService
			.api<string[]>({
				type: 'getRolesFilterList',
				query: {
					unitIds: unitIds?.map(unit => `${unit}`).join(),
				},
			})
			.then(data => dispatch(fetchRolesListSuccess(data)))
			.catch(() => {});
	};
