import React, { useEffect, useMemo, useRef, useState } from 'react';
import UnitsDataSection from './UnitsDataSection/UnitsDataSection.component';
import RolesWrapper from './RolesWrapper';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import getShadowStyle from '../../../../config/shadow';
import colors from '../../../../config/colors';
import { hasPermissionOnBusinessUnit } from '../../../../utils/globals.utils';
import { Privileges } from '../../../../constants/roleTypes.constant';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../../store/selectors/login.selectors';

const UnitsRolesWrapper = ({
	setRoles,
	Component,
	setIsModalOpen,
	businessUnitsList,
	addBusinessUnit,
	rolesInfo,
	isEditUser,
	validationFunctions,
	getValuesFunctions,
	disabled,
	defaultOpenUnit,
	userId,
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const [selectedUnitsArr, setSelectedUnitsArr] = useState<any>([]);
	const userData = useSelector(getUserData);
	const firstInit = useRef(true);
	const canAddUnit = useMemo(
		() =>
			!businessUnitsList.find(
				(unit: any) =>
					!hasPermissionOnBusinessUnit(
						parseInt(`${unit.id}`),
						Privileges.ROLE_MGM_USER_WRITE,
					),
			),
		[businessUnitsList],
	);
	const isUpdateHimself = useMemo(
		() => userData?.user_id === userId,
		[userData?.user_id, userId],
	);
	const unitIds = useMemo(
		() => rolesInfo && Object.keys(rolesInfo),
		[rolesInfo],
	);
	const disableSelectEmployeeRole = useMemo(
		() =>
			rolesInfo &&
			Object.keys(rolesInfo).find((unitId: string) =>
				rolesInfo[unitId].user_roles?.includes(6),
			) &&
			!isEditUser,
		[rolesInfo, isEditUser],
	);

	useEffect(() => {
		if (unitIds.length && firstInit.current) {
			firstInit.current = false;
			setSelectedUnitsArr(unitIds.map((id: string) => parseInt(id)));
		}
	}, [unitIds]);

	const onAddUnit = async () => {
		const nextUnit = businessUnitsList.find(
			(unit: any) => !unitIds.includes(unit.id.toString()),
		);
		await addBusinessUnit(nextUnit.id);
		setSelectedUnitsArr([...selectedUnitsArr, nextUnit.id]);
	};

	const onSelectUnit = async (value: number, prevUnitId: any) => {
		//TODO remove after fix todo in reactselect.componenet.native.
		if (value !== prevUnitId) {
			const prevIndex = selectedUnitsArr.findIndex(
				(unit: any) => unit === prevUnitId,
			);
			const tempSelected = [...selectedUnitsArr];
			tempSelected[prevIndex] = value;

			getValuesFunctions.current.delete(prevUnitId);
			validationFunctions.current.delete(prevUnitId);
			setSelectedUnitsArr(tempSelected);
			addBusinessUnit(value, prevUnitId);
		}
	};

	const onDeleteUnit = async (id: number) => {
		const units = selectedUnitsArr.filter((unit: any) => unit !== id);
		const tempRoles = { ...rolesInfo };
		delete tempRoles[id];
		getValuesFunctions.current.delete(id);
		validationFunctions.current.delete(id);
		setRoles({ ...tempRoles });
		setSelectedUnitsArr([...units]);
	};

	return (
		<View>
			{businessUnitsList.length > 1 && (
				<View
					style={{
						flexDirection: isMobile ? 'row-reverse' : 'row',
						alignItems: 'baseline',
						marginBottom: 13,
						paddingHorizontal: isMobile ? 20 : 0,
						marginTop: 18,
					}}
				>
					<I18n
						size={isMobile ? 16 : 20}
						style={{ flex: 1 }}
						weight='bold'
					>
						general.unitId
					</I18n>
					{unitIds?.length !== businessUnitsList.length &&
						canAddUnit && (
							<TouchableOpacity
								onPress={() => onAddUnit()}
								disabled={disabled}
								style={[
									styles.actionButtons,
									{
										flexDirection: isMobile
											? 'row-reverse'
											: 'row',
										opacity: disabled ? 0.5 : 1,
									},
								]}
							>
								<ImageIcon
									name='plusWhite'
									height={18}
									width={18}
									style={{ marginLeft: 5 }}
								/>
								<I18n color='white' weight='normal' size={16}>
									editUserModal.addUnit
								</I18n>
							</TouchableOpacity>
						)}
				</View>
			)}
			{selectedUnitsArr?.map((unit: string, index: number) => (
				<UnitsDataSection
					departments={rolesInfo[unit]?.departments}
					isEditUser={isEditUser}
					unitData={{ ...(rolesInfo[unit] || {}), id: unit }}
					key={unit}
					validationFunctions={validationFunctions}
					getValuesFunctions={getValuesFunctions}
					shouldShowTitle={businessUnitsList.length === 1}
					businessUnitsList={businessUnitsList}
					onSelectUnit={onSelectUnit}
					onDeleteUnit={onDeleteUnit}
					disabled={disabled}
					canDelete={selectedUnitsArr.length > 1 && !disabled}
					defaultOpenUnit={defaultOpenUnit}
					selectedUnitsList={selectedUnitsArr}
					setRoles={setRoles}
					isUpdateHimself={isUpdateHimself}
					index={index}
				>
					{!isUpdateHimself &&
					(hasPermissionOnBusinessUnit(
						parseInt(unit),
						Privileges.ROLE_MGM_USER_READ,
					) ||
						hasPermissionOnBusinessUnit(
							parseInt(unit),
							Privileges.ROLE_MGM_USER_WRITE,
						)) ? (
						<RolesWrapper
							{...{
								Component,
								userId,
								departments: rolesInfo[unit]?.departments,
								roles: rolesInfo[unit] || {},
								setRoles,
								setIsModalOpen,
								businessUnitsList,
								unitId: unit,
								hasOnlyOneUnit: businessUnitsList.length === 1,
								disableSelectEmployeeRole,
							}}
						/>
					) : null}
				</UnitsDataSection>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 210,
		backgroundColor: colors.lightPrimary,
		borderRadius: 35,
		minHeight: 40,
		paddingHorizontal: 22,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default UnitsRolesWrapper;
