import { Slide, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';

import I18n from '../i18n/I18n.component';
import colors from '../../../config/colors';
import Button from '../Buttons/Button.component';
import { useWindowDimensions } from 'react-native';
import classNames from 'classnames';

interface Props {
	showSlide: boolean;
}

interface PropsComponent {
	children: any;
	setIsDrawerOpen?: any;
	setOpenDrawerFunc: Function;
	icon: any;
}

const useStyles = makeStyles<Theme, Props>(theme => {
	return {
		slideWrapper: {
			position: 'relative',
			display: 'flex',
			zIndex: 1,
		},
		wrapper: {
			position: 'relative',
			display: 'flex',
			zIndex: 1,
		},
		slideBtn: {
			position: 'absolute',
			left: ({ showSlide }) => (showSlide ? 0 : -30),
			top: 44,
			zIndex: 3,
			display: 'flex',
			flexDirection: 'row',
			cursor: 'pointer',
		},
		button: {
			alignSelf: 'center',
			height: 33,
			width: 197,
			backgroundColor: colors.warning,
			alignItems: 'center',
			justifyContent: 'center',
			borderTopRightRadius: 98,
			borderTopLeftRadius: 98,
		},
		expanded: {
			width: 208,
			minWidth: 208,
			animationDuration: '0.35s',
			animationTimingFunction: 'ease',
			animationName: '$slideRight',
		},
		narrow: {
			width: 0,
			minWidth: 0,
			animationDuration: '0.35s',
			animationTimingFunction: 'ease',
			animationName: '$slideLeft',
		},
		'@keyframes slideLeft': {
			from: {
				width: 208,
				minWidth: 0,
			},
			to: {
				width: 0,
				minWidth: 0,
			},
		},
		'@keyframes slideRight': {
			from: {
				width: 0,
				minWidth: 208,
			},
			to: {
				width: 208,
				minWidth: 208,
			},
		},
	};
});

const LeftDrawer: FC<PropsComponent> = ({
	children,
	setIsDrawerOpen,
	setOpenDrawerFunc,
	icon,
}) => {
	const [showSlide, setShowSlide] = useState(true);
	const dimensions = useWindowDimensions();
	const containerRef = React.useRef(null);
	const onShowSlideHandlerRef = useRef<Function>();

	const classes = useStyles({ showSlide });

	const onShowSlideHandler = () => {
		setIsDrawerOpen(!showSlide);
		setShowSlide(prevState => !prevState);
	};

	onShowSlideHandlerRef.current = onShowSlideHandler;

	useEffect(() => {
		setOpenDrawerFunc && setOpenDrawerFunc(onShowSlideHandlerRef);
	}, []);

	return (
		<div className={classes.slideWrapper} ref={containerRef}>
			<div
				className={classNames(
					classes.wrapper,
					showSlide ? classes.expanded : classes.narrow,
				)}
				style={{ overflow: showSlide ? 'visible' : 'auto' }}
			>
				{children}
			</div>
			<Button
				buttonStyle={{
					position: showSlide ? 'absolute' : 'unset',
					alignSelf: 'center',
					top: (dimensions.height - 233) / 2 - 60,
					marginTop: showSlide
						? 0
						: (dimensions.height - 233) / 2 - 60,
					right: showSlide ? -33 : 170,
					zIndex: 100,
					height: 120,
					width: 33,
					backgroundColor: colors.warning,
					alignItems: 'center',
					justifyContent: 'center',
					borderTopRightRadius: 98,
					borderBottomRightRadius: 98,
				}}
				onPress={onShowSlideHandler}
			>
				{icon || (
					<FontAwesome
						name='comment'
						size={14}
						color={colors.white}
					/>
				)}
			</Button>
		</div>
	);
};

export default LeftDrawer;
