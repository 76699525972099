import { StyleSheet, useWindowDimensions, View } from 'react-native';
import React from 'react';
import I18n from '../i18n/I18n.component';
import colors from '../../../config/colors';
import ImageIcon from '../Icon/ImageIcon.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';

interface Props {
	iconName?: string;
	title?: string;
	showIcon?: boolean;
	showTitle?: boolean;
	isMobile?: boolean;
	style?: Object;
	iconWidth?: number;
	iconHeight?: number;
	iconStyle?: Object;
	textStyle?: Object;
	textColor?: string;
	textWeight?: string;
	textSize?: number;
}

const NoResults = ({
	iconName = 'noResults',
	title = 'general.noResultsFound',
	showIcon = true,
	showTitle = true,
	isMobile,
	style,
	iconWidth,
	iconHeight,
	iconStyle,
	textStyle,
	textColor = colors.darkGrey,
	textWeight = '400',
	textSize = 20,
}: Props) => {
	const dimensions = useWindowDimensions();
	const platform = useDevicePlatform();
	const isitMobile = isMobile || platform !== DeviceType.WEB;

	const iconWidthSet = iconWidth || isitMobile ? 179 : 265;
	const iconHeightSet = iconHeight || isitMobile ? 109 : 161;

	return (
		<View
			style={[
				isitMobile
					? [
							styles.mobileContainer,
							{ height: dimensions.height - 270 },
					  ]
					: styles.webContainer,
				style,
			]}
		>
			{showIcon && (
				<ImageIcon
					name={iconName}
					width={iconWidthSet}
					height={iconHeightSet}
					style={iconStyle}
				/>
			)}
			{showTitle && (
				<I18n
					color={textColor}
					weight={textWeight}
					size={textSize}
					style={[{ marginTop: isitMobile ? 15 : 20 }, textStyle]}
				>
					{title}
				</I18n>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	webContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.white,
	},
	mobileContainer: {
		margin: 15,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: colors.white,
	},
});

export default NoResults;
