import React from 'react';
import useLoadFonts from './src/hooks/useLoadFonts.hook';
import { Provider } from 'react-redux';
import store from './src/store/store';
import Router from './src/pages/Router/RouterWrapper';
import { Platform, SafeAreaView, View, StatusBar } from 'react-native';
import { Provider as MenuProvider } from 'react-native-paper';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import ModalsContainer from './src/components/shared/Modal/Modals.container';
import colors from './src/config/colors';

const App = () => {
	const didLoadFont = useLoadFonts();
	if (!didLoadFont) return null;

	return (
		<SafeAreaView style={{ flex: 1, overflow: 'hidden' }}>
			{Platform.OS === 'android' && Platform.Version >= 20 ? (
				<View
					style={{
						height: StatusBar.currentHeight,
						backgroundColor: colors.darkGrey,
					}}
				/>
			) : null}
			<Provider store={store}>
				<ActionSheetProvider>
					<MenuProvider>
						<Router />
						<ModalsContainer />
					</MenuProvider>
				</ActionSheetProvider>
			</Provider>
		</SafeAreaView>
	);
};

export default App;
