import { UserModel } from '../../../models/users.model';
import httpService from '../../../services/http.service';

export const initialState: UsersTabeState = {
	search: '',
	users: [],
	errorMessage: '',
	completed: true,
	totalReports: undefined,
	hasMoreUsers: false,
	page: 0,
	pageSize: 30,
	sortBy: 'fullName',
	sortDirection: 'ASC',
};

export type UsersTabeState = {
	users: any[];
	errorMessage?: string;
	completed: boolean;
	totalReports?: number;
	hasMoreUsers: boolean;
	page: number;
	pageSize: number;
	sortBy: string;
	sortDirection: string;
	search: string;
};
export type Action = {
	type: string;
	payload: any;
};

export const UsersTabeReducer: React.Reducer<UsersTabeState, Action> = (
	state,
	action,
) => {
	switch (action.type) {
		case 'reset_page':
			return {
				...state,
				users: [],
				hasMoreUsers: false,
				page: 0,
			};
		case 'increment_page':
			return {
				...state,
				page: state.page + 1,
			};
		case 'set_sort':
			return {
				...state,
				page: 0,
				sortBy: action.payload.field,
				sortDirection: action.payload.direction,
				search: state.search,
			};
		case 'update_search':
			return {
				...state,
				page: 0,
				search: action.payload,
			};
		case 'fetch_users_request':
			return {
				...state,
				completed: false,
			};
		case 'update_user':
			const tempUsers = [...state.users];
			const updatedIdx = tempUsers.findIndex(
				user => user.id === action.payload.id,
			);
			if (updatedIdx >= 0) {
				tempUsers[updatedIdx] = {
					...tempUsers[updatedIdx],
					...action.payload,
				};
			}
			return {
				...state,
				users: [...tempUsers],
			};
		case 'fetch_users_success':
			return {
				...state,
				errorMessage:
					state.page === 0 && !action.payload.data?.length
						? 'usersManagement.table.noUsers'
						: undefined,
				completed: true,
				totalReports: action.payload.total,
				hasMoreUsers: action.payload.data?.length === state.pageSize,
				users:
					state.page === 0
						? [...action.payload.data]
						: [...state.users, ...action.payload.data],
			};
		default:
			return state;
	}
};

export const fetchUserReports = ({
	sortBy,
	page,
	pageSize,
	sortDirection,
	filter,
	dispatch,
	search,
}: any) => {
	dispatch({ action: 'fetch_users_request' });
	httpService
		.api<any>({
			type: 'getUserReports',
			data: {
				p: page,
				ps: pageSize,
				s: sortBy,
				sd: sortDirection,
				f: filter,
				search: search || '',
			},
		})
		.then(data => {
			dispatch({
				type: 'fetch_users_success',
				payload: {
					...data,
					data: data.data.map((report: any) => new UserModel(report)),
				},
			});
		});
	// .catch(err => dispatch(fetchVacationReportsFailure(err)));
};

export const fetchUsersReportExcel = async ({
	sortBy,
	sortDirection,
	filter,
}: any) => {
	try {
		const res = await httpService.api<any>({
			type: 'getUsersExcel',
			responseType: 'blob',
			returnAllRes: true,
			data: {
				s: sortBy,
				sd: sortDirection,
				f: filter,
			},
		});
		return res;
	} catch (e) {
		console.log(e);
	}
};
