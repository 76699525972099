import React from 'react';
import {
	ImageBackground,
	StyleSheet,
	View,
	Platform,
	KeyboardAvoidingView,
} from 'react-native';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import colors from '../../config/colors';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { openStore } from '../../utils/globals.utils';

const imageSrc = require('../../../assets/images/login/bg.png');

const ForceUpdate = () => {
	const currPlatform = useDevicePlatform();
	const isMobile: boolean = currPlatform !== DeviceType.WEB;

	return (
		<View style={styles.wrapper}>
			<ImageBackground
				source={imageSrc}
				style={[
					styles.image,
					{
						height: isMobile ? 300 : 700,
						width: isMobile ? 430 : 700,
						bottom: isMobile ? -50 : 0,
					},
				]}
			></ImageBackground>
			<KeyboardAvoidingView
				behavior='padding'
				enabled={Platform.OS === 'ios'}
				keyboardVerticalOffset={100}
			>
				<View
					style={[
						styles.outerContainer,
						{
							width: isMobile ? 334 : 448,
							height: isMobile ? 435 : 474,
						},
					]}
				>
					<View style={styles.container}>
						<View style={styles.logoSection}>
							<ImageIcon
								name={'liram'}
								width={96}
								height={29}
							></ImageIcon>
							<ImageIcon
								name={'michpal'}
								width={83}
								height={59}
							></ImageIcon>
						</View>
						<View style={styles.separatorLine}></View>
						<View style={{ marginTop: 47 }}>
							<I18n size={22} weight='normal'>
								forceUpdate.message
							</I18n>
							<SolidButtonContainer
								overrideStyle={{
									height: 61,
									marginTop: 40,
									alignItems: 'center',
									justifyContent: 'center',
								}}
								onPress={openStore}
							>
								<I18n size={16} weight='normal' color='white'>
									forceUpdate.updateButton
								</I18n>
							</SolidButtonContainer>
						</View>
					</View>
				</View>
			</KeyboardAvoidingView>
		</View>
	);
};

const styles = StyleSheet.create({
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		height: '100%',
		width: '100%',
		overflow: 'hidden',
		backgroundColor: '#efefef',
	},
	image: {
		resizeMode: 'cover',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
	},
	outerContainer: {
		backgroundColor: colors.white,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		paddingHorizontal: 16,
		paddingTop: 18,
		paddingBottom: 47,
	},
	container: {
		flexDirection: 'column',
		width: 301,
	},
	logoSection: {
		height: 70,
		paddingBottom: 10,
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	separatorLine: {
		backgroundColor: colors.primary,
		height: 1,
		opacity: 0.38,
	},
});
export default ForceUpdate;
