import { FontAwesome } from '@expo/vector-icons';
import moment from 'moment';
import React from 'react';
import { TouchableHighlight, View, StyleSheet, Platform } from 'react-native';
import colors from '../../../config/colors';
import useDevicePlatform from '../../../hooks/useDevicePlatform.hook';
import {
	BusinessUnit,
	Employee101Data,
} from '../../../pages/ReportPDFPage/ReportPDFPage.utils';
import { goBack } from '../../../utils/goBack.utils';
import Select from '../FieldBuilder/Select/ReactSelect.component';
import I18n from '../i18n/I18n.component';
import ImageIcon from '../Icon/ImageIcon.component';
import Typography from '../Typography/Typography.component';
import MobileWorkerCardHeader from './MobileWorkerCardHeader.component';

const monthsMap: any = {
	january: 'ינואר',
	february: 'פברואר',
	march: 'מרץ',
	april: 'אפריל',
	may: 'מאי',
	june: 'יוני',
	july: 'יולי',
	august: 'אוגוסט',
	september: 'ספטמבר',
	october: 'אוקטובר',
	november: 'נובמבר',
	december: 'דצמבר',
};

interface ReportRangeListContainerProps {
	download: Function;
	navigation?: any;
	isError?: boolean;
	selectedDate?: string;
	title?: string;
	dateMode?: string;
	backText?: string;
	downloadOnMobile?: boolean;
	innerHeaderData?: Employee101Data;
	businessUnitsList: BusinessUnit[];
	onChangeBusinessUnit: (id: string) => void;
	selectedBusinessUnit: BusinessUnit | null;
}

const ReportsViewHeader = ({
	download,
	navigation,
	isError,
	selectedDate,
	title,
	dateMode,
	backText,
	innerHeaderData,
	businessUnitsList,
	onChangeBusinessUnit,
	selectedBusinessUnit,
	downloadOnMobile = true,
}: ReportRangeListContainerProps) => {
	const devicePlatform = useDevicePlatform();
	const shouldShowUnitSelect = businessUnitsList?.length > 1;
	const downloadBtnStyle = StyleSheet.flatten([
		styles.btn,
		isError && styles.btnDisabled,
	]);

	return (
		<View style={styles.header}>
			<View style={styles.headerTop}>
				<TouchableHighlight
					underlayColor='transparent'
					onPress={() => goBack(navigation)}
				>
					<View style={styles.goBack}>
						<FontAwesome
							name='angle-left'
							style={{
								fontSize: 26,
								color: colors.darkGrey,
							}}
						/>
						<I18n
							style={styles.title}
							size={20}
							weight='400'
							color='darkGrey'
						>
							{backText}
						</I18n>
					</View>
				</TouchableHighlight>

				{
					<View style={styles.actionBtns}>
						<TouchableHighlight
							disabled={isError}
							style={downloadBtnStyle}
							underlayColor='transparent'
							onPress={e => {
								e.persist();
								download();
							}}
						>
							<ImageIcon
								name='downloadPaycheck'
								height={20}
								width={20}
							/>
						</TouchableHighlight>
					</View>
				}
			</View>
			{!innerHeaderData && (
				<View style={styles.headerText}>
					{selectedDate && (
						<Typography size={20} weight={'normal'}>
							{dateMode === 'months'
								? `${
										monthsMap[
											moment(selectedDate)
												.format('MMMM')
												.toLowerCase()
										]
								  } `
								: null}
							{moment(selectedDate).format('YYYY')}
						</Typography>
					)}
					<I18n
						style={styles.title}
						size={16}
						weight='400'
						color='darkGrey'
					>
						{title}
					</I18n>
				</View>
			)}

			{innerHeaderData && (
				<MobileWorkerCardHeader innerHeaderData={innerHeaderData} />
			)}
			{shouldShowUnitSelect && (
				<View style={styles.filterText}>
					<Select
						options={businessUnitsList}
						onChange={onChangeBusinessUnit}
						value={selectedBusinessUnit?.id}
						style={{ width: 200 }}
					/>
					<I18n
						size={16}
						weight='400'
						color='darkGrey'
						style={{ marginHorizontal: 3 }}
					>
						:
					</I18n>
					<I18n
						style={styles.title}
						size={16}
						weight='400'
						color='darkGrey'
					>
						general.business
					</I18n>
				</View>
			)}
			<View style={styles.separator} />
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'column',
		zIndex: 1,
	},
	paycheckView: {
		position: 'relative',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		marginRight: 30,
		backgroundColor: colors.white,
	},
	goBack: {
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: 21,
	},
	title: {
		marginLeft: 8,
	},
	btn: {
		height: 20,
		width: 40,
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	headerTop: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 35,
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		marginRight: 18,
	},
	headerText: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 22,
		marginRight: 18,
	},
	filterText: {
		zIndex: 1,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 22,
		marginRight: 18,
		alignSelf: 'flex-end',
	},
	separator: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
		marginBottom: 16.5,
		marginLeft: 20,
		marginRight: 18,
		marginTop: 22.5,
	},
	errorMessage: {
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	btnDisabled: {
		opacity: 0.5,
	},
});

export default ReportsViewHeader;
