import * as repActions from '../actions/repEnterMode.actions';
import {
	RepEnterModeState,
	StoreAction,
} from '../../interfaces/redux.interface';
import { EmployerPrivileges } from '../../constants/roleTypes.constant';
import { storeDataByKey } from '../actions/login.actions';
import { AsyncLocalStorage } from '../../config/localStorage';
import _ from 'lodash';

const initialState: RepEnterModeState = {
	customerName: '',
	customerHp: undefined,
	customerId: undefined,
	privilegs: undefined,
	customer_privilege: null,
	privileges: [],
	employeeRolePrivileges: [],
	employerRolePrivileges: [],
	repRolePrivileges: [],
	customer_modules: {},
	goBackTo: '',
};

const repEnterModeReducer = (
	state: typeof initialState = initialState,
	action: StoreAction<any>,
): any => {
	switch (action.type) {
		case repActions.SET_REP_ENTER_CUSTOMER_MODE:
			const isEnter = action.payload.hp || action.payload.id;
			storeDataByKey(
				AsyncLocalStorage.REP_DATA,
				JSON.stringify(isEnter ? action.payload : {}),
			);
			storeDataByKey(
				AsyncLocalStorage.REP_EXIT_FROM,
				JSON.stringify(
					isEnter
						? {}
						: {
								customerHp: state.customerHp,
								goBackTo: state.goBackTo,
						  },
				),
			);

			const { customer_privilege, customer_modules, privileges } = isEnter
				? action.payload.customer_privilege.reduce(
						(res: any, current: any) => ({
							customer_privilege: {
								...res.customer_privilege,
								[current.customerId]: current.privileges,
							},
							customer_modules: {
								...res.customer_modules,
								[current.customerId]: current.modules,
							},
							privileges: [
								...res.privileges,
								...(current.privileges || []),
							],
						}),
						{
							customer_privilege: {},
							customer_modules: {},
							privileges: [],
						},
				  )
				: {
						customer_privilege: {},
						customer_modules: {},
						privileges: [],
				  };

			return {
				...state,
				customerName: action.payload.name,
				customerHp: action.payload.hp || action.payload.id,
				customerId: action.payload.customerId,
				goBackTo: action.payload.goBackTo,
				privileges: _.uniq(privileges),
				customer_privilege,
				customer_modules,
				allowMultipleSelection: isEnter ? true : false,
				employerRolePrivileges: _.uniq(privileges),
				employeeRolePrivileges: [],
				repRolePrivileges: [],
			};
		default:
			return state;
	}
};

export default repEnterModeReducer;
