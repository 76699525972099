import React from 'react';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import { getState } from '../../../../store/store';
import { i18nService } from '../../../../services/i18n.service';

interface Header {
	Header: string;
	accessor: string;
	id: string;
}

Font.register({
	family: 'RubikBold',
	src: '/fonts/Rubik-Bold.ttf',
});

Font.register({
	family: 'RubikMedium',
	src: '/fonts/Rubik-Medium.ttf',
});

Font.register({
	family: 'RubikRegular',
	src: '/fonts/Rubik-Regular.ttf',
});

const HeaderCells = ({ originalData, showSum }) => {
	return (
		<View style={styles.header}>
			<View style={[styles.headerCell, styles.rightColumn]}>
				<Text style={[styles.text, { color: 'black' }]}>פריט</Text>
			</View>
			{originalData.map((item: Header, index) => (
				<View style={styles.headerCell}>
					<Text style={[styles.text, { color: 'black' }]}>
						{item.fullName}
					</Text>
					<Text style={[styles.text, { color: 'black' }]}>
						{item.idNumber}
					</Text>
				</View>
			))}
			{showSum && (
				<View style={[styles.headerCell, { justifyContent: 'center' }]}>
					<Text
						style={[
							styles.text,
							{ color: 'black', paddingRight: 16 },
						]}
					>
						סה"כ
					</Text>
				</View>
			)}
		</View>
	);
};

const SubRowsHeader = ({ data, originalData, showSum }) => {
	return (
		<View>
			<View style={styles.subRowHeader}>
				<Text style={[styles.text, { color: 'white' }]}>
					{data.item}
				</Text>
			</View>
			<View style={{ flexDirection: 'column' }}>
				{data.subRows.map((itm, index) => {
					return (
						<View
							wrap={false}
							style={[
								{ flexDirection: 'row-reverse' },
								index !== 0
									? {
										borderTopWidth: 1,
										borderColor: '#D1D1D1',
										borderTopStyle: 'solid',
									}
									: {},
							]}
						>
							<View style={[styles.subRow, styles.rightColumn]}>
								<Text style={styles.text}>{itm.item}</Text>
							</View>
							{originalData.map((it, index) => (
								<View style={styles.subRow}>
									<Text style={styles.text}>
										{itm[it.profileId]}
									</Text>
								</View>
							))}
							{showSum && (
								<View style={styles.sum}>
									<Text style={styles.text}>{itm.sum}</Text>
								</View>
							)}
						</View>
					);
				})}
			</View>
		</View>
	);
};

const Row = ({ data, originalData, showSum }) => {
	return (
		<View
			wrap={false}
			style={{
				flexDirection: 'row-reverse',
				backgroundColor: 'rgb(239, 244, 248)',
				borderTopWidth: 1,
				borderColor: '#D1D1D1',
				borderTopStyle: 'solid',
			}}
		>
			<View style={[styles.row, styles.rightColumn]}>
				<Text style={[styles.text, { color: 'rgb(40, 130, 184)' }]}>
					{data.item}
				</Text>
			</View>
			{originalData.map((it, index) => (
				<View style={styles.row}>
					<Text style={styles.text}>{data[it.profileId]}</Text>
				</View>
			))}
			{showSum && (
				<View
					style={[
						styles.sum,
						{ backgroundColor: 'rgb(224, 233, 239)' },
					]}
				>
					<Text style={styles.text}>{data.sum}</Text>
				</View>
			)}
		</View>
	);
};

export const WorkPaperPdfCreator = ({ pdfData, filters }) => {
	const employerData = getState().login.employer;

	const employer =
		typeof employerData === 'string'
			? JSON.parse(employerData)
			: employerData;

	console.log({ pdfData });
	return (
		<Document>
			{pdfData &&
				pdfData?.arrangedData.map((i: any, index: number) => {
					return (
						<Page
							size='A4'
							style={{
								backgroundColor: '#fff',
								fontFamily: 'RubikMedium',
								padding: 30,
								justifyContent: 'space-between',
							}}
							key={`${index}`}
						>
							<View>
								<View fixed>
									<Text style={styles.headerText}>
										{employer.name}
									</Text>
									<View
										style={{
											flexDirection: 'row-reverse',
											paddingTop: 10,
										}}
									>
										<Text style={styles.footerText}>
											פירוט נתוני שכר{' '}
										</Text>
										<Text
											style={styles.footerText}
										>{`${filters.to_month[0]}.${filters.to_year[0]}-${filters.from_month[0]}.${filters.from_year[0]}`}</Text>
									</View>
									<View
										style={{
											flexDirection: 'row-reverse',
											paddingTop: 5,
											paddingBottom: 10,
										}}
									>
										<Text style={styles.footerText}>
											תאריך יצירת דוח:{' '}
										</Text>
										<Text style={styles.footerText}>
											{moment().format(
												'HH:mm DD.MM.YYYY',
											)}
										</Text>
									</View>
								</View>

								<HeaderCells
									originalData={pdfData?.slicedData[index]}
									showSum={
										index + 1 ===
										pdfData?.arrangedData.length
									}
								/>
								{i?.rows.map((item: any) => {
									return item.canHaveSubRows ? (
										<SubRowsHeader
											data={item}
											originalData={
												pdfData?.slicedData[index]
											}
											showSum={
												index + 1 ===
												pdfData?.arrangedData.length
											}
										/>
									) : item.isSaperator ? (
										<View style={styles.subRowHeader} />
									) : (
										<Row
											data={item}
											originalData={
												pdfData?.slicedData[index]
											}
											showSum={
												index + 1 ===
												pdfData?.arrangedData.length
											}
										/>
									);
								})}
							</View>
							<View style={[styles.header]} fixed>
								<Text
									style={[
										styles.footerText,
										{ alignSelf: 'flex-end' },
									]}
									render={({ pageNumber, totalPages }) =>
										`עמוד ${pageNumber
											.toString()
											.split('')
											.reverse()
											.join(
												'',
											)} מ ${totalPages
												?.toString()
												.split('')
												.reverse()
												.join('')}`
									}
								/>
								<View style={{ flex: 1 }} />
								<Text
									style={[
										styles.footerText,
										{ alignSelf: 'flex-end' },
									]}
								>
									{i18nService.translate(
										'workPaper.pdf.generateBy',
									)}
								</Text>
							</View>
						</Page>
					);
				})}
		</Document>
	);
};

const styles = StyleSheet.create({
	header: {
		flexDirection: 'row-reverse',
	},
	headerText: {
		fontSize: 20,
		color: 'black',
		textAlign: 'right',
		fontFamily: 'RubikBold',
	},
	headerCell: {
		width: 70,
		paddingVertical: 7,
	},
	rightColumn: {
		paddingRight: 7,
		justifyContent: 'center',
		width: 105,
	},
	text: {
		fontSize: 9,
		color: '#525962',
		textAlign: 'right',
	},
	footerText: {
		textAlign: 'right',
		fontSize: 10,
		color: 'gray',
	},
	subRowHeader: {
		backgroundColor: 'rgb(40, 130, 184)',
		paddingVertical: 4,
		paddingHorizontal: 16,
	},
	subRow: {
		width: 70,
		height: 32,
		backgroundColor: 'white',
		justifyContent: 'center',
	},
	row: {
		width: 70,
		backgroundColor: 'rgb(239, 244, 248)',
		height: 32,
		justifyContent: 'center',
	},
	sum: {
		flex: 1,
		maxWidth: 100,
		backgroundColor: 'rgb(239, 242, 247)',
		paddingRight: 16,
		height: 32,
		justifyContent: 'center',
	},
});
