import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import { FilterData } from '../../../components/web/Filters/Filters.container';
import {
	getDepartmentsFilterList,
	getEmployeesFilterList,
	getRolesFilterList,
} from '../../../store/selectors/reportsFilters.selectors';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
	fetchRolesFilterList,
} from '../../../store/actions/reportsFilters.actions';
import {
	UsersTabeReducer,
	initialState,
	UsersTabeState,
	Action,
	fetchUserReports,
	fetchUsersReportExcel,
} from './usersTableReducer';
import UsersTablePage from './UsersTablePage.component';
import { useDispatch, useSelector } from 'react-redux';
import { i18nService } from '../../../services/i18n.service';
import { useRoute } from '@react-navigation/native';
import { getExcelFileFromResponse } from '../../../utils/downloadFile.web.utils';
import {
	getBussinesUnitsModules,
	getEmployerRolesPrivileges,
	getUserData,
} from '../../../store/selectors/login.selectors';
import { Platform } from 'react-native';
import { UserManagementContext } from '../userManagementContext';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';
import { compact } from 'lodash';
import { Privileges } from '../../../constants/roleTypes.constant';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';

const UsersTableContainer = ({ navigation }: any) => {
	const pageFilterSize = 20;
	const dispatch = useDispatch();
	const employeesFilterList = useSelector(getEmployeesFilterList);
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const rolesFilterList = useSelector(getRolesFilterList);
	const [isRolesPageOpened, setRolesPageOpened] = useState(false);
	const [mobileFilters, setMobileFilters] = useState({});
	const userData = useSelector(getUserData);
	const employerRolePrivileges = useSelector(getEmployerRolesPrivileges);
	const [filters, setFilters] = useState<any>({});
	const { userManagementData } = useContext(UserManagementContext);
	const route: any = useRoute();

	const [
		{
			users,
			hasMoreUsers,
			errorMessage,
			sortBy,
			sortDirection,
			page,
			pageSize,
			search,
		},
		localDispatch,
	] = useReducer<React.Reducer<UsersTabeState, Action>>(
		UsersTabeReducer,
		initialState,
	);
	const modules = useSelector(getBussinesUnitsModules);

	const docPermission = useSelector(getEmployerRolesPrivileges)?.includes(
		Privileges.ROLE_MNG_DOCS_WRITE,
	);

	const handleFilter = useCallback(
		(data: FilterData) => {
			if (data.name === 'search') {
				localDispatch({ type: `update_search`, payload: data.value });
				fetchUserReports({
					sortBy,
					sortDirection,
					page: 0,
					pageSize,
					filter: {
						...filters,
						unitIds: getSelectedUnits(filters?.unitIds),
					},
					search: data.value,
					dispatch: localDispatch,
				});
			} else {
				localDispatch({ type: 'reset_page', payload: null });
				let mobileData = {
					[data.name]: data.value.map?.((el: any) => el?.id) || [
						`${data.value}`,
					],
				};
				if (data.name === 'unitIds') {
					mobileData = {
						unitIds: data.isMulti
							? data.value
									.filter((val: any) => val.id !== 'all')
									.map((val: any) => val.id || val)
							: [data.value],
						dep: [],
						emp: [],
					};
				}
				setMobileFilters(prev => ({
					...prev,
					...mobileData,
				}));

				let tempFilter = { ...filters };
				switch (data.name) {
					case 'unitIds':
						tempFilter = {
							...tempFilter,
							unitIds: data.isMulti
								? data.value
										.filter((val: any) => val.id !== 'all')
										.map((val: any) => val.id || val)
								: [data.value],
							dep: [],
							emp: [],
						};
						break;
					case 'departments':
						tempFilter = {
							...tempFilter,
							emp: [],
							dep: data.value.map((el: any) => el.id),
						};
						break;
					case 'finish_work':
						tempFilter = {
							...tempFilter,
							finish_work: [`${data.value}`],
						};
						break;
					case 'employees':
						tempFilter = {
							...tempFilter,
							emp: data.value.map((el: any) => el.id),
						};
						break;
					case 'role':
						tempFilter = {
							...tempFilter,
							emp: [],
							role: data.value.map((el: any) => el.id),
						};
						break;
					case 'status':
						tempFilter = {
							...tempFilter,
							status:
								data.value.map((el: any) => el.id).length > 1
									? []
									: data.value.map((el: any) => el.id),
						};
						break;
				}

				setFilters((prev: any) => ({ ...prev, ...tempFilter }));
				fetchUserReports({
					sortBy,
					sortDirection,
					page: 0,
					pageSize,
					filter: {
						...tempFilter,
						unitIds: getSelectedUnits(tempFilter?.unitIds),
					},
					search,
					dispatch: localDispatch,
				});
			}
		},
		[filters, sortDirection, sortBy, search, pageSize],
	);

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.usersManagment.privileges,
		onChangeFilter: handleFilter,
	});

	const onSort = useCallback(
		(field, direction) => {
			localDispatch({ type: 'set_sort', payload: { field, direction } });
			fetchUserReports({
				sortBy: field,
				sortDirection: direction,
				page: 0,
				pageSize,
				filter: {
					...filters,
					unitIds: getSelectedUnits(filters?.unitIds),
				},
				search,
				dispatch: localDispatch,
			});
		},
		[filters, search, pageSize],
	);

	useEffect(() => {
		if ((userManagementData as any)?.shouldRefresh) {
			refreshPage();
		} else if (userManagementData) {
			updateRow(userManagementData);
		}
	}, [userManagementData]);

	useEffect(() => {
		onMount();
	}, [userData]);

	useEffect(() => {
		if (filters.unitIds?.length || multiValue) {
			dispatch(fetchRolesFilterList(getSelectedUnits(filters.unitIds)));
			dispatch(
				fetchDepartmentsFilterList(
					'users',
					getSelectedUnits(filters.unitIds),
				),
			);
		}
	}, [filters.unitIds]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('UsersTableContainer');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};

	const refreshPage = () => {
		fetchUserReports({
			sortBy,
			sortDirection,
			page: page ? 0 : page,
			pageSize,
			filter: {
				...filters,
				unitIds: getSelectedUnits(filters?.unitIds),
			},
			search,
			dispatch: localDispatch,
		});
	};

	const goToRolesPage = () => {
		navigation.navigate('rolesAndPermissions');
		setRolesPageOpened(true);
	};

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res: any = await fetchEmployeesFilterList('users', {
			page,
			pageSize: pageFilterSize,
			search,
			unitIds: getSelectedUnits(filters.unitIds),
			filter: { role: filters?.role || [], dep: filters?.dep || [] },
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: pageFilterSize === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const getExcel = async () => {
		const res = await fetchUsersReportExcel({
			sortBy: sortBy,
			sortDirection: sortDirection,
			filter: filters,
		});
		if (res) {
			getExcelFileFromResponse(res);
		}
	};

	const goBack = () => {
		navigation.navigate('users');
	};

	const filtersData = useMemo(
		() =>
			compact([
				{
					name: 'finish_work',
					type: 'checkbox',
					placeholder: 'usersManagement.filter.finishWork',
					value: filters?.finish_work?.[0] === 'true',
					styles: {
						width: 274,
					},
				},
				{
					name: 'search',
					options: [],
					type: 'searchInput',
					placeholder: 'search',
				},
				{
					name: 'status',
					type: 'multiselect',
					options: [
						{
							name: i18nService.translate('general.active'),
							id: 'true',
						},
						{
							name: i18nService.translate('general.inactive'),
							id: 'false',
						},
					],
					placeholder: 'usersManagement.status',
					key: JSON.stringify(userData),
				},
				{
					name: 'departments',
					type: 'multiselect',
					options: departmentsFilterList,
					placeholder: 'departments',
					styles: { control: { maxWidth: 140, minWidth: 140 } },
					key: `${JSON.stringify(userData)}${JSON.stringify(
						filters.unitIds,
					)}`,
				},
				{
					name: 'role',
					type: 'multiselect',
					options: rolesFilterList,
					placeholder: 'usersManagement.role',
					styles: { control: { maxWidth: 140, minWidth: 140 } },
					key: JSON.stringify(userData),
				},
				{
					name: 'employees',
					type: 'asyncmultiselect',
					placeholder: 'usersManagement.employees',
					loadOptions: getEmployeesFilter,
					key: `${JSON.stringify(filters?.dep)}${JSON.stringify(
						filters?.role,
					)}${departmentsFilterList}${JSON.stringify(
						filters.unitIds,
					)}`,
					styles: {
						width: 274,
					},
				},
				businessUnitsList?.length > 1
					? isBusinessUnitsMulti
						? {
								name: 'unitIds',
								type: 'multiselect',
								placeholder: 'businessUnits',
								noSpecialAll: true,
								value: filters.unitIds?.map((unit: any) =>
									businessUnitsList.find(
										(bunit: any) => bunit.id === unit,
									),
								),
								options: businessUnitsList,

								styles: {
									control: {
										width: 200,
										maxWidth: 200,
									},
								},
						  }
						: {
								name: 'unitIds',
								type: 'select',
								placeholder: 'businessUnits',
								defaultValue: filters.unitIds?.[0],
								options: businessUnitsList,
								hideDefaultOption: true,
								styles: {
									width: 150,
								},
						  }
					: undefined,
			]),
		[
			filters,
			employeesFilterList,
			departmentsFilterList,
			rolesFilterList,
			userData,
		],
	);

	const getNextData = useCallback(() => {
		if (hasMoreUsers) {
			localDispatch({ type: 'increment_page', payload: null });
			updateUsers({ page: page + 1 });
		}
	}, [hasMoreUsers, page, filters]);

	const updateRow = (data: any) => {
		localDispatch({ type: 'reset_page', payload: null });
		updateUsers({ page: 0 });
	};

	const showCreateUserBtn = useMemo(() => {
		return businessUnitsList.some((unit: any) =>
			hasPermissionOnBusinessUnit(
				unit.id,
				Privileges.ROLE_CREATE_USER_WRITE,
			),
		)
			? true
			: false;
	}, [filters?.unitIds]);

	const updateUsers = useCallback(
		(defaultData: any = {}) => {
			fetchUserReports({
				sortBy,
				sortDirection,
				page,
				pageSize,
				filter: {
					...filters,
					unitIds: getSelectedUnits(filters?.unitIds),
				},
				search,
				dispatch: localDispatch,
				...defaultData,
			});
		},
		[
			sortBy,
			sortDirection,
			page,
			pageSize,
			filters,
			search,
			dispatch,
			getSelectedUnits,
		],
	);

	return (
		<UsersTablePage
			filtersData={filtersData}
			errorMessage={errorMessage}
			onFilter={handleFilter}
			data={users}
			navigation={navigation}
			onSort={onSort}
			getNextData={getNextData}
			getExcel={getExcel}
			goBack={goBack}
			isRolesPageOpened={isRolesPageOpened}
			goToRolesPage={goToRolesPage}
			route={route}
			onModalBtnPressedHandler={() => {}}
			modalOpened={false}
			modalData={[]}
			userData={userData}
			employerRolePrivileges={employerRolePrivileges}
			updateUsers={updateUsers}
			updateRow={updateRow}
			selectedFilters={filters}
			selectedFiltersForMobile={mobileFilters}
			refreshPage={refreshPage}
			getSelectedUnits={getSelectedUnits}
			businessUnitsList={businessUnitsList}
			docPermission={docPermission}
			showCreateUserBtn={showCreateUserBtn}
			modules={modules}
		/>
	);
};

export default UsersTableContainer;
