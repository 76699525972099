import React, { useState, useEffect, useRef, useMemo } from 'react';
import ClockReport from './Clock.component';
import ClockReportMobile from './ClockMobile.component';
import { modalService } from '../../../services/modal.service';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import CompleteEntryContainer from './CompleteEntryModal/CompleteEntryModal.container';
import httpService from '../../../services/http.service';
import I18n from '../i18n/I18n.component';
import {
	convertFromUtc,
	calcTimerInitValue,
	cantReportModal,
	onDelete,
} from './Clock.utils';
import { Record } from './Record/Record.component';
import { openAlertModal } from './Clock.utils';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../services/i18n.service';
import moment from 'moment';
import { Option } from './EventsAndProjectsSelect/EventsAndProjectsSelect.utils';
import Typography from '../Typography/Typography.component';
import { toNumber } from 'lodash';
import { getUserData } from '../../../store/selectors/login.selectors';
import { Privileges } from '../../../constants/roleTypes.constant';
import { useSelector, useDispatch } from 'react-redux';
import {
	getTodayWorkLogs,
	getReFetchedFlag,
} from '../../../store/selectors/clockReport.selector';
import {
	setTodayWorklogs,
	setTodayWorkLogsAction,
	toggleReFetch,
} from '../../../store/actions/clockReport.actions';
import { useRoute } from '@react-navigation/native';
import AddRequest from '../../../modals/AddRequestModal/AddRequestModal.container';
import { getCurrentDateTime } from '../../../store/actions/dateTime.actions';
import { getServerDateTime } from '../../../store/selectors/dateTime.selectors';
import { getState } from '../../../store/store';
import { formatStandardHours } from '../../../modals/AddRequestModal/AddRequestModal.utils';
import { getLocationPermission } from '../../../utils/permissions.utils';
import { AppState, Platform } from 'react-native';
import useEffectNotInitial from '../../../hooks/useEffectNotInitial';

interface SelectedItem {
	name: any;
	isProject: boolean;
	id: any;
	isFullDay: boolean;
}

const ClockReportContainer = ({
	report101warning,
	eventsOptionsProp = null,
	standardHoursProp = undefined,
	workLogListForClock = null,
}: {
	report101warning?: boolean;
	eventsOptionsProp?: any;
	standardHoursProp?: any;
	workLogListForClock?: any;
}) => {
	const userData = useSelector(getUserData);
	const isFinishWorkDateExpired = useMemo(
		() =>
			moment
				.utc(getState().dateTime.currentDateTime)
				.local()
				.isAfter(
					moment(
						userData?.operative_module?.[101]?.finish_work,
						'YYYY-MM-DD',
					),
				),
		[userData],
	);

	const route = useRoute();
	const dispatch = useDispatch();
	const currentUtcTime = useSelector(getServerDateTime);
	const reFetchFlag = useSelector(getReFetchedFlag);
	const todayWorkLogs = useSelector(getTodayWorkLogs);
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const isWebOrMobileweb = platform !== DeviceType.MOBILE;
	const Component = useMemo(
		() => (isMobile ? ClockReportMobile : ClockReport),
		[isMobile],
	);
	const isFirstInit = useRef(true);
	const reportedExit = useRef(false);
	const [initTime, setInitTime] = useState<any>(null);
	const [currentRunnigWorkLog, setCurrentRunningWorkLog] =
		useState<any>(null);
	const [shiftTime, setShiftTime] = useState(12); //default

	const currentRunnigWorkLogRef = useRef<any>();
	currentRunnigWorkLogRef.current = currentRunnigWorkLog;
	const shiftTimeRef = useRef<any>();
	shiftTimeRef.current = shiftTime;
	const canEdit = useMemo(() => {
		return (
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_WRITE,
			) && userData.customer_setting.allowUpdateReport
		);
	}, [userData]);
	const [missingExitWorkLog, setMissingExitWorkLog] = useState({
		isMissing: false,
		workLogId: null,
	});
	const [defaultProjectOption, setDefaultProjectOption] = useState({
		name: '',
		id: null,
		lastReported: true,
	});
	const [defaultEventOption, setDefaultEventOption] = useState({
		name: '',
		id: '',
	});
	const [eventsOptions, setEventsOptions] = useState<Option[]>([]);
	const [projectsOptions, setProjectsOptions] = useState<Option[]>([]);
	const [selectedItem, setSelectedItem] = useState<SelectedItem>({
		name: null,
		isProject: true,
		id: null,
		isFullDay: true,
	});
	const [standardHours, setStandartHours] = useState<any>(null);
	const [selectedItemType, setSelectedItemType] = useState<
		'projects' | 'events' | null
	>(null);

	const handleOpenOptions = (
		isOpen: boolean,
		type: 'projects' | 'events',
	) => {
		if (isOpen) {
			switch (type) {
				case 'projects':
					setSelectedItemType('projects');
					break;
				case 'events':
					setSelectedItemType('events');
					break;

				default:
					return;
			}
		} else {
			setSelectedItemType(null);
		}
	};

	useEffect(() => {
		if (!currentRunnigWorkLog && selectedItem?.id) {
			setSelectedItem({
				name: null,
				isProject: true,
				id: null,
				isFullDay: true,
			});
		}
	}, [currentRunnigWorkLog]);

	const canReportFullDay = useMemo(() => {
		if (!standardHours) return todayWorkLogs.data.length === 0;
		//can report full day only if the standard is not bigger than max shift hours and no current work logs
		const standard = formatStandardHours(standardHours);
		const enter = moment(standard.enterTime, 'HH:mm');
		const exit = moment(standard.exitTime, 'HH:mm');

		const canReport = !(
			exit.diff(enter, 'minutes') >
			userData?.customer_setting.maxShiftHour * 60
		);
		return todayWorkLogs.data.length === 0 && canReport;
	}, [todayWorkLogs.data, standardHours]);

	const totalReported = useMemo(
		() =>
			todayWorkLogs.data.reduce((total: number, worklog: Record) => {
				return total + worklog.total / 60;
			}, 0),
		[todayWorkLogs, reFetchFlag],
	);

	const totalReportedRef = useRef<any>();
	totalReportedRef.current = totalReported;

	const getProjects = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getAllProjectNameList',
				query: {
					pageName: 'employeeHome',
					date: moment().format('YYYY-MM-DD'),
				},
			});
			if (res) {
				let tempDefaultOption: Option = {
					name: '',
					id: null,
					lastReported: true,
				};
				const options = res.filter((option: Option) => {
					if (option.lastReported) {
						tempDefaultOption = { ...option };
					}
					return option.lastReported === false;
				});
				if (res.length) {
					const allProjectOptions = tempDefaultOption.id
						? [{ ...tempDefaultOption }].concat(options)
						: options;
					setProjectsOptions(allProjectOptions);
					setDefaultProjectOption(tempDefaultOption);
				} else {
					setProjectsOptions([]);
				}
			}
		} catch (e) {}
	};

	const getEvents = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getAllEventNameList',
				query: { date: moment().format('YYYY-MM-DD') },
			});
			if (res) {
				setEventsOptions(res);
			}
		} catch (e) {}
	};

	useEffect(() => {
		if (!isFirstInit.current) {
			let missingExitWorklog = { isMissing: false, workLogId: null };

			todayWorkLogs.data.forEach((workLog: Record) => {
				if (!workLog.exitTime) {
					missingExitWorklog.isMissing = true;
					missingExitWorklog.workLogId = workLog.workLogId;
				}
			});
			setMissingExitWorkLog(missingExitWorklog);
			setCurrentRunningWorkLog(todayWorkLogs.activeWorkLog);
			if (todayWorkLogs.activeWorkLog?.eventId) {
				setDefaultEventOption({
					id: todayWorkLogs.activeWorkLog.eventId,
					name: '',
				});
			}
			if (!todayWorkLogs.activeWorkLog) {
				setInitTime(null);
			}
		}
	}, [todayWorkLogs]);

	useEffect(() => {
		if (!isFirstInit.current && selectedItem.id && !reportedExit.current) {
			startWorkLog();
		}
		if (reportedExit.current) {
			reportedExit.current = false;
		}
	}, [selectedItem]);

	useEffect(() => {
		if (!isFirstInit.current && currentRunnigWorkLog && !initTime) {
			const value = calcTimerInitValue(
				currentRunnigWorkLog,
				shiftTime - totalReported,
			);
			setInitTime(value);
		} else if (!isFirstInit.current && !currentRunnigWorkLog && initTime) {
			setInitTime(undefined);
		}
	}, [currentRunnigWorkLog]);

	useEffect(() => {
		const subscription: any = AppState.addEventListener(
			'change',
			nextAppState => {
				if (
					Platform.OS !== 'web' &&
					currentRunnigWorkLogRef.current &&
					nextAppState === 'active'
				) {
					const value = calcTimerInitValue(
						currentRunnigWorkLogRef.current,
						shiftTimeRef.current - totalReportedRef.current,
					);
					setInitTime(value);
				}
			},
		);

		return () => {
			subscription?.remove();
		};
	}, []);

	const getStandardHours = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getStandardByDate',
				params: {
					date: (getState().dateTime.currentDateTime
						? moment(
								getState().dateTime.currentDateTime,
								'YYYY-MM-DDTHH:mm',
						  )
						: moment()
					)
						.local()
						.format('YYYY-MM-DD'),
				},
			});
			if (res) {
				setStandartHours(res);
			}
		} catch (e) {}
	};

	useEffect(() => {
		if (
			userData?.id &&
			!isFinishWorkDateExpired &&
			(userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_READ,
			) ||
				userData?.employeeRolePrivileges?.includes(
					Privileges.ROLE_LOG_WORK_WRITE,
				))
		) {
			getProjects();
			if (eventsOptionsProp?.length > 0) {
				setEventsOptions(eventsOptionsProp);
			} else {
				getEvents();
			}
			if (standardHoursProp !== undefined) {
				setStandartHours(standardHoursProp);
			} else {
				getStandardHours();
			}
			setInitTime(undefined);
			setDefaultProjectOption({
				name: '',
				id: null,
				lastReported: true,
			});
			setDefaultEventOption({
				name: '',
				id: '',
			});
			setSelectedItem({
				name: null,
				isProject: true,
				id: null,
				isFullDay: true,
			});
		}
	}, [userData]);

	const getTodaysWorkLogs = () => {
		if (!isFinishWorkDateExpired) {
			dispatch(setTodayWorklogs());
		}
	};

	useEffect(() => {
		if (
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_READ,
			) ||
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_WRITE,
			)
		) {
			if (workLogListForClock?.data.length > 0) {
				dispatch(setTodayWorkLogsAction(workLogListForClock));
			} else {
				getTodaysWorkLogs();
			}
		}
	}, [userData, workLogListForClock]);

	useEffectNotInitial(() => {
		if (
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_READ,
			) ||
			userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_WRITE,
			)
		) {
			getTodaysWorkLogs();
		}
	}, [reFetchFlag]);

	useEffect(() => {
		if (
			(userData?.employeeRolePrivileges?.includes(
				Privileges.ROLE_LOG_WORK_READ,
			) ||
				userData?.employeeRolePrivileges?.includes(
					Privileges.ROLE_LOG_WORK_WRITE,
				)) &&
			currentUtcTime &&
			isFirstInit.current
		) {
			isFirstInit.current = false;
			setShiftTime(userData.customer_setting.maxShiftHour);
		}
	}, [currentUtcTime]);

	const disableButtons = useMemo(() => {
		let disable = false;
		let eventName = '';
		const fulldayReport = todayWorkLogs.data.filter(
			workLog => workLog.eventId && workLog.fullDay,
		);

		if (fulldayReport.length) {
			disable = true;
			eventName = fulldayReport[0].eventName;
		}
		return { disable, eventName };
	}, [todayWorkLogs.data]);
	const disableEntryButton = useMemo(() => {
		//disable entry button if the user did not choose any project
		return projectsOptions.length && !defaultProjectOption.id;
	}, [projectsOptions, defaultProjectOption]);

	if (isFinishWorkDateExpired) return null;

	const alertIfMaxHoursReached = () => {
		if (totalReported >= userData?.customer_setting.maxShiftHour) {
			openAlertModal(
				`${i18nService.translate('general.maxShiftTimeError')} ${
					userData?.customer_setting.maxShiftHour
				} ${i18nService.translate('general.maxShiftTimeError2')}`,
				'attention',
			);
			return true;
		}
		return false;
	};

	const checkIfCanEntry = (option: { id: string }) => {
		const isIncludes = userData?.location.find(
			item =>
				item.projectId === option.id || (!!item.id && !item.projectId),
		);
		return !isIncludes;
	};

	const handleEntry = async e => {
		Platform.OS === 'web' && document?.activeElement?.blur?.();
		const projectId = !projectsOptions.length
			? null
			: selectedItem.id
			? selectedItem.id
			: defaultProjectOption.id;

		if (
			isWebOrMobileweb &&
			selectedItem.isProject &&
			!checkIfCanEntry({ id: projectId })
		) {
			dispatch(getCurrentDateTime());
			if (alertIfMaxHoursReached()) return;
			if (currentRunnigWorkLog || missingExitWorkLog.isMissing) {
				openAlertModal('general.alertCompleteEntry', 'attention');
			} else
				await cantReportModal(
					isMobile,
					userData?.location.find(pr => pr.projectId === projectId),
				);
		} else {
			dispatch(getCurrentDateTime());
			if (alertIfMaxHoursReached()) return;
			if (currentRunnigWorkLog || missingExitWorkLog.isMissing) {
				openAlertModal('general.alertCompleteEntry', 'attention');
			} else
				await modalService.openModal(
					null,
					{
						onCancel: (callback: Function) => {
							callback();
						},
						onSubmit: async (callback: Function) => {
							try {
								await callback();
								const eventId = !eventsOptions.length
									? null
									: selectedItem.id && !selectedItem.isProject
									? selectedItem.id
									: null;

								let lat = null;
								let lon = null;
								if (
									platform === DeviceType.MOBILE &&
									selectedItem.isProject &&
									!checkIfCanEntry({ id: projectId })
								) {
									const location =
										await getLocationPermission();
									lat = location?.coords?.latitude || null;
									lon = location?.coords?.longitude || null;
									if (!location) {
										return;
									}
								}
								const data = {
									date: moment(
										getState().dateTime.currentDateTime,
										'YYYY-MM-DDTHH:mm:ss',
									)
										.local()
										.format('YYYY-MM-DD'),
									enterTime: moment(
										getState().dateTime.currentDateTime,
										'YYYY-MM-DDTHH:mm:ss',
									).format('YYYY-MM-DDTHH:mm:ss'),
									projectId: selectedItem.isProject
										? projectId
										: null,
									eventId,
									mobile: !isWebOrMobileweb,
									lat,
									lon,
								};

								if (Platform.OS !== 'web') {
									const crashlytics = (
										await import(
											'@react-native-firebase/crashlytics'
										)
									).default;
									crashlytics().recordError(
										new Error(
											`entryClockWorkLog data: ${JSON.stringify(
												data,
											)} `,
										),
									);
								}
								const res = await httpService.api<any>({
									type: 'startClockWorklog',
									data,
								});

								dispatch(toggleReFetch());
								setInitTime({ h: 0, m: 0, s: 0 });
								openAlertModal(
									defaultProjectOption.name
										? `${i18nService.translate(
												'general.WorkLogAddedSuccessfullyForProject',
										  )} ${
												defaultProjectOption.name
										  } ${i18nService.translate(
												'general.Successfully',
										  )}`
										: 'general.WorkLogAddedSuccessfully',
									'tick',
								);
							} catch (error) {
								console.log(error);
								switch (error.data.error) {
									case 'LOCATION_PROJECT_ERROR':
										platform === DeviceType.MOBILE
											? openAlertModal(
													'workLogError.project.phone',
													'attention',
													'general.close',
											  )
											: await cantReportModal(
													isMobile,
													userData?.location.find(
														pr =>
															pr.projectId ===
															projectId,
													),
											  );
										break;
									case 'LOCATION_ERROR':
										platform === DeviceType.MOBILE
											? openAlertModal(
													'workLogError.location.phone',
													'attention',
													'general.close',
											  )
											: await cantReportModal(
													isMobile,
													userData?.location.find(
														pr =>
															pr.projectId ===
															projectId,
													),
											  );
										break;

									default:
										openAlertModal(
											'general.serverError',
											'attention',
										);
										break;
								}
							}
						},
						submitBtnText: 'general.true',
						cancelBtnText: 'general.false',
						iconName: 'attention',
					},

					(props: any) => (
						<ModalConfirmActions {...props}>
							{defaultProjectOption.name ? (
								<Typography
									size={18}
									color={'darkGrey'}
									weight='400'
									style={{
										marginVertical: 10,
										textAlign: 'center',
									}}
								>
									{`${i18nService.translate(
										'general.shouldReportEntryToProject',
									)} ${defaultProjectOption.name}?`}
								</Typography>
							) : (
								<I18n>general.shouldReportEntry</I18n>
							)}
						</ModalConfirmActions>
					),
				);
		}
	};

	const handleExit = async () => {
		Platform.OS === 'web' && document?.activeElement?.blur?.();
		const projectId = !projectsOptions.length
			? ''
			: selectedItem.id
			? selectedItem.id
			: defaultProjectOption.id;
		if (
			isWebOrMobileweb &&
			currentRunnigWorkLog?.projectId &&
			!checkIfCanEntry({ id: projectId })
		) {
			await cantReportModal(
				isMobile,
				userData?.location.find(pr => pr.projectId === projectId),
			);
		} else {
			dispatch(getCurrentDateTime());
			if (currentRunnigWorkLog || !canEdit) {
				const projectRunning = currentRunnigWorkLog?.projectName;
				const eventRunning = currentRunnigWorkLog?.eventName;

				let lat = null;
				let lon = null;

				if (
					platform === DeviceType.MOBILE &&
					!currentRunnigWorkLog.eventId &&
					!checkIfCanEntry({ id: projectId })
				) {
					const location = await getLocationPermission();
					lat = location?.coords?.latitude || null;
					lon = location?.coords?.longitude || null;
					if (!location) {
						return;
					}
				}
				await modalService.openModal(
					null,
					{
						onCancel: (callback: Function) => {
							callback();
						},
						onSubmit: async (callback: Function) => {
							try {
								callback();

								const data = {
									...(!canEdit && {
										date: moment(
											getState().dateTime.currentDateTime,
											'YYYY-MM-DDTHH:mm',
										)
											.local()
											.format('YYYY-MM-DD'),
									}),
									exitTime: moment(
										getState().dateTime.currentDateTime,
										'YYYY-MM-DDTHH:mm',
									).format('YYYY-MM-DDTHH:mm'),
									mobile: !isWebOrMobileweb,
									lat,
									lon,
								};

								if (Platform.OS !== 'web') {
									const crashlytics = (
										await import(
											'@react-native-firebase/crashlytics'
										)
									).default;
									crashlytics().recordError(
										new Error(
											`exitClockWorkLog data: ${JSON.stringify(
												data,
											)} `,
										),
									);
								}

								const res = await httpService.api<any>({
									type: canEdit
										? 'exitClockWorklogById'
										: currentRunnigWorkLog
										? 'exitClockWorklogById'
										: 'exitClockWorkLog',
									data,
									params: {
										...(currentRunnigWorkLog
											? {
													workLogId:
														currentRunnigWorkLog?.workLogId,
											  }
											: {}),
									},
								});

								setInitTime(null); //should stop and remove the timer
								reportedExit.current = true;
								setSelectedItem({
									name: defaultProjectOption.name,
									isProject: true,
									id: defaultProjectOption.id,
									isFullDay: false,
								});
								dispatch(toggleReFetch());
								openAlertModal(
									projectRunning
										? `${i18nService.translate(
												'general.WorkLogExitSuccessfullyForProject',
										  )} ${projectRunning} ${i18nService.translate(
												'general.Successfully',
										  )}`
										: eventRunning
										? `${i18nService.translate(
												'general.WorkLogExitSuccessfullyForEvent',
										  )} ${eventRunning} ${i18nService.translate(
												'general.Successfully',
										  )}`
										: 'general.WorkLogClosedSuccessfully',
									'tick',
								);
							} catch (error) {
								switch (error.data.error) {
									case 'LOCATION_PROJECT_ERROR':
										platform === DeviceType.MOBILE
											? openAlertModal(
													'workLogError.project.phone',
													'attention',
													'general.close',
											  )
											: await cantReportModal(
													isMobile,
													userData?.location.find(
														pr =>
															pr.projectId ===
															projectId,
													),
											  );
										break;
									case 'LOCATION_ERROR':
										platform === DeviceType.MOBILE
											? openAlertModal(
													'workLogError.location.phone',
													'attention',
													'general.close',
											  )
											: await cantReportModal(
													isMobile,
													userData?.location.find(
														pr =>
															pr.projectId ===
															projectId,
													),
											  );
										break;
									default:
										break;
								}
							}
						},

						submitBtnText: 'general.true',

						cancelBtnText: 'general.false',

						iconName: 'attention',
					},

					(props: any) => (
						<ModalConfirmActions {...props}>
							<Typography
								size={18}
								color={'darkGrey'}
								weight='400'
								style={{
									marginVertical: 10,
									textAlign: 'center',
								}}
							>
								{projectRunning
									? `${i18nService.translate(
											'general.shouldReportExitFromProject',
									  )} ${projectRunning}?`
									: eventRunning
									? `${i18nService.translate(
											'general.shouldReportExitFromEvent',
									  )} ${eventRunning}?`
									: `${i18nService.translate(
											'general.shouldReportExit',
									  )}`}
							</Typography>
						</ModalConfirmActions>
					),
				);
			} else {
				if (alertIfMaxHoursReached()) return;
				await modalService.openModal(
					null,
					{
						onCancel: (callback: Function) => {
							callback();
						},
						onSubmit: async (callback: Function, date: string) => {
							dispatch(toggleReFetch());
							callback();
							setInitTime(null); //should stop and remove the timer
							getTodaysWorkLogs();
							openAlertModal(
								`${i18nService.translate(
									'general.WorkLogAddedSuccessfullyForDate',
								)} ${date} `,
								'tick',
							);
						},
						projectsOptions,
						eventsOptions,
						shiftHours: shiftTime,
						totalReported,
						prevValues: {
							date: moment(
								getState().dateTime.currentDateTime,
								'YYYY-MM-DDTHH:mm',
							).format('DD/MM/YY'),
							projectId: defaultProjectOption.id,
							eventId: null,
						},
						submitBtnText: 'general.reportExit',
						cancelBtnText: 'general.cancel',
						checkIfCanEntry,
					},

					(props: any) => <CompleteEntryContainer {...props} />,
				);
			}
		}
	};

	const startWorkLog = async () => {
		const tempProjectId = selectedItem.id
			? selectedItem.id
			: defaultProjectOption.id;
		const eventId =
			selectedItem.id && !selectedItem.isProject ? selectedItem.id : null;
		let exitTime = null;
		let enterTime = moment(
			getState().dateTime.currentDateTime,
			'YYYY-MM-DDTHH:mm:ss',
		).format('YYYY-MM-DDTHH:mm:ss');
		const projectId = selectedItem.isProject ? tempProjectId : null;
		if (!selectedItem.isProject && selectedItem.isFullDay) {
			[exitTime, enterTime] = formatStandard();
		}

		let lat = null;
		let lon = null;
		if (
			platform === DeviceType.MOBILE &&
			selectedItem.isProject &&
			!checkIfCanEntry({ id: projectId })
		) {
			const location = await getLocationPermission();
			lat = location?.coords?.latitude || null;
			lon = location?.coords?.longitude || null;
			if (!location) {
				return;
			}
		}
		const data = {
			date: moment(
				getState().dateTime.currentDateTime,
				'YYYY-MM-DDTHH:mm:ss',
			)
				.local()
				.format('YYYY-MM-DD'),
			enterTime,
			exitTime,
			projectId,
			eventId,
			fullDay: projectId ? false : selectedItem.isFullDay,
			mobile: !isWebOrMobileweb,
			lat,
			lon,
		};

		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().recordError(
				new Error(`startWorkLog data: ${JSON.stringify(data)} `),
			);
		}

		try {
			const res = await httpService.api<any>({
				type: 'startClockWorklog',
				data,
			});
		} catch (error) {
			switch (error.data.error) {
				case 'LOCATION_PROJECT_ERROR':
					platform === DeviceType.MOBILE
						? openAlertModal(
								'workLogError.project.phone',
								'attention',
								'general.close',
						  )
						: await cantReportModal(
								isMobile,
								userData?.location.find(
									pr => pr.projectId === projectId,
								),
						  );
					break;
				case 'LOCATION_ERROR':
					platform === DeviceType.MOBILE
						? openAlertModal(
								'workLogError.location.phone',
								'attention',
								'general.close',
						  )
						: await cantReportModal(
								isMobile,
								userData?.location.find(
									pr => pr.projectId === projectId,
								),
						  );
					break;
				case 'PARTIAL_ERROR':
					openAlertModal(
						'general.alertCompleteEntry',
						'attention',
						'',
					);
					break;
				default:
					openAlertModal(
						'general.WorkLogAddingFailed',
						'attention',
						'',
					);
					break;
			}
			return;
		}

		dispatch(toggleReFetch());
		getTodaysWorkLogs();
		setInitTime({ h: 0, m: 0, s: 0 });
		openAlertModal(
			selectedItem.isProject && selectedItem.name
				? `${i18nService.translate(
						'general.WorkLogAddedSuccessfullyForProject',
				  )} ${selectedItem.name} ${i18nService.translate(
						'general.Successfully',
				  )}`
				: eventId && selectedItem.isFullDay
				? `${i18nService.translate('general.eventReport')} ${
						selectedItem.name
				  } ${i18nService.translate('general.successfullyForFullDay')}`
				: 'general.WorkLogAddedSuccessfully',
			'tick',
		);
		if (!selectedItem.isProject && selectedItem.isFullDay) {
			setSelectedItem({
				name: null,
				isProject: true,
				id: null,
				isFullDay: true,
			});
		}
	};

	const formatStandard = () => {
		let enterTime = '08:00:00';
		let exitTime = '08:00:00';
		if (standardHours) {
			if (
				standardHours.from === '00:00:00' &&
				standardHours.to === '00:00:00'
			) {
				const [hours, minutes, seconds] = standardHours.fixHours
					.toString()
					.split(':');
				exitTime = moment('08:00:00', 'HH:mm:ss')
					.add(toNumber(hours), 'h')
					.format('HH:mm:ss');
				exitTime = moment(exitTime, 'HH:mm:ss')
					.add(toNumber(minutes), 'm')
					.format('HH:mm:ss')
					.toString();
			} else {
				enterTime = standardHours.from;
				exitTime = standardHours.to;
			}
		}
		//convert to utc before return
		exitTime = moment(exitTime, 'HH:mm:ss')
			.utc()
			.format('YYYY-MM-DDTHH:mm:ss');
		enterTime = moment(enterTime, 'HH:mm:ss')
			.utc()
			.format('YYYY-MM-DDTHH:mm:ss');
		return [exitTime, enterTime];
	};

	const handleProjectOrEventSelect = async (
		option: Option,
		isEventSelect: boolean,
		isFullDay: boolean,
	) => {
		if (isWebOrMobileweb && !isEventSelect && !checkIfCanEntry(option)) {
			await cantReportModal(
				isMobile,
				userData?.location.find(pr => pr.projectId === option.id),
			);
		} else {
			dispatch(getCurrentDateTime());
			if (alertIfMaxHoursReached()) return;
			if (currentRunnigWorkLog) {
				const runnigProject = currentRunnigWorkLog.projectName;
				const runnigEvent = currentRunnigWorkLog.eventName;
				const exitLabel = runnigProject
					? `${i18nService.translate(
							'general.shouldReportExitFromProject',
					  )} ${runnigProject}`
					: runnigEvent
					? `${i18nService.translate(
							'general.shouldReportExitFromEvent',
					  )} ${runnigEvent}`
					: i18nService.translate('general.shouldReportExitAndEntry');
				const entryLabel = isEventSelect
					? `${i18nService.translate(
							'general.andEntryEvent',
					  )} ${i18nService.translate(option.name)}?`
					: `${i18nService.translate(
							'general.andEntryProject',
					  )} ${i18nService.translate(option.name)}?`;
				modalService.openModal(
					null,
					{
						onCancel: (callback: Function) => {
							callback();
						},
						onSubmit: async (callback: Function) => {
							try {
								callback();

								let lat = null;
								let lon = null;
								if (
									platform === DeviceType.MOBILE &&
									selectedItem.isProject &&
									!checkIfCanEntry({
										id: currentRunnigWorkLog.projectId,
									})
								) {
									const location =
										await getLocationPermission();
									lat = location?.coords?.latitude || null;
									lon = location?.coords?.longitude || null;
									if (!location) {
										return;
									}
								}

								const data = {
									exitTime: moment(
										getState().dateTime.currentDateTime,
										'YYYY-MM-DDTHH:mm',
									).format('YYYY-MM-DDTHH:mm'),
									mobile: !isWebOrMobileweb,
									lat,
									lon,
								};

								if (Platform.OS !== 'web') {
									const crashlytics = (
										await import(
											'@react-native-firebase/crashlytics'
										)
									).default;
									crashlytics().recordError(
										new Error(
											`selectProjectOrEvent data: ${JSON.stringify(
												data,
											)} `,
										),
									);
								}

								const res = await httpService.api<any>({
									type: 'exitClockWorklogById',
									data,
									params: {
										workLogId:
											currentRunnigWorkLog.workLogId,
									},
								});

								setSelectedItem({
									name: option.name,
									isProject: !isEventSelect,
									id: option.id,
									isFullDay,
								});
								if (!isEventSelect)
									setDefaultProjectOption({ ...option });
							} catch (error) {
								switch (error.data.error) {
									case 'LOCATION_PROJECT_ERROR':
										platform === DeviceType.MOBILE
											? openAlertModal(
													'workLogError.project.phone',
													'attention',
													'general.close',
											  )
											: await cantReportModal(
													isMobile,
													userData?.location.find(
														pr =>
															pr.projectId ===
															option.id,
													),
											  );
										break;
									case 'LOCATION_ERROR':
										platform === DeviceType.MOBILE
											? openAlertModal(
													'workLogError.location.phone',
													'attention',
													'general.close',
											  )
											: await cantReportModal(
													isMobile,
													userData?.location.find(
														pr =>
															pr.projectId ===
															option.id,
													),
											  );
										break;
									default:
										break;
								}
							}
						},

						submitBtnText: 'general.true',

						cancelBtnText: 'general.false',

						iconName: 'attention',
					},

					(props: any) => (
						<ModalConfirmActions {...props}>
							<Typography
								size={18}
								color={'darkGrey'}
								weight='400'
								style={{
									marginVertical: 10,
									textAlign: 'center',
								}}
							>
								{`${exitLabel} ${entryLabel}`}
							</Typography>
						</ModalConfirmActions>
					),
				);
			} else
				await modalService.openModal(
					null,
					{
						onCancel: (callback: Function) => {
							callback();
						},
						onSubmit: async (callback: Function) => {
							try {
								callback();
								setSelectedItem({
									name: option.name,
									isProject: !isEventSelect,
									id: option.id,
									isFullDay,
								});
								if (!isEventSelect)
									setDefaultProjectOption({ ...option });
							} catch (error) {
								console.log(error);
							}
						},

						submitBtnText: 'general.true',

						cancelBtnText: 'general.false',

						iconName: 'attention',
					},

					(props: any) => (
						<ModalConfirmActions {...props}>
							<Typography
								size={18}
								color={'darkGrey'}
								weight='400'
								style={{
									marginVertical: 10,
									textAlign: 'center',
								}}
							>
								{`${i18nService.translate(
									isEventSelect
										? 'general.shouldReportEntryToEvent'
										: 'general.shouldReportEntryToProject',
								)} ${option.name} ${i18nService.translate(
									isFullDay && isEventSelect
										? 'general.shouldReportFullDay'
										: '',
								)}?`}
							</Typography>
						</ModalConfirmActions>
					),
				);
		}
	};

	const handleDelete = async (id: string) => {
		if (workLogListForClock?.data) {
			workLogListForClock.data = [];
		}
		onDelete(id, () => {
			dispatch(toggleReFetch());
		});
	};

	const handleEdit = async (id, workLog) => {
		const fullName = `${userData?.first_name} ${userData?.last_name}`;
		let logValues = {};
		if (id && !workLog) {
			logValues = await httpService.api<any>({
				type: 'workLogByIdEmployee',
				params: { workLogId: id },
			});
		}
		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},
				onSubmit: async (callback: Function) => {
					dispatch(toggleReFetch());
					callback();
				},
				submitBtnText: 'general.accept',
				route: route,
				cancelBtnText: 'general.cancel',
				isEdit: true,
				openEditModal: handleEdit,
				workLogId: id,
				isManager: false,
				prevValues: {
					...(workLog || logValues),
					originalExitEntry: {
						entryTime:
							id && !workLog
								? logValues.entryTime
								: workLog.enterTime,
						exitTime:
							id && !workLog
								? logValues.exitTime
								: workLog.exitTime,
					},
					entryTime: convertFromUtc(
						workLog ? workLog.enterTime : logValues.entryTime,
						true,
					),
					exitTime: convertFromUtc(
						workLog ? workLog.exitTime : logValues.exitTime,
						true,
					),
					employee: {
						id: userData?.id,
						name: fullName,
					},
					fullName,
					date: moment(
						getState().dateTime.currentDateTime,
						'YYYY-MM-DDTHH:mm',
					).format('YYYY-MM-DD'),
				},
				totalReported,
				shiftHours: userData?.customer_setting.maxShiftHour,
			},
			(props: any) => <AddRequest hideName {...props} />,
		);
	};

	const handleReachedMax = () => {
		setCurrentRunningWorkLog(null);
		setInitTime(null);
		dispatch(toggleReFetch());
	};

	const handleAddNewLog = async eventId => {
		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},

				onSubmit: async (callback: Function) => {
					dispatch(toggleReFetch());
					callback();
				},
				shiftHours: userData?.customer_setting.maxShiftHour,
				submitBtnText: 'general.accept',
				route: { name: 'calendar' },
				cancelBtnText: 'general.cancel',
				isEdit: false,
				isManager: false,
				prevValues: {
					date: moment(
						getState().dateTime.currentDateTime,
						'YYYY-MM-DDTHH:mm',
					).format('YYYY-MM-DD'),
					employeeId: userData?.id,
					fullName: `${userData?.first_name} ${userData?.last_name}`,
					eventId,
					fullDay: true,
				},
				fullEvent: true,
				selectedDateForEvent: moment(
					getState().dateTime.currentDateTime,
					'YYYY-MM-DDTHH:mm',
				).format('YYYY-MM-DD'),
			},
			(props: any) => (
				<AddRequest hideName addNew hideProjects {...props} />
			),
		);
	};
	return (
		<Component
			selectedItemType={selectedItemType}
			handleOpenOptions={handleOpenOptions}
			report101warning={report101warning}
			disableEntryButton={disableEntryButton}
			disableButtons={disableButtons}
			canReportFullDay={canReportFullDay}
			initialTime={initTime}
			projectsOptions={projectsOptions}
			eventsOptions={eventsOptions}
			eventPlaceholdar={
				currentRunnigWorkLog?.eventId
					? currentRunnigWorkLog?.eventName
					: 'menu.events'
			}
			label={
				currentRunnigWorkLog?.projectId
					? `${i18nService.translate(
							'general.youAreNowReportingProject',
					  )} ${i18nService.translate(
							currentRunnigWorkLog?.projectName,
					  )}`
					: currentRunnigWorkLog?.eventId
					? `${i18nService.translate(
							'general.youAreNowReportingEvent',
					  )} ${i18nService.translate(
							currentRunnigWorkLog?.eventName,
					  )}`
					: null
			}
			records={todayWorkLogs.data}
			onSelectProjectOrEvent={handleProjectOrEventSelect}
			totalReported={totalReported}
			standardHours={standardHours}
			onPressEntry={handleEntry}
			onPressExit={handleExit}
			shiftTime={shiftTime}
			defaultProjectOption={defaultProjectOption}
			selectedItem={selectedItem}
			defaultEventOption={defaultEventOption}
			reachedMax={handleReachedMax}
			onEdit={handleEdit}
			onDelete={handleDelete}
			currentRunnigWorkLog={currentRunnigWorkLog}
			handleAddNewLog={handleAddNewLog}
		/>
	);
};

export default ClockReportContainer;
