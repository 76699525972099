import React from 'react';
import { modalService } from '../../../../services/modal.service';
import AlertModal from '../../../../modals/AlertModal/AlertModal.component';
import httpService from '../../../../services/http.service';
import I18n from '../../../../components/shared/i18n/I18n.component';
import { omit } from 'lodash';

export const updateCustomerStatus = async (
	customerId: any,
	updatedStatus: boolean,
	onSuccess?: Function,
	onError?: () => any,
	extraOptions: any = {},
) => {
	try {
		const res = await httpService.api({
			type: 'updateRepStatus',
			params: { customerId, active: updatedStatus },
		});
		onSuccess?.();
		!extraOptions?.notDisplaySuccessMessage &&
			alertMessage('editCustomer.editStatusSuccess');
	} catch (e: any) {
		onError?.();
		alertMessage('editCustomer.editStatusFailed');
	}
};

export const alertMessage = async (message: String) => {
	await modalService.openModal(
		null,
		{
			submitBtnText: 'general.close',
			iconName: 'attention',
		},
		(props: any) => (
			<AlertModal {...props}>
				<I18n>{message}</I18n>
			</AlertModal>
		),
	);
};
