import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import FieldBuilder from '../../../../components/shared/FieldBuilder/FieldBuilder';
import I18n from '../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../components/shared/Typography/Typography.component';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import httpService from '../../../../services/http.service';
import {
	onSendNewRequest,
	SendButton,
	showUpdateButton,
	TwoButtonsContainer,
} from './RequestForm.utils';
import { RequestDetails } from '../EditRequest';
import { StatusOptions } from '../../ApplicationsToEmployer.utils';
import moment from 'moment';
import { Privileges } from '../../../../constants/roleTypes.constant';
import { useSelector } from 'react-redux';
import { getEmployeeRolesPrivileges } from '../../../../store/selectors/login.selectors';

interface VacationRequestProps {
	requestData?: RequestDetails;
	onClose: any;
	from: string | undefined;
	to: string | undefined;
	savedDescription?: string | undefined;
	validationDate: number;
	onrefresh(): void;
	disabled?: boolean;
	setSavedDescription?: Dispatch<SetStateAction<undefined>>;
	setFrom: Dispatch<SetStateAction<undefined>>;
	setTo: Dispatch<SetStateAction<undefined>>;
}

export interface VacationData {
	fromDate?: string;
	toDate?: string;
	description?: string;
}

const VacationRequest = ({
	requestData,
	onClose,
	from,
	setFrom,
	to,
	setTo,
	savedDescription = '',
	setSavedDescription,
	onrefresh,
	validationDate,
	disabled,
}: VacationRequestProps) => {
	const [vacation, setVacationBalance] = useState<{
		vacationBalance: number;
		vacationBalanceDate: string;
	} | null>(null);
	const [showEditButton, setShowEditButton] = useState(false);
	const [updatedForm, setUpdatedForm] = useState(
		requestData ? { ...requestData } : {},
	);
	const [showError, setShowError] = useState(false);
	const [formData, setFormData] = useState(null);

	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

	const platform = useDevicePlatform();
	const isMobile = platform === DeviceType.MOBILE;

	useEffect(() => {
		requestData
			? setVacationBalance(requestData?.lastVacationData)
			: getVacationBalance();
	}, []);

	useEffect(() => {
		if (requestData) {
			setTo(
				requestData.toDate ? requestData.toDate : requestData.fromDate,
			);
			setFrom(requestData.fromDate);
		}
	}, []);

	const creaateForm = async () => {
		const values: VacationData = formMethods.getValues();
		const formValues = {
			type: 'VACATION',
			fromDate: values.fromDate,
			toDate: values.fromDate === values.toDate ? null : values.toDate,
			description: values?.description || '',
		};
		let dataToSend;

		if (Platform.OS === 'android' || Platform.OS === 'ios') {
			dataToSend = [];
			dataToSend.push({
				name: 'request-data',
				type: 'application/json',
				data: JSON.stringify(formValues),
			});
		} else {
			const formData: any = new FormData();
			const data = new Blob([JSON.stringify(formValues)], {
				type: 'application/json',
			});
			formData.append('request-data', data);
			dataToSend = formData;
		}

		return { dataToSend, values };
	};

	const getVacationBalance = async () => {
		try {
			const res: {
				vacationBalance: number;
				vacationBalanceDate: string;
			} = await httpService.api({
				type: 'getCurrentVacationBalance',
			});

			if (res) {
				setVacationBalance(res);
			}
		} catch {}
	};

	const formMethods = useForm({
		defaultValues: requestData
			? {
					...requestData,
					toDate: requestData.toDate
						? requestData.toDate
						: requestData.fromDate,
			  }
			: { fromDate: from, toDate: to, description: savedDescription },
		mode: 'onBlur',
	});

	const onSubmit = async () => {
		try {
			const formValues: any = formMethods.getValues();

			if (!formValues?.fromDate || !formValues?.toDate) {
				setFormData(formValues);
				return setShowError(true);
			}

			const { dataToSend, values } = await creaateForm();
			onSendNewRequest(
				values,
				'VACATION',
				onClose,
				dataToSend,
				onrefresh,
			);
		} catch {}
	};

	const onFormUpdate = (field: string, val: string) => {
		setShowError(false);
		if (field === 'fromDate') {
			setFrom(val);
			if (!to) {
				formMethods.setValue('toDate', val);
				setTo(val);
			}
		} else if (field === 'toDate') {
			setTo(val);
			if (!from) {
				formMethods.setValue('fromDate', val);
				setFrom(val);
			}
		}

		if (requestData) {
			const form = { ...updatedForm, [field]: val };
			let isUpdate = false;

			if (
				form?.['fromDate'] !== requestData['fromDate'] ||
				form?.['toDate'] !== requestData['toDate'] ||
				form?.['description'] !== requestData['description']
			) {
				isUpdate = true;
			}
			setShowEditButton(isUpdate);
			setUpdatedForm(form);
		}
		setShowError(false);
	};

	return (
		<FormProvider {...formMethods}>
			<View
				style={[
					styles.vacationContainer,
					{ marginBottom: isMobile ? 50 : 115 },
				]}
			>
				<I18n size={14} weight='normal'>
					vacationRequest.selectDates
				</I18n>

				<View style={styles.vacationDates}>
					<View style={{ width: 140 }}>
						<FieldBuilder
							styles={{
								wrapper: { margin: 0, marginBottom: 30 },
							}}
							key={'fromDate'}
							name={'fromDate'}
							type={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
										? 'datepicker'
										: undefined
									: 'datepicker'
							}
							maxDate={
								to
									? new Date(
											moment(to, 'DD/MM/YYYY').valueOf(),
									  )
									: undefined
							}
							minDate={new Date(validationDate)}
							label={() => (
								<I18n size={14} weight='normal'>
									vacationRequest.fromDate
								</I18n>
							)}
							placeholder={'general.datePickerPlaceholder'}
							isRequired={true}
							disabled={disabled}
							editable={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
									: true
							}
							onChange={(val, a, b) => {
								onFormUpdate(
									'fromDate',
									typeof b === 'string'
										? b
										: b?.target?.value,
								);
							}}
							error={showError && !formData?.fromDate}
						/>
					</View>
					<View style={{ width: 140 }}>
						<FieldBuilder
							styles={{
								wrapper: { margin: 0, marginBottom: 30 },
							}}
							key={'toDate'}
							name={'toDate'}
							type={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
										? 'datepicker'
										: undefined
									: 'datepicker'
							}
							minDate={
								from
									? new Date(
											moment(
												from,
												'DD/MM/YYYY',
											).valueOf(),
									  )
									: new Date(validationDate)
							}
							label={() => (
								<I18n size={14} weight='normal'>
									vacationRequest.toDate
								</I18n>
							)}
							placeholder={'general.datePickerPlaceholder'}
							isRequired={true}
							disabled={disabled}
							editable={
								requestData
									? showUpdateButton(
											requestData,
											validationDate,
									  )
									: true
							}
							onChange={(val, a, b) => {
								onFormUpdate(
									'toDate',
									typeof b === 'string'
										? b
										: b?.target?.value,
								);
							}}
							error={showError && !formData?.toDate}
						/>
					</View>
				</View>

				<View style={styles.vacationBalance}>
					{!!vacation ? (
						<>
							<View
								style={{
									width: 170,
									flexDirection: 'row-reverse',
								}}
							>
								<I18n size={14} weight='normal'>
									vacationRequest.vacationRest
								</I18n>
								<I18n size={14} weight='normal'>
									{`months.${moment(
										vacation?.vacationBalanceDate,
										'YYYY-MM',
									).format('MMMM')}`}
								</I18n>
							</View>
							<Typography weight='normal' size={14}>
								{vacation?.vacationBalance}
							</Typography>
						</>
					) : (
						<>
							<View style={{ width: 160 }}>
								<I18n size={14} weight='normal'>
									vacationRequest.noVacationRest
								</I18n>
							</View>
							<Typography weight='normal' size={14}>
								--
							</Typography>
						</>
					)}
				</View>
				<FieldBuilder
					key={'description'}
					name={'description'}
					multiline
					label={() => (
						<I18n size={14} weight='normal'>
							vacationRequest.description
						</I18n>
					)}
					placeholder={'vacationRequest.addDescription'}
					isRequired={false}
					editable={
						disabled
							? false
							: requestData
							? showUpdateButton(requestData, validationDate)
							: true
					}
					maxLength={300}
					height={91}
					limitMargin={15}
					styles={{ wrapper: { margin: 0 } }}
					onChange={(val, a, b) => {
						onFormUpdate('description', b || '');
						setSavedDescription && setSavedDescription(b);
					}}
				/>

				{requestData && (
					<>
						<View
							style={[styles.vacationBalance, { marginTop: 15 }]}
						>
							<View style={{ width: 160 }}>
								<I18n size={14} weight='normal'>
									applicationsToEmployerContainer.status
								</I18n>
							</View>
							<Typography weight='normal' size={14}>
								{StatusOptions[requestData.status]}
							</Typography>
						</View>

						<View style={styles.vacationBalance}>
							<View style={{ width: 160 }}>
								<I18n size={14} weight='normal'>
									applicationsToEmployerContainer.lastUpdated
								</I18n>
							</View>
							<Typography weight='normal' size={14}>
								{requestData.lastUpdate}
							</Typography>
						</View>

						{!!requestData?.employerComment && (
							<View style={styles.vacationBalance}>
								<View style={{ width: 100 }}>
									<I18n size={14} weight='normal'>
										vacationRequest.employerNote
									</I18n>
								</View>
								<View style={{ width: 230 }}>
									<Typography weight='normal' size={14}>
										{requestData.employerComment}
									</Typography>
								</View>
							</View>
						)}
					</>
				)}
			</View>
			<View>
				{requestData &&
				employeeRolesPrivileges?.includes(
					Privileges.ROLE_VACATION_REQUEST_WRITE,
				) ? (
					<TwoButtonsContainer
						showEditButton={showEditButton}
						requestData={requestData}
						onClose={onClose}
						sendRequestUpdate={creaateForm}
						to={to}
						from={from}
						validationDate={validationDate}
					/>
				) : (
					<SendButton disabled={disabled} onSend={onSubmit} />
				)}
			</View>
		</FormProvider>
	);
};
const styles = StyleSheet.create({
	vacationContainer: {
		alignContent: 'space-between',
		marginTop: 17,
		marginBottom: 20,
		justifyContent: 'space-between',
	},
	vacationDates: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 90,
		justifyContent: 'space-between',
		zIndex: 1000,
		marginTop: 15,
	},
	vacationBalance: {
		display: 'flex',
		flexDirection: 'row-reverse',
		marginBottom: 25,
		zIndex: 100,
	},
	alertContainer: {
		paddingVertical: 39,
		paddingHorizontal: 50,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
	},
});

export default VacationRequest;
