import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import CommentModal from '../../../modals/CommentModal/CommentModal.component';
import httpService from '../../../services/http.service';
import { i18nService } from '../../../services/i18n.service';
import { modalService } from '../../../services/modal.service';
import {
	getIsHasComment,
	openRejectFormModal,
	rejectForm,
} from '../../WorkerCard/WorkerCardEditor/WorkerCardViewer.utils';

interface ActionButtonProps {
	imageName: string,
	text: string,
	disabled?: boolean,
	width?: number,
	containerStyles?: any,
	onPress(): void,
}

export const reject = async ({ cancel, formId }: any) => {
	const data: any =
		(await httpService.api({
			type: 'getWorkerCardDataManager',
			params: { formId },
		})) || {};

	if (!getIsHasComment({ data })) {
		await modalService.openModal(
			null,
			{
				fieldLabel: i18nService.translate(
					'workerCard.viewer.rejection.addReasonMessage',
				),
				fieldName: '',
				fieldValue: '',
				cancel: cancel,
				commentExist: false,
				readOnly: false,
				saveComment: async (value: string) => {
					const comment = data.comment?.find(
						(c: any) => c?.fieldName === 'ROOT',
					);

					const res: any = await httpService.api({
						type: comment
							? 'updateEmployerComment'
							: 'addEmployerComment',
						params: {
							formId,
							commentId: comment?.id,
						},
						data: comment
							? {
									comment: value,
							  }
							: {
									comment: value,
									fieldName: 'ROOT',
									objectName: 'ROOT',
							  },
					});

					rejectForm({ formId, cancel });
				},
				removeComment: () => {},
			},
			CommentModal,
		);
	} else {
		openRejectFormModal({
			rejectForm: () => rejectForm({ formId, cancel }),
			data,
		});
	}
};


export const ActionButton = ({ onPress, imageName, text, disabled, width, containerStyles }: ActionButtonProps) => {
	return (
			<TouchableOpacity onPress={onPress} disabled={disabled}>
					<View style={[styles.buttonContainer, containerStyles, disabled && { opacity: 0.5 }]}>
							<ImageIcon name={imageName} height={20} width={width || 16} style={{ marginBottom: 5 }} />
							<I18n weight='normal' size={12}>
									{text}
							</I18n>
					</View>
			</TouchableOpacity>
	)
};

const styles = StyleSheet.create({
	buttonContainer: {
			width: 80,
			height: 53,
			alignItems: 'center',
			justifyContent: 'center'
	},
})