import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import I18n from '../../i18n/I18n.component';
import { FontSize, Weight } from '../../../../interfaces/core.interface';
import { compact } from 'lodash';

type DoubleButtonProps = {
	activeButton: string;
	onButtonClick: (name: string) => void;
	stylesConf: {
		width: number;
		height: number;
		textSize: number;
		textWeight?: string;
		borderColor?: string;
	};
	buttons: any[];
};

export const DoubleButton = ({
	activeButton,
	onButtonClick,
	stylesConf,
	buttons,
}: DoubleButtonProps) => {
	return (
		<View
			style={[
				styles(stylesConf).buttonsContainer,
				{ height: stylesConf?.height },
			]}
		>
			{buttons.map((button: any, index: number) => (
				<TouchableOpacity
					key={button.name}
					style={compact([
						styles({
							...stylesConf,
							isFirst: index === 0,
							isLast: index === buttons.length - 1,
							onlyTwo: buttons.length === 2,
						}).button,
						{
							borderColor: stylesConf?.borderColor
								? stylesConf?.borderColor
								: '#EDF3F7',
						},
						activeButton === button.name &&
							styles(stylesConf).activeButton,
					])}
					onPress={() => {
						onButtonClick(button.name);
					}}
					//labelStyle={{ marginHorizontal: 0, marginVertical: 0 }}
				>
					<I18n
						size={stylesConf.textSize as FontSize}
						weight={(stylesConf.textWeight as Weight) || '400'}
						color={
							activeButton !== button.name ? 'darkGrey' : 'white'
						}
						style={{}}
					>
						{`general.${button.name}`}
					</I18n>
				</TouchableOpacity>
			))}
		</View>
	);
};

export const styles = (props: {
	width: number;
	height: number;
	isFirst?: boolean;
	isLast?: boolean;
	onlyTwo?: boolean;
}) =>
	StyleSheet.create({
		buttonsContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
		},
		button: {
			width: props.width,
			height: props.height,
			backgroundColor: 'white',
			borderWidth: 3,
			borderColor: '#EDF3F7',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginHorizontal: 0,
			marginVertical: 0,
			borderLeftWidth:
				(!props.isFirst && !props.isLast) ||
				(props.onlyTwo && props.isLast)
					? 0
					: 3,
			borderRightWidth: !props.isFirst && !props.isLast ? 0 : 3,
			borderTopLeftRadius: props.isFirst ? 50 : 0,
			borderBottomLeftRadius: props.isFirst ? 50 : 0,
			borderTopRightRadius: props.isLast ? 50 : 0,
			borderBottomRightRadius: props.isLast ? 50 : 0,
		},
		activeButton: {
			backgroundColor: '#3B70A4',
		},
	});
