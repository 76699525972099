import React, {
	useEffect,
	useState,
	useMemo,
	useRef,
	useCallback,
} from 'react';
import { usePrevious } from '../../../hooks/usePrevious.hook';
import httpService from '../../../services/http.service';
import EmployeeHoursReport from './EmployeeHoursReport.component';
import { modalService } from '../../../services/modal.service';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import { useRoute } from '@react-navigation/native';
import AddRequest from '../../../modals/AddRequestModal/AddRequestModal.container';
import { debounce } from 'lodash';
import { getUserData } from '../../../store/selectors/login.selectors';
import { useSelector } from 'react-redux';
import {
	getDefaultDateFilter,
	monthMap,
	convertFromUtc,
} from './EmployeeHoursReport.utils';
import { i18nService } from '../../../services/i18n.service';
import moment from 'moment';
import { openAlertModal } from '../../../components/shared/ClockReport/Clock.utils';
export interface tableData {
	id: number;
	teudatZeut: string;
	fullName: string;
	projectName: any;
	eventName: string;
	date: string;
	enterTime: string;
	exitTime: string;
	lastUpdate: string;
	total: number;
	updatedBy: string;
	updatedEnter: boolean;
	updatedExit: boolean;
	updatedProject: boolean;
	pendingStatus: string;
}

const EmployeeHoursReportContainer = ({ navigation }: { navigation: any }) => {
	const userData = useSelector(getUserData);
	const [tableData, setTableData] = useState<tableData[]>([]);
	const [totalRow, setTotalRow] = useState({});
	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('ASC');
	const [sortBy, setSortBy] = useState(null);
	const [events, setEvents] = useState([]);
	const [projects, setProjects] = useState([]);
	const route = useRoute();
	const [month, setMonth] = useState([getDefaultDateFilter('month')]);
	const [year, setYear] = useState(getDefaultDateFilter('year'));
	const [profileAid, setAid] = useState('');
	const [filters, setFilters] = useState({
		year: [getDefaultDateFilter('year')],
		month: [...month],
		event: [],
		status: [],
		special: [],
		project: [],
	});
	const [resetMonthFilter, setResetMonthFilter] = useState(false);
	const canEdit = useMemo(
		() =>
			userData?.employeeRolePrivileges?.includes('ROLE_LOG_WORK_WRITE') &&
			userData.customer_setting.allowUpdateReport,
		[userData],
	);
	const defaultSort = null;
	const defaultOrder = 'ASC';
	const isFirstInit = useRef(true);
	const prevYear = usePrevious(...filters.year);
	const isLocked = selectedDate => {
		switch (userData?.customer_setting.worklogLockType) {
			case 'SELECTED_DAY':
				const lockDay = userData.customer_setting.lockDay;
				const lockDate = `${moment().format('YYYY-MM')}-${
					lockDay < 10 ? `0${lockDay}` : lockDay
				}`;

				return (
					moment().isSameOrAfter(moment(lockDate, 'YYYY-MM-DD')) &&
					moment(selectedDate, 'YYYY-MM-DD').isBefore(
						moment().startOf('month'),
					)
				);

			case 'END_OF_MONTH':
				return moment(selectedDate, 'YYYY-MM-DD').isBefore(
					moment().startOf('month'),
				);
			default:
				//NO LOCK
				return false;
		}
	};
	useEffect(() => {
		if (!isFirstInit.current) {
			getMonth();
		}
	}, [year]);
	useEffect(() => {
		if (!isFirstInit.current) {
			setResetMonthFilter(true);
			const chosenFilters = {
				year: [year],
				month: month.includes(getDefaultDateFilter('month'))
					? [getDefaultDateFilter('month')]
					: month[month.length - 1]
					? [month[month.length - 1]]
					: [],
				event: [...filters.event],
				status: [...filters.status],
				special: [...filters.special],
				project: [...filters.project],
			};
			setFilters(chosenFilters);
		}
	}, [month]);
	useEffect(() => {
		if (!isFirstInit.current) {
			// if (currentEmployee === prevEmployee.current)
			getData(true, defaultSort, defaultOrder);
		}
	}, [filters]);

	useEffect(() => {
		getEventsOptions();
		getProjectsOptions();
		if (!isFirstInit.current) {
			if (getDefaultDateFilter('year') === prevYear) {
				setResetMonthFilter(true);
				const chosenFilters = {
					year: [year],
					month: month.includes(getDefaultDateFilter('month'))
						? [getDefaultDateFilter('month')]
						: month[month.length - 1]
						? [month[month.length - 1]]
						: [],
					event: [],
					status: [],
					special: [],
					project: [],
				};
				setFilters(chosenFilters);
			} else setYear(getDefaultDateFilter('year'));
		} else {
			getMonth();
		}
		isFirstInit.current = false;
		setAid(userData?.id);
	}, [userData]);

	useEffect(() => {
		if (!isFirstInit.current && resetMonthFilter)
			setResetMonthFilter(false);
	}, [resetMonthFilter]);

	const filtersConfig = useMemo(() => {
		const monthOptions = month.map(month => ({
			id: month,
			name: i18nService.translate(monthMap[month]),
		}));
		return [
			{
				name: 'status',
				options: [
					{ id: 'APPROVED', name: 'reportView.APPROVED' },
					{
						id: 'NO_APPROVAL_REQUIRED',
						name: 'reportView.NO_APPROVAL_REQUIRED',
					},
					{
						id: 'PENDING_APPROVAL',
						name: 'reportView.PENDING_APPROVAL',
					},
				],
				placeholder: 'status',
				styles: { width: 134 },
				type: 'multiselect',
				key: userData,
				alwaysRerender: true,
			},
			{
				name: 'special',
				options: [
					{ id: true, name: 'workerCard.editor.missingFields' },
				],
				placeholder: 'special',
				styles: { width: 134 },
				type: 'select',
				defaultValue: filters.special[0],
				key: userData,
				alwaysRerender: true,
			},
			{
				name: 'event',
				options: events,
				placeholder: 'event',
				styles: { width: 134 },
				type: 'multiselect',
				key: userData,
				alwaysRerender: true,
			},
			{
				defaultValue: '',
				options: projects,
				name: 'project',
				placeholder: 'project',
				styles: { width: 134 },
				type: 'multiselect',
				key: userData,
				alwaysRerender: true,
			},
			{
				name: 'year',
				type: 'datepicker',
				placeholder: year,
				showYearPicker: true,
				showQuarterYearPicker: false,
				styles: {
					width: 140,
				},
				value: year,
			},
			{
				name: 'month',
				options: monthOptions,
				placeholder: 'month',
				styles: { width: 134 },
				type: 'multiselect',
				value: filters.month.map(month => ({
					id: month,
					name: i18nService.translate(monthMap[month]),
				})),
				alwaysRerender: resetMonthFilter,
			},
		];
	}, [events, projects, month, filters, userData, year, resetMonthFilter]);

	const getEventsOptions = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getEventNameList',
				query: { pageName: 'employeeWorkLogReports' },
			});
			if (res) {
				setEvents(res);
			}
		} catch (e) {}
	};

	const getProjectsOptions = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getProjectNameList',
				query: { pageName: 'employeeWorkLogReports' },
			});
			if (res) {
				setProjects(res);
			}
		} catch (e) {}
	};

	const getData = async (
		firstInit: boolean,
		newSortBy?: any,
		neworder?: string,
	) => {
		try {
			const res = await httpService.api<any>({
				type: 'getEmployeeReportsSummery',
				data: {
					p: firstInit ? 0 : nextPage,
					ps: 20,
					s: newSortBy ? newSortBy : sortBy,
					sd: neworder ? neworder : order,
					f: filters,
				},
			});
			if (res) {
				if (res.totalRow) setTotalRow(res.totalRow);
				setDisableLoad(!res.hasNext);
				if (firstInit) {
					setTableData(res.data);
					setNextPage(1);
				} else {
					setTableData([...tableData, ...res.data]);
					setNextPage(nextPage + 1);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const getMonth = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getEmpMonthOfHoursReportSummery',
				query: { year: year },
			});
			if (res) {
				setMonth(res);
			}
		} catch (e) {}
	};

	const onSort = (newSort: string) => {
		let newOrder = order;
		if (newSort === sortBy) {
			newOrder = order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			newOrder = 'DESC';
		}
		setOrder(newOrder);
		setSortBy(newSort);
		getData(true, newSort, newOrder);
	};

	const onFilter = (filterData: any) => {
		const chosenFilters = {};
		Object.keys(filters).forEach(key => {
			if (key === 'year') chosenFilters[key] = filters[key];
			else chosenFilters[key] = [...filters[key]];
		});
		let isYearChanged = false;
		switch (filterData.name) {
			case 'special':
				chosenFilters[filterData.name] =
					filterData.value === undefined ? [] : [filterData.value];

				break;
			case 'status':
				chosenFilters[filterData.name] = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
			case 'year':
				isYearChanged = true;
				const chosenYear = moment(filterData.value).year().toString();
				setYear(chosenYear);
				break;
			case 'month':
				if (filterData.isAllSelected) {
					chosenFilters[filterData.name] = month.map(month => month);
					break;
				}
				chosenFilters[filterData.name] = filterData.value.map(
					month => month.id,
				);
				break;
			case 'project':
				if (filterData.isAllSelected) {
					chosenFilters[filterData.name] = projects.map(
						project => project.id,
					);
					break;
				}
				if (filterData.value.length === 0) {
					chosenFilters[filterData.name] = [];
					break;
				}
				chosenFilters[filterData.name] = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
			default:
				if (filterData.isAllSelected) {
					chosenFilters[filterData.name] = events.map(
						event => event.id,
					);
					break;
				}
				if (filterData.value.length === 0) {
					chosenFilters[filterData.name] = [];
					break;
				}
				chosenFilters[filterData.name] = filterData.value.map(
					valueObj => valueObj.id,
				);
				break;
		}
		if (!isYearChanged) setFilters(chosenFilters);
	};

	const handleDelete = async (id, date) => {
		if (isLocked(date)) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else
			await modalService.openModal(
				null,

				{
					onCancel: (callback: Function) => {
						callback();
					},

					onSubmit: async (callback: Function) => {
						try {
							const res = await httpService.api<any>({
								type: 'deleteEmployeeReportByEmployee',
								params: { workLogId: id },
							});
							getData(true, defaultSort, defaultOrder);
						} catch (error) {
							console.log(error);
						}
						callback();
					},

					submitBtnText: 'general.true',

					cancelBtnText: 'general.false',

					iconName: 'question',
				},

				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>general.shouldDeleteItem</I18n>
					</ModalConfirmActions>
				),
			);
	};

	const handleEdit = async (id, profileAid?, fullName?, date?) => {
		if (isLocked(date)) {
			openAlertModal('modal.monthIsLocked', 'attention', 'general.close');
		} else {
			let logValues = {};
			if (id) {
				logValues = await httpService.api<any>({
					type: 'workLogByIdEmployee',
					params: { workLogId: id },
				});
			}

			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						getData(true, sortBy, order);
						callback();
					},
					isManager: false,
					shiftHours: userData?.customer_setting.maxShiftHour,
					submitBtnText: 'general.accept',
					route: { name: 'employeeWorkLogReports' },
					cancelBtnText: 'general.cancel',
					isEdit: id ? true : false,
					openEditModal: handleEdit,
					workLogId: id,
					prevValues: id
						? {
								...logValues,
								originalExitEntry: {
									entryTime: logValues.entryTime,
									exitTime: logValues.exitTime,
								},
								employee: {
									id: logValues.employeeId,
									name: logValues.fullName,
								},
								entryTime: convertFromUtc(
									logValues.entryTime,
									true,
									'YYYY-MM-DDTHH:mm:ss',
								),
								exitTime: convertFromUtc(
									logValues.exitTime,
									true,
									'YYYY-MM-DDTHH:mm:ss',
								),
						  }
						: profileAid
						? {
								employee: {
									id: profileAid,
									name: fullName,
								},
								fullName,
								date,
						  }
						: null,
				},
				(props: any) => <AddRequest hideName {...props} />,
			);
		}
	};

	const handleAddNewLog = async () => {
		await modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},

				onSubmit: async (callback: Function) => {
					getData(true, sortBy, order);
					callback();
				},
				isManager: false,
				submitBtnText: 'general.accept',
				route: { name: 'employeeWorkLogReports' },
				cancelBtnText: 'general.cancel',
				isEdit: false,
				prevValues: {
					employeeId: profileAid,
					fullName: `${userData?.first_name} ${userData?.last_name}`,
				},
				shiftHours: userData?.customer_setting.maxShiftHour,
				openEditModal: handleEdit,
			},
			(props: any) => <AddRequest addNew hideName {...props} />,
		);
	};

	return (
		<EmployeeHoursReport
			addReport={handleAddNewLog}
			navigation={navigation}
			canEdit={canEdit}
			data={tableData}
			totalData={totalRow}
			onSort={onSort}
			onLoadMore={() => {
				return disabledLoad ? null : getData(false);
			}}
			onFilter={onFilter}
			filtersConfig={filtersConfig}
			refresh={() => getData(true, defaultSort, defaultOrder)}
			onEdit={handleEdit}
			onDelete={handleDelete}
			worklogReportFormat={userData?.customer_setting.worklogReportFormat}
		/>
	);
};

export default EmployeeHoursReportContainer;
