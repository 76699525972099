import React, { FunctionComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import MultiselectContainer from '../Multiselect/Multiselect.container';
import DatePicker from '../ReactDatePicker/ReactDatePicker.component';
import CustomDatePicker from '../CustomDataPicker/CustomDatePicker.container';
import SearchInputContainer from '../../shared/SearchInput/SearchInput.container';
import SelectContainer from '../Select/Select.container';
import { FilterData, FilterTypes } from './Filters.container';
import AsyncMultiSelect from '../../shared/AsyncMultiSelect/AsyncMultiSelect.container';
import I18n from '../../shared/i18n/I18n.component';
import Checkbox from '../../shared/FieldBuilder/Checkbox/Checkbox';

interface FiltersProps {
	onChange: (obj: FilterData) => void;
	config: SingleConfig[];
	onSearchTextChange?: (data: string) => void; //for auctocomplete filter
	onSelection?: (id: any) => void; //for auctocomplete filter
	customStyles?: any;
	hideSearchInput?: boolean;
	resetActiveSortByProps?: [];
}

export interface SingleConfig {
	type: FilterTypes;
	name: string;
	options?: Array<{ id: string; name: string }>;
	placeholder?: string;
	styles?: any;
	defaultLabel?: string;
	label: string;
	extraProps?: any;
	customStyles?: {
		container: any;
		filterContainer: any;
		otherFiltersWrapper: any;
	};
	key?: string;
	value?: any;
	hideFilter: boolean;
}

const filterComponentMap: Record<FilterTypes, FunctionComponent<any>> = {
	searchInput: SearchInputContainer,
	multiselect: MultiselectContainer,
	select: SelectContainer,
	datepicker: DatePicker,
	asyncmultiselect: AsyncMultiSelect,
	customDatePicker: CustomDatePicker,
};

const Filters = ({
	onChange,
	config,
	onSearchTextChange,
	onSelection,
	resetActiveSortByProps,
	hideSearchInput = false,
	customStyles = { container: {}, filterContainer: {} },
}: FiltersProps) => {
	const getFilter = (singleConfig: SingleConfig) => {
		if (singleConfig.hideFilter) {
			return null;
		} else {
			const FilterComponent = filterComponentMap[singleConfig.type];
			switch (singleConfig.type) {
				case 'select':
				case 'asyncmultiselect': {
					return (
						<FilterComponent
							key={singleConfig.key}
							onChange={onChange}
							config={singleConfig}
							resetActiveSortByProps={resetActiveSortByProps}
						/>
					);
				}
				case 'checkbox':
					return (
						<View
							style={{
								flexDirection: 'row-reverse',
								alignItems: 'center',
								marginTop: -5,
							}}
						>
							<Checkbox
								onChange={(value: boolean) =>
									onChange({ name: singleConfig.name, value })
								}
								value={singleConfig.value}
							/>
							<I18n>{singleConfig.placeholder}</I18n>
						</View>
					);
				default: {
					return (
						<FilterComponent
							onChange={onChange}
							config={singleConfig}
							resetActiveSortByProps={resetActiveSortByProps}
						/>
					);
				}
			}
		}
	};
	return (
		<View style={{ ...styles.container, ...customStyles.container }}>
			{!hideSearchInput &&
				config.some(
					(singleConf: SingleConfig) =>
						singleConf.type === 'searchInput',
				) && (
					<View
						nativeID='#searchWrapper'
						style={styles.searchWrapper}
					>
						<SearchInputContainer
							onChange={onChange}
							resetActiveSortByProps={resetActiveSortByProps}
							config={
								config.find(
									(singleConf: SingleConfig) =>
										singleConf.type === 'searchInput',
								)!
							}
						/>
					</View>
				)}
			<View
				nativeID='#otherFiltersWrapper'
				style={[
					styles.otherFiltersWrapper,
					customStyles.otherFiltersWrapper,
				]}
			>
				{config && config.length > 0
					? config
							.filter(
								(singleConfig: SingleConfig) =>
									singleConfig.type !== 'searchInput',
							)
							.map((singleConfig: SingleConfig, idx: number) => {
								return (
									<View
										style={{
											...styles.filterContainer,
											...customStyles.filterContainer,
											...singleConfig.styles?.container,
										}}
										key={idx}
									>
										{singleConfig.label && (
											<View
												style={
													customStyles.label
														? customStyles.label
														: {}
												}
											>
												<I18n>
													{singleConfig.label}
												</I18n>
											</View>
										)}
										{getFilter(singleConfig)}
									</View>
								);
							})
					: null}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: '100%',
		flexDirection: 'row',
		zIndex: 3,
		//flexWrap: 'wrap',
	},
	filterContainer: {
		flexDirection: 'row',
		marginRight: 15,
	},
	searchWrapper: {
		display: 'flex',
		marginTop: 10,
		marginRight: 15,
	},
	otherFiltersWrapper: {
		marginTop: 10,
		display: 'flex',
		flexDirection: 'row',
		zIndex: 3,
	},
});

export default Filters;
