import { Tooltip } from '@material-ui/core';
import React from 'react';
import { TouchableHighlight, View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import getShadowStyle from '../../config/shadow';
import { Privileges } from '../../constants/roleTypes.constant';
import httpService from '../../services/http.service';
import { i18nService } from '../../services/i18n.service';
import { modalService } from '../../services/modal.service';
import { getEmployeeRolesPrivileges } from '../../store/selectors/login.selectors';
import { ApplicationsData } from './ApplicationsToEmployer.container';
import RequestWrapper from './CreateNewRequest/RequestWrapper';

export const EditButton = ({ data, validationDate, onrefresh }: { data: any, validationDate: number, onrefresh: () => void }) => {
  const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);

  const vacationPermition = employeeRolesPrivileges?.includes(Privileges.ROLE_VACATION_REQUEST_WRITE) && data.type === i18nService.translate('applicationsToEmployerContainer.vacationRequest');
  const siknessPermition = employeeRolesPrivileges?.includes(Privileges.ROLE_SICKNESS_REQUEST_WRITE) && data.type === i18nService.translate('applicationsToEmployerContainer.sicknessReport')

  const onEdit = async (requestId?: number) => {
    await modalService.openModal(
      null,
      {},
      (props: any) => (
        <RequestWrapper requestId={requestId} {...props} onrefresh={onrefresh} validationDate={validationDate} />
      ),
    );
  };

  return (
    <TouchableHighlight
      underlayColor='transparent'
      disabled={!vacationPermition && !siknessPermition}
      style={{ opacity: (!vacationPermition && !siknessPermition) ? 0.2 : 1 }}
      onPress={() => onEdit(data.id)}
    >
      <div >
        <ImageIcon name='edit' height={20} width={20} style={{ margin: 10 }} />
      </div>
    </TouchableHighlight>
  );
};

export const downloadFile = async (request: ApplicationsData, isMobile?: boolean) => {
  const res: any = await httpService.api({
    type: 'getApplicationsAttachments',
    params: { requestId: request.id },
    responseType: 'blob',
  });

  let elm = document.createElement('a');
  elm.href = URL.createObjectURL(res);
  elm.setAttribute(
    'download',
    `${request?.teudatZeut || ''}_${request.dateRange}.zip`,
  );
  elm.click();
};

export const DownloadSection = ({ data }: { data: ApplicationsData }) => {
  return (
    <View>
      {
        data.attachedFiles > 0 ? (
          <div onClick={async (e: any) => downloadFile(data)}>
            <ImageIcon name='downloadPaycheck' height={20} width={20} />
          </div>
        ) : <div />
      }
    </View>
  );
};
export const StatusOptions = {
  'PENDING': i18nService.translate('general.PENDING'),
  'APPROVED': i18nService.translate('general.APPROVED'),
  'REJECTED': i18nService.translate('general.REJECTED'),
  'CANCELED': i18nService.translate('general.CANCELED')
};

const statusColorMatcher = {
  [i18nService.translate('applicationsToEmployerContainer.status.APPROVED')]: {
    color: 'green',
  },
  [i18nService.translate('applicationsToEmployerContainer.status.PENDING')]: {
    color: 'blue'
  },
  [i18nService.translate('applicationsToEmployerContainer.status.DECLINED')]: {
    color: 'orange'
  },
  [i18nService.translate('applicationsToEmployerContainer.status.CANCELED')]: {
    color: 'gray'
  }
};

export const StatusSection = ({ item, style, isMobile }: { item: ApplicationsData, isMobile?: boolean, style?: any }) => {
  return (
    <View style={[styles.statusSection, style && { ...style }]}>
      <View style={[styles.statusDot, { backgroundColor: statusColorMatcher[item.status].color }]} />
      <View>
        <I18n size={12} weight='normal'>
          {item.status}
        </I18n>
      </View>
    </View>
  )
};

export const GeneralText = ({ value }: { value: string }) => {
  return (
    <View style={styles.textCell}>
      <Tooltip title={value || ''} placement='top-start'>
        <Typography size={12} numberOfLines={2}>
          {value || '-'}
        </Typography>
      </Tooltip>
    </View>
  )
}

export const OpenNewRequestButton = ({ onNewRequest, disabled, style }: { onNewRequest: () => void, disabled?: boolean, style?: any }) => {
  return (
    <View style={{ alignItems: 'flex-end' }}>
      <TouchableHighlight underlayColor='transparent' onPress={onNewRequest} disabled={disabled} style={style}>
        <View style={styles.newRequestButton}>
          <Typography
            style={{ marginLeft: 10, marginRight: 10 }}
            color={'white'}
            weight='400'
            size={14}
          >
            {i18nService.translate('applicationsToEmployerContainer.createNewRequest')}
          </Typography>
        </View>
      </TouchableHighlight>
    </View>
  )
};

const styles = StyleSheet.create({
  statusSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  statusDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginLeft: 5
  },
  textCell: {
    minWidth: 50,
    maxWidth: 200
  },
  newRequestButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 174,
    margin: 10,
    backgroundColor: colors.lightPrimary,
    borderRadius: 45,
    ...getShadowStyle(20, 'rgb(59, 112, 164)'),
  }
});
