import React, { useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import Pie from './Pie';
import Rows from './Rows';
import { useNavigation } from '@react-navigation/native';

const Card = ({
	title,
	width,
	type,
	navigateTo,
	defaultParams = {},
	moreParams = {},
	...rest
}: any) => {
	const navigation = useNavigation();

	const navigate = (params?: any) => {
		navigation.navigate(navigateTo, {
			filtersData: JSON.stringify(
				params ? { ...params, ...defaultParams } : defaultParams,
			),
			...moreParams,
		});
	};

	return (
		<div
			style={{
				display: 'flex',
				borderRadius: 20,
				flexDirection: 'column',
				height: 258,
				backgroundColor: colors.white,
				width: width || '50%',
				boxShadow: '0px 4px 30px rgba(59, 112, 164, 0.25)',
				padding: '18px 34px',
			}}
		>
			<TouchableOpacity
				style={{ alignItems: 'center', flexDirection: 'row-reverse' }}
				onPress={() => navigate()}
			>
				<I18n size={18} weight='normal'>
					{title}
				</I18n>
				<ImageIcon
					name='enterPageArrow'
					width={22}
					height={11.5}
					style={{ marginRight: 9 }}
				/>
			</TouchableOpacity>
			{type === 'rows' ? (
				<Rows {...rest} navigate={navigate} title={title} />
			) : (
				<Pie {...rest} navigate={navigate} title={title} />
			)}
		</div>
	);
};

export default Card;
