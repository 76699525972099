import React from 'react';
import {
	View,
	StyleSheet,
	useWindowDimensions,
	TouchableHighlight,
} from 'react-native';
import HeaderContainer from '../../components/shared/Header/Header.container';
import colors from '../../config/colors';
import I18n from '../../components/shared/i18n/I18n.component';
import { HomePageProps } from './HomePage.component';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import WageDataSummary from './SubComponents/WageDataSummary';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import ClockReport from '../../components/shared/ClockReport/Clock.container';

const HomePageWeb = ({
	navigation,
	isSummaryOnly,
	isPaycheckBlockShown,
	reportDateYear,
	reportContentData,
	report101warning,
	form101Id,
}: HomePageProps) => {
	const windowWidth = useWindowDimensions().width;
	const windowHeight = useWindowDimensions().height;
	const maxWidth = windowWidth > 1700 ? 612 : windowWidth > 1500 ? 540 : 480;
	const contentBlock = {
		marginTop: 28,
		marginBottom: 48,
		height: windowHeight - 167,
		backgroundColor: colors.white,
		width: '100%',
		maxWidth: maxWidth,
	};

	if (isSummaryOnly) {
		return (
			<>
				<HeaderContainer
					containerStyle={{ backgroundColor: colors.white }}
					navigation={navigation}
					component={WebHeader}
				/>
				<View
					style={{
						width: '100%',
						flexDirection: 'row-reverse',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<View style={contentBlock} nativeID='contanetBlockWeb2'>
						<WageDataSummary
							reportDateYear={reportDateYear}
							reportContentData={reportContentData}
							report101warning={report101warning}
							isPaycheckBlockShown={isPaycheckBlockShown}
							form101Id={form101Id}
						/>
					</View>
				</View>
			</>
		);
	}

	return (
		<View>
			<HeaderContainer
				containerStyle={{ backgroundColor: colors.white }}
				navigation={navigation}
				component={WebHeader}
			/>
			<View style={{ width: '100%', marginTop: 38 }}>
				<View style={styles.container} nativeID='homePageWebContainer'>
					<ClockReport />
					{/* Report101 details below*/}
					<View style={contentBlock} nativeID='contanetBlockWeb2'>
						<WageDataSummary
							reportDateYear={reportDateYear}
							reportContentData={reportContentData}
							report101warning={report101warning}
							isPaycheckBlockShown={isPaycheckBlockShown}
							form101Id={form101Id}
						/>
					</View>
				</View>
				{/* Move to previouse below*/}
				{!isSummaryOnly && (
					<View
						style={{
							width: '100%',
							flexDirection: 'row-reverse',
							alignItems: 'center',
							justifyContent: 'space-evenly',
						}}
					>
						<View style={{ maxWidth: maxWidth, width: '100%' }} />
						<View
							style={{
								maxWidth: maxWidth,
								width: '100%',
								flexDirection: 'row',
								justifyContent: 'center',
							}}
						>
							<TouchableHighlight
								underlayColor='none'
								accessibilityRole='button'
								onPress={() =>
									navigation.navigate(
										'calendarAttendanceReports',
									)
								}
							>
								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<ImageIcon
										name={'back'}
										width={12}
										height={12}
										style={{ marginRight: 5 }}
									></ImageIcon>
									<I18n
										size={18}
										weight={'normal'}
										color={'primary'}
									>
										reportView.wageDataSummary.redirectToPreviouse
									</I18n>
								</View>
							</TouchableHighlight>
						</View>
					</View>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		height: 'calc(100vh - 168px)',
	},

	reportView: {
		width: '100%',
		flexDirection: 'column',
		backgroundColor: colors.white,
	},
	space: {
		marginLeft: 10,
	},
	currentProject: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 10,
		marginBottom: 10,
	},
	currentProjectText: {
		marginLeft: 10,
	},
});

export default HomePageWeb;
