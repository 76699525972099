import React, { useEffect, useMemo, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	Dimensions,
	Platform,
} from 'react-native';
import { Portal } from 'react-native-paper';
import { useSelector } from 'react-redux';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import ModalContainer from '../../../../components/shared/Modal/Modal.container';
import colors from '../../../../config/colors';
import getShadowStyle from '../../../../config/shadow';
import { Privileges } from '../../../../constants/roleTypes.constant';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import httpService from '../../../../services/http.service';
import { modalService } from '../../../../services/modal.service';
import { getUserData } from '../../../../store/selectors/login.selectors';
import RoleRow from './RoleRow.component';
import SelectEmployeeExtra from './RolesSectionMobile/SelectEmployeeExtra';
import SelectExtraDataModal from './RolesSectionMobile/SelectExtraDataModal';
import SelectPermissionsModal from './RolesSectionMobile/SelectPermissionsModal';
import SelectPermissionsModalWeb from './RolesSectionModalsWeb/SelectPermissionsModal';
import SelectDepartmentsModal from './RolesSectionModalsWeb/SelectDepartmentsModal';
import { getCustomersModal, getUsermodal } from './getUsermodal.util';
import { useNavigation } from '@react-navigation/native';
import { compact, flattenDeep } from 'lodash';
import { hasPermissionOnBusinessUnit } from '../../../../utils/globals.utils';

const adminRoles = [1, 2, 3, 7];

const RolesSection = ({
	roles,
	departments,
	onSelectRole,
	onChangeDep,
	onChangeUsers,
	getEmployeesFilter,
	totalUsers,
	setIsModalOpen,
	onAddRole,
	onDeleteRole,
	onChangePermissions,
	unitId,
	hasOnlyOneUnit,
	onChangeCustomers,
	cleanRolesOptions,
	disableSelectEmployeeRole,
	userId,
}: any) => {
	const userData = useSelector(getUserData);
	const [showSelectModal, setShowSelectModal] = useState<boolean | any>(
		false,
	);
	const [permissionsMap, setPermissionMap] = useState<any>({});
	const [customersLength, setCustomersLength] = useState<number>(0);
	const hasMuchragim = useMemo(
		() =>
			roles.user_roles?.find((role: number) => adminRoles.includes(role)),
		[roles.user_roles],
	);
	const editMySelf = useMemo(
		() => (userId === userData.user_id ? true : false),
		[userId, userData.user_id],
	);
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const navigation: any = isMobile ? useNavigation() : {};

	const getPermissions = async (roleId: number) => {
		const permissions: any = await httpService.api({
			type: 'getRolePermissions',
			params: { id: roleId },
			query: { unitId },
		});

		setPermissionMap((prev: any) => ({
			...prev,
			[roleId]: permissions.sort((a: any, b: any) =>
				a.allow_read_only !== b.allow_read_only
					? a.allow_read_only
						? -1
						: 1
					: a.name > b.name
					? 1
					: -1,
			),
		}));
	};

	const openModal = async (
		roleId: number,
		type: 'departments' | 'permissions' | 'users' | 'customers',
		isAdmin?: boolean,
		customerUnits?: any,
	) => {
		if (type === 'permissions' && !permissionsMap[roleId]) {
			await getPermissions(roleId);
		}
		setShowSelectModal({ type, roleId, isAdmin, customerUnits });
		isMobile && type !== 'customers' && setIsModalOpen(true);
	};

	const closeModal = () => {
		setShowSelectModal(false);
		isMobile && setIsModalOpen(false);
	};

	const getRepCustomers = async () => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: 0,
				ps: 5,
				f: {
					unitIds: [unitId],
				},
			},
		});

		if (res.total) {
			setCustomersLength(res.total);
		}
	};

	useEffect(() => {
		if (showSelectModal?.type && !isMobile) {
			(async () => {
				const Component =
					showSelectModal?.type === 'users'
						? await getUsermodal()
						: showSelectModal?.type === 'customers'
						? await getCustomersModal()
						: () => <View />;
				const modalData = getModalsData();
				modalService.openModal(
					null,
					{
						onNext: (callback: Function) => {
							setShowSelectModal(false);
							callback();
						},
						unitId,
						...modalData,
					},
					(props: any) =>
						showSelectModal?.type === 'users' ? (
							<Component {...props} />
						) : showSelectModal?.type === 'customers' ? (
							<Component {...props} />
						) : showSelectModal?.type === 'permissions' ? (
							<SelectPermissionsModalWeb {...props} />
						) : (
							<SelectDepartmentsModal {...props} />
						),
				);
			})();
		} else if (showSelectModal?.type === 'customers' && isMobile) {
			const modalData = getModalsData();
			navigation.navigate?.('customers', { ...modalData });
		}
	}, [showSelectModal]);

	const getModalsData = () => {
		const selectedRoleData =
			roles.roles?.find(
				(role: any) => role.id === showSelectModal?.roleId,
			) || {};
		const disabled = !hasPermissionOnBusinessUnit(
			unitId,
			Privileges.ROLE_MGM_USER_WRITE,
		);
		switch (showSelectModal?.type) {
			case 'permissions':
				return {
					selectedData:
						roles.user_role_permission?.[showSelectModal?.roleId] ||
						compact(
							permissionsMap[String(showSelectModal?.roleId)].map(
								d =>
									d.disable
										? undefined
										: { permission_id: d.id },
							),
						),
					allData: permissionsMap[String(showSelectModal?.roleId)],
					onSubmit: (value: any[]) => {
						onChangePermissions(
							value,
							showSelectModal?.roleId,
							permissionsMap[String(showSelectModal?.roleId)],
							showSelectModal?.isAdmin,
						);
					},
					close: closeModal,
					role: selectedRoleData,
					title: selectedRoleData?.name,
					disabled,
					checkAll:
						!roles.user_role_permission?.[showSelectModal?.roleId]
							?.length,
					showSwitch: true,
					editMySelf: selectedRoleData.id !== 6 && editMySelf,
				};
			case 'departments':
				return {
					selectedData:
						roles.user_role_department?.[showSelectModal?.roleId],
					allData: [
						{ id: 'all', name: 'general.all' },
						...departments,
					],
					onSubmit: (value: any[]) => {
						onChangeDep(value, showSelectModal?.roleId);
					},
					close: closeModal,
					role: selectedRoleData,
					subTitle:
						!roles?.user_role_department?.[showSelectModal?.roleId]
							?.length &&
						roles.roles?.find(
							(el: any) => el.id === showSelectModal?.roleId,
						)?.allocate_department
							? 'editUserModal.department.error'
							: '',
					disabled,
					isSelected: (item: any, selected: any) =>
						!!selected.find((val: any) => val.id === item.id),
				};
			case 'users':
				const shouldCheckNew =
					(roles?.excluded_user && !roles?.excluded_user.length) ||
					(roles?.excluded_user && !roles?.exclude_selected);
				return {
					selectedData: roles?.excluded_user || [],
					disabled,
					onFilter: getEmployeesFilter,
					onSubmit: onChangeUsers,
					close: closeModal,
					role: selectedRoleData,
					shouldCheckNew,
					isSelected: (item: any, selected: any) =>
						!!selected.find((val: any) => val.id === item.id),
					displayExcluded: roles?.excluded_user?.length,
					initialData: {
						exclude_selected: roles?.exclude_selected,
						excluded_user: roles?.excluded_user,
					},
					unitId,
					departments: !roles.user_roles.find(
						(id: number) =>
							!roles.roles?.find((el: any) => el.id === id)
								?.allocate_department,
					)
						? flattenDeep(
								compact(
									Object.keys(
										roles.user_role_department || {},
									).map(
										(key: string) =>
											roles.user_role_department[key],
									),
								),
						  )
						: [],
				};
			case 'customers':
				return {
					id: showSelectModal.roleId,
					data: roles.user_role_rep_customer_info?.[
						showSelectModal.roleId
					],
					onNext: closeModal,
					onSubmit: (data: any) => {
						onChangeCustomers(data, showSelectModal.roleId);
						closeModal();
					},
					unitId,
					isAdmin: showSelectModal?.isAdmin,
					disabled,
				};
		}
	};

	const modalData = useMemo(() => {
		if (!showSelectModal) return;
		return getModalsData();
	}, [showSelectModal, userData, departments, roles]);

	return (
		<View
			style={{
				marginTop: 16,
				marginRight:
					roles.user_roles?.length > 1 && !isMobile && hasOnlyOneUnit
						? 30
						: 0,
			}}
		>
			{roles.user_roles?.map((role: number) => (
				<RoleRow
					key={role}
					role={role}
					onDeleteRole={onDeleteRole}
					onSelectRole={onSelectRole}
					openModal={openModal}
					departmentsLength={departments?.length}
					canDelete={roles.user_roles?.length > 1}
					roles={roles}
					getRepCustomers={getRepCustomers}
					customersLength={customersLength}
					hasDepError={
						!roles?.user_role_department?.[role]?.length &&
						roles.roles?.find((el: any) => el.id === role)
							?.allocate_department
					}
					getPermissions={getPermissions}
					permissions={permissionsMap[String(role)]}
					disabled={
						!hasPermissionOnBusinessUnit(
							unitId,
							Privileges.ROLE_MGM_USER_WRITE,
						)
					}
					showCustomersButton={
						hasPermissionOnBusinessUnit(
							unitId,
							Privileges.ROLE_REP_CUSTOMER_READ,
							true,
						) ||
						hasPermissionOnBusinessUnit(
							unitId,
							Privileges.ROLE_REP_CUSTOMER_WRITE,
							true,
						)
					}
					disableSelectEmployeeRole={disableSelectEmployeeRole}
				/>
			))}
			{/* </ScrollView> */}
			<View
				style={{
					flexDirection: isMobile ? 'row-reverse' : 'column',
					width: isMobile
						? Dimensions.get('window')?.width - 40
						: '100%',
				}}
			>
				{!!cleanRolesOptions?.length &&
					hasPermissionOnBusinessUnit(
						unitId,
						Privileges.ROLE_MGM_USER_WRITE,
					) && (
						<TouchableOpacity
							onPress={() => onAddRole()}
							style={[
								styles.actionButtons,
								{
									marginRight: Platform.OS !== 'web' ? 13 : 0,
									flexDirection: isMobile
										? 'row-reverse'
										: 'row',
									marginTop: isMobile ? 0 : 16,
								},
							]}
						>
							<ImageIcon
								name='plusWhite'
								height={18}
								width={18}
								style={{ marginLeft: 10 }}
							/>
							<I18n color='white' weight='normal' size={16}>
								editUserModal.addRole
							</I18n>
						</TouchableOpacity>
					)}
				{hasMuchragim && (
					<TouchableOpacity
						onPress={() => openModal(undefined, 'users')}
						style={[
							isMobile
								? styles.actionButtons
								: styles.buttonMuchragWeb,
							{
								backgroundColor: colors.primary,
								marginTop: isMobile ? 0 : 20,
								marginRight: isMobile ? 13 : 0,
								flexDirection: isMobile ? 'row-reverse' : 'row',
							},
						]}
					>
						<I18n color='white' weight='normal' size={16}>
							editUserModal.muchragim
						</I18n>
						{roles?.excluded_user?.length ? (
							<I18n
								color='white'
								weight='normal'
								size={14}
								style={{ marginRight: 3 }}
							>
								(
								{!roles?.exclude_selected
									? totalUsers - roles?.excluded_user?.length
									: roles?.excluded_user?.length}
								)
							</I18n>
						) : null}
					</TouchableOpacity>
				)}
			</View>
			{isMobile && showSelectModal?.type !== 'customers' && (
				<Portal>
					<ModalContainer
						disabledKeyboard
						visible={showSelectModal}
						component={
							showSelectModal?.type === 'users'
								? SelectEmployeeExtra
								: showSelectModal?.type === 'permissions'
								? SelectPermissionsModal
								: SelectExtraDataModal
						}
						onNext={() => closeModal()}
						onSubmit={() => closeModal()}
						notDisplayModal
						{...modalData}
					/>
				</Portal>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		textAlign: 'right',
		justifyContent: 'center',
		alignSelf: 'stretch',
	},
	buttonSelected: {
		backgroundColor: colors.primary,
	},
	centeredText: {
		textAlign: 'center',
	},
	containerStyle: {
		width: 150,
	},
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 180,
		backgroundColor: colors.lightPrimary,
		borderRadius: 35,
		minHeight: 45,
		paddingHorizontal: 24,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
	buttonMuchragWeb: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 150,
		backgroundColor: colors.lightPrimary,
		borderRadius: 35,
		minHeight: 38,
		paddingHorizontal: 7,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default RolesSection;
