import React, { useMemo } from 'react';
import { Dimensions, useWindowDimensions, View } from 'react-native';
import { Breadcrumbs, Link } from '@material-ui/core';
import colors from '../../config/colors';
import I18n from '../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import Header from '../../components/shared/Header/Header.container';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import ReportsPageWrapper from '../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import {
	getHeaders,
	ScreenSendDocumentsToEmployeeModal,
	SendDocumentModal,
	Total,
} from './Documents.utils';
import DocumentsMobile from './Documents.component.native';
import Filters from '../../components/web/Filters/Filters.component';
import TableContainer from '../../components/web/ReactTable/Table.container';
import NoResults from '../../components/shared/NoResults/NoResults';

export interface DocumentsProps {
	navigation: any;
	route: any;
	data: any[];
	filters: any;
	selectedFilters: any;
	filtersConfig: any;
	filtesSelected: boolean;
	isEmployer: boolean | undefined;
	onLoadMore(): void;
	onFilter(filterData: any): void;
	disabled: boolean;
	onSort?: (field: string, direction: string) => void;
	userData: any;
	users: any;
	filtersData: any;
	onUserFilter: Function;
	onUserSort: Function;
	userFilters: any;
	getNextData: Function;
	refreshPage: Function;
	businessUnitsList: any;
	dimentions: any;
	startFiltering: Function;
	isSubMenuOpen: any;
	setIsSubMenuOpen: Function;
	setRemoveDoc: Function;
	modules: any;
	total: number;
	isLoading: boolean;
}

const Documents = ({
	navigation,
	data,
	filtersConfig,
	isEmployer,
	selectedFilters,
	filtesSelected,
	onSort,
	onLoadMore,
	onFilter,
	disabled,
	businessUnitsList,
	setRemoveDoc,
	modules,
	total,
	isLoading,
}: DocumentsProps) => {
	const { height: windowHeight } = useWindowDimensions();

	const isSmallDimensions = useMemo(
		() => window.innerWidth <= 1600,
		[window.innerWidth],
	);
	const platform = useDevicePlatform();

	const isMobileWeb = platform === DeviceType.MOBILE_WEB;
	const updateFilters = (val: any) => {
		onFilter(val);
	};

	const headers = getHeaders(
		isEmployer,
		businessUnitsList,
		disabled,
		setRemoveDoc,
		modules,
	);

	return isMobileWeb ? (
		<DocumentsMobile
			navigation={navigation}
			data={data}
			selectedFilters={selectedFilters}
			filtersConfig={filtersConfig}
			filtesSelected={filtesSelected}
			isEmployer={isEmployer}
			businessUnitsList={businessUnitsList}
			onFilter={onFilter}
			onLoadMore={onLoadMore}
			disabled={disabled}
			total={total}
		/>
	) : (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<Breadcrumbs separator='<'>
						<Link color='inherit' underline={'none'}>
							<I18n size={36} weight='normal' style={{ flex: 1 }}>
								documents.title
							</I18n>
						</Link>
					</Breadcrumbs>
				}
				noHeader={true}
			>
				{isEmployer && (
					<View
						style={{
							marginBottom: 20,
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
							zIndex: 100,
						}}
					>
						<Filters
							config={filtersConfig}
							onChange={updateFilters}
							// hideSearchInput
							customStyles={{
								container: {
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-end',
								},
								filterContainer: {
									marginLeft: 15,
									zIndex: 10001,
								},
							}}
						/>
						{!disabled && (
							<SendDocumentModal
								disabled={disabled}
								isMobile={false}
								onPress={() =>
									ScreenSendDocumentsToEmployeeModal()
								}
							/>
						)}
					</View>
				)}

				{data.length > 0 || isLoading ? (
					<View
						style={{
							maxHeight: isSmallDimensions
								? windowHeight - 240
								: windowHeight - 250,
						}}
					>
						<Total total={total} isMobile={false} />
						<TableContainer
							keyName={''}
							headers={headers}
							data={data || []}
							getNextData={onLoadMore}
							onSort={onSort}
							hasFooter={false}
						/>
					</View>
				) : (
					<NoResults title='documents.table.noResult' />
				)}
			</ReportsPageWrapper>
		</View>
	);
};

export default React.memo(Documents);
