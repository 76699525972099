import * as yup from 'yup';

const idValidation = function (item?: string) {
	let id = item;
	id = String(id).trim();

	if (
		//!['0', '2', '7', '3'].includes(id[0]) ||
		id.length > 9 ||
		isNaN(Number(id))
	) {
		return false;
	}
	console.log({ id, item });
	id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
	return (
		Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) %
			10 ===
		0
	);
};

export const validationSchema = (id: any) => {
	return yup.object().shape({
		identity_type: yup.string(),
		first_name: yup
			.string()
			.required('general.validation.required')
			.min(1, 'general.validation.invalidValue')
			.max(50, 'general.validation.invalidValue')
			.when(
				'identity_type',
				(identity_type: string, validationSchema: any) => {
					if (identity_type === 'PASSPORT') {
						return validationSchema.matches(
							/^[A-Z\s-]+$/,
							'general.validation.invalidValue',
						);
					}

					return;
				},
			)
			.nullable(),
		last_name: yup
			.string()
			.required('general.validation.required')
			.min(1, 'general.validation.invalidValue')
			.max(50, 'general.validation.invalidValue')
			.when('identity_type', (identity_type: string, schema: any) => {
				if (identity_type === 'PASSPORT') {
					return schema.matches(
						/^[A-Z\s-]+$/,
						'general.validation.invalidValue',
					);
				}

				return;
			})
			.nullable(),
		teudat_zeut: id
			? yup.string()
			: yup
					.string()
					.when(
						'identity_type',
						(identity_type: string, schema: any) => {
							if (identity_type === 'ID') {
								return schema.required(
									'general.validation.required',
								);
							}
							return;
						},
					)
					.when(
						'identity_type',
						(identity_type: string, schema: any) => {
							if (identity_type === 'ID') {
								return schema.test(
									'idTest',
									'general.validation.invalidValue',
									idValidation,
								);
							}
							return;
						},
					)
					.transform(value => (value === '' ? undefined : value))
					.max(9, 'general.validation.invalidValue')
					.min(9, 'general.validation.invalidValue'),
		darkon: id
			? yup.string()
			: yup
					.string()
					.when(
						'identity_type',
						(identity_type: string, schema: any) => {
							if (identity_type === 'PASSPORT') {
								return schema.required(
									'general.validation.required',
								);
							}

							return;
						},
					)
					.transform(value => (value === '' ? undefined : value))
					.max(13, 'general.validation.invalidValue')
					.min(5, 'general.validation.invalidValue'),
		email: yup
			.string()
			.required('general.validation.required')
			.matches(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				'general.validation.invalidValue',
			)
			.nullable(),
		phone_prefix: yup.string().required('general.validation.required'),
		phone: yup
			.string()
			.required('general.validation.required')
			.min(7, 'general.validation.invalidValue')
			.max(7, 'general.validation.invalidValue')
			.matches(/[0-9]*/, 'general.validation.invalidValue')
			.nullable(),
	});
};
