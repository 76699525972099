import AsyncStorage from '@react-native-async-storage/async-storage';
import { Linking, Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { Privileges } from '../constants/roleTypes.constant';
import { getState } from '../store/store';

export const isRoleExistInPrivilegs = (item: Privileges, arr: Privileges[]) =>
	arr.includes(item);

export const getDocumentTitle = (text: string) =>
	Platform.select({ web: `מיכפל +`, default: text });

export const getIsSafari = () => {
	const ua = navigator.userAgent.toLowerCase();
	if (ua.indexOf('safari') != -1) {
		if (ua.indexOf('chrome') === -1) {
			return true;
		}
	}
};

export const getIsVersionLower = (version: string = '') => {
	const currVersion = DeviceInfo.getVersion() || '0.0.0';
	const reqVersionArr = version.split('.');
	const currVersionArr = currVersion.split('.');
	let isVersionLower = false;

	if (+reqVersionArr[0] > +currVersionArr[0]) {
		isVersionLower = true;
	} else if (reqVersionArr[0] === currVersionArr[0]) {
		if (+reqVersionArr[1] > +currVersionArr[1]) {
			isVersionLower = true;
		} else if (reqVersionArr[1] === currVersionArr[1]) {
			if (+reqVersionArr[2] > +currVersionArr[2]) {
				isVersionLower = true;
			}
		}
	}

	return isVersionLower;
};

export const openStore = () => {
	const url =
		Platform.OS === 'android'
			? 'https://play.google.com/store/apps/details?id=com.michpal.michpalhrs'
			: 'itms-apps://apps.apple.com/us/app/%D7%9E%D7%99%D7%9B%D7%A4%D7%9C/id1583931924';
	Linking.canOpenURL(url).then(
		supported => {
			supported && Linking.openURL(url);
		},
		err => console.log(err),
	);
};

export const phonePrefixOptions = [
	{
		id: '050',
		name: '050',
	},
	{
		id: '051',
		name: '051',
	},
	{
		id: '052',
		name: '052',
	},
	{
		id: '053',
		name: '053',
	},
	{
		id: '054',
		name: '054',
	},
	{
		id: '055',
		name: '055',
	},
	{
		id: '056',
		name: '056',
	},
	{
		id: '058',
		name: '058',
	},
	{
		id: '059',
		name: '059',
	},
];

export const hasPermissionOnBusinessUnit = (
	unitId: number,
	permission: string,
	forceNotRepMode?: boolean,
) => {
	const customer =
		getState().repEnterMode.customerHp && !forceNotRepMode
			? getState().repEnterMode?.customer_privilege?.[unitId]
			: getState().login?.user?.customer_privilege?.[unitId];
	return !!customer?.includes(permission);
};

export const getItemFromLocalStorage = async (name: string) => {
	if (Platform.OS === 'android' || Platform.OS === 'ios') {
		return await AsyncStorage.getItem(name);
	}
	return localStorage.getItem(name);
};

export const setItemToLocalStorage = async (name: string, value: string) => {
	if (Platform.OS === 'android' || Platform.OS === 'ios') {
		await AsyncStorage.setItem(name, value);
	} else {
		localStorage.setItem(name, value);
	}
};
