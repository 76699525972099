import React, { useEffect, useRef, useState } from 'react';
import CustomerDataSection from './CustomerDataSection.component';
import {
	Dimensions,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	View,
} from 'react-native';
import useDevicePlatform, {
	DeviceType,
} from '../../../../../hooks/useDevicePlatform.hook';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import getShadowStyle from '../../../../../config/shadow';
import colors from '../../../../../config/colors';
import { i18nService } from '../../../../../services/i18n.service';
import NoResults from '../../../../../components/shared/NoResults/NoResults';
import CustomerRolesSection from './CustomerRolesSection.component';
import { modalService } from '../../../../../services/modal.service';
import AddCustomerWeb from './AddCustomer';
import _, { debounce } from 'lodash';
import SearchInputContainer from '../../../../../components/shared/SearchInput/SearchInput.container';
import httpService from '../../../../../services/http.service';
import { Portal } from 'react-native-paper';
import ModalContainer from '../../../../../components/shared/Modal/Modal.container';
import { useNavigation } from '@react-navigation/native';

const CustomerRolesWrapper = ({
	setCustomersInfo,
	customersInfo,
	disabled,
	unitId,
	setDeletedArray,
	deleteArray,
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const [customers, setCustomers] = useState<any>(customersInfo);
	const customersLength = useRef<any>(customersInfo?.length);
	const [showModal, setShowModal] = useState<boolean | any>(false);
	const navigation: any = isMobile ? useNavigation() : {};

	useEffect(() => {
		if (customersInfo.length === customersLength.current) {
			setCustomers(
				customers.map((cust: any) =>
					customersInfo.find(
						(customer: any) =>
							customer.customer_id === cust.customer_id,
					),
				),
			);
		} else {
			customersLength.current = customersInfo.length;
		}
	}, [customersInfo]);

	useEffect(() => {
		setCustomers(customersInfo);
	}, [customersInfo.length]);

	const getCustomerUnits = async (id?: number) => {
		const tempCustomers = [...customersInfo];
		const index = customersInfo.findIndex(
			(cust: any) => cust.customer_id === id,
		);

		if (!tempCustomers[index]?.customerUnits) {
			const res: any = await httpService.api({
				type: 'getRepCustomerDetails',
				params: { customerId: id },
				disableBI: true,
			});
			if (res) {
				tempCustomers[index] = {
					...tempCustomers[index],
					customerUnits: res.businessUnits.map((unit: any) => {
						return { id: unit.id, name: unit.name };
					}),
				};

				setCustomersInfo(tempCustomers);
			}
		}
	};

	const searchCustomers = (search: any) => {
		if (search === '') {
			setCustomers(customersInfo);
			return;
		}

		const tempCust = customersInfo.filter(
			(cust: any) =>
				cust.customer_name.includes(search.value) ||
				cust.customer_hp.includes(search.value),
		);
		setCustomers(tempCust);
	};

	const removeDeleteArrayOnAddCust = (newCustomers: any[]) => {
		const deletedArr = deleteArray.filter(
			(id: number) =>
				!newCustomers.find((cust: any) => cust.customer_id === id),
		);
		if (!_.isEqual(deleteArray, deletedArr)) {
			setDeletedArray(deletedArr);
		}
	};

	const onAddCustomer = async () => {
		if (isMobile) {
			setShowModal(true);
			return;
		}
		modalService.openModal(
			null,
			{
				onNext: (callback: Function) => {
					callback();
				},
				onSubmit: (newCustomers: any) => {
					setCustomersInfo([...customersInfo, ...newCustomers]);
					removeDeleteArrayOnAddCust(newCustomers);
				},
				selectedData: customersInfo.map(
					(cust: any) => cust.customer_id,
				),
				unitId,
			},
			(props: any) => <AddCustomerWeb {...props} />,
		);
	};

	const onSelectCustomer = async (value: any, index: any) => {
		getCustomerUnits(value?.customer_id);
	};

	const onDeleteCustomer = async (id: number) => {
		const customersToSet = customersInfo?.filter(
			(customer: any) => customer.customer_id !== id,
		);
		setCustomersInfo([...customersToSet]);
		setDeletedArray((state: any[]) => [...state, id]);
	};

	const handleSelectRole = (role: number, id: number) => {
		setCustomersInfo((prev: any) => [
			...prev.map((item: any, i: number) =>
				item.customer_id === id ? { ...item, role_id: role } : item,
			),
		]);
	};

	const onChangUnits = (value: any, id: number) => {
		setCustomersInfo((prev: any) => [
			...prev.map((item: any, i: number) =>
				item.customer_id === id
					? {
							...item,
							unit_ids: value,
							excluded_user: null,
							exclude_selected: true,
					  }
					: item,
			),
		]);
	};

	const onChangeUsers = (value: any, id: number) => {
		setCustomersInfo((prev: any) => [
			...prev.map((item: any, i: number) =>
				item.customer_id === id ? { ...item, ...value } : item,
			),
		]);
	};

	const onChangePermissions = (
		value: any,
		permissionCount: any,
		id: number,
	) => {
		setCustomersInfo((prev: any) => [
			...prev.map((item: any, i: number) =>
				item.customer_id === id
					? {
							...item,
							user_role_permission: value,
					  }
					: item,
			),
		]);
	};

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
		noConvert?: boolean,
		displayExcluded?: boolean,
		excludedUser?: any,
		excludeSelected?: any,
		repCustomerId?: any,
		unitIds?: any,
	) => {
		const users = excludedUser;
		const exclude_selected = !excludedUser || excludeSelected;

		const res: any[] = await httpService.api<any>({
			type: 'getUsersByDepartmentsAndUnits',
			query: {
				pageName: 'users',
				unitId: unitIds,
				...(repCustomerId ? { repCustomerId } : {}),
			},
			data: {
				search: search,
				f: { dep: [] },
			},
		});
		let options = res
			.map((unit: any) => unit.departments.map((dep: any) => dep.users))
			?.flat(2)
			?.map((el: any) => {
				return {
					[noConvert ? 'id' : 'value']: el.profileId,
					[noConvert ? 'name' : 'label']:
						el.name + ` (${el.teudatZeut})`,
					extra: el,
				};
			});

		if (displayExcluded && !exclude_selected) {
			options = options.filter(
				(opt: any) => !users?.includes(opt.value || opt.id),
			);
		}

		if (displayExcluded && exclude_selected) {
			options = options.filter((opt: any) => users?.includes(opt.id));
		}

		if (displayExcluded && !users?.length) {
			options = [];
		}

		return {
			options:
				!page && !displayExcluded
					? [
							{
								[noConvert ? 'id' : 'value']: 'all',
								[noConvert ? 'name' : 'label']:
									i18nService.translate('general.all'),
							},
							...options,
					  ]
					: options,
			hasMore: false,
		};
	};

	return (
		<View
			style={{
				flex: 1,
				height: '100%',
			}}
		>
			<View
				style={{
					flexDirection: isMobile ? 'column-reverse' : 'row',
					alignItems: 'baseline',
					marginBottom: isMobile ? 28 : 32,
					justifyContent: 'space-between',
					paddingHorizontal: isMobile ? 20 : 0,
					marginTop: isMobile ? 26 : 0,
					zIndex: 1,
				}}
			>
				{customersInfo?.length ? (
					<SearchInputContainer
						key={`${customersInfo?.length}`}
						placeholder={i18nService.translate(
							'customerManagementModal.search.placeholder',
						)}
						config={{ name: 'search', type: 'searchInput' }}
						onChange={debounce(searchCustomers, 500)}
						styleConf={{
							container: {
								borderWidth: isMobile ? 0 : 1,
								borderColor: '#E0E0E0',
								borderRadius: 9,
								alignItems: 'center',
								paddingVertical: 5,
								width: isMobile ? '100%' : 290,
							},
							input: {
								flexDirection: 'row',
								width: '100%',
							},
						}}
					/>
				) : (
					<View />
				)}
				{!disabled && (
					<TouchableOpacity
						onPress={() => onAddCustomer()}
						style={[
							styles.actionButtons,
							{
								flexDirection: isMobile ? 'row-reverse' : 'row',
								marginBottom: isMobile ? 10 : 0,
							},
						]}
					>
						<I18n color='white' weight='normal' size={16}>
							customerManagementModal.addCustomer
						</I18n>
					</TouchableOpacity>
				)}
			</View>
			<ScrollView
				style={{
					flex: 1,
					backgroundColor: colors.white,
					maxHeight:
						Dimensions.get('screen').height -
						(platform === DeviceType.MOBILE_WEB ? 300 : 280),
				}}
				contentContainerStyle={{ paddingBottom: 80 }}
			>
				{customers.length > 0 ? (
					customers?.map((customer: any, index: number) => (
						<CustomerDataSection
							data={customer}
							index={index}
							key={customer?.customer_id}
							onSelectCustomer={onSelectCustomer}
							onDeleteCustomer={onDeleteCustomer}
							canDelete={true}
							disabled={disabled}
						>
							{
								<CustomerRolesSection
									{...{
										customerInfo: customer || {},
										index: index,
										handleSelectRole,
										data: customer,
										onChangUnits,
										onChangePermissions,
										onChangeUsers,
										unitId,
										getEmployeesFilter,
										disabled,
									}}
								/>
							}
						</CustomerDataSection>
					))
				) : (
					<NoResults title={'customerManagementModal.noResult'} />
				)}
				{isMobile && (
					<Portal>
						<ModalContainer
							disabledKeyboard
							visible={showModal}
							component={AddCustomerWeb}
							notDisplayModal
							{...{
								onNext: (callback: Function) => {
									//callback();
									setShowModal(false);
								},
								onSubmit: (newCustomers: any) => {
									setCustomersInfo([
										...customersInfo,
										...newCustomers,
									]);
									removeDeleteArrayOnAddCust(newCustomers);
									setShowModal(false);
								},
								selectedData: customersInfo.map(
									(cust: any) => cust.customer_id,
								),
								unitId,
							}}
						/>
					</Portal>
				)}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	actionButtons: {
		...getShadowStyle(10, 'rgb(59, 112, 164)'),
		maxWidth: 210,
		backgroundColor: colors.lightPrimary,
		borderRadius: 35,
		minHeight: 40,
		paddingHorizontal: 22,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row-reverse',
	},
});

export default CustomerRolesWrapper;
