import { createSelector } from 'reselect';
import { RootState } from '../reducers/root.reducer';

export const getEmployeesFilterList = (state: RootState) =>
	state.reportsFilters.employeesFilterList;
export const getDepartmentsFilterList = (state: RootState) =>
	state.reportsFilters.departmentsFilterList;
export const getRolesFilterList = (state: RootState) =>
	state.reportsFilters.rolesFilterList;
export const getReport101State = (state: RootState) => state.report101Filters;
export const getRequestFilterState = (state: RootState) =>
	state.reportsFilters.requestsFilters;
export const getBusinessUnitsList = (state: RootState) =>
	state.reportsFilters.businessUnitsList;
export const getBusinessUnitsListByPermissions = createSelector(
	(state: RootState) => state.reportsFilters.businessUnitsList,
	(state: RootState) => state.login.user?.customer_privilege,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.customer_privilege,
	(_: any, permissions: string[]) => permissions,
	(
		businessUnitsList: any,
		customerPrivilege: any,
		customerHp: any,
		repCustomerPrivilege: any,
		permissions: string[],
	) =>
		businessUnitsList.filter((customer: any) =>
			permissions.find(permission =>
				(customerHp ? repCustomerPrivilege : customerPrivilege)[
					customer.id
				]?.includes(permission),
			),
		),
);

export const getHasSamePrivilegsUnits = createSelector(
	(state: RootState) => state.login.user?.customer_privilege,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.customer_privilege,
	(customerPrivilege: any, customerHp: any, repCustomerPrivilege: any) => {
		let hasSame: boolean = false;
		const privilegsObj: any = {};

		for (let cust of Object.values(
			customerHp ? repCustomerPrivilege : customerPrivilege,
		)) {
			for (let privileg of cust as any[]) {
				{
					if (privilegsObj[privileg]) {
						hasSame = true;
						break;
					} else {
						privilegsObj[privileg] = true;
					}
				}
				if (hasSame) {
					break;
				}
			}
		}
		return hasSame;
	},
);
