import React, { useRef, useState } from 'react';
import { Dimensions, View, StyleSheet } from 'react-native';
import Button from '../../../shared/Buttons/Button.component';
import I18n from '../../../shared/i18n/I18n.component';
import BottomSheet from 'reanimated-bottom-sheet';
import colors from '../../../../config/colors';
import ReportsListComponent from './ReportsListComponent.component';

const windowHeight = Dimensions.get('window').height;
const ReportsListContainer = ({ setShow, standardHours, aId }: any) => {
	const sheetRef: any = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	const renderHeader = () => (
		<View style={styles.header}>
			<Button
				buttonStyle={StyleSheet.flatten([
					styles.button,
					{ backgroundColor: colors.warning },
				])}
				onPress={() => {
					setShow();
					sheetRef.current.snapTo(0);
				}}
			>
				<I18n size={14} weight={'normal'} color='white'>
					general.closing
				</I18n>
			</Button>
		</View>
	);

	const renderContent = () => (
		<View style={styles.content}>
			<ReportsListComponent aId={aId} standardHours={standardHours} />
		</View>
	);

	return (
		<>
			<Button
				buttonStyle={styles.button}
				onPress={() => {
					setShow('reports');
					sheetRef.current.snapTo(2);
				}}
			>
				<I18n size={14} weight={'normal'} color='white'>
					general.startDay
				</I18n>
			</Button>
			{isOpen && <View style={styles.background} />}
			<BottomSheet
				initialSnap={0}
				onOpenStart={() => {
					setIsOpen(true);
				}}
				onCloseStart={() => {
					setIsOpen(false);
				}}
				callbackThreshold={0.3}
				ref={sheetRef}
				snapPoints={[0, '50%', '90%']}
				enabledContentGestureInteraction={false}
				renderHeader={renderHeader}
				renderContent={renderContent}
			/>
		</>
	);
};
const styles = StyleSheet.create({
	button: {
		alignSelf: 'center',
		height: 38,
		width: 140,
		backgroundColor: colors.warning,
		alignItems: 'center',
		justifyContent: 'center',
		borderTopRightRadius: 98,
		borderTopLeftRadius: 98,
	},
	background: {
		position: 'absolute',
		backgroundColor: colors.backgroundOpacity,
		left: 0,
		bottom: 0,
		height: windowHeight,
		width: '100%',
	},
	header: {
		paddingTop: windowHeight - (windowHeight - 150),
	},
	content: {
		backgroundColor: colors.white,
		minHeight: windowHeight - 150,
		alignItems: 'center',
		justifyContent: 'center',
	},
});

export default ReportsListContainer;
