import React from 'react';
import { TouchableHighlight } from 'react-native';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { modalService } from '../../../services/modal.service';
import httpService from '../../../services/http.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import LocationSettingsModal from './LocationSettingsModal/LocationSettingsModal';

export interface LocationData {
	id: number;
	location: {
		id: number;
		lat: number;
		lon: number;
		locationName: string;
		placeId: string;
	};
	name: string;
	status: boolean;
	radius: number;
	projects: string[];
	allDepartments: true;
	departments: string[];
	allUsers: boolean;
	users: {
		excludeSelected: boolean;
		userIds: number[];
	};
}

export const EditDeleteButton = ({
	iconName,
	height,
	width,
	onPress,
	disabled,
}: {
	iconName: string;
	height: number;
	width: number;
	onPress(): void;
	disabled: boolean;
}) => {
	return (
		<TouchableHighlight
			underlayColor='transparent'
			disabled={disabled}
			onPress={onPress}
			style={{
				opacity: disabled ? 0.5 : 1,
			}}
		>
			<ImageIcon name={iconName} height={height} width={width} />
		</TouchableHighlight>
	);
};

export const onDelete = async (id, refresh) => {
	await modalService.openModal(
		null,
		{
			submitBtnText: 'general.accept',
			cancelBtnText: 'general.cancel',
			iconName: 'attention',
		},
		(props: any) => (
			<ModalConfirmActions
				{...props}
				onSubmit={onNext => onNext(startDelete(id, refresh))}
				onCancel={onNext => onNext()}
			>
				<I18n>locationSettings.deleteModal</I18n>
			</ModalConfirmActions>
		),
	);
};

const startDelete = async (id, refresh) => {
	try {
		const res = await httpService.api<any>({
			type: 'deleteLocation',
			params: { locationId: id },
		});
		refresh();
	} catch (e) {}
};

export const onEdit = async (
	id,
	projectsFilterList,
	departmentsFilterList,
	refresh,
	businessUnitId: number,
) => {
	try {
		const res: LocationData = await httpService.api<any>({
			type: 'getExistingLocation',
			params: { locationId: id },
		});

		const users = await httpService.api<any>({
			type: 'getUsersByDepartments',
			query: { pageName: 'locationSettings', unitId: businessUnitId },
			data: {
				search: '',
				f: { dep: [], internal: ['true'] },
			},
		});

		await modalService.openModal(null, {}, (props: any) => (
			<LocationSettingsModal
				{...props}
				users={users}
				locationId={id}
				businessUnitId={businessUnitId}
				locationData={res}
				projectsFilterList={projectsFilterList}
				departmentsFilterList={departmentsFilterList}
				refresh={refresh}
			/>
		));
	} catch (e) {}
};
