import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
	getChosenDate,
	isOpenChosenDateModal,
} from '../../store/selectors/clockReport.selector';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import HeaderContainer from '../../components/shared/Header/Header.container';
import colors from '../../config/colors';
import ReportsCalendarContainer from '../../components/shared/ReportsCalendar/ReportsCalendar.container';
import { createStackNavigator } from '@react-navigation/stack';
import DayReportsContainer from './DayReports/DayReports.container';
import { getDocumentTitle } from '../../utils/globals.utils';
import { TouchableHighlight, StyleSheet, View } from 'react-native';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import getShadowStyle from '../../config/shadow';

const Stack = createStackNavigator();

interface Props {
	navigation: any;
}

const CalendarAttendanceReports = ({ navigation }: Props) => {
	const isFirstInit = useRef(true);
	const showModal = useSelector(isOpenChosenDateModal);

	useEffect(() => {
		if (!isFirstInit.current && showModal) {
			navigation.navigate('chosenDate');
		}

		isFirstInit.current = false;
	}, [showModal]);

	return (
		<>
			<Stack.Navigator
				mode='modal'
				screenOptions={{ headerShown: false ,	
					title: getDocumentTitle('calendar'),
				}}
			>
				<Stack.Screen
					name='calendar'
					component={() => (

						<>
							<HeaderContainer
								containerStyle={{
									backgroundColor: colors.white,
								}}
								navigation={navigation}
								component={MobileHeader}
							/>
							<ReportsCalendarContainer />
						</>
					)}
				/>
				<Stack.Screen
					name='chosenDate'
					component={() => (
						<DayReportsContainer navigation={navigation} />
					)}
				/>
			</Stack.Navigator>
		</>
	);
};

const styles = StyleSheet.create({
	fabButton: {
		borderRadius: 30,
		width: 40,
		height: 40,
		margin: 10,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});



export default CalendarAttendanceReports;
