import React from 'react';
import { useWindowDimensions, View } from 'react-native';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { Breadcrumbs, Button, Link, makeStyles } from '@material-ui/core';
import { i18nService } from '../../../services/i18n.service';
import colors from '../../../config/colors';
import classnames from 'classnames';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

type UsersTableProps = {
	navigation: any;
	rolesHeaders: any[];
	permissionsHeaders: any[];
	data: any[];
	getNextData: () => void;
	onRowClick: (rowData: any, event: any) => void;
	goBack: () => void;
	isRolesPageOpened: boolean;
	goToRolesPage: () => void;
	roleId: number | null;
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			padding: '0!important',
			minWidth: '20px',
			color: colors.primary,
			position: 'relative',
			left: '-27px',

			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		linkMain: {
			color: colors.primary,
			fontSize: 36,
			cursor: 'pointer',
			fontFamily: 'RubikMedium',
		},
		linkSelected: {
			color: colors.darkGrey,
			fontSize: 36,
			fontFamily: 'RubikMedium',
		},
		separator: {
			color: '#3b70a4',
			fontFamily: 'RubikRegular',
			fontSize: '27px',
			marginTop: '4px',
			fontWeight: 600,
		},
		tablesWrapper: {
			display: 'flex',
			flexDirection: 'row-reverse',
			justifyContent: 'space-between',
			height: 'calc(100% - 38px)',
		},
		rolesWrapper: {
			backgroundColor: '#fff',
			boxSizing: 'border-box',
			padding: '38px 38px 88px',
			width: '49%',
		},
		permissionsWrapper: {
			boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.15)',
			position: 'relative',
		},
		tableRoot: {
			border: '1px solid #e7e7e8',
		},
		specialRow: {
			'& td:first-child': {
				borderRight: `5px solid ${colors.primary}`,
			},
		},
		tableHeaders: {
			fontFamily: 'RubikRegular',
			fontSize: '1.25rem',
			color: colors.darkGrey,
			marginBottom: '16px',
			textAlign: 'right',
		},
		mainHeaderWrapper: {
			display: 'flex',
			flexDirection: 'row-reverse',
			justifyContent: 'space-between',
			width: '100%',
		},
	};
});

const UserRolesAndPermissions = ({
	navigation,
	data,
	rolesHeaders,
	permissionsHeaders,
	getNextData,
	onRowClick,
	goBack,
	roleId,
}: UsersTableProps) => {
	const { height } = useWindowDimensions();
	const classes = useStyles();

	return (
		<>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<div className={classes.mainHeaderWrapper}>
						<Breadcrumbs
							separator='<'
							classes={{ separator: classes.separator }}
						>
							<Link
								color='inherit'
								underline='none'
								variant='h4'
								className={classes.linkSelected}
							>
								{i18nService.translate(
									'usersManagement.rolesAndPermissions.title',
								)}
							</Link>
							<Link
								color='inherit'
								className={classes.linkMain}
								onClick={goBack}
							>
								{i18nService.translate(
									'usersManagement.table.title',
								)}
							</Link>
						</Breadcrumbs>
						<Button
							classes={{
								root: classes.root,
							}}
							onClick={goBack}
						>
							<KeyboardArrowLeftIcon fontSize='small' />
							{i18nService.translate('general.back')}
						</Button>
					</div>
				}
			>
				<View
					style={{
						maxHeight: height - 241,
						minHeight: height - 241,
					}}
				>
					<div className={classes.tablesWrapper}>
						<div className={classes.rolesWrapper}>
							<h4 className={classes.tableHeaders}>
								{i18nService.translate(
									'usersManagement.rolesAndPermissions.rolesTable.title',
								)}
							</h4>
							<TableContainer
								getNextData={getNextData}
								onRowClick={onRowClick}
								headers={rolesHeaders}
								data={data || []}
								count={15}
								keyName={''}
								onSort={() => {}}
								hasFooter={false}
								outerClasses={{
									tableRoot: classes.tableRoot,
									specialRow: classes.specialRow,
								}}
							/>
						</div>
						{roleId !== null && (
							<div
								className={classnames(
									classes.rolesWrapper,
									classes.permissionsWrapper,
								)}
							>
								<h4 className={classes.tableHeaders}>
									{i18nService.translate(
										'usersManagement.rolesAndPermissions.permissionsTable.title',
									)}
								</h4>
								<TableContainer
									getNextData={getNextData}
									onRowClick={onRowClick}
									headers={permissionsHeaders}
									data={
										data.find(el => el.id === roleId)
											.permissions || []
									}
									count={15}
									keyName={''}
									onSort={() => {}}
									hasFooter={false}
									outerClasses={{
										tableRoot: classes.tableRoot,
									}}
								/>
							</div>
						)}
					</div>
				</View>
			</ReportsPageWrapper>
			)
		</>
	);
};

export default UserRolesAndPermissions;
