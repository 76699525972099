import { DetailedCostingReport } from '../interfaces/redux.interface';
export class DetailedCostingDepartmentModel implements DetailedCostingReport{
	public id: string;
    public name: string;
    public tashlumim: number;
    public tagmuley_maasik: number;
    public keren_hishtalmut: number;
    public compensation: number;
    public shonut: number;
    public bleumi_maasik: number;
    public mas_maasikim: number;
    public mas_sachar: number;
    public total: number;
    public periods: DetailedCostingReport[]
    public business_unit_name: string;

	constructor(props: any) {
		this.id = props.id;
		this.name = props.name;
		this.tashlumim = props.tashlumim;
        this.tagmuley_maasik = props.tagmuley_maasik;
        this.keren_hishtalmut = props.keren_hishtalmut;
        this.compensation = props.compensation;
        this.shonut = props.shonut;
        this.bleumi_maasik = props.bleumi_maasik;
        this.mas_maasikim = props.mas_maasikim;
        this.mas_sachar = props.mas_sachar;
        this.total = props.total;
		this.periods = props.periods;
        this.business_unit_name = props.business_unit_name
	}
}

export class DetailedCostingEmployeeModel{
	public id: string;
    public name: string;
    public tashlumim: number;
    public tagmuley_maasik: number;
    public keren_hishtalmut: number;
    public compensation: number;
    public shonut: number;
    public bleumi_maasik: number;
    public mas_maasikim: number;
    public mas_sachar: number;
    public total: number;

	constructor(props: any) {
		this.id = props.id;
		this.name = props.name + ` (${props.teudat_zeut})`;
		this.tashlumim = props.tashlumim;
        this.tagmuley_maasik = props.tagmuley_maasik;
        this.keren_hishtalmut = props.keren_hishtalmut;
        this.compensation = props.compensation;
        this.shonut = props.shonut;
        this.bleumi_maasik = props.bleumi_maasik;
        this.mas_maasikim = props.mas_maasikim;
        this.mas_sachar = props.mas_sachar;
        this.total = props.total;
	}
}