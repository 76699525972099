import React from 'react';
// @ts-ignore
import Modal from 'modal-enhanced-react-native-web';
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import ImageIcon from '../Icon/ImageIcon.component';
import getShadowStyle from '../../../config/shadow';
import { ModalCallback } from '../../../interfaces/core.interface';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import Loader from '../Loader/Loader.component';
interface ModalWebProps {
	visible: boolean;
	Component: any;
	onNext: (value: ModalCallback<any>) => void;
	notDisplayModal: boolean;
	showLoader?: boolean;
}

const ModalWeb = ({
	visible,
	onNext,
	Component,
	notDisplayModal,
	showLoader,
	...restProps
}: ModalWebProps) => {
	const isMobile: boolean = useDevicePlatform() !== DeviceType.WEB;
	return (
		<Modal
			style={[
				styles.modalContainer,
				notDisplayModal
					? { position: 'absolute', margin: 0 }
					: undefined,
			]}
			isVisible={visible}
			onBackdropPress={() => onNext({ type: 'close' })}
		>
			{visible && (
				<>
					<View style={{ position: 'relative' }}>
						{Component && (
							<Component
								{...restProps}
								style={{ position: 'relative' }}
								onNext={onNext}
							/>
						)}
						<TouchableHighlight
							underlayColor={'transperent'}
							style={[
								styles.iconClose,
								{
									top: isMobile ? -37 : -27,
									left: isMobile ? -6 : -27,
								},
							]}
							onPress={() => onNext({ type: 'close' })}
						>
							<ImageIcon
								name={'closeWeb'}
								height={36}
								width={36}
							/>
						</TouchableHighlight>
						{showLoader && <Loader />}
					</View>
				</>
			)}
		</Modal>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		alignSelf: 'center',
		...getShadowStyle(1, '#000'),
	},
	iconClose: {
		position: 'absolute',
		overflow: 'visible',
	},
});

export default ModalWeb;
