export const spouseFields = {
	pageRoute: 'spouse',
	fields: [
		[
			{
				name: 'spouse.identityType',
				type: 'buttonsGroup',
				label: 'workerCard.editor.spouse.identityType',
				tooltip: 'workerCard.editor.spouse.id.tooltip',
				options: [
					{ label: 'general.id', value: 'ID' },
					{ label: 'general.passport', value: 'PASSPORT' },
				],
				isRequired: true,
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
					prevValue: any,
					trigger: Function,
				) => {
					trigger('spouse.idNumber');
				},
			},
			{
				name: 'spouse.idNumber',
				type: 'input',
				// label: 'workerCard.editor.spouse.id',
				// isRequired: true,
				fieldWrapperStyle: (isMobile: boolean) => ({
					marginRight: isMobile ? 0 : 0,
					marginTop: isMobile ? -30 : 0,
				}),
				shouldDisabled: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] !== 'MARRIED',
				}),
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
					prevValue: any,
					trigger: Function,
				) => {
					if (
						selectedValue &&
						getValues().spouse.identityType === 'ID'
					) {
						setTimeout(() => {
							trigger([
								...getValues().children.map(
									(child: any, index: number) =>
										child?.idNumber &&
										`children[${index}].idNumber`,
								),
							]);
						}, 500);
					}
				},
			},
		],
		[
			{
				name: 'spouse.firstName',
				type: 'input',
				label: 'workerCard.editor.spouse.firstName',
				tooltip: 'workerCard.editor.spouse.firstName.tooltip',
				isRequired: true,
				maxLength: 50,
				shouldDisabled: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] !== 'MARRIED',
				}),
			},
			{
				name: 'spouse.lastName',
				type: 'input',
				label: 'workerCard.editor.spouse.lastName',
				tooltip: 'workerCard.editor.spouse.lastName.tooltip',
				isRequired: true,
				maxLength: 50,
				shouldDisabled: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] !== 'MARRIED',
				}),
			},
			{
				name: 'spouse.birthDate',
				type: 'datepicker',
				maxDate: new Date(),
				label: 'workerCard.editor.spouse.birthDate',
				tooltip: 'workerCard.editor.spouse.birthDate.tooltip',
				placeholder: 'general.datePickerPlaceholder',
				isRequired: true,
				shouldDisabled: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] !== 'MARRIED',
				}),
			},
			{
				name: 'spouse.immigrationDate',
				type: 'datepicker',
				maxDate: new Date(),
				label: 'workerCard.editor.spouse.immigrationDate',
				tooltip: 'workerCard.editor.spouse.immigrationDate.tooltip',
				placeholder: 'general.datePickerPlaceholder',
				shouldDisabled: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] !== 'MARRIED',
				}),
				deleteable: true,
			},
		],
		[
			{
				name: 'spouse.hasIncome',
				label: 'workerCard.editor.spouse.hasIncome',
				tooltip: 'workerCard.editor.spouse.hasImcome.tooltip',
				type: 'buttonsGroup',
				options: [
					{ label: 'general.true', value: true },
					{ label: 'general.false', value: false },
				],
				isRequired: true,
				shouldDisabled: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] !== 'MARRIED',
				}),
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
				) => {
					!selectedValue && setValue('spouse.incomeType', []);
				},
			},
		],
		[
			{
				name: 'spouse.incomeType',
				label: 'workerCard.editor.spouse.incomeType',
				tooltip: 'workerCard.editor.spouse.incomeType.tooltip',
				options: [
					{
						label: 'workerCard.editor.spouse.incomeType.workAllowanceBusiness',
						value: 'WORK_ALLOWANCE_BUSINESS',
					},
					{
						label: 'workerCard.editor.spouse.incomeType.other',
						value: 'OTHER',
					},
				],
				type: 'multiButtonsGroup',
				isRequired: true,
				shouldDisabled: () => ({
					watch: ['employee.maritalStatus'],
					callback: (values: any) => values[0] !== 'MARRIED',
				}),
				shouldDisplay: () => ({
					watch: ['spouse.hasIncome'],
					callback: (values: any) => values[0],
				}),
			},
		],
	],
};

// const isRequired = function (
//     value: any
// ) {
//     console.log('this.from[this.from.length - 1].value?.employee?.maritalStatus', this.from,this.from[this.from.length - 1].value?.employee?.maritalStatus)
//     if (
//         this.from[this.from.length - 1].value?.employee?.maritalStatus === 'MARRIED'
//     ) {
//         if (!value || !value.length || value === '') {
//             return false;
//         }
//     }
//     return true;
// }

// const idSpouseDuplication = function (item?: string) {
// 	let id = item;
// 	id = String(id).trim();
// 	const dataValue = this?.from[1].value;

// 	if (
// 		dataValue.employee?.idNumber === id ||
// 		dataValue.children?.filter((child: any) => child.idNumber === item)
// 			?.length
// 	) {
// 		return false;
// 	}
// 	return true;
// };

// export const idValidation = function (item?: string) {

//     if (
//         this.from[this.from.length - 1].value?.employee?.maritalStatus !== 'MARRIED'
//     ) {
//         return true
//     }

// 	let id = item;
// 	id = String(id).trim();
// 	if (
// 		!['0', '2', '7', '3'].includes(id[0]) ||
// 		id.length > 9 ||
// 		isNaN(Number(id))
// 	) {
// 		return false;
// 	}
// 	id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
// 	return (
// 		Array.from(id, Number).reduce((counter, digit, i) => {
// 			const step = digit * ((i % 2) + 1);
// 			return counter + (step > 9 ? step - 9 : step);
// 		}) %
// 			10 ===
// 		0
// 	);
// };

// export const spouseValidation = yup.object().shape({
//     idNumber: yup
//     	.string()
//     	.test(
//     		'idTest',
//     		'general.validation.invalidValue',
//     		idValidation,
//     	)
//     	.test(
//     		'idSameSpouse',
//     		'general.validation.idAlreadyExists',
//     		idSpouseDuplication,
//     	)
//         .test('isRequired', 'general.validation.required', isRequired)
//     	.nullable(),
//     firstName: yup
//         .string()
//         .matches(alphaNumeriRegex, 'general.validation.invalidValue')
//         .test('isRequired', 'general.validation.required', isRequired)
//         .nullable(),
//     lastName: yup
//         .string()
//         .matches(alphaNumeriRegex, 'general.validation.invalidValue')
//         .test('isRequired', 'general.validation.required', isRequired)
//         .nullable(),
//     birthDate: yup
//         .string()
//         .test('isRequired', 'general.validation.required', isRequired)
//         .nullable(),
//     immigrationDate: yup.string().nullable(),
//     hasIncome: yup
//         .boolean()
//         .test('isRequiredHasIncome', 'general.validation.required', function (
//             value,
//         ) {
//             if (
//                 this.from && this.from[this.from.length - 1].value?.employee?.maritalStatus === 'MARRIED'
//             ) {
//                 if (value === undefined || value === null) {
//                     return false;
//                 }
//             }
//             return true;
//         })
//         .nullable(),
//     incomeType: yup
//         .array(yup.string())
//         .test('isRequired', 'general.validation.required', function (
//             value,
//         ) {
//             if (
//                 this.parent.hasIncome &&
//                 this.from[this.from.length - 1].value.employee
//                     .maritalStatus === 'MARRIED'
//             ) {
//                 if (!value || !value.length) {
//                     return false;
//                 }
//             }
//             return true;
//         })
//         .nullable(),
// })
