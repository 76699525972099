import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import ActionButtons from './ActionButtons/ActionButtons';


const UnautorisedModal = ({
	fieldLabel,
	fieldValue,
	height = 357,
	onNext,
	onSubmit,
	fromCommentPanel,
	isRequired,
	comment,
	readOnly,
}: {
	fieldLabel: string;
	fieldValue: string;
	children?: any;
	fromCommentPanel: any;
	onSubmit: Function;
	submitBtnText?: string;
	height?: number;
	onNext?: Function;
	isRequired?: boolean;
	inputValue?: any;
	comment?: string;
	cancel?: any;
	readOnly: boolean;
}) => {
	const patform = useDevicePlatform();
	const isMobile = patform !== DeviceType.WEB;
	const isMobileWeb = patform === DeviceType.MOBILE_WEB;

	const styles = StyleSheet.create({
		container: {
			paddingVertical: 35,
			paddingHorizontal: 35,
			flexDirection: 'column',
			shadowColor: 'rgba(0, 0, 0, 0)',
			shadowOpacity: 0.1,
			shadowOffset: { width: 0, height: 2 },
			backgroundColor: colors.white,
			borderRadius: 20,
		},
		text: {
			color: colors.darkGrey
		},
		icon: {
			alignItems: 'center',
			justifyContent: 'center',
		},
		body: {
			marginTop: 20,
			alignItems: 'center',
			justifyContent: 'center',
		},
		actionButtons: {
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 48,
		},
		buttonOk: {
			width: 92,
			height: 40,
			justifyContent: 'center',
			backgroundColor: colors.lightPrimary,
		},
	});

	const containerS = StyleSheet.flatten([
		{
			minWidth: 335,
			width: isMobile ? '90%' : isMobileWeb ? '100%' : 335,
			height: isMobile ? height : 'auto',
		},
		styles.container,
	]);

	console.log('onNext', onNext)

	const _onSubmit = () => {
		onSubmit(onNext);
	}

	return (
		<View style={containerS}>
			<View style={styles.icon}>
				<ImageIcon name='attention' width={100} height={100} />
			</View>
			<View style={styles.body}>
				<I18n size={16} weight='normal' style={styles.text}>
					modal.unautorisedModal.message
				</I18n>
			</View>
			<View style={styles.actionButtons}>
				<SolidButtonContainer
					overrideStyle={styles.buttonOk}
					onPress={() => _onSubmit()}
				>
					<I18n size={16} weight='normal' color='white'>
						general.closing
					</I18n>
				</SolidButtonContainer>
			</View>
		</View>
	);
};

export default UnautorisedModal;
