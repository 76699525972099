import { debounce } from 'lodash';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import colors from '../../../config/colors';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { FilterData } from '../../../components/web/Filters/Filters.container';
import {
	FullAttendanceReportList,
	FullAttendanceReportListLike,
} from '../../../models/fullAttendanceReportList.model';
import httpService from '../../../services/http.service';
import { getUserData } from '../../../store/selectors/login.selectors';

import { dispatch } from '../../../store/store';
import {
	filter,
	incrementPage,
	sort,
	updateBusinessUnits,
	updateDepartment,
} from './AttendanceReportPage.actions';
import reducer, {
	AttendanceReportsRequest,
	fetchReportLastDate,
	initialState,
	Action,
} from './AttendanceReportPage.reducer';
import {
	AttendanceFiltersDataType,
	attendancePageFilterSize,
	AttendanceReportDataType,
	attendanceReportHeaders,
	AttendanceReportsFilterTypes,
	createFilterOptionsForTable,
	initAttendanceFilters,
	initAttendanceListReport,
	PAGINATION_PAGES_VISIBLE,
} from './AttendanceReportPage.utils';

import AttendanceReportPage from './AttendanceReportPage.component';
import { generatePdfName } from '../../../utils/generatePdfName.utlis';
import { getDepartmentsFilterList } from '../../../store/selectors/reportsFilters.selectors';
import { downloadFile } from '../../../utils/downloadFile.web.utils';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
} from '../../../store/actions/reportsFilters.actions';
import { useRoute } from '@react-navigation/native';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
} from '../../../store/actions/config.actions';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';

const AttendanceReportPageContainer = ({ navigation }: { navigation: any }) => {
	const [attendanceListReport, setAttendanceListReport] =
		useState<FullAttendanceReportListLike>(initAttendanceListReport);
	const [attendanceFilters, setAttendanceFilters] =
		useState<AttendanceFiltersDataType>(initAttendanceFilters);

	const [fileUrl, setFileUrl] = useState(); //PDF
	const [selectedAttendanceReport, setSelectedAttendanceReport] =
		useState<AttendanceReportDataType | null>(null);
	const userData = useSelector(getUserData);

	const [isRowSelected, setIsRowSelected] = useState<boolean>(false);

	const [tableState, tableDispatch] = useReducer<
		React.Reducer<AttendanceReportsRequest, Action>
	>(reducer, initialState);
	const [componentInit, setComponentInit] = useState<boolean>(false);
	const [selectedDepartments, setSelectedDepartments] = useState<any>({
		dep: [],
	});

	const [reportNotFound, setReportNotFound] = useState<boolean>(false);

	const [currentPageselected, setCurrentPageselected] = useState<number>(0);
	const route = useRoute() as any;
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const isLoadLast = useRef<boolean>();
	const counter = useRef<any[]>([]);

	const handleFilter = useCallback((data: FilterData) => {
		if (data.name === 'unitIds') {
			tableDispatch(updateBusinessUnits(data));
			return;
		}
		setSelectedAttendanceReport(null);
		tableDispatch(
			filter(
				data.name as AttendanceReportsFilterTypes,
				data.value,
				data.isMulti,
			),
		);
		if (data.name === 'department') {
			setSelectedDepartments({
				dep: data.value.map((el: any) => el.id),
			});
			tableDispatch(updateDepartment());
		}
	}, []);

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.attendanceReports.privileges,
		onChangeFilter: handleFilter,
	});

	useEffect(() => {
		tableDispatch({ type: 'RESET_FILTERS', payload: null });
		dispatch(incrementBusyIndicator());
		counter.current.push(1);

		if (route.params?.screen === 'details') {
			setIsRowSelected(true);
		}
		setSelectedDepartments({
			dep: [],
		});
	}, [userData]);

	useEffect(() => {
		if (tableState !== initialState && isLoadLast.current) {
			debouncedUpdateData(tableState);
			setComponentInit(true);
		}
	}, [tableState]);

	const updateIsLoadlast = () => {
		isLoadLast.current = true;
	};

	useEffect(() => {
		if (tableState.filter.unitIds.length || multiValue) {
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(tableState.filter.unitIds),
				),
			);
			loadAttendanceListYearsFilter();
			isLoadLast.current = false;
			fetchReportLastDate(
				tableDispatch,
				getSelectedUnits(tableState.filter.unitIds),
				updateIsLoadlast,
			);
		}
	}, [tableState.filter.unitIds]);

	useEffect(() => {
		setAttendanceFilters(state => ({
			...state,
			department: departmentsFilterList,
		}));
	}, [departmentsFilterList]);

	useEffect(() => {
		const pageNumber = pageNumberCalc();
		setCurrentPageselected(pageNumber);
	}, [selectedAttendanceReport]);

	const disabledLoadMore = useMemo(
		() => attendanceListReport.hasNext,
		[attendanceListReport, tableState.page],
	);

	const onLoadMore = () => {
		if (disabledLoadMore) {
			tableDispatch(incrementPage());
		}
	};

	const debouncedUpdateData = useCallback(
		debounce((data: AttendanceReportsRequest) => {
			loadAttendanceList(data);
		}, 500),
		[],
	);

	const loadAttendanceList = ({
		page,
		pageSize,
		sortBy,
		sortDirection,
		filter,
	}: AttendanceReportsRequest) => {
		httpService
			.api<FullAttendanceReportListLike>({
				type: 'getAttendanceReportList',
				data: {
					p: page,
					ps: pageSize,
					s: sortBy === 'businessUnitName' ? 'id' : sortBy,
					sd: sortDirection,
					f: {
						...filter,
						unitIds: getSelectedUnits(filter?.unitIds),
					},
				},
			})
			.then(async (res: any) => {
				if (res) {
					const reqData = res.data.map(
						(fw: FullAttendanceReportList) =>
							new FullAttendanceReportList(fw),
					);
					if (!res.data[0] && page === 0) {
						setIsRowSelected(false);
						setSelectedAttendanceReport(null);
						setReportNotFound(true);
					} else setReportNotFound(false);
					setAttendanceListReport(state => ({
						hasNext: res.data.length < pageSize ? false : true,
						total: res.total,
						data: [...(page === 0 ? [] : state.data), ...reqData],
					}));
					if (route.params?.screen === 'details' && page === 0) {
						setSelectedAttendanceReport(reqData[0]);
					}

					setSelectedAttendanceReport(reqData[0]);
				}
			})
			.catch(err => setAttendanceListReport(initAttendanceListReport))
			.finally(() => {
				counter.current.forEach(() =>
					dispatch(decrementBusyIndicator()),
				);
				counter.current = [];
			});
	};

	const loadAttendanceListYearsFilter = () => {
		httpService
			.api<number[]>({
				type: 'getAttendanceReportListYearsFilter',
				query: {
					unitIds: getSelectedUnits(tableState.filter.unitIds).join(),
				},
			})
			.then(async (res: any) => {
				if (res) {
					setAttendanceFilters(state => ({ ...state, year: res }));
				}
			})
			.catch(err =>
				setAttendanceFilters({ ...attendanceFilters, year: [] }),
			);
	};

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: attendancePageFilterSize,
			search,
			filter: selectedDepartments,
			unitIds: getSelectedUnits(tableState.filter.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: attendancePageFilterSize === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const pageNumberCalc = () => {
		if (
			isRowSelected &&
			attendanceListReport.data.length &&
			selectedAttendanceReport
		) {
			const indexNumber = attendanceListReport.data.findIndex(current => {
				return (
					current.teudatZeut ===
						selectedAttendanceReport.teudatZeut &&
					current.fullName === selectedAttendanceReport.fullName &&
					current.departmentName ===
						selectedAttendanceReport.departmentName &&
					current.salaryMonth === selectedAttendanceReport.salaryMonth
				);
			});
			return indexNumber + 1;
		}
		return 1;
	};

	const handleSorting = useCallback((field: any, direction: any) => {
		tableDispatch(sort(field, direction));
	}, []);

	const selectedRowHandler = (rowData: AttendanceReportDataType) => {
		setSelectedAttendanceReport(rowData);
		setIsRowSelected(true);
		navigation.navigate('attendanceReports', { screen: 'details' });
	};

	const navigateBack = () => {
		setSelectedAttendanceReport(null);
		setIsRowSelected(false);
		navigation.navigate('attendanceReports', { screen: 'table' });
	};

	const handlePaginationChange = (currentPage: number) => {
		const total = attendanceListReport.data.length;
		const hasNext = attendanceListReport.hasNext;
		const visible =
			length < PAGINATION_PAGES_VISIBLE
				? length
				: PAGINATION_PAGES_VISIBLE;

		if (currentPage <= total) {
			const selectedRow = attendanceListReport.data[currentPage - 1];
			setSelectedAttendanceReport(selectedRow);
		} else if (hasNext && total - currentPage < visible + 1) {
			const selectedRow = attendanceListReport.data[currentPage - 1];
			setSelectedAttendanceReport(selectedRow);
			tableDispatch(incrementPage());
		}
	};

	const downloadPDF = () => {
		const fileName = generatePdfName(fileUrl, 'months');
		downloadFile(fileUrl, fileName);
	};

	return (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<AttendanceReportPage
				navigation={navigation}
				data={attendanceListReport.data}
				reportNotFound={reportNotFound}
				headers={attendanceReportHeaders(businessUnitsList)}
				filtersData={createFilterOptionsForTable(
					attendanceFilters,
					getEmployeesFilter,
					selectedDepartments,
					`${JSON.stringify(userData)}${JSON.stringify(
						tableState.filter.unitIds,
					)}`,
					tableState,
					businessUnitsList,
					isBusinessUnitsMulti,
				)}
				onSort={handleSorting}
				onLoadMore={onLoadMore}
				onFilter={handleFilter}
				onRowClick={data => selectedRowHandler(data)}
				isRowSelected={isRowSelected}
				selectedRowData={selectedAttendanceReport}
				navigateBack={navigateBack}
				pageCurrent={currentPageselected}
				pageTotal={attendanceListReport.total}
				onPaginationChange={handlePaginationChange}
				downloadPDF={downloadPDF}
				filePDF={fileUrl}
				setFilePDF={setFileUrl}
			/>
		</View>
	);
};

export default AttendanceReportPageContainer;
