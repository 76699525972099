import React from 'react';
import Modal from './Modal.component';

const ModalContainer = (props: any) => {
	const ComponentName = props?.component;

	return <Modal {...props} Component={ComponentName} />;
};

export default ModalContainer;
