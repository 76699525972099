import React, { useEffect } from 'react';
import { View, StyleSheet, ScrollView, Dimensions } from 'react-native';
import colors from '../../../config/colors';
import CreateNewRequest from './CreateNewRequest';
import EditRequest from './EditRequest';

interface RequestWrapperProps {
  requestId?: string,
  onNext: any,
  validationDate: number,
  onrefresh: () => void
}

const windowHeight = Dimensions.get('window').height;

const RequestWrapper = ({ requestId, onNext, validationDate, onrefresh }: RequestWrapperProps) => {

  return (
    <View style={[styles.container, { height: windowHeight > 720 ? 720 : windowHeight - 50 }]}>
      <ScrollView
        style={{ zIndex: -1 }}
        centerContent={true}
        nativeID='#LocationListContainer'
        contentContainerStyle={{ paddingBottom: 30, paddingHorizontal: 20 }}
      >
        {!!requestId ? <EditRequest
          requestId={requestId}
          onNext={() => { onrefresh(); onNext(onrefresh); }}
          onrefresh={onrefresh}
          validationDate={validationDate}
        />
          : <CreateNewRequest
            onNext={onNext}
            onrefresh={onrefresh}
            validationDate={validationDate}
          />
        }
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 408,
    shadowColor: 'rgba(0, 0, 0, 0)',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    backgroundColor: colors.white,
    borderRadius: 20,
    paddingVertical: 39,
    paddingHorizontal: 20,
  }
});

export default RequestWrapper;
