import React from 'react';
import { TouchableHighlight, View, StyleSheet } from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import WebCheckBox from 'expo-checkbox';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import getShadowStyle from '../../config/shadow';
import httpService from '../../services/http.service';
import { i18nService } from '../../services/i18n.service';
import { EmplooyeeRequestsData } from './EmplooyeeRequests.container';
import RequestDetails from './RequestDetails/RequestDetails';
import { modalService } from '../../services/modal.service';
import { Privileges } from '../../constants/roleTypes.constant';
import { Tooltip } from '@material-ui/core';
import { hasPermissionOnBusinessUnit } from '../../utils/globals.utils';

export const downloadFile = async (request: EmplooyeeRequestsData) => {
	try {
		const res: any = await httpService.api({
			type: 'getEmployeeRequestsAttachments',
			params: { requestId: request.id },
			responseType: 'blob',
		});

		let elm = document.createElement('a');
		elm.href = URL.createObjectURL(res);
		elm.setAttribute(
			'download',
			`${request.teudatZeut}_${request.dateRange}.zip`,
		);
		elm.click();
	} catch (e) {}
};

export const CheckBox = ({ value, onValueChange }) => {
	return (
		<WebCheckBox
			value={value}
			onValueChange={onValueChange}
			color={colors.primary}
			style={{
				width: 15,
				height: 15,
				borderRadius: 4,
				marginLeft: 10,
			}}
		/>
	);
};

export const DownloadSection = ({ data }: { data: EmplooyeeRequestsData }) => {
	return (
		<View>
			{data.attachedFiles > 0 ? (
				<div onClick={async (e: any) => downloadFile(data)}>
					<ImageIcon name='downloadPaycheck' height={20} width={20} />
				</div>
			) : (
				<div />
			)}
		</View>
	);
};

export const EditButton = ({
	data,
	onRefresh,
	validationDate,
}: {
	data: EmplooyeeRequestsData;
	validationDate: number;
	onRefresh: () => void;
}) => {
	const vacationPermition =
		hasPermissionOnBusinessUnit(
			data.businessUnitId,
			Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
		) &&
		data.type ===
			i18nService.translate(
				'applicationsToEmployerContainer.vacationRequest',
			);
	const siknessPermition =
		hasPermissionOnBusinessUnit(
			data.businessUnitId,
			Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
		) &&
		data.type ===
			i18nService.translate(
				'applicationsToEmployerContainer.sicknessReport',
			);

	const onEdit = async () => {
		await modalService.openModal(null, {}, (props: any) => (
			<RequestDetails
				requestId={data.id}
				{...props}
				onRefresh={onRefresh}
				validationDate={validationDate}
				businessUnitId={data.businessUnitId}
				businessUnitName={data.businessUnitName}
			/>
		));
	};

	return (
		<TouchableHighlight
			underlayColor='transparent'
			disabled={!vacationPermition && !siknessPermition}
			style={{
				opacity: !vacationPermition && !siknessPermition ? 0.2 : 1,
			}}
			onPress={() => onEdit()}
		>
			<div>
				<ImageIcon
					name='edit'
					height={20}
					width={20}
					style={{ margin: 10 }}
				/>
			</div>
		</TouchableHighlight>
	);
};

const statusColorMatcher = {
	[i18nService.translate('applicationsToEmployerContainer.status.APPROVED')]:
		{
			color: 'green',
		},
	[i18nService.translate('applicationsToEmployerContainer.status.PENDING')]: {
		color: 'blue',
	},
	[i18nService.translate('applicationsToEmployerContainer.status.DECLINED')]:
		{
			color: 'orange',
		},
	[i18nService.translate('applicationsToEmployerContainer.status.CANCELED')]:
		{
			color: 'gray',
		},
};

export const StatusSection = ({
	status,
	style,
	isMobile,
	onleyDot,
}: {
	status: string;
	style?: any;
	isMobile?: boolean;
	onleyDot?: boolean;
}) => {
	return (
		<View style={[styles.statusSection, style && { ...style }]}>
			<View
				style={[
					styles.statusDot,
					{ backgroundColor: statusColorMatcher[status].color },
				]}
			/>
			{!onleyDot && (
				<View style={{ width: isMobile ? 60 : undefined }}>
					<I18n size={14} weight={isMobile ? 'normal' : undefined}>
						{status}
					</I18n>
				</View>
			)}
		</View>
	);
};

export const DescroptionText = ({ value }: { value: string }) => {
	return (
		<View style={{ maxWidth: 200 }}>
			<Tooltip title={value} placement='top-start'>
				<Typography size={14} numberOfLines={2}>
					{value}
				</Typography>
			</Tooltip>
		</View>
	);
};

export const StatusOptions = [
	{ id: 'PENDING', name: i18nService.translate('general.PENDING') },
	{ id: 'APPROVED', name: i18nService.translate('general.APPROVED') },
	{ id: 'REJECTED', name: i18nService.translate('general.REJECTED') },
	{ id: 'CANCELED', name: i18nService.translate('general.CANCELED') },
];

export const typeOptions = (vacation, sikness) => {
	let options = [];
	if (vacation) {
		options.push({
			id: 'VACATION',
			name: i18nService.translate(
				'applicationsToEmployerContainer.vacationRequest',
			),
		});
	}
	if (sikness) {
		options.push({
			id: 'SICKNESS',
			name: i18nService.translate(
				'applicationsToEmployerContainer.sicknessReport',
			),
		});
	}
	return options;
};

export const ApproveAllButton = ({
	onStartSelect,
	disabled,
}: {
	onStartSelect(): void;
	disabled?: boolean;
}) => {
	return (
		<TouchableHighlight
			underlayColor='transparent'
			disabled={disabled}
			style={{ opacity: disabled ? 0.7 : 1 }}
			onPress={onStartSelect}
		>
			<View style={styles.approveAllButton}>
				<Typography
					style={{ marginLeft: 10, marginRight: 10 }}
					color={'white'}
					weight='400'
					size={14}
				>
					{i18nService.translate('employeeRequests.approveAll')}
				</Typography>
			</View>
		</TouchableHighlight>
	);
};

export const approveMultiple = async (requestIds, refresh) => {
	try {
		await httpService.api({
			type: 'approveMultipleRequests',
			data: {
				ids: requestIds,
			},
		});
		refresh();
	} catch (e) {}
};

export const ApproveButtons = ({
	onApprove,
	onCancel,
	disabled,
	style,
}: {
	onApprove(): void;
	onCancel(): void;
	disabled?: boolean;
	style?: any;
}) => {
	return (
		<View style={[styles.buttonContainer, style]}>
			<TouchableHighlight
				onPress={onApprove}
				underlayColor='transparent'
				disabled={disabled}
			>
				<View style={styles.button}>
					<I18n size={16} weight='normal' color='white'>
						employeeRequests.approve
					</I18n>
				</View>
			</TouchableHighlight>

			<TouchableHighlight
				onPress={onCancel}
				underlayColor='transparent'
				disabled={disabled}
			>
				<View style={styles.cancelButton}>
					<I18n size={16} weight='normal' color='lightPrimary'>
						employeeRequests.cancel
					</I18n>
				</View>
			</TouchableHighlight>
		</View>
	);
};

export const CommentModal = ({ text }) => {
	const onShow = async (requestId?: number) => {
		await modalService.openModal(null, {}, (props: any) => (
			<View style={styles.commentContainer}>
				<View
					style={{
						width: 300,
						paddingHorizontal: 20,
						paddingVertical: 10,
						backgroundColor: colors.lightBlue,
						borderRadius: 5,
					}}
				>
					<I18n size={16} weight='normal'>
						{text}
					</I18n>
				</View>
			</View>
		));
	};
	return (
		<TouchableHighlight onPress={onShow} underlayColor='transparent'>
			<View style={styles.commentIconContainer}>
				<ImageIcon name={'message'} width={12} height={12} />
			</View>
		</TouchableHighlight>
	);
};

const styles = StyleSheet.create({
	statusSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	commentIconContainer: {
		backgroundColor: colors.lightPrimary,
		height: 18,
		width: 18,
		borderRadius: 9,
		alignItems: 'center',
		justifyContent: 'center',
		margin: 5,
		paddingTop: 2,
	},
	commentContainer: {
		paddingVertical: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		minHeight: 239,
		width: 334,
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	statusDot: {
		width: 10,
		height: 10,
		borderRadius: 5,
		marginLeft: 5,
	},
	textCell: {
		minWidth: 50,
	},
	approveAllButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 141,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
		width: 350,
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	cancelButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: 'white',
		borderRadius: 45,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	sendNewRequestContainer: {
		paddingVertical: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		height: 239,
		width: 334,
		alignItems: 'center',
		justifyContent: 'space-around',
	},
});
