import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Platform } from 'react-native';
import _, { compact, debounce } from 'lodash';

import Documents from './Documents.component';
import { StatusOptions } from './Documents.utils';
import { getRequestFilterState } from '../../store/selectors/reportsFilters.selectors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import {
	getBussinesUnitsModules,
	getUserData,
} from '../../store/selectors/login.selectors';
import { fetchEmployeesFilterList } from '../../store/actions/reportsFilters.actions';
import { updateRequestsFilters } from '../../store/actions/reportsFilters.actions';
import routes from '../../config/routes';
import useBusinessUnitsState from '../../hooks/useBusinessUnitsState';
import httpService from '../../services/http.service';
import { Privileges } from '../../constants/roleTypes.constant';
import { getEmployerRolesPrivileges } from '../../store/selectors/login.selectors';
import moment from 'moment';
import useEffectNotInitial from '../../hooks/useEffectNotInitial';
import { i18nService } from '../../services/i18n.service';

export interface DocumentsData {
	id: number;
	lastUpdate: string;
	fullName?: string;
	teudatZeut?: string;
	type: string;
	fileName: string;
	status: string;
	clientStatus: string;
	businessUnitName: Number;
	businessUnitId: number;
	unitId: number;
	userId: number;
	identityType: string;
}

const DocumentsContainer = ({
	navigation,
	route,
	isEmployer,
}: {
	navigation: any;
	route: any;
	isEmployer: boolean;
}) => {
	const sevedFilters = useSelector(getRequestFilterState);

	const userData = useSelector(getUserData);

	const platform = useDevicePlatform();
	const isWeb = platform === DeviceType.WEB;
	const isMobile =
		platform === DeviceType.MOBILE || platform === DeviceType.MOBILE_WEB;

	const defaultFilters = {
		user_id: [],
		doc_name: [],
		status: [],
		unitIds: [],
	};
	const [requestsData, setRequestsData] = useState<DocumentsData[]>([]);
	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [total, setTotal] = useState(0);
	const [order, setOrder] = useState('DESC');
	const [sortByDocs, setSortByDocs] = useState('lastUpdate');
	const defaultSort = 'lastUpdate';
	const defaultOrder = 'DESC';
	const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
	const [filters, setFilters] = useState(defaultFilters);
	const [removeDoc, setRemoveDoc] = useState(false);
	const [filtesSelected, setFiltesSelected] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectRequestsFilters, setSelectRequestsFilters] = useState(null);
	const searchTextRef = useRef();
	const tempFiltersRef = useRef({});
	const tempSearchRef = useRef({});
	tempFiltersRef.current = {};
	tempSearchRef.current = '';

	const dispatch = useDispatch();
	const docPermission = useSelector(getEmployerRolesPrivileges)?.includes(
		Privileges.ROLE_MNG_DOCS_WRITE,
	);
	const modules = useSelector(getBussinesUnitsModules);

	const onFilter = (filterData: any) => {
		if (filterData.name === 'search') {
			searchTextRef.current = filterData.value || null;

			dispatch(
				updateRequestsFilters({
					requestsFilters: { ...selectedFilters },
					requestsSearch: filterData.value,
				}),
			);
			getRequestsData(true, sortByDocs, order, filters, filterData.value);
		} else {
			let requestsFilters: any;
			if (filterData.name === 'unitIds') {
				requestsFilters = {
					...selectedFilters,
					unitIds: filterData.isMulti
						? filterData.value.map((val: any) => val.id || val)
						: [filterData.value],
					user_id: [],
					status: [],
				};
			} else if (filterData.name === 'status') {
				requestsFilters = {
					...selectedFilters,
					status: filterData.value.map((el: any) => el?.id),
				};
			} else if (filterData.name === 'employees-docs') {
				requestsFilters = {
					...selectedFilters,
					user_id: filterData.value.map((el: any) => el?.id),
				};
			}
			setSelectedFilters(prev => ({
				...prev,
				...requestsFilters,
			}));
			dispatch(
				updateRequestsFilters({
					requestsFilters,
					requestsSearch: searchTextRef.current,
				}),
			);
			const filter = handleFilter(filterData);
			setFilters({ ...filter });
		}
	};

	const { businessUnitsList, isBusinessUnitsMulti, getSelectedUnits } =
		useBusinessUnitsState({
			privileges: isEmployer
				? routes.documentsManagment.privileges
				: routes.documents.privileges,
			onChangeFilter: onFilter,
		});

	const has114Module = useMemo(() => {
		if (modules) {
			const module114 = getSelectedUnits(filters?.unitIds).find(
				(unit: any) =>
					modules[unit]?.find(
						(module: any) =>
							module.id === 114 && module?.status === 'ACTIVE',
					),
			);

			if (module114) {
				return true;
			}
			return false;
		}

		return true;
	}, [modules, filters?.unitIds]);
	const isDisabled = docPermission && has114Module ? false : true;

	useEffect(() => {
		setIsLoading(true);
	}, []);

	useEffectNotInitial(() => {
		const fil =
			isWeb || Object.keys(sevedFilters.requestsFilters)?.length === 0
				? filters || defaultFilters
				: handelFiltersForMobile(sevedFilters.requestsFilters);

		let ser = '';
		if (!(isWeb || !sevedFilters.requestsSearch)) {
			searchTextRef.current = sevedFilters.requestsSearch;
			ser = sevedFilters.requestsSearch;
		}

		debouncedGetMoreData(true, defaultSort, defaultOrder, fil, ser);
		onMount();
	}, [filters.unitIds, filters.status, filters.user_id, isEmployer]);

	useEffect(() => {
		if (removeDoc) {
			debouncedGetMoreData(
				true,
				defaultSort,
				defaultOrder,
				filters,
				searchTextRef.current,
			);
			setRemoveDoc(false);
		}
	}, [removeDoc]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('EmplooyeeRequests');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};
	const debouncedGetMoreData = debounce((...args) => {
		getRequestsData(...args);
	}, 300);

	const getManagerDocsFilter = async (
		search: string,
		loadOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const tempStatus = {
			unitIds: getSelectedUnits(filters?.unitIds),
		};
		const ps = 20;
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: ps,
			search,
			filter: tempStatus,
			unitIds: getSelectedUnits(filters?.unitIds),
		});
		const list = res?.data?.map((el: any) => {
			return {
				value: el.employeeId,
				label: el.name + ` (${el.teudatZeut})`,
			};
		});

		return {
			options: list,
			total: res?.total,
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	// const getManagerDocsFilter = async (
	// 	search: string,
	// 	loadedOptions: null,
	// 	{ page }: { page: number },
	// ) => {
	// 	const tempStatus = {
	// 		unitIds: getSelectedUnits(filters?.unitIds),
	// 	};
	// 	const ps = 20;
	// 	const res = await httpService.api<any>({
	// 		type: 'getManagerDocsFilter',
	// 		data: {
	// 			p: page,
	// 			ps: ps,
	// 			f: tempStatus,
	// 			search:
	// 				typeof search === 'string' ? search : searchTextRef.current,
	// 		},
	// 	});

	// return {
	// 	options: res?.data?.map((el: any) => {
	// 		return {
	// 			value: el.id,
	// 			label: el.fullName + ` (${el.teudatZeut})`,
	// 		};
	// 	}),
	// 	hasMore: ps === res?.data?.length,
	// 	additional: {
	// 		page: page + 1,
	// 	},
	// };
	// };

	const filtersConfig = useMemo(() => {
		return compact([
			{
				key: `docs-${filters.unitIds}-status`,
				name: 'status',
				options: StatusOptions,
				placeholder: 'status',
				styles: { width: 180, control: { maxWidth: 180 } },
				type: 'multiselect',
			},
			isWeb && {
				key: `docs-${filters.unitIds}-employees`,
				name: 'employees-docs',
				type: 'asyncmultiselect',
				placeholder: 'usersManagement.employees',
				loadOptions: getManagerDocsFilter,
				alwaysRerender: true,
				styles: {
					width: 310,
				},
			},
			{
				name: 'search',
				options: [],
				type: 'searchInput',
				placeholder: isMobile
					? 'search'
					: i18nService.translate('documents.filters.docName'),
				minChars: 3,
			},
			businessUnitsList?.length > 1
				? isBusinessUnitsMulti
					? {
							name: 'unitIds',
							type: 'multiselect',
							placeholder: 'businessUnits',
							noSpecialAll: true,
							value: filters.unitIds?.map((unit: any) =>
								businessUnitsList.find(
									(bunit: any) => bunit.id === unit,
								),
							),
							options: businessUnitsList,
							styles: {
								control: {
									width: 200,
									maxWidth: 200,
								},
							},
					  }
					: {
							name: 'unitIds',
							type: 'select',
							placeholder: 'businessUnits',
							defaultValue: filters.unitIds?.[0],
							options: businessUnitsList,
							hideDefaultOption: true,
							styles: {
								width: 150,
							},
					  }
				: undefined,
		]);
	}, [isWeb, userData, businessUnitsList, filters.unitIds, filters.status]);

	const handleFilter = (data: any, filtersData?: any) => {
		let fl = filtersData ? { ...filtersData } : { ...filters };
		setFiltesSelected(true);
		if (data.name === 'status') {
			fl = { ...fl, status: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'employees-docs') {
			fl = { ...fl, user_id: data.value.map((el: any) => el.id) };
		}
		if (data.name === 'unitIds') {
			fl = {
				unitIds: data.isMulti
					? data.value
							.filter((val: any) => val.id !== 'all')
							.map((val: any) => val.id || val)
					: [data.value],
				user_id: [],
				status: [],
			};
		}
		if (fl?.status?.length === 0 && fl?.employees?.length === 0) {
			setFiltesSelected(false);
		}
		return fl;
	};

	const handelFiltersForMobile = data => {
		setSelectedFilters(data);
		let temp = {};

		if (data.hasOwnProperty('status')) {
			temp = { ...temp, status: data['status'] };
		}
		if (data.hasOwnProperty('unitIds')) {
			temp = { ...temp, unitIds: data['unitIds'] };
		}
		if (data?.status?.length > 0 || data?.unitIds?.length > 0) {
			setFiltesSelected(true);
		}

		setFilters(prev => ({ ...prev, ...temp }));
		return temp;
	};

	const getRequestsData = async (
		first: boolean,
		newSortBy?: string,
		neworder?: string,
		filterData?: any,
		search?: string,
	) => {
		if (
			(!filters?.unitIds.length &&
				!filtesSelected &&
				isEmployer &&
				isBusinessUnitsMulti) ||
			(_.isEqual(_.omit(filterData), _.omit(tempFiltersRef.current)) &&
				tempSearchRef.current === search &&
				isEmployer &&
				isBusinessUnitsMulti)
		) {
			return;
		}
		tempFiltersRef.current = filterData || filters;
		tempSearchRef.current = search || searchTextRef.current;
		try {
			const tempFilter = filterData || filters;
			const ps = isMobile ? 15 : 40;
			const searchValue =
				typeof search === 'string' ? search : searchTextRef.current;
			const res = await httpService.api<any>({
				type: isEmployer ? 'getManagerDocs' : 'getDocs',
				data: isEmployer
					? {
							p: first ? 0 : nextPage,
							ps: ps,
							s: newSortBy ? newSortBy : sortByDocs,
							sd: neworder ? neworder : order,
							f: {
								...tempFilter,
								unitIds: getSelectedUnits(tempFilter?.unitIds),
								doc_name: isMobile
									? []
									: [searchTextRef.current],
							},
							search: isMobile ? searchValue : '',
					  }
					: null,
			});
			setTotal(res.total);
			const firstData = isEmployer
				? res.data
				: res.filter((res: any) => res.statusCode !== 'ABANDONED');
			const data = firstData.map(({ statusCode, ...rest }: any) => ({
				status: statusCode,
				...rest,
			}));

			if (res) {
				setDisableLoad(data?.length < ps);
				if (data.length !== 0) {
					if (first) {
						setRequestsData(data);
						setNextPage(1);
					} else {
						setRequestsData([...requestsData, ...data]);
						setNextPage(nextPage + 1);
					}
				} else {
					if (first) {
						setRequestsData([]);
					} else {
						setDisableLoad(true);
					}
				}
				setIsLoading(false);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	};
	const localSort = (newSort: string, newOrder: string) => {
		const tempRequestsData = [...requestsData];
		tempRequestsData.sort((a, b) => {
			const formattedA: any = moment(a[newSort], 'DD-MM-YYYY').toDate();
			const formattedB: any = moment(b[newSort], 'DD-MM-YYYY').toDate();

			return newOrder === 'ASC'
				? formattedA - formattedB
				: formattedB - formattedA;
		});
		setRequestsData([...tempRequestsData]);
	};

	const onSort = (newSort: string, order: string) => {
		setOrder(order);
		setSortByDocs(newSort);
		isEmployer
			? getRequestsData(true, newSort, order, filters)
			: localSort(newSort, order);
	};

	return (
		<Documents
			navigation={navigation}
			data={requestsData}
			onSort={onSort}
			onLoadMore={() =>
				disabledLoad
					? null
					: debouncedGetMoreData(
							false,
							sortByDocs,
							order,
							selectRequestsFilters
								? selectRequestsFilters
								: filters,
					  )
			}
			onFilter={onFilter}
			isEmployer={isEmployer}
			filters={filters}
			selectedFilters={selectedFilters}
			filtersConfig={filtersConfig}
			filtesSelected={filtesSelected}
			disabled={isDisabled}
			businessUnitsList={businessUnitsList}
			setRemoveDoc={setRemoveDoc}
			modules={modules}
			total={total}
			isLoading={isLoading}
		/>
	);
};

export default React.memo(DocumentsContainer);
