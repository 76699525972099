import React from 'react';
import moment from 'moment';
import { i18nService } from '../../../../services/i18n.service';
import { FilterData } from '../../../../components/web/Filters/Filters.container';
import { compact } from 'lodash';
import { EnterCustomerComponenet } from '../RepReports.utils';

const newDate = new Date();
export const defaultFilters = {
	unitIds: [],
	year: [
		Number(moment(newDate).month().toString()) === 0
			? Number(moment(newDate).subtract(1, 'year').year().toString())
			: Number(moment(newDate).year().toString()),
	],
	repCustomerIds: [],
	repCustomerUnitIds: [],
	operatorIds: [],
	status: [],
};

export const statusOptions = [
	{
		id: false,
		name: i18nService.translate(
			'repReports.for106eports.status.NO_PAYCHECKS',
		),
	},
	{
		id: true,
		name: i18nService.translate(
			'repReports.form106eports.status.PAYCHECKS',
		),
	},
];

export const getHeaders = (canEnterCustomer: boolean, routeName: string) => {
	return compact([
		{
			accessor: 'year',
			Header: i18nService.translate(
				'repReports.general.tableHeader.year',
			),
		},
		{
			accessor: 'repCustomer',
			Header: i18nService.translate(
				'repReports.general.tableHeader.repCustomer',
			),
			isSortable: true,
		},
		{
			accessor: 'hp',
			Header: i18nService.translate('repReports.general.tableHeader.hp'),
			isSortable: true,
		},
		{
			accessor: 'businessUnitName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.businessUnitName',
			),
			isSortable: true,
		},
		{
			accessor: 'operatorName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.operatorName',
			),
			isSortable: true,
		},
		{
			accessor: 'value',
			Header: i18nService.translate(
				'repReports.for106eports.tableHeader.value',
			),
			isSortable: true,
		},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate(
				'repReports.general.tableHeader.lastUpdate',
			),
			isSortable: true,
		},
		canEnterCustomer && {
			accessor: 'enterCustomer',
			Header: '',
			CustomCell: ({ row }: any) => {
				return (
					<EnterCustomerComponenet
						data={row.original}
						routeName={routeName}
					/>
				);
			},
		},
	]);
};

export const handleFilter = (
	data: FilterData,
	setFilters: Function,
	filters: any,
	setRepCustData: Function,
) => {
	if (data.name === 'unitIds') {
		setRepCustData([]);
		setFilters({
			...defaultFilters,
			unitIds: [data.value],
		});
	} else if (data.name === 'year') {
		setFilters({
			...filters,
			year: [Number(moment(data.value).year().toString())],
		});
	} else if (data.name === 'repCustomerIds') {
		setRepCustData([]);
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerIds: chosenIds,
		});
	} else if (data.name === 'repCustomerUnitIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerUnitIds: chosenIds,
		});
	} else if (data.name === 'operatorIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			operatorIds: chosenIds?.includes?.('all') ? [] : chosenIds,
		});
	} else if (data.name === 'status') {
		const chosenIds = data.value.map((val: any) => val.id);
		setFilters({
			...filters,
			status: chosenIds,
		});
	}
};
