import React, { useEffect, useState } from 'react';
import {
	FlatList,
	TouchableOpacity,
	View,
	StyleSheet,
	Dimensions,
	Platform,
} from 'react-native';
import OutlineButtonContainer from '../../../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../../../components/shared/Buttons/SolidButton.container';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import colors from '../../../../../config/colors';
import ExtraDataRow from './ExtraDataRow';

const SelectPermissionsModal = ({
	selectedData,
	allData,
	close,
	onNext,
	role,
	title,
	disabled,
	dontUseRoleDisable,
	onSubmit,
	editMySelf,
}: any) => {
	const [selected, setSelected] = useState(selectedData || []);
	const onCheck = (itemId: number, value?: boolean) => {
		if (value) {
			setSelected([...selected, { permission_id: itemId }]);
		} else {
			setSelected(
				selected.filter((s: any) => s.permission_id !== itemId),
			);
		}
	};

	const onSwitch = (itemId: number, value?: boolean) => {
		const itemIndex = selected.findIndex(
			(s: any) => s.permission_id === itemId,
		);
		console.log({ itemIndex, value });
		if (itemIndex > -1) {
			const tempSelected = [...selected];
			tempSelected[itemIndex] = {
				...tempSelected[itemIndex],
				read_only: value,
			};
			setSelected([...tempSelected]);
		} else {
			setSelected([
				...selected,
				{ permission_id: itemId, read_only: value },
			]);
		}
	};

	const _onSubmit = () => {
		onSubmit(selected.filter((i: any) => i !== 'all'));
		close?.();
		onNext?.();
	};

	useEffect(() => {
		setSelected(selectedData || []);
	}, [selectedData]);

	return (
		<View style={styles.wrapper}>
			<View style={styles.header}>
				<Typography size={28} weight='normal' style={{ flex: 1 }}>
					{role.name}
				</Typography>
				<TouchableOpacity onPress={onNext || close}>
					<ImageIcon name='leftArrow' />
				</TouchableOpacity>
			</View>
			{editMySelf && (
				<I18n
					size={14}
					color='red'
					weight='400'
					style={{ marginTop: 8 }}
				>
					editUserModal.permissionsTable.note.toMySelf
				</I18n>
			)}
			<View
				style={{
					flexDirection: 'row-reverse',
					justifyContent: 'space-between',
					marginTop: 30,
				}}
			>
				<I18n size={14}>editUserModal.permissionsTable.name</I18n>
				{allData[0].allow_read_only && (
					<I18n size={14} style={{ marginLeft: 40 }}>
						editUserModal.permissionsTable.viewOnly
					</I18n>
				)}
			</View>
			<FlatList
				style={{ marginTop: 17, flex: 1, marginBottom: 60 }}
				data={allData}
				contentContainerStyle={{ paddingBottom: 50 }}
				renderItem={({ item, ...rest }) => (
					<ExtraDataRow
						isChecked={
							(item?.id === 'all' &&
								selected.length === allData.length - 1) ||
							!!selected.find(
								(s: any) => s.permission_id === item.id,
							)
						}
						showSwitch={item.allow_read_only}
						onSwitch={onSwitch}
						isSwitchOn={
							selected.find(
								(s: any) => s.permission_id === item.id,
							)?.read_only
						}
						disabled={
							disabled || (item.disable && !dontUseRoleDisable)
						}
						item={item}
						roleId={role.id}
						onCheck={onCheck}
						{...rest}
					/>
				)}
				keyExtractor={item => item.id}
			/>
			<View
				style={{
					flexDirection: 'row-reverse',
					height: 120,
					marginTop: 10,
				}}
			>
				<SolidButtonContainer
					disabled={!selected?.length || disabled}
					overrideStyle={{ minWidth: 90 }}
					onPress={_onSubmit}
				>
					general.update
				</SolidButtonContainer>
				<OutlineButtonContainer
					size='medium'
					onPress={onNext || close}
					overrideStyle={{ minWidth: 90, marginRight: 15 }}
				>
					general.cancel
				</OutlineButtonContainer>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		paddingLeft: 3,
	},
	wrapper: {
		width: Dimensions.get('screen').width,
		height: Dimensions.get('window').height,
		backgroundColor: colors.white,
		opacity: 1,
		paddingVertical: 25,
		paddingHorizontal: 16,
		top: 0,
		left: 0,
		position: Platform.OS !== 'web' ? 'absolute' : undefined,
	},
});

export default SelectPermissionsModal;
