import React, { useEffect, useState, useMemo, useRef } from 'react';
import httpService from '../../services/http.service';
import HoursReportSummary from './HoursReportSummary.component';
import EmployeeHoursReportSummaryContainer from './EmployeeHoursReportSummary/EmployeeHoursReportSummary.container';
import WorklogReportsPageContainer from './WorkLogReportsPage/WorklogReportsPage.container';
import { fetchEmployeesFilterList } from '../../store/actions/reportsFilters.actions';
import moment from 'moment';
import { compact, toNumber } from 'lodash';
import { i18nService } from '../../services/i18n.service';
import { useSelector } from 'react-redux';
import { getUserData } from '../../store/selectors/login.selectors';
import { debounce } from 'lodash';
import useBusinessUnitsState from '../../hooks/useBusinessUnitsState';
import routes from '../../config/routes';

export interface HoursData {
	standardMinutes: number;
	standardDays: number;
	workLogsMinutes: number;
	workLogsDays: number;
	missDays: number;
	profileId: number;
	fullName: string;
	teudatZeut: string;
}

const HoursReportSummaryContainer = ({
	navigation,
	route,
}: {
	navigation: any;
	route: any;
}) => {
	const getDefaultDateFilter = filter => {
		const currentDay = toNumber(moment().format('DD'));
		const currentYear = toNumber(moment().format('YYYY'));
		const currentMonth = toNumber(moment().format('MM'));
		switch (filter) {
			case 'year':
				if (currentDay >= 15) return currentYear;
				return moment().subtract(1, 'months').format('YYYY');
			case 'month':
				if (currentDay >= 15) return currentMonth;
				return toNumber(moment().subtract(1, 'months').format('MM'));
			default:
				return moment().format('YYYY-MM-DD');
		}
	};
	const monthMap = {
		1: 'months.January',
		2: 'months.February',
		3: 'months.March',
		4: 'months.April',
		5: 'months.May',
		6: 'months.June',
		7: 'months.July',
		8: 'months.August',
		9: 'months.September',
		10: 'months.October',
		11: 'months.November',
		12: 'months.December',
	};
	const [departments, setDepartments] = useState([]);
	const [hoursData, setHoursData] = useState<HoursData[]>([]);
	const [totalData, setTotalData] = useState({});
	const [month, setMonth] = useState([]);
	const [year, setYear] = useState(getDefaultDateFilter('year'));
	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('DESC');
	const [sortBy, setSortBy] = useState('fullName');
	const [resetEmpFilter, setResetEmpFilter] = useState(false);
	const [resetMonthFilter, setResetMonthFilter] = useState(false);
	const [isLoadingData, setIsLoadingData] = useState(true);
	const [filters, setFilters] = useState({
		year: [getDefaultDateFilter('year')],
		month: [getDefaultDateFilter('month')],
		emp: [],
		dep: [],
		finish_work: [],
		unitIds: [],
	});
	const [indexofEmployeeToShow, setIndex] = useState(0);
	const [isTotalEmpView, setIsTotalEmpView] = useState(true);
	const [isAllWorkLogReports, setIsWorkLogReports] = useState(false);
	const isFirstInit = useRef(true);
	const userData = useSelector(getUserData);
	const PrevUser = useRef(userData);
	const defaultSort = 'fullName';
	const defaultOrder = 'DESC';

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.hoursReportSummary.privileges,
		onChangeFilter: ({ value, isMulti }: any) => {
			isFirstInit.current = true;
			setFilters((state: any) => ({
				...state,
				unitIds: isMulti
					? value.map((val: any) => val.id || val)
					: [value],
			}));
		},
	});

	useEffect(() => {
		if (PrevUser.current === userData && !isFirstInit.current)
			if (route.params.singleEmployee) {
				setIsTotalEmpView(false);
			}
	}, [route.params]);
	useEffect(() => {
		if (!isFirstInit.current) {
			getMonth();
			setResetMonthFilter(!resetMonthFilter);
		}
	}, [year]);
	useEffect(() => {
		if (!isFirstInit.current && !isAllWorkLogReports) {
			if (PrevUser.current === userData) {
				// should prevent extra http req when filter reset because userData changes
				getData(true, sortBy || defaultSort, order || defaultOrder);
			}
		}
	}, [filters, userData]);
	useEffect(() => {
		if (!isAllWorkLogReports && !isFirstInit.current) {
			PrevUser.current = userData;
			navigation.setParams({
				singleEmployee: false,
			});
			getData(true, sortBy || defaultSort, order || defaultOrder);
		}
	}, [isAllWorkLogReports]);

	useEffect(() => {
		if (!isFirstInit.current) {
			setFilters({
				...filters,
				year: [year],
				month: month.includes(getDefaultDateFilter('month'))
					? [getDefaultDateFilter('month')]
					: month[month.length - 1]
					? [month[month.length - 1]]
					: [],
			});
		}
	}, [month]);

	useEffect(() => {
		navigation.setParams({
			singleEmployee: false,
		});
		setIsTotalEmpView(true);
		PrevUser.current = userData;
		setYear(getDefaultDateFilter('year'));
		setResetEmpFilter(!resetEmpFilter);
	}, [userData]);

	useEffect(() => {
		if (hoursData.length - 1 > indexofEmployeeToShow) setIndex(0);
	}, [hoursData]);

	useEffect(() => {
		if ((filters.unitIds.length || multiValue) && !isAllWorkLogReports) {
			getDepartmentsFilter();
			getMonth();
			isFirstInit.current = false;
		}
	}, [filters.unitIds]);

	const getDepartmentsFilter = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getDepartmentsFilterList',
				query: {
					unitIds: getSelectedUnits(filters.unitIds).join(','),
					pageName: route.name,
				},
			});
			if (res) {
				setDepartments(res);
			}
		} catch (e) {
			console.log(e);
		}
	};
	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: 20,
			search,
			filter: { dep: [], finish_work: [true] },
			unitIds: getSelectedUnits(filters.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: 20 === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const monthOptions = useMemo(
		() =>
			month.map(month => ({
				id: month,
				name: i18nService.translate(monthMap[month]),
			})),
		[month],
	);

	const filtersConfig = useMemo(() => {
		return compact([
			{
				name: 'finish_work',
				type: 'checkbox',
				placeholder: 'usersManagement.filter.finishWork',
				value: filters?.finish_work?.[0] === 'true',
				styles: {
					width: 274,
				},
			},
			{
				name: 'department',
				options: departments,
				placeholder: 'department',
				styles: { width: 134 },
				type: 'multiselect',
				key: `${JSON.stringify(userData)}${JSON.stringify(
					filters.unitIds,
				)}`,
				value: filters.dep?.map((unit: any) =>
					departments.find((bunit: any) => bunit.id === unit),
				),
			},
			{
				name: 'year',
				type: 'datepicker',
				placeholder: year,
				showYearPicker: true,
				showQuarterYearPicker: false,
				styles: {
					width: 140,
				},
				value: [year],
			},
			{
				name: 'month',
				options: monthOptions,
				placeholder: 'month',
				styles: { width: 134 },
				type: 'multiselect',
				key: resetMonthFilter,
				alwaysRerender: true,
				value: filters.month?.map((unit: any) =>
					monthOptions.find((bunit: any) => bunit.id === unit),
				),
			},
			{
				name: 'employees',
				type: 'asyncmultiselect',
				placeholder: 'employees',
				loadOptions: getEmployeesFilter,
				styles: {
					width: 274,
				},
				key: `${resetEmpFilter}${JSON.stringify(filters.unitIds)}`,
			},
			businessUnitsList?.length > 1
				? isBusinessUnitsMulti
					? {
							name: 'unitIds',
							type: 'multiselect',
							placeholder: 'businessUnits',
							value: filters.unitIds?.map((unit: any) =>
								businessUnitsList.find(
									(bunit: any) => bunit.id === unit,
								),
							),
							options: businessUnitsList,
							styles: {
								control: {
									width: 200,
									maxWidth: 200,
								},
							},
					  }
					: {
							name: 'unitIds',
							type: 'select',
							placeholder: 'businessUnits',
							defaultValue: filters.unitIds?.[0],
							options: businessUnitsList,
							hideDefaultOption: true,
							styles: {
								width: 150,
							},
					  }
				: undefined,
		]);
	}, [
		month,
		year,
		departments,
		year,
		monthOptions,
		userData,
		resetMonthFilter,
		businessUnitsList,
		isBusinessUnitsMulti,
		filters,
	]);

	const getMonth = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getMonthOfHoursReportSummery',
				query: {
					year,
					unitIds: getSelectedUnits(filters.unitIds).join(','),
				},
			});
			if (res) {
				setMonth(res);
			}
		} catch (e) {}
	};

	const getData = async (
		firstInit: boolean,
		newSortBy?: string,
		neworder?: string,
	) => {
		try {
			const res = await httpService.api<any>({
				type: 'getHoursReportSummery',
				data: {
					p: firstInit ? 0 : nextPage,
					ps: 15,
					s:
						newSortBy === 'businessUnitName'
							? 'employeer_name'
							: newSortBy
							? newSortBy
							: sortBy === 'businessUnitName'
							? 'employeer_name'
							: sortBy,
					sd: neworder ? neworder : order,
					f: {
						...filters,
						unitIds: getSelectedUnits(filters.unitIds),
					},
				},
			});

			if (res) {
				if (res.totalRow || firstInit) setTotalData(res.totalRow || {});
				setDisableLoad(res.data.length < 15 ? true : false);
				if (firstInit) {
					setHoursData(res.data);
					setNextPage(1);
				} else {
					setHoursData([...hoursData, ...res.data]);
					setNextPage(nextPage + 1);
				}
				setIsLoadingData(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onSort = (newSort: string) => {
		let newOrder = order;
		if (newSort === sortBy) {
			newOrder = order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			newOrder = 'DESC';
		}
		setOrder(newOrder);
		setSortBy(newSort);
		getData(true, newSort, newOrder);
	};

	const onFilter = (filterData: any) => {
		let chosenFilters: any = {
			...filters,
		};

		const key = filterData.name;
		let isYearChanged = false;
		switch (key) {
			case 'unitIds':
				isFirstInit.current = true;
				chosenFilters = {
					...chosenFilters,
					unitIds: filterData.isMulti
						? filterData.value.map((val: any) => val.id || val)
						: [filterData.value],
					dep: [],
					emp: [],
				};
				break;
			case 'year':
				const chosenYear = moment(filterData.value).year().toString();
				chosenFilters.year = [chosenYear];
				isYearChanged = true;
				setYear(chosenYear);
				break;
			case 'finish_work':
				chosenFilters.finish_work = [`${filterData.value}`];
				break;
			case 'department':
				chosenFilters.dep = filterData.value.map(dep => dep.id);
				break;
			case 'employees':
				chosenFilters.emp = filterData.value.map(emp => emp.id);
				break;
			default:
				if (filterData.isAllSelected) {
					chosenFilters[key] = month;
					break;
				}
				chosenFilters[key] = filterData.value.map(month => month.id);
				break;
		}
		if (!isYearChanged) setFilters(chosenFilters);
	};

	const handleEmployeeChoice = item => {
		setIndex(toNumber(item.id));
	};

	const toggleEmployeeToView = async goNext => {
		if (goNext) {
			if (indexofEmployeeToShow < hoursData.length - 1)
				setIndex(index => index + 1);
			else {
				if (
					disabledLoad &&
					indexofEmployeeToShow === hoursData.length - 1
				) {
					return;
				}
				await getData(false);
			}
		} else {
			if (indexofEmployeeToShow > 0) setIndex(index => index - 1);
			else return;
		}
	};
	const handleBackToAllHoursReports = () => {
		setIsTotalEmpView(true);
		const chosenFilters: any = {};
		Object.keys(filters).forEach(key => {
			chosenFilters[key] = [...filters[key]];
		});
		chosenFilters.emp = [];
		setFilters(chosenFilters);
		navigation.setParams({
			singleEmployee: false,
		});
	};

	const handleToAllWorkLogs = () => {
		const chosenFilters = {};
		Object.keys(filters).forEach(key => {
			if (key === 'emp') chosenFilters[key] = [];
			else chosenFilters[key] = [...filters[key]];
		});
		setFilters(chosenFilters);
		setIsWorkLogReports(true);
	};
	const debouncedGetMoreData = debounce(() => {
		getData(false);
	}, 300);

	return isAllWorkLogReports ? (
		<WorklogReportsPageContainer
			route={route}
			monthFilter={filters.month?.map((unit: any) =>
				monthOptions.find((bunit: any) => bunit.id === unit),
			)}
			yearFilter={[year]}
			navigation={navigation}
			backTohoursReportSummery={() => {
				setIsTotalEmpView(true);
				setIsWorkLogReports(false);
			}}
		/>
	) : isTotalEmpView ? (
		<HoursReportSummary
			reqForWorkLogREports={handleToAllWorkLogs}
			navigation={navigation}
			data={hoursData}
			isLoadingData={isLoadingData}
			totalData={totalData}
			onSort={onSort}
			onLoadMore={() => (disabledLoad ? null : debouncedGetMoreData())}
			onFilter={onFilter}
			filtersConfig={filtersConfig}
			refresh={() => getData(true, sortBy, order)}
			onRowPress={handleEmployeeChoice}
			worklogReportFormat={userData?.customer_setting.worklogReportFormat}
			businessUnitsList={businessUnitsList}
		/>
	) : (
		<EmployeeHoursReportSummaryContainer
			backToAllHoursReports={handleBackToAllHoursReports}
			reqForWorkLogREports={handleToAllWorkLogs}
			navigation={navigation}
			currentEmployee={{
				...hoursData[indexofEmployeeToShow],
				itemIndex: indexofEmployeeToShow,
			}}
			OnPrevEmployeePress={() => toggleEmployeeToView(false)}
			OnNextEmployeePress={() => toggleEmployeeToView(true)}
			monthFilter={filters.month}
			yearFilter={filters.year}
			disableLoad={disabledLoad}
			disableNextButton={
				disabledLoad && indexofEmployeeToShow === hoursData.length - 1
			}
			worklogReportFormat={userData?.customer_setting.worklogReportFormat}
		/>
	);
};

export default HoursReportSummaryContainer;
