import React, { useMemo } from 'react';
import {
	TouchableHighlight,
	View,
	StyleSheet,
	TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';

import { UserDetails } from './PersonalInformation.container';
import I18n from '../../components/shared/i18n/I18n.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import getShadowStyle from '../../config/shadow';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Select from '../../components/shared/FieldBuilder/Select/ReactSelect.component';
import { i18nService } from '../../services/i18n.service';
import { getHasSamePrivilegsUnits } from '../../store/selectors/reportsFilters.selectors';
import BusinessUnitsInfo from './BusinessUnitsInfo';
import SelectGroup from '../../components/shared/SelectGroup/SelectGroup.component';
import { getDefaultDisplayWageMode } from '../../store/selectors/config.selectors';
import {
	getEmployeePrivileges,
	getEmployerRolesPrivileges,
} from '../../store/selectors/login.selectors';
import {
	Home_One_Permissions,
	Privileges,
} from '../../constants/roleTypes.constant';

interface dataProps {
	userData: UserDetails | any;
	isMobileWeb?: boolean;
	onUpdatePassword(): void;
	onUpdateEmail(): void;
	employerList: any;
	isEmployerAndEmployee: boolean;
	showDefaultSalaryData: boolean;
	onChangeCustomer: (newCustomer: any) => void;
	onChangeEnterMode: (newEnterMode: any) => void;
	onChangeSalaryData: (newSalaryData: any) => void;
	onChangeEmployeeFormAlerts: (newAlertData: boolean) => void;
	onChangeDocumentsAlerts: (newAlertData: boolean) => void;
	onUpdatePhone(): void;
}

export const DataContainer = ({
	isMobileWeb,
	userData,
	onUpdatePassword,
	onUpdateEmail,
	employerList,
	isEmployerAndEmployee,
	showDefaultSalaryData,
	onChangeCustomer,
	onChangeEnterMode,
	onChangeSalaryData,
	onChangeEmployeeFormAlerts,
	onChangeDocumentsAlerts,
	onUpdatePhone,
}: dataProps) => {
	const width = isMobileWeb ? 90 : 200;
	const hasSamePrivilegsUnits = useSelector(getHasSamePrivilegsUnits);
	const defaultDisplayWageMode = useSelector(getDefaultDisplayWageMode);
	const userPrivilegesStatus = useSelector(getEmployeePrivileges);
	const employerPrivileges = useSelector(getEmployerRolesPrivileges);
	const canView101Reports = useMemo(
		() =>
			employerPrivileges?.includes(
				Privileges.ROLE_EMPLOYEE_FORM_REPORT_READ,
			) ||
			employerPrivileges?.includes(
				Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
			),
		[employerPrivileges],
	);
	const canViewDocuments = useMemo(
		() =>
			employerPrivileges?.includes(Privileges.ROLE_MNG_DOCS_READ) ||
			employerPrivileges?.includes(Privileges.ROLE_MNG_DOCS_WRITE),
		[employerPrivileges],
	);
	const showDefaultDisplayWageModeSetting = userPrivilegesStatus?.some(priv =>
		Home_One_Permissions.includes(priv),
	);

	return (
		<View style={{ paddingBottom: isMobileWeb ? 50 : 0 }}>
			<View style={styles.rowCintainer}>
				<I18n size={14} style={{ width }}>
					personalInformation.idNumber
				</I18n>
				<Typography weight='normal' size={14}>
					{userData?.teudatZeut || ''}
				</Typography>
			</View>
			<View style={styles.rowCintainer}>
				<I18n size={14} style={{ width }}>
					personalInformation.name
				</I18n>
				<Typography weight='normal' size={14}>
					{userData?.fullName || ''}
				</Typography>
			</View>
			<View style={styles.rowCintainer}>
				<I18n size={14} style={{ width }}>
					personalInformation.phone
				</I18n>
				<Typography weight='normal' size={14}>
					{userData?.phone || ''}
				</Typography>
				{userData.status && (
					<TouchableOpacity onPress={onUpdatePhone}>
						<ImageIcon
							name='edit'
							height={15}
							width={15}
							style={{ marginRight: 10 }}
						/>
					</TouchableOpacity>
				)}
			</View>
			<View style={styles.rowCintainer}>
				<I18n size={14} style={{ width }}>
					personalInformation.email
				</I18n>
				<Typography weight='normal' size={14}>
					{userData?.email || ''}
				</Typography>
				<TouchableOpacity onPress={onUpdateEmail}>
					<ImageIcon
						name='edit'
						height={15}
						width={15}
						style={{ marginRight: 10 }}
					/>
				</TouchableOpacity>
			</View>
			<View
				style={{
					flexDirection: 'column',
					zIndex: 2,
				}}
			>
				<View
					style={{
						flexDirection: 'column',
						alignItems: 'flex-end',
						marginVertical: isMobileWeb ? 10 : 20,
						width: '100%',
					}}
				>
					{employerList?.length > 1 || isEmployerAndEmployee ? (
						<SelectGroup
							disabled={!isEmployerAndEmployee}
							name='defaultEmployeeEmployer'
							title='personalInformation.displayMode'
							options={[
								{
									value: 'EMPLOYEE',
									label: i18nService.translate(
										'personalInformation.employee',
									),
								},
								{
									value: 'EMPLOYER',
									label: i18nService.translate(
										'personalInformation.employer',
									),
								},
							]}
							onChange={onChangeEnterMode}
							value={userData.displayMode || 'EMPLOYEE'}
							isMobile={isMobileWeb}
							width={450}
						/>
					) : null}
					<SelectGroup
						disabled={
							!showDefaultSalaryData ||
							!showDefaultDisplayWageModeSetting
						}
						name='defaultSalaryData'
						title='personalInformation.select.default.salaryData.title'
						options={[
							{
								value: true,
								label: i18nService.translate(
									'personalInformation.select.default.showSalaryData',
								),
							},
							{
								value: false,
								label: i18nService.translate(
									'personalInformation.select.default.hideSalaryData',
								),
							},
						]}
						onChange={onChangeSalaryData}
						value={defaultDisplayWageMode || false}
						isMobile={isMobileWeb}
						width={450}
					/>
					{employerList?.length > 1 ? (
						<View
							style={{
								flexDirection: isMobileWeb
									? 'column'
									: 'row-reverse',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginTop: 20,
								width: isMobileWeb ? '100%' : 580,
								zIndex: 10,
							}}
						>
							<I18n
								size={14}
								weight={isMobileWeb ? 'bold' : 'normal'}
								numberOfLines={isMobileWeb ? 2 : 1}
								style={{
									lineHeight: 20,
									width: isMobileWeb ? '100%' : width * 2.5,
									textAlign: isMobileWeb ? 'center' : 'right',
									paddingBottom: isMobileWeb ? 10 : undefined,
								}}
							>
								personalInformation.customer
							</I18n>
							<Select
								options={[
									{
										name: i18nService.translate(
											'personalInformation.noChoise',
										),
										id: '0',
									},
									...employerList.map((item: any) => ({
										name: item.name,
										id: `${item.customer_id}`,
									})),
								]}
								onChange={onChangeCustomer}
								horizontalPadding={60}
								value={userData.defaultCustomerId?.toString()}
							/>
						</View>
					) : null}
					{canView101Reports && (
						<SelectGroup
							name='disableEmployeeFormAlert'
							title='personalInformation.employeeFormAlert'
							options={[
								{
									value: false,
									label: i18nService.translate(
										'general.true',
									),
								},
								{
									value: true,
									label: i18nService.translate(
										'general.false',
									),
								},
							]}
							forceMargin
							onChange={onChangeEmployeeFormAlerts}
							value={userData.disableEmployeeFormAlert || false}
							isMobile={isMobileWeb}
							width={450}
						/>
					)}
					{canViewDocuments && (
						<SelectGroup
							name='disableDocumentAlert'
							title='personalInformation.documentsAlert'
							options={[
								{
									value: false,
									label: i18nService.translate(
										'general.true',
									),
								},
								{
									value: true,
									label: i18nService.translate(
										'general.false',
									),
								},
							]}
							forceMargin
							onChange={onChangeDocumentsAlerts}
							value={userData.disableDocumentAlert || false}
							isMobile={isMobileWeb}
							width={450}
						/>
					)}
				</View>
			</View>

			{hasSamePrivilegsUnits ? (
				<BusinessUnitsInfo isMobileWeb={isMobileWeb} />
			) : null}
			<View
				style={{ alignItems: isMobileWeb ? 'flex-start' : 'flex-end' }}
			>
				<TouchableHighlight
					onPress={onUpdatePassword}
					underlayColor='transparent'
				>
					<View style={[styles.button, { marginTop: 15 }]}>
						<I18n size={14} weight='normal' color='white'>
							personalInformation.updatePassword
						</I18n>
					</View>
				</TouchableHighlight>
			</View>
		</View>
	);
};

export const ButtonsContainer = ({
	acceptText,
	disabled,
	onAccept,
	onCancel,
}: {
	acceptText?: string;
	disabled?: boolean;
	onAccept: () => void;
	onCancel: (val: any) => void;
}) => {
	return (
		<View>
			<View style={styles.divider} />
			<View style={styles.buttonContainer}>
				<TouchableHighlight
					onPress={onAccept}
					underlayColor='transparent'
					disabled={disabled}
				>
					<View style={[styles.button, disabled && { opacity: 0.4 }]}>
						<I18n size={16} weight='normal' color='white'>
							{acceptText || 'general.accept'}
						</I18n>
					</View>
				</TouchableHighlight>

				<TouchableHighlight
					onPress={onCancel}
					underlayColor='transparent'
				>
					<View style={styles.cancelButton}>
						<I18n size={16} weight='normal' color='lightPrimary'>
							general.cancel
						</I18n>
					</View>
				</TouchableHighlight>
			</View>
		</View>
	);
};

export const Required = () => {
	return (
		<Typography
			style={{ marginRight: 2, textAlign: 'right' }}
			size={14}
			color='red'
		>
			*
		</Typography>
	);
};

const styles = StyleSheet.create({
	rowCintainer: {
		flexDirection: 'row-reverse',
		marginBottom: 27,
	},
	divider: {
		backgroundColor: colors.darkGrey,
		opacity: 0.2,
		height: 1,
		width: '100%',
		marginVertical: 20,
	},
	button: {
		alignItems: 'center',
		justifyContent: 'center',
		alignSelf: 'flex-end',
		height: 40,
		minWidth: 126,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-around',
		width: '100%',
	},
	cancelButton: {
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 126,
		backgroundColor: 'white',
		borderRadius: 45,
		borderColor: colors.lightPrimary,
		borderWidth: 1,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});
