import * as configActions from '../constants/configActions';
import { ConfigState, StoreAction } from '../../interfaces/redux.interface';

const initialState: ConfigState = {
	busyCounter: 0,
	navigateTo: undefined,
	defaultDisplayMode: undefined,
	files101: undefined,
	isMaintenance: false,
};

const configReducer = (
	state: typeof initialState = initialState,
	action: StoreAction<any>,
): any => {
	switch (action.type) {
		case configActions.DECREMENT_BUSY_COUNTER:
			return {
				...state,
				busyCounter: state.busyCounter ? state.busyCounter - 1 : 0,
			};
		case configActions.SET_HIDE_CHAT:
			return {
				...state,
				hideChat: action.payload,
			};
		case configActions.INCREMENT_BUSY_COUNTER:
			return {
				...state,
				busyCounter: state.busyCounter + 1,
			};
		case configActions.RESET_BUSY_COUNTER:
			return {
				...state,
				busyCounter: 0,
			};
		case configActions.SET_NAVIGATE_TO:
			return {
				...state,
				navigateTo: action.payload,
			};
		case configActions.RESET_NAVIGATE_TO:
			return {
				...state,
				navigateTo: undefined,
			};
		case configActions.SET_DEFAULT_DISPLAY_MODE:
			return {
				...state,
				defaultDisplayMode: action.payload,
			};
		case configActions.SET_101_FILES:
			return {
				...state,
				files101: action.payload,
			};
		case configActions.SET_DEFAULT_DISPLAY_WAGE_MODE:
			return {
				...state,
				defaultDisplayWageMode: action.payload,
			};
		case configActions.SET_IS_MAINTENANCE:
			return {
				...state,
				isMaintenance: action.payload,
			};
		default:
			return { ...state };
	}
};

export default configReducer;
