import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Platform } from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import ActionButtons from './ActionButtons/ActionButtons';


const CommentModal = ({
	fieldLabel,
	fieldValue,
	height = 357,
	onNext,
	fromCommentPanel,
	isRequired,
	comment,
	readOnly,
}: {
	fieldLabel: string;
	fieldValue: string;
	children?: any;
	fromCommentPanel: any;
	onSubmit?: Function;
	submitBtnText?: string;
	height?: number;
	onNext?: Function;
	isRequired?: boolean;
	inputValue?: any;
	comment?: string;
	cancel?: any;
	readOnly: boolean;
}) => {
	const patform = useDevicePlatform();
	const isMobile = patform !== DeviceType.WEB;
	const isMobileWeb = patform === DeviceType.MOBILE_WEB;

	const styles = StyleSheet.create({
		container: {
			flexDirection: 'column',
			shadowColor: 'rgba(0, 0, 0, 0)',
			shadowOpacity: 0.1,
			shadowOffset: { width: 0, height: 2 },
			backgroundColor: colors.white,
			borderRadius: 20,
		},
		titleContainer: {
			paddingTop: 39,
			paddingHorizontal: isMobile ? 20 : 50,
		},
		inputContainer: {
			paddingTop: 10,
			paddingHorizontal: isMobile ? 20 : 50,
			marginBottom: 15,
			borderBottomWidth: 1,
			paddingBottom: 20,
			borderBottomColor: '#ddd',
		},
		buttonsContainer: {
			paddingHorizontal: isMobile ? 20 : 50,
			flexDirection: 'row',
			paddingBottom: 39,
			...Platform.select({
				web: {
					position: 'static',
					display: 'block',
				},
			}),
		},
		fieldValue: {
			paddingTop: 20,
			paddingHorizontal: isMobile ? 20 : 50,
			color: colors.darkGrey,
			fontSize: 22,
			fontFamily: 'RubikMedium',
			fontWeight: 'normal',
			textAlign: 'right',
			marginTop: 2,
			marginBottom: 22,
		},
	});

	const containerS = StyleSheet.flatten([
		{
			minWidth: 335,
			width: isMobile ? '90%' : isMobileWeb ? '100%' : 335,
			height: isMobile ? height : 'auto',
		},
		styles.container,
	]);


	return (
		<View style={containerS}>
			<View style={styles.titleContainer}>
				{fromCommentPanel 
					? (<Typography>
					{fieldLabel}
					</Typography>)
					: (<I18n>
						{fieldLabel}
						</I18n>)
				}

				<Typography>{isRequired ? '*' : null}</Typography>
			</View>
			<Typography style={styles.fieldValue}>{fieldValue}</Typography>
			<View style={styles.inputContainer}>
				<Typography>{comment}</Typography>
			</View>
			<View style={styles.buttonsContainer}>
				<ActionButtons
					save={() => { }}
					remove={() => { }}
					cancel={() => { }}
					commentExist={false}
					onNext={onNext}
					isEmployee={true}
				/>
			</View>
		</View>
	);
};

export default CommentModal;
