import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import colors from '../../../config/colors';
import Button from './Button.component';
import { ButtonProps } from '../../../interfaces/buttonProps.interface';
import getShadowStyle from '../../../config/shadow';

const OutlineButtonContainer = ({
	children,
	onPress,
	disabled,
	size,
	overrideStyle,
}: ButtonProps) => {
	const buttonStyles = useMemo(
		() =>
			StyleSheet.flatten([
				size === 'medium' ? styles.mediumButton : styles.buttonStyle,
				overrideStyle,
			]),
		[size, overrideStyle],
	);

	return (
		<Button
			buttonStyle={buttonStyles}
			onPress={onPress}
			disabled={disabled}
		>
			{children}
		</Button>
	);
};

const styles = StyleSheet.create({
	buttonStyle: {
		textAlign: 'center',
		alignItems: 'center',
		borderWidth: 1,
		borderColor: colors.primary,
		backgroundColor: colors.white,
		padding: 22,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	mediumButton: {
		paddingHorizontal: 22,
		paddingVertical: 10,
		minWidth: 124,
		textAlign: 'center',
		alignItems: 'center',
		borderWidth: 1,
		borderColor: colors.primary,
		backgroundColor: colors.white,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default OutlineButtonContainer;
