import React from 'react';
import { StyleSheet, View } from 'react-native';
import colors from '../../../config/colors';
import Button from './Button.component';
import { ButtonProps } from '../../../interfaces/buttonProps.interface';
import getShadowStyle from '../../../config/shadow';

const SolidButtonContainer = ({
	children,
	onPress,
	disabled,
	overrideStyle,
	textOptions,
	size = 'medium',
}: ButtonProps) => {
	const buttonStyles = StyleSheet.flatten([
		size === 'small'
			? styles.smallButton
			: size === 'medium'
			? styles.mediumButton
			: styles.buttonStyle,
		overrideStyle,
	]);

	return (
		<Button
			buttonStyle={buttonStyles}
			onPress={onPress}
			disabled={disabled}
			textOptions={{
				color: 'white',
				...textOptions,
			}}
		>
			{children}
		</Button>
	);
};

const styles = StyleSheet.create({
	buttonStyle: {
		backgroundColor: colors.lightPrimary,
		textAlign: 'center',
		alignItems: 'center',
		padding: 22,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	smallButton: {
		backgroundColor: colors.lightPrimary,
		textAlign: 'center',
		alignItems: 'center',
		padding: 6,
		borderRadius: 45,
		...getShadowStyle(8, 'rgb(59, 112, 164)'),
	},
	mediumButton: {
		backgroundColor: colors.lightPrimary,
		textAlign: 'center',
		alignItems: 'center',
		paddingHorizontal: 22,
		paddingVertical: 10,
		minWidth: 124,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
});

export default SolidButtonContainer;
