import React from 'react';
import { TouchableOpacity } from 'react-native';
import _, { AnyKindOfDictionary } from 'lodash';
import { Base64 } from 'js-base64';

import ModalConfirmActionsCustomStyle from '../../../modals/ModalConfirmActionsCustomStyle/ModalConfirmActionsCustomStyle.component';
import { i18nService } from '../../../services/i18n.service';
import { modalService } from '../../../services/modal.service';
import FromMonthToMonthAddNewLine from './FromMonthToMonthAddNewLine';
import AdditionalData from './Formats/AdditionalData';
import AdditionalDataComprehensive from '../ComprehensiveForm/Formats/AdditionalData';
import WageComponent from './Formats/WageComponent';
import WageComponentComprehensive from '../ComprehensiveForm/Formats/WageComponent';
import OptionalDeductions from './Formats/OptionalDeductions';
import OptionalDeductionsComprehensive from '../ComprehensiveForm/Formats/OptionalDeductions';
import httpService from '../../../services/http.service';
import { fromMonthToMonthListLike } from '../../../models/fromMonthToMonth.model';
import I18n from '../../../components/shared/i18n/I18n.component';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import AddNewWage from '../ComprehensiveForm/FromMonthToMonthAddNewWage';
import moment from 'moment';

export const handleAddButtonModal = async (
	name: string,
	customerWageComponents: any,
	flexTableData: any,
	handleAddNewWage: Function,
) => {
	const list = customerWageComponents[name].filter(
		(item: any) => item.showEdit === 2 && item.allowAdd,
	);
	let index: any;
	if (name === 'wageComponent') {
		index = 1;
	} else if (name === 'optionalDeductionsComponent') {
		index = 2;
	}
	const exList = Object.keys(flexTableData[index]);

	const newList = list.map((item: any) => {
		if (!exList.includes(item.code)) {
			return item;
		}
	});

	const finList = newList.filter((item: any) => item !== undefined);

	await modalService.openModal(
		null,
		{
			separator: true,
			hideButtons: true,
			containerCustomStyle: {
				height: 350,
				paddingHorizontal: 15,
				paddingBottom: 35,
				paddingTop: 20,
			},
		},
		(props: any) => (
			<ModalConfirmActionsCustomStyle {...props}>
				<FromMonthToMonthAddNewLine
					{...props}
					flexTableData={flexTableData[index]}
					type={name}
					list={finList}
					error={
						finList.length === 0
							? 'FromMonthToMonthReports.AddNewLine.ErrorMessage'
							: null
					}
					onCancel={(callback: Function) => {
						callback();
					}}
					onSubmit={(callback: Function, value: any) => {
						callback();
						handleAddNewWage(value, index);
					}}
					submitBtnText={'updateUserermisions.add'}
					cancelBtnText={'general.cancel'}
					buttonsCustomStyle={{
						height: 45,
						width: 125,
					}}
				/>
			</ModalConfirmActionsCustomStyle>
		),
	);
};

export const DataFormatTool = (
	method: string,
	data: any,
	formMethods: any,
	flexTableData: any,
	setInitialValues: Function,
	customerDraftData: any,
	wage?: string,
	comprehensive?: boolean,
	customerWageComponents?: any,
	removeValue?: boolean,
) => {
	if (method === 'getData') {
		const additionalData = comprehensive
			? AdditionalDataComprehensive(data.additionalData)
			: AdditionalData(data);
		const wageComponent = comprehensive
			? WageComponentComprehensive(data.wageComponent)
			: WageComponent(data, customerWageComponents?.wageComponent);
		const optionalDeductions = comprehensive
			? OptionalDeductionsComprehensive(data.optionalDeductions)
			: OptionalDeductions(
					data,
					customerWageComponents?.optionalDeductionsComponent,
			  );
		const format = [
			additionalData.format,
			wageComponent.format,
			optionalDeductions.format,
		];
		const comprehensiveFormat = {};
		format.forEach(item => {
			Object.assign(comprehensiveFormat, item);
		});
		return comprehensive ? comprehensiveFormat : format;
	} else if (method === 'addNew') {
		const componentType =
			wage === 'wageComponent'
				? WageComponent(data, [], true)
				: OptionalDeductions(data, [], true);

		return componentType;
	} else if (method === 'beforeSave') {
		Object.keys(data).forEach(key => {
			let newKey;
			if (key.includes('_optional')) {
				newKey = comprehensive
					? key.replace('_optional', '').split('_')[1]
					: key.replace('_optional', '').split('-')[0];
				data[newKey] = {
					code: newKey,
					componentId: getComponentIdbyCode(
						key.replace('_optional', ''),
						customerDraftData,
						'optionalDeductionsComponent',
					),
					componentType: 'OPTIONAL_DEDUCTION',
					value: data[key][key]
						? data[key][key]
						: removeValue
						? ''
						: null,
					name: getWageNameByCode(
						newKey,
						'OPTIONAL_DEDUCTION',
						flexTableData,
						comprehensive,
						customerWageComponents,
					),
				};
				delete data[key];
			} else if (key.includes('_amount')) {
				newKey = comprehensive
					? key.replace('_amount', '').split('_')[1]
					: key.replace('_amount', '').split('-')[0];
				data[newKey] = {
					code: newKey,
					componentId: getComponentIdbyCode(
						key.replace('_amount', ''),
						customerDraftData,
						'wageComponent',
					),
					componentType: 'WAGE',
					amount: data[key][key]
						? data[key][key]
						: removeValue
						? ''
						: null,
					name: getWageNameByCode(
						newKey,
						'WAGE',
						flexTableData,
						comprehensive,
						customerWageComponents,
					),
				};
				delete data[key];
				delete data[`${newKey}_amount`];
			} else if (key.includes('_rate')) {
				newKey = comprehensive
					? key.replace('_rate', '').split('_')[1]
					: key.replace('_rate', '').split('-')[0];
				data[newKey] = {
					code: newKey,
					componentId: getComponentIdbyCode(
						key.replace('_rate', ''),
						customerDraftData,
						'wageComponent',
					),
					componentType: 'WAGE',
					rate: data[key][key]
						? data[key][key]
						: removeValue
						? ''
						: null,
					name: getWageNameByCode(
						newKey,
						'WAGE',
						flexTableData,
						comprehensive,
						customerWageComponents,
					),
				};
				delete data[key];
				delete data[`${newKey}_rate`];
			} else if (key.includes('_sum')) {
				newKey = comprehensive
					? key.replace('_sum', '').split('_')[1]
					: key.replace('_sum', '').split('-')[0];
				data[newKey] = {
					code: newKey,
					componentId: getComponentIdbyCode(
						key.replace('_sum', ''),
						customerDraftData,
						'wageComponent',
					),
					componentType: 'WAGE',
					value: data[key][key]
						? data[key][key]
						: removeValue
						? ''
						: null,
					name: getWageNameByCode(
						newKey,
						'WAGE',
						flexTableData,
						comprehensive,
						customerWageComponents,
					),
				};
				delete data[key];
			} else if (key.includes('_neto')) {
				newKey = comprehensive
					? key.replace('_neto', '').split('_')[1]
					: key.replace('_neto', '').split('-')[0];
				data[newKey] = {
					code: newKey,
					componentType: 'WAGE',
					componentId: getComponentIdbyCode(
						key.replace('_neto', ''),
						customerDraftData,
						'wageComponent',
					),
					net: data[key][key] === 'נטו' ? true : false,
					name: getWageNameByCode(
						newKey,
						'WAGE',
						flexTableData,
						comprehensive,
						customerWageComponents,
					),
				};
				delete data[key];
			} else {
				newKey = comprehensive ? key.split('_')[1] : key;
				data[newKey] = {
					[newKey]: data[key][key]
						? data[key][key]
						: removeValue
						? ''
						: null,
					name: i18nService.translate(
						`FromMonthToMonthReports.formats.AdditionalData.${newKey}`,
					),
				};
				if (comprehensive) {
					delete data[key];
				}
			}
		});
		return data;
	} else if (method === 'defaultValues') {
		const additionalData = comprehensive
			? AdditionalDataComprehensive(data.additionalData)
			: AdditionalData(data);
		const wageComponent = comprehensive
			? WageComponentComprehensive(data.wageComponent)
			: WageComponent(data, customerWageComponents?.wageComponent);
		const optionalDeductions = comprehensive
			? OptionalDeductionsComprehensive(data.optionalDeductions)
			: OptionalDeductions(
					data,
					customerWageComponents?.optionalDeductionsComponent,
			  );
		setInitialValues({
			...additionalData.initialValues,
			...wageComponent.initialValues,
			...optionalDeductions.initialValues,
		});
		formMethods.reset({
			...additionalData.initialValues,
			...wageComponent.initialValues,
			...optionalDeductions.initialValues,
		});
	} else if (method === 'headerAdditionalData') {
		return {
			headerAdditionalData: [
				{
					code: 'payworkdays',
					name: i18nService.translate(
						'FromMonthToMonthReports.formats.AdditionalData.payworkdays',
					),
				},
				{
					code: 'workdays',
					name: i18nService.translate(
						'FromMonthToMonthReports.formats.AdditionalData.workdays',
					),
				},
				{
					code: 'workingHours',
					name: i18nService.translate(
						'FromMonthToMonthReports.formats.AdditionalData.workingHours',
					),
				},
				{
					code: 'vacationUsed',
					name: i18nService.translate(
						'FromMonthToMonthReports.formats.AdditionalData.vacationUsed',
					),
				},
				{
					code: 'sickLeaveUsed',
					name: i18nService.translate(
						'FromMonthToMonthReports.formats.AdditionalData.sickLeaveUsed',
					),
				},
			],
		};
	}
};
export const getWageNameByCode = (
	code: string,
	wage: string,
	flexTableData: any,
	comprehensive: boolean,
	customerWageComponents: any,
): string => {
	if (comprehensive) {
		const element =
			wage === 'WAGE' ? 'wageComponent' : 'optionalDeductionsComponent';

		const result = customerWageComponents[element].find((item: any) => {
			return item.code === code;
		});

		return result?.name;
	} else {
		let index;
		if (wage === 'WAGE') {
			index = 1;
		} else {
			index = 2;
		}
		return flexTableData[index][code].header[0].value;
	}
};
const getRateByAmount = (code: string, formMethods: any, isSum?: boolean) => {
	const data = formMethods.getValues();
	return data[code] ? data[code] : null;
};

const getComponentIdbyCode = (
	code: string,
	customerDraftData: any,
	type: string,
) => {
	const id = customerDraftData[type]?.find(
		(item: any) => (item.newCode || item.code) === code,
	)?.id;
	return id;
};

export const indexName = (index: number): string => {
	if (index === 0) {
		return 'HeaderAdditionalData';
	} else if (index === 1) {
		return 'wageComponent';
	} else if (index === 2) {
		return 'optionalDeductionsComponent';
	}
	return '';
};

export const removeDisplayComponent = (
	data: any,
	displayComponents: any,
	setDisplayComponents: Function,
	setRemoveOrAddNewComponent: Function,
	setTriggerRemoveOrAddNewComponent: Function,
	selectUnitId: any,
) => {
	httpService
		.api<fromMonthToMonthListLike>({
			type: 'removeDisplayComponent',
			query: { unitId: selectUnitId?.[0] },
			returnAllRes: true,
			data,
		})
		.then(async (res: any) => {
			if (res.status === 200) {
				const newData = displayComponents.filter(
					(item: any) => !_.isEqual(item, data),
				);
				setDisplayComponents(newData);
				setRemoveOrAddNewComponent(true);
				setTriggerRemoveOrAddNewComponent((prev: any) =>
					prev === 0 ? 1 : 0,
				);
			}
		});
};

export const getComponentTypeBeforeSend = (name: string): string => {
	if (name === 'wageComponent') {
		return 'WAGE';
	} else if (name === 'optionalDeductionsComponent') {
		return 'OPTIONAL_DEDUCTION';
	}
	return name;
};

export const handleAddBWageModal = async (
	customerWageComponents: any,
	getEmployeesFilter: Function,
	getDepartmentsFilter: Function,
	displayComponents: any,
	maxParameter: number,
	total: number,
	setDisplayComponents: Function,
	getUndoAction: Function,
	handleUndoIds: Function,
	setRemoveOrAddNewComponent: Function,
	setTriggerRemoveOrAddNewComponent: Function,
	setComprehensiveAddAdditional: Function,
	selectUnitId: any,
	editMode?: any,
) => {
	const headerAdditionalData = DataFormatTool('headerAdditionalData', null);

	const newCustomerWageComponents = {
		wageComponent: customerWageComponents.wageComponent.filter(
			(item: any) => item.showEdit === 2 && item.allowAdd,
		),
		optionalDeductionsComponent:
			customerWageComponents.optionalDeductionsComponent.filter(
				(item: any) => item.showEdit === 2 && item.allowAdd,
			),
	};
	await modalService.openModal(
		null,
		{
			separator: true,
			hideButtons: true,
			containerCustomStyle: {
				minHeight: 400,
				paddingHorizontal: 15,
				paddingBottom: 35,
				paddingTop: 20,
			},
		},
		(props: any) => (
			<ModalConfirmActionsCustomStyle {...props}>
				<AddNewWage
					{...props}
					list={{
						...headerAdditionalData,
						...newCustomerWageComponents,
					}}
					getEmployeesFilter={getEmployeesFilter}
					getDepartmentsFilter={getDepartmentsFilter}
					maxParameter={maxParameter}
					total={total}
					onCancel={(callback: Function) => {
						callback();
					}}
					selectUnitId={selectUnitId}
					editMode={editMode}
					onSubmit={(
						callback: Function,
						action: string,
						codeName: string,
						code: string,
						componentType: string,
						formData: any,
					) => {
						const ifComponentAllreadyShown =
							checkIfComponentAllreadyShown(
								componentType,
								code,
								displayComponents,
							);
						if (componentType === 'headerAdditionalData') {
							setComprehensiveAddAdditional(true);
						}
						if (action === 'MANUAL') {
							if (!ifComponentAllreadyShown) {
								const data = {
									componentType:
										componentType === 'wageComponent'
											? 'WAGE'
											: componentType ===
											  'headerAdditionalData'
											? 'ADDITIONAL'
											: 'OPTIONAL_DEDUCTION',
									code: Number(code) ? code : null,
								};
								saveDisplayComponent(
									data,
									displayComponents,
									setDisplayComponents,
									setRemoveOrAddNewComponent,
									setTriggerRemoveOrAddNewComponent,
									selectUnitId,
								);
							}
							callback();
						} else {
							onApproveComprehensiveWage(
								action,
								codeName,
								code,
								componentType,
								formData,
								saveDisplayComponent,
								displayComponents,
								setDisplayComponents,
								ifComponentAllreadyShown,
								getUndoAction,
								handleUndoIds,
								setRemoveOrAddNewComponent,
								setTriggerRemoveOrAddNewComponent,
								selectUnitId,
								callback,
							);
						}
					}}
					submitBtnText={'general.accept'}
					cancelBtnText={'general.cancel'}
					buttonsCustomStyle={{
						height: 45,
						width: 125,
					}}
				/>
			</ModalConfirmActionsCustomStyle>
		),
	);
};

const saveDisplayComponent = (
	data: any,
	displayComponents: any,
	setDisplayComponents: Function,
	setRemoveOrAddNewComponent: Function,
	setTriggerRemoveOrAddNewComponent: Function,
	selectUnitId: any,
) => {
	httpService
		.api<fromMonthToMonthListLike>({
			type: 'saveDisplayComponent',
			query: { unitId: selectUnitId?.[0] },
			returnAllRes: true,
			data,
		})
		.then(async (res: any) => {
			if (res.status === 200) {
				setDisplayComponents([...displayComponents, data]);
				setRemoveOrAddNewComponent(true);
				setTriggerRemoveOrAddNewComponent((prev: any) =>
					prev === 0 ? 1 : 0,
				);
			}
		});
};
const checkIfComponentAllreadyShown = (
	componentType: string,
	code: number,
	displayComponents: any,
) => {
	const componentTypeS =
		componentType === 'wageComponent'
			? 'WAGE'
			: componentType === 'headerAdditionalData'
			? 'ADDITIONAL'
			: 'OPTIONAL_DEDUCTION';

	const codeS = Number(code) ? code : null;

	const result = displayComponents.some(
		(component: any) =>
			component.code === codeS &&
			component.componentType === componentTypeS,
	);
	return result;
};

export const onApproveComprehensiveWage = (
	action: string,
	codeName: string,
	code: string,
	componentType: string,
	formData: any,
	saveDisplayComponent: Function,
	displayComponents: any,
	setDisplayComponents: Function,
	ifComponentAllreadyShown: boolean,
	getUndoAction: Function,
	handleUndoIds: Function,
	setRemoveOrAddNewComponent: Function,
	setTriggerRemoveOrAddNewComponent: Function,
	selectUnitId: any,
	firstCallBack: Function,
) => {
	modalService.openModal(
		null,
		{
			onCancel: (callback: Function) => {
				callback();
			},
			onSubmit: async (callback: Function) => {
				callback();
				modalService.openModal(
					null,
					{
						onSubmit: (callback: Function) => {
							callback();
							firstCallBack();
						},
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>
								modal.FromMonthToMonthReports.editWage.success.text
							</I18n>
						</AlertModal>
					),
				);
				const res: any = await httpService.api({
					type: 'saveWorkPaperDraftData',
					query: { unitId: selectUnitId?.[0] },
					data: formData,
				});
				const errors = res.totalFailed > 0 ? true : false;
				if (!errors) {
					getUndoAction();
					handleUndoIds();
				}
				modalService.closeAllModal();
				modalService.openModal(
					null,
					{
						onSubmit: (callback: Function) => {
							callback();
						},
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>
								{`modal.FromMonthToMonthReports.editWage.${
									res.jobId
										? 'asyncSuccess'
										: errors
										? 'failure'
										: 'success'
								}`}
							</I18n>

							<TouchableOpacity
								onPress={() => onDownload(res?.result)}
								style={{ marginTop: 5 }}
							>
								<I18n size={16} weight='normal' color='primary'>
									modal.FromMonthToMonthReports.editWage.details
								</I18n>
							</TouchableOpacity>
						</AlertModal>
					),
				);
				const ifComponentAllreadyShown = checkIfComponentAllreadyShown(
					componentType,
					code,
					displayComponents,
				);
				if (!ifComponentAllreadyShown) {
					const data = {
						componentType:
							componentType === 'wageComponent'
								? 'WAGE'
								: componentType === 'headerAdditionalData'
								? 'ADDITIONAL'
								: 'OPTIONAL_DEDUCTION',
						code: Number(code) ? code : null,
					};
					saveDisplayComponent(
						data,
						displayComponents,
						setDisplayComponents,
						setRemoveOrAddNewComponent,
						setTriggerRemoveOrAddNewComponent,
						selectUnitId,
					);
				} else {
					setDisplayComponents([...displayComponents]);
					setRemoveOrAddNewComponent(true);
					setTriggerRemoveOrAddNewComponent((prev: any) =>
						prev === 0 ? 1 : 0,
					);
				}
				callback();
			},
			submitBtnText: 'general.true',
			cancelBtnText: 'general.false',
			iconName: 'question',
		},
		(props: any) => (
			<ModalConfirmActions {...props}>
				{ifComponentAllreadyShown ? (
					<I18n>
						{`${i18nService.translate(
							'modal.FromMonthToMonthReports.editWage.question.msg.update',
						)}`}
					</I18n>
				) : (
					<I18n>
						{`${i18nService.translate(
							'modal.FromMonthToMonthReports.editWage.question.msg.a',
						)} ${i18nService.translate(
							`FromMonthToMonthReports.comprehensive.editWage.selectAction.${action}`,
						)} ${i18nService.translate(
							'modal.FromMonthToMonthReports.editWage.question.msg.b',
						)} ${codeName} ${i18nService.translate(
							'modal.FromMonthToMonthReports.editWage.question.msg.c',
						)}`}
					</I18n>
				)}
			</ModalConfirmActions>
		),
	);
};

export const onDownload = async data => {
	let elm = document.createElement('a');
	const csvFile = Base64.decode(data);
	const universalBOM = '\uFEFF';

	elm.href =
		'data:attachment/csv,' + encodeURIComponent(universalBOM + csvFile);

	elm.setAttribute(
		'download',
		`update_payrollcode${moment()
			.format('DD-MM-YYYY')
			.replaceAll('-', '')}.csv`,
	);
	elm.click();
};
