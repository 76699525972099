import React, { useContext, useMemo  } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { getReportsTimeSummary } from '../../../store/selectors/reports.selectors';
import MobileHeader from '../Header/MobileHeader.component';
import ReportsTimeSum from './ReportsTimeSum.component';
import { CalendarData } from './ReportsCalendar.container';

const convertHoursFormat = (
	minutesValue: any,
	format?: string,
	inminutes?: boolean,
) => {
	if (minutesValue) {
		const value = inminutes ? minutesValue : Math.floor(minutesValue * 60);
		if (format === 'HH:mm') {
			const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
			const minutes =
				value % 60 !== 0
					? value % 60 < 10
						? `0${value % 60}`
						: `${value % 60}`
					: '00';
			return `${hours}:${minutes}`;
		}
		return `${(value / 60).toFixed(2)}`;
	}
	return '00:00';
};

const ReportsTimeSumContainer = () => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const calendarData = useContext(CalendarData);
	const [neededTime, reportedTime] = useMemo(() => {
		const reported = convertHoursFormat(calendarData?.total, 'HH:mm', true);
		const needed = convertHoursFormat(
			calendarData?.standardTotal,
			'HH:mm',
			true,
		);
		return [needed, reported];
	}, [calendarData]);

	return (
		<>
			<ReportsTimeSum
				isMobile = {isMobile}
				error={calendarData?.total < calendarData?.standardTotal}
				neededTime={neededTime}
				reportedTime={reportedTime}
			/>
		</>
	);
};

export default ReportsTimeSumContainer;
