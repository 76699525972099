import { Report101FiltersType } from './Report101Page.utils';
import * as reports101Actions from './Report101Page.actions';

export interface Report101Request {
	page: number;
	pageSize: number;
	sortBy: string | undefined;
	sortDirection: 'ASC' | 'DESC' | '';
	search: '';
	filter: Record<Report101FiltersType, string[]>;
	getSelectedUnits: Function;
}

export const initialState: Report101Request = {
	page: 0,
	pageSize: 30,
	sortBy: 'submitDate',
	sortDirection: 'DESC',
	search: '',
	filter: {
		dep: [],
		user_id: [],
		year: [],
		status: [],
		unitIds: [],
	},
};

const reducer = (state: Report101Request = initialState, action: any) => {
	switch (action.type) {
		case reports101Actions.RESET_PAGE:
			return { ...state, page: 0 };
		case reports101Actions.SET_ALL_FILTERS:
			return { ...action.payload };
		case reports101Actions.INCREMENT_PAGE:
			return {
				...state,
				page: state.page + 1,
			};
		case reports101Actions.SET_SORT:
			return {
				...state,
				page: 0,
				search: state.search,
				filter: state.filter,
				sortBy: action.payload.name,
				sortDirection: action.payload.direction,
			};
		case reports101Actions.UPDATE_UNITIDS:
			return {
				...state,
				filter: {
					...state.filter,
					unitIds: action.payload.isMulti
						? action.payload.value.map((val: any) => val.id || val)
						: [action.payload.value],
					dep: [],
					user_id: [],
				},
			};
		case reports101Actions.SET_SEARCH_QUERY:
			return {
				...state,
				page: 0,
				search: action.payload,
			};
		case reports101Actions.SET_FILTER:
			const { isMultiselect } = action.payload;
			let filterData: string[] = [];
			if (isMultiselect && !action.payload.value.length) {
				filterData = [];
			} else if (isMultiselect || Array.isArray(action.payload.value)) {
				filterData = action.payload.value
					.filter((v: any) => v?.id !== 'all')
					.map((v: any) => v.id);
			} else {
				filterData = action.payload.value ? [action.payload.value] : [];
			}

			return {
				...state,
				page: 0,
				filter: {
					...state.filter,
					[action.payload.filter]: filterData,
				},
			};
		case reports101Actions.RESET_FILTERS:
			return {
				...state,
				sortBy: '',
				sortDirection: 'DESC',
				search: '',
				filter: {
					dep: [],
					user_id: [],
					year: [],
					status: [],
				},
			};
		default:
			return state;
	}
};

export default reducer;
