import { ButtonGroup, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PaginationPageButton from './PaginationPageButton';

const useStyles = makeStyles((theme: any) => ({
	leftArrow: {
		margin: '0 10px',
	},
	buttons: {
		margin: '0 10px',
		border: 'none',
		backgroundColor: 'white',
	},
	buttonlast: {
		border: 'none',
		backgroundColor: 'white',
	},
}));

interface PaginationProps {
	pageCurrent: number;
	pageTotal: number;
	onChange: (value: number) => void;
	pagesVisible: number;
}

const Pagination = ({
	pageCurrent,
	pageTotal,
	onChange,
	pagesVisible,
}: PaginationProps) => {
	const classes = useStyles();

	const pagesVisibleCalc =
		pageTotal < pagesVisible ? pageTotal : pagesVisible;

	return (
		<ButtonGroup size='small'>
			<IconButton
				size='small'
				className={classes.leftArrow}
				onClick={() => onChange(pageCurrent - 1)}
				disabled={pageCurrent === 1}
			>
				<NavigateBeforeIcon />
			</IconButton>

			<PaginationPageButton
				pagesVisible={pagesVisibleCalc}
				onChange={onChange}
				pageCurrent={pageCurrent}
				totalPages={pageTotal}
			/>

			<IconButton
				size='small'
				onClick={() => onChange(pageCurrent + 1)}
				disabled={
					pageTotal === pagesVisibleCalc || pageCurrent >= pageTotal
				}
			>
				<NavigateNextIcon />
			</IconButton>
		</ButtonGroup>
	);
};

export default Pagination;
