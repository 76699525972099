import * as loginActions from '../constants/loginActions';
import {
	LoginState,
	StoreAction,
	LoginCredentials,
} from '../../interfaces/redux.interface';
import {
	EmployerPrivileges,
	Privileges,
	EmployeePrivileges,
	RepresentativePrivileges,
} from '../../constants/roleTypes.constant';
import moment from 'moment';

const initialState: LoginState = {
	token: undefined,
	refresh_token: undefined,
	roles: [],
	customer: '',
	loggedIn: false,
	error: false,
	errorMessage: '',
	complete: false,
	employer: { id: null, name: '' },
	employerList: [],
	setWorkerCardAlertOnChangedEmp: false,
	isInWorkerCard: false,
	modules: [],
	user: {
		customer_setting: {
			allowUpdateReport: false,
			approveManualReport: false,
			approveManualReportFromDate: '',
			employeeFormMailRemainderFrequency: '',
			eventSelectionType: '',
			events: null,
			lockDay: null,
			maxShiftHour: 12,
			requestEmployeeIdFile: false,
			selectedEventIds: null,
			worklogLockType: '',
			worklogReportFormat: '',
		},
		customer_privilege: null,
		customer_modules: null,
		location: false,
		first_name: '',
		last_name: '',
		projects: false,
		privileges: [],
		employeeRolePrivileges: [],
		employerRolePrivileges: [],
		repRolePrivileges: [],
		repEmployerRolePrivileges: [],
	},
	version: '',
};

const loginReducer = (
	state: typeof initialState = initialState,
	action: StoreAction<LoginCredentials> | any,
): LoginState => {
	switch (action.type) {
		case loginActions.LOGIN_SUCCESS:
			return {
				...state,
				token: action.payload?.token,
				refresh_token: action.payload?.refresh_token,
				roles: action.payload?.roles,
				customer: action.payload?.customer,
				modules: action.payload?.modules,
				loggedIn: true,
				error: false,
				errorMessage: '',
				complete: true,
				employer: action.payload?.employer,
				version: action.payload?.version,
			};
		case loginActions.EMPLOYER_LIST_SUCCESS:
			return {
				...state,
				employerList: action.payload?.employerList.sort(
					(a: any, b: any) => {
						if (
							a.finish_work &&
							b.finish_work &&
							moment(a.finish_work, 'YYYY-MM-DD').isBefore(
								moment(b.finish_work, 'YYYY-MM-DD'),
							)
						) {
							return 1;
						}
						if (
							a.finish_work &&
							b.finish_work &&
							!moment(a.finish_work, 'YYYY-MM-DD').isBefore(
								moment(b.finish_work, 'YYYY-MM-DD'),
							)
						) {
							return -1;
						}
						if (!b.finish_work && a.finish_work) {
							return 1;
						}
						return 0;
					},
				),
			};
		case loginActions.SELECT_EMPLOYER_SUCCESS:
			return {
				...state,
				employer: action.payload.employer,
			};
		case loginActions.SET_WORKER_CARD_ALERT:
			return {
				...state,
				setWorkerCardAlertOnChangedEmp: action.payload,
			};
		case loginActions.SET_IS_IN_WORKER_CARD:
			return {
				...state,
				isInWorkerCard: action.payload,
			};
		case loginActions.NO_PERMISSIONS:
			console.log('loginActions.NO_PERMISSIONS');
			return {
				...initialState,
				error: true,
				errorMessage: 'NO_PERMISSIONS',
				complete: true,
			};
		case loginActions.OUTDATED_APP_VERSION:
			return {
				...initialState,
				error: true,
				errorMessage: 'OUTDATED_APP_VERSION',
				complete: true,
				version: action.payload?.version,
			};
		case loginActions.LOGIN_FAILURE:
		case loginActions.FORGOT_PASSWORD_FAILURE:
		case loginActions.RESET_PASSWORD_FAILURE:
			return {
				...initialState,
				error: true,
				errorMessage: action.payload?.errorMessage,
				complete: true,
			};
		case loginActions.RESET_LOGIN_ERROR:
			return {
				...state,
				error: false,
				errorMessage: undefined,
				complete: true,
			};
		case loginActions.LOGOUT:
			return { ...initialState, complete: true };
		case loginActions.LOGIN_INITIAL_STATE:
			return {
				...initialState,
				complete: true,
			};
		case loginActions.REFRESH_TOKEN:
			return {
				...state,
				token: action.payload?.token,
				refresh_token: action.payload?.refresh_token,
			};
		case loginActions.VERIFY_FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				complete: true,
				token: action.payload?.token,
			};
		case loginActions.LOCAL_UPDATE_USER_DATA:
			return {
				...state,
				user: {
					...state.user,
					...action.payload,
				},
			};
		case loginActions.USER_DATA_SUCCESS:
			const repEmployerRole =
				action.payload.user?.role_privilege.REPRESENT?.filter(role =>
					EmployerPrivileges.includes(role),
				);
			const repRoles =
				action.payload.user?.role_privilege.REPRESENT?.filter(role =>
					RepresentativePrivileges.includes(role),
				);

			const { customer_privilege, customer_modules } = !Object.keys(
				action.payload.user?.role_privilege,
			).length
				? { customer_privilege: {}, customer_modules: {} }
				: action.payload.user.customer_privilege.reduce(
						(res: any, current: any) => ({
							customer_privilege: {
								...res.customer_privilege,
								[current.customerId]: current.privileges,
							},
							customer_modules: {
								...res.customer_modules,
								[current.customerId]: current.modules,
							},
						}),
						{ customer_privilege: {}, customer_modules: {} },
				  );

			return {
				...state,
				user: {
					...action.payload.user,
					allowMultipleSelection:
						action.payload.user?.allow_multiple_selection,
					defaultUnitIds: action.payload.user?.default_unit_ids,
					leadingUnitId: action.payload.user?.leading_unit_id,
					customer_privilege,
					customer_modules,
					//privileges: [...(action.payload.user?.privileges || [])],
					employeeRolePrivileges: !Object.keys(
						action.payload.user?.role_privilege,
					).length
						? [Privileges.ROLE_PERSONAL_INFORMATION_ONLY]
						: action.payload.user?.role_privilege.EMPLOYEE,
					employerRolePrivileges:
						action.payload.user?.role_privilege.MANAGER,
					repRolePrivileges: repRoles,
					repEmployerRolePrivileges: repEmployerRole,
				},
			};
		default:
			return { ...state };
	}
};

export default loginReducer;
