

import React, { useEffect, useState } from "react"
import { TouchableHighlight, View, StyleSheet, TouchableOpacity } from "react-native"
import {Base64} from 'js-base64';
import Typography from '../../../components/shared/Typography/Typography.component';
import WebCheckBox from 'expo-checkbox';
import I18n from "../../../components/shared/i18n/I18n.component"
import colors from "../../../config/colors"
import getShadowStyle from "../../../config/shadow"
import moment from "moment";


export const RadioSection = ({ startSelect, onSelect }) => {
  const [addChecked, setAddChecked] = useState(false);
  const [removeChecked, setRemoveChecked] = useState(false);

  useEffect(() => {
    if (!startSelect) {
      setAddChecked(false);
      setRemoveChecked(false)
    }
  }, [startSelect]);

  const handleChange = (val) => {
    onSelect(val);
    if (val === 'ADD') {
      setAddChecked(true);
      setRemoveChecked(false)
    } else {
      setAddChecked(false);
      setRemoveChecked(true)
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => handleChange('REMOVE')}
        style={[styles.radioContainer, { marginRight: 25 }]}
        disabled={!startSelect}
      >
        <I18n size={14} weight='normal'>
          updateUserermisions.remove
        </I18n>
        <View style={[styles.radioBorder, { borderColor: removeChecked ? colors.lightPrimary : colors.grey }]}>
          <View style={[styles.innerSircle, { backgroundColor: removeChecked ? colors.lightPrimary : colors.white }]} />
        </View>
      </TouchableOpacity>

      <TouchableOpacity
        disabled={!startSelect}
        onPress={() => handleChange('ADD')}
        style={styles.radioContainer}
      >
        <I18n size={14} weight='normal'>
          updateUserermisions.add
        </I18n>
        <View style={[styles.radioBorder, { borderColor: addChecked ? colors.lightPrimary : colors.grey }]}>
          <View style={[styles.innerSircle, { backgroundColor: addChecked ? colors.lightPrimary : colors.white }]} />
        </View>
      </TouchableOpacity>
    </View>
  )
};


export const NotificationModal = ({ onAccept, onNext }: any) => {
  return (
    <View style={styles.sendNewRequestContainer}>
      <View style={styles.sendNewRequestText}>
        <I18n>updateUserermisions.areYouWantToUpdate</I18n>
      </View>

      <View style={styles.buttonContainer}>
        <TouchableHighlight onPress={() => onAccept(onNext)} underlayColor='transparent'>
          <View style={styles.button}>
            <I18n size={16} weight='normal' color='white'>
              general.accept
            </I18n>
          </View>
        </TouchableHighlight>

        <TouchableHighlight onPress={() => onNext()} underlayColor='transparent'>
          <View style={styles.cancelButton}>
            <I18n size={16} weight='normal' color="lightPrimary">
              general.cancel
            </I18n>
          </View>
        </TouchableHighlight>
      </View>
    </View>
  )
};


export const RenderRow = ({data, setCheckedRoles, checkedRoles, showError}) => {

  const onCheck = (val) => {
    if (val) {
      setCheckedRoles(prev => ({ ...prev, [data.id]: null }))
    } else {
      const temp = { ...checkedRoles };
      delete temp[data.id];
      setCheckedRoles(temp)
    }
  };

  return (
    <View style={styles.rowContainer}>
      <View style={{ flexDirection: 'row-reverse', width: 150 }}>
        <WebCheckBox
          value={checkedRoles.hasOwnProperty(data?.id)}
          onValueChange={onCheck}
          color={colors.lightPrimary}
          style={{
            width: 15,
            height: 15,
            borderRadius: 4,
            marginLeft: 10
          }}
        />
        <Typography size={14} weight='normal'>
          {data.name}
        </Typography>
      </View>
      <View style={{ width: 200, height: 35 }}>
        <RadioSection startSelect={checkedRoles.hasOwnProperty(data?.id)} onSelect={(val) => setCheckedRoles(prev => ({ ...prev, [data.id]: val }))} />
        {(showError && !checkedRoles[data.id] && checkedRoles.hasOwnProperty(data?.id)) && <I18n size={12} color='red' style={{ textAlign: 'left' }}>updateUserermisions.error</I18n>}
      </View>
    </View>
  )
}

export const onDownload = async(data) => {
  let elm = document.createElement('a');
  const csvFile = Base64.decode(data)
  const universalBOM = "\uFEFF";

  elm.href = 'data:attachment/csv,' +  encodeURIComponent(universalBOM+csvFile);

  elm.setAttribute(
    'download',
    `UPDATE_USER_ROLE${moment().format('DD-MM-YYYY').replaceAll('-', '')}.csv`,
  );
  elm.click();
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  rowContainer: {
    flexDirection: 'row-reverse',
    marginBottom: 12
  },
  radioContainer: {
    flexDirection: 'row',
    width: 50,
    alignItems: 'center'
  },
  radioBorder: {
    borderWidth: 1.5,
    backgroundColor: colors.white,
    height: 15,
    width: 15,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 7
  },
  innerSircle: {
    height: 11,
    width: 11,
    borderRadius: 7,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 126,
    backgroundColor: colors.lightPrimary,
    borderRadius: 45,
    ...getShadowStyle(20, 'rgb(59, 112, 164)'),
  },
  sendNewRequestContainer: {
    paddingVertical: 39,
    flexDirection: 'column',
    shadowColor: 'rgba(0, 0, 0, 0)',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    backgroundColor: colors.white,
    borderRadius: 20,
    height: 239,
    width: 334,
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-around',
    width: '100%'
  },
  sendNewRequestText: {
    paddingHorizontal: 50,
    marginBottom: 20
  },
  cancelButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 126,
    backgroundColor: 'white',
    borderRadius: 45,
    borderColor: colors.lightPrimary,
    borderWidth: 1,
    ...getShadowStyle(20, 'rgb(59, 112, 164)'),
  },
  buttonOk: {
    width: 92,
    height: 40,
    justifyContent: 'center',
    backgroundColor: colors.lightPrimary,
  },
})