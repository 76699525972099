import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text, Dimensions } from 'react-native';
import { default as ReactSelect, components } from 'react-select';
import { Checkbox, makeStyles } from '@material-ui/core';
// @ts-ignore
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import classnames from 'classnames';
import { usePrevious } from '../../../hooks/usePrevious.hook';

interface MultiselectProps {
	config: {
		type?: string;
		name: string;
		options?: { label: string; value: string }[];
		placeholder?: string;
		styles?: any;
		disabled?: boolean;
	};
	selectedOptions: SelectedOptions[];
	onChangeCheckBox: (selections: any, item: any) => void;
	onShowHideList: () => void;
	showList: boolean;
}

const useStyles = makeStyles(() => ({
	chip: {
		borderRadius: 2,
		color: 'hsl(0, 0%, 20%)',
		fontSize: '85%',
		padding: 3,
		paddingLeft: 6,
		margin: 2,
		backgroundColor: 'hsl(0, 0%, 90%)',
	},
	checkbox: {
		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
		},
	},
	disabled: {
		pointerEvents: 'auto',
		color: colors.grey,
	},
	checked: {},
}));

const customStyles = (props: any) => {
	// You can use the 'styles' prop to override the styling of the component.
	const {
		control = {},
		menu,
		option,
		indicatorsContainer,
		indicatorSeparator,
		clearIndicator,
		container,
		placeholder = {},
		singleValue = {},
		input,
		menuPortal,
		menuList,
	} = props.styles || props.config?.styles || {};

	return {
		control: (base: any) => ({
			...base,
			maxWidth: 160,
			minHeight: 32,
			height: 32,
			backgroundColor: colors.white,
			color: colors.darkGrey,
			borderColor: colors.filterBorder,
			borderWidth: 1,
			boxShadow: 'none',
			minWidth: 99,
			borderRadius: 2,
			'&:hover': {
				borderColor: colors.filterBorder,
			},
			width: props.config.styles?.width
				? props.config.styles?.width + 2
				: 'unset',
			...props.style,
			...control,
		}),
		menu: (provided: any) => ({
			...provided,
			top: 34,
			left: 0,
			width: '100%',
			backgroundColor: colors.white,
			borderWidth: 1,
			borderRadius: 2,
			borderColor: 'transparent',
			margin: 0,
			zIndex: 9999999999999,
			...menu,
		}),
		menuPortal: (base: any) => ({
			...base,
			...menuPortal,
		}),
		menuList: (base: any) => ({
			...base,
			...menuList,
		}),
		valueContainer: (base: any) => ({
			...base,
			height: 'inherit',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			visibility: 'visible',
			flexWrap: 'unset',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			display: 'flex',
			alignItems: 'center',
			height: 29,
			marginTop: 6,
			padding: 0,
			backgroundColor: state.isSelected
				? colors.selectOptionSelected
				: state.isFocused
				? colors.selectOptionFocused
				: colors.white,
			...option,
		}),
		indicatorsContainer: (base: any) => ({
			...base,
			height: 'inherit',
			...indicatorsContainer,
		}),
		dropdownIndicator: (base: any) => ({
			...base,
			color: colors.primary,
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			visibility: 'hidden',
			...indicatorSeparator,
		}),
		clearIndicator: (base: any) => ({
			...base,
			visibility: 'hidden',
			...clearIndicator,
		}),
		container: (provided: any) => ({
			...provided,
			width: '100%',
			...container,
		}),
		placeholder: (base: any) => ({
			...base,
			fontSize: 14,
			color: colors.darkGrey,
			fontFamily: 'RubikRegular',
			...placeholder,
		}),
		input: (provided: any) => ({
			...provided,
			// ...icon(props),
			...input,
			padding: 0,
			margin: 0,
			color: colors.darkGrey,
		}),
	};
};

const ValueContainer = (props: any) => {
	const hiddenChips = useMemo(
		() => props.selectProps.value?.length - 1,
		[props.selectProps.value],
	);
	const prevMenuIsOpen = usePrevious(props.selectProps.menuIsOpen);
	const prevValue = usePrevious(props.selectProps.value?.length);

	useEffect(() => {
		if (
			props.selectProps.menuIsOpen &&
			(props.selectProps.menuIsOpen !== prevMenuIsOpen ||
				props.selectProps.value?.length !== prevValue)
		) {
			props.selectProps.selectRef.current?.focus();
		}
	}, [props.selectProps.menuIsOpen, props.selectProps.value?.length]);

	const classes = useStyles();
	return (
		<components.ValueContainer {...props}>
			{props.selectProps.value?.length && props.selectProps.menuIsOpen
				? React.cloneElement(props.children[1])
				: null}
			{props.selectProps.value?.length ? (
				!props.selectProps.menuIsOpen ? (
					<>
						{props.children[0].slice(0, 1)}
						{hiddenChips ? (
							<div
								className={classes.chip}
							>{`+${hiddenChips}`}</div>
						) : null}
					</>
				) : null
			) : (
				props.children
			)}
		</components.ValueContainer>
	);
};

const ValueContainerNoChips = (props: any) => {
	return (
		<components.ValueContainer {...props}>
			{props.selectProps.value?.length
				? React.cloneElement(props.children[1])
				: null}
			{props.selectProps.value?.length
				? !props.selectProps?.selectRef?.current?.select?.state
						?.isFocused && (
						<components.Placeholder {...props}>
							{props.selectProps.placeholder}
						</components.Placeholder>
				  )
				: props.children}
		</components.ValueContainer>
	);
};

const CheckboxOption = (props: any) => {
	const classes = useStyles();

	return (
		<components.Option {...props}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					padding: '4px 0',
				}}
				title={props.children}
			>
				<Checkbox
					size='small'
					color='default'
					checked={props.isSelected}
					classes={{
						root: classes.checkbox,
						checked: classnames({
							[classes.checked]: classes.checked,
							[classes.disabled]: props.isDisabled
								? classes.disabled
								: null,
						}),
					}}
				/>
				<div
					style={{
						textAlign: 'right',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						fontSize: 14,
						color: colors.darkGrey,
						fontFamily: 'RubikRegular',
						overflow: 'hidden',
						paddingLeft: 5,
					}}
				>
					{props.children}
				</div>
			</div>
		</components.Option>
	);
};

interface SelectedOptions {
	label: string;
	value: string;
}

const Multiselect = ({
	showList,
	config,
	selectedOptions,
	onChangeCheckBox,
	onShowHideList,
	style,
	styles,
	...restProps
}: // onChange
MultiselectProps | any) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const selectRef = useRef() as any;

	const renderedPlaceholder = useMemo(
		() =>
			config.placeholder
				? i18nService.translate(
						`filter.multiselect.${config.placeholder}`,
				  )
				: '',
		[config.placeholder],
	);

	// const selectValue = useMemo(() => {
	// 	if (selectedOption && !restProps?.getOptionLabel) {
	// 		return {
	// 			...selectedOption,
	// 			label: i18nService.translate(selectedOption.name),
	// 		};
	// 	}
	// 	return selectedOption;
	// }, [selectedOption]);

	const defaultGetOptionLabel = (option: any) =>
		i18nService.translate(option.label);

	// Closed menu display
	const onMenuClose = () => {
		setMenuIsOpen(false);
		// setValueContainer(() => valueContainerBlur);
		// setIsFocused(false);
	};

	// Opened menu display
	const onMenuOpen = () => {
		setMenuIsOpen(true);
		// setValueContainer(() => valueContainerFocus);
		// setIsFocused(true);
	};

	return (
		<ReactSelect
			components={{
				Option: CheckboxOption,
				ValueContainer: !restProps.noSelection
					? ValueContainer
					: ValueContainerNoChips,
			}}
			isRtl
			isMulti
			ref={ref => (selectRef.current = ref)}
			selectRef={selectRef}
			placeholder={renderedPlaceholder || ''}
			closeMenuOnSelect={false}
			//   className={styles.Select}
			onChange={(options: any) => onChangeCheckBox(options)}
			value={selectedOptions}
			filterOption={restProps?.filterOption}
			menuPortalTarget={restProps?.menuPortalTarget}
			backspaceRemovesValue
			isClearable={false}
			defaultValue={restProps?.defaultValue}
			isDisabled={config.disabled}
			options={config.options}
			menuIsOpen={restProps.menuIsOpenDefault || menuIsOpen}
			onMenuClose={onMenuClose}
			onMenuOpen={onMenuOpen}
			styles={customStyles({ style, styles, config })}
			maxMenuHeight={restProps?.maxMenuHeight}
			getOptionLabel={restProps?.getOptionLabel || defaultGetOptionLabel}
			getOptionValue={
				restProps?.getOptionValue || ((opt: any) => opt.value)
			}
			hideSelectedOptions={false}
			onInputChange={restProps?.onInputChanged}
			onBlur={restProps?.onBlur}
			noOptionsMessage={() => null}
			inputValue={restProps?.inputValue}
			onFocus={restProps?.onFocus}
			blurInputOnSelect={restProps?.blurInputOnSelect}
			key={config.key}
		/>
	);
};

export default Multiselect;
