import moment from 'moment';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { createGlobalStyle } from 'styled-components';
import colors from '../../../../config/colors';
import ImageIcon from '../../Icon/ImageIcon.component';
import MaskedInput from 'react-text-mask';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { Platform } from 'react-native';
import 'react-datepicker/dist/react-datepicker.css';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import he from 'date-fns/locale/he';
import { i18nService } from '../../../../services/i18n.service';

registerLocale('he', he);

const GlobalStyle = createGlobalStyle`
  .react-datepicker-popper {
	z-index: 900
  }
`;

const Datepicker = ({
	value,
	onChange,
	onBlur,
	minDate,
	maxDate,
	error,
	disabled,
	format = 'DD/MM/YYYY',
	placeholder,
	deleteable,
	...rest
}: any) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	const currentValue = useMemo(() => value, [value]);
	const _onChange = useMemo(() => onChange, [onChange]);

	useEffect(() => {
		value && onBlur();
	}, [value]);

	const ExampleCustomInput = forwardRef(
		({ value, onClick, onBlur, onChange, ...rest }: any, ref) => {
			const [localValue, setLocalValue] = useState(value);

			useEffect(() => {
				setLocalValue(localValue);
			}, [value]);
			const onClearValue = () => {
				_onChange({
					target: {
						value: '',
					},
				});
				setLocalValue('');
			};

			const _onBlur = async () => {
				try {
					if (localValue.includes('_')) {
						setLocalValue(value);
					} else {
						const val =
							!localValue || localValue === ''
								? localValue
								: !moment(localValue, 'DD/MM/YYYY').isValid() ||
								  moment(localValue, 'DD/MM/YYYY').isAfter(
										maxDate,
								  )
								? maxDate
									? maxDate
									: ''
								: localValue;
						await onChange({
							target: {
								value: val,
							},
						});

						setLocalValue(
							val ? moment(val).format('DD/MM/YYYY') : val,
						);
					}
				} catch (e) {
					console.log(e);
				}
			};

			return (
				<div
					style={{
						height: 40,
						maxWidth: isMobile ? 'unset' : 190,
						borderBottom: `1px solid ${
							colors[error ? 'red' : 'primary']
						}`,
						borderWidth: isMobile ? 1 : 0,
						backgroundColor:
							colors[error ? 'brightRed' : 'lightBlue'],
						borderRadius: 5,
						padding: 10,
						display: 'flex',
						flexDirection: 'row-reverse',
						flex: 1,
						boxSizing: 'border-box',
					}}
				>
					<MaskedInput
						disabled={disabled || isMobile}
						value={localValue}
						onChange={e => {
							const text = e?.target?.value;
							setLocalValue(text);
							currentValue !== text &&
								(!text || text === '') &&
								_onChange({
									target: {
										value: text,
									},
								});
						}}
						mask={[
							/\d/,
							/\d/,
							'/',
							/\d/,
							/\d/,
							'/',
							/\d/,
							/\d/,
							/\d/,
							/\d/,
						]}
						placeholder={
							placeholder && i18nService.translate(placeholder)
						}
						onBlur={_onBlur}
						style={{
							height: 16,
							flex: 1,
							textAlign: 'right',
							width: '90%',
							fontSize: isMobile ? 16 : 14,
							backgroundColor: 'transparent',
							borderWidth: 0,
							...Platform.select({
								web: {
									outlineWidth: 0,
								},
							}),
						}}
					/>
					{deleteable && value ? (
						<TouchableWithoutFeedback
							disabled={disabled}
							onPress={onClearValue}
							style={{ padding: 6, marginLeft: 10 }}
						>
							<ImageIcon name='remove' width={10} height={10} />
						</TouchableWithoutFeedback>
					) : null}
					<TouchableWithoutFeedback
						onPress={onClick}
						disabled={disabled}
					>
						<ImageIcon name='calendar_web' width={20} height={20} />
					</TouchableWithoutFeedback>
				</div>
			);
		},
	);

	return (
		<>
			<GlobalStyle />
			<DatePicker
				{...rest}
				selected={
					value ? new Date(moment(value, format).format()) : undefined
				}
				minDate={minDate || new Date('01/01/1920')}
				dateFormat='dd/MM/yyyy'
				maxDate={maxDate}
				onChange={(date: any) => {
					date !== null &&
						onChange(
							!!date ? moment(new Date(date)).format(format) : '',
						);
				}}
				onBlur={onBlur}
				customInput={<ExampleCustomInput />}
				scrollableYearDropdown={true}
				yearDropdownItemNumber={100}
				showYearDropdown
				disabled={disabled}
				locale='he'
			/>
		</>
	);
};

export default Datepicker;
