import { Button, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

const useStyles = makeStyles((theme: any) => ({
	buttons: {
		margin: '0 10px',
		border: 'none',
		backgroundColor: 'white',
	},
}));

interface Props {
	pagesVisible: number;
	onChange: (value: number) => void;
	pageCurrent: number;
	totalPages: number;
}

const PaginationPageButton = React.memo(
	({ pagesVisible, onChange, pageCurrent, totalPages }: Props) => {
		const classes = useStyles();
		const currentActivePageNumber = (el: number) => {
			if (totalPages === 1 && pageCurrent === 0) return pageCurrent + 1;
			if (pageCurrent === 1) return pageCurrent + el;
			if (pageCurrent === totalPages && pageCurrent === 2)
				return pageCurrent + el - 1;
			if (pageCurrent === totalPages && pageCurrent > 2)
				return pageCurrent + el - 2;
			return pageCurrent - 1 + el;
		};

		return (
			<>
				{Array.from(Array(pagesVisible).keys()).map(el => (
					<Button
						key={el}
						className={classes.buttons}
						onClick={() => onChange(currentActivePageNumber(el))}
						style={
							currentActivePageNumber(el) === pageCurrent
								? { fontWeight: 800 }
								: { fontWeight: 400 }
						}
					>
						{currentActivePageNumber(el)}
					</Button>
				))}
			</>
		);
	},
);

export default PaginationPageButton;
