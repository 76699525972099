import { EventData } from './../models/event.model';
import { RootState } from './../store/reducers/root.reducer';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { Project } from '../models/project.model';
import { CalendarDay } from '../models/calendar.model';
import { FullWorklog } from '../models/fullWorklog.model';
import { Option } from './option.interface';
import { Privileges } from '../constants/roleTypes.constant';

export type ThunkedAction = ThunkAction<
	void,
	RootState,
	unknown,
	Action<string>
>;
export interface StoreState {
	login: LoginState;
	busyIndicator: BusyCounterState;
	projectList: ProjectListState;
	dateTime: CurrentDateTime;
}
export interface StoreAction<T = {}> {
	type: string;
	payload: T;
}
export interface CurrentDateTime {
	currentDateTime: string;
}
export interface ConfigState {
	busyCounter: number;
	navigateTo?: string;
	hideChat?: boolean;
	defaultDisplayMode?: string;
	defaultDisplayWageMode?: string;
	files101?: any;
	isMaintenance: boolean;
}

export interface RepEnterModeState {
	customerHp?: number;
	customerId?: number;
	privilegs?: string[];
	customerName?: string;
	privileges: Privileges[];
	employeeRolePrivileges?: Privileges[];
	employerRolePrivileges?: Privileges[];
	repRolePrivileges?: Privileges[];
	customer_privilege: any;
	allowMultipleSelection?: boolean;
	defaultUnitIds?: number[];
	leadingUnitId?: number[];
	customer_modules?: any;
	goBackTo: String;
}

export enum Period {
	MONTH = 'MONTH',
	YEAR = 'YEAR',
	QUARTERLY = 'QUARTERLY',
}
export interface FiltersState {
	locations: Option[];
	employees: Option[];
	customersAutocomplete: Option[];
	projects: Option[];
	events: Option[];
	years: string[];
	months: string[];
	specials: string[];
	statuses: string[];
}

export interface Filters101State {}

type EmployeesForVacationPage = {
	total: number;
	data: EmployeesForVacationPageList[];
	hasNext: boolean;
};

type EmployeesForVacationPageList = {
	id: string;
	name: string;
	teudatZeut: string;
};

type Module = {
	customer: string;
	modules: {
		id: number;
		name: string;
		status: 'ACTIVE' | 'INACTIVE';
	};
};
export type OperativeModule = {
	customer_id: number;
	finish_work: string;
	hp: string;
	id: string;
	name: string;
	start_work: string;
};
export enum UserRoles {
	EMPLOYEE = 'EMPLOYEE',
	MANAGER = 'MANAGER',
	REPRESENT = 'REPRESENT',
}
export interface LoginState {
	complete: boolean;
	loggedIn: boolean;
	error: boolean;
	errorMessage?: string;
	token?: string;
	refresh_token?: string;
	roles: Array<any>;
	customer: string;
	modules: Module[];
	user?: {
		user_roles?: UserRoles[];
		customer_setting: CustomerSetting;
		location: LocationItem[] | boolean;
		first_name: string;
		last_name: string;
		projects: boolean;
		privileges: Privileges[];
		employeeRolePrivileges?: Privileges[];
		employerRolePrivileges?: Privileges[];
		repRolePrivileges?: Privileges[];
		repEmployerRolePrivileges?: Privileges[];
		id: string;
		customer_privilege: any;
		customer_modules: any;
		allowMultipleSelection: boolean;
		defaultUnitIds: number[];
		leadingUnitId: number[];
		operative_module: {
			'101': OperativeModule;
			'102': OperativeModule;
			'113': OperativeModule;
		};
		pending_docs: boolean;
		disable_employee_form_alert?: boolean;
		disable_document_alert?: boolean;
	};
	employer?: {
		id: string | null;
		name: string;
	};
	employerList?: {
		id: null | string;
		name: string;
		finish_work: null | string;
		customer_id: number;
	}[];
	setWorkerCardAlertOnChangedEmp: boolean;
	isInWorkerCard: boolean;
	version: any;
}

export interface LocationItem {
	id: number;
	lat: number;
	lon: number;
	projectId: string;
}

export interface ProjectListState {
	currentProject?: Project;
	projects: Project[];
	error: boolean;
	errorMessage: string;
	completed: boolean;
	modal: string;
	showModal: any;
	showAlert: boolean;
}

export interface EventListState {
	currentEvent?: EventData | undefined;
	events: EventData[];
	error: boolean;
	errorMessage: string;
	completed: boolean;
	eventForceMode: boolean;
}

export interface ReportsFiltersReducer {
	employeesFilterList: EmployeesForVacationPage;
	departmentsFilterList: string[];
	rolesFilterList: string[];
	requestsFilters: any;
	businessUnitsList: { id: number; name: string; stringId: string }[];
}
export interface ReportsState extends Calendar {
	currentMonth: number;
	currentYear: number;
	currentEditedWorklogId: string | null;
	error: boolean;
	errorMessage: string;
	completed: boolean;
	shouldSave: boolean;
	fullWorklogs: FullWorklog[];
	worklogsByDate: any;
}

export interface VacationReportsState {
	vacationReports: VacationReport[];
	vacationEmployeesReports: VacationReport[];
	errorMessage: string;
	completed: boolean;
	totalReports: Pick<
		VacationReport,
		'new_balance' | 'prev_balance' | 'refund_days' | 'used'
	> | null;
	totalEmployeesReports: number | null;
	hasMoreEmployeesReports: boolean;
}

export interface VacationReport {
	id: string;
	name: string;
	prev_balance: number;
	refund_days: number;
	used: number;
	new_balance: number;
	periods: Omit<VacationReport, 'id'>[];
}

export interface DetailedCostingReport {
	id: string;
	name: string;
	tashlumim: number;
	tagmuley_maasik: number;
	keren_hishtalmut: number;
	compensation: number;
	shonut: number;
	bleumi_maasik: number;
	mas_maasikim: number;
	mas_sachar: number;
	total: number;
	periods: DetailedCostingReport[];
}
export interface DetailedCostingReportsState {
	detailedCostingReports: DetailedCostingReport[];
	detailedCostingEmployeesReports: Omit<DetailedCostingReport, 'periods'>[];
	errorMessage: string;
	completed: boolean;
	totalReports: Omit<DetailedCostingReport, 'name' | 'periods' | 'id'> | null;
	totalEmployeesReports: number | null;
	hasMoreEmployeesReports: boolean;
}

export interface CostCompareReportsState {
	costCompareReports: CostCompareReport[];
	costCompareEmployeesReports: CostCompareReport[];
	periods: string[];
	errorMessage: string;
	completed: boolean;
	totalReports: Record<string, number> | null;
}

export interface CostCompareReport {
	id: string;
	name: string;
	periods: Record<string, number>;
}
export interface Calendar {
	working_hours: string;
	worked_hours: string;
	currentMonth: number;
	currentYear: number;
	selectedDate: string;
	month: CalendarDay[];
}

export interface ReportWorklogState {}

export interface BusyCounterState {
	busyCounter: number;
}

export interface DeviceState {
	deviceType: string;
	width: number;
}

export interface LoginCredentials {
	token: string;
	refresh_token: string;
	roles: any;
	errorMessage?: string;
	user?: {
		location: boolean;
		first_name: string;
		last_name: string;
		projects: boolean;
		privileges: Privileges[];
	};
	employer?: {
		id: string;
		name: string;
	};
	employerList?: {
		id: string | null;
		name: string;
	}[];
	version?: any;
	customer?: any;
	modules: Module[];
}

export interface UserCredentials {
	login: string;
	password: string;
}

export interface FailureActionArgs {
	errorMessage: string;
	errorStatus: number;
}

export interface User {
	id: string;
	fullName: string;
	email: number;
	hasExcludedPermission: boolean;
	hasExcludedUser: boolean;
	phone: string;
	roleNameList: string[];
	status: boolean;
	teudatZeut: string;
	departmentAid: string;
	departmentName: string;
}

export interface Record {
	workLogId: number;
	projectId: any;
	projectName: any;
	eventId: any;
	eventName: any;
	enterTime: any;
	exitTime: any;
	fullDay: boolean;
	total: number;
}
export interface WorkLogsState {
	todayWorkLogs: {
		activeWorkLog: any;
		data: Record[];
	};
	chosenDate: string;
	openChosenDateModal: boolean;
	reFetchedFlag: boolean;
}

export interface CustomerSetting {
	allowUpdateReport: boolean;
	approveManualReport: boolean;
	approveManualReportFromDate: string;
	employeeFormMailRemainderFrequency: string;
	eventSelectionType: string;
	events: any;
	lockDay: any;
	maxShiftHour: number;
	requestEmployeeIdFile: boolean;
	selectedEventIds: any;
	worklogLockType: string;
	worklogReportFormat: string;
}
