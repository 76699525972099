import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CommonActions } from '@react-navigation/native';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import I18n from '../../components/shared/i18n/I18n.component';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import httpService from '../../services/http.service';
import { modalService } from '../../services/modal.service';
import { getUserData as getUserUpdatedData } from '../../store/actions/login.actions';
import {
	getEmployerRolesPrivileges,
	getLoginData,
	getUserData,
} from '../../store/selectors/login.selectors';
import BusinessSettings from './BusinessSettings.component';
import { Platform } from 'react-native';
import useBusinessUnitsState from '../../hooks/useBusinessUnitsState';
import routes from '../../config/routes';
import { tabsOptions } from './BusinessSettings.utils';

const BusinessSettingsContainer = ({ navigation }: { navigation: any }) => {
	const dispatch = useDispatch();
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);

	const [settingsData, setSettingsData] = useState<any>(null);
	const userData = useSelector(getUserData);
	const loginData = useSelector(getLoginData);

	const [eventsOptions, setEventsOptions] = useState<Event[]>([]);
	const [selectedEvents, setSelectedEvents] = useState([]);
	const [showEvents, setShowEvents] = useState(false);
	const [showDatePicker, setShowDatePicker] = useState(null);
	const [editLockDay, setEditLockDay] = useState(
		settingsData?.worklogLockType === 'SELECTED_DAY',
	);
	const [maxShiftHour, setMaxShiftHour] = useState(
		settingsData?.maxShiftHour || 12,
	);
	const [notRender, setNotRender] = useState(false);
	const [presenceModuleExists, setPresenceModuleExists] = useState(true);
	const [presenceModuleStatus, setPresenceModuleStatus] = useState<
		'ACTIVE' | 'NOT_ACTIVE'
	>('ACTIVE');

	const formMethods = useForm({
		defaultValues: settingsData || {},
		mode: 'onBlur',
	});
	const [form101ModuleStatus, setForm101ModuleStatus] = useState('ACTIVE');
	const [payCheckModuleExists, setPayCheckModuleExists] = useState('ACTIVE');

	const { businessUnitsList, selectedBusinessUnits, onChangeBusinessUnits } =
		useBusinessUnitsState({
			privileges: routes.businessSettings.privileges,
			forceSingle: true,
			forceChangeOnlyInOnPress: notRender,
		});

	const tabs = useCallback(
		tabsOptions(navigation, employerRolesPrivileges, presenceModuleExists),
		[userData, presenceModuleExists],
	);

	const handleChangeBusinessUnits = (value: any) => {
		setNotRender(false);
		onChangeBusinessUnits(value);
	};
	useEffect(() => {
		settingsData?.maxShiftHour &&
			setMaxShiftHour(settingsData?.maxShiftHour);
		setShowEvents(settingsData?.eventSelectionType === 'PARTIAL');
		setEditLockDay(settingsData?.worklogLockType === 'SELECTED_DAY');
		if (settingsData?.approveManualReportFromDate) {
			const date = moment(
				`${settingsData?.approveManualReportFromDate}-01`,
				'YYYY-MM-DD',
			).format();
			setShowDatePicker(new Date(date));
		}
	}, [
		settingsData?.events,
		settingsData?.eventSelectionType,
		settingsData?.worklogLockType,
		settingsData?.maxShiftHour,
		settingsData?.approveManualReportFromDate,
	]);

	useEffect(() => {
		if (selectedBusinessUnits?.length && !notRender) {
			setSelectedEvents([]);
			getSettingsData();
		}
		onMount();
	}, [selectedBusinessUnits]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('UsersTableContainer');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};
	const getEventById = (events: any, eventsOptions: any) => {
		const result = eventsOptions.filter((event: any) => {
			if (events.includes(event.id)) {
				return event;
			}
		});

		return result;
	};

	const getSettingsData = async () => {
		try {
			const res = await httpService.api<any>({
				type: 'getCustomerSettings',
				query: { unitId: selectedBusinessUnits?.[0] },
			});

			if (res) {
				if (res.lockDay === null) {
					res.lockDay = '15';
				}
				const module101 = res?.customerModules?.find(
					(module: any) => module.id === 101,
				);
				setPresenceModuleExists(!!module101);
				if (module101) {
					setPresenceModuleStatus(module101.status);
				}
				delete res?.customerModules;
				if (res.workPaperDraftOpenMonthDay === null) {
					res.workPaperDraftOpenMonthDay = '15';
				}

				setSettingsData(res);
				setEventsOptions(res.events);
				if (res.selectedEventIds) {
					setSelectedEvents(
						getEventById(
							res.selectedEventIds?.split(','),
							res.events,
						),
					);
				}
				formMethods.reset(res);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleEvents = () => {
		const events = settingsData?.events.map((event: Event) => {
			return {
				...event,
				isSelected: settingsData?.selectedEventIds
					? settingsData?.selectedEventIds.includes(event.id)
					: false,
			};
		});

		setEventsOptions(events);
	};

	const hasLocationModule = () => {
		if (loginData?.employer && loginData?.modules) {
			const customer =
				typeof loginData?.employer === 'object'
					? loginData?.employer
					: JSON.parse(loginData?.employer);
			const modules =
				typeof loginData?.modules === 'object'
					? loginData?.modules
					: JSON.parse(loginData?.modules);

			if (!modules || !customer) {
				return;
			}
			const module = modules?.find(
				(el: any) => el.customer === customer.id,
			)?.modules;
			const module101 = module?.find((module: any) => module.id === 101);
			setPresenceModuleExists(!!module101);
			if (module101) {
				setPresenceModuleStatus(module101.status);
			}
			const module113 = module?.find((module: any) => module.id === 113);
			setForm101ModuleStatus(!!module113);
			if (module113) {
				setForm101ModuleStatus(module113.status);
			}
			const module102 = module?.find((module: any) => module.id === 102);
			setPayCheckModuleExists(!!module102);
			if (module102) {
				setPayCheckModuleExists(module102.status);
			}
		}
	};

	const sendNewSettings = async (events: any) => {
		try {
			const data: any = formMethods.getValues();

			const settings = {
				allowUpdateReport: data.allowUpdateReport,
				approveManualReport: data.approveManualReport,
				approveManualReportFromDate: data.approveManualReportFromDate
					? moment(data.approveManualReportFromDate).format('YYYY-MM')
					: moment().format('YYYY-MM'),
				employeeFormMailRemainderFrequency:
					data.employeeFormMailRemainderFrequency,
				eventSelectionType: data.eventSelectionType,
				lockDay:
					data.worklogLockType === 'SELECTED_DAY'
						? data.lockDay
						: null,
				maxShiftHour: data.maxShiftHour,
				requestEmployeeIdFile: data.requestEmployeeIdFile,
				selectedEventIds:
					data.eventSelectionType === 'PARTIAL'
						? selectedEvents
								?.map((event: any) => event.value || event.id)
								?.toString()
						: null,
				worklogLockType: data.worklogLockType,
				worklogReportFormat: data.worklogReportFormat,
				customerId: selectedBusinessUnits?.[0],
				workPaperDraftOpenMonthDay: Number(
					data.workPaperDraftOpenMonthDay,
				),
			};

			await httpService.api<any>({
				type: 'sendCustomerSettings',
				data: settings,
			});

			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>businessSettings.updateAlert</I18n>
					</AlertModal>
				),
			);
			dispatch(getUserUpdatedData());
			setNotRender(true);
		} catch (e) {
			console.log(e);
		}
	};

	const onCancel = () => {
		navigation.dispatch(
			CommonActions.reset({
				index: 0,
				routes: [{ name: 'employer' }],
			}),
		);
	};

	useEffect(() => {
		if (!notRender) {
			const tabs = tabsOptions(
				navigation,
				employerRolesPrivileges,
				presenceModuleExists,
			);
			navigation.navigate('businessSettings', { screen: tabs[0].name });
		}
	}, [userData, employerRolesPrivileges, presenceModuleExists]);

	return !settingsData ? null : (
		<FormProvider {...formMethods}>
			<BusinessSettings
				navigation={navigation}
				data={settingsData}
				onSend={sendNewSettings}
				onCancel={onCancel}
				eventsOptions={eventsOptions}
				setEventsOptions={setEventsOptions}
				selectedEvents={selectedEvents}
				setSelectedEvents={setSelectedEvents}
				showEvents={showEvents}
				setShowEvents={setShowEvents}
				showDatePicker={showDatePicker}
				setShowDatePicker={setShowDatePicker}
				editLockDay={editLockDay}
				setEditLockDay={setEditLockDay}
				maxShiftHour={maxShiftHour}
				setMaxShiftHour={setMaxShiftHour}
				presenceModuleExists={presenceModuleExists}
				presenceModuleStatus={presenceModuleStatus}
				businessUnitsList={businessUnitsList}
				selectedBusinessUnits={selectedBusinessUnits || []}
				onChangeBusinessUnits={handleChangeBusinessUnits}
				form101ModuleStatus={form101ModuleStatus}
				payCheckModuleExists={payCheckModuleExists}
				formMethods={formMethods}
				userData={userData}
				tabs={tabs}
				notRender={notRender}
			/>
		</FormProvider>
	);
};

export default BusinessSettingsContainer;
