export default {
	primary: '#3b70a4',
	lightPrimary: '#4aaeef',
	lightBlue: '#eff5f8',
	white: '#ffffff',
	grey: '#aeaeae',
	darkGrey: '#525962',
	success: '#1baa40',
	failure: '#e02020',
	warning: '#eb8b2d',
	pageBackground: '#DFF1F6',
	lightPageBackground: '#E3F0F6',
	horizontalSeparator: 'rgba(151, 151, 151, 0.2)',
	boxSeparator: '#c7d9eb',
	generalSeparator: '#979797',
	filterBorder: '#e7e7e8',
	coloredTableRow: '#fbfcfd',
	tableBackground: '#e8faff33',
	tableBackgroundOpacity: 'rgba(74,174,239,0.1)',
	tableDisableBackgroundOpacity: 'rgba(130,130,130,0.1)',
	opacity: 'rgba(0,0,0,0)',
	backgroundOpacity: 'rgba(151, 151, 151, 0.5)',
	filterIcon: 'rgba(82, 89, 98, 0.3);',
	selectOptionSelected: '#f3f3f3',
	selectOptionFocused: '#f1f1f1',
	green: '#83b252',
	red: '#f04d4d',
	brightRed: 'rgba(240, 77, 77, 0.1)',
	brightBlue: '#f6fafb',
	uploadBright: '#f4f6f7',
	selectRowColor: '#c9edff',
	greyBlue: '#b4d3f2',
	totalRow: '#eff4f8',
	totalColumn: '#EFF2F7',
	totalRowAndColumn: '#E0E9EF',
	darkBlue: 'rgb(59, 112, 164)',
	tableHeaders: '#2882b8',
	tableGrayHeaders: '#828282',
	repStatus: {
		NO_RESULTS: 'hsl(360, 79%, 63%)',
		TRANS_PENDING_CHECK: '#F2C94C',
		TRANS_PENDING_APPROVE: '#F2C94C',
		TRANS_APPROVE: 'hsl(145, 50%, 62%)',
		TRANS_VALID: 'hsl(145, 50%, 62%)',
		NO_TRANS_REQUIRED: '#BDBDBD',
		BEFORE_TRANS: '#EB8B2E',
		TRANS: '#F2C94C',
		TRANS_PAID: 'hsl(145, 50%, 62%)',
	},
};
