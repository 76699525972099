import { Checkbox as MuiCheckbox, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { Divider, Switch } from 'react-native-paper';
import OutlineButtonContainer from '../../../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../../../components/shared/Buttons/SolidButton.container';
import ExtraInfo from '../../../../../components/shared/ExtraInfo/ExtraInfo.component';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import colors from '../../../../../config/colors';
import AlertModal from '../../../../../modals/AlertModal/AlertModal.component';
import { modalService } from '../../../../../services/modal.service';
import { i18nService } from '../../../../../services/i18n.service';

const useStyles = makeStyles(() => ({
	checkbox: {
		marginRight: -10,

		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
			backgroundColor: colors.white,
		},
	},
	checked: {},
}));

const Row = ({
	isChecked,
	item,
	onCheck,
	disabled,
	showSwitch,
	onSwitch,
	isSwitchOn,
}: any) => {
	const classes = useStyles();

	return (
		<View
			style={{
				flexDirection: 'row-reverse',
				alignItems: 'center',
				opacity: disabled ? 0.7 : 1,
			}}
		>
			<TouchableOpacity
				accessibilityRole='button'
				onPress={e => {
					e?.stopPropagation();
					onCheck(item.id, !isChecked);
				}}
				style={{
					alignItems: 'center',
					flexDirection: 'row-reverse',
					flex: 2,
				}}
				disabled={disabled}
			>
				<MuiCheckbox
					size='small'
					color='default'
					checked={!!isChecked}
					classes={{
						root: classes?.checkbox,
						checked: classes?.checked,
					}}
				/>
				<I18n
					weight='400'
					size={14}
					style={{
						textAlign: 'right',
						marginRight: 13,
					}}
					color='darkGrey'
				>
					{item.name}
				</I18n>
			</TouchableOpacity>
			{showSwitch && (
				<View
					style={{
						marginLeft: 10,
						flex: 1,
						flexDirection: 'row-reverse',
					}}
				>
					<Switch
						onValueChange={() => {
							onSwitch?.(item.id, !isSwitchOn);
						}}
						color={colors.lightPrimary}
						value={isSwitchOn}
						disabled={disabled}
					/>
				</View>
			)}
			{item.description && (
				<ExtraInfo
					infoText={item.description}
					iconName='questionMark'
					mode='big'
				/>
			)}
		</View>
	);
};
const SelectPermissionsModal = ({
	selectedData,
	allData,
	close,
	role,
	disabled,
	onNext,
	onSubmit,
	dontUseRoleDisable,
	editMySelf,
}: any) => {
	const [selected, setSelected] = useState(selectedData || []);
	const onCheck = (itemId: number, value?: boolean) => {
		if (value) {
			setSelected([...selected, { permission_id: itemId }]);
		} else {
			const tempSelected = selected.filter(
				(s: any) => s.permission_id !== itemId,
			);
			setSelected(tempSelected);
			if (!tempSelected.length) {
				modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>editUserModal.permissions.selectOne</I18n>
						</AlertModal>
					),
				);
			}
		}
	};

	const onSwitch = (itemId: number, value?: boolean) => {
		const itemIndex = selected.findIndex(
			(s: any) => s.permission_id === itemId,
		);
		if (itemIndex > -1) {
			const tempSelected = [...selected];
			tempSelected[itemIndex] = {
				...tempSelected[itemIndex],
				read_only: value,
			};
			setSelected([...tempSelected]);
		} else {
			setSelected([
				...selected,
				{ permission_id: itemId, read_only: value },
			]);
		}
	};

	const _onSubmit = () => {
		onSubmit(selected.filter((i: any) => i !== 'all'));
		close();
		onNext();
	};

	useEffect(() => {
		setSelected(selectedData || []);
	}, [selectedData]);

	return (
		<View
			style={{
				borderRadius: 15,
				width: 531,
				paddingHorizontal: 38,
				paddingVertical: 22,
				backgroundColor: colors.white,
				maxHeight: '85vh',
			}}
		>
			<I18n size={30} style={{ marginBottom: 19 }} weight='normal'>
				editUserModal.permissions
			</I18n>
			<Divider />
			<Typography
				size={16}
				weight='normal'
				style={{ marginTop: 20, marginBottom: 5 }}
			>
				{`${i18nService.translate(
					'editUserModal.permissionsTable.note.a',
				)} ${role.name} ${i18nService.translate(
					'editUserModal.permissionsTable.note.b',
				)}`}
			</Typography>
			{editMySelf && (
				<I18n
					size={14}
					color='red'
					weight='400'
					style={{ marginTop: 5 }}
				>
					editUserModal.permissionsTable.note.toMySelf
				</I18n>
			)}
			<View style={{ flexDirection: 'row-reverse', marginLeft: 30 }}>
				<I18n
					size={16}
					style={{ marginTop: 19, flex: 2 }}
					weight='normal'
				>
					editUserModal.permissionsTable.name
				</I18n>
				{allData[0].allow_read_only && (
					<I18n
						size={16}
						style={{ marginTop: 19, flex: 1 }}
						weight='normal'
					>
						editUserModal.permissionsTable.viewOnly
					</I18n>
				)}
			</View>
			<FlatList
				style={{ marginTop: 10, flex: 1, marginBottom: 30 }}
				data={allData}
				contentContainerStyle={{ paddingBottom: 30 }}
				renderItem={({ item, ...rest }) => (
					<Row
						isChecked={
							(item?.id === 'all' &&
								selected.length === allData.length - 1) ||
							!!selected.find(
								(s: any) => s.permission_id === item.id,
							)
						}
						showSwitch={item.allow_read_only}
						onSwitch={onSwitch}
						isSwitchOn={
							selected.find(
								(s: any) => s.permission_id === item.id,
							)?.read_only
						}
						disabled={
							disabled || (item.disable && !dontUseRoleDisable)
						}
						item={item}
						roleId={role.id}
						onCheck={onCheck}
						{...rest}
					/>
				)}
				keyExtractor={item => item.id}
			/>
			<Divider />
			<View style={{ flexDirection: 'row-reverse', marginTop: 24 }}>
				<SolidButtonContainer
					disabled={!selected?.length || disabled}
					overrideStyle={{ minWidth: 90 }}
					onPress={_onSubmit}
				>
					general.update
				</SolidButtonContainer>
				<OutlineButtonContainer
					size='medium'
					onPress={onNext}
					overrideStyle={{ minWidth: 90, marginRight: 30 }}
				>
					general.cancel
				</OutlineButtonContainer>
			</View>
		</View>
	);
};

export default SelectPermissionsModal;
