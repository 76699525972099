import React, { useMemo, useState } from 'react';
import { Dimensions, useWindowDimensions, View } from 'react-native';
import colors from '../../../config/colors';
import I18n from '../../../components/shared/i18n/I18n.component';
import Header from '../../../components/shared/Header/Header.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { i18nService } from '../../../services/i18n.service';
import Filters from '../../../components/web/Filters/Filters.component';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import { useSelector } from 'react-redux';
import { getEmployerRolesPrivileges } from '../../../store/selectors/login.selectors';
import { Privileges } from '../../../constants/roleTypes.constant';
import WebHeader from '../../../components/shared/Header/WebHeader.component';
import {
	EditDeleteButton,
	onDelete,
	onEdit,
} from './LocationSettingsPage.utils';
import { modalService } from '../../../services/modal.service';
import LocationSettingsModal from './LocationSettingsModal/LocationSettingsModal';
import httpService from '../../../services/http.service';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import ExistingLocationSettingsModal from './ExistingLocationSettingsModal/ExistingLocationSettingsModal';
import Typography from '../../../components/shared/Typography/Typography.component';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import { compact } from 'lodash';

export interface Props {
	navigation: any;
	data: any[];
	projectsFilterList: any[];
	departmentsFilterList: any[];
	filtersConfig: any;
	onSort: (field: string, direction: string) => void;
	onLoadMore(): void;
	onFilter(filterData: any): void;
	refresh(): void;
	businessUnitsList: any[];
	selectedUnitIds: any[];
}

const LocationSettingsPage = ({
	navigation,
	data,
	filtersConfig,
	projectsFilterList,
	departmentsFilterList,
	onSort,
	onLoadMore,
	onFilter,
	refresh,
	businessUnitsList,
	selectedUnitIds,
}: Props) => {
	const [getUsersByDepartments, setGetUsersByDepartments] = useState([]);
	const { height: windowHeight } = useWindowDimensions();

	const headers = () => {
		const firstList = compact([
			{
				accessor: 'locationName',
				Header: i18nService.translate('locationSettings.table.address'),
				isSortable: true,
			},
			{
				accessor: 'name',
				Header: i18nService.translate(
					'locationSettings.table.addressName',
				),
				isSortable: true,
			},
			{
				accessor: 'radius',
				Header: i18nService.translate('locationSettings.table.radius'),
				isSortable: true,
				CustomCell: ({ value }: any) => (
					<Typography size={14}>
						{value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
					</Typography>
				),
			},
			businessUnitsList.length > 1
				? {
						accessor: 'businessUnitName',
						Header: i18nService.translate('general.businessUnits'),
						isSortable: true,
				  }
				: null,
		]);

		const projectList = projectsFilterList.length
			? [
					{
						accessor: 'projects',
						Header: i18nService.translate(
							'locationSettings.table.projects',
						),
						isSortable: false,
						CustomCell: ({ value }: any) => {
							if (!!value) {
								const names = value.split(',');
								return (
									<>
										<I18n size={14}>
											{`${
												names.length > 2
													? `${names[0]}, ${names[1]}`
													: value
											}`}
										</I18n>
										{names.length > 2 && (
											<I18n size={14}>
												{`\n+${names.length - 2}`}
											</I18n>
										)}
									</>
								);
							} else return <View />;
						},
					},
			  ]
			: [];

		const secondList = [
			{
				accessor: 'accessFor',
				Header: i18nService.translate('locationSettings.table.belongs'),
				isSortable: false,
				CustomCell: ({ value }: any) => {
					const departments = value?.departments;
					const users = value?.users;
					if (!!users || !!departments) {
						const namesArr = users?.split(',') || [];
						const departmentsArr = departments?.split(',') || [];
						let text = '';
						let number = namesArr.length + departmentsArr.length;
						if (!!users && !!departments) {
							text = `${departmentsArr[0]}, ${namesArr[0]}`;
							number = number - 2;
						} else {
							text = `${departmentsArr[0] || namesArr[0]}${
								number > 1
									? `, ${departmentsArr[1] || namesArr[1]}`
									: ''
							}`;
							number = number > 2 ? number - 2 : 0;
						}
						return (
							<>
								<I18n size={14}>{text}</I18n>
								{number > 0 && (
									<I18n size={14}>{`\n+${number}`}</I18n>
								)}
							</>
						);
					} else return <View />;
				},
			},
			{
				accessor: 'status',
				Header: i18nService.translate('locationSettings.table.status'),
				isSortable: true,
				CustomCell: ({ value }: any) => (
					<I18n size={14}>
						{value ? 'general.active' : 'general.inactive'}
					</I18n>
				),
			},
			{
				accessor: 'edit',
				Header: '',
				sortable: false,
				CustomCell: ({ row }: any) => (
					<EditDeleteButton
						iconName='edit'
						onPress={() =>
							onEdit(
								row.original.id,
								projectsFilterList,
								departmentsFilterList,
								refresh,
								row.original.businessUnitId,
							)
						}
						height={20}
						width={20}
					/>
				),
			},
			{
				accessor: 'delete',
				Header: '',
				sortable: false,
				CustomCell: ({ row }: any) =>
					hasPermissionOnBusinessUnit(
						row.original.businessUnitId,
						Privileges.ROLE_LOCATION_WRITE,
					) ? (
						<EditDeleteButton
							iconName='delete'
							onPress={() => onDelete(row.original.id, refresh)}
							height={20}
							width={18}
						/>
					) : null,
			},
		];

		return [...firstList, ...projectList, ...secondList];
	};

	const updateFilters = val => {
		onFilter(val);
	};

	const onAddNewLocation = async () => {
		let userList = [];
		const allowedBusinessUnitsList = businessUnitsList.filter(unit =>
			hasPermissionOnBusinessUnit(
				unit.id,
				Privileges.ROLE_LOCATION_WRITE,
			),
		);
		const allowedSelectedUnitIds = selectedUnitIds.filter(unit =>
			hasPermissionOnBusinessUnit(unit, Privileges.ROLE_LOCATION_WRITE),
		);
		if (
			!getUsersByDepartments.length &&
			allowedSelectedUnitIds?.length === 1
		) {
			const res = await httpService.api<any>({
				type: 'getUsersByDepartments',
				query: {
					pageName: 'locationSettings',
					unitId: allowedSelectedUnitIds?.[0],
				},
				data: {
					search: '',
					f: { dep: [], internal: ['true'] },
				},
			});
			userList = res;
			setGetUsersByDepartments(res);
		} else {
			userList = getUsersByDepartments;
		}

		await modalService.openModal(null, {}, (props: any) => (
			<LocationSettingsModal
				{...props}
				refresh={refresh}
				users={userList}
				unitIds={allowedSelectedUnitIds}
				businessUnitsList={allowedBusinessUnitsList}
				projectsFilterList={projectsFilterList}
				departmentsFilterList={departmentsFilterList}
			/>
		));
	};

	const onUpdateExistingLocation = async () => {
		const allowedBusinessUnitsList = businessUnitsList.filter(
			unit =>
				hasPermissionOnBusinessUnit(
					unit.id,
					Privileges.ROLE_LOCATION_WRITE,
				) ||
				hasPermissionOnBusinessUnit(
					unit.id,
					Privileges.ROLE_LOCATION_ASSOCIATION_WRITE,
				),
		);
		const allowedSelectedUnitIds = selectedUnitIds.filter(
			unit =>
				hasPermissionOnBusinessUnit(
					unit,
					Privileges.ROLE_LOCATION_WRITE,
				) ||
				hasPermissionOnBusinessUnit(
					unit,
					Privileges.ROLE_LOCATION_ASSOCIATION_WRITE,
				),
		);

		await modalService.openModal(null, {}, (props: any) => (
			<ExistingLocationSettingsModal
				{...props}
				refresh={refresh}
				locationsList={[]}
				projectsList={projectsFilterList}
				unitIds={allowedSelectedUnitIds}
				businessUnitsList={allowedBusinessUnitsList}
			/>
		));
	};
	const disabled = useMemo(
		() =>
			selectedUnitIds?.some(unit =>
				hasPermissionOnBusinessUnit(
					unit,
					Privileges.ROLE_LOCATION_ASSOCIATION_WRITE,
				),
			)
				? false
				: true,
		[selectedUnitIds],
	);

	const createDisabled = useMemo(
		() =>
			selectedUnitIds?.some(unit =>
				hasPermissionOnBusinessUnit(
					unit,
					Privileges.ROLE_LOCATION_WRITE,
				),
			)
				? false
				: true,
		[selectedUnitIds],
	);

	return (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<I18n size={36} weight='normal' style={{ flex: 1 }}>
						locationSettings.title
					</I18n>
				}
				noHeader={true}
			>
				<View
					style={{
						flexDirection: 'row-reverse',
						justifyContent: 'space-between',
						zIndex: 100,
					}}
				>
					<Filters
						config={filtersConfig}
						onChange={updateFilters}
						customStyles={{
							container: {
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
							},
							filterContainer: {
								marginRight: 15,
								zIndex: 10001,
							},
						}}
					/>
					<View style={{ flexDirection: 'row-reverse' }}>
						{data.length
							? (!createDisabled || !disabled) && (
									<SolidButtonContainer
										size='medium'
										onPress={onUpdateExistingLocation}
										overrideStyle={{
											marginTop: 8,
											marginHorizontal: 10,
										}}
									>
										{`${i18nService.translate(
											'existingLocationSettingsModal.title',
										)} ${
											projectsFilterList.length
												? i18nService.translate(
														'existingLocationSettingsModal.titleOrProject',
												  )
												: ''
										}`}
									</SolidButtonContainer>
							  )
							: null}
						{!createDisabled && (
							<EditDeleteButton
								iconName='roundPlus'
								onPress={onAddNewLocation}
								height={70}
								width={70}
							/>
						)}
					</View>
				</View>
				<View
					style={{
						flex: 1,
						maxHeight: windowHeight - (!createDisabled ? 235 : 255),
						marginTop: !createDisabled ? 0 : 20,
					}}
				>
					{data.length ? (
						<TableContainer
							keyName={''}
							headers={headers()}
							data={data || []}
							getNextData={onLoadMore}
							onSort={onSort}
							hasFooter={false}
							selectedRowId={''}
						/>
					) : (
						<View
							style={{
								flex: 1,
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<I18n
								weight='bold'
								size={24}
								style={{ marginRight: 26, marginTop: 50 }}
							>
								locationSettings.noData
							</I18n>
						</View>
					)}
				</View>
			</ReportsPageWrapper>
		</View>
	);
};

export default LocationSettingsPage;
