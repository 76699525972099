import { compact } from 'lodash';
import React from 'react';
import Typography from '../../../components/shared/Typography/Typography.component';
import { Option } from '../../../interfaces/option.interface';

import { FullSalaryReportList } from '../../../models/fullSalaryReportList.model';
import { i18nService } from '../../../services/i18n.service';
import { SalaryReportsRequest } from './SalaryReportPage.reducer';

export type FiltersDataType = Record<
	'department' | 'workers' | 'years' | 'months',
	string[] | Option[] | Record<number, string>
>;

export type SalaryReportDataType = Record<keyof FullSalaryReportList, string>;
export type SalaryReportRowType = Record<
	keyof Omit<FullSalaryReportList, 'id'>,
	string
>;

export type TemplateDataType = {
	template: any;
	fileName: string;
};

export const monthsMapFilter: Record<number, string> = {
	1: 'ינואר',
	2: 'פברואר',
	3: 'מרץ',
	4: 'אפריל',
	5: 'מאי',
	6: 'יוני',
	7: 'יולי',
	8: 'אוגוסט',
	9: 'ספטמבר',
	10: 'אוקטובר',
	11: 'נובמבר',
	12: 'דצמבר',
};

export const fieldToLabelMap: SalaryReportRowType = {
	teudatZeut: i18nService.translate('salaryReportPage.id'),
	fullName: i18nService.translate('salaryReportPage.workerName'),
	businessUnitName: i18nService.translate('general.businessUnits'),
	departmentName: i18nService.translate('salaryReportPage.department'),
	salaryMonth: i18nService.translate('salaryReportPage.salaryMonth'),
	firstViewedSlipDate: i18nService.translate('salaryReportPage.reportDate'),
};

export const salaryReportHeaders: any = (businessUnitsList: any) =>
	compact(
		Object.entries(fieldToLabelMap).map(([key, value]) =>
			key !== 'businessUnitName' || businessUnitsList.length > 1
				? ({
						accessor: key,
						Header: value,
						isSortable: true,
						CustomCell:
							key === 'teudatZeut'
								? ({ value, row }: any) => {
										return (
											<Typography
												color='primary'
												weight='bold'
												size={14}
											>
												{value}
											</Typography>
										);
								  }
								: null,
				  } as any)
				: undefined,
		),
	);

export const createFilterOptionsForTable = (
	filtersData: FiltersDataType,
	getEmployeesFilter: any,
	selectedDepartments: any,
	tableSt: SalaryReportsRequest,
	businessUnitsList: any[],
	isBusinessUnitsMulti: boolean,
	selectedPaycheckFilter: any,
	departmentFilterKey?: string,
) => {
	let mnth: number = tableSt?.filter?.month[0]
		? Number(tableSt?.filter?.month[0])
		: 0;
	let defVal = mnth ? monthsMapFilter[mnth] : null;
	let defValYear = tableSt?.filter?.year[0]
		? Number(tableSt?.filter?.year[0])
		: null;
	return compact([
		{
			name: 'year',
			options: (filtersData.years as string[])?.map((val: string) => ({
				id: val,
				name: val,
			})),
			type: 'select',
			defaultValue: defValYear,
			placeholder: 'year',
			styles: { width: 114 },
		},
		{
			name: 'month',
			defaultValue: defVal,
			options: Object.entries(filtersData.months).map(([key, value]) => ({
				id: key,
				name: value,
			})),
			type: 'select',
			placeholder: 'month',
			styles: { width: 114 },
		},
		{
			name: 'emp',
			loadOptions: getEmployeesFilter,
			type: 'asyncmultiselect',
			key: `${JSON.stringify(selectedDepartments)}${
				filtersData.department
			}}${JSON.stringify(tableSt.filter.unitIds)}`,
			placeholder: 'employees',
			styles: { width: 276 },
		},
		{
			name: 'department',
			options: filtersData.department,
			type: 'multiselect',
			placeholder: 'departments',
			defaultLabel: i18nService.translate('general.all'),
			styles: { width: 134 },
			key: departmentFilterKey,
		},
		{
			name: 'paycheck-view',
			options: watchedPaycheckFilter,
			value: selectedPaycheckFilter,
			type: 'multiselect',
			placeholder: 'watchedPaychecks',
			styles: { width: 160 },
		},
		businessUnitsList?.length > 1
			? isBusinessUnitsMulti
				? {
						name: 'unitIds',
						type: 'multiselect',
						placeholder: 'businessUnits',
						value: tableSt.filter.unitIds?.map((unit: any) =>
							businessUnitsList.find(
								(bunit: any) => bunit.id === unit,
							),
						),
						options: businessUnitsList,
						styles: {
							control: {
								width: 200,
								maxWidth: 200,
							},
						},
				  }
				: {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: tableSt.filter.unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
			: undefined,
	]);
};

const watchedPaycheckFilter = [
	{
		id: 'VIEW',
		name: i18nService.translate('salaryReportPage.filter.VIEW'),
	},
	{
		id: 'NOT_VIEW',
		name: i18nService.translate('salaryReportPage.filter.NOT_VIEW'),
	},
	{
		id: 'NO_PERMISSION',
		name: i18nService.translate('salaryReportPage.filter.NO_PERMISSION'),
	},
];

export const pageFilterSize = 20;

export const initSalaryListReport = {
	hasNext: false,
	total: 0,
	data: [],
};

export const initSalaryFilters = {
	department: [],
	workers: [],
	years: [],
	months: monthsMapFilter,
	['paycheck-view']: [
		{
			id: 'VIEW',
			name: i18nService.translate('salaryReportPage.filter.VIEW'),
		},
		{
			id: 'NOT_VIEW',
			name: i18nService.translate('salaryReportPage.filter.NOT_VIEW'),
		},
	],
};

export const initSalaryRow = {
	id: '',
	teudatZeut: '',
	fullName: '',
	departmentName: '',
	salaryMonth: '',
	firstViewedSlipDate: '',
};

export const PAGINATION_PAGES_VISIBLE = 3;
