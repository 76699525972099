import React from 'react';
import { StyleSheet, Image, View, TouchableHighlight } from 'react-native';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import colors from '../../../../config/colors';
import iconsMap from '../../../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';

type CommentIconButtonProps = {
	color: any;
	onPress: () => void;
	style?: any;
	hasComment?: boolean;
	disabled?: boolean;
};

const CommentIconButton = ({
	color,
	onPress,
	hasComment,
	style,
	disabled,
}: CommentIconButtonProps) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;
	return (
		<>
			<TouchableHighlight
				disabled={disabled}
				onPress={onPress}
				style={StyleSheet.flatten([
					styles.icon,
					style,
					disabled && { opacity: 0.7 },
				])}
			>
				<Image
					source={
						hasComment
							? iconsMap.addedCommentIcon
							: iconsMap.initialCommentIcon
					}
					fadeDuration={0}
					style={{
						height: '100%',
						width: '100%',
					}}
				/>
			</TouchableHighlight>
		</>
	);
};

const styles = StyleSheet.create({
	icon: {
		height: 14,
		width: 14,
		marginTop: 2,
		marginLeft: 10,
	},
});

export default CommentIconButton;
