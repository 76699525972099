// import { createSelector } from 'reselect';
import { createSelector } from 'reselect';
import { Privileges } from '../../constants/roleTypes.constant';
import { UserRoles } from '../../interfaces/redux.interface';
import { RootState } from '../reducers/root.reducer';

export const isLoggedIn = (state: RootState) => state.login.loggedIn;
export const getLoginData = createSelector(
	(state: RootState) => state.login as any,
	(login: any) => login,
);
export const getLoginModulesAndEmployer = createSelector(
	(state: RootState) => state.login.modules,
	(state: RootState) => state.login.employer,
	(modules: any, employer: any) => ({ employer, modules }),
);
export const getIsLoginComplete = (state: RootState) =>
	state.login?.complete as any;

export const getRole = (state: RootState): Privileges[] => state.login.roles;

export const getEmployer = (state: RootState) => state.login.employer;
export const getEmployersData = (state: RootState) => state.login.employerList;
export const getSetWorkerCardAlert = (state: RootState) =>
	state.login.setWorkerCardAlertOnChangedEmp;
export const getIsInWorkerCard = (state: RootState) =>
	state.login.isInWorkerCard;

export const getUserData = createSelector(
	(state: RootState) => state.login.user,
	(user: any) => user,
);

export const getResetPasswordError = (state: RootState) => state.login.error;
export const getResetPasswordErrorMessage = (state: RootState) =>
	state.login.errorMessage;
export const getEmployeePrivileges = createSelector(
	(state: RootState) => state.login.user?.employeeRolePrivileges,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.employeeRolePrivileges,
	(privileges: any, customerHp: any, customerPrivileges: any) =>
		customerHp ? customerPrivileges : privileges,
);

export const getUserPrivilegesStatus = createSelector(
	(state: RootState) => state.login.user,
	(state: RootState) => state.repEnterMode,
	(user: any, repEnterMode: any): boolean => {
		const currentData = repEnterMode.customerHp ? repEnterMode : user;
		const isEmployer = currentData?.employerRolePrivileges?.length ? 1 : 0;
		const isEmployee = currentData?.employeeRolePrivileges?.length ? 1 : 0;
		const isRep = currentData?.repRolePrivileges?.length ? 1 : 0;
		return isEmployer + isEmployee + isRep > 1 ? true : false;
	},
);

export const getUserTypes = (state: RootState): any => {
	const isEmployer = state.login.user?.user_roles?.includes(
		UserRoles.MANAGER,
	);
	const isEmployee = state.login.user?.user_roles?.includes(
		UserRoles.EMPLOYEE,
	);
	const isRep = state.login.user?.user_roles?.includes(UserRoles.REPRESENT);
	return { isEmployer, isEmployee, isRep };
};

export const getEmployeeRolesPrivileges = createSelector(
	(state: RootState) => state.login.user?.employeeRolePrivileges,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.employeeRolePrivileges,
	(
		employeeRolePrivileges: any,
		customerHp: any,
		customerEmployeeRolePrivileges: any,
	) => (customerHp ? customerEmployeeRolePrivileges : employeeRolePrivileges),
);

export const getEmployerRolesPrivileges = createSelector(
	(state: RootState) => state.login.user?.employerRolePrivileges,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.employerRolePrivileges,
	(
		employerRolePrivileges: any,
		customerHp: any,
		customerEmployerRolePrivileges: any,
	) => (customerHp ? customerEmployerRolePrivileges : employerRolePrivileges),
);

export const getRepRolesPrivileges = createSelector(
	(state: RootState) => state.login.user?.repRolePrivileges,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.repRolePrivileges,
	(repRolePrivileges: any, customerHp: any, customerRepRolePrivileges: any) =>
		customerHp ? customerRepRolePrivileges : repRolePrivileges,
);

export const getIsEmployerAndEmployee = (state: RootState) => {
	return (
		state.login.user?.employerRolePrivileges?.length &&
		state.login.user?.employeeRolePrivileges?.length
	);
};

export const getBussinesUnitsData = createSelector(
	(state: RootState) => state.login.user?.customer_privilege,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.customer_privilege,
	(privileges: any, customerHp: any, customerPrivileges: any) =>
		customerHp ? customerPrivileges : privileges,
);

export const getBussinesUnitsModules = createSelector(
	(state: RootState) => state.login.user?.customer_modules,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.customer_modules,
	(modules: any, customerHp: any, customer_modules: any) =>
		(customerHp ? customer_modules : modules) || {},
);

export const getBusinessUnitsConfig = createSelector(
	(state: RootState) => state.login.user?.allowMultipleSelection,
	(state: RootState) => state.login.user?.defaultUnitIds,
	(state: RootState) => state.login.user?.leadingUnitId,
	(state: RootState) => state.repEnterMode.customerHp,
	(state: RootState) => state.repEnterMode.allowMultipleSelection,
	(state: RootState) => state.repEnterMode.defaultUnitIds,
	(state: RootState) => state.repEnterMode.leadingUnitId,
	(
		allowMultipleSelection: any,
		defaultUnitIds: any,
		leadingUnitId: any,
		customerHp: any,
		customerMulti,
		customerDefault,
		customerLeading,
	) =>
		customerHp
			? {
					allowMultipleSelection: customerMulti,
					defaultUnitIds: customerDefault,
					leadingUnitId: customerLeading,
			  }
			: { allowMultipleSelection, defaultUnitIds, leadingUnitId },
);
