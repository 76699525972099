import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FAB } from 'react-native-paper';
import { TouchableHighlight, View, StyleSheet } from 'react-native';
import { Privileges } from '../../../constants/roleTypes.constant';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { ListType } from '../../../interfaces/core.interface';
import {
	Employee101Data,
	Employee101YearStatus,
} from '../../../pages/ReportPDFPage/ReportPDFPage.utils';
import {
	onPressCreate,
	onPressWorkerCardYear,
} from '../../../pages/WorkerCard/workerCard.utils';
import httpService from '../../../services/http.service';
import { getState } from '../../../store/store';
import Typography from '../Typography/Typography.component';
import ReportRangeList from './ReportRangeList.component';
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import { Platform } from '@unimodules/react-native-adapter';
import { login } from '../../../store/actions/login.actions';

interface ReportRangeListContainerProps {
	minDate?: string;
	maxDate?: string;
	dateList?: Employee101YearStatus[];
	innerHeaderData?: (
		selectedDate: Employee101YearStatus,
		isFinishWorkDateExpired: boolean,
	) => Employee101Data;
	navigation?: any;
	onSelectDate?: (date: any) => void;
	navigateToRoute?: string;
	listType: ListType;
	title?: string;
	rangeTitle?: string;
	is101Page?: boolean;
	enableCreate?: boolean;
}

const ReportRangeListContainer = ({
	minDate,
	maxDate,
	dateList,
	innerHeaderData,
	navigation,
	onSelectDate,
	navigateToRoute,
	listType,
	title,
	rangeTitle,
	enableCreate,
	is101Page,
	...restProps
}: ReportRangeListContainerProps) => {
	const [dates, setDates] = useState<string[] | Employee101YearStatus[]>([]);
	const [selectedReport, setSelectedReport] = useState<
		string | Employee101YearStatus
	>('');
	const isWeb = useDevicePlatform() === DeviceType.WEB;
	const [selectedCurrentStatus, setSelectedCurrentStatus] = useState<any>();

	useEffect(() => {
		setDates(dateList as Employee101YearStatus[]);
		onSelectDate && dateList?.length && onSelectDate(dateList[0]);
		dateList?.length && setSelectedReport(dateList[0]);
	}, [dateList]);

	const createFormHandler = () => {
		onPressCreate(navigation);
	};

	useEffect(() => {
		let innerHeaderDataObj = null;

		if (selectedCurrentStatus) {
			if (
				selectedReport &&
				typeof selectedReport !== 'string' &&
				!isWeb &&
				innerHeaderData
			) {
				const expWorkDate =
					getState().login?.user?.operative_module?.[113]
						?.finish_work;
				const isFinishWorkDateExpired: boolean =
					selectedReport?.taxYear >
					moment(expWorkDate, 'YYYY-MM-DD').year();
				innerHeaderDataObj = JSON.stringify(
					innerHeaderData(selectedReport, isFinishWorkDateExpired),
				);
			}
			onPressWorkerCardYear(
				navigation,
				navigateToRoute,
				{
					selectedDate: {
						[selectedReport.taxYear]: selectedReport.status,
					},
					dateMode: listType,
					title,
					innerHeaderDataObj,
					...restProps,
				},
				selectedCurrentStatus,
			);
			setSelectedCurrentStatus(undefined);
		}
	}, [selectedCurrentStatus]);

	useEffect(() => {
		if (!is101Page) {
			let a = moment(minDate).set('date', 1).format('YYYY-MM-DD');
			let b = moment(maxDate).set('date', 1).format('YYYY-MM-DD');
			let diff =
				listType === 'months'
					? moment(b).diff(a, 'months') + 1
					: moment(b).diff(a, 'years') + 1;

			let arrDates = [a];
			let tempStartDate = a;
			for (let i = 1; i < diff; i++) {
				tempStartDate = moment(tempStartDate)
					.add(1, listType === 'months' ? 'month' : 'year')
					.format('YYYY-MM-DD');
				arrDates.push(tempStartDate);
			}
			const list = arrDates.reverse();
			setDates(list);
			onSelectDate && onSelectDate(list[0]); // need to inform parent about selected date
			list.length && setSelectedReport(list[0]);
		}
	}, [minDate, maxDate, is101Page]);

	const onSelectReportDate = async (
		date: string | Employee101YearStatus | any,
	) => {
		let innerHeaderDataObj = null;

		if (date && typeof date !== 'string' && !isWeb && innerHeaderData) {
			innerHeaderDataObj = JSON.stringify(innerHeaderData(date));
		}

		setSelectedReport(date);
		onSelectDate && onSelectDate(date); // need to inform parent about selected date

		if (!isWeb && navigateToRoute) {
			const employeeRolesPrivileges =
				getState().login.user?.employeeRolePrivileges;
			if (
				restProps.is101 &&
				employeeRolesPrivileges?.includes(
					Privileges.ROLE_EMPLOYEE_FORM_WRITE,
				)
			) {
				const data = JSON.parse(innerHeaderDataObj || '');
				const statusCode: string = await httpService.api({
					type: 'getWorkerCardStatus',
					params: { formId: data?.id },
				});
				setSelectedCurrentStatus(statusCode);
			} else {
				navigation?.navigate(navigateToRoute, {
					selectedDate: date,
					dateMode: listType,
					title,
					innerHeaderDataObj,
					...restProps,
				});
			}
		}
	};

	return (
		<>
			<ReportRangeList
				title={rangeTitle}
				listType={listType}
				list={dates}
				selectedReport={selectedReport}
				onSelectReportDate={onSelectReportDate}
			/>
			{is101Page && enableCreate ? (
				<FAB
					style={styles.fab}
					small
					color={colors.white}
					icon=''
					label={i18nService.translate('report101ReportPage.add')}
					onPress={() => createFormHandler()}
				/>
			) : null}
		</>
	);
};

const styles = StyleSheet.create({
	innerButtonItemContainer: {
		display: 'flex',
		flexDirection: 'row',
		minWidth: 176,
		alignItems: 'center',
		marginLeft: 10,
	},
	innerButtonItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 60,
		width: 176,
		backgroundColor: '#1b80c4',
		position: 'absolute',
		bottom: 37,
		borderRadius: 50,
	},
	btn: {
		height: 20,
		width: 20,
		justifyContent: 'center',
	},
	fab: {
		position: 'absolute',
		margin: 16,
		left: 0,
		bottom: Platform.OS === 'ios' ? 80 : 15,
		borderColor: colors.primary,
		backgroundColor: colors.primary,
		borderWidth: 1,
		width: 176,
	},
});

export default ReportRangeListContainer;
