import { remove } from 'lodash';
import React, { useMemo } from 'react';
import { FloatingAction } from 'react-native-floating-action';
import { FAB } from 'react-native-paper';
import colors from '../../../config/colors';
import icons from '../../../config/icons';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../services/i18n.service';
import ActionItem from './ActionButton';

const defaultActionStyles = {
	margin: 0,
};

const buttons = {
	save: {
		name: 'save',
		style: { backgroundColor: colors.lightPrimary },
		text: i18nService.translate('general.save'),
		render: () => (
			<ActionItem
				color={colors.primary}
				icon={icons.save}
				text={i18nService.translate('general.save')}
			/>
		),
		...defaultActionStyles,
	},
	close: {
		name: 'close',
		color: colors.primary,
		style: {
			borderWidth: 1,
			borderColor: colors.primary,
			backgroundColor: colors.white,
		},
		text: i18nService.translate('general.close'),
		render: () => (
			<ActionItem
				color={colors.white}
				buttonStyle={{ borderWidth: 1, borderColor: colors.primary }}
				icon={icons.save}
				text={i18nService.translate('general.close')}
			/>
		),
		...defaultActionStyles,
	},
	cancel: {
		name: 'cancel',
		color: colors.primary,
		style: {
			borderWidth: 1,
			borderColor: colors.primary,
			backgroundColor: colors.white,
		},
		text: i18nService.translate('general.cancel'),
		render: () => (
			<ActionItem
				color={colors.white}
				buttonStyle={{ borderWidth: 1, borderColor: colors.primary }}
				icon={icons.back}
				text={i18nService.translate('general.cancel')}
			/>
		),
		...defaultActionStyles,
	},
	submission: {
		name: 'submission',
		style: { backgroundColor: colors.warning },
		text: i18nService.translate('general.submission'),
		render: () => (
			<ActionItem
				color={colors.green}
				icon={icons.checked}
				text={i18nService.translate('general.submission')}
			/>
		),
		...defaultActionStyles,
	},
	remove: {
		name: 'remove',
		style: { backgroundColor: colors.warning },
		text: i18nService.translate('general.delete'),
		render: () => (
			<ActionItem
				color={colors.green}
				icon={icons.checked}
				text={i18nService.translate('general.delete')}
			/>
		),
		...defaultActionStyles,
	},
	rejection: {
		...defaultActionStyles,
		render: () => (
			<ActionItem
				color={colors.red}
				icon={icons.reject}
				text={i18nService.translate('general.rejection')}
			/>
		),
		style: { backgroundColor: colors.lightPrimary },
		text: i18nService.translate('general.rejection'),
		name: 'rejection',
	},
	accept: {
		style: { backgroundColor: colors.warning },
		text: i18nService.translate('general.accept'),
		name: 'approve',
		render: () => (
			<ActionItem
				color={colors.green}
				icon={icons.checked}
				text={i18nService.translate('general.accept')}
			/>
		),
		...defaultActionStyles,
	},
};

const ActionButtons = ({
	dispatch = () => {},
	commentExist,
	cancel,
	save,
	remove,
	submission = () => {},
	onNext,
	isEmployee,
	readOnly = false,
	disabledSave,
}: any) => {
	const platform = useDevicePlatform();
	const isWeb = useMemo(() => platform === DeviceType.WEB, [platform]);
	const employeeActions = useMemo(
		() => [buttons.cancel, buttons.submission, buttons.save],
		[],
	);

	const onButtonClick = async (buttonName?: string) => {
		switch (buttonName) {
			case 'cancel':
				onNext && onNext();
				break;
			case 'close':
				onNext && onNext();
			case 'save':
				await save();
				onNext && onNext();
				break;
			case 'submission':
				submission();
				break;
			case 'remove':
				await remove();
				onNext && onNext();
				break;
			default:
				dispatch({ type: buttonName });
		}
	};

	const employerCommentModalActions = useMemo(
		() =>
			readOnly
				? [buttons.cancel]
				: [buttons.cancel, buttons.remove, buttons.save],
		[],
	);

	const employerInitialActions = useMemo(
		() => (readOnly ? [buttons.cancel] : [buttons.cancel, buttons.save]),
		[],
	);

	const getActions = () => {
		if (isEmployee) {
			return [buttons.close];
		}

		if (commentExist) {
			return employerCommentModalActions;
		}

		return employerInitialActions;
	};

	const getButtonStyle = (
		style: any,
		index: number,
		array: any[],
		isDisabled?: boolean,
	) => {
		if (isEmployee) {
			return {
				marginVertical: 0,
				marginHorizontal: 'auto',
				width: 90,
				opacity: isDisabled ? 0.7 : 1,
				...style,
			};
		}

		if (index === array.length - 1) {
			return {
				marginVertical: 16,
				marginHorizontal: 5,
				float: 'right',
				width: 90,
				opacity: isDisabled ? 0.7 : 1,
				...style,
			};
		}

		return {
			marginVertical: 16,
			marginHorizontal: index > 0 && index < array.length - 1 ? 9 : 5,
			float: 'left',
			width: 90,
			opacity: isDisabled ? 0.7 : 1,
			...style,
		};
	};

	return (
		<>
			{[...getActions()].map(
				({ name, text, style = {}, color }: any, index, array) => (
					<FAB
						key={name}
						disabled={name === 'save' && disabledSave}
						style={getButtonStyle(
							style,
							index,
							array,
							name === 'save' && disabledSave,
						)}
						icon=''
						small
						color={color || colors.white}
						label={text}
						onPress={() => onButtonClick(name)}
					/>
				),
			)}
		</>
	);
};

export default ActionButtons;
