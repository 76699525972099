import * as React from 'react';
import Tooltip from 'rc-tooltip';
import { makeStyles, Theme, withStyles } from '@material-ui/core';
import ImageIcon from '../Icon/ImageIcon.component';
import I18n from '../i18n/I18n.component';
import iconsMap from '../../../config/icons';
import 'rc-tooltip/assets/bootstrap_white.css';

type ExtraInfoPropsType = {
	infoText: string | string[];
	mode?: 'big';
	iconName?: keyof typeof iconsMap;
};

const useStylesExtraInfo = makeStyles((theme: Theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
	},
}));

const InfoTextComponent = ({ infoText }: ExtraInfoPropsType) => {
	return (
		<div
			style={{
				textAlign: 'right',
				justifyContent: 'right',
				alignItems: 'right',
			}}
		>
			{Array.isArray(infoText) ? (
				infoText?.map(el => {
					return (
						<I18n key={el} size={14} weight={'normal'}>
							{el}
						</I18n>
					);
				})
			) : (
				<I18n size={14} weight={'normal'}>
					{infoText}
				</I18n>
			)}
		</div>
	);
};

const ExtraInfo = ({ infoText, mode, iconName }: ExtraInfoPropsType) => {
	const classes = useStylesExtraInfo();
	return (
		<Tooltip
			trigger={['hover', 'click']}
			overlay={<InfoTextComponent infoText={infoText} />}
			overlayStyle={{ zIndex: 9999, maxWidth: 300 }}
		>
			<a>
				<ImageIcon
					name={iconName || 'extraInfo'}
					width={mode === 'big' ? 20 : 14}
					height={mode === 'big' ? 20 : 14}
					style={{ marginRight: 5, marginLeft: 5 }}
				/>
			</a>
		</Tooltip>
	);
};

export default ExtraInfo;
