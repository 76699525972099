import { RootState } from '../reducers/root.reducer';

export const getBusyCounter = (state: RootState) => state.config.busyCounter;

export const getNavigationTo = (state: RootState) => state.config.navigateTo;

export const getHideChat = (state: RootState) => state.config.hideChat;

export const getDefaultDisplayMode = (state: RootState) =>
	state.config.defaultDisplayMode;

export const getDefaultDisplayWageMode = (state: RootState) =>
	state.config.defaultDisplayWageMode;

export const getIsMaintenance = (state: RootState) =>
	state.config.isMaintenance;
