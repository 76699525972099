import { useRoute } from '@react-navigation/native';
import React, { useMemo, useState } from 'react';
import {
	StyleSheet,
	FlatList,
	TouchableOpacity,
	View,
	TouchableHighlight,
	useWindowDimensions,
	Platform,
} from 'react-native';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';

const BusinessUnitItem = ({ unit }: any) => {
	const [isRowSelected, SetIsRowSelected] = useState(false);
	const dimensions = useWindowDimensions();
	const detailsConfig = useMemo(
		() =>
			!unit
				? []
				: [
						{ key: 'address', title: 'address' },
						{ key: 'phone', title: 'phone' },
						{ key: 'tikNikuim', title: 'tikNikuim' },
						{ key: 'modules', title: 'modules' },
				  ].filter((item: any) => !!unit[item.key]),
		[unit],
	);

	const contactDetailsConfig = useMemo(
		() =>
			!unit
				? []
				: ['contactName', 'contactEmail', 'contactPhone'].filter(
						(item: any) => !!unit[item],
				  ),
		[unit],
	);

	return (
		<>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => SetIsRowSelected(!isRowSelected)}
			>
				<View
					style={{
						display: 'flex',
						height: !isRowSelected ? 70 : 340,
						backgroundColor: 'white',
						marginTop: 10,
						marginBottom: 10,
						alignItems: 'center',
						paddingHorizontal: 20,
						paddingVertical: 25,
						width: '100%',
					}}
				>
					<View
						style={{
							flexDirection: 'row-reverse',
							width: '100%',
						}}
					>
						<View
							style={{
								flexDirection:
									unit.name?.length >= 50
										? 'column'
										: 'row-reverse',
								alignItems: 'center',
								flex: 1,
							}}
						>
							<Typography
								color='lightPrimary'
								weight='bold'
								size={16}
							>
								{unit.name}
							</Typography>
						</View>
					</View>
					{isRowSelected ? (
						<>
							{detailsConfig.map((item: any) => (
								<View
									key={item.key}
									style={{
										flexDirection: 'row-reverse',
										marginTop: 14,
										width: '100%',
									}}
								>
									<I18n
										size={14}
										weight='normal'
										style={{ width: 93 }}
									>{`customerDatails.businessUnits.${item.title}`}</I18n>
									<Typography
										size={14}
										weight='400'
										style={{
											maxWidth: dimensions.width - 120,
										}}
									>
										{unit[item.key]}
									</Typography>
								</View>
							))}
							{contactDetailsConfig.length ? (
								<I18n
									size={14}
									weight='bold'
									style={{ marginTop: 24, width: '100%' }}
								>
									customerDetails.contactDetails
								</I18n>
							) : null}
							{contactDetailsConfig.map((item: any) => (
								<View
									key={item}
									style={{
										flexDirection: 'row-reverse',
										marginTop: 14,
										width: '100%',
									}}
								>
									<I18n
										size={14}
										weight='normal'
										style={{ width: 93 }}
									>{`customerDatails.businessUnits.${item}`}</I18n>
									<Typography size={14} weight='400'>
										{unit[item]}
									</Typography>
								</View>
							))}
						</>
					) : null}
				</View>
			</TouchableHighlight>
		</>
	);
};

const BusinessUnits = ({ navigation }: any) => {
	const route = useRoute();
	const dimensions = useWindowDimensions();
	const customerData = useMemo(
		() => (route.params as any)?.customerData,
		[route.params],
	);

	return (
		<View style={styles.outWrapper}>
			<View style={styles.wrapper}>
				<TouchableOpacity onPress={() => navigation.goBack()}>
					<View style={styles.header}>
						<I18n size={20} weight='400' style={{}}>
							repCustomersPage.title
						</I18n>

						<ImageIcon name='leftArrow' />
					</View>
				</TouchableOpacity>

				<View style={{ alignItems: 'center' }}>
					<I18n size={28} weight='normal' style={{ marginTop: 18 }}>
						customerDetails.businessUnits
					</I18n>
					<Typography
						size={20}
						weight='normal'
						style={{ marginTop: 15 }}
					>
						{customerData.name}
					</Typography>
				</View>
				<View style={styles.dataContainer}>
					<View
						style={{
							flexDirection: 'row-reverse',
							alignSelf: 'flex-start',
							marginHorizontal: 10,
						}}
					>
						<I18n size={14}>general.numberOfRecords</I18n>
						<Typography size={14} weight='normal'>
							{` ${customerData?.businessUnits.length || '0'} `}
						</Typography>
					</View>
					<FlatList
						data={customerData?.businessUnits}
						renderItem={({ item }) => (
							<BusinessUnitItem unit={item} />
						)}
						keyExtractor={item => item.id}
						style={{
							height:
								dimensions.height -
								(Platform.OS === 'web' ? 250 : 240),
						}}
						contentContainerStyle={{ paddingBottom: 110 }}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginHorizontal: 19,
	},
	outWrapper: {
		backgroundColor: 'white',
		flex: 1,
	},
	wrapper: {
		backgroundColor: '#E3EDF2',
		flex: 1,
		paddingVertical: 25,
	},
	dataContainer: {
		marginTop: 24,
		marginBottom: 70,
	},
});

export default BusinessUnits;
