import React from 'react';
import {
	TextInput,
	TouchableHighlight,
	View,
	StyleSheet,
	Platform,
} from 'react-native';
import LinkButtonContainer from '../../../components/shared/Buttons/LinkButton.container';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import colors from '../../../config/colors';
import routes from '../../../config/routes';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../services/i18n.service';
import { UserCredentials } from '../SignIn.container';

interface SignInProps {
	navigation: any;
	username: string;
	password: string;
	shouldShowPassword: boolean;
	setUsername: (val: string) => void;
	setPassword: (val: string) => void;
	signIn: (data: UserCredentials) => void;
	toggleShowPassword: () => void;
	hasError: boolean;
	error: JSX.Element;
}

export const SigninWithPassword = ({
	navigation,
	username,
	password,
	shouldShowPassword,
	setUsername,
	setPassword,
	signIn,
	toggleShowPassword,
	hasError,
	error,
}: SignInProps) => {
	const errorInputStyle = hasError ? styles.errorInput : {};
	const currPlatform = useDevicePlatform();
	const isWeb: boolean =
		currPlatform === DeviceType.WEB ||
		currPlatform === DeviceType.MOBILE_WEB;
	const handlerKeyEnter = (e: any) => {
		if (e.nativeEvent.key === 'Enter') {
			signIn({ login: username, password });
		}
	};

	return (
		<>
			<I18n size={14} style={{ paddingBottom: 10 }}>
				login.loginWithPass.desc
			</I18n>
			<View style={styles.credentials}>
				<View style={[styles.inputContainer, errorInputStyle]}>
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						placeholder={i18nService.translate(
							isWeb
								? 'signin.teudatZehut'
								: 'signin.web.teudatZehut',
						)}
						value={username}
						onChangeText={setUsername}
						onKeyPress={handlerKeyEnter}
					/>
					<ImageIcon name={'user'} width={20} height={20} />
				</View>
				<View style={[styles.inputContainer, {}, errorInputStyle]}>
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						placeholder='סיסמה'
						value={password}
						onChangeText={setPassword}
						secureTextEntry={!shouldShowPassword}
						onKeyPress={handlerKeyEnter}
					/>
					<TouchableHighlight onPress={toggleShowPassword}>
						<ImageIcon
							name={
								shouldShowPassword
									? 'hidePassword'
									: 'showPassword'
							}
							width={22}
							height={14}
						/>
					</TouchableHighlight>
				</View>
			</View>
			{error}
			<SolidButtonContainer
				disabled={!username || !password}
				overrideStyle={{
					height: 61,
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: 35,
				}}
				onPress={() =>
					signIn({
						login: username,
						password,
					})
				}
			>
				<I18n size={16} weight='normal' color='white'>
					general.enterence
				</I18n>
			</SolidButtonContainer>
			<LinkButtonContainer
				onPress={() => navigation.navigate(routes.forgotPassword)}
				overrideStyle={{ width: 110 }}
			>
				<I18n size={14} weight='400' color='lightPrimary'>
					login.forgotPassword
				</I18n>
			</LinkButtonContainer>
		</>
	);
};

const styles = StyleSheet.create({
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		height: '100%',
		width: '100%',
		backgroundColor: '#efefef',
	},
	image: {
		resizeMode: 'center',
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: 500,
	},
	outerContainer: {
		backgroundColor: colors.white,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		paddingHorizontal: 16,
		paddingTop: 18,
		paddingBottom: 47,
	},
	container: {
		flexDirection: 'column',
		width: 301,
	},
	logoSection: {
		height: 70,
		paddingBottom: 10,
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	separatorLine: {
		backgroundColor: colors.primary,
		height: 1,
		opacity: 0.38,
	},
	credentials: {
		height: 115,
		marginBottom: 10,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	inputContainer: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 300,
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: 220,
		height: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		...Platform.select({
			web: {
				outlineWidth: 0,
				outlineStyle: 'none',
			},
		}),
	},
	errorInput: {
		borderWidth: 1,
		borderColor: colors.failure,
	},
	errorText: {
		alignItems: 'center',
		marginBottom: 16,
	},
});
