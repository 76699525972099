import httpService from '../../../services/http.service';

export const downloadAttachmentsHandler = async (fieldId: number, extraData: any) => {
	const res: any = await httpService.api({
		type: 'getManagerForm101Attachments',
		params: { formId: fieldId },
		responseType: 'blob',
	});
	let elm = document.createElement('a');
	elm.href = URL.createObjectURL(res);
	elm.setAttribute('download', `tofes101_${extraData.teudatZeut}_${extraData.taxYear}_attachments.zip`);
	elm.click();
};
