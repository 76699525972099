import React from 'react';
import SearchInput from './SearchInput.component';

interface SearchInputProps {
	onChange: (obj: { name: string; value: any }) => void;
	config: {
		type: string;
		name: string;
		defaultValue?: string;
	};
	styleConf?: {
		container: {
			[val: string]: any;
		};
		input?: { [val: string]: any };
	};
	selectedFilters?: any;
	placeholder?: string;
	hideIcon?: boolean;
	resetActiveSortByProps?: [];
}

const SearchInputContainer = ({
	onChange,
	config,
	styleConf,
	selectedFilters,
	placeholder,
	hideIcon,
	resetActiveSortByProps,
}: SearchInputProps) => {
	return (
		<SearchInput
			onChange={onChange}
			config={config}
			styleConf={styleConf}
			selectedFilters={selectedFilters}
			placeholder={placeholder || config.placeholder}
			hideIcon={hideIcon}
			resetActiveSortByProps={resetActiveSortByProps}
		/>
	);
};

export default SearchInputContainer;
