import _, { cloneDeep, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import httpService from '../../../../../services/http.service';

const CustomerManagmentWrapper = ({
	id,
	onNext,
	route,
	Component,
	isAdmin,
	openModal,
	unitId,
	data,
	onSubmit,
	disabled,
}: any) => {
	const [customersInfo, setCustomersInfo] = useState<any>(
		data?.user_role_rep_customer || [],
	);
	const [deleteArray, setDeletedArray] = useState(data?.deleted_ids || []);

	const getRepCustomers: any = async (data: any[]) => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: 0,
				ps: 1000,
				search: '',
				f: {
					unitIds: [unitId],
				},
			},
		});

		return res.hasNext
			? await getRepCustomers([...data, ...res.data])
			: [...data, ...res.data];
	};

	const getAdminCustomers = async () => {
		const newcCustomers = await getRepCustomers([]);
		setCustomersInfo(
			newcCustomers.map((cust: any) => ({
				role_id: 8,
				customer_id: cust.numericId,
				customer_name: cust.name,
				customer_hp: cust.id,
				unit_ids: [],
				user_role_permission: [],
				excluded_user: null,
				exclude_selected: true,
			})),
		);
	};

	useEffect(() => {
		if (
			isAdmin &&
			(!data?.user_role_rep_customer ||
				!data?.user_role_rep_customer.length) &&
			(!data?.hasOwnProperty('all_customer') || data?.all_customer)
		) {
			getAdminCustomers();
		}
	}, []);

	const _onSubmit = async () => {
		onSubmit({
			user_role_rep_customer: customersInfo.map((cust: any) =>
				omit(cust, ['customerUnits']),
			),
			deleted_ids: deleteArray.length ? deleteArray : null,
		});
		onNext();
	};

	const checkIfSaveButtonIsDisabled = () => {};

	const onCancel = async () => {
		onNext();
	};

	return (
		<Component
			setCustomersInfo={setCustomersInfo}
			customersInfo={customersInfo}
			onSubmit={_onSubmit}
			onCancel={onCancel}
			disabledSubmit={checkIfSaveButtonIsDisabled()}
			setDeletedArray={setDeletedArray}
			deleteArray={deleteArray}
			route={route}
			id={id}
			isAdmin={isAdmin}
			openModal={openModal}
			unitId={unitId}
			disabled={disabled}
		/>
	);
};

export default CustomerManagmentWrapper;
