export const addPaddingToTime = (time: string): string => {
	if (!time) return '';

	let [hours, minutes] = time.split(':');

	if (!hours && !minutes) return '';

	if (hours.length === 1) {
		hours = '0' + hours;
	}

	if (!minutes || minutes.length == 0) {
		minutes = '00';
	} else if (minutes.length === 1) {
		if (Number(minutes) > 5) {
			minutes = '0' + minutes;
		} else {
			minutes = minutes + '0';
		}
	}

	const correctedTime = hours + ':' + minutes;
	return correctedTime;
};

export const prepareTime = (value: string) => {
	let str = '';
	const numberVal = Number(value);
	//when time contains ":" already
	if (value.includes(':')) {
		let [hours, minutes] = value.split(':');
		if (minutes.length <= 2) {
			if (Number(minutes) <= 60) {
				str = hours + ':' + minutes;
			} else {
				//reject new time if minutes > 60 (e.g. 78)
				str = value.slice(0, -1);
			}
			//reject new time if minutes bigger than 2 numbers
		} else str = value.slice(0, -1);
	} else {
		if (value.length === 1) {
			str = value;
		}
		if (value.length === 2) {
			if (numberVal <= 23) {
				str = `${value}`;
				//reject new time if hours bigger than 23 hours
			} else {
				str = `${value[0]}:${value[1]}`;
			}
		}
		//if entered 3 numbers should add ":" and split time to hours:minutes
		if (value.length === 3) {
			if (numberVal <= 236) {
				str = `${value[0]}${value[1]}:${value[2]}`;
			} else {
				str = value.slice(0, -1);
			}
		}
	}
	return str;
};
