import { yupResolver } from '@hookform/resolvers/yup';
import _, { cloneDeep } from 'lodash';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import I18n from '../../../components/shared/i18n/I18n.component';
import { Privileges } from '../../../constants/roleTypes.constant';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import httpService from '../../../services/http.service';
import { modalService } from '../../../services/modal.service';
import {
	getBussinesUnitsModules,
	getUserData,
} from '../../../store/selectors/login.selectors';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import { onSubmitFail, onSubmitSuccess } from './createUpdateUser.utils';
import { UserEditorContext } from './CreateUpdateUserMobile/userEditorContext';
import { validationSchema } from './CreateUpdateUserValidation';

const CreateUpdateUserWrapper = ({
	id,
	onNext,
	callback,
	route,
	Component,
	unitId,
	businessUnitsList: tempBusinessUnitsList,
}: any) => {
	const [rolesInfo, setRolesInfo] = useState<any>({});
	const [initialData, setInitialData] = useState<any>();
	const [userDetails, setUserDetails] = useState({
		identity_type: 'ID',
		phone_prefix: '050',
	} as any);
	const unitsModules = useSelector(getBussinesUnitsModules);
	const userData = useSelector(getUserData);
	const validationFunctions = useRef<Map<number, Function>>(new Map());
	const getValuesFunctions = useRef<Map<number, Function>>(new Map());
	const isGetData = useRef(false);
	const { updatedCustomers } = useContext(UserEditorContext);
	const isUpdateHimself = useMemo(
		() => userData?.user_id === id,
		[userData?.user_id, id],
	);
	const businessUnitsList = useMemo(
		() =>
			tempBusinessUnitsList.filter(
				(unit: any) =>
					hasPermissionOnBusinessUnit(
						parseInt(unit.id),
						Privileges.ROLE_CREATE_USER_WRITE,
					) || initialData?.[unit.id],
				// 	&&
				// (id ||
				// 	hasPermissionOnBusinessUnit(
				// 		parseInt(unit.id),
				// 		Privileges.ROLE_MGM_USER_WRITE,
				// 	)),
			),

		[tempBusinessUnitsList, initialData],
	);

	const formMethods = useForm({
		defaultValues: userDetails,
		resolver: yupResolver(validationSchema(id)),
		context: { identityType: userDetails?.identity_type },
		mode: 'onBlur',
		reValidateMode: 'onChange',
	});

	const canSend = useMemo(
		(): boolean | string =>
			businessUnitsList.find(
				(unit: any) =>
					(hasPermissionOnBusinessUnit(
						parseInt(unit.id),
						Privileges.ROLE_CREATE_USER_WRITE,
					) &&
						hasPermissionOnBusinessUnit(
							parseInt(unit.id),
							Privileges.ROLE_MGM_USER_WRITE,
						) &&
						!isUpdateHimself &&
						(JSON.stringify(rolesInfo) !==
							JSON.stringify(initialData) ||
							formMethods.formState.isDirty)) ||
					(!hasPermissionOnBusinessUnit(
						parseInt(unit.id),
						Privileges.ROLE_CREATE_USER_WRITE,
					) &&
						hasPermissionOnBusinessUnit(
							parseInt(unit.id),
							Privileges.ROLE_MGM_USER_WRITE,
						) &&
						!isUpdateHimself &&
						JSON.stringify(rolesInfo) !==
							JSON.stringify(initialData)) ||
					(hasPermissionOnBusinessUnit(
						parseInt(unit.id),
						Privileges.ROLE_CREATE_USER_WRITE,
					) &&
						(!hasPermissionOnBusinessUnit(
							parseInt(unit.id),
							Privileges.ROLE_MGM_USER_WRITE,
						) ||
							isUpdateHimself) &&
						formMethods.formState.isDirty),
			),
		[
			businessUnitsList,
			rolesInfo,
			initialData,
			formMethods.formState.isDirty,
			isUpdateHimself,
		],
	);

	useEffect(() => {
		if (updatedCustomers?.unitId) {
			const { unitId, data } = updatedCustomers;

			setRolesInfo({
				...rolesInfo,
				[unitId]: {
					...rolesInfo[unitId],
					user_role_rep_customer_info: {
						...(rolesInfo[unitId].user_role_rep_customer_info ||
							{}),
						...data,
					},
				},
			});
		}
	}, [updatedCustomers]);

	useEffect(() => {
		formMethods.reset(userDetails);
	}, [userDetails]);

	const addBusinessUnit = async (id: number, prevId?: number) => {
		const tempRolesInfo = { ...rolesInfo };
		const departments: any = await httpService.api({
			type: 'getDepartmentsFilterList',
			query: { unitIds: id, pageName: 'users' },
		});
		let roles: any = await httpService.api({
			type: 'getRoles',
			query: { unitIds: id },
		});

		if (departments.length <= 1) {
			roles = roles.filter((role: any) => !role.allocate_department);
		}

		if (prevId) {
			delete tempRolesInfo[prevId];
		}

		const isInternal = unitsModules[id]?.find(
			(modul: any) => modul.id !== 115,
		);

		const hasEmp = roles.find((rol: any) => rol.id === 6);
		const user_roles =
			!Object.keys(tempRolesInfo).find((unitId: string) =>
				tempRolesInfo[unitId].user_roles.includes(6),
			) && hasEmp
				? [6]
				: [roles[0].id];
		const department_id =
			departments.length === 1 && isInternal ? departments[0].id : null;

		if (!hasPermissionOnBusinessUnit(id, Privileges.ROLE_MGM_USER_WRITE)) {
			setRolesInfo({
				...tempRolesInfo,
				[id]: {
					roles,
					departments,
					employee_type: isInternal ? 'INTERNAL' : 'EXTERNAL',
					department_id,
				},
			});
		} else {
			setRolesInfo({
				...tempRolesInfo,
				[id]: {
					roles,
					departments,
					employee_type: isInternal ? 'INTERNAL' : 'EXTERNAL',
					department_id,
					user_roles,
					user_role_department: {},
					excluded_user: null,
					exclude_selected: true,
					user_role_permission: {},
					user_roles_indicator: {},
					user_role_rep_customer_info:
						user_roles[0] === 7
							? { 7: { all_customer: true } }
							: {},
				},
			});
		}
	};

	const getUserDetails = async () => {
		try {
			const res: any = await httpService.api({
				type: 'getUserDetails',
				params: { id, unitId },
			});

			if (res) {
				const { role_info, info } = res;

				const userInfo: any = {
					...info,
					phone_prefix: '',
				};

				if (userInfo?.phone && userInfo?.phone?.length > 7) {
					userInfo.phone_prefix = userInfo.phone.substr(0, 3);
					userInfo.phone = userInfo.phone.substr(3);
				} else if (userInfo?.phone) {
					userInfo.phone_prefix = '050';
				}

				setUserDetails(userInfo);

				//since BE not return departments array (if select all), this is overlap to fix
				const rolesData = Object.keys(role_info).reduce(
					(res, role: any) => {
						const rolesWithAllocatedDepartment = role_info[
							role
						].roles
							?.filter((role: any) => role.allocate_department)
							.map((el: any) => el.id);

						let user_role_department =
							role_info[role].user_role_department;

						if (
							!role_info[role].user_role_department &&
							role_info[role].user_roles?.length &&
							rolesWithAllocatedDepartment?.length
						) {
							user_role_department = role_info[
								role
							].user_roles.reduce(
								(r: any, id: any) => ({
									...r,
									...(rolesWithAllocatedDepartment.includes(
										id,
									)
										? {
												[id]: role_info[
													role
												].departments.map(
													(dep: any) => dep.id,
												),
										  }
										: {}),
								}),
								{},
							);
						}
						return {
							...res,
							[role]: {
								...role_info[role],
								isEditUnit: true,
								user_role_department,
							},
						};
					},
					{},
				);
				setRolesInfo(cloneDeep(rolesData));
				setInitialData(cloneDeep(rolesData));
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (id && !isGetData.current) {
			getUserDetails();
			isGetData.current = true;
		} else if (
			businessUnitsList.length &&
			!initialData &&
			!isGetData.current
		) {
			isGetData.current = true;

			addBusinessUnit(businessUnitsList[0].id);
		}
		//
	}, [businessUnitsList]);

	const onSubmit = async () => {
		const result = await formMethods.trigger();
		const isNotValidArr = await Promise.all(
			[...validationFunctions.current.values()].map((key: any) =>
				key?.(),
			),
		);

		if (Object.keys(formMethods.formState.errors).length) {
			return;
		}

		let errors = isNotValidArr?.find((res: any) => !!res);
		let role_info: any = null;

		if (!errors && !id) {
			role_info = [...getValuesFunctions?.current.entries()].reduce(
				(res, [key, func]: any) => ({
					...res,
					...(func?.(initialData?.[key]) || {}),
				}),
				{},
			);
			const { hasEmployeeRole, shouldHaveEmployee } = Object.keys(
				rolesInfo,
			).reduce(
				(res, role) => {
					const shouldHaveEmployee =
						role_info[role].employee_type === 'INTERNAL' &&
						role_info[role].user_roles &&
						rolesInfo[role].roles.find(rol => rol.id === 6);
					const hasEmployeeRole =
						role_info[role].user_roles?.includes(6);
					return {
						shouldHaveEmployee:
							res.shouldHaveEmployee || shouldHaveEmployee,
						hasEmployeeRole:
							shouldHaveEmployee && hasEmployeeRole
								? res.hasEmployeeRole + 1
								: res.hasEmployeeRole,
					};
				},
				{ hasEmployeeRole: 0, shouldHaveEmployee: false },
			);
			if (shouldHaveEmployee && !hasEmployeeRole) {
				errors = { showErrModal: 'editUserModal.mustEmployeeRole' };
			}
		}

		if (errors) {
			errors?.showErrModal &&
				(await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>{errors?.showErrModal}</I18n>
						</AlertModal>
					),
				));
			return;
		}

		const values = formMethods.getValues();
		const teudat_zeut =
			values.identity_type === 'ID' || id
				? values.teudat_zeut
				: values.darkon;

		delete values.darkon;

		const payload = {
			...values,
			teudat_zeut,
			phone: `${values.phone_prefix}${values.phone}`,
			role_info:
				role_info ||
				[...getValuesFunctions?.current.entries()].reduce(
					(res, [key, func]: any) => ({
						...res,
						...(func?.(initialData?.[key]) || {}),
					}),
					{},
				),
		};

		try {
			const res = await httpService.api({
				type: id ? 'updateUserDetails' : 'createUser',
				params: { id, unitId },
				data: payload,
			});
			onNext();
			callback();
			onSubmitSuccess(id ? 'edit' : 'create');
		} catch (error) {
			onSubmitFail(error);
		}
	};

	const onCancel = async () => {
		if (canSend) {
			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						onNext();
						callback();
					},
					onSubmit: (callback: Function) => {
						onSubmit();
						callback();
					},
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},
				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>general.shouldSaveChanges</I18n>
					</ModalConfirmActions>
				),
			);
		} else {
			onNext();
		}
	};

	return (
		<FormProvider {...formMethods}>
			<Component
				setRoles={setRolesInfo}
				isActive={userDetails.status}
				defaultOpenUnit={unitId}
				userId={id}
				roles={rolesInfo}
				rolesInfo={rolesInfo}
				onSubmit={onSubmit}
				onCancel={onCancel}
				disabledSubmit={!canSend}
				addBusinessUnit={addBusinessUnit}
				validationFunctions={validationFunctions}
				getValuesFunctions={getValuesFunctions}
				route={route}
				id={id}
				businessUnitsList={businessUnitsList}
			/>
		</FormProvider>
	);
};

export default CreateUpdateUserWrapper;
