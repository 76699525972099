import React from 'react';
import { View } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import I18n from '../../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../../../components/shared/Typography/Typography.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../../../hooks/useDevicePlatform.hook';

const ChildRowTitle = ({
	index,
	remove,
	canRemove,
}: {
	index: number;
	remove: Function;
	canRemove: boolean;
}) => {
	const platform = useDevicePlatform();
	const isMobile = platform !== DeviceType.WEB;

	return (
		<View
			style={{
				flexDirection: 'row-reverse',
				marginBottom: isMobile ? 27 : 22,
				alignItems: isMobile ? 'center' : 'baseline',
			}}
		>
			<View
				style={
					{
						flex: isMobile ? 1 : 'unset',
						flexDirection: 'row-reverse',
					} as any
				}
			>
				<I18n size={20} weight='normal'>
					general.child
				</I18n>
				<Typography size={20} weight='normal'>
					{` ${index + 1} `}
				</Typography>
			</View>
			{canRemove ? (
				<TouchableWithoutFeedback
					onPress={() => {
						canRemove && remove([index]);
					}}
				>
					<View>
						{isMobile ? (
							<ImageIcon name='delete' width={17} />
						) : (
							<I18n
								style={{ marginRight: 4 }}
								size={12}
								color='primary'
							>
								general.remove
							</I18n>
						)}
					</View>
				</TouchableWithoutFeedback>
			) : null}
		</View>
	);
};

export default ChildRowTitle;
