import {
	DrawerContentComponentProps,
	DrawerContentOptions,
} from '@react-navigation/drawer';
import React from 'react';
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import colors from '../../../config/colors';
import Typography from '../Typography/Typography.component';

interface DrawerItemProps {
	routeName: string;
	isActive: boolean;
	index: number;
	onPress: (routeName: string) => void;
}

const DrawerItem = ({
	routeName,
	isActive,
	index,
	onPress,
}: DrawerItemProps) => {
	const containerStyle = StyleSheet.flatten([
		styles.route,
		isActive ? styles.selectedRoute : {},
	]);

	return (
		<TouchableHighlight
			underlayColor='transparent'
			onPress={() => onPress(routeName)}
		>
			<View key={index} style={containerStyle}>
				<Typography
					style={{ textAlign: 'right' }}
					color={isActive ? 'white' : 'darkGrey'}
					weight='normal'
					size={14}
				>
					{routeName}
				</Typography>
			</View>
		</TouchableHighlight>
	);
};

const styles = StyleSheet.create({
	route: {
		position: 'relative',
		width: '100%',
		// marginLeft: 12,
		height: 50,
		justifyContent: 'center',
		paddingRight: 18,
		borderTopLeftRadius: 50,
		borderBottomLeftRadius: 50,
	},
	selectedRoute: {
		backgroundColor: colors.primary,
	},
});

export default DrawerItem;
