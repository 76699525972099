import React, { useEffect, useState } from 'react';
import httpService from '../../../../services/http.service';
import CustomerDetails from './CustomerDetails.component';

const CustomerDetailsContainer = ({
	navigation,
	customerId,
	canEditCustomer,
	updateRow,
	forceRefresh,
}: any) => {
	const [customerData, setCustomerData] = useState<any>();

	const getCustomerData = async () => {
		const res: any = await httpService.api({
			type: 'getRepCustomerDetails',
			params: { customerId },
		});
		if (res) {
			setCustomerData({
				...res,
				contactName: `${res.contactFirstName || ''} ${
					res.contactLastName || ''
				}`,
				contactPhone: `${res.contactCellPrefix || ''}${
					res.contactCellPhone || ''
				}`,
				businessUnits: res?.businessUnits
					?.map((unit: any) => ({
						...unit,
						contactPhone: `${unit.contactCellPrefix || ''}${
							unit.contactCellPhone || ''
						}`,
					}))
					.sort((a: any, b: any) => a.name.localeCompare(b.name)),
			});
		}
	};

	const updateData = (data: any) => {
		updateRow(data);
		setCustomerData({
			...customerData,
			...data,
			contactName: `${data.contactFirstName || ''} ${
				data.contactLastName || ''
			}`,
			contactPhone: `${data.contactCellPrefix || ''}${
				data.contactCellPhone || ''
			}`,
		});
	};

	useEffect(() => {
		if (customerId) {
			getCustomerData();
		}
	}, [customerId, forceRefresh]);

	return (
		<CustomerDetails
			customerData={customerData}
			canEditCustomer={canEditCustomer}
			navigation={navigation}
			customerId={customerId}
			updateData={updateData}
		/>
	);
};

export default CustomerDetailsContainer;
