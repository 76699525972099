import React, { useMemo } from 'react';
import {
	Dimensions,
	StyleSheet,
	useWindowDimensions,
	View,
} from 'react-native';
import { Breadcrumbs, Link } from '@material-ui/core';

import colors from '../../../../config/colors';
import Header from '../../../../components/shared/Header/Header.container';
import WebHeader from '../../../../components/shared/Header/WebHeader.component';
import ReportsPageWrapper from '../../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { getHeaders } from './RepForm106Reports.utils';
import Filters from '../../../../components/web/Filters/Filters.component';
import TableContainer from '../../../../components/web/ReactTable/Table.container';
import NoResults from '../../../../components/shared/NoResults/NoResults';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getExcel } from '../RepReports.utils';

export interface RepForm106ReportsProps {
	navigation: any;
	route: any;
	data: any[];
	filtersConfig: any;
	onLoadMore(): void;
	onFilter(filterData: any): void;
	onSort: (field: string, direction: string) => void;
	disabled: boolean;
	filters: any;
	refresh(): void;
	reportType: number;
	isLoading: boolean;
	canEnterCustomer: boolean;
}

const RepForm106Reports = ({
	navigation,
	route,
	data,
	filtersConfig,
	onSort,
	onLoadMore,
	onFilter,
	filters,
	isLoading,
	canEnterCustomer,
}: RepForm106ReportsProps) => {
	const { height: windowHeight } = useWindowDimensions();
	const isSmallDimensions = useMemo(
		() => window.innerWidth <= 1600,
		[window.innerWidth],
	);

	const updateFilters = val => {
		onFilter(val);
	};

	return (
		<View style={styles.container}>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<I18n size={36} weight='normal' style={{ flex: 1 }}>
						repReports.form106eports.title
					</I18n>
				}
				noHeader={true}
			>
				<View style={styles.filterWrapper}>
					<Filters
						config={filtersConfig}
						onChange={updateFilters}
						hideSearchInput
						customStyles={{
							container: {
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								alignItems: 'center',
							},
							filterContainer: {
								marginLeft: 15,
								zIndex: 10001,
							},
						}}
					/>
					<TouchableOpacity
						style={styles.excel}
						onPress={() =>
							getExcel(
								filters,
								`106_status_report_${filters.year}`,
								'form-106',
							)
						}
					>
						<ImageIcon name='excel' />
					</TouchableOpacity>
				</View>

				{data.length > 0 || isLoading ? (
					<View
						style={{
							maxHeight: isSmallDimensions
								? windowHeight - 240
								: windowHeight - 250,
						}}
					>
						<TableContainer
							keyName={''}
							headers={getHeaders(canEnterCustomer, route.name)}
							data={data || []}
							getNextData={onLoadMore}
							onSort={onSort}
							hasFooter={false}
						/>
					</View>
				) : (
					<NoResults title='repReports.general.noResult' />
				)}
			</ReportsPageWrapper>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.tableBackground,
		height: '100%',
	},
	filterWrapper: {
		marginBottom: 20,
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		zIndex: 100,
	},
	excel: {
		paddingLeft: 5,
		alignItems: 'center',
		marginTop: 10,
	},
	employeeListContainer: {
		width: 420,
	},
});

export default React.memo(RepForm106Reports);
