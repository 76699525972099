import React from 'react';
import { StyleSheet, TouchableHighlight, View } from 'react-native';
import { DocumentsData } from './Documents.container';
import Typography from '../../components/shared/Typography/Typography.component';
import {
	DownloadEmployeeSection,
	DownloadSection,
	SendReminderSection,
	StatusSection,
} from './Documents.utils';
import { i18nService } from '../../services/i18n.service';
import ActionButtonsMenu from '../UsersManagmentPage/UsersTablePage/UserTableMobileRow/ActionButtonsMenu';
import I18n from '../../components/shared/i18n/I18n.component';

const mobileRowItem = (
	item: DocumentsData,
	businessUnitsList: any,
	isEmployer: boolean,
	disabled: boolean,
	dimentions: any,
	setRemoveDoc: () => void,
	setShowModal: () => void,
) => {
	const showMenu = item.identityType === 'ID' ? true : false;
	return (
		<View
			style={[
				styles.rowContainer,
				{ width: dimentions.width },
				{ minHeight: isEmployer ? 139 : 70 },
			]}
			key={item?.id}
		>
			<TouchableHighlight
				key={item?.id}
				// onPress={() => console.log('onPress')}
				underlayColor='transparent'
				style={{ width: '100%' }}
			>
				{isEmployer ? (
					<View style={styles.downloadSection}>
						<View
							style={[
								styles.innerContainerStatus,
								disabled && { paddingVertical: 7 },
								businessUnitsList?.length > 1 &&
									!disabled && { paddingBottom: 7 },
							]}
						>
							<View style={styles.dataRowContainer}>
								<View>
									{businessUnitsList.length > 1 && (
										<Typography
											weight='bold'
											size={12}
											style={{ marginBottom: 5 }}
										>
											{item.businessUnitName}
										</Typography>
									)}
									<View style={styles.dataRowContainer}>
										<StatusSection
											status={item.status}
											statusName={
												item.clientStatus
													? item.clientStatus
													: i18nService.translate(
															`documents.filters.status.${item.status}`,
													  )
											}
											style={{
												flexDirection: 'row-reverse',
											}}
											isMobile={true}
										/>
										{!disabled && (
											<View
												style={styles.dataRowContainer}
											>
												<SendReminderSection
													data={item}
													disabled={disabled}
													isMobile={true}
													userId={item?.userId}
												/>
											</View>
										)}
									</View>
								</View>
							</View>
							{!disabled && showMenu && (
								<View style={{ width: 20, zIndex: 1 }}>
									<ActionButtonsMenu
										userId={item.userId}
										name={`${item.fullName} (${item.teudatZeut})`}
										unitId={item.businessUnitId}
										docId={item.id}
										showSendReminder={
											item.status === 'AWAITING_SIGN'
										}
										showDelete={
											item.status === 'ABANDONED' ||
											item.status === 'SECURITY_FAILED'
										}
										setRemoveDoc={setRemoveDoc}
										setShowModal={setShowModal}
										customStayle={{
											alignItems: 'flex-start',
										}}
									/>
								</View>
							)}
						</View>
						<View style={styles.innerContainerFile}>
							<DownloadSection
								data={item}
								disabled={disabled}
								isMobile={true}
								maxWidth={dimentions.width * 0.67}
							/>
							<I18n
								size={12}
								style={{
									lineHeight: 20,
									paddingRight: 7,
								}}
							>
								{item.type}
							</I18n>
						</View>
						<View style={styles.innerContainerName}>
							<Typography size={14} weight='bold'>
								{item.fullName}
							</Typography>
							<Typography size={14} style={styles.teudatZeut}>
								{item.teudatZeut}
							</Typography>
						</View>
						<View style={styles.innerContainerLast}>
							<Typography
								size={12}
								color={'grey'}
							>{`${i18nService.translate(
								'documents.mobile.table.lastUpdate',
							)} ${item.lastUpdate}`}</Typography>
						</View>
					</View>
				) : (
					<View>
						<DownloadEmployeeSection data={item} isMobile={true} />
						<Typography size={14}>{`${i18nService.translate(
							'documents.mobile.table.lastUpdate',
						)} ${item.lastUpdate}`}</Typography>
					</View>
				)}
			</TouchableHighlight>
		</View>
	);
};

const styles = StyleSheet.create({
	title: {
		marginLeft: 8,
	},
	rowContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		backgroundColor: 'white',
		marginBottom: 16,
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingVertical: 16,
		paddingHorizontal: 20,
	},
	dataRowContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	downloadSection: {
		flex: 1,
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		alignItems: 'center',
	},
	innerContainerStatus: {
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'space-between',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		zIndex: 1,
	},
	innerContainerFile: {
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'flex-start',
		flexDirection: 'row-reverse',
		alignItems: 'baseline',
		marginBottom: 10,
	},
	innerContainerName: {
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'flex-start',
		flexDirection: 'row-reverse',
		alignItems: 'baseline',
		marginBottom: 9,
	},
	innerContainerLast: {
		flexWrap: 'wrap',
		width: '100%',
		justifyContent: 'flex-start',
		flexDirection: 'row-reverse',
		alignItems: 'baseline',
	},
	teudatZeut: {
		marginRight: 5,
	},
});

export default mobileRowItem;
