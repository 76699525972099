import Typography from '../../../../components/shared/Typography/Typography.component';
import {
	addCommaSeparatorToNumber,
	addToTableCellCurrencySymbol,
	getPeriodNumber,
	prepareRowNameForClient,
} from '../../../../utils/reportsPagesHelpers.utils';
import { i18nService } from '../../../../services/i18n.service';
import React from 'react';
import httpService from '../../../../services/http.service';
import { Period } from '../../../../interfaces/redux.interface';
import moment from 'moment';
import { compact } from 'lodash';
import { useSelector } from 'react-redux';
import { getBusinessUnitsListByPermissions } from '../../../../store/selectors/reportsFilters.selectors';
import routes from '../../../../config/routes';

export interface TableHeader {
	Header: string;
	accessor: string;
	Footer?: string;
	CustomCell?: (props: any) => void;
	SubCell?: (props: any) => void;
	isSortable?: boolean;
	id?: string;
	style?: any;
}

export const setTableHeaders = (periods: string[], totalReports: any) => {
	let total: any = {};
	for (const field in totalReports) {
		total[field] = addCommaSeparatorToNumber(totalReports[field]);
	}
	total = addToTableCellCurrencySymbol(total);
	const initialHeaders: TableHeader[] = [
		{
			accessor: 'expander',
			Header: '',
			id: 'expander',
			style: { width: 0 },
		},
		{
			accessor: 'name',
			Header: '',
			CustomCell: ({ value, row }: any) => {
				const businessUnitsList = useSelector((state: any) =>
					getBusinessUnitsListByPermissions(
						state,
						routes.costingReports.privileges,
					),
				);

				return (
					<Typography weight='bold' size={14}>
						{value}
						{businessUnitsList.length > 1
							? ` (${row.original.business_unit_name})`
							: ''}
					</Typography>
				);
			},
			SubCell: ({ value }: any) => {
				return (
					<Typography weight='bold' size={14} color='primary'>
						{value}
					</Typography>
				);
			},
			Footer: i18nService.translate('worklogReportPage.total'),
		},
	];

	for (const period of periods) {
		initialHeaders.push({
			accessor: period,
			Header: period.includes('-') ? '' : prepareRowNameForClient(period),
			isSortable: !period.includes('-'),
			Footer: total[period],
		});
	}
	return initialHeaders;
};

export const fetchExcel = async ({ sortBy, sortDirection, filter }: any) => {
	try {
		const res = await httpService.api<any>({
			type: 'getCompareCostReportsExcelFile',
			responseType: 'blob',
			returnAllRes: true,
			data: {
				s: sortBy,
				sd: sortDirection,
				f: filter,
			},
		});
		return res;
	} catch (e) {
		console.log(e);
	}
};

const periodOptions = [
	{
		id: 'MONTH',
		name: 'period.month',
	},
	{
		id: 'QUARTERLY',
		name: 'period.quaterly',
	},
	{ id: 'YEAR', name: 'period.year' },
];

export const getFiltersConfig = (
	period: any,
	departmentsList: any,
	selectedDepartments: any,
	getEmployeesFilter: Function,
	date: {
		toMonth: number;
		toYear: number;
		fromMonth: number;
		fromYear: number;
	},
	userData: any,
	dateChanged: string,
	isInitialConfig: boolean,
	businessUnitsList: any,
	isBusinessUnitsMulti: boolean,
	unitIds: any,
	departmentFilterKey?: string,
	originalPeriods?: string[],
	periods?: string[],
) => {
	let dateTo =
		date.toMonth && date.toYear
			? new Date(date.toYear, date.toMonth - 1)
			: new Date();
	let dateFrom =
		date.fromMonth && date.fromYear
			? new Date(date.fromYear, date.fromMonth - 1)
			: new Date();

	const periodNumberTo = getPeriodNumber(date.toMonth);
	const periodNumberFrom = getPeriodNumber(date.fromMonth);

	let dateToPlaceholder;
	let dateFromPlaceholder;

	let minDate = null;
	let maxDate = null;

	switch (period) {
		case Period.QUARTERLY:
			if (dateChanged === 'dateTo') {
				const quarterPeriodMinDate = new Date(
					date.toYear,
					date.toMonth - 1,
				);
				quarterPeriodMinDate.setFullYear(
					quarterPeriodMinDate.getFullYear() - 3,
				);
				minDate = quarterPeriodMinDate;

				if (dateFrom < minDate) {
					dateFrom = minDate;
				}
			}

			if (dateChanged === 'dateFrom') {
				const quarterPeriodMaxDate = new Date(
					date.fromYear,
					date.fromMonth - 1,
				);
				quarterPeriodMaxDate.setFullYear(
					quarterPeriodMaxDate.getFullYear() + 3,
				);
				maxDate = quarterPeriodMaxDate;

				if (dateTo > maxDate) {
					dateTo = maxDate;
				}
			}
			dateFromPlaceholder =
				i18nService.translate('filter.quarter') +
				' ' +
				periodNumberFrom +
				` ,${dateFrom.getFullYear()}`;
			dateToPlaceholder =
				i18nService.translate('filter.quarter') +
				' ' +
				periodNumberTo +
				` ,${dateTo.getFullYear()}`;

			break;
		case Period.YEAR:
			if (dateChanged === 'dateTo') {
				const yearPeriodMinDate = new Date(
					date.toYear,
					date.toMonth - 1,
				);
				yearPeriodMinDate.setFullYear(
					yearPeriodMinDate.getFullYear() - 12,
				);
				minDate = yearPeriodMinDate;

				if (dateFrom < minDate) {
					dateFrom = minDate;
				}
			}

			if (dateChanged === 'dateFrom') {
				const yearPeriodMaxDate = new Date(
					date.fromYear,
					date.fromMonth - 1,
				);
				yearPeriodMaxDate.setFullYear(
					yearPeriodMaxDate.getFullYear() + 12,
				);
				maxDate = yearPeriodMaxDate;

				if (dateTo > maxDate) {
					dateTo = maxDate;
				}
			}

			dateFromPlaceholder = `${dateFrom.getFullYear()}`;
			dateToPlaceholder = `${dateTo.getFullYear()}`;

			break;
		default:
			if (dateChanged === 'dateTo') {
				const monthPeriodMinDate = new Date(
					date.toYear,
					date.toMonth - 1,
				);
				monthPeriodMinDate.setMonth(
					monthPeriodMinDate.getMonth() - 1 - 12,
				);
				minDate = monthPeriodMinDate;

				if (dateFrom < minDate) {
					dateFrom = minDate;
				}
			}

			if (dateChanged === 'dateFrom') {
				const monthPeriodMaxDate = new Date(
					date.fromYear,
					date.fromMonth - 1,
				);
				monthPeriodMaxDate.setMonth(
					monthPeriodMaxDate.getMonth() - 1 + 12,
				);
				maxDate = monthPeriodMaxDate;

				if (dateTo > maxDate) {
					dateTo = maxDate;
				}
			}

			dateFromPlaceholder =
				i18nService.translate(
					`months.${moment(dateFrom).format('MMMM')}`,
				) + ` ${dateFrom.getFullYear()}`;
			dateToPlaceholder =
				i18nService.translate(
					`months.${moment(dateTo).format('MMMM')}`,
				) + ` ${dateTo.getFullYear()}`;

			break;
	}

	return compact([
		{
			name: 'periods',
			type: 'multiselect',
			placeholder: 'periodsCompare',
			options: originalPeriods
				?.filter((period: any) => !period.includes('-'))
				.map((period: any) => ({
					id: period,
					name: prepareRowNameForClient(period),
				})),
			styles: {
				control: {
					width: 150,
					maxWidth: 150,
				},
			},
			value: periods?.map((val: string) => ({
				id: val,
				name: prepareRowNameForClient(val),
			})),
		},
		{
			name: 'employees',
			type: 'asyncmultiselect',
			placeholder: 'employees',
			loadOptions: getEmployeesFilter,
			styles: {
				width: 274,
			},
			key: `${JSON.stringify(
				selectedDepartments,
			)}${departmentsList}${JSON.stringify(unitIds)}`,
		},
		{
			name: 'departments',
			type: 'multiselect',
			options: departmentsList,
			placeholder: 'departments',
			styles: {
				width: 200,
			},
			key: departmentFilterKey,
		},
		{
			name: 'date_to',
			type: 'datepicker',
			date: dateTo,
			maxDate,
			label: 'filter.to',
			placeholder: dateToPlaceholder,
			showYearPicker: period === Period.YEAR ? true : false,
			showQuarterYearPicker: period === Period.QUARTERLY ? true : false,
			styles: {
				width: 140,
			},
		},
		{
			name: 'date_from',
			type: 'datepicker',
			date: dateFrom,
			label: 'filter.from',
			placeholder: dateFromPlaceholder,
			minDate,
			showYearPicker: period === Period.YEAR ? true : false,
			showQuarterYearPicker: period === Period.QUARTERLY ? true : false,
			styles: {
				width: 140,
			},
		},
		{
			name: 'period',
			key: `period-${JSON.stringify(userData)}`,
			options: periodOptions,
			hideDefaultOption: true,
			defaultValue: 'period.month',
			label: 'filter.select.period.label',
			type: 'select',
			placeholder: 'period',
			styles: {
				width: 116,
			},
		},
		businessUnitsList?.length > 1
			? isBusinessUnitsMulti
				? {
						name: 'unitIds',
						type: 'multiselect',
						placeholder: 'businessUnits',
						value: unitIds?.map((unit: any) =>
							businessUnitsList.find(
								(bunit: any) => bunit.id === unit,
							),
						),
						options: businessUnitsList,
						styles: {
							control: {
								width: 200,
								maxWidth: 200,
							},
						},
				  }
				: {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
			: undefined,
	]);
};

export const pageFilterSize = 20;
export const maxNumberOfEmployeesForAccordion = 15;
export const colors = [
	'#57cee5',
	'#fc8099',
	'#6c99b4',
	'#44d7b6',
	'#8e7aad',
	'#4aaeef',
	'#fde85e',
	'#83b252',
	'#eb8b2d',
	'#3b70a4',
];
