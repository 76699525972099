import { i18nService } from '../../../services/i18n.service';

export interface TableHeader {
	Header: string;
	accessor: string;
	Footer?: string;
	CustomCell?: (props: any) => void;
	SubCell?: (props: any) => void;
	isSortable?: boolean;
	id?: string;
	style?: any;
}

export const rolesHeaders: TableHeader[] = [
	{
		accessor: 'name',
		Header: i18nService.translate(
			'usersManagement.rolesAndPermissions.rolesTable.name',
		),
		isSortable: false,
	},
	{
		accessor: 'description',
		Header: i18nService.translate(
			'usersManagement.rolesAndPermissions.rolesTable.description',
		),
		isSortable: false,
	},
];

export const permissionHeaders: TableHeader[] = [
	{
		accessor: 'name',
		Header: i18nService.translate(
			'usersManagement.rolesAndPermissions.permissionsTable.name',
		),
		isSortable: false,
	},
	{
		accessor: 'category_name',
		Header: i18nService.translate(
			'usersManagement.rolesAndPermissions.permissionsTable.category_name',
		),
		isSortable: false,
	},
	{
		accessor: 'description',
		Header: i18nService.translate(
			'usersManagement.rolesAndPermissions.permissionsTable.description',
		),
		isSortable: false,
	},
];
