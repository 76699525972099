import { combineReducers, Reducer } from 'redux';
import * as RI from '../../interfaces/redux.interface';
import loginReducer from './login.reducer';
import dateTimeReducer from './dateTime.reducer';
import configReducer from './config.reducer';
import eventListReducer from './eventList.reducer';
import reportsFiltersReducer from './reportsFilters.reducer';
import reports101FiltersReducer from './report101Filter.reducer';
import ClockReportReducer from './clockReport.reducer';
import repEnterModeReducer from './repEnterMode.reducer';
const appReducer = combineReducers({
	login: loginReducer as Reducer<RI.LoginState, any>,
	eventList: eventListReducer as Reducer<RI.EventListState, any>,
	dateTime: dateTimeReducer as Reducer<RI.CurrentDateTime, any>,
	report101Filters: reports101FiltersReducer as Reducer<any, any>,
	config: configReducer as Reducer<RI.ConfigState, any>,
	reportsFilters: reportsFiltersReducer as Reducer<
		RI.ReportsFiltersReducer,
		any
	>,
	ClockReport: ClockReportReducer as Reducer<RI.WorkLogsState>,
	repEnterMode: repEnterModeReducer as Reducer<RI.RepEnterModeState>,
});

export const rootReducer = (state: any, action: any) => {
	if (action.type === 'LOGOUT') {
		state = undefined;
	}

	return appReducer(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;
