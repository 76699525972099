import React from 'react';
import { Platform } from 'react-native';
import colors from '../../../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { FormControl, MenuItem, Select, makeStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const useStyles = makeStyles((theme: any) => {
	return {
		dropdownRoot: {
			zIndex: '999999999!important'
		},
		root: {
			'& .MuiInputBase-root::before': {
				display: 'none'
			},
			// '& .MuiInputBase-root .MuiSelect-select::focus': {
			// 	backgroundColor: 'transparent'
			// }
		},
		icon: {
			color: colors.primary,
			left: 10,
			right: 'auto'
		},
		listItem: {
			direction: 'rtl'
		}
	}
})

const MuiSelect = ({
	value,
	onChange,
	onBlur,
	error,
	options,
	editable
}: any) => {
	const platform = useDevicePlatform();
	const classes = useStyles();
	const isEditable = editable ?? true;
	const isMobile = platform !== DeviceType.WEB;

	if (isMobile) {
		return null;
	}
	
	const selectOptions = options?.map((el: {id: any, name: string}) => {
		return (
		  <MenuItem key={el.id} value={el.id} classes={{
			  root: classes.listItem
		  }}>
			{el.name}
		  </MenuItem>
		)
	 });

	return (
		<FormControl
			classes={{root: classes.root}}
		>
			<Select
				style={{
					height: 40,
					fontSize: 14,
					backgroundColor: colors[error ? 'brightRed' : 'lightBlue'],
					color: isEditable ? colors.darkGrey : colors.grey,
					borderBottomWidth: isMobile ? 1 : 0,
					borderColor: colors[error ? 'red' : 'primary'],
					borderRadius: 5,
					padding: 10,
					textAlign: 'right',
					...Platform.select({
						web: {
							outlineWidth: 0,
						},
					}),
				}}
				IconComponent={KeyboardArrowDownIcon}
				classes={{
					root: classes.root,
					icon: classes.icon
				}}
				disabled={editable === false ? true : false}
				onChange={onChange}
				MenuProps={{PopoverClasses: { root: classes.dropdownRoot}}}
				onBlur={onBlur}
				value={value}
			>
				{selectOptions}
			</Select>
		</FormControl>

	);
};

export default MuiSelect;
