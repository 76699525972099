import React, { useMemo, useState } from 'react';
import {
	View,
	ScrollView,
	StyleSheet,
	Dimensions,
	useWindowDimensions,
	TouchableHighlight,
	Image,
	TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';
const windowHeight = Dimensions.get('window').height;
const imageEmptyState = require('../../../../assets/images/reportEmptyState/report-empty.png');
import { convertFromUtc, Props } from './Clock.utils';
import { Record } from './Record/Record.component';
import colors from '../../../config/colors';
import I18n from '../i18n/I18n.component';
import SelectFilter from './EventsAndProjectsSelect/EventsAndProjectsSelect.container';
import Timer from '../Timer/Timer.container';
import Typography from '../Typography/Typography.component';
import RecordCard from './Record/Record.component';
import moment from 'moment';
import { getServerDateTime } from '../../../store/selectors/dateTime.selectors';
import { i18nService } from '../../../services/i18n.service';
import { Platform } from '@unimodules/core';
import { toNumber } from 'lodash';

const ClockReportComponent = ({
	initialTime,
	records,
	shiftTime,
	totalReported,
	reachedMax,
	onPressEntry,
	onPressExit,
	projectsOptions,
	eventsOptions,
	onSelectProjectOrEvent,
	selectedItem,
	defaultProjectOption,
	defaultEventOption,
	label,
	canReportFullDay,
	disableButtons,
	standardHours,
	onDelete,
	onEdit,
	disableEntryButton,
	selectedItemType,
	handleOpenOptions,
	eventPlaceholdar,
	currentRunnigWorkLog,
	handleAddNewLog,
}: Props) => {
	const windowWidth = useWindowDimensions().width;
	const windowHeight = useWindowDimensions().height;
	const maxWidth = windowWidth > 1700 ? 612 : windowWidth > 1500 ? 540 : 480;

	const currentServerTime = useSelector(getServerDateTime);

	const contentBlock = {
		marginTop: 28,
		marginBottom: 48,
		height: windowHeight - 167,
		backgroundColor: colors.white,
		width: '100%',
		maxWidth: maxWidth,
		paddingTop: 30,
		paddingLeft: 30,
		paddingBottom: 30,
		paddingRight: 0,
		alignItems: 'center',
		justifyContent: 'space-between',
		zIndex: 9999,
	};

	const convertHoursFormat = (
		minutesValue: any,
		format?: string,
		inminutes?: boolean,
	) => {
		if (minutesValue) {
			const value = inminutes
				? minutesValue
				: Math.floor(minutesValue * 60);
			if (format === 'HH:mm') {
				const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
				const minutes =
					value % 60 !== 0
						? value % 60 < 10
							? `0${value % 60}`
							: `${value % 60}`
						: '00';
				return `${hours}:${minutes}`;
			}
			return `${(value / 60).toFixed(2)}`;
		}
		return '00:00';
	};

	const convertToHoursFormat = time => {
		if (!time) return '00:00';
		const [hours, minutes, seconds] = time.split(':');
		return `${toNumber(hours).toLocaleString()}:${minutes}`;
	};

	const isTotalReportedSmallerThanStandard = useMemo(() => {
		let standard = 0;
		if (standardHours?.fixHours) {
			standard =
				moment(standardHours.fixHours, 'HH:mm:ss').diff(
					moment('00:00', 'HH:mm'),
					'minutes',
				) / 60;
		}

		return totalReported < standard;
	}, [totalReported, standardHours]);
	const standard = useMemo(() => {
		return convertToHoursFormat(standardHours?.fixHours);
	}, [standardHours]);

	return (
		<View style={contentBlock} nativeID='clockReport'>
			<View nativeID='detailsHeader' style={styles.detailsHeader}>
				<View style={{ flexDirection: 'row' }}>
					<Typography size={18} color={'darkGrey'} weight='400'>
						{`${convertFromUtc(
							currentServerTime,
							false,
							true,
							'YYYY-MM-DDTHH:mm',
							'DD/MM/YY',
						)}  | `}
					</Typography>
					<Typography
						size={18}
						color={'darkGrey'}
						weight='bold'
						style={{ marginHorizontal: 8 }}
					>
						{convertFromUtc(
							currentServerTime,
							true,
							false,
							'YYYY-MM-DDTHH:mm',
						)}
					</Typography>
				</View>
				<View style={{ flexDirection: 'row' }}>
					<Typography
						size={18}
						color={
							standard === '0:00'
								? 'darkGrey'
								: isTotalReportedSmallerThanStandard
								? 'red'
								: 'green'
						}
						weight='400'
					>
						{convertHoursFormat(totalReported, 'HH:mm', false)}
					</Typography>
					<Typography size={18} color={'darkGrey'} weight='bold'>
						{`/${standard}`}
					</Typography>
				</View>
			</View>
			{!disableButtons.disable ? (
				<View nativeID='FiltersHolder' style={styles.FiltersHolder}>
					{eventsOptions.length ? (
						<SelectFilter //events select
							canReportFullDay={canReportFullDay}
							options={eventsOptions}
							isProject={false}
							placeHolder={eventPlaceholdar}
							onSubmit={onSelectProjectOrEvent}
							disabled={selectedItemType === 'projects'}
							onOpen={isOpen =>
								handleOpenOptions(isOpen, 'events')
							}
							defaultOption={defaultEventOption}
							currentRunnigWorkLog={currentRunnigWorkLog}
							handleAddNewLog={handleAddNewLog}
						/>
					) : null}
					{projectsOptions.length ? (
						<SelectFilter //projects select
							canReportFullDay={canReportFullDay}
							options={projectsOptions}
							isProject={true}
							placeHolder={
								defaultProjectOption.name
									? defaultProjectOption.name
									: 'menu.projects'
							}
							onSubmit={onSelectProjectOrEvent}
							disabled={selectedItemType === 'events'}
							onOpen={isOpen =>
								handleOpenOptions(isOpen, 'projects')
							}
							defaultOption={defaultProjectOption}
							currentRunnigWorkLog={currentRunnigWorkLog}
						/>
					) : null}
				</View>
			) : null}
			{label ? (
				<Typography
					size={22}
					color={'darkGrey'}
					weight='bold'
					style={{ marginVertical: 10, textAlign: 'center' }}
				>
					{label}
				</Typography>
			) : null}
			{!disableButtons.disable ? (
				<View nativeID='ButtonsHolder' style={styles.ButtonsHolder}>
					{initialTime ? (
						<Timer
							max={shiftTime - totalReported}
							maxAlert={reachedMax}
							initialTime={initialTime}
							textColor='primary'
							fontSize={36}
						/>
					) : (
						<TouchableHighlight
							underlayColor='transparent'
							onPress={onPressEntry}
							style={{
								alignItems: 'center',
								opacity: disableEntryButton ? 0.3 : 1,
							}}
							disabled={disableEntryButton}
						>
							<View style={styles.EntryButton}>
								<I18n size={22} weight={'normal'} color='white'>
									general.enterence
								</I18n>
							</View>
						</TouchableHighlight>
					)}
					<TouchableHighlight
						underlayColor='transparent'
						onPress={onPressExit}
						style={{ alignItems: 'center' }}
					>
						<View style={styles.ExitButton}>
							<I18n size={22} weight={'normal'} color='white'>
								general.exit
							</I18n>
						</View>
					</TouchableHighlight>
				</View>
			) : (
				<View
					style={{
						flexDirection: 'row-reverse',
						justifyContent: 'center',
						alignItems: 'center',
						height: 100,
						paddingRight: 30,
						marginTop: 10,
					}}
				>
					<I18n weight='bold'>{`${i18nService.translate(
						'general.youAreNowReportingEvent',
					)} `}</I18n>
					<I18n weight='bold' color='warning'>
						{i18nService.translate(disableButtons.eventName)}
					</I18n>
				</View>
			)}

			{records.length ? (
				<View
					style={{ width: '100%', paddingTop: 30, paddingRight: 15 }}
				>
					<I18n
						size={18}
						style={{
							AlignSelf: 'flex-end',
							marginBottom: 10,
							paddingRight: 15,
						}}
						color={'darkGrey'}
						weight='bold'
					>
						general.startDay
					</I18n>
					<ScrollView
						nativeID='tableHolder'
						style={styles.tableHolder}
					>
						{records.map((record: Record) => (
							<RecordCard
								onDelete={onDelete}
								onEdit={onEdit}
								key={record.workLogId}
								record={record}
							/>
						))}
						<View style={styles.recordLine} />
					</ScrollView>
				</View>
			) : (
				<View
					style={{ justifyContent: 'center', alignItems: 'center' }}
				>
					<Image
						source={imageEmptyState}
						fadeDuration={0}
						style={{
							height: 86,
							width: 178,
							marginTop: 50,
						}}
					/>
					<I18n size={18} color={'darkGrey'} weight='400'>
						reportView.noWorklogsToday
					</I18n>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	recordLine: {
		opacity: 0.2,
		width: '100%',
		height: 0,
		borderTopColor: '#525962',
		borderTopWidth: 2,
		marginVertical: 5,
	},

	detailsHeader: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 20,
		paddingRight: 30,
	},
	FiltersHolder: {
		justifyContent: 'center',
		alignItems: 'center',
		height: 42,
		marginBottom: 10,
		paddingRight: 30,
		flexDirection: 'row',
		width: '100%',
		zIndex: 11111,
	},
	ButtonsHolder: {
		justifyContent: 'center',
		alignItems: 'center',
		height: 100,
		paddingRight: 30,
		marginTop: 10,
	},
	EntryButton: {
		width: 200,
		height: 60,
		marginVertical: 10,
		borderRadius: 40,
		...Platform.select({
			web: { boxShadow: '0 10px 30px 0 rgba(59, 112, 164, 0.5)' },
		}),
		backgroundColor: '#4aaeef',
		justifyContent: 'center',
		alignItems: 'center',
	},
	ExitButton: {
		width: 200,
		height: 60,
		borderRadius: 40,
		marginVertical: 10,
		...Platform.select({
			web: { boxShadow: '0 10px 30px 0 rgba(82, 89, 98, 0.4)' },
		}),
		backgroundColor: '#eb8b2d',
		justifyContent: 'center',
		alignItems: 'center',
	},
	tableHolder: {
		width: '100%',
		minHeight: 140,
		maxHeight: 190,
		direction: 'ltr',
		paddingRight: 15,
	},
});
export default ClockReportComponent;
