import { RootState } from '../reducers/root.reducer';

export const getChosenDate = (state: RootState) =>
	state.ClockReport.chosenDate

export const getTodayWorkLogs = (state: RootState) =>
	state.ClockReport.todayWorkLogs;

export const isOpenChosenDateModal = (state: RootState) =>
	state.ClockReport.openChosenDateModal

export const getReFetchedFlag = (state:RootState) =>
	state.ClockReport.reFetchedFlag