import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Platform } from 'react-native';
import { compact, debounce } from 'lodash';
import moment from 'moment';

import {
	institutionTypeList,
	reportTypeList,
	statusOptions100,
	statusOptions102,
	defaultFilters,
	handleFilter,
} from './RepOrgReports.utils';
import {
	getBussinesUnitsModules,
	getUserData,
} from '../../../../store/selectors/login.selectors';
import httpService from '../../../../services/http.service';
import { Privileges } from '../../../../constants/roleTypes.constant';
import useEffectNotInitial from '../../../../hooks/useEffectNotInitial';
import RepOrgReports from './RepOrgReports.component';
import { FilterData } from '../../../../components/web/Filters/Filters.container';
import { i18nService } from '../../../../services/i18n.service';
import { getSelectedUnits } from '../../rep.utils';
import { useRoute } from '@react-navigation/native';

const RepOrgReportsContainer = ({ navigation }: { navigation: any }) => {
	const newDate = new Date();
	const [requestsData, setRequestsData] = useState([]);
	const [noOperators, setNoOperators] = useState<boolean>(false);
	const [operatorsList, setOperatorsList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [businessUnitsList, setBusinessUnitsList] = useState([]);
	const [prevInstitutionType, setPrevInstitutionType] = useState(
		defaultFilters.type,
	);
	const [reportType, setReportType] = useState<number>(102);
	const [disabledLoad, setDisableLoad] = useState(false);
	const [nextPage, setNextPage] = useState(0);
	const [order, setOrder] = useState('ASC');
	const [sortBy, setSortBy] = useState('repCustomer');
	const defaultSort = 'repCustomer';
	const defaultOrder = 'ASC';
	const [filters, setFilters] = useState(defaultFilters);
	const [repCustData, setRepCustData] = useState(false);

	const userData = useSelector(getUserData);
	const modules = useSelector(getBussinesUnitsModules);
	const route = useRoute();

	const canEnterCustomer = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ACCESS_CUSTOMER_WRITE,
			),
		[userData?.repRolePrivileges],
	);

	useEffect(() => {
		if (route.params?.filtersData) {
			const f = JSON.parse(route.params?.filtersData);
			if (f.reportType === 100) {
				f.type = ['SOCIAL_SECURITY'];
			}
			setReportType(f.reportType);
			delete f.reportType;
			setFilters(state => ({
				...state,
				...f,
			}));
			navigation.setParams({ filtersData: undefined });
		}
		if (route.params?.customersFullData) {
			const repCustData = JSON.parse(route.params?.customersFullData);
			setRepCustData(
				repCustData.map((d: any) => ({ label: d.name, value: d.id })),
			);
			navigation.setParams({ customersFullData: undefined });
		}
	}, [route.params]);

	const getBusinessUnitsList = async () => {
		const res: any = await httpService.api<string[]>({
			type: 'getRepresntUnits',
			query: { pageName: 'repOrgReport' },
			disableBI: true,
		});
		setBusinessUnitsList(
			res.map((item: any) => ({
				...item,
				id: item.numericId,
				stringId: item.id,
			})),
		);
		setFilters(state => ({ ...state, unitIds: [res[0]?.numericId] }));
	};

	const getRepCustomerUnitsList = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res: any = await httpService.api<string[]>({
			type: 'getRepCustomerUnitsList',
			data: {
				p: page,
				ps: 15,
				search,
				f: {
					unitIds: getSelectedUnits(
						filters.unitIds,
						businessUnitsList,
					),
					repCustomerIds: filters.repCustomerIds,
				},
			},
			query: { pageName: 'repOrgReport' },
			disableBI: true,
		});
		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.id,
					label: el.name,
				};
			}),
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	const getOperatorList = async () => {
		const res: any = await httpService.api({
			type: 'getCustomerOperatorsList',
			query: {
				repCustomerIds: filters.repCustomerIds.join(','),
				unitIds: getSelectedUnits(
					filters.unitIds,
					businessUnitsList,
				).join(','),
			},
			disableBI: true,
		});
		if (res?.length) {
			setNoOperators(false);
			setOperatorsList([
				{
					id: -1,
					name: i18nService.translate(
						'repCustomersPage.operator.noManager',
					),
				},
				...res,
			]);
		} else {
			setOperatorsList([
				{
					id: -1,
					name: i18nService.translate(
						'repCustomersPage.operator.noManager',
					),
				},
			]);
			setNoOperators(true);
		}
	};

	const onFilter = (data: FilterData) => {
		handleFilter(
			data,
			setFilters,
			filters,
			prevInstitutionType,
			setPrevInstitutionType,
			reportType,
			setReportType,
			setRepCustData,
		);
	};

	const has115Module = useMemo(() => {
		if (modules) {
			const module115 = filters?.unitIds.find((unit: any) =>
				modules[unit]?.find(
					(module: any) =>
						module.id === 115 && module?.status === 'ACTIVE',
				),
			);

			if (module115) {
				return true;
			}
			return false;
		}

		return true;
	}, [modules, filters?.unitIds]);

	const repOrgPermission = useMemo(
		() =>
			userData?.repRolePrivileges?.includes(
				Privileges.ROLE_REP_ORG_REPORT_WRITE,
			),
		[userData?.repRolePrivileges],
	);
	const disabled = useMemo(
		() => (repOrgPermission && has115Module ? false : true),
		[repOrgPermission, has115Module],
	);

	useEffect(() => {
		setIsLoading(true);
		getBusinessUnitsList();
	}, [userData]);

	useEffectNotInitial(() => {
		if (businessUnitsList.length && filters.unitIds.length) {
			getOperatorList();
			onMount();
		}
	}, [filters.unitIds, filters.repCustomerIds, businessUnitsList]);

	useEffectNotInitial(() => {
		debouncedGetMoreData(true, defaultSort, defaultOrder, filters);
	}, [reportType, filters]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('EmplooyeeRequests');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};
	const debouncedGetMoreData = debounce((...args) => {
		getRequestsData(...args);
	}, 300);

	const getRepCustomerIdsData = async (
		search: string,
		loadedOptions: null,
		{ page }: { page: number },
	) => {
		const res: any = await httpService.api({
			type: 'getRepCustomers',
			data: {
				p: page,
				ps: 15,
				search,
				f: {
					unitIds: getSelectedUnits(
						filters?.unitIds,
						businessUnitsList,
					),
				},
			},
			disableBI: true,
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.numericId,
					label: el.name + ` (${el.id})`,
				};
			}),
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	const filtersConfig = useMemo(() => {
		const statusOptions =
			reportType === 100
				? statusOptions100(true)
				: statusOptions102(
						filters.type.includes('IRS')
							? 'IRS'
							: 'SOCIAL_SECURITY',
						true,
				  );
		const dateVal = filters.year.length
			? moment()
					.set('year', filters.year[0])
					.set('month', filters.month[0] - 1)
					.toDate()
			: moment(newDate).subtract(1, 'months').toDate();

		return compact([
			{
				key: `${reportType}${filters.type}-status`,
				name: 'status',
				options: statusOptions,
				value: filters.status.map(id =>
					statusOptions.find(item => item.id === id),
				),
				noSpecialAll: true,
				placeholder: 'status',
				alwaysRerender: true,
				styles: { width: 180, control: { maxWidth: 180 } },
				type: 'multiselect',
			},
			{
				key: `${JSON.stringify(reportType)}-institutionType`,
				name: 'type',
				type: 'multiselect',
				alwaysRerender: reportType === 100 ? true : false,
				options: institutionTypeList,
				disabled: reportType === 100,
				forceNoAll: true,
				value: institutionTypeList.filter((item: any) =>
					filters.type.includes(item.id),
				),
				styles: {
					width: 160,
					container: { opacity: reportType === 100 ? 0.8 : 1 },
				},
			},
			{
				name: 'reportType',
				type: 'select',
				defaultValue: reportType,
				options: reportTypeList,
				hideDefaultOption: true,
				styles: {
					minWidth: 75,
				},
			},
			{
				key: `${JSON.stringify(filters.unitIds)}-${JSON.stringify(
					filters.repCustomerIds,
				)}-${JSON.stringify(noOperators)}-operatorIds`,
				name: 'operatorIds',
				type: 'multiselect',
				options: operatorsList,
				forceNoAll: noOperators ? true : false,
				placeholder: 'operators',
				value:
					operatorsList?.length === 1
						? operatorsList
						: filters.operatorIds.map(id =>
								operatorsList.find(item => item.id === id),
						  ),
				styles: {
					width: 200,
				},
			},
			{
				key: `${JSON.stringify(filters.unitIds)}-${JSON.stringify(
					filters.repCustomerIds,
				)}-repCustomerUnitIds`,
				name: 'repCustomerUnitIds',
				type: 'asyncmultiselect',
				placeholder: 'businessUnits.placeholder',
				loadOptions: getRepCustomerUnitsList,
				styles: { width: 220 },
			},
			{
				key: `${JSON.stringify(filters.unitIds)}-repCustomerIds`,
				name: 'repCustomerIds',
				loadOptions: getRepCustomerIdsData,
				value: repCustData,
				type: 'asyncmultiselect',
				placeholder: 'customers',
				styles: { width: 250 },
			},
			{
				key: `monthYear`,
				name: 'monthYear',
				type: 'datepicker',
				date: dateVal,
				placeholder:
					filters.year.length &&
					i18nService.translate(
						`months.${moment(dateVal).format('MMMM')}`,
					) + ` ${dateVal.getFullYear()}`,
				onlyMonthYear: true,
				showYearPicker: false,
				showQuarterYearPicker: false,
				styles: {
					width: 140,
				},
			},
			businessUnitsList?.length > 1
				? {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: filters.unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
				: undefined,
		]);
	}, [
		userData,
		businessUnitsList,
		operatorsList,
		filters.unitIds,
		filters.repCustomerIds,
		filters.type,
		filters.operatorIds,
		filters.year,
		repCustData,
		noOperators,
		reportType,
	]);

	const getRequestsData = async (
		first: boolean,
		newSortBy?: string,
		neworder?: string,
		filterData?: any,
		search?: string,
	) => {
		if (!filters?.unitIds.length) {
			return;
		}
		try {
			const tempFilter = filterData || filters;
			const ps = 40;
			const res = await httpService.api<any>({
				type: 'getRepresentOrgReport',
				params: { type: reportType },
				data: {
					p: first ? 0 : nextPage,
					ps: ps,
					s: newSortBy ? newSortBy : sortBy,
					sd: neworder ? neworder : order,
					f: {
						...tempFilter,
						unitIds: getSelectedUnits(
							tempFilter?.unitIds,
							businessUnitsList,
						),
						status: tempFilter?.status?.filter(
							(stat: any) => stat !== 'all',
						),
					},
					search: search,
				},
			});

			const data = res.data;

			if (res) {
				setDisableLoad(data?.length < ps);
				if (data.length !== 0) {
					if (first) {
						setRequestsData(data);
						setNextPage(1);
					} else {
						setRequestsData([...requestsData, ...data]);
						setNextPage(nextPage + 1);
					}
				} else {
					setRequestsData([]);
				}
				setIsLoading(false);
			}
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	};

	const onSort = (newSort: string, order: string) => {
		setOrder(order);
		setSortBy(newSort);
		getRequestsData(true, newSort, order, filters);
	};

	return (
		<RepOrgReports
			navigation={navigation}
			route={route}
			data={requestsData}
			onSort={onSort}
			onLoadMore={() =>
				disabledLoad
					? null
					: debouncedGetMoreData(false, sortBy, order, filters)
			}
			refresh={() => debouncedGetMoreData(true, sortBy, order, filters)}
			onFilter={onFilter}
			filtersConfig={filtersConfig}
			disabled={disabled}
			sortBy={sortBy}
			order={order}
			filters={filters}
			reportType={reportType}
			isLoading={isLoading}
			canEnterCustomer={canEnterCustomer}
		/>
	);
};

export default React.memo(RepOrgReportsContainer);
