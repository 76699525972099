import React from 'react';
import { StyleSheet, View } from 'react-native';
import colors from '../../../config/colors';
import Typography from '../Typography/Typography.component';

interface ReportsTimeSumProps {
	reportedTime: string;
	neededTime: string;
	error: boolean;
	isMobile: boolean;
}

const ReportsTimeSum = ({
	reportedTime,
	neededTime,
	error,
	isMobile,
}: ReportsTimeSumProps) => {
	return (
		<View
			style={{
				flexDirection: 'row-reverse',
				justifyContent: isMobile ? 'center' : 'flex-start',
				paddingBottom: 20,
				backgroundColor: colors.white,
				width: '100%',
			}}
		>
			<Typography weight='400' size={18}>
				{' '}
				/ {neededTime}
			</Typography>
			<Typography
				color={error ? 'darkGrey' : 'success'}
				weight='normal'
				size={18}
			>
				{reportedTime}
			</Typography>
		</View>
	);
};

export default ReportsTimeSum;
