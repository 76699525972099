import React from 'react';
import { StyleSheet, View, TouchableHighlight } from 'react-native';
import { useSelector } from 'react-redux';
import colors from '../../../config/colors';
import { Privileges } from '../../../constants/roleTypes.constant';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import {
	getEmployeeRolesPrivileges,
	getUserData,
} from '../../../store/selectors/login.selectors';
import Typography from '../Typography/Typography.component';

interface DrawerItemProps {
	routeName: string;
	route: string;
	isActive: boolean;
	isParentActive: boolean;
	index: number;
	onPress: (routeName: string) => void;
}

const SubDrawerItem = ({
	routeName,
	route,
	isActive,
	isParentActive,
	index,
	onPress,
}: DrawerItemProps) => {
	const isInMobileView = useDevicePlatform() !== DeviceType.WEB;
	const userData = useSelector(getUserData);
	const docPermission = useSelector(getEmployeeRolesPrivileges)?.some(
		(per: any) =>
			per === Privileges.ROLE_MY_DOCS_READ ||
			per === Privileges.ROLE_MY_DOCS_WRITE,
	);

	const paycheckDrillMenuStyle = StyleSheet.flatten([
		styles.paycheckDrillMenu,
		isInMobileView ? styles.paycheckMenuMobile : styles.paycheckMenuWeb,
		isActive
			? styles.paycheckMenuSelected
			: isParentActive
			? styles.paycheckMenuSelected
			: { backgroundColor: 'white' },
	]);

	return (
		<TouchableHighlight
			underlayColor='transparent'
			onPress={() => onPress(routeName)}
		>
			<View style={styles.paycheckMenuWrapper}>
				<View style={paycheckDrillMenuStyle}>
					<Typography
						style={{
							textAlign: 'right',
							paddingRight: isInMobileView ? 40 : 0,
						}}
						color={isActive ? 'primary' : 'darkGrey'}
						weight={isActive ? 'bold' : '400'}
						size={14}
					>
						{routeName}
					</Typography>
					{userData?.pending_docs &&
						docPermission &&
						route === 'documents' && (
							<View style={styles.pendingDocs}></View>
						)}
				</View>
				{isActive && <View style={styles.verticalLine} />}
			</View>
		</TouchableHighlight>
	);
};

const styles = StyleSheet.create({
	paycheckDrillMenu: {
		width: '100%',
		paddingRight: 50,
		position: 'relative',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row-reverse',
	},
	paycheckMenuWeb: {
		paddingRight: 53,
		paddingTop: 14,
		paddingBottom: 14,
	},
	paycheckMenuMobile: {
		paddingRight: 53,
		paddingTop: 14,
		paddingBottom: 14,
	},
	paycheckMenuSelected: {
		backgroundColor: 'rgba(59, 112, 164, 0.1)',
	},
	verticalLine: {
		width: 3,
		height: 35,
		borderRadius: 1.5,
		backgroundColor: colors.primary,
		position: 'absolute',
		right: 1,
		top: 5,
	},
	paycheckMenuWrapper: {
		display: 'flex',
		flexDirection: 'row',
	},
	pendingDocs: {
		width: 12,
		height: 12,
		borderRadius: 6,
		backgroundColor: colors.red,
		marginHorizontal: 6,
	},
});

export default SubDrawerItem;
