import React, { useCallback, useEffect, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	FlatList,
	TextInput,
	Platform,
} from 'react-native';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { makeStyles } from '@material-ui/core';
import WebCheckBox from 'expo-checkbox';
import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import httpService from '../../../../services/http.service';
import { SelectedUserItem, TreeDropDown } from './TreeSelect.utils';
import { debounce } from 'lodash';

interface UserData {
	projects?: string[];
	users: Users[];
}

interface Users {
	id?: number;
	departmentAid: string;
	selected: boolean;
	users: number[];
}

interface DepFilterData {
	id: string;
	name: string;
	users: UsersFilter[];
	isChecked?: boolean;
}

interface UsersFilter {
	id: string;
	name: string;
	teudatZeut: string;
	employeeId: number;
	isChecked?: boolean;
}
const useStyles = makeStyles(() => ({
	chip: {
		borderRadius: 2,
		color: 'hsl(0, 0%, 20%)',
		fontSize: '85%',
		padding: 3,
		paddingLeft: 6,
		margin: 2,
		backgroundColor: 'hsl(0, 0%, 90%)',
	},
	checkbox: {
		color: '#6d7278',
		'&$checked': {
			color: colors.lightPrimary,
		},
		'&.Mui-disabled': {
			pointerEvents: 'auto',
			color: colors.grey,
		},
	},
	checked: {},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: ({ isSelected }: any) =>
			isSelected ? colors.lightBlue : 'transparent',

		'&:hover': {
			cursor: 'pointer',
			backgroundColor: colors.lightBlue,
		},
	},
	box: {
		display: 'flex',
		alignItems: 'center',
	},
	statusWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	arrow: {
		color: colors.primary,
		marginRight: 5,
	},
}));

interface Props {
	userList: DepFilterData[];
	userData: UserData;
	setUserData(data: any): void;
	disabled?: boolean;
}

const UserTreeSelect = ({ onChange, disabled }: Props) => {
	const [isAllSelected, setIsAllSelected] = useState(false);
	const [listState, setListState] = useState([]);
	const [openDepartment, setOpenDepartment] = useState('');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [userList, setUserList] = useState<
		{ name: string; id: string; isDepartment: boolean }[]
	>([]);

	useEffect(() => {
		getUsers('', true);
	}, []);

	const getUsers = async (searchText: string, isFirstTime?: boolean) => {
		try {
			const res: DepFilterData[] = await httpService.api<any>({
				type: 'getUsersByDepartments',
				query: { pageName: 'locationSettings' },
				data: {
					search: searchText,
					f: { dep: [], internal: ['true'] },
				},
			});
			if (res) {
				arrangeDepartmentUserList(res, isFirstTime);
			}
		} catch (e) {}
	};

	const onSearch = async (text: string) => {
		setSearchText(text);
		search(text);
		setIsDropdownOpen(true);
	};

	const search = useCallback(
		debounce((text: string) => getUsers(text), 600),
		[],
	);

	const arrangeDepartmentUserList = (
		list: DepFilterData[],
		isFirstTime?: boolean,
	) => {
		const newList = list.map(item => {
			let newUsers = [];
			const selected = userList.map(us => us.id);
			newUsers =
				item?.users?.map(user => ({
					...user,
					isChecked: selected.includes(user.id),
				})) || [];
			const isCheckDep =
				newUsers.filter(us => us.isChecked).length === newUsers.length;

			return { ...item, isChecked: isCheckDep, users: newUsers };
		});

		setListState(newList);
		isFirstTime && listToShow(newList);
	};

	const listToShow = list => {
		const temp = [];
		list.forEach(item => {
			if (item.isChecked) {
				return temp.push({
					id: item.id,
					name: item.name,
					isDepartment: true,
				});
			}
			item?.users?.forEach(user => {
				if (user.isChecked) {
					return temp.push({
						id: user.id,
						name: user.name,
						isDepartment: false,
					});
				}
			});
		});
		setUserList(temp);
	};

	const onDepartmentRowPress = (
		departmentData: DepFilterData,
		departmentIndex: number,
		isChecked: boolean,
	) => {
		const temp = [...listState];
		temp[departmentIndex] = {
			...departmentData,
			isChecked,
			users: departmentData.users.map(user => ({
				...user,
				isChecked: isChecked,
			})),
		};
		setListState(temp);

		let tempUserList = [...userList];
		const depUsers = departmentData.users.map(user => user.id);
		if (isChecked) {
			tempUserList = [
				...tempUserList,
				...departmentData.users.map(user => ({
					id: user.id,
					name: user.name,
				})),
			];
		} else {
			tempUserList = tempUserList.filter(us => !depUsers.includes(us.id));
		}
		listToShow(temp);
		onChange(tempUserList);

		if (!isChecked && isAllSelected) {
			setIsAllSelected(false);
		}
	};

	const onUpdateUser = (
		val: boolean,
		user: UsersFilter,
		userIndex: number,
		departmentData: DepFilterData,
		departmentIndex: number,
	) => {
		const temp = [...listState];
		let newUsers = [...departmentData?.users] || [];
		newUsers[userIndex] = { ...user, isChecked: val };
		const isCheckDep = val
			? !searchText &&
			  newUsers.filter(us => us.isChecked).length === newUsers.length
				? true
				: departmentData.isChecked
			: false;
		temp[departmentIndex] = {
			...departmentData,
			isChecked: isCheckDep,
			users: newUsers,
		};
		setListState(temp);

		let tempUserList = [...userList];
		if (val) {
			tempUserList = [...tempUserList, { id: user.id, name: user.name }];
		} else {
			tempUserList = tempUserList.filter(us => us.id != user.id);
		}
		listToShow(temp);
		onChange(tempUserList);
	};

	const onAllSelect = (isChecked: boolean) => {
		let tempUserList = [];
		setIsAllSelected(isChecked);
		const newList = listState.map(item => {
			const temp = item.users.map(user => {
				if (isChecked) {
					tempUserList = [
						...tempUserList,
						{ id: user.id, name: user.name },
					];
				}
				return { ...user, isChecked: isChecked };
			});
			return { ...item, isChecked: isChecked, users: temp };
		});
		setListState(newList);
		listToShow(newList);
		onChange(tempUserList);
	};

	const onDeleteItem = (id, isDepartment) => {
		const tempUserList = userList.filter(us => us.id != id);

		onChange(tempUserList);

		const newList = listState.map(item => {
			let isChecked = item.isChecked;
			const temp = item.users.map(user => {
				if (user.id === id || id === item.id) {
					isChecked = false;
					return { ...user, isChecked: false };
				} else {
					return { ...user };
				}
			});

			return { ...item, isChecked: isChecked, users: temp };
		});
		setListState(newList);
		listToShow(newList);
	};

	const onClose = () => {
		setIsDropdownOpen(!isDropdownOpen);
		if (!!searchText) {
			setSearchText('');
			getUsers('');
		}
	};

	const classes = useStyles({ isSelected: isDropdownOpen });

	return (
		<View style={[styles.dataContainer]}>
			<TouchableOpacity onPress={onClose} style={styles.mainSelectButton}>
				<View
					style={{
						width: 280,
						flexDirection: 'row-reverse',
						alignItems: 'center',
					}}
				>
					{userList.length > 2 ? (
						<View style={styles.selectedItems}>
							{userList.slice(0, 2).map(item => (
								<SelectedUserItem
									key={item.id}
									title={item.name}
									onPress={() =>
										onDeleteItem(item.id, item.isDepartment)
									}
								/>
							))}
							<SelectedUserItem
								key={`+${userList.length - 2}`}
								number={`+${userList.length - 2}`}
							/>
						</View>
					) : (
						<View style={styles.selectedItems}>
							{userList.map(item => (
								<SelectedUserItem
									key={item.id}
									title={item.name}
									onPress={() =>
										onDeleteItem(item.id, item.isDepartment)
									}
								/>
							))}
						</View>
					)}
					<TextInput
						style={[styles.inputStyle, { width: '100%' }]}
						onChangeText={onSearch}
						selectTextOnFocus
						pointerEvents='auto'
						value={searchText}
						editable={!disabled}
						placeholder={''}
					></TextInput>
				</View>
				<View
					style={{
						height: 36,
						width: 36,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<KeyboardArrowDownRoundedIcon
						classes={{ root: classes.arrow }}
						fontSize='medium'
					/>
				</View>
			</TouchableOpacity>
			<FlatList
				data={listState}
				ListHeaderComponent={() => (
					<View style={styles.allRow}>
						<WebCheckBox
							value={isAllSelected}
							onValueChange={val => onAllSelect(val)}
							color={colors.lightPrimary}
							style={styles.checkBox}
							disabled={disabled}
						/>
						<I18n size={16} style={{ zIndex: 'unset' }}>
							general.all
						</I18n>
					</View>
				)}
				renderItem={({ item, index }) => (
					<View key={item.id}>
						<TreeDropDown
							departmentData={item}
							departmentIndex={index}
							onUpdateDepartment={onDepartmentRowPress}
							disabled={disabled}
							onUpdateUser={onUpdateUser}
							openDropDown={openDepartment}
							setOpenDropDown={setOpenDepartment}
							openOnLoad={listState.length === 1}
						/>
					</View>
				)}
				style={[
					styles.dropDown,
					{
						height: isDropdownOpen ? 250 : 0,
						borderColor: isDropdownOpen ? '#e7e7e8' : colors.white,
					},
				]}
				contentContainerStyle={{ paddingBottom: 15 }}
				keyExtractor={item => `${item.id}`}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	dataContainer: {
		position: 'relative',
	},
	mainSelectButton: {
		backgroundColor: colors.lightBlue,
		borderRadius: 5,
		paddingHorizontal: 5,
		height: 40,
		flexDirection: 'row-reverse',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	inputStyle: {
		borderColor: colors.lightBlue,
		borderWidth: 0,
		paddingHorizontal: 7,
		height: 36,
		textAlign: 'right',
		color: colors.darkGrey,
		fontSize: 14,
		fontFamily: 'RubikRegular',
		...Platform.select({
			web: {
				outlineWidth: 0,
				outlineColor: colors.lightBlue,
			},
		}),
	},
	dropDown: {
		position: 'absolute',
		overflow: 'hidden',
		borderWidth: 1,
		backgroundColor: colors.white,
		top: 40,
		width: 340,
	},
	allRow: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		marginBottom: 5,
	},
	checkBox: {
		width: 15,
		height: 15,
		borderRadius: 4,
		margin: 12,
	},
	text: {
		paddingRight: 8,
	},
	dataRowContainer: {
		marginVertical: 3,
		overflow: 'hidden',
	},
	dataRowHeader: {
		flexDirection: 'row-reverse',
		alignItems: 'center',
		height: 40,
	},
	selectedItems: {
		flexDirection: 'row-reverse',
	},
});

export default UserTreeSelect;
