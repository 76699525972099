import React, { useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployeesFilterList } from '../../../store/actions/reportsFilters.actions';
import { getUserData } from '../../../store/selectors/login.selectors';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';
import SendingDocument from './SendingDocument.component';
import { getSelectedUnits } from '../../representative/rep.utils';
import httpService from '../../../services/http.service';
import { modalService } from '../../../services/modal.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import { openNewWindow } from '../Documents.utils';
import colors from '../../../config/colors';

export const selectStyle = {
	control: {
		minHeight: 32,
		height: 32,
		backgroundColor: '#eff5f8',
		color: colors.darkGrey,
		borderColor: colors.filterBorder,
		borderWidth: 1,
		boxShadow: 'none',
		width: 340,
	},
};

const SendingDocumentContainer = ({
	navigation,
	onNext,
	onCancel,
	id,
	name,
	unitId,
	...rest
}: any) => {
	const pageFilterSize = 20;
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [chosenEmp, setChosenEmp] = useState(
		id ? { value: id, label: name } : undefined,
	);
	const [chosenUnit, setChosenUnit] = useState();
	const [chosenRecipienats, setChosenRecipienats] = useState([]);
	const [recipienatsKey, setRecipienatsKey] = useState(false);

	const { businessUnitsList } = useBusinessUnitsState({
		privileges: routes.documentsManagment.privileges,
		noRoute: true,
	});

	useEffect(() => {
		onMount();
	}, [userData]);

	useEffect(() => {
		if (businessUnitsList.length) {
			!chosenUnit && setChosenUnit(unitId || businessUnitsList[0]?.id);
		}
	}, [businessUnitsList]);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('UsersTableContainer');
			await crashlytics().setAttributes({
				userId: userData?.id || '',
				userName:
					`${userData?.first_name} ${userData?.last_name}` || '',
			});
		}
	};

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
		isEmp: boolean,
	) => {
		const res: any = await fetchEmployeesFilterList('docRequest', {
			page,
			pageSize: pageFilterSize,
			search,
			unitIds: isEmp
				? [chosenUnit]
				: getSelectedUnits([], businessUnitsList),
			excludePassport: true,
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: pageFilterSize === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const onChooseUnit = (newVal: any) => {
		setChosenUnit(newVal);
		setChosenEmp(undefined);
	};

	const onChooseEmployee = (newVal: any) => {
		setChosenEmp(newVal);
	};

	const onChooseRecipienats = (newVal: any) => {
		setChosenRecipienats(newVal.value);
	};

	const onRemoveRecipienats = (id: number) => {
		setChosenRecipienats(
			chosenRecipienats.filter(recip => recip.id !== id),
		);
		setRecipienatsKey(!recipienatsKey);
	};

	const onSubmit = async () => {
		try {
			const res: any = await httpService.api({
				type: 'sendDocumentsToEmployee',
				returnAllRes: true,
				data: {
					userIds: [
						chosenEmp?.value,
						...chosenRecipienats.map((recip: any) => recip?.id),
					],
					unitIds: `${businessUnitsList.map((unit: any) => unit.id)}`,
				},
				query: { unitId: chosenUnit },
			});
			if (res.status === 200) {
				onCancel ? onCancel(onNext) : onNext();
				Platform.OS !== 'web'
					? Linking.openURL(res.data)
					: openNewWindow(res.data);
			}
		} catch (err: any) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
					separator: true,
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n
							size={20}
							weight='bold'
							style={{
								textAlign: 'center',
								marginBottom: 15,
							}}
						>
							usersManagement.modal.documents.integration.error.title
						</I18n>
						<I18n style={{ textAlign: 'center' }}>
							{err.data === 'GENERAL_ERROR' ||
							typeof err.data !== 'string'
								? 'usersManagement.modal.documents.integration.error.msg'
								: err.data}
						</I18n>
					</AlertModal>
				),
			);
		}
	};

	return (
		<SendingDocument
			onChooseEmployee={onChooseEmployee}
			chosenEmp={chosenEmp}
			chosenRecipienats={chosenRecipienats}
			onChooseRecipienats={onChooseRecipienats}
			navigation={navigation}
			modalOpened={false}
			modalData={[]}
			id={id}
			onCancel={onNext}
			onSubmit={onSubmit}
			userData={userData}
			getEmployeesFilter={getEmployeesFilter}
			onRemoveRecipienats={onRemoveRecipienats}
			recipienatsKey={recipienatsKey}
			onChooseUnit={onChooseUnit}
			chosenUnit={chosenUnit}
			businessUnitsList={businessUnitsList}
		/>
	);
};

export default SendingDocumentContainer;
