import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { makeStyles } from '@material-ui/core';

import FlexTable from './Table';
import colors from '../../../config/colors';
import {
	getWageNameByCode,
	removeDisplayComponent,
} from '../ByEmployeeform/FromMonthToMonthReportsSalaryDataFormByEmployee.utils';
import { fromMonthToMonthListLike } from '../../../models/fromMonthToMonth.model';
import httpService from '../../../services/http.service';
import _, { compact } from 'lodash';
import useEffectNotInitial from '../../../hooks/useEffectNotInitial';
import { i18nService } from '../../../services/i18n.service';
import { resetBusyIndicator } from '../../../store/actions/config.actions';
import { dispatch } from '../../../store/store';

interface FromMonthToMonthReportsPageProps {
	disabled: boolean;
	data: [];
	selectedRowData: any;
	onSendReport: Function;
	customerWageComponents: any;
	handleAddBWage: Function;
	isFullScreen: boolean;
	customerDraftData: any;
	setCustomerDraftData: Function;
	setOnLineChange: Function;
	handleSelectLine: Function;
	tableRealTimeHight: number;
	displayComponents: any;
	setDisplayComponents: Function;
	getDisplayComponents: Function;
	onSort: Function;
	selectedRowIndex: any;
	pageData: any;
	getUndoAction: Function;
	undoAction: any;
	triggerUndo: number;
	removeOrAddNewComponent: boolean;
	setRemoveOrAddNewComponent: Function;
	triggerRemoveOrAddNewComponent: number;
	setTriggerRemoveOrAddNewComponent: Function;
	userData: any;
	undoIds: any;
	handleUndoIds: Function;
	selectUnitId: any;
}

interface FormInputs<T> {
	[key: string]: T;
}

const useStyles = makeStyles((theme: any) => ({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		direction: 'rtl',
		alignItems: 'flex-start',
		padding: ({ isFullScreen }: any) =>
			isFullScreen ? '10px 20px 0 0' : '10px 5px 0 0',
		overflowX: 'auto',
		overflowY: 'hidden',
	},
	itemWrapper: {
		backgroundColor: colors.white,
		margin: '0 10px 0 0px',
		padding: '0 10px',
	},
	noResultsFound: {
		color: colors.darkGrey,
		fontSize: '20px',
		fontWeight: 600,
		fontFamily: 'RubikRegular',
		padding: '20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},
}));

const ComprehensiveForm = ({
	data,
	selectedRowData,
	selectedRowIndex,
	disabled,
	customerWageComponents,
	handleAddBWage,
	isFullScreen,
	customerDraftData,
	setCustomerDraftData,
	setOnLineChange,
	handleSelectLine,
	tableRealTimeHight,
	displayComponents,
	setDisplayComponents,
	onSort,
	pageData,
	getUndoAction,
	undoAction,
	triggerUndo,
	removeOrAddNewComponent,
	setRemoveOrAddNewComponent,
	triggerRemoveOrAddNewComponent,
	setTriggerRemoveOrAddNewComponent,
	undoIds,
	handleUndoIds,
	userData,
	selectUnitId,
}: FromMonthToMonthReportsPageProps) => {
	const [allTableData, setAllTableData] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [editCurrentLineOnlyMode, setEditCurrentLineOnlyMode] =
		useState(false);
	const lastUpdateRow = useRef<any>();
	const preRelevantData = useRef<any>();
	const wageComponents = useMemo(
		() => compact(allTableData?.wageComponent),
		[allTableData?.wageComponent],
	);

	const additionalData = useMemo(
		() => compact(allTableData?.additionalData),
		[allTableData?.additionalData],
	);

	const optionalDeductions = useMemo(
		() => compact(allTableData?.optionalDeductions),
		[allTableData?.optionalDeductions],
	);

	const handlePreesRemove = useCallback(
		({ code, componentType }: any) => {
			const data = {
				componentType,
				code: Number(code) ? code : null,
			};
			removeDisplayComponent(
				data,
				displayComponents,
				setDisplayComponents,
				setRemoveOrAddNewComponent,
				setTriggerRemoveOrAddNewComponent,
				selectUnitId,
			);
		},
		[displayComponents],
	);

	const preperDataToServer = (
		data: any,
		extraData: any,
		allData: any,
		userId: number,
		allTableData: any,
	) => {
		if (extraData.componentType === 'WAGE') {
			if (
				data.hasOwnProperty('amount') ||
				data.hasOwnProperty('rate') ||
				data.hasOwnProperty('value')
			) {
				return {
					...extraData,
					value: allData.value,
					amount: allData.amount,
					rate: allData.rate,
					componentId: getComponentIdByUserId(
						'wageComponent',
						extraData.code,
						userId,
						allTableData,
					),
					...data,
				};
			} else if (data.hasOwnProperty('neto')) {
				return {
					...extraData,
					net: data.net === 'נטו' ? true : false,
					componentId: getComponentIdByUserId(
						'wageComponent',
						extraData.code,
						userId,
						allTableData,
					),
				};
			}
		} else if (extraData.componentType === 'OPTIONAL_DEDUCTION') {
			if (data.hasOwnProperty('value')) {
				return {
					...extraData,
					value: allData.value,
					componentId: getComponentIdByUserId(
						'optionalDeductions',
						extraData.code,
						userId,
						allTableData,
					),
				};
			}
		} else {
			return {
				...data,
				name: i18nService.translate(
					`FromMonthToMonthReports.formats.AdditionalData.${Object.keys(
						data,
					)}`,
				),
			};
		}
	};

	const getComponentIdByUserId = (
		type: string,
		code: number,
		id: number,
		list: any,
	) => {
		const componentId = list[type]
			?.filter((component: any) => component.code === code)[0]
			?.employees?.find((user: any) => user.id === id)?.componentId;
		return componentId || null;
	};

	const handleAutoSave = useCallback(
		(formMethods, extraData, originalValues, callback, selectedRowData) => {
			const values = formMethods.getValues();
			const keys = Object.keys(values);
			const newData: any = values;
			keys.forEach((key: any) => {
				if (!newData[key]) {
					delete newData[key];
				}
			});

			if (
				Object.keys(newData).length > 0 &&
				Object.keys(formMethods.formState.errors).length === 0
			) {
				if (
					(newData.hasOwnProperty('amount') ||
						newData.hasOwnProperty('rate')) &&
					newData.hasOwnProperty('value')
				) {
					return;
				}
				const relevantData: any = {};
				// get the all form data includes empty values
				const allData = formMethods.getValues();

				keys.forEach(key => {
					if (allData[key] != originalValues[key]) {
						relevantData[key] = allData[key];
					}
				});

				if (
					Object.keys(relevantData).length !== 0 &&
					!_.isEqual(relevantData, preRelevantData.current)
				) {
					setIsLoading(true);
					preRelevantData.current = { ...relevantData };
					const sendData = preperDataToServer(
						relevantData,
						extraData,
						values,
						selectedRowData.id,
						allTableData,
					);

					saveCustomerDraftData(
						selectedRowData?.id,
						selectedRowData?.draftId ||
							customerDraftData?.draft?.id,
						{ ...sendData },
						formMethods,
						callback,
						originalValues,
						userData.id,
					);
					setOnLineChange((prev: Array<number>) => [
						...prev,
						selectedRowData?.id,
					]);
				}
			}
		},
		[userData, allTableData],
	);

	const saveCustomerDraftData = (
		id: number,
		draftId: number,
		data: any,
		formMethods: any,
		callback: Function,
		originalValues: any,
		userDataId: any,
	) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'saveCustomerDraftData',
				query: { unitId: selectUnitId?.[0] },
				params: {
					id,
					draftId,
				},
				returnAllRes: true,
				data,
				disableBI: true,
			})
			.then(async (res: any) => {
				setIsLoading(false);
				if (res.status === 200) {
					const formValues = formMethods.getValues();
					getUndoAction();
					handleUndoIds(userDataId);
					lastUpdateRow.current = id;
					callback?.({ ...originalValues, ...formValues });
				}
			})
			.catch(err => {
				setIsLoading(false);
			});
	};

	const getComponentsDraftData = async (data: any, firstData: any) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getComponentsDraftData',
				query: { unitId: selectUnitId?.[0] },
				data,
			})
			.then(async (res: any) => {
				if (res) {
					const allData: any = {};
					allData.wageComponent = [];
					allData.optionalDeductions = [];
					allData.additionalData = [];
					res.forEach((component: any, index: number) => {
						if (component.componentType === 'ADDITIONAL') {
							const prevEmp =
								pageData.page &&
								allTableData?.additionalData?.[index]
									?.employees;

							allData.additionalData[index] = {
								componentType: component.componentType,
								employees: prevEmp
									? [...prevEmp, ...firstData]
									: firstData,
							};
						} else if (component.componentType === 'WAGE') {
							const prevEmp =
								pageData.page &&
								allTableData?.wageComponent?.[index]?.employees;
							const currentEmp = firstData.map((element: any) => {
								return getValuesByUderId(
									element.id,
									component.componentType,
									component.code,
									res,
								);
							});

							allData.wageComponent[index] = {
								componentType: component.componentType,
								code: component.code,
								name: getWageNameByCode(
									component.code,
									component.componentType,
									[],
									true,
									customerWageComponents,
								),
								employees: prevEmp
									? [...prevEmp, ...currentEmp]
									: currentEmp,
							};
						} else if (
							component.componentType === 'OPTIONAL_DEDUCTION'
						) {
							const prevEmp =
								pageData.page &&
								allTableData?.optionalDeductions?.[index]
									?.employees;
							const currentEmp = firstData.map((element: any) => {
								return getValuesByUderId(
									element.id,
									component.componentType,
									component.code,
									res,
								);
							});

							allData.optionalDeductions[index] = {
								componentType: component.componentType,
								code: component.code,
								name: getWageNameByCode(
									component.code,
									component.componentType,
									[],
									true,
									customerWageComponents,
								),
								employees: prevEmp
									? [...prevEmp, ...currentEmp]
									: currentEmp,
							};
						}
					});
					if (!allData.additionalData) {
						allData.additionalData = [];
					}
					if (!allData.wageComponent) {
						allData.wageComponent = [];
					}
					if (!allData.optionalDeductions) {
						allData.optionalDeductions = [];
					}
					setAllTableData(allData);
					dispatch(resetBusyIndicator());
				}
			});
	};

	const getValuesByUderId = (
		id: number,
		componentType: string,
		code: number,
		list: any,
	): any => {
		let user: any = {};
		list.forEach((item: any) => {
			if (item.code === code && item.componentType === componentType) {
				user = item.employees?.find(
					(employee: any) => employee.id === id,
				);
				return;
			}
		});

		if (componentType === 'WAGE') {
			return {
				id: id,
				componentId: user?.componentId || null,
				value: user?.value || '',
				amount: user?.amount || '',
				rate: user?.rate || '',
				net: user?.net || false,
			};
		} else {
			return {
				id: id,
				componentId: user?.componentId || null,
				value: user?.value || '',
			};
		}
	};

	const handleOnSort = (
		name: string,
		direction: string,
		code: number,
	): void => {
		let componentType;
		let newName;
		if (name === 'optional') {
			componentType = 'OPTIONAL_DEDUCTION';
		}
		if (
			name === 'amount' ||
			name === 'rate' ||
			name === 'sum' ||
			name === 'neto'
		) {
			componentType = 'WAGE';
		}
		newName = `${componentType}__${code}__${
			name === 'optional' || name === 'sum'
				? 'value'
				: name === 'neto'
				? 'net'
				: name
		}`;

		onSort(componentType ? newName : name, direction);
	};

	const getCustomerDraftData = (id: number) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getCustomerDraftData',
				query: { unitId: selectUnitId?.[0] },
				params: {
					id,
				},
			})
			.then(async (res: any) => {
				if (res) {
					data[selectedRowIndex].draftId = res.draft.id;
					setCustomerDraftData(res);
				}
			});
	};

	const handleOnPress = useCallback(
		(index: number) => {
			handleSelectLine(data[index]);
		},
		[data, handleSelectLine],
	);

	const classes = useStyles({ isFullScreen });

	const makeListFormatWithData = (
		displayComponents: any,
		addOrRemove?: boolean,
	) => {
		const currentEmp = data.slice(pageData.page * pageData.pageSize);
		const firstData = currentEmp.map((item: any) => {
			return {
				id: item.id,
				payworkdays: item.payworkdays,
				workdays: item.workdays,
				workingHours: item.workingHours,
				vacationUsed: item.vacationUsed,
				sickLeaveUsed: item.sickLeaveUsed,
			};
		});

		if (displayComponents.length > 0 || addOrRemove) {
			getComponentsDraftData(
				{
					employees: currentEmp.map((item: any) => item.id),
					components: displayComponents,
				},
				firstData,
			);
		}
	};

	useEffectNotInitial(() => {
		if (
			Object.keys(undoAction).length > 0 &&
			undoIds.includes(userData.id)
		) {
			getUndoAction();
		}
	}, [userData]);

	useEffect(() => {
		if (
			Object.keys(undoAction).length > 0 &&
			undoIds.includes(userData.id)
		) {
			getUndoAction();
		}
	}, [selectUnitId]);

	useEffect(() => {
		if (displayComponents.length > 0) {
			makeListFormatWithData(displayComponents);
		}
		setEditCurrentLineOnlyMode(true);
	}, [displayComponents, data]);

	useEffect(() => {
		if (!data[selectedRowIndex]?.draftId) {
			getCustomerDraftData(data[selectedRowIndex]?.id);
		}
	}, [selectedRowIndex]);

	useEffect(() => {
		if (removeOrAddNewComponent) {
			makeListFormatWithData(displayComponents, true);
			setRemoveOrAddNewComponent(false);
		}
	}, [triggerRemoveOrAddNewComponent]);

	useEffectNotInitial(() => {
		makeListFormatWithData(displayComponents);
	}, [triggerUndo]);

	const disabledByComponent = (
		list: any,
		code: string,
		type: string,
	): boolean => {
		if (list[type].length === 0) return false;
		const showEdit = list[type].find((c: any) => c.code === code)?.showEdit;
		return showEdit === 2 ? false : true;
	};

	return (
		<div className={classes.container}>
			{additionalData?.map((item: any) => (
				<div
					className={classes.itemWrapper}
					key={`${JSON.stringify(item)}`}
				>
					<FlexTable
						key={`${JSON.stringify(item)}`}
						type={'table'}
						data={item}
						tableName={
							'FromMonthToMonthReports.formats.AdditionalData.header'
						}
						disabled={disabled || false}
						isLoading={isLoading}
						onBlur={handleAutoSave}
						onPreesRemove={handlePreesRemove}
						onPreesEdit={handleAddBWage}
						onPress={handleOnPress}
						keyboardType={['numeric', 'neto']}
						toFixed={2}
						headerBackgroundColor={
							disabled ? colors.tableGrayHeaders : colors.primary
						}
						userId={selectedRowData.id}
						nextUserId={data[selectedRowIndex + 1]?.id}
						tableRealTimeHight={tableRealTimeHight}
						onSort={handleOnSort}
						maxLength={10}
						forceMin={true}
						editCurrentLineOnlyMode={editCurrentLineOnlyMode}
						selectedRowData={selectedRowData}
						triggerUndo={triggerUndo}
						lastUpdateRow={lastUpdateRow}
					/>
				</div>
			))}
			{wageComponents?.map((item: any) => (
				<div
					className={classes.itemWrapper}
					key={`${JSON.stringify(item)}`}
				>
					<FlexTable
						key={`${JSON.stringify(item)}`}
						type={'table'}
						data={item}
						tableName={`${item.name} - ${item.code}`}
						disabled={
							disabled ||
							disabledByComponent(
								customerWageComponents,
								item.code,
								'wageComponent',
							) ||
							false
						}
						onBlur={handleAutoSave}
						onPreesRemove={handlePreesRemove}
						onPreesEdit={handleAddBWage}
						onPress={handleOnPress}
						keyboardType={['numeric', 'neto']}
						toFixed={2}
						headerBackgroundColor={
							disabled ||
							disabledByComponent(
								customerWageComponents,
								item.code,
								'wageComponent',
							)
								? colors.tableGrayHeaders
								: colors.primary
						}
						userId={selectedRowData.id}
						nextUserId={data[selectedRowIndex + 1]?.id}
						tableRealTimeHight={tableRealTimeHight}
						onSort={handleOnSort}
						maxLength={10}
						forceMin={true}
						editCurrentLineOnlyMode={editCurrentLineOnlyMode}
						selectedRowData={selectedRowData}
						triggerUndo={triggerUndo}
						lastUpdateRow={lastUpdateRow}
					/>
				</div>
			))}
			{optionalDeductions?.map((item: any) => (
				<div
					className={classes.itemWrapper}
					key={`${JSON.stringify(item)}`}
				>
					<FlexTable
						key={`${JSON.stringify(item)}`}
						type={'table'}
						data={item}
						tableName={
							'FromMonthToMonthReports.formats.OptionalDeductions.header'
						}
						disabled={
							disabled ||
							disabledByComponent(
								customerWageComponents,
								item.code,
								'optionalDeductionsComponent',
							) ||
							false
						}
						onBlur={handleAutoSave}
						onPreesRemove={handlePreesRemove}
						onPreesEdit={handleAddBWage}
						onPress={handleOnPress}
						keyboardType={['numeric', 'neto']}
						toFixed={2}
						headerBackgroundColor={
							disabled ||
							disabledByComponent(
								customerWageComponents,
								item.code,
								'wageComponent',
							)
								? colors.tableGrayHeaders
								: colors.primary
						}
						maxLength={10}
						userId={selectedRowData.id}
						nextUserId={data[selectedRowIndex + 1]?.id}
						tableRealTimeHight={tableRealTimeHight}
						onSort={handleOnSort}
						forceMin={true}
						editCurrentLineOnlyMode={editCurrentLineOnlyMode}
						selectedRowData={selectedRowData}
						triggerUndo={triggerUndo}
						lastUpdateRow={lastUpdateRow}
					/>
				</div>
			))}
		</div>
	);
};

export default React.memo(ComprehensiveForm);
