import React, { useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { compact } from 'lodash';
import moment from 'moment';

import I18n from '../../../../components/shared/i18n/I18n.component';
import colors from '../../../../config/colors';
import { i18nService } from '../../../../services/i18n.service';
import Select from '../../../../components/web/Select/Select.component';
import httpService from '../../../../services/http.service';
import { FilterData } from '../../../../components/web/Filters/Filters.container';
import { EnterCustomerComponenet } from '../RepReports.utils';

const newDate = new Date();
export const defaultFilters = {
	unitIds: [],
	year: [
		Number(moment(newDate).month().toString()) === 0
			? Number(moment(newDate).subtract(1, 'year').year().toString())
			: Number(moment(newDate).year().toString()),
	],
	repCustomerIds: [],
	repCustomerUnitIds: [],
	operatorIds: [],
	reportType: ['SEMIANNUAL', 'ANNUAL', 'FINAL'],
	type: ['SOCIAL_SECURITY', 'IRS'],
	status: [],
};

export const institutionTypeList = [
	{
		id: 'SOCIAL_SECURITY',
		name: i18nService.translate(
			'repReports.repOrgReports.status.filter.SOCIAL_SECURITY',
		),
	},
	{
		id: 'IRS',
		name: i18nService.translate(
			'repReports.repOrgReports.status.filter.IRS',
		),
	},
];

export const reportTypeOptions = (typeCode: string) => {
	return compact([
		typeCode !== 'IRS' && {
			id: 'SEMIANNUAL',
			name: i18nService.translate('repDashboard.yearly.type.SEMIANNUAL'),
		},
		typeCode !== 'IRS' && {
			id: 'ANNUAL',
			name: i18nService.translate('repDashboard.yearly.type.ANNUAL'),
		},
		{
			id: 'FINAL',
			name: i18nService.translate('repDashboard.yearly.type.FINAL'),
		},
	]);
};

export const statusOptions126 = (typeCode: string, isFilter: boolean) => {
	return compact([
		isFilter && {
			id: 'NO_RESULTS',
			name: i18nService.translate('repDashboard.yearly.126BL.NO_RESULTS'),
		},
		{
			id: 'BEFORE_TRANS',
			name: i18nService.translate(
				'repDashboard.yearly.126BL.BEFORE_TRANS',
			),
		},
		typeCode !== 'IRS' && {
			id: 'TRANS_PENDING_APPROVE',
			name: i18nService.translate(
				'repDashboard.yearly.126MS.TRANS_PENDING_APPROVE',
			),
		},
		typeCode !== 'IRS' && {
			id: 'TRANS_APPROVE',
			name: i18nService.translate(
				'repDashboard.yearly.126MS.TRANS_APPROVE',
			),
		},
		typeCode !== 'SOCIAL_SECURITY' && {
			id: 'TRANS_PENDING_CHECK',
			name: i18nService.translate(
				'repDashboard.yearly.126BL.TRANS_PENDING_CHECK',
			),
		},
		typeCode !== 'SOCIAL_SECURITY' && {
			id: 'TRANS_VALID',
			name: i18nService.translate(
				'repDashboard.yearly.126BL.TRANS_VALID',
			),
		},
	]);
};

export const StatusSection = ({
	status,
	statusName,
	dotOnly,
}: {
	status: any;
	statusName: string;
	dotOnly?: boolean;
}) => {
	return (
		<View style={[styles.statusSection]}>
			<View
				style={[
					styles.statusDot,
					{ backgroundColor: colors.repStatus[status] },
				]}
			></View>
			{!dotOnly && (
				<View>
					<I18n size={14}>{statusName}</I18n>
				</View>
			)}
		</View>
	);
};

export const StatusTableSection = ({
	data,
	disabled,
	refresh,
}: {
	data: any;
	disabled: boolean;
	refresh(): void;
}) => {
	const statusRef = useRef<any>();
	statusRef.current = { status: data.statusCode, statusName: data.status };

	const onSelect = async (val: any) => {
		try {
			const res = await httpService.api<any>({
				type: 'updateOrgReportStatus',
				returnAllRes: true,
				data: {
					status: val.id,
				},
				params: {
					id: data.id,
					type: 126,
				},
			});
			if (res.status === 200) {
				refresh();
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<View key={data.id} style={{ flexDirection: 'row', minWidth: 120 }}>
			{disabled ||
			statusRef.current?.status === 'NO_RESULTS' ||
			statusRef.current?.status === 'NO_TRANS_REQUIRED' ? (
				<StatusSection
					status={statusRef.current?.status}
					statusName={statusRef.current?.statusName}
				/>
			) : (
				<View style={{ flexDirection: 'row' }}>
					<StatusSection
						status={statusRef.current?.status}
						statusName={statusRef.current?.statusName}
						dotOnly={true}
					/>
					<Select
						key={`${data.id}-${statusRef.current?.status}`}
						dontTranslate
						options={statusOptions126(data.typeCode, false)}
						menuPlacement={'auto'}
						menuPosition={'absolute'}
						placeholder={statusRef.current?.statusName}
						selectedOption={{ name: statusRef.current?.statusName }}
						style={{ minWidth: 130 }}
						onSelection={onSelect}
						menuPortalTarget={document.body}
						closeMenuOnScroll={v => true}
						styles={{
							option: { justifyContent: 'flex-end' },
							menu: { top: undefined },
						}}
					/>
				</View>
			)}
		</View>
	);
};

export const getHeaders = (
	disabled: boolean,
	refresh: any,
	canEnterCustomer: boolean,
	routeName: string,
) => {
	return compact([
		{
			accessor: 'year',
			Header: i18nService.translate(
				'repReports.general.tableHeader.year',
			),
		},
		{
			accessor: 'repCustomer',
			Header: i18nService.translate(
				'repReports.general.tableHeader.repCustomer',
			),
			isSortable: true,
		},
		{
			accessor: 'hp',
			Header: i18nService.translate('repReports.general.tableHeader.hp'),
			isSortable: true,
		},
		{
			accessor: 'businessUnitName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.businessUnitName',
			),
			isSortable: true,
		},
		{
			accessor: 'operatorName',
			Header: i18nService.translate(
				'repReports.general.tableHeader.operatorName',
			),
			isSortable: true,
		},
		{
			accessor: 'type',
			Header: i18nService.translate(
				'repReports.repOrgReports.tableHeader.type',
			),
			isSortable: true,
		},
		{
			accessor: 'reportType',
			Header: i18nService.translate(
				'repReports.repOrgReports.tableHeader.reportType',
			),
			isSortable: true,
		},
		{
			accessor: 'status',
			Header: i18nService.translate(
				'repReports.repOrgReports.tableHeader.status',
			),
			isSortable: true,
			CustomCell: ({ row, column }: any) => (
				<StatusTableSection
					data={row.original}
					disabled={disabled}
					refresh={refresh}
				/>
			),
		},
		{
			accessor: 'lastUpdate',
			Header: i18nService.translate(
				'repReports.general.tableHeader.lastUpdate',
			),
			isSortable: true,
		},
		canEnterCustomer && {
			accessor: 'enterCustomer',
			Header: '',
			CustomCell: ({ row }: any) => {
				return (
					<EnterCustomerComponenet
						data={row.original}
						routeName={routeName}
					/>
				);
			},
		},
	]);
};

const styles = StyleSheet.create({
	statusSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	statusDot: {
		width: 12,
		height: 12,
		borderRadius: 6,
		marginLeft: 6,
	},
});

export const handleFilter = (
	data: FilterData,
	setFilters: Function,
	filters: any,
	prevReportType: any,
	setPrevReportType: Function,
	setRepCustData: Function,
) => {
	if (data.name === 'unitIds') {
		setRepCustData([]);
		setFilters({
			...defaultFilters,
			unitIds: [data.value],
		});
	} else if (data.name === 'year') {
		setFilters({
			...filters,
			year: [Number(moment(data.value).year().toString())],
		});
	} else if (data.name === 'repCustomerIds') {
		setRepCustData([]);
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerIds: chosenIds,
		});
	} else if (data.name === 'repCustomerUnitIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			repCustomerUnitIds: chosenIds,
		});
	} else if (data.name === 'operatorIds') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			operatorIds: chosenIds?.includes?.('all') ? [] : chosenIds,
		});
	} else if (data.name === 'type') {
		if (
			data.value[0]?.id === 'IRS' &&
			data.value?.length === 1 &&
			(!filters.type.includes('IRS') || filters.type?.length !== 1)
		) {
			setPrevReportType(filters.reportType);
		}
		if (data.value?.length) {
			const chosenIds = data.value.map((val: any) => val.id || val);
			setFilters({
				...filters,
				type: chosenIds,
				status: [],
				reportType:
					data.value[0]?.id === 'IRS' && data.value?.length === 1
						? ['FINAL']
						: prevReportType,
			});
		} else {
			setFilters({
				...filters,
				type: defaultFilters.type,
				status: [],
				reportType:
					data.value[0]?.id === 'IRS' && data.value?.length === 1
						? ['FINAL']
						: prevReportType,
			});
		}
	} else if (data.name === 'reportType') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			reportType: chosenIds,
		});
	} else if (data.name === 'status') {
		const chosenIds = data.value.map((val: any) => val.id || val);
		setFilters({
			...filters,
			status: chosenIds,
		});
	}
};
