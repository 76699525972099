import React, { useMemo, useEffect, useState, useContext } from "react";
import {
	View,
	StyleSheet,
	Dimensions,
	useWindowDimensions,
	Platform,
	ScrollView,
	Image,
	TouchableHighlight,
} from 'react-native';
const imageEmptyState = require('../../../../assets/images/reportEmptyState/report-empty.png');
import colors from '../../../config/colors';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Typography from '../../../components/shared/Typography/Typography.component';
import { convertFromUtc } from '../../../components/shared/ClockReport/Clock.utils';
import { getServerDateTime } from '../../../store/selectors/dateTime.selectors';
import I18n from '../../../components/shared/i18n/I18n.component';
import WorkLog from '../../../components/shared/ClockReport/Record/Record.component';
import { Record } from '../../../components/shared/ClockReport/Record/Record.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import getShadowStyle from '../../../config/shadow';
import { i18nService } from '../../../services/i18n.service';
import { toNumber } from 'lodash';

const convertToHoursFormat = (time) => {
  if (!time) return "00:00";
  const [hours, minutes, seconds] = time.split(":");
  return `${toNumber(hours).toLocaleString()}:${minutes}`;
};

const convertHoursFormat = (
  minutesValue: any,
  format?: string,
  inminutes?: boolean
) => {
  if (minutesValue) {
    const value = inminutes ? minutesValue : Math.floor(minutesValue * 60);
    if (format === "HH:mm") {
      const hours = `${Math.floor(value / 60)?.toLocaleString()}`;
      const minutes =
        value % 60 !== 0
          ? value % 60 < 10
            ? `0${value % 60}`
            : `${value % 60}`
          : "00";
      return `${hours}:${minutes}`;
    }
    return `${(value / 60).toFixed(2)}`;
  }
  return "00:00";
};

interface Props {
  reportSelectedDate: any[];
  openDrawer: any;
  onDelete: Function;
  onEdit: Function;
  hideButton: boolean;
  addWorkLog: Function;
  standardHours: any;
  totalReported: any;
  isToday: boolean;
  selectedDay: any;
  canEdit: boolean;
}

const DayReportsWeb = ({
  canEdit,
  onDelete,
  onEdit,
  hideButton,
  addWorkLog,
  standardHours,
  totalReported,
  isToday,
  selectedDay,
  reportSelectedDate,
}: Props) => {
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const maxWidth = windowWidth > 1700 ? 612 : windowWidth > 1500 ? 540 : 480;
  const contentBlock = {
    marginTop: 28,
    marginBottom: 48,
    height: windowHeight - 167,
    backgroundColor: colors.white,
    width: "100%",
    maxWidth: maxWidth,
    padding: 20,
  };
  const isTotalReportedSmallerThanStandard = useMemo(()=>{
    let standard = 0;
    if(standardHours?.fixHours){
      standard = moment(standardHours.fixHours, "HH:mm:ss").diff(moment("00:00","HH:mm"),"minutes")/60
    }

    return  totalReported < standard
    
  },[totalReported , standardHours])
  const standard = useMemo(()=>{
    return convertToHoursFormat(standardHours?.fixHours)
  },[standardHours])
  
  return (
    <View style={contentBlock}>
      <View nativeID="detailsHeader" style={styles.detailsHeader}>
        <View style={{ flexDirection: "row" }}>
          <Typography size={18} color={standard=== "0:00"?"darkGrey":isTotalReportedSmallerThanStandard?"red":"green"} weight="400">
            {`${convertHoursFormat(totalReported, "HH:mm", false)}`}
          </Typography>
          <Typography size={18} color={"darkGrey"} weight="bold">
            {`/${standard}`}
          </Typography>
        </View>
      </View>
      <I18n
        style={{
          width: "95%",
          textAlign: "right",
          paddingHorizontal: 10,
        }}
        size={18}
        color="darkGrey"
        weight="normal"
      >
        {`${i18nService.translate("reportView.reportFrom")} ${selectedDay}`}
      </I18n>
      {reportSelectedDate?.length ? (
        <View style={{ flex: 1, width: "100%"  , marginTop:10}}>
          <View
            nativeID="tableHolder"
            style={{ width: "100%", justifyContent: "flex-end", flex: 1 }}
          >
            <ScrollView nativeID="tableHolder" style={styles.tableHolder}>
              {reportSelectedDate?.map((record: Record) => (
                <WorkLog
                  onEdit={onEdit}
                  onDelete={onDelete}
                  isToday={isToday}
                  key={record.workLogId}
                  record={record}
                />
              ))}
              <View style={styles.recordLine} />
            </ScrollView>
          </View>
        </View>
      ) : (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Image
            source={imageEmptyState}
            fadeDuration={0}
            style={{
              height: 86,
              width: 178,
              marginTop: 50,
            }}
          />
          <I18n size={18} color={"darkGrey"} weight="400">
            {isToday
              ? "reportView.noWorklogsToday"
              : "general.noLogsForThisDate"}
          </I18n>
        </View>
      )}
      <View style={styles.addButtonHolder}>
        {!hideButton && canEdit && (
          <TouchableHighlight
            style={styles.fabButton}
            underlayColor="transparent"
            onPress={addWorkLog}
          >
            <ImageIcon name={"add"} width={40} height={40}></ImageIcon>
          </TouchableHighlight>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  line: {
    opacity: 0.2,
    width: "95%",
    height: 0,
    borderTopColor: "#525962",
    borderTopWidth: 2,
    marginVertical: 5,
  },
  recordLine: {
    opacity: 0.2,
    width: "100%",
    height: 0,
    borderTopColor: "#525962",
    borderTopWidth: 2,
    marginVertical: 5,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "calc(100vh - 90px)",
  },
  detailsHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  addButtonHolder: {
    flexDirection: "row",
    width: "100%",
    padding: 10,
  },
  tableHolder: {
    width: "100%",
    minHeight: 140,
    direction: "ltr",
    paddingHorizontal: 15,
    flex: 1,
  },
  fabButton: {
    borderRadius: 30,
    width: 40,
    height: 40,
    marginTop: 10,
    marginLeft: 10,
    ...getShadowStyle(20, "rgb(59, 112, 164)"),
  },
});
export default DayReportsWeb;
