import { compact } from 'lodash';
import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import Typography from '../../../components/shared/Typography/Typography.component';
import colors from '../../../config/colors';
import routes from '../../../config/routes';
import { i18nService } from '../../../services/i18n.service';
import { getBusinessUnitsListByPermissions } from '../../../store/selectors/reportsFilters.selectors';
import {
	addCommaSeparatorToNumber,
	addCommaSeparatorToNumberAndFixMinus,
	reverseNumberWithPresentage,
} from '../../../utils/reportsPagesHelpers.utils';

export const initialHeaders: any[] = [
	{
		accessor: 'expander',
		Header: '',
		id: 'expander',
		style: { width: 0, right: 0 },
		sticky: 'right',
	},
	{
		accessor: 'item',
		Header: i18nService.translate('workPaper.table.item'),
		CustomCell: ({ value, row, ...rest }: any) => {
			return (
				<Typography
					weight={row.original.style?.fontWeight || 'bold'}
					size={14}
					color={row.original.style?.color || colors.primary}
				>
					{value}
				</Typography>
			);
		},
		SubCell: ({ value, row, ...rest }: any) => {
			return (
				<Typography size={14} color='primary'>
					{value}
				</Typography>
			);
		},
		style: { minWidth: 150, width: 150, right: 64 },
		sticky: 'right',
	},
	{
		accessor: 'sum',
		Header: i18nService.translate('workPaper.table.sum'),
		CustomCell: ({ value, row, ...rest }: any) => {
			return (
				<Typography weight='bold' size={14}>
					{value}
				</Typography>
			);
		},
		subCellStyle: { backgroundColor: colors.totalColumn },
		cellStyle: (row: any) => ({
			backgroundColor:
				row.original.style?.backgroundColor !== colors.tableHeaders
					? colors.totalRowAndColumn
					: colors.tableHeaders,
		}),
		style: { minWidth: 130, width: 130, right: 238 },
		sticky: 'right',
	},
];

export const rowsOrder = [
	'wageComponent',
	'totalWageComponent',
	'taxComponent',
	'totalTaxComponent',
	'saperator',
	'totalGross',
	'taxableWage',
	'mandatoryDeductionsComponent',
	'totalMandatoryDeductions',
	'saperator',
	'personalNetWages',
	'optionalDeductionsComponent',
	'totalOptionalDeductions',
	'saperator',
	'totalPayment',
	'moreDetails',
];

export const moreDetailsOrder = [
	'workdays',
	'workingHours',
	'vacationUsed',
	'lastVacationLeft',
	'sickLeaveUsed',
	'lastSickLeaveLeft',
];

export const sectionHeaderStyle = {
	padding: '0px 16px 0px 0px',
	height: 10,
	backgroundColor: colors.tableHeaders,
	color: colors.white,
	fontWeight: '400',
};

export const saperatorStyle = {
	padding: '2px 18px 1px 18px',
	height: 27,
	backgroundColor: colors.tableHeaders,
	color: colors.white,
};

export const totalStyle = {
	backgroundColor: colors.totalRow,
	fontWeight: 'bold',
	fontFamily: 'RubikBold',
};

export const subRowStyle = {
	backgroundColor: colors.white,
	color: colors.darkGrey,
};

export const initialRows = {
	wageComponent: {
		canHaveSubRows: true,
		subRows: {},
		item: i18nService.translate('workPaper.table.wageComponent'),
		style: sectionHeaderStyle,
	},
	totalWageComponent: {
		item: `${i18nService.translate(
			'workPaper.table.sum',
		)} ${i18nService.translate('workPaper.table.wageComponent')}`,
		style: totalStyle,
	},
	taxComponent: {
		canHaveSubRows: true,
		subRows: {},
		item: i18nService.translate('workPaper.table.taxComponent'),
		style: sectionHeaderStyle,
	},
	totalTaxComponent: {
		item: `${i18nService.translate(
			'workPaper.table.sum',
		)} ${i18nService.translate('workPaper.table.taxComponent')} `,
		style: totalStyle,
	},
	saperator: { isSaperator: true, style: sectionHeaderStyle },
	totalGross: {
		item: i18nService.translate('workPaper.table.totalGross'),
		style: totalStyle,
	},
	taxableWage: {
		item: i18nService.translate('workPaper.table.taxableWage'),
		style: totalStyle,
	},
	mandatoryDeductionsComponent: {
		canHaveSubRows: true,
		subRows: {},
		item: i18nService.translate(
			'workPaper.table.mandatoryDeductionsComponent',
		),
		style: sectionHeaderStyle,
	},
	totalMandatoryDeductions: {
		item: `${i18nService.translate(
			'workPaper.table.sum',
		)} ${i18nService.translate(
			'workPaper.table.mandatoryDeductionsComponent',
		)}`,
		style: totalStyle,
	},
	personalNetWages: {
		item: i18nService.translate('workPaper.table.personalNetWages'),
		style: totalStyle,
	},
	optionalDeductionsComponent: {
		canHaveSubRows: true,
		subRows: {},
		item: i18nService.translate(
			'workPaper.table.optionalDeductionsComponent',
		),
		style: sectionHeaderStyle,
	},
	totalOptionalDeductions: {
		item: `${i18nService.translate(
			'workPaper.table.sum',
		)} ${i18nService.translate(
			'workPaper.table.optionalDeductionsComponent',
		)}`,
		style: totalStyle,
	},
	totalPayment: {
		item: i18nService.translate('workPaper.table.totalPayment'),
		style: totalStyle,
	},
	moreDetails: {
		canHaveSubRows: true,
		subRows: {
			workdays: {
				item: i18nService.translate('workPaper.table.workdays'),
			},
			workingHours: {
				item: i18nService.translate('workPaper.table.workingHours'),
			},
			vacationUsed: {
				item: i18nService.translate('workPaper.table.vacationUsed'),
			},
			lastVacationLeft: {
				item: i18nService.translate('workPaper.table.lastVacationLeft'),
			},
			sickLeaveUsed: {
				item: i18nService.translate('workPaper.table.sickLeaveUsed'),
			},
			lastSickLeaveLeft: {
				item: i18nService.translate(
					'workPaper.table.lastSickLeaveLeft',
				),
			},
		},
		item: i18nService.translate('workPaper.table.moreDetails'),
		style: sectionHeaderStyle,
	},
};

const getDynamicSubRows = (
	current: any,
	data: any,
	prev: any,
	fixingDataFunc: Function,
	reverseNumber?: Function,
) =>
	data.reduce(
		(res: any, curr: any) => {
			return {
				...res,
				[`${curr.code}${curr.name}`]: {
					...(res[`${curr.code}${curr.name}`] || {}),
					item: reverseNumber?.(curr.name) || curr.name,
					[current.profileId]: fixingDataFunc(curr.value),
					style: subRowStyle,
				},
			};
		},
		{ ...(prev || {}) },
	);

export const getCurrentRow = (
	rows: any,
	current: any,
	fixingDataFunc: Function,
	reverseNumber?: Function,
) => ({
	...rows,
	wageComponent: {
		...rows.wageComponent,
		subRows: getDynamicSubRows(
			current,
			current.wageComponent,
			rows.wageComponent.subRows,
			fixingDataFunc,
			reverseNumber,
		),
	}, //
	totalWageComponent: {
		...rows.totalWageComponent,
		[current.profileId]: fixingDataFunc(current.totalWageComponent),
	},
	taxComponent: {
		...rows.taxComponent,
		subRows: getDynamicSubRows(
			current,
			current.taxComponent,
			rows.taxComponent.subRows,
			fixingDataFunc,
			reverseNumber,
		),
	}, //
	totalTaxComponent: {
		...rows.totalTaxComponent,
		[current.profileId]: fixingDataFunc(current.totalTaxComponent),
	},
	totalGross: {
		...rows.totalGross,
		[current.profileId]: fixingDataFunc(current.totalGross),
	},
	taxableWage: {
		...rows.taxableWage,
		[current.profileId]: fixingDataFunc(current.taxableWage),
	},
	mandatoryDeductionsComponent: {
		...rows.mandatoryDeductionsComponent,
		subRows: getDynamicSubRows(
			current,
			current.mandatoryDeductionsComponent,
			rows.mandatoryDeductionsComponent.subRows,
			fixingDataFunc,
			reverseNumber,
		),
	}, //
	totalMandatoryDeductions: {
		...rows.totalMandatoryDeductions,
		[current.profileId]: fixingDataFunc(current.totalMandatoryDeductions),
	},
	personalNetWages: {
		...rows.personalNetWages,
		[current.profileId]: fixingDataFunc(current.personalNetWages),
	},
	optionalDeductionsComponent: {
		...rows.optionalDeductionsComponent,
		subRows: getDynamicSubRows(
			current,
			current.optionalDeductionsComponent,
			rows.optionalDeductionsComponent.subRows,
			fixingDataFunc,
			reverseNumber,
		),
	}, //
	totalOptionalDeductions: {
		...rows.totalOptionalDeductions,
		[current.profileId]: fixingDataFunc(current.totalOptionalDeductions),
	},
	totalPayment: {
		...rows.totalPayment,
		[current.profileId]: fixingDataFunc(current.totalPayment),
	},
	moreDetails: {
		...rows.moreDetails,
		subRows: {
			workdays: {
				...rows.moreDetails.subRows.workdays,
				[current.profileId]: fixingDataFunc(current.workdays),
				style: subRowStyle,
			},
			workingHours: {
				...rows.moreDetails.subRows.workingHours,
				[current.profileId]: fixingDataFunc(current.workingHours),
				style: subRowStyle,
			},
			vacationUsed: {
				...rows.moreDetails.subRows.vacationUsed,
				[current.profileId]: fixingDataFunc(current.vacationUsed),
				style: subRowStyle,
			},
			lastVacationLeft: {
				...rows.moreDetails.subRows.lastVacationLeft,
				[current.profileId]: fixingDataFunc(current.lastVacationLeft),
				style: subRowStyle,
			},
			sickLeaveUsed: {
				...rows.moreDetails.subRows.sickLeaveUsed,
				[current.profileId]: fixingDataFunc(current.sickLeaveUsed),
				style: subRowStyle,
			},
			lastSickLeaveLeft: {
				...rows.moreDetails.subRows.lastSickLeaveLeft,
				[current.profileId]: fixingDataFunc(current.lastSickLeaveLeft),
				style: subRowStyle,
			},
		},
	},
});

export const convertToRowsArray = (rows: any) =>
	rowsOrder.map(key => ({
		...rows[key],
		subRows: rows[key]?.subRows
			? key !== 'moreDetails'
				? Object.values(rows[key].subRows)
				: moreDetailsOrder.map(sub => rows[key].subRows[sub])
			: undefined,
	}));

export const buildReportData = (
	res: { data: any; totalRow: any },
	options?: { noMinusFixing: boolean; reverseSubTitles: boolean },
) => {
	const fixingDataFunc = options?.noMinusFixing
		? addCommaSeparatorToNumber
		: addCommaSeparatorToNumberAndFixMinus;
	const reverseNumber =
		options?.reverseSubTitles && reverseNumberWithPresentage;
	const rowWithTotal =
		res.totalRow &&
		getCurrentRow(
			initialRows,
			{
				...(res.totalRow || []),
				profileId: 'sum',
			},
			fixingDataFunc,
			reverseNumber,
		);

	const { columns, rows } =
		rowWithTotal && res.data.length
			? res?.data?.reduce(
					(result: any, current: any, index: number) => ({
						columns: [
							...result.columns,
							{
								accessor: `${current.profileId}`,
								Header: () => {
									const businessUnitsList = useSelector(
										(state: any) =>
											getBusinessUnitsListByPermissions(
												state,
												routes.workPaper.privileges,
											),
									);
									return (
										<View>
											<Typography
												size={14}
												weight='normal'
											>
												{current.fullName}
											</Typography>
											<Typography
												size={14}
												weight='normal'
											>
												{current.idNumber}
											</Typography>
											{businessUnitsList.length > 1 ? (
												<Typography size={12}>
													{` (${current.businessUnitName})`}
												</Typography>
											) : null}
										</View>
									);
								},
								style:
									index === res.data.length - 1
										? {}
										: { minWidth: 130, width: 130 },
							},
						],
						rows: {
							...result.rows,
							...getCurrentRow(
								result.rows,
								current,
								fixingDataFunc,
								reverseNumber,
							),
						},
					}),
					{
						columns: initialHeaders,
						rows: rowWithTotal,
					},
			  )
			: { columns: initialHeaders, rows: [] };
	const rowsArray =
		rowWithTotal && res.data.length && convertToRowsArray(rows);
	return { columns, rows: rowsArray };
};

export const getFiltersConfig = (
	departmentsList: any,
	selectedDepartments: any,
	getEmployeesFilter: Function,
	date: any,
	lastMonth: any,
	departmentsKey: string,
	businessUnitsList: any,
	isBusinessUnitsMulti: boolean,
	unitIds: any,
) => {
	let dateTo =
		date.to_month && date.to_year
			? new Date(date.to_year, date.to_month - 1)
			: new Date();
	let dateFrom =
		date.from_month && date.from_year
			? new Date(date.from_year, date.from_month - 1)
			: new Date();

	const dateToPlaceholder =
		date.to_month?.length && date.to_year?.length
			? `${i18nService.translate(
					`months.${moment(dateTo).format('MMMM')}`,
			  )} ${dateTo.getFullYear()}`
			: i18nService.translate('worklogReportPage.date');
	const dateFromPlaceholder =
		date.from_month?.length && date.from_year?.length
			? `${i18nService.translate(
					`months.${moment(dateFrom).format('MMMM')}`,
			  )} ${dateFrom.getFullYear()}`
			: i18nService.translate('worklogReportPage.date');

	return compact([
		{
			name: 'employees',
			type: 'asyncmultiselect',
			placeholder: 'employees',
			loadOptions: getEmployeesFilter,
			styles: {
				width: 274,
			},
			key: `${JSON.stringify(
				selectedDepartments,
			)}${departmentsList}${JSON.stringify(unitIds)}`,
		},
		{
			name: 'departments',
			type: 'multiselect',
			options: departmentsList,
			placeholder: 'departments',
			styles: {
				width: 200,
			},
			key: departmentsKey,
		},
		{
			name: 'date_to',
			type: 'datepicker',
			date: dateTo,
			label: 'filter.to',
			placeholder: dateToPlaceholder,
			minDate: dateFrom,
			maxDate: lastMonth,
			// showYearPicker: true,
			styles: {
				width: 140,
			},
		},
		{
			name: 'date_from',
			type: 'datepicker',
			date: dateFrom,
			maxDate: dateTo,
			label: 'filter.from',
			placeholder: dateFromPlaceholder,
			// showYearPicker: true,
			styles: {
				width: 140,
			},
		},
		businessUnitsList?.length > 1
			? isBusinessUnitsMulti
				? {
						name: 'unitIds',
						type: 'multiselect',
						placeholder: 'businessUnits',
						value: unitIds?.map((unit: any) =>
							businessUnitsList.find(
								(bunit: any) => bunit.id === unit,
							),
						),
						options: businessUnitsList,
						styles: {
							control: {
								width: 200,
								maxWidth: 200,
							},
						},
				  }
				: {
						name: 'unitIds',
						type: 'select',
						placeholder: 'businessUnits',
						defaultValue: unitIds?.[0],
						options: businessUnitsList,
						hideDefaultOption: true,
						styles: {
							width: 150,
						},
				  }
			: undefined,
	]);
};
